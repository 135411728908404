import { useHistory } from "react-router-dom";
import React from "react";
import ResultContent from "src/app/components/Commons/ResultContent";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { ROUTE } from "src/app/configs/constants";

type MintCompleteModalProps = {
  mintAmount: number;
};
export default function MintCompleteModal(props: MintCompleteModalProps) {
  const { mintAmount } = props;
  const history = useHistory();

  function redirectToWallet() {
    history.push(`${ROUTE.WALLET}?owned=true&sortBy=Highest%20Level&page=1`);
    modalService.close();
  }
  return (
    <BasicModalContent
      onSubmit={() => redirectToWallet()}
      submitText={"Go to Wallet"}
      content={
        <ResultContent
          isSuccess
          content={
            <div>
              Congratulations! You have successfully minted {mintAmount} lands. Please wait a bit before your lands
              appear in your wallet and in the marketplace.
            </div>
          }
        />
      }
    />
  );
}
