import React from "react";
import common from "src/assets/images/instructions/common.png";
import uncommon from "src/assets/images/instructions/uncommon.png";
import rare from "src/assets/images/instructions/rare.png";
import epic from "src/assets/images/instructions/epic.png";
import legendary from "src/assets/images/instructions/legendary.png";
import mythical from "src/assets/images/instructions/mythical.png";
import eyes from "src/assets/images/hero-details/attributes/Human/Eyes.png";
import hair from "src/assets/images/hero-details/attributes/Human/Hair.png";
import mouth from "src/assets/images/hero-details/attributes/Human/Mouth.png";
import scar from "src/assets/images/hero-details/attributes/Human/Scar.png";
import skin from "src/assets/images/hero-details/attributes/Human/Skintone.png";
import tattoo from "src/assets/images/hero-details/attributes/Human/Tattoo.png";
import { Tooltip } from "@material-ui/core";

const BodyParts = () => {
  const rankings = [
    common,
    uncommon,
    rare,
    epic,
    legendary,
    mythical,
  ];

  const parts = [
    { name: "Mouth", image: mouth },
    { name: "Scar", image: scar },
    { name: "Skintone", image: skin },
    { name: "Tattoo", image: tattoo },
    { name: "Eyes", image: eyes },
    { name: "Hair", image: hair },
  ];

  return (
    <div className="heroes-parts slide-up">
      <div className="heroes-parts__title pb-4">
        <h1>Body Part Ranking</h1>
      </div>
      <div className="heroes-parts__desc">A hero has a minimum of four and a maximum of six body parts.</div>
      <div className="heroes-parts__desc mt-1 mb-5">
        Body parts ranking indicates the rarity of Faraland heroes.
      </div>
      <div>
        <div className="heroes__divider" />
        <div className="heroes-parts__list">
          {parts.map((p, idx) => (
            <Tooltip key={idx} title={p.name} arrow placement="top">
              <img src={p.image} style={{ maxWidth: 41 }} alt="" />
            </Tooltip>
          ))}
        </div>
        <div className="heroes__divider" />
      </div>
      <div className="heroes-parts__ranking">
        {rankings.map((value, id) => (
          <img key={id} src={value} alt="" />
        ))}
      </div>
    </div>
  );
};

export default BodyParts;
