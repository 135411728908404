import { REHYDRATE } from "redux-persist/lib/constants";
import { accountActionTypes } from "src/app/actions/accountAction";
import { getWalletByType } from "src/app/utils/helpers";
import { AccountReducer } from "src/app/types/reducer-state";

const initialState: AccountReducer = {
  address: "",
  spaceDomain: "",
  type: "",
  wallet: null,
  balance: {
    FARA: "0",
    BNB: "0",
    SOUL_STONE: "0",
    EMPEROR_STONE: "0",
    EXP_JAR: "0",
  },
};

export default function accountReducer(state = initialState, action: any): AccountReducer {
  switch (action.type) {
    case REHYDRATE: {
      const lastData = action.payload;

      if (action.key === "account" && lastData && lastData.type) {
        const wallet = getWalletByType(lastData.address, lastData.type);
        if (wallet) return { ...state, wallet };
        return initialState;
      }

      return { ...state };
    }
    case accountActionTypes.IMPORT_ACCOUNT: {
      const { address, wallet, type, spaceDomain } = action.payload;

      return {
        ...state,
        address: address.toLowerCase(),
        wallet,
        type,
        spaceDomain,
      };
    }
    case accountActionTypes.SET_BALANCE: {
      return {
        ...state,
        balance: action.payload,
      };
    }
    case accountActionTypes.CLEAR_ACCOUNT: {
      return initialState;
    }
    default:
      return state;
  }
}
