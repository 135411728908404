import { Knight } from "src/app/types/knight";

export const knightActionTypes = {
  TRANSFER: "KNIGHT.TRANSFER",
  SACRIFICE: "KNIGHT.SACRIFICE",
  CHANGE_NAME: "KNIGHT.CHANGE_NAME",
  EQUIP_ITEMS: "KNIGHT.EQUIP_ITEMS",
  UNEQUIP_ITEMS: "KNIGHT.UNEQUIP_ITEMS",
  SET_EQUIPPABLE: "KNIGHT.SET_EQUIPPABLE",
  INCREASE_STATS: "KNIGHT.INCREASE_STATS",
  CONVERT_EXP: "KNIGHT.CONVERT_EXP",
  LEARN_SKILL_BOOK: "KNIGHT.LEARN_SKILL_BOOK",
  RESET_STATS: "KNIGHT.RESET_STATS",
  UPGRADE_BODY_PART: "KNIGHT.UPGRADE_BODY_PART",
  SELECT: "KNIGHT.SELECT",
  DESELECT: "KNIGHT.DESELECT",
  RESET_SELECTION: "KNIGHT.RESET_SELECTION",
  BURN: "KNIGHT.BURN",
  REFRESH: "KNIGHT.REFRESH"
};

export function transfer(knightId: number, from: string, to: string) {
  return {
    type: knightActionTypes.TRANSFER,
    payload: { knightId, from, to },
  };
}

export function changeName(knightId: number, name: string) {
  return {
    type: knightActionTypes.CHANGE_NAME,
    payload: { knightId, name },
  };
}

export function sacrifice(knightId: number) {
  return {
    type: knightActionTypes.SACRIFICE,
    payload: { knightId },
  };
}

export function equipItems(knightId: number, itemIds: any[], onSuccess: any) {
  return {
    type: knightActionTypes.EQUIP_ITEMS,
    payload: { knightId, itemIds },
    callbacks: { onSuccess },
  };
}

export function unequipItems(knightId: number, itemIds: any[], onSuccess: any) {
  return {
    type: knightActionTypes.UNEQUIP_ITEMS,
    payload: { knightId, itemIds },
    callbacks: { onSuccess },
  };
}

export function setEquippable(equippable: boolean) {
  return {
    type: knightActionTypes.SET_EQUIPPABLE,
    payload: equippable,
  };
}

export function increaseStats(knightId: number, knightType: number, stats: any) {
  return {
    type: knightActionTypes.INCREASE_STATS,
    payload: { knightId, knightType, stats },
  };
}

export function convertKnightExp(knightId: number, knightType: number, levelUpAmount: number, onSuccess: any) {
  return {
    type: knightActionTypes.CONVERT_EXP,
    payload: { knightId, knightType, levelUpAmount },
    callbacks: { onSuccess },
  };
}

export function learnSkillBook(knightId: number, knightType: number, skillId: number, onSuccess: any) {
  return {
    type: knightActionTypes.LEARN_SKILL_BOOK,
    payload: { knightId, knightType, skillId },
    callbacks: { onSuccess },
  };
}

export function resetStats(knightId: number, knightType: number) {
  return {
    type: knightActionTypes.RESET_STATS,
    payload: { knightId, knightType },
  };
}

export function upgradeBodyPart(knightId: number, bodyPart: string, onSuccess: any) {
  return {
    type: knightActionTypes.UPGRADE_BODY_PART,
    payload: { knightId, bodyPart, keepModal: true },
    callbacks: { onSuccess },
  };
}

export function selectKnight(knight: Knight) {
  return {
    type: knightActionTypes.SELECT,
    payload: { knight },
  };
}

export function deselectKnight(knight: Knight) {
  return {
    type: knightActionTypes.DESELECT,
    payload: { knight },
  };
}

export function resetSelectedKnight() {
  return {
    type: knightActionTypes.RESET_SELECTION,
  };
}

export function knightRefresh(refresh: boolean) {
  return {
    type: knightActionTypes.REFRESH,
    payload: { refresh },
  };
}

export function burnKnight(ids: string[], onSuccess?: any) {
  return {
    type: knightActionTypes.BURN,
    payload: { ids },
    callbacks: { onSuccess },
  };
}