import * as React from "react";
import crystal from "src/assets/images/decors/gems/gem-yellow.svg";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
import introBanner from "src/assets/images/instructions/intro-banner.png";
import androidBtn from "src/assets/images/instructions/android-btn.png";
import appleBtn from "src/assets/images/instructions/apple-btn.png";

const Intro = () => {
  return (
    <div className="instructions-intro">
      <FloatingCrystal speed={5} src={crystal} style={{ right: 0, top: 500, zIndex: -1 }} />
      <img className="instructions-intro__image" src={introBanner} alt="" />
      <div className="instructions-intro__content">
        <h2>THE BIGGEST WAR GAME ON BLOCKCHAIN</h2>
        <p>
          Faraland has exquisitely digital collectibles created using blockchain technology. Each collectible is
          matchless, genuine and varies in rarity. The Faraland Universe has a lot of different races like human, orc,
          angel, demon, dragonborn, elf and fairy which are waiting for the user to discover and collect.
        </p>
        <p>
          Last but not least, Faraland is also a multiplayer RPG NFT GAME that lets the user engage in the combat arena
          and profit from battles.
        </p>
        <div className="instructions-intro__download">
          <a
            href="https://blog.faraland.io/2021/11/30/faraland-expedition-game-register-instruction-android-version/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={androidBtn} alt="" />
          </a>
          <a
            href=" https://blog.faraland.io/2021/11/30/faraland-expedition-game-register-instruction-ios-version/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={appleBtn} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Intro;
