import React from "react";

import { INVENTORY_TYPE } from "src/app/configs/constants";
import Marketplace from "src/app/components/Commons/Marketplace";
import WalletInventory from "src/app/components/Wallet/WalletInventory";

export default function Wallet() {
  return (
    <div className="wallet slide-up">
      <div className="flex container wallet__inventory-container">
        <WalletInventory type={INVENTORY_TYPE.TRADITIONAL} />

        <WalletInventory type={INVENTORY_TYPE.NON_FEE} />
      </div>

      <div>
        <Marketplace />
      </div>
    </div>
  );
}
