import React, { useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core";
import { formatAddress, timeAgo } from "src/app/utils/helpers";
import { ExchangeItem, Item, UserItem } from "src/app/types/equipment";
import { ACTIONS } from "src/app/configs/constants";
import ItemExchange from "../Commons/ItemExchange";
import { modalService } from "../../Commons/Modals/ModalListener";
import EquipmentActionModal from "./EquipmentActionModal";
import { queryUserEquipment } from "src/app/services/api/subgraphService";
import { useSelector } from "react-redux";
import { useEffect } from "react";

type EquipmentExchangeTableProps = {
  exchanges: ExchangeItem[];
  openExchangeItemModal: any;
  itemBalance: number;
  isExchanging: boolean;
  ownExchanges: ExchangeItem[];
  item: Item;
};

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#111121",
      color: theme.palette.common.white,
      fontFamily: `"American Captain", sans-serif`,
      textTransform: "uppercase",
      fontWeight: 500,
      fontSize: 18,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export default function EquipmentExchangeTable(props: EquipmentExchangeTableProps) {
  const { address } = useSelector((state: any) => state.account);
  const { exchanges, ownExchanges, item } = props;
  const [myExchanges, setMyExchanges] = useState(false);
  const [userItems, setUserItems] = useState<UserItem[]>([]);
  const isEmpty = myExchanges ? ownExchanges.length === 0 : exchanges.length === 0;
  
  useEffect(() => {
    if (!address) {
      setUserItems([]);
      return;
    }
    _fetchUserOwnEquipment(address);
  }, [address]); // eslint-disable-line

  async function _fetchUserOwnEquipment(address: string) {
    const result = await queryUserEquipment(address);
    if (result) setUserItems(result.equipment);
  }

  function toggleMyExchanges() {
    setMyExchanges(!myExchanges);
  }

  function onOpenPopup(type: number, selectedExchange?: ExchangeItem) {
    modalService.show(EquipmentActionModal, {
      type: type,
      item: item,
      isEquipmentMarket: true,
      selectedExchange: selectedExchange,
      items: userItems
    });
  }

  function renderButtons(row: any) {
    if (address === row.seller) {
      return (
        <StyledTableCell>
          <div className="btn btn--small" onClick={() => onOpenPopup(ACTIONS.CANCEL_EXCHANGE_EQUIPMENT)}>
            Cancel
          </div>
        </StyledTableCell>
      )
    } else if (address) {
      return (
        <StyledTableCell>
          <div className="btn btn--small" onClick={() => onOpenPopup(ACTIONS.TAKE_EXCHANGE_OFFER, row)}>
            Accept
          </div>
        </StyledTableCell>
      )
    }

    return (
      <StyledTableCell />
    )
  }

  return (
    <div className={`equipment__table cm-table ${isEmpty ? "cm-table--empty" : ""} mt-10`}>
      <div className="cm-table__header">
        <div className="cm-table__title">Exchanges</div>
        {address && (
          <div className="flex-center-start bot-6">
            <div className="toggle mr-3">
              <div>My Exchange</div>
              <input id="my-exchanges" type="checkbox" checked={myExchanges} onChange={toggleMyExchanges} />
              <label htmlFor="my-exchanges" />
            </div>
            {(props.itemBalance !== 0 || props.isExchanging) && (
              <div className="btn" onClick={props.openExchangeItemModal}>
                Exchange
              </div>
            )}
          </div>
        )}
      </div>
      <Grid container>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>From</StyledTableCell>
                <StyledTableCell>Amount</StyledTableCell>
                <StyledTableCell>Rule</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {!isEmpty ? (
                (myExchanges ? ownExchanges : exchanges).map((row, eid) => (
                  <StyledTableRow key={eid}>
                    <StyledTableCell>{row.seller && formatAddress(row.seller, 4)}</StyledTableCell>
                    <StyledTableCell>{row.amount}</StyledTableCell>
                    <StyledTableCell>
                      <ItemExchange srcItem={item} ruleDestItems={row.ruleDestItems} />
                    </StyledTableCell>
                    <StyledTableCell>{timeAgo(+row.timestamp * 1000)}</StyledTableCell>
                    {renderButtons(row)}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell className="cm-table__no-record">
                    <img src={require("src/assets/images/icons/empty-bids.svg")} width={60} height={60} alt="" />
                    <div>No Exchange Yet</div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}
