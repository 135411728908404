import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { isDemiInactive } from "src/app/utils/helpers";
import { minTwoDigits } from "src/app/utils/mappingHelpers";

type BlackMarketTimerProps = {
  refillTime: number;
};

const BlackMarketTimer = (props: BlackMarketTimerProps) => {
  const { refillTime } = props;

  const refillTimeInterval = useRef<any>();
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    refillTimeInterval.current = setInterval(() => {
      const { then, now } = _setIsExpired();
      // if (isExpired) {
      //   clearInterval(refillTimeInterval.current);
      //   return;
      // }

      let duration = moment.duration(then.diff(now));

      //Get Days and subtract from duration
      let days = Math.floor(duration.asDays());
      duration.subtract(moment.duration(days, "days"));
      //Get hours and subtract from duration
      let hours = duration.hours();
      setHours(hours + 24 * days);
      duration.subtract(moment.duration(hours, "hours"));

      //Get Minutes and subtract from duration
      let minutes = duration.minutes();
      setMinutes(minutes);
      duration.subtract(moment.duration(minutes, "minutes"));

      //Get seconds
      let seconds = duration.seconds();
      setSeconds(seconds);
    }, 1000);

    return () => {
      clearInterval(refillTimeInterval.current);
    };
  }, []); // eslint-disable-line

  function _setIsExpired() {
    const { then, now } = isDemiInactive(+refillTime);

    return { then, now };
  }

  return (
    <div>
      <p className="black-market__timer-countdown">
        {minTwoDigits(hours)}:{minTwoDigits(minutes)}:{minTwoDigits(seconds)}
      </p>
      <p className="black-market__timer-subtitle">until the next reset</p>
    </div>
  );
};

export default BlackMarketTimer;
