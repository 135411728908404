import { SlotEquipment } from "src/app/types/equipment";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { KnightStats } from "src/app/types/knight";
import Web3Service from "src/app/services/web3/Web3Service";

export function convertItemIdToSlotEquipment(itemIds: number[]): SlotEquipment[] {
  let equipments: SlotEquipment[] = [];

  for (let i = 0; i < itemIds.length; i++) {
    if (!+itemIds[i]) continue;

    const item = EQUIPMENT[+itemIds[i]];
    equipments.push({
      id: item.id,
      name: item.name,
      slot: item.type
    });
  }

  return equipments;
}

export async function updateKnightStats(
  id: string,
  level: number,
  data: KnightStats,
  setKnightStats: any,
  web3Service: Web3Service,
  isDemi = false
) {
  try {
    let allocatedStats;

    if (isDemi) {
      allocatedStats = await web3Service.fetchDemiKnightAllocatedStats(id);
    } else {
      allocatedStats = await web3Service.fetchKnightAllocatedStats(id);
    }

    const totalAllocatedStats = allocatedStats.str + allocatedStats.agi + allocatedStats.int + allocatedStats.luk;

    data.availablePoints = level - 1 - totalAllocatedStats;
    data.allocatedStats = {
      str: allocatedStats.str,
      agi: allocatedStats.agi,
      int: allocatedStats.int,
      luk: allocatedStats.luk,
    }

    setKnightStats(data);
  } catch (e) {
    console.log(e);
  }
}