import React from "react";
import { useDispatch, useSelector } from "react-redux";
import broadcastedIcon from "src/assets/images/icons/status-broadcasted.svg";
import doneIcon from "src/assets/images/icons/status-done.svg";
import failedIcon from "src/assets/images/icons/status-failed.svg";
import loadingIcon from "src/assets/images/icons/loading-circle.svg";
import externalLinkIcon from "src/assets/images/icons/external-link.svg";
import { ACTIONS, TX_STATUS } from "src/app/configs/constants";
import { calculateRewards, formatAddress, formatBigNumber } from "src/app/utils/helpers";
import ENV from "src/app/configs/env";
import { TxHistoryRecord } from "src/app/types/tx";
import { setGlobalModal } from "src/app/actions/globalAction";
import Modal from "src/app/components/Commons/Modals/Modal";
import { MATERIALS } from "src/app/configs/materials/materials";

export default function TxTrackingModal() {
  const dispatch = useDispatch();
  const { modal } = useSelector((state: any) => state.global);
  const { trackingTxs } = useSelector((state: any) => state.tx);

  const lastTx: TxHistoryRecord = trackingTxs[trackingTxs.length - 1] ?? {};
  const data = renderDataByStatus();
  const txTrackingModal = modal.txTracking;

  function handleClose() {
    dispatch(setGlobalModal("txTracking"));
  }

  function renderDataByStatus() {
    let data;

    if (lastTx.status === TX_STATUS.SUCCESS) {
      data = {
        icon: doneIcon,
        content: renderSuccessDataByTxType(lastTx.action, lastTx.data),
      };
    } else if (lastTx.status === TX_STATUS.FAILED) {
      data = {
        icon: failedIcon,
        content: <div className="flex-center-center mb-3">Transaction error</div>,
      };
    } else {
      data = {
        icon: broadcastedIcon,
        content: (
          <div className="flex-start-center mb-7">
            <img className="loading-icon" src={loadingIcon} alt="loading" />
            <div>Waiting for the transaction to be mined</div>
          </div>
        ),
      };
    }

    return data;
  }

  function renderSuccessDataByTxType(txType: number, txData: any) {
    let content;

    if (txType === ACTIONS.APPROVE) {
      content = (
        <div className="tx-tracking__content">
          <div>Successfully Approved</div>
          <div>Approved token to contract {formatAddress(txData.spender)}</div>
        </div>
      );
    } else if (txType === ACTIONS.STAKE) {
      content = (
        <div className="tx-tracking__content">
          <div>Successfully Staked</div>
          <div>
            Staked {formatBigNumber(txData.amount, 18, 4)} token into #{txData.knightId} with {txData.lockedMonths}{" "}
            months locked
          </div>
          {/*TODO: Re-enable for Staking V3 */}
          {txData.earnedReward && (
            <div>
              Earned {txData.earnedReward.amount} {MATERIALS[txData.earnedReward.id].displayName}
            </div>
          )}
        </div>
      );
    } else if (txType === ACTIONS.UNSTAKE) {
      content = (
        <div className="tx-tracking__content">
          <div>Successfully Unstaked</div>
          <div>
            Unstaked {formatBigNumber(txData.amount, 18, 4)} token from #{txData.knightId}
          </div>
        </div>
      );
    } else if (txType === ACTIONS.CLAIM) {
      content = (
        <div className="tx-tracking__content">
          <div>Successfully Claimed</div>
          <div>Claimed FARA and EXP as reward from #{txData.knightId}</div>
        </div>
      );
    } else if (txType === ACTIONS.CONVERT_EXP) {
      content = (
        <div className="tx-tracking__content">
          <div>Successfully Leveled Up</div>
          <div>
            #{txData.knightId} leveled up by {txData.levelUpAmount} levels
          </div>
        </div>
      );
    } else if (txType === ACTIONS.REGISTER) {
      content = (
        <div className="tx-tracking__content">
          <div>Your Demi has been successfully activated</div>
          <div>Please wait for a while to have it reflected in the UI</div>
        </div>
      );
    } else if (txType === ACTIONS.EQUIPMENT_DISMANTLE) {
      const rewards = calculateRewards(txData.dismantleMaterials);
      content = (
        <div className="tx-tracking__content">
          <div>Successfully Dismantled</div>
          <div>
            Received{" "}
            {rewards.materials.map((material, idx) => (
              <span>
                <span className="success-text fs-3">{rewards.quantity}x</span> {MATERIALS[material].displayName}
                {idx < rewards.materials.length - 1 && ", "}
              </span>
            ))}
          </div>
        </div>
      );
    } else if (txType === ACTIONS.CLAIM_EXP_JAR) {
      content = (
        <div className="tx-tracking__content">
          <div>Successfully Claimed {txData.expJarAmount} EXP Jars</div>
        </div>
      );
    }

    return content;
  }

  return (
    <Modal layer={3} isActive={txTrackingModal.active} onClose={handleClose}>
      <div className="basic-modal md-small tx-tracking">
        <div className="flex-center-center mb-5">
          <img className="mr-1" src={data.icon} alt={lastTx.status} />
          <div className="uppercase">{lastTx.status}!</div>
        </div>

        <div className="basic-modal__content">
          <div>{data.content}</div>
          <div className="tx-tracking__hash">
            <div>TxHash:</div>
            <div className="flex-center-start">
              <div className="mr-2">{formatAddress(lastTx.hash, 20, -5)}</div>
              <a href={`${ENV.URL.EXPLORER}/tx/${lastTx.hash}`} target="_blank" rel="noopener noreferrer">
                <img className="top-1" src={externalLinkIcon} alt="Show tx" />
              </a>
            </div>
          </div>
        </div>

        <div className={`basic-modal__panel`}>
          <div className="btn" onClick={handleClose}>
            {lastTx.status === TX_STATUS.PENDING ? "Cancel" : "Done"}
          </div>
        </div>
      </div>
    </Modal>
  );
}
