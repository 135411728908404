import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ENV from "src/app/configs/env";
import Modal from "src/app/components/Commons/Modals/Modal";
import { setGlobalModal } from "src/app/actions/globalAction";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { EquipmentDismantle, Item, UserItem } from "src/app/types/equipment";
import useApprovalForAll from "src/app/hooks/useApprovalForAll";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import { DISMANTLE_REWARDS } from "src/app/configs/equipment/dismantle";
import arrowDown from "src/assets/images/decors/arrow-down.png";
import useDismantleData from "src/app/hooks/useDismantleData";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { dismantle } from "src/app/actions/equipmentAction";

export default function EquipmentDismantleModal() {
  const dispatch = useDispatch();
  const { modal } = useSelector((state: any) => state.global);
  const [needApprovalForAll, sendApproveTxForAll] = useApprovalForAll(
    ENV.CONTRACT.EQUIPMENT,
    ENV.CONTRACT.EQUIPMENT_DISMANTLE
  );
  const [availableItem, setAvailableItem] = useState(1);
  const [error, setError] = useState("");
  const [itemSpecs, setItemSpecs] = useState<EquipmentDismantle[]>([]);
  const { renderDismantleMaterials } = useDismantleData(itemSpecs);
  const currentModal = modal.equipmentDismantleModal;
  const item: Item = currentModal.data.item;
  const userItem: UserItem = currentModal.data.userItem;

  useEffect(() => {
    setError("");
  }, [currentModal.active]);

  useEffect(() => {
    if (!item) return;
    const selectedDismantleMaterials = Array.from({ length: availableItem }, (_) => EQUIPMENT[item.id]).map(
      (item) => DISMANTLE_REWARDS[`${item.rarity}-${item.tier}`]
    );
    setItemSpecs(selectedDismantleMaterials);
  }, [availableItem, item]);

  function handleClose() {
    dispatch(setGlobalModal("equipmentDismantleModal"));
  }

  function handleAvailableSelection(availableItem: number, isRemove: boolean) {
    if (isRemove) {
      if (availableItem === 1) return;
      setAvailableItem((prev) => prev - 1);
    } else {
      if (availableItem === userItem.available) return;
      setAvailableItem((prev) => prev + 1);
    }
  }

  function handleConfirm() {
    if (needApprovalForAll) {
      return sendApproveTxForAll();
    }
    if (availableItem === 0) {
      setError("You need to have at least 1 item available to dismantle");
    }
    dispatch(dismantle([item.id], [availableItem], itemSpecs, handleClose));
  }

  return (
    <Modal className="medium" isActive={currentModal.active} onClose={handleClose} layer={2}>
      {item && (
        <BasicModalContent
          className="equipment__dismantle-modal"
          customTitle={`Dismantle - ${item.displayName}`}
          close={handleClose}
          onSubmit={handleConfirm}
          submitText={needApprovalForAll ? "Approve NFT" : "Confirm"}
          content={
            <div className="equipment__dismantle">
              <div className="flex-column-center">
                <ItemIconPreview item={item} isMarketplace />
                <div className="flex-center-start equipment__dismantle-amount">
                  <div
                    className={`btn knight-preview__wrapper-btn ${availableItem === 1 && "disabled"} no-margin`}
                    onClick={() => handleAvailableSelection(availableItem, true)}
                  >
                    <RemoveIcon />
                  </div>
                  <div style={{ width: 50, textAlign: "center" }}>{availableItem}</div>
                  <div
                    className={`btn knight-preview__wrapper-btn no-margin ${
                      availableItem === userItem.available && "disabled"
                    }`}
                    onClick={() => handleAvailableSelection(availableItem, false)}
                  >
                    <AddIcon />
                  </div>
                </div>
                {availableItem > 0 && <img src={arrowDown} alt="Arrow" className="mt-2" />}
                <div className="flex-center-between">{renderDismantleMaterials()}</div>
              </div>
              {error !== "" && <div className="small-text small-text--error slide-up mt-4">{error}</div>}
            </div>
          }
        />
      )}
    </Modal>
  );
}
