import React, { useEffect, useRef, useState } from "react";
import { Lottie } from "@crello/react-lottie";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";
import { getMaterialFilterFromParams } from "src/app/utils/filterHelper";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import * as notFoundJson from "src/assets/lotties/404.json";
import { ItemQuery } from "src/app/types/equipment";
import { useSelector } from "react-redux";
import { useQueryString } from "use-route-as-state";
import { Pagination } from "@material-ui/lab";
import { LIMIT, SORT_DATA } from "src/app/configs/constants";
import useUpdateEffect from "src/app/hooks/useUpdateEffect";
import { Material, MaterialFilterType } from "src/app/types/materials";
import MaterialSwitcher from "src/app/components/Materials/Marketplace/MaterialSwitcher";
import MaterialListContent from "src/app/components/Materials/Marketplace/MaterialListContent";
import MaterialListFilter from "src/app/components/Materials/Marketplace/MaterialListFilter";
import { queryMaterialListings, queryMaterialOffers, queryUserMaterials } from "src/app/services/api/subgraphService";
import { fetchMaterialsFromApi } from "src/app/services/api/faralandService";
import { MATERIALS } from "src/app/configs/materials/materials";

export default function MaterialMarketplace() {
  const { address } = useSelector((state: any) => state.account);
  const materialListRef = useRef(null);
  const [queryParams, setQueryParams] = useQueryString();
  const [total, setTotal] = useState(0);
  const [materialFilters, setMaterialFilters] = useState<MaterialFilterType>(getMaterialFilterFromParams(queryParams));
  const [materials, setMaterials] = useState<Material[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const disablePagination = materialFilters.owned || materialFilters.offering || materialFilters.listings;
  const [showConnectWalletError, setShowConnectWalletError] = useState(disablePagination && !address);

  useUpdateEffect(() => {
    setMaterialFilters(getMaterialFilterFromParams(queryParams));
  }, [queryParams]);

  useEffect(() => {
    setShowConnectWalletError(disablePagination && !address);
  }, [disablePagination, address]);

  useEffect(() => {
    setPage(materialFilters.page);
    fetchItems(materialFilters);
  }, [address, materialFilters]); // eslint-disable-line

  async function fetchItems(filter: MaterialFilterType) {
    let filteredItems: Material[] = [];
    const sortData = SORT_DATA[filter.sortBy];
    const variables: ItemQuery = {
      limit: LIMIT.MATERIALS,
      orderBy: sortData.key,
      orderDirection: sortData.direction,
      itemPerPage: LIMIT.MATERIALS,
      page: filter.page,
    };

    setLoading(true);

    try {
      let result;
      if (filter.owned) {
        result = await queryUserMaterials(address);
      } else if (filter.offering) {
        result = await queryMaterialOffers(address);
      } else if (filter.listings) {
        result = await queryMaterialListings(address);
      } else {
        result = await fetchMaterialsFromApi(filter, variables);
      }
      filteredItems = result?.list ?? [];
      setTotal(result?.total ?? 0);
    } catch (e) {
      console.log(e);
    }

    setMaterials(filteredItems.map((item) => ({ ...item, rarity: MATERIALS[item.id]?.rarity })));

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  useEffect(() => {
    if (total && total < LIMIT.MATERIALS) {
      handleChangePage(1);
    }

    const newTotalPage = Math.ceil(total / LIMIT.MATERIALS);
    setTotalPage(newTotalPage);
    if (newTotalPage && page > newTotalPage) {
      handleChangePage(1);
    }
  }, [total]); // eslint-disable-line

  function onClickPaging(page: number) {
    if (materialListRef && materialListRef.current) {
      // @ts-ignore
      materialListRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    handleChangePage(page);
  }

  function handleChangePage(page: number) {
    setQueryParams({
      ...queryParams,
      page: page.toString(),
    });
  }

  return (
    <div className="slide-up" ref={materialListRef}>
      <div className="market">
        <MaterialListFilter />

        <div className="market__content">
          <MaterialSwitcher isLoading={isLoading} />

          {isLoading && (
            <Lottie
              className="market__loading market__loading--marketplace slide-up"
              config={getAnimatedJsonOptions(loadingJson)}
            />
          )}

          {!isLoading && materials.length === 0 && !showConnectWalletError && (
            <div className="align-center slide-up">
              <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
              <div className="market__404-title">No Material found with your searching queries.</div>
            </div>
          )}

          {!isLoading && showConnectWalletError && (
            <div className="align-center slide-up">
              <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
              <div className="market__404-title">Please connect your wallet first</div>
            </div>
          )}

          {!isLoading && materials.length !== 0 && !showConnectWalletError && (
            <div className="market__items">
              {materials.map((material: Material, index: number) => {
                return (
                  <MaterialListContent
                    key={index}
                    material={material}
                    showOwned={materialFilters.owned}
                    showOffers={materialFilters.offering}
                    showListings={materialFilters.listings}
                  />
                );
              })}
            </div>
          )}

          {totalPage > 1 && !disablePagination && (
            <div className="market__pagination">
              <Pagination
                count={totalPage}
                page={page}
                onChange={(_, val) => onClickPaging(val)}
                variant="outlined"
                color="primary"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
