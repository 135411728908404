import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS } from "src/app/configs/constants";
import { _sendTx } from "src/app/sagas/index";
import { tokenActionTypes } from "src/app/actions/tokenAction";

function* deposit(action: any): any {
  yield call(_sendTx, ACTIONS.TOKEN_DEPOSIT, action.payload, action.callbacks.onSuccess);
}

function* withdraw(action: any): any {
  yield call(_sendTx, ACTIONS.TOKEN_WITHDRAW, action.payload, action.callbacks.onSuccess);
}

export default function* tokenSaga() {
  yield takeLatest(tokenActionTypes.DEPOSIT, deposit);
  yield takeLatest(tokenActionTypes.WITHDRAW, withdraw);
}
