import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { depositTokens, withdrawTokens } from "src/app/actions/tokenAction";
import InputGroup from "src/app/components/Commons/InputGroup";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { INVENTORY_TYPE, NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";
import ENV from "src/app/configs/env";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import { fetchWithdrawEquipmentSignature } from "src/app/services/api/faralandService";
import { UserToken } from "src/app/types/token";
import { compareNumbers } from "src/app/utils/calculators";
import { formatNumber, toBigAmount } from "src/app/utils/helpers";

type TokenTransferModalProps = {
  userTokens: UserToken[];
  type: number;
};

export default function TokenTransferModal(props: TokenTransferModalProps) {
  const { type, userTokens } = props;
  const { address } = useSelector((state: any) => state.account);
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.EQUIPMENT_DEPOSIT);
  const dispatch = useDispatch();
  const [transferNo, setTransferNo] = useState("");
  const [invalidNumber, setInvalidNumber] = useState("");

  function handleTransferChange(e: any, amount?: string) {
    setInvalidNumber("");
    if(amount) {
      setTransferNo(amount);
      return;
    }
    setTransferNo(e.target.value);
  }

  function renderTransferContent() {
    return (
      <>
        <InputGroup
          className="text-field text-field--transfer mt-6"
          value={transferNo}
          handleChange={handleTransferChange}
          symbol=""
          balance={userTokens[0].amount}
          error={invalidNumber}
          label={"Amount"}
          balanceText="Token balance"
        />
      </>
    );
  }

  async function sendTokenTransferTx() {

    if (compareNumbers(transferNo, userTokens[0].amount) === 1) {
      setInvalidNumber(
        `Can only ${type === INVENTORY_TYPE.TRADITIONAL ? "deposit" : "withdraw"} ${
          formatNumber(userTokens[0].amount, 4)
        } tokens at most.`
      );
      return;
    }
    if (transferNo === "0" || transferNo === "") {
      setInvalidNumber(`Invalid token amount`);
      return;
    }
    if (type === INVENTORY_TYPE.TRADITIONAL) {
      dispatch(depositTokens(toBigAmount(transferNo), () => modalService.close()));
    } else if (type === INVENTORY_TYPE.NON_FEE) {
      const res = await fetchWithdrawEquipmentSignature(address, toBigAmount(transferNo), [], NON_FEE_INVENTORY_TYPE.TOKEN);
      if(!res) return;
      dispatch(withdrawTokens(res, () => modalService.close()));
    }
  }

  function renderPopupContent() {
    let content, onSubmit, submitText;

    content = renderTransferContent();
    onSubmit = needApprove ? sendApproveTx : sendTokenTransferTx;
    submitText = needApprove ? "Approve" : type === INVENTORY_TYPE.TRADITIONAL ? "Deposit" : "Withdraw";

    return { content, onSubmit, submitText };
  }

  const { content, onSubmit, submitText } = renderPopupContent();

  return (
    <BasicModalContent
      customTitle={`${type === INVENTORY_TYPE.TRADITIONAL ? "Deposit" : "Withdraw"} Your Tokens`}
      onSubmit={onSubmit}
      submitText={submitText}
      content={<div className="text-center mt-7">{content}</div>}
    />
  );
}
