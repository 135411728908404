import _ from "lodash";
import { useEffect, useState } from "react";
import { getDomainNamesForTransactions } from "src/app/utils/mappingHelpers";

export default function useFetchSpaceDomain() {
  const [addresses, setAddresses] = useState();
  const [domains, setDomains] = useState({});

  useEffect(() => {
    async function fetchDomainNames(addresses) {
      const domainNames = await getDomainNamesForTransactions(addresses);
      setDomains(domainNames);
    }
    fetchDomainNames(addresses);
  }, [addresses]);

  const getAddresses = (initialArray, remainingArrays, isGuild?: boolean) => {
    const walletAddresses = initialArray
      .concat(remainingArrays.flat())
      .map((field) => ({ from: field?.from, to: field?.to, seller: field?.seller, buyer: field?.buyer, id: isGuild && field?.id }));
    if (!_.isEqual(addresses, walletAddresses)) {
      setAddresses(walletAddresses);
    }
  };

  return { domains, getAddresses };
}
