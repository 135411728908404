import React from "react";
import { useSelector } from "react-redux";
import { timeAgo } from "src/app/utils/helpers";
import { SYNCED_MINUTE_THRESHOLD } from "src/app/configs/constants";
import moment from "moment";

export default function SyncedBlock() {
  const { syncedBlock } = useSelector((state: any) => state.global);
  const minuteAgo = moment().diff(moment(syncedBlock.timestamp * 1000), 'minutes');
  const differentTime = timeAgo(syncedBlock.timestamp * 1000);
  const isUpToDate = minuteAgo < SYNCED_MINUTE_THRESHOLD;

  return (
    <div className={`synced-block ${isUpToDate ? 'synced-block--green' : 'synced-block--orange'}`}>
      {syncedBlock.timestamp !== 0 && (
        <>
          <div className="synced-block__text">{isUpToDate ? 'Fully synced' : `Synced ${differentTime}`}</div>
          <div className="synced-block__circle" />
        </>
      )}
    </div>
  );
}
