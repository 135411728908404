import React from "react";
import demiHero from "src/assets/images/instructions/demi-hero.png";
import soulstone from "src/assets/images/instructions/soulstone.png";
const DemiHero = () => {
  return (
    <div className="instructions-demi">
      <div className="container">
        <div className="flex-start-center">
        <img className="instructions-demi__image" src={demiHero} alt="" />
          <div className="instructions-demi__content">
            <img src={soulstone} alt="" />
            <h2>Demi Hero</h2>
            <p>
              A long time ago, there was a myth about one portal connecting parallel worlds in the land of Miesen.
              Initially, people from other realms came to trade and cooperate with the citizens of Faraland. Over time,
              Miesen became one of the most prosperous municipalities in Faraland. But not all realms that came to
              Faraland had the same purpose. Soon after, the war between the realms turned Miesen, which was once a
              magical land, into an eternal frozen land. The gate to other realms was destroyed, leaving the vestiges of
              the war in the city of Miesen. Although the war has ended, there are still people trapped in Faraland, who
              are unable to return to their own world. The Miesen calls them "Demi".
            </p>
            <p className="mt-5">
              Demi Heroes have all the features of NFT heroes, players can use Soul Stone to randomly summon and
              maintain the demi's life. Note that the amount of Soul Stone to maintain will increase after time. They
              can be equipped with items, leveled up, and engaged in all sorts of activities exactly like a real NFT
            </p>
            <p>
              Moreover, although the number of demi heroes is unlimited, they will also be divided into races like
              heroes
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemiHero;
