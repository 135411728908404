// import { Tooltip } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import LandOfferTable from "src/app/components/Land/Details/LandOfferTable";
import { Land, LandAttributes, LandOffer } from "src/app/types/land";
import { multiplyNumbers } from "src/app/utils/calculators";
import { formatNumber } from "src/app/utils/helpers";
import faraLogo from "src/assets/images/tokens/fara.png";
import activeGem from "src/assets/images/decors/gems/tier-gem-active.svg";
import { Tooltip } from "@material-ui/core";
import { mapLandTraitType } from "src/app/utils/mappingHelpers";

type LandDataProps = {
  land: Land;
  isOwner: boolean;
  offers: LandOffer[];
  isHaveOffers: boolean;
  onOpenPopup: any;
  handleTakeOffer: any;
};
export default function LandData(props: LandDataProps) {
  const { tokenPrice } = useSelector((state: any) => state.global);
  const { land, isOwner, offers, isHaveOffers, onOpenPopup, handleTakeOffer } = props;
  return (
    <div>
      <div className="detail-price">
        <div className="detail-price__container">
          <div>
            <div className="detail-price__price">{land.price || "-/-"} FARA</div>
            {!!land.price && (
              <div className="detail-price__dollars">
                ≈ ${formatNumber(multiplyNumbers(land.price, tokenPrice.FARA), 2)}
              </div>
            )}
          </div>
          <img className="detail-price__logo" src={faraLogo} alt="BNB" />
        </div>
      </div>
      <div className="equipment__info">
        <div className="flex-column-center">
          <div className="equipment__info-title">Tier</div>
          <div className="lands__item-tier">
            {Array(land.tier)
              .fill(1)
              .map((_, idx) => (
                <img key={idx} src={activeGem} alt="" />
              ))}
          </div>
        </div>
        <div>
          <div className="equipment__info-title">Crystals</div>
          <div className="equipment__info-value">TBD</div>
        </div>
        <div>
          <div className="equipment__info-title">Type</div>
          <div className="equipment__info-value">{land.landType}</div>
        </div>

        {/* <div>
          <div className="equipment__info-title">Rarity</div>
          <div className={`equipment__info-tag equipment__info-tag--${lowerCaseRarity}`}>{lowerCaseRarity}</div>
        </div> */}
      </div>
      <div className="knight__body-parts">
        <div className="knight__title">Land Attributes</div>
        <div className="body-part">
          {land.attributes &&
            land.attributes.map((attributes: LandAttributes, index: number) => (
              <div className="body-part__item" key={index}>
                <Tooltip title={mapLandTraitType(attributes.traitType)} placement="top">
                  <img
                    src={require(`src/assets/images/lands/trait/${land.landType.toLowerCase()}/${
                      ["decoa", "decob"].includes(attributes.traitType.toLowerCase())
                        ? "decoa"
                        : attributes.traitType.toLowerCase()
                    }_1.png`)}
                    alt={attributes.traitType}
                    width={60}
                  />
                </Tooltip>
                <div className="body-part__content">
                  <div className="body-part__name">{attributes.value}</div>
                  <div className={`body-part__rarity rarity-${attributes.displayRarity.toLowerCase()}`}>
                    {attributes.displayRarity}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <LandOfferTable
        land={land}
        offers={offers}
        isOwner={isOwner}
        isHaveOffers={isHaveOffers}
        onOpenPopup={onOpenPopup}
        handleTakeOffer={handleTakeOffer}
      />
    </div>
  );
}
