import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS } from "src/app/configs/constants";
import { _sendTx } from "src/app/sagas/index";
import { materialActionTypes } from "src/app/actions/materialAction";

function* list(action: any): any {
  yield call(_sendTx, ACTIONS.LIST_MATERIAL, action.payload);
}

function* delist(action: any): any {
  yield call(_sendTx, ACTIONS.DELIST_MATERIAL, action.payload);
}

function* buy(action: any): any {
  yield call(_sendTx, ACTIONS.BUY_MATERIAL, action.payload);
}

function* offer(action: any): any {
  yield call(_sendTx, ACTIONS.OFFER_MATERIAL, action.payload);
}

function* cancelOffer(action: any): any {
  yield call(_sendTx, ACTIONS.CANCEL_OFFER_MATERIAL, action.payload);
}

function* takeOffer(action: any): any {
  yield call(_sendTx, ACTIONS.TAKE_OFFER_MATERIAL, action.payload);
}

function* transfer(action: any): any {
  yield call(_sendTx, ACTIONS.MATERIAL_TRANSFER, action.payload, action.callbacks.onSuccess);
}

function* consumeSoulStone(action: any): any {
  yield call(_sendTx, ACTIONS.CONSUME_SS, action.payload, action.callbacks.onSuccess);
}

function* batchTransfer(action: any): any {
  yield call(_sendTx, ACTIONS.MATERIAL_BATCH_TRANSFER, action.payload, action.callbacks.onSuccess);
}

function* deposit(action: any): any {
  yield call(_sendTx, ACTIONS.MATERIAL_DEPOSIT, action.payload, action.callbacks.onSuccess);
}

function* withdraw(action: any): any {
  yield call(_sendTx, ACTIONS.MATERIAL_WITHDRAW, action.payload, action.callbacks.onSuccess);
}

export default function* equipmentSaga() {
  yield takeLatest(materialActionTypes.LIST, list);
  yield takeLatest(materialActionTypes.DELIST, delist);
  yield takeLatest(materialActionTypes.BUY, buy);
  yield takeLatest(materialActionTypes.OFFER, offer);
  yield takeLatest(materialActionTypes.CANCEL_OFFER, cancelOffer);
  yield takeLatest(materialActionTypes.TAKE_OFFER, takeOffer);
  yield takeLatest(materialActionTypes.TRANSFER, transfer);
  yield takeLatest(materialActionTypes.CONSUME_SS, consumeSoulStone);
  yield takeLatest(materialActionTypes.BATCH_TRANSFER, batchTransfer);
  yield takeLatest(materialActionTypes.DEPOSIT, deposit);
  yield takeLatest(materialActionTypes.WITHDRAW, withdraw);
}
