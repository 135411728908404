import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import Web3Service from "src/app/services/web3/Web3Service";
import { BlockData, TokenPriceData } from "src/app/types/common";

export const globalActionTypes = {
  SET_MODAL: 'GLOBAL.SET_MODAL',
  SET_WEB3_SERVICE: 'GLOBAL.SET_WEB3_SERVICE',
  SET_TOKEN_PRICE: 'GLOBAL.SET_TOKEN_PRICE',
  SET_SYNCED_BLOCK: 'GLOBAL.SET_SYNCED_BLOCK',
  SET_SUBGRAPH_CLIENT: 'GLOBAL.SET_SUBGRAPH_CLIENT',
};

export function setGlobalModal(key: string, data: any = null) {
  return {
    type: globalActionTypes.SET_MODAL,
    payload: { key, data }
  }
}

export function setWeb3Service(web3Service: Web3Service) {
  return {
    type: globalActionTypes.SET_WEB3_SERVICE,
    payload: web3Service
  }
}

export function setTokenPrice(tokenPrice: TokenPriceData) {
  return {
    type: globalActionTypes.SET_TOKEN_PRICE,
    payload: tokenPrice
  }
}

export function setSyncedBlock(syncedBlock: BlockData) {
  return {
    type: globalActionTypes.SET_SYNCED_BLOCK,
    payload: syncedBlock
  }
}

export function setSubgraphClient(subgraphClient: ApolloClient<NormalizedCacheObject> | null) {
  return {
    type: globalActionTypes.SET_SUBGRAPH_CLIENT,
    payload: subgraphClient
  }
}