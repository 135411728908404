import React, { useState, useEffect, useCallback } from "react";
import { FormControl, InputLabel, Select, StylesProvider, TextField } from "@material-ui/core";
import { INIT_GUILD_FILTER, SORT_GUILD_LIST_BY } from "src/app/configs/constants";
import { getFilterCount, getGuildFilterFromParams } from "src/app/utils/filterHelper";
import { useQueryString } from "use-route-as-state";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { debounce } from "lodash";
import { GUILD_COSTS } from "src/app/configs/constants";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalModal } from "src/app/actions/globalAction";
import { Guild, GuildFilterType } from "src/app/types/guild";
import { Link } from "react-router-dom";
import { formatNumber } from "src/app/utils/helpers";

type GuildListFilterProps = {
  guildOfAddress?: Guild;
};

export default function GuildListFilter(props: GuildListFilterProps) {
  const dispatch = useDispatch();
  const { balance } = useSelector((state: any) => state.account);
  const [filterCount, setFilterCount] = useState(0);
  const [queryParams, setQueryParams] = useQueryString();
  const [guildFilters, setGuildFilters] = useState<GuildFilterType>(getGuildFilterFromParams(queryParams, false));
  const [guildIdInput, setGuildIdInput] = useState<any>(guildFilters.id);
  const [guildNameInput, setGuildNameInput] = useState<any>(guildFilters.name);
  const [createGuildError, setCreateGuildError] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);
  const disableFilter = guildFilters.joinable || guildFilters.pending;

  useEffect(() => {
    setFilterCount(getFilterCount(guildFilters));
  }, [guildFilters]);

  useEffect(() => {
    const newFilters = getGuildFilterFromParams(queryParams, false);
    setGuildFilters({ ...newFilters });
  }, [queryParams]);

  const handleInputChange = (category: string) => (e: any) => {
    if (category === "name") {
      setGuildNameInput(e.target.value);
      debouncePushGuildName(e.target.value);
    } else if (category === "id") {
      setGuildIdInput(e.target.value);
      debouncePushGuildId(e.target.value);
    }
  };

  const debouncePushGuildId = useCallback(debounce(pushGuildIdToUri, 500), []);
  const debouncePushGuildName = useCallback(debounce(pushGuildNameToUri, 500), []);

  function pushGuildIdToUri(guildId: string) {
    setQueryParams({
      ...queryParams,
      id: guildId,
    });
  }

  function pushGuildNameToUri(guildName: string) {
    setQueryParams({
      ...queryParams,
      name: guildName,
    });
  }

  const handleSelectionChange = (category: string) => (e: any) => {
    setQueryParams({
      ...queryParams,
      [category]: e.target.value,
    });
  };

  function handleClearFilters() {
    setGuildFilters(INIT_GUILD_FILTER);
    setGuildIdInput("");
    setGuildNameInput("");
    setQueryParams({});
  }

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  function renderSelectionBox(category: string, values: any, constant: any) {
    return (
      <div className={category && category !== "sortBy" ? `mb-4` : ""}>
        {category !== "sortBy" && (
          <div className="market__filter-title">
            <span>{category === "sortBy" ? "Sort By" : "Slot Type"}</span>
          </div>
        )}
        <FormControl
          variant="outlined"
          className="input mr-4"
          style={{ width: `${category !== "sortBy" ? "100%" : "200px"}` }}
        >
          <InputLabel htmlFor="outlined-age-native-simple">
            {category === "sortBy" ? "Sort By" : "Slot Type"}
          </InputLabel>
          <Select
            label={category}
            native
            value={values}
            onChange={handleSelectionChange(category)}
            style={{ padding: `${category !== "sortBy" && "9px"}` }}
          >
            {Object.keys(constant).map((key: string) => {
              return (
                <option value={constant[key]} key={key}>
                  {constant[key]}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  }

  function renderNameField(category: string, label: string) {
    return (
      <div className={category && category !== "id" ? `mb-4` : ""}>
        {category !== "id" && (
          <div className="market__filter-title market__filter-title--guild mb-4">
            <span>{label}</span>
          </div>
        )}
        <StylesProvider injectFirst>
          <TextField
            value={category === "id" ? guildIdInput : guildNameInput}
            className={`input ${category !== "id" && "market__input"}`}
            label={label}
            type={category === "id" ? "number" : "text"}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={handleInputChange(category)}
          />
        </StylesProvider>
      </div>
    );
  }

  function openGuildCreationModal() {
    setCreateGuildError("");

    if (+balance.FARA < GUILD_COSTS.CREATE_FARA) {
      setCreateGuildError("Insufficient FARA Balance");
      return;
    }
    if (+balance.EMPEROR_STONE < GUILD_COSTS.CREATE_EMPEROR_STONE) {
      setCreateGuildError("Insufficient Emperor Stone Balance");
      return;
    }

    dispatch(setGlobalModal("guildCreationModal", { active: true, guildRefresh: false }));
  }

  function renderCreateGuildButton() {
    return (
      <div className={`mb-2`}>
        <div className="market__filter-title market__filter-title--guild mb-3">
          <span>Create Guild</span>
        </div>
        <div className="flex-column-center">
          {props.guildOfAddress ? (
            <>
              <Link to={`guild/${props.guildOfAddress.id}`}>
                <div className="btn">View Guild Details</div>
              </Link>
              <div className="flex-center-center mt-3 fs-3 warning-text align-center">
                You are already a member of {props.guildOfAddress.name}
              </div>
            </>
          ) : (
            <>
              <div className={`btn btn--large center-margin mb-3`} onClick={openGuildCreationModal}>
                Create A Guild
              </div>
              {createGuildError !== "" && <div className="flex-center-center fs-3 error-text">{createGuildError}</div>}
              <div>
                {GUILD_COSTS.CREATE_EMPEROR_STONE} Emperor Stone + {formatNumber(GUILD_COSTS.CREATE_FARA)} FARA
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`market__filter-container ${disableFilter ? "disabled" : ""}`}>
      <div className="market__filter-button">
        <div className="btn" onClick={handleShowFilter}>
          Filters <ArrowDropDownIcon />
        </div>
      </div>
      <div className={`market__filter market__filter--guild ${showFilter && "market__filter--mobile"} fade-in`}>
        <div className="market__filter-header">
          <div className="fw-4 top-1">FILTERS ({filterCount})</div>
          <div className="btn btn--small" onClick={handleClearFilters}>
            Clear Filter
          </div>
        </div>
        <div className="flex mb-4">
          {renderSelectionBox("sortBy", guildFilters.sortBy, SORT_GUILD_LIST_BY)}
          {renderNameField("id", "Guild ID")}
        </div>
        {renderNameField("name", "Guild Name")}
        {renderCreateGuildButton()}
      </div>
    </div>
  );
}
