import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WalletConnectService from "src/app/services/accounts/WalletConnectService";
import { clearAccount, importAccount } from "src/app/actions/accountAction";
import { WALLET_TYPE } from "src/app/configs/constants";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { getWalletParams} from "src/app/utils/helpers";
import { setGlobalModal } from "src/app/actions/globalAction";

export default function WalletConnectAccount() {
  const dispatch = useDispatch();
  const { web3Service } = useSelector((state: any) => state.global);

  async function connect() {
    try {
      const props = getWalletParams();
      const wallet = new WalletConnectService(props);
      await wallet.initiateWallet();
      const address = await wallet.connect(openConnectErrorModal);

      if (address) {
        const spaceDomain = await web3Service?.sid.getName(address);
        // await signAndAuthenticateWallet(wallet, address);
        wallet.getDisconnected(
          () => dispatch(clearAccount()),
          (address: string, wallet: any, walletType: string, domainName: string) =>
            dispatch(importAccount(address, wallet, walletType, domainName)),
          wallet,
        );
        dispatch(importAccount(address, wallet, WALLET_TYPE.WALLET_CONNECT, spaceDomain.name ?? ""));
        modalService.close();
      }
    } catch (e) {
      console.log(e);
    }

    dispatch(setGlobalModal("loading"));
  }

  function openConnectErrorModal() {
    dispatch(
      setGlobalModal("error", {
        active: true,
        data: <div>Cannot connect to your Wallet.</div>,
      })
    );
  }

  return (
    <div className="account__item" onClick={connect}>
      <div className="account__icon wallet-connect" />
      <div className="account__name">Connect</div>
    </div>
  );
}
