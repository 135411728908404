import React, { useState } from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import InputGroup from "src/app/components/Commons/InputGroup";
import AddressInputGroup from "src/app/components/Commons/AddressInputGroup";
import { useSelector, useDispatch } from "react-redux";
import { isAddress } from "web3-utils";
import { setGlobalModal } from "src/app/actions/globalAction";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { compareNumbers } from "src/app/utils/calculators";
import Modal from "src/app/components/Commons/Modals/Modal";
import { UserMaterial } from "src/app/types/materials";
import { transferMaterials } from "src/app/actions/materialAction";

export default function MaterialTransferModal() {
  const dispatch = useDispatch();

  const { modal } = useSelector((state: any) => state.global);
  const { address } = useSelector((state: any) => state.account);

  const [receiveAddr, setReceiveAddr] = useState("");
  const [transferNo, setTransferNo] = useState("");
  const [invalidAddressError, setInvalidAddressError] = useState("");
  const [invalidNumber, setInvalidNumber] = useState("");
  const [receiverInput, setReceiverInput] = useState("");

  const transferMaterialModal = modal.transferMaterials;
  const selectedMaterial: UserMaterial = transferMaterialModal.data?.selectedMaterial ?? {};

  function sendMaterialTransferTx() {
    let isAddressCorrect = false;
    let isTransferNoCorrect = false;

    if (!isAddress(receiveAddr)) {
      setInvalidAddressError("Invalid BSC address.");
    } else if (receiveAddr.toLowerCase() === address.toLowerCase()) {
      setInvalidAddressError("The receiving address cannot be the same as your current address.");
    } else {
      isAddressCorrect = true;
    }
    if (compareNumbers(transferNo, selectedMaterial.amount) === 1) {
      setInvalidNumber(`Can only transfer ${selectedMaterial.amount} items at most.`);
    } else if (transferNo === "0" || transferNo === "") {
      setInvalidNumber(`You need to transfer at least 1 material.`);
    } else {
      isTransferNoCorrect = true;
    }

    if (isAddressCorrect && isTransferNoCorrect) {
      dispatch(
        transferMaterials(address, receiveAddr, +selectedMaterial.material.id, +transferNo, () => modalService.close())
      );
      handleClose();
    }
  }

  function handleClose() {
    dispatch(setGlobalModal("transferMaterials"));
    setReceiveAddr("");
    setTransferNo("");
    setInvalidAddressError("");
    setInvalidNumber("");
  }

  function handleSetReceiverAddress(value: string) {
    setReceiveAddr(value);
  }

  function handleTransferChange(e: any) {
    setInvalidNumber("");
    setTransferNo(e.target.value);
  }

  function handleAddressChange(e: any) {
    setInvalidAddressError("");
    setReceiverInput(e.target.value);
  }

  function renderTransferContent() {
    return (
      <>
        <AddressInputGroup
          value={receiverInput}
          setAddress={handleSetReceiverAddress}
          handleChange={handleAddressChange}
          error={invalidAddressError}
          label={"Receiving Address"}
        />
        <InputGroup
          className="text-field text-field--transfer mt-6"
          value={transferNo.toString()}
          handleChange={handleTransferChange}
          symbol=""
          balance={selectedMaterial.amount?.toString()}
          error={invalidNumber}
          label={"Number of items"}
          balanceText="Materials available for transfer"
          hideMaxBtn
        />
      </>
    );
  }

  function renderPopupContent() {
    let content, onSubmit, submitText;

    content = renderTransferContent();
    onSubmit = sendMaterialTransferTx;
    submitText = "Transfer";

    return { content, onSubmit, submitText };
  }

  const { content, onSubmit, submitText } = renderPopupContent();

  return (
    <Modal isActive={transferMaterialModal.active} onClose={handleClose}>
      <BasicModalContent
        customTitle="Transfer Your Materials"
        onSubmit={onSubmit}
        submitText={submitText}
        close={handleClose}
        content={<div className="text-center mt-7">{content}</div>}
      />
    </Modal>
  );
}
