export const demiActionTypes = {
  SUMMON: "DEMI.SUMMON",
  DENY: "DEMI.DENY",
  REGISTER: "DEMI.REGISTER",
  DRINK_SOULSTONE: "DEMI.DRINK_SOULSTONE",
  EQUIP_ITEMS: "DEMI.EQUIP_ITEMS",
  UNEQUIP_ITEMS: "DEMI.UNEQUIP_ITEMS",
  TRANSFER: "DEMI.TRANSFER",
  SELECT: "DEMI.SELECT",
  DESELECT: "DEMI.DESELECT",
  RESET_SELECTION: "DEMI.RESET_SELECTION",
  APPROVE_FOR_ALL: "DEMI.APPROVE_FOR_ALL",
  BURN: "DEMI.BURN",
  REFRESH: "DEMI.REFRESH",
};

export function summonDemiKnight(onSuccess: any) {
  return {
    type: demiActionTypes.SUMMON,
    callbacks: { onSuccess },
  };
}

export function denyDemiKnight(demiId: number, onSuccess: any) {
  return {
    type: demiActionTypes.DENY,
    payload: { demiId },
    callbacks: { onSuccess },
  };
}

export function registerDemiKnight(attributes: any, onSuccess: any) {
  return {
    type: demiActionTypes.REGISTER,
    payload: { attributes },
    callbacks: { onSuccess },
  };
}

export function drinkSoulstone(demiId: number, onSuccess: any) {
  return {
    type: demiActionTypes.DRINK_SOULSTONE,
    payload: { demiId },
    callbacks: { onSuccess },
  };
}

export function demiEquipItems(demiId: number, itemIds: any[], onSuccess: any) {
  return {
    type: demiActionTypes.EQUIP_ITEMS,
    payload: { demiId, itemIds },
    callbacks: { onSuccess },
  };
}

export function demiUnequipItems(demiId: number, itemIds: any[], onSuccess: any) {
  return {
    type: demiActionTypes.UNEQUIP_ITEMS,
    payload: { demiId, itemIds },
    callbacks: { onSuccess },
  };
}

export function demiTransfer(demiId: number, from: string, to: string, isSafeTransfer: boolean = true) {
  return {
    type: demiActionTypes.TRANSFER,
    payload: { demiId, from, to, isSafeTransfer },
  };
}
