import React, { useEffect, useState } from "react";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";
import { CSSProperties } from "react";
import * as animatedJson from "src/assets/lotties/dots-in-move.json";
import fullBannerPlaceholderImage from "src/assets/images/decors/banner-placeholder-full.png";
import Lottie from "react-lottie";
import mintLandBtn from "src/assets/images/lands/mint-btn.png";
import { ACTIONS, LAND_CLAIM_PHASE, MAX_LAND_SUPPLY } from "src/app/configs/constants";
import LandActionModal from "src/app/components/Land/LandActionModal";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { useDispatch, useSelector } from "react-redux";
import { claimAirdropLands, claimPublicMint } from "src/app/actions/landAction";
import ENV from "src/app/configs/env";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import { setGlobalModal } from "src/app/actions/globalAction";
import MintCompleteModal from "src/app/components/Land/Mint/MintCompleteModal";

const jsonOptions = getAnimatedJsonOptions(animatedJson);
const jsonStyles: CSSProperties = {
  width: 300,
  height: 300,
  position: "absolute",
  right: 0,
  left: 0,
  margin: "0 auto",
  top: "-105px",
};

export default function LandBannerContent() {
  const dispatch = useDispatch();
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.LAND);
  const { web3Service } = useSelector((state: any) => state.global);
  const [currentMinted, setCurrentMinted] = useState(0);
  const [barWidth, setBarWidth] = useState<string | number>(0);
  const phase = LAND_CLAIM_PHASE.PUBLIC_MINT;

  function handleClaim(phase: number) {
    switch (phase) {
      case LAND_CLAIM_PHASE.AIRDROP:
        dispatch(claimAirdropLands());
        break;
      case LAND_CLAIM_PHASE.PRIVATE_MINT:
        handlePrivateMint();
        break;
      case LAND_CLAIM_PHASE.PUBLIC_MINT:
        handlePublicMint();
        break;
    }
  }

  function handlePrivateMint() {
    modalService.show(LandActionModal, {
      type: ACTIONS.PRIVATE_LAND_MINT,
    });
  }
  function handlePublicMint() {
    // modalService.show(LandActionModal, {
    //   type: ACTIONS.PUBLIC_LAND_MINT,
    // });
    if (needApprove) {
      sendApproveTx();
      return;
    }
    dispatch(claimPublicMint(1, () => displayMintCompleteModal(1)));
  }

  function displayMintCompleteModal(mintAmount: number) {
    dispatch(setGlobalModal("txTracking"));
    modalService.show(MintCompleteModal, { mintAmount });
  }
  useEffect(() => {
    if (!web3Service) return;
    async function getCurrentMinted() {
      const result = await web3Service.fetchLandSupply();
      setCurrentMinted(result);
    }

    getCurrentMinted();
  }, [web3Service]);

  useEffect(() => {
    const width = currentMinted >= MAX_LAND_SUPPLY ? 100 : ((currentMinted / MAX_LAND_SUPPLY) * 100).toFixed(0);
    setBarWidth(width);
  }, [currentMinted]);

  // function onOpenPopup(type: number) {
  //   modalService.show(LandActionModal, {
  //     type: type,
  //   });
  // }

  return (
    <div className="home-banner">
      <img className="home-banner__placeholder" src={fullBannerPlaceholderImage} alt="" />
      {currentMinted < MAX_LAND_SUPPLY && (
        <div className="home-banner__lottie">
          <Lottie style={jsonStyles} options={jsonOptions} isClickToPauseDisabled />
        </div>
      )}
      <div className="home-banner__button pointer">
        <img
          src={mintLandBtn}
          className={`${currentMinted >= MAX_LAND_SUPPLY && "disabled"}`}
          alt="Mint Land"
          onClick={() => handleClaim(phase)}
        />
      </div>
      <div className="progress-bar">
        <div className="progress-bar__info">
          <h4>
            {currentMinted}/{MAX_LAND_SUPPLY} MINTED
          </h4>
          <h4>{barWidth}% MINTED</h4>
        </div>
        <div className="progress-bar__bar">
          <div style={{ width: `${barWidth}%` }} />
        </div>
      </div>
    </div>
  );
}
