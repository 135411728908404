import { ACTIONS, DEFAULT_GAS_PRICE, KNIGHT_TYPE } from "src/app/configs/constants";
import { getBiggestNumber, getStakingContract, toHex, toWei } from "src/app/utils/helpers";
import { TxData, TxObject } from "src/app/types/tx";
import Web3Service from "src/app/services/web3/Web3Service";
import ENV from "src/app/configs/env";

interface txDataObject {
  web3Service: Web3Service;
  action: any;
  tx: TxData;
}

export function getTxObjectByType(txType: number, data: txDataObject): TxObject | null {
  let txObject: TxObject | null = null;

  if (txType === ACTIONS.APPROVE) {
    txObject = _getApproveTxObject(data);
  } else if (txType === ACTIONS.STAKE) {
    txObject = _getStakeTxObject(data);
  } else if (txType === ACTIONS.UNSTAKE) {
    txObject = _getUnstakeTxObject(data);
  } else if (txType === ACTIONS.CLAIM) {
    txObject = _getClaimTxObject(data);
  } else if (txType === ACTIONS.CONVERT_EXP) {
    txObject = _getConvertExpTxObject(data);
  } else if (txType === ACTIONS.LIST) {
    txObject = _getListTxObject(data);
  } else if (txType === ACTIONS.DELIST) {
    txObject = _getDelistTxObject(data);
  } else if (txType === ACTIONS.OFFER) {
    txObject = _getOfferTxObject(data);
  } else if (txType === ACTIONS.TAKE_OFFER) {
    txObject = _getTakeOfferTxObject(data);
  } else if (txType === ACTIONS.CANCEL_OFFER) {
    txObject = _getCancelOfferTxObject(data);
  } else if (txType === ACTIONS.BUY) {
    txObject = _getBuyTxObject(data);
  } else if (txType === ACTIONS.TRANSFER) {
    txObject = _getTransferTxObject(data);
  } else if (txType === ACTIONS.SACRIFICE) {
    txObject = _getSacrificeTxObject(data);
  } else if (txType === ACTIONS.HERO_EQUIP_ITEMS) {
    txObject = _getHeroEquipItemsTxObject(data);
  } else if (txType === ACTIONS.HERO_UNEQUIP_ITEMS) {
    txObject = _getHeroUnequipItemsTxObject(data);
  } else if (txType === ACTIONS.CHANGE_NAME) {
    txObject = _getChangeNameTxObject(data);
  } else if (txType === ACTIONS.ROLL_GACHA) {
    txObject = _rollGachaTxObject(data);
  } else if (txType === ACTIONS.TRANSFER_EQUIPMENT) {
    txObject = _transferEquipment(data);
  } else if (txType === ACTIONS.UPGRADE_EQUIPMENT) {
    txObject = _upgradeEquipment(data);
  } else if (txType === ACTIONS.LIST_EQUIPMENT) {
    txObject = _listEquipment(data);
  } else if (txType === ACTIONS.DELIST_EQUIPMENT) {
    txObject = _delistEquipment(data);
  } else if (txType === ACTIONS.BUY_EQUIPMENT) {
    txObject = _buyEquipment(data);
  } else if (txType === ACTIONS.OFFER_EQUIPMENT) {
    txObject = _offerEquipment(data);
  } else if (txType === ACTIONS.CANCEL_OFFER_EQUIPMENT) {
    txObject = _cancelOfferEquipment(data);
  } else if (txType === ACTIONS.TAKE_OFFER_EQUIPMENT) {
    txObject = _takeOfferEquipment(data);
  } else if (txType === ACTIONS.LIST_EXCHANGE_EQUIPMENT) {
    txObject = _listExchangeEquipment(data);
  } else if (txType === ACTIONS.CANCEL_EXCHANGE_EQUIPMENT) {
    txObject = _cancelExchangeEquipment(data);
  } else if (txType === ACTIONS.TAKE_EXCHANGE_OFFER) {
    txObject = _takeEXchangeOffer(data);
  } else if (txType === ACTIONS.INCREASE_STATS) {
    txObject = _increaseStats(data);
  } else if (txType === ACTIONS.SUMMON_DEMI) {
    txObject = _summonDemiKnight(data);
  } else if (txType === ACTIONS.LIST_MATERIAL) {
    txObject = _listMaterial(data);
  } else if (txType === ACTIONS.DELIST_MATERIAL) {
    txObject = _delistMaterial(data);
  } else if (txType === ACTIONS.BUY_MATERIAL) {
    txObject = _buyMaterial(data);
  } else if (txType === ACTIONS.OFFER_MATERIAL) {
    txObject = _offerMaterial(data);
  } else if (txType === ACTIONS.CANCEL_OFFER_MATERIAL) {
    txObject = _cancelOfferMaterial(data);
  } else if (txType === ACTIONS.TAKE_OFFER_MATERIAL) {
    txObject = _takeOfferMaterial(data);
  } else if (txType === ACTIONS.DENY_DEMI) {
    txObject = _denyDemiKnight(data);
  } else if (txType === ACTIONS.REGISTER) {
    txObject = _registerDemiKnight(data);
  } else if (txType === ACTIONS.DRINK_SOULSTONE) {
    txObject = _drinkSoulStone(data);
  } else if (txType === ACTIONS.DEMI_EQUIP_ITEMS) {
    txObject = _getDemiEquipItemsTxObject(data);
  } else if (txType === ACTIONS.DEMI_UNEQUIP_ITEMS) {
    txObject = _getDemiUnequipItemsTxObject(data);
  } else if (txType === ACTIONS.DEMI_TRANSFER) {
    txObject = _getDemiTransferTxObject(data);
  } else if (txType === ACTIONS.MATERIAL_TRANSFER) {
    txObject = _transferMaterials(data);
  } else if (txType === ACTIONS.CONVERT_KNIGHT_EXP) {
    txObject = _convertKnightExp(data);
  } else if (txType === ACTIONS.CONSUME_SS) {
    txObject = _consumeSoulStoneForExp(data);
  } else if (txType === ACTIONS.LEARN_SKILL_BOOK) {
    txObject = _learnSkillBook(data);
  } else if (txType === ACTIONS.RESET_STATS) {
    txObject = _resetStats(data);
  } else if (txType === ACTIONS.BATCH_TRANSFER) {
    txObject = _batchTransferEquipment(data);
  } else if (txType === ACTIONS.CREATE_GUILD) {
    txObject = _createGuild(data);
  } else if (txType === ACTIONS.JOIN_GUILD) {
    txObject = _joinGuild(data);
  } else if (txType === ACTIONS.APPROVE_MEMBERS) {
    txObject = _approveMembers(data);
  } else if (txType === ACTIONS.KICK_MEMBERS) {
    txObject = _kickMembers(data);
  } else if (txType === ACTIONS.REJECT_MEMBERS) {
    txObject = _rejectMembers(data);
  } else if (txType === ACTIONS.LEAVE_GUILD) {
    txObject = _leaveGuild(data);
  } else if (txType === ACTIONS.UPGRADE_BODY_PART) {
    txObject = _upgradeBodyPart(data);
  } else if (txType === ACTIONS.STAKING_CLAIM_ITEM) {
    txObject = _getClaimItemTxObject(data);
  } else if (txType === ACTIONS.EMERGENCY_WITHDRAW) {
    txObject = _getEmergencyWithdrawTxObject(data);
  } else if (txType === ACTIONS.BURN) {
    txObject = _burnKnight(data);
  } else if (txType === ACTIONS.APPROVE_FOR_ALL) {
    txObject = _approveForAll(data);
  } else if (txType === ACTIONS.CHANGE_GUILD_NAME) {
    txObject = _changeGuildName(data);
  } else if (txType === ACTIONS.CRAFT_ITEM) {
    txObject = _craftItem(data);
  } else if (txType === ACTIONS.TRANSFER_GUILD_OWNERSHIP) {
    txObject = _transferGuildOwnership(data);
  } else if (txType === ACTIONS.CANCEL_JOIN_REQUEST) {
    txObject = _cancelJoinRequest(data);
  } else if (txType === ACTIONS.THROW_ITEM_GACHA) {
    txObject = _throwItemGacha(data);
  } else if (txType === ACTIONS.MATERIAL_BATCH_TRANSFER) {
    txObject = _batchTransferMaterials(data);
  } else if (txType === ACTIONS.LEVEL_UP_BLACKSMITH) {
    txObject = _levelUpBlacksmith(data);
  } else if (txType === ACTIONS.EQUIPMENT_DISMANTLE) {
    txObject = _dismantleEquipment(data);
  } else if (txType === ACTIONS.CONVERT_EXP_TO_BP) {
    txObject = _convertExpToBp(data);
  } else if (txType === ACTIONS.CLAIM_EQUIPMENT) {
    txObject = _claimEquipment(data);
  } else if (txType === ACTIONS.MINT_LAND) {
    txObject = _mintLand(data);
  } else if (txType === ACTIONS.GET_LAND_REWARDS) {
    txObject = _getLandRewards(data);
  } else if (txType === ACTIONS.BUY_LAND) {
    txObject = _buyLand(data);
  } else if (txType === ACTIONS.LIST_LAND) {
    txObject = _listLand(data);
  } else if (txType === ACTIONS.DELIST_LAND) {
    txObject = _delistLand(data);
  } else if (txType === ACTIONS.TAKE_LAND_OFFER) {
    txObject = _takeLandOffer(data);
  } else if (txType === ACTIONS.CANCEL_LAND_OFFER) {
    txObject = _cancelLandOffer(data);
  } else if (txType === ACTIONS.OFFER_LAND) {
    txObject = _offerLand(data);
  } else if (txType === ACTIONS.TRANSFER_LAND) {
    txObject = _transferLand(data);
  } else if (txType === ACTIONS.CLAIM_SELECTED_LANDS) {
    txObject = _claimSelectedLands(data);
  } else if (txType === ACTIONS.WHITELIST_LAND_CLAIM) {
    txObject = _claimWhitelistLands(data);
  } else if (txType === ACTIONS.PRIVATE_LAND_MINT) {
    txObject = _claimPrivateMintLands(data);
  } else if (txType === ACTIONS.PRIVATE_LAND_MINT) {
    txObject = _claimPrivateMintLands(data);
  } else if (txType === ACTIONS.PUBLIC_LAND_MINT) {
    txObject = _claimPublicMintLands(data);
  } else if (txType === ACTIONS.CLAIM_EXP_JAR) {
    txObject = _claimExpJar(data);
  } else if (txType === ACTIONS.CONSUME_EXP_JAR) {
    txObject = _consumeExpJar(data);
  } else if (txType === ACTIONS.DISTRIBUTE_EXP) {
    txObject = _distributeExp(data);
  } else if (txType === ACTIONS.MATERIAL_DEPOSIT) {
    txObject = _depositMaterials(data);
  } else if (txType === ACTIONS.TOKEN_DEPOSIT) {
    txObject = _depositTokens(data);
  } else if (txType === ACTIONS.EQUIPMENT_DEPOSIT) {
    txObject = _depositEquipment(data);
  } else if (txType === ACTIONS.MATERIAL_WITHDRAW) {
    txObject = _withdrawMaterials(data);
  } else if (txType === ACTIONS.EQUIPMENT_WITHDRAW) {
    txObject = _withdrawEquipment(data);
  }else if (txType === ACTIONS.TOKEN_WITHDRAW) {
    txObject = _withdrawTokens(data);
  }

  return txObject;
}

function _depositMaterials(data: txDataObject) {
  const methodData = data.web3Service.equipmentDepositContract.methods
    .depositMaterials(data.action.ids, data.action.amounts)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_DEPOSIT, methodData, data.tx);
}

function _depositTokens(data: txDataObject) {
  const methodData = data.web3Service.equipmentDepositContract.methods.depositTokens(data.action.amount).encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_DEPOSIT, methodData, data.tx);
}

function _depositEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentDepositContract.methods
    .depositEquipments(data.action.ids, data.action.amounts)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_DEPOSIT, methodData, data.tx);
}

function _withdrawMaterials(data: txDataObject) {
  const methodData = data.web3Service.equipmentDepositContract.methods
    .withdrawMaterials(data.action.withdrawData)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_DEPOSIT, methodData, data.tx);
}

function _withdrawTokens(data: txDataObject) {
  const methodData = data.web3Service.equipmentDepositContract.methods
    .withdrawTokens(data.action.withdrawData)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_DEPOSIT, methodData, data.tx);
}

function _withdrawEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentDepositContract.methods
    .withdrawEquipments(data.action.withdrawData)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_DEPOSIT, methodData, data.tx);
}

function _claimExpJar(data: txDataObject) {
  const methodData = data.web3Service.expJarContract.methods.mintExpJar(data.action.expJarAmount).encodeABI();
  return _getTxObject(ENV.CONTRACT.EXP_JAR, methodData, data.tx);
}

function _distributeExp(data: txDataObject) {
  const methodData = data.web3Service.expJarContract.methods
    .distributeExpToKnight(data.action.knightIds, data.action.amount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EXP_JAR, methodData, data.tx);
}

function _consumeExpJar(data: txDataObject) {
  const methodData = data.web3Service.expJarContract.methods
    .consumeExpJar(data.action.knightId, data.action.amount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EXP_JAR, methodData, data.tx);
}

function _mintLand(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods.mint(data.action.amount).encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _claimPrivateMintLands(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods.privateMint(data.action.amount).encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _claimPublicMintLands(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods.publicMint(data.action.amount).encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _buyLand(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods.buy(data.action.landId).encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _listLand(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods.list(data.action.landId, data.action.price).encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _delistLand(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods.delist(data.action.landId).encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _takeLandOffer(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods
    .takeOffer(data.action.landId, data.action.buyer, data.action.minPrice)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _cancelLandOffer(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods.cancelOffer(data.action.landId).encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _transferLand(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods
    .safeTransferFrom(data.action.from, data.action.to, data.action.landId)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}
function _getLandRewards(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods.getRewards(data.action.landIds).encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _offerLand(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods
    .offer(data.action.landId, data.action.offerValue)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _claimSelectedLands(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods.claimReward(data.action.landIds).encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _claimWhitelistLands(data: txDataObject) {
  const methodData = data.web3Service.landContract.methods.claimLands().encodeABI();
  return _getTxObject(ENV.CONTRACT.LAND, methodData, data.tx);
}

function _burnKnight(data: txDataObject) {
  const methodData = data.web3Service.heroBurnContract.methods.exchange(data.action.ids).encodeABI();
  return _getTxObject(ENV.CONTRACT.HERO_BURN, methodData, data.tx);
}

function _claimEquipment(data: txDataObject) {
  const methodData = data.web3Service.rewardsContract.methods
    .claimNFTs(data.action.nftRewards, data.action.nonce)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.REWARDS, methodData, data.tx);
}

function _dismantleEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentDismantleContract.methods
    .dismantleItems(data.action.itemIds, data.action.amount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_DISMANTLE, methodData, data.tx);
}

function _convertExpToBp(data: txDataObject) {
  const methodData = data.web3Service.equipmentDismantleContract.methods.convertEXPtoBP().encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_DISMANTLE, methodData, data.tx);
}

function _approveForAll(data: txDataObject) {
  let contract;
  switch (data.action.contract) {
    case ENV.CONTRACT.MOONKNIGHT:
      contract = data.web3Service.moonKnightContract;
      break;
    case ENV.CONTRACT.EQUIPMENT:
      contract = data.web3Service.equipmentContract;
      break;
    case ENV.CONTRACT.MATERIAL:
      contract = data.web3Service.materialContract;
      break;
  }

  const methodData = contract.methods.setApprovalForAll(data.action.operator, data.action.approved).encodeABI();
  return _getTxObject(data.action.contract, methodData, data.tx);
}

function _throwItemGacha(data: txDataObject) {
  const methodData = data.web3Service.gachaContract.methods.throwItem(data.action.itemId).encodeABI();
  data.tx.gas = "400000";

  return _getTxObject(ENV.CONTRACT.GACHA, methodData, data.tx);
}

function _changeGuildName(data: txDataObject) {
  const methodData = data.web3Service.guildContract.methods
    .changeGuildName(data.action.guildId, data.action.newName)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.GUILD, methodData, data.tx);
}

function _cancelJoinRequest(data: txDataObject) {
  const methodData = data.web3Service.guildContract.methods.cancelJoinRequest(data.action.guildId).encodeABI();
  return _getTxObject(ENV.CONTRACT.GUILD, methodData, data.tx);
}

function _transferGuildOwnership(data: txDataObject) {
  const methodData = data.web3Service.guildContract.methods
    .safeTransferFrom(data.action.from, data.action.to, data.action.guildId, "0x")
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.GUILD, methodData, data.tx);
}

function _createGuild(data: txDataObject) {
  const methodData = data.web3Service.guildContract.methods.createGuild(data.action.name).encodeABI();
  return _getTxObject(ENV.CONTRACT.GUILD, methodData, data.tx);
}

function _joinGuild(data: txDataObject) {
  const methodData = data.web3Service.guildContract.methods.joinGuild(data.action.guildId).encodeABI();
  return _getTxObject(ENV.CONTRACT.GUILD, methodData, data.tx);
}

function _approveMembers(data: txDataObject) {
  const methodData = data.web3Service.guildContract.methods
    .approveMembers(data.action.guildId, data.action.address)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.GUILD, methodData, data.tx);
}

function _rejectMembers(data: txDataObject) {
  const methodData = data.web3Service.guildContract.methods
    .rejectMembers(data.action.guildId, data.action.address)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.GUILD, methodData, data.tx);
}
function _kickMembers(data: txDataObject) {
  const methodData = data.web3Service.guildContract.methods
    .kickMembers(data.action.guildId, data.action.address)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.GUILD, methodData, data.tx);
}

function _leaveGuild(data: txDataObject) {
  const methodData = data.web3Service.guildContract.methods.leaveGuild(data.action.guildId).encodeABI();
  return _getTxObject(ENV.CONTRACT.GUILD, methodData, data.tx);
}

function _batchTransferEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentContract.methods
    .safeBatchTransferFrom(data.action.from, data.action.to, data.action.ids, data.action.amounts, "0x")
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT, methodData, data.tx);
}

function _batchTransferMaterials(data: txDataObject) {
  const methodData = data.web3Service.materialContract.methods
    .safeBatchTransferFrom(data.action.from, data.action.to, data.action.ids, data.action.amounts, "0x")
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.MATERIAL, methodData, data.tx);
}

function _resetStats(data: txDataObject) {
  const methodData = data.web3Service.knightAttributeContract.methods
    .resetStats(data.action.knightId, data.action.knightType)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.KNIGHT_ATTRIBUTE, methodData, data.tx);
}

function _summonDemiKnight(data: txDataObject) {
  const methodData = data.web3Service.demiKnightContract.methods.summonDemiKnight().encodeABI();
  return _getTxObject(ENV.CONTRACT.DEMI_KNIGHT, methodData, data.tx);
}

function _denyDemiKnight(data: txDataObject) {
  const methodData = data.web3Service.demiKnightContract.methods.sacrificeKnight(data.action.demiId).encodeABI();
  return _getTxObject(ENV.CONTRACT.DEMI_KNIGHT, methodData, data.tx);
}

function _registerDemiKnight(data: txDataObject) {
  const methodData = data.web3Service.rewardsContract.methods.claimAttributes(data.action.attributes).encodeABI();
  return _getTxObject(ENV.CONTRACT.REWARDS, methodData, data.tx);
}

function _drinkSoulStone(data: txDataObject) {
  const methodData = data.web3Service.demiKnightContract.methods.drinkSoulStone(data.action.demiId).encodeABI();
  return _getTxObject(ENV.CONTRACT.DEMI_KNIGHT, methodData, data.tx);
}

function _getDemiEquipItemsTxObject(data: txDataObject) {
  const methodData = data.web3Service.demiKnightContract.methods
    .equipItems(data.action.demiId, data.action.itemIds)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.DEMI_KNIGHT, methodData, data.tx);
}

function _getDemiUnequipItemsTxObject(data: txDataObject) {
  const methodData = data.web3Service.demiKnightContract.methods
    .removeItems(data.action.demiId, data.action.itemIds)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.DEMI_KNIGHT, methodData, data.tx);
}

function _getDemiTransferTxObject(data: txDataObject) {
  let methodData;
  if (data.action.isSafeTransfer) {
    methodData = data.web3Service.demiKnightContract.methods
      .safeTransferFrom(data.action.from, data.action.to, data.action.demiId)
      .encodeABI();
  } else {
    methodData = data.web3Service.demiKnightContract.methods
      .transferFrom(data.action.from, data.action.to, data.action.demiId)
      .encodeABI();
  }
  return _getTxObject(ENV.CONTRACT.DEMI_KNIGHT, methodData, data.tx);
}

function _getListTxObject(data: txDataObject) {
  const contract = data.action.isDemi ? data.web3Service.demiKnightContract : data.web3Service.moonKnightContract;
  const to = data.action.isDemi ? ENV.CONTRACT.DEMI_KNIGHT : ENV.CONTRACT.MOONKNIGHT;
  const methodData = contract.methods.list(data.action.knightId, data.action.price).encodeABI();
  return _getTxObject(to, methodData, data.tx);
}

function _getDelistTxObject(data: txDataObject) {
  const contract = data.action.isDemi ? data.web3Service.demiKnightContract : data.web3Service.moonKnightContract;
  const to = data.action.isDemi ? ENV.CONTRACT.DEMI_KNIGHT : ENV.CONTRACT.MOONKNIGHT;
  const methodData = contract.methods.delist(data.action.knightId).encodeABI();
  return _getTxObject(to, methodData, data.tx);
}

function _getOfferTxObject(data: txDataObject) {
  const contract = data.action.isDemi ? data.web3Service.demiKnightContract : data.web3Service.moonKnightContract;
  const to = data.action.isDemi ? ENV.CONTRACT.DEMI_KNIGHT : ENV.CONTRACT.MOONKNIGHT;
  const methodData = contract.methods.offer(data.action.knightId, data.action.offerValue).encodeABI();
  return _getTxObject(to, methodData, data.tx);
}

function _getTakeOfferTxObject(data: txDataObject) {
  const contract = data.action.isDemi ? data.web3Service.demiKnightContract : data.web3Service.moonKnightContract;
  const to = data.action.isDemi ? ENV.CONTRACT.DEMI_KNIGHT : ENV.CONTRACT.MOONKNIGHT;
  const methodData = contract.methods
    .takeOffer(data.action.knightId, data.action.buyer, data.action.minPrice)
    .encodeABI();
  return _getTxObject(to, methodData, data.tx);
}

function _getCancelOfferTxObject(data: txDataObject) {
  const contract = data.action.isDemi ? data.web3Service.demiKnightContract : data.web3Service.moonKnightContract;
  const to = data.action.isDemi ? ENV.CONTRACT.DEMI_KNIGHT : ENV.CONTRACT.MOONKNIGHT;
  const methodData = contract.methods.cancelOffer(data.action.knightId).encodeABI();
  return _getTxObject(to, methodData, data.tx);
}

function _getBuyTxObject(data: txDataObject) {
  const contract = data.action.isDemi ? data.web3Service.demiKnightContract : data.web3Service.moonKnightContract;
  const to = data.action.isDemi ? ENV.CONTRACT.DEMI_KNIGHT : ENV.CONTRACT.MOONKNIGHT;
  const methodData = contract.methods.buy(data.action.knightId).encodeABI();
  return _getTxObject(to, methodData, data.tx);
}

function _getTransferTxObject(data: txDataObject) {
  const methodData = data.web3Service.moonKnightContract.methods
    .safeTransferFrom(data.action.from, data.action.to, data.action.knightId)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.MOONKNIGHT, methodData, data.tx);
}

function _getChangeNameTxObject(data: txDataObject) {
  const methodData = data.web3Service.moonKnightContract.methods
    .changeKnightName(data.action.knightId, data.action.name)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.MOONKNIGHT, methodData, data.tx);
}
function _getSacrificeTxObject(data: txDataObject) {
  const methodData = data.web3Service.moonKnightContract.methods.sacrificeKnight(data.action.knightId).encodeABI();
  return _getTxObject(ENV.CONTRACT.MOONKNIGHT, methodData, data.tx);
}

function _getHeroEquipItemsTxObject(data: txDataObject) {
  const methodData = data.web3Service.moonKnightContract.methods
    .equipItems(data.action.knightId, data.action.itemIds)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.MOONKNIGHT, methodData, data.tx);
}

function _getHeroUnequipItemsTxObject(data: txDataObject) {
  const methodData = data.web3Service.moonKnightContract.methods
    .removeItems(data.action.knightId, data.action.itemIds)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.MOONKNIGHT, methodData, data.tx);
}

function _getApproveTxObject(data: txDataObject) {
  const allowanceAmount = getBiggestNumber();
  const methodData = data.web3Service.faraContract.methods.approve(data.action.spender, allowanceAmount).encodeABI();
  return _getTxObject(data.action.tokenAddress, methodData, data.tx);
}

function _getStakeTxObject(data: txDataObject) {
  const { contract, address } = getStakingContract(data.action.selectedPool);
  const methodData = data.web3Service[contract].methods
    .stake(data.action.knightId, data.action.amount, data.action.lockedMonths)
    .encodeABI();
  return _getTxObject(address, methodData, data.tx);
}

function _getUnstakeTxObject(data: txDataObject) {
  const { contract, address } = getStakingContract(data.action.selectedPool);
  const methodData = data.web3Service[contract].methods.unstake(data.action.knightId, data.action.amount).encodeABI();
  return _getTxObject(address, methodData, data.tx);
}

function _getClaimTxObject(data: txDataObject) {
  const { contract, address } = getStakingContract(data.action.selectedPool);
  const isDeprecated = address === ENV.CONTRACT.DEPRECATED_SINGLE_STAKING;
  let methodData;

  if (isDeprecated) {
    methodData = data.web3Service[contract].methods.claim(data.action.knightId).encodeABI();
  } else {
    methodData = data.web3Service[contract].methods.claimReward(data.action.knightId).encodeABI();
  }

  return _getTxObject(address, methodData, data.tx);
}

function _getClaimItemTxObject(data: txDataObject) {
  const { contract, address } = getStakingContract(data.action.selectedPool);
  const methodData = data.web3Service[contract].methods.claimItems(data.action.knightId).encodeABI();
  return _getTxObject(address, methodData, data.tx);
}

function _getEmergencyWithdrawTxObject(data: txDataObject) {
  const { contract, address } = getStakingContract(data.action.selectedPool);
  const methodData = data.web3Service[contract].methods.emergencyWithdraw().encodeABI();
  return _getTxObject(address, methodData, data.tx);
}

function _getConvertExpTxObject(data: txDataObject) {
  const methodData = data.web3Service.deprecatedStakingContract.methods
    .convertExpToLevels(data.action.knightId, data.action.levelUpAmount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.DEPRECATED_SINGLE_STAKING, methodData, data.tx);
}

function _consumeSoulStoneForExp(data: txDataObject) {
  const methodData = data.web3Service.soulStoneForExpContract.methods
    .consumeSoulStoneForExp(data.action.knightId, data.action.knightType, data.action.amount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.SOUL_STONE_FOR_EXP, methodData, data.tx);
}

function _convertKnightExp(data: txDataObject) {
  let methodData;

  if (data.action.knightType === KNIGHT_TYPE.DEMI) {
    methodData = data.web3Service.expCollectorContract.methods
      .convertExpToDemiKnightLevels(data.action.knightId, data.action.levelUpAmount)
      .encodeABI();
  } else {
    methodData = data.web3Service.expCollectorContract.methods
      .convertExpToKnightLevels(data.action.knightId, data.action.levelUpAmount)
      .encodeABI();
  }

  return _getTxObject(ENV.CONTRACT.EXP_COLLECTOR, methodData, data.tx);
}

function _learnSkillBook(data: txDataObject) {
  let methodData, contract;

  if (data.action.knightType === KNIGHT_TYPE.DEMI) {
    methodData = data.web3Service.demiKnightContract.methods
      .learnSkill(data.action.knightId, data.action.skillId)
      .encodeABI();
    contract = ENV.CONTRACT.DEMI_KNIGHT;
  } else {
    methodData = data.web3Service.moonKnightContract.methods
      .learnSkill(data.action.knightId, data.action.skillId)
      .encodeABI();
    contract = ENV.CONTRACT.MOONKNIGHT;
  }

  return _getTxObject(contract, methodData, data.tx);
}

function _rollGachaTxObject(data: txDataObject) {
  const methodData = data.web3Service.equipmentContract.methods
    .rollEquipmentGacha(data.action.vendorId, data.action.amount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT, methodData, data.tx);
}

function _transferEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentContract.methods
    .safeTransferFrom(data.action.from, data.action.to, data.action.id, data.action.amount, "0x")
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT, methodData, data.tx);
}

function _upgradeEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentContract.methods.upgradeItem(data.action.itemId).encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT, methodData, data.tx);
}

function _listEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentMarketContract.methods
    .list(data.action.itemId, data.action.price, data.action.amount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_MARKET, methodData, data.tx);
}

function _delistEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentMarketContract.methods.delist(data.action.itemId).encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_MARKET, methodData, data.tx);
}

function _buyEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentMarketContract.methods
    .buy(data.action.itemId, data.action.price, data.action.amount, data.action.seller)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_MARKET, methodData, data.tx);
}

function _offerEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentMarketContract.methods
    .offer(data.action.itemId, data.action.price, data.action.amount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_MARKET, methodData, data.tx);
}

function _cancelOfferEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentMarketContract.methods.cancelOffer(data.action.itemId).encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_MARKET, methodData, data.tx);
}

function _takeOfferEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentMarketContract.methods
    .takeOffer(data.action.itemId, data.action.price, data.action.amount, data.action.buyer)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_MARKET, methodData, data.tx);
}

function _listExchangeEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentMarketContract.methods
    .offerExchange(data.action.srcItemId, data.action.destItemIds, data.action.amount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_MARKET, methodData, data.tx);
}

function _cancelExchangeEquipment(data: txDataObject) {
  const methodData = data.web3Service.equipmentMarketContract.methods
    .cancelOfferExchange(data.action.srcItemId)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_MARKET, methodData, data.tx);
}

function _takeEXchangeOffer(data: txDataObject) {
  const methodData = data.web3Service.equipmentMarketContract.methods
    .takeExchangeOffer(data.action.srcItemId, data.action.amount, data.action.seller)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_MARKET, methodData, data.tx);
}

function _increaseStats(data: txDataObject) {
  const methodData = data.web3Service.knightAttributeContract.methods
    .increaseStats(data.action.knightId, data.action.knightType, data.action.stats)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.KNIGHT_ATTRIBUTE, methodData, data.tx);
}

function _listMaterial(data: txDataObject) {
  const methodData = data.web3Service.materialMarketContract.methods
    .list(data.action.mineralId, data.action.price, data.action.amount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.MATERIAL_MARKET, methodData, data.tx);
}

function _delistMaterial(data: txDataObject) {
  const methodData = data.web3Service.materialMarketContract.methods.delist(data.action.mineralId).encodeABI();
  return _getTxObject(ENV.CONTRACT.MATERIAL_MARKET, methodData, data.tx);
}

function _buyMaterial(data: txDataObject) {
  const methodData = data.web3Service.materialMarketContract.methods
    .buy(data.action.mineralId, data.action.price, data.action.amount, data.action.seller)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.MATERIAL_MARKET, methodData, data.tx);
}

function _offerMaterial(data: txDataObject) {
  const methodData = data.web3Service.materialMarketContract.methods
    .offer(data.action.mineralId, data.action.price, data.action.amount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.MATERIAL_MARKET, methodData, data.tx);
}

function _cancelOfferMaterial(data: txDataObject) {
  const methodData = data.web3Service.materialMarketContract.methods.cancelOffer(data.action.mineralId).encodeABI();
  return _getTxObject(ENV.CONTRACT.MATERIAL_MARKET, methodData, data.tx);
}

function _takeOfferMaterial(data: txDataObject) {
  const methodData = data.web3Service.materialMarketContract.methods
    .takeOffer(data.action.mineralId, data.action.price, data.action.amount, data.action.buyer)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.MATERIAL_MARKET, methodData, data.tx);
}

function _transferMaterials(data: txDataObject) {
  const methodData = data.web3Service.materialContract.methods
    .safeTransferFrom(data.action.from, data.action.to, data.action.id, data.action.amount, "0x")
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.MATERIAL, methodData, data.tx);
}

function _craftItem(data: txDataObject) {
  const { itemRecipeId, amount, enhanceMaterialId } = data.action;
  const methodData = data.web3Service.equipmentCraftingContract.methods
    .craftItem(itemRecipeId, amount, enhanceMaterialId)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_CRAFTING, methodData, data.tx);
}

function _levelUpBlacksmith(data: txDataObject) {
  const methodData = data.web3Service.equipmentCraftingContract.methods
    .levelUpBlacksmith(data.action.levelUpAmount)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.EQUIPMENT_CRAFTING, methodData, data.tx);
}

function _upgradeBodyPart(data: txDataObject) {
  const methodData = data.web3Service.knightUpgradeContract.methods
    .upgradeBodyPart(data.action.knightId, data.action.bodyPart)
    .encodeABI();
  return _getTxObject(ENV.CONTRACT.KNIGHT_UPGRADE, methodData, data.tx);
}

function _getTxObject(contractAddress: string, methodData: string, txData: TxData) {
  let txObject: TxObject = {
    from: txData.address,
    to: contractAddress,
    value: txData.value !== undefined ? txData.value : "0x0",
    data: methodData,
    gasPrice: toHex(toWei(DEFAULT_GAS_PRICE)),
  };

  if (txData.nonce !== undefined) txObject.nonce = toHex(txData.nonce);
  if (txData.gas !== undefined) txObject.gas = toHex(txData.gas);

  return txObject;
}
