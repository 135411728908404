import { Stepper, Step, StepLabel } from "@material-ui/core";
import React from "react";
import landFightImg from "src/assets/images/lands/land-fight.png";
export default function MintStepper() {
  return (
    <div className="land__mint-fight">
      <div className="container">
        <Stepper alternativeLabel activeStep={0} className="land__mint-stepper">
          <Step className="land__mint-stepper--step">
            <StepLabel>
              <h2>Phase 1</h2>
              <h1 className="land__mint-stepper--phase">Mint & Trading</h1>
              <p>Unique NFT Lands </p>
              <p>Explore, create, trade, own,</p>
              <p>limitless possibilities.</p>
            </StepLabel>
          </Step>

          <Step className="land__mint-stepper--step">
            <StepLabel>
              <h2>Phase 2</h2>

              <h1 className="land__mint-stepper--phase">Land Wars</h1>
              <p>Compete, strategize, conquer, expand,</p>
              <p>and dominate in</p>
              <p>immersive battles for territory.</p>
            </StepLabel>
          </Step>
        </Stepper>
      </div>
      <img src={landFightImg} alt="Land Fight" className="land__mint-fight--img" />
    </div>
  );
}
