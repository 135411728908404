import { useEffect, useRef } from 'react';

const useUpdateEffect = (callback: any, dependencies: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      callback();
    } else {
      didMount.current = true;
    }
  }, dependencies); // eslint-disable-line
}

export default useUpdateEffect;