import React, { useEffect, useState } from "react";
import { Equipment, Item, SlotEquipment, UserItem } from "src/app/types/equipment";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { convertItemIdToSlotEquipment } from "src/app/utils/converters";
import { useDispatch, useSelector } from "react-redux";
import { queryUserEquipment } from "src/app/services/api/subgraphService";
import { setGlobalModal } from "src/app/actions/globalAction";
import { equipItems, knightRefresh, learnSkillBook, setEquippable, unequipItems } from "src/app/actions/knightAction";
import {
  equipOffChainItems,
  fetchNonFeeInventory,
  generateDemiImage,
  generateKnightImage,
  switchKnightBuilds,
  unequipOffChainItems,
} from "src/app/services/api/faralandService";
import { EQUIPMENT_LABEL, EQUIPMENT_TYPE, KNIGHT_TYPE, NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import { createItem } from "src/app/factories/equipmentFactory";
import { Tab, Tabs, Tooltip } from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import { demiEquipItems, demiUnequipItems } from "src/app/actions/demiAction";
import { renderEmptyInventory } from "src/app/utils/renderHelpers";
import { isDemiInactive, knightMetRequiredStats, signAndAuthenticateWallet } from "src/app/utils/helpers";
import { SkeletonData, Skin, SpinePlayer } from "@esotericsoftware/spine-player";
import ENV from "src/app/configs/env";
import PersonIcon from "@material-ui/icons/Accessibility";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import { Lottie } from "@crello/react-lottie";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";
import { mapChibiRaceValue } from "src/app/utils/mappingHelpers";
import { getTotalPrimaryStats } from "../utils/helpers";
import _ from "lodash";
import { Knight, KnightStats } from "src/app/types/knight";
import { DemiKnight } from "src/app/types/demiKnight";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

const useKnightPreview = (
  knight: Knight | DemiKnight,
  slotItems: any,
  setSlotItems: any,
  isOwner: boolean,
  isDemi: boolean,
  knightStats: KnightStats,
  isOffChain: boolean
) => {
  const dispatch = useDispatch();
  const { address, wallet } = useSelector((state: any) => state.account);
  const { equippable, refresh } = useSelector((state: any) => state.knight);

  const [tabValue, setTabValue] = useState(0);
  const [equippedItems, setEquippedItems] = useState<number[]>([]);
  const [equippingItems, setEquippingItems] = useState<number[]>([]);
  const [unequippingItems, setUnequippingItems] = useState<number[]>([]);
  const [userItems, setUserItems] = useState<UserItem[]>([]);
  const [inventoryItems, setInventoryItems] = useState<UserItem[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [slotEquipments, setSlotEquipments] = useState<SlotEquipment[]>([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [refreshingCount, setRefreshingCount] = useState(+new Date());
  const [chibiImage, setChibiImage] = useState(false);
  const [chibiPlayer, setChibiPlayer] = useState<SpinePlayer>();
  const [isLoading, setLoading] = useState(true);
  const equippingItemCount = equippingItems.length;
  const unequippingItemCount = unequippingItems.length;
  const [firstRun, setFirstRun] = useState(true);
  const [prevBuildStatus, setPrevBuildStatus] = useState<boolean>();

  useEffect(() => {
    if (isOffChain === prevBuildStatus) return;
    let equipmentIds: number[] = [];
    if (knight && knight.equipments && knight.equipments.length) {
      knight.equipments.forEach((item: any) => {
        equipmentIds[item.slot] = +item.id;
      });
    }
    if (firstRun) {
      setSlotItems(equipmentIds);
    }
    setEquippedItems([...equipmentIds]);
  }, [isOffChain, knight.equipments]); // eslint-disable-line

  useEffect(() => {
    if (chibiPlayer) {
      chibiPlayer.dispose();
      setLoading(true);
    }

    const player = new SpinePlayer("chibi-container", {
      jsonUrl: `${ENV.URL.FARALAND_GITHUB_ASSET}/Chibis/${mapChibiRaceValue(
        knight.displayRace
      )}_${knight.gender.substring(0, 1)}/${mapChibiRaceValue(knight.displayRace)}_${knight.gender.substring(
        0,
        1
      )}.json`,
      atlasUrl: `${ENV.URL.FARALAND_GITHUB_ASSET}/Chibis/${mapChibiRaceValue(
        knight.displayRace
      )}_${knight.gender.substring(0, 1)}/${mapChibiRaceValue(knight.displayRace)}_${knight.gender.substring(
        0,
        1
      )}.atlas`,
      animation: getWeaponAnimType(slotItems),
      alpha: true,
      showControls: false,
      showLoading: false,
      preserveDrawingBuffer: true,
      success: function (player: SpinePlayer) {
        equipChibiSkins(player, slotItems);
        setLoading(false);
      },
    });
    setChibiPlayer(player);
  }, [slotItems]); //eslint-disable-line

  useEffect(() => {
    fetchUserOwnEquipment();
  }, [knight.id, address, isOffChain]); // eslint-disable-line

  useEffect(() => {
    const equipments: SlotEquipment[] = convertItemIdToSlotEquipment(slotItems);
    setSlotEquipments(equipments);
  }, [slotItems]); // eslint-disable-line

  useEffect(() => {
    setFirstRun(false);
    let newEquippingItems: number[] = [];
    let newUnequippingItems: number[] = [];
    slotItems.forEach((item: any, index: number) => {
      if (item) {
        if (!equippedItems[index]) {
          newEquippingItems.push(+item);
        } else if (equippedItems[index] !== item) {
          newEquippingItems.push(+item);
          newUnequippingItems.push(+equippedItems[index]);
        }
      } else if (equippedItems[index]) {
        newUnequippingItems.push(+equippedItems[index]);
      }
    });
    setEquippingItems([...newEquippingItems]);
    setUnequippingItems([...newUnequippingItems]);
  }, [slotItems]); // eslint-disable-line

  useEffect(() => {
    const userItemObj: any = {};
    const items: Item[] = [];

    userItems.forEach((item: UserItem) => {
      userItemObj[item.item.id] = { ...item };
    });
    if (userItems.length) {
      equippingItems.forEach((equipItemId: number) => {
        userItemObj[equipItemId].available =
          !userItemObj[equipItemId]?.available || userItemObj[equipItemId]?.available === 0
            ? 0
            : userItemObj[equipItemId]?.available - 1;
      });

      unequippingItems.forEach((removeItemId: number) => {
        userItemObj[removeItemId].available = (userItemObj[removeItemId]?.available ?? 0) + 1;
      });
    }

    setInventoryItems(Object.values(userItemObj));
    Object.values(userItemObj).forEach((item: any) => {
      items.push(EQUIPMENT[item.item.id]);
    });
    setItems(items);
  }, [userItems, equippingItems, unequippingItems]); // eslint-disable-line

  const equipChibiSkins = (player: SpinePlayer, items: any) => {
    if (player.skeleton === null) return;

    const skeletonData: SkeletonData = player.skeleton.data;
    let newSkin = new Skin("full-set");
    const skins = getSkins(items);
    skins.forEach((s) => {
      const skin = skeletonData.findSkin(s);
      if (skin !== null) {
        newSkin.addSkin(skin);
      }
    });
    player.skeleton.setSkin(newSkin);
  };

  const _convertSlotToItemType = (slot: number) => {
    switch (slot) {
      case 0:
      case 1:
        return "weapon";
      case 2:
        return "headgear";
      case 3:
        return "armor";
      case 4:
        return "footwear";
      case 5:
        return "pants";
      case 6:
        return "gloves";
      default:
        return "";
    }
  };

  const getSkins = (items: any) => {
    if (items.length === 0 || items.filter((item) => item !== undefined).length === 0) {
      return ["armor/0", "footwear/0", "pants/0", "gloves/0"];
    }
    const equippedItems = items.map((item, index) => {
      return {
        id: item,
        slot: _convertSlotToItemType(index),
      };
    });
    return equippedItems
      .filter((item) => {
        return item.slot;
      })
      .map((item) => {
        return `${item.slot}/${item.id ?? 0}`;
      });
  };

  const getWeaponAnimType = (items: any) => {
    const mainWeapon = items[EQUIPMENT_TYPE.MAIN_WEAPON];
    if (!mainWeapon) return "no_weapon/idle";
    const item: Item = EQUIPMENT[mainWeapon];
    return `${item.animType}/idle`;
  };

  async function fetchUserOwnEquipment() {
    let result;
    if (!knight.id || !address) return setUserItems([]);
    if (isOffChain) {
      result = await fetchNonFeeInventory(NON_FEE_INVENTORY_TYPE.ITEM, address);
    } else {
      result = await queryUserEquipment(address);
    }
    if (result) {
      setUserItems(result.equipment);
    }
  }

  const handleTabChange = (_e: any, newValue: number) => {
    setTabValue(newValue);
  };

  async function onSelectItem(inventoryItem: UserItem) {
    const item = inventoryItem.item;
    const newArraySlotItem = slotItems;
    newArraySlotItem[item.slot] = item.id;

    const isMainWeapon2Handed = EQUIPMENT[slotItems[EQUIPMENT_TYPE.MAIN_WEAPON]]?.twoHanded;
    if (item.slot === EQUIPMENT_TYPE.SUB_WEAPON && isMainWeapon2Handed) {
      newArraySlotItem[EQUIPMENT_TYPE.MAIN_WEAPON] = undefined;
    } else if (item.slot === EQUIPMENT_TYPE.MAIN_WEAPON && isMainWeapon2Handed) {
      newArraySlotItem[EQUIPMENT_TYPE.SUB_WEAPON] = undefined;
    }
    setSlotItems([...newArraySlotItem]);
  }

  function onRemoveSlotItem(slotIndex: number) {
    const newArraySlotItem = slotItems;
    newArraySlotItem[slotIndex] = undefined;
    setSlotItems([...newArraySlotItem]);
  }

  async function handleOffChainItems(isEquip: boolean, heroId: number, itemIds: number[], isDemi = false) {
    const res = isEquip
      ? await equipOffChainItems(heroId, itemIds, isDemi)
      : await unequipOffChainItems(heroId, itemIds, isDemi);
    if (res === 401) {
      const response = await signAndAuthenticateWallet(wallet, address);
      if (response.success) {
        handleOffChainItems(isEquip, heroId, itemIds, isDemi);
      } else {
        dispatch(
          setGlobalModal("error", {
            active: true,
            data: <div>Unable to authenticate your wallet. Please try again</div>,
          })
        );
      }
      return;
    }
    if (res.message === "Success") {
      refreshKnightImage(isEquip);
      dispatch(knightRefresh(!refresh));
      setFirstRun(false);
    }
  }

  function handleEquipItems(isEquip: boolean) {
    if (knight.price) {
      dispatch(
        setGlobalModal("error", {
          active: true,
          data: "Cannot equip items for Hero that is currently listed on the marketplace, you need to delist first.",
        })
      );
      return;
    } else if (isDemi && isEquip) {
      const { isExpired } = isDemiInactive((knight as DemiKnight).lifespan);
      if (isExpired) {
        dispatch(
          setGlobalModal("error", {
            active: true,
            data: "Cannot equip items for Hero that is currently inactive, you need to wake him up first.",
          })
        );
        return;
      }
    }

    const callRefreshImage = () => {
      refreshKnightImage(isEquip);
      dispatch(knightRefresh(!refresh));
      setFirstRun(false);
    };
    if (isEquip) {
      if (isDemi) {
        isOffChain
          ? handleOffChainItems(isEquip, knight.id, equippingItems, true)
          : dispatch(demiEquipItems(+knight.id, equippingItems, callRefreshImage));
      } else {
        isOffChain
          ? handleOffChainItems(isEquip, knight.id, equippingItems)
          : dispatch(equipItems(+knight.id, equippingItems, callRefreshImage));
      }
    } else {
      if (isDemi) {
        isOffChain
          ? handleOffChainItems(isEquip, knight.id, unequippingItems, true)
          : dispatch(demiUnequipItems(+knight.id, unequippingItems, callRefreshImage));
      } else {
        isOffChain
          ? handleOffChainItems(isEquip, knight.id, unequippingItems)
          : dispatch(unequipItems(+knight.id, unequippingItems, callRefreshImage));
      }
    }
  }

  function learnSkill(inventoryItem: UserItem) {
    dispatch(
      learnSkillBook(+knight.id, isDemi ? KNIGHT_TYPE.DEMI : KNIGHT_TYPE.IMMORTAL, inventoryItem.item.id, () =>
        refreshKnightImage()
      )
    );
    return;
  }

  async function swapBuilds() {
    setFirstRun(true);
    setPrevBuildStatus(isOffChain);
    const signData = await wallet.signData(
      address,
      { knightId: knight.id },
      {
        Group: [{ name: "knightId", type: "uint256" }],
      }
    );
    await switchKnightBuilds(knight.id, signData.sign, signData.msgParams, isDemi);
    await refreshKnightImage();
    dispatch(knightRefresh(!refresh));
  }
  async function refreshKnightImage(isEquip?: boolean) {
    setRefreshing(true);

    let result;
    if (isDemi) {
      result = await generateDemiImage(knight.id.toString());
    } else {
      result = await generateKnightImage(knight.id.toString());
    }

    if (result) {
      setRefreshing(false);
      setRefreshingCount(refreshingCount + 1);
      let itemsToUpdate: UserItem[];
      if (isEquip !== undefined) {
        if (isEquip) {
          itemsToUpdate = equippingItems.map((id) =>
            inventoryItems.find((inventoryItem) => inventoryItem.id === id)
          ) as UserItem[];
          setEquippingItems([]);
        } else {
          itemsToUpdate = unequippingItems.map((id) =>
            inventoryItems.find((inventoryItem) => inventoryItem.id === id)
          ) as UserItem[];
          setUnequippingItems([]);
        }
        const newUserItems = [...userItems];
        if (itemsToUpdate.length) {
          itemsToUpdate.forEach((updatedItem) => {
            const changedItem = newUserItems.find((item) => item.id === updatedItem.id);
            if (changedItem) {
              newUserItems.splice(newUserItems.indexOf(changedItem), 1, updatedItem);
            }
          });
        }
        setUserItems(newUserItems);
      }
    } else {
      setTimeout(() => {
        refreshKnightImage(isEquip);
      }, 2000);
    }
  }

  function toggleEquippable() {
    dispatch(setEquippable(!equippable));
  }

  function getEquipErrorText(item: Equipment, available: number | null, itemEquippable = true): string {
    const equipment: Item = EQUIPMENT[item.id];
    const totalStats = getTotalPrimaryStats(knightStats, true);
    let error = "";
    if (equipment && knight) {
      const supportedRace = equipment.races.find((race) => race === knight.race);
      if (!supportedRace) {
        error += `<div>- This item can only be equipped on ${equipment.races.join(", ")}.</div>`;
      }

      const supportedGender = equipment.genders.find((gender) => gender === knight.gender);
      if (!supportedGender) {
        error += `<div>- This item can only be equipped on ${equipment.genders.toString()}.</div>`;
      }

      if (equipment.requiredLevel > knight.level) {
        error += `<div>- Required level: ${equipment.requiredLevel}.</div>`;
      }

      if (
        !itemEquippable &&
        EQUIPMENT[slotItems[EQUIPMENT_TYPE.MAIN_WEAPON]]?.twoHanded &&
        equipment.type === EQUIPMENT_TYPE.SUB_WEAPON
      ) {
        error += `<div>- Stats from this Sub Weapon will not be calculated since you already equipped a two-handed Main Weapon.</div>`;
      }
    }

    if (!itemEquippable && error === "" && available === 0) {
      error += `<div>- Unavailable item.</div>`;
    }

    if (!(_.isEmpty(equipment.requiredStats) || knightMetRequiredStats(totalStats, equipment.requiredStats))) {
      error += `<div>- You don't have the required base stats to equip this item.</div>`;
    }
    return error;
  }

  function renderInventoryItems(isSkillsTab: boolean) {
    if (inventoryItems.length > 0) {
      let itemsToRender = inventoryItems.filter((inventoryItem: UserItem) => {
        const skillItems = items
          .filter((item: Item) => {
            return item && EQUIPMENT_LABEL[item.type] === "Skill Book";
          })
          .map((item: Item) => {
            return item.id;
          });
        return isSkillsTab ? skillItems.includes(inventoryItem.item.id) : !skillItems.includes(inventoryItem.item.id);
      });

      if (itemsToRender.length > 0) {
        let filteredItems: UserItem[] = itemsToRender.filter((item) => item.amount > 0);

        if (equippable) {
          filteredItems = itemsToRender.filter((inventoryItem) => {
            const error = getEquipErrorText(inventoryItem.item, inventoryItem.available);
            return error === "";
          });
        }

        return filteredItems.map((inventoryItem: UserItem, index) => {
          const error = getEquipErrorText(inventoryItem.item, inventoryItem.available);

          return (
            <div className={inventoryItem.available === 0 ? "low-opacity" : ""} key={index}>
              <ItemIcon
                userItem={inventoryItem}
                item={inventoryItem.item}
                slotEquipments={slotEquipments}
                onSubmit={() => (isSkillsTab ? learnSkill(inventoryItem) : onSelectItem(inventoryItem))}
                showBalance={true}
                submitText={isSkillsTab ? "Learn" : "Equip"}
                isKnightPreview={true}
                closeOnSubmit={true}
                error={error}
                isComparisonTooltip={!isSkillsTab}
                knightStats={knightStats}
              />
            </div>
          );
        });
      } else {
        return renderEmptyInventory(14);
      }
    } else {
      return renderEmptyInventory(14);
    }
  }

  function renderAllSlotItems() {
    return (
      <>
        <div className="knight-preview__wrapper-left">
          {renderSlotItem(EQUIPMENT_TYPE.MAIN_WEAPON, "right")}
          {renderSlotItem(EQUIPMENT_TYPE.ARMOR, "right")}
          {renderSlotItem(EQUIPMENT_TYPE.PANTS, "right")}
          {renderSlotItem(EQUIPMENT_TYPE.FOOTWEAR, "right")}
        </div>
        <div className="knight-preview__wrapper-right">
          {renderSlotItem(EQUIPMENT_TYPE.SUB_WEAPON, "left")}
          {renderSlotItem(EQUIPMENT_TYPE.HEADGEAR, "left")}
          {renderSlotItem(EQUIPMENT_TYPE.GLOVES, "left")}
          {renderSlotItem(EQUIPMENT_TYPE.MOUNT, "left")}
          {renderSlotItem(EQUIPMENT_TYPE.MEDAL, "left")}
        </div>
      </>
    );
  }

  function renderSlotItem(index: number, tooltipPosition: "left" | "right") {
    const itemInSlotId = slotItems[index];
    const item = EQUIPMENT[itemInSlotId];
    const slotLabel = EQUIPMENT_LABEL[index];

    if (item) {
      const error = getEquipErrorText(item, null, false);

      return (
        <div className="knight-preview__wrapper-itemSlot" key={index}>
          <ItemIcon
            item={createItem(item)}
            slotEquipments={slotEquipments}
            onSubmit={isOwner ? () => onRemoveSlotItem(index) : undefined}
            showBalance={false}
            submitText="Remove"
            isKnightPreview={true}
            closeOnSubmit={true}
            error={error}
            knightStats={knightStats}
          />
        </div>
      );
    } else {
      return (
        <Tooltip title={slotLabel} placement={tooltipPosition} key={index}>
          <div className="knight-preview__wrapper-itemSlot">
            <img
              className="knight-preview__wrapper-itemSlot--bg"
              src={require(`src/assets/images/hero-details/slot/slot-${index}.png`)}
              alt=""
            />
          </div>
        </Tooltip>
      );
    }
  }

  function renderInventory() {
    return (
      <div className="knight-preview__inventory">
        <div className="knight-preview__inventory-title">
          <p>Inventory</p>
          <Tooltip title="Refresh Hero Image" placement="top" arrow>
            <div
              className={`btn knight-preview__inventory-icon ${isRefreshing ? "disabled" : ""}`}
              onClick={() => refreshKnightImage()}
            >
              <SyncIcon className={`${isRefreshing ? "spinning" : ""}`} />
            </div>
          </Tooltip>
          <Tooltip
            title={equippable ? "Click to Show All Items" : "Click to show Equippable Items"}
            placement="top"
            arrow
          >
            <div
              className={`btn knight-preview__inventory-icon equippable ${equippable ? "on" : ""}`}
              onClick={toggleEquippable}
            />
          </Tooltip>
          <Tooltip title={`Swap to ${isOffChain ? "On-Chain" : "Off-Chain"}`} placement="top" arrow>
            <div className={`btn knight-preview__inventory-icon swap ${isOffChain ? "on" : ""}`} onClick={swapBuilds}>
              <SwapHorizIcon />
            </div>
          </Tooltip>
          {unequippingItemCount > 0 && (
            <div className="btn btn--small" onClick={() => handleEquipItems(false)}>
              Unequip {unequippingItemCount} Items
            </div>
          )}
          {equippingItemCount > 0 && (
            <div
              className={`btn btn--small ${unequippingItemCount > 0 ? "disabled" : ""}`}
              onClick={() => handleEquipItems(true)}
            >
              Equip {equippingItemCount} Items
            </div>
          )}
        </div>
        {!isDemi && (
          <Tabs value={tabValue} onChange={handleTabChange} className="knight-preview__tab" variant="fullWidth">
            <Tab
              label={
                <p className={isOffChain ? `success-text fs-3` : ""}>
                  <span>{isOffChain ? "Non-fee" : ""}</span> EQUIPMENT
                </p>
              }
            />
            <Tab label="Skills" />
          </Tabs>
        )}
        <div
          className={`inventory nice-scroll nice-scroll--small ${
            inventoryItems.length === 0 ? "inventory--small" : ""
          }`}
        >
          {renderInventoryItems(Boolean(tabValue))}
        </div>
      </div>
    );
  }

  function renderImageButton() {
    return (
      <Tooltip title={chibiImage ? "Show NFT Image" : "Show In-Game Character"} placement="top" arrow>
        <div
          className={`btn knight-preview__wrapper-btn ${chibiImage && "btn--active"}`}
          onClick={() => setChibiImage(!chibiImage)}
        >
          <PersonIcon />
        </div>
      </Tooltip>
    );
  }

  function renderChibi() {
    return (
      <>
        {chibiImage && isLoading && (
          <Lottie
            className="market__loading market__loading--chibi slide-up"
            config={getAnimatedJsonOptions(loadingJson)}
          />
        )}
        <div id="chibi-container" className={`knight-preview__chibi ${chibiImage && !isLoading ? "" : "hidden"}`} />
      </>
    );
  }

  return [
    refreshingCount,
    renderAllSlotItems as any,
    renderInventory as any,
    renderChibi as any,
    renderImageButton as any,
    chibiImage,
  ];
};

export default useKnightPreview;
