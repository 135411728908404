import React from "react";
import {
  TableCell,
  TableRow,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core";

import { Transaction } from "src/app/types/demiKnight";

import MarketTable from "src/app/components/Commons/MarketTable";

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#111121",
      color: theme.palette.common.white,
      fontFamily: `"American Captain", sans-serif`,
      textTransform: "uppercase",
      fontWeight: 500,
      fontSize: 18,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

type TransactionHistoryProps = {
  transactions: Transaction[];
};

export default function DemiHistoryTable(props: TransactionHistoryProps) {
  const transactions = props.transactions;
  const isEmpty = transactions.length === 0;
  return (
    <div className={`cm-table ${isEmpty ? "cm-table--empty" : ""} mt-7`}>
      <div className="cm-table__title">Sale History</div>
      <MarketTable
        headerTitles={["Action", "Price", "From", "To", "Time"]}
        cellTypes={["action", "price", "address", "address", "time"]}
        cellKeys={["type", "price", "from", "to", "timestamp"]}
        isEmpty={isEmpty}
        orderData={props.transactions}
        emptyMessage="No History Yet"
        isMyOrder={false}
        myOrderData={false}
        bnbToken
      />
    </div>
  );
}
