import * as React from "react";

type TitleProps = {
  value: string
  style?: any
}
const Title = (props: TitleProps) => {
  return (
    <div className="instructions-title" style={props.style}>
      <h1>{props.value}</h1>
    </div>
  );
};

export default Title;
