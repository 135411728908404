import React, { useEffect, useState } from "react";
import { formatNumber } from 'src/app/utils/helpers';
import { SummaryData } from "src/app/types/common";
import { createSummaryData } from "src/app/factories/commonFactory";
import { querySummary } from "src/app/services/api/subgraphService";

const DemiSummary = () => {
  const [summary, setSummary] = useState<SummaryData>(createSummaryData(false));

  useEffect(() => {
    getTotalInfo();
  }, []);

  async function getTotalInfo() {    
    const result = await querySummary();
    if (result) setSummary(result);
  }
  
  return (
    <div className="data-summary">
      <div className="data-summary__container">
        <div>
          <div className="data-summary__title">
            Demis Sold
          </div>
          <div className="data-summary__value">
            {summary.demiCount ? `${formatNumber(summary.demiCount)}` : "--/--"}
          </div>
        </div>
        <div>
          <div color="primary" className="data-summary__title">
            Total volume
          </div>
          <div className="data-summary__value">
            {+summary.demiVolume ? `${formatNumber(summary.demiVolume)} FARA` : "--/--"}
          </div>
        </div>
        <div>
          <div color="primary" className="data-summary__title">
            Highest price
          </div>
          <div className="data-summary__value">
            {summary.highestDemiPrice ? `${formatNumber(summary.highestDemiPrice)} FARA` : "--/--"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemiSummary;
