import { Box, TextField } from "@material-ui/core";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { GACHA_COST, INTERVAL, NON_FEE_INVENTORY_TYPE, RARITY_LABEL, ROUTE } from "src/app/configs/constants";
import { UserItem } from "src/app/types/equipment";
import { formatNumber, getEquipmentImageURL } from "src/app/utils/helpers";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import faraLogo from "src/assets/images/tokens/fara.png";
import soulStoneLogo from "src/assets/images/tokens/soulstone.png";
import { compareNumbers } from "src/app/utils/calculators";
import { ItemsInPoolGacha } from "src/app/types/gacha";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { renderRarityFilter, renderTierFilter } from "src/app/utils/renderHelpers";
import { fetchNonFeeBalance, fetchNonFeeInventory } from "src/app/services/api/faralandService";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";

interface ChoosingEquipmentFilter {
  name: string;
  tier: number;
  rarity: string;
}

interface ChoosingEquipmentModalProps {
  poolItems: ItemsInPoolGacha[];
  onItemSelected: (itemId: number | undefined) => void;
  filterCriteria: ChoosingEquipmentFilter;
  setFilterCriteria: (filterObj: ChoosingEquipmentFilter) => void;
}

export default function ChoosingEquipmentModal(props: ChoosingEquipmentModalProps) {
  const { poolItems, onItemSelected, filterCriteria, setFilterCriteria } = props;

  const { address } = useSelector((state: any) => state.account);
  const [userItems, setUserItems] = useState<UserItem[]>([]);
  const [filteredItems, setFilteredItems] = useState<UserItem[]>([]);
  const [filterObj, setFilterObj] = useState(filterCriteria);
  const [itemLoading, setItemLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<UserItem>();
  const [error, setError] = useState("");
  const [faraBalance, setFaraBalance] = useState("0");
  const [soulstoneBalance, setSoulstoneBalance] = useState("0");
  const balanceInterval = useRef<any>();
  const debounceFilterItems = useCallback(_.debounce(filterItemsByName, 500), [filteredItems]);

  useEffect(() => {
    if (!address) {
      setUserItems([]);
      return;
    }
    async function fetchGachaBalance(address: string) {
      const nonFeeWalletBalance = await fetchNonFeeBalance(address);
      if (nonFeeWalletBalance && nonFeeWalletBalance.success) {
        const nonFeeFaraBalance = nonFeeWalletBalance.totalFara;
        const nonFeeSoulStoneBalance = nonFeeWalletBalance.totalSoulstone;
        setFaraBalance(nonFeeFaraBalance);
        setSoulstoneBalance(nonFeeSoulStoneBalance);
      }
    }

    _fetchUserOwnEquipment(address);
    fetchGachaBalance(address);
    balanceInterval.current = setInterval(() => {
      fetchGachaBalance(address);
    }, INTERVAL.BALANCE);

    return () => {
      clearInterval(balanceInterval.current);
    };
  }, [address]); // eslint-disable-line

  useEffect(() => {
    const result = userItems.filter((ui) => {
      return (
        ui.item.displayName.toLowerCase().includes(filterObj.name.toLowerCase()) &&
        EQUIPMENT[ui.id].tier === filterObj.tier &&
        RARITY_LABEL[ui.item.rarity].includes(filterObj.rarity)
      );
    });
    setFilteredItems(result);
    setFilterCriteria(filterObj);
  }, [userItems, filterObj]); // eslint-disable-line

  async function _fetchUserOwnEquipment(address: string) {
    setItemLoading(true);

    const result = await fetchNonFeeInventory(NON_FEE_INVENTORY_TYPE.ITEM, address);
    if (result) {
      result.equipment = _.sortBy(result.equipment as UserItem[], (item) => EQUIPMENT[item.id].rarity);
      const availableItems = result.equipment.filter((inventoryItem: UserItem) => {
        return inventoryItem.available > 0;
      });
      setUserItems(availableItems);
      setFilteredItems(availableItems);
    }

    setItemLoading(false);
  }

  function filterItemsByName(name: string) {
    setFilterObj({ ...filterObj, name: name });
  }

  function filterItemsByTier(tier: number) {
    setFilterObj({ ...filterObj, tier: tier });
  }

  function filterItemsByRarity(value: any) {
    setFilterObj({ ...filterObj, rarity: value === "All" ? "" : value });
  }

  function onSubmit() {
    onItemSelected(selectedItem?.id);
    modalService.close();
  }

  function getSubmitText() {
    return "Confirm";
  }

  function isSubmitDisabled() {
    return Boolean(error);
  }

  function getGachaCost(rarity: number) {
    switch (rarity) {
      case 0:
      case 1:
        return {
          fara: 4,
          soulstone: 4,
        };
      case 2:
        return {
          fara: 8,
          soulstone: 8,
        };
      default:
        return {
          fara: GACHA_COST.FARA,
          soulstone: GACHA_COST.SS,
        };
    }
  }
  function chooseItem(item: UserItem) {
    setSelectedItem(item);
    setError("");
    const gachaCosts = getGachaCost(item.item.rarity);
    if (compareNumbers(faraBalance, gachaCosts.fara) === -1) {
      setError("Insufficient FARA Balance.");
    } else if (+soulstoneBalance < gachaCosts.soulstone) {
      setError("Insufficient Soul Stone Balance.");
    }
  }

  function getRarityInPool(rarity: number, previous: boolean, increment: number): number {
    while (true) {
      rarity += previous ? -increment : increment;
      if (rarity < 0 || rarity === poolItems.length) return rarity;
      const value = rarity;
      if (_.some(poolItems, (item) => EQUIPMENT[item.id].rarity === value)) {
        return value;
      }
    }
  }

  return (
    <BasicModalContent
      onSubmit={onSubmit}
      submitText={getSubmitText()}
      isSubmitDisabled={isSubmitDisabled()}
      content={
        <>
          <div className="axe__choosing-item">
            {selectedItem ? (
              <div className="axe__choosing-item-item">
                <img
                  style={{ maxWidth: 70 }}
                  className={`item-icon__image axe__choosing-item-item--image`}
                  src={getEquipmentImageURL(selectedItem.item.id, "178x178")}
                  alt=""
                />
                <div className="axe__choosing-item-item--name">{selectedItem.item.displayName}</div>
              </div>
            ) : (
              <div className="axe__content-right--slot">
                <div>+</div>
              </div>
            )}
            <TextField
              className="input item-crafting__recipes-search mt-4"
              label="Search"
              placeholder="Search..."
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              onChange={(e) => debounceFilterItems(e.target.value)}
              fullWidth
            />
            <Box display="flex" justifyContent="space-between" alignContent="center" className="mt-4" gridGap={1}>
              {renderRarityFilter(filterObj, filterItemsByRarity)}
              {renderTierFilter(filterObj, filterItemsByTier)}
            </Box>
          </div>
          <div className="mt-6">
            {filteredItems.length > 0 && !itemLoading ? (
              <div className="inventory nice-scroll nice-scroll--small axe__choosing-item-inventory">
                {filteredItems.map((userItem: UserItem, index: number) => {
                  return (
                    <ItemIcon
                      key={index}
                      userItem={userItem}
                      item={userItem.item}
                      amount={userItem.available}
                      closeOnSubmit={true}
                      hideSelectedBorder
                      submitText="Detail"
                      onClick={() => chooseItem(userItem)}
                      className="axe__choosing-item-inventory-item"
                    />
                  );
                })}
              </div>
            ) : (
              <div className="mt-3 text-gray">
                {itemLoading ? "Loading..." : address ? "No equipment found." : "Please connect your wallet first."}
              </div>
            )}
          </div>

          {selectedItem && (
            <>
              <div className="dashed-title mt-2">
                <span className="axe__content-right--rarity">
                  <b>You will receive</b>
                </span>
              </div>
              <div className="fs-4 mb-1 ml-10 mr-10" style={{ color: "#C4C4C4" }}>
                <Box display="flex">
                  <Box flex="1" alignSelf="center" className="ml-1" style={{ textAlign: "left" }}>
                    {RARITY_LABEL[getRarityInPool(selectedItem.item.rarity, true, 1)] ?? "Loss"}
                  </Box>
                  <Box alignSelf="center">
                    <b>20%</b>
                  </Box>
                </Box>
              </div>
              <div className="fs-4 mb-1 ml-10 mr-10" style={{ color: "#C4C4C4" }}>
                <Box display="flex">
                  <Box flex="1" alignSelf="center" className="ml-1" style={{ textAlign: "left" }}>
                    {RARITY_LABEL[selectedItem.item.rarity]}
                  </Box>
                  <Box alignSelf="center">
                    <b>
                      {selectedItem.item.rarity === RARITY_LABEL.length - 1 ||
                      getRarityInPool(selectedItem.item.rarity, false, 1) - selectedItem.item.rarity > 1
                        ? "80%"
                        : RARITY_LABEL[selectedItem.item?.rarity ?? 0] === "Epic"
                        ? "75%"
                        : "70%"}
                    </b>
                  </Box>
                </Box>
              </div>
              {(selectedItem.item?.rarity ?? 0) + 1 < RARITY_LABEL.length &&
                getRarityInPool(selectedItem.item.rarity, false, 1) - selectedItem.item.rarity === 1 && (
                  <div className="fs-4 mb-1 ml-10 mr-10" style={{ color: "#C4C4C4" }}>
                    <Box display="flex">
                      <Box flex="1" alignSelf="center" className="ml-1" style={{ textAlign: "left" }}>
                        {RARITY_LABEL[getRarityInPool(selectedItem.item.rarity, false, 1)]}
                      </Box>
                      <Box alignSelf="center">
                        <b>
                          {RARITY_LABEL[(selectedItem.item?.rarity ?? 0) + 1] === "Legendary"
                            ? "3%"
                            : RARITY_LABEL[(selectedItem.item?.rarity ?? 0) + 1] === "Epic"
                            ? "9.5%"
                            : "10%"}
                        </b>
                      </Box>
                    </Box>
                  </div>
                )}
              {["Rare", "Epic"].includes(RARITY_LABEL[selectedItem.item?.rarity ?? 0]) && (
                <div className="fs-4 ml-10 mr-10" style={{ color: "#C4C4C4" }}>
                  <Box display="flex">
                    <Box flex="1" alignSelf="center" className="ml-1" style={{ textAlign: "left" }}>
                      {RARITY_LABEL[getRarityInPool(selectedItem.item.rarity, false, 2)]}
                    </Box>
                    <Box alignSelf="center">
                      <b>{RARITY_LABEL[(selectedItem.item?.rarity ?? 0) + 2] === "Legendary" ? "0.5%" : "2%"}</b>
                    </Box>
                  </Box>
                </div>
              )}

              <div className="item-crafting__materials-cost align-center mt-2">
                <span>Cost: </span>
                <span className="text-9">{getGachaCost(selectedItem.item.rarity).fara} FARA</span>
                <img className="token token--small top-5 ml-1" src={faraLogo} alt="" />
                <span> + </span>
                <span className="text-9">{getGachaCost(selectedItem.item.rarity).soulstone} Soul Stones</span>
                <img className="token token--small top-5 ml-1" src={soulStoneLogo} alt="" />
              </div>
              {error && <div className="fs-3 error-text align-center">{error}</div>}
            </>
          )}
          <div className="item-crafting__materials-cost align-center mt-2">
            <span>Balance: </span>
            <span className="text-gray">
              {formatNumber(faraBalance, 2)} FARA & {formatNumber(soulstoneBalance)} Soul Stones
            </span>
          </div>
          <div className="market__items--black-market mt-1 align-center fs-3 warning-text">
            Tokens need to be deposited to non-fee wallet first before they appear above
          </div>
          <div className="market__items--black-market fs-3 mb-3 align-center">
            <a href={`${ROUTE.WALLET}?owned=true&sortBy=Highest%20Level&page=1`} className="market__links">
              Click here
            </a>{" "}
            to deposit them!
          </div>
        </>
      }
    />
  );
}
