import React, { useState } from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import Modal from "src/app/components/Commons/Modals/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalModal } from "src/app/actions/globalAction";
import { Item } from "src/app/types/equipment";
import SwapIcon from "@material-ui/icons/SwapVerticalCircle";
import SearchIcon from "@material-ui/icons/Search";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { cancelExchange, listExchange } from "src/app/actions/equipmentAction";
import { ITEM_EXCHANGE_FEE } from "src/app/configs/constants";
import faraLogo from "src/assets/images/tokens/fara.png";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import ENV from "src/app/configs/env";
import { roundNumber } from "src/app/utils/helpers";
import { compareNumbers } from "src/app/utils/calculators";

export default function EquipmentExchangeModal() {
  const dispatch = useDispatch();
  const { modal } = useSelector((state: any) => state.global);
  const { balance } = useSelector((state: any) => state.account);
  const [searchName, setSearchName] = useState("");
  const [destItemIds, setDestItemIds] = useState<number[]>([]);
  const [balanceError, setBalanceError] = useState("");
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.EQUIPMENT_MARKET);

  const exchangeModal = modal.exchangeItem;
  const modalData = exchangeModal.data ?? {};
  const item: Item = modalData.item;
  const filteredItems = filterEquipment();

  function handleClose() {
    dispatch(setGlobalModal("exchangeItem"));
    setSearchName("");
    setBalanceError("");
    setDestItemIds([]);
  }

  function filterEquipment(): any[] {
    if (!item) return [];

    return  Object.values(EQUIPMENT).filter((value: any) => {
      const itemCondition = value.id !== 0 && value.id !== item.id;
      const rarityCondition = value.rarity <= item.rarity;
      const tierCondition = value.rarity === item.rarity ? value.tier <= item.tier : true;
      const searchCondition = searchName === "" ? true : value.displayName.toLowerCase().includes(searchName.toLowerCase());

      return itemCondition && tierCondition && rarityCondition && searchCondition;
    });
  }

  function handleSearchNameChange(e: any) {
    setBalanceError("");
    setSearchName(e.target.value);
  }

  function selectDestItem(itemId: number) {
    setBalanceError("");
    if (destItemIds.length === 10) return;
    setDestItemIds([...destItemIds, itemId]);
  }

  function resetDestItems() {
    setDestItemIds([]);
  }

  function handleOnConfirm() {
    const isNotEnough = compareNumbers(balance.FARA, ITEM_EXCHANGE_FEE ?? 0);
    if (needApprove) {
      sendApproveTx();
      return;
    } else if (modalData.isExchanging) {
      dispatch(cancelExchange(item.id, onCancelSuccess));
      return;
    }
    if (isNotEnough === -1) {
      setBalanceError("FARA Balance not enough");
    } else if (destItemIds.length === 0) {
      setBalanceError("Select at least 1 item to exchange");
    } else {
      dispatch(listExchange(item.id, destItemIds, 1, onExchangeSuccess));
    }
  }

  function onExchangeSuccess() {
    handleClose();
    modalData.setIsExchanging(true);
  }

  function onCancelSuccess() {
    handleClose();
    modalData.setIsExchanging(false);
  }

  if (!item) return null;

  return (
    <Modal isActive={exchangeModal.active} onClose={handleClose}>
      <BasicModalContent
        customTitle={modalData.isExchanging ? "Cancel Exchange Order" : ""}
        onSubmit={handleOnConfirm}
        submitText={needApprove ? "Approve FARA" : "Confirm"}
        close={handleClose}
        content={
          <div className="item-exchange">
            {modalData.isExchanging ? (
              <div>
                <span>You already made an exchange order for </span>
                <span className="text-9">
                  #{item.id} {item.displayName}
                </span>
                <span>. You have to cancel the previous order first before making another.</span>
                <div className="mt-2">Are you sure you want to cancel the current one?</div>
              </div>
            ) : (
              <>
                <div className="item-exchange__wrapper">
                  <div className="item-exchange__title">From</div>
                  <div className="item-exchange__block">
                    <div className="item-exchange__amount">01</div>
                    <div className="item-exchange__content">
                      <div className="item-exchange__name">{item.displayName}</div>
                      <ItemIcon item={item} layer={2} />
                    </div>
                  </div>
                </div>

                <div className="align-center">
                  <SwapIcon className="item-exchange__swap" />
                </div>

                <div className="item-exchange__wrapper">
                  <div className="item-exchange__header">
                    <div className="flex-center-start">
                      <div className="item-exchange__title mr-2">To ({destItemIds.length})</div>
                      <div className="btn btn--tiny" onClick={resetDestItems}>
                        Reset
                      </div>
                    </div>
                    <div className="icon-input">
                      <SearchIcon className="icon-input__icon" />
                      <input
                        className="icon-input__input"
                        value={searchName}
                        onChange={handleSearchNameChange}
                        type="text"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="item-exchange__storage nice-scroll nice-scroll--small nice-scroll--gray">
                    {filteredItems.length === 0 && <div>No Item found...</div>}

                    {filteredItems.map((item: Item, i: number) => {
                      const selectedAmount = destItemIds.filter((id) => id === item.id).length;
                      return (
                        <ItemIcon amount={selectedAmount} key={i} item={item} layer={2} onClick={selectDestItem} />
                      );
                    })}
                  </div>
                </div>

                <div className="mt-2">
                  <span>Exchange Offering Fee: </span>
                  <span className="text-9">
                    <span>{ITEM_EXCHANGE_FEE} FARA</span>
                    <img className="token top-5 ml-1" src={faraLogo} alt="FARA" />
                  </span>
                </div>
                <div className="mt-2">
                  <div>
                    <span>Current balance:</span>
                    <span> {roundNumber(balance.BNB, 3, true)} BNB</span>
                    <span>, {roundNumber(balance.FARA, 2, true)} FARA</span>
                  </div>
                </div>
                {balanceError && <div className="error-text">{balanceError}</div>}
              </>
            )}
          </div>
        }
      />
    </Modal>
  );
}
