import React, { useEffect, useState } from "react";
import { EQUIPMENT_LABEL, EQUIPMENT_TYPE, MAX_TIER, RARITY_LABEL } from "src/app/configs/constants";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { Item, ItemStatsLabel, SkillBook, SlotEquipment, UserItem } from "src/app/types/equipment";
import { useDispatch } from "react-redux";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import ItemRequirement from "src/app/components/Equipment/Commons/ItemRequirement";
import ItemEffect from "src/app/components/Equipment/Commons/ItemEffect";
import ItemSetBonus from "src/app/components/Equipment/Commons/ItemSetBonus";
import { setGlobalModal } from "src/app/actions/globalAction";
import _ from "lodash";
import { renderStats } from "src/app/utils/renderHelpers";
import { fetchSkillBookFromApi } from "src/app/services/api/faralandService";
import { mapSkillBookValue } from "src/app/utils/mappingHelpers";
import { KnightStats } from 'src/app/types/knight';

type ItemInfoProps = {
  itemId: number;
  userItem?: UserItem;
  slotEquipments?: SlotEquipment[];
  isKnightPreview?: boolean;
  noUpgradeInfo?: boolean;
  isLearnedSkills?: boolean;
  knightStats?: KnightStats;
};

export default function ItemInfo(props: ItemInfoProps) {
  const dispatch = useDispatch();
  const [skillBookInfo, setSkillBookInfo] = useState<SkillBook>();

  const itemId = props.itemId;
  const isLearnedSkills = props.isLearnedSkills;
  const item: Item = EQUIPMENT[itemId];
  const itemStats: ItemStatsLabel = item.stats;
  const isUpgradable = !!item.upgradeAmount;
  const isDismantleable = ["Common", "Uncommon"].includes(RARITY_LABEL[item.rarity]) && props.userItem?.available! > 0;
  const balance = props.userItem ? props.userItem.available : undefined;

  useEffect(() => {
    _getSkillbookData(props.itemId.toString());
  }, [props.itemId]); // eslint-disable-line

  function openUpgradeModal() {
    dispatch(
      setGlobalModal("upgradeEquipment", {
        active: true,
        data: { item: item, balance: balance },
      })
    );
  }

  function openDismantleModal() {
    dispatch(
      setGlobalModal("equipmentDismantleModal", {
        active: true,
        data: { item: item, userItem: props.userItem },
      })
    );
  }

  async function _getSkillbookData(id: string) {
    try {
      if (item.type !== EQUIPMENT_TYPE.SKILL_BOOK) return;
      const bookInfo = await fetchSkillBookFromApi(id);
      setSkillBookInfo(bookInfo);
    } catch (err) {
      console.log(err);
    }
  }

  function renderUpgradeInfo() {
    const isMaxTier = item.tier === MAX_TIER;
    let text = "";

    if (!isUpgradable && !isMaxTier) {
      text = "Not Available";
    } else if (isMaxTier) {
      text = "Max Tier";
    } else {
      return "";
    }

    return (
      <span className="text-gray fs-2 fw-4" style={{ marginLeft: 2 }}>
        {text}
      </span>
    );
  }

  function renderSkillInfo() {
    return (
      <>
        <div className="mt-2">
          <div>Skill Effect:</div>
          <div className="fs-2 mb-2 pl-1 stats__skill-effect">{skillBookInfo?.skillDescription}</div>
        </div>
        {!_.isEmpty(skillBookInfo?.elementalBonus) && (
          <div className="mt-2">
            <div>Elemental Bonus:</div>
            {skillBookInfo?.elementalBonus.map((bonus, index) => (
              <div key={index} className="fs-2 pl-1 text-gray">
                +{bonus.val[1]}% {mapSkillBookValue(bonus.val[0])} if hero is of {bonus.type} type
              </div>
            ))}
          </div>
        )}
        {!_.isEmpty(skillBookInfo?.partDescription) && (
          <div className="mt-2">
            <div>Body Part Bonus:</div>
            {skillBookInfo?.partDescription.map((bonus) => (
              <div className="fs-2 pl-1 text-gray">{bonus}</div>
            ))}
          </div>
        )}
        <div className="mt-1">
          <div>Requirement:</div>
          <div className="pl-1">
            <ItemRequirement item={item} />
          </div>
        </div>
      </>
    );
  }

  function renderItemInfo() {
    return (
      <>
        {item.description && (
          <div className="mt-2">
            <div>Description:</div>
            <div className="text-gray fs-2" dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        )}

        {renderStats(itemStats, false, item)}

        {itemStats.effects.length > 0 && (
          <div className="mt-1">
            <div>Effects:</div>
            <div className="pl-1">
              <ItemEffect itemStats={itemStats} />
            </div>
          </div>
        )}

        <ItemSetBonus item={item} slotEquipments={props.slotEquipments} isKnightPreview={props.isKnightPreview} knightStats={props.knightStats}/>

        <div className="mt-1">
          <div>Requirement:</div>
          <div className="pl-1">
            <ItemRequirement item={item} knightStats={props.knightStats}/>
          </div>
        </div>
      </>
    );
  }
  return (
    <div>
      <div className="item-icon__header">
        <ItemIconPreview item={item} isLearnedSkills={isLearnedSkills} />
        <div className="item-icon__info">
          <div>
            Tier: <span className="fs-2 text-9">{item.tier}</span>
          </div>
          <div>
            Name: <span className="fs-2 text-9">{item.displayName}</span>
          </div>
          <div>
            <span>Type: </span>
            <span className="fs-2 text-9">
              {EQUIPMENT_LABEL[item.type]}{" "}
              {item.type === EQUIPMENT_TYPE.MAIN_WEAPON && (item.twoHanded ? "(Two Handed)" : "(One Handed)")}
            </span>
          </div>
          <div>
            <span>Rarity: </span>
            <span className={`fs-2 fw-4 rarity-${RARITY_LABEL[item.rarity].toLowerCase()}`}>
              {RARITY_LABEL[item.rarity]}
            </span>
          </div>
        </div>
      </div>

      {!props.noUpgradeInfo && (
        <div className="flex-start-between item-icon__enhance-container">
          <div className="mt-2">
            <div>
              <span>Enhancement: {renderUpgradeInfo()}</span>
              {isUpgradable && (
                <span onClick={openUpgradeModal} className="btn item-icon__btn">
                  Preview
                </span>
              )}
            </div>
            {isUpgradable && (
              <div className="pl-1">
                {balance !== undefined && (
                  <div>
                    - Inventory <span className="fs-2 text-9">x{props.userItem?.available}</span>
                  </div>
                )}
                <div>
                  - Upgrade Amount <span className="fs-2 text-9">x{item.upgradeAmount}</span>
                </div>
              </div>
            )}
          </div>
          {isDismantleable && (
            <div className="mt-2">
              <div>
                <span>Dismantle: </span>
                <span onClick={openDismantleModal} className="btn item-icon__btn">
                  Preview
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      {EQUIPMENT_LABEL[item.type] === "Skill Book" ? renderSkillInfo() : renderItemInfo()}
    </div>
  );
}
