import React from "react";
import MetamaskAccount from "src/app/components/Commons/Account/MetamaskAccount";
import WalletConnectAccount from "src/app/components/Commons/Account/WalletConnectAccount";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import Coin98Account from "src/app/components/Commons/Account/Coin98Account";
import CoinbaseWalletAccount from "src/app/components/Commons/Account/CoinbaseWalletAccount";

export default function ImportModal() {
  return (
    <BasicModalContent
      customTitle="Import your Wallet"
      noPanel={true}
      content={(
        <div className="account">
          <MetamaskAccount/>
          <WalletConnectAccount/>
          <CoinbaseWalletAccount/>
          <Coin98Account/>
        </div>
      )}
    />
  )
}