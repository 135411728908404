import React from "react";
import pancakeLogo from "src/assets/images/logos/pancakeswap.svg";
import biswapLogo from "src/assets/images/logos/biswap.png";
import { ROUTE } from "src/app/configs/constants";
import SyncedBlock from "src/app/components/Layout/SyncedBlock";

export default function Footer({ path }) {
  const showSyncedBlock = path === ROUTE.HOME || path === ROUTE.PROJECT_OVERVIEW || path === ROUTE.TOKEN_METRIC || path === ROUTE.INSTRUCTIONS;
  const noMargin = [
    ROUTE.HOME,
    ROUTE.PROJECT_OVERVIEW,
    ROUTE.TOKEN_METRIC,
    ROUTE.INSTRUCTIONS,
    ROUTE.EVENT,
    ROUTE.GUILD,
    ROUTE.GUILD_DETAILS,
    ROUTE.EQUIPMENT_CRAFTING,
    ROUTE.EQUIPMENT_GACHA,
    ROUTE.LAND_MINT
  ].includes(path);

  return (
    <div className={noMargin ? 'relative' : "mt-100 relative"}>
      {!showSyncedBlock && (
        <SyncedBlock />
      )}
      <div className={`footer`}>
        <div className="footer__container container">
          <div className="footer__content-left footer__title">© MoonKnight Labs</div>
          <div className="footer__content">
            <div className="footer__block">
              <div className="footer__title">About</div>
              <div>
                <a
                  className="footer__link"
                  href={require("src/assets/documents/whitepaper.pdf")}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  White Paper
                </a>
                <a className="footer__link" href={ROUTE.PROJECT_OVERVIEW}>
                  Project Overview
                </a>
                <a className="footer__link" href={ROUTE.TOKEN_METRIC}>
                  Token Metrics
                </a>
                <a className="footer__link" href={ROUTE.INSTRUCTIONS}>
                  Game Instructions
                </a>
              </div>
            </div>
            <div className="footer__block">
              <div className="footer__title">Social</div>
              <div>
                <a
                  className="footer__link"
                  href="https://discord.com/invite/k52aRU7k4p"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Discord
                </a>
                <a
                  className="footer__link"
                  href="https://twitter.com/faraland_io"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Twitter
                </a>
                <a
                  className="footer__link"
                  href="https://t.me/faraland_ann"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Telegram
                </a>
                <a className="footer__link" href="https://blog.faraland.io/" target="_blank" rel="noreferrer noopener">
                  Blog
                </a>
                <a className="footer__link" href="https://vote.faraland.io/#/" target="_blank" rel="noreferrer noopener">
                  DAO Governance
                </a>
              </div>
            </div>
            <div className="footer__block">
              <div className="footer__title">Others</div>
              <div>
                <a
                  className="footer__link"
                  href="https://bscscan.com/token/0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Official Token
                </a>
                <a className="footer__link" href={ROUTE.HELP_CENTER}>
                  Help Center
                </a>
                <a className="footer__link"
                  href="https://farastarter.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  FaraStarter
                </a>
                <div className="flex-column">
                  <a
                    className="footer__biswap mt-2"
                    href="https://exchange.biswap.org/#/swap?outputCurrency=0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={biswapLogo} alt="PancakeSwap" />
                    <span>Buy FARA</span>
                  </a>
                  <a
                    className="footer__pancake"
                    href="https://pancakeswap.finance/swap?outputCurrency=0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={pancakeLogo} alt="PancakeSwap" />
                    <span>Buy FARA</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
