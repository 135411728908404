export const STAKING_REWARDS = {
  5000: {
    id: 18,
    amount: 1,
  },
  7000: {
    id: 18,
    amount: 2,
  },
  20000: {
    id: 18,
    amount: 6,
  },
  30000: {
    id: 18,
    amount: 10,
  },
  50000: {
    id: 18,
    amount: 18,
  },
  80000: {
    id: 18,
    amount: 30,
  },
  100000: {
    id: 19,
    amount: 1,
  },
  200000: {
    id: 19,
    amount: 3,
  },
  300000: {
    id: 19,
    amount: 5,
  },
  400000: {
    id: 19,
    amount: 7,
  },
  500000: {
    id: 19,
    amount: 9,
  },
  600000: {
    id: 19,
    amount: 11,
  },
  700000: {
    id: 19,
    amount: 13,
  },
  800000: {
    id: 19,
    amount: 15,
  },
  900000: {
    id: 19,
    amount: 17,
  },
  1000000: {
    id: 19,
    amount: 20,
  },
};

export const STAKING_REWARDS_LP = {
  10: {
    id: 18,
    amount: 2,
  },
  14: {
    id: 18,
    amount: 4,
  },
  40: {
    id: 18,
    amount: 8,
  },
  60: {
    id: 18,
    amount: 15,
  },
  100: {
    id: 18,
    amount: 28,
  },
  160: {
    id: 18,
    amount: 45,
  },
  200: {
    id: 19,
    amount: 2,
  },
  400: {
    id: 19,
    amount: 6,
  },
  600: {
    id: 19,
    amount: 10,
  },
  800: {
    id: 19,
    amount: 14,
  },
  1000: {
    id: 19,
    amount: 18,
  },
  1200: {
    id: 19,
    amount: 23,
  },
  1400: {
    id: 19,
    amount: 28,
  },
  1600: {
    id: 19,
    amount: 32,
  },
  1800: {
    id: 19,
    amount: 38,
  },
  2000: {
    id: 19,
    amount: 45,
  },
};
