import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core";
import { formatNumber, roundNumber, timeAgo } from "src/app/utils/helpers";
import faraLogo from "src/assets/images/tokens/fara.png";
import { TRANSACTION_TYPE } from "src/app/configs/constants";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PanToolIcon from "@material-ui/icons/PanTool";
import PhonelinkEraseIcon from "@material-ui/icons/PhonelinkErase";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { mapHistoryTypeToText } from "src/app/utils/mappingHelpers";
import ItemExchange from "src/app/components/Equipment/Commons/ItemExchange";
import { Item } from "src/app/types/equipment";
import { useSelector } from "react-redux";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import bnbLogo from "src/assets/images/tokens/bnb.svg";
import { renderWalletAddress } from "src/app/utils/renderHelpers";

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#111121",
      color: theme.palette.common.white,
      fontFamily: `"American Captain", sans-serif`,
      textTransform: "uppercase",
      fontWeight: 500,
      fontSize: 18,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

type MarketTableProps = {
  headerTitles: string[];
  cellTypes: string[];
  cellKeys: string[];
  isEmpty: boolean;
  isMyOrder: boolean;
  myOrderData: any;
  orderData: any;
  emptyMessage: string;
  item?: Item;
  renderBtnActions?: any;
  bnbToken?: boolean;
};

export default function MarketTable(props: MarketTableProps) {
  const { tokenPrice } = useSelector((state: any) => state.global);
  const {
    item,
    headerTitles,
    cellTypes,
    cellKeys,
    isEmpty,
    isMyOrder,
    myOrderData,
    orderData,
    renderBtnActions,
    emptyMessage,
    bnbToken,
  } = props;

  function mapHistoryTypeToIcon(historyType: string) {
    switch (historyType) {
      case TRANSACTION_TYPE.TYPE_ITEM_OFFER:
      case TRANSACTION_TYPE.OFFER:
        return <LocalOfferIcon fontSize="small" />;
      case TRANSACTION_TYPE.TYPE_ITEM_LIST:
      case TRANSACTION_TYPE.LIST:
        return <PanToolIcon fontSize="small" />;
      case TRANSACTION_TYPE.TYPE_ITEM_DELIST:
      case TRANSACTION_TYPE.TYPE_ITEM_EXCHANGE_CANCELED:
      case TRANSACTION_TYPE.TYPE_ITEM_OFFER_CANCELED:
      case TRANSACTION_TYPE.DELIST:
        return <PhonelinkEraseIcon fontSize="small" />;
      case TRANSACTION_TYPE.TYPE_ITEM_BOUGHT:
      case TRANSACTION_TYPE.BOUGHT:
        return <MonetizationOnIcon fontSize="small" />;
      case TRANSACTION_TYPE.TYPE_ITEM_EXCHANGE_OFFERED:
        return <TransferWithinAStationIcon fontSize="small" />;
      case TRANSACTION_TYPE.TYPE_ITEM_EXCHANGED:
        return <SwapHorizIcon fontSize="small" />;
      case TRANSACTION_TYPE.TYPE_ITEM_OFFER_TAKEN:
      case TRANSACTION_TYPE.TAKE_OFFER:
        return <ShoppingCartIcon fontSize="small" />;
      case TRANSACTION_TYPE.MINT:
        return <StarOutlineIcon fontSize="small" />;
      default:
        break;
    }
  }

  function mapCellKeyToDomainType(cellKey: string) {
    switch (cellKey) {
      case "from":
        return "fromAddressDomain";
      case "to":
        return "toAddressDomain";
      case "seller":
        return "sellerDomain";
      case "buyer":
        return "buyerDomain";
      default:
        return "";
    }
  }
  function formatCell(order: any, cellType: string, cellKey: string) {
    if (cellType === "address") {
      return order[cellKey]
        ? renderWalletAddress(order[cellKey], order[mapCellKeyToDomainType(cellKey)] ?? "", 4)
        : "--/--";
    } else if (cellType === "amount") {
      return formatNumber(order[cellKey]);
    } else if (cellType === "price") {
      if (order[cellKey]) {
        return (
          <div className="flex-center-start">
            <div className="mr-1 mt-1 fs-4 align-right">
              <div>{formatNumber(order[cellKey], 4)}</div>
              <div className="fs-1 text-gray">
                ${roundNumber(order[cellKey] * (bnbToken ? tokenPrice.BNB : tokenPrice.FARA), 2)}
              </div>
            </div>
            <img className="token token--small top-2" src={bnbToken ? bnbLogo : faraLogo} alt="" />
          </div>
        );
      } else if (item !== undefined) {
        return <ItemExchange srcItem={item} ruleDestItems={order.ruleDestItems} />;
      }
    } else if (cellType === "time") {
      return timeAgo(+order[cellKey] * 1000);
    } else if (cellType === "action") {
      return (
        <div className="flex-center-start">
          <div className="top-1 flex">{mapHistoryTypeToIcon(order[cellKey])}</div>
          <div className="ml-1 mt-1">{mapHistoryTypeToText(order[cellKey])}</div>
        </div>
      );
    } else {
      return order[cellKey];
    }
  }

  return (
    <Grid container>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headerTitles.map((title: string, index: number) => {
                return <StyledTableCell key={index}>{title}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty ? (
              (isMyOrder ? myOrderData : orderData).map((order, index) => (
                <StyledTableRow key={index}>
                  {cellTypes.map((type: string, idx: number) => {
                    return <StyledTableCell key={idx}>{formatCell(order, type, cellKeys[idx])}</StyledTableCell>;
                  })}
                  {renderBtnActions !== undefined && <StyledTableCell>{renderBtnActions(order)}</StyledTableCell>}
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell className="cm-table__no-record">
                  <img src={require("src/assets/images/icons/empty-bids.svg")} width={60} height={60} alt="" />
                  <div>{emptyMessage}</div>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
