import React, { useState, useEffect, useCallback } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Paper,
  Select,
  Slider,
  StylesProvider,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  INIT_ITEM_FILTER,
  RACE,
  RARITY_LABEL,
  SORT_ITEM_BY,
  STATS_LABEL,
  EQUIPMENT_LABEL,
  MAX_STAT_RANGE
} from "src/app/configs/constants";
import {
  getFilterCount,
  getItemFilterFromParams,
  getAdvancedFilterCount,
  getBasicFilterCount,
} from "src/app/utils/filterHelper";
import { useQueryString } from "use-route-as-state";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { debounce } from "lodash";
import { ItemFilterType } from "src/app/types/equipment";
import { ToggleButtonGroup, ToggleButton, Autocomplete } from "@material-ui/lab";
import disabledGem from "src/assets/images/decors/gems/tier-gem-disabled.svg";
import activeGem from "src/assets/images/decors/gems/tier-gem-active.svg";
import { EQUIPMENT_EFFECTS } from "src/app/configs/equipment/effects";
import { EQUIPMENT_SET } from "src/app/configs/equipment/sets";
import { mapEquipmentSetToObjectArray } from "src/app/utils/mappingHelpers";

export default function EquipmentFilter() {
  const [raceGenderIcon, setRaceGenderIcon] = useState("male");
  const [filterCount, setFilterCount] = useState(0);
  const [basicFilterCount, setBasicFilterCount] = useState(0);
  const [advancedFilterCount, setAdvancedFilterCount] = useState(0);
  const [queryParams, setQueryParams] = useQueryString();
  const [itemFilters, setItemFilters] = useState<ItemFilterType>(getItemFilterFromParams(queryParams));
  const [itemIdInput, setItemIdInput] = useState<any>(itemFilters.id);
  const [itemNameInput, setItemNameInput] = useState<any>(itemFilters.name);
  const [tabValue, setTabValue] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [strSlider, setStrSlider] = useState<any>(itemFilters.stats[STATS_LABEL.STR]);
  const [agiSlider, setAgiSlider] = useState<any>(itemFilters.stats[STATS_LABEL.AGI]);
  const [intSlider, setIntSlider] = useState<any>(itemFilters.stats[STATS_LABEL.INT]);
  const [lukSlider, setLukSlider] = useState<any>(itemFilters.stats[STATS_LABEL.LUK]);
  const [levelSlider, setLevelSlider] = useState<any>(itemFilters.levels.LVL);
  const [rarityValue, setRarityValue] = useState<any>(itemFilters.rarity);
  const disableFilter = itemFilters.offering || itemFilters.owned || itemFilters.listings || itemFilters.exchanges || itemFilters.craftable;
  const debounceSetParams = useCallback(debounce(setQueryParams, 500), []);

  useEffect(() => {
    const raceGender = Math.random() < 0.5 ? "male" : "female";
    setRaceGenderIcon(raceGender);
  }, []);

  useEffect(() => {
    setFilterCount(getFilterCount(itemFilters));
    setBasicFilterCount(getBasicFilterCount(itemFilters));
    setAdvancedFilterCount(getAdvancedFilterCount(itemFilters));
  }, [itemFilters]);

  useEffect(() => {
    const newFilters = getItemFilterFromParams(queryParams);
    setItemFilters({ ...newFilters });
  }, [queryParams]);

  const handleInputChange = (category: string) => (e: any) => {
    const value = e.target.value;

    if (category === "name") {
      setItemNameInput(value);
    } else if (category === "id") {
      setItemIdInput(value);
    } else {
      return;
    }

    debounceSetParams({
      ...queryParams,
      [category]: value.toLowerCase()
    });
  };

  const handleSelectionChange = (category: string) => (e: any) => {
    setQueryParams({
      ...queryParams,
      [category]: e.target.value,
    });
  };

  function handleClearFilters() {
    setItemFilters(INIT_ITEM_FILTER);
    setItemIdInput("");
    setItemNameInput("");
    setRarityValue("");
    setQueryParams({});
    setStrSlider([0, MAX_STAT_RANGE]);
    setAgiSlider([0, MAX_STAT_RANGE]);
    setIntSlider([0, MAX_STAT_RANGE]);
    setLukSlider([0, MAX_STAT_RANGE]);
    setLevelSlider([1, 100]);
  }

  function handleCheckboxChange(e: any, category: string, key: string) {
    const selectedCategory = queryParams[category];
    const arraySelected = selectedCategory ? selectedCategory.split(",") : [];
    const found = arraySelected.indexOf(key);
    if (found >= 0 && !e.target.value) {
      arraySelected.splice(found, 1);
    } else if (e.target.checked) {
      arraySelected.push(key);
    }

    setQueryParams({
      ...queryParams,
      [category]: arraySelected.join(","),
    });
  }

  function _mapFiltersToState(key: string) {
    switch (key) {
      case STATS_LABEL.STR:
        return strSlider;
      case STATS_LABEL.AGI:
        return agiSlider;
      case STATS_LABEL.INT:
        return intSlider;
      case "LVL":
        return levelSlider;
      default:
        return lukSlider;
    }
  }

  const handleSliderChange = (key: string) => (_e: any, newValue: any) => {
    switch (key) {
      case STATS_LABEL.STR:
        setStrSlider(newValue);
        break;
      case STATS_LABEL.AGI:
        setAgiSlider(newValue);
        break;
      case STATS_LABEL.INT:
        setIntSlider(newValue);
        break;
      case "LVL":
        setLevelSlider(newValue);
        break;
      default:
        setLukSlider(newValue);
        break;
    }

    debounceSetParams({
      ...queryParams,
      [key]: newValue,
    });
  };

  const handleTabChange = (_e: any, newValue: number) => {
    setTabValue(newValue);
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleRarityChange = (_e: any, value: any) => {
    if (value === null) {
      setRarityValue("");
    } else {
      setRarityValue(value);
    }
    setQueryParams({
      ...queryParams,
      rarity: value ?? "",
    });
  };

  const handleTierChange = (value: any) => {
    if (queryParams && queryParams.tier && queryParams.tier === value.toString()) {
      setQueryParams({
        ...queryParams,
        tier: "",
      });
    } else {
      setQueryParams({
        ...queryParams,
        tier: value,
      });
    }
  };

  function _getDataByCheckbox(category: string, imageName?: string) {
    let data;
    let icon = "";

    if (category === "races") {
      data = itemFilters.races;
      icon = imageName ? require(`src/assets/images/icons/races/${imageName}`) : "";
    } else if (category === "genders") {
      data = itemFilters.genders;
    }
    return { data, icon };
  }

  function renderCheckboxes(category: string, values: any = {}, iconMargin = "", subImageName = "") {
    return (
      <div className="mb-2">
        <div className="dashed-title">
          <span>{category}</span>
        </div>
        <div className="market__filter-items">
          {Object.keys(values).map((key: string) => {
            const { icon } = _getDataByCheckbox(
              category,
              `${key === RACE.DRAGONBORN ? "dragon" : key.toLowerCase()}${subImageName}.png`
            );

            return (
              <FormControlLabel
                key={key}
                className={`market__filter-item`}
                control={
                  <Checkbox
                    checked={values[key]}
                    onChange={(e) => handleCheckboxChange(e, category, key)}
                    color="primary"
                  />
                }
                label={
                  <div className="flex-center-start bot-1">
                    {icon !== "" && <img className={`icon ${iconMargin}`} src={icon} alt="" />}
                    <span className="top-2 fs-3">{key === RACE.DRAGONBORN ? "Dragon" : key}</span>
                  </div>
                }
              />
            );
          })}
        </div>
      </div>
    );
  }

  function renderToggleButtons(category: string) {
    return (
      <div className="mb-2">
        <div className="dashed-title">
          <span>{category}</span>
        </div>
        <ToggleButtonGroup
          value={rarityValue}
          className="market__filter-rarity"
          exclusive
          onChange={handleRarityChange}
        >
          {RARITY_LABEL.map((rarity) => (
            <ToggleButton value={rarity} key={rarity}>
              {rarity}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    );
  }

  function renderButtonGroup(category: string, values: any) {
    return (
      <div className="mb-2">
        <div className="dashed-title">
          <span>{category}</span>
        </div>
        <div className="flex-center-center">
          <Tooltip title="Tier 1" placement="top">
            <IconButton onClick={() => handleTierChange(1)}>
              <img src={values >= 1 ? activeGem : disabledGem} alt="" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Tier 2" placement="top">
            <IconButton onClick={() => handleTierChange(2)}>
              <img src={values >= 2 ? activeGem : disabledGem} alt="" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Tier 3" placement="top">
            <IconButton onClick={() => handleTierChange(3)}>
              <img src={values === 3 ? activeGem : disabledGem} alt="" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  }

  function renderSliders(category: string, values: any) {
    const statMarks = [
      { value: 0, label: "0" },
      { value: MAX_STAT_RANGE, label: MAX_STAT_RANGE.toString() },
    ];
    const levelMarks = [
      { value: 1, label: "1" },
      { value: 100, label: "100" },
    ];
    return (
      <div className="mb-2">
        <div className="dashed-title">
          <span>{category}</span>
        </div>
        {Object.keys(values).map((key: string) => {
          return (
            <div className="flex mt-5 mr-2 mb-5 z-index-2" key={key}>
              {category === "stats" ? (
                <Tooltip title={key} placement="top" className="market__filter-slider--stats">
                  <img src={require(`src/assets/images/hero-details/stats/${key.toLowerCase()}.svg`)} alt="" />
                </Tooltip>
              ) : (
                <Tooltip title="Level" placement="top" className="market__filter-slider--levels">
                  <div>{key}</div>
                </Tooltip>
              )}
              <Slider
                value={_mapFiltersToState(key)}
                min={category === "stats" ? 0 : 1}
                max={category === "stats" ? MAX_STAT_RANGE : 100}
                step={1}
                onChange={handleSliderChange(key)}
                valueLabelDisplay="auto"
                marks={category === "stats" ? statMarks : levelMarks}
              />
            </div>
          );
        })}
      </div>
    );
  }

  const handleAutocorrectChange = (category: string) => (_e: any, newValue: any) => {
    setQueryParams({
      ...queryParams,
      [category]:
        category === "effects"
          ? newValue.map((value) => {
              return value.name;
            })
          : newValue?.id,
    });
  };

  function renderAutoComplete(category: string, values: any, label: string) {
    return (
      <div className="mb-4">
        <div className="dashed-title mb-4">
          <span>{category}</span>
        </div>
        <Autocomplete
          multiple={category === "effects"}
          className="input"
          options={category === "effects" ? EQUIPMENT_EFFECTS : mapEquipmentSetToObjectArray(EQUIPMENT_SET)}
          value={values}
          getOptionLabel={(option) => option.name || ""}
          onChange={handleAutocorrectChange(category)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              InputLabelProps={{ shrink: true }}
              placeholder={`Search ${label}`}
              InputProps={{
                ...params.InputProps,
                endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
              }}
            />
          )}
          PaperComponent={(props) => (
            <StylesProvider injectFirst>
              <Paper {...props} className="autocomplete-paper" />
            </StylesProvider>
          )}
          renderOption={(option) => (
            <div className="market__filter-body-part">
              <div className="body-part__content">
                <div className="body-part__name">{option.name}</div>
              </div>
            </div>
          )}
        />
      </div>
    );
  }

  function renderSelectionBox(category: string, values: any, constant: any) {
    return (
      <div className={category && category !== "sortBy" ? `mb-4` : ""}>
        {category !== "sortBy" && (
          <div className="dashed-title">
            <span>{category === "sortBy" ? "Sort By" : "Slot Type"}</span>
          </div>
        )}
        <FormControl variant="outlined" className="input mr-4" style={{width: `${category !== "sortBy" ? '100%' : '200px'}`}}>
          <InputLabel htmlFor="outlined-age-native-simple">{category === "sortBy" ? "Sort By" : "Slot Type"}</InputLabel>
          <Select label={category} native value={values} onChange={handleSelectionChange(category)} style={{padding: `${category !== "sortBy" && "9px"}`}}>
            {Object.keys(constant).map((key: string) => {
              return (
                <option value={constant[key]} key={key}>
                  {constant[key]}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  }

  function renderNameField(category: string, label: string) {
    return (
      <div className={category && category !== "id" ? `mb-4` : ""}>
        {category !== "id" && (
          <div className="dashed-title mb-4">
            <span>{label}</span>
          </div>
        )}
        <StylesProvider injectFirst>
          <TextField
            value={category === "id" ? itemIdInput : itemNameInput}
            className={`input ${category !== "id" && "market__input"}`}
            label={label}
            type={category === "id" ? "number" : "text"}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={handleInputChange(category)}
          />
        </StylesProvider>
      </div>
    );
  }

  return (
    <div className={`market__filter-container ${disableFilter ? "disabled" : ""}`}>
      <div className="market__filter-button">
        <div className="btn" onClick={handleShowFilter}>
          Filters <ArrowDropDownIcon />
        </div>
      </div>
      <div className={`market__filter ${showFilter && "market__filter--mobile"} fade-in`}>
        <div className="market__filter-header">
          <div className="fw-4 top-1">FILTERS ({filterCount})</div>
          <div className="btn btn--small" onClick={handleClearFilters}>
            Clear Filter
          </div>
        </div>
        <div className="flex mb-4">
          {renderSelectionBox("sortBy", itemFilters.sortBy, SORT_ITEM_BY)}
          {renderNameField("id", "Item ID")}
        </div>
        <Tabs value={tabValue} onChange={handleTabChange} className="market__filter-tabs" variant="fullWidth">
          <Tab label={`Basic ${basicFilterCount ? `(${basicFilterCount})` : ""}`} />
          <Tab label={`Advanced ${advancedFilterCount ? `(${advancedFilterCount})` : ""}`} />
        </Tabs>
        {tabValue === 0 ? (
          <>
            {renderNameField("name", "Item Name")}
            {renderCheckboxes("races", itemFilters.races, "mr-2", `-${raceGenderIcon}`)}
            {renderToggleButtons("Rarity")}
            {renderButtonGroup("tier", itemFilters.tier)}
            {renderCheckboxes("genders", itemFilters.genders)}
          </>
        ) : (
          <>
            {renderSelectionBox("slotType", itemFilters.slotType, ["All", ...EQUIPMENT_LABEL])}
            {renderAutoComplete("effects", itemFilters.effects, "Effects")}
            {renderAutoComplete("set", itemFilters.set, "Item Sets")}
            {renderSliders("required level", itemFilters.levels)}
            {renderSliders("stats", itemFilters.stats)}
          </>
        )}
      </div>
    </div>
  );
}
