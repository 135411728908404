import React from "react";
import MaterialIconPreview from "src/app/components/Materials/Commons/MaterialIconPreview";
import { MATERIALS } from "src/app/configs/materials/materials";
import { EquipmentDismantle } from "src/app/types/equipment";
import { Material } from "src/app/types/materials";
import { calculateRewards } from "src/app/utils/helpers";

export default function useDismantleData(dismantleMaterials: EquipmentDismantle[]) {
  // const dispatch = useDispatch();
  // const { web3Service } = useSelector((state: any) => state.global);
  // const [dismantlePoints, setDismantlePoints] = useState(0);
  // const { address } = useSelector((state: any) => state.account);
  // const bonusDismantlePoints = calculateRewards(dismantleMaterials).dismantlePoints;

  // useEffect(() => {
  //   if (!web3Service || !address) return;
  //   _fetchDismantlePoints(address);
  // }, [web3Service, address]); // eslint-disable-line

  // async function _fetchDismantlePoints(address: string) {
  //   const data = await web3Service.fetchDismantlePoints(address);
  //   setDismantlePoints(data);
  // }

  // function _convertExpToBp() {
  //   dispatch(convertExpToBp());
  // }

  function renderDismantleMaterials() {
    return (
      <div className="equipment__dismantle flex-center-between mt-2">
        {dismantleMaterials[0]?.materials.map((id, key) => {
          const material: Material = MATERIALS[id];
          return (
            <div className="flex-column-center fs-2" key={key}>
              <MaterialIconPreview material={material} />
              <div className="equipment__dismantle-materials">
                <span className="success-text">{calculateRewards(dismantleMaterials).quantity}</span> x{" "}
                {material.displayName}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  // function renderDismantlePointsBar() {
  //   return (
  //     <div className="equipment__dismantle-points mt-3">
  //       <div className="flex-center-between fs-2 top-3">
  //         <div>Dismantle Points:</div>
  //         <div>
  //           {bonusDismantlePoints !== 0 && (
  //             <span className="success-text">{bonusDismantlePoints} + </span>
  //           )}
  //           <span className="warning-text">
  //             {dismantlePoints} / {DISMANTLE_POINT_PER_EXP}
  //           </span>
  //           {dismantlePoints >= DISMANTLE_POINT_PER_EXP && (
  //             <Tooltip title={`Convert your Dismantle Points to ${Math.floor(dismantlePoints / DISMANTLE_POINT_PER_EXP)} Blacksmith Exps`} arrow placement="top">
  //               <span className={`plus-btn`} onClick={_convertExpToBp}>+</span>
  //             </Tooltip>
  //           )}
  //         </div>
  //       </div>
  //       <ExpProgressBar currentExp={dismantlePoints} requiredExp={DISMANTLE_POINT_PER_EXP} hideExp />
  //     </div>
  //   );
  // }

  return { renderDismantleMaterials };
}
