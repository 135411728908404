import { gql } from "@apollo/client";

const knightData = `
  id
  name
  level
  soldPrice
  listedPrice
  offerPrice
  owner {
    id
  }
  equipments {
    id
  }
`;

export const SYNCED_BLOCK_QUERY = () => {
  return gql`
    query GetOffers {
      _meta {
        block {
          number
        }
      }
    }
  `;
};

export const OFFER_QUERY = (owner: string) => {
  return gql`
    query GetOffers {
      offers(orderBy: timestamp, orderDirection: desc, where: { buyer: "${owner}", isActive: true }) {
        price
        knight {${knightData}}
      }
    }
  `;
};

export const STAKING_SUMMARY_QUERY = () => {
  return gql`
    query GetStakingSummary {
      faraStakings {
        type
        totalStaked
      }
    }
  `;
};

export const STAKING_QUERY = (owner: string) => {
  return gql`
    query GetUserStaking {
      userStakes(where: { user: "${owner}", totalStaked_gt: 0 }
      first: 1000
      ) {
        stakedAmountLP
        stakedAmountSingle
        stakedAmountOldSingle
        stakedAmountLPV3
        stakedAmountSingleV3
        knight { id }
      }
    }
  `;
};

export const GACHA_ITEMS_QUERY = (vendorId: number) => {
  return gql`
    query GetItemsInGacha {
      gachaItems(orderBy: itemIdNumber, where: {vendor: "${vendorId}", amount_gt: 0}) {
        item {
          id
          minted
          tier
        }
      }
    }
  `;
};

export const GACHA_RECEIVED_ITEMS_QUERY = (txHash: string) => {
  return gql`
    query GetItemsReceivedInGacha {
      gachaReceivedItem(id: "${txHash}") {
        items {
          item {
            id
            minted
            tier
          }
          amount
        }
      }
    }
  `;
};

export const OWNED_KNIGHTS_INFO = gql`
  query info($owner: String!) {
    knights(where: { owner: $owner }) {
      id
      symbol
      name
      isAlive
      level
      listedPrice
      owner {
        id
      }
    }
  }
`;

export const MULTIPLE_KNIGHTS_INFO = gql`
  query info($ids: [Int]!) {
    knights(where: { id_in: $ids }) {
      id
      symbol
      name
      isAlive
      level
      listedPrice
      owner {
        id
      }
      equipments {
        id
        name
        slot
      }
    }
  }
`;
export const KNIGHT_INFO = gql`
  query info($id: String!) {
    knights(where: { id: $id }) {
      id
      symbol
      name
      isAlive
      level
      listedPrice
      owner {
        id
      }
      equipments {
        id
        name
        slot
      }
    }
    lists(where: { knight: $id, isActive: true }, orderBy: timestamp, orderDirection: desc, first: 1) {
      knight {
        id
      }
      price
      blockNumber
      timestamp
    }
    offers(where: { knight: $id, isActive: true }, orderBy: price, orderDirection: desc, first: 10) {
      id
      knight {
        id
      }
      buyer {
        id
      }
      price
      timestamp
      blockNumber
    }
    transactions(where: { knight: $id }, orderBy: timestamp, orderDirection: desc, first: 30) {
      type
      knight {
        id
      }
      price
      from {
        id
      }
      to {
        id
      }
      timestamp
    }
  }
`;

export const TOTAL_INFO = gql`
  query summary {
    summary: faraStores {
      id
      knightCount
      demiCount
      equipmentCount
      totalVolume
      totalDemiVolume
      highestPrice
      highestDemiPrice
      numberActiveUser
      tradeCount
      totalNumberEquipmentSold
      totalVolumeEquipmentSold
      highestPriceEquipment
    }
  }
`;

export const MATERIAL_TOTAL_INFO = gql`
  query summary {
    summary: materialMarketplaces {
      materialSold
      totalVolume
      highestPrice
    }
  }
`;

export const USER_ITEMS = gql`
  query userItems($owner: String!, $fromId: Int!) {
    userOwnEquipments(where: { user: $owner, amount_gt: 0, itemIdNumber_gte: $fromId }, orderBy: itemIdNumber) {
      id
      itemIdNumber
      user {
        id
      }
      item {
        id
        minted
        slot
        maxSupply
        rarity
        name
        tier
      }
      amount
      used
    }
  }
`;

export const USER_ITEM_OFFERS = gql`
  query itemOffers($buyer: String!) {
    offerItems(where: { buyer: $buyer, amount_gt: 0 }) {
      id
      item {
        id
        minted
        slot
        maxSupply
        rarity
        name
        tier
      }
      amount
      price
    }
  }
`;

export const USER_ITEM_LISTINGS = gql`
  query itemListings($seller: String!) {
    listItems(where: { seller: $seller, amount_gt: 0 }) {
      id
      item {
        id
        minted
        slot
        maxSupply
        rarity
        name
        tier
      }
      amount
      price
    }
  }
`;

export const USER_ITEM_EXCHANGES = gql`
  query itemExchanges($seller: String!) {
    exchangeItems(where: { seller: $seller, amount_gt: 0 }) {
      id
      item {
        id
        minted
        slot
        maxSupply
        rarity
        name
        tier
      }
      amount
      ruleDestItem {
        itemAmountExchangeRules {
          amount
          item {
            id
            minted
            slot
            maxSupply
            rarity
            name
            tier
          }
        }
      }
    }
  }
`;

export const ITEM_INFO = gql`
  query info($id: String!) {
    equipment(id: $id) {
      id
      name
      maxSupply
      slot
      rarity
      minted
      burned
      tier
      upgradeAmount
      nextTierItem {
        id
        name
        maxSupply
        slot
        rarity
        minted
        tier
      }
    }
    listItems(where: { item: $id, amount_gt: 0 }, orderBy: price) {
      id
      item {
        id
        name
        maxSupply
        slot
        rarity
        minted
        tier
      }
      amount
      seller {
        id
      }
      price
      timestamp
    }
    offerItems(where: { item: $id, amount_gt: 0 }, orderBy: price, orderDirection: desc) {
      id
      item {
        id
        name
        maxSupply
        slot
        rarity
        minted
        tier
      }
      amount
      buyer {
        id
      }
      price
      timestamp
    }
    exchangeItems(where: { item: $id, amount_gt: 0 }, orderBy: timestamp, orderDirection: desc) {
      id
      item {
        id
        name
        maxSupply
        slot
        rarity
        minted
        tier
      }
      amount
      seller {
        id
      }
      ruleDestItem {
        itemAmountExchangeRules {
          amount
          item {
            id
            minted
            slot
            maxSupply
            rarity
            name
            tier
          }
        }
      }
      timestamp
    }
    itemMarketplaceTransactions(where: { item: $id }, orderBy: timestamp, orderDirection: desc) {
      id
      type
      item {
        id
        name
        maxSupply
        slot
        rarity
        minted
        tier
      }
      amount
      price
      ruleDestItem {
        itemAmountExchangeRules {
          amount
          item {
            id
            minted
            slot
            maxSupply
            rarity
            name
            tier
          }
        }
      }
      from {
        id
      }
      to {
        id
      }
      timestamp
    }
  }
`;

export const ITEM_OWN_INFO = gql`
  query info($id: String!, $address: String!) {
    userOwnEquipments(where: { user: $address, amount_gt: 0, item: $id }, orderBy: itemIdNumber) {
      id
      user {
        id
      }
      item {
        id
        minted
        slot
        maxSupply
        rarity
        name
        tier
      }
      amount
      used
    }
    listItems(where: { item: $id, amount_gt: 0, seller: $address }, orderBy: price) {
      id
      item {
        id
        name
        maxSupply
        slot
        rarity
        minted
        tier
      }
      amount
      seller {
        id
      }
      price
      timestamp
    }
    offerItems(where: { item: $id, amount_gt: 0, buyer: $address }, orderBy: price, orderDirection: desc) {
      id
      item {
        id
        name
        maxSupply
        slot
        rarity
        minted
        tier
      }
      amount
      buyer {
        id
      }
      price
      timestamp
    }
    exchangeItems(where: { item: $id, amount_gt: 0, seller: $address }, orderBy: timestamp, orderDirection: desc) {
      id
      item {
        id
        name
        maxSupply
        slot
        rarity
        minted
        tier
      }
      amount
      seller {
        id
      }
      ruleDestItem {
        itemAmountExchangeRules {
          amount
          item {
            id
            minted
            slot
            maxSupply
            rarity
            name
            tier
          }
        }
      }
      timestamp
    }
  }
`;

export const USER_MATERIALS = gql`
  query userMaterials($owner: String!) {
    userOwnMaterials(where: { user: $owner, amount_gt: 0 }) {
      id
      user {
        id
      }
      material {
        id
        name
        minted
        burned
      }
      amount
    }
  }
`;

export const USER_MATERIAL_LISTINGS = gql`
  query materialListings($seller: String!) {
    listMaterials(where: { seller: $seller, amount_gt: 0 }) {
      id
      material {
        id
        name
        minted
        burned
      }
      amount
      price
    }
  }
`;

export const USER_MATERIAL_OFFERS = gql`
  query materialOffers($buyer: String!) {
    offerMaterials(where: { buyer: $buyer, amount_gt: 0 }) {
      id
      material {
        id
        name
        minted
        burned
      }
      amount
      price
    }
  }
`;

export const MATERIAL_INFO = gql`
  query materialInfo($id: String!) {
    material(id: $id) {
      id
      name
      minted
      burned
    }
    listMaterials(where: { material: $id, amount_gt: 0 }, orderBy: price) {
      id
      material {
        id
        name
        minted
        burned
      }
      seller {
        id
      }
      amount
      price
      timestamp
    }
    offerMaterials(where: { material: $id, amount_gt: 0 }, orderBy: price, orderDirection: desc) {
      id
      material {
        id
        name
        minted
        burned
      }
      buyer {
        id
      }
      amount
      price
      timestamp
    }
    materialMarketplaceTransactions(where: { material: $id }, orderBy: timestamp, orderDirection: desc) {
      id
      type
      material {
        id
        name
        minted
        burned
      }
      amount
      price
      from {
        id
      }
      to {
        id
      }
      timestamp
    }
  }
`;

export const MATERIAL_OWN_INFO = gql`
  query info($id: String!, $address: String!) {
    userOwnMaterials(where: { user: $address, amount_gt: 0, material: $id }, orderBy: materialIdNumber) {
      id
      user {
        id
      }
      material {
        id
        name
        minted
        burned
      }
      amount
    }
    listMaterials(where: { material: $id, amount_gt: 0, seller: $address }, orderBy: price) {
      id
      material {
        id
        name
        minted
        burned
      }
      seller {
        id
      }
      amount
      price
      timestamp
    }
    offerMaterials(where: { material: $id, amount_gt: 0, buyer: $address }, orderBy: price, orderDirection: desc) {
      id
      material {
        id
        name
        minted
        burned
      }
      buyer {
        id
      }
      amount
      price
      timestamp
    }
  }
`;

export const DEMI_INFO = gql`
  query info($id: String!) {
    demiKnight(id: $id) {
      registered
    }
    demiLists(where: { knight: $id, isActive: true }, orderBy: timestamp, orderDirection: desc, first: 1) {
      knight {
        id
      }
      price
      blockNumber
      timestamp
    }
    demiTransactions(where: { knight: $id }, orderBy: timestamp, orderDirection: desc, first: 30) {
      type
      knight {
        id
      }
      price
      from {
        id
      }
      to {
        id
      }
      timestamp
    }
  }
`;

export const GUILD_LIST = gql`
  query info($name: String!) {
    faraGuilds(where: { name_contains: $name }) {
      id
      name
      owner
      memberCount
      level
      totalStrength
      timeCreated
    }
  }
`;

export const JOIN_REQUESTS = gql`
  query info($id: String!, $name: String!) {
    joinRequests(
      where: { guild: $id, isActive: true, member_contains: $name }
      orderBy: timestamp
      orderDirection: asc
    ) {
      member {
        id
        strength
        position
        timeJoin
      }
      guild {
        id
      }
      timestamp
    }
  }
`;

export const PENDING_REQUESTS = gql`
  query info($id: String!) {
    joinRequests(where: { member: $id, isActive: true }, orderBy: timestamp, orderDirection: asc) {
      member {
        id
        strength
        position
        timeJoin
      }
      guild {
        id
      }
      timestamp
    }
  }
`;
export const GUILD_INFO = gql`
  query info($id: String!) {
    faraGuild(id: $id) {
      id
      name
      owner
      memberCount
      level
      totalStrength
    }
    members(where: { guild: $id }, orderBy: timeJoin, orderDirection: asc) {
      id
      guild {
        id
      }
      strength
      position
      timeJoin
    }
    joinRequests(where: { guild: $id, isActive: true }, orderBy: timestamp, orderDirection: asc) {
      member {
        id
        strength
        position
        timeJoin
      }
      guild {
        id
      }
      timestamp
    }
  }
`;

export const GUILD_OF_MEMBER = gql`
  query info($id: String!) {
    member(id: $id) {
      guild {
        id
        name
        owner
        totalStrength
        memberCount
        level
      }
    }
  }
`;

export const KNIGHT_LEARNED_SKILLS = gql`
  query learnedSkills($id: String!) {
    knight(id: $id) {
      id
      owner {
        id
      }
      skills {
        id
        name
      }
    }
  }
`;

export const GUILD_SUMMARY = gql`
  query guildSummary {
    summaries {
      totalMems
      totalGuilds
    }
  }
`;

export const GACHA_EQUIPMENT_SUMMARY = gql`
  query gachaEquipmentSummary {
    _meta {
      block {
        number
      }
    }
    itemInPortals(where: { amount_gt: 0 }) {
      id
      amount
    }
    histories(orderBy: timestamp, orderDirection: desc, where: { jackpot_not: null }) {
      jackpot {
        account
        amount
      }
      timestamp
    }
    userReceivedItems(orderBy: "blockNumber", orderDirection: "desc") {
      id
      account
      burnedItem
      receiveItem
      blockNumber
      timestamp
    }
    contributors(orderBy: "amount", orderDirection: "desc") {
      id
      amount
    }
  }
`;

export const CRAFTING_HISTORY = gql`
  query craftingHistory {
    craftHistories(orderBy: timestamp, orderDirection: desc) {
      account {
        id
      }
      craftedItemIds {
        id
        equipment {
          id
        }
      }
      timestamp
    }
  }
`;

export const LAND_INFO = gql`
  query info($id: String!) {
    lands(where: { id: $id }) {
      id
      name
      listedPrice
      owner {
        id
      }
    }
    lists(where: { land: $id, isActive: true }, orderBy: timestamp, orderDirection: desc, first: 1) {
      land {
        id
      }
      price
      blockNumber
      timestamp
    }
    offers(where: { land: $id, isActive: true }, orderBy: price, orderDirection: desc, first: 10) {
      id
      land {
        id
      }
      buyer {
        id
      }
      price
      timestamp
      blockNumber
    }
    transactions(where: { land: $id }, orderBy: timestamp, orderDirection: desc, first: 30) {
      type
      land {
        id
      }
      price
      from {
        id
      }
      to {
        id
      }
      timestamp
    }
  }
`;

export const LAND_OFFER_QUERY = (owner: string) => {
  return gql`
    query GetOffers {
      offers(orderBy: timestamp, orderDirection: desc, where: { buyer: "${owner}", isActive: true }) {
        price
        land {
          id
          name
          soldPrice
          listedPrice
          offerPrice
          owner {
            id
          }
        }
      }
    }
  `;
};

export const LAND_TOTAL_INFO = gql`
  query summary {
    summary: landStores {
      tradeCount
      totalVolume
      highestPrice
    }
  }
`;

export const QUERY_TOTAL_EQUIPMENT = (id: number) => {
  return gql`
    query queryTotalEquipment {
      totalEquipments(where: { id: "${id}" }) {
        id
        totalAmount
      }
    }
  `;
};

export const QUERY_TOTAL_MATERIAL = (id: number) => {
  return gql`
    query queryTotalMaterials {
      totalMaterials(where: { id: "${id}" }) {
      id
      totalAmount
      }
    }
  `;
};
