import ENV from 'src/app/configs/env';
import { TokenPriceData } from "src/app/types/common";

export async function fetchTokenUsdPrice(): Promise<TokenPriceData> {
  let bnbPriceInUsd = 0;
  let faraPriceInUsd = 0;

  try {
    const response = await fetch(`${ENV.URL.COINGECKO_API}/api/v3/simple/token_price/binance-smart-chain?vs_currencies=USD&contract_addresses=${ENV.CONTRACT.FARA_TOKEN}%2C${ENV.CONTRACT.WBNB_TOKEN}`);
    const data = await response.json();

    faraPriceInUsd = data[ENV.CONTRACT.FARA_TOKEN].usd;
    bnbPriceInUsd = data[ENV.CONTRACT.WBNB_TOKEN].usd;
  } catch (e) {
    console.log(e);
  }

  return {
    FARA: faraPriceInUsd,
    BNB: bnbPriceInUsd
  }
}