import React from "react";
import LandBuying from "src/app/components/Land/Mint/LandBuying";
import LandClaim from "src/app/components/Land/Mint/LandClaim";
import LandDefinition from "src/app/components/Land/Mint/LandDefinition";
import LandMap from "src/app/components/Land/Mint/LandMap";
import MintStepper from "src/app/components/Land/Mint/MintStepper";

export default function LandMint() {
  return (
    <div className="land__mint">
      <MintStepper />
      <LandMap />
      <LandClaim />
      <LandDefinition />
      <LandBuying />
    </div>
  );
}
