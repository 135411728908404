import { TableContainer, Table, TableBody, TableRow, TableCell, withStyles, createStyles } from "@material-ui/core";
import React from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { BASE_STATS, STATS_ALLOCATION, STATS_CAP } from "src/app/configs/stats-allocation/statsAllocation";

export default function StatsAllocationModal() {
  const StyledTable = withStyles(() =>
    createStyles({
      root: {
        border: "1px solid gray",
      },
    })
  )(Table);

  const StyledTableCell = withStyles(() =>
    createStyles({
      root: {
        borderBottom: "1px solid gray",
        fontSize: 16,
      },
    })
  )(TableCell);

  const renderBaseStats = (baseStats) => {
    switch (baseStats.name) {
      case "Base HP":
        return baseStats.stats + " HP";
      case "1 Level":
        return baseStats.stats + " HP + " + baseStats.stats + " DMG";
      case "Base Critical Chance":
      case "Base Critical Damage":
      case "Base Evasion Chance":
        return baseStats.stats + "%";
      default:
        return baseStats.stats;
    }
  };

  const renderStatAllocationEffects = (effects) => {
    const effectObj = Object.entries(effects);
    return effectObj.map((effect, i) => {
      return (
        <div key={i}>
          {effect[1]} {effect[0]} {i + 1 < effectObj.length && "& "}
        </div>
      );
    });
  };

  const renderStatsAllocationContent = () => {
    return (
      <>
        <div className="stats__allocation">
          <TableContainer>
            <StyledTable aria-label="simple table">
              <TableBody>
                {STATS_ALLOCATION.map((allocation, id) => (
                  <TableRow key={id}>
                    <StyledTableCell className="fs-3" width={90}>
                      <div className="flex-center-center">
                        {allocation.number}
                        <img
                          className="bot-1 ml-1"
                          src={require(`src/assets/images/hero-details/stats/${allocation.stat}.svg`)}
                          alt={allocation.stat}
                          width={30}
                        />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell className="fs-3">
                      {renderStatAllocationEffects(allocation.effects)}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
          <TableContainer>
            <StyledTable aria-label="simple table">
              <TableBody>
                {BASE_STATS.map((row, id) => (
                  <TableRow key={id}>
                    <StyledTableCell width={200} className="fs-3">
                      {row.name}{" "}
                    </StyledTableCell>
                    <StyledTableCell className="fs-3">{renderBaseStats(row)}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </div>
        <div className="stats__allocation-cap">
          <TableContainer>
            <StyledTable aria-label="simple table">
              <TableBody>
                {STATS_CAP.map((row, id) => (
                  <TableRow key={id}>
                    <StyledTableCell width={200} className="fs-3">
                      {row.name}{" "}
                    </StyledTableCell>
                    <StyledTableCell className="fs-3">{row.stats + "%"}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </div>
      </>
    );
  };

  return (
    <BasicModalContent
      className="title-center"
      customTitle="Stats Allocation"
      content={renderStatsAllocationContent()}
    />
  );
}
