import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import Modal from "src/app/components/Commons/Modals/Modal";
import { setGlobalModal } from "src/app/actions/globalAction";

export default function ErrorModal() {
  const dispatch = useDispatch();
  const { modal } = useSelector((state: any) => state.global);
  const errorModal = modal.error;

  function handleClose() {
    dispatch(setGlobalModal('error'));
  }

  return (
    <Modal
      layer={3}
      title="Error"
      isActive={errorModal.active}
      onClose={handleClose}
    >
      <BasicModalContent
        content={errorModal.data}
        close={handleClose}
      />
    </Modal>
  )
}
