import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { ItemRecipe, CraftingSetRecipe, Material } from "src/app/types/materials";
import { useDispatch, useSelector } from "react-redux";
import { queryUserMaterials } from "src/app/services/api/subgraphService";
import { UserMaterial } from "src/app/types/materials";
import { craft } from "src/app/actions/equipmentAction";
import { RECIPES } from "src/app/configs/equipment/crafting";
import { Avatar, Box, Grid, Tab, Tabs } from "@material-ui/core";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { EQUIPMENT_LABEL, INTERVAL, NON_FEE_INVENTORY_TYPE, RUNE_INDEX } from "src/app/configs/constants";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import { signAndAuthenticateWallet } from "src/app/utils/helpers";
import defaultRune from "src/assets/images/rune/default-rune.png";
import { compareNumbers } from "src/app/utils/calculators";
import { heroes } from "src/app/components/Home/DemoCharacters/Hero/Heroes";
import CraftingFilter from "src/app/components/Equipment/Crafting/CraftingFilter";
import _ from "lodash";
import useBlacksmithData from "src/app/hooks/useBlacksmithData";
import {
  claimCraftedItems,
  craftRune,
  fetchCraftingRewards,
  fetchNonFeeBalance,
  fetchNonFeeInventory,
  fetchNonFeeMaterials,
  levelUpRune,
} from "src/app/services/api/faralandService";
import { CraftedItem, CraftedMaterials, Item, UserItem } from "src/app/types/equipment";
import { claimEquipment } from "src/app/actions/equipmentAction";
import CraftingResultModal from "src/app/components/Equipment/Crafting/CraftingResultModal";
import EquipmentClaimHistory from "src/app/components/Equipment/Crafting/EquipmentClaimHistory";
import EquipmentCraftingHistory from "src/app/components/Equipment/Crafting/EquipmentCraftingHistory";
import ENV from "src/app/configs/env";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import runeFilter from "src/assets/images/rune/rune-filter.png";
import RecipeItemList from "src/app/components/Equipment/Crafting/RecipeItemList";
import { setGlobalModal } from "src/app/actions/globalAction";
import CraftingRequirements from "src/app/components/Equipment/Crafting/CraftingRequirements";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import RuneCraftingResultModal from "src/app/components/Equipment/Crafting/Rune/RuneCraftingResultModal";
import { createMaterial } from "src/app/factories/materialFactory";
import { RuneLevelUpSection } from "src/app/components/Equipment/Crafting/Rune/RuneLevelUpSection";
import { BlacksmithSection } from "src/app/components/Equipment/Crafting/BlacksmithSection";
import RuneLevelUpResultModal from "src/app/components/Equipment/Crafting/Rune/RuneLevelUpResultModal";
import RuneCraftHistory from "src/app/components/Equipment/Crafting/Rune/RuneCraftingHistory";
import { refreshRune, selectRune } from "src/app/actions/runeAction";
import { MATERIALS } from "src/app/configs/materials/materials";

export default function EquipmentCrafting() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { recipeId } = useParams<{ recipeId: string }>();
  const { address, balance, wallet } = useSelector((state: any) => state.account);
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.EQUIPMENT_CRAFTING);
  const { web3Service } = useSelector((state: any) => state.global);
  const { getBlacksmithData } = useBlacksmithData(address);
  const itemSetRecipe: CraftingSetRecipe = RECIPES[+recipeId];
  const [userMaterials, setUserMaterials] = useState<UserMaterial[]>([]);
  const [userItems, setUserItems] = useState<UserItem[]>([]);
  const [craftingAmount, setCraftingAmount] = useState("1");
  const [selectedStone, setSelectedStone] = useState<number>();
  const [selectedItemRecipe, setSelectedItemRecipe] = useState<ItemRecipe>(itemSetRecipe.items[0]);
  const [error, setError] = useState("");
  const [activeHeroIndex, setActiveHeroIndex] = useState<number>();
  const balanceInterval = useRef<any>();
  const [refresh, setRefresh] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [craftedItems, setCraftedItems] = useState<CraftedItem[]>([]);
  const [craftedRunes, setCraftedRunes] = useState<CraftedMaterials[]>([]);
  const [reclaimableEmpty, setReclaimableEmpty] = useState(false);
  const craftingNumber = +craftingAmount === 0 ? 1 : +craftingAmount;
  const totalFaraAmount = selectedItemRecipe ? selectedItemRecipe.tokenFee * +craftingAmount : 0;
  const isChakraRune = itemSetRecipe.race === "Rune";
  const [tabValue, setTabValue] = useState(0);
  const [isRuneLevelUp, setIsRuneLevelUp] = useState(tabValue === 1);
  const { requiredItemIds, requiredItemAmount, selectedRune, runeRefresh } = useSelector((state: any) => state.rune);
  const [nonFeeFara, setNonFeeFara] = useState(0);

  useEffect(() => {
    setSelectedItemRecipe(itemSetRecipe.items[0]);
  }, [recipeId]); // eslint-disable-line

  useEffect(() => {
    if (!isRuneLevelUp || !selectedRune) return;
    setSelectedItemRecipe(itemSetRecipe.items[selectedRune?.material?.level]);
  }, [selectedRune, isRuneLevelUp]); // eslint-disable-line

  useEffect(() => {
    if (isChakraRune) return;
    setNonFeeFara(balance.FARA);
  }, [balance, isChakraRune]);

  useEffect(() => {
    if (isChakraRune && tabValue === 1) {
      setIsRuneLevelUp(true);
      return;
    }
    setIsRuneLevelUp(false);
  }, [tabValue, isChakraRune]);

  useEffect(() => {
    if (!address || !web3Service) {
      setUserMaterials([]);
      setUserItems([]);
      setCraftedItems([]);
      setCraftedRunes([]);
      return;
    }
    async function _fetchNonFeeFaraBalance(address: string) {
      if (!isChakraRune) {
        setNonFeeFara(balance.FARA);
        return;
      }
      const nonFeeWalletBalance = await fetchNonFeeBalance(address);
      if (nonFeeWalletBalance && nonFeeWalletBalance.success) {
        const nonFeeFaraBalance = nonFeeWalletBalance.totalFara;
        setNonFeeFara(nonFeeFaraBalance);
      }
    }

    _fetchUserOwnMaterials(address);
    _fetchUserOwnEquipment(address);
    _fetchCraftedItems(address, isChakraRune);
    _fetchNonFeeFaraBalance(address);
    balanceInterval.current = setInterval(() => {
      _fetchUserOwnMaterials(address);
      _fetchUserOwnEquipment(address);
      _fetchCraftedItems(address, isChakraRune);
      _fetchNonFeeFaraBalance(address);
    }, INTERVAL.BALANCE);

    return () => {
      clearInterval(balanceInterval.current);
    };
  }, [address, web3Service, isChakraRune, refresh]); // eslint-disable-line

  async function _fetchUserOwnMaterials(address: string) {
    let result;
    if (isChakraRune) {
      result = await fetchNonFeeMaterials(address);
    } else {
      result = await queryUserMaterials(address);
    }
    if (result) setUserMaterials(result.materials);
  }

  async function _fetchUserOwnEquipment(address: string) {
    const items: Item[] = [];

    const result = await fetchNonFeeInventory(NON_FEE_INVENTORY_TYPE.ITEM, address);
    if (result) {
      result.equipment.forEach((userItem: UserItem) => {
        items.push(EQUIPMENT[userItem.item.id]);
      });
      const skillItems = items
        .filter((item: Item) => {
          return item && EQUIPMENT_LABEL[item.type] === "Skill Book";
        })
        .map((item: Item) => {
          return item.id;
        });
      setUserItems(
        result.equipment.filter((inventoryItem: UserItem) => {
          return !skillItems.includes(inventoryItem.item.id);
        })
      );
    }
  }

  async function _fetchCraftedItems(address: string, isChakraRune: boolean) {
    const rewards = await fetchCraftingRewards(address, isChakraRune);
    if (rewards) {
      if (isChakraRune) {
        setCraftedRunes(rewards as CraftedMaterials[]);
      } else {
        setCraftedItems(rewards as CraftedItem[]);
      }
    }
  }

  async function handleClaimEquipment(itemsClaim: CraftedItem[] | CraftedMaterials[], wallet: string) {
    setClaimLoading(true);
    const res = await claimCraftedItems(itemsClaim, wallet);
    if (res && res.length > 0) {
      dispatch(
        claimEquipment(res, res[0].nonce, () => {
          setClaimLoading(false);
          setRefresh((prev) => !prev);
        })
      );
    }
  }

  const handleTabChange = (_e: any, newValue: number) => {
    setError("");
    setTabValue(newValue);
  };

  const onSelectHero = (idx: number | undefined) => {
    if (idx === activeHeroIndex) {
      setActiveHeroIndex(undefined);
    } else {
      setActiveHeroIndex(idx);
    }
  };

  function handleReclaimableCheck(value: boolean) {
    setReclaimableEmpty(value);
  }

  async function handleCraftRune(
    walletAddress: string,
    amount: number,
    requiredMaterial: number[],
    requiredMaterialAmount: number[],
    requiredItem: number[],
    requiredItemAmount: number[]
  ) {
    const res = await craftRune(
      walletAddress,
      amount,
      requiredMaterial,
      requiredMaterialAmount,
      requiredItem,
      requiredItemAmount
    );
    if (res === 401) {
      const response = await signAndAuthenticateWallet(wallet, address);
      if (response.success) {
        handleCraftRune(
          walletAddress,
          amount,
          requiredMaterial,
          requiredMaterialAmount,
          requiredItem,
          requiredItemAmount
        );
      } else {
        dispatch(
          setGlobalModal("error", {
            active: true,
            data: <div>Unable to authenticate your wallet. Please try again</div>,
          })
        );
      }
      return;
    }
    if (res.success) {
      const runesCrafted = res.result.map((data) => createMaterial(data));
      modalService.show(BasicModalContent, {
        content: <RuneCraftingResultModal runesCrafted={runesCrafted} getBlacksmithData={getBlacksmithData} />,
        layer: 3,
      });
      setCraftingAmount("1");
      setRefresh((prev) => !prev);
    }
  }

  async function handleLevelUpRune(
    walletAddress: string,
    runeId: number,
    requiredMaterial: number[],
    requiredMaterialAmount: number[],
    requiredItem: number[],
    requiredItemAmount: number[],
    inUnclaimed: boolean
  ) {
    const res = await levelUpRune(
      walletAddress,
      runeId,
      requiredMaterial,
      requiredMaterialAmount,
      requiredItem,
      requiredItemAmount,
      inUnclaimed
    );
    if (res === 401) {
      const response = await signAndAuthenticateWallet(wallet, address);
      if (response.success) {
        handleLevelUpRune(
          walletAddress,
          runeId,
          requiredMaterial,
          requiredMaterialAmount,
          requiredItem,
          requiredItemAmount,
          inUnclaimed
        );
      } else {
        dispatch(
          setGlobalModal("error", {
            active: true,
            data: <div>Unable to authenticate your wallet. Please try again</div>,
          })
        );
      }
      return;
    }
    if (res.success) {
      const runeCreated = createMaterial(res.result.rune);
      modalService.show(BasicModalContent, {
        content: (
          <RuneLevelUpResultModal
            isSuccess={res?.result?.status}
            runeCreated={runeCreated}
            getBlacksmithData={getBlacksmithData}
          />
        ),
        close: () => {
          dispatch(selectRune(null));
          modalService.close();
        },
        layer: 3,
      });
      setRefresh((prev) => !prev);
      dispatch(refreshRune(!runeRefresh));
    }
  }
  async function craftItem() {
    if (!isChakraRune && needApprove) return sendApproveTx();
    const hasEnoughMaterial = selectedItemRecipe.materialIds.reduce((acc, materialId, idx) => {
      const userMaterial: UserMaterial[] = userMaterials?.filter(
        (userMaterial: UserMaterial) => userMaterial.id === +materialId
      );
      return acc && selectedItemRecipe.materialAmounts[idx] * craftingNumber <= (userMaterial[0]?.amount ?? 0);
    }, true);

    const hasEnoughEquipment = selectedItemRecipe.requiredItemAmount?.reduce((acc, amount, idx) => {
      return acc && amount * craftingNumber <= (requiredItemAmount[idx] ?? 0);
    }, true);

    if (!selectedItemRecipe) return;
    if (+craftingAmount === 0) {
      return setError("The crafting amount should be at least 1.");
    }
    if (compareNumbers(nonFeeFara, totalFaraAmount) === -1) {
      return setError("Insufficient FARA balance");
    }
    if (!hasEnoughMaterial) {
      return setError("Insufficient required materials.");
    }
    if (isChakraRune && !hasEnoughEquipment) {
      return setError("Insufficient required equipment");
    }

    setError("");

    const hasEnoughEnhancementStone =
      selectedStone && (userMaterials?.find((u) => u.id === selectedStone)?.amount ?? 0) >= +craftingAmount;

    if (selectedStone && !hasEnoughEnhancementStone) {
      modalService.show(BasicModalContent, {
        content: (
          <div style={{ textAlign: "center" }}>
            You don't have enough enhanced stone to craft. The chosen enhanced stone will not be used.
          </div>
        ),
        onSubmit: () => {
          if (hasEnoughMaterial && Number.isInteger(+craftingAmount)) {
            dispatch(craft(selectedItemRecipe.id, +craftingAmount, 0, openCraftingResultModal));
          }
        },
        closeOnSubmit: true,
      });
      return;
    }
    if (isChakraRune && !isRuneLevelUp) {
      await handleCraftRune(
        address,
        +craftingAmount,
        selectedItemRecipe.materialIds,
        selectedItemRecipe.materialAmounts.map((amount) => amount * +craftingAmount),
        requiredItemIds.filter((id) => id !== null),
        selectedItemRecipe.requiredItemAmount?.map((amount) => amount * +craftingAmount) ?? []
      );
    } else if (isRuneLevelUp) {
      modalService.show(BasicModalContent, {
        content: (
          <div className="">
            <p>
              Leveling up comes with a risk of failure. In the event of failure,{" "}
              {MATERIALS[selectedRune?.material?.id]?.displayName} Level {MATERIALS[selectedRune?.material?.id]?.level}
              {MATERIALS[selectedRune?.material?.id]?.level === 1
                ? " will stay the same"
                : ` will be reduced to Level ${Math.floor(MATERIALS[selectedRune?.material?.id]?.level / 2)}`}
            </p>
            <p className="text-red mt-3">Do you still wish to proceed with the level up?</p>
          </div>
        ),
        submitText: "Continue",
        onSubmit: async () => {
          await handleLevelUpRune(
            address,
            selectedRune?.material?.id,
            selectedItemRecipe.materialIds,
            selectedItemRecipe.materialAmounts.map((amount) => amount * +craftingAmount),
            requiredItemIds.filter((id) => id !== null),
            selectedItemRecipe.requiredItemAmount?.map((amount) => amount * +craftingAmount) ?? [],
            selectedRune.inUnclaimed
          );
          dispatch(refreshRune(!runeRefresh));
        },
      });
    } else {
      dispatch(craft(selectedItemRecipe.id, +craftingAmount, selectedStone ?? 0, openCraftingResultModal));
    }
  }

  function openCraftingResultModal(txHash: string) {
    modalService.show(BasicModalContent, {
      content: (
        <CraftingResultModal txHash={txHash} itemSetRecipe={itemSetRecipe} getBlacksmithData={getBlacksmithData} />
      ),
      layer: 3,
    });
    setSelectedStone(undefined);
    setCraftingAmount("1");
    setRefresh((prev) => !prev);
  }

  function redirectToEquipmentDetails(item: Item) {
    history.push(`/equipment/${item.id}`);
  }

  function redirectToMaterialDetails(material: Material) {
    history.push(`/material/${material.id}`);
  }

  function showRuneInfoModal() {
    modalService.show(BasicModalContent, {
      content: (
        <div className="fs-3">
          <h3 className="mt-1 mb-3 text-yellow">What is a Chakra Rune?</h3>
          <div>
            <div>
              It is a type of item that can be attached to the hero's body part. Through this, it will enhance the
              hero's strength to a new level, thereby increasing the hero's value.
            </div>
            <div> Additionally, they can be used as materials for another feature in the future.</div>
            <h3 className="mt-3 mb-3 text-yellow">Chakra Rune Info</h3>
            <div>
              There are a total of 7 types of Runes:
              <div>
                - <b>Laguz Rune</b>: Enhances the hero's HP
              </div>
              <div>
                - <b>Eihwaz Rune</b>: Enhances the hero's Physical Defense
              </div>
              <div>
                - <b>Wunjo Rune</b>: Enhances the hero's Magical Defense
              </div>
              <div>
                - <b>Sowilo Rune</b>: Enhances the hero's Passive
              </div>
              <div>
                - <b>Algiz Rune</b>: Enhances the hero's Active
              </div>
              <div>
                - <b>Uruz Rune</b>: Enhances the hero's Normal Attack
              </div>
              <div>
                - <b>Tiwaz Rune</b>: Enhances the hero's Elemental Counter
              </div>
            </div>
            <div className="mt-1">
              Rune has a total of 10 levels, and the higher the level, the greater its strength.
            </div>
            <div className="mt-1">
              Limit: A hero can only equip 3 runes of the same type, regardless of their level.
            </div>
            <div>
              For example, A hero can only equip 3 Laguz Runes, even if they had a fourth Laguz Rune with a different
              level.
            </div>
            <div className="mt-1">
              You need a body part with a certain quality to have a rune slot, and then you can unlock it to insert a
              rune. Below are the requirements:
            </div>
            <div className="mt-1">
              <div>
                - <b className="rarity-mythical">Mythical</b>: 3 Rune slots
              </div>
              <div>
                - <b className="rarity-legendary">Legendary</b>: 2 Rune slots
              </div>
              <div>
                - <b className="rarity-epic">Epic</b>: 1 Rune slot
              </div>
              <div>
                - <b className="rarity-rare">Rare</b>: 0 Rune slots
              </div>
            </div>
          </div>
        </div>
      ),
    });
  }

  return (
    <div className="item-crafting slide-up">
      <div className="container">
        <Box className="item-crafting__race-filter">
          <div className="heroes__divider" />
          <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={2}>
            <div className="heroes__thumbnail">
              {heroes.map((h, idx) => (
                <Avatar
                  key={idx}
                  onClick={() => onSelectHero(idx)}
                  src={h.thumbnail}
                  sizes="lg"
                  className={clsx(
                    "heroes__thumbnail-icon",
                    idx === activeHeroIndex && "heroes__thumbnail-icon--active"
                  )}
                />
              ))}
              <Avatar
                onClick={() => onSelectHero(RUNE_INDEX)}
                src={runeFilter}
                sizes="lg"
                className={clsx(
                  "heroes__thumbnail-icon",
                  activeHeroIndex === RUNE_INDEX && "heroes__thumbnail-icon--active"
                )}
              />
            </div>
          </Box>
          <div className="heroes__divider" />
        </Box>
        <Grid container spacing={1} justifyContent="center" className="item-crafting__content">
          <Grid item lg={3} className="item-crafting__content-navigation">
            <CraftingFilter recipeId={recipeId} heroIndex={activeHeroIndex} onOpenInfo={showRuneInfoModal} />
            <BlacksmithSection selectedItemRecipe={selectedItemRecipe} isRuneLevelUp={isRuneLevelUp} />
          </Grid>
          <Grid item lg={6}>
            {isChakraRune && (
              <Tabs value={tabValue} onChange={handleTabChange} className="item-crafting__rune-tab" variant="fullWidth">
                <Tab label={"CRAFT RUNE"} />
                <Tab label={"LEVEL UP"} />s
              </Tabs>
            )}
            {isChakraRune && tabValue === 1 ? (
              <RuneLevelUpSection onOpenInfo={showRuneInfoModal} />
            ) : (
              <div className="item-crafting__slideshow">
                {itemSetRecipe.items.map((itemRecipe, index) => {
                  if (itemSetRecipe.race === "Rune") {
                    return (
                      <img
                        key={index}
                        alt=""
                        src={defaultRune}
                        className={`item-crafting__slideshow-item item-crafting__slideshow-item${
                          selectedItemRecipe.id !== itemRecipe.id && "--hidden"
                        }`}
                        style={{ zIndex: selectedItemRecipe.id === itemRecipe.id ? 2 : 0 }}
                      />
                    );
                  }
                  const item = EQUIPMENT[itemRecipe.itemIds[0]];
                  const itemImage = `${_.kebabCase(itemSetRecipe.name)}/${
                    itemRecipe.imageName ?? _.kebabCase(EQUIPMENT_LABEL[item.type])
                  }.png`;
                  return (
                    <img
                      key={index}
                      alt=""
                      src={require(`src/assets/images/crafting/sets/${itemImage}`)}
                      className={`item-crafting__slideshow-item item-crafting__slideshow-item${
                        selectedItemRecipe.id !== itemRecipe.id && "--hidden"
                      }`}
                      style={{ zIndex: selectedItemRecipe.id === itemRecipe.id ? 2 : 0 }}
                    />
                  );
                })}
              </div>
            )}

            {!isChakraRune && (
              <RecipeItemList
                selectedItemRecipe={selectedItemRecipe}
                itemSetRecipe={itemSetRecipe}
                setSelectedItemRecipe={setSelectedItemRecipe}
              />
            )}
          </Grid>
          <Grid item lg={3}>
            <CraftingRequirements
              selectedItemRecipe={selectedItemRecipe}
              userMaterials={userMaterials}
              userItems={userItems}
              craftingNumber={craftingNumber}
              isChakraRune={isChakraRune}
              handleAction={craftItem}
              error={error}
              setError={setError}
              faraBalance={nonFeeFara}
              craftingAmount={craftingAmount}
              setCraftingAmount={setCraftingAmount}
              isRuneLevelUp={isRuneLevelUp}
            />
          </Grid>
        </Grid>
        {isChakraRune && craftedRunes && craftedRunes.length > 0 && (
          <div className="mt-5">
            <div className="cm-table__header">
              <div className="cm-table__title">Crafted Runes</div>
            </div>
            <div className="inventory item-crafting__inventory nice-scroll nice-scroll--small">
              {craftedRunes.map((craftedRune: CraftedMaterials, index: number) => {
                console.log(craftedRune);
                return (
                  <MaterialIcon
                    key={index}
                    className="item-crafting__inventory--item"
                    material={craftedRune.material}
                    showBalance={true}
                    closeOnSubmit={true}
                    submitText="Detail"
                    hideSelectedBorder
                    amount={craftedRune.quantity}
                    onSubmit={() => redirectToMaterialDetails(craftedRune.material)}
                  />
                );
              })}
            </div>
            <div className="flex-column-center">
              <div
                className={`item-crafting__claim btn mt-4 mb-2 ${(claimLoading || !reclaimableEmpty) && "disabled"}`}
                onClick={() => handleClaimEquipment(craftedRunes, address)}
              >
                Claim Crafted Runes
              </div>
              {!reclaimableEmpty && (
                <div className="warning-text fs-3">
                  Please reclaim your remaining Runes in the "Claim History" below first
                </div>
              )}
            </div>
          </div>
        )}
        {craftedItems && craftedItems.length > 0 && (
          <div className="mt-5">
            <div className="cm-table__header">
              <div className="cm-table__title">Crafted Items</div>
            </div>
            <div className="inventory item-crafting__inventory nice-scroll nice-scroll--small">
              {craftedItems.map((craftedItem: CraftedItem, index: number) => {
                return (
                  <ItemIcon
                    key={index}
                    className="item-crafting__inventory--item"
                    item={craftedItem.item}
                    showBalance={true}
                    closeOnSubmit={true}
                    submitText="Detail"
                    hideSelectedBorder
                    amount={craftedItem.quantity}
                    onSubmit={() => redirectToEquipmentDetails(craftedItem.item)}
                  />
                );
              })}
            </div>
            <div className="flex-column-center">
              <div
                className={`item-crafting__claim btn mt-4 mb-2 ${(claimLoading || !reclaimableEmpty) && "disabled"}`}
                onClick={() => handleClaimEquipment(craftedItems, address)}
              >
                Claim Crafted Items
              </div>
              {!reclaimableEmpty && (
                <div className="warning-text fs-3">
                  Please reclaim your remaining items in the "Claim History" below first
                </div>
              )}
            </div>
          </div>
        )}
        {isChakraRune ? (
          <>
            <RuneCraftHistory />
          </>
        ) : (
          <>
            <EquipmentCraftingHistory />
          </>
        )}
        <EquipmentClaimHistory refresh={refresh} handleReclaimableCheck={handleReclaimableCheck} />
      </div>
    </div>
  );
}
