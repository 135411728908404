import React, { useEffect, useState } from "react";
import submitButton from "src/assets/images/event/btn-submit.png";
import downloadGuidelineButton from "src/assets/images/event/btn-guideline-download.png";
import firstPrize from "src/assets/images/event/first-prize.png";
import secondPrize from "src/assets/images/event/second-prize.png";
import thirdPrize from "src/assets/images/event/third-prize.png";
import prizeDecor from "src/assets/images/event/prize-decor.png";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
import greenCrystal from "src/assets/images/decors/gems/gem-green.svg";
import purpleCrystal from "src/assets/images/decors/gems/gem-purple.svg";
import orangeCrystal from "src/assets/images/decors/gems/gem-orange.svg";
import prevButtonImage from "src/assets/images/event/btn-prev.png";
import nextButtonImage from "src/assets/images/event/btn-next.png";
import voteImage from "src/assets/images/event/vote.svg";
import sortIcon from "src/assets/images/event/sort.svg";
import artIcon from "src/assets/images/event/art.svg";
import timelineImage from "src/assets/images/event/timeline.png";
import judgeLeTrongDao from "src/assets/images/event/judges/le-trong-dao.png";
import judgeNguyenMinhHoang from "src/assets/images/event/judges/nguyen-minh-hoang.png";
import judgeDaoTuanViet from "src/assets/images/event/judges/dao-tuan-viet.png";
import judgeActiveFrame from "src/assets/images/event/judge-active.png";
import judgeInfoFrame from "src/assets/images/event/judge-info.png";
import sponsorsImage from "src/assets/images/event/sponsors.png";
import { Box, Modal } from "@material-ui/core";
import ENV from "src/app/configs/env";
import useWindowDimensions from "src/app/hooks/useWindowDimensions";
import { fetchEventCollections } from "src/app/services/api/faralandService";
import { useQueryString } from "use-route-as-state";
import { Project } from "src/app/types/event";
import { timeAgo } from "src/app/utils/helpers";
// import { useSelector } from "react-redux";
import _ from "lodash";
import Rule from "src/app/components/Event/Rule";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";

const sortByTypes = ["New", "Most Liked"];
const judges = [
  {
    name: "Mr. Lê Trọng Đạo",
    desc: "With 4+ years of experience as a Senior Illustrator at Riot Games and past partnerships with Valve, Rovio, Tencent, Moontoon, etc., he is currently the Founder of Bubble Cat. Besides, Mr. Le Trong Dao has also been a judge for multiple contests from Huion and Mytheria. His ArtStation channel also attracted a lot of attention from the Artist community with over 115,000 followers: https://www.artstation.com/daole",
    image: judgeLeTrongDao,
  },
  {
    name: "Mr. Đào Tuấn Việt",
    desc: "Having graduated from the Graphics Design major of the Hanoi University of Industrial Fine Arts, he has gained pro experience in many positions, namely Lead Character Designer, Lead Layout Designer at Vinata Animation Studio. Especially, Mr. Viet is currently the Lead of the NFT Design Department at Moon Knight Labs.",
    image: judgeDaoTuanViet,
  },
  {
    name: "Mr. Nguyễn Minh Hoàng",
    desc: "A graduate from the National University of Art Education, with a Master Degree in Graphics Design. He has diverse experiences in the field of arts, from being a lecturer of graphics design at BTEC FPT, VTC Academy, Master Media, etc., to being a Lead in 3D Design at Sanding JSC. Plus, he is now a Technical Artist at Gameloft.",
    image: judgeNguyenMinhHoang,
  },
];

export default function Event() {
  const dimensions = useWindowDimensions();
  // const { address, wallet } = useSelector((state: any) => state.account);
  const [queryString, setQueryString] = useQueryString();
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [collectionsPerPage, setCollectionsPerPage] = useState(12);
  const [sortByIndex, setSortByIndex] = useState(0);
  const [activeJudgeId, setActiveJudgeId] = useState(0);

  useEffect(() => {
    fetchCollections();
    setTimeout(() => checkResult(queryString.result), 200);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (dimensions.width < 600) {
      setCollectionsPerPage(1);
    } else if (dimensions.width < 900) {
      setCollectionsPerPage(6);
    } else {
      setCollectionsPerPage(12);
    }
    setCurrentPage(0);
  }, [dimensions]);

  useEffect(() => {
    viewProject(queryString.id && !queryString.result ? +queryString.id : undefined);
  }, [projects]); // eslint-disable-line

  function checkResult(result: string) {
    if (result === "success") {
      modalService.show(BasicModalContent, {
        content: <div className="align-center fs-4">Vote successfully!</div>,
        close: () => {
          setQueryString(_.omit(queryString, ["result"]));
          window.location.reload();
        },
      });
    } else if (result === "failed") {
      modalService.show(BasicModalContent, {
        content: <div className="align-center fs-4 error-text">Vote failed!</div>,
        close: () => {
          setQueryString(_.omit(queryString, ["result"]));
          window.location.reload();
        },
      });
    }
  }

  function nextProject() {
    const nextIndex = Math.max(currentProjectIndex - 1, 0);
    setCurrentProjectIndex(nextIndex);
    viewProject(projects[nextIndex].projectId);
  }

  function previousProject() {
    const previousIndex = Math.min(currentProjectIndex + 1, projects.length - 1);
    setCurrentProjectIndex(previousIndex);
    viewProject(projects[previousIndex].projectId);
  }

  function chooseProject(index: number) {
    setCurrentProjectIndex(index);
    viewProject(projects[index].projectId);
  }

  function listOfPreviewProjects() {
    const indices: number[] = [];
    for (let i of _.range(0, 5)) {
      const value = (currentProjectIndex + i) % projects.length;
      if (i !== 0 && value === currentProjectIndex) {
        break;
      }
      indices.push(value);
    }
    return indices;
  }

  async function fetchCollections() {
    const result = await fetchEventCollections();
    setProjects(result);
  }

  function viewProject(id?: number) {
    if (id !== undefined) {
      setQueryString({ ...queryString, id: id.toString() });
      setSelectedProject(projects?.find((p) => p.projectId === id));
    } else {
      setQueryString({});
      setSelectedProject(undefined);
    }
  }

  // async function voteWithWallet(projectId: number) {
  //   const signData = await wallet.signData(
  //     address,
  //     { projectId: projectId },
  //     {
  //       Group: [{ name: "projectId", type: "uint256" }],
  //     }
  //   );

  //   const result = await voteProjectWithWallet(projectId, signData.sign, signData.msgParams);
  //   setSelectedProject(undefined);
  //   checkResult(result.result);
  // }

  function cycleSortBy() {
    setSortByIndex((sortByIndex + 1) % sortByTypes.length);
    setProjects(_.orderBy(projects, (p) => {
      switch (sortByTypes[(sortByIndex + 1) % sortByTypes.length]) {
        case "New":
          return p.updatedAt;
        case "Most Liked":
          return p.totalVote;
      }
    }, "desc"));
  }

  function cycleNumber(n: number, max: number) {
    if (n < 0) {
      return max;
    }
    if (n > max) {
      return 0;
    }
    return n;
  }

  return (
    <div className="event">
      <div className="event__sponsors">
        <img src={sponsorsImage} alt="" />
      </div>
      <div className="event__header">
        BRING YOUR ART TO <span>FARALAND!</span>
        <FloatingCrystal speed={-2} src={greenCrystal} style={{ top: 10, left: 5 }} />
      </div>
      <div className="event__content">
        <div className="event__content-desc">
          Welcome all Faradians to LET YOUR ART SHINE - Faraland's set items design event. Are you talented? Do you have a big love for Faraland? Want your designs to appear in the game? Desire to win huge prizes? If the answer is YES, Well then, congratulations, this is a great opportunity that Faraland offers for you!
        </div>
        <div className="event__content-desc event__content-desc--variant">
          We open this contest with the aim to build a healthy and sustainable environment to help local artists show their talents via unique and impressive designs. Through community vote, as well as from our Judges, your "hero outfit" design will have a chance to be featured in the game Faraland.
        </div>
        <br /> <br />
        <a href="https://forms.gle/jjn9CMdaopt9bqU27" target="_blank" rel="noopener noreferrer">
          <img src={submitButton} className="home__button" alt="" />
        </a>
      </div>

      <div className="event__header">
        <span>REWARDS</span>
        <FloatingCrystal speed={-2} src={orangeCrystal} style={{ top: 100, right: 5 }} />
      </div>
      <div className="event__content event__reward">
        <div className="event__reward-text">
          The community will be voting for their favorite entries on the Home page by connecting their social media like Twitter, Google and Facebook. Each account is limited to only 1 vote for 1 favorite piece of work only.
        </div>
        <div className="event__reward-wrapper">
          <img src={prizeDecor} className={`event__reward-image event__reward-image--decor`} alt="" />
          <img src={firstPrize} className={`event__reward-image event__reward-image--0`} alt="" />
          <img src={secondPrize} className={`event__reward-image event__reward-image--1`} alt="" />
          <img src={thirdPrize} className={`event__reward-image event__reward-image--2`} alt="" />
          <div className="event__reward-prizes">
            <div className={`event__reward-prize event__reward-prize--active`}>
              <div className={`event__reward-title event__reward-title--active`}>WINNER</div>
              <div className="event__reward-desc">1000 BUSD</div>
              <div className="event__reward-subdesc">
                *with a special commission of 2% of the Lady of the Lake fees within 2 months.
                (Can be up to 50,000 BUSD)
              </div>
            </div>
            <div className={`event__reward-prize`}>
              <div className={`event__reward-title event__reward-title--2`}>FIRST RUNNER-UP</div>
              <div className="event__reward-desc event__reward-desc--2">500 BUSD</div>
            </div>
            <div className={`event__reward-prize`}>
              <div className={`event__reward-title event__reward-title--3`}>SECOND RUNNER-UP</div>
              <div className="event__reward-desc">300 BUSD</div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="event__header">
        HALL OF <span>FAME</span>
      </div>
      <div className="event__content" style={{ maxWidth: 1600 }}>
        <div>Our most promising Participants</div>
        <div className="event__hof">
          <div className="event__hof-rear">
            <img src={collectionsFrame} />
          </div>

          <div className="event__content-collection event__hof-current">
            <img src={prevButtonImage} />
            <div style={{ minWidth: 0 }}>
              <div className="event__content-collection--viewer">
                <img style={{ maxWidth: "100%" }} src={collectionsFrame} />
                <div className="event__content-collection--viewer-desc">
                  <div>Name of Artwork</div>
                  <div>Name of Artist</div>
                </div>
              </div>
            </div>
            <img src={nextButtonImage} />
          </div>
          <div className="event__hof-rear">
            <img src={collectionsFrame} />
          </div>
        </div>
        <div className="mt-3">Current #1: 999 votes</div>
      </div> */}

      {projects.length !== 0 && (
        <>
          <div className="event__header">
            <span>COLLECTIONS</span>
          </div>
          <div className="event__content" style={{ maxWidth: 1000 }}>
            <div>Support your favourite Artwork now!</div>
            <Box className="event__collections">
              <img
                className="event__collections-navbtn"
                src={prevButtonImage}
                alt=""
                style={{ display: collectionsPerPage < projects.length ? "initial" : "none", opacity: currentPage === 0 ? 0.5 : 1 }}
                onClick={() => setCurrentPage(Math.max(0, currentPage - 1))} />
              <Box flex={1}>
                <div className="event__collections-options">
                  <div onClick={cycleSortBy} className="event__collections-options--sort">
                    <img src={sortIcon} alt="" /> Sort by: {sortByTypes[sortByIndex]}
                  </div>
                  <div>Total artwork: {projects.length} <img src={artIcon} alt="" /></div>
                </div>
                <div className="event__collections-container">
                  {projects.slice(currentPage * collectionsPerPage, Math.min(projects.length, collectionsPerPage * (currentPage + 1))).map((p) => (
                    <div key={p.projectId} className="event__collections-item">
                      <div className="event__collections-item--image" onClick={() => viewProject(p.projectId)}>
                        <div>
                          <img src={`${ENV.URL.EVENT_IMAGE_URL}/${p.projectId}/${p.pictureLink[0]}`} alt="" />
                        </div>
                      </div>
                      <div className="event__collections-item--title">
                        <div>{p.projectName}</div>
                        <div>{p.totalVote} <img src={voteImage} alt="" /></div>
                      </div>
                    </div>
                  ))}
                </div>
              </Box>
              <img
                className="event__collections-navbtn"
                src={nextButtonImage}
                alt=""
                style={{
                  display: collectionsPerPage < projects.length ? "initial" : "none",
                  opacity: currentPage === Math.ceil(projects.length / collectionsPerPage) - 1 ? 0.5 : 1
                }}
                onClick={() => setCurrentPage(Math.min(currentPage + 1, Math.ceil(projects.length / collectionsPerPage) - 1))} />
            </Box>
          </div>

          {selectedProject && (
            <Modal
              open
              onClose={() => viewProject()}
              BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.7)" } }}>
              <div className="event__collections-modal">
                <Box display="flex" justifyContent="space-between" className="event__collections-modal-container">
                  <Box
                    className="event__collections-closebtn"
                    onClick={() => viewProject()}>X</Box>
                  <Box display="flex" alignItems="center" className="event__collections-modal--left">
                    <img
                      src={prevButtonImage}
                      className="event__collections-modal--navbtn"
                      onClick={() => nextProject()}
                      style={{ opacity: currentProjectIndex === 0 ? 0.5 : 1 }}
                      alt="" />
                    <div className={`event__collections-modal--image-container ${selectedProject.pictureLink.length === 1 && "event__collections-modal--image-container--center"}`}>
                      {selectedProject.pictureLink.map((link, id) => (
                        <a key={id} href={`${ENV.URL.EVENT_IMAGE_URL}/${selectedProject.projectId}/${link}`} target="_blank" rel="noopener noreferrer">
                          <img
                            key={id}
                            alt=""
                            src={`${ENV.URL.EVENT_IMAGE_URL}/${selectedProject.projectId}/${link}`}
                            className="event__collections-modal--image" />
                        </a>
                      ))}
                    </div>
                    <img
                      src={nextButtonImage}
                      className="event__collections-modal--navbtn"
                      onClick={() => previousProject()}
                      style={{ opacity: currentProjectIndex === projects.length - 1 ? 0.5 : 1 }}
                      alt=""
                    />
                  </Box>
                  <Box className="event__collections-modal--right">
                    <div className="event__collections-modal--separator"></div>
                    <div className="event__collections-modal--title">{selectedProject.projectName}</div>
                    <div className="event__collections-modal--subtitle">{selectedProject.projectAuthor}</div>
                    <div className="event__collections-modal--desc">{selectedProject.description}</div>
                    <div className="event__collections-modal--time">Posted {timeAgo(selectedProject.updatedAt)}</div>
                    <div className="event__collections-modal--vote">
                      <div><img src={voteImage} alt="" /> {selectedProject?.totalVote} votes</div>
                    </div>
                    {/* <div className="event__collections-modal--actions">
                      <a href={`${ENV.URL.FARALAND}/api/event/vote/facebook/${selectedProject.projectId}`}><img src={facebookImage} alt="" /> Vote</a>
                      <a href={`${ENV.URL.FARALAND}/api/event/vote/google/${selectedProject.projectId}`}><img src={googleImage} alt="" /> Vote</a>
                      <a href={`${ENV.URL.FARALAND}/api/event/vote/twitter/${selectedProject.projectId}`}><img src={twitterImage} alt="" /> Vote</a>
                      {address && <div onClick={() => voteWithWallet(selectedProject.projectId)}>Wallet Vote</div>}
                    </div> */}
                  </Box>
                </Box>
                <div className="event__content-collection--thumbnail">
                  {listOfPreviewProjects().map((id) => (
                    <div className="event__content-collection--wrapper" key={id}>
                      <div>
                        <img
                          src={`${ENV.URL.EVENT_IMAGE_URL}/${projects[id].projectId}/${projects[id].pictureLink[0]}`}
                          alt=""
                          onClick={() => chooseProject(id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          )}
        </>
      )
      }

      <div className="event__header">
        COMPETITION <span>RULES</span>
      </div>
      <FloatingCrystal speed={-2} src={purpleCrystal} style={{ left: 5 }} />
      <div className="event__content" style={{ maxWidth: 1050 }}>
        <a href="https://drive.google.com/drive/u/0/folders/1RJK2RlW6RbzDW6Ijk9jvva5sbH_Aa-Tx" target="_blank" rel="noopener noreferrer">
          <img src={downloadGuidelineButton} className="home__button" alt="" />
        </a>

        <Rule number={1} title="Entry Admission Rule">
          <div>
            Design a special outfit/costume for one of the 7 races of Faraland: Human, Elf, Orc, Fairy, Dragonborn, Angel, and Demon according to the theme of “XXX”, the product must conform to the following factors:
          </div>
          <div>
            <br />
            <b>A. Faraland-guided designs: </b> <span>(*)</span>
            <br />
            Faraland will be providing contestants with PSD templates (final + icon) and original design files of the 7 races to ensure maximum utility during the production. The product must be designed on the template provided.
          </div>
          <div>
            <br />
            <b>B. Personal designs:</b> <span>(*)</span>
            <br />
            1. A final [.PNG] file for preview (3 tiers)
            <br />
            2. A [.PSD] file (splitted according to the guidelines - 3 tiers)
            <br />
            3. An icon for each piece of equipment.
            <br />
            Note: Faraland’s equipment comes in 7 types: Main weapon, Sub-weapon, Headwear, Body armor, Pants, Gloves, Footwears.
          </div>
          <div>
            <br />
            <b>C. Contents:</b> <span>(*)</span>
            <br />
            A brief description of the costume: motivation, backstory, etc..
          </div>
          <div>
            <br />
            <b>D. Bonus:</b>
            <br />
            Short video trailer 5-10s (optional)
          </div>
          <span>
            <i style={{ fontSize: 12 }}>(*): required</i>
          </span>
        </Rule>

        <Rule number={2} title="Submission">
          <div>
            Contestants submit their work by fill in the following form:
            <br />
            <b>Information required:</b>
            <br />
            · Full name:
            <br />
            · Product name:
            <br />
            · Design progression (a screenshot for each phase of design)
            <br />
            · A short introduction about the product (paragraph or video)
          </div>
        </Rule>

        <Rule number={3} title="Voting">
          Qualified entries will be selected and uploaded onto [website]. The official time for the voting round will be from 23:00 Nov 12th to 23:00 Nov 26th, 2022. <br />
          The community will be voting for their favorite entries on the Landing page by connecting their social media like Twitter and Facebook. Each account is limited to only 1 vote for 1 favorite piece of work only.
        </Rule>

        <Rule number={4} title="Grading from Judges">
          Points for entries will be alloted based on the following criteria: <br />
          Creativity, aestheticism, visual effectiveness, all of which will be judged by the Faraland team. This will account for 50% of the final score. <br />
          Community votes (as shown on the website by the end of the voting round): 50% of the final score. <br />
          - If final scores of certain entries are equal, the winner will be decided based on scores given by judges.
        </Rule>
      </div>

      <div className="event__header">ENTRY <span>REQUIREMENTS</span></div>
      <div className="event__content" style={{ textAlign: "left", maxWidth: 900 }}>
        · Creativity within boundaries, without violating social, cultural and religious customs, and non-explicit towards viewers.
        <br />· Plagiarism of any other artist’s work is strictly prohibited .
        <br />· Design a costume set with 30% of the Faraland character’s features retained so that viewers can still recognize the Hero.
        <br />· Products can be designed on 3D applications, but need to be exported according to the prerequisite format.
        <br />· All entries MUST BE COLORED.
        <br />· Records of designing stages and progresses are compulsory (preferably videos, timelapses, which will be considered to get featured on Faraland’s social media)
        <br />· In all cases, the organizers are to make the ultimate decision.
        <br />· Fraudulent entries will be removed from the contest without notice.
      </div>

      <div className="event__header">THE <span>JUDGES</span></div>
      <div className="event__content" style={{ textAlign: "left", maxWidth: 900 }}>
        <div className="event__judge-line">
          <img
            alt=""
            src={judges[cycleNumber(activeJudgeId - 1, judges.length - 1)].image}
            className="event__judge-line--image"
            onClick={() => setActiveJudgeId(cycleNumber(activeJudgeId - 1, judges.length - 1))} />
          <div key={activeJudgeId} className="event__judge-active slide-up">
            <img alt="" src={judges[activeJudgeId].image} className="event__judge-active--image" />
            <img alt="" src={judgeActiveFrame} className="event__judge-active--background" />
          </div>
          <img
            alt=""
            src={judges[cycleNumber(activeJudgeId + 1, judges.length - 1)].image}
            className="event__judge-line--image"
            onClick={() => setActiveJudgeId(cycleNumber(activeJudgeId + 1, judges.length - 1))} />
        </div>
        <div className="event__judge-info">
          <img alt="" src={judgeInfoFrame} className="" />
          <div className="event__judge-info--text">
            <div className="event__judge-info--title">{judges[activeJudgeId].name}</div>
            <div className="event__judge-info--desc mt-1">
              {judges[activeJudgeId].desc}
            </div>
          </div>
        </div>
      </div>

      <div className="event__header"><span>TIMELINE</span></div>
      <div className="event__content event__timeline" style={{ textAlign: "left", maxWidth: 900 }}>
        <img alt="" src={timelineImage} />
      </div>

      <div className="event__header">GENERAL <span>TERMS</span></div>
      <div className="event__content" style={{ textAlign: "left", maxWidth: 900 }}>
        · In all cases, the organizers will be the ones to make the ultimate decision.
        <br />· Products must be designed out of artists’ creativity. Any form of plagiarism will lead to immediate disqualification.
        <br />· Faraland has every right to use the entries in promotions during the event as well as in future design ideas.
        <br />· Any contestant with 2 or more entries will be awarded the highest prize.
        <br />· Faraland has every right to make any necessary edit to the work.
        <br />· If materials provided by Faraland are used for any other purpose than for the contest, the Organizers have the right to file a complaint against the violator. All supplied materials are of Faraland’s intellectual property.
        <br />· All personnels from Moon Knight Labs are restricted from the contest under any circumstance. Violations will be strictly disqualified.
        <br />· For any further inquiries on the rules of the contest, please reach us via  contest@faraland.io or hotline: 0866.111.330
      </div>
    </div >
  );
}