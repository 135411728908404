import { combineReducers } from 'redux'
import { persistReducer } from "redux-persist";
import localStorage from 'redux-persist/lib/storage'
import globalReducer from "src/app/reducers/globalReducer";
import accountReducer from "src/app/reducers/accountReducer";
import txReducer from "src/app/reducers/txReducer";
import stakingReducer from "src/app/reducers/stakingReducer";
import knightReducer from "src/app/reducers/knightReducer";
import marketReducer from 'src/app/reducers/marketReducer';
import guildReducer from './guildReducer';
import landReducer from 'src/app/reducers/landReducer';
import runeReducer from 'src/app/reducers/runeReducer';

const rootReducer = combineReducers({
  tx: txReducer,
  staking: stakingReducer,
  global: globalReducer,
  land: persistReducer({
    key: 'land',
    storage: localStorage,
    whitelist: ['selectedLands', 'selection']
  }, landReducer),
  account: persistReducer({
    key: 'account',
    storage: localStorage,
    whitelist: ['address', 'type', 'spaceDomain']
  }, accountReducer),
  knight: persistReducer({
    key: 'knight',
    storage: localStorage,
    whitelist: ['equippable', 'selectedHero', 'refresh']
  }, knightReducer),
  guild: persistReducer({
    key: 'guild',
    storage: localStorage,
    whitelist: ['refresh']
  }, guildReducer),
  market: persistReducer({
    key: 'market',
    storage: localStorage,
    whitelist: ['tabNumber']
  }, marketReducer),
  rune: runeReducer,
});

export default rootReducer
