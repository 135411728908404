import React, { useState, useRef, MouseEvent } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Paper, MenuList, MenuItem, Grow, ClickAwayListener, Popper } from '@material-ui/core';
import { Knight } from "src/app/types/knight";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ACTIONS } from "src/app/configs/constants";
import { renderBackBtn } from "src/app/utils/renderHelpers";

type KnightNavigateProps = {
  knight: Knight
  onOpenPopup: any
  isOwner: boolean
}

export default function KnightHeaderButtons(props: KnightNavigateProps) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { knight, onOpenPopup, isOwner } = props;

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(event: MouseEvent<EventTarget>) {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }

  function onSelectDropdown(event: MouseEvent<EventTarget>, type?: number) {
    handleClose(event)
    if (type) onOpenPopup(type)
  }
  
  function renderButton() {
    if (isOwner) {
      return (
        <>
          <div className="btn" onClick={() => onOpenPopup(ACTIONS.LIST)}>Sell</div>
          <Button
            ref={anchorRef}
            variant="outlined"
            className="knight__btn-more bot-1"
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <MoreHorizIcon />
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={onSelectDropdown}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      {knight.price !== 0 && (
                        <MenuItem onClick={(e: any) => onSelectDropdown(e, ACTIONS.DELIST)}>Delist</MenuItem>
                      )}
                      <MenuItem onClick={(e: any) => onSelectDropdown(e, ACTIONS.TRANSFER)}>Transfer</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )
    }

    if (knight.price) {
      return <div className="btn" onClick={() => onOpenPopup(ACTIONS.BUY)}>Buy now</div>;
    }

    return <div className="btn" onClick={() => onOpenPopup(ACTIONS.OFFER)}>Make Offer</div>;
  }

  return (
    <div className="knight__btn">
      <div>{renderBackBtn(history)}</div>
      <div>
        {renderButton()}
      </div>
    </div>
  )
}