import { Tabs, Tab } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRune } from "src/app/actions/runeAction";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import { INTERVAL, MATERIAL_IDS } from "src/app/configs/constants";
import { MATERIALS } from "src/app/configs/materials/materials";
import { fetchRuneInventory } from "src/app/services/api/faralandService";
import { KnightAttributes } from "src/app/types/attribute";
import { Material, UserMaterial } from "src/app/types/materials";

type RuneSelectionModalProps = {
  selectedSlot: number;
  currentAttributes: KnightAttributes;
  userMaterials: UserMaterial[];
  close: Function;
  onSubmit: Function;
  handleEquipRune: (selectedRune: Material, selectedSlot: number) => void;
  handleUnequipRune: (selectedSlot: number) => void;
};
export const RuneSelectionModal = (props: RuneSelectionModalProps) => {
  const dispatch = useDispatch();
  const { close, selectedSlot, currentAttributes, userMaterials, handleEquipRune, handleUnequipRune } = props;
  const { selectedRune } = useSelector((state: any) => state.rune);
  const [claimedRunes, setClaimedRunes] = useState<UserMaterial[]>([]);
  const { address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const balanceInterval = useRef<any>();
  const title = !currentAttributes[`slot${selectedSlot}`] ? "Choose Rune to Equip" : "";
  const [tabValue, setTabValue] = useState(0);
  const knightToken = userMaterials?.find((material) => material.id === MATERIAL_IDS.KNIGHT_TOKEN);
  const [unequipError, setUnequipError] = useState("");

  const handleTabChange = (_e: any, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!address || !web3Service) {
      setClaimedRunes([]);
      return;
    }
    _fetchRuneInventory(address);
    balanceInterval.current = setInterval(() => {
      _fetchRuneInventory(address);
    }, INTERVAL.BALANCE);

    return () => {
      clearInterval(balanceInterval.current);
    };
  }, [address, web3Service]); // eslint-disable-line

  async function _fetchRuneInventory(address: string) {
    const res = await fetchRuneInventory(address);
    if (res) setClaimedRunes(res);
  }

  const handleClose = () => {
    dispatch(selectRune(null));
    setUnequipError("");
    close();
  };

  const unequipRune = () => {
    if (!knightToken || knightToken?.amount < 2) {
      setUnequipError("Insufficient knight token balance");
      return;
    }
    handleUnequipRune(selectedSlot);
  };

  return (
    <BasicModalContent
      customTitle={title}
      close={handleClose}
      onSubmit={
        selectedRune && (title || tabValue === 1)
          ? () => handleEquipRune(selectedRune.material, selectedSlot)
          : tabValue === 0 && !title
          ? unequipRune
          : undefined
      }
      submitText={tabValue === 0 && !title ? "Unequip" : "Equip"}
      content={
        <>
          {!title && (
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              className="item-crafting__rune-tab no-margin"
              variant="fullWidth"
            >
              <Tab label={"UNEQUIP"} />
              <Tab label={"REPLACE"} />
            </Tabs>
          )}
          <div className="mt-5">
            {!title && tabValue === 0 ? (
              <>
                <div className="fs-5">
                  <b>
                    You will use a Knight Token to unequip
                    <span className="text-9"> {MATERIALS[currentAttributes[`slot${selectedSlot}`]].name}</span> without
                    destroying it. The rune will be back in your non-fee wallet after unequipping.
                  </b>
                </div>
                <div className="flex-center-center mt-3">
                  <MaterialIcon
                    userMaterial={knightToken}
                    material={MATERIALS[MATERIAL_IDS.KNIGHT_TOKEN]}
                    requiredAmount={2}
                  />
                </div>
                <div className="error-text fs-3">Are you sure you want to unequip?</div>
                <div className="error-text fs-3">{unequipError}</div>
              </>
            ) : (
              <>
                <div className="inventory item-crafting__inventory nice-scroll nice-scroll--small">
                  {claimedRunes.map((rune: UserMaterial, index: number) => (
                    <MaterialIcon
                      key={index}
                      userMaterial={rune}
                      className="item-crafting__inventory--item"
                      material={rune.material}
                      closeOnSubmit={true}
                      submitText="Detail"
                      hideAmount
                      showBalance
                      amount={selectedRune?.material?.id === rune.material.id ? 1 : 0}
                      onSelect={() => dispatch(selectRune(rune))}
                    />
                  ))}
                </div>
                {currentAttributes[`slot${selectedSlot}`] ? (
                  <>
                    <div className="warning-text fs-3">
                      This slot already has a Rune. If you equip another rune into this slot, it will destroy the
                      current rune.
                    </div>
                  </>
                ) : (
                  <>
                    <div className="warning-text fs-3">Please deposit your runes into your non-fee wallet first</div>
                    <div className="warning-text fs-3 mt-2">
                      Once equipped, you will need a royal token to remove it or use another rune as a replacement and
                      destroy the old rune.
                    </div>
                  </>
                )}
                {selectedRune && (
                  <div className={`${currentAttributes[`slot${selectedSlot}`] ? "error-text" : ""} mt-2 fs-3`}>
                    Are you sure you want to equip{" "}
                    <span className={!currentAttributes[`slot${selectedSlot}`] ? "text-9 fs-4" : ""}>
                      {selectedRune.material.name}
                    </span>
                    ?
                  </div>
                )}
              </>
            )}
          </div>
        </>
      }
    />
  );
};
