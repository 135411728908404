import React, { useEffect, useState } from "react";
import crystal from "src/assets/images/decors/gems/gem-blue.svg";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
import gameplayInterface from "src/assets/images/instructions/interface.png";
import gameplayMap from "src/assets/images/instructions/gameplay-map.png";
import gameplay1 from "src/assets/images/instructions/gameplay-1.png";
import gameplay2 from "src/assets/images/instructions/gameplay-2.png";
import gameplay3 from "src/assets/images/instructions/gameplay-3.png";
import gameplay4 from "src/assets/images/instructions/gameplay-4.png";
import gameplay5 from "src/assets/images/instructions/gameplay-5.png";
import gameplay6 from "src/assets/images/instructions/gameplay-6.png";
import gameplay7 from "src/assets/images/instructions/gameplay-7.png";
import Title from "../Commons/Title";

const Gameplay = () => {
  const gameplay = [
    {
      image: gameplay1,
    },
    {
      image: gameplay2,
    },
    {
      image: gameplay3,
    },
    {
      image: gameplay4,
    },
    {
      image: gameplay5,
    },
    {
      image: gameplay6,
    },
    {
      image: gameplay7,
    },
  ];
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect((): any => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="instructions-gameplay">
      <FloatingCrystal speed={5} src={crystal} style={{ left: 0, top: 2000, zIndex: -1 }} />
      <Title value="Gameplay Interface" />
      {dimensions.width <= 700 ? (
        <div>
          <img className="instructions-gameplay__interface-map" src={gameplayMap} alt="" />
          <div className="instructions-gameplay__interface">
            {gameplay.map((value) => (
              <img className="instructions-gameplay__interface-map--mobile" src={value.image} alt="" />
            ))}
          </div>
        </div>
      ) : (
        <img className="instructions-gameplay__interface-img" src={gameplayInterface} alt="" />
      )}
    </div>
  );
};

export default Gameplay;
