import { Box } from "@material-ui/core";
import React from "react";
import { heroes } from "src/app/components/Home/DemoCharacters/Hero/Heroes";

const CharacterImages = (props: any) => {
  const { index } = props;
  const activeHero = heroes[index];

  const getFrontHeroImgClass = (race: any) => {
    const name = "hero__img-front";
    if (race === "Demon") return name + " hero__img-front--demon";
    if (race === "Orc") return name + " hero__img-front--orc";
    if (race === "Elf") return name + " hero__img-front--elf";
    if (race === "Fairy") return name + " hero__img-front--fairy";
    if (race === "Human") return name + " hero__img-front--human";
    return name;
  };

  const getBehindHeroImgClass = (race: any) => {
    const name = "hero__img-behind";
    if (race === "Demon") return name + " hero__img-behind--demon";
    if (race === "Orc") return name + " hero__img-behind--orc";
    if (race === "Elf") return name + " hero__img-behind--elf";
    if (race === "Fairy") return name + " hero__img-behind--fairy";
    return name;
  };
  return (
    <Box className="hero__img" key={index}>
      {activeHero.frontImg && (
        <img
          alt="front-character"
          src={activeHero.frontImg}
          className={getFrontHeroImgClass(activeHero.race)}
        />
      )}
      {activeHero.behindImg && (
        <img
          alt="behind-character"
          src={activeHero.behindImg}
          className={getBehindHeroImgClass(activeHero.race)}
        />
      )}
      {activeHero.centerImg && (
        <img
          alt="behind-character"
          src={activeHero.centerImg}
          className={`hero__img-center 
            ${activeHero.race === "Dragonborn"
              ? "hero__img-center--dragon"
              : activeHero.race === "Angel"
                ? "hero__img-center--angel"
                : ""}`}
        />
      )}
      <img
        alt="char-bg"
        src={activeHero.background}
        className="hero__img-background"
      />
    </Box>
  );
};

export default CharacterImages;
