import { EquipmentDismantle } from "src/app/types/equipment";

export const equipmentActionTypes = {
  ROLL_GACHA: "EQUIPMENT.ROLL_GACHA",
  TRANSFER: "EQUIPMENT.TRANSFER",
  UPGRADE: "EQUIPMENT.UPGRADE",
  LIST: "EQUIPMENT.LIST",
  DELIST: "EQUIPMENT.DELIST",
  BUY: "EQUIPMENT.BUY",
  OFFER: "EQUIPMENT.OFFER",
  CANCEL_OFFER: "EQUIPMENT.CANCEL_OFFER",
  TAKE_OFFER: "EQUIPMENT.TAKE_OFFER",
  LIST_EXCHANGE: "EQUIPMENT.LIST_EXCHANGE",
  CANCEL_EXCHANGE: "EQUIPMENT.CANCEL_EXCHANGE",
  TAKE_EXCHANGE_OFFER: "EQUIPMENT.TAKE_EXCHANGE_OFFER",
  BATCH_TRANSFER: "EQUIPMENT.BATCH_TRANSFER",
  CRAFT: "EQUIPMENT.CRAFT",
  THROW_ITEM_GACHA: "EQUIPMENT.THROW_ITEM_GACHA",
  LEVEL_UP_BLACKSMITH: "EQUIPMENT.LEVEL_UP_BLACKSMITH",
  DISMANTLE: "EQUIPMENT.DISMANTLE",
  CONVERT_EXP_TO_BP: "EQUIPMENT.CONVERT_EXP_TO_BP",
  CLAIM: "EQUIPMENT.CLAIM",
  DEPOSIT: "EQUIPMENT.DEPOSIT",
  WITHDRAW: "EQUIPMENT.WITHDRAW",
};

export function rollEquipment(vendorId: number, amount: number, onSuccess: any) {
  return {
    type: equipmentActionTypes.ROLL_GACHA,
    payload: { vendorId, amount },
    callbacks: { onSuccess },
  };
}

export function transferEquipment(from: string, to: string, id: number, amount: number, onSuccess: any) {
  return {
    type: equipmentActionTypes.TRANSFER,
    payload: { from, to, id, amount },
    callbacks: { onSuccess },
  };
}

export function listExchange(srcItemId: number, destItemIds: number[], amount: number, onSuccess: any) {
  return {
    type: equipmentActionTypes.LIST_EXCHANGE,
    payload: { srcItemId, destItemIds, amount },
    callbacks: { onSuccess },
  };
}

export function cancelExchange(srcItemId: number, onSuccess?: any) {
  return {
    type: equipmentActionTypes.CANCEL_EXCHANGE,
    payload: { srcItemId },
    callbacks: { onSuccess },
  };
}

export function takeExchangeOffer(srcItemId: number, amount: number, seller: string) {
  return {
    type: equipmentActionTypes.TAKE_EXCHANGE_OFFER,
    payload: { srcItemId, amount, seller },
  };
}

export function upgradeEquipment(itemId: number, onSuccess: any) {
  return {
    type: equipmentActionTypes.UPGRADE,
    payload: { itemId },
    callbacks: { onSuccess },
  };
}

export function list(itemId: number, price: string, amount: string) {
  return {
    type: equipmentActionTypes.LIST,
    payload: { itemId, price, amount },
  };
}
export function delist(itemId: number) {
  return {
    type: equipmentActionTypes.DELIST,
    payload: { itemId },
  };
}
export function buy(itemId: number, price: string, amount: number, seller: string) {
  return {
    type: equipmentActionTypes.BUY,
    payload: { itemId, price, amount, seller },
  };
}

export function offer(itemId: number, price: string, amount: string) {
  return {
    type: equipmentActionTypes.OFFER,
    payload: { itemId, price, amount },
  };
}

export function cancelOffer(itemId: number) {
  return {
    type: equipmentActionTypes.CANCEL_OFFER,
    payload: { itemId },
  };
}

export function takeOffer(itemId: number, price: string, amount: number, buyer: string) {
  return {
    type: equipmentActionTypes.TAKE_OFFER,
    payload: { itemId, price, amount, buyer },
  };
}

export function batchTransferEquipment(from: string, to: string, ids: number[], amounts: number[], onSuccess: any) {
  return {
    type: equipmentActionTypes.BATCH_TRANSFER,
    payload: { from, to, ids, amounts },
    callbacks: { onSuccess },
  };
}

export function craft(itemRecipeId: number, amount: number, enhanceMaterialId: number, onSuccess?: any) {
  return {
    type: equipmentActionTypes.CRAFT,
    payload: { itemRecipeId, amount, enhanceMaterialId, keepModal: true },
    callbacks: { onSuccess },
  };
}

export function levelUpBlacksmith(levelUpAmount: number, onSuccess: any) {
  return {
    type: equipmentActionTypes.LEVEL_UP_BLACKSMITH,
    payload: { levelUpAmount },
    callbacks: { onSuccess },
  };
}

export function throwItemGacha(itemId: number, onSuccess?: any) {
  return {
    type: equipmentActionTypes.THROW_ITEM_GACHA,
    payload: { itemId },
    callbacks: { onSuccess },
  };
}

export function dismantle(
  itemIds: number[],
  amount: number[],
  dismantleMaterials: EquipmentDismantle[],
  onSuccess?: any
) {
  return {
    type: equipmentActionTypes.DISMANTLE,
    payload: { itemIds, amount, dismantleMaterials },
    callbacks: { onSuccess },
  };
}

export function claimEquipment(nftRewards: any, nonce: number, onSuccess?: any) {
  return {
    type: equipmentActionTypes.CLAIM,
    payload: { nftRewards, nonce },
    callbacks: { onSuccess },
  };
}

export function convertExpToBp(onSuccess?: any) {
  return {
    type: equipmentActionTypes.CONVERT_EXP_TO_BP,
    callbacks: { onSuccess },
  };
}

export function depositEquipment(ids: number[], amounts: number[], onSuccess: any) {
  return {
    type: equipmentActionTypes.DEPOSIT,
    payload: { ids, amounts },
    callbacks: { onSuccess },
  };
}

export function withdrawEquipment(withdrawData: any, onSuccess: any) {
  return {
    type: equipmentActionTypes.WITHDRAW,
    payload: { withdrawData },
    callbacks: { onSuccess },
  };
}
