import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalModal } from "src/app/actions/globalAction";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import Modal from "src/app/components/Commons/Modals/Modal";
import { defineDemiKnight } from "src/app/services/api/faralandService";
import DemiDetails from "../Details/DemiDetails";

export default function DemiModal() {
  const dispatch = useDispatch();
  const { modal } = useSelector((state: any) => state.global);

  const demiModal = modal.demiModal;
  const demiId = demiModal.data?.demiId;

  useEffect(() => {
    if (!demiModal.active) return;
    fetchDemiKnight();
  }, [demiModal.active]); // eslint-disable-line

  async function fetchDemiKnight() {
    await defineDemiKnight(demiId);
  }

  function closeModal() {
    dispatch(setGlobalModal("demiModal"));
  }

  return (
    <Modal isActive={demiModal.active} onClose={closeModal} className="knight-modal" layer={1}>
      <BasicModalContent
        noPanel
        contentClassName="line-height-1"
        content={<DemiDetails demiId={demiId} />}
      />
    </Modal>
  );
}
