import React, { useState } from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import InputGroup from "src/app/components/Commons/InputGroup";
import AddressInputGroup from "src/app/components/Commons/AddressInputGroup";
import { useSelector, useDispatch } from "react-redux";
import { roundNumber, toBigAmount } from "src/app/utils/helpers";
import { compareNumbers } from "src/app/utils/calculators";
import { list, delist, buy } from "src/app/actions/marketAction";
import { demiTransfer } from "src/app/actions/demiAction";
import faraLogo from "src/assets/images/tokens/fara.png";
import { isAddress } from "web3-utils";
import { ACTIONS, BURN_ADDRESS } from "src/app/configs/constants";
import { DemiKnight, List } from "src/app/types/demiKnight";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import ENV from "src/app/configs/env";

type ActionModalProps = {
  type: number;
  demi: DemiKnight;
  currentList: List;
};

export default function DemiActionModal(props: ActionModalProps) {
  const { demi, currentList, type } = props;

  const dispatch = useDispatch();
  const { balance } = useSelector((state: any) => state.account);
  const [needApprove, sendApproveTx] = useFetchingAllowance(
    ENV.CONTRACT.DEMI_KNIGHT,
    "faraContract",
    ENV.CONTRACT.FARA_TOKEN,
    currentList?.price ?? 0
  );
  const [receiveAddr, setReceiveAddr] = useState("");
  const [receiverInput, setReceiverInput] = useState("");
  const [inputError, setInputError] = useState("");
  const [listingPrice, setListingPrice] = useState(
    currentList && currentList.price ? currentList.price.toString() : ""
  );

  function handleListingAmountChange(e: any, amount?: string) {
    setInputError("");
    if (amount) {
      setListingPrice(amount);
      return;
    }
    setListingPrice(e.target.value);
  }

  function sendBuyTx() {
    dispatch(buy(demi.id, "0", true));
  }

  function handleSetReceiverAddress(value: string) {
    setReceiveAddr(value);
  }
  function sendListTx() {
    const compareListingPrice = compareNumbers(listingPrice, demi.price || 0);

    if (listingPrice === "") {
      setInputError("Invalid listing price.");
    } else if (compareListingPrice === 0) {
      setInputError(`New listing price must not be the same as your current listing price.`);
    } else {
      dispatch(list(demi.id, toBigAmount(listingPrice), true));
    }
  }

  function sendDelistTx() {
    dispatch(delist(demi.id, true));
  }

  function sendTransferTx() {
    if (!isAddress(receiveAddr)) {
      setInputError("Invalid BSC address.");
    } else {
      dispatch(demiTransfer(demi.id, demi.owner, receiveAddr));
    }
  }

  function sendDeleteTx() {
    dispatch(demiTransfer(demi.id, demi.owner, BURN_ADDRESS, false));
  }

  function renderBalanceBlock(error: string) {
    return (
      <div className="mt-2">
        <div>
          <span>Current balance:</span>
          <span> {roundNumber(balance.BNB, 3, true)} BNB</span>
          <span>, {roundNumber(balance.FARA, 2, true)} FARA</span>
        </div>
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }

  function renderBuyContent() {
    let content, onSubmit, submitText;
    const isNotEnough = compareNumbers(toBigAmount(balance.FARA), demi.price ?? 0);
    content = (
      <div className="knight__checkoutWrapper">
        <div>
          Are you sure to buy this Hero with <b className="text-orange">{currentList.price} FARA</b>?
        </div>
        <div className="fs-2">{renderBalanceBlock(isNotEnough === -1 ? "Not enough $FARA balance" : "")}</div>
      </div>
    );
    if (needApprove) {
      onSubmit = sendApproveTx;
      submitText = "Approve FARA";
    } else {
      onSubmit = sendBuyTx;
      submitText = "Buy";
    }
    return { content, onSubmit, submitText };
  }

  function renderDelistContent() {
    return <div>Are you sure to delist your Demi from the marketplace?</div>;
  }

  function renderDeleteContent() {
    return <div>This action cannot be reverted, are you sure you want to delete your Demi permanently?</div>;
  }

  function renderTransferContent() {
    return (
      <AddressInputGroup
        value={receiverInput}
        handleChange={(event: any) => setReceiverInput(event.target.value)}
        setAddress={handleSetReceiverAddress}
        error={inputError}
        label={"Receive Address"}
      />
    );
  }

  function renderListContent() {
    return (
      <InputGroup
        className="text-field--large"
        value={listingPrice}
        handleChange={handleListingAmountChange}
        symbol="FARA"
        balance={balance.FARA}
        error={inputError}
        tokenImage={faraLogo}
        label={"FARA Amount"}
        hideMaxBtn={true}
      />
    );
  }

  function renderPopupContent() {
    let content, onSubmit, submitText;

    if (type === ACTIONS.BUY) {
      const buyData = renderBuyContent();
      content = buyData.content;
      onSubmit = buyData.onSubmit;
      submitText = buyData.submitText;
    } else if (type === ACTIONS.LIST) {
      content = renderListContent();
      onSubmit = sendListTx;
      submitText = "List";
    } else if (type === ACTIONS.DELIST) {
      content = renderDelistContent();
      onSubmit = sendDelistTx;
      submitText = "Delist";
    } else if (type === ACTIONS.TRANSFER) {
      content = renderTransferContent();
      onSubmit = sendTransferTx;
      submitText = "Transfer";
    } else if (type === ACTIONS.DELETE_DEMI) {
      content = renderDeleteContent();
      onSubmit = sendDeleteTx;
      submitText = "Delete";
    }

    return { content, onSubmit, submitText };
  }

  const { content, onSubmit, submitText } = renderPopupContent();

  return (
    <BasicModalContent
      onSubmit={onSubmit}
      submitText={submitText}
      content={<div className="text-center">{content}</div>}
    />
  );
}
