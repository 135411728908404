import React, { useState } from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { useDispatch } from "react-redux";
import InputGroup from "src/app/components/Commons/InputGroup";
import expJarLogo from "src/assets/images/tokens/expjar.png";

import { compareNumbers } from "src/app/utils/calculators";
import { claimExpJar } from "src/app/actions/stakingAction";

type MintExpJarModalProps = {
  eligibleExpJar: string | number;
};

export default function MintExpJarModal(props: MintExpJarModalProps) {
  const dispatch = useDispatch();
  const { eligibleExpJar } = props;
  const [inputError, setInputError] = useState("");
  const [amount, setAmount] = useState("");
  const invalidAmount = +amount <= 0 || amount.indexOf(".") === 1;

  function handleAmountChange(e: any, amount?: string) {
    setInputError("");
    if (amount) {
      setAmount(amount);
      return;
    }
    setAmount(e.target.value);
  }

  function claim() {
    const compareBalance = compareNumbers(eligibleExpJar, amount);
    if (invalidAmount) {
      setInputError(`Invalid amount.`);
      return;
    } else if (compareBalance === -1) {
      setInputError(`Cannot exceed eligible amount`);
      return;
    }

    dispatch(claimExpJar(amount));
  }

  return (
    <BasicModalContent
      customTitle="Claim EXP Jars"
      submitText="Claim"
      onSubmit={claim}
      content={
        <div className="mt-6">
          <InputGroup
            className="text-field--large"
            value={amount}
            handleChange={handleAmountChange}
            symbol="EXP Jar(s)"
            balance={eligibleExpJar.toString()}
            balanceText="Eligible for"
            error={inputError}
            tokenImage={expJarLogo}
            label={"EXP Jar Amount"}
          />
        </div>
      }
    />
  );
}
