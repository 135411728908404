import { globalActionTypes } from "src/app/actions/globalAction";
import { GlobalReducer } from "src/app/types/reducer-state";

const initialState: GlobalReducer = {
  web3Service: null,
  syncedBlock: {
    number: 0,
    timestamp: 0,
  },
  tokenPrice: {
    FARA: 0,
    BNB: 0,
  },
  subgraphClient: null,
  modal: {
    loading: { active: false },
    error: { active: false, data: null },
    knightStaking: { active: false, data: null },
    txTracking: { active: false, data: null },
    exchangeItem: { active: false, data: null },
    transferEquipment: { active: false, data: null },
    upgradeEquipment: { active: false, data: {} },
    demiModal: { active: false, data: null },
    transferMaterials: { active: false, data: null },
    downloadModal: { active: false },
    guildCreationModal: { active: false, guildRefresh: false },
    equipmentDismantleModal: { active: false, data: {} },
    skillbookActionModal: { active: false, data: {} },
    runeEquipmentModal: {active: false, data: {}},
  },
};

export default function globalReducer(state = initialState, action: any): GlobalReducer {
  switch (action.type) {
    case globalActionTypes.SET_MODAL: {
      const { key, data } = action.payload;

      return {
        ...state,
        modal: {
          ...state.modal,
          [key]: data ?? { ...state.modal[key], active: false },
        },
      };
    }
    case globalActionTypes.SET_WEB3_SERVICE: {
      return {
        ...state,
        web3Service: action.payload,
      };
    }
    case globalActionTypes.SET_TOKEN_PRICE: {
      return {
        ...state,
        tokenPrice: action.payload,
      };
    }
    case globalActionTypes.SET_SYNCED_BLOCK: {
      return {
        ...state,
        syncedBlock: action.payload,
      };
    }
    case globalActionTypes.SET_SUBGRAPH_CLIENT: {
      return {
        ...state,
        subgraphClient: action.payload,
      };
    }
    default:
      return state;
  }
}
