import {
  UserItem,
  Equipment,
  ListItem,
  OfferItem,
  ExchangeItem,
  ItemTransaction,
  Item,
  RuleDestItem,
  SkillBook,
  ClaimHistory,
  CraftedItem,
  ClaimHistoryNonce,
  CraftHistory,
  WithdrawHistory,
  WithdrawHistoryNonce,
  CraftedMaterials,
} from "src/app/types/equipment";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { RARITY_LABEL, EQUIPMENT_LABEL, EQUIPMENT_TYPE } from "src/app/configs/constants";
import { timeAgo } from "src/app/utils/helpers";
import { convertRarityToNumber, createWithdrawDataByType } from "src/app/utils/mappingHelpers";
import { MATERIALS } from "src/app/configs/materials/materials";

export function createSkillBook(data: any): SkillBook {
  return {
    id: data.id,
    name: data.name,
    displayName: data.displayName,
    elementalBonus: Object.entries(data.elementalBonus).map((v: any) => {
      return {
        type: v[0],
        val: Object.entries(v[1]).flat(),
      };
    }),
    partDescription: data.partDescription.split("\n"),
    skillDescription: data.skillDescription.replace("\r\n", "\n"),
    rarity: data.rarity,
    manaCost: data.manaCost,
    range: data.rangeTile,
    requiredLevel: data.requiredLevel,
    tier: data.tier,
    upgradeAmount: data.upgradeAmount,
  };
}

export function createUserItem(data: any): UserItem {
  const id = data.item.id;
  const metadata = _getMetaData(id);

  return {
    id: +id,
    owner: data.user?.id,
    item: createItem(data.item, data),
    amount: +data.amount,
    used: +data.used,
    available: +data.amount - +data.used,
    info: `${EQUIPMENT_LABEL[metadata.type]}: ${metadata.name} - ${RARITY_LABEL[metadata.rarity]}`,
    price: data.price && +data.price,
    ruleDestItem: data.ruleDestItem && createItems(data.ruleDestItem.itemAmountExchangeRules),
  };
}

export function createUserItemFromAPI(data: any): UserItem {
  const id = data.itemId;
  const metadata = _getMetaData(id);
  return {
    id: +id,
    owner: data.walletAddress,
    item: createItem(data.item, data),
    amount: +data.quantity,
    used: +data.used,
    available: +data.quantity - +data.used,
    info: `${EQUIPMENT_LABEL[metadata.type]}: ${metadata.name} - ${RARITY_LABEL[metadata.rarity]}`,
    price: data.price && +data.price,
    ruleDestItem: data.ruleDestItem && createItems(data.ruleDestItem.itemAmountExchangeRules),
  };
}

export function createItem(item: any, data = { amount: 0, available: 0, used: 0, price: 0 }): Equipment {
  return {
    id: item.itemNftId ? +item.itemNftId : +item.id,
    name: item.name,
    displayName: item.displayName ?? `${_getSuffixDisplayName(item.tier)}${item.name}`,
    maxSupply: +item.maxSupply,
    minted: +item.minted,
    rarity: isNaN(+item.rarity) ? convertRarityToNumber(item.rarity) : item.rarity,
    slot: item.slot !== undefined ? item.slot : EQUIPMENT_TYPE[item.equipmentSlotType],
    amount: data && +data.amount,
    available: +data.amount - +data.used,
    price: +data.price,
    burned: +item.burned,
  };
}

export function createFilteredItemData(data: any): Item {
  const metadata = _getMetaData(data.id);

  return {
    id: data.id,
    name: metadata.name,
    displayName: metadata.displayName,
    races: metadata.races,
    genders: metadata.genders,
    requiredLevel: metadata.requiredLevel,
    rarity: metadata.rarity,
    type: metadata.type,
    tier: metadata.tier,
    maxSupply: metadata.maxSupply,
    requiredStats: metadata.requiredStats,
    primaryStats: {
      str: metadata.stats.STR,
      agi: metadata.stats.AGI,
      int: metadata.stats.INT,
      luk: metadata.stats.LUK,
    },
    stats: metadata.stats,
    listedPrice: data.price ? data.price : data.listedPrice,
    available: data.available ?? 0,
    amount: data.amount ?? 0,
    nextTierId: metadata.nextTierId,
    description: metadata.description ?? undefined,
    animType: metadata.animType,
  };
}

export function createItems(data: any): Equipment[] {
  return data.map((equipment: any) => {
    const item = equipment.item;
    const metadata = _getMetaData(item.id);

    return {
      id: +metadata.id,
      name: metadata.name,
      displayName: metadata.displayName,
      maxSupply: +metadata.maxSupply,
      type: metadata.type,
      rarity: metadata.rarity,
      races: metadata.races,
      genders: metadata.genders,
      minted: +item.minted,
      amount: equipment.amount,
    };
  });
}

export function createListItem(data: any, domainNames?: any): ListItem {
  return {
    id: data.id,
    item: createItem(data.item),
    amount: +data.amount,
    seller: data.seller.id,
    price: data.price,
    timestamp: data.timestamp,
    sellerDomain: domainNames ? domainNames[data.seller.id] : "",
  };
}

export function createOfferItem(data: any, domainNames?: any): OfferItem {
  return {
    id: data.id,
    item: createItem(data.item),
    amount: +data.amount,
    buyer: data.buyer.id,
    price: data.price,
    timestamp: data.timestamp,
    buyerDomain: domainNames ? domainNames[data.buyer.id] : "",
  };
}

export function createExchangeItem(data: any, domainNames?: any): ExchangeItem {
  return {
    id: data.id,
    item: createItem(data.item),
    amount: +data.amount,
    seller: data.seller && data.seller.id,
    ruleDestItems:
      data.ruleDestItem && data.ruleDestItem.itemAmountExchangeRules
        ? data.ruleDestItem.itemAmountExchangeRules.map((i) => createRuleDestItem(i))
        : [],
    timestamp: data.timestamp,
    sellerDomain: domainNames ? domainNames[data.seller.id] : "",
  };
}

export function createRuleDestItem(data: any): RuleDestItem {
  return {
    amount: data.amount,
    item: createItem(data.item),
  };
}

export function createItemTransaction(data: any, domainNames?: any): ItemTransaction {
  return {
    id: data.id,
    type: data.type,
    amount: +data.amount,
    item: createItem(data.item),
    price: data.price,
    ruleDestItems:
      data.ruleDestItem && data.ruleDestItem.itemAmountExchangeRules
        ? data.ruleDestItem.itemAmountExchangeRules.map((i) => createRuleDestItem(i))
        : [],
    from: data.from && data.from.id,
    to: data.to && data.to.id,
    timestamp: data.timestamp,
    fromAddressDomain: domainNames ? domainNames[data.from?.id] : "",
    toAddressDomain: domainNames ? domainNames[data.to?.id] : "",
  };
}

function _getSuffixDisplayName(tier: number) {
  let suffixDisplayName = "";

  if (tier === 2) {
    suffixDisplayName = "Mighty ";
  } else if (tier === 3) {
    suffixDisplayName = "Superior ";
  }

  return suffixDisplayName;
}

function _getMetaData(id: number) {
  let metadata = EQUIPMENT[id];
  if (!metadata) metadata = EQUIPMENT[0];
  return metadata;
}

export function createClaimHistory(data: any): ClaimHistory {
  return {
    createdAt: timeAgo(data.createdAt),
    owner: data.owner,
    claimHistoryNonce: data.claimHistoryNonce.map((nonce) => createClaimHistoryNonce(nonce)),
    nonce: data.nonce,
    reclaimAble: data.reclaimAble,
    spaceDomain: data.spaceDomain,
  };
}

export function createWithdrawHistory(data: any): WithdrawHistory {
  return {
    createdAt: timeAgo(data.createdAt),
    owner: data.owner,
    type: data.type,
    withdrawHistoryNonce: data.withdrawnHistoryNonce
      .map((nonce) => createWithdrawHistoryNonce(nonce, data.type))
      .flat(),
    nonce: data.nonce,
    reclaimAble: data.reclaimAble,
    spaceDomain: data.spaceDomain,
  };
}

export function createWithdrawHistoryNonce(data: any, type: string): WithdrawHistoryNonce {
  return {
    ids: data.ids,
    amounts: data.amounts,
    data: data.ids.map((id, index) => {
      return {
        item: createWithdrawDataByType(type)[id],
        quantity: data.amounts[index],
      };
    }),
    tokenAmount: data.tokenAmount,
    signature: data.signature,
  };
}

export function createClaimHistoryNonce(data: any): ClaimHistoryNonce {
  return {
    itemId: data.material ? data.material.id : data.item.id,
    nftId: data.material ? data.material.id : data.item.itemNftId,
    itemName: data.material ? data.material.name : data.item.name,
    item: EQUIPMENT[data.item?.itemNftId] ?? undefined,
    material: MATERIALS[data.material?.id] ?? undefined,
    signature: data.signature,
    quantity: data.quantity,
  };
}

export function createCraftedItems(data: any): CraftedItem {
  return {
    itemId: data.item.id,
    nftId: data.item.itemNftId,
    itemName: data.item.name,
    item: EQUIPMENT[data.item.itemNftId],
    type: "EQUIPMENT",
    quantity: data.quantity,
    typeReward: data.type,
  };
}

export function createCraftedMaterials(data: any): CraftedMaterials {
  return {
    itemId: data.material.id,
    nftId: data.material.id,
    itemName: data.material.name,
    material: MATERIALS[data.material.id],
    type: "MATERIAL",
    quantity: data.quantity,
    typeReward: data.type,
  };
}

export function createCraftHistory(data: any): CraftHistory {
  return {
    createdAt: timeAgo(data.createdAt),
    owner: data.walletAddress,
    txHash: data.txHash,
    items: data.reason.filter((item) => item.status === true).map((item) => EQUIPMENT[item.itemId]),
    spaceDomain: data.spaceDomain,
  };
}
