import { Grid } from "@material-ui/core";
import React from "react";
import { STATS_LABEL } from "src/app/configs/constants";
import { PrimaryStats } from "src/app/types/attribute";

interface KnightStatsInfoProps {
  baseStats: PrimaryStats;
}

const labels = ["STR", "AGI", "INT", "LUK"];

export default function KnightStatsInfo(props: KnightStatsInfoProps) {
  return (
    <div className="knights__stats">
      <Grid container spacing={5} style={{ justifyContent: "flex-start" }}>
        {Object.values(STATS_LABEL).map((stats, index) => {
          const data = props.baseStats[stats.toLowerCase()] ?? {};
          return (
            <Grid item key={index}>
              <div className="stats__name fs-3">{labels[index]}</div>
              <div className="stats__value mt-1">
                <img
                  src={require(`src/assets/images/hero-details/stats/${stats.toLowerCase()}.svg`)}
                  alt={labels[index]}
                  width={20}
                />
                <div className="fs-2">{data}</div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}