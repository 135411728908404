import React from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { useSelector } from "react-redux";
import MaterialTable from "@material-table/core";

type GuildTableProps = {
  isOwner: boolean;
  columns: any;
  emptyMessage: string;
  data: any;
  handleSelectionChange: any;
  isEmpty: boolean;
};

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#987F5A",
      contrastText: "#36393f",
    },
    background: {
      paper: "transparent",
    },
    secondary: {
      main: "#987F5A",
    },
  },
  overrides: {
    MuiTable: {
      root: {
        borderCollapse: "separate",
        borderRadius: 4,
      },
    },
    MuiTableHead: {
      root: {
        position: "sticky",
        top: 0,
        zIndex: 3,
      },
    },
    MuiTableRow: {
      root: {
        "& th div": {
          position: "relative",
          top: 3,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid #987f5a",
        color: "#fff !important",
      },
    },
    MuiToolbar: {
      root: {
        background: "#111121",
      },
    },
  },
});

export default function GuildTables(props: GuildTableProps) {
  const { address } = useSelector((state: any) => state.account);
  const { isOwner, columns, emptyMessage, data, isEmpty } = props;


  function handleSelectionChange(rows) {
    props.handleSelectionChange(rows);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        columns={columns}
        data={data}
        style={{
          border: "1px solid #987f5a",
          borderRadius: 4,
        }}
        options={{
          selection: isOwner,
          paging: false,
          selectionProps: (rowData) => ({
            disabled: rowData.id === address,
          }),
          headerStyle: {
            borderBottom: "1px solid #987f5a",
            backgroundColor: "#111121",
            fontFamily: `"American Captain", sans-serif`,
            textTransform: "uppercase",
            fontWeight: 500,
            fontSize: 22,
            height: 60,
          },
          rowStyle: {
            background: "linear-gradient(180deg, rgba(66, 52, 4, 0) 0%, #3a2e00 100%)",
            height: 75,
          },
          grouping: false,
          sorting: false,
          actionsColumnIndex: -1,
          toolbar: false,
          showSelectAllCheckbox: false,
          emptyRowsWhenPaging: false,
          minBodyHeight: isEmpty ? 190 : "auto",
          draggable: false
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <div className="cm-table__no-record">
                <img src={require("src/assets/images/icons/empty-bids.svg")} width={60} height={60} alt="" />
                <div>{emptyMessage}</div>
              </div>
            ),
          },
        }}
        onSelectionChange={(rows) => {
          handleSelectionChange(rows);
        }}
      />
    </MuiThemeProvider>
  );
}
