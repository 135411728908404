import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS } from "src/app/configs/constants";
import { stakingActionTypes } from "src/app/actions/stakingAction";
import { _sendTx } from "src/app/sagas/index";

function* stake(action: any): any {
  yield call(_sendTx, ACTIONS.STAKE, action.payload);
}

function* unstake(action: any): any {
  yield call(_sendTx, ACTIONS.UNSTAKE, action.payload);
}

function* claim(action: any): any {
  yield call(_sendTx, ACTIONS.CLAIM, action.payload);
}

function* claimItem(action: any): any {
  yield call(_sendTx, ACTIONS.STAKING_CLAIM_ITEM, action.payload);
}

function* convertExp(action: any): any {
  yield call(_sendTx, ACTIONS.CONVERT_EXP, action.payload, action.callbacks.onSuccess);
}

function* emergencyWithdraw(action: any): any {
  yield call(_sendTx, ACTIONS.EMERGENCY_WITHDRAW, action.payload);
}

function* claimExpJar(action: any): any {
  yield call(_sendTx, ACTIONS.CLAIM_EXP_JAR, action.payload);
}

function* consumeExpJar(action: any): any {
  yield call(_sendTx, ACTIONS.CONSUME_EXP_JAR, action.payload, action.callbacks.onSuccess);
}

function* distributeExp(action: any): any {
  yield call(_sendTx, ACTIONS.DISTRIBUTE_EXP, action.payload, action.callbacks.onSuccess);
}

export default function* stakingWatcher() {
  yield takeLatest(stakingActionTypes.STAKE, stake);
  yield takeLatest(stakingActionTypes.UNSTAKE, unstake);
  yield takeLatest(stakingActionTypes.CLAIM, claim);
  yield takeLatest(stakingActionTypes.CLAIM_ITEM, claimItem);
  yield takeLatest(stakingActionTypes.CONVERT_EXP, convertExp);
  yield takeLatest(stakingActionTypes.EMERGENCY_WITHDRAW, emergencyWithdraw);
  yield takeLatest(stakingActionTypes.CLAIM_EXP_JAR, claimExpJar);
  yield takeLatest(stakingActionTypes.CONSUME_EXP_JAR, consumeExpJar);
  yield takeLatest(stakingActionTypes.DISTRIBUTE_EXP, distributeExp);
}
