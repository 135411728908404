import React from "react";
import { Item } from "src/app/types/equipment";
import { EQUIPMENT_LABEL } from "src/app/configs/constants";
import _ from "lodash";
import { KnightStats } from "src/app/types/knight";
import { getTotalPrimaryStats } from "../../../utils/helpers";

type ItemIconProps = {
  item: Item;
  largeText?: boolean;
  knightStats?: KnightStats;
};

export default function ItemRequirement({ item, largeText, knightStats }: ItemIconProps) {
  let totalStats = knightStats && getTotalPrimaryStats(knightStats, true);

  return (
    <div className={`item-stats item-stats--${largeText && "large"}`}>
      <div className="item-stats__block">
        <span className="mr-1">- Level: </span>
        <span className="item-stats__value">{item.requiredLevel}</span>
      </div>
      {!(EQUIPMENT_LABEL[item.type] === "Skill Book") && (
        <div className="item-stats__block">
          <span className="mr-1">- Gender:</span>
          {item.genders.map((gender: string, index: number) => {
            return (
              <span key={index} className="item-stats__value">
                {index ? ", " : ""}
                {gender}
              </span>
            );
          })}
        </div>
      )}
      {!(EQUIPMENT_LABEL[item.type] === "Skill Book") && (
        <div className="item-stats__block">
          <span className="mr-1">- Race:</span>
          {item.races.map((race: string, index: number) => {
            return (
              <span key={index} className="item-stats__value">
                {index ? ", " : ""}
                {race}
              </span>
            );
          })}
        </div>
      )}
      {!_.isEmpty(item.requiredStats) && (
        <>
          <div className="item-stats__block">- And meet 2/3 of these stats requirements:</div>
          {item.requiredStats.map((stat, idx) => {
            const label = idx === 0 ? "str" : idx === 1 ? "agi" : "int";
            return (
              <div className="item-stats__block ml-2" key={idx}>
                <span className="mr-1">+ {label.toUpperCase()}:</span>
                <span className={`item-stats__value ${totalStats && totalStats[label] >= stat && "text-9"}`}>
                  {totalStats ? `${totalStats[label]} / ${stat}` : stat}
                </span>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}
