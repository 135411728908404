import React from 'react';
import RellaxWrapper from 'react-rellax-wrapper';

type FloatingCrystalProps = {
  speed: number,
  src: string,
  style?: any,
  percentage?: number,
};

const FloatingCrystal = ({ speed, src, style, percentage }: FloatingCrystalProps) => {
  return (
    <div className="crystal" style={style}>
      <RellaxWrapper speed={speed} percentage={percentage}>
        <embed src={src} className="crystal__img" />
      </RellaxWrapper>
    </div>
  );
};
export default FloatingCrystal;
