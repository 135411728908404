import moment from "moment";
import { all, call, fork, put, select } from "redux-saga/effects";
import { setGlobalModal } from "src/app/actions/globalAction";
import { getTxObjectByType } from "src/app/services/web3/txService";
import { appendTrackingTx } from "src/app/actions/txAction";
import { TX_STATUS } from "src/app/configs/constants";
import stakingWatcher from "src/app/sagas/stakingSaga";
import marketWatcher from "src/app/sagas/marketSaga";
import knightWatcher from "src/app/sagas/knightSaga";
import equipmentSaga from "src/app/sagas/equipmentSaga";
import txSaga from "src/app/sagas/txSaga";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import demiWatcher from "src/app/sagas/demiSaga";
import materialSaga from "src/app/sagas/materialSaga";
import guildWatcher from "src/app/sagas/guildSaga";
import commonWatcher from "src/app/sagas/commonSaga";
import landWatcher from "src/app/sagas/landSaga";
import tokenSaga from "src/app/sagas/tokenSaga";

export default function* rootSaga() {
  yield all([
    fork(commonWatcher),
    fork(stakingWatcher),
    fork(marketWatcher),
    fork(knightWatcher),
    fork(demiWatcher),
    fork(equipmentSaga),
    fork(materialSaga),
    fork(tokenSaga),
    fork(txSaga),
    fork(guildWatcher),
    fork(landWatcher),
  ]);
}

export function* _sendTx(
  actionType: number,
  data: any,
  onSuccess?: any,
  value = "0",
  topics: string[] = [],
): any {
  const getAccountState = (state: any) => state.account;
  const getGlobalState = (state: any) => state.global;
  const { address, wallet } = yield select(getAccountState);
  const { web3Service } = yield select(getGlobalState);

  yield put(setGlobalModal("loading", { active: true, text: "Waiting for your TX confirmation", }));

  try {
    const txObject = getTxObjectByType(actionType, {
      web3Service,
      action: data,
      tx: { address, value },
    });

    if (txObject === null) {
      throw Error('Cannot build Tx Object');
    }

    const txHash = yield call(wallet.makeTransaction, txObject);

    if (data && !data.keepModal) {
      modalService.close();
    }

    yield put(appendTrackingTx({
      action: actionType,
      from: address,
      to: txObject.to,
      timeStamp: moment().unix(),
      hash: txHash,
      status: TX_STATUS.PENDING,
      data: data,
      onSuccess: onSuccess,
      topics: topics
    }));
  } catch (e: any) {
    yield put(setGlobalModal('error', {
      active: true,
      data: typeof e === "string" ? e : e.message
    }));
  }

  yield put(setGlobalModal("loading"));
}
