import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatNumber } from "src/app/utils/helpers"
import { STAKING_CONFIG } from "src/app/configs/constants";
import { queryStakingSummary } from "src/app/services/api/subgraphService";
import { multiplyNumbers, plusNumbers } from "src/app/utils/calculators";

const StakingSummary = () => {
  const { web3Service, tokenPrice } = useSelector((state: any) => state.global);
  const [tvl, setTvl] = useState<string | number>('');
  const [totalSingleStaked, setTotalSingleStaked] = useState<String>();
  const [totalLpStaked, setTotalLpStaked] = useState<String>();

  useEffect(() => {
    if (!web3Service || !tokenPrice.FARA) return;

    async function callFaraBalance() {
      try {
        const stakingSummary = await queryStakingSummary();
        const totalSingleStakedAmount = plusNumbers(stakingSummary.singleBalance, stakingSummary.deprecatedBalance);
        setTotalSingleStaked(totalSingleStakedAmount);
        setTotalLpStaked(stakingSummary.lpBalance);

        const singlePoolTvl = multiplyNumbers(tokenPrice.FARA, totalSingleStakedAmount);
        const lpPoolTvl = multiplyNumbers(tokenPrice.FARA, multiplyNumbers(stakingSummary.lpBalance, 100));
        setTvl(plusNumbers(singlePoolTvl, lpPoolTvl));
      } catch (e) {
        console.log(e);
      }
    }

    callFaraBalance();
  }, [web3Service, tokenPrice]);

  return (
    <div className="data-summary">
      <div className="data-summary__container">
        <div>
          <div className="data-summary__title">
            Total Value Locked
          </div>
          <div className="data-summary__value">
            {tvl ? `$${formatNumber(tvl)}` : "-/-"}
          </div>
        </div>
        <div>
          <div className="data-summary__title">{STAKING_CONFIG.SINGLE.SYMBOL} Staked</div>
          <div className="data-summary__value">
            {totalSingleStaked ? `${formatNumber(totalSingleStaked)} ${STAKING_CONFIG.SINGLE.SYMBOL}` : "-/-"}
          </div>
        </div>
        <div>
          <div className="data-summary__title">{STAKING_CONFIG.LIQUIDITY.SYMBOL} Staked</div>
          <div className="data-summary__value">
            {totalLpStaked ? `${formatNumber(totalLpStaked)} ${STAKING_CONFIG.LIQUIDITY.SYMBOL}` : "-/-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingSummary;
