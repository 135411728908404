import { ApolloClient, InMemoryCache } from '@apollo/client';
import ENV from "src/app/configs/env";

export const marketClient = new ApolloClient({
  uri: ENV.URL.MARKET_GRAPHQL,
  cache: new InMemoryCache()
})

export const guildClient = new ApolloClient({
  uri: ENV.URL.GUILD_GRAPHQL,
  cache: new InMemoryCache()
});

export const gachaEquipmentClient = new ApolloClient({
  uri: ENV.URL.GACHA_EQUIPMENT_GRAPHQL,
  cache: new InMemoryCache()
});

export const stakingSubgraph = new ApolloClient({
  uri: ENV.URL.GRAPHQL_STAKING,
  cache: new InMemoryCache()
})

export const landClient = new ApolloClient({
  uri: ENV.URL.LAND_GRAPHQL,
  cache: new InMemoryCache()
})

export const depositEquipmentClient = new ApolloClient({
  uri: ENV.URL.DEPOSIT_EQUIPMENT_GRAPHQL,
  cache: new InMemoryCache()
})