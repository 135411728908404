import React from "react";
import { useSelector } from "react-redux";
import ExpProgressBar from "src/app/components/Commons/ExpProgressBar";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { BASE_BLACKSMITH_EXP, BLACKSMITH_RATE } from "src/app/configs/constants";
import useBlacksmithData from "src/app/hooks/useBlacksmithData";
import { ItemRecipe } from "src/app/types/materials";
import blacksmithIcon from "src/assets/images/crafting/blacksmith-icon.svg";
import infoIcon from "src/assets/images/icons/info-icon-white.svg";

type BlacksmithSectionProps = {
  selectedItemRecipe: ItemRecipe;
  isRuneLevelUp: boolean;
};
export const BlacksmithSection = ({ selectedItemRecipe, isRuneLevelUp }: BlacksmithSectionProps) => {
  const { address } = useSelector((state: any) => state.account);
  const { blacksmithData } = useBlacksmithData(address);

  function openBlacksmithModal() {
    modalService.show(BasicModalContent, {
      className: "small",
      content: (
        <div>
          <div className="align-center">
            <img className="item-crafting__bs-icon" src={blacksmithIcon} alt="Blacksmith Icon" />
          </div>
          <h3 className="mt-3 mb-1">What is Blacksmith?</h3>
          <div className="mb-3 fs-2">
            Gain the quintessence of craftsmanship passed down from the dwarf brothers of Brokkr and Eitri by crafting
            practice. Increase blacksmith level for more chance of creating gears beautiful and handy in battles!
          </div>
          <h3 className="mt-3 mb-1">Blacksmith Info:</h3>
          <div className="normal-line-height fs-2">
            <div className="mb-1">
              1. Gets an extra <b>1%</b> chance of getting items in their highest rarity if it's legendary or mythical (
              <b>2%</b> for epic or lower) per blacksmith level gained.
            </div>
            <div className="mb-1">
              2. Each recipe will give different amounts of Blacksmith EXP once successfully crafted.
            </div>
            <div className="mb-1">3. There will be a maximum to the bonus success rate for each rarity.</div>
            <div>4. Leveling up Blacksmith formula will be: Required EXP = (Current Level + 1) x 100.</div>
          </div>
        </div>
      ),
    });
  }

  return (
    <div className="item-crafting__block">
      <div className="item-crafting__bs">
        <img className="item-crafting__bs-icon" src={blacksmithIcon} alt="Blacksmith Icon" />
        <div className="item-crafting__bs-content">
          <div className="fw-4 fs-4">
            <span>Blacksmith</span>
            <img className="info-icon top-2" src={infoIcon} alt="" onClick={openBlacksmithModal} />
          </div>
          <ExpProgressBar
            level={blacksmithData.level}
            currentExp={blacksmithData.exp}
            requiredExp={(blacksmithData.level + 1) * BASE_BLACKSMITH_EXP}
          />
        </div>
      </div>
      <div>
        <div className="item-crafting__bs-title">Level {blacksmithData.level}</div>
        <div>
          <div className="item-crafting__bs-desc">
            {isRuneLevelUp ? (
              <>
                <b className="text-9">+{blacksmithData.level / 2}%</b>
                <span> extra chance of successful rune level-up</span>
              </>
            ) : (
              <>
                <b className="text-9">+{(BLACKSMITH_RATE * blacksmithData.level) / 2}%</b>
                <span> chance of getting items in their highest rarity if it's legendary or mythical and</span>
                <b className="text-9"> +{BLACKSMITH_RATE * blacksmithData.level}%</b> for epic or lower.
              </>
            )}
          </div>
          <div className="mt-4 fs-2">
            <div className="dashed-title dashed-title--small">
              <span>Recipe</span>
            </div>
            <div>
              Name: <b className="text-yellow">{selectedItemRecipe.name}</b>
            </div>
            <div className="mb-1 mt-1">
              EXP per item crafted successfully: <b className="text-yellow">+{selectedItemRecipe.point} EXP</b>
            </div>
            {selectedItemRecipe.maxRarityImproved > 0 && (
              <div>
                Max bonus rate: <b className="text-yellow">+{selectedItemRecipe.maxRarityImproved}%</b>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
