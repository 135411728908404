import { RuneReducer } from "src/app/types/reducer-state";
import { runeActionTypes } from "src/app/actions/runeAction";

const initialState: RuneReducer = {
  requiredItemIds: new Array(3).fill(null),
  requiredItemAmount: new Array(3).fill(0),
  selectedRune: null,
  runeRefresh: false,
};

export default function runeReducer(state = initialState, action: any): RuneReducer {
  switch (action.type) {
    case runeActionTypes.SET_REQUIRED_ITEM: {
      const cloneRequiredItemIds = [...state.requiredItemIds];
      cloneRequiredItemIds.splice(action.payload.index, 1, action.payload.itemId);

      const cloneRequiredItemAmount = [...state.requiredItemAmount];
      cloneRequiredItemAmount.splice(action.payload.index, 1, action.payload.itemAmount);
      return {
        ...state,
        requiredItemIds: cloneRequiredItemIds,
        requiredItemAmount: cloneRequiredItemAmount,
      };
    }
    case runeActionTypes.REFRESH: {
      return {
        ...state,
        runeRefresh: action.payload.runeRefresh,
        requiredItemIds: new Array(3).fill(null),
        requiredItemAmount: new Array(3).fill(0),
      };
    }
    case runeActionTypes.SELECT_RUNE: {
      return {
        ...state,
        selectedRune: action.payload.rune,
      };
    }
    default:
      return state;
  }
}
