import React, { useCallback, useState } from "react";
import { ExchangeItem, Item, ListItem, OfferItem, RuleDestItem, UserItem } from "src/app/types/equipment";
import { formatNumber, roundNumber, toBigAmount } from "src/app/utils/helpers";
import { compareNumbers, minusNumbers, multiplyNumbers } from "src/app/utils/calculators";
import {
  list,
  delist,
  buy,
  offer,
  takeOffer,
  cancelOffer,
  takeExchangeOffer,
  cancelExchange,
} from "src/app/actions/equipmentAction";
import {
  listMaterial,
  delistMaterial,
  buyMaterial,
  offerMaterial,
  takeOfferMaterial,
  cancelOfferMaterial,
} from "src/app/actions/materialAction";
import { Material, UserMaterial } from "src/app/types/materials";
import InputGroup from "src/app/components/Commons/InputGroup";
import faraLogo from "src/assets/images/tokens/fara.png";
import { ACTIONS, ITEM_EXCHANGE_FEE } from "src/app/configs/constants";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import SwapIcon from "@material-ui/icons/SwapVerticalCircle";
import { useDispatch, useSelector } from "react-redux";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import ENV from "src/app/configs/env";
import { debounce } from "lodash";

export type userItemActionProps = {
  type: number;
  isEquipmentMarket: boolean;
  item: Item | Material;
  items?: any; // UserItem[] | UserMaterial[]
  selectedList?: ListItem;
  arrayLists?: ListItem[];
  ownLists?: ListItem[];
  selectedOffer?: OfferItem;
  arrayOffers?: OfferItem[];
  ownOffers?: OfferItem[];
  selectedExchange?: ExchangeItem;
};

export default function useItemAction(props: userItemActionProps) {
  const {
    type,
    isEquipmentMarket,
    item,
    items,
    selectedList,
    arrayLists,
    ownLists,
    selectedOffer,
    arrayOffers,
    ownOffers,
    selectedExchange,
  } = props;

  const dispatch = useDispatch();
  const { address, balance } = useSelector((state: any) => state.account);

  const [needApprove, sendApproveTx, addSpendingAmount] = useFetchingAllowance(
    isEquipmentMarket ? ENV.CONTRACT.EQUIPMENT_MARKET : ENV.CONTRACT.MATERIAL_MARKET,
    undefined,
    undefined,
    getSpendingAmountByType(type)
  );

  const debounceAddSpendingAmount = useCallback(debounce(addSpendingAmount, 500), []);
  const [listingPrice, setListingPrice] = useState("");
  const [listingAmount, setListingAmount] = useState("1");
  const [buyAmount, setBuyAmount] = useState("1");
  const [offerPrice, setOfferPrice] = useState("");
  const [offerAmount, setOfferAmount] = useState("1");
  const [takeOfferAmount, setTakeOfferAmount] = useState("1");
  const [priceError, setPriceError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [exchangeError, setExchangeError] = useState("");

  const userItem = items?.find((userItemData: UserItem | UserMaterial) => +userItemData.id === item.id);
  const { content, onSubmit, submitText } = renderPopupContent();

  function handleListingPriceChange(e: any, price?: string) {
    setPriceError("");
    setAmountError("");
    if (price) {
      setListingPrice(price);
      return;
    }
    setListingPrice(e.target.value);
  }

  function getSpendingAmountByType(type: number) {
    switch (type) {
      case ACTIONS.BUY_EQUIPMENT:
      case ACTIONS.BUY_MATERIAL:
        return multiplyNumbers(selectedList?.price ?? 0, 1);
      case ACTIONS.OFFER_EQUIPMENT:
      case ACTIONS.OFFER_MATERIAL:
        return multiplyNumbers(0, 1);
    }
  }

  function handleListingAmountChange(e: any, amount?: string) {
    setPriceError("");
    setAmountError("");
    if (amount) {
      setListingAmount(amount);
      return;
    }
    setListingAmount(e.target.value);
  }

  function handleBuyAmountChange(e: any, amount?: string) {
    setAmountError("");
    if (amount) {
      setBuyAmount(amount);
      return;
    }
    setBuyAmount(e.target.value);
    debounceAddSpendingAmount(multiplyNumbers(selectedList?.price ?? 0, e.target.value ?? 0));
  }

  function handleOfferPriceChange(e: any, price?: string) {
    setPriceError("");
    if (price) {
      setOfferPrice(price);
      return;
    }
    setOfferPrice(e.target.value);
    debounceAddSpendingAmount(multiplyNumbers(e.target.value ?? 0, offerAmount ?? 0));
  }

  function handleOfferAmountChange(e: any, amount?: string) {
    setAmountError("");
    if (amount) {
      setOfferAmount(amount);
      return;
    }
    setOfferAmount(e.target.value);
    debounceAddSpendingAmount(multiplyNumbers(offerPrice ?? 0, e.target.value ?? 0));
  }

  function handleTakeOfferAmountChange(e: any, amount?: string) {
    setAmountError("");
    if (amount) {
      setTakeOfferAmount(amount);
      return;
    }
    setTakeOfferAmount(e.target.value);
  }

  function sendListTx() {
    const isNotEnough = !userItem || compareNumbers(userItem.available ?? 0, listingAmount ?? 0) === -1;

    if (listingPrice === "" || +listingPrice <= 0) {
      setPriceError("Invalid listing price.");
    } else if (listingAmount === "" || +listingAmount <= 0) {
      setAmountError("Invalid listing amount.");
    } else if (isNotEnough) {
      setAmountError("Insufficient item balance.");
    } else {
      const { list } = _getDispatchActions();
      dispatch(list(item.id, toBigAmount(listingPrice), listingAmount));
    }
  }

  function sendDelistTx() {
    const { delist } = _getDispatchActions();
    dispatch(delist(item.id));
  }

  function sendBuyTx() {
    const totalCost = multiplyNumbers(selectedList?.price ?? 0, buyAmount ?? 0);
    const isNotEnough = compareNumbers(balance.FARA, totalCost ?? 0);

    if (buyAmount === "" || +buyAmount <= 0) {
      setAmountError("Invalid buying amount.");
    } else if (isNotEnough === -1) {
      setAmountError("Insufficient FARA balance.");
    } else if (selectedList && +buyAmount > selectedList.amount) {
      setAmountError("Insufficient item amount for this listing order.");
    } else {
      const { buy } = _getDispatchActions();
      dispatch(buy(item.id, toBigAmount(selectedList?.price ?? 0), +buyAmount, selectedList?.seller ?? "0"));
    }
  }

  function sendOfferTx() {
    if (offerPrice === "" || +offerPrice <= 0) {
      setPriceError("Invalid offering price.");
    } else if (offerAmount === "" || +offerAmount <= 0) {
      setAmountError("Invalid offering amount.");
    } else {
      const totalCost = multiplyNumbers(offerPrice, offerAmount);
      const isNotEnough = compareNumbers(balance.FARA, totalCost ?? 0);
      if (isNotEnough === -1) {
        setPriceError("Insufficient FARA balance.");
      } else {
        const { offer } = _getDispatchActions();
        dispatch(offer(item.id, toBigAmount(offerPrice), offerAmount));
      }
    }
  }

  function sendCancelOfferTx() {
    const { cancelOffer } = _getDispatchActions();
    dispatch(cancelOffer(item.id));
  }

  function sendTakeOfferTx() {
    if (!selectedOffer) return;
    const isNotEnough = userItem ? compareNumbers(userItem.available ?? 0, takeOfferAmount ?? 0) : true;

    if (takeOfferAmount === "" || takeOfferAmount === "0") {
      setAmountError("Invalid amount.");
    } else if (+takeOfferAmount > selectedOffer.amount) {
      setAmountError(`You can only sell maximum of ${formatNumber(selectedOffer.amount)} items for this offer.`);
    } else if (isNotEnough === true || isNotEnough === -1) {
      setAmountError("Insufficient item balance.");
    } else {
      const { takeOffer } = _getDispatchActions();
      dispatch(takeOffer(item.id, toBigAmount(selectedOffer.price), +takeOfferAmount, selectedOffer.buyer));
    }
  }

  function sendCancelOfferExchangeTx() {
    dispatch(cancelExchange(item.id));
  }

  function sendTakeExchangeOfferTx() {
    if (!selectedExchange) return;

    const inventory = _getItemAvailability(selectedExchange.ruleDestItems);

    if (inventory.find((item) => !item.isValid)) {
      setExchangeError("Insufficient items to exchange");
    } else if (selectedExchange.seller) {
      dispatch(takeExchangeOffer(item.id, 1, selectedExchange.seller));
    }
  }

  function renderListContent() {
    let content, onSubmit, submitText, childContent, maxAmount;
    const highestOffer = _getHighestOffer();
    const isTakeOffer = !!(highestOffer && +listingPrice > 0 && +listingPrice <= +highestOffer.price);
    const itemBalance = items && items[0] ? items[0].available : 0;

    if (ownLists && ownLists[0]) {
      childContent = (
        <div>
          You've already have a listing order of {ownLists[0]?.amount} {item.displayName} with{" "}
          {formatNumber(ownLists[0]?.price, 4)} FARA each. Please cancel your current order first before creating a new
          one.
        </div>
      );
      onSubmit = sendDelistTx;
      submitText = "Delist";
    } else if (isTakeOffer && highestOffer && itemBalance > 0) {
      childContent = (
        <div className="slide-up">
          Your listing price of {formatNumber(listingPrice, 4)} FARA is lower or equal to the highest offering order of{" "}
          {formatNumber(highestOffer.price, 4)} FARA. Please place a listing order with higher price or you can sell
          them directly by accepting existing orders.
        </div>
      );
    } else {
      if (+listingAmount > 0 && +listingPrice > 0) {
        const totalPrice = multiplyNumbers(listingAmount, listingPrice);
        const deductedAmount = minusNumbers(totalPrice, multiplyNumbers(totalPrice, ITEM_EXCHANGE_FEE / 100));

        childContent = (
          <div className="slide-up">
            You are making a listing order of {listingAmount} {item.displayName} with {formatNumber(listingPrice, 4)}{" "}
            FARA each. After deducting {ITEM_EXCHANGE_FEE}% marketplace fee, you will receive total of{" "}
            {formatNumber(deductedAmount, 4)} FARA.
          </div>
        );
      }

      maxAmount = isEquipmentMarket ? itemBalance : userItem.available;
      onSubmit = sendListTx;
      submitText = "List";
    }

    content = (
      <div>
        <InputGroup
          className="text-field--large"
          value={listingPrice}
          handleChange={handleListingPriceChange}
          symbol="FARA"
          balance={balance.FARA}
          error={priceError}
          tokenImage={faraLogo}
          hideBalance={true}
          label={"Item Price"}
          hideMaxBtn={true}
        />
        <br />
        <InputGroup
          className="text-field--large"
          value={listingAmount}
          handleChange={handleListingAmountChange}
          symbol="BNB"
          balance={balance.BNB}
          error={amountError}
          hideBalance={true}
          label={"Item Amount"}
          hideMaxBtn={!maxAmount}
          maxAmount={maxAmount}
        />
        <div className="fs-2">{renderInventoryBlock()}</div>
        <div className="mt-3 small-text small-text--warning">{childContent}</div>
      </div>
    );

    return { content, onSubmit, submitText };
  }

  function renderBuyContent() {
    const selectedPrice = selectedList?.price ?? 0;
    const selectedAmount = selectedList?.amount ?? 0;
    const possibleBuyAmount = Math.floor(balance.FARA / +selectedPrice);
    const maxAmount = possibleBuyAmount <= selectedAmount ? possibleBuyAmount : selectedAmount;
    const totalCost = multiplyNumbers(selectedPrice, buyAmount ?? 0);

    return (
      <div className="knight__checkoutWrapper">
        <InputGroup
          className="text-field--large"
          value={buyAmount}
          handleChange={handleBuyAmountChange}
          symbol="FARA"
          balance={balance.FARA}
          error={amountError}
          hideBalance={true}
          label={"Item Amount"}
          maxAmount={maxAmount}
        />
        <div className="mt-1 fs-2">{renderBalanceBlock()}</div>
        <div className="mt-3 small-text small-text--warning">
          You are buying {buyAmount ? formatNumber(buyAmount) : 0} {item.displayName} with total of{" "}
          {formatNumber(totalCost, 4)} FARA.
        </div>
      </div>
    );
  }

  function renderMakeOfferContent() {
    let content, onSubmit, submitText, childContent;
    const lowestListing = _getLowestListing();
    const isBought = !!(lowestListing && +offerPrice >= +lowestListing.price);
    const offerExist = ownOffers && ownOffers[0];

    if (ownOffers && ownOffers[0]) {
      childContent = (
        <div>
          You've already have an offer of {ownOffers[0]?.amount} {item.displayName} with{" "}
          {formatNumber(ownOffers[0]?.price, 4)} FARA each. Please cancel your current offer before creating a new one.
        </div>
      );
      onSubmit = sendCancelOfferTx;
      submitText = "Cancel Offer";
    } else if (isBought && lowestListing) {
      childContent = (
        <div className="slide-up">
          Your offering price of {formatNumber(offerPrice, 4)} FARA is higher or equal to the lowest listing order of{" "}
          {formatNumber(lowestListing.price, 4)} FARA. Please place an offering order with lower price or you can buy
          them directly by accepting existing orders.
        </div>
      );
    } else {
      if (+offerAmount > 0 && +offerPrice > 0) {
        const totalCost = multiplyNumbers(offerAmount, offerPrice);

        childContent = (
          <div>
            You are making an offering order of {formatNumber(offerAmount)} {item.displayName} with{" "}
            {formatNumber(offerPrice, 4)} FARA each and total of {formatNumber(totalCost, 4)} FARA.
          </div>
        );
      }

      onSubmit = sendOfferTx;
      submitText = "Offer";
    }

    content = (
      <div>
        {!offerExist && (
          <>
            <InputGroup
              className="text-field--large"
              value={offerPrice}
              handleChange={handleOfferPriceChange}
              symbol="FARA"
              balance={balance.FARA}
              error={priceError}
              tokenImage={faraLogo}
              hideBalance={true}
              label={"Item Price"}
              hideMaxBtn={true}
            />
            <br />
            <InputGroup
              className="text-field--large"
              value={offerAmount}
              handleChange={handleOfferAmountChange}
              symbol="BNB"
              balance={balance.BNB}
              error={amountError}
              hideBalance={true}
              label={"Item Amount"}
              hideMaxBtn={true}
            />
            <div className="mt-1 fs-2">{renderBalanceBlock()}</div>
          </>
        )}
        <div className="mt-3 small-text small-text--warning">{childContent}</div>
      </div>
    );

    return { content, onSubmit, submitText };
  }

  function renderTakeOfferContent() {
    const selectedPrice = selectedOffer?.price ?? 0;
    const selectedAmount = selectedOffer?.amount ?? 0;
    const inventoryData = _getInventoryData();
    const possibleBuyAmount = Math.floor(balance.FARA / +selectedPrice);
    const possibleAmount = selectedAmount <= inventoryData.available ? selectedAmount : inventoryData.available;
    const maxAmount = possibleBuyAmount <= possibleAmount ? possibleBuyAmount : possibleAmount;
    const totalTake = multiplyNumbers(selectedPrice ?? 0, takeOfferAmount ?? 0);
    const deductedAmount = minusNumbers(totalTake, multiplyNumbers(totalTake, ITEM_EXCHANGE_FEE / 100));

    return (
      <div className="knight__checkoutWrapper">
        <InputGroup
          className="text-field--large"
          value={takeOfferAmount}
          handleChange={handleTakeOfferAmountChange}
          symbol="FARA"
          balance={balance.FARA}
          error={amountError}
          hideBalance={true}
          label={"Item Amount"}
          maxAmount={maxAmount}
        />
        <div className="fs-2">{renderInventoryBlock(inventoryData)}</div>
        {takeOfferAmount && (
          <div className="mt-3 small-text slide-up small-text--warning">
            - You are selling {takeOfferAmount ? formatNumber(takeOfferAmount) : 0} {item.displayName} with{" "}
            {formatNumber(selectedPrice, 4)} FARA each. After deducting {ITEM_EXCHANGE_FEE}% marketplace fee, you will
            receive total of {formatNumber(deductedAmount, 4)} FARA.
          </div>
        )}
        <div className="mt-1 small-text slide-up small-text--warning">
          - You can only sell maximum of {formatNumber(selectedAmount)} items for this order.
        </div>
      </div>
    );
  }

  function renderTakeExchangeOfferContent() {
    if (!selectedExchange) return "";

    const inventory = _getItemAvailability(selectedExchange.ruleDestItems);

    return (
      <div className="knight__checkoutWrapper">
        <div className="mt-3 small-text small-text--warning">
          Are you sure you want to exchange these items from your inventory for 1 {item.displayName}?
        </div>
        <div className="item-exchange__title mt-3">From ({_getTotalAmount(selectedExchange.ruleDestItems)})</div>
        <div className="item-exchange__storage item-exchange__storage--take">
          {selectedExchange.ruleDestItems.map((rule, index: number) => {
            return <ItemIcon item={rule.item} layer={2} rule={rule} key={index} />;
          })}
        </div>
        <div className="align-center mt-3">
          <SwapIcon className="item-exchange__swap no-margin" />
        </div>
        <div className="item-exchange__title">To</div>
        <div className=" item-exchange item-exchange__block item-exchange__block--take">
          <div className="item-exchange__amount">01</div>
          <div className="item-exchange__content">
            <div className="item-exchange__name">{item.displayName}</div>
            <ItemIcon item={item as Item} layer={2} />
          </div>
        </div>
        <div>
          <div className="mt-3">
            <span>Current inventory:</span>
            {inventory.map((itemAvail: any, index: number) => (
              <div key={index} className="fs-2">
                - {itemAvail.displayName} x{itemAvail.available}
              </div>
            ))}
          </div>
        </div>
        {exchangeError && <div className="error-text">{exchangeError}</div>}
      </div>
    );
  }

  function renderPopupContent() {
    let content, onSubmit, submitText;

    if (type === ACTIONS.LIST_EQUIPMENT || type === ACTIONS.LIST_MATERIAL) {
      const listData = renderListContent();
      content = listData.content;
      onSubmit = listData.onSubmit;
      submitText = listData.submitText;
    } else if (type === ACTIONS.DELIST_EQUIPMENT || type === ACTIONS.DELIST_MATERIAL) {
      content = renderDelistContent();
      onSubmit = sendDelistTx;
      submitText = "Delist";
    } else if (type === ACTIONS.BUY_EQUIPMENT || type === ACTIONS.BUY_MATERIAL) {
      content = renderBuyContent();
      onSubmit = sendBuyTx;
      submitText = "Buy";
    } else if (type === ACTIONS.OFFER_EQUIPMENT || type === ACTIONS.OFFER_MATERIAL) {
      const makeOfferData = renderMakeOfferContent();
      content = makeOfferData.content;
      onSubmit = makeOfferData.onSubmit;
      submitText = makeOfferData.submitText;
    } else if (type === ACTIONS.CANCEL_OFFER_EQUIPMENT || type === ACTIONS.CANCEL_OFFER_MATERIAL) {
      content = renderCancelOfferContent();
      onSubmit = sendCancelOfferTx;
      submitText = "Cancel Offer";
    } else if (type === ACTIONS.TAKE_OFFER_EQUIPMENT || type === ACTIONS.TAKE_OFFER_MATERIAL) {
      content = renderTakeOfferContent();
      onSubmit = sendTakeOfferTx;
      submitText = "Sell";
    } else if (type === ACTIONS.CANCEL_EXCHANGE_EQUIPMENT) {
      content = renderCancelExchangeContent();
      onSubmit = sendCancelOfferExchangeTx;
      submitText = "Cancel Offer";
    } else if (type === ACTIONS.TAKE_EXCHANGE_OFFER) {
      content = renderTakeExchangeOfferContent();
      onSubmit = sendTakeExchangeOfferTx;
      submitText = "Exchange";
    }
    if (
      needApprove &&
      (type === ACTIONS.BUY_EQUIPMENT ||
        type === ACTIONS.OFFER_EQUIPMENT ||
        type === ACTIONS.BUY_MATERIAL ||
        type === ACTIONS.OFFER_MATERIAL)
    ) {
      onSubmit = sendApproveTx;
      submitText = "Approve";
    }

    return { content, onSubmit, submitText };
  }

  function renderInventoryBlock(inventory?) {
    if (!inventory) inventory = _getInventoryData();

    return (
      <div className="mt-1">
        <div>
          <span>Current inventory:</span>
          <span>
            {" "}
            {inventory.displayName} x{inventory.available}
          </span>
        </div>
      </div>
    );
  }

  function renderCancelOfferContent() {
    return <div>Are you sure you want to cancel your offer from the marketplace?</div>;
  }

  function renderCancelExchangeContent() {
    return <div>Are you sure you want to cancel your exchange offer from the marketplace?</div>;
  }

  function renderDelistContent() {
    return <div>Are you sure you want to delist your item from the marketplace?</div>;
  }

  function renderBalanceBlock() {
    return (
      <div>
        <span>Current balance:</span>
        <span> {roundNumber(balance.BNB, 3, true)} BNB</span>
        <span>, {roundNumber(balance.FARA, 2, true)} FARA</span>
      </div>
    );
  }

  function _getInventoryData() {
    const inventory = {
      displayName: item.displayName,
      available: 0,
    };

    if (userItem) {
      inventory.available = userItem.available;
    }

    return inventory;
  }

  function _getLowestListing() {
    if (!arrayLists || !arrayLists.length) return null;
    const arrayListsExcludeUser = arrayLists.filter((item) => item.seller !== address);
    if (!arrayListsExcludeUser || !arrayListsExcludeUser.length) return null;
    return arrayListsExcludeUser[0];
  }

  function _getHighestOffer() {
    if (!arrayOffers || !arrayOffers.length) return null;
    const arrayOfferExcludeUser = arrayOffers.filter((item) => item.buyer !== address);
    if (!arrayOfferExcludeUser || !arrayOfferExcludeUser.length) return null;
    return arrayOfferExcludeUser[0];
  }

  function _getTotalAmount(items: any[]) {
    return items
      .map((item) => {
        return item.amount;
      })
      .reduce((acc, value) => {
        return +acc + +value;
      });
  }

  function _getItemAvailability(ruleDestItems: RuleDestItem[]) {
    const inventory: any[] = [];
    if (ruleDestItems) {
      ruleDestItems.forEach((rule: RuleDestItem) => {
        const foundUserItem = items?.find((i: UserItem) => i.item.id === rule.item.id);
        inventory.push({
          displayName: foundUserItem ? foundUserItem.item.displayName : rule.item.displayName,
          available: foundUserItem ? foundUserItem.available : 0,
          isValid: foundUserItem ? rule.amount - foundUserItem.available <= 0 : false,
        });
      });
    }
    return inventory;
  }

  function _getDispatchActions() {
    if (isEquipmentMarket) {
      return {
        list: list,
        delist: delist,
        buy: buy,
        offer: offer,
        takeOffer: takeOffer,
        cancelOffer: cancelOffer,
      };
    }

    return {
      list: listMaterial,
      delist: delistMaterial,
      buy: buyMaterial,
      offer: offerMaterial,
      takeOffer: takeOfferMaterial,
      cancelOffer: cancelOfferMaterial,
    };
  }

  return [onSubmit, submitText, content];
}
