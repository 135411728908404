export const allItems: any = {
  "1": {
    "id": 1,
    "displayName": "Dazzling Summer Armor",
    "name": "Dazzling Summer Armor",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 1000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 8,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 98,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 141,
    "tierItemIds": [
      1,
      141,
      142
    ],
    "setId": 1,
    "animType": null
  },
  "2": {
    "id": 2,
    "displayName": "Dazzling Summer Boots",
    "name": "Dazzling Summer Boots",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 9000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 143,
    "tierItemIds": [
      2,
      143,
      144
    ],
    "setId": 1,
    "animType": null
  },
  "3": {
    "id": 3,
    "displayName": "Dazzling Summer Gloves",
    "name": "Dazzling Summer Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 8000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 1,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 145,
    "tierItemIds": [
      3,
      145,
      146
    ],
    "setId": 1,
    "animType": null
  },
  "4": {
    "id": 4,
    "displayName": "Dazzling Summer Helmet",
    "name": "Dazzling Summer Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 9000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 2,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 147,
    "tierItemIds": [
      4,
      147,
      148
    ],
    "setId": 1,
    "animType": null
  },
  "5": {
    "id": 5,
    "displayName": "Dazzling Summer Pants",
    "name": "Dazzling Summer Pants",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 4000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 149,
    "tierItemIds": [
      5,
      149,
      150
    ],
    "setId": 1,
    "animType": null
  },
  "6": {
    "id": 6,
    "displayName": "Dazzling Summer Bow",
    "name": "Dazzling Summer Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 700,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 44,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 4,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Breaking Strike",
          "description": "Attacks have a 20% chance of dealing extra damage depending on the target's Physical Defense."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 48,
    "tierItemIds": [
      6,
      48,
      49
    ],
    "setId": 1,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "7": {
    "id": 7,
    "displayName": "Summer Solstice Armor",
    "name": "Summer Solstice Armor",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 4000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 5,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 34,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 151,
    "tierItemIds": [
      7,
      151,
      152
    ],
    "setId": 2,
    "animType": null
  },
  "8": {
    "id": 8,
    "displayName": "Summer Solstice Boots",
    "name": "Summer Solstice Boots",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 10000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 9,
      "INT": 0,
      "LUK": 0,
      "HP": 11,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 153,
    "tierItemIds": [
      8,
      153,
      154
    ],
    "setId": 2,
    "animType": null
  },
  "9": {
    "id": 9,
    "displayName": "Summer Solstice Gloves",
    "name": "Summer Solstice Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 3500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 1,
      "LUK": 1,
      "HP": 34,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 155,
    "tierItemIds": [
      9,
      155,
      156
    ],
    "setId": 2,
    "animType": null
  },
  "10": {
    "id": 10,
    "displayName": "Summer Solstice Helmet",
    "name": "Summer Solstice Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 9,
    "requiredStats": [],
    "maxSupply": 1200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 8,
      "Magical Defense": 2,
      "Physical Defense": 8,
      "Critical Damage": 5,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 2,
      "HP": 80,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 157,
    "tierItemIds": [
      10,
      157,
      158
    ],
    "setId": 2,
    "animType": null
  },
  "11": {
    "id": 11,
    "displayName": "Summer Solstice Pants",
    "name": "Summer Solstice Pants",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 8000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 11,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 159,
    "tierItemIds": [
      11,
      159,
      160
    ],
    "setId": 2,
    "animType": null
  },
  "12": {
    "id": 12,
    "displayName": "Summer Solstice Bow",
    "name": "Summer Solstice Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 700,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 48,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 6,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "ViperSting",
          "description": "Attacks apply Poison to enemy, inflicts 10 Pure Damage per turn for 2 turn(s)."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 50,
    "tierItemIds": [
      12,
      50,
      51
    ],
    "setId": 2,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "13": {
    "id": 13,
    "displayName": "Evil Eye Rod",
    "name": "Evil Eye Weapon",
    "races": [
      "Dragonborn",
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 500,
    "tier": 1,
    "stats": {
      "Magical Damage": 39,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 1,
      "AGI": 1,
      "INT": 10,
      "LUK": 2,
      "HP": 58,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Mark of Death",
          "description": "Attacks have a 20% chance of marking enemy, dealing 20 Pure Damage after 2 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 52,
    "tierItemIds": [
      13,
      52,
      53
    ],
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "14": {
    "id": 14,
    "displayName": "Unscrutable Armor",
    "name": "Unscrutable Armor",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 3,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 8000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 161,
    "tierItemIds": [
      14,
      161,
      162
    ],
    "setId": 5,
    "animType": null
  },
  "15": {
    "id": 15,
    "displayName": "Unscrutable Boots",
    "name": "Unscrutable Boots",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 9000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 1,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 163,
    "tierItemIds": [
      15,
      163,
      164
    ],
    "setId": 5,
    "animType": null
  },
  "16": {
    "id": 16,
    "displayName": "Unscrutable Gloves",
    "name": "Unscrutable Gloves",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 1200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 2,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 165,
    "tierItemIds": [
      16,
      165,
      166
    ],
    "setId": 5,
    "animType": null
  },
  "17": {
    "id": 17,
    "displayName": "Unscrutable Pants",
    "name": "Unscrutable Pants",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 9000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 167,
    "tierItemIds": [
      17,
      167,
      168
    ],
    "setId": 5,
    "animType": null
  },
  "18": {
    "id": 18,
    "displayName": "Unscrutable Dagger",
    "name": "Unscrutable Weapon",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 3000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 28,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 5,
    "nextTierId": 54,
    "tierItemIds": [
      18,
      54,
      55
    ],
    "setId": 5,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "19": {
    "id": 19,
    "displayName": "Artemis",
    "name": "Artemis Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 3000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 22,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 6,
      "INT": 1,
      "LUK": 2,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 56,
    "tierItemIds": [
      19,
      56,
      57
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "20": {
    "id": 20,
    "displayName": "Iron Fist Gloves",
    "name": "Iron Fist Gloves",
    "races": [
      "Elf",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 10000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 12,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 169,
    "tierItemIds": [
      20,
      169,
      170
    ],
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "21": {
    "id": 21,
    "displayName": "Taming Sari",
    "name": "Taming Sari Weapon",
    "races": [
      "Elf",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 27,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 54,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 4,
      "AGI": 11,
      "INT": 2,
      "LUK": 6,
      "HP": 90,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 4,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 12 HP per attack."
        },
        {
          "name": "Beast Penetration",
          "description": "Pierces 20% of Orcs and Dragons' Physical Defense."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 58,
    "tierItemIds": [
      21,
      58,
      59
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "22": {
    "id": 22,
    "displayName": "Harpe",
    "name": "Harpe Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 1200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 27,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 8,
      "INT": 0,
      "LUK": 2,
      "HP": 35,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 5 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 60,
    "tierItemIds": [
      22,
      60,
      61
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "23": {
    "id": 23,
    "displayName": "Lagan Armor",
    "name": "Lagan Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 3500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 1,
      "INT": 0,
      "LUK": 3,
      "HP": 76,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 171,
    "tierItemIds": [
      23,
      171,
      172
    ],
    "setId": 3,
    "animType": null
  },
  "24": {
    "id": 24,
    "displayName": "Lagan Boots",
    "name": "Lagan Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 4000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 2,
      "HP": 65,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 173,
    "tierItemIds": [
      24,
      173,
      174
    ],
    "setId": 3,
    "animType": null
  },
  "25": {
    "id": 25,
    "displayName": "Lagan Pants",
    "name": "Lagan Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 9000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 1,
      "INT": 1,
      "LUK": 0,
      "HP": 43,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 175,
    "tierItemIds": [
      25,
      175,
      176
    ],
    "setId": 3,
    "animType": null
  },
  "26": {
    "id": 26,
    "displayName": "Lagan Wand",
    "name": "Lagan Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 1200,
    "tier": 1,
    "stats": {
      "Magical Damage": 29,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 62,
    "tierItemIds": [
      26,
      62,
      63
    ],
    "setId": 3,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "27": {
    "id": 27,
    "displayName": "Stormbreaker",
    "name": "Stormbreaker Weapon",
    "races": [
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 6,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 98,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 5,
      "STR": 8,
      "AGI": 2,
      "INT": 5,
      "LUK": 10,
      "HP": 157,
      "Critical": 8,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": [
        {
          "name": "Heaven Revenge",
          "description": "Counterattacks regardless of the attacker's range."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 64,
    "tierItemIds": [
      27,
      64,
      65
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "28": {
    "id": 28,
    "displayName": "Talhoffer Armor",
    "name": "Talhoffer Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 4000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 68,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 177,
    "tierItemIds": [
      28,
      177,
      178
    ],
    "setId": 6,
    "animType": null
  },
  "29": {
    "id": 29,
    "displayName": "Talhoffer Boots",
    "name": "Talhoffer Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 10000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 179,
    "tierItemIds": [
      29,
      179,
      180
    ],
    "setId": 6,
    "animType": null
  },
  "30": {
    "id": 30,
    "displayName": "Talhoffer Gloves",
    "name": "Talhoffer Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 10000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 1,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 181,
    "tierItemIds": [
      30,
      181,
      182
    ],
    "setId": 6,
    "animType": null
  },
  "31": {
    "id": 31,
    "displayName": "Talhoffer Helmet",
    "name": "Talhoffer Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 9000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 1,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 183,
    "tierItemIds": [
      31,
      183,
      184
    ],
    "setId": 6,
    "animType": null
  },
  "32": {
    "id": 32,
    "displayName": "Talhoffer Pants",
    "name": "Talhoffer Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 7,
    "requiredStats": [],
    "maxSupply": 1200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 77,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 185,
    "tierItemIds": [
      32,
      185,
      186
    ],
    "setId": 6,
    "animType": null
  },
  "33": {
    "id": 33,
    "displayName": "Talhoffer Sword",
    "name": "Talhoffer Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 1200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 30,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 66,
    "tierItemIds": [
      33,
      66,
      67
    ],
    "setId": 6,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "34": {
    "id": 34,
    "displayName": "Phalanx Armor",
    "name": "Phalanx Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 3000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 187,
    "tierItemIds": [
      34,
      187,
      188
    ],
    "setId": 4,
    "animType": null
  },
  "35": {
    "id": 35,
    "displayName": "Phalanx Boots",
    "name": "Phalanx Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 10000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 189,
    "tierItemIds": [
      35,
      189,
      190
    ],
    "setId": 4,
    "animType": null
  },
  "36": {
    "id": 36,
    "displayName": "Phalanx Gloves",
    "name": "Phalanx Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 10000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 6,
    "nextTierId": 191,
    "tierItemIds": [
      36,
      191,
      192
    ],
    "setId": 4,
    "animType": null
  },
  "37": {
    "id": 37,
    "displayName": "Phalanx Helmet",
    "name": "Phalanx Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 6,
    "requiredStats": [],
    "maxSupply": 1200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 10,
      "Critical Damage": 2,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 82,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 193,
    "tierItemIds": [
      37,
      193,
      194
    ],
    "setId": 4,
    "animType": null
  },
  "38": {
    "id": 38,
    "displayName": "Phalanx Pants",
    "name": "Phalanx Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 4000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 1,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 195,
    "tierItemIds": [
      38,
      195,
      196
    ],
    "setId": 4,
    "animType": null
  },
  "39": {
    "id": 39,
    "displayName": "Phalanx Halberd",
    "name": "Phalanx Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 1200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 31,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 40,
    "tierItemIds": [
      39,
      40,
      41
    ],
    "setId": 4,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "40": {
    "id": 40,
    "displayName": "Mighty Phalanx Halberd",
    "name": "Phalanx Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 44,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 4,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 41,
    "tierItemIds": [
      39,
      40,
      41
    ],
    "setId": 4,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "41": {
    "id": 41,
    "displayName": "Superior Phalanx Halberd",
    "name": "Phalanx Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 62,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 6,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      39,
      40,
      41
    ],
    "setId": 4,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "42": {
    "id": 42,
    "displayName": "Nami",
    "name": "Nami Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 7,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 44,
    "tierItemIds": [
      42,
      44,
      45
    ],
    "animType": null
  },
  "43": {
    "id": 43,
    "displayName": "Waling",
    "name": "Waling Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 7,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 2,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Sea Proof",
          "description": "Increase Water and Ice Resistance by 3%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 46,
    "tierItemIds": [
      43,
      46,
      47
    ],
    "animType": null
  },
  "44": {
    "id": 44,
    "displayName": "Mighty Nami",
    "name": "Nami Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 7,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 45,
    "tierItemIds": [
      42,
      44,
      45
    ],
    "animType": null
  },
  "45": {
    "id": 45,
    "displayName": "Superior Nami",
    "name": "Nami Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 7,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      42,
      44,
      45
    ],
    "animType": null
  },
  "46": {
    "id": 46,
    "displayName": "Mighty Waling",
    "name": "Waling Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 7,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 3,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Sea Proof",
          "description": "Increase Water and Ice Resistance by 4%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 47,
    "tierItemIds": [
      43,
      46,
      47
    ],
    "animType": null
  },
  "47": {
    "id": 47,
    "displayName": "Superior Waling",
    "name": "Waling Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 7,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 3,
      "INT": 4,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Sea Proof",
          "description": "Increase Water and Ice Resistance by 5%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      43,
      46,
      47
    ],
    "animType": null
  },
  "48": {
    "id": 48,
    "displayName": "Mighty Dazzling Summer Bow",
    "name": "Dazzling Summer Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 62,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Breaking Strike",
          "description": "Attacks have a 30% chance of dealing extra damage depending on the target's Physical Defense."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 49,
    "tierItemIds": [
      6,
      48,
      49
    ],
    "setId": 1,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "49": {
    "id": 49,
    "displayName": "Superior Dazzling Summer Bow",
    "name": "Dazzling Summer Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 88,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 13,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Breaking Strike",
          "description": "Attacks have a 40% chance of dealing extra damage depending on the target's Physical Defense."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      6,
      48,
      49
    ],
    "setId": 1,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "50": {
    "id": 50,
    "displayName": "Mighty Summer Solstice Bow",
    "name": "Summer Solstice Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Male",
      "Female"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 66,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "ViperSting",
          "description": "Attacks apply Poison to enemy, inflicts 20 Pure Damage per turn for 2 turn(s)."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 51,
    "tierItemIds": [
      12,
      50,
      51
    ],
    "setId": 2,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "51": {
    "id": 51,
    "displayName": "Superior Summer Solstice Bow",
    "name": "Summer Solstice Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Male",
      "Female"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 93,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 25,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 8,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "ViperSting",
          "description": "Attacks apply Poison to enemy, inflicts 30 Pure Damage per turn for 2 turn(s)."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      12,
      50,
      51
    ],
    "setId": 2,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "52": {
    "id": 52,
    "displayName": "Mighty Evil Eye Rod",
    "name": "Evil Eye Weapon",
    "races": [
      "Dragonborn",
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 55,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 2,
      "AGI": 2,
      "INT": 15,
      "LUK": 3,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Mark of Death",
          "description": "Attacks have a 35% chance of marking enemy, dealing 35 Pure Damage after 2 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 53,
    "tierItemIds": [
      13,
      52,
      53
    ],
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "53": {
    "id": 53,
    "displayName": "Superior Evil Eye Rod",
    "name": "Evil Eye Weapon",
    "races": [
      "Dragonborn",
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 78,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 3,
      "AGI": 3,
      "INT": 21,
      "LUK": 4,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Mark of Death",
          "description": "Attacks have a 50% chance of marking enemy, dealing 50 Pure Damage after 2 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      13,
      52,
      53
    ],
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "54": {
    "id": 54,
    "displayName": "Mighty Unscrutable Dagger",
    "name": "Unscrutable Weapon",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 36,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 16,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 55,
    "tierItemIds": [
      18,
      54,
      55
    ],
    "setId": 5,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "55": {
    "id": 55,
    "displayName": "Superior Unscrutable Dagger",
    "name": "Unscrutable Weapon",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 43,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 23,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      18,
      54,
      55
    ],
    "setId": 5,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "56": {
    "id": 56,
    "displayName": "Mighty Artemis",
    "name": "Artemis Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 32,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 9,
      "INT": 2,
      "LUK": 3,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 57,
    "tierItemIds": [
      19,
      56,
      57
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "57": {
    "id": 57,
    "displayName": "Superior Artemis",
    "name": "Artemis Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 46,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 13,
      "INT": 4,
      "LUK": 5,
      "HP": 66,
      "Critical": 1,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      19,
      56,
      57
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "58": {
    "id": 58,
    "displayName": "Mighty Taming Sari",
    "name": "Taming Sari Weapon",
    "races": [
      "Elf",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 76,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 6,
      "AGI": 16,
      "INT": 3,
      "LUK": 9,
      "HP": 127,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 20 HP per attack."
        },
        {
          "name": "Beast Penetration",
          "description": "Pierces 30% of Orcs and Dragons' Physical Defense."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 59,
    "tierItemIds": [
      21,
      58,
      59
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "59": {
    "id": 59,
    "displayName": "Superior Taming Sari",
    "name": "Taming Sari Weapon",
    "races": [
      "Elf",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 106,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 8,
      "AGI": 23,
      "INT": 4,
      "LUK": 13,
      "HP": 178,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 28 HP per attack."
        },
        {
          "name": "Beast Penetration",
          "description": "Pierces 40% of Orcs and Dragons' Physical Defense."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      21,
      58,
      59
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "60": {
    "id": 60,
    "displayName": "Mighty Harpe",
    "name": "Harpe Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 38,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 11,
      "INT": 0,
      "LUK": 3,
      "HP": 50,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 10 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 61,
    "tierItemIds": [
      22,
      60,
      61
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "61": {
    "id": 61,
    "displayName": "Superior Harpe",
    "name": "Harpe Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 53,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 16,
      "INT": 0,
      "LUK": 5,
      "HP": 70,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 15 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      22,
      60,
      61
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "62": {
    "id": 62,
    "displayName": "Mighty Lagan Wand",
    "name": "Lagan Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 42,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 63,
    "tierItemIds": [
      26,
      62,
      63
    ],
    "setId": 3,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "63": {
    "id": 63,
    "displayName": "Superior Lagan Wand",
    "name": "Lagan Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 58,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 0,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      26,
      62,
      63
    ],
    "setId": 3,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "64": {
    "id": 64,
    "displayName": "Mighty Stormbreaker",
    "name": "Stormbreaker Weapon",
    "races": [
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 138,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 7,
      "STR": 11,
      "AGI": 3,
      "INT": 7,
      "LUK": 14,
      "HP": 221,
      "Critical": 12,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": [
        {
          "name": "Heaven Revenge",
          "description": "Counterattacks regardless of the attacker's range."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 65,
    "tierItemIds": [
      27,
      64,
      65
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "65": {
    "id": 65,
    "displayName": "Superior Stormbreaker",
    "name": "Stormbreaker Weapon",
    "races": [
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 193,
      "Magical Defense": 8,
      "Physical Defense": 18,
      "Critical Damage": 10,
      "STR": 15,
      "AGI": 4,
      "INT": 10,
      "LUK": 20,
      "HP": 310,
      "Critical": 15,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": [
        {
          "name": "Heaven Revenge",
          "description": "Counterattacks regardless of the attacker's range."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      27,
      64,
      65
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "66": {
    "id": 66,
    "displayName": "Mighty Talhoffer Sword",
    "name": "Talhoffer Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 42,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 67,
    "tierItemIds": [
      33,
      66,
      67
    ],
    "setId": 6,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "67": {
    "id": 67,
    "displayName": "Superior Talhoffer Sword",
    "name": "Talhoffer Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 60,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 4,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      33,
      66,
      67
    ],
    "setId": 6,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "68": {
    "id": 68,
    "displayName": "Cheetara Gloves",
    "name": "Cheetara Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 4900,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 1,
      "LUK": 2,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 197,
    "tierItemIds": [
      68,
      197,
      198
    ],
    "setId": 10,
    "animType": null
  },
  "69": {
    "id": 69,
    "displayName": "Cheetara Helmet",
    "name": "Cheetara Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 5,
      "STR": 4,
      "AGI": 0,
      "INT": 1,
      "LUK": 2,
      "HP": 69,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 199,
    "tierItemIds": [
      69,
      199,
      200
    ],
    "setId": 10,
    "animType": null
  },
  "70": {
    "id": 70,
    "displayName": "Cheetara Armor",
    "name": "Cheetara Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 201,
    "tierItemIds": [
      70,
      201,
      202
    ],
    "setId": 10,
    "animType": null
  },
  "71": {
    "id": 71,
    "displayName": "Cheetara Boots",
    "name": "Cheetara Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 9200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 15,
    "nextTierId": 203,
    "tierItemIds": [
      71,
      203,
      204
    ],
    "setId": 10,
    "animType": null
  },
  "72": {
    "id": 72,
    "displayName": "Cheetara Pants",
    "name": "Cheetara Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 9200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 15,
    "nextTierId": 205,
    "tierItemIds": [
      72,
      205,
      206
    ],
    "setId": 10,
    "animType": null
  },
  "73": {
    "id": 73,
    "displayName": "Cheetara Chakram",
    "name": "Cheetara Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 74,
    "tierItemIds": [
      73,
      74,
      75
    ],
    "setId": 10,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "74": {
    "id": 74,
    "displayName": "Mighty Cheetara Chakram",
    "name": "Cheetara Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 50,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 75,
    "tierItemIds": [
      73,
      74,
      75
    ],
    "setId": 10,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "75": {
    "id": 75,
    "displayName": "Superior Cheetara Chakram",
    "name": "Cheetara Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 70,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      73,
      74,
      75
    ],
    "setId": 10,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "76": {
    "id": 76,
    "displayName": "Serenity Gloves",
    "name": "Serenity Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 4900,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 6,
      "LUK": 2,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 20,
    "nextTierId": 207,
    "tierItemIds": [
      76,
      207,
      208
    ],
    "setId": 11,
    "animType": null
  },
  "77": {
    "id": 77,
    "displayName": "Serenity Boots",
    "name": "Serenity Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 9200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 20,
    "nextTierId": 209,
    "tierItemIds": [
      77,
      209,
      210
    ],
    "setId": 11,
    "animType": null
  },
  "78": {
    "id": 78,
    "displayName": "Serenity Armor",
    "name": "Serenity Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 73,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 211,
    "tierItemIds": [
      78,
      211,
      212
    ],
    "setId": 11,
    "animType": null
  },
  "79": {
    "id": 79,
    "displayName": "Serenity Helmet",
    "name": "Serenity Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 9200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 20,
    "nextTierId": 213,
    "tierItemIds": [
      79,
      213,
      214
    ],
    "setId": 11,
    "animType": null
  },
  "80": {
    "id": 80,
    "displayName": "Serenity Pants",
    "name": "Serenity Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 73,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 215,
    "tierItemIds": [
      80,
      215,
      216
    ],
    "setId": 11,
    "animType": null
  },
  "81": {
    "id": 81,
    "displayName": "Serenity Staff",
    "name": "Serenity Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 200,
    "tier": 1,
    "stats": {
      "Magical Damage": 37,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Weakness",
          "description": "Attacks have a 20% chance of reducing the enemy's Physical Damage by 10 for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 82,
    "tierItemIds": [
      81,
      82,
      83
    ],
    "setId": 11,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "82": {
    "id": 82,
    "displayName": "Mighty Serenity Staff",
    "name": "Serenity Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 52,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 4,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Weakness",
          "description": "Attacks have a 20% chance of reducing the enemy's Physical Damage by 15 for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 83,
    "tierItemIds": [
      81,
      82,
      83
    ],
    "setId": 11,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "83": {
    "id": 83,
    "displayName": "Superior Serenity Staff",
    "name": "Serenity Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 73,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 6,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Weakness",
          "description": "Attacks have a 20% chance of reducing the enemy's Physical Damage by 22 for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      81,
      82,
      83
    ],
    "setId": 11,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "84": {
    "id": 84,
    "displayName": "Oroboros Boots",
    "name": "Oroboros Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 217,
    "tierItemIds": [
      84,
      217,
      218
    ],
    "setId": 9,
    "animType": null
  },
  "85": {
    "id": 85,
    "displayName": "Oroboros Pants",
    "name": "Oroboros Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 219,
    "tierItemIds": [
      85,
      219,
      220
    ],
    "setId": 9,
    "animType": null
  },
  "86": {
    "id": 86,
    "displayName": "Oroboros Armor",
    "name": "Oroboros Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 40,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 9,
      "LUK": 0,
      "HP": 84,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Burning",
          "description": "Attacks have a 15% chance of applying Burning, which deals 10 damage each turn for 2 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 221,
    "tierItemIds": [
      86,
      221,
      222
    ],
    "setId": 9,
    "animType": null
  },
  "87": {
    "id": 87,
    "displayName": "Oroboros Helmet",
    "name": "Oroboros Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 1,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 3,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 223,
    "tierItemIds": [
      87,
      223,
      224
    ],
    "setId": 9,
    "animType": null
  },
  "88": {
    "id": 88,
    "displayName": "Oroboros Gloves",
    "name": "Oroboros Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 3,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 225,
    "tierItemIds": [
      88,
      225,
      226
    ],
    "setId": 9,
    "animType": null
  },
  "89": {
    "id": 89,
    "displayName": "Oroboros Orb",
    "name": "Oroboros Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 32,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 90,
    "tierItemIds": [
      89,
      90,
      91
    ],
    "setId": 9,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "90": {
    "id": 90,
    "displayName": "Mighty Oroboros Orb",
    "name": "Oroboros Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 43,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 0,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 91,
    "tierItemIds": [
      89,
      90,
      91
    ],
    "setId": 9,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "91": {
    "id": 91,
    "displayName": "Superior Oroboros Orb",
    "name": "Oroboros Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 58,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 12,
      "STR": 0,
      "AGI": 0,
      "INT": 11,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      89,
      90,
      91
    ],
    "setId": 9,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "92": {
    "id": 92,
    "displayName": "Lysandra Boots",
    "name": "Lysandra Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 51,
      "Critical": 0,
      "Evade": 3,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 227,
    "tierItemIds": [
      92,
      227,
      228
    ],
    "setId": 12,
    "animType": null
  },
  "93": {
    "id": 93,
    "displayName": "Lysandra Helmet",
    "name": "Lysandra Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 2,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 2,
      "HP": 51,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 229,
    "tierItemIds": [
      93,
      229,
      230
    ],
    "setId": 12,
    "animType": null
  },
  "94": {
    "id": 94,
    "displayName": "Lysandra Armor",
    "name": "Lysandra Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 40,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 15% chance of absorbing 10 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 231,
    "tierItemIds": [
      94,
      231,
      232
    ],
    "setId": 12,
    "animType": null
  },
  "95": {
    "id": 95,
    "displayName": "Lysandra Gloves",
    "name": "Lysandra Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 3,
      "HP": 51,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 233,
    "tierItemIds": [
      95,
      233,
      234
    ],
    "setId": 12,
    "animType": null
  },
  "96": {
    "id": 96,
    "displayName": "Lysandra Pants",
    "name": "Lysandra Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 51,
      "Critical": 0,
      "Evade": 2,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 235,
    "tierItemIds": [
      96,
      235,
      236
    ],
    "setId": 12,
    "animType": null
  },
  "97": {
    "id": 97,
    "displayName": "Lysandra Scythe",
    "name": "Lysandra Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 28,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 98,
    "tierItemIds": [
      97,
      98,
      99
    ],
    "setId": 12,
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "98": {
    "id": 98,
    "displayName": "Mighty Lysandra Scythe",
    "name": "Lysandra Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 39,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 99,
    "tierItemIds": [
      97,
      98,
      99
    ],
    "setId": 12,
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "99": {
    "id": 99,
    "displayName": "Superior Lysandra Scythe",
    "name": "Lysandra Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 58,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 8,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      97,
      98,
      99
    ],
    "setId": 12,
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "100": {
    "id": 100,
    "displayName": "Legolas Gloves",
    "name": "Legolas Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 9200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 9,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 15,
    "nextTierId": 237,
    "tierItemIds": [
      100,
      237,
      238
    ],
    "setId": 13,
    "animType": null
  },
  "101": {
    "id": 101,
    "displayName": "Legolas Helmet",
    "name": "Legolas Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 9,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 2,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 239,
    "tierItemIds": [
      101,
      239,
      240
    ],
    "setId": 13,
    "animType": null
  },
  "102": {
    "id": 102,
    "displayName": "Legolas Armor",
    "name": "Legolas Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 4900,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 241,
    "tierItemIds": [
      102,
      241,
      242
    ],
    "setId": 13,
    "animType": null
  },
  "103": {
    "id": 103,
    "displayName": "Legolas Boots",
    "name": "Legolas Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 9200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 9,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 15,
    "nextTierId": 243,
    "tierItemIds": [
      103,
      243,
      244
    ],
    "setId": 13,
    "animType": null
  },
  "104": {
    "id": 104,
    "displayName": "Legolas Pants",
    "name": "Legolas Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 4900,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 9,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 10,
    "nextTierId": 245,
    "tierItemIds": [
      104,
      245,
      246
    ],
    "setId": 13,
    "animType": null
  },
  "105": {
    "id": 105,
    "displayName": "Legolas Bow",
    "name": "Legolas Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 235,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 106,
    "tierItemIds": [
      105,
      106,
      107
    ],
    "setId": 13,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "106": {
    "id": 106,
    "displayName": "Mighty Legolas Bow",
    "name": "Legolas Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 50,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 107,
    "tierItemIds": [
      105,
      106,
      107
    ],
    "setId": 13,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "107": {
    "id": 107,
    "displayName": "Superior Legolas Bow",
    "name": "Legolas Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 70,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      105,
      106,
      107
    ],
    "setId": 13,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "108": {
    "id": 108,
    "displayName": "Deathly Hallows",
    "name": "Deathly Hallows Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 5,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 96,
      "Magical Defense": 15,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 9,
      "AGI": 5,
      "INT": 12,
      "LUK": 5,
      "HP": 188,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": [
        {
          "name": "Deadly Strike",
          "description": "Leaps towards the targeted position within a 2-tile radius, dealing 40 Physical Damage to all enemies around the wielder upon impact. Damaged enemies will Bleed, suffering 10 Physical Damage each turn and getting any Healing effect reduced by 5% for 2 turns."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 109,
    "tierItemIds": [
      108,
      109,
      110
    ],
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "109": {
    "id": 109,
    "displayName": "Mighty Deathly Hallows",
    "name": "Deathly Hallows Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 135,
      "Magical Defense": 21,
      "Physical Defense": 8,
      "Critical Damage": 7,
      "STR": 13,
      "AGI": 8,
      "INT": 17,
      "LUK": 7,
      "HP": 264,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": [
        {
          "name": "Deadly Strike",
          "description": "Leaps towards the targeted position within a 2-tile radius, dealing 48 Physical Damage to all enemies around the wielder upon impact. Damaged enemies will Bleed, suffering 10 Physical Damage each turn and getting any Healing effect reduced by 7% for 2 turns."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 110,
    "tierItemIds": [
      108,
      109,
      110
    ],
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "110": {
    "id": 110,
    "displayName": "Superior Deathly Hallows",
    "name": "Deathly Hallows Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 190,
      "Magical Defense": 30,
      "Physical Defense": 12,
      "Critical Damage": 10,
      "STR": 19,
      "AGI": 10,
      "INT": 24,
      "LUK": 10,
      "HP": 370,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": [
        {
          "name": "Deadly Strike",
          "description": "Leaps towards the targeted position within a 2-tile radius, dealing 56 Physical Damage to all enemies around the wielder upon impact. Damaged enemies will Bleed, suffering 10 Physical Damage each turn and getting any Healing effect reduced by 10% for 3 turns."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      108,
      109,
      110
    ],
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "111": {
    "id": 111,
    "displayName": "Gory Hook",
    "name": "Gory Hook Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 10,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 53,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 2,
      "INT": 8,
      "LUK": 2,
      "HP": 101,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": [
        {
          "name": "Soul Hook",
          "description": "Pulls a target within a 2-tile radius towards the user. If the target is an enemy, performs a double-attack with 50% less damage and 10% more Critical chance. If the target is an ally, apply Berserk on the target, giving 2 extra STR and 2 AGI."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 112,
    "tierItemIds": [
      111,
      112,
      113
    ],
    "animType": "hook",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "112": {
    "id": 112,
    "displayName": "Mighty Gory Hook",
    "name": "Gory Hook Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 75,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 11,
      "AGI": 3,
      "INT": 12,
      "LUK": 3,
      "HP": 142,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": [
        {
          "name": "Soul Hook",
          "description": "Pulls a target within a 2-tile radius towards the user. If the target is an enemy, performs a double-attack with 40% less damage and 15% more Critical chance. If the target is an ally, apply Berserk on the target, giving 3 extra STR and 3 AGI."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 113,
    "tierItemIds": [
      111,
      112,
      113
    ],
    "animType": "hook",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "113": {
    "id": 113,
    "displayName": "Superior Gory Hook",
    "name": "Gory Hook Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 105,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 15,
      "AGI": 5,
      "INT": 18,
      "LUK": 4,
      "HP": 199,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": [
        {
          "name": "Soul Hook",
          "description": "Pulls a target within a 2-tile radius towards the user. If the target is an enemy, performs a double-attack with 33% less damage and 20% more Critical chance. If the target is an ally, apply Berserk on the target, giving 5 extra STR and 5 AGI."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      111,
      112,
      113
    ],
    "animType": "hook",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "114": {
    "id": 114,
    "displayName": "Otis Axe",
    "name": "Otis Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 1530,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 14,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 385,
    "tierItemIds": [
      114,
      385,
      386
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "115": {
    "id": 115,
    "displayName": "Erica Bow",
    "name": "Erica Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 1530,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 17,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 1,
      "HP": 19,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 387,
    "tierItemIds": [
      115,
      387,
      388
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "116": {
    "id": 116,
    "displayName": "Heulwen Sword",
    "name": "Heulwen Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 1530,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 14,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 389,
    "tierItemIds": [
      116,
      389,
      390
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "117": {
    "id": 117,
    "displayName": "Jocasta Knife",
    "name": "Jocasta Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 1530,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 15,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 391,
    "tierItemIds": [
      117,
      391,
      392
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "118": {
    "id": 118,
    "displayName": "Jocelyn Knife",
    "name": "Jocelyn Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 801,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 19,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 2,
      "AGI": 1,
      "INT": 2,
      "LUK": 2,
      "HP": 26,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 393,
    "tierItemIds": [
      118,
      393,
      394
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "119": {
    "id": 119,
    "displayName": "Helga Sword",
    "name": "Helga Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 801,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 18,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 2,
      "HP": 29,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 395,
    "tierItemIds": [
      119,
      395,
      396
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "120": {
    "id": 120,
    "displayName": "Elysia Bow",
    "name": "Elysia Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 801,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 24,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 2,
      "AGI": 4,
      "INT": 0,
      "LUK": 7,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 397,
    "tierItemIds": [
      120,
      397,
      398
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "121": {
    "id": 121,
    "displayName": "Orborne Axe",
    "name": "Orborne Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 801,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 25,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 7,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 399,
    "tierItemIds": [
      121,
      399,
      400
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "122": {
    "id": 122,
    "displayName": "Joyce Knife",
    "name": "Joyce Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 126,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 29,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 3,
      "AGI": 3,
      "INT": 0,
      "LUK": 7,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 401,
    "tierItemIds": [
      122,
      401,
      402
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "123": {
    "id": 123,
    "displayName": "Hypatia Sword",
    "name": "Hypatia Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 126,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 27,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 6,
      "HP": 31,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 403,
    "tierItemIds": [
      123,
      403,
      404
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "124": {
    "id": 124,
    "displayName": "Elfreda Bow",
    "name": "Elfreda Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 126,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 37,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 5,
      "AGI": 7,
      "INT": 0,
      "LUK": 6,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 405,
    "tierItemIds": [
      124,
      405,
      406
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "125": {
    "id": 125,
    "displayName": "Olwen Axe",
    "name": "Olwen Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 126,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 40,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 6,
      "STR": 7,
      "AGI": 5,
      "INT": 0,
      "LUK": 5,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 407,
    "tierItemIds": [
      125,
      407,
      408
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "126": {
    "id": 126,
    "displayName": "Eunice Axe",
    "name": "Eunice Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 141,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 26,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 5,
      "AGI": 5,
      "INT": 0,
      "LUK": 7,
      "HP": 34,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 409,
    "tierItemIds": [
      126,
      409,
      410
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "127": {
    "id": 127,
    "displayName": "Arian Sword",
    "name": "Arian Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 51,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 25,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 27,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 411,
    "tierItemIds": [
      127,
      411,
      412
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "128": {
    "id": 128,
    "displayName": "Edana Axe",
    "name": "Edana Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 51,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 29,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 6,
      "HP": 35,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 413,
    "tierItemIds": [
      128,
      413,
      414
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "129": {
    "id": 129,
    "displayName": "Ciara Sword",
    "name": "Ciara Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 21,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 30,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 415,
    "tierItemIds": [
      129,
      415,
      416
    ],
    "setId": 8,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "130": {
    "id": 130,
    "displayName": "Ciara Armor",
    "name": "Ciara Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 21,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 61,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 417,
    "tierItemIds": [
      130,
      417,
      418
    ],
    "setId": 8,
    "animType": null
  },
  "131": {
    "id": 131,
    "displayName": "Ciara Bracelets",
    "name": "Ciara Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 21,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 2,
      "STR": 4,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 49,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 419,
    "tierItemIds": [
      131,
      419,
      420
    ],
    "setId": 8,
    "animType": null
  },
  "132": {
    "id": 132,
    "displayName": "Ciara Boots",
    "name": "Ciara Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 21,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 48,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 421,
    "tierItemIds": [
      132,
      421,
      422
    ],
    "setId": 8,
    "animType": null
  },
  "133": {
    "id": 133,
    "displayName": "Ciara Pants",
    "name": "Ciara Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 21,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 423,
    "tierItemIds": [
      133,
      423,
      424
    ],
    "setId": 8,
    "animType": null
  },
  "134": {
    "id": 134,
    "displayName": "Aretha Spear",
    "name": "Aretha Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 11,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 50,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 14,
      "STR": 10,
      "AGI": 6,
      "INT": 0,
      "LUK": 4,
      "HP": 59,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Bash",
          "description": "Attacks have a 17% chance of Stunning the enemy for 1 turn(s)."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 425,
    "tierItemIds": [
      134,
      425,
      426
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "135": {
    "id": 135,
    "displayName": "Aliyah Sword",
    "name": "Aliyah Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 11,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 33,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 14,
      "STR": 7,
      "AGI": 6,
      "INT": 5,
      "LUK": 6,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Devour",
          "description": "Heals 20 HP per unit killed."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 427,
    "tierItemIds": [
      135,
      427,
      428
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "136": {
    "id": 136,
    "displayName": "Acacia Wand",
    "name": "Acacia Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 2,
    "tier": 1,
    "stats": {
      "Magical Damage": 61,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      136
    ],
    "setId": 7,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "137": {
    "id": 137,
    "displayName": "Acacia Armor",
    "name": "Acacia Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 2,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 15,
      "LUK": 7,
      "HP": 163,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Thunder Proof",
          "description": "Increase Thunder resistance by 5%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      137
    ],
    "setId": 7,
    "animType": null
  },
  "138": {
    "id": 138,
    "displayName": "Acacia Bracelets",
    "name": "Acacia Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 2,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 0,
      "INT": 14,
      "LUK": 8,
      "HP": 130,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      138
    ],
    "setId": 7,
    "animType": null
  },
  "139": {
    "id": 139,
    "displayName": "Acacia Boots",
    "name": "Acacia Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 2,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 130,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      139
    ],
    "setId": 7,
    "animType": null
  },
  "140": {
    "id": 140,
    "displayName": "Acacia Pants",
    "name": "Acacia Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 2,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 130,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      140
    ],
    "setId": 7,
    "animType": null
  },
  "141": {
    "id": 141,
    "displayName": "Mighty Dazzling Summer Armor",
    "name": "Dazzling Summer Armor",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 4,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 140,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 142,
    "tierItemIds": [
      1,
      141,
      142
    ],
    "setId": 1,
    "animType": null
  },
  "142": {
    "id": 142,
    "displayName": "Superior Dazzling Summer Armor",
    "name": "Dazzling Summer Armor",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 16,
      "Magical Defense": 8,
      "Physical Defense": 18,
      "Critical Damage": 8,
      "STR": 4,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 199,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1,
      141,
      142
    ],
    "setId": 1,
    "animType": null
  },
  "143": {
    "id": 143,
    "displayName": "Mighty Dazzling Summer Boots",
    "name": "Dazzling Summer Boots",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 9,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 1,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 144,
    "tierItemIds": [
      2,
      143,
      144
    ],
    "setId": 1,
    "animType": null
  },
  "144": {
    "id": 144,
    "displayName": "Superior Dazzling Summer Boots",
    "name": "Dazzling Summer Boots",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 1,
      "AGI": 13,
      "INT": 0,
      "LUK": 0,
      "HP": 44,
      "Critical": 1,
      "Evade": 2,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      2,
      143,
      144
    ],
    "setId": 1,
    "animType": null
  },
  "145": {
    "id": 145,
    "displayName": "Mighty Dazzling Summer Gloves",
    "name": "Dazzling Summer Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 1,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 2,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 146,
    "tierItemIds": [
      3,
      145,
      146
    ],
    "setId": 1,
    "animType": null
  },
  "146": {
    "id": 146,
    "displayName": "Superior Dazzling Summer Gloves",
    "name": "Dazzling Summer Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 3,
      "STR": 3,
      "AGI": 0,
      "INT": 5,
      "LUK": 4,
      "HP": 44,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      3,
      145,
      146
    ],
    "setId": 1,
    "animType": null
  },
  "147": {
    "id": 147,
    "displayName": "Mighty Dazzling Summer Helmet",
    "name": "Dazzling Summer Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 1,
      "STR": 2,
      "AGI": 0,
      "INT": 4,
      "LUK": 4,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 148,
    "tierItemIds": [
      4,
      147,
      148
    ],
    "setId": 1,
    "animType": null
  },
  "148": {
    "id": 148,
    "displayName": "Superior Dazzling Summer Helmet",
    "name": "Dazzling Summer Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 4,
      "Critical Damage": 3,
      "STR": 4,
      "AGI": 0,
      "INT": 6,
      "LUK": 6,
      "HP": 44,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      4,
      147,
      148
    ],
    "setId": 1,
    "animType": null
  },
  "149": {
    "id": 149,
    "displayName": "Mighty Dazzling Summer Pants",
    "name": "Dazzling Summer Pants",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 6,
      "Critical Damage": 2,
      "STR": 1,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 53,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 150,
    "tierItemIds": [
      5,
      149,
      150
    ],
    "setId": 1,
    "animType": null
  },
  "150": {
    "id": 150,
    "displayName": "Superior Dazzling Summer Pants",
    "name": "Dazzling Summer Pants",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 9,
      "Critical Damage": 4,
      "STR": 2,
      "AGI": 9,
      "INT": 0,
      "LUK": 0,
      "HP": 78,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      5,
      149,
      150
    ],
    "setId": 1,
    "animType": null
  },
  "151": {
    "id": 151,
    "displayName": "Mighty Summer Solstice Armor",
    "name": "Summer Solstice Armor",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 8,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 56,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 152,
    "tierItemIds": [
      7,
      151,
      152
    ],
    "setId": 2,
    "animType": null
  },
  "152": {
    "id": 152,
    "displayName": "Superior Summer Solstice Armor",
    "name": "Summer Solstice Armor",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      7,
      151,
      152
    ],
    "setId": 2,
    "animType": null
  },
  "153": {
    "id": 153,
    "displayName": "Mighty Summer Solstice Boots",
    "name": "Summer Solstice Boots",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 13,
      "INT": 0,
      "LUK": 0,
      "HP": 20,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 154,
    "tierItemIds": [
      8,
      153,
      154
    ],
    "setId": 2,
    "animType": null
  },
  "154": {
    "id": 154,
    "displayName": "Superior Summer Solstice Boots",
    "name": "Summer Solstice Boots",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 19,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 3,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      8,
      153,
      154
    ],
    "setId": 2,
    "animType": null
  },
  "155": {
    "id": 155,
    "displayName": "Mighty Summer Solstice Gloves",
    "name": "Summer Solstice Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 2,
      "HP": 56,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 156,
    "tierItemIds": [
      9,
      155,
      156
    ],
    "setId": 2,
    "animType": null
  },
  "156": {
    "id": 156,
    "displayName": "Superior Summer Solstice Gloves",
    "name": "Summer Solstice Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 4,
      "LUK": 3,
      "HP": 87,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      9,
      155,
      156
    ],
    "setId": 2,
    "animType": null
  },
  "157": {
    "id": 157,
    "displayName": "Mighty Summer Solstice Helmet",
    "name": "Summer Solstice Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 9,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 4,
      "Physical Defense": 12,
      "Critical Damage": 3,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 3,
      "HP": 100,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 158,
    "tierItemIds": [
      10,
      157,
      158
    ],
    "setId": 2,
    "animType": null
  },
  "158": {
    "id": 158,
    "displayName": "Superior Summer Solstice Helmet",
    "name": "Summer Solstice Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 9,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 18,
      "Magical Defense": 6,
      "Physical Defense": 18,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 0,
      "INT": 5,
      "LUK": 5,
      "HP": 142,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      10,
      157,
      158
    ],
    "setId": 2,
    "animType": null
  },
  "159": {
    "id": 159,
    "displayName": "Mighty Summer Solstice Pants",
    "name": "Summer Solstice Pants",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 20,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 160,
    "tierItemIds": [
      11,
      159,
      160
    ],
    "setId": 2,
    "animType": null
  },
  "160": {
    "id": 160,
    "displayName": "Superior Summer Solstice Pants",
    "name": "Summer Solstice Pants",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 14,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 3,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      11,
      159,
      160
    ],
    "setId": 2,
    "animType": null
  },
  "161": {
    "id": 161,
    "displayName": "Mighty Unscrutable Armor",
    "name": "Unscrutable Armor",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 3,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 162,
    "tierItemIds": [
      14,
      161,
      162
    ],
    "setId": 5,
    "animType": null
  },
  "162": {
    "id": 162,
    "displayName": "Superior Unscrutable Armor",
    "name": "Unscrutable Armor",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 3,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 81,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      14,
      161,
      162
    ],
    "setId": 5,
    "animType": null
  },
  "163": {
    "id": 163,
    "displayName": "Mighty Unscrutable Boots",
    "name": "Unscrutable Boots",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 2,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 164,
    "tierItemIds": [
      15,
      163,
      164
    ],
    "setId": 5,
    "animType": null
  },
  "164": {
    "id": 164,
    "displayName": "Superior Unscrutable Boots",
    "name": "Unscrutable Boots",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 11,
      "INT": 0,
      "LUK": 0,
      "HP": 81,
      "Critical": 0,
      "Evade": 3,
      "Speed": 8,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      15,
      163,
      164
    ],
    "setId": 5,
    "animType": null
  },
  "165": {
    "id": 165,
    "displayName": "Mighty Unscrutable Gloves",
    "name": "Unscrutable Gloves",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 3,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 166,
    "tierItemIds": [
      16,
      165,
      166
    ],
    "setId": 5,
    "animType": null
  },
  "166": {
    "id": 166,
    "displayName": "Superior Unscrutable Gloves",
    "name": "Unscrutable Gloves",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 5,
      "HP": 140,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      16,
      165,
      166
    ],
    "setId": 5,
    "animType": null
  },
  "167": {
    "id": 167,
    "displayName": "Mighty Unscrutable Pants",
    "name": "Unscrutable Pants",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 168,
    "tierItemIds": [
      17,
      167,
      168
    ],
    "setId": 5,
    "animType": null
  },
  "168": {
    "id": 168,
    "displayName": "Superior Unscrutable Pants",
    "name": "Unscrutable Pants",
    "races": [
      "Dragonborn",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 81,
      "Critical": 0,
      "Evade": 4,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      17,
      167,
      168
    ],
    "setId": 5,
    "animType": null
  },
  "169": {
    "id": 169,
    "displayName": "Mighty Iron Fist Gloves",
    "name": "Iron Fist Gloves",
    "races": [
      "Elf",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 18,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 170,
    "tierItemIds": [
      20,
      169,
      170
    ],
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "170": {
    "id": 170,
    "displayName": "Superior Iron Fist Gloves",
    "name": "Iron Fist Gloves",
    "races": [
      "Elf",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 26,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      20,
      169,
      170
    ],
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "171": {
    "id": 171,
    "displayName": "Mighty Lagan Armor",
    "name": "Lagan Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 107,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 172,
    "tierItemIds": [
      23,
      171,
      172
    ],
    "setId": 3,
    "animType": null
  },
  "172": {
    "id": 172,
    "displayName": "Superior Lagan Armor",
    "name": "Lagan Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 7,
      "LUK": 2,
      "HP": 150,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      23,
      171,
      172
    ],
    "setId": 3,
    "animType": null
  },
  "173": {
    "id": 173,
    "displayName": "Mighty Lagan Boots",
    "name": "Lagan Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 4,
      "INT": 4,
      "LUK": 1,
      "HP": 91,
      "Critical": 0,
      "Evade": 0,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 174,
    "tierItemIds": [
      24,
      173,
      174
    ],
    "setId": 3,
    "animType": null
  },
  "174": {
    "id": 174,
    "displayName": "Superior Lagan Boots",
    "name": "Lagan Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 5,
      "INT": 6,
      "LUK": 2,
      "HP": 128,
      "Critical": 0,
      "Evade": 0,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      24,
      173,
      174
    ],
    "setId": 3,
    "animType": null
  },
  "175": {
    "id": 175,
    "displayName": "Mighty Lagan Pants",
    "name": "Lagan Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 2,
      "LUK": 0,
      "HP": 61,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 176,
    "tierItemIds": [
      25,
      175,
      176
    ],
    "setId": 3,
    "animType": null
  },
  "176": {
    "id": 176,
    "displayName": "Superior Lagan Pants",
    "name": "Lagan Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 4,
      "LUK": 1,
      "HP": 86,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      25,
      175,
      176
    ],
    "setId": 3,
    "animType": null
  },
  "177": {
    "id": 177,
    "displayName": "Mighty Talhoffer Armor",
    "name": "Talhoffer Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 96,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 178,
    "tierItemIds": [
      28,
      177,
      178
    ],
    "setId": 6,
    "animType": null
  },
  "178": {
    "id": 178,
    "displayName": "Superior Talhoffer Armor",
    "name": "Talhoffer Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 135,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      28,
      177,
      178
    ],
    "setId": 6,
    "animType": null
  },
  "179": {
    "id": 179,
    "displayName": "Mighty Talhoffer Boots",
    "name": "Talhoffer Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 180,
    "tierItemIds": [
      29,
      179,
      180
    ],
    "setId": 6,
    "animType": null
  },
  "180": {
    "id": 180,
    "displayName": "Superior Talhoffer Boots",
    "name": "Talhoffer Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      29,
      179,
      180
    ],
    "setId": 6,
    "animType": null
  },
  "181": {
    "id": 181,
    "displayName": "Mighty Talhoffer Gloves",
    "name": "Talhoffer Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 2,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 182,
    "tierItemIds": [
      30,
      181,
      182
    ],
    "setId": 6,
    "animType": null
  },
  "182": {
    "id": 182,
    "displayName": "Superior Talhoffer Gloves",
    "name": "Talhoffer Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 3,
      "HP": 70,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      30,
      181,
      182
    ],
    "setId": 6,
    "animType": null
  },
  "183": {
    "id": 183,
    "displayName": "Mighty Talhoffer Helmet",
    "name": "Talhoffer Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 2,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 2,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 184,
    "tierItemIds": [
      31,
      183,
      184
    ],
    "setId": 6,
    "animType": null
  },
  "184": {
    "id": 184,
    "displayName": "Superior Talhoffer Helmet",
    "name": "Talhoffer Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 3,
      "STR": 2,
      "AGI": 0,
      "INT": 4,
      "LUK": 3,
      "HP": 70,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      31,
      183,
      184
    ],
    "setId": 6,
    "animType": null
  },
  "185": {
    "id": 185,
    "displayName": "Mighty Talhoffer Pants",
    "name": "Talhoffer Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 7,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 108,
      "Critical": 0,
      "Evade": 1,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 186,
    "tierItemIds": [
      32,
      185,
      186
    ],
    "setId": 6,
    "animType": null
  },
  "186": {
    "id": 186,
    "displayName": "Superior Talhoffer Pants",
    "name": "Talhoffer Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 7,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 18,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 152,
      "Critical": 0,
      "Evade": 2,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      32,
      185,
      186
    ],
    "setId": 6,
    "animType": null
  },
  "187": {
    "id": 187,
    "displayName": "Mighty Phalanx Armor",
    "name": "Phalanx Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 188,
    "tierItemIds": [
      34,
      187,
      188
    ],
    "setId": 4,
    "animType": null
  },
  "188": {
    "id": 188,
    "displayName": "Superior Phalanx Armor",
    "name": "Phalanx Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 113,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      34,
      187,
      188
    ],
    "setId": 4,
    "animType": null
  },
  "189": {
    "id": 189,
    "displayName": "Mighty Phalanx Boots",
    "name": "Phalanx Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 42,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 190,
    "tierItemIds": [
      35,
      189,
      190
    ],
    "setId": 4,
    "animType": null
  },
  "190": {
    "id": 190,
    "displayName": "Superior Phalanx Boots",
    "name": "Phalanx Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 2,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 59,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      35,
      189,
      190
    ],
    "setId": 4,
    "animType": null
  },
  "191": {
    "id": 191,
    "displayName": "Mighty Phalanx Gloves",
    "name": "Phalanx Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 1,
      "HP": 42,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 192,
    "tierItemIds": [
      36,
      191,
      192
    ],
    "setId": 4,
    "animType": null
  },
  "192": {
    "id": 192,
    "displayName": "Superior Phalanx Gloves",
    "name": "Phalanx Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 2,
      "HP": 60,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      36,
      191,
      192
    ],
    "setId": 4,
    "animType": null
  },
  "193": {
    "id": 193,
    "displayName": "Mighty Phalanx Helmet",
    "name": "Phalanx Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 6,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 15,
      "Critical Damage": 3,
      "STR": 4,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 115,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 194,
    "tierItemIds": [
      37,
      193,
      194
    ],
    "setId": 4,
    "animType": null
  },
  "194": {
    "id": 194,
    "displayName": "Superior Phalanx Helmet",
    "name": "Phalanx Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 6,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 21,
      "Critical Damage": 5,
      "STR": 5,
      "AGI": 0,
      "INT": 7,
      "LUK": 4,
      "HP": 161,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      37,
      193,
      194
    ],
    "setId": 4,
    "animType": null
  },
  "195": {
    "id": 195,
    "displayName": "Mighty Phalanx Pants",
    "name": "Phalanx Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 2,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 196,
    "tierItemIds": [
      38,
      195,
      196
    ],
    "setId": 4,
    "animType": null
  },
  "196": {
    "id": 196,
    "displayName": "Superior Phalanx Pants",
    "name": "Phalanx Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 9,
      "INT": 0,
      "LUK": 0,
      "HP": 113,
      "Critical": 0,
      "Evade": 4,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      38,
      195,
      196
    ],
    "setId": 4,
    "animType": null
  },
  "197": {
    "id": 197,
    "displayName": "Mighty Cheetara Gloves",
    "name": "Cheetara Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 2,
      "LUK": 3,
      "HP": 64,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 198,
    "tierItemIds": [
      68,
      197,
      198
    ],
    "setId": 10,
    "animType": null
  },
  "198": {
    "id": 198,
    "displayName": "Superior Cheetara Gloves",
    "name": "Cheetara Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 3,
      "LUK": 5,
      "HP": 90,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      68,
      197,
      198
    ],
    "setId": 10,
    "animType": null
  },
  "199": {
    "id": 199,
    "displayName": "Mighty Cheetara Helmet",
    "name": "Cheetara Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 10,
      "Critical Damage": 7,
      "STR": 6,
      "AGI": 0,
      "INT": 2,
      "LUK": 3,
      "HP": 97,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 200,
    "tierItemIds": [
      69,
      199,
      200
    ],
    "setId": 10,
    "animType": null
  },
  "200": {
    "id": 200,
    "displayName": "Superior Cheetara Helmet",
    "name": "Cheetara Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 15,
      "Critical Damage": 10,
      "STR": 9,
      "AGI": 0,
      "INT": 3,
      "LUK": 5,
      "HP": 136,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      69,
      199,
      200
    ],
    "setId": 10,
    "animType": null
  },
  "201": {
    "id": 201,
    "displayName": "Mighty Cheetara Armor",
    "name": "Cheetara Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 97,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 202,
    "tierItemIds": [
      70,
      201,
      202
    ],
    "setId": 10,
    "animType": null
  },
  "202": {
    "id": 202,
    "displayName": "Superior Cheetara Armor",
    "name": "Cheetara Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 15,
      "Critical Damage": 0,
      "STR": 9,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 136,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      70,
      201,
      202
    ],
    "setId": 10,
    "animType": null
  },
  "203": {
    "id": 203,
    "displayName": "Mighty Cheetara Boots",
    "name": "Cheetara Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 204,
    "tierItemIds": [
      71,
      203,
      204
    ],
    "setId": 10,
    "animType": null
  },
  "204": {
    "id": 204,
    "displayName": "Superior Cheetara Boots",
    "name": "Cheetara Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 4,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      71,
      203,
      204
    ],
    "setId": 10,
    "animType": null
  },
  "205": {
    "id": 205,
    "displayName": "Mighty Cheetara Pants",
    "name": "Cheetara Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 206,
    "tierItemIds": [
      72,
      205,
      206
    ],
    "setId": 10,
    "animType": null
  },
  "206": {
    "id": 206,
    "displayName": "Superior Cheetara Pants",
    "name": "Cheetara Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 4,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      72,
      205,
      206
    ],
    "setId": 10,
    "animType": null
  },
  "207": {
    "id": 207,
    "displayName": "Mighty Serenity Gloves",
    "name": "Serenity Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 9,
      "LUK": 3,
      "HP": 64,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 208,
    "tierItemIds": [
      76,
      207,
      208
    ],
    "setId": 11,
    "animType": null
  },
  "208": {
    "id": 208,
    "displayName": "Superior Serenity Gloves",
    "name": "Serenity Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 13,
      "LUK": 5,
      "HP": 90,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      76,
      207,
      208
    ],
    "setId": 11,
    "animType": null
  },
  "209": {
    "id": 209,
    "displayName": "Mighty Serenity Boots",
    "name": "Serenity Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 210,
    "tierItemIds": [
      77,
      209,
      210
    ],
    "setId": 11,
    "animType": null
  },
  "210": {
    "id": 210,
    "displayName": "Superior Serenity Boots",
    "name": "Serenity Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      77,
      209,
      210
    ],
    "setId": 11,
    "animType": null
  },
  "211": {
    "id": 211,
    "displayName": "Mighty Serenity Armor",
    "name": "Serenity Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 14,
      "LUK": 0,
      "HP": 103,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 212,
    "tierItemIds": [
      78,
      211,
      212
    ],
    "setId": 11,
    "animType": null
  },
  "212": {
    "id": 212,
    "displayName": "Superior Serenity Armor",
    "name": "Serenity Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 19,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 20,
      "LUK": 0,
      "HP": 145,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      78,
      211,
      212
    ],
    "setId": 11,
    "animType": null
  },
  "213": {
    "id": 213,
    "displayName": "Mighty Serenity Helmet",
    "name": "Serenity Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 1,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 49,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 214,
    "tierItemIds": [
      79,
      213,
      214
    ],
    "setId": 11,
    "animType": null
  },
  "214": {
    "id": 214,
    "displayName": "Superior Serenity Helmet",
    "name": "Serenity Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 4,
      "Critical Damage": 2,
      "STR": 2,
      "AGI": 0,
      "INT": 8,
      "LUK": 3,
      "HP": 69,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      79,
      213,
      214
    ],
    "setId": 11,
    "animType": null
  },
  "215": {
    "id": 215,
    "displayName": "Mighty Serenity Pants",
    "name": "Serenity Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 103,
      "Critical": 0,
      "Evade": 3,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 216,
    "tierItemIds": [
      80,
      215,
      216
    ],
    "setId": 11,
    "animType": null
  },
  "216": {
    "id": 216,
    "displayName": "Superior Serenity Pants",
    "name": "Serenity Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 19,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 145,
      "Critical": 0,
      "Evade": 5,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      80,
      215,
      216
    ],
    "setId": 11,
    "animType": null
  },
  "217": {
    "id": 217,
    "displayName": "Mighty Oroboros Boots",
    "name": "Oroboros Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 218,
    "tierItemIds": [
      84,
      217,
      218
    ],
    "setId": 9,
    "animType": null
  },
  "218": {
    "id": 218,
    "displayName": "Superior Oroboros Boots",
    "name": "Oroboros Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 99,
      "Critical": 0,
      "Evade": 1,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      84,
      217,
      218
    ],
    "setId": 9,
    "animType": null
  },
  "219": {
    "id": 219,
    "displayName": "Mighty Oroboros Pants",
    "name": "Oroboros Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 220,
    "tierItemIds": [
      85,
      219,
      220
    ],
    "setId": 9,
    "animType": null
  },
  "220": {
    "id": 220,
    "displayName": "Superior Oroboros Pants",
    "name": "Oroboros Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 100,
      "Critical": 0,
      "Evade": 1,
      "Speed": 8,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      85,
      219,
      220
    ],
    "setId": 9,
    "animType": null
  },
  "221": {
    "id": 221,
    "displayName": "Mighty Oroboros Armor",
    "name": "Oroboros Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 17,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 13,
      "LUK": 0,
      "HP": 118,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Burning",
          "description": "Attacks have a 20% chance of applying Burning, which deals 14 damage each turn for 2 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 222,
    "tierItemIds": [
      86,
      221,
      222
    ],
    "setId": 9,
    "animType": null
  },
  "222": {
    "id": 222,
    "displayName": "Superior Oroboros Armor",
    "name": "Oroboros Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 25,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 19,
      "LUK": 0,
      "HP": 166,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Burning",
          "description": "Attacks have a 25% chance of applying Burning, which deals 20 damage each turn for 3 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      86,
      221,
      222
    ],
    "setId": 9,
    "animType": null
  },
  "223": {
    "id": 223,
    "displayName": "Mighty Oroboros Helmet",
    "name": "Oroboros Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 2,
      "STR": 2,
      "AGI": 0,
      "INT": 4,
      "LUK": 5,
      "HP": 71,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 224,
    "tierItemIds": [
      87,
      223,
      224
    ],
    "setId": 9,
    "animType": null
  },
  "224": {
    "id": 224,
    "displayName": "Superior Oroboros Helmet",
    "name": "Oroboros Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 4,
      "STR": 3,
      "AGI": 0,
      "INT": 6,
      "LUK": 7,
      "HP": 100,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      87,
      223,
      224
    ],
    "setId": 9,
    "animType": null
  },
  "225": {
    "id": 225,
    "displayName": "Mighty Oroboros Gloves",
    "name": "Oroboros Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 4,
      "LUK": 5,
      "HP": 71,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 226,
    "tierItemIds": [
      88,
      225,
      226
    ],
    "setId": 9,
    "animType": null
  },
  "226": {
    "id": 226,
    "displayName": "Superior Oroboros Gloves",
    "name": "Oroboros Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 6,
      "LUK": 7,
      "HP": 100,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      88,
      225,
      226
    ],
    "setId": 9,
    "animType": null
  },
  "227": {
    "id": 227,
    "displayName": "Mighty Lysandra Boots",
    "name": "Lysandra Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 72,
      "Critical": 0,
      "Evade": 5,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 228,
    "tierItemIds": [
      92,
      227,
      228
    ],
    "setId": 12,
    "animType": null
  },
  "228": {
    "id": 228,
    "displayName": "Superior Lysandra Boots",
    "name": "Lysandra Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 12,
      "INT": 0,
      "LUK": 0,
      "HP": 102,
      "Critical": 0,
      "Evade": 7,
      "Speed": 10,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      92,
      227,
      228
    ],
    "setId": 12,
    "animType": null
  },
  "229": {
    "id": 229,
    "displayName": "Mighty Lysandra Helmet",
    "name": "Lysandra Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 6,
      "Critical Damage": 4,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 4,
      "HP": 72,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 230,
    "tierItemIds": [
      93,
      229,
      230
    ],
    "setId": 12,
    "animType": null
  },
  "230": {
    "id": 230,
    "displayName": "Superior Lysandra Helmet",
    "name": "Lysandra Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 9,
      "Critical Damage": 6,
      "STR": 7,
      "AGI": 0,
      "INT": 4,
      "LUK": 6,
      "HP": 101,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      93,
      229,
      230
    ],
    "setId": 12,
    "animType": null
  },
  "231": {
    "id": 231,
    "displayName": "Mighty Lysandra Armor",
    "name": "Lysandra Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 157,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 20% chance of absorbing 15 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 232,
    "tierItemIds": [
      94,
      231,
      232
    ],
    "setId": 12,
    "animType": null
  },
  "232": {
    "id": 232,
    "displayName": "Superior Lysandra Armor",
    "name": "Lysandra Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 20,
      "Critical Damage": 0,
      "STR": 14,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 221,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 25% chance of absorbing 20 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      94,
      231,
      232
    ],
    "setId": 12,
    "animType": null
  },
  "233": {
    "id": 233,
    "displayName": "Mighty Lysandra Gloves",
    "name": "Lysandra Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 5,
      "HP": 72,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 234,
    "tierItemIds": [
      95,
      233,
      234
    ],
    "setId": 12,
    "animType": null
  },
  "234": {
    "id": 234,
    "displayName": "Superior Lysandra Gloves",
    "name": "Lysandra Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 4,
      "LUK": 7,
      "HP": 101,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      95,
      233,
      234
    ],
    "setId": 12,
    "animType": null
  },
  "235": {
    "id": 235,
    "displayName": "Mighty Lysandra Pants",
    "name": "Lysandra Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 72,
      "Critical": 0,
      "Evade": 4,
      "Speed": 6,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 236,
    "tierItemIds": [
      96,
      235,
      236
    ],
    "setId": 12,
    "animType": null
  },
  "236": {
    "id": 236,
    "displayName": "Superior Lysandra Pants",
    "name": "Lysandra Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 101,
      "Critical": 0,
      "Evade": 6,
      "Speed": 9,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      96,
      235,
      236
    ],
    "setId": 12,
    "animType": null
  },
  "237": {
    "id": 237,
    "displayName": "Mighty Legolas Gloves",
    "name": "Legolas Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 17,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 238,
    "tierItemIds": [
      100,
      237,
      238
    ],
    "setId": 13,
    "animType": null
  },
  "238": {
    "id": 238,
    "displayName": "Superior Legolas Gloves",
    "name": "Legolas Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 1,
      "LUK": 5,
      "HP": 28,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      100,
      237,
      238
    ],
    "setId": 13,
    "animType": null
  },
  "239": {
    "id": 239,
    "displayName": "Mighty Legolas Helmet",
    "name": "Legolas Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 7,
      "Physical Defense": 10,
      "Critical Damage": 4,
      "STR": 2,
      "AGI": 0,
      "INT": 1,
      "LUK": 5,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 240,
    "tierItemIds": [
      101,
      239,
      240
    ],
    "setId": 13,
    "animType": null
  },
  "240": {
    "id": 240,
    "displayName": "Superior Legolas Helmet",
    "name": "Legolas Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 8,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 16,
      "Magical Defense": 11,
      "Physical Defense": 14,
      "Critical Damage": 6,
      "STR": 4,
      "AGI": 0,
      "INT": 2,
      "LUK": 8,
      "HP": 123,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      101,
      239,
      240
    ],
    "setId": 13,
    "animType": null
  },
  "241": {
    "id": 241,
    "displayName": "Mighty Legolas Armor",
    "name": "Legolas Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 242,
    "tierItemIds": [
      102,
      241,
      242
    ],
    "setId": 13,
    "animType": null
  },
  "242": {
    "id": 242,
    "displayName": "Superior Legolas Armor",
    "name": "Legolas Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 82,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      102,
      241,
      242
    ],
    "setId": 13,
    "animType": null
  },
  "243": {
    "id": 243,
    "displayName": "Mighty Legolas Boots",
    "name": "Legolas Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 12,
      "INT": 0,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 244,
    "tierItemIds": [
      103,
      243,
      244
    ],
    "setId": 13,
    "animType": null
  },
  "244": {
    "id": 244,
    "displayName": "Superior Legolas Boots",
    "name": "Legolas Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 17,
      "INT": 0,
      "LUK": 0,
      "HP": 28,
      "Critical": 0,
      "Evade": 4,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      103,
      243,
      244
    ],
    "setId": 13,
    "animType": null
  },
  "245": {
    "id": 245,
    "displayName": "Mighty Legolas Pants",
    "name": "Legolas Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 13,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 246,
    "tierItemIds": [
      104,
      245,
      246
    ],
    "setId": 13,
    "animType": null
  },
  "246": {
    "id": 246,
    "displayName": "Superior Legolas Pants",
    "name": "Legolas Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 19,
      "INT": 0,
      "LUK": 0,
      "HP": 82,
      "Critical": 0,
      "Evade": 4,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      104,
      245,
      246
    ],
    "setId": 13,
    "animType": null
  },
  "247": {
    "id": 247,
    "displayName": "Wakefield Wand",
    "name": "Wakefield Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 30,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 248,
    "tierItemIds": [
      247,
      248,
      249
    ],
    "setId": 14,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "248": {
    "id": 248,
    "displayName": "Mighty Wakefield Wand",
    "name": "Wakefield Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 42,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 249,
    "tierItemIds": [
      247,
      248,
      249
    ],
    "setId": 14,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "249": {
    "id": 249,
    "displayName": "Superior Wakefield Wand",
    "name": "Wakefield Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 60,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      247,
      248,
      249
    ],
    "setId": 14,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "250": {
    "id": 250,
    "displayName": "Wakefield Gloves",
    "name": "Wakefield Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 975,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 251,
    "tierItemIds": [
      250,
      251,
      252
    ],
    "setId": 14,
    "animType": null
  },
  "251": {
    "id": 251,
    "displayName": "Mighty Wakefield Gloves",
    "name": "Wakefield Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 66,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 252,
    "tierItemIds": [
      250,
      251,
      252
    ],
    "setId": 14,
    "animType": null
  },
  "252": {
    "id": 252,
    "displayName": "Superior Wakefield Gloves",
    "name": "Wakefield Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 12,
      "LUK": 3,
      "HP": 93,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      250,
      251,
      252
    ],
    "setId": 14,
    "animType": null
  },
  "253": {
    "id": 253,
    "displayName": "Wakefield Helmet",
    "name": "Wakefield Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 975,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 254,
    "tierItemIds": [
      253,
      254,
      255
    ],
    "setId": 14,
    "animType": null
  },
  "254": {
    "id": 254,
    "displayName": "Mighty Wakefield Helmet",
    "name": "Wakefield Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 9,
      "LUK": 1,
      "HP": 66,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 255,
    "tierItemIds": [
      253,
      254,
      255
    ],
    "setId": 14,
    "animType": null
  },
  "255": {
    "id": 255,
    "displayName": "Superior Wakefield Helmet",
    "name": "Wakefield Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 13,
      "LUK": 2,
      "HP": 93,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      253,
      254,
      255
    ],
    "setId": 14,
    "animType": null
  },
  "256": {
    "id": 256,
    "displayName": "Wakefield Armor",
    "name": "Wakefield Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 975,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 257,
    "tierItemIds": [
      256,
      257,
      258
    ],
    "setId": 14,
    "animType": null
  },
  "257": {
    "id": 257,
    "displayName": "Mighty Wakefield Armor",
    "name": "Wakefield Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 66,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 258,
    "tierItemIds": [
      256,
      257,
      258
    ],
    "setId": 14,
    "animType": null
  },
  "258": {
    "id": 258,
    "displayName": "Superior Wakefield Armor",
    "name": "Wakefield Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 12,
      "LUK": 0,
      "HP": 93,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      256,
      257,
      258
    ],
    "setId": 14,
    "animType": null
  },
  "259": {
    "id": 259,
    "displayName": "Wakefield Boots",
    "name": "Wakefield Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 975,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 46,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 260,
    "tierItemIds": [
      259,
      260,
      261
    ],
    "setId": 14,
    "animType": null
  },
  "260": {
    "id": 260,
    "displayName": "Mighty Wakefield Boots",
    "name": "Wakefield Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 65,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 261,
    "tierItemIds": [
      259,
      260,
      261
    ],
    "setId": 14,
    "animType": null
  },
  "261": {
    "id": 261,
    "displayName": "Superior Wakefield Boots",
    "name": "Wakefield Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 92,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      259,
      260,
      261
    ],
    "setId": 14,
    "animType": null
  },
  "262": {
    "id": 262,
    "displayName": "Wakefield Pants",
    "name": "Wakefield Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 263,
    "tierItemIds": [
      262,
      263,
      264
    ],
    "setId": 14,
    "animType": null
  },
  "263": {
    "id": 263,
    "displayName": "Mighty Wakefield Pants",
    "name": "Wakefield Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 2,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 264,
    "tierItemIds": [
      262,
      263,
      264
    ],
    "setId": 14,
    "animType": null
  },
  "264": {
    "id": 264,
    "displayName": "Superior Wakefield Pants",
    "name": "Wakefield Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 9,
      "INT": 0,
      "LUK": 0,
      "HP": 122,
      "Critical": 0,
      "Evade": 4,
      "Speed": 6,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      262,
      263,
      264
    ],
    "setId": 14,
    "animType": null
  },
  "265": {
    "id": 265,
    "displayName": "Tiamat Book",
    "name": "Tiamat Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 266,
    "tierItemIds": [
      265,
      266,
      267
    ],
    "setId": 15,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "266": {
    "id": 266,
    "displayName": "Mighty Tiamat Book",
    "name": "Tiamat Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 30,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 267,
    "tierItemIds": [
      265,
      266,
      267
    ],
    "setId": 15,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "267": {
    "id": 267,
    "displayName": "Superior Tiamat Book",
    "name": "Tiamat Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 44,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      265,
      266,
      267
    ],
    "setId": 15,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "268": {
    "id": 268,
    "displayName": "Tiamat Gloves",
    "name": "Tiamat Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 6,
      "LUK": 2,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 269,
    "tierItemIds": [
      268,
      269,
      270
    ],
    "setId": 15,
    "animType": null
  },
  "269": {
    "id": 269,
    "displayName": "Mighty Tiamat Gloves",
    "name": "Tiamat Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 9,
      "LUK": 3,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 270,
    "tierItemIds": [
      268,
      269,
      270
    ],
    "setId": 15,
    "animType": null
  },
  "270": {
    "id": 270,
    "displayName": "Superior Tiamat Gloves",
    "name": "Tiamat Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 13,
      "LUK": 5,
      "HP": 99,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      268,
      269,
      270
    ],
    "setId": 15,
    "animType": null
  },
  "271": {
    "id": 271,
    "displayName": "Tiamat Helmet",
    "name": "Tiamat Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 6,
      "LUK": 1,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 272,
    "tierItemIds": [
      271,
      272,
      273
    ],
    "setId": 15,
    "animType": null
  },
  "272": {
    "id": 272,
    "displayName": "Mighty Tiamat Helmet",
    "name": "Tiamat Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 5,
      "Critical Damage": 1,
      "STR": 2,
      "AGI": 0,
      "INT": 9,
      "LUK": 2,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 273,
    "tierItemIds": [
      271,
      272,
      273
    ],
    "setId": 15,
    "animType": null
  },
  "273": {
    "id": 273,
    "displayName": "Superior Tiamat Helmet",
    "name": "Tiamat Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 7,
      "Critical Damage": 2,
      "STR": 4,
      "AGI": 0,
      "INT": 13,
      "LUK": 4,
      "HP": 99,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      271,
      272,
      273
    ],
    "setId": 15,
    "animType": null
  },
  "274": {
    "id": 274,
    "displayName": "Tiamat Armor",
    "name": "Tiamat Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 275,
    "tierItemIds": [
      274,
      275,
      276
    ],
    "setId": 15,
    "animType": null
  },
  "275": {
    "id": 275,
    "displayName": "Mighty Tiamat Armor",
    "name": "Tiamat Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 9,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 276,
    "tierItemIds": [
      274,
      275,
      276
    ],
    "setId": 15,
    "animType": null
  },
  "276": {
    "id": 276,
    "displayName": "Superior Tiamat Armor",
    "name": "Tiamat Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 13,
      "LUK": 0,
      "HP": 99,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      274,
      275,
      276
    ],
    "setId": 15,
    "animType": null
  },
  "277": {
    "id": 277,
    "displayName": "Tiamat Boots",
    "name": "Tiamat Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 278,
    "tierItemIds": [
      277,
      278,
      279
    ],
    "setId": 15,
    "animType": null
  },
  "278": {
    "id": 278,
    "displayName": "Mighty Tiamat Boots",
    "name": "Tiamat Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 71,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 279,
    "tierItemIds": [
      277,
      278,
      279
    ],
    "setId": 15,
    "animType": null
  },
  "279": {
    "id": 279,
    "displayName": "Superior Tiamat Boots",
    "name": "Tiamat Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 100,
      "Critical": 0,
      "Evade": 4,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      277,
      278,
      279
    ],
    "setId": 15,
    "animType": null
  },
  "280": {
    "id": 280,
    "displayName": "Tiamat Pants",
    "name": "Tiamat Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 65,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 281,
    "tierItemIds": [
      280,
      281,
      282
    ],
    "setId": 15,
    "animType": null
  },
  "281": {
    "id": 281,
    "displayName": "Mighty Tiamat Pants",
    "name": "Tiamat Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 92,
      "Critical": 0,
      "Evade": 4,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 282,
    "tierItemIds": [
      280,
      281,
      282
    ],
    "setId": 15,
    "animType": null
  },
  "282": {
    "id": 282,
    "displayName": "Superior Tiamat Pants",
    "name": "Tiamat Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 17,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 130,
      "Critical": 0,
      "Evade": 6,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      280,
      281,
      282
    ],
    "setId": 15,
    "animType": null
  },
  "283": {
    "id": 283,
    "displayName": "Donatella Bow",
    "name": "Donatella Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 284,
    "tierItemIds": [
      283,
      284,
      285
    ],
    "setId": 16,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "284": {
    "id": 284,
    "displayName": "Mighty Donatella Bow",
    "name": "Donatella Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 46,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 285,
    "tierItemIds": [
      283,
      284,
      285
    ],
    "setId": 16,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "285": {
    "id": 285,
    "displayName": "Superior Donatella Bow",
    "name": "Donatella Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 60,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      283,
      284,
      285
    ],
    "setId": 16,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "286": {
    "id": 286,
    "displayName": "Donatella Gloves",
    "name": "Donatella Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 287,
    "tierItemIds": [
      286,
      287,
      288
    ],
    "setId": 16,
    "animType": null
  },
  "287": {
    "id": 287,
    "displayName": "Mighty Donatella Gloves",
    "name": "Donatella Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 0,
      "LUK": 4,
      "HP": 84,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 288,
    "tierItemIds": [
      286,
      287,
      288
    ],
    "setId": 16,
    "animType": null
  },
  "288": {
    "id": 288,
    "displayName": "Superior Donatella Gloves",
    "name": "Donatella Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 0,
      "LUK": 6,
      "HP": 118,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      286,
      287,
      288
    ],
    "setId": 16,
    "animType": null
  },
  "289": {
    "id": 289,
    "displayName": "Donatella Helmet",
    "name": "Donatella Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 1,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 290,
    "tierItemIds": [
      289,
      290,
      291
    ],
    "setId": 16,
    "animType": null
  },
  "290": {
    "id": 290,
    "displayName": "Mighty Donatella Helmet",
    "name": "Donatella Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 2,
      "STR": 4,
      "AGI": 0,
      "INT": 0,
      "LUK": 4,
      "HP": 84,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 291,
    "tierItemIds": [
      289,
      290,
      291
    ],
    "setId": 16,
    "animType": null
  },
  "291": {
    "id": 291,
    "displayName": "Superior Donatella Helmet",
    "name": "Donatella Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 3,
      "STR": 6,
      "AGI": 0,
      "INT": 0,
      "LUK": 6,
      "HP": 118,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      289,
      290,
      291
    ],
    "setId": 16,
    "animType": null
  },
  "292": {
    "id": 292,
    "displayName": "Donatella Armor",
    "name": "Donatella Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 975,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 293,
    "tierItemIds": [
      292,
      293,
      294
    ],
    "setId": 16,
    "animType": null
  },
  "293": {
    "id": 293,
    "displayName": "Mighty Donatella Armor",
    "name": "Donatella Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 294,
    "tierItemIds": [
      292,
      293,
      294
    ],
    "setId": 16,
    "animType": null
  },
  "294": {
    "id": 294,
    "displayName": "Superior Donatella Armor",
    "name": "Donatella Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 77,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      292,
      293,
      294
    ],
    "setId": 16,
    "animType": null
  },
  "295": {
    "id": 295,
    "displayName": "Donatella Boots",
    "name": "Donatella Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 4000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 296,
    "tierItemIds": [
      295,
      296,
      297
    ],
    "setId": 16,
    "animType": null
  },
  "296": {
    "id": 296,
    "displayName": "Mighty Donatella Boots",
    "name": "Donatella Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 297,
    "tierItemIds": [
      295,
      296,
      297
    ],
    "setId": 16,
    "animType": null
  },
  "297": {
    "id": 297,
    "displayName": "Superior Donatella Boots",
    "name": "Donatella Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 11,
      "INT": 0,
      "LUK": 0,
      "HP": 46,
      "Critical": 0,
      "Evade": 4,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      295,
      296,
      297
    ],
    "setId": 16,
    "animType": null
  },
  "298": {
    "id": 298,
    "displayName": "Donatella Pants",
    "name": "Donatella Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 975,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 299,
    "tierItemIds": [
      298,
      299,
      300
    ],
    "setId": 16,
    "animType": null
  },
  "299": {
    "id": 299,
    "displayName": "Mighty Donatella Pants",
    "name": "Donatella Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 11,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 2,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 300,
    "tierItemIds": [
      298,
      299,
      300
    ],
    "setId": 16,
    "animType": null
  },
  "300": {
    "id": 300,
    "displayName": "Superior Donatella Pants",
    "name": "Donatella Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 16,
      "INT": 0,
      "LUK": 0,
      "HP": 77,
      "Critical": 0,
      "Evade": 4,
      "Speed": 6,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      298,
      299,
      300
    ],
    "setId": 16,
    "animType": null
  },
  "301": {
    "id": 301,
    "displayName": "Yashita Axe",
    "name": "Yashita Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 40,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 39,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Winter Soldier",
          "description": "Increases 4 STR, AGI, INT, and LUK in Snowfield terrain."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 302,
    "tierItemIds": [
      301,
      302,
      303
    ],
    "setId": 17,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "302": {
    "id": 302,
    "displayName": "Mighty Yashita Axe",
    "name": "Yashita Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 55,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Winter Soldier",
          "description": "Increases 6 STR, AGI, INT, and LUK in Snowfield terrain."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 303,
    "tierItemIds": [
      301,
      302,
      303
    ],
    "setId": 17,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "303": {
    "id": 303,
    "displayName": "Superior Yashita Axe",
    "name": "Yashita Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 77,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Winter Soldier",
          "description": "Increases 10 STR, AGI, INT, and LUK in Snowfield terrain."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      301,
      302,
      303
    ],
    "setId": 17,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "304": {
    "id": 304,
    "displayName": "Yashita Gloves",
    "name": "Yashita Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 1,
      "LUK": 3,
      "HP": 67,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 305,
    "tierItemIds": [
      304,
      305,
      306
    ],
    "setId": 17,
    "animType": null
  },
  "305": {
    "id": 305,
    "displayName": "Mighty Yashita Gloves",
    "name": "Yashita Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 2,
      "LUK": 5,
      "HP": 94,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 306,
    "tierItemIds": [
      304,
      305,
      306
    ],
    "setId": 17,
    "animType": null
  },
  "306": {
    "id": 306,
    "displayName": "Superior Yashita Gloves",
    "name": "Yashita Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 3,
      "LUK": 7,
      "HP": 132,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      304,
      305,
      306
    ],
    "setId": 17,
    "animType": null
  },
  "307": {
    "id": 307,
    "displayName": "Yashita Helmet",
    "name": "Yashita Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 975,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 2,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 1,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 308,
    "tierItemIds": [
      307,
      308,
      309
    ],
    "setId": 17,
    "animType": null
  },
  "308": {
    "id": 308,
    "displayName": "Mighty Yashita Helmet",
    "name": "Yashita Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 3,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 2,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 309,
    "tierItemIds": [
      307,
      308,
      309
    ],
    "setId": 17,
    "animType": null
  },
  "309": {
    "id": 309,
    "displayName": "Superior Yashita Helmet",
    "name": "Yashita Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 5,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 4,
      "HP": 81,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      307,
      308,
      309
    ],
    "setId": 17,
    "animType": null
  },
  "310": {
    "id": 310,
    "displayName": "Yashita Armor",
    "name": "Yashita Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 140,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 67,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 311,
    "tierItemIds": [
      310,
      311,
      312
    ],
    "setId": 17,
    "animType": null
  },
  "311": {
    "id": 311,
    "displayName": "Mighty Yashita Armor",
    "name": "Yashita Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 94,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 312,
    "tierItemIds": [
      310,
      311,
      312
    ],
    "setId": 17,
    "animType": null
  },
  "312": {
    "id": 312,
    "displayName": "Superior Yashita Armor",
    "name": "Yashita Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 132,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      310,
      311,
      312
    ],
    "setId": 17,
    "animType": null
  },
  "313": {
    "id": 313,
    "displayName": "Yashita Boots",
    "name": "Yashita Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 4000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 13,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 314,
    "tierItemIds": [
      313,
      314,
      315
    ],
    "setId": 17,
    "animType": null
  },
  "314": {
    "id": 314,
    "displayName": "Mighty Yashita Boots",
    "name": "Yashita Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 315,
    "tierItemIds": [
      313,
      314,
      315
    ],
    "setId": 17,
    "animType": null
  },
  "315": {
    "id": 315,
    "displayName": "Superior Yashita Boots",
    "name": "Yashita Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 4,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      313,
      314,
      315
    ],
    "setId": 17,
    "animType": null
  },
  "316": {
    "id": 316,
    "displayName": "Yashita Pants",
    "name": "Yashita Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 975,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 317,
    "tierItemIds": [
      316,
      317,
      318
    ],
    "setId": 17,
    "animType": null
  },
  "317": {
    "id": 317,
    "displayName": "Mighty Yashita Pants",
    "name": "Yashita Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 318,
    "tierItemIds": [
      316,
      317,
      318
    ],
    "setId": 17,
    "animType": null
  },
  "318": {
    "id": 318,
    "displayName": "Superior Yashita Pants",
    "name": "Yashita Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 81,
      "Critical": 0,
      "Evade": 4,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      316,
      317,
      318
    ],
    "setId": 17,
    "animType": null
  },
  "319": {
    "id": 319,
    "displayName": "Antaura Scythe",
    "name": "Antaura Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 30,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 46,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Death and Decay",
          "description": "Reduces all enemies' Physical Defense & Physical Damage within a 3-tile radius by 5%. If this unit attacks an enemy, the effect is doubled for the target and cannot be removed until death."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 320,
    "tierItemIds": [
      319,
      320,
      321
    ],
    "setId": 18,
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "320": {
    "id": 320,
    "displayName": "Mighty Antaura Scythe",
    "name": "Antaura Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 65,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 16,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Death and Decay",
          "description": "Reduces all enemies' Physical Defense & Physical Damage within a 3-tile radius by 8%. If this unit attacks an enemy, the effect is doubled for the target and cannot be removed until death."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 321,
    "tierItemIds": [
      319,
      320,
      321
    ],
    "setId": 18,
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "321": {
    "id": 321,
    "displayName": "Superior Antaura Scythe",
    "name": "Antaura Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 88,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 23,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Death and Decay",
          "description": "Reduces all enemies' Physical Defense & Physical Damage within a 4-tile radius by 12%. If this unit attacks an enemy, the effect is doubled for the target and cannot be removed until death."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      319,
      320,
      321
    ],
    "setId": 18,
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "322": {
    "id": 322,
    "displayName": "Antaura Gloves",
    "name": "Antaura Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 7,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 2,
      "HP": 49,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 323,
    "tierItemIds": [
      322,
      323,
      324
    ],
    "setId": 18,
    "animType": null
  },
  "323": {
    "id": 323,
    "displayName": "Mighty Antaura Gloves",
    "name": "Antaura Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 6,
      "Critical Damage": 11,
      "STR": 5,
      "AGI": 0,
      "INT": 5,
      "LUK": 4,
      "HP": 69,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 324,
    "tierItemIds": [
      322,
      323,
      324
    ],
    "setId": 18,
    "animType": null
  },
  "324": {
    "id": 324,
    "displayName": "Superior Antaura Gloves",
    "name": "Antaura Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 9,
      "Critical Damage": 16,
      "STR": 7,
      "AGI": 0,
      "INT": 7,
      "LUK": 6,
      "HP": 97,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      322,
      323,
      324
    ],
    "setId": 18,
    "animType": null
  },
  "325": {
    "id": 325,
    "displayName": "Antaura Helmet",
    "name": "Antaura Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 49,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 326,
    "tierItemIds": [
      325,
      326,
      327
    ],
    "setId": 18,
    "animType": null
  },
  "326": {
    "id": 326,
    "displayName": "Mighty Antaura Helmet",
    "name": "Antaura Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 7,
      "LUK": 4,
      "HP": 69,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 327,
    "tierItemIds": [
      325,
      326,
      327
    ],
    "setId": 18,
    "animType": null
  },
  "327": {
    "id": 327,
    "displayName": "Superior Antaura Helmet",
    "name": "Antaura Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 10,
      "LUK": 6,
      "HP": 97,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      325,
      326,
      327
    ],
    "setId": 18,
    "animType": null
  },
  "328": {
    "id": 328,
    "displayName": "Antaura Armor",
    "name": "Antaura Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 64,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 329,
    "tierItemIds": [
      328,
      329,
      330
    ],
    "setId": 18,
    "animType": null
  },
  "329": {
    "id": 329,
    "displayName": "Mighty Antaura Armor",
    "name": "Antaura Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 90,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 330,
    "tierItemIds": [
      328,
      329,
      330
    ],
    "setId": 18,
    "animType": null
  },
  "330": {
    "id": 330,
    "displayName": "Superior Antaura Armor",
    "name": "Antaura Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 127,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      328,
      329,
      330
    ],
    "setId": 18,
    "animType": null
  },
  "331": {
    "id": 331,
    "displayName": "Antaura Boots",
    "name": "Antaura Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 332,
    "tierItemIds": [
      331,
      332,
      333
    ],
    "setId": 18,
    "animType": null
  },
  "332": {
    "id": 332,
    "displayName": "Mighty Antaura Boots",
    "name": "Antaura Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 4,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 333,
    "tierItemIds": [
      331,
      332,
      333
    ],
    "setId": 18,
    "animType": null
  },
  "333": {
    "id": 333,
    "displayName": "Superior Antaura Boots",
    "name": "Antaura Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 97,
      "Critical": 0,
      "Evade": 6,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      331,
      332,
      333
    ],
    "setId": 18,
    "animType": null
  },
  "334": {
    "id": 334,
    "displayName": "Antaura Pants",
    "name": "Antaura Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 335,
    "tierItemIds": [
      334,
      335,
      336
    ],
    "setId": 18,
    "animType": null
  },
  "335": {
    "id": 335,
    "displayName": "Mighty Antaura Pants",
    "name": "Antaura Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 3,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 336,
    "tierItemIds": [
      334,
      335,
      336
    ],
    "setId": 18,
    "animType": null
  },
  "336": {
    "id": 336,
    "displayName": "Superior Antaura Pants",
    "name": "Antaura Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 11,
      "INT": 0,
      "LUK": 0,
      "HP": 97,
      "Critical": 0,
      "Evade": 5,
      "Speed": 6,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      334,
      335,
      336
    ],
    "setId": 18,
    "animType": null
  },
  "337": {
    "id": 337,
    "displayName": "Frederick Staff",
    "name": "Frederick Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 30,
    "tier": 1,
    "stats": {
      "Magical Damage": 44,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Holy Blessing",
          "description": "Increases all allies' Physical Defense & Magical Defense within a 3-tile radius by 5%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 338,
    "tierItemIds": [
      337,
      338,
      339
    ],
    "setId": 19,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "338": {
    "id": 338,
    "displayName": "Mighty Frederick Staff",
    "name": "Frederick Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 62,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Holy Blessing",
          "description": "Increases all allies' Physical Defense & Magical Defense within a 3-tile radius by 10%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 339,
    "tierItemIds": [
      337,
      338,
      339
    ],
    "setId": 19,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "339": {
    "id": 339,
    "displayName": "Superior Frederick Staff",
    "name": "Frederick Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 87,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Holy Blessing",
          "description": "Increases all allies' Physical Defense & Magical Defense within a 4-tile radius by 15%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      337,
      338,
      339
    ],
    "setId": 19,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "340": {
    "id": 340,
    "displayName": "Frederick Gloves",
    "name": "Frederick Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 1,
      "AGI": 0,
      "INT": 6,
      "LUK": 2,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 341,
    "tierItemIds": [
      340,
      341,
      342
    ],
    "setId": 19,
    "animType": null
  },
  "341": {
    "id": 341,
    "displayName": "Mighty Frederick Gloves",
    "name": "Frederick Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 2,
      "AGI": 0,
      "INT": 9,
      "LUK": 3,
      "HP": 70,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 342,
    "tierItemIds": [
      340,
      341,
      342
    ],
    "setId": 19,
    "animType": null
  },
  "342": {
    "id": 342,
    "displayName": "Superior Frederick Gloves",
    "name": "Frederick Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 8,
      "STR": 3,
      "AGI": 0,
      "INT": 13,
      "LUK": 5,
      "HP": 98,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      340,
      341,
      342
    ],
    "setId": 19,
    "animType": null
  },
  "343": {
    "id": 343,
    "displayName": "Frederick Helmet",
    "name": "Frederick Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 344,
    "tierItemIds": [
      343,
      344,
      345
    ],
    "setId": 19,
    "animType": null
  },
  "344": {
    "id": 344,
    "displayName": "Mighty Frederick Helmet",
    "name": "Frederick Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 12,
      "LUK": 3,
      "HP": 70,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 345,
    "tierItemIds": [
      343,
      344,
      345
    ],
    "setId": 19,
    "animType": null
  },
  "345": {
    "id": 345,
    "displayName": "Superior Frederick Helmet",
    "name": "Frederick Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 18,
      "LUK": 5,
      "HP": 98,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      343,
      344,
      345
    ],
    "setId": 19,
    "animType": null
  },
  "346": {
    "id": 346,
    "displayName": "Frederick Armor",
    "name": "Frederick Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 65,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 347,
    "tierItemIds": [
      346,
      347,
      348
    ],
    "setId": 19,
    "animType": null
  },
  "347": {
    "id": 347,
    "displayName": "Mighty Frederick Armor",
    "name": "Frederick Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 91,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 348,
    "tierItemIds": [
      346,
      347,
      348
    ],
    "setId": 19,
    "animType": null
  },
  "348": {
    "id": 348,
    "displayName": "Superior Frederick Armor",
    "name": "Frederick Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 11,
      "LUK": 0,
      "HP": 128,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      346,
      347,
      348
    ],
    "setId": 19,
    "animType": null
  },
  "349": {
    "id": 349,
    "displayName": "Frederick Boots",
    "name": "Frederick Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 350,
    "tierItemIds": [
      349,
      350,
      351
    ],
    "setId": 19,
    "animType": null
  },
  "350": {
    "id": 350,
    "displayName": "Mighty Frederick Boots",
    "name": "Frederick Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 4,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 351,
    "tierItemIds": [
      349,
      350,
      351
    ],
    "setId": 19,
    "animType": null
  },
  "351": {
    "id": 351,
    "displayName": "Superior Frederick Boots",
    "name": "Frederick Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 14,
      "INT": 0,
      "LUK": 0,
      "HP": 97,
      "Critical": 0,
      "Evade": 6,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      349,
      350,
      351
    ],
    "setId": 19,
    "animType": null
  },
  "352": {
    "id": 352,
    "displayName": "Frederick Pants",
    "name": "Frederick Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 353,
    "tierItemIds": [
      352,
      353,
      354
    ],
    "setId": 19,
    "animType": null
  },
  "353": {
    "id": 353,
    "displayName": "Mighty Frederick Pants",
    "name": "Frederick Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 3,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 354,
    "tierItemIds": [
      352,
      353,
      354
    ],
    "setId": 19,
    "animType": null
  },
  "354": {
    "id": 354,
    "displayName": "Superior Frederick Pants",
    "name": "Frederick Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 15,
      "INT": 0,
      "LUK": 0,
      "HP": 98,
      "Critical": 0,
      "Evade": 5,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      352,
      353,
      354
    ],
    "setId": 19,
    "animType": null
  },
  "355": {
    "id": 355,
    "displayName": "Mellow Death Knife",
    "name": "Mellow Death Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 5,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 91,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 6,
      "INT": 3,
      "LUK": 5,
      "HP": 80,
      "Critical": 2,
      "Evade": 2,
      "Speed": 5,
      "Accuracy": 8,
      "effects": [
        {
          "name": "Sweet Party Treats",
          "description": "Attacks have a 10% chance of turning the target into a sweet candy (instant kill). Otherwise, there will be 15% chance of turning the target into a Chicken (unable to attack/use skills) for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 356,
    "tierItemIds": [
      355,
      356,
      357
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "356": {
    "id": 356,
    "displayName": "Mighty Mellow Death Knife",
    "name": "Mellow Death Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 128,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 5,
      "AGI": 9,
      "INT": 4,
      "LUK": 7,
      "HP": 113,
      "Critical": 3,
      "Evade": 3,
      "Speed": 7,
      "Accuracy": 11,
      "effects": [
        {
          "name": "Sweet Party Treats",
          "description": "Attacks have a 12% chance of turning the target into a sweet candy (instant kill). Otherwise, there will be 18% chance of turning the target into a Chicken (unable to attack/use skills) for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 357,
    "tierItemIds": [
      355,
      356,
      357
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "357": {
    "id": 357,
    "displayName": "Superior Mellow Death Knife",
    "name": "Mellow Death Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 180,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 8,
      "AGI": 13,
      "INT": 6,
      "LUK": 10,
      "HP": 159,
      "Critical": 5,
      "Evade": 5,
      "Speed": 10,
      "Accuracy": 14,
      "effects": [
        {
          "name": "Sweet Party Treats",
          "description": "Attacks have a 16% chance of turning the target into a sweet candy (instant kill). Otherwise, there will be 24% chance of turning the target into a Chicken (unable to attack/use skills) for 2 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      355,
      356,
      357
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "358": {
    "id": 358,
    "displayName": "Honeyed Sanction Staff",
    "name": "Honeyed Sanction Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 7,
    "tier": 1,
    "stats": {
      "Magical Damage": 51,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 2,
      "INT": 10,
      "LUK": 2,
      "HP": 86,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": [
        {
          "name": "Honeyed Sanction",
          "description": "Immune to all disabling spells."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 359,
    "tierItemIds": [
      358,
      359,
      360
    ],
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "359": {
    "id": 359,
    "displayName": "Mighty Honeyed Sanction Staff",
    "name": "Honeyed Sanction Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 72,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 4,
      "INT": 15,
      "LUK": 3,
      "HP": 121,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": [
        {
          "name": "Honeyed Sanction",
          "description": "Immune to all disabling spells."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 360,
    "tierItemIds": [
      358,
      359,
      360
    ],
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "360": {
    "id": 360,
    "displayName": "Superior Honeyed Sanction Staff",
    "name": "Honeyed Sanction Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 102,
      "Physical Damage": 0,
      "Magical Defense": 21,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 15,
      "AGI": 6,
      "INT": 22,
      "LUK": 5,
      "HP": 170,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": [
        {
          "name": "Honeyed Sanction",
          "description": "Immune to all disabling spells."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      358,
      359,
      360
    ],
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "361": {
    "id": 361,
    "displayName": "Permafrost SkillBook",
    "name": "Permafrost SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 15,
    "requiredStats": [],
    "maxSupply": 200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 362,
    "tierItemIds": [
      361,
      362,
      363
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Upon death, the hero enters an Ice Coffin for 2 turns with all buffs/debuffs removed. After 2 turns, the hero returns with HP equal to the remaining HP of the Ice Coffin\nThe hero can only be targeted with normal attacks which they cannot dodge, and gets their defense ignored during this state. \n\n- Use count limit: 1\n- Ice Coffin's HP (%Hero's Max HP): 45\n- Duration(turns): 2",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Ice Coffin's HP per Uncommon part.\r",
        "+ 0.2% Ice Coffin's HP per Rare part.\r",
        "+ 0.5% Ice Coffin's HP per Epic part.\r",
        "+ 2% Ice Coffin's HP per Legendary part.\r",
        "+ 5% Ice Coffin's HP per Mythical part."
      ]
    }
  },
  "362": {
    "id": 362,
    "displayName": "Mighty Permafrost SkillBook",
    "name": "Permafrost SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 363,
    "tierItemIds": [
      361,
      362,
      363
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Upon death, the hero enters an Ice Coffin for 2 turns with all buffs/debuffs removed. After 2 turns, the hero returns with HP equal to the remaining HP of the Ice Coffin\nThe hero can only be targeted with normal attacks which they cannot dodge, and gets their defense ignored during this state. \n\n- Use count limit: 1\n- Ice Coffin's HP (%Hero's Max HP): 60\n- Duration(turns): 2",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Ice Coffin's HP per Uncommon part.\r",
        "+ 0.2% Ice Coffin's HP per Rare part.\r",
        "+ 0.5% Ice Coffin's HP per Epic part.\r",
        "+ 2% Ice Coffin's HP per Legendary part.\r",
        "+ 5% Ice Coffin's HP per Mythical part."
      ]
    }
  },
  "363": {
    "id": 363,
    "displayName": "Superior Permafrost SkillBook",
    "name": "Permafrost SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      361,
      362,
      363
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Upon death, the hero enters an Ice Coffin for 2 turns with all buffs/debuffs removed. After 2 turns, the hero returns with HP equal to the remaining HP of the Ice Coffin\nThe hero can only be targeted with normal attacks which they cannot dodge, and gets their defense ignored during this state. \n\n- Use count limit: 1\n- Ice Coffin's HP (%Hero's Max HP): 70\n- Duration(turns): 2",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Ice Coffin's HP per Uncommon part.\r",
        "+ 0.2% Ice Coffin's HP per Rare part.\r",
        "+ 0.5% Ice Coffin's HP per Epic part.\r",
        "+ 2% Ice Coffin's HP per Legendary part.\r",
        "+ 5% Ice Coffin's HP per Mythical part."
      ]
    }
  },
  "364": {
    "id": 364,
    "displayName": "Shield of Faith SkillBook",
    "name": "Shield of Faith SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 22,
    "requiredStats": [],
    "maxSupply": 200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 365,
    "tierItemIds": [
      364,
      365,
      366
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Generates a shield for an ally or self that blocks damage based on the caster's INT. The shield protects its host against all types of damages. (Pure, Physical, and Magical).\n\n- Initial Cooldown(turns): 1 \n- Cooldown(turns): 3 \n- RT conversion (%SPD): 15\n- Cast range: 3\n- Use count limit: 4\n- Damage blocked (%INT): 90\n- Duration(turns): 3",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.5% Damage blocked (%INT) per Uncommon part.\r",
        "+ 1% Damage blocked (%INT) per Rare part.\r",
        "+ 5% Damage blocked (%INT) per Epic part.\r",
        "+ 10% Damage blocked (%INT) per Legendary part.\r",
        "+ 20% Damage blocked (%INT) per Mythical part."
      ]
    }
  },
  "365": {
    "id": 365,
    "displayName": "Mighty Shield of Faith SkillBook",
    "name": "Shield of Faith SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 366,
    "tierItemIds": [
      364,
      365,
      366
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Generates a shield for an ally or self that blocks damage based on the caster's INT. The shield protects its host against all types of damages. (Pure, Physical, and Magical).\n\n- Initial Cooldown(turns): 1\n- Cooldown(turns): 3 \n- RT conversion (%SPD): 15\n- Cast range: 3\n- Use count limit: 4\n- Damage blocked (%INT): 110\n- Duration(turns): 3",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.5% Damage blocked (%INT) per Uncommon part.\r",
        "+ 1% Damage blocked (%INT) per Rare part.\r",
        "+ 5% Damage blocked (%INT) per Epic part.\r",
        "+ 10% Damage blocked (%INT) per Legendary part.\r",
        "+ 20% Damage blocked (%INT) per Mythical part."
      ]
    }
  },
  "366": {
    "id": 366,
    "displayName": "Superior Shield of Faith SkillBook",
    "name": "Shield of Faith SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      364,
      365,
      366
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Generates a shield for an ally or self that blocks damage based on the caster's INT. The shield protects its host against all types of damages. (Pure, Physical, and Magical).\n\n- Initial Cooldown(turns): 1\n- Cooldown(turns): 3 \n- RT conversion (%SPD): 15\n- Cast range: 3\n- Use count limit: 4\n- Damage blocked (%INT): 130\n- Duration(turns): 3",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.5% Damage blocked (%INT) per Uncommon part.\r",
        "+ 1% Damage blocked (%INT) per Rare part.\r",
        "+ 5% Damage blocked (%INT) per Epic part.\r",
        "+ 10% Damage blocked (%INT) per Legendary part.\r",
        "+ 20% Damage blocked (%INT) per Mythical part."
      ]
    }
  },
  "367": {
    "id": 367,
    "displayName": "Lightning Bow SkillBook",
    "name": "Lightning Bow SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 10,
    "requiredStats": [],
    "maxSupply": 210,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 368,
    "tierItemIds": [
      367,
      368,
      369
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Musters INT from allies and self to launch a lightning bolt towards an enemy. Deals Magical Damage based on total INT of all current living allies\n\n- Initial Cooldown(turn): 1 \n- Cooldown(turns): 4 \n- Accuracy(%): 95\n- RT conversion (%SPD): 35\n- Cast Range: 5\n- Use count limit: 3\n- Magical Damage (%Total INT): 55",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.5% Magical Damage (Total INT) penalty per Uncommon part.\r",
        "+ 1% Magical Damage (Total INT) penalty per Rare part.\r",
        "+ 5% Magical Damage (Total INT) penalty per Epic part.\r",
        "+ 10% Magical Damage (Total INT) penalty per Legendary part.\r",
        "+ 20% Magical Damage (Total INT) penalty per Mythical part."
      ]
    }
  },
  "368": {
    "id": 368,
    "displayName": "Mighty Lightning Bow SkillBook",
    "name": "Lightning Bow SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 20,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 369,
    "tierItemIds": [
      367,
      368,
      369
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Musters INT from allies and self to launch a lightning bolt towards an enemy. Deals Magical Damage based on total INT of all current living allies\n\n- Initial Cooldown(turn): 1 \n- Cooldown(turns): 4\n- Accuracy(%): 95\n- RT conversion (%SPD): 35\n- Cast Range: 5\n- Use count limit: 3\n- Magical Damage (%Total INT): 60",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.5% Magical Damage (Total INT) penalty per Uncommon part.\r",
        "+ 1% Magical Damage (Total INT) penalty per Rare part.\r",
        "+ 5% Magical Damage (Total INT) penalty per Epic part.\r",
        "+ 10% Magical Damage (Total INT) penalty per Legendary part.\r",
        "+ 20% Magical Damage (Total INT) penalty per Mythical part."
      ]
    }
  },
  "369": {
    "id": 369,
    "displayName": "Superior Lightning Bow SkillBook",
    "name": "Lightning Bow SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      367,
      368,
      369
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Musters INT from allies and self to launch a lightning bolt towards an enemy. Deals Magical Damage based on total INT of all current living allies\n\n- Initial Cooldown(turn): 1 \n- Cooldown(turns): 4\n- Accuracy(%): 95\n- RT conversion (%SPD): 35\n- Cast Range: 5\n- Use count limit: 3\n- Magical Damage (%Total INT): 70",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.5% Magical Damage (Total INT) penalty per Uncommon part.\r",
        "+ 1% Magical Damage (Total INT) penalty per Rare part.\r",
        "+ 5% Magical Damage (Total INT) penalty per Epic part.\r",
        "+ 10% Magical Damage (Total INT) penalty per Legendary part.\r",
        "+ 20% Magical Damage (Total INT) penalty per Mythical part."
      ]
    }
  },
  "370": {
    "id": 370,
    "displayName": "Souls Drain SkillBook",
    "name": "Souls Drain SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 20,
    "requiredStats": [],
    "maxSupply": 210,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 371,
    "tierItemIds": [
      370,
      371,
      372
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Inflicts Magical Damage upon all enemy units (heroes and summoned units) based on the caster's INT.\nReduces cooldown for a skill in the caster's skill tree by 1 turn for every 3 target hit with Soul Drain in a single use (prioritizes longer CDs)\n\n- Initial Cooldown(turns): 1\n- Cooldown(turns): 4\n- Accuracy(%): 90\n- RT conversion (%SPD): 30\n- Cast Range: Global\n- Use count limit: 3\n- Magical Damage (%INT): 110",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Magical Damage (%INT) per Uncommon part.\r",
        "+ 0.2% Magical Damage (%INT) per Rare part.\r",
        "+ 1% Magical Damage (%INT) per Epic part.\r",
        "+ 5% Magical Damage (%INT) per Legendary part.\r",
        "+ 10% Magical Damage (%INT) per Mythical part."
      ]
    }
  },
  "371": {
    "id": 371,
    "displayName": "Mighty Souls Drain SkillBook",
    "name": "Souls Drain SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 28,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 372,
    "tierItemIds": [
      370,
      371,
      372
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Inflicts Magical Damage upon all enemy units (heroes and summoned units) based on the caster's INT.\nReduces cooldown for a skill in the caster's skill tree by 1 turn for every 3 target hit with Soul Drain in a single use (prioritizes longer CDs)\n\n- Initial Cooldown(turns): 1\n- Cooldown(turns): 4\n- Accuracy(%): 90\n- RT conversion (%SPD): 30\n- Cast Range: Global\n- Use count limit: 3\n- Magical Damage (%INT): 120",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Magical Damage (%INT) per Uncommon part.\r",
        "+ 0.2% Magical Damage (%INT) per Rare part.\r",
        "+ 1% Magical Damage (%INT) per Epic part.\r",
        "+ 5% Magical Damage (%INT) per Legendary part.\r",
        "+ 10% Magical Damage (%INT) per Mythical part."
      ]
    }
  },
  "372": {
    "id": 372,
    "displayName": "Superior Souls Drain SkillBook",
    "name": "Souls Drain SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      370,
      371,
      372
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Inflicts Magical Damage upon all enemy units (heroes and summoned units) based on the caster's INT.\nReduces cooldown for a skill in the caster's skill tree by 1 turn for every 3 target hit with Soul Drain in a single use (prioritizes longer CDs)\n\n- Initial Cooldown(turns): 1\n- Cooldown(turns): 4\n- Accuracy(%): 90\n- RT conversion (%SPD): 30\n- Cast Range: Global\n- Use count limit: 3\n- Magical Damage (%INT): 130",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Magical Damage (%INT) per Uncommon part.\r",
        "+ 0.2% Magical Damage (%INT) per Rare part.\r",
        "+ 1% Magical Damage (%INT) per Epic part.\r",
        "+ 5% Magical Damage (%INT) per Legendary part.\r",
        "+ 10% Magical Damage (%INT) per Mythical part."
      ]
    }
  },
  "373": {
    "id": 373,
    "displayName": "Frost Haven SkillBook",
    "name": "Frost Haven SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 20,
    "requiredStats": [],
    "maxSupply": 210,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 374,
    "tierItemIds": [
      373,
      374,
      375
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Summons an uncontrollable Yeti to the battlefield. The Yeti has a Passive skill that applies Bleeding on each normal attack. Each team can only have a maximum of 1 Yeti simultaneously. \n\n- Initial Cooldown(turn): 1 \n- RT conversion (%SPD): 15\n- Cast range: 3\n- Use count limit: 1\n\nYeti stats:\n- HP: 350\n- Physical Damage: 40\n- Magical Defense: 30\n- Physical Defense: 30\n- Bleeding damage: 25\n- Movement range: 4",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Yeti's HP per Uncommon part.",
        "+ 0.2% Yeti's HP per Rare part.",
        "+ 1% Yeti's HP per Epic part.",
        "+ 5% Yeti's HP per Legendary part.",
        "+ 10% Yeti's HP per Mythical part."
      ]
    }
  },
  "374": {
    "id": 374,
    "displayName": "Mighty Frost Haven SkillBook",
    "name": "Frost Haven SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 375,
    "tierItemIds": [
      373,
      374,
      375
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Summons an uncontrollable Yeti to the battlefield. The Yeti has a Passive skill that applies Bleeding on each normal attack. Each team can only have a maximum of 1 Yeti simultaneously. \n\n- Initial Cooldown(turn): 1 \n- RT conversion (%SPD): 15\n- Cast range: 3\n- Use count limit: 1\n\nYeti stats:\n- HP: 450\n- Physical Damage: 60\n- Magical Defense: 40\n- Physical Defense: 40\n- Bleeding damage: 35\n- Movement range: 4",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Yeti's HP per Uncommon part.",
        "+ 0.2% Yeti's HP per Rare part.",
        "+ 1% Yeti's HP per Epic part.",
        "+ 5% Yeti's HP per Legendary part.",
        "+ 10% Yeti's HP per Mythical part."
      ]
    }
  },
  "375": {
    "id": 375,
    "displayName": "Superior Frost Haven SkillBook",
    "name": "Frost Haven SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      373,
      374,
      375
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Summons an uncontrollable Yeti to the battlefield. The Yeti has a Passive skill that applies Bleeding on each normal attack. Each team can only have a maximum of 1 Yeti simultaneously. \n\n- Initial Cooldown(turn): 1 \n- RT conversion (%SPD): 15\n- Cast range: 3\n- Use count limit: 1\n\nYeti stats:\n- HP: 600\n- Physical Damage: 80\n- Magical Defense: 50\n- Physical Defense: 50\n- Bleeding damage: 45\n- Movement range:4",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Yeti's HP per Uncommon part.",
        "+ 0.2% Yeti's HP per Rare part.",
        "+ 1% Yeti's HP per Epic part.",
        "+ 5% Yeti's HP per Legendary part.",
        "+ 10% Yeti's HP per Mythical part."
      ]
    }
  },
  "376": {
    "id": 376,
    "displayName": "Spiritual Healer SkillBook",
    "name": "Spiritual Healer SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 9,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 60,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 377,
    "tierItemIds": [
      376,
      377,
      378
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Resurrects a dead ally to a portion of their max HP, and last them for several turns.\nThe resurrected gets 1 skill in their skill tree removed of cooldown (longest remaining) and can still be slain.\n\n- Initial Cooldown(turns): 3 \n- RT conversion (%SPD): 15\n- Cast Range: 4\n- Use count limit: 1\n- Resurrected HP(%): 25\n- Duration(turns): 3",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Resurrected HP per Uncommon part.\r",
        "+ 0.2% Resurrected HP per Rare part.\r",
        "+ 0.5% Resurrected HP per Epic part.\r",
        "+ 2% Resurrected HP per Legendary part.\r",
        "+ 5% Resurrected HP per Mythical part."
      ]
    }
  },
  "377": {
    "id": 377,
    "displayName": "Mighty Spiritual Healer SkillBook",
    "name": "Spiritual Healer SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 9,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 378,
    "tierItemIds": [
      376,
      377,
      378
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Resurrects a dead ally to a portion of their max HP, and last them for several turns.\nThe resurrected gets 1 skill in their skill tree removed of cooldown (longest remaining) and can still be slain.\n\n- Initial Cooldown(turns): 3 \n- RT conversion (%SPD): 15\n- Cast Range: 4\n- Use count limit: 1\n- Resurrected HP(%): 40\n- Duration(turns): 3",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Resurrected HP per Uncommon part.\r",
        "+ 0.2% Resurrected HP per Rare part.\r",
        "+ 0.5% Resurrected HP per Epic part.\r",
        "+ 2% Resurrected HP per Legendary part.\r",
        "+ 5% Resurrected HP per Mythical part."
      ]
    }
  },
  "378": {
    "id": 378,
    "displayName": "Superior Spiritual Healer SkillBook",
    "name": "Spiritual Healer SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 9,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      376,
      377,
      378
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Resurrects a dead ally to a portion of their max HP, and last them for several turns.\nThe resurrected gets 1 skill in their skill tree removed of cooldown (longest remaining) and can still be slain.\n\n- Initial Cooldown(turns): 3 \n- RT conversion (%SPD): 15\n- Cast Range: 4\n- Use count limit: 1\n- Resurrected HP(%): 65\n- Duration(turns): 3",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Resurrected HP per Uncommon part.\r",
        "+ 0.2% Resurrected HP per Rare part.\r",
        "+ 0.5% Resurrected HP per Epic part.\r",
        "+ 2% Resurrected HP per Legendary part.\r",
        "+ 5% Resurrected HP per Mythical part."
      ]
    }
  },
  "379": {
    "id": 379,
    "displayName": "Wailing Infernal SkillBook",
    "name": "Wailing Infernal SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 15,
    "requiredStats": [],
    "maxSupply": 210,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 380,
    "tierItemIds": [
      379,
      380,
      381
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Deals Pure Damage based on the hero's MAX HP to the target, regains a certain proportion of the MAX HP.\n\n- Initial Cooldown(turn): 1 \n- Cooldown(turns): 4\n- Accuracy(%): 90\n- RT conversion (%SPD): 50\n- Cast Range: 1\n- Use count limit: 2\n- HP regained(%): 10\n- Pure Damage(%): 10",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Pure Damage per Uncommon part.\r",
        "+ 0.2% Pure Damage per Rare part.\r",
        "+ 1% Pure Damage per Epic part.\r",
        "+ 5% Pure Damage per Legendary part.\r",
        "+ 10% Pure Damage per Mythical part."
      ]
    }
  },
  "380": {
    "id": 380,
    "displayName": "Mighty Wailing Infernal SkillBook",
    "name": "Wailing Infernal SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 381,
    "tierItemIds": [
      379,
      380,
      381
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Deals Pure Damage based on the hero's MAX HP to the target, regains a certain proportion of the MAX HP.\n\n- Initial Cooldown(turn): 1 \n- Cooldown(turns): 4\n- Accuracy(%): 90\n- RT conversion (%SPD): 50\n- Cast Range: 1\n- Use count limit: 2\n- HP regained(%): 13\n- Pure Damage(%): 15",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Pure Damage per Uncommon part.\r",
        "+ 0.2% Pure Damage per Rare part.\r",
        "+ 1% Pure Damage per Epic part.\r",
        "+ 5% Pure Damage per Legendary part.\r",
        "+ 10% Pure Damage per Mythical part."
      ]
    }
  },
  "381": {
    "id": 381,
    "displayName": "Superior Wailing Infernal SkillBook",
    "name": "Wailing Infernal SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      379,
      380,
      381
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Deals Pure Damage based on the hero's MAX HP to the target, regains a certain proportion of the MAX HP.\n\n- Initial Cooldown(turn): 1 \n- Cooldown(turns): 4\n- Accuracy(%): 90\n- RT conversion (%SPD): 50\n- Cast Range: 1\n- Use count limit: 2\n- HP regained(%): 15\n- Pure Damage(%): 20",
      "elementalBonus": [],
      "bodyPartBonus": [
        "+ 0.1% Pure Damage per Uncommon part.\r",
        "+ 0.2% Pure Damage per Rare part.\r",
        "+ 1% Pure Damage per Epic part.\r",
        "+ 5% Pure Damage per Legendary part.\r",
        "+ 10% Pure Damage per Mythical part."
      ]
    }
  },
  "382": {
    "id": 382,
    "displayName": "Blanca",
    "name": "Blanca Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 7,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 20000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 5,
    "nextTierId": 383,
    "tierItemIds": [
      382,
      383,
      384
    ],
    "animType": null
  },
  "383": {
    "id": 383,
    "displayName": "Mighty Blanca",
    "name": "Blanca Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 7,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 384,
    "tierItemIds": [
      382,
      383,
      384
    ],
    "animType": null
  },
  "384": {
    "id": 384,
    "displayName": "Superior Blanca",
    "name": "Blanca Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 7,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 0,
      "Critical": 1,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      382,
      383,
      384
    ],
    "animType": null
  },
  "385": {
    "id": 385,
    "displayName": "Mighty Otis Axe",
    "name": "Otis Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 20,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 386,
    "tierItemIds": [
      114,
      385,
      386
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "386": {
    "id": 386,
    "displayName": "Superior Otis Axe",
    "name": "Otis Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 28,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      114,
      385,
      386
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "387": {
    "id": 387,
    "displayName": "Mighty Erica Bow",
    "name": "Erica Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 25,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 2,
      "HP": 27,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 388,
    "tierItemIds": [
      115,
      387,
      388
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "388": {
    "id": 388,
    "displayName": "Superior Erica Bow",
    "name": "Erica Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 4,
      "AGI": 8,
      "INT": 0,
      "LUK": 3,
      "HP": 38,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      115,
      387,
      388
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "389": {
    "id": 389,
    "displayName": "Mighty Heulwen Sword",
    "name": "Heulwen Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 20,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 4,
      "AGI": 1,
      "INT": 0,
      "LUK": 5,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 390,
    "tierItemIds": [
      116,
      389,
      390
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "390": {
    "id": 390,
    "displayName": "Superior Heulwen Sword",
    "name": "Heulwen Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 29,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 6,
      "AGI": 2,
      "INT": 0,
      "LUK": 8,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      116,
      389,
      390
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "391": {
    "id": 391,
    "displayName": "Mighty Jocasta Knife",
    "name": "Jocasta Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 21,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 4,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 2,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 392,
    "tierItemIds": [
      117,
      391,
      392
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "392": {
    "id": 392,
    "displayName": "Superior Jocasta Knife",
    "name": "Jocasta Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 0,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 30,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 6,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 4,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      117,
      391,
      392
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "393": {
    "id": 393,
    "displayName": "Mighty Jocelyn Knife",
    "name": "Jocelyn Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 27,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 3,
      "AGI": 2,
      "INT": 3,
      "LUK": 4,
      "HP": 37,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 394,
    "tierItemIds": [
      118,
      393,
      394
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "394": {
    "id": 394,
    "displayName": "Superior Jocelyn Knife",
    "name": "Jocelyn Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 39,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 14,
      "STR": 5,
      "AGI": 3,
      "INT": 5,
      "LUK": 6,
      "HP": 53,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      118,
      393,
      394
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "395": {
    "id": 395,
    "displayName": "Mighty Helga Sword",
    "name": "Helga Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 26,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 3,
      "AGI": 2,
      "INT": 1,
      "LUK": 3,
      "HP": 41,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 396,
    "tierItemIds": [
      119,
      395,
      396
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "396": {
    "id": 396,
    "displayName": "Superior Helga Sword",
    "name": "Helga Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 33,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 5,
      "AGI": 3,
      "INT": 2,
      "LUK": 5,
      "HP": 58,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      119,
      395,
      396
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "397": {
    "id": 397,
    "displayName": "Mighty Elysia Bow",
    "name": "Elysia Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 34,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 4,
      "AGI": 5,
      "INT": 0,
      "LUK": 10,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 398,
    "tierItemIds": [
      120,
      397,
      398
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "398": {
    "id": 398,
    "displayName": "Superior Elysia Bow",
    "name": "Elysia Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 48,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 6,
      "AGI": 6,
      "INT": 0,
      "LUK": 14,
      "HP": 64,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      120,
      397,
      398
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "399": {
    "id": 399,
    "displayName": "Mighty Orborne Axe",
    "name": "Orborne Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 36,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 4,
      "AGI": 0,
      "INT": 0,
      "LUK": 10,
      "HP": 42,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 400,
    "tierItemIds": [
      121,
      399,
      400
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "400": {
    "id": 400,
    "displayName": "Superior Orborne Axe",
    "name": "Orborne Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 51,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 6,
      "AGI": 0,
      "INT": 0,
      "LUK": 14,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      121,
      399,
      400
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "401": {
    "id": 401,
    "displayName": "Mighty Joyce Knife",
    "name": "Joyce Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 41,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 5,
      "AGI": 5,
      "INT": 0,
      "LUK": 11,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 402,
    "tierItemIds": [
      122,
      401,
      402
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "402": {
    "id": 402,
    "displayName": "Superior Joyce Knife",
    "name": "Joyce Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 58,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 8,
      "AGI": 8,
      "INT": 0,
      "LUK": 16,
      "HP": 64,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      122,
      401,
      402
    ],
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "403": {
    "id": 403,
    "displayName": "Mighty Hypatia Sword",
    "name": "Hypatia Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 36,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 7,
      "AGI": 0,
      "INT": 0,
      "LUK": 9,
      "HP": 44,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 404,
    "tierItemIds": [
      123,
      403,
      404
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "404": {
    "id": 404,
    "displayName": "Superior Hypatia Sword",
    "name": "Hypatia Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 51,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 9,
      "AGI": 0,
      "INT": 0,
      "LUK": 13,
      "HP": 62,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      123,
      403,
      404
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "405": {
    "id": 405,
    "displayName": "Mighty Elfreda Bow",
    "name": "Elfreda Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 47,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 7,
      "AGI": 9,
      "INT": 0,
      "LUK": 9,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 406,
    "tierItemIds": [
      124,
      405,
      406
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "406": {
    "id": 406,
    "displayName": "Superior Elfreda Bow",
    "name": "Elfreda Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 66,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 16,
      "STR": 10,
      "AGI": 11,
      "INT": 0,
      "LUK": 13,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      124,
      405,
      406
    ],
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "407": {
    "id": 407,
    "displayName": "Mighty Olwen Axe",
    "name": "Olwen Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 49,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 9,
      "STR": 8,
      "AGI": 5,
      "INT": 0,
      "LUK": 7,
      "HP": 56,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 408,
    "tierItemIds": [
      125,
      407,
      408
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "408": {
    "id": 408,
    "displayName": "Superior Olwen Axe",
    "name": "Olwen Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 69,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 13,
      "STR": 11,
      "AGI": 8,
      "INT": 0,
      "LUK": 10,
      "HP": 79,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      125,
      407,
      408
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "409": {
    "id": 409,
    "displayName": "Mighty Eunice Axe",
    "name": "Eunice Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 38,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 7,
      "AGI": 7,
      "INT": 0,
      "LUK": 9,
      "HP": 48,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 410,
    "tierItemIds": [
      126,
      409,
      410
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "410": {
    "id": 410,
    "displayName": "Superior Eunice Axe",
    "name": "Eunice Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 52,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 14,
      "STR": 10,
      "AGI": 10,
      "INT": 0,
      "LUK": 11,
      "HP": 68,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      126,
      409,
      410
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "411": {
    "id": 411,
    "displayName": "Mighty Arian Sword",
    "name": "Arian Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 36,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 7,
      "AGI": 0,
      "INT": 0,
      "LUK": 8,
      "HP": 39,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 412,
    "tierItemIds": [
      127,
      411,
      412
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "412": {
    "id": 412,
    "displayName": "Superior Arian Sword",
    "name": "Arian Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 49,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 14,
      "STR": 9,
      "AGI": 0,
      "INT": 0,
      "LUK": 12,
      "HP": 55,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      127,
      411,
      412
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "413": {
    "id": 413,
    "displayName": "Mighty Edana Axe",
    "name": "Edana Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 41,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 7,
      "AGI": 1,
      "INT": 0,
      "LUK": 8,
      "HP": 49,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 414,
    "tierItemIds": [
      128,
      413,
      414
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "414": {
    "id": 414,
    "displayName": "Superior Edana Axe",
    "name": "Edana Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 53,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 10,
      "AGI": 2,
      "INT": 0,
      "LUK": 12,
      "HP": 69,
      "Critical": 8,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      128,
      413,
      414
    ],
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "415": {
    "id": 415,
    "displayName": "Mighty Ciara Sword",
    "name": "Ciara Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 42,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 416,
    "tierItemIds": [
      129,
      415,
      416
    ],
    "setId": 8,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "416": {
    "id": 416,
    "displayName": "Superior Ciara Sword",
    "name": "Ciara Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 60,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 12% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      129,
      415,
      416
    ],
    "setId": 8,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "417": {
    "id": 417,
    "displayName": "Mighty Ciara Armor",
    "name": "Ciara Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 86,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 418,
    "tierItemIds": [
      130,
      417,
      418
    ],
    "setId": 8,
    "animType": null
  },
  "418": {
    "id": 418,
    "displayName": "Superior Ciara Armor",
    "name": "Ciara Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 20,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 121,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 12% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      130,
      417,
      418
    ],
    "setId": 8,
    "animType": null
  },
  "419": {
    "id": 419,
    "displayName": "Mighty Ciara Bracelets",
    "name": "Ciara Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 11,
      "Critical Damage": 4,
      "STR": 6,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 69,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 420,
    "tierItemIds": [
      131,
      419,
      420
    ],
    "setId": 8,
    "animType": null
  },
  "420": {
    "id": 420,
    "displayName": "Superior Ciara Bracelets",
    "name": "Ciara Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 16,
      "Critical Damage": 6,
      "STR": 9,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 97,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 12% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      131,
      419,
      420
    ],
    "setId": 8,
    "animType": null
  },
  "421": {
    "id": 421,
    "displayName": "Mighty Ciara Boots",
    "name": "Ciara Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 68,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 422,
    "tierItemIds": [
      132,
      421,
      422
    ],
    "setId": 8,
    "animType": null
  },
  "422": {
    "id": 422,
    "displayName": "Superior Ciara Boots",
    "name": "Ciara Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 9,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 96,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 12% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      132,
      421,
      422
    ],
    "setId": 8,
    "animType": null
  },
  "423": {
    "id": 423,
    "displayName": "Mighty Ciara Pants",
    "name": "Ciara Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 424,
    "tierItemIds": [
      133,
      423,
      424
    ],
    "setId": 8,
    "animType": null
  },
  "424": {
    "id": 424,
    "displayName": "Superior Ciara Pants",
    "name": "Ciara Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 9,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 97,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 12% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      133,
      423,
      424
    ],
    "setId": 8,
    "animType": null
  },
  "425": {
    "id": 425,
    "displayName": "Mighty Aretha Spear",
    "name": "Aretha Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 70,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 20,
      "STR": 15,
      "AGI": 10,
      "INT": 0,
      "LUK": 6,
      "HP": 83,
      "Critical": 12,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Bash",
          "description": "Attacks have a 25% chance of Stunning the enemy for 1 turn(s)."
        },
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 426,
    "tierItemIds": [
      134,
      425,
      426
    ],
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "426": {
    "id": 426,
    "displayName": "Superior Aretha Spear",
    "name": "Aretha Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 90,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 28,
      "STR": 21,
      "AGI": 13,
      "INT": 0,
      "LUK": 9,
      "HP": 117,
      "Critical": 15,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Bash",
          "description": "Attacks have a 30% chance of Stunning the enemy for 1 turn(s)."
        },
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      134,
      425,
      426
    ],
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "427": {
    "id": 427,
    "displayName": "Mighty Aliyah Sword",
    "name": "Aliyah Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 47,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 20,
      "STR": 10,
      "AGI": 10,
      "INT": 10,
      "LUK": 9,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Devour",
          "description": "Heals 100 HP per unit killed."
        },
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 428,
    "tierItemIds": [
      135,
      427,
      428
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "428": {
    "id": 428,
    "displayName": "Superior Aliyah Sword",
    "name": "Aliyah Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 66,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 29,
      "STR": 15,
      "AGI": 13,
      "INT": 12,
      "LUK": 13,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Devour",
          "description": "Heals 200 HP per unit killed."
        },
        {
          "name": "Binance aura",
          "description": "Increases 10% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      135,
      427,
      428
    ],
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "429": {
    "id": 429,
    "displayName": "Biswap Shield",
    "name": "Biswap Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 5000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 1,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 556,
    "tierItemIds": [
      429,
      556,
      557
    ],
    "setId": 20,
    "animType": "shield",
    "damageType": "Physical"
  },
  "430": {
    "id": 430,
    "displayName": "Biswap Sword",
    "name": "Biswap Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 5000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 19,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 29,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 558,
    "tierItemIds": [
      430,
      558,
      559
    ],
    "setId": 20,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "431": {
    "id": 431,
    "displayName": "Legion Shield",
    "name": "Legion Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 1,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 5000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 2,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 432,
    "tierItemIds": [
      431,
      432,
      433
    ],
    "animType": "shield",
    "damageType": "Physical"
  },
  "432": {
    "id": 432,
    "displayName": "Mighty Legion Shield",
    "name": "Legion Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 1,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 2,
      "INT": 3,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 433,
    "tierItemIds": [
      431,
      432,
      433
    ],
    "animType": "shield",
    "damageType": "Physical"
  },
  "433": {
    "id": 433,
    "displayName": "Superior Legion Shield",
    "name": "Legion Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 1,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 3,
      "INT": 5,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 4% Physical Damage & 2% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      431,
      432,
      433
    ],
    "animType": "shield",
    "damageType": "Physical"
  },
  "434": {
    "id": 434,
    "displayName": "Dryakko Mask",
    "name": "Dryakko Mask Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 5000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 27,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 435,
    "tierItemIds": [
      434,
      435,
      436
    ],
    "animType": null
  },
  "435": {
    "id": 435,
    "displayName": "Mighty Dryakko Mask",
    "name": "Dryakko Mask Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 5,
      "LUK": 0,
      "HP": 39,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 436,
    "tierItemIds": [
      434,
      435,
      436
    ],
    "animType": null
  },
  "436": {
    "id": 436,
    "displayName": "Superior Dryakko Mask",
    "name": "Dryakko Mask Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 3,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 2,
      "INT": 8,
      "LUK": 0,
      "HP": 55,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      434,
      435,
      436
    ],
    "animType": null
  },
  "437": {
    "id": 437,
    "displayName": "Astaman Whirlbangle",
    "name": "Astaman Whirlbangle Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 5000,
    "tier": 1,
    "stats": {
      "Magical Damage": 5,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 18,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 438,
    "tierItemIds": [
      437,
      438,
      439
    ],
    "animType": null
  },
  "438": {
    "id": 438,
    "displayName": "Mighty Astaman Whirlbangle",
    "name": "Astaman Whirlbangle Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 26,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 439,
    "tierItemIds": [
      437,
      438,
      439
    ],
    "animType": null
  },
  "439": {
    "id": 439,
    "displayName": "Superior Astaman Whirlbangle",
    "name": "Astaman Whirlbangle Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 12,
      "LUK": 0,
      "HP": 37,
      "Critical": 0,
      "Evade": 3,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      437,
      438,
      439
    ],
    "animType": null
  },
  "440": {
    "id": 440,
    "displayName": "Shield of Armageddon",
    "name": "Shield Of Armageddon Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 15,
    "requiredStats": [],
    "maxSupply": 5000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 2,
      "Critical Damage": 7,
      "STR": 2,
      "AGI": 1,
      "INT": 5,
      "LUK": 0,
      "HP": 38,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 441,
    "tierItemIds": [
      440,
      441,
      442
    ],
    "animType": "shield",
    "damageType": "Physical"
  },
  "441": {
    "id": 441,
    "displayName": "Mighty Shield of Armageddon",
    "name": "Shield Of Armageddon Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 15,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 4,
      "Critical Damage": 10,
      "STR": 4,
      "AGI": 2,
      "INT": 8,
      "LUK": 0,
      "HP": 54,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 442,
    "tierItemIds": [
      440,
      441,
      442
    ],
    "animType": "shield",
    "damageType": "Physical"
  },
  "442": {
    "id": 442,
    "displayName": "Superior Shield of Armageddon",
    "name": "Shield Of Armageddon Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 15,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 6,
      "Critical Damage": 15,
      "STR": 6,
      "AGI": 3,
      "INT": 12,
      "LUK": 0,
      "HP": 76,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 10% Physical Damage & 5% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      440,
      441,
      442
    ],
    "animType": "shield",
    "damageType": "Physical"
  },
  "443": {
    "id": 443,
    "displayName": "Crown of Sovereign",
    "name": "Crown Of Sovereign Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 9,
    "requiredStats": [],
    "maxSupply": 5000,
    "tier": 1,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 3,
      "Critical Damage": 10,
      "STR": 2,
      "AGI": 0,
      "INT": 4,
      "LUK": 3,
      "HP": 31,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 444,
    "tierItemIds": [
      443,
      444,
      445
    ],
    "animType": null
  },
  "444": {
    "id": 444,
    "displayName": "Mighty Crown of Sovereign",
    "name": "Crown Of Sovereign Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 9,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 5,
      "Critical Damage": 14,
      "STR": 4,
      "AGI": 0,
      "INT": 6,
      "LUK": 5,
      "HP": 44,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 445,
    "tierItemIds": [
      443,
      444,
      445
    ],
    "animType": null
  },
  "445": {
    "id": 445,
    "displayName": "Superior Crown of Sovereign",
    "name": "Crown Of Sovereign Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 9,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 8,
      "Critical Damage": 20,
      "STR": 6,
      "AGI": 0,
      "INT": 9,
      "LUK": 8,
      "HP": 62,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      443,
      444,
      445
    ],
    "animType": null
  },
  "446": {
    "id": 446,
    "displayName": "Hercules Armilla",
    "name": "Hercules Armilla Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 11,
    "requiredStats": [],
    "maxSupply": 5000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 7,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 8,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 3,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 447,
    "tierItemIds": [
      446,
      447,
      448
    ],
    "animType": null
  },
  "447": {
    "id": 447,
    "displayName": "Mighty Hercules Armilla",
    "name": "Hercules Armilla Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 11,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 10,
      "Magical Defense": 7,
      "Physical Defense": 12,
      "Critical Damage": 12,
      "STR": 5,
      "AGI": 2,
      "INT": 0,
      "LUK": 5,
      "HP": 50,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 448,
    "tierItemIds": [
      446,
      447,
      448
    ],
    "animType": null
  },
  "448": {
    "id": 448,
    "displayName": "Superior Hercules Armilla",
    "name": "Hercules Armilla Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 11,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 15,
      "Magical Defense": 10,
      "Physical Defense": 17,
      "Critical Damage": 18,
      "STR": 7,
      "AGI": 3,
      "INT": 0,
      "LUK": 7,
      "HP": 71,
      "Critical": 2,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      446,
      447,
      448
    ],
    "animType": null
  },
  "449": {
    "id": 449,
    "displayName": "Emblem of Gusty",
    "name": "Emblem Of Gusty Medal",
    "races": [
      "Fairy",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 8,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 50,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Wild Boar Summoning",
          "description": "Summons a Lv{5} Wild Boar to the battlefield."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      449
    ],
    "animType": null
  },
  "450": {
    "id": 450,
    "displayName": "Emblem of Kaiser",
    "name": "Emblem Of Kaiser Medal",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 8,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 10,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 1,
      "INT": 1,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Mad Tiger Summoning",
          "description": "Summons a Lv10 Mad Tiger to the battlefield."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      450
    ],
    "animType": null
  },
  "451": {
    "id": 451,
    "displayName": "Hegemon Princess Fist",
    "name": "Hegemon Princess Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 31,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 452,
    "tierItemIds": [
      451,
      452,
      453
    ],
    "setId": 21,
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "452": {
    "id": 452,
    "displayName": "Mighty Hegemon Princess Fist",
    "name": "Hegemon Princess Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 44,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 453,
    "tierItemIds": [
      451,
      452,
      453
    ],
    "setId": 21,
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "453": {
    "id": 453,
    "displayName": "Superior Hegemon Princess Fist",
    "name": "Hegemon Princess Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 62,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      451,
      452,
      453
    ],
    "setId": 21,
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "454": {
    "id": 454,
    "displayName": "Hegemon Princess Fist",
    "name": "Hegemon Princess Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 44,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Magic Strike",
          "description": "Attacks have a 30% chance of dealing extra Magical Damage depending on the target's Magical Defense."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 455,
    "tierItemIds": [
      454,
      455,
      456
    ],
    "setId": 21,
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "455": {
    "id": 455,
    "displayName": "Mighty Hegemon Princess Fist",
    "name": "Hegemon Princess Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 62,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Magic Strike",
          "description": "Attacks have a 44% chance of dealing extra Magical Damage depending on the target's Magical Defense."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 456,
    "tierItemIds": [
      454,
      455,
      456
    ],
    "setId": 21,
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "456": {
    "id": 456,
    "displayName": "Superior Hegemon Princess Fist",
    "name": "Hegemon Princess Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 87,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Magic Strike",
          "description": "Attacks have a 64% chance of dealing extra Magical Damage depending on the target's Magical Defense."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      454,
      455,
      456
    ],
    "setId": 21,
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "457": {
    "id": 457,
    "displayName": "Hegemon Princess Fist",
    "name": "Hegemon Princess Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 62,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": [
        {
          "name": "Magic Strike",
          "description": "Attacks have a 40% chance of dealing extra Magical Damage depending on the target's Magical Defense."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 458,
    "tierItemIds": [
      457,
      458,
      459
    ],
    "setId": 21,
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "458": {
    "id": 458,
    "displayName": "Mighty Hegemon Princess Fist",
    "name": "Hegemon Princess Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 87,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 12,
      "effects": [
        {
          "name": "Magic Strike",
          "description": "Attacks have a 58% chance of dealing extra Magical Damage depending on the target's Magical Defense."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 459,
    "tierItemIds": [
      457,
      458,
      459
    ],
    "setId": 21,
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "459": {
    "id": 459,
    "displayName": "Superior Hegemon Princess Fist",
    "name": "Hegemon Princess Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 122,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 15,
      "effects": [
        {
          "name": "Magic Strike",
          "description": "Attacks have a 84% chance of dealing extra Magical Damage depending on the target's Magical Defense."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      457,
      458,
      459
    ],
    "setId": 21,
    "animType": "fist",
    "damageType": "Magical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "460": {
    "id": 460,
    "displayName": "Hegemon Princess Gloves",
    "name": "Hegemon Princess Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 5,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 461,
    "tierItemIds": [
      460,
      461,
      462
    ],
    "setId": 21,
    "animType": null
  },
  "461": {
    "id": 461,
    "displayName": "Mighty Hegemon Princess Gloves",
    "name": "Hegemon Princess Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 1,
      "HP": 97,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 462,
    "tierItemIds": [
      460,
      461,
      462
    ],
    "setId": 21,
    "animType": null
  },
  "462": {
    "id": 462,
    "displayName": "Superior Hegemon Princess Gloves",
    "name": "Hegemon Princess Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 2,
      "HP": 136,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      460,
      461,
      462
    ],
    "setId": 21,
    "animType": null
  },
  "463": {
    "id": 463,
    "displayName": "Hegemon Princess Gloves",
    "name": "Hegemon Princess Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 1,
      "HP": 108,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 464,
    "tierItemIds": [
      463,
      464,
      465
    ],
    "setId": 21,
    "animType": null
  },
  "464": {
    "id": 464,
    "displayName": "Mighty Hegemon Princess Gloves",
    "name": "Hegemon Princess Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 2,
      "HP": 152,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 465,
    "tierItemIds": [
      463,
      464,
      465
    ],
    "setId": 21,
    "animType": null
  },
  "465": {
    "id": 465,
    "displayName": "Superior Hegemon Princess Gloves",
    "name": "Hegemon Princess Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 5,
      "LUK": 4,
      "HP": 213,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      463,
      464,
      465
    ],
    "setId": 21,
    "animType": null
  },
  "466": {
    "id": 466,
    "displayName": "Hegemon Princess Gloves",
    "name": "Hegemon Princess Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 2,
      "HP": 152,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Magic Strike",
          "description": "Attacks have a 30% chance of dealing extra Magical Damage depending on the target's Magical Defense."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 467,
    "tierItemIds": [
      466,
      467,
      468
    ],
    "setId": 21,
    "animType": null
  },
  "467": {
    "id": 467,
    "displayName": "Mighty Hegemon Princess Gloves",
    "name": "Hegemon Princess Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 5,
      "LUK": 4,
      "HP": 213,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Magic Strike",
          "description": "Attacks have a 44% chance of dealing extra Magical Damage depending on the target's Magical Defense."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 468,
    "tierItemIds": [
      466,
      467,
      468
    ],
    "setId": 21,
    "animType": null
  },
  "468": {
    "id": 468,
    "displayName": "Superior Hegemon Princess Gloves",
    "name": "Hegemon Princess Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 15,
      "AGI": 0,
      "INT": 8,
      "LUK": 6,
      "HP": 299,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Magic Strike",
          "description": "Attacks have a 64% chance of dealing extra Magical Damage depending on the target's Magical Defense."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      466,
      467,
      468
    ],
    "setId": 21,
    "animType": null
  },
  "469": {
    "id": 469,
    "displayName": "Hegemon Princess Crown",
    "name": "Hegemon Princess Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 7,
      "AGI": 0,
      "INT": 5,
      "LUK": 3,
      "HP": 152,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 25% chance of absorbing 20 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 470,
    "tierItemIds": [
      469,
      470,
      471
    ],
    "setId": 21,
    "animType": null
  },
  "470": {
    "id": 470,
    "displayName": "Mighty Hegemon Princess Crown",
    "name": "Hegemon Princess Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 1,
      "Critical Damage": 11,
      "STR": 10,
      "AGI": 0,
      "INT": 7,
      "LUK": 5,
      "HP": 213,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 25% chance of absorbing 30 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 471,
    "tierItemIds": [
      469,
      470,
      471
    ],
    "setId": 21,
    "animType": null
  },
  "471": {
    "id": 471,
    "displayName": "Superior Hegemon Princess Crown",
    "name": "Hegemon Princess Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 2,
      "Critical Damage": 16,
      "STR": 15,
      "AGI": 0,
      "INT": 10,
      "LUK": 8,
      "HP": 299,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 25% chance of absorbing 40 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      469,
      470,
      471
    ],
    "setId": 21,
    "animType": null
  },
  "472": {
    "id": 472,
    "displayName": "Hegemon Princess Wings",
    "name": "Hegemon Princess Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 473,
    "tierItemIds": [
      472,
      473,
      474
    ],
    "setId": 21,
    "animType": null
  },
  "473": {
    "id": 473,
    "displayName": "Mighty Hegemon Princess Wings",
    "name": "Hegemon Princess Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 122,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 474,
    "tierItemIds": [
      472,
      473,
      474
    ],
    "setId": 21,
    "animType": null
  },
  "474": {
    "id": 474,
    "displayName": "Superior Hegemon Princess Wings",
    "name": "Hegemon Princess Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 14,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 171,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      472,
      473,
      474
    ],
    "setId": 21,
    "animType": null
  },
  "475": {
    "id": 475,
    "displayName": "Hegemon Princess Wings",
    "name": "Hegemon Princess Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 135,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 25% chance of absorbing 20 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 476,
    "tierItemIds": [
      475,
      476,
      477
    ],
    "setId": 21,
    "animType": null
  },
  "476": {
    "id": 476,
    "displayName": "Mighty Hegemon Princess Wings",
    "name": "Hegemon Princess Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 14,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 190,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 25% chance of absorbing 30 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 477,
    "tierItemIds": [
      475,
      476,
      477
    ],
    "setId": 21,
    "animType": null
  },
  "477": {
    "id": 477,
    "displayName": "Superior Hegemon Princess Wings",
    "name": "Hegemon Princess Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 20,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 267,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 25% chance of absorbing 40 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      475,
      476,
      477
    ],
    "setId": 21,
    "animType": null
  },
  "478": {
    "id": 478,
    "displayName": "Hegemon Princess Wings",
    "name": "Hegemon Princess Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 4,
    "type": 3,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 14,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 190,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 25% chance of absorbing 60 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 479,
    "tierItemIds": [
      478,
      479,
      480
    ],
    "setId": 21,
    "animType": null
  },
  "479": {
    "id": 479,
    "displayName": "Mighty Hegemon Princess Wings",
    "name": "Hegemon Princess Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 4,
    "type": 3,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 20,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 267,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 25% chance of absorbing 80 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 480,
    "tierItemIds": [
      478,
      479,
      480
    ],
    "setId": 21,
    "animType": null
  },
  "480": {
    "id": 480,
    "displayName": "Superior Hegemon Princess Wings",
    "name": "Hegemon Princess Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 4,
    "type": 3,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 28,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 18,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 374,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 25% chance of absorbing 100 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      478,
      479,
      480
    ],
    "setId": 21,
    "animType": null
  },
  "481": {
    "id": 481,
    "displayName": "Hegemon Princess Shoes",
    "name": "Hegemon Princess Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 33,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 482,
    "tierItemIds": [
      481,
      482,
      483
    ],
    "setId": 21,
    "animType": null
  },
  "482": {
    "id": 482,
    "displayName": "Mighty Hegemon Princess Shoes",
    "name": "Hegemon Princess Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 483,
    "tierItemIds": [
      481,
      482,
      483
    ],
    "setId": 21,
    "animType": null
  },
  "483": {
    "id": 483,
    "displayName": "Superior Hegemon Princess Shoes",
    "name": "Hegemon Princess Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 1,
      "LUK": 0,
      "HP": 67,
      "Critical": 0,
      "Evade": 3,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      481,
      482,
      483
    ],
    "setId": 21,
    "animType": null
  },
  "484": {
    "id": 484,
    "displayName": "Hegemon Princess Shoes",
    "name": "Hegemon Princess Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 53,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 485,
    "tierItemIds": [
      484,
      485,
      486
    ],
    "setId": 21,
    "animType": null
  },
  "485": {
    "id": 485,
    "displayName": "Mighty Hegemon Princess Shoes",
    "name": "Hegemon Princess Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 1,
      "LUK": 0,
      "HP": 75,
      "Critical": 0,
      "Evade": 3,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 486,
    "tierItemIds": [
      484,
      485,
      486
    ],
    "setId": 21,
    "animType": null
  },
  "486": {
    "id": 486,
    "displayName": "Superior Hegemon Princess Shoes",
    "name": "Hegemon Princess Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 2,
      "LUK": 0,
      "HP": 106,
      "Critical": 0,
      "Evade": 5,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      484,
      485,
      486
    ],
    "setId": 21,
    "animType": null
  },
  "487": {
    "id": 487,
    "displayName": "Hegemon Princess Shoes",
    "name": "Hegemon Princess Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 1,
      "LUK": 0,
      "HP": 75,
      "Critical": 0,
      "Evade": 3,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 488,
    "tierItemIds": [
      487,
      488,
      489
    ],
    "setId": 21,
    "animType": null
  },
  "488": {
    "id": 488,
    "displayName": "Mighty Hegemon Princess Shoes",
    "name": "Hegemon Princess Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 2,
      "LUK": 0,
      "HP": 106,
      "Critical": 0,
      "Evade": 5,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 489,
    "tierItemIds": [
      487,
      488,
      489
    ],
    "setId": 21,
    "animType": null
  },
  "489": {
    "id": 489,
    "displayName": "Superior Hegemon Princess Shoes",
    "name": "Hegemon Princess Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 11,
      "INT": 4,
      "LUK": 0,
      "HP": 149,
      "Critical": 0,
      "Evade": 7,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      487,
      488,
      489
    ],
    "setId": 21,
    "animType": null
  },
  "490": {
    "id": 490,
    "displayName": "Hegemon Princess Pants",
    "name": "Hegemon Princess Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 33,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 491,
    "tierItemIds": [
      490,
      491,
      492
    ],
    "setId": 21,
    "animType": null
  },
  "491": {
    "id": 491,
    "displayName": "Mighty Hegemon Princess Pants",
    "name": "Hegemon Princess Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 3,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 492,
    "tierItemIds": [
      490,
      491,
      492
    ],
    "setId": 21,
    "animType": null
  },
  "492": {
    "id": 492,
    "displayName": "Superior Hegemon Princess Pants",
    "name": "Hegemon Princess Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 4,
      "INT": 1,
      "LUK": 0,
      "HP": 67,
      "Critical": 0,
      "Evade": 5,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      490,
      491,
      492
    ],
    "setId": 21,
    "animType": null
  },
  "493": {
    "id": 493,
    "displayName": "Hegemon Princess Pants",
    "name": "Hegemon Princess Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 53,
      "Critical": 0,
      "Evade": 3,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 494,
    "tierItemIds": [
      493,
      494,
      495
    ],
    "setId": 21,
    "animType": null
  },
  "494": {
    "id": 494,
    "displayName": "Mighty Hegemon Princess Pants",
    "name": "Hegemon Princess Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 4,
      "INT": 1,
      "LUK": 0,
      "HP": 75,
      "Critical": 0,
      "Evade": 5,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 495,
    "tierItemIds": [
      493,
      494,
      495
    ],
    "setId": 21,
    "animType": null
  },
  "495": {
    "id": 495,
    "displayName": "Superior Hegemon Princess Pants",
    "name": "Hegemon Princess Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 6,
      "INT": 2,
      "LUK": 0,
      "HP": 106,
      "Critical": 0,
      "Evade": 7,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      493,
      494,
      495
    ],
    "setId": 21,
    "animType": null
  },
  "496": {
    "id": 496,
    "displayName": "Hegemon Princess Pants",
    "name": "Hegemon Princess Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 5,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 4,
      "INT": 1,
      "LUK": 0,
      "HP": 75,
      "Critical": 0,
      "Evade": 5,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 4% Physical Damage & 2% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 497,
    "tierItemIds": [
      496,
      497,
      498
    ],
    "setId": 21,
    "animType": null
  },
  "497": {
    "id": 497,
    "displayName": "Mighty Hegemon Princess Pants",
    "name": "Hegemon Princess Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 5,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 6,
      "INT": 2,
      "LUK": 0,
      "HP": 106,
      "Critical": 0,
      "Evade": 7,
      "Speed": 1,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 6% Physical Damage & 4% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 498,
    "tierItemIds": [
      496,
      497,
      498
    ],
    "setId": 21,
    "animType": null
  },
  "498": {
    "id": 498,
    "displayName": "Superior Hegemon Princess Pants",
    "name": "Hegemon Princess Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 5,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 9,
      "INT": 4,
      "LUK": 0,
      "HP": 149,
      "Critical": 0,
      "Evade": 10,
      "Speed": 2,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 9% Physical Damage & 6% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      496,
      497,
      498
    ],
    "setId": 21,
    "animType": null
  },
  "499": {
    "id": 499,
    "displayName": "Fretful Devastator Blade",
    "name": "Fretful Devastator Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 7,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 500,
    "tierItemIds": [
      499,
      500,
      501
    ],
    "setId": 22,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "500": {
    "id": 500,
    "displayName": "Mighty Fretful Devastator Blade",
    "name": "Fretful Devastator Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 11,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 501,
    "tierItemIds": [
      499,
      500,
      501
    ],
    "setId": 22,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "501": {
    "id": 501,
    "displayName": "Superior Fretful Devastator Blade",
    "name": "Fretful Devastator Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 16,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 0,
      "AGI": 14,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      499,
      500,
      501
    ],
    "setId": 22,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "502": {
    "id": 502,
    "displayName": "Fretful Devastator Blade",
    "name": "Fretful Devastator Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 11,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 35 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 503,
    "tierItemIds": [
      502,
      503,
      504
    ],
    "setId": 22,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "503": {
    "id": 503,
    "displayName": "Mighty Fretful Devastator Blade",
    "name": "Fretful Devastator Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 16,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 0,
      "AGI": 14,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 46 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 504,
    "tierItemIds": [
      502,
      503,
      504
    ],
    "setId": 22,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "504": {
    "id": 504,
    "displayName": "Superior Fretful Devastator Blade",
    "name": "Fretful Devastator Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 23,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 22,
      "STR": 0,
      "AGI": 20,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 60 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      502,
      503,
      504
    ],
    "setId": 22,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "505": {
    "id": 505,
    "displayName": "Fretful Devastator Blade",
    "name": "Fretful Devastator Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 16,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 0,
      "AGI": 14,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 57 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 506,
    "tierItemIds": [
      505,
      506,
      507
    ],
    "setId": 22,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "506": {
    "id": 506,
    "displayName": "Mighty Fretful Devastator Blade",
    "name": "Fretful Devastator Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 23,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 22,
      "STR": 0,
      "AGI": 20,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 75 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 507,
    "tierItemIds": [
      505,
      506,
      507
    ],
    "setId": 22,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "507": {
    "id": 507,
    "displayName": "Superior Fretful Devastator Blade",
    "name": "Fretful Devastator Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 40,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 31,
      "STR": 0,
      "AGI": 28,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 98 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      505,
      506,
      507
    ],
    "setId": 22,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "508": {
    "id": 508,
    "displayName": "Fretful Devastator Sigil",
    "name": "Fretful Devastator Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 42,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Wind Cutter",
          "description": "Allows a hero to perform a 2-tile ranged normal attack when pairing this item with Fretful Devastator Blade. The attack deals Magical Damage equal to 70% Physical Damage."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 509,
    "tierItemIds": [
      508,
      509,
      510
    ],
    "setId": 22,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "509": {
    "id": 509,
    "displayName": "Mighty Fretful Devastator Sigil",
    "name": "Fretful Devastator Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 60,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Wind Cutter",
          "description": "Allows a hero to perform a 2-tile ranged normal attack when pairing this item with Fretful Devastator Blade. The attack deals Magical Damage equal to 80% Physical Damage."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 510,
    "tierItemIds": [
      508,
      509,
      510
    ],
    "setId": 22,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "510": {
    "id": 510,
    "displayName": "Superior Fretful Devastator Sigil",
    "name": "Fretful Devastator Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 85,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Wind Cutter",
          "description": "Allows a hero to perform a 2-tile ranged normal attack when pairing this item with Fretful Devastator Blade. The attack deals Magical Damage equal to 90% Physical Damage."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      508,
      509,
      510
    ],
    "setId": 22,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "511": {
    "id": 511,
    "displayName": "Fretful Devastator Sigil",
    "name": "Fretful Devastator Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 60,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Wind Cutter",
          "description": "Allows a hero to perform a 2-tile ranged normal attack when pairing this item with Fretful Devastator Blade. The attack deals Magical Damage equal to 85% Physical Damage."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 512,
    "tierItemIds": [
      511,
      512,
      513
    ],
    "setId": 22,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "512": {
    "id": 512,
    "displayName": "Mighty Fretful Devastator Sigil",
    "name": "Fretful Devastator Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 85,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Wind Cutter",
          "description": "Allows a hero to perform a 2-tile ranged normal attack when pairing this item with Fretful Devastator Blade. The attack deals Magical Damage equal to 95% Physical Damage."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 513,
    "tierItemIds": [
      511,
      512,
      513
    ],
    "setId": 22,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "513": {
    "id": 513,
    "displayName": "Superior Fretful Devastator Sigil",
    "name": "Fretful Devastator Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 120,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 8,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Wind Cutter",
          "description": "Allows a hero to perform a 2-tile ranged normal attack when pairing this item with Fretful Devastator Blade. The attack deals Magical Damage equal to 105% Physical Damage."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      511,
      512,
      513
    ],
    "setId": 22,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "514": {
    "id": 514,
    "displayName": "Fretful Devastator Sigil",
    "name": "Fretful Devastator Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 1,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 85,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Wind Cutter",
          "description": "Allows a hero to perform a 2-tile ranged normal attack when pairing this item with Fretful Devastator Blade. The attack deals Magical Damage equal to 110% Physical Damage."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 515,
    "tierItemIds": [
      514,
      515,
      516
    ],
    "setId": 22,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "515": {
    "id": 515,
    "displayName": "Mighty Fretful Devastator Sigil",
    "name": "Fretful Devastator Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 1,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 120,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 8,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Wind Cutter",
          "description": "Allows a hero to perform a 2-tile ranged normal attack when pairing this item with Fretful Devastator Blade. The attack deals Magical Damage equal to 120% Physical Damage."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 516,
    "tierItemIds": [
      514,
      515,
      516
    ],
    "setId": 22,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "516": {
    "id": 516,
    "displayName": "Superior Fretful Devastator Sigil",
    "name": "Fretful Devastator Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 1,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 168,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 12,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Wind Cutter",
          "description": "Allows a hero to perform a 2-tile ranged normal attack when pairing this item with Fretful Devastator Blade. The attack deals Magical Damage equal to 130% Physical Damage."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      514,
      515,
      516
    ],
    "setId": 22,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "517": {
    "id": 517,
    "displayName": "Fretful Devastator Arms",
    "name": "Fretful Devastator Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 518,
    "tierItemIds": [
      517,
      518,
      519
    ],
    "setId": 22,
    "animType": null
  },
  "518": {
    "id": 518,
    "displayName": "Mighty Fretful Devastator Arms",
    "name": "Fretful Devastator Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 57,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 519,
    "tierItemIds": [
      517,
      518,
      519
    ],
    "setId": 22,
    "animType": null
  },
  "519": {
    "id": 519,
    "displayName": "Superior Fretful Devastator Arms",
    "name": "Fretful Devastator Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 81,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      517,
      518,
      519
    ],
    "setId": 22,
    "animType": null
  },
  "520": {
    "id": 520,
    "displayName": "Fretful Devastator Arms",
    "name": "Fretful Devastator Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 64,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 521,
    "tierItemIds": [
      520,
      521,
      522
    ],
    "setId": 22,
    "animType": null
  },
  "521": {
    "id": 521,
    "displayName": "Mighty Fretful Devastator Arms",
    "name": "Fretful Devastator Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 90,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 522,
    "tierItemIds": [
      520,
      521,
      522
    ],
    "setId": 22,
    "animType": null
  },
  "522": {
    "id": 522,
    "displayName": "Superior Fretful Devastator Arms",
    "name": "Fretful Devastator Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 127,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      520,
      521,
      522
    ],
    "setId": 22,
    "animType": null
  },
  "523": {
    "id": 523,
    "displayName": "Fretful Devastator Arms",
    "name": "Fretful Devastator Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 90,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 4% Physical Damage & 4% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 524,
    "tierItemIds": [
      523,
      524,
      525
    ],
    "setId": 22,
    "animType": null
  },
  "524": {
    "id": 524,
    "displayName": "Mighty Fretful Devastator Arms",
    "name": "Fretful Devastator Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 127,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 6% Physical Damage & 6% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 525,
    "tierItemIds": [
      523,
      524,
      525
    ],
    "setId": 22,
    "animType": null
  },
  "525": {
    "id": 525,
    "displayName": "Superior Fretful Devastator Arms",
    "name": "Fretful Devastator Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 18,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 178,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 8% Physical Damage & 8% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      523,
      524,
      525
    ],
    "setId": 22,
    "animType": null
  },
  "526": {
    "id": 526,
    "displayName": "Fretful Devastator Mask",
    "name": "Fretful Devastator Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 527,
    "tierItemIds": [
      526,
      527,
      528
    ],
    "setId": 22,
    "animType": null
  },
  "527": {
    "id": 527,
    "displayName": "Mighty Fretful Devastator Mask",
    "name": "Fretful Devastator Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 528,
    "tierItemIds": [
      526,
      527,
      528
    ],
    "setId": 22,
    "animType": null
  },
  "528": {
    "id": 528,
    "displayName": "Superior Fretful Devastator Mask",
    "name": "Fretful Devastator Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 10,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 81,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      526,
      527,
      528
    ],
    "setId": 22,
    "animType": null
  },
  "529": {
    "id": 529,
    "displayName": "Fretful Devastator Mask",
    "name": "Fretful Devastator Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 64,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 530,
    "tierItemIds": [
      529,
      530,
      531
    ],
    "setId": 22,
    "animType": null
  },
  "530": {
    "id": 530,
    "displayName": "Mighty Fretful Devastator Mask",
    "name": "Fretful Devastator Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 10,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 90,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 531,
    "tierItemIds": [
      529,
      530,
      531
    ],
    "setId": 22,
    "animType": null
  },
  "531": {
    "id": 531,
    "displayName": "Superior Fretful Devastator Mask",
    "name": "Fretful Devastator Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 12,
      "Critical Damage": 15,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 127,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      529,
      530,
      531
    ],
    "setId": 22,
    "animType": null
  },
  "532": {
    "id": 532,
    "displayName": "Fretful Devastator Mask",
    "name": "Fretful Devastator Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 10,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 90,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 5% Physical Damage & 4% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 533,
    "tierItemIds": [
      532,
      533,
      534
    ],
    "setId": 22,
    "animType": null
  },
  "533": {
    "id": 533,
    "displayName": "Mighty Fretful Devastator Mask",
    "name": "Fretful Devastator Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 12,
      "Critical Damage": 15,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 127,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 7% Physical Damage & 6% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 534,
    "tierItemIds": [
      532,
      533,
      534
    ],
    "setId": 22,
    "animType": null
  },
  "534": {
    "id": 534,
    "displayName": "Superior Fretful Devastator Mask",
    "name": "Fretful Devastator Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 18,
      "Critical Damage": 22,
      "STR": 3,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 178,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 9% Physical Damage & 8% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      532,
      533,
      534
    ],
    "setId": 22,
    "animType": null
  },
  "535": {
    "id": 535,
    "displayName": "Fretful Devastator Robe",
    "name": "Fretful Devastator Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 536,
    "tierItemIds": [
      535,
      536,
      537
    ],
    "setId": 22,
    "animType": null
  },
  "536": {
    "id": 536,
    "displayName": "Mighty Fretful Devastator Robe",
    "name": "Fretful Devastator Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 537,
    "tierItemIds": [
      535,
      536,
      537
    ],
    "setId": 22,
    "animType": null
  },
  "537": {
    "id": 537,
    "displayName": "Superior Fretful Devastator Robe",
    "name": "Fretful Devastator Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      535,
      536,
      537
    ],
    "setId": 22,
    "animType": null
  },
  "538": {
    "id": 538,
    "displayName": "Fretful Devastator Robe",
    "name": "Fretful Devastator Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 539,
    "tierItemIds": [
      538,
      539,
      540
    ],
    "setId": 22,
    "animType": null
  },
  "539": {
    "id": 539,
    "displayName": "Mighty Fretful Devastator Robe",
    "name": "Fretful Devastator Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 540,
    "tierItemIds": [
      538,
      539,
      540
    ],
    "setId": 22,
    "animType": null
  },
  "540": {
    "id": 540,
    "displayName": "Superior Fretful Devastator Robe",
    "name": "Fretful Devastator Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 158,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      538,
      539,
      540
    ],
    "setId": 22,
    "animType": null
  },
  "541": {
    "id": 541,
    "displayName": "Fretful Devastator Robe",
    "name": "Fretful Devastator Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 6% Physical Damage & 5% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 542,
    "tierItemIds": [
      541,
      542,
      543
    ],
    "setId": 22,
    "animType": null
  },
  "542": {
    "id": 542,
    "displayName": "Mighty Fretful Devastator Robe",
    "name": "Fretful Devastator Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 158,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 8% Physical Damage & 7% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 543,
    "tierItemIds": [
      541,
      542,
      543
    ],
    "setId": 22,
    "animType": null
  },
  "543": {
    "id": 543,
    "displayName": "Superior Fretful Devastator Robe",
    "name": "Fretful Devastator Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 23,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 9,
      "LUK": 0,
      "HP": 222,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Vanguard Warrior",
          "description": "If this unit stands alone (no adjacent allies within 2 tile), reduces 10% Physical Damage & 9% Magical Damage taken."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      541,
      542,
      543
    ],
    "setId": 22,
    "animType": null
  },
  "544": {
    "id": 544,
    "displayName": "Fretful Devastator Boots",
    "name": "Fretful Devastator Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 545,
    "tierItemIds": [
      544,
      545,
      546
    ],
    "setId": 22,
    "animType": null
  },
  "545": {
    "id": 545,
    "displayName": "Mighty Fretful Devastator Boots",
    "name": "Fretful Devastator Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 3,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 546,
    "tierItemIds": [
      544,
      545,
      546
    ],
    "setId": 22,
    "animType": null
  },
  "546": {
    "id": 546,
    "displayName": "Superior Fretful Devastator Boots",
    "name": "Fretful Devastator Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 5,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      544,
      545,
      546
    ],
    "setId": 22,
    "animType": null
  },
  "547": {
    "id": 547,
    "displayName": "Fretful Devastator Boots",
    "name": "Fretful Devastator Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 3,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 548,
    "tierItemIds": [
      547,
      548,
      549
    ],
    "setId": 22,
    "animType": null
  },
  "548": {
    "id": 548,
    "displayName": "Mighty Fretful Devastator Boots",
    "name": "Fretful Devastator Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 5,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 549,
    "tierItemIds": [
      547,
      548,
      549
    ],
    "setId": 22,
    "animType": null
  },
  "549": {
    "id": 549,
    "displayName": "Superior Fretful Devastator Boots",
    "name": "Fretful Devastator Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 7,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      547,
      548,
      549
    ],
    "setId": 22,
    "animType": null
  },
  "550": {
    "id": 550,
    "displayName": "Fretful Devastator Boots",
    "name": "Fretful Devastator Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 5,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 551,
    "tierItemIds": [
      550,
      551,
      552
    ],
    "setId": 22,
    "animType": null
  },
  "551": {
    "id": 551,
    "displayName": "Mighty Fretful Devastator Boots",
    "name": "Fretful Devastator Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 7,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 552,
    "tierItemIds": [
      550,
      551,
      552
    ],
    "setId": 22,
    "animType": null
  },
  "552": {
    "id": 552,
    "displayName": "Superior Fretful Devastator Boots",
    "name": "Fretful Devastator Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 11,
      "INT": 0,
      "LUK": 0,
      "HP": 88,
      "Critical": 0,
      "Evade": 11,
      "Speed": 8,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      550,
      551,
      552
    ],
    "setId": 22,
    "animType": null
  },
  "553": {
    "id": 553,
    "displayName": "Fretful Devastator Pants",
    "name": "Fretful Devastator Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 7,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 554,
    "tierItemIds": [
      553,
      554,
      555
    ],
    "setId": 22,
    "animType": null
  },
  "554": {
    "id": 554,
    "displayName": "Mighty Fretful Devastator Pants",
    "name": "Fretful Devastator Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 11,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 555,
    "tierItemIds": [
      553,
      554,
      555
    ],
    "setId": 22,
    "animType": null
  },
  "555": {
    "id": 555,
    "displayName": "Superior Fretful Devastator Pants",
    "name": "Fretful Devastator Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 11,
      "INT": 0,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 16,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      553,
      554,
      555
    ],
    "setId": 22,
    "animType": null
  },
  "556": {
    "id": 556,
    "displayName": "Mighty Biswap Shield",
    "name": "Biswap Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 17,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 2,
      "INT": 2,
      "LUK": 0,
      "HP": 64,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 557,
    "tierItemIds": [
      429,
      556,
      557
    ],
    "setId": 20,
    "animType": "shield",
    "damageType": "Physical"
  },
  "557": {
    "id": 557,
    "displayName": "Superior Biswap Shield",
    "name": "Biswap Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 5,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 24,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 3,
      "INT": 3,
      "LUK": 0,
      "HP": 90,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      429,
      556,
      557
    ],
    "setId": 20,
    "animType": "shield",
    "damageType": "Physical"
  },
  "558": {
    "id": 558,
    "displayName": "Mighty Biswap Sword",
    "name": "Biswap Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 27,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 4,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 41,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 559,
    "tierItemIds": [
      430,
      558,
      559
    ],
    "setId": 20,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "559": {
    "id": 559,
    "displayName": "Superior Biswap Sword",
    "name": "Biswap Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 38,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 6,
      "AGI": 0,
      "INT": 0,
      "LUK": 4,
      "HP": 58,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      430,
      558,
      559
    ],
    "setId": 20,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "560": {
    "id": 560,
    "displayName": "Emidst Armor",
    "name": "Emidst Armor",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 250,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 20,
      "Physical Defense": 25,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 9,
      "INT": 2,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 4,
      "Speed": 3,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Meteor",
          "description": "Summons a meteor landing on all enemy heroes, dealing Magical Damage equal to 5% of each enemy's current HP. The enemies hit then burned by 3% of their current HP as Pure Damage each turn for X turns."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 561,
    "tierItemIds": [
      560,
      561,
      562
    ],
    "setId": 23,
    "animType": null
  },
  "561": {
    "id": 561,
    "displayName": "Mighty Emidst Armor",
    "name": "Emidst Armor",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 28,
      "Physical Defense": 35,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 13,
      "INT": 4,
      "LUK": 0,
      "HP": 175,
      "Critical": 0,
      "Evade": 5,
      "Speed": 4,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Meteor",
          "description": "Summons a meteor landing on all enemy heroes, dealing Magical Damage equal to 10% of each enemy's current HP. The enemies hit then burned by 5% of their current HP as Pure Damage each turn for X turns."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 562,
    "tierItemIds": [
      560,
      561,
      562
    ],
    "setId": 23,
    "animType": null
  },
  "562": {
    "id": 562,
    "displayName": "Superior Emidst Armor",
    "name": "Emidst Armor",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 39,
      "Physical Defense": 49,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 18,
      "INT": 6,
      "LUK": 0,
      "HP": 245,
      "Critical": 0,
      "Evade": 6,
      "Speed": 5,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Meteor",
          "description": "Summons a meteor landing on all enemy heroes, dealing Magical Damage equal to 15% of each enemy's current HP. The enemies hit then burned by 7% of their current HP as Pure Damage each turn for X turns."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      560,
      561,
      562
    ],
    "setId": 23,
    "animType": null
  },
  "563": {
    "id": 563,
    "displayName": "Emidst Gloves",
    "name": "Emidst Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 250,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 20,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 8,
      "INT": 4,
      "LUK": 0,
      "HP": 105,
      "Critical": 0,
      "Evade": 4,
      "Speed": 3,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 75 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 564,
    "tierItemIds": [
      563,
      564,
      565
    ],
    "setId": 23,
    "animType": null
  },
  "564": {
    "id": 564,
    "displayName": "Mighty Emidst Gloves",
    "name": "Emidst Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 21,
      "Physical Defense": 28,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 11,
      "INT": 6,
      "LUK": 0,
      "HP": 147,
      "Critical": 0,
      "Evade": 5,
      "Speed": 4,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 105 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 565,
    "tierItemIds": [
      563,
      564,
      565
    ],
    "setId": 23,
    "animType": null
  },
  "565": {
    "id": 565,
    "displayName": "Superior Emidst Gloves",
    "name": "Emidst Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 29,
      "Physical Defense": 39,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 15,
      "INT": 8,
      "LUK": 0,
      "HP": 206,
      "Critical": 0,
      "Evade": 6,
      "Speed": 5,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 147 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      563,
      564,
      565
    ],
    "setId": 23,
    "animType": null
  },
  "566": {
    "id": 566,
    "displayName": "Grimfrost Spear",
    "name": "Grimfrost Spear Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 36,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 3,
      "INT": 0,
      "LUK": 2,
      "HP": 45,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 3%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 567,
    "tierItemIds": [
      566,
      567,
      568
    ],
    "setId": 24,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "567": {
    "id": 567,
    "displayName": "Mighty Grimfrost Spear",
    "name": "Grimfrost Spear Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 51,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 5,
      "INT": 0,
      "LUK": 3,
      "HP": 62,
      "Critical": 0,
      "Evade": 3,
      "Speed": 2,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 3%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 568,
    "tierItemIds": [
      566,
      567,
      568
    ],
    "setId": 24,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "568": {
    "id": 568,
    "displayName": "Superior Grimfrost Spear",
    "name": "Grimfrost Spear Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 72,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 7,
      "INT": 0,
      "LUK": 4,
      "HP": 86,
      "Critical": 0,
      "Evade": 4,
      "Speed": 3,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 3%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      566,
      567,
      568
    ],
    "setId": 24,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "569": {
    "id": 569,
    "displayName": "Grimfrost Spear",
    "name": "Grimfrost Spear Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 51,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 7,
      "AGI": 5,
      "INT": 0,
      "LUK": 4,
      "HP": 91,
      "Critical": 0,
      "Evade": 3,
      "Speed": 2,
      "Accuracy": 3,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 6%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 570,
    "tierItemIds": [
      569,
      570,
      571
    ],
    "setId": 24,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "570": {
    "id": 570,
    "displayName": "Mighty Grimfrost Spear",
    "name": "Grimfrost Spear Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 72,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 11,
      "AGI": 7,
      "INT": 0,
      "LUK": 6,
      "HP": 127,
      "Critical": 0,
      "Evade": 4,
      "Speed": 3,
      "Accuracy": 6,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 6%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 571,
    "tierItemIds": [
      569,
      570,
      571
    ],
    "setId": 24,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "571": {
    "id": 571,
    "displayName": "Superior Grimfrost Spear",
    "name": "Grimfrost Spear Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 102,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 20,
      "STR": 15,
      "AGI": 9,
      "INT": 0,
      "LUK": 8,
      "HP": 178,
      "Critical": 0,
      "Evade": 5,
      "Speed": 4,
      "Accuracy": 9,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 6%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      569,
      570,
      571
    ],
    "setId": 24,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "572": {
    "id": 572,
    "displayName": "Grimfrost Spear",
    "name": "Grimfrost Spear Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 72,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 10,
      "AGI": 8,
      "INT": 0,
      "LUK": 6,
      "HP": 106,
      "Critical": 3,
      "Evade": 4,
      "Speed": 3,
      "Accuracy": 8,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 10%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 573,
    "tierItemIds": [
      572,
      573,
      574
    ],
    "setId": 24,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "573": {
    "id": 573,
    "displayName": "Mighty Grimfrost Spear",
    "name": "Grimfrost Spear Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 102,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 22,
      "STR": 15,
      "AGI": 12,
      "INT": 0,
      "LUK": 9,
      "HP": 150,
      "Critical": 4,
      "Evade": 5,
      "Speed": 4,
      "Accuracy": 11,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 10%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 574,
    "tierItemIds": [
      572,
      573,
      574
    ],
    "setId": 24,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "574": {
    "id": 574,
    "displayName": "Superior Grimfrost Spear",
    "name": "Grimfrost Spear Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 143,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 30,
      "STR": 20,
      "AGI": 16,
      "INT": 0,
      "LUK": 12,
      "HP": 210,
      "Critical": 5,
      "Evade": 6,
      "Speed": 5,
      "Accuracy": 14,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 10%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      572,
      573,
      574
    ],
    "setId": 24,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "575": {
    "id": 575,
    "displayName": "Grimfrost Shield",
    "name": "Grimfrost Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 2,
      "HP": 30,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 576,
    "tierItemIds": [
      575,
      576,
      577
    ],
    "setId": 24,
    "animType": "shield",
    "damageType": "Physical"
  },
  "576": {
    "id": 576,
    "displayName": "Mighty Grimfrost Shield",
    "name": "Grimfrost Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 3,
      "INT": 0,
      "LUK": 3,
      "HP": 42,
      "Critical": 0,
      "Evade": 3,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 577,
    "tierItemIds": [
      575,
      576,
      577
    ],
    "setId": 24,
    "animType": "shield",
    "damageType": "Physical"
  },
  "577": {
    "id": 577,
    "displayName": "Superior Grimfrost Shield",
    "name": "Grimfrost Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 4,
      "INT": 0,
      "LUK": 4,
      "HP": 59,
      "Critical": 0,
      "Evade": 4,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      575,
      576,
      577
    ],
    "setId": 24,
    "animType": "shield",
    "damageType": "Physical"
  },
  "578": {
    "id": 578,
    "displayName": "Grimfrost Shield",
    "name": "Grimfrost Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 3,
      "INT": 0,
      "LUK": 3,
      "HP": 39,
      "Critical": 2,
      "Evade": 3,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 3%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 579,
    "tierItemIds": [
      578,
      579,
      580
    ],
    "setId": 24,
    "animType": "shield",
    "damageType": "Physical"
  },
  "579": {
    "id": 579,
    "displayName": "Mighty Grimfrost Shield",
    "name": "Grimfrost Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 4,
      "INT": 0,
      "LUK": 4,
      "HP": 54,
      "Critical": 3,
      "Evade": 4,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 3%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 580,
    "tierItemIds": [
      578,
      579,
      580
    ],
    "setId": 24,
    "animType": "shield",
    "damageType": "Physical"
  },
  "580": {
    "id": 580,
    "displayName": "Superior Grimfrost Shield",
    "name": "Grimfrost Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 18,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 5,
      "INT": 0,
      "LUK": 5,
      "HP": 76,
      "Critical": 4,
      "Evade": 5,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 3%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      578,
      579,
      580
    ],
    "setId": 24,
    "animType": "shield",
    "damageType": "Physical"
  },
  "581": {
    "id": 581,
    "displayName": "Grimfrost Shield",
    "name": "Grimfrost Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 15,
      "Critical Damage": 10,
      "STR": 7,
      "AGI": 5,
      "INT": 0,
      "LUK": 5,
      "HP": 87,
      "Critical": 3,
      "Evade": 4,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 6%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 582,
    "tierItemIds": [
      581,
      582,
      583
    ],
    "setId": 24,
    "animType": "shield",
    "damageType": "Physical"
  },
  "582": {
    "id": 582,
    "displayName": "Mighty Grimfrost Shield",
    "name": "Grimfrost Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 20,
      "Critical Damage": 15,
      "STR": 11,
      "AGI": 7,
      "INT": 0,
      "LUK": 7,
      "HP": 123,
      "Critical": 4,
      "Evade": 5,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 6%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 583,
    "tierItemIds": [
      581,
      582,
      583
    ],
    "setId": 24,
    "animType": "shield",
    "damageType": "Physical"
  },
  "583": {
    "id": 583,
    "displayName": "Superior Grimfrost Shield",
    "name": "Grimfrost Shield Sub Weapon",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 25,
      "Critical Damage": 20,
      "STR": 15,
      "AGI": 9,
      "INT": 0,
      "LUK": 9,
      "HP": 173,
      "Critical": 5,
      "Evade": 6,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Viking Warrior",
          "description": "Increases Physical Damage, Magical Defense, and Physical Defense by 6%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      581,
      582,
      583
    ],
    "setId": 24,
    "animType": "shield",
    "damageType": "Physical"
  },
  "584": {
    "id": 584,
    "displayName": "Sturdy Magic Gloves",
    "name": "Sturdy Magic Gloves",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [],
    "maxSupply": 500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 3% damage dealt per hero equipped with Binance items."
        },
        {
          "name": "LifeBurn",
          "description": "Ignores the hero’s Physical/ Magical Damage. A normal attack always deals 10% Pure Damage based on the target's max HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      584
    ],
    "animType": null
  },
  "585": {
    "id": 585,
    "displayName": "Zesty Magic Boots",
    "name": "Zesty Magic Boots",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [],
    "maxSupply": 500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 20,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Binance aura",
          "description": "Increases 3% damage dealt per hero equipped with Binance items."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      585
    ],
    "animType": null
  },
  "586": {
    "id": 586,
    "displayName": "Endeavor Magic Book",
    "name": "Endeavor Magic SkillBook",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 9,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [],
    "maxSupply": 200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": null,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": null,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      586
    ],
    "animType": null,
    "bookInfo": {
      "effect": "Modifies an enemy's HP to the amount equal to the caster's current HP.",
      "elementalBonus": [],
      "bodyPartBonus": []
    }
  },
  "587": {
    "id": 587,
    "displayName": "BSC Station Staff",
    "name": "Bsc Station Staff Weapon",
    "races": [
      "Angel",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 1000,
    "tier": 1,
    "stats": {
      "Magical Damage": 35,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 60,
      "Critical": 2,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Atrophy aura",
          "description": "Decreases Magical Defense of all enemies by 5%"
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      587
    ],
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "588": {
    "id": 588,
    "displayName": "Golden Pontinex Spear",
    "name": "Golden Pontinex Spear Weapon",
    "races": [
      "Demon",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 27,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 589,
    "tierItemIds": [
      588,
      589,
      590
    ],
    "setId": 26,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "589": {
    "id": 589,
    "displayName": "Mighty Golden Pontinex Spear",
    "name": "Golden Pontinex Spear Weapon",
    "races": [
      "Demon",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 39,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 590,
    "tierItemIds": [
      588,
      589,
      590
    ],
    "setId": 26,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "590": {
    "id": 590,
    "displayName": "Superior Golden Pontinex Spear",
    "name": "Golden Pontinex Spear Weapon",
    "races": [
      "Demon",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 55,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      588,
      589,
      590
    ],
    "setId": 26,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "591": {
    "id": 591,
    "displayName": "Golden Pontinex Spear",
    "name": "Golden Pontinex Spear Weapon",
    "races": [
      "Demon",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 39,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 592,
    "tierItemIds": [
      591,
      592,
      593
    ],
    "setId": 26,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "592": {
    "id": 592,
    "displayName": "Mighty Golden Pontinex Spear",
    "name": "Golden Pontinex Spear Weapon",
    "races": [
      "Demon",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 55,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 593,
    "tierItemIds": [
      591,
      592,
      593
    ],
    "setId": 26,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "593": {
    "id": 593,
    "displayName": "Superior Golden Pontinex Spear",
    "name": "Golden Pontinex Spear Weapon",
    "races": [
      "Demon",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 78,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      591,
      592,
      593
    ],
    "setId": 26,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "594": {
    "id": 594,
    "displayName": "Golden Pontinex Spear",
    "name": "Golden Pontinex Spear Weapon",
    "races": [
      "Demon",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 30,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 55,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 23 HP per attack."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 595,
    "tierItemIds": [
      594,
      595,
      596
    ],
    "setId": 26,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "595": {
    "id": 595,
    "displayName": "Mighty Golden Pontinex Spear",
    "name": "Golden Pontinex Spear Weapon",
    "races": [
      "Demon",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 78,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 35 HP per attack."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 596,
    "tierItemIds": [
      594,
      595,
      596
    ],
    "setId": 26,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "596": {
    "id": 596,
    "displayName": "Superior Golden Pontinex Spear",
    "name": "Golden Pontinex Spear Weapon",
    "races": [
      "Demon",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 110,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 42 HP per attack."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      594,
      595,
      596
    ],
    "setId": 26,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "597": {
    "id": 597,
    "displayName": "Golden Pontinex Armor",
    "name": "Golden Pontinex Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 598,
    "tierItemIds": [
      597,
      598,
      599
    ],
    "setId": 26,
    "animType": null
  },
  "598": {
    "id": 598,
    "displayName": "Mighty Golden Pontinex Armor",
    "name": "Golden Pontinex Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 599,
    "tierItemIds": [
      597,
      598,
      599
    ],
    "setId": 26,
    "animType": null
  },
  "599": {
    "id": 599,
    "displayName": "Superior Golden Pontinex Armor",
    "name": "Golden Pontinex Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      597,
      598,
      599
    ],
    "setId": 26,
    "animType": null
  },
  "600": {
    "id": 600,
    "displayName": "Golden Pontinex Armor",
    "name": "Golden Pontinex Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 601,
    "tierItemIds": [
      600,
      601,
      602
    ],
    "setId": 26,
    "animType": null
  },
  "601": {
    "id": 601,
    "displayName": "Mighty Golden Pontinex Armor",
    "name": "Golden Pontinex Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 602,
    "tierItemIds": [
      600,
      601,
      602
    ],
    "setId": 26,
    "animType": null
  },
  "602": {
    "id": 602,
    "displayName": "Superior Golden Pontinex Armor",
    "name": "Golden Pontinex Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 110,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      600,
      601,
      602
    ],
    "setId": 26,
    "animType": null
  },
  "603": {
    "id": 603,
    "displayName": "Golden Pontinex Boots",
    "name": "Golden Pontinex Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 604,
    "tierItemIds": [
      603,
      604,
      605
    ],
    "setId": 26,
    "animType": null
  },
  "604": {
    "id": 604,
    "displayName": "Mighty Golden Pontinex Boots",
    "name": "Golden Pontinex Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 605,
    "tierItemIds": [
      603,
      604,
      605
    ],
    "setId": 26,
    "animType": null
  },
  "605": {
    "id": 605,
    "displayName": "Superior Golden Pontinex Boots",
    "name": "Golden Pontinex Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 77,
      "Critical": 0,
      "Evade": 2,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      603,
      604,
      605
    ],
    "setId": 26,
    "animType": null
  },
  "606": {
    "id": 606,
    "displayName": "Golden Pontinex Boots",
    "name": "Golden Pontinex Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 607,
    "tierItemIds": [
      606,
      607,
      608
    ],
    "setId": 26,
    "animType": null
  },
  "607": {
    "id": 607,
    "displayName": "Mighty Golden Pontinex Boots",
    "name": "Golden Pontinex Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 77,
      "Critical": 0,
      "Evade": 2,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 608,
    "tierItemIds": [
      606,
      607,
      608
    ],
    "setId": 26,
    "animType": null
  },
  "608": {
    "id": 608,
    "displayName": "Superior Golden Pontinex Boots",
    "name": "Golden Pontinex Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 15,
      "INT": 0,
      "LUK": 0,
      "HP": 108,
      "Critical": 0,
      "Evade": 4,
      "Speed": 6,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      606,
      607,
      608
    ],
    "setId": 26,
    "animType": null
  },
  "609": {
    "id": 609,
    "displayName": "Golden Pontinex Gloves",
    "name": "Golden Pontinex Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 200,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 610,
    "tierItemIds": [
      609,
      610,
      611
    ],
    "setId": 26,
    "animType": null
  },
  "610": {
    "id": 610,
    "displayName": "Mighty Golden Pontinex Gloves",
    "name": "Golden Pontinex Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 611,
    "tierItemIds": [
      609,
      610,
      611
    ],
    "setId": 26,
    "animType": null
  },
  "611": {
    "id": 611,
    "displayName": "Superior Golden Pontinex Gloves",
    "name": "Golden Pontinex Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      609,
      610,
      611
    ],
    "setId": 26,
    "animType": null
  },
  "612": {
    "id": 612,
    "displayName": "Golden Pontinex Gloves",
    "name": "Golden Pontinex Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 613,
    "tierItemIds": [
      612,
      613,
      614
    ],
    "setId": 26,
    "animType": null
  },
  "613": {
    "id": 613,
    "displayName": "Mighty Golden Pontinex Gloves",
    "name": "Golden Pontinex Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 614,
    "tierItemIds": [
      612,
      613,
      614
    ],
    "setId": 26,
    "animType": null
  },
  "614": {
    "id": 614,
    "displayName": "Superior Golden Pontinex Gloves",
    "name": "Golden Pontinex Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 14,
      "Critical Damage": 1,
      "STR": 12,
      "AGI": 0,
      "INT": 1,
      "LUK": 3,
      "HP": 110,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      612,
      613,
      614
    ],
    "setId": 26,
    "animType": null
  },
  "615": {
    "id": 615,
    "displayName": "Golden Pontinex Helmet",
    "name": "Golden Pontinex Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 250,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 616,
    "tierItemIds": [
      615,
      616,
      617
    ],
    "setId": 26,
    "animType": null
  },
  "616": {
    "id": 616,
    "displayName": "Mighty Golden Pontinex Helmet",
    "name": "Golden Pontinex Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 617,
    "tierItemIds": [
      615,
      616,
      617
    ],
    "setId": 26,
    "animType": null
  },
  "617": {
    "id": 617,
    "displayName": "Superior Golden Pontinex Helmet",
    "name": "Golden Pontinex Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 49,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      615,
      616,
      617
    ],
    "setId": 26,
    "animType": null
  },
  "618": {
    "id": 618,
    "displayName": "Golden Pontinex Helmet",
    "name": "Golden Pontinex Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 150,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 619,
    "tierItemIds": [
      618,
      619,
      620
    ],
    "setId": 26,
    "animType": null
  },
  "619": {
    "id": 619,
    "displayName": "Mighty Golden Pontinex Helmet",
    "name": "Golden Pontinex Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 49,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 620,
    "tierItemIds": [
      618,
      619,
      620
    ],
    "setId": 26,
    "animType": null
  },
  "620": {
    "id": 620,
    "displayName": "Superior Golden Pontinex Helmet",
    "name": "Golden Pontinex Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 1,
      "LUK": 3,
      "HP": 69,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      618,
      619,
      620
    ],
    "setId": 26,
    "animType": null
  },
  "621": {
    "id": 621,
    "displayName": "Golden Pontinex Pants",
    "name": "Golden Pontinex Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 250,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 622,
    "tierItemIds": [
      621,
      622,
      623
    ],
    "setId": 26,
    "animType": null
  },
  "622": {
    "id": 622,
    "displayName": "Mighty Golden Pontinex Pants",
    "name": "Golden Pontinex Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 623,
    "tierItemIds": [
      621,
      622,
      623
    ],
    "setId": 26,
    "animType": null
  },
  "623": {
    "id": 623,
    "displayName": "Superior Golden Pontinex Pants",
    "name": "Golden Pontinex Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      621,
      622,
      623
    ],
    "setId": 26,
    "animType": null
  },
  "624": {
    "id": 624,
    "displayName": "Golden Pontinex Pants",
    "name": "Golden Pontinex Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 150,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 625,
    "tierItemIds": [
      624,
      625,
      626
    ],
    "setId": 26,
    "animType": null
  },
  "625": {
    "id": 625,
    "displayName": "Mighty Golden Pontinex Pants",
    "name": "Golden Pontinex Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 626,
    "tierItemIds": [
      624,
      625,
      626
    ],
    "setId": 26,
    "animType": null
  },
  "626": {
    "id": 626,
    "displayName": "Superior Golden Pontinex Pants",
    "name": "Golden Pontinex Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 14,
      "INT": 0,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 4,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      624,
      625,
      626
    ],
    "setId": 26,
    "animType": null
  },
  "627": {
    "id": 627,
    "displayName": "Tribataur Staff",
    "name": "Tribataur Staff Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 1000,
    "tier": 1,
    "stats": {
      "Magical Damage": 20,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 628,
    "tierItemIds": [
      627,
      628,
      629
    ],
    "setId": 25,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "628": {
    "id": 628,
    "displayName": "Mighty Tribataur Staff",
    "name": "Tribataur Staff Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 28,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 629,
    "tierItemIds": [
      627,
      628,
      629
    ],
    "setId": 25,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "629": {
    "id": 629,
    "displayName": "Superior Tribataur Staff",
    "name": "Tribataur Staff Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 40,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      627,
      628,
      629
    ],
    "setId": 25,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "630": {
    "id": 630,
    "displayName": "Tribataur Staff",
    "name": "Tribataur Staff Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 500,
    "tier": 1,
    "stats": {
      "Magical Damage": 28,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 631,
    "tierItemIds": [
      630,
      631,
      632
    ],
    "setId": 25,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "631": {
    "id": 631,
    "displayName": "Mighty Tribataur Staff",
    "name": "Tribataur Staff Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 40,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 632,
    "tierItemIds": [
      630,
      631,
      632
    ],
    "setId": 25,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "632": {
    "id": 632,
    "displayName": "Superior Tribataur Staff",
    "name": "Tribataur Staff Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 57,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      630,
      631,
      632
    ],
    "setId": 25,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "633": {
    "id": 633,
    "displayName": "Tribataur Staff",
    "name": "Tribataur Staff Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 100,
    "tier": 1,
    "stats": {
      "Magical Damage": 40,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Burning",
          "description": "Attacks have a 50% chance of applying Burning, which deals 35 damage each turn for 2 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 634,
    "tierItemIds": [
      633,
      634,
      635
    ],
    "setId": 25,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "634": {
    "id": 634,
    "displayName": "Mighty Tribataur Staff",
    "name": "Tribataur Staff Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 57,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Burning",
          "description": "Attacks have a 50% chance of applying Burning, which deals 55 damage each turn for 2 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 635,
    "tierItemIds": [
      633,
      634,
      635
    ],
    "setId": 25,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "635": {
    "id": 635,
    "displayName": "Superior Tribataur Staff",
    "name": "Tribataur Staff Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 81,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 16,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 8,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Burning",
          "description": "Attacks have a 50% chance of applying Burning, which deals 75 damage each turn for 2 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      633,
      634,
      635
    ],
    "setId": 25,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "636": {
    "id": 636,
    "displayName": "Tribataur Armor",
    "name": "Tribataur Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 1000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 38,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 637,
    "tierItemIds": [
      636,
      637,
      638
    ],
    "setId": 25,
    "animType": null
  },
  "637": {
    "id": 637,
    "displayName": "Mighty Tribataur Armor",
    "name": "Tribataur Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 54,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 638,
    "tierItemIds": [
      636,
      637,
      638
    ],
    "setId": 25,
    "animType": null
  },
  "638": {
    "id": 638,
    "displayName": "Superior Tribataur Armor",
    "name": "Tribataur Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 76,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      636,
      637,
      638
    ],
    "setId": 25,
    "animType": null
  },
  "639": {
    "id": 639,
    "displayName": "Tribataur Armor",
    "name": "Tribataur Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 54,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 640,
    "tierItemIds": [
      639,
      640,
      641
    ],
    "setId": 25,
    "animType": null
  },
  "640": {
    "id": 640,
    "displayName": "Mighty Tribataur Armor",
    "name": "Tribataur Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 76,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 641,
    "tierItemIds": [
      639,
      640,
      641
    ],
    "setId": 25,
    "animType": null
  },
  "641": {
    "id": 641,
    "displayName": "Superior Tribataur Armor",
    "name": "Tribataur Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 21,
      "LUK": 0,
      "HP": 107,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      639,
      640,
      641
    ],
    "setId": 25,
    "animType": null
  },
  "642": {
    "id": 642,
    "displayName": "Tribataur Boots",
    "name": "Tribataur Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 1000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 38,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 643,
    "tierItemIds": [
      642,
      643,
      644
    ],
    "setId": 25,
    "animType": null
  },
  "643": {
    "id": 643,
    "displayName": "Mighty Tribataur Boots",
    "name": "Tribataur Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 54,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 644,
    "tierItemIds": [
      642,
      643,
      644
    ],
    "setId": 25,
    "animType": null
  },
  "644": {
    "id": 644,
    "displayName": "Superior Tribataur Boots",
    "name": "Tribataur Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 76,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      642,
      643,
      644
    ],
    "setId": 25,
    "animType": null
  },
  "645": {
    "id": 645,
    "displayName": "Tribataur Boots",
    "name": "Tribataur Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 54,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 646,
    "tierItemIds": [
      645,
      646,
      647
    ],
    "setId": 25,
    "animType": null
  },
  "646": {
    "id": 646,
    "displayName": "Mighty Tribataur Boots",
    "name": "Tribataur Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 76,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 647,
    "tierItemIds": [
      645,
      646,
      647
    ],
    "setId": 25,
    "animType": null
  },
  "647": {
    "id": 647,
    "displayName": "Superior Tribataur Boots",
    "name": "Tribataur Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 107,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      645,
      646,
      647
    ],
    "setId": 25,
    "animType": null
  },
  "648": {
    "id": 648,
    "displayName": "Tribataur Gloves",
    "name": "Tribataur Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 1000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 3,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 38,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 649,
    "tierItemIds": [
      648,
      649,
      650
    ],
    "setId": 25,
    "animType": null
  },
  "649": {
    "id": 649,
    "displayName": "Mighty Tribataur Gloves",
    "name": "Tribataur Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 0,
      "INT": 10,
      "LUK": 2,
      "HP": 54,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 650,
    "tierItemIds": [
      648,
      649,
      650
    ],
    "setId": 25,
    "animType": null
  },
  "650": {
    "id": 650,
    "displayName": "Superior Tribataur Gloves",
    "name": "Tribataur Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 4,
      "Critical Damage": 8,
      "STR": 5,
      "AGI": 0,
      "INT": 15,
      "LUK": 3,
      "HP": 76,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      648,
      649,
      650
    ],
    "setId": 25,
    "animType": null
  },
  "651": {
    "id": 651,
    "displayName": "Tribataur Gloves",
    "name": "Tribataur Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 0,
      "INT": 10,
      "LUK": 2,
      "HP": 54,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 652,
    "tierItemIds": [
      651,
      652,
      653
    ],
    "setId": 25,
    "animType": null
  },
  "652": {
    "id": 652,
    "displayName": "Mighty Tribataur Gloves",
    "name": "Tribataur Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 4,
      "Critical Damage": 8,
      "STR": 5,
      "AGI": 0,
      "INT": 15,
      "LUK": 3,
      "HP": 76,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 653,
    "tierItemIds": [
      651,
      652,
      653
    ],
    "setId": 25,
    "animType": null
  },
  "653": {
    "id": 653,
    "displayName": "Superior Tribataur Gloves",
    "name": "Tribataur Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 6,
      "Critical Damage": 12,
      "STR": 8,
      "AGI": 0,
      "INT": 21,
      "LUK": 5,
      "HP": 107,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      651,
      652,
      653
    ],
    "setId": 25,
    "animType": null
  },
  "654": {
    "id": 654,
    "displayName": "Tribataur Helmet",
    "name": "Tribataur Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 2000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 1,
      "HP": 23,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 655,
    "tierItemIds": [
      654,
      655,
      656
    ],
    "setId": 25,
    "animType": null
  },
  "655": {
    "id": 655,
    "displayName": "Mighty Tribataur Helmet",
    "name": "Tribataur Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 4,
      "LUK": 2,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 656,
    "tierItemIds": [
      654,
      655,
      656
    ],
    "setId": 25,
    "animType": null
  },
  "656": {
    "id": 656,
    "displayName": "Superior Tribataur Helmet",
    "name": "Tribataur Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 6,
      "LUK": 3,
      "HP": 47,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      654,
      655,
      656
    ],
    "setId": 25,
    "animType": null
  },
  "657": {
    "id": 657,
    "displayName": "Tribataur Helmet",
    "name": "Tribataur Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 1000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 4,
      "LUK": 2,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 658,
    "tierItemIds": [
      657,
      658,
      659
    ],
    "setId": 25,
    "animType": null
  },
  "658": {
    "id": 658,
    "displayName": "Mighty Tribataur Helmet",
    "name": "Tribataur Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 6,
      "LUK": 3,
      "HP": 47,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 659,
    "tierItemIds": [
      657,
      658,
      659
    ],
    "setId": 25,
    "animType": null
  },
  "659": {
    "id": 659,
    "displayName": "Superior Tribataur Helmet",
    "name": "Tribataur Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 9,
      "LUK": 5,
      "HP": 67,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      657,
      658,
      659
    ],
    "setId": 25,
    "animType": null
  },
  "660": {
    "id": 660,
    "displayName": "Tribataur Pants",
    "name": "Tribataur Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 2000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 23,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 661,
    "tierItemIds": [
      660,
      661,
      662
    ],
    "setId": 25,
    "animType": null
  },
  "661": {
    "id": 661,
    "displayName": "Mighty Tribataur Pants",
    "name": "Tribataur Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 662,
    "tierItemIds": [
      660,
      661,
      662
    ],
    "setId": 25,
    "animType": null
  },
  "662": {
    "id": 662,
    "displayName": "Superior Tribataur Pants",
    "name": "Tribataur Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      660,
      661,
      662
    ],
    "setId": 25,
    "animType": null
  },
  "663": {
    "id": 663,
    "displayName": "Tribataur Pants",
    "name": "Tribataur Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 1000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 664,
    "tierItemIds": [
      663,
      664,
      665
    ],
    "setId": 25,
    "animType": null
  },
  "664": {
    "id": 664,
    "displayName": "Mighty Tribataur Pants",
    "name": "Tribataur Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 665,
    "tierItemIds": [
      663,
      664,
      665
    ],
    "setId": 25,
    "animType": null
  },
  "665": {
    "id": 665,
    "displayName": "Superior Tribataur Pants",
    "name": "Tribataur Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 67,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      663,
      664,
      665
    ],
    "setId": 25,
    "animType": null
  },
  "666": {
    "id": 666,
    "displayName": "Sauronus Crown",
    "name": "Sauronus Crown Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 8,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 8,
      "LUK": 0,
      "HP": 81,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 30% chance of absorbing 35 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 667,
    "tierItemIds": [
      666,
      667,
      668
    ],
    "animType": null
  },
  "667": {
    "id": 667,
    "displayName": "Mighty Sauronus Crown",
    "name": "Sauronus Crown Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 12,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 2,
      "INT": 12,
      "LUK": 0,
      "HP": 114,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 30% chance of absorbing 50 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 668,
    "tierItemIds": [
      666,
      667,
      668
    ],
    "animType": null
  },
  "668": {
    "id": 668,
    "displayName": "Superior Sauronus Crown",
    "name": "Sauronus Crown Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 18,
      "Physical Damage": 0,
      "Magical Defense": 19,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 3,
      "INT": 18,
      "LUK": 0,
      "HP": 160,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 30% chance of absorbing 65 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      666,
      667,
      668
    ],
    "animType": null
  },
  "669": {
    "id": 669,
    "displayName": "Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 34,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 25,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 4,
    "nextTierId": 670,
    "tierItemIds": [
      669,
      670,
      671
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "670": {
    "id": 670,
    "displayName": "Mighty Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 34,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 671,
    "tierItemIds": [
      669,
      670,
      671
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "671": {
    "id": 671,
    "displayName": "Superior Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 34,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 50,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      669,
      670,
      671
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "672": {
    "id": 672,
    "displayName": "Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 673,
    "tierItemIds": [
      672,
      673,
      674
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "673": {
    "id": 673,
    "displayName": "Mighty Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 50,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 674,
    "tierItemIds": [
      672,
      673,
      674
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "674": {
    "id": 674,
    "displayName": "Superior Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 71,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      672,
      673,
      674
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "675": {
    "id": 675,
    "displayName": "Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 56,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 50,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Piercing Strike",
          "description": "Ignores 20 Physical Defense of the target."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 676,
    "tierItemIds": [
      675,
      676,
      677
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "676": {
    "id": 676,
    "displayName": "Mighty Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 56,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 71,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Piercing Strike",
          "description": "Ignores 30 Physical Defense of the target."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 677,
    "tierItemIds": [
      675,
      676,
      677
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "677": {
    "id": 677,
    "displayName": "Superior Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 56,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 100,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Piercing Strike",
          "description": "Ignores 40 Physical Defense of the target."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      675,
      676,
      677
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "678": {
    "id": 678,
    "displayName": "Moon's Elegance Armor",
    "name": "Moons Elegance Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 8,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 679,
    "tierItemIds": [
      678,
      679,
      680
    ],
    "setId": 27,
    "animType": null
  },
  "679": {
    "id": 679,
    "displayName": "Mighty Moon's Elegance Armor",
    "name": "Moons Elegance Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 1,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 680,
    "tierItemIds": [
      678,
      679,
      680
    ],
    "setId": 27,
    "animType": null
  },
  "680": {
    "id": 680,
    "displayName": "Superior Moon's Elegance Armor",
    "name": "Moons Elegance Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 18,
      "Magical Defense": 2,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      678,
      679,
      680
    ],
    "setId": 27,
    "animType": null
  },
  "681": {
    "id": 681,
    "displayName": "Moon's Elegance Armor",
    "name": "Moons Elegance Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 41,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 1,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 682,
    "tierItemIds": [
      681,
      682,
      683
    ],
    "setId": 27,
    "animType": null
  },
  "682": {
    "id": 682,
    "displayName": "Mighty Moon's Elegance Armor",
    "name": "Moons Elegance Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 41,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 18,
      "Magical Defense": 2,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 683,
    "tierItemIds": [
      681,
      682,
      683
    ],
    "setId": 27,
    "animType": null
  },
  "683": {
    "id": 683,
    "displayName": "Superior Moon's Elegance Armor",
    "name": "Moons Elegance Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 41,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 26,
      "Magical Defense": 3,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 122,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      681,
      682,
      683
    ],
    "setId": 27,
    "animType": null
  },
  "684": {
    "id": 684,
    "displayName": "Moon's Elegance Boots Boots",
    "name": "Moons Elegance Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 685,
    "tierItemIds": [
      684,
      685,
      686
    ],
    "setId": 27,
    "animType": null
  },
  "685": {
    "id": 685,
    "displayName": "Mighty Moon's Elegance Boots",
    "name": "Moons Elegance Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 686,
    "tierItemIds": [
      684,
      685,
      686
    ],
    "setId": 27,
    "animType": null
  },
  "686": {
    "id": 686,
    "displayName": "Superior Moon's Elegance Boots",
    "name": "Moons Elegance Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      684,
      685,
      686
    ],
    "setId": 27,
    "animType": null
  },
  "687": {
    "id": 687,
    "displayName": "Moon's Elegance Boots",
    "name": "Moons Elegance Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 688,
    "tierItemIds": [
      687,
      688,
      689
    ],
    "setId": 27,
    "animType": null
  },
  "688": {
    "id": 688,
    "displayName": "Mighty Moon's Elegance Boots",
    "name": "Moons Elegance Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 689,
    "tierItemIds": [
      687,
      688,
      689
    ],
    "setId": 27,
    "animType": null
  },
  "689": {
    "id": 689,
    "displayName": "Superior Moon's Elegance Boots",
    "name": "Moons Elegance Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 7,
      "INT": 1,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 0,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      687,
      688,
      689
    ],
    "setId": 27,
    "animType": null
  },
  "690": {
    "id": 690,
    "displayName": "Moon's Elegance Gloves",
    "name": "Moons Elegance Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 2,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 691,
    "tierItemIds": [
      690,
      691,
      692
    ],
    "setId": 27,
    "animType": null
  },
  "691": {
    "id": 691,
    "displayName": "Mighty Moon's Elegance Gloves",
    "name": "Moons Elegance Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 3,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 692,
    "tierItemIds": [
      690,
      691,
      692
    ],
    "setId": 27,
    "animType": null
  },
  "692": {
    "id": 692,
    "displayName": "Superior Moon's Elegance Gloves",
    "name": "Moons Elegance Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 5,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      690,
      691,
      692
    ],
    "setId": 27,
    "animType": null
  },
  "693": {
    "id": 693,
    "displayName": "Moon's Elegance Gloves",
    "name": "Moons Elegance Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 3,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 694,
    "tierItemIds": [
      693,
      694,
      695
    ],
    "setId": 27,
    "animType": null
  },
  "694": {
    "id": 694,
    "displayName": "Mighty Moon's Elegance Gloves",
    "name": "Moons Elegance Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 5,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 695,
    "tierItemIds": [
      693,
      694,
      695
    ],
    "setId": 27,
    "animType": null
  },
  "695": {
    "id": 695,
    "displayName": "Superior Moon's Elegance Gloves",
    "name": "Moons Elegance Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 8,
      "Magical Defense": 3,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      693,
      694,
      695
    ],
    "setId": 27,
    "animType": null
  },
  "696": {
    "id": 696,
    "displayName": "Moon's Elegance Helmet",
    "name": "Moons Elegance Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 697,
    "tierItemIds": [
      696,
      697,
      698
    ],
    "setId": 27,
    "animType": null
  },
  "697": {
    "id": 697,
    "displayName": "Mighty Moon's Elegance Helmet",
    "name": "Moons Elegance Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 698,
    "tierItemIds": [
      696,
      697,
      698
    ],
    "setId": 27,
    "animType": null
  },
  "698": {
    "id": 698,
    "displayName": "Superior Moon's Elegance Helmet",
    "name": "Moons Elegance Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      696,
      697,
      698
    ],
    "setId": 27,
    "animType": null
  },
  "699": {
    "id": 699,
    "displayName": "Moon's Elegance Helmet",
    "name": "Moons Elegance Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 34,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 700,
    "tierItemIds": [
      699,
      700,
      701
    ],
    "setId": 27,
    "animType": null
  },
  "700": {
    "id": 700,
    "displayName": "Mighty Moon's Elegance Helmet",
    "name": "Moons Elegance Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 34,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 701,
    "tierItemIds": [
      699,
      700,
      701
    ],
    "setId": 27,
    "animType": null
  },
  "701": {
    "id": 701,
    "displayName": "Superior Moon's Elegance Helmet",
    "name": "Moons Elegance Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 34,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      699,
      700,
      701
    ],
    "setId": 27,
    "animType": null
  },
  "702": {
    "id": 702,
    "displayName": "Moon's Elegance Pants",
    "name": "Moons Elegance Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 28,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 703,
    "tierItemIds": [
      702,
      703,
      704
    ],
    "setId": 27,
    "animType": null
  },
  "703": {
    "id": 703,
    "displayName": "Mighty Moon's Elegance Pants",
    "name": "Moons Elegance Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 28,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 704,
    "tierItemIds": [
      702,
      703,
      704
    ],
    "setId": 27,
    "animType": null
  },
  "704": {
    "id": 704,
    "displayName": "Superior Moon's Elegance Pants",
    "name": "Moons Elegance Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 28,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      702,
      703,
      704
    ],
    "setId": 27,
    "animType": null
  },
  "705": {
    "id": 705,
    "displayName": "Moon's Elegance Pants",
    "name": "Moons Elegance Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 706,
    "tierItemIds": [
      705,
      706,
      707
    ],
    "setId": 27,
    "animType": null
  },
  "706": {
    "id": 706,
    "displayName": "Mighty Moon's Elegance Pants",
    "name": "Moons Elegance Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 707,
    "tierItemIds": [
      705,
      706,
      707
    ],
    "setId": 27,
    "animType": null
  },
  "707": {
    "id": 707,
    "displayName": "Superior Moon's Elegance Pants",
    "name": "Moons Elegance Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 1,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      705,
      706,
      707
    ],
    "setId": 27,
    "animType": null
  },
  "708": {
    "id": 708,
    "displayName": "Masked Maniac Mace",
    "name": "Masked Maniac Weapon",
    "races": [
      "Demon",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 22,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 709,
    "tierItemIds": [
      708,
      709,
      710
    ],
    "setId": 28,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "709": {
    "id": 709,
    "displayName": "Mighty Masked Maniac Mace",
    "name": "Masked Maniac Weapon",
    "races": [
      "Demon",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 32,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 710,
    "tierItemIds": [
      708,
      709,
      710
    ],
    "setId": 28,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "710": {
    "id": 710,
    "displayName": "Superior Masked Maniac Mace",
    "name": "Masked Maniac Weapon",
    "races": [
      "Demon",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 45,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      708,
      709,
      710
    ],
    "setId": 28,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "711": {
    "id": 711,
    "displayName": "Masked Maniac Mace",
    "name": "Masked Maniac Weapon",
    "races": [
      "Demon",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 32,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Soul Strike",
          "description": "Normal attacks deal a bonus of 55 Pure Damage when successfully landed."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 712,
    "tierItemIds": [
      711,
      712,
      713
    ],
    "setId": 28,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "712": {
    "id": 712,
    "displayName": "Mighty Masked Maniac Mace",
    "name": "Masked Maniac Weapon",
    "races": [
      "Demon",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 45,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Soul Strike",
          "description": "Normal attacks deal a bonus of 70 Pure Damage when successfully landed."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 713,
    "tierItemIds": [
      711,
      712,
      713
    ],
    "setId": 28,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "713": {
    "id": 713,
    "displayName": "Superior Masked Maniac Mace",
    "name": "Masked Maniac Weapon",
    "races": [
      "Demon",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 64,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 11,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Soul Strike",
          "description": "Normal attacks deal a bonus of 85 Pure Damage when successfully landed."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      711,
      712,
      713
    ],
    "setId": 28,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "714": {
    "id": 714,
    "displayName": "Masked Maniac Mace",
    "name": "Masked Maniac Weapon",
    "races": [
      "Demon",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 64,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 45,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": [
        {
          "name": "Soul Strike",
          "description": "Normal attacks deal a bonus of 100 Pure Damage when successfully landed."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 715,
    "tierItemIds": [
      714,
      715,
      716
    ],
    "setId": 28,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "715": {
    "id": 715,
    "displayName": "Mighty Masked Maniac Mace",
    "name": "Masked Maniac Weapon",
    "races": [
      "Demon",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 64,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 64,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 11,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": [
        {
          "name": "Soul Strike",
          "description": "Normal attacks deal a bonus of 120 Pure Damage when successfully landed."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 716,
    "tierItemIds": [
      714,
      715,
      716
    ],
    "setId": 28,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "716": {
    "id": 716,
    "displayName": "Superior Masked Maniac Mace",
    "name": "Masked Maniac Weapon",
    "races": [
      "Demon",
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 64,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 90,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 0,
      "Critical": 16,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": [
        {
          "name": "Soul Strike",
          "description": "Normal attacks deal a bonus of 140 Pure Damage when successfully landed."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      714,
      715,
      716
    ],
    "setId": 28,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "717": {
    "id": 717,
    "displayName": "Masked Maniac Armor",
    "name": "Masked Maniac Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 8,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 718,
    "tierItemIds": [
      717,
      718,
      719
    ],
    "setId": 28,
    "animType": null
  },
  "718": {
    "id": 718,
    "displayName": "Mighty Masked Maniac Armor",
    "name": "Masked Maniac Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 3,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 719,
    "tierItemIds": [
      717,
      718,
      719
    ],
    "setId": 28,
    "animType": null
  },
  "719": {
    "id": 719,
    "displayName": "Superior Masked Maniac Armor",
    "name": "Masked Maniac Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 17,
      "Magical Defense": 5,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 157,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      717,
      718,
      719
    ],
    "setId": 28,
    "animType": null
  },
  "720": {
    "id": 720,
    "displayName": "Masked Maniac Armor",
    "name": "Masked Maniac Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 3,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 721,
    "tierItemIds": [
      720,
      721,
      722
    ],
    "setId": 28,
    "animType": null
  },
  "721": {
    "id": 721,
    "displayName": "Mighty Masked Maniac Armor",
    "name": "Masked Maniac Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 17,
      "Magical Defense": 5,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 157,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 722,
    "tierItemIds": [
      720,
      721,
      722
    ],
    "setId": 28,
    "animType": null
  },
  "722": {
    "id": 722,
    "displayName": "Superior Masked Maniac Armor",
    "name": "Masked Maniac Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 24,
      "Magical Defense": 7,
      "Physical Defense": 23,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 221,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      720,
      721,
      722
    ],
    "setId": 28,
    "animType": null
  },
  "723": {
    "id": 723,
    "displayName": "Masked Maniac Boots",
    "name": "Masked Maniac Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 724,
    "tierItemIds": [
      723,
      724,
      725
    ],
    "setId": 28,
    "animType": null
  },
  "724": {
    "id": 724,
    "displayName": "Mighty Masked Maniac Boots",
    "name": "Masked Maniac Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 9,
      "INT": 1,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 1,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 725,
    "tierItemIds": [
      723,
      724,
      725
    ],
    "setId": 28,
    "animType": null
  },
  "725": {
    "id": 725,
    "displayName": "Superior Masked Maniac Boots",
    "name": "Masked Maniac Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 13,
      "INT": 2,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 2,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      723,
      724,
      725
    ],
    "setId": 28,
    "animType": null
  },
  "726": {
    "id": 726,
    "displayName": "Masked Maniac Boots",
    "name": "Masked Maniac Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 9,
      "INT": 1,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 1,
      "Speed": 5,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 18 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 727,
    "tierItemIds": [
      726,
      727,
      728
    ],
    "setId": 28,
    "animType": null
  },
  "727": {
    "id": 727,
    "displayName": "Mighty Masked Maniac Boots",
    "name": "Masked Maniac Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 13,
      "INT": 2,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 2,
      "Speed": 7,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 26 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 728,
    "tierItemIds": [
      726,
      727,
      728
    ],
    "setId": 28,
    "animType": null
  },
  "728": {
    "id": 728,
    "displayName": "Superior Masked Maniac Boots",
    "name": "Masked Maniac Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 19,
      "INT": 3,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 4,
      "Speed": 11,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 35 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      726,
      727,
      728
    ],
    "setId": 28,
    "animType": null
  },
  "729": {
    "id": 729,
    "displayName": "Masked Maniac Gloves",
    "name": "Masked Maniac Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 5,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 730,
    "tierItemIds": [
      729,
      730,
      731
    ],
    "setId": 28,
    "animType": null
  },
  "730": {
    "id": 730,
    "displayName": "Mighty Masked Maniac Gloves",
    "name": "Masked Maniac Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 7,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 731,
    "tierItemIds": [
      729,
      730,
      731
    ],
    "setId": 28,
    "animType": null
  },
  "731": {
    "id": 731,
    "displayName": "Superior Masked Maniac Gloves",
    "name": "Masked Maniac Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 10,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 80,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      729,
      730,
      731
    ],
    "setId": 28,
    "animType": null
  },
  "732": {
    "id": 732,
    "displayName": "Masked Maniac Gloves",
    "name": "Masked Maniac Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 7,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 733,
    "tierItemIds": [
      732,
      733,
      734
    ],
    "setId": 28,
    "animType": null
  },
  "733": {
    "id": 733,
    "displayName": "Mighty Masked Maniac Gloves",
    "name": "Masked Maniac Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 10,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 89,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 734,
    "tierItemIds": [
      732,
      733,
      734
    ],
    "setId": 28,
    "animType": null
  },
  "734": {
    "id": 734,
    "displayName": "Superior Masked Maniac Gloves",
    "name": "Masked Maniac Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 15,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 125,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      732,
      733,
      734
    ],
    "setId": 28,
    "animType": null
  },
  "735": {
    "id": 735,
    "displayName": "Masked Maniac Helmet",
    "name": "Masked Maniac Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 736,
    "tierItemIds": [
      735,
      736,
      737
    ],
    "setId": 28,
    "animType": null
  },
  "736": {
    "id": 736,
    "displayName": "Mighty Masked Maniac Helmet",
    "name": "Masked Maniac Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 737,
    "tierItemIds": [
      735,
      736,
      737
    ],
    "setId": 28,
    "animType": null
  },
  "737": {
    "id": 737,
    "displayName": "Superior Masked Maniac Helmet",
    "name": "Masked Maniac Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      735,
      736,
      737
    ],
    "setId": 28,
    "animType": null
  },
  "738": {
    "id": 738,
    "displayName": "Masked Maniac Helmet",
    "name": "Masked Maniac Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 17 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 739,
    "tierItemIds": [
      738,
      739,
      740
    ],
    "setId": 28,
    "animType": null
  },
  "739": {
    "id": 739,
    "displayName": "Mighty Masked Maniac Helmet",
    "name": "Masked Maniac Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 25 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 740,
    "tierItemIds": [
      738,
      739,
      740
    ],
    "setId": 28,
    "animType": null
  },
  "740": {
    "id": 740,
    "displayName": "Superior Masked Maniac Helmet",
    "name": "Masked Maniac Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 18,
      "Critical Damage": 0,
      "STR": 9,
      "AGI": 0,
      "INT": 8,
      "LUK": 1,
      "HP": 176,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Lifesteal",
          "description": "Heals 33 HP per attack."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      738,
      739,
      740
    ],
    "setId": 28,
    "animType": null
  },
  "741": {
    "id": 741,
    "displayName": "Masked Maniac Pants",
    "name": "Masked Maniac Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 742,
    "tierItemIds": [
      741,
      742,
      743
    ],
    "setId": 28,
    "animType": null
  },
  "742": {
    "id": 742,
    "displayName": "Mighty Masked Maniac Pants",
    "name": "Masked Maniac Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 7,
      "INT": 1,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 743,
    "tierItemIds": [
      741,
      742,
      743
    ],
    "setId": 28,
    "animType": null
  },
  "743": {
    "id": 743,
    "displayName": "Superior Masked Maniac Pants",
    "name": "Masked Maniac Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 10,
      "INT": 2,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 3,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      741,
      742,
      743
    ],
    "setId": 28,
    "animType": null
  },
  "744": {
    "id": 744,
    "displayName": "Masked Maniac Pants",
    "name": "Masked Maniac Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 41,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 7,
      "INT": 1,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 745,
    "tierItemIds": [
      744,
      745,
      746
    ],
    "setId": 28,
    "animType": null
  },
  "745": {
    "id": 745,
    "displayName": "Mighty Masked Maniac Pants",
    "name": "Masked Maniac Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 41,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 10,
      "INT": 2,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 3,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 746,
    "tierItemIds": [
      744,
      745,
      746
    ],
    "setId": 28,
    "animType": null
  },
  "746": {
    "id": 746,
    "displayName": "Superior Masked Maniac Pants",
    "name": "Masked Maniac Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 41,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 14,
      "INT": 3,
      "LUK": 0,
      "HP": 88,
      "Critical": 0,
      "Evade": 5,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      744,
      745,
      746
    ],
    "setId": 28,
    "animType": null
  },
  "750": {
    "id": 750,
    "displayName": "Honey Comb Staff",
    "name": "Honey Comb Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 93,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Absorb",
          "description": "Gets a 30% chance of absorbing 35 damage taken and recovering as HP."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      750
    ],
    "setId": 29,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "751": {
    "id": 751,
    "displayName": "Honey Comb Helmet",
    "name": "Honey Comb Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 58,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      751
    ],
    "setId": 29,
    "animType": null
  },
  "752": {
    "id": 752,
    "displayName": "Honey Comb Armor",
    "name": "Honey Comb Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 116,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      752
    ],
    "setId": 29,
    "animType": null
  },
  "753": {
    "id": 753,
    "displayName": "Honey Comb Gloves",
    "name": "Honey Comb Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 6,
      "Critical Damage": 1,
      "STR": 3,
      "AGI": 0,
      "INT": 16,
      "LUK": 2,
      "HP": 116,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      753
    ],
    "setId": 29,
    "animType": null
  },
  "754": {
    "id": 754,
    "displayName": "Honey Comb Pants",
    "name": "Honey Comb Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 116,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      754
    ],
    "setId": 29,
    "animType": null
  },
  "755": {
    "id": 755,
    "displayName": "Honey Comb Boots",
    "name": "Honey Comb Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      755
    ],
    "setId": 29,
    "animType": null
  },
  "756": {
    "id": 756,
    "displayName": "Tengu Mask",
    "name": "Tengu Mask Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 2,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 20,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 4,
      "INT": 5,
      "LUK": 3,
      "HP": 93,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Disarm",
          "description": "Attacks have a 5% chance of disarming the enemy, rendering them unable to perform a normal attack for 1 turn."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 757,
    "tierItemIds": [
      756,
      757,
      758
    ],
    "animType": null
  },
  "757": {
    "id": 757,
    "displayName": "Mighty Tengu Mask",
    "name": "Tengu Mask Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 2,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 18,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 6,
      "INT": 7,
      "LUK": 4,
      "HP": 131,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Disarm",
          "description": "Attacks have a 10% chance of disarming the enemy, rendering them unable to perform a normal attack for 1 turn."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 758,
    "tierItemIds": [
      756,
      757,
      758
    ],
    "animType": null
  },
  "758": {
    "id": 758,
    "displayName": "Superior Tengu Mask",
    "name": "Tengu Mask Helmet",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 2,
    "minted": 0,
    "requiredLevel": 60,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 26,
      "Magical Defense": 8,
      "Physical Defense": 18,
      "Critical Damage": 0,
      "STR": 15,
      "AGI": 9,
      "INT": 10,
      "LUK": 6,
      "HP": 184,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Disarm",
          "description": "Attacks have a 15% chance of disarming the enemy, rendering them unable to perform a normal attack for 1 turn."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      756,
      757,
      758
    ],
    "animType": null
  },
  "759": {
    "id": 759,
    "displayName": "Heavenly Stomps",
    "name": "Heavenly Stomps Boots",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 60,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 8,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 3,
      "HP": 0,
      "Critical": 5,
      "Evade": 5,
      "Speed": 3,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Disarm",
          "description": "Attacks have a 5% chance of disarming the enemy, rendering them unable to perform a normal attack for 1 turn."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 760,
    "tierItemIds": [
      759,
      760,
      761
    ],
    "animType": null
  },
  "760": {
    "id": 760,
    "displayName": "Mighty Heavenly Stomps",
    "name": "Heavenly Stomps Boots",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 12,
      "STR": 0,
      "AGI": 11,
      "INT": 0,
      "LUK": 5,
      "HP": 0,
      "Critical": 7,
      "Evade": 7,
      "Speed": 5,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Disarm",
          "description": "Attacks have a 10% chance of disarming the enemy, rendering them unable to perform a normal attack for 1 turn."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 761,
    "tierItemIds": [
      759,
      760,
      761
    ],
    "animType": null
  },
  "761": {
    "id": 761,
    "displayName": "Superior Heavenly Stomps",
    "name": "Heavenly Stomps Boots",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 50,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 10,
      "Critical Damage": 18,
      "STR": 0,
      "AGI": 16,
      "INT": 0,
      "LUK": 8,
      "HP": 0,
      "Critical": 10,
      "Evade": 10,
      "Speed": 8,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Disarm",
          "description": "Attacks have a 15% chance of disarming the enemy, rendering them unable to perform a normal attack for 1 turn."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      759,
      760,
      761
    ],
    "animType": null
  },
  "762": {
    "id": 762,
    "displayName": "Potence Staff",
    "name": "Potence Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 26,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 763,
    "tierItemIds": [
      762,
      763,
      764
    ],
    "setId": 30,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "763": {
    "id": 763,
    "displayName": "Mighty Potence Staff",
    "name": "Potence Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 37,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 764,
    "tierItemIds": [
      762,
      763,
      764
    ],
    "setId": 30,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "764": {
    "id": 764,
    "displayName": "Superior Potence Staff",
    "name": "Potence Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 53,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      762,
      763,
      764
    ],
    "setId": 30,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "765": {
    "id": 765,
    "displayName": "Potence Staff",
    "name": "Potence Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 37,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 766,
    "tierItemIds": [
      765,
      766,
      767
    ],
    "setId": 30,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "766": {
    "id": 766,
    "displayName": "Mighty Potence Staff",
    "name": "Potence Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 53,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 767,
    "tierItemIds": [
      765,
      766,
      767
    ],
    "setId": 30,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "767": {
    "id": 767,
    "displayName": "Superior Potence Staff",
    "name": "Potence Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 75,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 14,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      765,
      766,
      767
    ],
    "setId": 30,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "768": {
    "id": 768,
    "displayName": "Potence Staff",
    "name": "Potence Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 53,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Root",
          "description": "Attacks have 5% chance of rooting the enemy for 1 turn."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 769,
    "tierItemIds": [
      768,
      769,
      770
    ],
    "setId": 30,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "769": {
    "id": 769,
    "displayName": "Mighty Potence Staff",
    "name": "Potence Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 75,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 14,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Root",
          "description": "Attacks have 7% chance of rooting the enemy for 1 turn."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 770,
    "tierItemIds": [
      768,
      769,
      770
    ],
    "setId": 30,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "770": {
    "id": 770,
    "displayName": "Superior Potence Staff",
    "name": "Potence Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 106,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 20,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Root",
          "description": "Attacks have 9% chance of rooting the enemy for 1 turn."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      768,
      769,
      770
    ],
    "setId": 30,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "771": {
    "id": 771,
    "displayName": "Potence Duster",
    "name": "Potence Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 1440,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 772,
    "tierItemIds": [
      771,
      772,
      773
    ],
    "setId": 30,
    "animType": null
  },
  "772": {
    "id": 772,
    "displayName": "Mighty Potence Duster",
    "name": "Potence Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 46,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 773,
    "tierItemIds": [
      771,
      772,
      773
    ],
    "setId": 30,
    "animType": null
  },
  "773": {
    "id": 773,
    "displayName": "Superior Potence Duster",
    "name": "Potence Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 14,
      "LUK": 0,
      "HP": 65,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      771,
      772,
      773
    ],
    "setId": 30,
    "animType": null
  },
  "774": {
    "id": 774,
    "displayName": "Potence Duster",
    "name": "Potence Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 960,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 46,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 775,
    "tierItemIds": [
      774,
      775,
      776
    ],
    "setId": 30,
    "animType": null
  },
  "775": {
    "id": 775,
    "displayName": "Mighty Potence Duster",
    "name": "Potence Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 14,
      "LUK": 0,
      "HP": 65,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 776,
    "tierItemIds": [
      774,
      775,
      776
    ],
    "setId": 30,
    "animType": null
  },
  "776": {
    "id": 776,
    "displayName": "Superior Potence Duster",
    "name": "Potence Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 20,
      "LUK": 0,
      "HP": 92,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      774,
      775,
      776
    ],
    "setId": 30,
    "animType": null
  },
  "777": {
    "id": 777,
    "displayName": "Potence Boots",
    "name": "Potence Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 1440,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 778,
    "tierItemIds": [
      777,
      778,
      779
    ],
    "setId": 30,
    "animType": null
  },
  "778": {
    "id": 778,
    "displayName": "Mighty Potence Boots",
    "name": "Potence Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 46,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 779,
    "tierItemIds": [
      777,
      778,
      779
    ],
    "setId": 30,
    "animType": null
  },
  "779": {
    "id": 779,
    "displayName": "Superior Potence Boots",
    "name": "Potence Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 65,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      777,
      778,
      779
    ],
    "setId": 30,
    "animType": null
  },
  "780": {
    "id": 780,
    "displayName": "Potence Boots",
    "name": "Potence Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 960,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 46,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 781,
    "tierItemIds": [
      780,
      781,
      782
    ],
    "setId": 30,
    "animType": null
  },
  "781": {
    "id": 781,
    "displayName": "Mighty Potence Boots",
    "name": "Potence Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 65,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 782,
    "tierItemIds": [
      780,
      781,
      782
    ],
    "setId": 30,
    "animType": null
  },
  "782": {
    "id": 782,
    "displayName": "Superior Potence Boots",
    "name": "Potence Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 91,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      780,
      781,
      782
    ],
    "setId": 30,
    "animType": null
  },
  "783": {
    "id": 783,
    "displayName": "Potence Gloves",
    "name": "Potence Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 1440,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 1,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 32,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 784,
    "tierItemIds": [
      783,
      784,
      785
    ],
    "setId": 30,
    "animType": null
  },
  "784": {
    "id": 784,
    "displayName": "Mighty Potence Gloves",
    "name": "Potence Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 7,
      "STR": 2,
      "AGI": 0,
      "INT": 10,
      "LUK": 2,
      "HP": 46,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 785,
    "tierItemIds": [
      783,
      784,
      785
    ],
    "setId": 30,
    "animType": null
  },
  "785": {
    "id": 785,
    "displayName": "Superior Potence Gloves",
    "name": "Potence Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 10,
      "STR": 4,
      "AGI": 0,
      "INT": 15,
      "LUK": 4,
      "HP": 65,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      783,
      784,
      785
    ],
    "setId": 30,
    "animType": null
  },
  "786": {
    "id": 786,
    "displayName": "Potence Gloves",
    "name": "Potence Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 960,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 7,
      "STR": 2,
      "AGI": 0,
      "INT": 10,
      "LUK": 2,
      "HP": 46,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 787,
    "tierItemIds": [
      786,
      787,
      788
    ],
    "setId": 30,
    "animType": null
  },
  "787": {
    "id": 787,
    "displayName": "Mighty Potence Gloves",
    "name": "Potence Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 10,
      "STR": 4,
      "AGI": 0,
      "INT": 15,
      "LUK": 4,
      "HP": 65,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 788,
    "tierItemIds": [
      786,
      787,
      788
    ],
    "setId": 30,
    "animType": null
  },
  "788": {
    "id": 788,
    "displayName": "Superior Potence Gloves",
    "name": "Potence Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 15,
      "STR": 6,
      "AGI": 0,
      "INT": 21,
      "LUK": 6,
      "HP": 92,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      786,
      787,
      788
    ],
    "setId": 30,
    "animType": null
  },
  "789": {
    "id": 789,
    "displayName": "Potence Diadem",
    "name": "Potence Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 1920,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 1,
      "HP": 20,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 790,
    "tierItemIds": [
      789,
      790,
      791
    ],
    "setId": 30,
    "animType": null
  },
  "790": {
    "id": 790,
    "displayName": "Mighty Potence Diadem",
    "name": "Potence Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 2,
      "HP": 29,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 791,
    "tierItemIds": [
      789,
      790,
      791
    ],
    "setId": 30,
    "animType": null
  },
  "791": {
    "id": 791,
    "displayName": "Superior Potence Diadem",
    "name": "Potence Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 6,
      "LUK": 4,
      "HP": 41,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      789,
      790,
      791
    ],
    "setId": 30,
    "animType": null
  },
  "792": {
    "id": 792,
    "displayName": "Potence Diadem",
    "name": "Potence Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 1440,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 2,
      "HP": 29,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 793,
    "tierItemIds": [
      792,
      793,
      794
    ],
    "setId": 30,
    "animType": null
  },
  "793": {
    "id": 793,
    "displayName": "Mighty Potence Diadem",
    "name": "Potence Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 6,
      "LUK": 4,
      "HP": 41,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 794,
    "tierItemIds": [
      792,
      793,
      794
    ],
    "setId": 30,
    "animType": null
  },
  "794": {
    "id": 794,
    "displayName": "Superior Potence Diadem",
    "name": "Potence Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 9,
      "LUK": 6,
      "HP": 58,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      792,
      793,
      794
    ],
    "setId": 30,
    "animType": null
  },
  "795": {
    "id": 795,
    "displayName": "Potence Pants",
    "name": "Potence Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 1920,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 20,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 796,
    "tierItemIds": [
      795,
      796,
      797
    ],
    "setId": 30,
    "animType": null
  },
  "796": {
    "id": 796,
    "displayName": "Mighty Potence Pants",
    "name": "Potence Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 29,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 797,
    "tierItemIds": [
      795,
      796,
      797
    ],
    "setId": 30,
    "animType": null
  },
  "797": {
    "id": 797,
    "displayName": "Superior Potence Pants",
    "name": "Potence Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 41,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      795,
      796,
      797
    ],
    "setId": 30,
    "animType": null
  },
  "798": {
    "id": 798,
    "displayName": "Potence Pants",
    "name": "Potence Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 1440,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 29,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 799,
    "tierItemIds": [
      798,
      799,
      800
    ],
    "setId": 30,
    "animType": null
  },
  "799": {
    "id": 799,
    "displayName": "Mighty Potence Pants",
    "name": "Potence Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 41,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 800,
    "tierItemIds": [
      798,
      799,
      800
    ],
    "setId": 30,
    "animType": null
  },
  "800": {
    "id": 800,
    "displayName": "Superior Potence Pants",
    "name": "Potence Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 25,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 58,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      798,
      799,
      800
    ],
    "setId": 30,
    "animType": null
  },
  "801": {
    "id": 801,
    "displayName": "Piraslaus Sword",
    "name": "Piraslaus Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 17,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 802,
    "tierItemIds": [
      801,
      802,
      803
    ],
    "setId": 31,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "802": {
    "id": 802,
    "displayName": "Mighty Piraslaus Sword",
    "name": "Piraslaus Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 25,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 803,
    "tierItemIds": [
      801,
      802,
      803
    ],
    "setId": 31,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "803": {
    "id": 803,
    "displayName": "Superior Piraslaus Sword",
    "name": "Piraslaus Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      801,
      802,
      803
    ],
    "setId": 31,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "804": {
    "id": 804,
    "displayName": "Piraslaus Sword",
    "name": "Piraslaus Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 25,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 805,
    "tierItemIds": [
      804,
      805,
      806
    ],
    "setId": 31,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "805": {
    "id": 805,
    "displayName": "Mighty Piraslaus Sword",
    "name": "Piraslaus Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 806,
    "tierItemIds": [
      804,
      805,
      806
    ],
    "setId": 31,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "806": {
    "id": 806,
    "displayName": "Superior Piraslaus Sword",
    "name": "Piraslaus Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 50,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      804,
      805,
      806
    ],
    "setId": 31,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "807": {
    "id": 807,
    "displayName": "Piraslaus Sword",
    "name": "Piraslaus Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Blind",
          "description": "Attacks have a 5% chance of disabling the enemies' ranged attacks or ranged spells for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 808,
    "tierItemIds": [
      807,
      808,
      809
    ],
    "setId": 31,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "808": {
    "id": 808,
    "displayName": "Mighty Piraslaus Sword",
    "name": "Piraslaus Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 50,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Blind",
          "description": "Attacks have a 7% chance of disabling the enemies' ranged attacks or ranged spells for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 809,
    "tierItemIds": [
      807,
      808,
      809
    ],
    "setId": 31,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "809": {
    "id": 809,
    "displayName": "Superior Piraslaus Sword",
    "name": "Piraslaus Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 71,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Blind",
          "description": "Attacks have a 9% chance of disabling the enemies' ranged attacks or ranged spells for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      807,
      808,
      809
    ],
    "setId": 31,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "810": {
    "id": 810,
    "displayName": "Piraslaus Handgonne",
    "name": "Piraslaus Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 1,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 811,
    "tierItemIds": [
      810,
      811,
      812
    ],
    "setId": 31,
    "animType": null,
    "damageType": "Physical"
  },
  "811": {
    "id": 811,
    "displayName": "Mighty Piraslaus Handgonne",
    "name": "Piraslaus Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 1,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 17,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 812,
    "tierItemIds": [
      810,
      811,
      812
    ],
    "setId": 31,
    "animType": null,
    "damageType": "Physical"
  },
  "812": {
    "id": 812,
    "displayName": "Superior Piraslaus Handgonne",
    "name": "Piraslaus Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 1,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 25,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      810,
      811,
      812
    ],
    "setId": 31,
    "animType": null,
    "damageType": "Physical"
  },
  "813": {
    "id": 813,
    "displayName": "Piraslaus Hand Handgonne",
    "name": "Piraslaus Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 17,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 814,
    "tierItemIds": [
      813,
      814,
      815
    ],
    "setId": 31,
    "animType": null,
    "damageType": "Physical"
  },
  "814": {
    "id": 814,
    "displayName": "Mighty Piraslaus Hand Handgonne",
    "name": "Piraslaus Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 25,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 815,
    "tierItemIds": [
      813,
      814,
      815
    ],
    "setId": 31,
    "animType": null,
    "damageType": "Physical"
  },
  "815": {
    "id": 815,
    "displayName": "Superior Piraslaus Hand Handgonne",
    "name": "Piraslaus Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 16,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      813,
      814,
      815
    ],
    "setId": 31,
    "animType": null,
    "damageType": "Physical"
  },
  "816": {
    "id": 816,
    "displayName": "Piraslaus Cape",
    "name": "Piraslaus Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 2250,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 817,
    "tierItemIds": [
      816,
      817,
      818
    ],
    "setId": 31,
    "animType": null
  },
  "817": {
    "id": 817,
    "displayName": "Mighty Piraslaus Cape",
    "name": "Piraslaus Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 818,
    "tierItemIds": [
      816,
      817,
      818
    ],
    "setId": 31,
    "animType": null
  },
  "818": {
    "id": 818,
    "displayName": "Superior Piraslaus Cape",
    "name": "Piraslaus Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 9,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      816,
      817,
      818
    ],
    "setId": 31,
    "animType": null
  },
  "819": {
    "id": 819,
    "displayName": "Piraslaus Cape",
    "name": "Piraslaus Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 1500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 820,
    "tierItemIds": [
      819,
      820,
      821
    ],
    "setId": 31,
    "animType": null
  },
  "820": {
    "id": 820,
    "displayName": "Mighty Piraslaus Cape",
    "name": "Piraslaus Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 9,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 821,
    "tierItemIds": [
      819,
      820,
      821
    ],
    "setId": 31,
    "animType": null
  },
  "821": {
    "id": 821,
    "displayName": "Superior Piraslaus Cape",
    "name": "Piraslaus Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 13,
      "LUK": 0,
      "HP": 113,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      819,
      820,
      821
    ],
    "setId": 31,
    "animType": null
  },
  "822": {
    "id": 822,
    "displayName": "Piraslaus Boots",
    "name": "Piraslaus Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 2250,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 823,
    "tierItemIds": [
      822,
      823,
      824
    ],
    "setId": 31,
    "animType": null
  },
  "823": {
    "id": 823,
    "displayName": "Mighty Piraslaus Boots",
    "name": "Piraslaus Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 824,
    "tierItemIds": [
      822,
      823,
      824
    ],
    "setId": 31,
    "animType": null
  },
  "824": {
    "id": 824,
    "displayName": "Superior Piraslaus Boots",
    "name": "Piraslaus Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      822,
      823,
      824
    ],
    "setId": 31,
    "animType": null
  },
  "825": {
    "id": 825,
    "displayName": "Piraslaus Boots",
    "name": "Piraslaus Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 1500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 826,
    "tierItemIds": [
      825,
      826,
      827
    ],
    "setId": 31,
    "animType": null
  },
  "826": {
    "id": 826,
    "displayName": "Mighty Piraslaus Boots",
    "name": "Piraslaus Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 827,
    "tierItemIds": [
      825,
      826,
      827
    ],
    "setId": 31,
    "animType": null
  },
  "827": {
    "id": 827,
    "displayName": "Superior Piraslaus Boots",
    "name": "Piraslaus Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      825,
      826,
      827
    ],
    "setId": 31,
    "animType": null
  },
  "828": {
    "id": 828,
    "displayName": "Piraslaus Gloves",
    "name": "Piraslaus Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 2250,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 829,
    "tierItemIds": [
      828,
      829,
      830
    ],
    "setId": 31,
    "animType": null
  },
  "829": {
    "id": 829,
    "displayName": "Mighty Piraslaus Gloves",
    "name": "Piraslaus Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 830,
    "tierItemIds": [
      828,
      829,
      830
    ],
    "setId": 31,
    "animType": null
  },
  "830": {
    "id": 830,
    "displayName": "Superior Piraslaus Gloves",
    "name": "Piraslaus Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 9,
      "LUK": 1,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      828,
      829,
      830
    ],
    "setId": 31,
    "animType": null
  },
  "831": {
    "id": 831,
    "displayName": "Piraslaus Gloves",
    "name": "Piraslaus Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 1500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 832,
    "tierItemIds": [
      831,
      832,
      833
    ],
    "setId": 31,
    "animType": null
  },
  "832": {
    "id": 832,
    "displayName": "Mighty Piraslaus Gloves",
    "name": "Piraslaus Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 9,
      "LUK": 1,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 833,
    "tierItemIds": [
      831,
      832,
      833
    ],
    "setId": 31,
    "animType": null
  },
  "833": {
    "id": 833,
    "displayName": "Superior Piraslaus Gloves",
    "name": "Piraslaus Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 13,
      "LUK": 2,
      "HP": 113,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      831,
      832,
      833
    ],
    "setId": 31,
    "animType": null
  },
  "834": {
    "id": 834,
    "displayName": "Piraslaus Hat",
    "name": "Piraslaus Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 3000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 3,
      "STR": 2,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 835,
    "tierItemIds": [
      834,
      835,
      836
    ],
    "setId": 31,
    "animType": null
  },
  "835": {
    "id": 835,
    "displayName": "Mighty Piraslaus Hat",
    "name": "Piraslaus Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 836,
    "tierItemIds": [
      834,
      835,
      836
    ],
    "setId": 31,
    "animType": null
  },
  "836": {
    "id": 836,
    "displayName": "Superior Piraslaus Hat",
    "name": "Piraslaus Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 8,
      "STR": 5,
      "AGI": 0,
      "INT": 4,
      "LUK": 1,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      834,
      835,
      836
    ],
    "setId": 31,
    "animType": null
  },
  "837": {
    "id": 837,
    "displayName": "Piraslaus Hat",
    "name": "Piraslaus Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 2250,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 838,
    "tierItemIds": [
      837,
      838,
      839
    ],
    "setId": 31,
    "animType": null
  },
  "838": {
    "id": 838,
    "displayName": "Mighty Piraslaus Hat",
    "name": "Piraslaus Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 8,
      "STR": 5,
      "AGI": 0,
      "INT": 4,
      "LUK": 1,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 839,
    "tierItemIds": [
      837,
      838,
      839
    ],
    "setId": 31,
    "animType": null
  },
  "839": {
    "id": 839,
    "displayName": "Superior Piraslaus Hat",
    "name": "Piraslaus Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 12,
      "STR": 8,
      "AGI": 0,
      "INT": 6,
      "LUK": 2,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      837,
      838,
      839
    ],
    "setId": 31,
    "animType": null
  },
  "840": {
    "id": 840,
    "displayName": "Piraslaus Pants",
    "name": "Piraslaus Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 3000,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 841,
    "tierItemIds": [
      840,
      841,
      842
    ],
    "setId": 31,
    "animType": null
  },
  "841": {
    "id": 841,
    "displayName": "Mighty Piraslaus Pants",
    "name": "Piraslaus Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 842,
    "tierItemIds": [
      840,
      841,
      842
    ],
    "setId": 31,
    "animType": null
  },
  "842": {
    "id": 842,
    "displayName": "Superior Piraslaus Pants",
    "name": "Piraslaus Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      840,
      841,
      842
    ],
    "setId": 31,
    "animType": null
  },
  "843": {
    "id": 843,
    "displayName": "Piraslaus Pants",
    "name": "Piraslaus Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 2250,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 844,
    "tierItemIds": [
      843,
      844,
      845
    ],
    "setId": 31,
    "animType": null
  },
  "844": {
    "id": 844,
    "displayName": "Mighty Piraslaus Pants",
    "name": "Piraslaus Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 845,
    "tierItemIds": [
      843,
      844,
      845
    ],
    "setId": 31,
    "animType": null
  },
  "845": {
    "id": 845,
    "displayName": "Superior Piraslaus Pants",
    "name": "Piraslaus Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      843,
      844,
      845
    ],
    "setId": 31,
    "animType": null
  },
  "846": {
    "id": 846,
    "displayName": "Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 14,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 847,
    "tierItemIds": [
      846,
      847,
      848
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "847": {
    "id": 847,
    "displayName": "Mighty Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 20,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 848,
    "tierItemIds": [
      846,
      847,
      848
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "848": {
    "id": 848,
    "displayName": "Superior Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 28,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      846,
      847,
      848
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "849": {
    "id": 849,
    "displayName": "Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 20,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 850,
    "tierItemIds": [
      849,
      850,
      851
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "850": {
    "id": 850,
    "displayName": "Mighty Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 28,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 851,
    "tierItemIds": [
      849,
      850,
      851
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "851": {
    "id": 851,
    "displayName": "Superior Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 40,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      849,
      850,
      851
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "852": {
    "id": 852,
    "displayName": "Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 28,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Silent",
          "description": "Attacks have a 5% chance of dealing 20 extra Physical Damage to the enemy and silencing them for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 853,
    "tierItemIds": [
      852,
      853,
      854
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "853": {
    "id": 853,
    "displayName": "Mighty Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 40,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Silent",
          "description": "Attacks have a 10% chance of dealing 30 extra Physical Damage to the enemy and silencing them for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 854,
    "tierItemIds": [
      852,
      853,
      854
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "854": {
    "id": 854,
    "displayName": "Superior Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 57,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Silent",
          "description": "Attacks have a 15% chance of dealing 40 extra Physical Damage to the enemy and silencing them for 1 turn(s)."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      852,
      853,
      854
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "855": {
    "id": 855,
    "displayName": "Enigcrypt Shield",
    "name": "Enigcrypt Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 1,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 856,
    "tierItemIds": [
      855,
      856,
      857
    ],
    "setId": 32,
    "animType": null,
    "damageType": "Physical"
  },
  "856": {
    "id": 856,
    "displayName": "Mighty Enigcrypt Shield",
    "name": "Enigcrypt Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 1,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 857,
    "tierItemIds": [
      855,
      856,
      857
    ],
    "setId": 32,
    "animType": null,
    "damageType": "Physical"
  },
  "857": {
    "id": 857,
    "displayName": "Superior Enigcrypt Shield",
    "name": "Enigcrypt Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 1,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 7,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      855,
      856,
      857
    ],
    "setId": 32,
    "animType": null,
    "damageType": "Physical"
  },
  "858": {
    "id": 858,
    "displayName": "Enigcrypt Shield",
    "name": "Enigcrypt Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 859,
    "tierItemIds": [
      858,
      859,
      860
    ],
    "setId": 32,
    "animType": null,
    "damageType": "Physical"
  },
  "859": {
    "id": 859,
    "displayName": "Mighty Enigcrypt Shield",
    "name": "Enigcrypt Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 7,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 860,
    "tierItemIds": [
      858,
      859,
      860
    ],
    "setId": 32,
    "animType": null,
    "damageType": "Physical"
  },
  "860": {
    "id": 860,
    "displayName": "Superior Enigcrypt Shield",
    "name": "Enigcrypt Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 10,
      "HP": 122,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      858,
      859,
      860
    ],
    "setId": 32,
    "animType": null,
    "damageType": "Physical"
  },
  "861": {
    "id": 861,
    "displayName": "Enigcrypt Armor",
    "name": "Enigcrypt Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 3,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 862,
    "tierItemIds": [
      861,
      862,
      863
    ],
    "setId": 32,
    "animType": null
  },
  "862": {
    "id": 862,
    "displayName": "Mighty Enigcrypt Armor",
    "name": "Enigcrypt Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 5,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 863,
    "tierItemIds": [
      861,
      862,
      863
    ],
    "setId": 32,
    "animType": null
  },
  "863": {
    "id": 863,
    "displayName": "Superior Enigcrypt Armor",
    "name": "Enigcrypt Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 67,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      861,
      862,
      863
    ],
    "setId": 32,
    "animType": null
  },
  "864": {
    "id": 864,
    "displayName": "Enigcrypt Armor",
    "name": "Enigcrypt Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 5,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 53,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 865,
    "tierItemIds": [
      864,
      865,
      866
    ],
    "setId": 32,
    "animType": null
  },
  "865": {
    "id": 865,
    "displayName": "Mighty Enigcrypt Armor",
    "name": "Enigcrypt Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 75,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 866,
    "tierItemIds": [
      864,
      865,
      866
    ],
    "setId": 32,
    "animType": null
  },
  "866": {
    "id": 866,
    "displayName": "Superior Enigcrypt Armor",
    "name": "Enigcrypt Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 11,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 12,
      "LUK": 0,
      "HP": 105,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      864,
      865,
      866
    ],
    "setId": 32,
    "animType": null
  },
  "867": {
    "id": 867,
    "displayName": "Enigcrypt Boots",
    "name": "Enigcrypt Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 8,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 868,
    "tierItemIds": [
      867,
      868,
      869
    ],
    "setId": 32,
    "animType": null
  },
  "868": {
    "id": 868,
    "displayName": "Mighty Enigcrypt Boots",
    "name": "Enigcrypt Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 1,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 12,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 869,
    "tierItemIds": [
      867,
      868,
      869
    ],
    "setId": 32,
    "animType": null
  },
  "869": {
    "id": 869,
    "displayName": "Superior Enigcrypt Boots",
    "name": "Enigcrypt Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 2,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 1,
      "INT": 4,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      867,
      868,
      869
    ],
    "setId": 32,
    "animType": null
  },
  "870": {
    "id": 870,
    "displayName": "Enigcrypt Boots",
    "name": "Enigcrypt Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 1,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 13,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 871,
    "tierItemIds": [
      870,
      871,
      872
    ],
    "setId": 32,
    "animType": null
  },
  "871": {
    "id": 871,
    "displayName": "Mighty Enigcrypt Boots",
    "name": "Enigcrypt Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 2,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 1,
      "INT": 4,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 872,
    "tierItemIds": [
      870,
      871,
      872
    ],
    "setId": 32,
    "animType": null
  },
  "872": {
    "id": 872,
    "displayName": "Superior Enigcrypt Boots",
    "name": "Enigcrypt Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 4,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 2,
      "INT": 6,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 3,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      870,
      871,
      872
    ],
    "setId": 32,
    "animType": null
  },
  "873": {
    "id": 873,
    "displayName": "Enigcrypt Gloves",
    "name": "Enigcrypt Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 3,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 874,
    "tierItemIds": [
      873,
      874,
      875
    ],
    "setId": 32,
    "animType": null
  },
  "874": {
    "id": 874,
    "displayName": "Mighty Enigcrypt Gloves",
    "name": "Enigcrypt Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 5,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 42,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 875,
    "tierItemIds": [
      873,
      874,
      875
    ],
    "setId": 32,
    "animType": null
  },
  "875": {
    "id": 875,
    "displayName": "Superior Enigcrypt Gloves",
    "name": "Enigcrypt Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 8,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 60,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      873,
      874,
      875
    ],
    "setId": 32,
    "animType": null
  },
  "876": {
    "id": 876,
    "displayName": "Enigcrypt Gloves",
    "name": "Enigcrypt Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 5,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 47,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 877,
    "tierItemIds": [
      876,
      877,
      878
    ],
    "setId": 32,
    "animType": null
  },
  "877": {
    "id": 877,
    "displayName": "Mighty Enigcrypt Gloves",
    "name": "Enigcrypt Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 8,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 67,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 878,
    "tierItemIds": [
      876,
      877,
      878
    ],
    "setId": 32,
    "animType": null
  },
  "878": {
    "id": 878,
    "displayName": "Superior Enigcrypt Gloves",
    "name": "Enigcrypt Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 12,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 12,
      "LUK": 3,
      "HP": 94,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      876,
      877,
      878
    ],
    "setId": 32,
    "animType": null
  },
  "879": {
    "id": 879,
    "displayName": "Enigcrypt Diadem",
    "name": "Enigcrypt Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 31,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 3,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 880,
    "tierItemIds": [
      879,
      880,
      881
    ],
    "setId": 32,
    "animType": null
  },
  "880": {
    "id": 880,
    "displayName": "Mighty Enigcrypt Diadem",
    "name": "Enigcrypt Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 31,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 5,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 1,
      "Critical Damage": 5,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 42,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 881,
    "tierItemIds": [
      879,
      880,
      881
    ],
    "setId": 32,
    "animType": null
  },
  "881": {
    "id": 881,
    "displayName": "Superior Enigcrypt Diadem",
    "name": "Enigcrypt Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 31,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 2,
      "Critical Damage": 7,
      "STR": 3,
      "AGI": 0,
      "INT": 11,
      "LUK": 2,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      879,
      880,
      881
    ],
    "setId": 32,
    "animType": null
  },
  "882": {
    "id": 882,
    "displayName": "Enigcrypt Diadem",
    "name": "Enigcrypt Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 31,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 5,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 1,
      "Critical Damage": 5,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 883,
    "tierItemIds": [
      882,
      883,
      884
    ],
    "setId": 32,
    "animType": null
  },
  "883": {
    "id": 883,
    "displayName": "Mighty Enigcrypt Diadem",
    "name": "Enigcrypt Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 31,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 2,
      "Critical Damage": 7,
      "STR": 3,
      "AGI": 0,
      "INT": 11,
      "LUK": 2,
      "HP": 67,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 884,
    "tierItemIds": [
      882,
      883,
      884
    ],
    "setId": 32,
    "animType": null
  },
  "884": {
    "id": 884,
    "displayName": "Superior Enigcrypt Diadem",
    "name": "Enigcrypt Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 31,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 11,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 4,
      "Critical Damage": 11,
      "STR": 5,
      "AGI": 0,
      "INT": 16,
      "LUK": 4,
      "HP": 94,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      882,
      883,
      884
    ],
    "setId": 32,
    "animType": null
  },
  "885": {
    "id": 885,
    "displayName": "Enigcrypt Pants",
    "name": "Enigcrypt Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 8,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 886,
    "tierItemIds": [
      885,
      886,
      887
    ],
    "setId": 32,
    "animType": null
  },
  "886": {
    "id": 886,
    "displayName": "Mighty Enigcrypt Pants",
    "name": "Enigcrypt Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 1,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 12,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 887,
    "tierItemIds": [
      885,
      886,
      887
    ],
    "setId": 32,
    "animType": null
  },
  "887": {
    "id": 887,
    "displayName": "Superior Enigcrypt Pants",
    "name": "Enigcrypt Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 2,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      885,
      886,
      887
    ],
    "setId": 32,
    "animType": null
  },
  "888": {
    "id": 888,
    "displayName": "Enigcrypt Pants",
    "name": "Enigcrypt Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 1,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 13,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 889,
    "tierItemIds": [
      888,
      889,
      890
    ],
    "setId": 32,
    "animType": null
  },
  "889": {
    "id": 889,
    "displayName": "Mighty Enigcrypt Pants",
    "name": "Enigcrypt Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 2,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 890,
    "tierItemIds": [
      888,
      889,
      890
    ],
    "setId": 32,
    "animType": null
  },
  "890": {
    "id": 890,
    "displayName": "Superior Enigcrypt Pants",
    "name": "Enigcrypt Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 4,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 6,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 4,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      888,
      889,
      890
    ],
    "setId": 32,
    "animType": null
  },
  "891": {
    "id": 891,
    "displayName": "Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 22,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 4,
    "nextTierId": 892,
    "tierItemIds": [
      891,
      892,
      893
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "892": {
    "id": 892,
    "displayName": "Mighty Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 32,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 893,
    "tierItemIds": [
      891,
      892,
      893
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "893": {
    "id": 893,
    "displayName": "Superior Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 1,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 45,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      891,
      892,
      893
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "894": {
    "id": 894,
    "displayName": "Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 32,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 895,
    "tierItemIds": [
      894,
      895,
      896
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "895": {
    "id": 895,
    "displayName": "Mighty Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 45,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 896,
    "tierItemIds": [
      894,
      895,
      896
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "896": {
    "id": 896,
    "displayName": "Superior Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 64,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      894,
      895,
      896
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "897": {
    "id": 897,
    "displayName": "Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 45,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Piercing Strike",
          "description": "Ignores 20 Physical Defense of the target."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 898,
    "tierItemIds": [
      897,
      898,
      899
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "898": {
    "id": 898,
    "displayName": "Mighty Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 64,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Piercing Strike",
          "description": "Ignores 25 Physical Defense of the target."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 2,
    "nextTierId": 899,
    "tierItemIds": [
      897,
      898,
      899
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "899": {
    "id": 899,
    "displayName": "Superior Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 90,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 16,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Piercing Strike",
          "description": "Ignores 30 Physical Defense of the target."
        }
      ]
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      897,
      898,
      899
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "900": {
    "id": 900,
    "displayName": "Astrorius Armor",
    "name": "Astrorius Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 43,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 901,
    "tierItemIds": [
      900,
      901,
      902
    ],
    "setId": 33,
    "animType": null
  },
  "901": {
    "id": 901,
    "displayName": "Mighty Astrorius Armor",
    "name": "Astrorius Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 61,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 902,
    "tierItemIds": [
      900,
      901,
      902
    ],
    "setId": 33,
    "animType": null
  },
  "902": {
    "id": 902,
    "displayName": "Superior Astrorius Armor",
    "name": "Astrorius Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 86,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      900,
      901,
      902
    ],
    "setId": 33,
    "animType": null
  },
  "903": {
    "id": 903,
    "displayName": "Astrorius Armor",
    "name": "Astrorius Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 68,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 904,
    "tierItemIds": [
      903,
      904,
      905
    ],
    "setId": 33,
    "animType": null
  },
  "904": {
    "id": 904,
    "displayName": "Mighty Astrorius Armor",
    "name": "Astrorius Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 96,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 905,
    "tierItemIds": [
      903,
      904,
      905
    ],
    "setId": 33,
    "animType": null
  },
  "905": {
    "id": 905,
    "displayName": "Superior Astrorius Armor",
    "name": "Astrorius Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 15,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 135,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      903,
      904,
      905
    ],
    "setId": 33,
    "animType": null
  },
  "906": {
    "id": 906,
    "displayName": "Astrorius Boots",
    "name": "Astrorius Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 16,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 907,
    "tierItemIds": [
      906,
      907,
      908
    ],
    "setId": 33,
    "animType": null
  },
  "907": {
    "id": 907,
    "displayName": "Mighty Astrorius Boots",
    "name": "Astrorius Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 23,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 908,
    "tierItemIds": [
      906,
      907,
      908
    ],
    "setId": 33,
    "animType": null
  },
  "908": {
    "id": 908,
    "displayName": "Superior Astrorius Boots",
    "name": "Astrorius Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      906,
      907,
      908
    ],
    "setId": 33,
    "animType": null
  },
  "909": {
    "id": 909,
    "displayName": "Astrorius Boots",
    "name": "Astrorius Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 26,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 910,
    "tierItemIds": [
      909,
      910,
      911
    ],
    "setId": 33,
    "animType": null
  },
  "910": {
    "id": 910,
    "displayName": "Mighty Astrorius Boots",
    "name": "Astrorius Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 37,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 911,
    "tierItemIds": [
      909,
      910,
      911
    ],
    "setId": 33,
    "animType": null
  },
  "911": {
    "id": 911,
    "displayName": "Superior Astrorius Boots",
    "name": "Astrorius Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 11,
      "INT": 0,
      "LUK": 0,
      "HP": 53,
      "Critical": 0,
      "Evade": 0,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      909,
      910,
      911
    ],
    "setId": 33,
    "animType": null
  },
  "912": {
    "id": 912,
    "displayName": "Astrorius Gloves",
    "name": "Astrorius Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 913,
    "tierItemIds": [
      912,
      913,
      914
    ],
    "setId": 33,
    "animType": null
  },
  "913": {
    "id": 913,
    "displayName": "Mighty Astrorius Gloves",
    "name": "Astrorius Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 914,
    "tierItemIds": [
      912,
      913,
      914
    ],
    "setId": 33,
    "animType": null
  },
  "914": {
    "id": 914,
    "displayName": "Superior Astrorius Gloves",
    "name": "Astrorius Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 69,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      912,
      913,
      914
    ],
    "setId": 33,
    "animType": null
  },
  "915": {
    "id": 915,
    "displayName": "Astrorius Gloves",
    "name": "Astrorius Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 916,
    "tierItemIds": [
      915,
      916,
      917
    ],
    "setId": 33,
    "animType": null
  },
  "916": {
    "id": 916,
    "displayName": "Mighty Astrorius Gloves",
    "name": "Astrorius Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 77,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 917,
    "tierItemIds": [
      915,
      916,
      917
    ],
    "setId": 33,
    "animType": null
  },
  "917": {
    "id": 917,
    "displayName": "Superior Astrorius Gloves",
    "name": "Astrorius Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 108,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      915,
      916,
      917
    ],
    "setId": 33,
    "animType": null
  },
  "918": {
    "id": 918,
    "displayName": "Astrorius Helmet",
    "name": "Astrorius Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 919,
    "tierItemIds": [
      918,
      919,
      920
    ],
    "setId": 33,
    "animType": null
  },
  "919": {
    "id": 919,
    "displayName": "Mighty Astrorius Helmet",
    "name": "Astrorius Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 920,
    "tierItemIds": [
      918,
      919,
      920
    ],
    "setId": 33,
    "animType": null
  },
  "920": {
    "id": 920,
    "displayName": "Superior Astrorius Helmet",
    "name": "Astrorius Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 2,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 7,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      918,
      919,
      920
    ],
    "setId": 33,
    "animType": null
  },
  "921": {
    "id": 921,
    "displayName": "Astrorius Helmet",
    "name": "Astrorius Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 922,
    "tierItemIds": [
      921,
      922,
      923
    ],
    "setId": 33,
    "animType": null
  },
  "922": {
    "id": 922,
    "displayName": "Mighty Astrorius Helmet",
    "name": "Astrorius Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 7,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 77,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 923,
    "tierItemIds": [
      921,
      922,
      923
    ],
    "setId": 33,
    "animType": null
  },
  "923": {
    "id": 923,
    "displayName": "Superior Astrorius Helmet",
    "name": "Astrorius Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 33,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 11,
      "Critical Damage": 11,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 108,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      921,
      922,
      923
    ],
    "setId": 33,
    "animType": null
  },
  "924": {
    "id": 924,
    "displayName": "Astrorius Pants",
    "name": "Astrorius Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 925,
    "tierItemIds": [
      924,
      925,
      926
    ],
    "setId": 33,
    "animType": null
  },
  "925": {
    "id": 925,
    "displayName": "Mighty Astrorius Pants",
    "name": "Astrorius Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 24,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 926,
    "tierItemIds": [
      924,
      925,
      926
    ],
    "setId": 33,
    "animType": null
  },
  "926": {
    "id": 926,
    "displayName": "Superior Astrorius Pants",
    "name": "Astrorius Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 34,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      924,
      925,
      926
    ],
    "setId": 33,
    "animType": null
  },
  "927": {
    "id": 927,
    "displayName": "Astrorius Pants",
    "name": "Astrorius Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 928,
    "tierItemIds": [
      927,
      928,
      929
    ],
    "setId": 33,
    "animType": null
  },
  "928": {
    "id": 928,
    "displayName": "Mighty Astrorius Pants",
    "name": "Astrorius Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 38,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 2,
    "nextTierId": 929,
    "tierItemIds": [
      927,
      928,
      929
    ],
    "setId": 33,
    "animType": null
  },
  "929": {
    "id": 929,
    "displayName": "Superior Astrorius Pants",
    "name": "Astrorius Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 32,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 54,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      927,
      928,
      929
    ],
    "setId": 33,
    "animType": null
  },
  "930": {
    "id": 930,
    "displayName": "Papilo",
    "name": "Papilo Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 7,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 500,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Thunder Proof",
          "description": "Increase Thunder resistance by 3%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 931,
    "tierItemIds": [
      930,
      931,
      932
    ],
    "animType": null
  },
  "931": {
    "id": 931,
    "displayName": "Mighty Papilo",
    "name": "Papilo Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 7,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Thunder Proof",
          "description": "Increase Thunder resistance by 4%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 4,
    "nextTierId": 932,
    "tierItemIds": [
      930,
      931,
      932
    ],
    "animType": null
  },
  "932": {
    "id": 932,
    "displayName": "Superior Papilo",
    "name": "Papilo Mount",
    "races": [
      "Angel",
      "Demon",
      "Dragonborn",
      "Elf",
      "Fairy",
      "Human",
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 7,
    "minted": 0,
    "requiredLevel": 1,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": [
        {
          "name": "Thunder Proof",
          "description": "Increase Thunder resistance by 5%."
        }
      ]
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      930,
      931,
      932
    ],
    "animType": null
  },
  "933": {
    "id": 933,
    "displayName": "Silent Howl Sword",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 41,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 934,
    "tierItemIds": [
      933,
      934,
      935
    ],
    "setId": 34,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "934": {
    "id": 934,
    "displayName": "Mighty Silent Howl Sword",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 58,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 4,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 935,
    "tierItemIds": [
      933,
      934,
      935
    ],
    "setId": 34,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "935": {
    "id": 935,
    "displayName": "Superior Silent Howl Sword",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 82,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 6,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      933,
      934,
      935
    ],
    "setId": 34,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "936": {
    "id": 936,
    "displayName": "Silent Howl Sword",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 58,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 4,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 937,
    "tierItemIds": [
      936,
      937,
      938
    ],
    "setId": 34,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "937": {
    "id": 937,
    "displayName": "Mighty Silent Howl Sword",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 82,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 6,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 938,
    "tierItemIds": [
      936,
      937,
      938
    ],
    "setId": 34,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "938": {
    "id": 938,
    "displayName": "Superior Silent Howl Sword",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 115,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 9,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      936,
      937,
      938
    ],
    "setId": 34,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "939": {
    "id": 939,
    "displayName": "Silent Howl Sword",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 82,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 6,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 940,
    "tierItemIds": [
      939,
      940,
      941
    ],
    "setId": 34,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "940": {
    "id": 940,
    "displayName": "Mighty Silent Howl Sword",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 115,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 9,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 941,
    "tierItemIds": [
      939,
      940,
      941
    ],
    "setId": 34,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "941": {
    "id": 941,
    "displayName": "Superior Silent Howl Sword",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 162,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 13,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      939,
      940,
      941
    ],
    "setId": 34,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "942": {
    "id": 942,
    "displayName": "Silent Howl Shield",
    "name": "Silent Howl Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 46,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 943,
    "tierItemIds": [
      942,
      943,
      944
    ],
    "setId": 34,
    "animType": null,
    "damageType": "Physical"
  },
  "943": {
    "id": 943,
    "displayName": "Mighty Silent Howl Shield",
    "name": "Silent Howl Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 7,
      "HP": 65,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 944,
    "tierItemIds": [
      942,
      943,
      944
    ],
    "setId": 34,
    "animType": null,
    "damageType": "Physical"
  },
  "944": {
    "id": 944,
    "displayName": "Superior Silent Howl Shield",
    "name": "Silent Howl Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 11,
      "HP": 92,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      942,
      943,
      944
    ],
    "setId": 34,
    "animType": null,
    "damageType": "Physical"
  },
  "945": {
    "id": 945,
    "displayName": "Silent Howl Shield",
    "name": "Silent Howl Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 7,
      "HP": 73,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 946,
    "tierItemIds": [
      945,
      946,
      947
    ],
    "setId": 34,
    "animType": null,
    "damageType": "Physical"
  },
  "946": {
    "id": 946,
    "displayName": "Mighty Silent Howl Shield",
    "name": "Silent Howl Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 11,
      "HP": 103,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 947,
    "tierItemIds": [
      945,
      946,
      947
    ],
    "setId": 34,
    "animType": null,
    "damageType": "Physical"
  },
  "947": {
    "id": 947,
    "displayName": "Superior Silent Howl Shield",
    "name": "Silent Howl Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 16,
      "HP": 145,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      945,
      946,
      947
    ],
    "setId": 34,
    "animType": null,
    "damageType": "Physical"
  },
  "948": {
    "id": 948,
    "displayName": "Silent Howl Shield",
    "name": "Silent Howl Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 11,
      "HP": 103,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 949,
    "tierItemIds": [
      948,
      949,
      950
    ],
    "setId": 34,
    "animType": null,
    "damageType": "Physical"
  },
  "949": {
    "id": 949,
    "displayName": "Mighty Silent Howl Shield",
    "name": "Silent Howl Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 16,
      "HP": 145,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 950,
    "tierItemIds": [
      948,
      949,
      950
    ],
    "setId": 34,
    "animType": null,
    "damageType": "Physical"
  },
  "950": {
    "id": 950,
    "displayName": "Superior Silent Howl Shield",
    "name": "Silent Howl Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 20,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 23,
      "HP": 203,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      948,
      949,
      950
    ],
    "setId": 34,
    "animType": null,
    "damageType": "Physical"
  },
  "951": {
    "id": 951,
    "displayName": "Silent Howl Staff",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 54,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 952,
    "tierItemIds": [
      951,
      952,
      953
    ],
    "setId": 34,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "952": {
    "id": 952,
    "displayName": "Mighty Silent Howl Staff",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 76,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 953,
    "tierItemIds": [
      951,
      952,
      953
    ],
    "setId": 34,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "953": {
    "id": 953,
    "displayName": "Superior Silent Howl Staff",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 107,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      951,
      952,
      953
    ],
    "setId": 34,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "954": {
    "id": 954,
    "displayName": "Silent Howl Staff",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 76,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 4,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 955,
    "tierItemIds": [
      954,
      955,
      956
    ],
    "setId": 34,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "955": {
    "id": 955,
    "displayName": "Mighty Silent Howl Staff",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 107,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 956,
    "tierItemIds": [
      954,
      955,
      956
    ],
    "setId": 34,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "956": {
    "id": 956,
    "displayName": "Superior Silent Howl Staff",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 150,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      954,
      955,
      956
    ],
    "setId": 34,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "957": {
    "id": 957,
    "displayName": "Silent Howl Staff",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 107,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 958,
    "tierItemIds": [
      957,
      958,
      959
    ],
    "setId": 34,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "958": {
    "id": 958,
    "displayName": "Mighty Silent Howl Staff",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 150,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 12,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 959,
    "tierItemIds": [
      957,
      958,
      959
    ],
    "setId": 34,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "959": {
    "id": 959,
    "displayName": "Superior Silent Howl Staff",
    "name": "Silent Howl Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 210,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 15,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      957,
      958,
      959
    ],
    "setId": 34,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "960": {
    "id": 960,
    "displayName": "Silent Howl Gloves",
    "name": "Silent Howl Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 36,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 961,
    "tierItemIds": [
      960,
      961,
      962
    ],
    "setId": 34,
    "animType": null
  },
  "961": {
    "id": 961,
    "displayName": "Mighty Silent Howl Gloves",
    "name": "Silent Howl Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 51,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 962,
    "tierItemIds": [
      960,
      961,
      962
    ],
    "setId": 34,
    "animType": null
  },
  "962": {
    "id": 962,
    "displayName": "Superior Silent Howl Gloves",
    "name": "Silent Howl Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 72,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      960,
      961,
      962
    ],
    "setId": 34,
    "animType": null
  },
  "963": {
    "id": 963,
    "displayName": "Silent Howl Gloves",
    "name": "Silent Howl Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 964,
    "tierItemIds": [
      963,
      964,
      965
    ],
    "setId": 34,
    "animType": null
  },
  "964": {
    "id": 964,
    "displayName": "Mighty Silent Howl Gloves",
    "name": "Silent Howl Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 80,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 965,
    "tierItemIds": [
      963,
      964,
      965
    ],
    "setId": 34,
    "animType": null
  },
  "965": {
    "id": 965,
    "displayName": "Superior Silent Howl Gloves",
    "name": "Silent Howl Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 11,
      "LUK": 5,
      "HP": 112,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      963,
      964,
      965
    ],
    "setId": 34,
    "animType": null
  },
  "966": {
    "id": 966,
    "displayName": "Silent Howl Gloves",
    "name": "Silent Howl Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 80,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 967,
    "tierItemIds": [
      966,
      967,
      968
    ],
    "setId": 34,
    "animType": null
  },
  "967": {
    "id": 967,
    "displayName": "Mighty Silent Howl Gloves",
    "name": "Silent Howl Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 11,
      "LUK": 5,
      "HP": 112,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 968,
    "tierItemIds": [
      966,
      967,
      968
    ],
    "setId": 34,
    "animType": null
  },
  "968": {
    "id": 968,
    "displayName": "Superior Silent Howl Gloves",
    "name": "Silent Howl Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 9,
      "AGI": 0,
      "INT": 16,
      "LUK": 8,
      "HP": 157,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      966,
      967,
      968
    ],
    "setId": 34,
    "animType": null
  },
  "969": {
    "id": 969,
    "displayName": "Silent Howl Helmet",
    "name": "Silent Howl Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 1,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 36,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 970,
    "tierItemIds": [
      969,
      970,
      971
    ],
    "setId": 34,
    "animType": null
  },
  "970": {
    "id": 970,
    "displayName": "Mighty Silent Howl Helmet",
    "name": "Silent Howl Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 2,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 51,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 971,
    "tierItemIds": [
      969,
      970,
      971
    ],
    "setId": 34,
    "animType": null
  },
  "971": {
    "id": 971,
    "displayName": "Superior Silent Howl Helmet",
    "name": "Silent Howl Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 4,
      "AGI": 0,
      "INT": 10,
      "LUK": 5,
      "HP": 72,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      969,
      970,
      971
    ],
    "setId": 34,
    "animType": null
  },
  "972": {
    "id": 972,
    "displayName": "Silent Howl Helmet",
    "name": "Silent Howl Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 2,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 973,
    "tierItemIds": [
      972,
      973,
      974
    ],
    "setId": 34,
    "animType": null
  },
  "973": {
    "id": 973,
    "displayName": "Mighty Silent Howl Helmet",
    "name": "Silent Howl Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 4,
      "AGI": 0,
      "INT": 10,
      "LUK": 5,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 974,
    "tierItemIds": [
      972,
      973,
      974
    ],
    "setId": 34,
    "animType": null
  },
  "974": {
    "id": 974,
    "displayName": "Superior Silent Howl Helmet",
    "name": "Silent Howl Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 6,
      "AGI": 0,
      "INT": 14,
      "LUK": 8,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      972,
      973,
      974
    ],
    "setId": 34,
    "animType": null
  },
  "975": {
    "id": 975,
    "displayName": "Silent Howl Helmet",
    "name": "Silent Howl Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 4,
      "AGI": 0,
      "INT": 10,
      "LUK": 5,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 976,
    "tierItemIds": [
      975,
      976,
      977
    ],
    "setId": 34,
    "animType": null
  },
  "976": {
    "id": 976,
    "displayName": "Mighty Silent Howl Helmet",
    "name": "Silent Howl Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 6,
      "AGI": 0,
      "INT": 14,
      "LUK": 8,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 977,
    "tierItemIds": [
      975,
      976,
      977
    ],
    "setId": 34,
    "animType": null
  },
  "977": {
    "id": 977,
    "displayName": "Superior Silent Howl Helmet",
    "name": "Silent Howl Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 17,
      "Physical Defense": 7,
      "Critical Damage": 8,
      "STR": 9,
      "AGI": 0,
      "INT": 20,
      "LUK": 12,
      "HP": 157,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      975,
      976,
      977
    ],
    "setId": 34,
    "animType": null
  },
  "978": {
    "id": 978,
    "displayName": "Silent Howl Armor",
    "name": "Silent Howl Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 979,
    "tierItemIds": [
      978,
      979,
      980
    ],
    "setId": 34,
    "animType": null
  },
  "979": {
    "id": 979,
    "displayName": "Mighty Silent Howl Armor",
    "name": "Silent Howl Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 980,
    "tierItemIds": [
      978,
      979,
      980
    ],
    "setId": 34,
    "animType": null
  },
  "980": {
    "id": 980,
    "displayName": "Superior Silent Howl Armor",
    "name": "Silent Howl Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      978,
      979,
      980
    ],
    "setId": 34,
    "animType": null
  },
  "981": {
    "id": 981,
    "displayName": "Silent Howl Armor",
    "name": "Silent Howl Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 982,
    "tierItemIds": [
      981,
      982,
      983
    ],
    "setId": 34,
    "animType": null
  },
  "982": {
    "id": 982,
    "displayName": "Mighty Silent Howl Armor",
    "name": "Silent Howl Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 983,
    "tierItemIds": [
      981,
      982,
      983
    ],
    "setId": 34,
    "animType": null
  },
  "983": {
    "id": 983,
    "displayName": "Superior Silent Howl Armor",
    "name": "Silent Howl Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 11,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      981,
      982,
      983
    ],
    "setId": 34,
    "animType": null
  },
  "984": {
    "id": 984,
    "displayName": "Silent Howl Armor",
    "name": "Silent Howl Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 985,
    "tierItemIds": [
      984,
      985,
      986
    ],
    "setId": 34,
    "animType": null
  },
  "985": {
    "id": 985,
    "displayName": "Mighty Silent Howl Armor",
    "name": "Silent Howl Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 11,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 986,
    "tierItemIds": [
      984,
      985,
      986
    ],
    "setId": 34,
    "animType": null
  },
  "986": {
    "id": 986,
    "displayName": "Superior Silent Howl Armor",
    "name": "Silent Howl Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 16,
      "LUK": 0,
      "HP": 176,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      984,
      985,
      986
    ],
    "setId": 34,
    "animType": null
  },
  "987": {
    "id": 987,
    "displayName": "Silent Howl Boots",
    "name": "Silent Howl Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 9,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 988,
    "tierItemIds": [
      987,
      988,
      989
    ],
    "setId": 34,
    "animType": null
  },
  "988": {
    "id": 988,
    "displayName": "Mighty Silent Howl Boots",
    "name": "Silent Howl Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 13,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 989,
    "tierItemIds": [
      987,
      988,
      989
    ],
    "setId": 34,
    "animType": null
  },
  "989": {
    "id": 989,
    "displayName": "Superior Silent Howl Boots",
    "name": "Silent Howl Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 3,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      987,
      988,
      989
    ],
    "setId": 34,
    "animType": null
  },
  "990": {
    "id": 990,
    "displayName": "Silent Howl Boots",
    "name": "Silent Howl Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 991,
    "tierItemIds": [
      990,
      991,
      992
    ],
    "setId": 34,
    "animType": null
  },
  "991": {
    "id": 991,
    "displayName": "Mighty Silent Howl Boots",
    "name": "Silent Howl Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 3,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 992,
    "tierItemIds": [
      990,
      991,
      992
    ],
    "setId": 34,
    "animType": null
  },
  "992": {
    "id": 992,
    "displayName": "Superior Silent Howl Boots",
    "name": "Silent Howl Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 5,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      990,
      991,
      992
    ],
    "setId": 34,
    "animType": null
  },
  "993": {
    "id": 993,
    "displayName": "Silent Howl Boots",
    "name": "Silent Howl Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 3,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 994,
    "tierItemIds": [
      993,
      994,
      995
    ],
    "setId": 34,
    "animType": null
  },
  "994": {
    "id": 994,
    "displayName": "Mighty Silent Howl Boots",
    "name": "Silent Howl Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 5,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 995,
    "tierItemIds": [
      993,
      994,
      995
    ],
    "setId": 34,
    "animType": null
  },
  "995": {
    "id": 995,
    "displayName": "Superior Silent Howl Boots",
    "name": "Silent Howl Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 4,
      "INT": 7,
      "LUK": 0,
      "HP": 46,
      "Critical": 0,
      "Evade": 4,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      993,
      994,
      995
    ],
    "setId": 34,
    "animType": null
  },
  "996": {
    "id": 996,
    "displayName": "Silent Howl Pants",
    "name": "Silent Howl Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 9,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 997,
    "tierItemIds": [
      996,
      997,
      998
    ],
    "setId": 34,
    "animType": null
  },
  "997": {
    "id": 997,
    "displayName": "Mighty Silent Howl Pants",
    "name": "Silent Howl Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 13,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 998,
    "tierItemIds": [
      996,
      997,
      998
    ],
    "setId": 34,
    "animType": null
  },
  "998": {
    "id": 998,
    "displayName": "Superior Silent Howl Pants",
    "name": "Silent Howl Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      996,
      997,
      998
    ],
    "setId": 34,
    "animType": null
  },
  "999": {
    "id": 999,
    "displayName": "Silent Howl Pants",
    "name": "Silent Howl Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1000,
    "tierItemIds": [
      999,
      1000,
      1001
    ],
    "setId": 34,
    "animType": null
  },
  "1000": {
    "id": 1000,
    "displayName": "Mighty Silent Howl Pants",
    "name": "Silent Howl Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1001,
    "tierItemIds": [
      999,
      1000,
      1001
    ],
    "setId": 34,
    "animType": null
  },
  "1001": {
    "id": 1001,
    "displayName": "Superior Silent Howl Pants",
    "name": "Silent Howl Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 5,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 4,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      999,
      1000,
      1001
    ],
    "setId": 34,
    "animType": null
  },
  "1002": {
    "id": 1002,
    "displayName": "Silent Howl Pants",
    "name": "Silent Howl Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1003,
    "tierItemIds": [
      1002,
      1003,
      1004
    ],
    "setId": 34,
    "animType": null
  },
  "1003": {
    "id": 1003,
    "displayName": "Mighty Silent Howl Pants",
    "name": "Silent Howl Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 5,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 4,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1004,
    "tierItemIds": [
      1002,
      1003,
      1004
    ],
    "setId": 34,
    "animType": null
  },
  "1004": {
    "id": 1004,
    "displayName": "Superior Silent Howl Pants",
    "name": "Silent Howl Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 8,
      "LUK": 0,
      "HP": 46,
      "Critical": 0,
      "Evade": 6,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1002,
      1003,
      1004
    ],
    "setId": 34,
    "animType": null
  },
  "1005": {
    "id": 1005,
    "displayName": "Kingdom Scythe",
    "name": "kingdom Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [],
    "maxSupply": 90,
    "tier": 1,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1006,
    "tierItemIds": [
      1005,
      1006,
      1007
    ],
    "setId": 35,
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1006": {
    "id": 1006,
    "displayName": "Mighty Kingdom Scythe",
    "name": "kingdom Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 32,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1007,
    "tierItemIds": [
      1005,
      1006,
      1007
    ],
    "setId": 35,
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1007": {
    "id": 1007,
    "displayName": "Superior Kingdom Scythe",
    "name": "kingdom Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 46,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1005,
      1006,
      1007
    ],
    "setId": 35,
    "animType": "scythe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1008": {
    "id": 1008,
    "displayName": "Kingdom Orb",
    "name": "kingdom Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 90,
    "tier": 1,
    "stats": {
      "Magical Damage": 39,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 10,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1009,
    "tierItemIds": [
      1008,
      1009,
      1010
    ],
    "setId": 35,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1009": {
    "id": 1009,
    "displayName": "Mighty Kingdom Orb",
    "name": "kingdom Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 55,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 4,
      "STR": 0,
      "AGI": 0,
      "INT": 14,
      "LUK": 2,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1010,
    "tierItemIds": [
      1008,
      1009,
      1010
    ],
    "setId": 35,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1010": {
    "id": 1010,
    "displayName": "Superior Kingdom Orb",
    "name": "kingdom Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 68,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 6,
      "STR": 0,
      "AGI": 0,
      "INT": 20,
      "LUK": 4,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1008,
      1009,
      1010
    ],
    "setId": 35,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1011": {
    "id": 1011,
    "displayName": "Kingdom Gloves",
    "name": "kingdom Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 90,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1012,
    "tierItemIds": [
      1011,
      1012,
      1013
    ],
    "setId": 35,
    "animType": null
  },
  "1012": {
    "id": 1012,
    "displayName": "Mighty Kingdom Gloves",
    "name": "kingdom Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 10,
      "LUK": 2,
      "HP": 110,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1013,
    "tierItemIds": [
      1011,
      1012,
      1013
    ],
    "setId": 35,
    "animType": null
  },
  "1013": {
    "id": 1013,
    "displayName": "Superior Kingdom Gloves",
    "name": "kingdom Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 13,
      "Critical Damage": 0,
      "STR": 9,
      "AGI": 0,
      "INT": 15,
      "LUK": 4,
      "HP": 155,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1011,
      1012,
      1013
    ],
    "setId": 35,
    "animType": null
  },
  "1014": {
    "id": 1014,
    "displayName": "Kingdom Helmet",
    "name": "kingdom Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [],
    "maxSupply": 90,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 2,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1015,
    "tierItemIds": [
      1014,
      1015,
      1016
    ],
    "setId": 35,
    "animType": null
  },
  "1015": {
    "id": 1015,
    "displayName": "Mighty Kingdom Helmet",
    "name": "kingdom Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 4,
      "STR": 4,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 66,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1016,
    "tierItemIds": [
      1014,
      1015,
      1016
    ],
    "setId": 35,
    "animType": null
  },
  "1016": {
    "id": 1016,
    "displayName": "Superior Kingdom Helmet",
    "name": "kingdom Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 8,
      "Critical Damage": 6,
      "STR": 6,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 93,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1014,
      1015,
      1016
    ],
    "setId": 35,
    "animType": null
  },
  "1017": {
    "id": 1017,
    "displayName": "Kingdom Armor",
    "name": "kingdom Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [],
    "maxSupply": 90,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1018,
    "tierItemIds": [
      1017,
      1018,
      1019
    ],
    "setId": 35,
    "animType": null
  },
  "1018": {
    "id": 1018,
    "displayName": "Mighty Kingdom Armor",
    "name": "kingdom Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 66,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1019,
    "tierItemIds": [
      1017,
      1018,
      1019
    ],
    "setId": 35,
    "animType": null
  },
  "1019": {
    "id": 1019,
    "displayName": "Superior Kingdom Armor",
    "name": "kingdom Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 93,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1017,
      1018,
      1019
    ],
    "setId": 35,
    "animType": null
  },
  "1020": {
    "id": 1020,
    "displayName": "Kingdom Boots",
    "name": "kingdom Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 90,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1021,
    "tierItemIds": [
      1020,
      1021,
      1022
    ],
    "setId": 35,
    "animType": null
  },
  "1021": {
    "id": 1021,
    "displayName": "Mighty Kingdom Boots",
    "name": "kingdom Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 66,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1022,
    "tierItemIds": [
      1020,
      1021,
      1022
    ],
    "setId": 35,
    "animType": null
  },
  "1022": {
    "id": 1022,
    "displayName": "Superior Kingdom Boots",
    "name": "kingdom Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 12,
      "INT": 0,
      "LUK": 0,
      "HP": 93,
      "Critical": 0,
      "Evade": 1,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1020,
      1021,
      1022
    ],
    "setId": 35,
    "animType": null
  },
  "1023": {
    "id": 1023,
    "displayName": "Kingdom Pants",
    "name": "kingdom Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 90,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1024,
    "tierItemIds": [
      1023,
      1024,
      1025
    ],
    "setId": 35,
    "animType": null
  },
  "1024": {
    "id": 1024,
    "displayName": "Mighty Kingdom Pants",
    "name": "kingdom Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 66,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1025,
    "tierItemIds": [
      1023,
      1024,
      1025
    ],
    "setId": 35,
    "animType": null
  },
  "1025": {
    "id": 1025,
    "displayName": "Superior Kingdom Pants",
    "name": "kingdom Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 4,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 12,
      "INT": 0,
      "LUK": 0,
      "HP": 93,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1023,
      1024,
      1025
    ],
    "setId": 35,
    "animType": null
  },
  "1026": {
    "id": 1026,
    "displayName": "Encircled Spear",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 30,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1027,
    "tierItemIds": [
      1026,
      1027,
      1028
    ],
    "setId": 36,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1027": {
    "id": 1027,
    "displayName": "Mighty Encircled Spear",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 42,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1028,
    "tierItemIds": [
      1026,
      1027,
      1028
    ],
    "setId": 36,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1028": {
    "id": 1028,
    "displayName": "Superior Encircled Spear",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 60,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1026,
      1027,
      1028
    ],
    "setId": 36,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1029": {
    "id": 1029,
    "displayName": "Encircled Spear",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 42,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 4,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1030,
    "tierItemIds": [
      1029,
      1030,
      1031
    ],
    "setId": 36,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1030": {
    "id": 1030,
    "displayName": "Mighty Encircled Spear",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 60,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1031,
    "tierItemIds": [
      1029,
      1030,
      1031
    ],
    "setId": 36,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1031": {
    "id": 1031,
    "displayName": "Superior Encircled Spear",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 85,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1029,
      1030,
      1031
    ],
    "setId": 36,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1032": {
    "id": 1032,
    "displayName": "Encircled Spear",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 60,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1033,
    "tierItemIds": [
      1032,
      1033,
      1034
    ],
    "setId": 36,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1033": {
    "id": 1033,
    "displayName": "Mighty Encircled Spear",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 85,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 12,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1034,
    "tierItemIds": [
      1032,
      1033,
      1034
    ],
    "setId": 36,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1034": {
    "id": 1034,
    "displayName": "Superior Encircled Spear",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 120,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 15,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1032,
      1033,
      1034
    ],
    "setId": 36,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1035": {
    "id": 1035,
    "displayName": "Encircled Staff",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 30,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1036,
    "tierItemIds": [
      1035,
      1036,
      1037
    ],
    "setId": 36,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1036": {
    "id": 1036,
    "displayName": "Mighty Encircled Staff",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 42,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1037,
    "tierItemIds": [
      1035,
      1036,
      1037
    ],
    "setId": 36,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1037": {
    "id": 1037,
    "displayName": "Superior Encircled Staff",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 60,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1035,
      1036,
      1037
    ],
    "setId": 36,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1038": {
    "id": 1038,
    "displayName": "Encircled Staff",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 42,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 4,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1039,
    "tierItemIds": [
      1038,
      1039,
      1040
    ],
    "setId": 36,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1039": {
    "id": 1039,
    "displayName": "Mighty Encircled Staff",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 60,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1040,
    "tierItemIds": [
      1038,
      1039,
      1040
    ],
    "setId": 36,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1040": {
    "id": 1040,
    "displayName": "Superior Encircled Staff",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 85,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1038,
      1039,
      1040
    ],
    "setId": 36,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1041": {
    "id": 1041,
    "displayName": "Encircled Staff",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 60,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1042,
    "tierItemIds": [
      1041,
      1042,
      1043
    ],
    "setId": 36,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1042": {
    "id": 1042,
    "displayName": "Mighty Encircled Staff",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 85,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 12,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1043,
    "tierItemIds": [
      1041,
      1042,
      1043
    ],
    "setId": 36,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1043": {
    "id": 1043,
    "displayName": "Superior Encircled Staff",
    "name": "Encircled Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 120,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 15,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1041,
      1042,
      1043
    ],
    "setId": 36,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1044": {
    "id": 1044,
    "displayName": "Encircled Gloves",
    "name": "Encircled Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 7,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 36,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1045,
    "tierItemIds": [
      1044,
      1045,
      1046
    ],
    "setId": 36,
    "animType": null
  },
  "1045": {
    "id": 1045,
    "displayName": "Mighty Encircled Gloves",
    "name": "Encircled Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 10,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 51,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1046,
    "tierItemIds": [
      1044,
      1045,
      1046
    ],
    "setId": 36,
    "animType": null
  },
  "1046": {
    "id": 1046,
    "displayName": "Superior Encircled Gloves",
    "name": "Encircled Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 15,
      "Magical Defense": 3,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 1,
      "LUK": 3,
      "HP": 72,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1044,
      1045,
      1046
    ],
    "setId": 36,
    "animType": null
  },
  "1047": {
    "id": 1047,
    "displayName": "Encircled Gloves",
    "name": "Encircled Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 10,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 57,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1048,
    "tierItemIds": [
      1047,
      1048,
      1049
    ],
    "setId": 36,
    "animType": null
  },
  "1048": {
    "id": 1048,
    "displayName": "Mighty Encircled Gloves",
    "name": "Encircled Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 15,
      "Magical Defense": 3,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 1,
      "LUK": 3,
      "HP": 80,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1049,
    "tierItemIds": [
      1047,
      1048,
      1049
    ],
    "setId": 36,
    "animType": null
  },
  "1049": {
    "id": 1049,
    "displayName": "Superior Encircled Gloves",
    "name": "Encircled Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 22,
      "Magical Defense": 5,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 5,
      "HP": 112,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1047,
      1048,
      1049
    ],
    "setId": 36,
    "animType": null
  },
  "1050": {
    "id": 1050,
    "displayName": "Encircled Gloves",
    "name": "Encircled Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 15,
      "Magical Defense": 3,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 1,
      "LUK": 3,
      "HP": 80,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1051,
    "tierItemIds": [
      1050,
      1051,
      1052
    ],
    "setId": 36,
    "animType": null
  },
  "1051": {
    "id": 1051,
    "displayName": "Mighty Encircled Gloves",
    "name": "Encircled Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 22,
      "Magical Defense": 5,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 5,
      "HP": 112,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1052,
    "tierItemIds": [
      1050,
      1051,
      1052
    ],
    "setId": 36,
    "animType": null
  },
  "1052": {
    "id": 1052,
    "displayName": "Superior Encircled Gloves",
    "name": "Encircled Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 31,
      "Physical Damage": 31,
      "Magical Defense": 8,
      "Physical Defense": 13,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 3,
      "LUK": 8,
      "HP": 158,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1050,
      1051,
      1052
    ],
    "setId": 36,
    "animType": null
  },
  "1053": {
    "id": 1053,
    "displayName": "Encircled Helmet",
    "name": "Encircled Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 36,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1054,
    "tierItemIds": [
      1053,
      1054,
      1055
    ],
    "setId": 36,
    "animType": null
  },
  "1054": {
    "id": 1054,
    "displayName": "Mighty Encircled Helmet",
    "name": "Encircled Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 4,
      "HP": 51,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1055,
    "tierItemIds": [
      1053,
      1054,
      1055
    ],
    "setId": 36,
    "animType": null
  },
  "1055": {
    "id": 1055,
    "displayName": "Superior Encircled Helmet",
    "name": "Encircled Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 11,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 6,
      "HP": 72,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1053,
      1054,
      1055
    ],
    "setId": 36,
    "animType": null
  },
  "1056": {
    "id": 1056,
    "displayName": "Encircled Helmet",
    "name": "Encircled Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 4,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1057,
    "tierItemIds": [
      1056,
      1057,
      1058
    ],
    "setId": 36,
    "animType": null
  },
  "1057": {
    "id": 1057,
    "displayName": "Mighty Encircled Helmet",
    "name": "Encircled Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 11,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 6,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1058,
    "tierItemIds": [
      1056,
      1057,
      1058
    ],
    "setId": 36,
    "animType": null
  },
  "1058": {
    "id": 1058,
    "displayName": "Superior Encircled Helmet",
    "name": "Encircled Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 9,
      "Critical Damage": 16,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 9,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1056,
      1057,
      1058
    ],
    "setId": 36,
    "animType": null
  },
  "1059": {
    "id": 1059,
    "displayName": "Encircled Helmet",
    "name": "Encircled Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 11,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 6,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1060,
    "tierItemIds": [
      1059,
      1060,
      1061
    ],
    "setId": 36,
    "animType": null
  },
  "1060": {
    "id": 1060,
    "displayName": "Mighty Encircled Helmet",
    "name": "Encircled Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 9,
      "Critical Damage": 16,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 9,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1061,
    "tierItemIds": [
      1059,
      1060,
      1061
    ],
    "setId": 36,
    "animType": null
  },
  "1061": {
    "id": 1061,
    "displayName": "Superior Encircled Helmet",
    "name": "Encircled Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 13,
      "Critical Damage": 23,
      "STR": 4,
      "AGI": 0,
      "INT": 5,
      "LUK": 13,
      "HP": 158,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1059,
      1060,
      1061
    ],
    "setId": 36,
    "animType": null
  },
  "1062": {
    "id": 1062,
    "displayName": "Encircled Armor",
    "name": "Encircled Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 7,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1063,
    "tierItemIds": [
      1062,
      1063,
      1064
    ],
    "setId": 36,
    "animType": null
  },
  "1063": {
    "id": 1063,
    "displayName": "Mighty Encircled Armor",
    "name": "Encircled Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 10,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 64,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1064,
    "tierItemIds": [
      1062,
      1063,
      1064
    ],
    "setId": 36,
    "animType": null
  },
  "1064": {
    "id": 1064,
    "displayName": "Superior Encircled Armor",
    "name": "Encircled Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 15,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 90,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1062,
      1063,
      1064
    ],
    "setId": 36,
    "animType": null
  },
  "1065": {
    "id": 1065,
    "displayName": "Encircled Armor",
    "name": "Encircled Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 10,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1066,
    "tierItemIds": [
      1065,
      1066,
      1067
    ],
    "setId": 36,
    "animType": null
  },
  "1066": {
    "id": 1066,
    "displayName": "Mighty Encircled Armor",
    "name": "Encircled Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 15,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1067,
    "tierItemIds": [
      1065,
      1066,
      1067
    ],
    "setId": 36,
    "animType": null
  },
  "1067": {
    "id": 1067,
    "displayName": "Superior Encircled Armor",
    "name": "Encircled Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 22,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 140,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1065,
      1066,
      1067
    ],
    "setId": 36,
    "animType": null
  },
  "1068": {
    "id": 1068,
    "displayName": "Encircled Armor",
    "name": "Encircled Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 15,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1069,
    "tierItemIds": [
      1068,
      1069,
      1070
    ],
    "setId": 36,
    "animType": null
  },
  "1069": {
    "id": 1069,
    "displayName": "Mighty Encircled Armor",
    "name": "Encircled Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 22,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 140,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1070,
    "tierItemIds": [
      1068,
      1069,
      1070
    ],
    "setId": 36,
    "animType": null
  },
  "1070": {
    "id": 1070,
    "displayName": "Superior Encircled Armor",
    "name": "Encircled Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 32,
      "Physical Damage": 32,
      "Magical Defense": 8,
      "Physical Defense": 17,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 197,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1068,
      1069,
      1070
    ],
    "setId": 36,
    "animType": null
  },
  "1071": {
    "id": 1071,
    "displayName": "Encircled Boots",
    "name": "Encircled Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1072,
    "tierItemIds": [
      1071,
      1072,
      1073
    ],
    "setId": 36,
    "animType": null
  },
  "1072": {
    "id": 1072,
    "displayName": "Mighty Encircled Boots",
    "name": "Encircled Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 1,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1073,
    "tierItemIds": [
      1071,
      1072,
      1073
    ],
    "setId": 36,
    "animType": null
  },
  "1073": {
    "id": 1073,
    "displayName": "Superior Encircled Boots",
    "name": "Encircled Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 10,
      "INT": 1,
      "LUK": 0,
      "HP": 36,
      "Critical": 0,
      "Evade": 2,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1071,
      1072,
      1073
    ],
    "setId": 36,
    "animType": null
  },
  "1074": {
    "id": 1074,
    "displayName": "Encircled Boots",
    "name": "Encircled Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 28,
      "Critical": 0,
      "Evade": 1,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1075,
    "tierItemIds": [
      1074,
      1075,
      1076
    ],
    "setId": 36,
    "animType": null
  },
  "1075": {
    "id": 1075,
    "displayName": "Mighty Encircled Boots",
    "name": "Encircled Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 10,
      "INT": 1,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 2,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1076,
    "tierItemIds": [
      1074,
      1075,
      1076
    ],
    "setId": 36,
    "animType": null
  },
  "1076": {
    "id": 1076,
    "displayName": "Superior Encircled Boots",
    "name": "Encircled Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 14,
      "INT": 2,
      "LUK": 0,
      "HP": 56,
      "Critical": 0,
      "Evade": 3,
      "Speed": 10,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1074,
      1075,
      1076
    ],
    "setId": 36,
    "animType": null
  },
  "1077": {
    "id": 1077,
    "displayName": "Encircled Boots",
    "name": "Encircled Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 10,
      "INT": 1,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 2,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1078,
    "tierItemIds": [
      1077,
      1078,
      1079
    ],
    "setId": 36,
    "animType": null
  },
  "1078": {
    "id": 1078,
    "displayName": "Mighty Encircled Boots",
    "name": "Encircled Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 14,
      "INT": 2,
      "LUK": 0,
      "HP": 56,
      "Critical": 0,
      "Evade": 3,
      "Speed": 10,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1079,
    "tierItemIds": [
      1077,
      1078,
      1079
    ],
    "setId": 36,
    "animType": null
  },
  "1079": {
    "id": 1079,
    "displayName": "Superior Encircled Boots",
    "name": "Encircled Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 20,
      "INT": 3,
      "LUK": 0,
      "HP": 79,
      "Critical": 0,
      "Evade": 5,
      "Speed": 14,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1077,
      1078,
      1079
    ],
    "setId": 36,
    "animType": null
  },
  "1080": {
    "id": 1080,
    "displayName": "Encircled Pants",
    "name": "Encircled Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1081,
    "tierItemIds": [
      1080,
      1081,
      1082
    ],
    "setId": 36,
    "animType": null
  },
  "1081": {
    "id": 1081,
    "displayName": "Mighty Encircled Pants",
    "name": "Encircled Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1082,
    "tierItemIds": [
      1080,
      1081,
      1082
    ],
    "setId": 36,
    "animType": null
  },
  "1082": {
    "id": 1082,
    "displayName": "Superior Encircled Pants",
    "name": "Encircled Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 36,
      "Critical": 0,
      "Evade": 2,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1080,
      1081,
      1082
    ],
    "setId": 36,
    "animType": null
  },
  "1083": {
    "id": 1083,
    "displayName": "Encircled Pants",
    "name": "Encircled Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 28,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1084,
    "tierItemIds": [
      1083,
      1084,
      1085
    ],
    "setId": 36,
    "animType": null
  },
  "1084": {
    "id": 1084,
    "displayName": "Mighty Encircled Pants",
    "name": "Encircled Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 2,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1085,
    "tierItemIds": [
      1083,
      1084,
      1085
    ],
    "setId": 36,
    "animType": null
  },
  "1085": {
    "id": 1085,
    "displayName": "Superior Encircled Pants",
    "name": "Encircled Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 10,
      "INT": 1,
      "LUK": 0,
      "HP": 56,
      "Critical": 0,
      "Evade": 4,
      "Speed": 6,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1083,
      1084,
      1085
    ],
    "setId": 36,
    "animType": null
  },
  "1086": {
    "id": 1086,
    "displayName": "Encircled Pants",
    "name": "Encircled Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 2,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1087,
    "tierItemIds": [
      1086,
      1087,
      1088
    ],
    "setId": 36,
    "animType": null
  },
  "1087": {
    "id": 1087,
    "displayName": "Mighty Encircled Pants",
    "name": "Encircled Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 10,
      "INT": 1,
      "LUK": 0,
      "HP": 56,
      "Critical": 0,
      "Evade": 4,
      "Speed": 6,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1088,
    "tierItemIds": [
      1086,
      1087,
      1088
    ],
    "setId": 36,
    "animType": null
  },
  "1088": {
    "id": 1088,
    "displayName": "Superior Encircled Pants",
    "name": "Encircled Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 15,
      "INT": 2,
      "LUK": 0,
      "HP": 79,
      "Critical": 0,
      "Evade": 6,
      "Speed": 9,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1086,
      1087,
      1088
    ],
    "setId": 36,
    "animType": null
  },
  "1089": {
    "id": 1089,
    "displayName": "Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 71,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1090,
    "tierItemIds": [
      1089,
      1090,
      1091
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1090": {
    "id": 1090,
    "displayName": "Mighty Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 100,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1091,
    "tierItemIds": [
      1089,
      1090,
      1091
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1091": {
    "id": 1091,
    "displayName": "Superior Moon's Elegance Glaive",
    "name": "Moons Elegance Weapon",
    "races": [
      "Angel",
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 140,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 9,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1089,
      1090,
      1091
    ],
    "setId": 27,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1092": {
    "id": 1092,
    "displayName": "Moon's Elegance Armor",
    "name": "Moons Elegance Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 18,
      "Magical Defense": 2,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1093,
    "tierItemIds": [
      1092,
      1093,
      1094
    ],
    "setId": 27,
    "animType": null
  },
  "1093": {
    "id": 1093,
    "displayName": "Mighty Moon's Elegance Armor",
    "name": "Moons Elegance Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 26,
      "Magical Defense": 3,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 122,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1094,
    "tierItemIds": [
      1092,
      1093,
      1094
    ],
    "setId": 27,
    "animType": null
  },
  "1094": {
    "id": 1094,
    "displayName": "Superior Moon's Elegance Armor",
    "name": "Moons Elegance Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 29,
      "Magical Defense": 5,
      "Physical Defense": 20,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 172,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1092,
      1093,
      1094
    ],
    "setId": 27,
    "animType": null
  },
  "1095": {
    "id": 1095,
    "displayName": "Moon's Elegance Boots Boots",
    "name": "Moons Elegance Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1096,
    "tierItemIds": [
      1095,
      1096,
      1097
    ],
    "setId": 27,
    "animType": null
  },
  "1096": {
    "id": 1096,
    "displayName": "Mighty Moon's Elegance Boots",
    "name": "Moons Elegance Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 7,
      "INT": 1,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 0,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1097,
    "tierItemIds": [
      1095,
      1096,
      1097
    ],
    "setId": 27,
    "animType": null
  },
  "1097": {
    "id": 1097,
    "displayName": "Superior Moon's Elegance Boots",
    "name": "Moons Elegance Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 10,
      "INT": 2,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 0,
      "Speed": 6,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1095,
      1096,
      1097
    ],
    "setId": 27,
    "animType": null
  },
  "1098": {
    "id": 1098,
    "displayName": "Moon's Elegance Gloves",
    "name": "Moons Elegance Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 5,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1099,
    "tierItemIds": [
      1098,
      1099,
      1100
    ],
    "setId": 27,
    "animType": null
  },
  "1099": {
    "id": 1099,
    "displayName": "Mighty Moon's Elegance Gloves",
    "name": "Moons Elegance Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 8,
      "Magical Defense": 3,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1100,
    "tierItemIds": [
      1098,
      1099,
      1100
    ],
    "setId": 27,
    "animType": null
  },
  "1100": {
    "id": 1100,
    "displayName": "Superior Moon's Elegance Gloves",
    "name": "Moons Elegance Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 12,
      "Magical Defense": 5,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 138,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1098,
      1099,
      1100
    ],
    "setId": 27,
    "animType": null
  },
  "1101": {
    "id": 1101,
    "displayName": "Moon's Elegance Helmet",
    "name": "Moons Elegance Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1102,
    "tierItemIds": [
      1101,
      1102,
      1103
    ],
    "setId": 27,
    "animType": null
  },
  "1102": {
    "id": 1102,
    "displayName": "Mighty Moon's Elegance Helmet",
    "name": "Moons Elegance Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1103,
    "tierItemIds": [
      1101,
      1102,
      1103
    ],
    "setId": 27,
    "animType": null
  },
  "1103": {
    "id": 1103,
    "displayName": "Superior Moon's Elegance Helmet",
    "name": "Moons Elegance Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 138,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1101,
      1102,
      1103
    ],
    "setId": 27,
    "animType": null
  },
  "1104": {
    "id": 1104,
    "displayName": "Moon's Elegance Pants",
    "name": "Moons Elegance Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 5,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1105,
    "tierItemIds": [
      1104,
      1105,
      1106
    ],
    "setId": 27,
    "animType": null
  },
  "1105": {
    "id": 1105,
    "displayName": "Mighty Moon's Elegance Pants",
    "name": "Moons Elegance Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 5,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 1,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1106,
    "tierItemIds": [
      1104,
      1105,
      1106
    ],
    "setId": 27,
    "animType": null
  },
  "1106": {
    "id": 1106,
    "displayName": "Superior Moon's Elegance Pants",
    "name": "Moons Elegance Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 5,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 7,
      "INT": 2,
      "LUK": 0,
      "HP": 98,
      "Critical": 0,
      "Evade": 1,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1104,
      1105,
      1106
    ],
    "setId": 27,
    "animType": null
  },
  "1107": {
    "id": 1107,
    "displayName": "Masked Maniac Gloves",
    "name": "Masked Maniac Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 10,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 89,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1108,
    "tierItemIds": [
      1107,
      1108,
      1109
    ],
    "setId": 28,
    "animType": null
  },
  "1108": {
    "id": 1108,
    "displayName": "Mighty Masked Maniac Gloves",
    "name": "Masked Maniac Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 15,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 125,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1109,
    "tierItemIds": [
      1107,
      1108,
      1109
    ],
    "setId": 28,
    "animType": null
  },
  "1109": {
    "id": 1109,
    "displayName": "Superior Masked Maniac Gloves",
    "name": "Masked Maniac Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 21,
      "Magical Defense": 7,
      "Physical Defense": 18,
      "Critical Damage": 0,
      "STR": 9,
      "AGI": 0,
      "INT": 6,
      "LUK": 0,
      "HP": 176,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1107,
      1108,
      1109
    ],
    "setId": 28,
    "animType": null
  },
  "1110": {
    "id": 1110,
    "displayName": "Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 40,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1111,
    "tierItemIds": [
      1110,
      1111,
      1112
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1111": {
    "id": 1111,
    "displayName": "Mighty Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 57,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1112,
    "tierItemIds": [
      1110,
      1111,
      1112
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1112": {
    "id": 1112,
    "displayName": "Superior Enigcrypt Hammer",
    "name": "Enigcrypt Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 81,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 12,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 12,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1110,
      1111,
      1112
    ],
    "setId": 32,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1113": {
    "id": 1113,
    "displayName": "Enigcrypt Shield",
    "name": "Enigcrypt Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 7,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1114,
    "tierItemIds": [
      1113,
      1114,
      1115
    ],
    "setId": 32,
    "animType": null,
    "damageType": "Physical"
  },
  "1114": {
    "id": 1114,
    "displayName": "Mighty Enigcrypt Shield",
    "name": "Enigcrypt Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 10,
      "HP": 122,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1115,
    "tierItemIds": [
      1113,
      1114,
      1115
    ],
    "setId": 32,
    "animType": null,
    "damageType": "Physical"
  },
  "1115": {
    "id": 1115,
    "displayName": "Superior Enigcrypt Shield",
    "name": "Enigcrypt Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 22,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 14,
      "HP": 171,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1113,
      1114,
      1115
    ],
    "setId": 32,
    "animType": null,
    "damageType": "Physical"
  },
  "1116": {
    "id": 1116,
    "displayName": "Enigcrypt Armor",
    "name": "Enigcrypt Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 75,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1117,
    "tierItemIds": [
      1116,
      1117,
      1118
    ],
    "setId": 32,
    "animType": null
  },
  "1117": {
    "id": 1117,
    "displayName": "Mighty Enigcrypt Armor",
    "name": "Enigcrypt Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 11,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 12,
      "LUK": 0,
      "HP": 105,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1118,
    "tierItemIds": [
      1116,
      1117,
      1118
    ],
    "setId": 32,
    "animType": null
  },
  "1118": {
    "id": 1118,
    "displayName": "Superior Enigcrypt Armor",
    "name": "Enigcrypt Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 16,
      "Physical Damage": 0,
      "Magical Defense": 17,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 9,
      "AGI": 0,
      "INT": 18,
      "LUK": 0,
      "HP": 148,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1116,
      1117,
      1118
    ],
    "setId": 32,
    "animType": null
  },
  "1119": {
    "id": 1119,
    "displayName": "Enigcrypt Boots",
    "name": "Enigcrypt Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 2,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 1,
      "INT": 4,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1120,
    "tierItemIds": [
      1119,
      1120,
      1121
    ],
    "setId": 32,
    "animType": null
  },
  "1120": {
    "id": 1120,
    "displayName": "Mighty Enigcrypt Boots",
    "name": "Enigcrypt Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 4,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 2,
      "INT": 6,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 3,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1121,
    "tierItemIds": [
      1119,
      1120,
      1121
    ],
    "setId": 32,
    "animType": null
  },
  "1121": {
    "id": 1121,
    "displayName": "Superior Enigcrypt Boots",
    "name": "Enigcrypt Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 6,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 3,
      "INT": 9,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 5,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1119,
      1120,
      1121
    ],
    "setId": 32,
    "animType": null
  },
  "1122": {
    "id": 1122,
    "displayName": "Enigcrypt Gloves",
    "name": "Enigcrypt Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 8,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 67,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1123,
    "tierItemIds": [
      1122,
      1123,
      1124
    ],
    "setId": 32,
    "animType": null
  },
  "1123": {
    "id": 1123,
    "displayName": "Mighty Enigcrypt Gloves",
    "name": "Enigcrypt Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 12,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 12,
      "LUK": 3,
      "HP": 94,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1124,
    "tierItemIds": [
      1122,
      1123,
      1124
    ],
    "setId": 32,
    "animType": null
  },
  "1124": {
    "id": 1124,
    "displayName": "Superior Enigcrypt Gloves",
    "name": "Enigcrypt Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 17,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 18,
      "LUK": 5,
      "HP": 132,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1122,
      1123,
      1124
    ],
    "setId": 32,
    "animType": null
  },
  "1125": {
    "id": 1125,
    "displayName": "Enigcrypt Diadem",
    "name": "Enigcrypt Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 7,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 2,
      "Critical Damage": 7,
      "STR": 3,
      "AGI": 0,
      "INT": 11,
      "LUK": 2,
      "HP": 67,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1126,
    "tierItemIds": [
      1125,
      1126,
      1127
    ],
    "setId": 32,
    "animType": null
  },
  "1126": {
    "id": 1126,
    "displayName": "Mighty Enigcrypt Diadem",
    "name": "Enigcrypt Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 11,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 4,
      "Critical Damage": 11,
      "STR": 5,
      "AGI": 0,
      "INT": 16,
      "LUK": 4,
      "HP": 94,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1127,
    "tierItemIds": [
      1125,
      1126,
      1127
    ],
    "setId": 32,
    "animType": null
  },
  "1127": {
    "id": 1127,
    "displayName": "Superior Enigcrypt Diadem",
    "name": "Enigcrypt Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 16,
      "Physical Damage": 0,
      "Magical Defense": 19,
      "Physical Defense": 6,
      "Critical Damage": 16,
      "STR": 8,
      "AGI": 0,
      "INT": 23,
      "LUK": 6,
      "HP": 132,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1125,
      1126,
      1127
    ],
    "setId": 32,
    "animType": null
  },
  "1128": {
    "id": 1128,
    "displayName": "Enigcrypt Pants",
    "name": "Enigcrypt Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 2,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1129,
    "tierItemIds": [
      1128,
      1129,
      1130
    ],
    "setId": 32,
    "animType": null
  },
  "1129": {
    "id": 1129,
    "displayName": "Mighty Enigcrypt Pants",
    "name": "Enigcrypt Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 4,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 6,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 4,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1130,
    "tierItemIds": [
      1128,
      1129,
      1130
    ],
    "setId": 32,
    "animType": null
  },
  "1130": {
    "id": 1130,
    "displayName": "Superior Enigcrypt Pants",
    "name": "Enigcrypt Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 6,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 2,
      "INT": 9,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 6,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1128,
      1129,
      1130
    ],
    "setId": 32,
    "animType": null
  },
  "1131": {
    "id": 1131,
    "displayName": "Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 64,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 4,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1132,
    "tierItemIds": [
      1131,
      1132,
      1133
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1132": {
    "id": 1132,
    "displayName": "Mighty Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 90,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 16,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1133,
    "tierItemIds": [
      1131,
      1132,
      1133
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1133": {
    "id": 1133,
    "displayName": "Superior Astrorius Spear",
    "name": "Astrorius Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 126,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 23,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 9,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1131,
      1132,
      1133
    ],
    "setId": 33,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1134": {
    "id": 1134,
    "displayName": "Astrorius Armor",
    "name": "Astrorius Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 96,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1135,
    "tierItemIds": [
      1134,
      1135,
      1136
    ],
    "setId": 33,
    "animType": null
  },
  "1135": {
    "id": 1135,
    "displayName": "Mighty Astrorius Armor",
    "name": "Astrorius Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 15,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 135,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1136,
    "tierItemIds": [
      1134,
      1135,
      1136
    ],
    "setId": 33,
    "animType": null
  },
  "1136": {
    "id": 1136,
    "displayName": "Superior Astrorius Armor",
    "name": "Astrorius Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 21,
      "Critical Damage": 0,
      "STR": 9,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 190,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1134,
      1135,
      1136
    ],
    "setId": 33,
    "animType": null
  },
  "1137": {
    "id": 1137,
    "displayName": "Astrorius Boots",
    "name": "Astrorius Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 37,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1138,
    "tierItemIds": [
      1137,
      1138,
      1139
    ],
    "setId": 33,
    "animType": null
  },
  "1138": {
    "id": 1138,
    "displayName": "Mighty Astrorius Boots",
    "name": "Astrorius Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 11,
      "INT": 0,
      "LUK": 0,
      "HP": 53,
      "Critical": 0,
      "Evade": 0,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1139,
    "tierItemIds": [
      1137,
      1138,
      1139
    ],
    "setId": 33,
    "animType": null
  },
  "1139": {
    "id": 1139,
    "displayName": "Superior Astrorius Boots",
    "name": "Astrorius Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 4,
    "minted": 0,
    "requiredLevel": 45,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 16,
      "INT": 0,
      "LUK": 0,
      "HP": 75,
      "Critical": 0,
      "Evade": 1,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1137,
      1138,
      1139
    ],
    "setId": 33,
    "animType": null
  },
  "1140": {
    "id": 1140,
    "displayName": "Astrorius Gloves",
    "name": "Astrorius Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 77,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1141,
    "tierItemIds": [
      1140,
      1141,
      1142
    ],
    "setId": 33,
    "animType": null
  },
  "1141": {
    "id": 1141,
    "displayName": "Mighty Astrorius Gloves",
    "name": "Astrorius Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 108,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1142,
    "tierItemIds": [
      1140,
      1141,
      1142
    ],
    "setId": 33,
    "animType": null
  },
  "1142": {
    "id": 1142,
    "displayName": "Superior Astrorius Gloves",
    "name": "Astrorius Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 152,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1140,
      1141,
      1142
    ],
    "setId": 33,
    "animType": null
  },
  "1143": {
    "id": 1143,
    "displayName": "Astrorius Helmet",
    "name": "Astrorius Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 7,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 77,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1144,
    "tierItemIds": [
      1143,
      1144,
      1145
    ],
    "setId": 33,
    "animType": null
  },
  "1144": {
    "id": 1144,
    "displayName": "Mighty Astrorius Helmet",
    "name": "Astrorius Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 11,
      "Critical Damage": 11,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 108,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1145,
    "tierItemIds": [
      1143,
      1144,
      1145
    ],
    "setId": 33,
    "animType": null
  },
  "1145": {
    "id": 1145,
    "displayName": "Superior Astrorius Helmet",
    "name": "Astrorius Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 16,
      "Critical Damage": 16,
      "STR": 8,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 152,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1143,
      1144,
      1145
    ],
    "setId": 33,
    "animType": null
  },
  "1146": {
    "id": 1146,
    "displayName": "Astrorius Pants",
    "name": "Astrorius Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 38,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1147,
    "tierItemIds": [
      1146,
      1147,
      1148
    ],
    "setId": 33,
    "animType": null
  },
  "1147": {
    "id": 1147,
    "displayName": "Mighty Astrorius Pants",
    "name": "Astrorius Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 54,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1148,
    "tierItemIds": [
      1146,
      1147,
      1148
    ],
    "setId": 33,
    "animType": null
  },
  "1148": {
    "id": 1148,
    "displayName": "Superior Astrorius Pants",
    "name": "Astrorius Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 11,
      "INT": 0,
      "LUK": 0,
      "HP": 76,
      "Critical": 0,
      "Evade": 1,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1146,
      1147,
      1148
    ],
    "setId": 33,
    "animType": null
  },
  "1149": {
    "id": 1149,
    "displayName": "Rampaging Fury Staff",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 42,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1150,
    "tierItemIds": [
      1149,
      1150,
      1151
    ],
    "setId": 37,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1150": {
    "id": 1150,
    "displayName": "Mighty Rampaging Fury Staff",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 60,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1151,
    "tierItemIds": [
      1149,
      1150,
      1151
    ],
    "setId": 37,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1151": {
    "id": 1151,
    "displayName": "Superior Rampaging Fury Staff",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 85,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1149,
      1150,
      1151
    ],
    "setId": 37,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1152": {
    "id": 1152,
    "displayName": "Rampaging Fury Staff",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 60,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1153,
    "tierItemIds": [
      1152,
      1153,
      1154
    ],
    "setId": 37,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1153": {
    "id": 1153,
    "displayName": "Mighty Rampaging Fury Staff",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 85,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1154,
    "tierItemIds": [
      1152,
      1153,
      1154
    ],
    "setId": 37,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1154": {
    "id": 1154,
    "displayName": "Superior Rampaging Fury Staff",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 119,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1152,
      1153,
      1154
    ],
    "setId": 37,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1155": {
    "id": 1155,
    "displayName": "Rampaging Fury Staff",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 85,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1156,
    "tierItemIds": [
      1155,
      1156,
      1157
    ],
    "setId": 37,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1156": {
    "id": 1156,
    "displayName": "Mighty Rampaging Fury Staff",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 119,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1157,
    "tierItemIds": [
      1155,
      1156,
      1157
    ],
    "setId": 37,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1157": {
    "id": 1157,
    "displayName": "Superior Rampaging Fury Staff",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 167,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1155,
      1156,
      1157
    ],
    "setId": 37,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1158": {
    "id": 1158,
    "displayName": "Rampaging Fury Shield",
    "name": "Rampaging Fury Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1159,
    "tierItemIds": [
      1158,
      1159,
      1160
    ],
    "setId": 37,
    "animType": null,
    "damageType": "Physical"
  },
  "1159": {
    "id": 1159,
    "displayName": "Mighty Rampaging Fury Shield",
    "name": "Rampaging Fury Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 7,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1160,
    "tierItemIds": [
      1158,
      1159,
      1160
    ],
    "setId": 37,
    "animType": null,
    "damageType": "Physical"
  },
  "1160": {
    "id": 1160,
    "displayName": "Superior Rampaging Fury Shield",
    "name": "Rampaging Fury Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 10,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1158,
      1159,
      1160
    ],
    "setId": 37,
    "animType": null,
    "damageType": "Physical"
  },
  "1161": {
    "id": 1161,
    "displayName": "Rampaging Fury Shield",
    "name": "Rampaging Fury Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 7,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1162,
    "tierItemIds": [
      1161,
      1162,
      1163
    ],
    "setId": 37,
    "animType": null,
    "damageType": "Physical"
  },
  "1162": {
    "id": 1162,
    "displayName": "Mighty Rampaging Fury Shield",
    "name": "Rampaging Fury Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 10,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1163,
    "tierItemIds": [
      1161,
      1162,
      1163
    ],
    "setId": 37,
    "animType": null,
    "damageType": "Physical"
  },
  "1163": {
    "id": 1163,
    "displayName": "Superior Rampaging Fury Shield",
    "name": "Rampaging Fury Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 15,
      "HP": 110,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1161,
      1162,
      1163
    ],
    "setId": 37,
    "animType": null,
    "damageType": "Physical"
  },
  "1164": {
    "id": 1164,
    "displayName": "Rampaging Fury Shield",
    "name": "Rampaging Fury Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 10,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1165,
    "tierItemIds": [
      1164,
      1165,
      1166
    ],
    "setId": 37,
    "animType": null,
    "damageType": "Physical"
  },
  "1165": {
    "id": 1165,
    "displayName": "Mighty Rampaging Fury Shield",
    "name": "Rampaging Fury Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 15,
      "HP": 110,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1166,
    "tierItemIds": [
      1164,
      1165,
      1166
    ],
    "setId": 37,
    "animType": null,
    "damageType": "Physical"
  },
  "1166": {
    "id": 1166,
    "displayName": "Superior Rampaging Fury Shield",
    "name": "Rampaging Fury Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 13,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 22,
      "HP": 154,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1164,
      1165,
      1166
    ],
    "setId": 37,
    "animType": null,
    "damageType": "Physical"
  },
  "1167": {
    "id": 1167,
    "displayName": "Rampaging Fury Axe",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 55,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1168,
    "tierItemIds": [
      1167,
      1168,
      1169
    ],
    "setId": 37,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1168": {
    "id": 1168,
    "displayName": "Mighty Rampaging Fury Axe",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 78,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1169,
    "tierItemIds": [
      1167,
      1168,
      1169
    ],
    "setId": 37,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1169": {
    "id": 1169,
    "displayName": "Superior Rampaging Fury Axe",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 110,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1167,
      1168,
      1169
    ],
    "setId": 37,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1170": {
    "id": 1170,
    "displayName": "Rampaging Fury Axe",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 78,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1171,
    "tierItemIds": [
      1170,
      1171,
      1172
    ],
    "setId": 37,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1171": {
    "id": 1171,
    "displayName": "Mighty Rampaging Fury Axe",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 110,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1172,
    "tierItemIds": [
      1170,
      1171,
      1172
    ],
    "setId": 37,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1172": {
    "id": 1172,
    "displayName": "Superior Rampaging Fury Axe",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 155,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1170,
      1171,
      1172
    ],
    "setId": 37,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1173": {
    "id": 1173,
    "displayName": "Rampaging Fury Axe",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 110,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1174,
    "tierItemIds": [
      1173,
      1174,
      1175
    ],
    "setId": 37,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1174": {
    "id": 1174,
    "displayName": "Mighty Rampaging Fury Axe",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 155,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1175,
    "tierItemIds": [
      1173,
      1174,
      1175
    ],
    "setId": 37,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1175": {
    "id": 1175,
    "displayName": "Superior Rampaging Fury Axe",
    "name": "Rampaging Fury Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 217,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 9,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1173,
      1174,
      1175
    ],
    "setId": 37,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1176": {
    "id": 1176,
    "displayName": "Rampaging Fury Gloves",
    "name": "Rampaging Fury Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 1,
      "HP": 27,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1177,
    "tierItemIds": [
      1176,
      1177,
      1178
    ],
    "setId": 37,
    "animType": null
  },
  "1177": {
    "id": 1177,
    "displayName": "Mighty Rampaging Fury Gloves",
    "name": "Rampaging Fury Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 2,
      "HP": 38,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1178,
    "tierItemIds": [
      1176,
      1177,
      1178
    ],
    "setId": 37,
    "animType": null
  },
  "1178": {
    "id": 1178,
    "displayName": "Superior Rampaging Fury Gloves",
    "name": "Rampaging Fury Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 3,
      "HP": 54,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1176,
      1177,
      1178
    ],
    "setId": 37,
    "animType": null
  },
  "1179": {
    "id": 1179,
    "displayName": "Rampaging Fury Gloves",
    "name": "Rampaging Fury Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 2,
      "HP": 42,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1180,
    "tierItemIds": [
      1179,
      1180,
      1181
    ],
    "setId": 37,
    "animType": null
  },
  "1180": {
    "id": 1180,
    "displayName": "Mighty Rampaging Fury Gloves",
    "name": "Rampaging Fury Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 3,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1181,
    "tierItemIds": [
      1179,
      1180,
      1181
    ],
    "setId": 37,
    "animType": null
  },
  "1181": {
    "id": 1181,
    "displayName": "Superior Rampaging Fury Gloves",
    "name": "Rampaging Fury Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 5,
      "HP": 85,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1179,
      1180,
      1181
    ],
    "setId": 37,
    "animType": null
  },
  "1182": {
    "id": 1182,
    "displayName": "Rampaging Fury Gloves",
    "name": "Rampaging Fury Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 3,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1183,
    "tierItemIds": [
      1182,
      1183,
      1184
    ],
    "setId": 37,
    "animType": null
  },
  "1183": {
    "id": 1183,
    "displayName": "Mighty Rampaging Fury Gloves",
    "name": "Rampaging Fury Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 5,
      "HP": 85,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1184,
    "tierItemIds": [
      1182,
      1183,
      1184
    ],
    "setId": 37,
    "animType": null
  },
  "1184": {
    "id": 1184,
    "displayName": "Superior Rampaging Fury Gloves",
    "name": "Rampaging Fury Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 7,
      "LUK": 8,
      "HP": 119,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1182,
      1183,
      1184
    ],
    "setId": 37,
    "animType": null
  },
  "1185": {
    "id": 1185,
    "displayName": "Rampaging Fury Helmet",
    "name": "Rampaging Fury Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 2,
      "HP": 27,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1186,
    "tierItemIds": [
      1185,
      1186,
      1187
    ],
    "setId": 37,
    "animType": null
  },
  "1186": {
    "id": 1186,
    "displayName": "Mighty Rampaging Fury Helmet",
    "name": "Rampaging Fury Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 3,
      "HP": 38,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1187,
    "tierItemIds": [
      1185,
      1186,
      1187
    ],
    "setId": 37,
    "animType": null
  },
  "1187": {
    "id": 1187,
    "displayName": "Superior Rampaging Fury Helmet",
    "name": "Rampaging Fury Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 2,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 5,
      "HP": 54,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1185,
      1186,
      1187
    ],
    "setId": 37,
    "animType": null
  },
  "1188": {
    "id": 1188,
    "displayName": "Rampaging Fury Helmet",
    "name": "Rampaging Fury Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 3,
      "HP": 42,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1189,
    "tierItemIds": [
      1188,
      1189,
      1190
    ],
    "setId": 37,
    "animType": null
  },
  "1189": {
    "id": 1189,
    "displayName": "Mighty Rampaging Fury Helmet",
    "name": "Rampaging Fury Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 2,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 5,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1190,
    "tierItemIds": [
      1188,
      1189,
      1190
    ],
    "setId": 37,
    "animType": null
  },
  "1190": {
    "id": 1190,
    "displayName": "Superior Rampaging Fury Helmet",
    "name": "Rampaging Fury Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 4,
      "STR": 2,
      "AGI": 0,
      "INT": 6,
      "LUK": 8,
      "HP": 85,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1188,
      1189,
      1190
    ],
    "setId": 37,
    "animType": null
  },
  "1191": {
    "id": 1191,
    "displayName": "Rampaging Fury Helmet",
    "name": "Rampaging Fury Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 2,
      "STR": 1,
      "AGI": 0,
      "INT": 4,
      "LUK": 5,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1192,
    "tierItemIds": [
      1191,
      1192,
      1193
    ],
    "setId": 37,
    "animType": null
  },
  "1192": {
    "id": 1192,
    "displayName": "Mighty Rampaging Fury Helmet",
    "name": "Rampaging Fury Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 4,
      "STR": 2,
      "AGI": 0,
      "INT": 6,
      "LUK": 8,
      "HP": 85,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1193,
    "tierItemIds": [
      1191,
      1192,
      1193
    ],
    "setId": 37,
    "animType": null
  },
  "1193": {
    "id": 1193,
    "displayName": "Superior Rampaging Fury Helmet",
    "name": "Rampaging Fury Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 6,
      "STR": 4,
      "AGI": 0,
      "INT": 9,
      "LUK": 12,
      "HP": 119,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1191,
      1192,
      1193
    ],
    "setId": 37,
    "animType": null
  },
  "1194": {
    "id": 1194,
    "displayName": "Rampaging Fury Armor",
    "name": "Rampaging Fury Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1195,
    "tierItemIds": [
      1194,
      1195,
      1196
    ],
    "setId": 37,
    "animType": null
  },
  "1195": {
    "id": 1195,
    "displayName": "Mighty Rampaging Fury Armor",
    "name": "Rampaging Fury Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 42,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1196,
    "tierItemIds": [
      1194,
      1195,
      1196
    ],
    "setId": 37,
    "animType": null
  },
  "1196": {
    "id": 1196,
    "displayName": "Superior Rampaging Fury Armor",
    "name": "Rampaging Fury Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1194,
      1195,
      1196
    ],
    "setId": 37,
    "animType": null
  },
  "1197": {
    "id": 1197,
    "displayName": "Rampaging Fury Armor",
    "name": "Rampaging Fury Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1198,
    "tierItemIds": [
      1197,
      1198,
      1199
    ],
    "setId": 37,
    "animType": null
  },
  "1198": {
    "id": 1198,
    "displayName": "Mighty Rampaging Fury Armor",
    "name": "Rampaging Fury Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 67,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1199,
    "tierItemIds": [
      1197,
      1198,
      1199
    ],
    "setId": 37,
    "animType": null
  },
  "1199": {
    "id": 1199,
    "displayName": "Superior Rampaging Fury Armor",
    "name": "Rampaging Fury Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 95,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1197,
      1198,
      1199
    ],
    "setId": 37,
    "animType": null
  },
  "1200": {
    "id": 1200,
    "displayName": "Rampaging Fury Armor",
    "name": "Rampaging Fury Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 67,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1201,
    "tierItemIds": [
      1200,
      1201,
      1202
    ],
    "setId": 37,
    "animType": null
  },
  "1201": {
    "id": 1201,
    "displayName": "Mighty Rampaging Fury Armor",
    "name": "Rampaging Fury Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 95,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1202,
    "tierItemIds": [
      1200,
      1201,
      1202
    ],
    "setId": 37,
    "animType": null
  },
  "1202": {
    "id": 1202,
    "displayName": "Superior Rampaging Fury Armor",
    "name": "Rampaging Fury Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 133,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1200,
      1201,
      1202
    ],
    "setId": 37,
    "animType": null
  },
  "1203": {
    "id": 1203,
    "displayName": "Rampaging Fury Boots",
    "name": "Rampaging Fury Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 7,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1204,
    "tierItemIds": [
      1203,
      1204,
      1205
    ],
    "setId": 37,
    "animType": null
  },
  "1204": {
    "id": 1204,
    "displayName": "Mighty Rampaging Fury Boots",
    "name": "Rampaging Fury Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 10,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1205,
    "tierItemIds": [
      1203,
      1204,
      1205
    ],
    "setId": 37,
    "animType": null
  },
  "1205": {
    "id": 1205,
    "displayName": "Superior Rampaging Fury Boots",
    "name": "Rampaging Fury Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 15,
      "INT": 1,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 4,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1203,
      1204,
      1205
    ],
    "setId": 37,
    "animType": null
  },
  "1206": {
    "id": 1206,
    "displayName": "Rampaging Fury Boots",
    "name": "Rampaging Fury Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 12,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1207,
    "tierItemIds": [
      1206,
      1207,
      1208
    ],
    "setId": 37,
    "animType": null
  },
  "1207": {
    "id": 1207,
    "displayName": "Mighty Rampaging Fury Boots",
    "name": "Rampaging Fury Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 15,
      "INT": 1,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 4,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1208,
    "tierItemIds": [
      1206,
      1207,
      1208
    ],
    "setId": 37,
    "animType": null
  },
  "1208": {
    "id": 1208,
    "displayName": "Superior Rampaging Fury Boots",
    "name": "Rampaging Fury Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 2,
      "STR": 1,
      "AGI": 22,
      "INT": 2,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 6,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1206,
      1207,
      1208
    ],
    "setId": 37,
    "animType": null
  },
  "1209": {
    "id": 1209,
    "displayName": "Rampaging Fury Boots",
    "name": "Rampaging Fury Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 15,
      "INT": 1,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 4,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1210,
    "tierItemIds": [
      1209,
      1210,
      1211
    ],
    "setId": 37,
    "animType": null
  },
  "1210": {
    "id": 1210,
    "displayName": "Mighty Rampaging Fury Boots",
    "name": "Rampaging Fury Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 2,
      "STR": 1,
      "AGI": 22,
      "INT": 2,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 6,
      "Speed": 7,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1211,
    "tierItemIds": [
      1209,
      1210,
      1211
    ],
    "setId": 37,
    "animType": null
  },
  "1211": {
    "id": 1211,
    "displayName": "Superior Rampaging Fury Boots",
    "name": "Rampaging Fury Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 2,
      "AGI": 32,
      "INT": 3,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 9,
      "Speed": 10,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1209,
      1210,
      1211
    ],
    "setId": 37,
    "animType": null
  },
  "1212": {
    "id": 1212,
    "displayName": "Rampaging Fury Pants",
    "name": "Rampaging Fury Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 7,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1213,
    "tierItemIds": [
      1212,
      1213,
      1214
    ],
    "setId": 37,
    "animType": null
  },
  "1213": {
    "id": 1213,
    "displayName": "Mighty Rampaging Fury Pants",
    "name": "Rampaging Fury Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 10,
      "Critical": 0,
      "Evade": 3,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1214,
    "tierItemIds": [
      1212,
      1213,
      1214
    ],
    "setId": 37,
    "animType": null
  },
  "1214": {
    "id": 1214,
    "displayName": "Superior Rampaging Fury Pants",
    "name": "Rampaging Fury Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 11,
      "INT": 1,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 5,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1212,
      1213,
      1214
    ],
    "setId": 37,
    "animType": null
  },
  "1215": {
    "id": 1215,
    "displayName": "Rampaging Fury Pants",
    "name": "Rampaging Fury Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 12,
      "Critical": 0,
      "Evade": 3,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1216,
    "tierItemIds": [
      1215,
      1216,
      1217
    ],
    "setId": 37,
    "animType": null
  },
  "1216": {
    "id": 1216,
    "displayName": "Mighty Rampaging Fury Pants",
    "name": "Rampaging Fury Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 11,
      "INT": 1,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 5,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1217,
    "tierItemIds": [
      1215,
      1216,
      1217
    ],
    "setId": 37,
    "animType": null
  },
  "1217": {
    "id": 1217,
    "displayName": "Superior Rampaging Fury Pants",
    "name": "Rampaging Fury Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 16,
      "INT": 2,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 8,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1215,
      1216,
      1217
    ],
    "setId": 37,
    "animType": null
  },
  "1218": {
    "id": 1218,
    "displayName": "Rampaging Fury Pants",
    "name": "Rampaging Fury Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 11,
      "INT": 1,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 5,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1219,
    "tierItemIds": [
      1218,
      1219,
      1220
    ],
    "setId": 37,
    "animType": null
  },
  "1219": {
    "id": 1219,
    "displayName": "Mighty Rampaging Fury Pants",
    "name": "Rampaging Fury Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 16,
      "INT": 2,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 8,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1220,
    "tierItemIds": [
      1218,
      1219,
      1220
    ],
    "setId": 37,
    "animType": null
  },
  "1220": {
    "id": 1220,
    "displayName": "Superior Rampaging Fury Pants",
    "name": "Rampaging Fury Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 23,
      "INT": 4,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 12,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1218,
      1219,
      1220
    ],
    "setId": 37,
    "animType": null
  },
  "1221": {
    "id": 1221,
    "displayName": "Elven Grove Staff",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 40,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1222,
    "tierItemIds": [
      1221,
      1222,
      1223
    ],
    "setId": 38,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1222": {
    "id": 1222,
    "displayName": "Mighty Elven Grove Staff",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 57,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1223,
    "tierItemIds": [
      1221,
      1222,
      1223
    ],
    "setId": 38,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1223": {
    "id": 1223,
    "displayName": "Superior Elven Grove Staff",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 81,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1221,
      1222,
      1223
    ],
    "setId": 38,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1224": {
    "id": 1224,
    "displayName": "Elven Grove Staff",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 57,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1225,
    "tierItemIds": [
      1224,
      1225,
      1226
    ],
    "setId": 38,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1225": {
    "id": 1225,
    "displayName": "Mighty Elven Grove Staff",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 81,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1226,
    "tierItemIds": [
      1224,
      1225,
      1226
    ],
    "setId": 38,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1226": {
    "id": 1226,
    "displayName": "Superior Elven Grove Staff",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 114,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1224,
      1225,
      1226
    ],
    "setId": 38,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1227": {
    "id": 1227,
    "displayName": "Elven Grove Staff",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 81,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1228,
    "tierItemIds": [
      1227,
      1228,
      1229
    ],
    "setId": 38,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1228": {
    "id": 1228,
    "displayName": "Mighty Elven Grove Staff",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 114,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1229,
    "tierItemIds": [
      1227,
      1228,
      1229
    ],
    "setId": 38,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1229": {
    "id": 1229,
    "displayName": "Superior Elven Grove Staff",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 160,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 16,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1227,
      1228,
      1229
    ],
    "setId": 38,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1230": {
    "id": 1230,
    "displayName": "Elven Grove Orb",
    "name": "Elven Grove Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1231,
    "tierItemIds": [
      1230,
      1231,
      1232
    ],
    "setId": 38,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1231": {
    "id": 1231,
    "displayName": "Mighty Elven Grove Orb",
    "name": "Elven Grove Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 0,
      "AGI": 0,
      "INT": 11,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1232,
    "tierItemIds": [
      1230,
      1231,
      1232
    ],
    "setId": 38,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1232": {
    "id": 1232,
    "displayName": "Superior Elven Grove Orb",
    "name": "Elven Grove Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 12,
      "STR": 0,
      "AGI": 0,
      "INT": 16,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1230,
      1231,
      1232
    ],
    "setId": 38,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1233": {
    "id": 1233,
    "displayName": "Elven Grove Orb",
    "name": "Elven Grove Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 0,
      "AGI": 0,
      "INT": 11,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1234,
    "tierItemIds": [
      1233,
      1234,
      1235
    ],
    "setId": 38,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1234": {
    "id": 1234,
    "displayName": "Mighty Elven Grove Orb",
    "name": "Elven Grove Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 12,
      "STR": 0,
      "AGI": 0,
      "INT": 16,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1235,
    "tierItemIds": [
      1233,
      1234,
      1235
    ],
    "setId": 38,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1235": {
    "id": 1235,
    "displayName": "Superior Elven Grove Orb",
    "name": "Elven Grove Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 32,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 18,
      "STR": 0,
      "AGI": 0,
      "INT": 23,
      "LUK": 5,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1233,
      1234,
      1235
    ],
    "setId": 38,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1236": {
    "id": 1236,
    "displayName": "Elven Grove Orb",
    "name": "Elven Grove Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 12,
      "STR": 0,
      "AGI": 0,
      "INT": 16,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1237,
    "tierItemIds": [
      1236,
      1237,
      1238
    ],
    "setId": 38,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1237": {
    "id": 1237,
    "displayName": "Mighty Elven Grove Orb",
    "name": "Elven Grove Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 32,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 18,
      "STR": 0,
      "AGI": 0,
      "INT": 23,
      "LUK": 5,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1238,
    "tierItemIds": [
      1236,
      1237,
      1238
    ],
    "setId": 38,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1238": {
    "id": 1238,
    "displayName": "Superior Elven Grove Orb",
    "name": "Elven Grove Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 46,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 26,
      "STR": 0,
      "AGI": 0,
      "INT": 33,
      "LUK": 8,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1236,
      1237,
      1238
    ],
    "setId": 38,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1239": {
    "id": 1239,
    "displayName": "Elven Grove Sword",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 53,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1240,
    "tierItemIds": [
      1239,
      1240,
      1241
    ],
    "setId": 38,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1240": {
    "id": 1240,
    "displayName": "Mighty Elven Grove Sword",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 75,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1241,
    "tierItemIds": [
      1239,
      1240,
      1241
    ],
    "setId": 38,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1241": {
    "id": 1241,
    "displayName": "Superior Elven Grove Sword",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 105,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1239,
      1240,
      1241
    ],
    "setId": 38,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1242": {
    "id": 1242,
    "displayName": "Elven Grove Sword",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 75,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1243,
    "tierItemIds": [
      1242,
      1243,
      1244
    ],
    "setId": 38,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1243": {
    "id": 1243,
    "displayName": "Mighty Elven Grove Sword",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 105,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1244,
    "tierItemIds": [
      1242,
      1243,
      1244
    ],
    "setId": 38,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1244": {
    "id": 1244,
    "displayName": "Superior Elven Grove Sword",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 148,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1242,
      1243,
      1244
    ],
    "setId": 38,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1245": {
    "id": 1245,
    "displayName": "Elven Grove Sword",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 105,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1246,
    "tierItemIds": [
      1245,
      1246,
      1247
    ],
    "setId": 38,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1246": {
    "id": 1246,
    "displayName": "Mighty Elven Grove Sword",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 148,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1247,
    "tierItemIds": [
      1245,
      1246,
      1247
    ],
    "setId": 38,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1247": {
    "id": 1247,
    "displayName": "Superior Elven Grove Sword",
    "name": "Elven Grove Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 208,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 16,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1245,
      1246,
      1247
    ],
    "setId": 38,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1248": {
    "id": 1248,
    "displayName": "Elven Grove Gloves",
    "name": "Elven Grove Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1249,
    "tierItemIds": [
      1248,
      1249,
      1250
    ],
    "setId": 38,
    "animType": null
  },
  "1249": {
    "id": 1249,
    "displayName": "Mighty Elven Grove Gloves",
    "name": "Elven Grove Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 64,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1250,
    "tierItemIds": [
      1248,
      1249,
      1250
    ],
    "setId": 38,
    "animType": null
  },
  "1250": {
    "id": 1250,
    "displayName": "Superior Elven Grove Gloves",
    "name": "Elven Grove Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 90,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1248,
      1249,
      1250
    ],
    "setId": 38,
    "animType": null
  },
  "1251": {
    "id": 1251,
    "displayName": "Elven Grove Gloves",
    "name": "Elven Grove Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1252,
    "tierItemIds": [
      1251,
      1252,
      1253
    ],
    "setId": 38,
    "animType": null
  },
  "1252": {
    "id": 1252,
    "displayName": "Mighty Elven Grove Gloves",
    "name": "Elven Grove Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1253,
    "tierItemIds": [
      1251,
      1252,
      1253
    ],
    "setId": 38,
    "animType": null
  },
  "1253": {
    "id": 1253,
    "displayName": "Superior Elven Grove Gloves",
    "name": "Elven Grove Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 140,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1251,
      1252,
      1253
    ],
    "setId": 38,
    "animType": null
  },
  "1254": {
    "id": 1254,
    "displayName": "Elven Grove Gloves",
    "name": "Elven Grove Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1255,
    "tierItemIds": [
      1254,
      1255,
      1256
    ],
    "setId": 38,
    "animType": null
  },
  "1255": {
    "id": 1255,
    "displayName": "Mighty Elven Grove Gloves",
    "name": "Elven Grove Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 140,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1256,
    "tierItemIds": [
      1254,
      1255,
      1256
    ],
    "setId": 38,
    "animType": null
  },
  "1256": {
    "id": 1256,
    "displayName": "Superior Elven Grove Gloves",
    "name": "Elven Grove Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 11,
      "LUK": 2,
      "HP": 196,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1254,
      1255,
      1256
    ],
    "setId": 38,
    "animType": null
  },
  "1257": {
    "id": 1257,
    "displayName": "Elven Grove Helmet",
    "name": "Elven Grove Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1258,
    "tierItemIds": [
      1257,
      1258,
      1259
    ],
    "setId": 38,
    "animType": null
  },
  "1258": {
    "id": 1258,
    "displayName": "Mighty Elven Grove Helmet",
    "name": "Elven Grove Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 3,
      "Critical Damage": 1,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 64,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1259,
    "tierItemIds": [
      1257,
      1258,
      1259
    ],
    "setId": 38,
    "animType": null
  },
  "1259": {
    "id": 1259,
    "displayName": "Superior Elven Grove Helmet",
    "name": "Elven Grove Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 5,
      "Critical Damage": 2,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 90,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1257,
      1258,
      1259
    ],
    "setId": 38,
    "animType": null
  },
  "1260": {
    "id": 1260,
    "displayName": "Elven Grove Helmet",
    "name": "Elven Grove Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 3,
      "Critical Damage": 1,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1261,
    "tierItemIds": [
      1260,
      1261,
      1262
    ],
    "setId": 38,
    "animType": null
  },
  "1261": {
    "id": 1261,
    "displayName": "Mighty Elven Grove Helmet",
    "name": "Elven Grove Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 5,
      "Critical Damage": 2,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1262,
    "tierItemIds": [
      1260,
      1261,
      1262
    ],
    "setId": 38,
    "animType": null
  },
  "1262": {
    "id": 1262,
    "displayName": "Superior Elven Grove Helmet",
    "name": "Elven Grove Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 16,
      "Physical Defense": 7,
      "Critical Damage": 3,
      "STR": 3,
      "AGI": 0,
      "INT": 10,
      "LUK": 2,
      "HP": 140,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1260,
      1261,
      1262
    ],
    "setId": 38,
    "animType": null
  },
  "1263": {
    "id": 1263,
    "displayName": "Elven Grove Helmet",
    "name": "Elven Grove Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 5,
      "Critical Damage": 2,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1264,
    "tierItemIds": [
      1263,
      1264,
      1265
    ],
    "setId": 38,
    "animType": null
  },
  "1264": {
    "id": 1264,
    "displayName": "Mighty Elven Grove Helmet",
    "name": "Elven Grove Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 16,
      "Physical Defense": 7,
      "Critical Damage": 3,
      "STR": 3,
      "AGI": 0,
      "INT": 10,
      "LUK": 2,
      "HP": 140,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1265,
    "tierItemIds": [
      1263,
      1264,
      1265
    ],
    "setId": 38,
    "animType": null
  },
  "1265": {
    "id": 1265,
    "displayName": "Superior Elven Grove Helmet",
    "name": "Elven Grove Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 23,
      "Physical Defense": 10,
      "Critical Damage": 5,
      "STR": 5,
      "AGI": 0,
      "INT": 15,
      "LUK": 3,
      "HP": 196,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1263,
      1264,
      1265
    ],
    "setId": 38,
    "animType": null
  },
  "1266": {
    "id": 1266,
    "displayName": "Elven Grove Armor",
    "name": "Elven Grove Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1267,
    "tierItemIds": [
      1266,
      1267,
      1268
    ],
    "setId": 38,
    "animType": null
  },
  "1267": {
    "id": 1267,
    "displayName": "Mighty Elven Grove Armor",
    "name": "Elven Grove Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1268,
    "tierItemIds": [
      1266,
      1267,
      1268
    ],
    "setId": 38,
    "animType": null
  },
  "1268": {
    "id": 1268,
    "displayName": "Superior Elven Grove Armor",
    "name": "Elven Grove Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1266,
      1267,
      1268
    ],
    "setId": 38,
    "animType": null
  },
  "1269": {
    "id": 1269,
    "displayName": "Elven Grove Armor",
    "name": "Elven Grove Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1270,
    "tierItemIds": [
      1269,
      1270,
      1271
    ],
    "setId": 38,
    "animType": null
  },
  "1270": {
    "id": 1270,
    "displayName": "Mighty Elven Grove Armor",
    "name": "Elven Grove Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1271,
    "tierItemIds": [
      1269,
      1270,
      1271
    ],
    "setId": 38,
    "animType": null
  },
  "1271": {
    "id": 1271,
    "displayName": "Superior Elven Grove Armor",
    "name": "Elven Grove Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 175,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1269,
      1270,
      1271
    ],
    "setId": 38,
    "animType": null
  },
  "1272": {
    "id": 1272,
    "displayName": "Elven Grove Armor",
    "name": "Elven Grove Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1273,
    "tierItemIds": [
      1272,
      1273,
      1274
    ],
    "setId": 38,
    "animType": null
  },
  "1273": {
    "id": 1273,
    "displayName": "Mighty Elven Grove Armor",
    "name": "Elven Grove Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 175,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1274,
    "tierItemIds": [
      1272,
      1273,
      1274
    ],
    "setId": 38,
    "animType": null
  },
  "1274": {
    "id": 1274,
    "displayName": "Superior Elven Grove Armor",
    "name": "Elven Grove Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 13,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 11,
      "LUK": 0,
      "HP": 245,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1272,
      1273,
      1274
    ],
    "setId": 38,
    "animType": null
  },
  "1275": {
    "id": 1275,
    "displayName": "Elven Grove Boots",
    "name": "Elven Grove Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 10,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1276,
    "tierItemIds": [
      1275,
      1276,
      1277
    ],
    "setId": 38,
    "animType": null
  },
  "1276": {
    "id": 1276,
    "displayName": "Mighty Elven Grove Boots",
    "name": "Elven Grove Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1277,
    "tierItemIds": [
      1275,
      1276,
      1277
    ],
    "setId": 38,
    "animType": null
  },
  "1277": {
    "id": 1277,
    "displayName": "Superior Elven Grove Boots",
    "name": "Elven Grove Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1275,
      1276,
      1277
    ],
    "setId": 38,
    "animType": null
  },
  "1278": {
    "id": 1278,
    "displayName": "Elven Grove Boots",
    "name": "Elven Grove Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1279,
    "tierItemIds": [
      1278,
      1279,
      1280
    ],
    "setId": 38,
    "animType": null
  },
  "1279": {
    "id": 1279,
    "displayName": "Mighty Elven Grove Boots",
    "name": "Elven Grove Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1280,
    "tierItemIds": [
      1278,
      1279,
      1280
    ],
    "setId": 38,
    "animType": null
  },
  "1280": {
    "id": 1280,
    "displayName": "Superior Elven Grove Boots",
    "name": "Elven Grove Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 4,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1278,
      1279,
      1280
    ],
    "setId": 38,
    "animType": null
  },
  "1281": {
    "id": 1281,
    "displayName": "Elven Grove Boots",
    "name": "Elven Grove Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1282,
    "tierItemIds": [
      1281,
      1282,
      1283
    ],
    "setId": 38,
    "animType": null
  },
  "1282": {
    "id": 1282,
    "displayName": "Mighty Elven Grove Boots",
    "name": "Elven Grove Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 4,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1283,
    "tierItemIds": [
      1281,
      1282,
      1283
    ],
    "setId": 38,
    "animType": null
  },
  "1283": {
    "id": 1283,
    "displayName": "Superior Elven Grove Boots",
    "name": "Elven Grove Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 6,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1281,
      1282,
      1283
    ],
    "setId": 38,
    "animType": null
  },
  "1284": {
    "id": 1284,
    "displayName": "Elven Grove Pants",
    "name": "Elven Grove Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1285,
    "tierItemIds": [
      1284,
      1285,
      1286
    ],
    "setId": 38,
    "animType": null
  },
  "1285": {
    "id": 1285,
    "displayName": "Mighty Elven Grove Pants",
    "name": "Elven Grove Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1286,
    "tierItemIds": [
      1284,
      1285,
      1286
    ],
    "setId": 38,
    "animType": null
  },
  "1286": {
    "id": 1286,
    "displayName": "Superior Elven Grove Pants",
    "name": "Elven Grove Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 3,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1284,
      1285,
      1286
    ],
    "setId": 38,
    "animType": null
  },
  "1287": {
    "id": 1287,
    "displayName": "Elven Grove Pants",
    "name": "Elven Grove Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1288,
    "tierItemIds": [
      1287,
      1288,
      1289
    ],
    "setId": 38,
    "animType": null
  },
  "1288": {
    "id": 1288,
    "displayName": "Mighty Elven Grove Pants",
    "name": "Elven Grove Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 3,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1289,
    "tierItemIds": [
      1287,
      1288,
      1289
    ],
    "setId": 38,
    "animType": null
  },
  "1289": {
    "id": 1289,
    "displayName": "Superior Elven Grove Pants",
    "name": "Elven Grove Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 5,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1287,
      1288,
      1289
    ],
    "setId": 38,
    "animType": null
  },
  "1290": {
    "id": 1290,
    "displayName": "Elven Grove Pants",
    "name": "Elven Grove Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 3,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1291,
    "tierItemIds": [
      1290,
      1291,
      1292
    ],
    "setId": 38,
    "animType": null
  },
  "1291": {
    "id": 1291,
    "displayName": "Mighty Elven Grove Pants",
    "name": "Elven Grove Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 5,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1292,
    "tierItemIds": [
      1290,
      1291,
      1292
    ],
    "setId": 38,
    "animType": null
  },
  "1292": {
    "id": 1292,
    "displayName": "Superior Elven Grove Pants",
    "name": "Elven Grove Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 98,
      "Critical": 0,
      "Evade": 7,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1290,
      1291,
      1292
    ],
    "setId": 38,
    "animType": null
  },
  "1293": {
    "id": 1293,
    "displayName": "Draconic Cataclysm Staff",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 54,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1294,
    "tierItemIds": [
      1293,
      1294,
      1295
    ],
    "setId": 39,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1294": {
    "id": 1294,
    "displayName": "Mighty Draconic Cataclysm Staff",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 76,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1295,
    "tierItemIds": [
      1293,
      1294,
      1295
    ],
    "setId": 39,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1295": {
    "id": 1295,
    "displayName": "Superior Draconic Cataclysm Staff",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 107,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1293,
      1294,
      1295
    ],
    "setId": 39,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1296": {
    "id": 1296,
    "displayName": "Draconic Cataclysm Staff",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 76,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 4,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1297,
    "tierItemIds": [
      1296,
      1297,
      1298
    ],
    "setId": 39,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1297": {
    "id": 1297,
    "displayName": "Mighty Draconic Cataclysm Staff",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 107,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1298,
    "tierItemIds": [
      1296,
      1297,
      1298
    ],
    "setId": 39,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1298": {
    "id": 1298,
    "displayName": "Superior Draconic Cataclysm Staff",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 150,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1296,
      1297,
      1298
    ],
    "setId": 39,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1299": {
    "id": 1299,
    "displayName": "Draconic Cataclysm Staff",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 107,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1300,
    "tierItemIds": [
      1299,
      1300,
      1301
    ],
    "setId": 39,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1300": {
    "id": 1300,
    "displayName": "Mighty Draconic Cataclysm Staff",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 150,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 12,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1301,
    "tierItemIds": [
      1299,
      1300,
      1301
    ],
    "setId": 39,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1301": {
    "id": 1301,
    "displayName": "Superior Draconic Cataclysm Staff",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 210,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 9,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 15,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1299,
      1300,
      1301
    ],
    "setId": 39,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1302": {
    "id": 1302,
    "displayName": "Draconic Cataclysm Shield",
    "name": "Draconic Cataclysm Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1303,
    "tierItemIds": [
      1302,
      1303,
      1304
    ],
    "setId": 39,
    "animType": null,
    "damageType": "Physical"
  },
  "1303": {
    "id": 1303,
    "displayName": "Mighty Draconic Cataclysm Shield",
    "name": "Draconic Cataclysm Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1304,
    "tierItemIds": [
      1302,
      1303,
      1304
    ],
    "setId": 39,
    "animType": null,
    "damageType": "Physical"
  },
  "1304": {
    "id": 1304,
    "displayName": "Superior Draconic Cataclysm Shield",
    "name": "Draconic Cataclysm Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 113,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1302,
      1303,
      1304
    ],
    "setId": 39,
    "animType": null,
    "damageType": "Physical"
  },
  "1305": {
    "id": 1305,
    "displayName": "Draconic Cataclysm Shield",
    "name": "Draconic Cataclysm Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 90,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1306,
    "tierItemIds": [
      1305,
      1306,
      1307
    ],
    "setId": 39,
    "animType": null,
    "damageType": "Physical"
  },
  "1306": {
    "id": 1306,
    "displayName": "Mighty Draconic Cataclysm Shield",
    "name": "Draconic Cataclysm Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 126,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1307,
    "tierItemIds": [
      1305,
      1306,
      1307
    ],
    "setId": 39,
    "animType": null,
    "damageType": "Physical"
  },
  "1307": {
    "id": 1307,
    "displayName": "Superior Draconic Cataclysm Shield",
    "name": "Draconic Cataclysm Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 8,
      "HP": 177,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1305,
      1306,
      1307
    ],
    "setId": 39,
    "animType": null,
    "damageType": "Physical"
  },
  "1308": {
    "id": 1308,
    "displayName": "Draconic Cataclysm Shield",
    "name": "Draconic Cataclysm Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 126,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1309,
    "tierItemIds": [
      1308,
      1309,
      1310
    ],
    "setId": 39,
    "animType": null,
    "damageType": "Physical"
  },
  "1309": {
    "id": 1309,
    "displayName": "Mighty Draconic Cataclysm Shield",
    "name": "Draconic Cataclysm Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 8,
      "HP": 177,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1310,
    "tierItemIds": [
      1308,
      1309,
      1310
    ],
    "setId": 39,
    "animType": null,
    "damageType": "Physical"
  },
  "1310": {
    "id": 1310,
    "displayName": "Superior Draconic Cataclysm Shield",
    "name": "Draconic Cataclysm Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 20,
      "Physical Defense": 20,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 12,
      "HP": 249,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1308,
      1309,
      1310
    ],
    "setId": 39,
    "animType": null,
    "damageType": "Physical"
  },
  "1311": {
    "id": 1311,
    "displayName": "Draconic Cataclysm Axe",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 41,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1312,
    "tierItemIds": [
      1311,
      1312,
      1313
    ],
    "setId": 39,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1312": {
    "id": 1312,
    "displayName": "Mighty Draconic Cataclysm Axe",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 58,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1313,
    "tierItemIds": [
      1311,
      1312,
      1313
    ],
    "setId": 39,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1313": {
    "id": 1313,
    "displayName": "Superior Draconic Cataclysm Axe",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 82,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1311,
      1312,
      1313
    ],
    "setId": 39,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1314": {
    "id": 1314,
    "displayName": "Draconic Cataclysm Axe",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 58,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 4,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1315,
    "tierItemIds": [
      1314,
      1315,
      1316
    ],
    "setId": 39,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1315": {
    "id": 1315,
    "displayName": "Mighty Draconic Cataclysm Axe",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 82,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1316,
    "tierItemIds": [
      1314,
      1315,
      1316
    ],
    "setId": 39,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1316": {
    "id": 1316,
    "displayName": "Superior Draconic Cataclysm Axe",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 115,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1314,
      1315,
      1316
    ],
    "setId": 39,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1317": {
    "id": 1317,
    "displayName": "Draconic Cataclysm Axe",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 82,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1318,
    "tierItemIds": [
      1317,
      1318,
      1319
    ],
    "setId": 39,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1318": {
    "id": 1318,
    "displayName": "Mighty Draconic Cataclysm Axe",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 115,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 12,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1319,
    "tierItemIds": [
      1317,
      1318,
      1319
    ],
    "setId": 39,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1319": {
    "id": 1319,
    "displayName": "Superior Draconic Cataclysm Axe",
    "name": "Draconic Cataclysm Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 162,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 7,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 15,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1317,
      1318,
      1319
    ],
    "setId": 39,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1320": {
    "id": 1320,
    "displayName": "Draconic Cataclysm Gloves",
    "name": "Draconic Cataclysm Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1321,
    "tierItemIds": [
      1320,
      1321,
      1322
    ],
    "setId": 39,
    "animType": null
  },
  "1321": {
    "id": 1321,
    "displayName": "Mighty Draconic Cataclysm Gloves",
    "name": "Draconic Cataclysm Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1322,
    "tierItemIds": [
      1320,
      1321,
      1322
    ],
    "setId": 39,
    "animType": null
  },
  "1322": {
    "id": 1322,
    "displayName": "Superior Draconic Cataclysm Gloves",
    "name": "Draconic Cataclysm Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1320,
      1321,
      1322
    ],
    "setId": 39,
    "animType": null
  },
  "1323": {
    "id": 1323,
    "displayName": "Draconic Cataclysm Gloves",
    "name": "Draconic Cataclysm Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 69,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1324,
    "tierItemIds": [
      1323,
      1324,
      1325
    ],
    "setId": 39,
    "animType": null
  },
  "1324": {
    "id": 1324,
    "displayName": "Mighty Draconic Cataclysm Gloves",
    "name": "Draconic Cataclysm Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 97,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1325,
    "tierItemIds": [
      1323,
      1324,
      1325
    ],
    "setId": 39,
    "animType": null
  },
  "1325": {
    "id": 1325,
    "displayName": "Superior Draconic Cataclysm Gloves",
    "name": "Draconic Cataclysm Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 137,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1323,
      1324,
      1325
    ],
    "setId": 39,
    "animType": null
  },
  "1326": {
    "id": 1326,
    "displayName": "Draconic Cataclysm Gloves",
    "name": "Draconic Cataclysm Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 97,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1327,
    "tierItemIds": [
      1326,
      1327,
      1328
    ],
    "setId": 39,
    "animType": null
  },
  "1327": {
    "id": 1327,
    "displayName": "Mighty Draconic Cataclysm Gloves",
    "name": "Draconic Cataclysm Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 137,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1328,
    "tierItemIds": [
      1326,
      1327,
      1328
    ],
    "setId": 39,
    "animType": null
  },
  "1328": {
    "id": 1328,
    "displayName": "Superior Draconic Cataclysm Gloves",
    "name": "Draconic Cataclysm Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 10,
      "LUK": 5,
      "HP": 192,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1326,
      1327,
      1328
    ],
    "setId": 39,
    "animType": null
  },
  "1329": {
    "id": 1329,
    "displayName": "Draconic Cataclysm Helmet",
    "name": "Draconic Cataclysm Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 2,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 2,
      "HP": 44,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1330,
    "tierItemIds": [
      1329,
      1330,
      1331
    ],
    "setId": 39,
    "animType": null
  },
  "1330": {
    "id": 1330,
    "displayName": "Mighty Draconic Cataclysm Helmet",
    "name": "Draconic Cataclysm Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 5,
      "AGI": 0,
      "INT": 5,
      "LUK": 3,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1331,
    "tierItemIds": [
      1329,
      1330,
      1331
    ],
    "setId": 39,
    "animType": null
  },
  "1331": {
    "id": 1331,
    "displayName": "Superior Draconic Cataclysm Helmet",
    "name": "Draconic Cataclysm Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 7,
      "AGI": 0,
      "INT": 7,
      "LUK": 5,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1329,
      1330,
      1331
    ],
    "setId": 39,
    "animType": null
  },
  "1332": {
    "id": 1332,
    "displayName": "Draconic Cataclysm Helmet",
    "name": "Draconic Cataclysm Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 5,
      "AGI": 0,
      "INT": 5,
      "LUK": 3,
      "HP": 69,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1333,
    "tierItemIds": [
      1332,
      1333,
      1334
    ],
    "setId": 39,
    "animType": null
  },
  "1333": {
    "id": 1333,
    "displayName": "Mighty Draconic Cataclysm Helmet",
    "name": "Draconic Cataclysm Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 7,
      "AGI": 0,
      "INT": 7,
      "LUK": 5,
      "HP": 97,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1334,
    "tierItemIds": [
      1332,
      1333,
      1334
    ],
    "setId": 39,
    "animType": null
  },
  "1334": {
    "id": 1334,
    "displayName": "Superior Draconic Cataclysm Helmet",
    "name": "Draconic Cataclysm Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 7,
      "STR": 10,
      "AGI": 0,
      "INT": 10,
      "LUK": 7,
      "HP": 137,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1332,
      1333,
      1334
    ],
    "setId": 39,
    "animType": null
  },
  "1335": {
    "id": 1335,
    "displayName": "Draconic Cataclysm Helmet",
    "name": "Draconic Cataclysm Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 7,
      "AGI": 0,
      "INT": 7,
      "LUK": 5,
      "HP": 97,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1336,
    "tierItemIds": [
      1335,
      1336,
      1337
    ],
    "setId": 39,
    "animType": null
  },
  "1336": {
    "id": 1336,
    "displayName": "Mighty Draconic Cataclysm Helmet",
    "name": "Draconic Cataclysm Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 7,
      "STR": 10,
      "AGI": 0,
      "INT": 10,
      "LUK": 7,
      "HP": 137,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1337,
    "tierItemIds": [
      1335,
      1336,
      1337
    ],
    "setId": 39,
    "animType": null
  },
  "1337": {
    "id": 1337,
    "displayName": "Superior Draconic Cataclysm Helmet",
    "name": "Draconic Cataclysm Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 11,
      "Critical Damage": 10,
      "STR": 15,
      "AGI": 0,
      "INT": 15,
      "LUK": 10,
      "HP": 192,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1335,
      1336,
      1337
    ],
    "setId": 39,
    "animType": null
  },
  "1338": {
    "id": 1338,
    "displayName": "Draconic Cataclysm Armor",
    "name": "Draconic Cataclysm Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1339,
    "tierItemIds": [
      1338,
      1339,
      1340
    ],
    "setId": 39,
    "animType": null
  },
  "1339": {
    "id": 1339,
    "displayName": "Mighty Draconic Cataclysm Armor",
    "name": "Draconic Cataclysm Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1340,
    "tierItemIds": [
      1338,
      1339,
      1340
    ],
    "setId": 39,
    "animType": null
  },
  "1340": {
    "id": 1340,
    "displayName": "Superior Draconic Cataclysm Armor",
    "name": "Draconic Cataclysm Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1338,
      1339,
      1340
    ],
    "setId": 39,
    "animType": null
  },
  "1341": {
    "id": 1341,
    "displayName": "Draconic Cataclysm Armor",
    "name": "Draconic Cataclysm Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 77,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1342,
    "tierItemIds": [
      1341,
      1342,
      1343
    ],
    "setId": 39,
    "animType": null
  },
  "1342": {
    "id": 1342,
    "displayName": "Mighty Draconic Cataclysm Armor",
    "name": "Draconic Cataclysm Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 109,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1343,
    "tierItemIds": [
      1341,
      1342,
      1343
    ],
    "setId": 39,
    "animType": null
  },
  "1343": {
    "id": 1343,
    "displayName": "Superior Draconic Cataclysm Armor",
    "name": "Draconic Cataclysm Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 153,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1341,
      1342,
      1343
    ],
    "setId": 39,
    "animType": null
  },
  "1344": {
    "id": 1344,
    "displayName": "Draconic Cataclysm Armor",
    "name": "Draconic Cataclysm Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 109,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1345,
    "tierItemIds": [
      1344,
      1345,
      1346
    ],
    "setId": 39,
    "animType": null
  },
  "1345": {
    "id": 1345,
    "displayName": "Mighty Draconic Cataclysm Armor",
    "name": "Draconic Cataclysm Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 153,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1346,
    "tierItemIds": [
      1344,
      1345,
      1346
    ],
    "setId": 39,
    "animType": null
  },
  "1346": {
    "id": 1346,
    "displayName": "Superior Draconic Cataclysm Armor",
    "name": "Draconic Cataclysm Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 18,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 215,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1344,
      1345,
      1346
    ],
    "setId": 39,
    "animType": null
  },
  "1347": {
    "id": 1347,
    "displayName": "Draconic Cataclysm Boots",
    "name": "Draconic Cataclysm Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 1,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 12,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1348,
    "tierItemIds": [
      1347,
      1348,
      1349
    ],
    "setId": 39,
    "animType": null
  },
  "1348": {
    "id": 1348,
    "displayName": "Mighty Draconic Cataclysm Boots",
    "name": "Draconic Cataclysm Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 3,
      "STR": 2,
      "AGI": 2,
      "INT": 1,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1349,
    "tierItemIds": [
      1347,
      1348,
      1349
    ],
    "setId": 39,
    "animType": null
  },
  "1349": {
    "id": 1349,
    "displayName": "Superior Draconic Cataclysm Boots",
    "name": "Draconic Cataclysm Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 3,
      "INT": 2,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1347,
      1348,
      1349
    ],
    "setId": 39,
    "animType": null
  },
  "1350": {
    "id": 1350,
    "displayName": "Draconic Cataclysm Boots",
    "name": "Draconic Cataclysm Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 3,
      "STR": 2,
      "AGI": 2,
      "INT": 1,
      "LUK": 0,
      "HP": 20,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1351,
    "tierItemIds": [
      1350,
      1351,
      1352
    ],
    "setId": 39,
    "animType": null
  },
  "1351": {
    "id": 1351,
    "displayName": "Mighty Draconic Cataclysm Boots",
    "name": "Draconic Cataclysm Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 3,
      "INT": 2,
      "LUK": 0,
      "HP": 28,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1352,
    "tierItemIds": [
      1350,
      1351,
      1352
    ],
    "setId": 39,
    "animType": null
  },
  "1352": {
    "id": 1352,
    "displayName": "Superior Draconic Cataclysm Boots",
    "name": "Draconic Cataclysm Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 4,
      "Critical Damage": 7,
      "STR": 5,
      "AGI": 5,
      "INT": 3,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1350,
      1351,
      1352
    ],
    "setId": 39,
    "animType": null
  },
  "1353": {
    "id": 1353,
    "displayName": "Draconic Cataclysm Boots",
    "name": "Draconic Cataclysm Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 5,
      "STR": 3,
      "AGI": 3,
      "INT": 2,
      "LUK": 0,
      "HP": 28,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1354,
    "tierItemIds": [
      1353,
      1354,
      1355
    ],
    "setId": 39,
    "animType": null
  },
  "1354": {
    "id": 1354,
    "displayName": "Mighty Draconic Cataclysm Boots",
    "name": "Draconic Cataclysm Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 4,
      "Critical Damage": 7,
      "STR": 5,
      "AGI": 5,
      "INT": 3,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1355,
    "tierItemIds": [
      1353,
      1354,
      1355
    ],
    "setId": 39,
    "animType": null
  },
  "1355": {
    "id": 1355,
    "displayName": "Superior Draconic Cataclysm Boots",
    "name": "Draconic Cataclysm Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 6,
      "Critical Damage": 10,
      "STR": 8,
      "AGI": 7,
      "INT": 5,
      "LUK": 0,
      "HP": 56,
      "Critical": 0,
      "Evade": 1,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1353,
      1354,
      1355
    ],
    "setId": 39,
    "animType": null
  },
  "1356": {
    "id": 1356,
    "displayName": "Draconic Cataclysm Pants",
    "name": "Draconic Cataclysm Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 12,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1357,
    "tierItemIds": [
      1356,
      1357,
      1358
    ],
    "setId": 39,
    "animType": null
  },
  "1357": {
    "id": 1357,
    "displayName": "Mighty Draconic Cataclysm Pants",
    "name": "Draconic Cataclysm Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1358,
    "tierItemIds": [
      1356,
      1357,
      1358
    ],
    "setId": 39,
    "animType": null
  },
  "1358": {
    "id": 1358,
    "displayName": "Superior Draconic Cataclysm Pants",
    "name": "Draconic Cataclysm Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 1,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1356,
      1357,
      1358
    ],
    "setId": 39,
    "animType": null
  },
  "1359": {
    "id": 1359,
    "displayName": "Draconic Cataclysm Pants",
    "name": "Draconic Cataclysm Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 20,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1360,
    "tierItemIds": [
      1359,
      1360,
      1361
    ],
    "setId": 39,
    "animType": null
  },
  "1360": {
    "id": 1360,
    "displayName": "Mighty Draconic Cataclysm Pants",
    "name": "Draconic Cataclysm Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 1,
      "LUK": 0,
      "HP": 28,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1361,
    "tierItemIds": [
      1359,
      1360,
      1361
    ],
    "setId": 39,
    "animType": null
  },
  "1361": {
    "id": 1361,
    "displayName": "Superior Draconic Cataclysm Pants",
    "name": "Draconic Cataclysm Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 4,
      "INT": 2,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1359,
      1360,
      1361
    ],
    "setId": 39,
    "animType": null
  },
  "1362": {
    "id": 1362,
    "displayName": "Draconic Cataclysm Pants",
    "name": "Draconic Cataclysm Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 1,
      "LUK": 0,
      "HP": 28,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1363,
    "tierItemIds": [
      1362,
      1363,
      1364
    ],
    "setId": 39,
    "animType": null
  },
  "1363": {
    "id": 1363,
    "displayName": "Mighty Draconic Cataclysm Pants",
    "name": "Draconic Cataclysm Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 4,
      "INT": 2,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1364,
    "tierItemIds": [
      1362,
      1363,
      1364
    ],
    "setId": 39,
    "animType": null
  },
  "1364": {
    "id": 1364,
    "displayName": "Superior Draconic Cataclysm Pants",
    "name": "Draconic Cataclysm Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 6,
      "INT": 3,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1362,
      1363,
      1364
    ],
    "setId": 39,
    "animType": null
  },
  "1365": {
    "id": 1365,
    "displayName": "Aegis Sword",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 42,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1366,
    "tierItemIds": [
      1365,
      1366,
      1367
    ],
    "setId": 40,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1366": {
    "id": 1366,
    "displayName": "Mighty Aegis Sword",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 59,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1367,
    "tierItemIds": [
      1365,
      1366,
      1367
    ],
    "setId": 40,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1367": {
    "id": 1367,
    "displayName": "Superior Aegis Sword",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 83,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1365,
      1366,
      1367
    ],
    "setId": 40,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1368": {
    "id": 1368,
    "displayName": "Aegis Sword",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 59,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1369,
    "tierItemIds": [
      1368,
      1369,
      1370
    ],
    "setId": 40,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1369": {
    "id": 1369,
    "displayName": "Mighty Aegis Sword",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 83,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1370,
    "tierItemIds": [
      1368,
      1369,
      1370
    ],
    "setId": 40,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1370": {
    "id": 1370,
    "displayName": "Superior Aegis Sword",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 117,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1368,
      1369,
      1370
    ],
    "setId": 40,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1371": {
    "id": 1371,
    "displayName": "Aegis Sword",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 83,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1372,
    "tierItemIds": [
      1371,
      1372,
      1373
    ],
    "setId": 40,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1372": {
    "id": 1372,
    "displayName": "Mighty Aegis Sword",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 117,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1373,
    "tierItemIds": [
      1371,
      1372,
      1373
    ],
    "setId": 40,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1373": {
    "id": 1373,
    "displayName": "Superior Aegis Sword",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 165,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1371,
      1372,
      1373
    ],
    "setId": 40,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1374": {
    "id": 1374,
    "displayName": "Aegis Shield",
    "name": "Aegis Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1375,
    "tierItemIds": [
      1374,
      1375,
      1376
    ],
    "setId": 40,
    "animType": null,
    "damageType": "Physical"
  },
  "1375": {
    "id": 1375,
    "displayName": "Mighty Aegis Shield",
    "name": "Aegis Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1376,
    "tierItemIds": [
      1374,
      1375,
      1376
    ],
    "setId": 40,
    "animType": null,
    "damageType": "Physical"
  },
  "1376": {
    "id": 1376,
    "displayName": "Superior Aegis Shield",
    "name": "Aegis Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 99,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1374,
      1375,
      1376
    ],
    "setId": 40,
    "animType": null,
    "damageType": "Physical"
  },
  "1377": {
    "id": 1377,
    "displayName": "Aegis Shield",
    "name": "Aegis Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1378,
    "tierItemIds": [
      1377,
      1378,
      1379
    ],
    "setId": 40,
    "animType": null,
    "damageType": "Physical"
  },
  "1378": {
    "id": 1378,
    "displayName": "Mighty Aegis Shield",
    "name": "Aegis Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 110,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1379,
    "tierItemIds": [
      1377,
      1378,
      1379
    ],
    "setId": 40,
    "animType": null,
    "damageType": "Physical"
  },
  "1379": {
    "id": 1379,
    "displayName": "Superior Aegis Shield",
    "name": "Aegis Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 17,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 154,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1377,
      1378,
      1379
    ],
    "setId": 40,
    "animType": null,
    "damageType": "Physical"
  },
  "1380": {
    "id": 1380,
    "displayName": "Aegis Shield",
    "name": "Aegis Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 110,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1381,
    "tierItemIds": [
      1380,
      1381,
      1382
    ],
    "setId": 40,
    "animType": null,
    "damageType": "Physical"
  },
  "1381": {
    "id": 1381,
    "displayName": "Mighty Aegis Shield",
    "name": "Aegis Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 17,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 154,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1382,
    "tierItemIds": [
      1380,
      1381,
      1382
    ],
    "setId": 40,
    "animType": null,
    "damageType": "Physical"
  },
  "1382": {
    "id": 1382,
    "displayName": "Superior Aegis Shield",
    "name": "Aegis Sub Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 24,
      "Physical Defense": 23,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 216,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1380,
      1381,
      1382
    ],
    "setId": 40,
    "animType": null,
    "damageType": "Physical"
  },
  "1383": {
    "id": 1383,
    "displayName": "Aegis Staff",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 42,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1384,
    "tierItemIds": [
      1383,
      1384,
      1385
    ],
    "setId": 40,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1384": {
    "id": 1384,
    "displayName": "Mighty Aegis Staff",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 59,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1385,
    "tierItemIds": [
      1383,
      1384,
      1385
    ],
    "setId": 40,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1385": {
    "id": 1385,
    "displayName": "Superior Aegis Staff",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 83,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1383,
      1384,
      1385
    ],
    "setId": 40,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1386": {
    "id": 1386,
    "displayName": "Aegis Staff",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 59,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1387,
    "tierItemIds": [
      1386,
      1387,
      1388
    ],
    "setId": 40,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1387": {
    "id": 1387,
    "displayName": "Mighty Aegis Staff",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 83,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1388,
    "tierItemIds": [
      1386,
      1387,
      1388
    ],
    "setId": 40,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1388": {
    "id": 1388,
    "displayName": "Superior Aegis Staff",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 117,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1386,
      1387,
      1388
    ],
    "setId": 40,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1389": {
    "id": 1389,
    "displayName": "Aegis Staff",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 83,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1390,
    "tierItemIds": [
      1389,
      1390,
      1391
    ],
    "setId": 40,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1390": {
    "id": 1390,
    "displayName": "Mighty Aegis Staff",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 117,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1391,
    "tierItemIds": [
      1389,
      1390,
      1391
    ],
    "setId": 40,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1391": {
    "id": 1391,
    "displayName": "Superior Aegis Staff",
    "name": "Aegis Weapon",
    "races": [
      "Angel"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 165,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1389,
      1390,
      1391
    ],
    "setId": 40,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1392": {
    "id": 1392,
    "displayName": "Aegis Gloves",
    "name": "Aegis Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1393,
    "tierItemIds": [
      1392,
      1393,
      1394
    ],
    "setId": 40,
    "animType": null
  },
  "1393": {
    "id": 1393,
    "displayName": "Mighty Aegis Gloves",
    "name": "Aegis Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 42,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1394,
    "tierItemIds": [
      1392,
      1393,
      1394
    ],
    "setId": 40,
    "animType": null
  },
  "1394": {
    "id": 1394,
    "displayName": "Superior Aegis Gloves",
    "name": "Aegis Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1392,
      1393,
      1394
    ],
    "setId": 40,
    "animType": null
  },
  "1395": {
    "id": 1395,
    "displayName": "Aegis Gloves",
    "name": "Aegis Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 42,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1396,
    "tierItemIds": [
      1395,
      1396,
      1397
    ],
    "setId": 40,
    "animType": null
  },
  "1396": {
    "id": 1396,
    "displayName": "Mighty Aegis Gloves",
    "name": "Aegis Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1397,
    "tierItemIds": [
      1395,
      1396,
      1397
    ],
    "setId": 40,
    "animType": null
  },
  "1397": {
    "id": 1397,
    "displayName": "Superior Aegis Gloves",
    "name": "Aegis Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 12,
      "LUK": 0,
      "HP": 85,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1395,
      1396,
      1397
    ],
    "setId": 40,
    "animType": null
  },
  "1398": {
    "id": 1398,
    "displayName": "Aegis Gloves",
    "name": "Aegis Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1399,
    "tierItemIds": [
      1398,
      1399,
      1400
    ],
    "setId": 40,
    "animType": null
  },
  "1399": {
    "id": 1399,
    "displayName": "Mighty Aegis Gloves",
    "name": "Aegis Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 12,
      "LUK": 0,
      "HP": 85,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1400,
    "tierItemIds": [
      1398,
      1399,
      1400
    ],
    "setId": 40,
    "animType": null
  },
  "1400": {
    "id": 1400,
    "displayName": "Superior Aegis Gloves",
    "name": "Aegis Gloves",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 18,
      "Critical Damage": 0,
      "STR": 14,
      "AGI": 0,
      "INT": 18,
      "LUK": 1,
      "HP": 119,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1398,
      1399,
      1400
    ],
    "setId": 40,
    "animType": null
  },
  "1401": {
    "id": 1401,
    "displayName": "Aegis Helmet",
    "name": "Aegis Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 37,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1402,
    "tierItemIds": [
      1401,
      1402,
      1403
    ],
    "setId": 40,
    "animType": null
  },
  "1402": {
    "id": 1402,
    "displayName": "Mighty Aegis Helmet",
    "name": "Aegis Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 52,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1403,
    "tierItemIds": [
      1401,
      1402,
      1403
    ],
    "setId": 40,
    "animType": null
  },
  "1403": {
    "id": 1403,
    "displayName": "Superior Aegis Helmet",
    "name": "Aegis Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 8,
      "Critical Damage": 1,
      "STR": 8,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 74,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1401,
      1402,
      1403
    ],
    "setId": 40,
    "animType": null
  },
  "1404": {
    "id": 1404,
    "displayName": "Aegis Helmet",
    "name": "Aegis Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 52,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1405,
    "tierItemIds": [
      1404,
      1405,
      1406
    ],
    "setId": 40,
    "animType": null
  },
  "1405": {
    "id": 1405,
    "displayName": "Mighty Aegis Helmet",
    "name": "Aegis Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 8,
      "Critical Damage": 1,
      "STR": 8,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 74,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1406,
    "tierItemIds": [
      1404,
      1405,
      1406
    ],
    "setId": 40,
    "animType": null
  },
  "1406": {
    "id": 1406,
    "displayName": "Superior Aegis Helmet",
    "name": "Aegis Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 12,
      "Critical Damage": 2,
      "STR": 12,
      "AGI": 0,
      "INT": 15,
      "LUK": 1,
      "HP": 104,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1404,
      1405,
      1406
    ],
    "setId": 40,
    "animType": null
  },
  "1407": {
    "id": 1407,
    "displayName": "Aegis Helmet",
    "name": "Aegis Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 8,
      "Critical Damage": 1,
      "STR": 8,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 74,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1408,
    "tierItemIds": [
      1407,
      1408,
      1409
    ],
    "setId": 40,
    "animType": null
  },
  "1408": {
    "id": 1408,
    "displayName": "Mighty Aegis Helmet",
    "name": "Aegis Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 12,
      "Critical Damage": 2,
      "STR": 12,
      "AGI": 0,
      "INT": 15,
      "LUK": 1,
      "HP": 104,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1409,
    "tierItemIds": [
      1407,
      1408,
      1409
    ],
    "setId": 40,
    "animType": null
  },
  "1409": {
    "id": 1409,
    "displayName": "Superior Aegis Helmet",
    "name": "Aegis Helmet",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 21,
      "Physical Defense": 18,
      "Critical Damage": 4,
      "STR": 18,
      "AGI": 0,
      "INT": 22,
      "LUK": 2,
      "HP": 146,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1407,
      1408,
      1409
    ],
    "setId": 40,
    "animType": null
  },
  "1410": {
    "id": 1410,
    "displayName": "Aegis Armor",
    "name": "Aegis Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 38,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1411,
    "tierItemIds": [
      1410,
      1411,
      1412
    ],
    "setId": 40,
    "animType": null
  },
  "1411": {
    "id": 1411,
    "displayName": "Mighty Aegis Armor",
    "name": "Aegis Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 54,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1412,
    "tierItemIds": [
      1410,
      1411,
      1412
    ],
    "setId": 40,
    "animType": null
  },
  "1412": {
    "id": 1412,
    "displayName": "Superior Aegis Armor",
    "name": "Aegis Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 14,
      "LUK": 0,
      "HP": 76,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1410,
      1411,
      1412
    ],
    "setId": 40,
    "animType": null
  },
  "1413": {
    "id": 1413,
    "displayName": "Aegis Armor",
    "name": "Aegis Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 60,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1414,
    "tierItemIds": [
      1413,
      1414,
      1415
    ],
    "setId": 40,
    "animType": null
  },
  "1414": {
    "id": 1414,
    "displayName": "Mighty Aegis Armor",
    "name": "Aegis Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 14,
      "LUK": 0,
      "HP": 85,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1415,
    "tierItemIds": [
      1413,
      1414,
      1415
    ],
    "setId": 40,
    "animType": null
  },
  "1415": {
    "id": 1415,
    "displayName": "Superior Aegis Armor",
    "name": "Aegis Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 14,
      "AGI": 0,
      "INT": 20,
      "LUK": 0,
      "HP": 119,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1413,
      1414,
      1415
    ],
    "setId": 40,
    "animType": null
  },
  "1416": {
    "id": 1416,
    "displayName": "Aegis Armor",
    "name": "Aegis Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 14,
      "LUK": 0,
      "HP": 85,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1417,
    "tierItemIds": [
      1416,
      1417,
      1418
    ],
    "setId": 40,
    "animType": null
  },
  "1417": {
    "id": 1417,
    "displayName": "Mighty Aegis Armor",
    "name": "Aegis Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 14,
      "AGI": 0,
      "INT": 20,
      "LUK": 0,
      "HP": 119,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1418,
    "tierItemIds": [
      1416,
      1417,
      1418
    ],
    "setId": 40,
    "animType": null
  },
  "1418": {
    "id": 1418,
    "displayName": "Superior Aegis Armor",
    "name": "Aegis Armor",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 18,
      "Physical Defense": 20,
      "Critical Damage": 0,
      "STR": 20,
      "AGI": 0,
      "INT": 28,
      "LUK": 0,
      "HP": 167,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1416,
      1417,
      1418
    ],
    "setId": 40,
    "animType": null
  },
  "1419": {
    "id": 1419,
    "displayName": "Aegis Boots",
    "name": "Aegis Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 10,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1420,
    "tierItemIds": [
      1419,
      1420,
      1421
    ],
    "setId": 40,
    "animType": null
  },
  "1420": {
    "id": 1420,
    "displayName": "Mighty Aegis Boots",
    "name": "Aegis Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1421,
    "tierItemIds": [
      1419,
      1420,
      1421
    ],
    "setId": 40,
    "animType": null
  },
  "1421": {
    "id": 1421,
    "displayName": "Superior Aegis Boots",
    "name": "Aegis Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1419,
      1420,
      1421
    ],
    "setId": 40,
    "animType": null
  },
  "1422": {
    "id": 1422,
    "displayName": "Aegis Boots",
    "name": "Aegis Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1423,
    "tierItemIds": [
      1422,
      1423,
      1424
    ],
    "setId": 40,
    "animType": null
  },
  "1423": {
    "id": 1423,
    "displayName": "Mighty Aegis Boots",
    "name": "Aegis Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 24,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1424,
    "tierItemIds": [
      1422,
      1423,
      1424
    ],
    "setId": 40,
    "animType": null
  },
  "1424": {
    "id": 1424,
    "displayName": "Superior Aegis Boots",
    "name": "Aegis Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 34,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1422,
      1423,
      1424
    ],
    "setId": 40,
    "animType": null
  },
  "1425": {
    "id": 1425,
    "displayName": "Aegis Boots",
    "name": "Aegis Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 24,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1426,
    "tierItemIds": [
      1425,
      1426,
      1427
    ],
    "setId": 40,
    "animType": null
  },
  "1426": {
    "id": 1426,
    "displayName": "Mighty Aegis Boots",
    "name": "Aegis Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 34,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1427,
    "tierItemIds": [
      1425,
      1426,
      1427
    ],
    "setId": 40,
    "animType": null
  },
  "1427": {
    "id": 1427,
    "displayName": "Superior Aegis Boots",
    "name": "Aegis Boots",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 6,
      "AGI": 12,
      "INT": 0,
      "LUK": 0,
      "HP": 48,
      "Critical": 0,
      "Evade": 2,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1425,
      1426,
      1427
    ],
    "setId": 40,
    "animType": null
  },
  "1428": {
    "id": 1428,
    "displayName": "Aegis Pants",
    "name": "Aegis Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 10,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1429,
    "tierItemIds": [
      1428,
      1429,
      1430
    ],
    "setId": 40,
    "animType": null
  },
  "1429": {
    "id": 1429,
    "displayName": "Mighty Aegis Pants",
    "name": "Aegis Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1430,
    "tierItemIds": [
      1428,
      1429,
      1430
    ],
    "setId": 40,
    "animType": null
  },
  "1430": {
    "id": 1430,
    "displayName": "Superior Aegis Pants",
    "name": "Aegis Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1428,
      1429,
      1430
    ],
    "setId": 40,
    "animType": null
  },
  "1431": {
    "id": 1431,
    "displayName": "Aegis Pants",
    "name": "Aegis Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1432,
    "tierItemIds": [
      1431,
      1432,
      1433
    ],
    "setId": 40,
    "animType": null
  },
  "1432": {
    "id": 1432,
    "displayName": "Mighty Aegis Pants",
    "name": "Aegis Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1433,
    "tierItemIds": [
      1431,
      1432,
      1433
    ],
    "setId": 40,
    "animType": null
  },
  "1433": {
    "id": 1433,
    "displayName": "Superior Aegis Pants",
    "name": "Aegis Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1431,
      1432,
      1433
    ],
    "setId": 40,
    "animType": null
  },
  "1434": {
    "id": 1434,
    "displayName": "Aegis Pants",
    "name": "Aegis Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1435,
    "tierItemIds": [
      1434,
      1435,
      1436
    ],
    "setId": 40,
    "animType": null
  },
  "1435": {
    "id": 1435,
    "displayName": "Mighty Aegis Pants",
    "name": "Aegis Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1436,
    "tierItemIds": [
      1434,
      1435,
      1436
    ],
    "setId": 40,
    "animType": null
  },
  "1436": {
    "id": 1436,
    "displayName": "Superior Aegis Pants",
    "name": "Aegis Pants",
    "races": [
      "Angel"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 9,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1434,
      1435,
      1436
    ],
    "setId": 40,
    "animType": null
  },
  "1437": {
    "id": 1437,
    "displayName": "Razorwing Staff",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 41,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1438,
    "tierItemIds": [
      1437,
      1438,
      1439
    ],
    "setId": 41,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1438": {
    "id": 1438,
    "displayName": "Mighty Razorwing Staff",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 58,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1439,
    "tierItemIds": [
      1437,
      1438,
      1439
    ],
    "setId": 41,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1439": {
    "id": 1439,
    "displayName": "Superior Razorwing Staff",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 82,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1437,
      1438,
      1439
    ],
    "setId": 41,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1440": {
    "id": 1440,
    "displayName": "Razorwing Staff",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 58,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1441,
    "tierItemIds": [
      1440,
      1441,
      1442
    ],
    "setId": 41,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1441": {
    "id": 1441,
    "displayName": "Mighty Razorwing Staff",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 82,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1442,
    "tierItemIds": [
      1440,
      1441,
      1442
    ],
    "setId": 41,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1442": {
    "id": 1442,
    "displayName": "Superior Razorwing Staff",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 116,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1440,
      1441,
      1442
    ],
    "setId": 41,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1443": {
    "id": 1443,
    "displayName": "Razorwing Staff",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 82,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1444,
    "tierItemIds": [
      1443,
      1444,
      1445
    ],
    "setId": 41,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1444": {
    "id": 1444,
    "displayName": "Mighty Razorwing Staff",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 116,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1445,
    "tierItemIds": [
      1443,
      1444,
      1445
    ],
    "setId": 41,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1445": {
    "id": 1445,
    "displayName": "Superior Razorwing Staff",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 163,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 16,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1443,
      1444,
      1445
    ],
    "setId": 41,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1446": {
    "id": 1446,
    "displayName": "Razorwing Book",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 11,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1447,
    "tierItemIds": [
      1446,
      1447,
      1448
    ],
    "setId": 41,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1447": {
    "id": 1447,
    "displayName": "Mighty Razorwing Book",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 16,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1448,
    "tierItemIds": [
      1446,
      1447,
      1448
    ],
    "setId": 41,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1448": {
    "id": 1448,
    "displayName": "Superior Razorwing Book",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 23,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 0,
      "INT": 12,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1446,
      1447,
      1448
    ],
    "setId": 41,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1449": {
    "id": 1449,
    "displayName": "Razorwing Book",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 16,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1450,
    "tierItemIds": [
      1449,
      1450,
      1451
    ],
    "setId": 41,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1450": {
    "id": 1450,
    "displayName": "Mighty Razorwing Book",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 23,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 0,
      "INT": 12,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1451,
    "tierItemIds": [
      1449,
      1450,
      1451
    ],
    "setId": 41,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1451": {
    "id": 1451,
    "displayName": "Superior Razorwing Book",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 33,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 0,
      "AGI": 0,
      "INT": 17,
      "LUK": 5,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1449,
      1450,
      1451
    ],
    "setId": 41,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1452": {
    "id": 1452,
    "displayName": "Razorwing Book",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 23,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 0,
      "INT": 12,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1453,
    "tierItemIds": [
      1452,
      1453,
      1454
    ],
    "setId": 41,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1453": {
    "id": 1453,
    "displayName": "Mighty Razorwing Book",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 33,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 0,
      "AGI": 0,
      "INT": 17,
      "LUK": 5,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1454,
    "tierItemIds": [
      1452,
      1453,
      1454
    ],
    "setId": 41,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1454": {
    "id": 1454,
    "displayName": "Superior Razorwing Book",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 47,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 21,
      "STR": 0,
      "AGI": 0,
      "INT": 24,
      "LUK": 7,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1452,
      1453,
      1454
    ],
    "setId": 41,
    "animType": "book",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1455": {
    "id": 1455,
    "displayName": "Razorwing Blade",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 11,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1456,
    "tierItemIds": [
      1455,
      1456,
      1457
    ],
    "setId": 41,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1456": {
    "id": 1456,
    "displayName": "Mighty Razorwing Blade",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 16,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 10,
      "INT": 0,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1457,
    "tierItemIds": [
      1455,
      1456,
      1457
    ],
    "setId": 41,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1457": {
    "id": 1457,
    "displayName": "Superior Razorwing Blade",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 23,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 15,
      "INT": 0,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1455,
      1456,
      1457
    ],
    "setId": 41,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1458": {
    "id": 1458,
    "displayName": "Razorwing Blade",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 16,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 10,
      "INT": 0,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1459,
    "tierItemIds": [
      1458,
      1459,
      1460
    ],
    "setId": 41,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1459": {
    "id": 1459,
    "displayName": "Mighty Razorwing Blade",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 23,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 15,
      "INT": 0,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1460,
    "tierItemIds": [
      1458,
      1459,
      1460
    ],
    "setId": 41,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1460": {
    "id": 1460,
    "displayName": "Superior Razorwing Blade",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 33,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 0,
      "AGI": 22,
      "INT": 0,
      "LUK": 5,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1458,
      1459,
      1460
    ],
    "setId": 41,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1461": {
    "id": 1461,
    "displayName": "Razorwing Blade",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 23,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 10,
      "STR": 0,
      "AGI": 15,
      "INT": 0,
      "LUK": 3,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1462,
    "tierItemIds": [
      1461,
      1462,
      1463
    ],
    "setId": 41,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1462": {
    "id": 1462,
    "displayName": "Mighty Razorwing Blade",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 33,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 15,
      "STR": 0,
      "AGI": 22,
      "INT": 0,
      "LUK": 5,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1463,
    "tierItemIds": [
      1461,
      1462,
      1463
    ],
    "setId": 41,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1463": {
    "id": 1463,
    "displayName": "Superior Razorwing Blade",
    "name": "Razorwing Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 47,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 21,
      "STR": 0,
      "AGI": 32,
      "INT": 0,
      "LUK": 7,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1461,
      1462,
      1463
    ],
    "setId": 41,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1464": {
    "id": 1464,
    "displayName": "Razorwing Sword",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 41,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1465,
    "tierItemIds": [
      1464,
      1465,
      1466
    ],
    "setId": 41,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1465": {
    "id": 1465,
    "displayName": "Mighty Razorwing Sword",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 58,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1466,
    "tierItemIds": [
      1464,
      1465,
      1466
    ],
    "setId": 41,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1466": {
    "id": 1466,
    "displayName": "Superior Razorwing Sword",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 82,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1464,
      1465,
      1466
    ],
    "setId": 41,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1467": {
    "id": 1467,
    "displayName": "Razorwing Sword",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 58,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1468,
    "tierItemIds": [
      1467,
      1468,
      1469
    ],
    "setId": 41,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1468": {
    "id": 1468,
    "displayName": "Mighty Razorwing Sword",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 82,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1469,
    "tierItemIds": [
      1467,
      1468,
      1469
    ],
    "setId": 41,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1469": {
    "id": 1469,
    "displayName": "Superior Razorwing Sword",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 116,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1467,
      1468,
      1469
    ],
    "setId": 41,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1470": {
    "id": 1470,
    "displayName": "Razorwing Sword",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 82,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1471,
    "tierItemIds": [
      1470,
      1471,
      1472
    ],
    "setId": 41,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1471": {
    "id": 1471,
    "displayName": "Mighty Razorwing Sword",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 116,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1472,
    "tierItemIds": [
      1470,
      1471,
      1472
    ],
    "setId": 41,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1472": {
    "id": 1472,
    "displayName": "Superior Razorwing Sword",
    "name": "Razorwing Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 163,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 16,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1470,
      1471,
      1472
    ],
    "setId": 41,
    "animType": "sword",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1473": {
    "id": 1473,
    "displayName": "Razorwing Gloves",
    "name": "Razorwing Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1474,
    "tierItemIds": [
      1473,
      1474,
      1475
    ],
    "setId": 41,
    "animType": null
  },
  "1474": {
    "id": 1474,
    "displayName": "Mighty Razorwing Gloves",
    "name": "Razorwing Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1475,
    "tierItemIds": [
      1473,
      1474,
      1475
    ],
    "setId": 41,
    "animType": null
  },
  "1475": {
    "id": 1475,
    "displayName": "Superior Razorwing Gloves",
    "name": "Razorwing Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 6,
      "LUK": 1,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1473,
      1474,
      1475
    ],
    "setId": 41,
    "animType": null
  },
  "1476": {
    "id": 1476,
    "displayName": "Razorwing Gloves",
    "name": "Razorwing Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 4,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1477,
    "tierItemIds": [
      1476,
      1477,
      1478
    ],
    "setId": 41,
    "animType": null
  },
  "1477": {
    "id": 1477,
    "displayName": "Mighty Razorwing Gloves",
    "name": "Razorwing Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 6,
      "LUK": 1,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1478,
    "tierItemIds": [
      1476,
      1477,
      1478
    ],
    "setId": 41,
    "animType": null
  },
  "1478": {
    "id": 1478,
    "displayName": "Superior Razorwing Gloves",
    "name": "Razorwing Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 9,
      "LUK": 2,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1476,
      1477,
      1478
    ],
    "setId": 41,
    "animType": null
  },
  "1479": {
    "id": 1479,
    "displayName": "Razorwing Gloves",
    "name": "Razorwing Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 6,
      "LUK": 1,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1480,
    "tierItemIds": [
      1479,
      1480,
      1481
    ],
    "setId": 41,
    "animType": null
  },
  "1480": {
    "id": 1480,
    "displayName": "Mighty Razorwing Gloves",
    "name": "Razorwing Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 9,
      "LUK": 2,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1481,
    "tierItemIds": [
      1479,
      1480,
      1481
    ],
    "setId": 41,
    "animType": null
  },
  "1481": {
    "id": 1481,
    "displayName": "Superior Razorwing Gloves",
    "name": "Razorwing Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 4,
      "AGI": 0,
      "INT": 13,
      "LUK": 4,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1479,
      1480,
      1481
    ],
    "setId": 41,
    "animType": null
  },
  "1482": {
    "id": 1482,
    "displayName": "Razorwing Helmet",
    "name": "Razorwing Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1483,
    "tierItemIds": [
      1482,
      1483,
      1484
    ],
    "setId": 41,
    "animType": null
  },
  "1483": {
    "id": 1483,
    "displayName": "Mighty Razorwing Helmet",
    "name": "Razorwing Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1484,
    "tierItemIds": [
      1482,
      1483,
      1484
    ],
    "setId": 41,
    "animType": null
  },
  "1484": {
    "id": 1484,
    "displayName": "Superior Razorwing Helmet",
    "name": "Razorwing Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 7,
      "Critical Damage": 7,
      "STR": 1,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1482,
      1483,
      1484
    ],
    "setId": 41,
    "animType": null
  },
  "1485": {
    "id": 1485,
    "displayName": "Razorwing Helmet",
    "name": "Razorwing Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 63,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1486,
    "tierItemIds": [
      1485,
      1486,
      1487
    ],
    "setId": 41,
    "animType": null
  },
  "1486": {
    "id": 1486,
    "displayName": "Mighty Razorwing Helmet",
    "name": "Razorwing Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 7,
      "Critical Damage": 7,
      "STR": 1,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1487,
    "tierItemIds": [
      1485,
      1486,
      1487
    ],
    "setId": 41,
    "animType": null
  },
  "1487": {
    "id": 1487,
    "displayName": "Superior Razorwing Helmet",
    "name": "Razorwing Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 11,
      "Critical Damage": 10,
      "STR": 2,
      "AGI": 0,
      "INT": 12,
      "LUK": 4,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1485,
      1486,
      1487
    ],
    "setId": 41,
    "animType": null
  },
  "1488": {
    "id": 1488,
    "displayName": "Razorwing Helmet",
    "name": "Razorwing Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 7,
      "Critical Damage": 7,
      "STR": 1,
      "AGI": 0,
      "INT": 8,
      "LUK": 2,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1489,
    "tierItemIds": [
      1488,
      1489,
      1490
    ],
    "setId": 41,
    "animType": null
  },
  "1489": {
    "id": 1489,
    "displayName": "Mighty Razorwing Helmet",
    "name": "Razorwing Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 11,
      "Critical Damage": 10,
      "STR": 2,
      "AGI": 0,
      "INT": 12,
      "LUK": 4,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1490,
    "tierItemIds": [
      1488,
      1489,
      1490
    ],
    "setId": 41,
    "animType": null
  },
  "1490": {
    "id": 1490,
    "displayName": "Superior Razorwing Helmet",
    "name": "Razorwing Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 16,
      "Critical Damage": 15,
      "STR": 4,
      "AGI": 0,
      "INT": 17,
      "LUK": 6,
      "HP": 176,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1488,
      1489,
      1490
    ],
    "setId": 41,
    "animType": null
  },
  "1491": {
    "id": 1491,
    "displayName": "Razorwing Armor",
    "name": "Razorwing Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 40,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1492,
    "tierItemIds": [
      1491,
      1492,
      1493
    ],
    "setId": 41,
    "animType": null
  },
  "1492": {
    "id": 1492,
    "displayName": "Mighty Razorwing Armor",
    "name": "Razorwing Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1493,
    "tierItemIds": [
      1491,
      1492,
      1493
    ],
    "setId": 41,
    "animType": null
  },
  "1493": {
    "id": 1493,
    "displayName": "Superior Razorwing Armor",
    "name": "Razorwing Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1491,
      1492,
      1493
    ],
    "setId": 41,
    "animType": null
  },
  "1494": {
    "id": 1494,
    "displayName": "Razorwing Armor",
    "name": "Razorwing Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 63,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1495,
    "tierItemIds": [
      1494,
      1495,
      1496
    ],
    "setId": 41,
    "animType": null
  },
  "1495": {
    "id": 1495,
    "displayName": "Mighty Razorwing Armor",
    "name": "Razorwing Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1496,
    "tierItemIds": [
      1494,
      1495,
      1496
    ],
    "setId": 41,
    "animType": null
  },
  "1496": {
    "id": 1496,
    "displayName": "Superior Razorwing Armor",
    "name": "Razorwing Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1494,
      1495,
      1496
    ],
    "setId": 41,
    "animType": null
  },
  "1497": {
    "id": 1497,
    "displayName": "Razorwing Armor",
    "name": "Razorwing Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1498,
    "tierItemIds": [
      1497,
      1498,
      1499
    ],
    "setId": 41,
    "animType": null
  },
  "1498": {
    "id": 1498,
    "displayName": "Mighty Razorwing Armor",
    "name": "Razorwing Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1499,
    "tierItemIds": [
      1497,
      1498,
      1499
    ],
    "setId": 41,
    "animType": null
  },
  "1499": {
    "id": 1499,
    "displayName": "Superior Razorwing Armor",
    "name": "Razorwing Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 20,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 21,
      "LUK": 0,
      "HP": 176,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1497,
      1498,
      1499
    ],
    "setId": 41,
    "animType": null
  },
  "1500": {
    "id": 1500,
    "displayName": "Razorwing Boots",
    "name": "Razorwing Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1501,
    "tierItemIds": [
      1500,
      1501,
      1502
    ],
    "setId": 41,
    "animType": null
  },
  "1501": {
    "id": 1501,
    "displayName": "Mighty Razorwing Boots",
    "name": "Razorwing Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1502,
    "tierItemIds": [
      1500,
      1501,
      1502
    ],
    "setId": 41,
    "animType": null
  },
  "1502": {
    "id": 1502,
    "displayName": "Superior Razorwing Boots",
    "name": "Razorwing Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1500,
      1501,
      1502
    ],
    "setId": 41,
    "animType": null
  },
  "1503": {
    "id": 1503,
    "displayName": "Razorwing Boots",
    "name": "Razorwing Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1504,
    "tierItemIds": [
      1503,
      1504,
      1505
    ],
    "setId": 41,
    "animType": null
  },
  "1504": {
    "id": 1504,
    "displayName": "Mighty Razorwing Boots",
    "name": "Razorwing Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1505,
    "tierItemIds": [
      1503,
      1504,
      1505
    ],
    "setId": 41,
    "animType": null
  },
  "1505": {
    "id": 1505,
    "displayName": "Superior Razorwing Boots",
    "name": "Razorwing Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1503,
      1504,
      1505
    ],
    "setId": 41,
    "animType": null
  },
  "1506": {
    "id": 1506,
    "displayName": "Razorwing Boots",
    "name": "Razorwing Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1507,
    "tierItemIds": [
      1506,
      1507,
      1508
    ],
    "setId": 41,
    "animType": null
  },
  "1507": {
    "id": 1507,
    "displayName": "Mighty Razorwing Boots",
    "name": "Razorwing Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1508,
    "tierItemIds": [
      1506,
      1507,
      1508
    ],
    "setId": 41,
    "animType": null
  },
  "1508": {
    "id": 1508,
    "displayName": "Superior Razorwing Boots",
    "name": "Razorwing Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 14,
      "INT": 0,
      "LUK": 0,
      "HP": 88,
      "Critical": 0,
      "Evade": 4,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1506,
      1507,
      1508
    ],
    "setId": 41,
    "animType": null
  },
  "1509": {
    "id": 1509,
    "displayName": "Razorwing Pants",
    "name": "Razorwing Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1510,
    "tierItemIds": [
      1509,
      1510,
      1511
    ],
    "setId": 41,
    "animType": null
  },
  "1510": {
    "id": 1510,
    "displayName": "Mighty Razorwing Pants",
    "name": "Razorwing Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1511,
    "tierItemIds": [
      1509,
      1510,
      1511
    ],
    "setId": 41,
    "animType": null
  },
  "1511": {
    "id": 1511,
    "displayName": "Superior Razorwing Pants",
    "name": "Razorwing Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1509,
      1510,
      1511
    ],
    "setId": 41,
    "animType": null
  },
  "1512": {
    "id": 1512,
    "displayName": "Razorwing Pants",
    "name": "Razorwing Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1513,
    "tierItemIds": [
      1512,
      1513,
      1514
    ],
    "setId": 41,
    "animType": null
  },
  "1513": {
    "id": 1513,
    "displayName": "Mighty Razorwing Pants",
    "name": "Razorwing Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1514,
    "tierItemIds": [
      1512,
      1513,
      1514
    ],
    "setId": 41,
    "animType": null
  },
  "1514": {
    "id": 1514,
    "displayName": "Superior Razorwing Pants",
    "name": "Razorwing Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 9,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 3,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1512,
      1513,
      1514
    ],
    "setId": 41,
    "animType": null
  },
  "1515": {
    "id": 1515,
    "displayName": "Razorwing Pants",
    "name": "Razorwing Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 6,
      "INT": 0,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 2,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1516,
    "tierItemIds": [
      1515,
      1516,
      1517
    ],
    "setId": 41,
    "animType": null
  },
  "1516": {
    "id": 1516,
    "displayName": "Mighty Razorwing Pants",
    "name": "Razorwing Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 9,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 3,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1517,
    "tierItemIds": [
      1515,
      1516,
      1517
    ],
    "setId": 41,
    "animType": null
  },
  "1517": {
    "id": 1517,
    "displayName": "Superior Razorwing Pants",
    "name": "Razorwing Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 13,
      "INT": 0,
      "LUK": 0,
      "HP": 88,
      "Critical": 0,
      "Evade": 5,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1515,
      1516,
      1517
    ],
    "setId": 41,
    "animType": null
  },
  "1518": {
    "id": 1518,
    "displayName": "Invictus Staff",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 49,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1519,
    "tierItemIds": [
      1518,
      1519,
      1520
    ],
    "setId": 42,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1519": {
    "id": 1519,
    "displayName": "Mighty Invictus Staff",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 69,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1520,
    "tierItemIds": [
      1518,
      1519,
      1520
    ],
    "setId": 42,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1520": {
    "id": 1520,
    "displayName": "Superior Invictus Staff",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 97,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1518,
      1519,
      1520
    ],
    "setId": 42,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1521": {
    "id": 1521,
    "displayName": "Invictus Staff",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 69,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1522,
    "tierItemIds": [
      1521,
      1522,
      1523
    ],
    "setId": 42,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1522": {
    "id": 1522,
    "displayName": "Mighty Invictus Staff",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 97,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1523,
    "tierItemIds": [
      1521,
      1522,
      1523
    ],
    "setId": 42,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1523": {
    "id": 1523,
    "displayName": "Superior Invictus Staff",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 136,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1521,
      1522,
      1523
    ],
    "setId": 42,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1524": {
    "id": 1524,
    "displayName": "Invictus Staff",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 97,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1525,
    "tierItemIds": [
      1524,
      1525,
      1526
    ],
    "setId": 42,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1525": {
    "id": 1525,
    "displayName": "Mighty Invictus Staff",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 136,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1526,
    "tierItemIds": [
      1524,
      1525,
      1526
    ],
    "setId": 42,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1526": {
    "id": 1526,
    "displayName": "Superior Invictus Staff",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 191,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1524,
      1525,
      1526
    ],
    "setId": 42,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1527": {
    "id": 1527,
    "displayName": "Invictus Shield",
    "name": "Invictus Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1528,
    "tierItemIds": [
      1527,
      1528,
      1529
    ],
    "setId": 42,
    "animType": null,
    "damageType": "Physical"
  },
  "1528": {
    "id": 1528,
    "displayName": "Mighty Invictus Shield",
    "name": "Invictus Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1529,
    "tierItemIds": [
      1527,
      1528,
      1529
    ],
    "setId": 42,
    "animType": null,
    "damageType": "Physical"
  },
  "1529": {
    "id": 1529,
    "displayName": "Superior Invictus Shield",
    "name": "Invictus Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 141,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1527,
      1528,
      1529
    ],
    "setId": 42,
    "animType": null,
    "damageType": "Physical"
  },
  "1530": {
    "id": 1530,
    "displayName": "Invictus Shield",
    "name": "Invictus Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1531,
    "tierItemIds": [
      1530,
      1531,
      1532
    ],
    "setId": 42,
    "animType": null,
    "damageType": "Physical"
  },
  "1531": {
    "id": 1531,
    "displayName": "Mighty Invictus Shield",
    "name": "Invictus Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 157,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1532,
    "tierItemIds": [
      1530,
      1531,
      1532
    ],
    "setId": 42,
    "animType": null,
    "damageType": "Physical"
  },
  "1532": {
    "id": 1532,
    "displayName": "Superior Invictus Shield",
    "name": "Invictus Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 7,
      "HP": 221,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1530,
      1531,
      1532
    ],
    "setId": 42,
    "animType": null,
    "damageType": "Physical"
  },
  "1533": {
    "id": 1533,
    "displayName": "Invictus Shield",
    "name": "Invictus Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 157,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1534,
    "tierItemIds": [
      1533,
      1534,
      1535
    ],
    "setId": 42,
    "animType": null,
    "damageType": "Physical"
  },
  "1534": {
    "id": 1534,
    "displayName": "Mighty Invictus Shield",
    "name": "Invictus Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 7,
      "HP": 221,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1535,
    "tierItemIds": [
      1533,
      1534,
      1535
    ],
    "setId": 42,
    "animType": null,
    "damageType": "Physical"
  },
  "1535": {
    "id": 1535,
    "displayName": "Superior Invictus Shield",
    "name": "Invictus Sub Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 18,
      "Physical Defense": 20,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 10,
      "HP": 310,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1533,
      1534,
      1535
    ],
    "setId": 42,
    "animType": null,
    "damageType": "Physical"
  },
  "1536": {
    "id": 1536,
    "displayName": "Invictus Spear",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 37,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1537,
    "tierItemIds": [
      1536,
      1537,
      1538
    ],
    "setId": 42,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1537": {
    "id": 1537,
    "displayName": "Mighty Invictus Spear",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 53,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1538,
    "tierItemIds": [
      1536,
      1537,
      1538
    ],
    "setId": 42,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1538": {
    "id": 1538,
    "displayName": "Superior Invictus Spear",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 75,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1536,
      1537,
      1538
    ],
    "setId": 42,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1539": {
    "id": 1539,
    "displayName": "Invictus Spear",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 53,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1540,
    "tierItemIds": [
      1539,
      1540,
      1541
    ],
    "setId": 42,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1540": {
    "id": 1540,
    "displayName": "Mighty Invictus Spear",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 75,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1541,
    "tierItemIds": [
      1539,
      1540,
      1541
    ],
    "setId": 42,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1541": {
    "id": 1541,
    "displayName": "Superior Invictus Spear",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 105,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1539,
      1540,
      1541
    ],
    "setId": 42,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1542": {
    "id": 1542,
    "displayName": "Invictus Spear",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 75,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1543,
    "tierItemIds": [
      1542,
      1543,
      1544
    ],
    "setId": 42,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1543": {
    "id": 1543,
    "displayName": "Mighty Invictus Spear",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 105,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1544,
    "tierItemIds": [
      1542,
      1543,
      1544
    ],
    "setId": 42,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1544": {
    "id": 1544,
    "displayName": "Superior Invictus Spear",
    "name": "Invictus Weapon",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 147,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1542,
      1543,
      1544
    ],
    "setId": 42,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1545": {
    "id": 1545,
    "displayName": "Invictus Gloves",
    "name": "Invictus Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1546,
    "tierItemIds": [
      1545,
      1546,
      1547
    ],
    "setId": 42,
    "animType": null
  },
  "1546": {
    "id": 1546,
    "displayName": "Mighty Invictus Gloves",
    "name": "Invictus Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1547,
    "tierItemIds": [
      1545,
      1546,
      1547
    ],
    "setId": 42,
    "animType": null
  },
  "1547": {
    "id": 1547,
    "displayName": "Superior Invictus Gloves",
    "name": "Invictus Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1545,
      1546,
      1547
    ],
    "setId": 42,
    "animType": null
  },
  "1548": {
    "id": 1548,
    "displayName": "Invictus Gloves",
    "name": "Invictus Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1549,
    "tierItemIds": [
      1548,
      1549,
      1550
    ],
    "setId": 42,
    "animType": null
  },
  "1549": {
    "id": 1549,
    "displayName": "Mighty Invictus Gloves",
    "name": "Invictus Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1550,
    "tierItemIds": [
      1548,
      1549,
      1550
    ],
    "setId": 42,
    "animType": null
  },
  "1550": {
    "id": 1550,
    "displayName": "Superior Invictus Gloves",
    "name": "Invictus Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1548,
      1549,
      1550
    ],
    "setId": 42,
    "animType": null
  },
  "1551": {
    "id": 1551,
    "displayName": "Invictus Gloves",
    "name": "Invictus Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1552,
    "tierItemIds": [
      1551,
      1552,
      1553
    ],
    "setId": 42,
    "animType": null
  },
  "1552": {
    "id": 1552,
    "displayName": "Mighty Invictus Gloves",
    "name": "Invictus Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1553,
    "tierItemIds": [
      1551,
      1552,
      1553
    ],
    "setId": 42,
    "animType": null
  },
  "1553": {
    "id": 1553,
    "displayName": "Superior Invictus Gloves",
    "name": "Invictus Gloves",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 17,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1551,
      1552,
      1553
    ],
    "setId": 42,
    "animType": null
  },
  "1554": {
    "id": 1554,
    "displayName": "Invictus Helmet",
    "name": "Invictus Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1555,
    "tierItemIds": [
      1554,
      1555,
      1556
    ],
    "setId": 42,
    "animType": null
  },
  "1555": {
    "id": 1555,
    "displayName": "Mighty Invictus Helmet",
    "name": "Invictus Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1556,
    "tierItemIds": [
      1554,
      1555,
      1556
    ],
    "setId": 42,
    "animType": null
  },
  "1556": {
    "id": 1556,
    "displayName": "Superior Invictus Helmet",
    "name": "Invictus Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1554,
      1555,
      1556
    ],
    "setId": 42,
    "animType": null
  },
  "1557": {
    "id": 1557,
    "displayName": "Invictus Helmet",
    "name": "Invictus Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1558,
    "tierItemIds": [
      1557,
      1558,
      1559
    ],
    "setId": 42,
    "animType": null
  },
  "1558": {
    "id": 1558,
    "displayName": "Mighty Invictus Helmet",
    "name": "Invictus Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1559,
    "tierItemIds": [
      1557,
      1558,
      1559
    ],
    "setId": 42,
    "animType": null
  },
  "1559": {
    "id": 1559,
    "displayName": "Superior Invictus Helmet",
    "name": "Invictus Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 14,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 175,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1557,
      1558,
      1559
    ],
    "setId": 42,
    "animType": null
  },
  "1560": {
    "id": 1560,
    "displayName": "Invictus Helmet",
    "name": "Invictus Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1561,
    "tierItemIds": [
      1560,
      1561,
      1562
    ],
    "setId": 42,
    "animType": null
  },
  "1561": {
    "id": 1561,
    "displayName": "Mighty Invictus Helmet",
    "name": "Invictus Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 11,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 14,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 175,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1562,
    "tierItemIds": [
      1560,
      1561,
      1562
    ],
    "setId": 42,
    "animType": null
  },
  "1562": {
    "id": 1562,
    "displayName": "Superior Invictus Helmet",
    "name": "Invictus Helmet",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 16,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 20,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 245,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1560,
      1561,
      1562
    ],
    "setId": 42,
    "animType": null
  },
  "1563": {
    "id": 1563,
    "displayName": "Invictus Armor",
    "name": "Invictus Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1564,
    "tierItemIds": [
      1563,
      1564,
      1565
    ],
    "setId": 42,
    "animType": null
  },
  "1564": {
    "id": 1564,
    "displayName": "Mighty Invictus Armor",
    "name": "Invictus Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1565,
    "tierItemIds": [
      1563,
      1564,
      1565
    ],
    "setId": 42,
    "animType": null
  },
  "1565": {
    "id": 1565,
    "displayName": "Superior Invictus Armor",
    "name": "Invictus Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1563,
      1564,
      1565
    ],
    "setId": 42,
    "animType": null
  },
  "1566": {
    "id": 1566,
    "displayName": "Invictus Armor",
    "name": "Invictus Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 89,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1567,
    "tierItemIds": [
      1566,
      1567,
      1568
    ],
    "setId": 42,
    "animType": null
  },
  "1567": {
    "id": 1567,
    "displayName": "Mighty Invictus Armor",
    "name": "Invictus Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1568,
    "tierItemIds": [
      1566,
      1567,
      1568
    ],
    "setId": 42,
    "animType": null
  },
  "1568": {
    "id": 1568,
    "displayName": "Superior Invictus Armor",
    "name": "Invictus Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 16,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 175,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1566,
      1567,
      1568
    ],
    "setId": 42,
    "animType": null
  },
  "1569": {
    "id": 1569,
    "displayName": "Invictus Armor",
    "name": "Invictus Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 125,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1570,
    "tierItemIds": [
      1569,
      1570,
      1571
    ],
    "setId": 42,
    "animType": null
  },
  "1570": {
    "id": 1570,
    "displayName": "Mighty Invictus Armor",
    "name": "Invictus Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 16,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 175,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1571,
    "tierItemIds": [
      1569,
      1570,
      1571
    ],
    "setId": 42,
    "animType": null
  },
  "1571": {
    "id": 1571,
    "displayName": "Superior Invictus Armor",
    "name": "Invictus Armor",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 14,
      "Physical Defense": 17,
      "Critical Damage": 0,
      "STR": 23,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 245,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1569,
      1570,
      1571
    ],
    "setId": 42,
    "animType": null
  },
  "1572": {
    "id": 1572,
    "displayName": "Invictus Boots",
    "name": "Invictus Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 14,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1573,
    "tierItemIds": [
      1572,
      1573,
      1574
    ],
    "setId": 42,
    "animType": null
  },
  "1573": {
    "id": 1573,
    "displayName": "Mighty Invictus Boots",
    "name": "Invictus Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 20,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1574,
    "tierItemIds": [
      1572,
      1573,
      1574
    ],
    "setId": 42,
    "animType": null
  },
  "1574": {
    "id": 1574,
    "displayName": "Superior Invictus Boots",
    "name": "Invictus Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 29,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1572,
      1573,
      1574
    ],
    "setId": 42,
    "animType": null
  },
  "1575": {
    "id": 1575,
    "displayName": "Invictus Boots",
    "name": "Invictus Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 23,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1576,
    "tierItemIds": [
      1575,
      1576,
      1577
    ],
    "setId": 42,
    "animType": null
  },
  "1576": {
    "id": 1576,
    "displayName": "Mighty Invictus Boots",
    "name": "Invictus Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1577,
    "tierItemIds": [
      1575,
      1576,
      1577
    ],
    "setId": 42,
    "animType": null
  },
  "1577": {
    "id": 1577,
    "displayName": "Superior Invictus Boots",
    "name": "Invictus Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1575,
      1576,
      1577
    ],
    "setId": 42,
    "animType": null
  },
  "1578": {
    "id": 1578,
    "displayName": "Invictus Boots",
    "name": "Invictus Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1579,
    "tierItemIds": [
      1578,
      1579,
      1580
    ],
    "setId": 42,
    "animType": null
  },
  "1579": {
    "id": 1579,
    "displayName": "Mighty Invictus Boots",
    "name": "Invictus Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1580,
    "tierItemIds": [
      1578,
      1579,
      1580
    ],
    "setId": 42,
    "animType": null
  },
  "1580": {
    "id": 1580,
    "displayName": "Superior Invictus Boots",
    "name": "Invictus Boots",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 10,
      "INT": 0,
      "LUK": 0,
      "HP": 66,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1578,
      1579,
      1580
    ],
    "setId": 42,
    "animType": null
  },
  "1581": {
    "id": 1581,
    "displayName": "Invictus Pants",
    "name": "Invictus Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1582,
    "tierItemIds": [
      1581,
      1582,
      1583
    ],
    "setId": 42,
    "animType": null
  },
  "1582": {
    "id": 1582,
    "displayName": "Mighty Invictus Pants",
    "name": "Invictus Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1583,
    "tierItemIds": [
      1581,
      1582,
      1583
    ],
    "setId": 42,
    "animType": null
  },
  "1583": {
    "id": 1583,
    "displayName": "Superior Invictus Pants",
    "name": "Invictus Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1581,
      1582,
      1583
    ],
    "setId": 42,
    "animType": null
  },
  "1584": {
    "id": 1584,
    "displayName": "Invictus Pants",
    "name": "Invictus Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 24,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1585,
    "tierItemIds": [
      1584,
      1585,
      1586
    ],
    "setId": 42,
    "animType": null
  },
  "1585": {
    "id": 1585,
    "displayName": "Mighty Invictus Pants",
    "name": "Invictus Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 34,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1586,
    "tierItemIds": [
      1584,
      1585,
      1586
    ],
    "setId": 42,
    "animType": null
  },
  "1586": {
    "id": 1586,
    "displayName": "Superior Invictus Pants",
    "name": "Invictus Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 48,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1584,
      1585,
      1586
    ],
    "setId": 42,
    "animType": null
  },
  "1587": {
    "id": 1587,
    "displayName": "Invictus Pants",
    "name": "Invictus Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 34,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1588,
    "tierItemIds": [
      1587,
      1588,
      1589
    ],
    "setId": 42,
    "animType": null
  },
  "1588": {
    "id": 1588,
    "displayName": "Mighty Invictus Pants",
    "name": "Invictus Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 48,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1589,
    "tierItemIds": [
      1587,
      1588,
      1589
    ],
    "setId": 42,
    "animType": null
  },
  "1589": {
    "id": 1589,
    "displayName": "Superior Invictus Pants",
    "name": "Invictus Pants",
    "races": [
      "Dragonborn"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 68,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1587,
      1588,
      1589
    ],
    "setId": 42,
    "animType": null
  },
  "1590": {
    "id": 1590,
    "displayName": "Colossal Guardian Staff",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 46,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1591,
    "tierItemIds": [
      1590,
      1591,
      1592
    ],
    "setId": 43,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1591": {
    "id": 1591,
    "displayName": "Mighty Colossal Guardian Staff",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 65,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1592,
    "tierItemIds": [
      1590,
      1591,
      1592
    ],
    "setId": 43,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1592": {
    "id": 1592,
    "displayName": "Superior Colossal Guardian Staff",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 92,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1590,
      1591,
      1592
    ],
    "setId": 43,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1593": {
    "id": 1593,
    "displayName": "Colossal Guardian Staff",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 65,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1594,
    "tierItemIds": [
      1593,
      1594,
      1595
    ],
    "setId": 43,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1594": {
    "id": 1594,
    "displayName": "Mighty Colossal Guardian Staff",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 92,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1595,
    "tierItemIds": [
      1593,
      1594,
      1595
    ],
    "setId": 43,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1595": {
    "id": 1595,
    "displayName": "Superior Colossal Guardian Staff",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 130,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1593,
      1594,
      1595
    ],
    "setId": 43,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1596": {
    "id": 1596,
    "displayName": "Colossal Guardian Staff",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 92,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1597,
    "tierItemIds": [
      1596,
      1597,
      1598
    ],
    "setId": 43,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1597": {
    "id": 1597,
    "displayName": "Mighty Colossal Guardian Staff",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 130,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1598,
    "tierItemIds": [
      1596,
      1597,
      1598
    ],
    "setId": 43,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1598": {
    "id": 1598,
    "displayName": "Superior Colossal Guardian Staff",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 182,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 16,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1596,
      1597,
      1598
    ],
    "setId": 43,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1599": {
    "id": 1599,
    "displayName": "Colossal Guardian Shield",
    "name": "Colossal Guardian Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1600,
    "tierItemIds": [
      1599,
      1600,
      1601
    ],
    "setId": 43,
    "animType": null,
    "damageType": "Physical"
  },
  "1600": {
    "id": 1600,
    "displayName": "Mighty Colossal Guardian Shield",
    "name": "Colossal Guardian Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 71,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1601,
    "tierItemIds": [
      1599,
      1600,
      1601
    ],
    "setId": 43,
    "animType": null,
    "damageType": "Physical"
  },
  "1601": {
    "id": 1601,
    "displayName": "Superior Colossal Guardian Shield",
    "name": "Colossal Guardian Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 4,
      "HP": 100,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1599,
      1600,
      1601
    ],
    "setId": 43,
    "animType": null,
    "damageType": "Physical"
  },
  "1602": {
    "id": 1602,
    "displayName": "Colossal Guardian Shield",
    "name": "Colossal Guardian Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1603,
    "tierItemIds": [
      1602,
      1603,
      1604
    ],
    "setId": 43,
    "animType": null,
    "damageType": "Physical"
  },
  "1603": {
    "id": 1603,
    "displayName": "Mighty Colossal Guardian Shield",
    "name": "Colossal Guardian Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 4,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1604,
    "tierItemIds": [
      1602,
      1603,
      1604
    ],
    "setId": 43,
    "animType": null,
    "damageType": "Physical"
  },
  "1604": {
    "id": 1604,
    "displayName": "Superior Colossal Guardian Shield",
    "name": "Colossal Guardian Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 6,
      "HP": 157,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1602,
      1603,
      1604
    ],
    "setId": 43,
    "animType": null,
    "damageType": "Physical"
  },
  "1605": {
    "id": 1605,
    "displayName": "Colossal Guardian Shield",
    "name": "Colossal Guardian Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 4,
      "HP": 112,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1606,
    "tierItemIds": [
      1605,
      1606,
      1607
    ],
    "setId": 43,
    "animType": null,
    "damageType": "Physical"
  },
  "1606": {
    "id": 1606,
    "displayName": "Mighty Colossal Guardian Shield",
    "name": "Colossal Guardian Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 6,
      "HP": 157,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1607,
    "tierItemIds": [
      1605,
      1606,
      1607
    ],
    "setId": 43,
    "animType": null,
    "damageType": "Physical"
  },
  "1607": {
    "id": 1607,
    "displayName": "Superior Colossal Guardian Shield",
    "name": "Colossal Guardian Sub Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 19,
      "Physical Defense": 18,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 9,
      "HP": 220,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1605,
      1606,
      1607
    ],
    "setId": 43,
    "animType": null,
    "damageType": "Physical"
  },
  "1608": {
    "id": 1608,
    "displayName": "Colossal Guardian Hammer",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 35,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1609,
    "tierItemIds": [
      1608,
      1609,
      1610
    ],
    "setId": 43,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1609": {
    "id": 1609,
    "displayName": "Mighty Colossal Guardian Hammer",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 50,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1610,
    "tierItemIds": [
      1608,
      1609,
      1610
    ],
    "setId": 43,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1610": {
    "id": 1610,
    "displayName": "Superior Colossal Guardian Hammer",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 71,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1608,
      1609,
      1610
    ],
    "setId": 43,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1611": {
    "id": 1611,
    "displayName": "Colossal Guardian Hammer",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 50,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1612,
    "tierItemIds": [
      1611,
      1612,
      1613
    ],
    "setId": 43,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1612": {
    "id": 1612,
    "displayName": "Mighty Colossal Guardian Hammer",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 71,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1613,
    "tierItemIds": [
      1611,
      1612,
      1613
    ],
    "setId": 43,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1613": {
    "id": 1613,
    "displayName": "Superior Colossal Guardian Hammer",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 100,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1611,
      1612,
      1613
    ],
    "setId": 43,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1614": {
    "id": 1614,
    "displayName": "Colossal Guardian Hammer",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 71,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1615,
    "tierItemIds": [
      1614,
      1615,
      1616
    ],
    "setId": 43,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1615": {
    "id": 1615,
    "displayName": "Mighty Colossal Guardian Hammer",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 100,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 8,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1616,
    "tierItemIds": [
      1614,
      1615,
      1616
    ],
    "setId": 43,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1616": {
    "id": 1616,
    "displayName": "Superior Colossal Guardian Hammer",
    "name": "Colossal Guardian Weapon",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 140,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 12,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1614,
      1615,
      1616
    ],
    "setId": 43,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1617": {
    "id": 1617,
    "displayName": "Colossal Guardian Gloves",
    "name": "Colossal Guardian Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1618,
    "tierItemIds": [
      1617,
      1618,
      1619
    ],
    "setId": 43,
    "animType": null
  },
  "1618": {
    "id": 1618,
    "displayName": "Mighty Colossal Guardian Gloves",
    "name": "Colossal Guardian Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1619,
    "tierItemIds": [
      1617,
      1618,
      1619
    ],
    "setId": 43,
    "animType": null
  },
  "1619": {
    "id": 1619,
    "displayName": "Superior Colossal Guardian Gloves",
    "name": "Colossal Guardian Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1617,
      1618,
      1619
    ],
    "setId": 43,
    "animType": null
  },
  "1620": {
    "id": 1620,
    "displayName": "Colossal Guardian Gloves",
    "name": "Colossal Guardian Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 35,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1621,
    "tierItemIds": [
      1620,
      1621,
      1622
    ],
    "setId": 43,
    "animType": null
  },
  "1621": {
    "id": 1621,
    "displayName": "Mighty Colossal Guardian Gloves",
    "name": "Colossal Guardian Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1622,
    "tierItemIds": [
      1620,
      1621,
      1622
    ],
    "setId": 43,
    "animType": null
  },
  "1622": {
    "id": 1622,
    "displayName": "Superior Colossal Guardian Gloves",
    "name": "Colossal Guardian Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1620,
      1621,
      1622
    ],
    "setId": 43,
    "animType": null
  },
  "1623": {
    "id": 1623,
    "displayName": "Colossal Guardian Gloves",
    "name": "Colossal Guardian Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1624,
    "tierItemIds": [
      1623,
      1624,
      1625
    ],
    "setId": 43,
    "animType": null
  },
  "1624": {
    "id": 1624,
    "displayName": "Mighty Colossal Guardian Gloves",
    "name": "Colossal Guardian Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1625,
    "tierItemIds": [
      1623,
      1624,
      1625
    ],
    "setId": 43,
    "animType": null
  },
  "1625": {
    "id": 1625,
    "displayName": "Superior Colossal Guardian Gloves",
    "name": "Colossal Guardian Gloves",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 14,
      "Critical Damage": 0,
      "STR": 15,
      "AGI": 0,
      "INT": 11,
      "LUK": 5,
      "HP": 98,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1623,
      1624,
      1625
    ],
    "setId": 43,
    "animType": null
  },
  "1626": {
    "id": 1626,
    "displayName": "Colossal Guardian Helmet",
    "name": "Colossal Guardian Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 1,
      "STR": 4,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1627,
    "tierItemIds": [
      1626,
      1627,
      1628
    ],
    "setId": 43,
    "animType": null
  },
  "1627": {
    "id": 1627,
    "displayName": "Mighty Colossal Guardian Helmet",
    "name": "Colossal Guardian Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 2,
      "STR": 6,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1628,
    "tierItemIds": [
      1626,
      1627,
      1628
    ],
    "setId": 43,
    "animType": null
  },
  "1628": {
    "id": 1628,
    "displayName": "Superior Colossal Guardian Helmet",
    "name": "Colossal Guardian Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 7,
      "Critical Damage": 4,
      "STR": 9,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 77,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1626,
      1627,
      1628
    ],
    "setId": 43,
    "animType": null
  },
  "1629": {
    "id": 1629,
    "displayName": "Colossal Guardian Helmet",
    "name": "Colossal Guardian Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 2,
      "STR": 6,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 61,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1630,
    "tierItemIds": [
      1629,
      1630,
      1631
    ],
    "setId": 43,
    "animType": null
  },
  "1630": {
    "id": 1630,
    "displayName": "Mighty Colossal Guardian Helmet",
    "name": "Colossal Guardian Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 7,
      "Critical Damage": 4,
      "STR": 9,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 86,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1631,
    "tierItemIds": [
      1629,
      1630,
      1631
    ],
    "setId": 43,
    "animType": null
  },
  "1631": {
    "id": 1631,
    "displayName": "Superior Colossal Guardian Helmet",
    "name": "Colossal Guardian Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 10,
      "Critical Damage": 6,
      "STR": 13,
      "AGI": 0,
      "INT": 10,
      "LUK": 5,
      "HP": 121,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1629,
      1630,
      1631
    ],
    "setId": 43,
    "animType": null
  },
  "1632": {
    "id": 1632,
    "displayName": "Colossal Guardian Helmet",
    "name": "Colossal Guardian Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 7,
      "Critical Damage": 4,
      "STR": 9,
      "AGI": 0,
      "INT": 7,
      "LUK": 3,
      "HP": 86,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1633,
    "tierItemIds": [
      1632,
      1633,
      1634
    ],
    "setId": 43,
    "animType": null
  },
  "1633": {
    "id": 1633,
    "displayName": "Mighty Colossal Guardian Helmet",
    "name": "Colossal Guardian Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 10,
      "Critical Damage": 6,
      "STR": 13,
      "AGI": 0,
      "INT": 10,
      "LUK": 5,
      "HP": 121,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1634,
    "tierItemIds": [
      1632,
      1633,
      1634
    ],
    "setId": 43,
    "animType": null
  },
  "1634": {
    "id": 1634,
    "displayName": "Superior Colossal Guardian Helmet",
    "name": "Colossal Guardian Helmet",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 17,
      "Physical Defense": 14,
      "Critical Damage": 9,
      "STR": 19,
      "AGI": 0,
      "INT": 14,
      "LUK": 8,
      "HP": 170,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1632,
      1633,
      1634
    ],
    "setId": 43,
    "animType": null
  },
  "1635": {
    "id": 1635,
    "displayName": "Colossal Guardian Armor",
    "name": "Colossal Guardian Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1636,
    "tierItemIds": [
      1635,
      1636,
      1637
    ],
    "setId": 43,
    "animType": null
  },
  "1636": {
    "id": 1636,
    "displayName": "Mighty Colossal Guardian Armor",
    "name": "Colossal Guardian Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1637,
    "tierItemIds": [
      1635,
      1636,
      1637
    ],
    "setId": 43,
    "animType": null
  },
  "1637": {
    "id": 1637,
    "displayName": "Superior Colossal Guardian Armor",
    "name": "Colossal Guardian Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 77,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1635,
      1636,
      1637
    ],
    "setId": 43,
    "animType": null
  },
  "1638": {
    "id": 1638,
    "displayName": "Colossal Guardian Armor",
    "name": "Colossal Guardian Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 61,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1639,
    "tierItemIds": [
      1638,
      1639,
      1640
    ],
    "setId": 43,
    "animType": null
  },
  "1639": {
    "id": 1639,
    "displayName": "Mighty Colossal Guardian Armor",
    "name": "Colossal Guardian Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 86,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1640,
    "tierItemIds": [
      1638,
      1639,
      1640
    ],
    "setId": 43,
    "animType": null
  },
  "1640": {
    "id": 1640,
    "displayName": "Superior Colossal Guardian Armor",
    "name": "Colossal Guardian Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 15,
      "AGI": 0,
      "INT": 12,
      "LUK": 0,
      "HP": 121,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1638,
      1639,
      1640
    ],
    "setId": 43,
    "animType": null
  },
  "1641": {
    "id": 1641,
    "displayName": "Colossal Guardian Armor",
    "name": "Colossal Guardian Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 86,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1642,
    "tierItemIds": [
      1641,
      1642,
      1643
    ],
    "setId": 43,
    "animType": null
  },
  "1642": {
    "id": 1642,
    "displayName": "Mighty Colossal Guardian Armor",
    "name": "Colossal Guardian Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 15,
      "AGI": 0,
      "INT": 12,
      "LUK": 0,
      "HP": 121,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1643,
    "tierItemIds": [
      1641,
      1642,
      1643
    ],
    "setId": 43,
    "animType": null
  },
  "1643": {
    "id": 1643,
    "displayName": "Superior Colossal Guardian Armor",
    "name": "Colossal Guardian Armor",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 22,
      "AGI": 0,
      "INT": 17,
      "LUK": 0,
      "HP": 170,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1641,
      1642,
      1643
    ],
    "setId": 43,
    "animType": null
  },
  "1644": {
    "id": 1644,
    "displayName": "Colossal Guardian Boots",
    "name": "Colossal Guardian Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 10,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1645,
    "tierItemIds": [
      1644,
      1645,
      1646
    ],
    "setId": 43,
    "animType": null
  },
  "1645": {
    "id": 1645,
    "displayName": "Mighty Colossal Guardian Boots",
    "name": "Colossal Guardian Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1646,
    "tierItemIds": [
      1644,
      1645,
      1646
    ],
    "setId": 43,
    "animType": null
  },
  "1646": {
    "id": 1646,
    "displayName": "Superior Colossal Guardian Boots",
    "name": "Colossal Guardian Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1644,
      1645,
      1646
    ],
    "setId": 43,
    "animType": null
  },
  "1647": {
    "id": 1647,
    "displayName": "Colossal Guardian Boots",
    "name": "Colossal Guardian Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1648,
    "tierItemIds": [
      1647,
      1648,
      1649
    ],
    "setId": 43,
    "animType": null
  },
  "1648": {
    "id": 1648,
    "displayName": "Mighty Colossal Guardian Boots",
    "name": "Colossal Guardian Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1649,
    "tierItemIds": [
      1647,
      1648,
      1649
    ],
    "setId": 43,
    "animType": null
  },
  "1649": {
    "id": 1649,
    "displayName": "Superior Colossal Guardian Boots",
    "name": "Colossal Guardian Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1647,
      1648,
      1649
    ],
    "setId": 43,
    "animType": null
  },
  "1650": {
    "id": 1650,
    "displayName": "Colossal Guardian Boots",
    "name": "Colossal Guardian Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1651,
    "tierItemIds": [
      1650,
      1651,
      1652
    ],
    "setId": 43,
    "animType": null
  },
  "1651": {
    "id": 1651,
    "displayName": "Mighty Colossal Guardian Boots",
    "name": "Colossal Guardian Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1652,
    "tierItemIds": [
      1650,
      1651,
      1652
    ],
    "setId": 43,
    "animType": null
  },
  "1652": {
    "id": 1652,
    "displayName": "Superior Colossal Guardian Boots",
    "name": "Colossal Guardian Boots",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1650,
      1651,
      1652
    ],
    "setId": 43,
    "animType": null
  },
  "1653": {
    "id": 1653,
    "displayName": "Colossal Guardian Pants",
    "name": "Colossal Guardian Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 10,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1654,
    "tierItemIds": [
      1653,
      1654,
      1655
    ],
    "setId": 43,
    "animType": null
  },
  "1654": {
    "id": 1654,
    "displayName": "Mighty Colossal Guardian Pants",
    "name": "Colossal Guardian Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1655,
    "tierItemIds": [
      1653,
      1654,
      1655
    ],
    "setId": 43,
    "animType": null
  },
  "1655": {
    "id": 1655,
    "displayName": "Superior Colossal Guardian Pants",
    "name": "Colossal Guardian Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1653,
      1654,
      1655
    ],
    "setId": 43,
    "animType": null
  },
  "1656": {
    "id": 1656,
    "displayName": "Colossal Guardian Pants",
    "name": "Colossal Guardian Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 17,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1657,
    "tierItemIds": [
      1656,
      1657,
      1658
    ],
    "setId": 43,
    "animType": null
  },
  "1657": {
    "id": 1657,
    "displayName": "Mighty Colossal Guardian Pants",
    "name": "Colossal Guardian Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1658,
    "tierItemIds": [
      1656,
      1657,
      1658
    ],
    "setId": 43,
    "animType": null
  },
  "1658": {
    "id": 1658,
    "displayName": "Superior Colossal Guardian Pants",
    "name": "Colossal Guardian Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1656,
      1657,
      1658
    ],
    "setId": 43,
    "animType": null
  },
  "1659": {
    "id": 1659,
    "displayName": "Colossal Guardian Pants",
    "name": "Colossal Guardian Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1660,
    "tierItemIds": [
      1659,
      1660,
      1661
    ],
    "setId": 43,
    "animType": null
  },
  "1660": {
    "id": 1660,
    "displayName": "Mighty Colossal Guardian Pants",
    "name": "Colossal Guardian Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1661,
    "tierItemIds": [
      1659,
      1660,
      1661
    ],
    "setId": 43,
    "animType": null
  },
  "1661": {
    "id": 1661,
    "displayName": "Superior Colossal Guardian Pants",
    "name": "Colossal Guardian Pants",
    "races": [
      "Orc"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1659,
      1660,
      1661
    ],
    "setId": 43,
    "animType": null
  },
  "1662": {
    "id": 1662,
    "displayName": "Excalibur Staff",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 48,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1663,
    "tierItemIds": [
      1662,
      1663,
      1664
    ],
    "setId": 44,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1663": {
    "id": 1663,
    "displayName": "Mighty Excalibur Staff",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 68,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1664,
    "tierItemIds": [
      1662,
      1663,
      1664
    ],
    "setId": 44,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1664": {
    "id": 1664,
    "displayName": "Superior Excalibur Staff",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 96,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1662,
      1663,
      1664
    ],
    "setId": 44,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1665": {
    "id": 1665,
    "displayName": "Excalibur Staff",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 68,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1666,
    "tierItemIds": [
      1665,
      1666,
      1667
    ],
    "setId": 44,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1666": {
    "id": 1666,
    "displayName": "Mighty Excalibur Staff",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 96,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1667,
    "tierItemIds": [
      1665,
      1666,
      1667
    ],
    "setId": 44,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1667": {
    "id": 1667,
    "displayName": "Superior Excalibur Staff",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 135,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1665,
      1666,
      1667
    ],
    "setId": 44,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1668": {
    "id": 1668,
    "displayName": "Excalibur Staff",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 96,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1669,
    "tierItemIds": [
      1668,
      1669,
      1670
    ],
    "setId": 44,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1669": {
    "id": 1669,
    "displayName": "Mighty Excalibur Staff",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 135,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1670,
    "tierItemIds": [
      1668,
      1669,
      1670
    ],
    "setId": 44,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1670": {
    "id": 1670,
    "displayName": "Superior Excalibur Staff",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 189,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 4,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 16,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1668,
      1669,
      1670
    ],
    "setId": 44,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1671": {
    "id": 1671,
    "displayName": "Excalibur Shield",
    "name": "Excalibur Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 43,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1672,
    "tierItemIds": [
      1671,
      1672,
      1673
    ],
    "setId": 44,
    "animType": null,
    "damageType": "Physical"
  },
  "1672": {
    "id": 1672,
    "displayName": "Mighty Excalibur Shield",
    "name": "Excalibur Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 61,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1673,
    "tierItemIds": [
      1671,
      1672,
      1673
    ],
    "setId": 44,
    "animType": null,
    "damageType": "Physical"
  },
  "1673": {
    "id": 1673,
    "displayName": "Superior Excalibur Shield",
    "name": "Excalibur Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 86,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1671,
      1672,
      1673
    ],
    "setId": 44,
    "animType": null,
    "damageType": "Physical"
  },
  "1674": {
    "id": 1674,
    "displayName": "Excalibur Shield",
    "name": "Excalibur Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 68,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1675,
    "tierItemIds": [
      1674,
      1675,
      1676
    ],
    "setId": 44,
    "animType": null,
    "damageType": "Physical"
  },
  "1675": {
    "id": 1675,
    "displayName": "Mighty Excalibur Shield",
    "name": "Excalibur Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 96,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1676,
    "tierItemIds": [
      1674,
      1675,
      1676
    ],
    "setId": 44,
    "animType": null,
    "damageType": "Physical"
  },
  "1676": {
    "id": 1676,
    "displayName": "Superior Excalibur Shield",
    "name": "Excalibur Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 15,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 135,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1674,
      1675,
      1676
    ],
    "setId": 44,
    "animType": null,
    "damageType": "Physical"
  },
  "1677": {
    "id": 1677,
    "displayName": "Excalibur Shield",
    "name": "Excalibur Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 3,
      "HP": 96,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1678,
    "tierItemIds": [
      1677,
      1678,
      1679
    ],
    "setId": 44,
    "animType": null,
    "damageType": "Physical"
  },
  "1678": {
    "id": 1678,
    "displayName": "Mighty Excalibur Shield",
    "name": "Excalibur Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 15,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 5,
      "HP": 135,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1679,
    "tierItemIds": [
      1677,
      1678,
      1679
    ],
    "setId": 44,
    "animType": null,
    "damageType": "Physical"
  },
  "1679": {
    "id": 1679,
    "displayName": "Superior Excalibur Shield",
    "name": "Excalibur Sub Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 21,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 8,
      "HP": 189,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1677,
      1678,
      1679
    ],
    "setId": 44,
    "animType": null,
    "damageType": "Physical"
  },
  "1680": {
    "id": 1680,
    "displayName": "Excalibur spear",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 37,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1681,
    "tierItemIds": [
      1680,
      1681,
      1682
    ],
    "setId": 44,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1681": {
    "id": 1681,
    "displayName": "Mighty Excalibur spear",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 52,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1682,
    "tierItemIds": [
      1680,
      1681,
      1682
    ],
    "setId": 44,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1682": {
    "id": 1682,
    "displayName": "Superior Excalibur spear",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 74,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1680,
      1681,
      1682
    ],
    "setId": 44,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1683": {
    "id": 1683,
    "displayName": "Excalibur spear",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 52,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1684,
    "tierItemIds": [
      1683,
      1684,
      1685
    ],
    "setId": 44,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1684": {
    "id": 1684,
    "displayName": "Mighty Excalibur spear",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 74,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1685,
    "tierItemIds": [
      1683,
      1684,
      1685
    ],
    "setId": 44,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1685": {
    "id": 1685,
    "displayName": "Superior Excalibur spear",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 104,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1683,
      1684,
      1685
    ],
    "setId": 44,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1686": {
    "id": 1686,
    "displayName": "Excalibur spear",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 74,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1687,
    "tierItemIds": [
      1686,
      1687,
      1688
    ],
    "setId": 44,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1687": {
    "id": 1687,
    "displayName": "Mighty Excalibur spear",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 104,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1688,
    "tierItemIds": [
      1686,
      1687,
      1688
    ],
    "setId": 44,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1688": {
    "id": 1688,
    "displayName": "Superior Excalibur spear",
    "name": "Excalibur Weapon",
    "races": [
      "Human"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 146,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1686,
      1687,
      1688
    ],
    "setId": 44,
    "animType": "spear",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 2
    }
  },
  "1689": {
    "id": 1689,
    "displayName": "Excalibur Gloves",
    "name": "Excalibur Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1690,
    "tierItemIds": [
      1689,
      1690,
      1691
    ],
    "setId": 44,
    "animType": null
  },
  "1690": {
    "id": 1690,
    "displayName": "Mighty Excalibur Gloves",
    "name": "Excalibur Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1691,
    "tierItemIds": [
      1689,
      1690,
      1691
    ],
    "setId": 44,
    "animType": null
  },
  "1691": {
    "id": 1691,
    "displayName": "Superior Excalibur Gloves",
    "name": "Excalibur Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 66,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1689,
      1690,
      1691
    ],
    "setId": 44,
    "animType": null
  },
  "1692": {
    "id": 1692,
    "displayName": "Excalibur Gloves",
    "name": "Excalibur Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 52,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1693,
    "tierItemIds": [
      1692,
      1693,
      1694
    ],
    "setId": 44,
    "animType": null
  },
  "1693": {
    "id": 1693,
    "displayName": "Mighty Excalibur Gloves",
    "name": "Excalibur Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 74,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1694,
    "tierItemIds": [
      1692,
      1693,
      1694
    ],
    "setId": 44,
    "animType": null
  },
  "1694": {
    "id": 1694,
    "displayName": "Superior Excalibur Gloves",
    "name": "Excalibur Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 104,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1692,
      1693,
      1694
    ],
    "setId": 44,
    "animType": null
  },
  "1695": {
    "id": 1695,
    "displayName": "Excalibur Gloves",
    "name": "Excalibur Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 74,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1696,
    "tierItemIds": [
      1695,
      1696,
      1697
    ],
    "setId": 44,
    "animType": null
  },
  "1696": {
    "id": 1696,
    "displayName": "Mighty Excalibur Gloves",
    "name": "Excalibur Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 104,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1697,
    "tierItemIds": [
      1695,
      1696,
      1697
    ],
    "setId": 44,
    "animType": null
  },
  "1697": {
    "id": 1697,
    "displayName": "Superior Excalibur Gloves",
    "name": "Excalibur Gloves",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 15,
      "AGI": 0,
      "INT": 7,
      "LUK": 4,
      "HP": 146,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1695,
      1696,
      1697
    ],
    "setId": 44,
    "animType": null
  },
  "1698": {
    "id": 1698,
    "displayName": "Excalibur Helmet",
    "name": "Excalibur Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 1,
      "HP": 33,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1699,
    "tierItemIds": [
      1698,
      1699,
      1700
    ],
    "setId": 44,
    "animType": null
  },
  "1699": {
    "id": 1699,
    "displayName": "Mighty Excalibur Helmet",
    "name": "Excalibur Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 2,
      "HP": 47,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1700,
    "tierItemIds": [
      1698,
      1699,
      1700
    ],
    "setId": 44,
    "animType": null
  },
  "1700": {
    "id": 1700,
    "displayName": "Superior Excalibur Helmet",
    "name": "Excalibur Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 4,
      "LUK": 3,
      "HP": 66,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1698,
      1699,
      1700
    ],
    "setId": 44,
    "animType": null
  },
  "1701": {
    "id": 1701,
    "displayName": "Excalibur Helmet",
    "name": "Excalibur Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 2,
      "HP": 52,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1702,
    "tierItemIds": [
      1701,
      1702,
      1703
    ],
    "setId": 44,
    "animType": null
  },
  "1702": {
    "id": 1702,
    "displayName": "Mighty Excalibur Helmet",
    "name": "Excalibur Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 4,
      "LUK": 3,
      "HP": 74,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1703,
    "tierItemIds": [
      1701,
      1702,
      1703
    ],
    "setId": 44,
    "animType": null
  },
  "1703": {
    "id": 1703,
    "displayName": "Superior Excalibur Helmet",
    "name": "Excalibur Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 11,
      "Critical Damage": 1,
      "STR": 12,
      "AGI": 0,
      "INT": 6,
      "LUK": 5,
      "HP": 104,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1701,
      1702,
      1703
    ],
    "setId": 44,
    "animType": null
  },
  "1704": {
    "id": 1704,
    "displayName": "Excalibur Helmet",
    "name": "Excalibur Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 4,
      "LUK": 3,
      "HP": 74,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1705,
    "tierItemIds": [
      1704,
      1705,
      1706
    ],
    "setId": 44,
    "animType": null
  },
  "1705": {
    "id": 1705,
    "displayName": "Mighty Excalibur Helmet",
    "name": "Excalibur Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 11,
      "Critical Damage": 1,
      "STR": 12,
      "AGI": 0,
      "INT": 6,
      "LUK": 5,
      "HP": 104,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1706,
    "tierItemIds": [
      1704,
      1705,
      1706
    ],
    "setId": 44,
    "animType": null
  },
  "1706": {
    "id": 1706,
    "displayName": "Superior Excalibur Helmet",
    "name": "Excalibur Helmet",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 16,
      "Critical Damage": 2,
      "STR": 18,
      "AGI": 0,
      "INT": 9,
      "LUK": 7,
      "HP": 146,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1704,
      1705,
      1706
    ],
    "setId": 44,
    "animType": null
  },
  "1707": {
    "id": 1707,
    "displayName": "Excalibur Armor",
    "name": "Excalibur Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 37,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1708,
    "tierItemIds": [
      1707,
      1708,
      1709
    ],
    "setId": 44,
    "animType": null
  },
  "1708": {
    "id": 1708,
    "displayName": "Mighty Excalibur Armor",
    "name": "Excalibur Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 52,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1709,
    "tierItemIds": [
      1707,
      1708,
      1709
    ],
    "setId": 44,
    "animType": null
  },
  "1709": {
    "id": 1709,
    "displayName": "Superior Excalibur Armor",
    "name": "Excalibur Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 73,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1707,
      1708,
      1709
    ],
    "setId": 44,
    "animType": null
  },
  "1710": {
    "id": 1710,
    "displayName": "Excalibur Armor",
    "name": "Excalibur Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 58,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1711,
    "tierItemIds": [
      1710,
      1711,
      1712
    ],
    "setId": 44,
    "animType": null
  },
  "1711": {
    "id": 1711,
    "displayName": "Mighty Excalibur Armor",
    "name": "Excalibur Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 82,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1712,
    "tierItemIds": [
      1710,
      1711,
      1712
    ],
    "setId": 44,
    "animType": null
  },
  "1712": {
    "id": 1712,
    "displayName": "Superior Excalibur Armor",
    "name": "Excalibur Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 14,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 116,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1710,
      1711,
      1712
    ],
    "setId": 44,
    "animType": null
  },
  "1713": {
    "id": 1713,
    "displayName": "Excalibur Armor",
    "name": "Excalibur Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 8,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 82,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1714,
    "tierItemIds": [
      1713,
      1714,
      1715
    ],
    "setId": 44,
    "animType": null
  },
  "1714": {
    "id": 1714,
    "displayName": "Mighty Excalibur Armor",
    "name": "Excalibur Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 12,
      "Critical Damage": 0,
      "STR": 14,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 116,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1715,
    "tierItemIds": [
      1713,
      1714,
      1715
    ],
    "setId": 44,
    "animType": null
  },
  "1715": {
    "id": 1715,
    "displayName": "Superior Excalibur Armor",
    "name": "Excalibur Armor",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 18,
      "Critical Damage": 0,
      "STR": 20,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 163,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1713,
      1714,
      1715
    ],
    "setId": 44,
    "animType": null
  },
  "1716": {
    "id": 1716,
    "displayName": "Excalibur Boots",
    "name": "Excalibur Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 9,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1717,
    "tierItemIds": [
      1716,
      1717,
      1718
    ],
    "setId": 44,
    "animType": null
  },
  "1717": {
    "id": 1717,
    "displayName": "Mighty Excalibur Boots",
    "name": "Excalibur Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 13,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1718,
    "tierItemIds": [
      1716,
      1717,
      1718
    ],
    "setId": 44,
    "animType": null
  },
  "1718": {
    "id": 1718,
    "displayName": "Superior Excalibur Boots",
    "name": "Excalibur Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 19,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1716,
      1717,
      1718
    ],
    "setId": 44,
    "animType": null
  },
  "1719": {
    "id": 1719,
    "displayName": "Excalibur Boots",
    "name": "Excalibur Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1720,
    "tierItemIds": [
      1719,
      1720,
      1721
    ],
    "setId": 44,
    "animType": null
  },
  "1720": {
    "id": 1720,
    "displayName": "Mighty Excalibur Boots",
    "name": "Excalibur Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1721,
    "tierItemIds": [
      1719,
      1720,
      1721
    ],
    "setId": 44,
    "animType": null
  },
  "1721": {
    "id": 1721,
    "displayName": "Superior Excalibur Boots",
    "name": "Excalibur Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1719,
      1720,
      1721
    ],
    "setId": 44,
    "animType": null
  },
  "1722": {
    "id": 1722,
    "displayName": "Excalibur Boots",
    "name": "Excalibur Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1723,
    "tierItemIds": [
      1722,
      1723,
      1724
    ],
    "setId": 44,
    "animType": null
  },
  "1723": {
    "id": 1723,
    "displayName": "Mighty Excalibur Boots",
    "name": "Excalibur Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1724,
    "tierItemIds": [
      1722,
      1723,
      1724
    ],
    "setId": 44,
    "animType": null
  },
  "1724": {
    "id": 1724,
    "displayName": "Superior Excalibur Boots",
    "name": "Excalibur Boots",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1722,
      1723,
      1724
    ],
    "setId": 44,
    "animType": null
  },
  "1725": {
    "id": 1725,
    "displayName": "Excalibur Pants",
    "name": "Excalibur Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 8,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1726,
    "tierItemIds": [
      1725,
      1726,
      1727
    ],
    "setId": 44,
    "animType": null
  },
  "1726": {
    "id": 1726,
    "displayName": "Mighty Excalibur Pants",
    "name": "Excalibur Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 12,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1727,
    "tierItemIds": [
      1725,
      1726,
      1727
    ],
    "setId": 44,
    "animType": null
  },
  "1727": {
    "id": 1727,
    "displayName": "Superior Excalibur Pants",
    "name": "Excalibur Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 18,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1725,
      1726,
      1727
    ],
    "setId": 44,
    "animType": null
  },
  "1728": {
    "id": 1728,
    "displayName": "Excalibur Pants",
    "name": "Excalibur Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1729,
    "tierItemIds": [
      1728,
      1729,
      1730
    ],
    "setId": 44,
    "animType": null
  },
  "1729": {
    "id": 1729,
    "displayName": "Mighty Excalibur Pants",
    "name": "Excalibur Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1730,
    "tierItemIds": [
      1728,
      1729,
      1730
    ],
    "setId": 44,
    "animType": null
  },
  "1730": {
    "id": 1730,
    "displayName": "Superior Excalibur Pants",
    "name": "Excalibur Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1728,
      1729,
      1730
    ],
    "setId": 44,
    "animType": null
  },
  "1731": {
    "id": 1731,
    "displayName": "Excalibur Pants",
    "name": "Excalibur Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1732,
    "tierItemIds": [
      1731,
      1732,
      1733
    ],
    "setId": 44,
    "animType": null
  },
  "1732": {
    "id": 1732,
    "displayName": "Mighty Excalibur Pants",
    "name": "Excalibur Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 1,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1733,
    "tierItemIds": [
      1731,
      1732,
      1733
    ],
    "setId": 44,
    "animType": null
  },
  "1733": {
    "id": 1733,
    "displayName": "Superior Excalibur Pants",
    "name": "Excalibur Pants",
    "races": [
      "Human"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 4,
      "INT": 0,
      "LUK": 0,
      "HP": 43,
      "Critical": 0,
      "Evade": 2,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1731,
      1732,
      1733
    ],
    "setId": 44,
    "animType": null
  },
  "1734": {
    "id": 1734,
    "displayName": "Hecateus Staff",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 40,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1735,
    "tierItemIds": [
      1734,
      1735,
      1736
    ],
    "setId": 45,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1735": {
    "id": 1735,
    "displayName": "Mighty Hecateus Staff",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 57,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1736,
    "tierItemIds": [
      1734,
      1735,
      1736
    ],
    "setId": 45,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1736": {
    "id": 1736,
    "displayName": "Superior Hecateus Staff",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 81,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1734,
      1735,
      1736
    ],
    "setId": 45,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1737": {
    "id": 1737,
    "displayName": "Hecateus Staff",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 57,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1738,
    "tierItemIds": [
      1737,
      1738,
      1739
    ],
    "setId": 45,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1738": {
    "id": 1738,
    "displayName": "Mighty Hecateus Staff",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 81,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1739,
    "tierItemIds": [
      1737,
      1738,
      1739
    ],
    "setId": 45,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1739": {
    "id": 1739,
    "displayName": "Superior Hecateus Staff",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 114,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1737,
      1738,
      1739
    ],
    "setId": 45,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1740": {
    "id": 1740,
    "displayName": "Hecateus Staff",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 81,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1741,
    "tierItemIds": [
      1740,
      1741,
      1742
    ],
    "setId": 45,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1741": {
    "id": 1741,
    "displayName": "Mighty Hecateus Staff",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 114,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1742,
    "tierItemIds": [
      1740,
      1741,
      1742
    ],
    "setId": 45,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1742": {
    "id": 1742,
    "displayName": "Superior Hecateus Staff",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 160,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1740,
      1741,
      1742
    ],
    "setId": 45,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1743": {
    "id": 1743,
    "displayName": "Hecateus Shield",
    "name": "Hecateus Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 42,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1744,
    "tierItemIds": [
      1743,
      1744,
      1745
    ],
    "setId": 45,
    "animType": null,
    "damageType": "Physical"
  },
  "1744": {
    "id": 1744,
    "displayName": "Mighty Hecateus Shield",
    "name": "Hecateus Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 59,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1745,
    "tierItemIds": [
      1743,
      1744,
      1745
    ],
    "setId": 45,
    "animType": null,
    "damageType": "Physical"
  },
  "1745": {
    "id": 1745,
    "displayName": "Superior Hecateus Shield",
    "name": "Hecateus Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 83,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1743,
      1744,
      1745
    ],
    "setId": 45,
    "animType": null,
    "damageType": "Physical"
  },
  "1746": {
    "id": 1746,
    "displayName": "Hecateus Shield",
    "name": "Hecateus Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 1,
      "HP": 66,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1747,
    "tierItemIds": [
      1746,
      1747,
      1748
    ],
    "setId": 45,
    "animType": null,
    "damageType": "Physical"
  },
  "1747": {
    "id": 1747,
    "displayName": "Mighty Hecateus Shield",
    "name": "Hecateus Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 93,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1748,
    "tierItemIds": [
      1746,
      1747,
      1748
    ],
    "setId": 45,
    "animType": null,
    "damageType": "Physical"
  },
  "1748": {
    "id": 1748,
    "displayName": "Superior Hecateus Shield",
    "name": "Hecateus Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 4,
      "HP": 131,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1746,
      1747,
      1748
    ],
    "setId": 45,
    "animType": null,
    "damageType": "Physical"
  },
  "1749": {
    "id": 1749,
    "displayName": "Hecateus Shield",
    "name": "Hecateus Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 2,
      "HP": 93,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1750,
    "tierItemIds": [
      1749,
      1750,
      1751
    ],
    "setId": 45,
    "animType": null,
    "damageType": "Physical"
  },
  "1750": {
    "id": 1750,
    "displayName": "Mighty Hecateus Shield",
    "name": "Hecateus Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 4,
      "HP": 131,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1751,
    "tierItemIds": [
      1749,
      1750,
      1751
    ],
    "setId": 45,
    "animType": null,
    "damageType": "Physical"
  },
  "1751": {
    "id": 1751,
    "displayName": "Superior Hecateus Shield",
    "name": "Hecateus Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 22,
      "Physical Defense": 13,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 6,
      "HP": 184,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1749,
      1750,
      1751
    ],
    "setId": 45,
    "animType": null,
    "damageType": "Physical"
  },
  "1752": {
    "id": 1752,
    "displayName": "Hecateus Axe",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 53,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1753,
    "tierItemIds": [
      1752,
      1753,
      1754
    ],
    "setId": 45,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1753": {
    "id": 1753,
    "displayName": "Mighty Hecateus Axe",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 75,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1754,
    "tierItemIds": [
      1752,
      1753,
      1754
    ],
    "setId": 45,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1754": {
    "id": 1754,
    "displayName": "Superior Hecateus Axe",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 105,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1752,
      1753,
      1754
    ],
    "setId": 45,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1755": {
    "id": 1755,
    "displayName": "Hecateus Axe",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 75,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 3,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1756,
    "tierItemIds": [
      1755,
      1756,
      1757
    ],
    "setId": 45,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1756": {
    "id": 1756,
    "displayName": "Mighty Hecateus Axe",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 105,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 6,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1757,
    "tierItemIds": [
      1755,
      1756,
      1757
    ],
    "setId": 45,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1757": {
    "id": 1757,
    "displayName": "Superior Hecateus Axe",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 148,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 9,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1755,
      1756,
      1757
    ],
    "setId": 45,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1758": {
    "id": 1758,
    "displayName": "Hecateus Axe",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 105,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1759,
    "tierItemIds": [
      1758,
      1759,
      1760
    ],
    "setId": 45,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1759": {
    "id": 1759,
    "displayName": "Mighty Hecateus Axe",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 148,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1760,
    "tierItemIds": [
      1758,
      1759,
      1760
    ],
    "setId": 45,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1760": {
    "id": 1760,
    "displayName": "Superior Hecateus Axe",
    "name": "Hecateus Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 208,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 14,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1758,
      1759,
      1760
    ],
    "setId": 45,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1761": {
    "id": 1761,
    "displayName": "Hecateus Gloves",
    "name": "Hecateus Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1762,
    "tierItemIds": [
      1761,
      1762,
      1763
    ],
    "setId": 45,
    "animType": null
  },
  "1762": {
    "id": 1762,
    "displayName": "Mighty Hecateus Gloves",
    "name": "Hecateus Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1763,
    "tierItemIds": [
      1761,
      1762,
      1763
    ],
    "setId": 45,
    "animType": null
  },
  "1763": {
    "id": 1763,
    "displayName": "Superior Hecateus Gloves",
    "name": "Hecateus Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 10,
      "LUK": 1,
      "HP": 64,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1761,
      1762,
      1763
    ],
    "setId": 45,
    "animType": null
  },
  "1764": {
    "id": 1764,
    "displayName": "Hecateus Gloves",
    "name": "Hecateus Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 51,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1765,
    "tierItemIds": [
      1764,
      1765,
      1766
    ],
    "setId": 45,
    "animType": null
  },
  "1765": {
    "id": 1765,
    "displayName": "Mighty Hecateus Gloves",
    "name": "Hecateus Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 10,
      "LUK": 1,
      "HP": 72,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1766,
    "tierItemIds": [
      1764,
      1765,
      1766
    ],
    "setId": 45,
    "animType": null
  },
  "1766": {
    "id": 1766,
    "displayName": "Superior Hecateus Gloves",
    "name": "Hecateus Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 14,
      "LUK": 2,
      "HP": 101,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1764,
      1765,
      1766
    ],
    "setId": 45,
    "animType": null
  },
  "1767": {
    "id": 1767,
    "displayName": "Hecateus Gloves",
    "name": "Hecateus Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 10,
      "LUK": 1,
      "HP": 72,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1768,
    "tierItemIds": [
      1767,
      1768,
      1769
    ],
    "setId": 45,
    "animType": null
  },
  "1768": {
    "id": 1768,
    "displayName": "Mighty Hecateus Gloves",
    "name": "Hecateus Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 14,
      "LUK": 2,
      "HP": 101,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1769,
    "tierItemIds": [
      1767,
      1768,
      1769
    ],
    "setId": 45,
    "animType": null
  },
  "1769": {
    "id": 1769,
    "displayName": "Superior Hecateus Gloves",
    "name": "Hecateus Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 0,
      "INT": 20,
      "LUK": 3,
      "HP": 142,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1767,
      1768,
      1769
    ],
    "setId": 45,
    "animType": null
  },
  "1770": {
    "id": 1770,
    "displayName": "Hecateus Helmet",
    "name": "Hecateus Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1771,
    "tierItemIds": [
      1770,
      1771,
      1772
    ],
    "setId": 45,
    "animType": null
  },
  "1771": {
    "id": 1771,
    "displayName": "Mighty Hecateus Helmet",
    "name": "Hecateus Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 8,
      "LUK": 1,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1772,
    "tierItemIds": [
      1770,
      1771,
      1772
    ],
    "setId": 45,
    "animType": null
  },
  "1772": {
    "id": 1772,
    "displayName": "Superior Hecateus Helmet",
    "name": "Hecateus Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 12,
      "LUK": 2,
      "HP": 64,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1770,
      1771,
      1772
    ],
    "setId": 45,
    "animType": null
  },
  "1773": {
    "id": 1773,
    "displayName": "Hecateus Helmet",
    "name": "Hecateus Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 8,
      "LUK": 1,
      "HP": 51,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1774,
    "tierItemIds": [
      1773,
      1774,
      1775
    ],
    "setId": 45,
    "animType": null
  },
  "1774": {
    "id": 1774,
    "displayName": "Mighty Hecateus Helmet",
    "name": "Hecateus Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 12,
      "LUK": 2,
      "HP": 72,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1775,
    "tierItemIds": [
      1773,
      1774,
      1775
    ],
    "setId": 45,
    "animType": null
  },
  "1775": {
    "id": 1775,
    "displayName": "Superior Hecateus Helmet",
    "name": "Hecateus Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 17,
      "LUK": 3,
      "HP": 101,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1773,
      1774,
      1775
    ],
    "setId": 45,
    "animType": null
  },
  "1776": {
    "id": 1776,
    "displayName": "Hecateus Helmet",
    "name": "Hecateus Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 12,
      "LUK": 2,
      "HP": 72,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1777,
    "tierItemIds": [
      1776,
      1777,
      1778
    ],
    "setId": 45,
    "animType": null
  },
  "1777": {
    "id": 1777,
    "displayName": "Mighty Hecateus Helmet",
    "name": "Hecateus Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 13,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 10,
      "AGI": 0,
      "INT": 17,
      "LUK": 3,
      "HP": 101,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1778,
    "tierItemIds": [
      1776,
      1777,
      1778
    ],
    "setId": 45,
    "animType": null
  },
  "1778": {
    "id": 1778,
    "displayName": "Superior Hecateus Helmet",
    "name": "Hecateus Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 19,
      "Physical Defense": 10,
      "Critical Damage": 1,
      "STR": 14,
      "AGI": 0,
      "INT": 25,
      "LUK": 5,
      "HP": 142,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1776,
      1777,
      1778
    ],
    "setId": 45,
    "animType": null
  },
  "1779": {
    "id": 1779,
    "displayName": "Hecateus Armor",
    "name": "Hecateus Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 36,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1780,
    "tierItemIds": [
      1779,
      1780,
      1781
    ],
    "setId": 45,
    "animType": null
  },
  "1780": {
    "id": 1780,
    "displayName": "Mighty Hecateus Armor",
    "name": "Hecateus Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 51,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1781,
    "tierItemIds": [
      1779,
      1780,
      1781
    ],
    "setId": 45,
    "animType": null
  },
  "1781": {
    "id": 1781,
    "displayName": "Superior Hecateus Armor",
    "name": "Hecateus Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 72,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1779,
      1780,
      1781
    ],
    "setId": 45,
    "animType": null
  },
  "1782": {
    "id": 1782,
    "displayName": "Hecateus Armor",
    "name": "Hecateus Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 57,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1783,
    "tierItemIds": [
      1782,
      1783,
      1784
    ],
    "setId": 45,
    "animType": null
  },
  "1783": {
    "id": 1783,
    "displayName": "Mighty Hecateus Armor",
    "name": "Hecateus Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1784,
    "tierItemIds": [
      1782,
      1783,
      1784
    ],
    "setId": 45,
    "animType": null
  },
  "1784": {
    "id": 1784,
    "displayName": "Superior Hecateus Armor",
    "name": "Hecateus Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 0,
      "INT": 22,
      "LUK": 0,
      "HP": 113,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1782,
      1783,
      1784
    ],
    "setId": 45,
    "animType": null
  },
  "1785": {
    "id": 1785,
    "displayName": "Hecateus Armor",
    "name": "Hecateus Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 80,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1786,
    "tierItemIds": [
      1785,
      1786,
      1787
    ],
    "setId": 45,
    "animType": null
  },
  "1786": {
    "id": 1786,
    "displayName": "Mighty Hecateus Armor",
    "name": "Hecateus Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 0,
      "INT": 22,
      "LUK": 0,
      "HP": 113,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1787,
    "tierItemIds": [
      1785,
      1786,
      1787
    ],
    "setId": 45,
    "animType": null
  },
  "1787": {
    "id": 1787,
    "displayName": "Superior Hecateus Armor",
    "name": "Hecateus Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 17,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 16,
      "AGI": 0,
      "INT": 31,
      "LUK": 0,
      "HP": 159,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1785,
      1786,
      1787
    ],
    "setId": 45,
    "animType": null
  },
  "1788": {
    "id": 1788,
    "displayName": "Hecateus Boots",
    "name": "Hecateus Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 8,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1789,
    "tierItemIds": [
      1788,
      1789,
      1790
    ],
    "setId": 45,
    "animType": null
  },
  "1789": {
    "id": 1789,
    "displayName": "Mighty Hecateus Boots",
    "name": "Hecateus Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 12,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1790,
    "tierItemIds": [
      1788,
      1789,
      1790
    ],
    "setId": 45,
    "animType": null
  },
  "1790": {
    "id": 1790,
    "displayName": "Superior Hecateus Boots",
    "name": "Hecateus Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 18,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1788,
      1789,
      1790
    ],
    "setId": 45,
    "animType": null
  },
  "1791": {
    "id": 1791,
    "displayName": "Hecateus Boots",
    "name": "Hecateus Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1792,
    "tierItemIds": [
      1791,
      1792,
      1793
    ],
    "setId": 45,
    "animType": null
  },
  "1792": {
    "id": 1792,
    "displayName": "Mighty Hecateus Boots",
    "name": "Hecateus Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1793,
    "tierItemIds": [
      1791,
      1792,
      1793
    ],
    "setId": 45,
    "animType": null
  },
  "1793": {
    "id": 1793,
    "displayName": "Superior Hecateus Boots",
    "name": "Hecateus Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1791,
      1792,
      1793
    ],
    "setId": 45,
    "animType": null
  },
  "1794": {
    "id": 1794,
    "displayName": "Hecateus Boots",
    "name": "Hecateus Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1795,
    "tierItemIds": [
      1794,
      1795,
      1796
    ],
    "setId": 45,
    "animType": null
  },
  "1795": {
    "id": 1795,
    "displayName": "Mighty Hecateus Boots",
    "name": "Hecateus Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1796,
    "tierItemIds": [
      1794,
      1795,
      1796
    ],
    "setId": 45,
    "animType": null
  },
  "1796": {
    "id": 1796,
    "displayName": "Superior Hecateus Boots",
    "name": "Hecateus Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 42,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1794,
      1795,
      1796
    ],
    "setId": 45,
    "animType": null
  },
  "1797": {
    "id": 1797,
    "displayName": "Hecateus Pants",
    "name": "Hecateus Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 8,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1798,
    "tierItemIds": [
      1797,
      1798,
      1799
    ],
    "setId": 45,
    "animType": null
  },
  "1798": {
    "id": 1798,
    "displayName": "Mighty Hecateus Pants",
    "name": "Hecateus Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 12,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1799,
    "tierItemIds": [
      1797,
      1798,
      1799
    ],
    "setId": 45,
    "animType": null
  },
  "1799": {
    "id": 1799,
    "displayName": "Superior Hecateus Pants",
    "name": "Hecateus Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 18,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1797,
      1798,
      1799
    ],
    "setId": 45,
    "animType": null
  },
  "1800": {
    "id": 1800,
    "displayName": "Hecateus Pants",
    "name": "Hecateus Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1801,
    "tierItemIds": [
      1800,
      1801,
      1802
    ],
    "setId": 45,
    "animType": null
  },
  "1801": {
    "id": 1801,
    "displayName": "Mighty Hecateus Pants",
    "name": "Hecateus Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1802,
    "tierItemIds": [
      1800,
      1801,
      1802
    ],
    "setId": 45,
    "animType": null
  },
  "1802": {
    "id": 1802,
    "displayName": "Superior Hecateus Pants",
    "name": "Hecateus Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1800,
      1801,
      1802
    ],
    "setId": 45,
    "animType": null
  },
  "1803": {
    "id": 1803,
    "displayName": "Hecateus Pants",
    "name": "Hecateus Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1804,
    "tierItemIds": [
      1803,
      1804,
      1805
    ],
    "setId": 45,
    "animType": null
  },
  "1804": {
    "id": 1804,
    "displayName": "Mighty Hecateus Pants",
    "name": "Hecateus Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1805,
    "tierItemIds": [
      1803,
      1804,
      1805
    ],
    "setId": 45,
    "animType": null
  },
  "1805": {
    "id": 1805,
    "displayName": "Superior Hecateus Pants",
    "name": "Hecateus Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 42,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1803,
      1804,
      1805
    ],
    "setId": 45,
    "animType": null
  },
  "1806": {
    "id": 1806,
    "displayName": "Galestrike Staff",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 41,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1807,
    "tierItemIds": [
      1806,
      1807,
      1808
    ],
    "setId": 46,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1807": {
    "id": 1807,
    "displayName": "Mighty Galestrike Staff",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 58,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1808,
    "tierItemIds": [
      1806,
      1807,
      1808
    ],
    "setId": 46,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1808": {
    "id": 1808,
    "displayName": "Superior Galestrike Staff",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 82,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1806,
      1807,
      1808
    ],
    "setId": 46,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1809": {
    "id": 1809,
    "displayName": "Galestrike Staff",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 58,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 2,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1810,
    "tierItemIds": [
      1809,
      1810,
      1811
    ],
    "setId": 46,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1810": {
    "id": 1810,
    "displayName": "Mighty Galestrike Staff",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 82,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1811,
    "tierItemIds": [
      1809,
      1810,
      1811
    ],
    "setId": 46,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1811": {
    "id": 1811,
    "displayName": "Superior Galestrike Staff",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 115,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1809,
      1810,
      1811
    ],
    "setId": 46,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1812": {
    "id": 1812,
    "displayName": "Galestrike Staff",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 82,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1813,
    "tierItemIds": [
      1812,
      1813,
      1814
    ],
    "setId": 46,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1813": {
    "id": 1813,
    "displayName": "Mighty Galestrike Staff",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 115,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1814,
    "tierItemIds": [
      1812,
      1813,
      1814
    ],
    "setId": 46,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1814": {
    "id": 1814,
    "displayName": "Superior Galestrike Staff",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 161,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 9,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1812,
      1813,
      1814
    ],
    "setId": 46,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1815": {
    "id": 1815,
    "displayName": "Galestrike Orb",
    "name": "Galestrike Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 6,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1816,
    "tierItemIds": [
      1815,
      1816,
      1817
    ],
    "setId": 46,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1816": {
    "id": 1816,
    "displayName": "Mighty Galestrike Orb",
    "name": "Galestrike Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 9,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1817,
    "tierItemIds": [
      1815,
      1816,
      1817
    ],
    "setId": 46,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1817": {
    "id": 1817,
    "displayName": "Superior Galestrike Orb",
    "name": "Galestrike Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 13,
      "LUK": 4,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1815,
      1816,
      1817
    ],
    "setId": 46,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1818": {
    "id": 1818,
    "displayName": "Galestrike Orb",
    "name": "Galestrike Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 9,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1819,
    "tierItemIds": [
      1818,
      1819,
      1820
    ],
    "setId": 46,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1819": {
    "id": 1819,
    "displayName": "Mighty Galestrike Orb",
    "name": "Galestrike Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 13,
      "LUK": 4,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1820,
    "tierItemIds": [
      1818,
      1819,
      1820
    ],
    "setId": 46,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1820": {
    "id": 1820,
    "displayName": "Superior Galestrike Orb",
    "name": "Galestrike Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 32,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 19,
      "LUK": 6,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1818,
      1819,
      1820
    ],
    "setId": 46,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1821": {
    "id": 1821,
    "displayName": "Galestrike Orb",
    "name": "Galestrike Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 13,
      "LUK": 4,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1822,
    "tierItemIds": [
      1821,
      1822,
      1823
    ],
    "setId": 46,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1822": {
    "id": 1822,
    "displayName": "Mighty Galestrike Orb",
    "name": "Galestrike Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 32,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 19,
      "LUK": 6,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1823,
    "tierItemIds": [
      1821,
      1822,
      1823
    ],
    "setId": 46,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1823": {
    "id": 1823,
    "displayName": "Superior Galestrike Orb",
    "name": "Galestrike Sub Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 46,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 16,
      "STR": 0,
      "AGI": 0,
      "INT": 27,
      "LUK": 9,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1821,
      1822,
      1823
    ],
    "setId": 46,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1824": {
    "id": 1824,
    "displayName": "Galestrike bow",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 53,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1825,
    "tierItemIds": [
      1824,
      1825,
      1826
    ],
    "setId": 46,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "1825": {
    "id": 1825,
    "displayName": "Mighty Galestrike bow",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 75,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1826,
    "tierItemIds": [
      1824,
      1825,
      1826
    ],
    "setId": 46,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "1826": {
    "id": 1826,
    "displayName": "Superior Galestrike bow",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 106,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1824,
      1825,
      1826
    ],
    "setId": 46,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "1827": {
    "id": 1827,
    "displayName": "Galestrike bow",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 75,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 2,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1828,
    "tierItemIds": [
      1827,
      1828,
      1829
    ],
    "setId": 46,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "1828": {
    "id": 1828,
    "displayName": "Mighty Galestrike bow",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 106,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1829,
    "tierItemIds": [
      1827,
      1828,
      1829
    ],
    "setId": 46,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "1829": {
    "id": 1829,
    "displayName": "Superior Galestrike bow",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 149,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 8,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1827,
      1828,
      1829
    ],
    "setId": 46,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "1830": {
    "id": 1830,
    "displayName": "Galestrike bow",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 106,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1831,
    "tierItemIds": [
      1830,
      1831,
      1832
    ],
    "setId": 46,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "1831": {
    "id": 1831,
    "displayName": "Mighty Galestrike bow",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 149,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 8,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1832,
    "tierItemIds": [
      1830,
      1831,
      1832
    ],
    "setId": 46,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "1832": {
    "id": 1832,
    "displayName": "Superior Galestrike bow",
    "name": "Galestrike Weapon",
    "races": [
      "Elf"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 209,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 12,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1830,
      1831,
      1832
    ],
    "setId": 46,
    "animType": "bow",
    "damageType": "Physical",
    "moveRange": 2,
    "attackRange": {
      "min": 3,
      "max": 4
    }
  },
  "1833": {
    "id": 1833,
    "displayName": "Galestrike Gloves",
    "name": "Galestrike Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1834,
    "tierItemIds": [
      1833,
      1834,
      1835
    ],
    "setId": 46,
    "animType": null
  },
  "1834": {
    "id": 1834,
    "displayName": "Mighty Galestrike Gloves",
    "name": "Galestrike Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 1,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1835,
    "tierItemIds": [
      1833,
      1834,
      1835
    ],
    "setId": 46,
    "animType": null
  },
  "1835": {
    "id": 1835,
    "displayName": "Superior Galestrike Gloves",
    "name": "Galestrike Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 4,
      "LUK": 2,
      "HP": 63,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1833,
      1834,
      1835
    ],
    "setId": 46,
    "animType": null
  },
  "1836": {
    "id": 1836,
    "displayName": "Galestrike Gloves",
    "name": "Galestrike Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 1,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1837,
    "tierItemIds": [
      1836,
      1837,
      1838
    ],
    "setId": 46,
    "animType": null
  },
  "1837": {
    "id": 1837,
    "displayName": "Mighty Galestrike Gloves",
    "name": "Galestrike Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 4,
      "LUK": 2,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1838,
    "tierItemIds": [
      1836,
      1837,
      1838
    ],
    "setId": 46,
    "animType": null
  },
  "1838": {
    "id": 1838,
    "displayName": "Superior Galestrike Gloves",
    "name": "Galestrike Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 6,
      "LUK": 3,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1836,
      1837,
      1838
    ],
    "setId": 46,
    "animType": null
  },
  "1839": {
    "id": 1839,
    "displayName": "Galestrike Gloves",
    "name": "Galestrike Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 4,
      "LUK": 2,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1840,
    "tierItemIds": [
      1839,
      1840,
      1841
    ],
    "setId": 46,
    "animType": null
  },
  "1840": {
    "id": 1840,
    "displayName": "Mighty Galestrike Gloves",
    "name": "Galestrike Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 6,
      "LUK": 3,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1841,
    "tierItemIds": [
      1839,
      1840,
      1841
    ],
    "setId": 46,
    "animType": null
  },
  "1841": {
    "id": 1841,
    "displayName": "Superior Galestrike Gloves",
    "name": "Galestrike Gloves",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 10,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 9,
      "LUK": 5,
      "HP": 138,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1839,
      1840,
      1841
    ],
    "setId": 46,
    "animType": null
  },
  "1842": {
    "id": 1842,
    "displayName": "Galestrike Helmet",
    "name": "Galestrike Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 1,
      "HP": 32,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1843,
    "tierItemIds": [
      1842,
      1843,
      1844
    ],
    "setId": 46,
    "animType": null
  },
  "1843": {
    "id": 1843,
    "displayName": "Mighty Galestrike Helmet",
    "name": "Galestrike Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 2,
      "HP": 45,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1844,
    "tierItemIds": [
      1842,
      1843,
      1844
    ],
    "setId": 46,
    "animType": null
  },
  "1844": {
    "id": 1844,
    "displayName": "Superior Galestrike Helmet",
    "name": "Galestrike Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 3,
      "HP": 63,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1842,
      1843,
      1844
    ],
    "setId": 46,
    "animType": null
  },
  "1845": {
    "id": 1845,
    "displayName": "Galestrike Helmet",
    "name": "Galestrike Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 2,
      "HP": 50,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1846,
    "tierItemIds": [
      1845,
      1846,
      1847
    ],
    "setId": 46,
    "animType": null
  },
  "1846": {
    "id": 1846,
    "displayName": "Mighty Galestrike Helmet",
    "name": "Galestrike Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 3,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1847,
    "tierItemIds": [
      1845,
      1846,
      1847
    ],
    "setId": 46,
    "animType": null
  },
  "1847": {
    "id": 1847,
    "displayName": "Superior Galestrike Helmet",
    "name": "Galestrike Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 7,
      "Critical Damage": 7,
      "STR": 1,
      "AGI": 0,
      "INT": 8,
      "LUK": 5,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1845,
      1846,
      1847
    ],
    "setId": 46,
    "animType": null
  },
  "1848": {
    "id": 1848,
    "displayName": "Galestrike Helmet",
    "name": "Galestrike Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 3,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1849,
    "tierItemIds": [
      1848,
      1849,
      1850
    ],
    "setId": 46,
    "animType": null
  },
  "1849": {
    "id": 1849,
    "displayName": "Mighty Galestrike Helmet",
    "name": "Galestrike Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 6,
      "Physical Defense": 7,
      "Critical Damage": 7,
      "STR": 1,
      "AGI": 0,
      "INT": 8,
      "LUK": 5,
      "HP": 98,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1850,
    "tierItemIds": [
      1848,
      1849,
      1850
    ],
    "setId": 46,
    "animType": null
  },
  "1850": {
    "id": 1850,
    "displayName": "Superior Galestrike Helmet",
    "name": "Galestrike Helmet",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 9,
      "Physical Defense": 10,
      "Critical Damage": 11,
      "STR": 2,
      "AGI": 0,
      "INT": 12,
      "LUK": 8,
      "HP": 138,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1848,
      1849,
      1850
    ],
    "setId": 46,
    "animType": null
  },
  "1851": {
    "id": 1851,
    "displayName": "Galestrike Armor",
    "name": "Galestrike Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1852,
    "tierItemIds": [
      1851,
      1852,
      1853
    ],
    "setId": 46,
    "animType": null
  },
  "1852": {
    "id": 1852,
    "displayName": "Mighty Galestrike Armor",
    "name": "Galestrike Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1853,
    "tierItemIds": [
      1851,
      1852,
      1853
    ],
    "setId": 46,
    "animType": null
  },
  "1853": {
    "id": 1853,
    "displayName": "Superior Galestrike Armor",
    "name": "Galestrike Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 78,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1851,
      1852,
      1853
    ],
    "setId": 46,
    "animType": null
  },
  "1854": {
    "id": 1854,
    "displayName": "Galestrike Armor",
    "name": "Galestrike Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1855,
    "tierItemIds": [
      1854,
      1855,
      1856
    ],
    "setId": 46,
    "animType": null
  },
  "1855": {
    "id": 1855,
    "displayName": "Mighty Galestrike Armor",
    "name": "Galestrike Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1856,
    "tierItemIds": [
      1854,
      1855,
      1856
    ],
    "setId": 46,
    "animType": null
  },
  "1856": {
    "id": 1856,
    "displayName": "Superior Galestrike Armor",
    "name": "Galestrike Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 123,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1854,
      1855,
      1856
    ],
    "setId": 46,
    "animType": null
  },
  "1857": {
    "id": 1857,
    "displayName": "Galestrike Armor",
    "name": "Galestrike Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1858,
    "tierItemIds": [
      1857,
      1858,
      1859
    ],
    "setId": 46,
    "animType": null
  },
  "1858": {
    "id": 1858,
    "displayName": "Mighty Galestrike Armor",
    "name": "Galestrike Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 123,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1859,
    "tierItemIds": [
      1857,
      1858,
      1859
    ],
    "setId": 46,
    "animType": null
  },
  "1859": {
    "id": 1859,
    "displayName": "Superior Galestrike Armor",
    "name": "Galestrike Armor",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 13,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 173,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1857,
      1858,
      1859
    ],
    "setId": 46,
    "animType": null
  },
  "1860": {
    "id": 1860,
    "displayName": "Galestrike Boots",
    "name": "Galestrike Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1861,
    "tierItemIds": [
      1860,
      1861,
      1862
    ],
    "setId": 46,
    "animType": null
  },
  "1861": {
    "id": 1861,
    "displayName": "Mighty Galestrike Boots",
    "name": "Galestrike Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1862,
    "tierItemIds": [
      1860,
      1861,
      1862
    ],
    "setId": 46,
    "animType": null
  },
  "1862": {
    "id": 1862,
    "displayName": "Superior Galestrike Boots",
    "name": "Galestrike Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 12,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1860,
      1861,
      1862
    ],
    "setId": 46,
    "animType": null
  },
  "1863": {
    "id": 1863,
    "displayName": "Galestrike Boots",
    "name": "Galestrike Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1864,
    "tierItemIds": [
      1863,
      1864,
      1865
    ],
    "setId": 46,
    "animType": null
  },
  "1864": {
    "id": 1864,
    "displayName": "Mighty Galestrike Boots",
    "name": "Galestrike Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 12,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1865,
    "tierItemIds": [
      1863,
      1864,
      1865
    ],
    "setId": 46,
    "animType": null
  },
  "1865": {
    "id": 1865,
    "displayName": "Superior Galestrike Boots",
    "name": "Galestrike Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 18,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1863,
      1864,
      1865
    ],
    "setId": 46,
    "animType": null
  },
  "1866": {
    "id": 1866,
    "displayName": "Galestrike Boots",
    "name": "Galestrike Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 12,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 1,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1867,
    "tierItemIds": [
      1866,
      1867,
      1868
    ],
    "setId": 46,
    "animType": null
  },
  "1867": {
    "id": 1867,
    "displayName": "Mighty Galestrike Boots",
    "name": "Galestrike Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 18,
      "INT": 0,
      "LUK": 0,
      "HP": 50,
      "Critical": 0,
      "Evade": 2,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1868,
    "tierItemIds": [
      1866,
      1867,
      1868
    ],
    "setId": 46,
    "animType": null
  },
  "1868": {
    "id": 1868,
    "displayName": "Superior Galestrike Boots",
    "name": "Galestrike Boots",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 26,
      "INT": 0,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 4,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1866,
      1867,
      1868
    ],
    "setId": 46,
    "animType": null
  },
  "1869": {
    "id": 1869,
    "displayName": "Galestrike Pants",
    "name": "Galestrike Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 15,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1870,
    "tierItemIds": [
      1869,
      1870,
      1871
    ],
    "setId": 46,
    "animType": null
  },
  "1870": {
    "id": 1870,
    "displayName": "Mighty Galestrike Pants",
    "name": "Galestrike Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 22,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1871,
    "tierItemIds": [
      1869,
      1870,
      1871
    ],
    "setId": 46,
    "animType": null
  },
  "1871": {
    "id": 1871,
    "displayName": "Superior Galestrike Pants",
    "name": "Galestrike Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 31,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1869,
      1870,
      1871
    ],
    "setId": 46,
    "animType": null
  },
  "1872": {
    "id": 1872,
    "displayName": "Galestrike Pants",
    "name": "Galestrike Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 25,
      "Critical": 0,
      "Evade": 1,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1873,
    "tierItemIds": [
      1872,
      1873,
      1874
    ],
    "setId": 46,
    "animType": null
  },
  "1873": {
    "id": 1873,
    "displayName": "Mighty Galestrike Pants",
    "name": "Galestrike Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1874,
    "tierItemIds": [
      1872,
      1873,
      1874
    ],
    "setId": 46,
    "animType": null
  },
  "1874": {
    "id": 1874,
    "displayName": "Superior Galestrike Pants",
    "name": "Galestrike Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 12,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 3,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1872,
      1873,
      1874
    ],
    "setId": 46,
    "animType": null
  },
  "1875": {
    "id": 1875,
    "displayName": "Galestrike Pants",
    "name": "Galestrike Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 35,
      "Critical": 0,
      "Evade": 2,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1876,
    "tierItemIds": [
      1875,
      1876,
      1877
    ],
    "setId": 46,
    "animType": null
  },
  "1876": {
    "id": 1876,
    "displayName": "Mighty Galestrike Pants",
    "name": "Galestrike Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 12,
      "INT": 0,
      "LUK": 0,
      "HP": 49,
      "Critical": 0,
      "Evade": 3,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1877,
    "tierItemIds": [
      1875,
      1876,
      1877
    ],
    "setId": 46,
    "animType": null
  },
  "1877": {
    "id": 1877,
    "displayName": "Superior Galestrike Pants",
    "name": "Galestrike Pants",
    "races": [
      "Elf"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 18,
      "INT": 0,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 5,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1875,
      1876,
      1877
    ],
    "setId": 46,
    "animType": null
  },
  "1878": {
    "id": 1878,
    "displayName": "Antheia Staff",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 39,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1879,
    "tierItemIds": [
      1878,
      1879,
      1880
    ],
    "setId": 47,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1879": {
    "id": 1879,
    "displayName": "Mighty Antheia Staff",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 55,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1880,
    "tierItemIds": [
      1878,
      1879,
      1880
    ],
    "setId": 47,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1880": {
    "id": 1880,
    "displayName": "Superior Antheia Staff",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 78,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1878,
      1879,
      1880
    ],
    "setId": 47,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1881": {
    "id": 1881,
    "displayName": "Antheia Staff",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 55,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1882,
    "tierItemIds": [
      1881,
      1882,
      1883
    ],
    "setId": 47,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1882": {
    "id": 1882,
    "displayName": "Mighty Antheia Staff",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 78,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1883,
    "tierItemIds": [
      1881,
      1882,
      1883
    ],
    "setId": 47,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1883": {
    "id": 1883,
    "displayName": "Superior Antheia Staff",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 110,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1881,
      1882,
      1883
    ],
    "setId": 47,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1884": {
    "id": 1884,
    "displayName": "Antheia Staff",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 78,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1885,
    "tierItemIds": [
      1884,
      1885,
      1886
    ],
    "setId": 47,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1885": {
    "id": 1885,
    "displayName": "Mighty Antheia Staff",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 110,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1886,
    "tierItemIds": [
      1884,
      1885,
      1886
    ],
    "setId": 47,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1886": {
    "id": 1886,
    "displayName": "Superior Antheia Staff",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 154,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 16,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1884,
      1885,
      1886
    ],
    "setId": 47,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1887": {
    "id": 1887,
    "displayName": "Antheia talisman",
    "name": "Antheia Sub Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 10,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1888,
    "tierItemIds": [
      1887,
      1888,
      1889
    ],
    "setId": 47,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1888": {
    "id": 1888,
    "displayName": "Mighty Antheia talisman",
    "name": "Antheia Sub Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1889,
    "tierItemIds": [
      1887,
      1888,
      1889
    ],
    "setId": 47,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1889": {
    "id": 1889,
    "displayName": "Superior Antheia talisman",
    "name": "Antheia Sub Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1887,
      1888,
      1889
    ],
    "setId": 47,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1890": {
    "id": 1890,
    "displayName": "Antheia talisman",
    "name": "Antheia Sub Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 15,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 10,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1891,
    "tierItemIds": [
      1890,
      1891,
      1892
    ],
    "setId": 47,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1891": {
    "id": 1891,
    "displayName": "Mighty Antheia talisman",
    "name": "Antheia Sub Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1892,
    "tierItemIds": [
      1890,
      1891,
      1892
    ],
    "setId": 47,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1892": {
    "id": 1892,
    "displayName": "Superior Antheia talisman",
    "name": "Antheia Sub Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 31,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 22,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1890,
      1891,
      1892
    ],
    "setId": 47,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1893": {
    "id": 1893,
    "displayName": "Antheia talisman",
    "name": "Antheia Sub Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 22,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 0,
      "AGI": 0,
      "INT": 15,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1894,
    "tierItemIds": [
      1893,
      1894,
      1895
    ],
    "setId": 47,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1894": {
    "id": 1894,
    "displayName": "Mighty Antheia talisman",
    "name": "Antheia Sub Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 31,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 22,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1895,
    "tierItemIds": [
      1893,
      1894,
      1895
    ],
    "setId": 47,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1895": {
    "id": 1895,
    "displayName": "Superior Antheia talisman",
    "name": "Antheia Sub Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 44,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 4,
      "STR": 0,
      "AGI": 0,
      "INT": 32,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1893,
      1894,
      1895
    ],
    "setId": 47,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1896": {
    "id": 1896,
    "displayName": "Antheia dagger",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 51,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1897,
    "tierItemIds": [
      1896,
      1897,
      1898
    ],
    "setId": 47,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1897": {
    "id": 1897,
    "displayName": "Mighty Antheia dagger",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 72,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1898,
    "tierItemIds": [
      1896,
      1897,
      1898
    ],
    "setId": 47,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1898": {
    "id": 1898,
    "displayName": "Superior Antheia dagger",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 101,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1896,
      1897,
      1898
    ],
    "setId": 47,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1899": {
    "id": 1899,
    "displayName": "Antheia dagger",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 72,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1900,
    "tierItemIds": [
      1899,
      1900,
      1901
    ],
    "setId": 47,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1900": {
    "id": 1900,
    "displayName": "Mighty Antheia dagger",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 101,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1901,
    "tierItemIds": [
      1899,
      1900,
      1901
    ],
    "setId": 47,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1901": {
    "id": 1901,
    "displayName": "Superior Antheia dagger",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 142,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 11,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1899,
      1900,
      1901
    ],
    "setId": 47,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1902": {
    "id": 1902,
    "displayName": "Antheia dagger",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 101,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1903,
    "tierItemIds": [
      1902,
      1903,
      1904
    ],
    "setId": 47,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1903": {
    "id": 1903,
    "displayName": "Mighty Antheia dagger",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 142,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1904,
    "tierItemIds": [
      1902,
      1903,
      1904
    ],
    "setId": 47,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1904": {
    "id": 1904,
    "displayName": "Superior Antheia dagger",
    "name": "Antheia Weapon",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 200,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 16,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1902,
      1903,
      1904
    ],
    "setId": 47,
    "animType": "knife",
    "damageType": "Physical",
    "moveRange": 4,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1905": {
    "id": 1905,
    "displayName": "Antheia Gloves",
    "name": "Antheia Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1906,
    "tierItemIds": [
      1905,
      1906,
      1907
    ],
    "setId": 47,
    "animType": null
  },
  "1906": {
    "id": 1906,
    "displayName": "Mighty Antheia Gloves",
    "name": "Antheia Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1907,
    "tierItemIds": [
      1905,
      1906,
      1907
    ],
    "setId": 47,
    "animType": null
  },
  "1907": {
    "id": 1907,
    "displayName": "Superior Antheia Gloves",
    "name": "Antheia Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1905,
      1906,
      1907
    ],
    "setId": 47,
    "animType": null
  },
  "1908": {
    "id": 1908,
    "displayName": "Antheia Gloves",
    "name": "Antheia Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1909,
    "tierItemIds": [
      1908,
      1909,
      1910
    ],
    "setId": 47,
    "animType": null
  },
  "1909": {
    "id": 1909,
    "displayName": "Mighty Antheia Gloves",
    "name": "Antheia Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 97,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1910,
    "tierItemIds": [
      1908,
      1909,
      1910
    ],
    "setId": 47,
    "animType": null
  },
  "1910": {
    "id": 1910,
    "displayName": "Superior Antheia Gloves",
    "name": "Antheia Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 137,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1908,
      1909,
      1910
    ],
    "setId": 47,
    "animType": null
  },
  "1911": {
    "id": 1911,
    "displayName": "Antheia Gloves",
    "name": "Antheia Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 97,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1912,
    "tierItemIds": [
      1911,
      1912,
      1913
    ],
    "setId": 47,
    "animType": null
  },
  "1912": {
    "id": 1912,
    "displayName": "Mighty Antheia Gloves",
    "name": "Antheia Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 9,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 137,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1913,
    "tierItemIds": [
      1911,
      1912,
      1913
    ],
    "setId": 47,
    "animType": null
  },
  "1913": {
    "id": 1913,
    "displayName": "Superior Antheia Gloves",
    "name": "Antheia Gloves",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 13,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 10,
      "LUK": 1,
      "HP": 192,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1911,
      1912,
      1913
    ],
    "setId": 47,
    "animType": null
  },
  "1914": {
    "id": 1914,
    "displayName": "Antheia Helmet",
    "name": "Antheia Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1915,
    "tierItemIds": [
      1914,
      1915,
      1916
    ],
    "setId": 47,
    "animType": null
  },
  "1915": {
    "id": 1915,
    "displayName": "Mighty Antheia Helmet",
    "name": "Antheia Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1916,
    "tierItemIds": [
      1914,
      1915,
      1916
    ],
    "setId": 47,
    "animType": null
  },
  "1916": {
    "id": 1916,
    "displayName": "Superior Antheia Helmet",
    "name": "Antheia Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1914,
      1915,
      1916
    ],
    "setId": 47,
    "animType": null
  },
  "1917": {
    "id": 1917,
    "displayName": "Antheia Helmet",
    "name": "Antheia Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 8,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 69,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1918,
    "tierItemIds": [
      1917,
      1918,
      1919
    ],
    "setId": 47,
    "animType": null
  },
  "1918": {
    "id": 1918,
    "displayName": "Mighty Antheia Helmet",
    "name": "Antheia Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 97,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1919,
    "tierItemIds": [
      1917,
      1918,
      1919
    ],
    "setId": 47,
    "animType": null
  },
  "1919": {
    "id": 1919,
    "displayName": "Superior Antheia Helmet",
    "name": "Antheia Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 17,
      "Physical Defense": 9,
      "Critical Damage": 1,
      "STR": 4,
      "AGI": 0,
      "INT": 10,
      "LUK": 1,
      "HP": 137,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1917,
      1918,
      1919
    ],
    "setId": 47,
    "animType": null
  },
  "1920": {
    "id": 1920,
    "displayName": "Antheia Helmet",
    "name": "Antheia Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 12,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 7,
      "LUK": 0,
      "HP": 97,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1921,
    "tierItemIds": [
      1920,
      1921,
      1922
    ],
    "setId": 47,
    "animType": null
  },
  "1921": {
    "id": 1921,
    "displayName": "Mighty Antheia Helmet",
    "name": "Antheia Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 17,
      "Physical Defense": 9,
      "Critical Damage": 1,
      "STR": 4,
      "AGI": 0,
      "INT": 10,
      "LUK": 1,
      "HP": 137,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1922,
    "tierItemIds": [
      1920,
      1921,
      1922
    ],
    "setId": 47,
    "animType": null
  },
  "1922": {
    "id": 1922,
    "displayName": "Superior Antheia Helmet",
    "name": "Antheia Helmet",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 25,
      "Physical Defense": 13,
      "Critical Damage": 2,
      "STR": 6,
      "AGI": 0,
      "INT": 14,
      "LUK": 2,
      "HP": 192,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1920,
      1921,
      1922
    ],
    "setId": 47,
    "animType": null
  },
  "1923": {
    "id": 1923,
    "displayName": "Antheia Armor",
    "name": "Antheia Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1924,
    "tierItemIds": [
      1923,
      1924,
      1925
    ],
    "setId": 47,
    "animType": null
  },
  "1924": {
    "id": 1924,
    "displayName": "Mighty Antheia Armor",
    "name": "Antheia Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 77,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1925,
    "tierItemIds": [
      1923,
      1924,
      1925
    ],
    "setId": 47,
    "animType": null
  },
  "1925": {
    "id": 1925,
    "displayName": "Superior Antheia Armor",
    "name": "Antheia Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 109,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1923,
      1924,
      1925
    ],
    "setId": 47,
    "animType": null
  },
  "1926": {
    "id": 1926,
    "displayName": "Antheia Armor",
    "name": "Antheia Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 5,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1927,
    "tierItemIds": [
      1926,
      1927,
      1928
    ],
    "setId": 47,
    "animType": null
  },
  "1927": {
    "id": 1927,
    "displayName": "Mighty Antheia Armor",
    "name": "Antheia Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 122,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1928,
    "tierItemIds": [
      1926,
      1927,
      1928
    ],
    "setId": 47,
    "animType": null
  },
  "1928": {
    "id": 1928,
    "displayName": "Superior Antheia Armor",
    "name": "Antheia Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 171,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1926,
      1927,
      1928
    ],
    "setId": 47,
    "animType": null
  },
  "1929": {
    "id": 1929,
    "displayName": "Antheia Armor",
    "name": "Antheia Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 7,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 122,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1930,
    "tierItemIds": [
      1929,
      1930,
      1931
    ],
    "setId": 47,
    "animType": null
  },
  "1930": {
    "id": 1930,
    "displayName": "Mighty Antheia Armor",
    "name": "Antheia Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 11,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 171,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1931,
    "tierItemIds": [
      1929,
      1930,
      1931
    ],
    "setId": 47,
    "animType": null
  },
  "1931": {
    "id": 1931,
    "displayName": "Superior Antheia Armor",
    "name": "Antheia Armor",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 15,
      "Physical Defense": 16,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 12,
      "LUK": 0,
      "HP": 240,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1929,
      1930,
      1931
    ],
    "setId": 47,
    "animType": null
  },
  "1932": {
    "id": 1932,
    "displayName": "Antheia Boots",
    "name": "Antheia Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1933,
    "tierItemIds": [
      1932,
      1933,
      1934
    ],
    "setId": 47,
    "animType": null
  },
  "1933": {
    "id": 1933,
    "displayName": "Mighty Antheia Boots",
    "name": "Antheia Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1934,
    "tierItemIds": [
      1932,
      1933,
      1934
    ],
    "setId": 47,
    "animType": null
  },
  "1934": {
    "id": 1934,
    "displayName": "Superior Antheia Boots",
    "name": "Antheia Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 43,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1932,
      1933,
      1934
    ],
    "setId": 47,
    "animType": null
  },
  "1935": {
    "id": 1935,
    "displayName": "Antheia Boots",
    "name": "Antheia Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 34,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1936,
    "tierItemIds": [
      1935,
      1936,
      1937
    ],
    "setId": 47,
    "animType": null
  },
  "1936": {
    "id": 1936,
    "displayName": "Mighty Antheia Boots",
    "name": "Antheia Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 48,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1937,
    "tierItemIds": [
      1935,
      1936,
      1937
    ],
    "setId": 47,
    "animType": null
  },
  "1937": {
    "id": 1937,
    "displayName": "Superior Antheia Boots",
    "name": "Antheia Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 68,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1935,
      1936,
      1937
    ],
    "setId": 47,
    "animType": null
  },
  "1938": {
    "id": 1938,
    "displayName": "Antheia Boots",
    "name": "Antheia Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 48,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1939,
    "tierItemIds": [
      1938,
      1939,
      1940
    ],
    "setId": 47,
    "animType": null
  },
  "1939": {
    "id": 1939,
    "displayName": "Mighty Antheia Boots",
    "name": "Antheia Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 7,
      "INT": 0,
      "LUK": 0,
      "HP": 68,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1940,
    "tierItemIds": [
      1938,
      1939,
      1940
    ],
    "setId": 47,
    "animType": null
  },
  "1940": {
    "id": 1940,
    "displayName": "Superior Antheia Boots",
    "name": "Antheia Boots",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 11,
      "INT": 0,
      "LUK": 0,
      "HP": 96,
      "Critical": 0,
      "Evade": 1,
      "Speed": 5,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1938,
      1939,
      1940
    ],
    "setId": 47,
    "animType": null
  },
  "1941": {
    "id": 1941,
    "displayName": "Antheia Pants",
    "name": "Antheia Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 21,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1942,
    "tierItemIds": [
      1941,
      1942,
      1943
    ],
    "setId": 47,
    "animType": null
  },
  "1942": {
    "id": 1942,
    "displayName": "Mighty Antheia Pants",
    "name": "Antheia Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 30,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1943,
    "tierItemIds": [
      1941,
      1942,
      1943
    ],
    "setId": 47,
    "animType": null
  },
  "1943": {
    "id": 1943,
    "displayName": "Superior Antheia Pants",
    "name": "Antheia Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 43,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1941,
      1942,
      1943
    ],
    "setId": 47,
    "animType": null
  },
  "1944": {
    "id": 1944,
    "displayName": "Antheia Pants",
    "name": "Antheia Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 34,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1945,
    "tierItemIds": [
      1944,
      1945,
      1946
    ],
    "setId": 47,
    "animType": null
  },
  "1945": {
    "id": 1945,
    "displayName": "Mighty Antheia Pants",
    "name": "Antheia Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 48,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1946,
    "tierItemIds": [
      1944,
      1945,
      1946
    ],
    "setId": 47,
    "animType": null
  },
  "1946": {
    "id": 1946,
    "displayName": "Superior Antheia Pants",
    "name": "Antheia Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 68,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1944,
      1945,
      1946
    ],
    "setId": 47,
    "animType": null
  },
  "1947": {
    "id": 1947,
    "displayName": "Antheia Pants",
    "name": "Antheia Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 48,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1948,
    "tierItemIds": [
      1947,
      1948,
      1949
    ],
    "setId": 47,
    "animType": null
  },
  "1948": {
    "id": 1948,
    "displayName": "Mighty Antheia Pants",
    "name": "Antheia Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 68,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1949,
    "tierItemIds": [
      1947,
      1948,
      1949
    ],
    "setId": 47,
    "animType": null
  },
  "1949": {
    "id": 1949,
    "displayName": "Superior Antheia Pants",
    "name": "Antheia Pants",
    "races": [
      "Fairy"
    ],
    "genders": [
      "Female"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 10,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 8,
      "INT": 0,
      "LUK": 0,
      "HP": 96,
      "Critical": 0,
      "Evade": 0,
      "Speed": 3,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1947,
      1948,
      1949
    ],
    "setId": 47,
    "animType": null
  },
  "1950": {
    "id": 1950,
    "displayName": "Astarot Staff",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 42,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1951,
    "tierItemIds": [
      1950,
      1951,
      1952
    ],
    "setId": 48,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1951": {
    "id": 1951,
    "displayName": "Mighty Astarot Staff",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 59,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1952,
    "tierItemIds": [
      1950,
      1951,
      1952
    ],
    "setId": 48,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1952": {
    "id": 1952,
    "displayName": "Superior Astarot Staff",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 83,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1950,
      1951,
      1952
    ],
    "setId": 48,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1953": {
    "id": 1953,
    "displayName": "Astarot Staff",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 59,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 2,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1954,
    "tierItemIds": [
      1953,
      1954,
      1955
    ],
    "setId": 48,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1954": {
    "id": 1954,
    "displayName": "Mighty Astarot Staff",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 83,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1955,
    "tierItemIds": [
      1953,
      1954,
      1955
    ],
    "setId": 48,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1955": {
    "id": 1955,
    "displayName": "Superior Astarot Staff",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 117,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1953,
      1954,
      1955
    ],
    "setId": 48,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1956": {
    "id": 1956,
    "displayName": "Astarot Staff",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 83,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1957,
    "tierItemIds": [
      1956,
      1957,
      1958
    ],
    "setId": 48,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1957": {
    "id": 1957,
    "displayName": "Mighty Astarot Staff",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 117,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 4,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1958,
    "tierItemIds": [
      1956,
      1957,
      1958
    ],
    "setId": 48,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1958": {
    "id": 1958,
    "displayName": "Superior Astarot Staff",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 165,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 6,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1956,
      1957,
      1958
    ],
    "setId": 48,
    "animType": "staff",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1959": {
    "id": 1959,
    "displayName": "Astarot Orb",
    "name": "Astarot Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 11,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 0,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1960,
    "tierItemIds": [
      1959,
      1960,
      1961
    ],
    "setId": 48,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1960": {
    "id": 1960,
    "displayName": "Mighty Astarot Orb",
    "name": "Astarot Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 16,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1961,
    "tierItemIds": [
      1959,
      1960,
      1961
    ],
    "setId": 48,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1961": {
    "id": 1961,
    "displayName": "Superior Astarot Orb",
    "name": "Astarot Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 2,
    "type": 1,
    "minted": 0,
    "requiredLevel": 44,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 23,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 11,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1959,
      1960,
      1961
    ],
    "setId": 48,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1962": {
    "id": 1962,
    "displayName": "Astarot Orb",
    "name": "Astarot Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 16,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 3,
      "STR": 0,
      "AGI": 0,
      "INT": 7,
      "LUK": 1,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1963,
    "tierItemIds": [
      1962,
      1963,
      1964
    ],
    "setId": 48,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1963": {
    "id": 1963,
    "displayName": "Mighty Astarot Orb",
    "name": "Astarot Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 23,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 11,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1964,
    "tierItemIds": [
      1962,
      1963,
      1964
    ],
    "setId": 48,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1964": {
    "id": 1964,
    "displayName": "Superior Astarot Orb",
    "name": "Astarot Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 1,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 33,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 16,
      "LUK": 3,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1962,
      1963,
      1964
    ],
    "setId": 48,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1965": {
    "id": 1965,
    "displayName": "Astarot Orb",
    "name": "Astarot Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 23,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 5,
      "STR": 0,
      "AGI": 0,
      "INT": 11,
      "LUK": 2,
      "HP": 0,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1966,
    "tierItemIds": [
      1965,
      1966,
      1967
    ],
    "setId": 48,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1966": {
    "id": 1966,
    "displayName": "Mighty Astarot Orb",
    "name": "Astarot Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 33,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 7,
      "STR": 0,
      "AGI": 0,
      "INT": 16,
      "LUK": 3,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1967,
    "tierItemIds": [
      1965,
      1966,
      1967
    ],
    "setId": 48,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1967": {
    "id": 1967,
    "displayName": "Superior Astarot Orb",
    "name": "Astarot Sub Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 1,
    "minted": 0,
    "requiredLevel": 52,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 47,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 11,
      "STR": 0,
      "AGI": 0,
      "INT": 23,
      "LUK": 5,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1965,
      1966,
      1967
    ],
    "setId": 48,
    "animType": "orb",
    "damageType": "Magical",
    "moveRange": 2,
    "attackRange": {
      "min": 2,
      "max": 3
    }
  },
  "1968": {
    "id": 1968,
    "displayName": "Astarot axe",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 55,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1969,
    "tierItemIds": [
      1968,
      1969,
      1970
    ],
    "setId": 48,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1969": {
    "id": 1969,
    "displayName": "Mighty Astarot axe",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 77,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1970,
    "tierItemIds": [
      1968,
      1969,
      1970
    ],
    "setId": 48,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1970": {
    "id": 1970,
    "displayName": "Superior Astarot axe",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 3,
    "type": 0,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 108,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1968,
      1969,
      1970
    ],
    "setId": 48,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1971": {
    "id": 1971,
    "displayName": "Astarot axe",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 77,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 2,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 2,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1972,
    "tierItemIds": [
      1971,
      1972,
      1973
    ],
    "setId": 48,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1972": {
    "id": 1972,
    "displayName": "Mighty Astarot axe",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 108,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 5,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1973,
    "tierItemIds": [
      1971,
      1972,
      1973
    ],
    "setId": 48,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1973": {
    "id": 1973,
    "displayName": "Superior Astarot axe",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 4,
    "type": 0,
    "minted": 0,
    "requiredLevel": 54,
    "requiredStats": [
      20,
      20,
      20
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 152,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 8,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1971,
      1972,
      1973
    ],
    "setId": 48,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1974": {
    "id": 1974,
    "displayName": "Astarot axe",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 108,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 3,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 7,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1975,
    "tierItemIds": [
      1974,
      1975,
      1976
    ],
    "setId": 48,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1975": {
    "id": 1975,
    "displayName": "Mighty Astarot axe",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 152,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 5,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 10,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 3,
    "nextTierId": 1976,
    "tierItemIds": [
      1974,
      1975,
      1976
    ],
    "setId": 48,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1976": {
    "id": 1976,
    "displayName": "Superior Astarot axe",
    "name": "Astarot Weapon",
    "races": [
      "Demon"
    ],
    "genders": [
      "Female",
      "Male"
    ],
    "rarity": 5,
    "type": 0,
    "minted": 0,
    "requiredLevel": 57,
    "requiredStats": [
      25,
      25,
      25
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 214,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 0,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 0,
      "Critical": 8,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 13,
      "effects": []
    },
    "twoHanded": true,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1974,
      1975,
      1976
    ],
    "setId": 48,
    "animType": "axe",
    "damageType": "Physical",
    "moveRange": 3,
    "attackRange": {
      "min": 1,
      "max": 1
    }
  },
  "1977": {
    "id": 1977,
    "displayName": "Astarot Gloves",
    "name": "Astarot Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 56,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1978,
    "tierItemIds": [
      1977,
      1978,
      1979
    ],
    "setId": 48,
    "animType": null
  },
  "1978": {
    "id": 1978,
    "displayName": "Mighty Astarot Gloves",
    "name": "Astarot Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 79,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1979,
    "tierItemIds": [
      1977,
      1978,
      1979
    ],
    "setId": 48,
    "animType": null
  },
  "1979": {
    "id": 1979,
    "displayName": "Superior Astarot Gloves",
    "name": "Astarot Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 6,
    "minted": 0,
    "requiredLevel": 37,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 111,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1977,
      1978,
      1979
    ],
    "setId": 48,
    "animType": null
  },
  "1980": {
    "id": 1980,
    "displayName": "Astarot Gloves",
    "name": "Astarot Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 88,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1981,
    "tierItemIds": [
      1980,
      1981,
      1982
    ],
    "setId": 48,
    "animType": null
  },
  "1981": {
    "id": 1981,
    "displayName": "Mighty Astarot Gloves",
    "name": "Astarot Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 124,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1982,
    "tierItemIds": [
      1980,
      1981,
      1982
    ],
    "setId": 48,
    "animType": null
  },
  "1982": {
    "id": 1982,
    "displayName": "Superior Astarot Gloves",
    "name": "Astarot Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 6,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 174,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1980,
      1981,
      1982
    ],
    "setId": 48,
    "animType": null
  },
  "1983": {
    "id": 1983,
    "displayName": "Astarot Gloves",
    "name": "Astarot Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 8,
      "AGI": 0,
      "INT": 3,
      "LUK": 1,
      "HP": 124,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1984,
    "tierItemIds": [
      1983,
      1984,
      1985
    ],
    "setId": 48,
    "animType": null
  },
  "1984": {
    "id": 1984,
    "displayName": "Mighty Astarot Gloves",
    "name": "Astarot Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 12,
      "AGI": 0,
      "INT": 5,
      "LUK": 2,
      "HP": 174,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1985,
    "tierItemIds": [
      1983,
      1984,
      1985
    ],
    "setId": 48,
    "animType": null
  },
  "1985": {
    "id": 1985,
    "displayName": "Superior Astarot Gloves",
    "name": "Astarot Gloves",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 6,
    "minted": 0,
    "requiredLevel": 47,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 17,
      "AGI": 0,
      "INT": 8,
      "LUK": 3,
      "HP": 244,
      "Critical": 1,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1983,
      1984,
      1985
    ],
    "setId": 48,
    "animType": null
  },
  "1986": {
    "id": 1986,
    "displayName": "Astarot Helmet",
    "name": "Astarot Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 1,
      "STR": 5,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 56,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1987,
    "tierItemIds": [
      1986,
      1987,
      1988
    ],
    "setId": 48,
    "animType": null
  },
  "1987": {
    "id": 1987,
    "displayName": "Mighty Astarot Helmet",
    "name": "Astarot Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 79,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1988,
    "tierItemIds": [
      1986,
      1987,
      1988
    ],
    "setId": 48,
    "animType": null
  },
  "1988": {
    "id": 1988,
    "displayName": "Superior Astarot Helmet",
    "name": "Astarot Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 2,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 3,
      "STR": 10,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 111,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1986,
      1987,
      1988
    ],
    "setId": 48,
    "animType": null
  },
  "1989": {
    "id": 1989,
    "displayName": "Astarot Helmet",
    "name": "Astarot Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 0,
      "Critical Damage": 2,
      "STR": 7,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 88,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1990,
    "tierItemIds": [
      1989,
      1990,
      1991
    ],
    "setId": 48,
    "animType": null
  },
  "1990": {
    "id": 1990,
    "displayName": "Mighty Astarot Helmet",
    "name": "Astarot Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 3,
      "STR": 10,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 124,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1991,
    "tierItemIds": [
      1989,
      1990,
      1991
    ],
    "setId": 48,
    "animType": null
  },
  "1991": {
    "id": 1991,
    "displayName": "Superior Astarot Helmet",
    "name": "Astarot Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 2,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 5,
      "STR": 14,
      "AGI": 0,
      "INT": 7,
      "LUK": 2,
      "HP": 174,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1989,
      1990,
      1991
    ],
    "setId": 48,
    "animType": null
  },
  "1992": {
    "id": 1992,
    "displayName": "Astarot Helmet",
    "name": "Astarot Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 1,
      "Critical Damage": 3,
      "STR": 10,
      "AGI": 0,
      "INT": 5,
      "LUK": 1,
      "HP": 124,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1993,
    "tierItemIds": [
      1992,
      1993,
      1994
    ],
    "setId": 48,
    "animType": null
  },
  "1993": {
    "id": 1993,
    "displayName": "Mighty Astarot Helmet",
    "name": "Astarot Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 5,
      "Physical Defense": 2,
      "Critical Damage": 5,
      "STR": 14,
      "AGI": 0,
      "INT": 7,
      "LUK": 2,
      "HP": 174,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1994,
    "tierItemIds": [
      1992,
      1993,
      1994
    ],
    "setId": 48,
    "animType": null
  },
  "1994": {
    "id": 1994,
    "displayName": "Superior Astarot Helmet",
    "name": "Astarot Helmet",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 2,
    "minted": 0,
    "requiredLevel": 48,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 7,
      "Physical Defense": 4,
      "Critical Damage": 8,
      "STR": 20,
      "AGI": 0,
      "INT": 10,
      "LUK": 4,
      "HP": 244,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1992,
      1993,
      1994
    ],
    "setId": 48,
    "animType": null
  },
  "1995": {
    "id": 1995,
    "displayName": "Astarot Armor",
    "name": "Astarot Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 0,
      "INT": 1,
      "LUK": 0,
      "HP": 70,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1996,
    "tierItemIds": [
      1995,
      1996,
      1997
    ],
    "setId": 48,
    "animType": null
  },
  "1996": {
    "id": 1996,
    "displayName": "Mighty Astarot Armor",
    "name": "Astarot Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 99,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1997,
    "tierItemIds": [
      1995,
      1996,
      1997
    ],
    "setId": 48,
    "animType": null
  },
  "1997": {
    "id": 1997,
    "displayName": "Superior Astarot Armor",
    "name": "Astarot Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 3,
    "minted": 0,
    "requiredLevel": 39,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 139,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1995,
      1996,
      1997
    ],
    "setId": 48,
    "animType": null
  },
  "1998": {
    "id": 1998,
    "displayName": "Astarot Armor",
    "name": "Astarot Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 0,
      "INT": 2,
      "LUK": 0,
      "HP": 110,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 1999,
    "tierItemIds": [
      1998,
      1999,
      2000
    ],
    "setId": 48,
    "animType": null
  },
  "1999": {
    "id": 1999,
    "displayName": "Mighty Astarot Armor",
    "name": "Astarot Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 155,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2000,
    "tierItemIds": [
      1998,
      1999,
      2000
    ],
    "setId": 48,
    "animType": null
  },
  "2000": {
    "id": 2000,
    "displayName": "Superior Astarot Armor",
    "name": "Astarot Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 3,
    "minted": 0,
    "requiredLevel": 42,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 16,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 217,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      1998,
      1999,
      2000
    ],
    "setId": 48,
    "animType": null
  },
  "2001": {
    "id": 2001,
    "displayName": "Astarot Armor",
    "name": "Astarot Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 0,
      "INT": 3,
      "LUK": 0,
      "HP": 155,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2002,
    "tierItemIds": [
      2001,
      2002,
      2003
    ],
    "setId": 48,
    "animType": null
  },
  "2002": {
    "id": 2002,
    "displayName": "Mighty Astarot Armor",
    "name": "Astarot Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 4,
      "Critical Damage": 0,
      "STR": 16,
      "AGI": 0,
      "INT": 5,
      "LUK": 0,
      "HP": 217,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2003,
    "tierItemIds": [
      2001,
      2002,
      2003
    ],
    "setId": 48,
    "animType": null
  },
  "2003": {
    "id": 2003,
    "displayName": "Superior Astarot Armor",
    "name": "Astarot Armor",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 3,
    "type": 3,
    "minted": 0,
    "requiredLevel": 49,
    "requiredStats": [
      15,
      15,
      15
    ],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 4,
      "Physical Defense": 6,
      "Critical Damage": 0,
      "STR": 23,
      "AGI": 0,
      "INT": 8,
      "LUK": 0,
      "HP": 305,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      2001,
      2002,
      2003
    ],
    "setId": 48,
    "animType": null
  },
  "2004": {
    "id": 2004,
    "displayName": "Astarot Boots",
    "name": "Astarot Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 1,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2005,
    "tierItemIds": [
      2004,
      2005,
      2006
    ],
    "setId": 48,
    "animType": null
  },
  "2005": {
    "id": 2005,
    "displayName": "Mighty Astarot Boots",
    "name": "Astarot Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2006,
    "tierItemIds": [
      2004,
      2005,
      2006
    ],
    "setId": 48,
    "animType": null
  },
  "2006": {
    "id": 2006,
    "displayName": "Superior Astarot Boots",
    "name": "Astarot Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 4,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      2004,
      2005,
      2006
    ],
    "setId": 48,
    "animType": null
  },
  "2007": {
    "id": 2007,
    "displayName": "Astarot Boots",
    "name": "Astarot Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2008,
    "tierItemIds": [
      2007,
      2008,
      2009
    ],
    "setId": 48,
    "animType": null
  },
  "2008": {
    "id": 2008,
    "displayName": "Mighty Astarot Boots",
    "name": "Astarot Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2009,
    "tierItemIds": [
      2007,
      2008,
      2009
    ],
    "setId": 48,
    "animType": null
  },
  "2009": {
    "id": 2009,
    "displayName": "Superior Astarot Boots",
    "name": "Astarot Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 4,
    "minted": 0,
    "requiredLevel": 35,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      2007,
      2008,
      2009
    ],
    "setId": 48,
    "animType": null
  },
  "2010": {
    "id": 2010,
    "displayName": "Astarot Boots",
    "name": "Astarot Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2011,
    "tierItemIds": [
      2010,
      2011,
      2012
    ],
    "setId": 48,
    "animType": null
  },
  "2011": {
    "id": 2011,
    "displayName": "Mighty Astarot Boots",
    "name": "Astarot Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2012,
    "tierItemIds": [
      2010,
      2011,
      2012
    ],
    "setId": 48,
    "animType": null
  },
  "2012": {
    "id": 2012,
    "displayName": "Superior Astarot Boots",
    "name": "Astarot Boots",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 4,
    "minted": 0,
    "requiredLevel": 40,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 3,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 5,
      "INT": 0,
      "LUK": 0,
      "HP": 122,
      "Critical": 0,
      "Evade": 0,
      "Speed": 4,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      2010,
      2011,
      2012
    ],
    "setId": 48,
    "animType": null
  },
  "2013": {
    "id": 2013,
    "displayName": "Astarot Pants",
    "name": "Astarot Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 2,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 27,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2014,
    "tierItemIds": [
      2013,
      2014,
      2015
    ],
    "setId": 48,
    "animType": null
  },
  "2014": {
    "id": 2014,
    "displayName": "Mighty Astarot Pants",
    "name": "Astarot Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 39,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2015,
    "tierItemIds": [
      2013,
      2014,
      2015
    ],
    "setId": 48,
    "animType": null
  },
  "2015": {
    "id": 2015,
    "displayName": "Superior Astarot Pants",
    "name": "Astarot Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 0,
    "type": 5,
    "minted": 0,
    "requiredLevel": 30,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 55,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      2013,
      2014,
      2015
    ],
    "setId": 48,
    "animType": null
  },
  "2016": {
    "id": 2016,
    "displayName": "Astarot Pants",
    "name": "Astarot Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 0,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 3,
      "AGI": 0,
      "INT": 0,
      "LUK": 0,
      "HP": 44,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2017,
    "tierItemIds": [
      2016,
      2017,
      2018
    ],
    "setId": 48,
    "animType": null
  },
  "2017": {
    "id": 2017,
    "displayName": "Mighty Astarot Pants",
    "name": "Astarot Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2018,
    "tierItemIds": [
      2016,
      2017,
      2018
    ],
    "setId": 48,
    "animType": null
  },
  "2018": {
    "id": 2018,
    "displayName": "Superior Astarot Pants",
    "name": "Astarot Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 1,
    "type": 5,
    "minted": 0,
    "requiredLevel": 38,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      2016,
      2017,
      2018
    ],
    "setId": 48,
    "animType": null
  },
  "2019": {
    "id": 2019,
    "displayName": "Astarot Pants",
    "name": "Astarot Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 1,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 1,
      "Physical Defense": 0,
      "Critical Damage": 0,
      "STR": 5,
      "AGI": 1,
      "INT": 0,
      "LUK": 0,
      "HP": 62,
      "Critical": 0,
      "Evade": 0,
      "Speed": 0,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2020,
    "tierItemIds": [
      2019,
      2020,
      2021
    ],
    "setId": 48,
    "animType": null
  },
  "2020": {
    "id": 2020,
    "displayName": "Mighty Astarot Pants",
    "name": "Astarot Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 2,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 2,
      "Physical Defense": 1,
      "Critical Damage": 0,
      "STR": 7,
      "AGI": 2,
      "INT": 0,
      "LUK": 0,
      "HP": 87,
      "Critical": 0,
      "Evade": 0,
      "Speed": 1,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 3,
    "nextTierId": 2021,
    "tierItemIds": [
      2019,
      2020,
      2021
    ],
    "setId": 48,
    "animType": null
  },
  "2021": {
    "id": 2021,
    "displayName": "Superior Astarot Pants",
    "name": "Astarot Pants",
    "races": [
      "Demon"
    ],
    "genders": [
      "Male"
    ],
    "rarity": 2,
    "type": 5,
    "minted": 0,
    "requiredLevel": 43,
    "requiredStats": [],
    "maxSupply": 0,
    "tier": 3,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 0,
      "Magical Defense": 3,
      "Physical Defense": 2,
      "Critical Damage": 0,
      "STR": 11,
      "AGI": 3,
      "INT": 0,
      "LUK": 0,
      "HP": 122,
      "Critical": 0,
      "Evade": 0,
      "Speed": 2,
      "Accuracy": 0,
      "effects": []
    },
    "twoHanded": false,
    "upgradeAmount": 0,
    "nextTierId": null,
    "tierItemIds": [
      2019,
      2020,
      2021
    ],
    "setId": 48,
    "animType": null
  }
}