import React from "react";
import { Container, Grid, Box } from "@material-ui/core";
import backers from "src/app/components/Home/BackedBy/Backers";

export default function BackedBy() {
  function getBackerImgClass(name: string) {
    switch (name) {
      case "morningstar":
        return "backer--morningstar";
      case "av start":
        return "backer--av";
      case "x21":
        return "backer--x21";
      case "590 Venture":
        return "backer--venture590";
      case "eee":
        return "backer--eee";
      default:
        return "backer";
    }
  }

  return (
    <div className="backers">
      <Container maxWidth="md">
        <div className="backers__title">
          BACKED BY
        </div>
        <Box mt={6}>
          <Grid container spacing={2} className="backers__grid">
            {backers.map((backer: any, index: number) => (
              <Grid className="backers__grid-item" item xs={6} sm={2} md={2} key={index}>
                <img
                  alt={backer.name}
                  src={backer.img}
                  className={getBackerImgClass(backer.name)}
                />
              </Grid>
            ))}
            <Grid item xs={6} sm={2} md={2} />
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
