import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  createStyles,
  TableCell,
  Theme,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GachaHistory } from "src/app/types/gacha";
import { formatNumber, timeAgo } from "src/app/utils/helpers";
import { renderWalletAddress } from "src/app/utils/renderHelpers";
import jackpotImage from "src/assets/images/equipment-gacha/jackpot.png";
import faraLogo from "src/assets/images/tokens/fara.png";

interface HistoryTableProps {
  histories: GachaHistory[];
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#111121",
      color: theme.palette.common.white,
      fontFamily: `"American Captain", sans-serif`,
      textTransform: "uppercase",
      fontWeight: 500,
      fontSize: 18,
      textAlign: "center",
    },
    body: {
      fontSize: 14,
      textAlign: "center",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export default function JackpotHistoryTable(props: HistoryTableProps) {
  const { histories } = props;
  const [jackpot, setJackpot] = useState<GachaHistory>();

  useEffect(() => {
    setJackpot(histories.find((h) => h.jackpot));
  }, [histories]);

  return (
    <div className="axe__history">
      <img src={jackpotImage} style={{ width: 146 }} className="mb-3" alt="" />
      {jackpot && jackpot.jackpot && (
        <div className="axe__history-winner">
          <span>
            <b className="capitalize">{moment(jackpot.timestamp).fromNow(true)}</b>
          </span>
          <span>
            {" "}
            ago since <b>{renderWalletAddress(jackpot.jackpot.account, jackpot.jackpot.spaceDomain, 4)}</b> claimed all
            rewards from Senora Chest.
          </span>
        </div>
      )}
      {histories.length !== 0 ? (
        <div className="cm-table">
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Wallet</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Time</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {histories.map((history: GachaHistory, idx) => (
                  <StyledTableRow key={idx}>
                    <StyledTableCell>{renderWalletAddress(history.jackpot.account, history.jackpot.spaceDomain, 4)}</StyledTableCell>
                    <StyledTableCell>
                      <span>{formatNumber(history.jackpot.amount)} FARA</span>
                      <img className="token token--small top-3 ml-1" src={faraLogo} alt="" />
                    </StyledTableCell>
                    <StyledTableCell>{timeAgo(history.timestamp)}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div>
          <b>No winner so far</b>
        </div>
      )}
    </div>
  );
}
