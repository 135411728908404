import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MetamaskService from "src/app/services/accounts/MetamaskService";
import { clearAccount, importAccount } from "src/app/actions/accountAction";
import { WALLET_TYPE } from "src/app/configs/constants";
import { checkIsCoin98, getWalletParams } from "src/app/utils/helpers";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { setGlobalModal } from "src/app/actions/globalAction";

export default function MetamaskAccount() {
  const dispatch = useDispatch();
  const { web3Service } = useSelector((state: any) => state.global);

  async function connect() {
    if (checkIsCoin98()) {
      openConnectErrorModal("Coin98 extension is enabled, you cannot use both Metamask and Coin98 at once.");
      return;
    }

    dispatch(setGlobalModal("loading", { active: true }));

    const props = getWalletParams();
    const wallet = new MetamaskService(props);
    const address = await wallet.connect(openConnectErrorModal);

    if (address) {
      const spaceDomain = await web3Service?.sid.getName(address);
      // await signAndAuthenticateWallet(wallet, address);
      wallet.getDisconnected(
        () => {
          dispatch(clearAccount());
        },
        (address: string, wallet: any, walletType: string, domainName: string) => {
          dispatch(importAccount(address, wallet, walletType, domainName));
        },
        wallet
      );
      dispatch(importAccount(address, wallet, WALLET_TYPE.METAMASK, spaceDomain.name ?? ""));
      modalService.close();
    }

    dispatch(setGlobalModal("loading"));
  }

  function openConnectErrorModal(message?: string) {
    dispatch(
      setGlobalModal("error", {
        active: true,
        data: (
          <div>{message ? message : "Cannot connect to Metamask. Please make sure you have Metamask installed."}</div>
        ),
      })
    );
  }

  return (
    <div className="account__item" onClick={connect}>
      <div className="account__icon metamask" />
      <div className="account__name">Metamask</div>
    </div>
  );
}
