import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Item } from "src/app/types/equipment";
import { formatNumber } from "src/app/utils/helpers";
import { multiplyNumbers } from "src/app/utils/calculators";
import heroPlaceholder from "src/assets/images/decors/hero-placeholder.png";
import faraLogo from "src/assets/images/tokens/fara.png";
import { EQUIPMENT_LABEL, RARITY_LABEL } from "src/app/configs/constants";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import { Link } from "react-router-dom";

export interface EquipmentListContentProps {
  item: Item;
  showOwned: boolean;
  showOffers: boolean;
  showListings: boolean;
  showExchanges: boolean;
  showCraftable: boolean;
}

const EquipmentListContent = (props: EquipmentListContentProps) => {
  const { item, showOwned, showOffers, showListings, showExchanges, showCraftable } = props;
  const { tokenPrice } = useSelector((state: any) => state.global);
  const [isLoading, setLoading] = useState(true);
  const showAvailable = showOwned;
  const showAmount = showOffers || showListings || showExchanges;

  function handleImageLoaded(loading: boolean) {
    setLoading(loading);
  }

  return (
    <Link to={`equipment/${item.id}`} className={`market__item`}>
      <div className="item-list__images">
        {isLoading && <img className="item-list__item--placeholder" src={heroPlaceholder} alt="" />}
        <div style={!isLoading ? {} : { display: "none" }} className="item-list__item">
          <ItemIconPreview item={item} isMarketplace onLoad={handleImageLoaded} showAvailable={showAvailable} showAmount={showAmount}/>
        </div>
      </div>
      <div className={`item-list__rarity ${RARITY_LABEL[item.rarity].toLowerCase()} `}>
        {RARITY_LABEL[item.rarity].toUpperCase()}
      </div>
      <div className="item-list__content">
        <div className="flex-column">
          <div className="item-list__item-info--name">{item.displayName.toUpperCase()}</div>
          <div className="item-list__item-info mb-1 mt-1 text-gray fw-4">Type: {EQUIPMENT_LABEL[item.type]}</div>
          <div className="item-list__item-info text-gray fw-4">Tier: {item.tier}</div>
        </div>
        {!(showExchanges || showOwned || showCraftable) && (
          <div className="flex-start-none ml-2">
            <div className="flex-column-right">
              <div className="item-list__price">
                <img className="token mr-1 top-2" src={faraLogo} alt="" />
                <div>{item.listedPrice ? formatNumber(item.listedPrice, 2) : "--/--"}</div>
              </div>
              {item.listedPrice && (
                <div className="item-list__price--converted">
                  ${formatNumber(multiplyNumbers(item.listedPrice, tokenPrice.FARA), 2)}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

export default EquipmentListContent;
