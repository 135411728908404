/**
 * Rarity type - item tier
 * Example: 0-1 -> Common item - tier 1
 */
export const DISMANTLE_REWARDS = {
  "0-1": {
    materials: [8, 9],
    quantity: 3,
    dismantlePoints: 1,
  },
  "0-2": {
    materials: [8, 9],
    quantity: 7,
    dismantlePoints: 1,
  },
  "0-3": {
    materials: [8, 9],
    quantity: 17,
    dismantlePoints: 2,
  },
  "1-1": {
    materials: [8, 9],
    quantity: 6,
    dismantlePoints: 2,
  },
  "1-2": {
    materials: [8, 9],
    quantity: 14,
    dismantlePoints: 2,
  },
  "1-3": {
    materials: [8, 9],
    quantity: 35,
    dismantlePoints: 3,
  },
};
