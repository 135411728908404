import { KnightQuery } from "src/app/types/knight";
import {
  marketClient,
  guildClient,
  stakingSubgraph,
  landClient,
  depositEquipmentClient,
} from "src/app/services/subgraph/client";
import {
  OFFER_QUERY,
  USER_ITEMS,
  STAKING_QUERY,
  TOTAL_INFO,
  USER_ITEM_OFFERS,
  USER_ITEM_LISTINGS,
  USER_ITEM_EXCHANGES,
  SYNCED_BLOCK_QUERY,
  USER_MATERIALS,
  USER_MATERIAL_LISTINGS,
  USER_MATERIAL_OFFERS,
  GUILD_LIST,
  GUILD_INFO,
  KNIGHT_LEARNED_SKILLS,
  STAKING_SUMMARY_QUERY,
  JOIN_REQUESTS,
  GUILD_SUMMARY,
  GUILD_OF_MEMBER,
  MATERIAL_TOTAL_INFO,
  LAND_OFFER_QUERY,
  LAND_TOTAL_INFO,
  OWNED_KNIGHTS_INFO,
  QUERY_TOTAL_EQUIPMENT,
  QUERY_TOTAL_MATERIAL,
} from "src/app/services/subgraph/queries";
import { createKnight } from "src/app/factories/knightFactory";
import { createFilteredItemData, createUserItem } from "src/app/factories/equipmentFactory";
import { createLandSummaryData, createMaterialSummaryData, createSummaryData } from "src/app/factories/commonFactory";
import { LandSummaryData, MaterialSummaryData, SummaryData } from "src/app/types/common";
import {
  fetchJoinRequestsFromApi,
  fetchKnightsAttribute,
  fetchLandAttribute,
  fetchMultipleGuilds,
  fetchTopGuild,
} from "src/app/services/api/faralandService";
import { createUserMaterial, createMaterial } from "src/app/factories/materialFactory";
import { createGuild, createGuildMember, createGuildSummary, createJoinRequest } from "src/app/factories/guildFactory";
import { plusNumbers } from "src/app/utils/calculators";
import { PENDING_REQUESTS, MULTIPLE_KNIGHTS_INFO } from "../subgraph/queries";
import { GuildQuery, GuildSummaryData } from "src/app/types/guild";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { LandQuery } from "src/app/types/land";
import { createLand } from "src/app/factories/landFactory";
import { NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";

export async function querySyncedBlock(client: ApolloClient<NormalizedCacheObject>): Promise<number> {
  try {
    const result = await client.query({
      query: SYNCED_BLOCK_QUERY(),
      fetchPolicy: "no-cache",
    });

    return result.data._meta.block.number;
  } catch (error) {
    return 0;
  }
}

export async function queryStakingSummary() {
  let deprecatedBalance = "0";
  let singleBalance = "0";
  let lpBalance = "0";

  try {
    const result = await stakingSubgraph.query({
      query: STAKING_SUMMARY_QUERY(),
    });
    const data = result.data.faraStakings;

    const deprecated = data.find((item) => item.type === "OLD");
    const single = data.find((item) => item.type === "SINGLE");
    const lp = data.find((item) => item.type === "LP");

    deprecatedBalance = deprecated ? deprecated.totalStaked : "0";
    singleBalance = single ? single.totalStaked : "0";
    lpBalance = lp ? lp.totalStaked : "0";
  } catch (error) {
    console.log(error);
  }

  return { deprecatedBalance, singleBalance, lpBalance };
}

export async function queryStakeFromSubgraph(query: KnightQuery) {
  try {
    const result = await stakingSubgraph.query({
      query: STAKING_QUERY(query.owner as string),
      fetchPolicy: "network-only",
    });
    const stakingKnights = result.data.userStakes ?? [];
    if (!stakingKnights.length) return { list: [], total: 0 };

    const arrayIds = stakingKnights.map((data: any) => data.knight.id);
    const attributes = await fetchKnightsAttribute(arrayIds);
    const list = stakingKnights.map((data: any) => {
      const knightId = data.knight.id;
      const attribute = attributes[knightId];
      return createKnight({
        id: knightId,
        owner: { id: query.owner },
        name: attribute.name,
        level: attribute.level,
        type: attribute.type,
        element: attribute.type,
        gender: attribute.gender,
        race: attribute.race,
        stakedAmount: plusNumbers(
          plusNumbers(data.stakedAmountSingle, data.stakedAmountOldSingle),
          data.stakedAmountSingleV3
        ),
        lpStakedAmount: plusNumbers(data.stakedAmountLP, data.stakedAmountLPV3),
        listedPrice: 0,
        equipments: [],
      });
    });

    return { list, total: list.length };
  } catch (error) {
    console.log(error);
  }
}

export async function queryOfferFromSubgraph(query: KnightQuery) {
  try {
    const result = await marketClient.query({
      query: OFFER_QUERY(query.owner as string),
      fetchPolicy: "network-only",
    });
    const data = result.data.offers ?? [];
    const arrayIds = data.map((data: any) => data.knight.id);
    const attributes = await fetchKnightsAttribute(arrayIds);
    const list = data.map((data: any) => createKnight(data.knight, data.price, attributes[data.knight.id]));

    return { list, total: list.length };
  } catch (error) {
    console.log(error);
  }
}

export async function queryUserEquipment(address: string) {
  try {
    let startId = 0;
    const arrayUserItems: any[] = [];
    const arrayEquipments: any[] = [];
    while (true) {
      const result = await marketClient.query({
        query: USER_ITEMS,
        variables: { owner: address, fromId: startId },
        fetchPolicy: "no-cache",
      });
      if (result && result.data && result.data.userOwnEquipments.length) {
        const arrayUserOwnEquipment = result.data.userOwnEquipments;
        const equipment = arrayUserOwnEquipment.map((item: any) => createUserItem(item));
        const list = equipment.map((data: any) => createFilteredItemData(data.item));
        startId = +arrayUserOwnEquipment[arrayUserOwnEquipment.length - 1].itemIdNumber + 1;
        arrayUserItems.push(...list);
        arrayEquipments.push(...equipment);

        if (result.data.userOwnEquipments.length < 100) {
          return {
            equipment: arrayEquipments,
            list: arrayUserItems,
            total: arrayUserItems.length,
          };
        }
      } else {
        return {
          equipment: arrayEquipments,
          list: arrayUserItems,
          total: arrayUserItems.length,
        };
      }
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function queryItemOffers(address: string) {
  try {
    const result = await marketClient.query({
      query: USER_ITEM_OFFERS,
      variables: { buyer: address },
      fetchPolicy: "no-cache",
    });
    const equipment = result.data.offerItems.map((item: any) => createUserItem(item));
    const list = equipment.map((data: any) => createFilteredItemData(data.item));
    return { equipment, list, total: list.length };
  } catch (error) {
    console.log(error);
  }
}

export async function queryItemListings(address: string) {
  try {
    const result = await marketClient.query({
      query: USER_ITEM_LISTINGS,
      variables: { seller: address },
      fetchPolicy: "no-cache",
    });
    const equipment = result.data.listItems.map((item: any) => createUserItem(item));
    const list = equipment.map((data: any) => createFilteredItemData(data.item));
    return { equipment, list, total: list.length };
  } catch (error) {
    console.log(error);
  }
}

export async function queryItemExchanges(address: string) {
  try {
    const result = await marketClient.query({
      query: USER_ITEM_EXCHANGES,
      variables: { seller: address },
      fetchPolicy: "no-cache",
    });
    const equipment = result.data.exchangeItems.map((item: any) => createUserItem(item));
    const list = equipment.map((data: any) => createFilteredItemData(data.item));
    return { equipment, list, total: list.length };
  } catch (error) {
    console.log(error);
  }
}

export async function querySummary(): Promise<SummaryData | false> {
  try {
    const summaryResult = await marketClient.query({
      query: TOTAL_INFO,
      fetchPolicy: "cache-first",
    });
    return createSummaryData(summaryResult);
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function queryMaterialSummary(): Promise<MaterialSummaryData | false> {
  try {
    const summaryResult = await marketClient.query({
      query: MATERIAL_TOTAL_INFO,
      fetchPolicy: "cache-first",
    });
    return createMaterialSummaryData(summaryResult);
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function queryLandSummary(): Promise<LandSummaryData | false> {
  try {
    const summaryResult = await landClient.query({
      query: LAND_TOTAL_INFO,
      fetchPolicy: "cache-first",
    });
    return createLandSummaryData(summaryResult);
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function queryMaterialListings(address: string) {
  try {
    const result = await marketClient.query({
      query: USER_MATERIAL_LISTINGS,
      variables: { seller: address },
      fetchPolicy: "no-cache",
    });
    const materials = result.data.listMaterials.map((material: any) => createUserMaterial(material));
    const list = materials.map((data: any) => createMaterial(data.material, data.price));
    return { materials, list, total: list.length };
  } catch (error) {
    console.log(error);
  }
}

export async function queryUserMaterials(address: string) {
  try {
    const result = await marketClient.query({
      query: USER_MATERIALS,
      variables: { owner: address },
      fetchPolicy: "no-cache",
    });
    const materials = result.data.userOwnMaterials.map((material: any) => createUserMaterial(material));
    const list = materials.map((data: any) => createMaterial(data.material));
    return { materials, list, total: list.length };
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function queryMaterialOffers(address: string) {
  try {
    const result = await marketClient.query({
      query: USER_MATERIAL_OFFERS,
      variables: { buyer: address },
      fetchPolicy: "no-cache",
    });
    const materials = result.data.offerMaterials.map((material: any) => createUserMaterial(material));
    const list = materials.map((data: any) => createMaterial(data.material, data.price));
    return { materials, list, total: list.length };
  } catch (error) {
    console.log(error);
  }
}

export async function queryGuildList(name: string) {
  try {
    const result = await guildClient.query({
      query: GUILD_LIST,
      variables: { name },
      fetchPolicy: "no-cache",
    });
    const list = result.data.faraGuilds.map((guild: any) => createGuild(guild));
    return { list, total: list.length };
  } catch (error) {
    console.log(error);
  }
}

export async function queryGuildInfo(id: string) {
  try {
    const result = await guildClient.query({
      query: GUILD_INFO,
      variables: { id },
      fetchPolicy: "no-cache",
    });
    const guild = result.data.faraGuild && createGuild(result.data.faraGuild);
    const members = result.data.members.map((member: any) => createGuildMember(member));
    const joinRequests = result.data.joinRequests.map((request: any) => createJoinRequest(request));
    return { guild, members, joinRequests };
  } catch (error) {
    console.log(error);
  }
}

export async function queryJoinRequests(id: string, name?: string) {
  try {
    const result = await guildClient.query({
      query: JOIN_REQUESTS,
      variables: { id, name: name ?? "" },
      fetchPolicy: "no-cache",
    });
    const wallets = result.data.joinRequests.map((request: any) => request.member.id);
    const apiResponse = await fetchJoinRequestsFromApi(wallets);
    const joinRequests = result.data.joinRequests.map((request: any) => createJoinRequest(request, apiResponse.data));
    return { joinRequests, total: joinRequests.length };
  } catch (error) {
    console.log(error);
  }
}

export async function queryPendingRequests(query: GuildQuery) {
  try {
    const result = await guildClient.query({
      query: PENDING_REQUESTS,
      variables: { id: query.owner as string },
      fetchPolicy: "no-cache",
    });
    const guildIds = await result.data.joinRequests.map((request: any) => request.guild.id);
    const guildDetails = await fetchMultipleGuilds(guildIds);
    return { list: guildDetails, total: guildDetails.length, ids: guildIds };
  } catch (error) {
    console.log(error);
  }
}

export async function queryLearnedSkills(id: string) {
  try {
    const result = await marketClient.query({
      query: KNIGHT_LEARNED_SKILLS,
      variables: { id: id },
      fetchPolicy: "no-cache",
    });
    return result.data.knight.skills ?? [];
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function queryGuildSummary(): Promise<GuildSummaryData | false> {
  try {
    const summaryResult = await guildClient.query({
      query: GUILD_SUMMARY,
      fetchPolicy: "cache-first",
    });
    const topGuild = await fetchTopGuild();
    return createGuildSummary(summaryResult.data.summaries[0], topGuild.data);
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function queryMultipleKnights(ids: number[]) {
  try {
    const result = await marketClient.query({
      query: MULTIPLE_KNIGHTS_INFO,
      variables: { ids },
      fetchPolicy: "no-cache",
    });
    const knights = await result.data?.knights.map((knight) => createKnight(knight));
    return knights;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function queryOwnedKnights(owner: string) {
  try {
    const result = await marketClient.query({
      query: OWNED_KNIGHTS_INFO,
      variables: { owner },
      fetchPolicy: "no-cache",
    });
    const data = result.data.knights ?? [];
    const arrayIds = data.map((data: any) => data.id);
    const attributes = await fetchKnightsAttribute(arrayIds);
    const knights = data.map((knight) => createKnight(knight, 0, attributes[knight.id]));
    return knights;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function fetchGuildOfMember(id: string) {
  try {
    const result = await guildClient.query({
      query: GUILD_OF_MEMBER,
      variables: { id },
      fetchPolicy: "no-cache",
    });
    const guild = result.data.member.guild && createGuild(result.data.member.guild);
    return guild;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function queryLandOfferFromSubgraph(query: LandQuery) {
  try {
    const result = await landClient.query({
      query: LAND_OFFER_QUERY(query.owner as string),
      fetchPolicy: "network-only",
    });
    const data = result.data.offers ?? [];
    const arrayIds = data.map((data: any) => data.land.id);
    const attributes = await fetchLandAttribute(arrayIds);
    const list = data.map((data: any) => createLand(data.land, data.price, attributes[+data.land.id]));

    return { list, total: list.length };
  } catch (error) {
    console.log(error);
  }
}

export async function queryTotalAmount(id: string, type: string) {
  try {
    const result = await depositEquipmentClient.query({
      query: type === NON_FEE_INVENTORY_TYPE.ITEM ? QUERY_TOTAL_EQUIPMENT(+id) : QUERY_TOTAL_MATERIAL(+id),
      fetchPolicy: "no-cache",
    });
    const data = type === NON_FEE_INVENTORY_TYPE.ITEM ? result.data.totalEquipments : result.data.totalMaterials ?? [];
    let totalAmount = 0;
    if (data.length > 0) {
      totalAmount = +data[0].totalAmount;
    }
    
    return { totalAmount };
  } catch (error) {
    console.log(error);
  }
}
