import { KnightStats, Knight, Offer, Transaction } from "src/app/types/knight";
import { RACE, RARITY_LABEL } from "src/app/configs/constants";
import { TraitStats, PrimaryStats, KnightTrait, KnightAttributes } from "src/app/types/attribute";
import { ItemRanges, ItemStatsLabel, RuneStatsLabel } from "src/app/types/equipment";
import { EQUIPMENT_SET } from "src/app/configs/equipment/sets";
import { mapGender } from "src/app/utils/mappingHelpers";
import { DemiKnight } from "src/app/types/demiKnight";
import fp from "lodash/fp";

export function createKnight(data: any, price?: number, knightTrait?: KnightTrait): Knight {
  const race = knightTrait ? knightTrait.race : data.race;
  return {
    id: data.id,
    name: data.name,
    owner: data.owner?.id?.toLowerCase() || data.owner,
    level: +data.level > 0 ? +data.level : 1,
    race: race,
    displayRace: race === RACE.DRAGONBORN ? "Dragon" : race,
    type: knightTrait ? knightTrait.type : data.type,
    element: knightTrait ? knightTrait.type : data.type,
    gender: mapGender(knightTrait ? knightTrait.gender : data.gender),
    stakedAmount: data.stakedAmount,
    lpStakedAmount: data.lpStakedAmount,
    price: price ? +price : +data.listedPrice,
    equipments: data.equipments,
    attributes: _createKnightAttributes(data.attributes),
    isHidden: data.isHidden,
    isOffChain: data.isOffChain,
    hasOffchainEquipment: data.hasOffchainEquipment ?? false,
  };
}

export function createDemiKnight(data: any, dataFromList?: boolean): DemiKnight {
  const igCharacter = !dataFromList ? data.igcharacter : data;

  return {
    id: data.id,
    name: igCharacter.name,
    owner: data.owner?.toLowerCase(),
    level: +data.level,
    race: data.race,
    displayRace: data.race === RACE.DRAGONBORN ? "Dragon" : data.race,
    type: igCharacter.type,
    element: dataFromList ? data.element : igCharacter.elementId,
    gender: dataFromList ? mapGender(data.gender) : data.gender,
    price: +data.listedPrice,
    lifespan: data.lifespan,
    registered: data.registered,
    equipments: data.equipments,
    attributes: _createKnightAttributes(igCharacter.attributes),
    isOffChain: data.isOffChain,
    hasOffchainEquipment: data.hasOffchainEquipment ?? false,
  };
}

export function createDemiKnightStats(data: any): KnightStats {
  const igCharacter = data.igcharacter;

  return {
    id: data.id,
    level: +igCharacter.level,
    traitStats: _createTraitStats(igCharacter.attributes).filter((trait) => {
      return trait.name !== null;
    }),
    baseStats: {
      str: igCharacter.basedStr,
      agi: igCharacter.basedAgi,
      int: igCharacter.basedIntel,
      luk: igCharacter.basedLuck,
    },
    elementStats: {
      str: data.elementStats.str,
      agi: data.elementStats.agi,
      int: data.elementStats.intel,
      luk: data.elementStats.luck,
    },
    raceStats: {
      agi: data.raceStats.agi,
      int: data.raceStats.intel,
      luk: data.raceStats.luck,
      str: data.raceStats.str,
    },
    allocatedStats: {
      str: 0,
      agi: 0,
      int: 0,
      luk: 0,
    },
    availablePoints: 0,
    itemStats: data.itemStats,
    itemSetBonus: data.itemSetBonus,
  };
}

export function updateKnightAttributesFromApi(
  apiResult: any,
  knights: (Knight | DemiKnight)[]
): Map<number, KnightStats> {
  const knightStatsMap = new Map<number, KnightStats>();
  const traits = ["Eyes", "Hair", "Mouth", "Scar", "Skintone", "Tattoo"];

  (apiResult?.list ?? []).forEach((r: any, idx: number) => {
    knights[idx].attributes = _createAttributes(
      fp.pipe(
        fp.pickAll(traits.map((t) => `trait${t}`)),
        fp.entries,
        fp.reject(([_, v]) => v === null),
        fp.map(([k, v]) => ({ trait_type: k.replace("trait", ""), ...v }))
      )(r)
    );

    knightStatsMap[r.id] = { ...createKnightStats(r), totalBaseStats: r.total_base_stats };
  });

  return knightStatsMap;
}

export function createKnightStats(data: any): KnightStats {
  return {
    id: data.id,
    level: +data.level,
    traitStats: _createTraitStats(data.attributes ?? []),
    baseStats: {
      str: data.basedStr,
      agi: data.basedAgi,
      int: data.basedIntel,
      luk: data.basedLuck,
    },
    elementStats: {
      str: data.elementStats?.str,
      agi: data.elementStats?.agi,
      int: data.elementStats?.intel,
      luk: data.elementStats?.luck,
    },
    raceStats: {
      agi: data.raceStats?.agi,
      int: data.raceStats?.intel,
      luk: data.raceStats?.luck,
      str: data.raceStats?.str,
    },
    allocatedStats: {
      str: 0,
      agi: 0,
      int: 0,
      luk: 0,
    },
    availablePoints: 0,
    itemStats: _createItemStats(data.itemsStats ?? {}),
    itemSetBonus: _createItemSetBonus(data.itemSetBonus ?? []),
  };
}

export function createStats(stats: any): PrimaryStats {
  if (!stats) {
    return { str: 0, agi: 0, int: 0, luk: 0 };
  }

  return {
    str: +stats.STR,
    agi: +stats.AGI,
    int: +stats.INT,
    luk: +stats.LUK,
  };
}

export function createOffer(data: any, domainNames?: any): Offer {
  return {
    id: data.id,
    knightId: data.knight.id,
    isActive: data.isActive,
    buyer: data.buyer.id,
    price: data.price,
    timestamp: data.timestamp,
    buyerDomain: domainNames ? domainNames[data.buyer.id] : "",
  };
}

export function createTx(data: any, domainNames: any): Transaction {
  return {
    type: data.type,
    price: data.price,
    knightId: data.knight.id,
    from: data.from?.id,
    to: data.to?.id,
    timestamp: data.timestamp,
    fromAddressDomain: domainNames ? domainNames[data.from?.id] : "",
    toAddressDomain: domainNames ? domainNames[data.to?.id] : "",
  };
}

export function getEmptyStats(): ItemStatsLabel {
  return {
    STR: 0,
    AGI: 0,
    INT: 0,
    LUK: 0,
    HP: 0,
    Speed: 0,
    Critical: 0,
    "Critical Damage": 0,
    Evade: 0,
    "Physical Damage": 0,
    "Physical Defense": 0,
    "Magical Damage": 0,
    "Magical Defense": 0,
    "Armor Penetration": 0,
    Accuracy: 0,
    effects: [],
  };
}

export function getEmptyRuneStats(): RuneStatsLabel {
  return {
    HP: 0,
    "Physical Defense": 0,
    "Magical Defense": 0,
    "Bonus Passive Skill": 0,
    "Bonus Active Skill": 0,
    "Bonus Normal Attack": 0,
    "Bonus Elemental Counter": 0,

  };
}
export function getEmptyRanges(): ItemRanges {
  return {
    "Move Range": 0,
    attackRange: [],
  };
}

function _createAttributes(traits: any[]): KnightAttributes[] {
  return traits.map((t) => ({
    id: `${t.display_name}-${t.trait_type}`,
    traitType: t.trait_type,
    value: t.display_name,
    rarity: t.tier,
    displayRarity: RARITY_LABEL[t.tier],
    isUpgraded: t.og_tier !== t.tier,
    slot1: t.slot1,
    slot2: t.slot2,
    slot3: t.slot3,
  }));
}

function _createKnightAttributes(attributes: any): KnightAttributes[] {
  if (!attributes) return [];

  return attributes
    .map((attribute: any) => {
      return {
        id: `${attribute.value.display_name}-${attribute.trait_type}`,
        traitType: attribute.trait_type,
        value: attribute.value.display_name,
        rarity: attribute.value.tier,
        displayRarity: RARITY_LABEL[attribute.value.tier],
        isUpgraded: attribute.value.og_tier !== attribute.value.tier,
        slot1: attribute.value.slot1,
        slot2: attribute.value.slot2,
        slot3: attribute.value.slot3,
      };
    })
    .filter((attribute: any) => {
      return attribute.value !== undefined;
    });
}

function _createTraitStats(traits: any): TraitStats[] {
  return traits.map((trait: any) => {
    return {
      name: trait.value.name,
      traitType: trait.trait_type,
      stats: {
        agi: trait.value.agi ? trait.value.agi : 0,
        int: trait.value.intel ? trait.value.intel : 0,
        luk: trait.value.luck ? trait.value.luck : 0,
        str: trait.value.str ? trait.value.str : 0,
      },
    };
  });
}

function _createItemSetBonus(data: any): ItemStatsLabel {
  let stats = getEmptyStats();

  data.forEach((bonus) => {
    const result = _getReducedStats(bonus);
    stats = _mergeStats(stats, result);
  });

  return stats;
}

function _getReducedStats(bonus: any) {
  const setId = bonus.setId;
  const totalEquipmentItem = bonus.totalEquipmentItem;
  return Object.keys(bonus.bonuses).reduce((stats: any, value: any, index: any) => {
    if (value > totalEquipmentItem) return stats;

    let effects = null;
    const apiStats = bonus.bonuses[value];
    if (apiStats.effects && apiStats.effects.length > 0) {
      effects = EQUIPMENT_SET[setId].bonuses[index].stats.effects;
    }

    return {
      STR: stats["STR"] + (apiStats.str || 0),
      AGI: stats["AGI"] + (apiStats.agi || 0),
      INT: stats["INT"] + (apiStats.intel || 0),
      LUK: stats["LUK"] + (apiStats.luck || 0),
      HP: stats["HP"] + (apiStats.hp || 0),
      Speed: stats["Speed"] + (apiStats.speed || 0),
      Critical: stats["Critical"] + (apiStats.critical || 0),
      "Critical Damage": stats["Critical Damage"],
      Evade: stats["Evade"] + (apiStats.evade || 0),
      "Physical Damage": stats["Physical Damage"] + (apiStats.physicDmg || 0),
      "Physical Defense": stats["Physical Defense"] + (apiStats.physicDef || 0),
      "Magical Damage": stats["Magical Damage"] + (apiStats.magicDmg || 0),
      "Magical Defense": stats["Magical Defense"] + (apiStats.magicDef || 0),
      effects: effects ? stats["effects"].concat(effects) : stats["effects"],
    };
  }, getEmptyStats());
}

function _mergeStats(stats: ItemStatsLabel, data: ItemStatsLabel) {
  return {
    STR: stats["STR"] + data["STR"],
    AGI: stats["AGI"] + data["AGI"],
    INT: stats["INT"] + data["INT"],
    LUK: stats["LUK"] + data["LUK"],
    HP: stats["HP"] + data["HP"],
    Speed: stats["Speed"] + data["Speed"],
    Critical: stats["Critical"] + data["Critical"],
    "Critical Damage": (stats["Critical Damage"] ?? 0) + (data["Critical Damage"] ?? 0),
    Evade: stats["Evade"] + data["Evade"],
    "Physical Damage": stats["Physical Damage"] + data["Physical Damage"],
    "Physical Defense": stats["Physical Defense"] + data["Physical Defense"],
    "Magical Damage": stats["Magical Damage"] + data["Magical Damage"],
    "Magical Defense": stats["Magical Defense"] + data["Magical Defense"],
    effects: data.effects ? stats["effects"].concat(data.effects) : stats["effects"],
  };
}

function _createItemStats(data: any): ItemStatsLabel {
  return {
    STR: data.str ?? 0,
    AGI: data.agi ?? 0,
    INT: data.intel ?? 0,
    LUK: data.luck ?? 0,
    HP: data.hp ?? 0,
    Speed: data.speed ?? 0,
    Critical: data.critical ?? 0,
    "Critical Damage": 0,
    Evade: data.evade ?? 0,
    "Physical Damage": data.physicDmg ?? 0,
    "Physical Defense": data.physicDef ?? 0,
    "Magical Damage": data.magicDmg ?? 0,
    "Magical Defense": data.magicDef ?? 0,
    effects: data.effects ?? [],
  };
}
