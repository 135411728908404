import React, { useEffect, useRef, useState } from "react";
import { Lottie } from "@crello/react-lottie";
import { formatBigNumber, getAnimatedJsonOptions } from "src/app/utils/helpers";
import { getLandFilterFromParams } from "src/app/utils/filterHelper";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import * as notFoundJson from "src/assets/lotties/404.json";
import { useDispatch, useSelector } from "react-redux";
import { useQueryString } from "use-route-as-state";
import { Pagination } from "@material-ui/lab";
import { LIMIT, ROUTE, SORT_DATA } from "src/app/configs/constants";
import { fetchLandFromApi, getLandTraits } from "src/app/services/api/faralandService";
import useUpdateEffect from "src/app/hooks/useUpdateEffect";
import { Land, LandFilterType, LandQuery } from "src/app/types/land";
import LandFilter from "./LandFilter";
import LandSwitcher from "src/app/components/Land/LandSwitcher";
import LandListContent from "src/app/components/Land/LandListContent";
import LandClaimBtn from "src/app/components/Land/LandClaimBtn";
import { useRouteMatch } from "react-router-dom";
import { setOwnedLands } from "src/app/actions/landAction";
import { queryLandOfferFromSubgraph } from "src/app/services/api/subgraphService";

interface LandListProps {
  hideSwitcher?: boolean;
  showClaim?: boolean;
}

export default function LandList(props: LandListProps) {
  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.account);
  const { refresh } = useSelector((state: any) => state.land);
  const { web3Service } = useSelector((state: any) => state.global);
  const landListRef = useRef(null);
  const [queryParams, setQueryParams] = useQueryString();
  const [total, setTotal] = useState(0);
  const [landFilters, setLandFilters] = useState<LandFilterType>(getLandFilterFromParams(queryParams));
  const [lands, setLands] = useState<Land[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const disablePagination = landFilters.owned;
  const walletMatch = useRouteMatch(ROUTE.WALLET);
  const [showConnectWalletError, setShowConnectWalletError] = useState(disablePagination && !address);

  useUpdateEffect(() => {
    setLandFilters(getLandFilterFromParams(queryParams));
  }, [queryParams]);

  useEffect(() => {
    setShowConnectWalletError(disablePagination && !address);
  }, [disablePagination, address]);

  useEffect(() => {
    if (!web3Service) return;
    setPage(landFilters.page);
    fetchLand(landFilters);
    getLandTraits("");
  }, [address, landFilters, web3Service, refresh]); // eslint-disable-line

  async function fetchLand(filter: LandFilterType) {
    let filteredLand: Land[] = [];
    const sortData = SORT_DATA[filter.sortBy];
    const variables: LandQuery = {
      limit: LIMIT.LAND,
      orderBy: sortData.key,
      orderDirection: sortData.direction,
      owner: address,
      itemPerPage: LIMIT.LAND,
      page: filter.page,
    };

    setLoading(true);

    try {
      let result;
      let rewards;
      if (filter.offering) {
        result = await queryLandOfferFromSubgraph(variables);
      } else {
        result = await fetchLandFromApi(filter, variables);
      }

      filteredLand = result?.list ?? [];
      if (walletMatch) {
        rewards = await web3Service.fetchLandRewards(filteredLand.map((land) => land.id));
        filteredLand = filteredLand.map((land, idx) => {
          return {
            ...land,
            reward: formatBigNumber(rewards[idx]),
          };
        });
        dispatch(setOwnedLands(result?.landIds ?? []));
      }
      setTotal(result?.total ?? 0);
    } catch (e) {
      console.log(e);
    }
    setLands(filteredLand);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  useEffect(() => {
    if (total && total < LIMIT.LAND) {
      handleChangePage(1);
    }

    const newTotalPage = Math.ceil(total / LIMIT.LAND);
    setTotalPage(newTotalPage);
    if (newTotalPage && page > newTotalPage) {
      handleChangePage(1);
    }
  }, [total]); // eslint-disable-line

  function onClickPaging(page: number) {
    if (landListRef && landListRef.current) {
      // @ts-ignore
      landListRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    handleChangePage(page);
  }

  function handleChangePage(page: number) {
    setQueryParams({
      ...queryParams,
      page: page.toString(),
    });
  }

  return (
    <div className="equipment-marketplace slide-up" ref={landListRef}>
      <div className="market">
        <LandFilter showClaim={props.showClaim} />

        <div className="market__content">
          {!props.hideSwitcher && <LandSwitcher isLoading={isLoading} />}
          {isLoading && (
            <Lottie
              className="market__loading market__loading--marketplace slide-up"
              config={getAnimatedJsonOptions(loadingJson)}
            />
          )}

          {!isLoading && lands.length === 0 && !showConnectWalletError && (
            <div className="align-center slide-up">
              <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
              <div className="market__404-title">No Land found with your searching queries.</div>
            </div>
          )}

          {!isLoading && showConnectWalletError && (
            <div className="align-center slide-up">
              <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
              <div className="market__404-title">Please connect your wallet first</div>
            </div>
          )}

          {!isLoading && lands.length !== 0 && !showConnectWalletError && (
            <>
              {props.showClaim && <LandClaimBtn />}

              <div className="market__items">
                {lands.map((land: Land, index: number) => {
                  return <LandListContent land={land} key={index} showClaim={props.showClaim} />;
                })}
              </div>
            </>
          )}

          {totalPage > 1 && !disablePagination && (
            <div className="market__pagination">
              <Pagination
                count={totalPage}
                page={page}
                onChange={(_, val) => onClickPaging(val)}
                variant="outlined"
                color="primary"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
