import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputGroup from "src/app/components//Commons/InputGroup";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import imagePlaceholder from "src/assets/images/guild/image-placeholder.png";
import { StylesProvider } from "@material-ui/styles";
import { changeGuildName, refreshGuildDetails } from "src/app/actions/guildAction";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import ENV from "src/app/configs/env";
import { compareNumbers } from "src/app/utils/calculators";
import { GUILD_COSTS } from "src/app/configs/constants";
import { Tab, Tabs } from "@material-ui/core";
import { updateGuildDetails } from "src/app/services/api/faralandService";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { formatNumber } from "src/app/utils/helpers";

type GuildInfoChangeModalProps = {
  guildId: string;
  guildImg: any;
  guildDesc: string;
};

export default function GuildInfoChangeModal(props: GuildInfoChangeModalProps) {
  const dispatch = useDispatch();
  const { wallet, address, balance } = useSelector((state: any) => state.account);
  const { refresh } = useSelector((state: any) => state.guild);
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.GUILD);
  const [guildName, setGuildName] = useState("");
  const [guildNameError, setGuildNameError] = useState("");
  const [guildImg, setGuildImg] = useState<any>(props.guildImg);
  const [imgFile, setImgFile] = useState<File>();
  const [imageError, setImageError] = useState("");
  const [desc, setDesc] = useState(props.guildDesc);
  const [descError, setDescError] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const handleNameChange = (e: any) => {
    setGuildNameError("");
    setGuildName(e.target.value);
  };

  const handleTabChange = (_e: any, newValue: number) => {
    setTabValue(newValue);
  };

  const handleImageSelect = (e) => {
    let reader = new FileReader();
    if (!e.target.files[0]) return;
    const fileExtension = e.target.files[0].name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
      setImageError(`Unsupported file type`);
      return false;
    }
    reader.onload = function (e) {
      if (reader.readyState === 2) {
        setImageError("");
        setGuildImg(reader.result);
      }
    };
    setImgFile(e.target?.files[0]);
    reader.readAsDataURL(e.target?.files[0]);
  };

  const handleDescChange = (e: any) => {
    setDescError("");
    setDesc(e.target.value);
  };

  const onSubmitNameChange = () => {
    if (needApprove) {
      sendApproveTx();
      return;
    }
    const compareFara = compareNumbers(balance.FARA, GUILD_COSTS.CHANGE_NAME_FARA);

    if (compareFara === -1) {
      setGuildNameError("Insufficient FARA Balance");
      return;
    }
    if (guildName.length === 0) {
      setGuildNameError("Guild Name cannot be empty");
      return;
    }
    if (guildName.length < 6 || guildName.length > 30) {
      setGuildNameError("Guild Name must have between 6 and 30 characters");
      return;
    }
    dispatch(changeGuildName(+props.guildId, guildName));
  };

  const onSubmitDetailsChange = async () => {
    if (!guildImg) {
      setImageError("Please select a guild image");
      return;
    }
    if (desc.length === 0) {
      setDescError("Descripiton cannot be empty");
      return;
    }
    const signData = await wallet.signData(
      address,
      { file: imgFile?.name, desc },
      {
        Group: [
          { name: "file", type: "string" },
          { name: "desc", type: "string" },
        ],
      }
    );
    const res = await updateGuildDetails(props.guildId, imgFile, desc, signData.sign, signData.msgParams);

    if (res.success) {
      modalService.close();
      dispatch(refreshGuildDetails(!refresh));
    }
  };

  function renderNameChangeContent() {
    return (
      <StylesProvider>
        <Tabs value={tabValue} onChange={handleTabChange} className="market__filter-tabs" variant="fullWidth">
          <Tab label={"Change Name"} />
          <Tab label={"Change Details"} />
        </Tabs>
        {tabValue === 0 ? (
          <>
            <InputGroup
              className="text-field--default"
              value={guildName}
              handleChange={handleNameChange}
              symbol="FARA"
              balance={balance.FARA}
              label={"Guild Name"}
              hideMaxBtn={true}
              placeholder="Enter a guild name"
              isTextField
              error={guildNameError}
            />
            <div className="mt-2 fs-3 warning-text">Charge Fee: {formatNumber(GUILD_COSTS.CHANGE_NAME_FARA)} FARA</div>
          </>
        ) : (
          <div className="guild-creation">
            <div className="mr-5 guild-creation__container">
              <div className="guild-creation__image">
                <img
                  src={guildImg ?? imagePlaceholder}
                  alt=""
                  className={guildImg && "guild-creation__image--selected"}
                />
              </div>
              <input accept="image/*" id="image-select" type="file" hidden onChange={handleImageSelect} />
              <label htmlFor="image-select">
                <div className="btn btn--small mt-3">Choose File</div>
              </label>
            </div>
            <div className="full-width">
              <InputGroup
                className="guild-creation__text-field"
                label="Description"
                placeholder="Enter guild description"
                value={desc ?? ""}
                handleChange={handleDescChange}
                hideMaxBtn={true}
                isTextField
                error={descError}
                multiline
                maxLength={100}
                hideBalance
              />
            </div>
          </div>
        )}
        <div className="error-text">{imageError}</div>
      </StylesProvider>
    );
  }
  return (
    <BasicModalContent
      customTitle="Edit Guild Info"
      onSubmit={tabValue === 0 ? onSubmitNameChange : onSubmitDetailsChange}
      submitText={needApprove ? "Approve" : "Confirm"}
      content={renderNameChangeContent()}
    />
  );
}
