import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Material } from "src/app/types/materials";
import { roundNumber } from "src/app/utils/helpers";
import { multiplyNumbers } from "src/app/utils/calculators";
import heroPlaceholder from "src/assets/images/decors/hero-placeholder.png";
import faraLogo from "src/assets/images/tokens/fara.png";
import { Link } from "react-router-dom";
import MaterialIconPreview from "src/app/components/Materials/Commons/MaterialIconPreview";

export interface EquipmentListContentProps {
  material: Material;
  showOwned: boolean;
  showOffers: boolean;
  showListings: boolean;
}

const MaterialListContent = (props: EquipmentListContentProps) => {
  const { material, showOwned, showOffers, showListings } = props;
  const { tokenPrice } = useSelector((state: any) => state.global);
  const [isLoading, setLoading] = useState(true);
  const showAvailable = showOwned;
  const showAmount = showOffers || showListings;

  function handleImageLoaded(loading: boolean) {
    setLoading(loading);
  }

  return (
    <Link to={`material/${material.id}`} className={`market__item`}>
      <div className="material-list__images">
        {isLoading && <img className="material-list__item--placeholder" src={heroPlaceholder} alt="" />}
        <div style={!isLoading ? {} : { display: "none" }} className="material-list__item">
          <MaterialIconPreview
            material={material}
            isMarketplace
            onLoad={handleImageLoaded}
            showAvailable={showAvailable}
            showAmount={showAmount}
          />
        </div>
      </div>
      <div className="material-list__content flex-start-center">
        <div className="flex-column">
          <div className="material-list__item-info--name">{material.name.toUpperCase()}</div>
        </div>
        {!showOwned && (
          <div className="flex-start-none ml-2">
            <div className="flex-column-right">
              <div className="material-list__price">
                <img className="token mr-1" src={faraLogo} alt="" />
                <div>{material.price ? roundNumber(material.price, 2) : "--/--"}</div>
              </div>
              {material.price && (
                <div className="material-list__price--converted">
                  ${roundNumber(multiplyNumbers(material.price, tokenPrice.FARA), 2)}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

export default MaterialListContent;
