import React from "react";
import metrics from "src/assets/images/footer/token_metrics.jpeg";

const TokenMetrics = () => {
  return (
    <div>
      <img src={metrics} alt="Token Metrics" style={{ maxWidth: "100%" }} />
    </div>
  );
};

export default TokenMetrics;
