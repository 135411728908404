import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { Item, UserItem } from "src/app/types/equipment";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import _ from "lodash";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import { EQUIPMENT_LABEL, NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";
import { fetchNonFeeInventory } from "src/app/services/api/faralandService";
import SearchIcon from "@material-ui/icons/Search";
import { setRequiredItem } from "src/app/actions/runeAction";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";

interface ChoosingEquipmentFilter {
  name: string;
}

type RuneEquipmentSelectModalProps = {
  rarity: number;
  requiredAmount: number;
  selectedItemId: number;
  selectedItemAmount: number;
};

export default function RuneEquipmentSelectModal(props: RuneEquipmentSelectModalProps) {
  const dispatch = useDispatch();
  const { rarity, requiredAmount, selectedItemId, selectedItemAmount } = props;
  const { address } = useSelector((state: any) => state.account);
  const [userItems, setUserItems] = useState<UserItem[]>([]);
  const [error, setError] = useState("");
  const [itemId, setItemId] = useState<number | null>(selectedItemId);
  const [itemAmount, setItemAmount] = useState<number>(selectedItemAmount);
  const [filteredItems, setFilteredItems] = useState<UserItem[]>([]);
  const debounceFilterItems = useCallback(_.debounce(filterItemsByName, 500), [filteredItems]);
  const [filterObj, setFilterObj] = useState<ChoosingEquipmentFilter>({ name: "" });

  useEffect(() => {
    if (!address) {
      setUserItems([]);
      return;
    }
    _fetchUserOwnEquipment(address);
  }, [address]); // eslint-disable-line

  async function _fetchUserOwnEquipment(address: string) {
    const items: Item[] = [];

    const result = await fetchNonFeeInventory(NON_FEE_INVENTORY_TYPE.ITEM, address);
    if (result) {
      result.equipment.forEach((userItem: UserItem) => {
        items.push(EQUIPMENT[userItem.item.id]);
      });
      const skillItems = items
        .filter((item: Item) => {
          return item && EQUIPMENT_LABEL[item.type] === "Skill Book";
        })
        .map((item: Item) => {
          return item.id;
        });
      setUserItems(
        result.equipment.filter((inventoryItem: UserItem) => {
          return !skillItems.includes(inventoryItem.item.id) && inventoryItem.available >= requiredAmount;
        })
      );
    }
  }

  useEffect(() => {
    if (!userItems) {
      setFilteredItems([]);
    } else {
      const result = userItems.filter((value: UserItem) => {
        return (
          value.item.displayName.toLowerCase().includes(filterObj.name.toLowerCase()) && value.item.rarity === rarity
        );
      });
      setFilteredItems(result);
    }
  }, [userItems, filterObj]); // eslint-disable-line

  function filterItemsByName(name: string) {
    setFilterObj({ ...filterObj, name: name });
  }

  function resetSelectedItems() {
    setItemId(null);
  }

  function selectItem(itemId: number) {
    setError("");
    const item = filteredItems.find((item) => {
      return item.id === itemId;
    });
    setItemId(itemId);
    setItemAmount(item?.available ?? 0);
  }

  function handleConfirm() {
    if (itemId === undefined || itemId === null) {
      return setError("Select at least 1 item");
    } else {
      dispatch(setRequiredItem(itemId, itemAmount, rarity - 2));
      modalService.close();
    }
  }

  return (
    <BasicModalContent
      onSubmit={handleConfirm}
      submitText={"Select"}
      content={
        <div>
          <div className="item-exchange mt-5">
            <div className="item-exchange__wrapper">
              <div className="item-exchange__title mb-1">Select Item to craft your rune</div>
              <div className="item-exchange__header">
                <div className="flex-center-start mb-2">
                  <div className="btn btn--tiny" onClick={resetSelectedItems}>
                    Reset
                  </div>
                </div>
                <div className="icon-input">
                  <SearchIcon className="icon-input__icon" />
                  <input
                    className="icon-input__input"
                    onChange={(e) => debounceFilterItems(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="item-exchange__storage nice-scroll nice-scroll--small nice-scroll--gray">
                {filteredItems.length === 0 && <div>No Item found...</div>}
                {filteredItems.map((userItem: UserItem, i: number) => {
                  return (
                    <ItemIcon
                      amount={itemId === userItem.item.id ? itemAmount : 0}
                      hideAmount
                      key={i}
                      item={userItem.item}
                      layer={2}
                      onClick={selectItem}
                    />
                  );
                })}
              </div>
            </div>
            {error && <div className="error-text">{error}</div>}
          </div>
        </div>
      }
    />
  );
}
