import React, { useState, useEffect } from "react";
import ENV from "src/app/configs/env";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import InputGroup from "src/app/components/Commons/InputGroup";
import AddressInputGroup from "src/app/components/Commons/AddressInputGroup";
import { useSelector, useDispatch } from "react-redux";
import { roundNumber, toBigAmount } from "src/app/utils/helpers";
import { compareNumbers, minusNumbers } from "src/app/utils/calculators";
import { list, delist, offer, takeOffer, cancelOffer, buy } from "src/app/actions/marketAction";
import { changeName, transfer } from "src/app/actions/knightAction";
import { Knight, Offer, List } from "src/app/types/knight";
import bnbLogo from "src/assets/images/tokens/bnb.svg";
import { isAddress } from "web3-utils";
import { ACTIONS, MOONKNIGHT_SERVICE_FEE } from "src/app/configs/constants";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";

type ActionModalProps = {
  type: number;
  knight: Knight;
  currentList: List;
  offerPrice: string;
  buyer: string;
  ownerActiveOffer?: Offer;
  currentActiveOffer?: Offer;
};

export default function KnightActionModal(props: ActionModalProps) {
  const dispatch = useDispatch();
  const { balance } = useSelector((state: any) => state.account);
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.MOONKNIGHT);
  const { knight, currentActiveOffer, ownerActiveOffer, currentList, type } = props;

  const [offerPrice, setOfferPrice] = useState("");
  const [listingPrice, setListingPrice] = useState(
    currentList && currentList.price ? currentList.price.toString() : ""
  );
  const [receiveAddr, setReceiveAddr] = useState("");
  const [name, setName] = useState("");
  const [inputError, setInputError] = useState("");
  const [receiverInput, setReceiverInput] = useState("");

  useEffect(() => {
    if (!ownerActiveOffer) return;
    setOfferPrice(ownerActiveOffer.price);
  }, [ownerActiveOffer]);

  function handleOfferPriceChange(e: any, amount?: string) {
    setInputError("");
    if (amount) {
      setOfferPrice(amount);
      return;
    }
    setOfferPrice(e.target.value);
  }

  function handleListingAmountChange(e: any, amount?: string) {
    setInputError("");
    if (amount) {
      setListingPrice(amount);
      return;
    }
    setListingPrice(e.target.value);
  }

  function handleSetReceiverAddress(value: string) {
    setReceiveAddr(value);
  }

  function handleNameChange(e: any) {
    setInputError("");
    setName(e.target.value);
  }

  function sendBuyTx() {
    dispatch(buy(knight.id, toBigAmount(currentList.price), false));
  }

  function sendOfferTx() {
    const compareBNB = compareNumbers(balance.BNB, offerPrice);

    if (offerPrice === "") {
      setInputError(`Invalid amount.`);
    } else if (compareBNB === -1) {
      setInputError(`Insufficient BNB Balance.`);
    } else {
      const value = toBigAmount(offerPrice);
      dispatch(offer(knight.id, value, value, false));
    }
  }

  function sendMoreOfferTx(currentOfferPrice: string, isLower = false) {
    if (!currentActiveOffer) return;

    const compareOffer = compareNumbers(offerPrice, currentActiveOffer.price);
    const isOfferMore = currentActiveOffer.price && (compareOffer === 1 || compareOffer === 0);
    const sendingValue = isOfferMore ? +offerPrice - +currentActiveOffer.price : offerPrice;
    const compareBNB = compareNumbers(balance.BNB, sendingValue);

    if (compareBNB === -1) {
      setInputError(`Insufficient BNB Balance.`);
    } else {
      let value = "0";
      const price = toBigAmount(offerPrice);
      if (!isLower) {
        const moreValue = minusNumbers(offerPrice, currentOfferPrice);
        value = toBigAmount(moreValue);
      }
      dispatch(offer(knight.id, price, value, false));
    }
  }

  function sendCancelOfferTx() {
    dispatch(cancelOffer(knight.id, false));
  }

  function sendTakeOfferTx() {
    if (knight.hasOffchainEquipment) {
      setInputError("Please unequip all your items in off-chain mode before taking the offer");
      return;
    }
    dispatch(takeOffer(knight.id, props.buyer, toBigAmount(props.offerPrice), false));
  }

  function sendListTx() {
    const compareListingPrice = compareNumbers(listingPrice, knight.price || 0);

    if (listingPrice === "") {
      setInputError("Invalid listing price.");
    } else if (compareListingPrice === 0) {
      setInputError(`New listing price must not be the same as your current listing price.`);
    } else if (knight.hasOffchainEquipment) {
      setInputError("Please unequip all your items in off-chain mode before listing");
    } else {
      dispatch(list(knight.id, toBigAmount(listingPrice), false));
    }
  }

  function sendDelistTx() {
    dispatch(delist(knight.id, false));
  }

  function sendTransferTx() {
    if (!isAddress(receiveAddr)) {
      setInputError("Invalid BSC address.");
      return;
    }
    if (knight.hasOffchainEquipment) {
      setInputError("Please unequip all your items in off-chain mode before transferring");
      return;
    }
    dispatch(transfer(knight.id, knight.owner, receiveAddr));
  }

  function sendChangeNameTx() {
    const compareFARA = compareNumbers(balance.FARA, MOONKNIGHT_SERVICE_FEE);
    const trimmedName = name.trim();

    if (trimmedName.length > 20 || !trimmedName.match(/^[A-Za-z ]+$/)) {
      setInputError("Name should have 20 characters max and contains letters");
    } else if (compareFARA === -1) {
      setInputError("Insufficient FARA balance.");
    } else {
      dispatch(changeName(knight.id, trimmedName));
    }
  }

  function renderBalanceBlock(error: string) {
    return (
      <div className="mt-2">
        <div>
          <span>Current balance:</span>
          <span> {roundNumber(balance.BNB, 3, true)} BNB</span>
          <span>, {roundNumber(balance.FARA, 2, true)} FARA</span>
        </div>
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }

  function renderBuyContent() {
    const isNotEnough = compareNumbers(toBigAmount(balance.BNB), knight.price ?? 0);

    return (
      <div className="knight__checkoutWrapper">
        <div>
          Are you sure to buy this Hero with <b>{currentList.price} BNB</b>?
        </div>
        <div className="fs-2">{renderBalanceBlock(isNotEnough === -1 ? "Not enough $BNB balance" : "")}</div>
      </div>
    );
  }

  function renderMakeOfferContent() {
    let content, onSubmit, submitText, childContent;
    const currentListedPrice = knight ? knight.price : 0;
    const isBought = !!(currentListedPrice && +offerPrice >= currentListedPrice);
    const isOfferMore = !!(currentActiveOffer && currentActiveOffer.price && +offerPrice >= +currentActiveOffer.price);
    const isNeedCancelOffer = !!(
      currentActiveOffer &&
      currentActiveOffer.price &&
      +offerPrice < +currentActiveOffer.price
    );

    if (isBought) {
      childContent = (
        <div className="slide-up mt-2 small-text small-text--warning">
          Your offer price is higher than listing price of {currentListedPrice} BNB. The transaction will buy this hero
          directly instead of creating offer.
        </div>
      );
      onSubmit = sendBuyTx;
      submitText = "Buy";
    } else if (isOfferMore && currentActiveOffer) {
      childContent = (
        <div className="slide-up mt-2 small-text small-text--warning">
          You already placed an offer for this hero with {currentActiveOffer.price} BNB. This offer will need{" "}
          {minusNumbers(offerPrice, currentActiveOffer.price)} BNB more.
        </div>
      );
      onSubmit = () => sendMoreOfferTx(currentActiveOffer.price, false);
      submitText = "Offer More";
    } else if (isNeedCancelOffer && currentActiveOffer) {
      childContent = (
        <div className="slide-up mt-2 small-text small-text--warning">
          You had an offer at higher price of {currentActiveOffer.price} BNB. By placing this new offer,{" "}
          {minusNumbers(currentActiveOffer.price, offerPrice)} BNB will be returned to your address.
        </div>
      );
      onSubmit = () => sendMoreOfferTx(currentActiveOffer.price, true);
      submitText = "Update Offer";
    } else if (knight.equipments.length > 0) {
      childContent = (
        <div className="slide-up mt-2 small-text small-text--warning">
          This hero has equipment on. Please be careful with your offer price as the owner can unequip them before
          accepting your offer.
        </div>
      );
      onSubmit = sendOfferTx;
      submitText = "Offer";
    } else {
      onSubmit = sendOfferTx;
      submitText = "Offer";
    }

    content = (
      <div>
        <InputGroup
          className="text-field--large"
          value={offerPrice.toString()}
          handleChange={handleOfferPriceChange}
          symbol="BNB"
          balance={balance.BNB}
          error={inputError}
          tokenImage={bnbLogo}
          label={"BNB Amount"}
        />
        {childContent}
      </div>
    );

    return { content, onSubmit, submitText };
  }

  function renderTakeOfferContent() {
    return (
      <>
        <div>Are you sure to sell this Hero with {props.offerPrice} BNB?</div>
        {inputError && <div className="error-text">{inputError}</div>}
      </>
    );
  }

  function renderCancelOfferContent() {
    return <div>Are you sure to cancel this offer?</div>;
  }

  function renderDelistContent() {
    return <div>Are you sure to delist your Hero from the marketplace?</div>;
  }

  function renderTransferContent() {
    return (
      <AddressInputGroup
        value={receiverInput}
        setAddress={handleSetReceiverAddress}
        handleChange={(event: any) => setReceiverInput(event.target.value)}
        error={inputError}
        label={"Receive Address"}
      />
    );
  }

  function renderChangeNameContent() {
    let content, onSubmit, submitText;
    const raceImage = require(`src/assets/images/icons/races/${knight.race.toLowerCase()}-${knight.gender.toLowerCase()}.png`);

    content = (
      <div>
        <InputGroup
          className="text-field--large"
          value={name.toString()}
          handleChange={handleNameChange}
          symbol="BNB"
          balance={balance.BNB}
          error={inputError}
          tokenImage={raceImage}
          label={"Change Name"}
          secondBalance={balance.FARA}
          secondSymbol="FARA"
          hideMaxBtn={true}
          placeholder="Hero Name"
          isTextField
        />
        <div className="mt-2 fs-3">Charge Fee: {MOONKNIGHT_SERVICE_FEE} FARA</div>
      </div>
    );

    if (needApprove) {
      onSubmit = sendApproveTx;
      submitText = "Approve";
    } else {
      onSubmit = sendChangeNameTx;
      submitText = "Change Name";
    }

    return { content, onSubmit, submitText };
  }

  function renderListContent() {
    return (
      <InputGroup
        className="text-field--large"
        value={listingPrice}
        handleChange={handleListingAmountChange}
        symbol="BNB"
        balance={balance.BNB}
        error={inputError}
        tokenImage={bnbLogo}
        label={"BNB Amount"}
        hideMaxBtn={true}
      />
    );
  }

  function renderPopupContent() {
    let content, onSubmit, submitText;

    if (type === ACTIONS.BUY) {
      content = renderBuyContent();
      onSubmit = sendBuyTx;
      submitText = "Buy";
    } else if (type === ACTIONS.OFFER) {
      const makeOfferData = renderMakeOfferContent();
      content = makeOfferData.content;
      onSubmit = makeOfferData.onSubmit;
      submitText = makeOfferData.submitText;
    } else if (type === ACTIONS.TAKE_OFFER) {
      content = renderTakeOfferContent();
      onSubmit = sendTakeOfferTx;
      submitText = "Take Offer";
    } else if (type === ACTIONS.CANCEL_OFFER) {
      content = renderCancelOfferContent();
      onSubmit = sendCancelOfferTx;
      submitText = "Cancel Offer";
    } else if (type === ACTIONS.LIST) {
      content = renderListContent();
      onSubmit = sendListTx;
      submitText = "List";
    } else if (type === ACTIONS.DELIST) {
      content = renderDelistContent();
      onSubmit = sendDelistTx;
      submitText = "Delist";
    } else if (type === ACTIONS.TRANSFER) {
      content = renderTransferContent();
      onSubmit = sendTransferTx;
      submitText = "Transfer";
    } else if (type === ACTIONS.CHANGE_NAME) {
      const nameChangeData = renderChangeNameContent();
      content = nameChangeData.content;
      onSubmit = nameChangeData.onSubmit;
      submitText = nameChangeData.submitText;
    }

    return { content, onSubmit, submitText };
  }

  const { content, onSubmit, submitText } = renderPopupContent();

  return (
    <BasicModalContent
      onSubmit={onSubmit}
      submitText={submitText}
      content={<div className="text-center">{content}</div>}
    />
  );
}
