import React from "react";
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber, isDemiInactive, isKnightDead } from "src/app/utils/helpers";
import { multiplyNumbers } from "src/app/utils/calculators";
import { BASE_KNIGHT_EXP, KNIGHT_TYPE, STATS_LABEL, SUMMON_DEMI_COST } from "src/app/configs/constants";
import { KnightAttributes } from "src/app/types/attribute";
import soulStoneIcon from "src/assets/images/tokens/soulstone.png";
import { setGlobalModal } from "src/app/actions/globalAction";
import faraLogo from "src/assets/images/tokens/fara.png";
import { DemiKnight } from "src/app/types/demiKnight";
import { denyDemiKnight, registerDemiKnight, drinkSoulstone } from "src/app/actions/demiAction";
import { claimAttribute } from "src/app/services/api/faralandService";
import { HERO_TYPE } from "src/app/configs/constants";
import DemiLifespanTimer from "src/app/components/Demi/Details/DemiLifespanTimer";
import ExpProgressBar from "src/app/components/Commons/ExpProgressBar";
import { KnightStats } from "src/app/types/knight";
import useKnightData from "src/app/hooks/useKnightData";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { resetStats } from "src/app/actions/knightAction";
import useKnightLevelData from "src/app/hooks/useKnightLevelData";

type DemiDataProps = {
  knight: DemiKnight;
  isOwner: boolean;
  slotItems: number[];
  knightStats: KnightStats;
  demiDrinkSoulStone?: string;
};

export default function DemiData(props: DemiDataProps) {
  const dispatch = useDispatch();

  const { knight, knightStats, slotItems, demiDrinkSoulStone } = props;
  const { tokenPrice, modal } = useSelector((state: any) => state.global);

  const [
    statsData,
    totalBaseStats,
    totalItemStats,
    totalAllocatedStats,
    knightLevel,
    setKnightLevel,
    showLevelUpModal,
    showStatsDetailsModal
  ] = useKnightData(knight, knightStats, slotItems, true);
  const latestLevel = knightLevel === 0 ? knight.level : knightLevel;
  const [
    knightExp,
    levelUpAmount,
    convertExpToLevels,
    openIncreaseEXPModal,
  ] = useKnightLevelData(knight.id, KNIGHT_TYPE.DEMI, latestLevel, setKnightLevel, false);
  const demiModalActive = modal.demiModal.active;

  function handleCloseDemiModal() {
    dispatch(setGlobalModal("demiModal"));
  }

  function denyDemi() {
    dispatch(denyDemiKnight(knight.id, handleCloseDemiModal));
  }

  async function register() {
    const result = await claimAttribute(knight.id.toString(), HERO_TYPE.DEMI_KNIGHT);

    if (result.statusCode !== 500) {
      const attributes = {
        knightId: result.data.knightId,
        knightType: HERO_TYPE.DEMI_KNIGHT,
        stats: result.data.stats,
        bodyParts: result.data.bodyParts,
        race: result.data.race,
        gender: result.data.gender,
        element: result.data.element,
        signature: result.sigs,
      };
      dispatch(registerDemiKnight(attributes, dispatch(setGlobalModal("txTracking"))));
    } else {
      modalService.show(BasicModalContent, {
        title: "Error",
        content: <div>{result.message ? result.message : "Error activating Demi Hero"}</div>
      });
    }
  }

  function consumeSoulStone() {
    dispatch(drinkSoulstone(knight.id, dispatch(setGlobalModal("txTracking"))));
  }

  function sendResetStatsTx() {
    dispatch(resetStats(knight.id, KNIGHT_TYPE.DEMI));
  }

  return (
    <div className={`knight-stats ${demiModalActive && "knight-stats--modal"}`}>
      {!demiModalActive && (
        <div className="detail-price">
          <div className="detail-price__container">
            <div>
              <div className="detail-price__price">{knight.price || "-/-"} FARA</div>
              {!!knight.price && (
                <div className="detail-price__dollars">
                  ≈ ${formatNumber(multiplyNumbers(knight.price, tokenPrice.FARA), 2)}
                </div>
              )}
            </div>
            <img className="detail-price__logo" src={faraLogo} alt="BNB" />
          </div>
        </div>
      )}
      <div className="knight-stats__info">
        <div className={`knight-stats__info-general ${demiModalActive && "knight-stats__info-general--modal"}`}>
          <div className="knight-stats__info-item">
            <div className="knight-stats__info-name">Class</div>
            <div className="knight-stats__info-value bot-5">
              <img
                className="knight-stats__info-icon"
                src={require(`src/assets/images/icons/races/${knight.race.toLowerCase()}-${knight.gender.toLowerCase()}.png`)}
                alt=""
              />
              <span className="top-1">{knight.displayRace}</span>
            </div>
          </div>
          <div className="knight-stats__info-item">
            <div className="knight-stats__info-name">Level</div>
            <div className="knight-stats__info-value top-6">{latestLevel}</div>
          </div>
          <div className="knight-stats__info-item">
            <div className="knight-stats__info-name">Element</div>
            <div className="knight-stats__info-value top-1">
              <img
                className="knight-stats__info-icon knight-stats__info-icon--small"
                src={require(`src/assets/images/hero-details/type/${knight.element.toLowerCase()}.png`)}
                alt={knight.element}
              />
              <span>{knight.element}</span>
            </div>
          </div>
          <div className="knight-stats__info-item flex-wrap">
            <div className="knight-stats__info-name">Status</div>
            <div className="knight-stats__info-value top-4">
              {isKnightDead(knight.owner) ? "Dead" : +knight.lifespan > 0 ? "Active" : "Inactive"}
            </div>
          </div>
        </div>
      </div>

      <ExpProgressBar
        currentExp={knightExp}
        requiredExp={latestLevel * BASE_KNIGHT_EXP}
        levelUpAmount={levelUpAmount}
        isOwner={props.isOwner}
        levelUpText={`Convert your EXPs to ${levelUpAmount} levels`}
        convertExpToLevels={convertExpToLevels}
        openIncreaseEXPModal={openIncreaseEXPModal}
        isExpired={isDemiInactive(knight.lifespan).isExpired}
      />

      <div className="stats mt-5">
        <div className="flex-center-start">
          <div className="stats__title">
            <span>Stats ({totalBaseStats} </span>
            {totalItemStats + totalAllocatedStats !== 0 && (
              <span className="stats__title-bonus"> + {totalItemStats + totalAllocatedStats}</span>
            )}
            <span>)</span>
          </div>
          <div className="stats__btn-container">
            <div className="btn stats__btn" onClick={() => showStatsDetailsModal(knightStats)}>
              Details
            </div>
            {props.isOwner && (
              <div className="btn stats__btn" onClick={showLevelUpModal}>
                Increase Stats
              </div>
            )}
            {props.isOwner && totalAllocatedStats > 0 && (
              <div className="btn stats__btn" onClick={sendResetStatsTx}>
                Reset Stats
              </div>
            )}
          </div>

        </div>
        <div className="stats__container">
          {Object.values(STATS_LABEL).map((stats: any, index: number) => {
            const data = statsData[stats] ?? {};
            return (
              <div className="stats__item" key={index}>
                <div className="stats__name">{data.label}</div>
                <Tooltip title={data.tooltip ?? ""} placement="bottom">
                  <div className="stats__value">
                    <img
                      src={require(`src/assets/images/hero-details/stats/${stats.toLowerCase()}.svg`)}
                      alt={data.label}
                    />
                    <div>
                      {data.baseValue}
                      {data.itemStatsValue !== 0 && <span className="text-17"> + {data.itemStatsValue}</span>}
                    </div>
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div>
      <div className="knight__body-parts">
        <div className="knight__title">Body Parts</div>
        <div className="body-part">
          {knight.attributes.map((attributes: KnightAttributes, index: number) => (
            <div className="body-part__item--demi" key={index}>
              <Tooltip title={attributes.traitType} placement="top">
                <img
                  src={require(`src/assets/images/hero-details/attributes/${knight.displayRace}/${attributes.traitType}.png`)}
                  alt={attributes.traitType}
                  width={60}
                />
              </Tooltip>
              <div className="body-part__content">
                <div className="body-part__name">{attributes.value}</div>
                <div className={`body-part__rarity rarity-${attributes.displayRarity.toLowerCase()}`}>
                  {attributes.displayRarity}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {demiModalActive ? (
        <div className="demi-summon__modal-buttons flex-center-between flex-start-none mt-10">
          <div className="btn btn--large pt-3 pb-3 mb-3" onClick={handleCloseDemiModal}>
            Back
          </div>
          <div className="flex-column-center">
            <div className="btn btn--large pt-3 pb-3" onClick={denyDemi}>
              Sacrifice
            </div>
            <div className="mb-2 mt-1">
              <span className="text-orange">Sacrifice</span> this demi to get back{" "}
              <span className="text-orange">{SUMMON_DEMI_COST.DENY}</span>{" "}
              <img className="demi-summon__small-icon" src={soulStoneIcon} alt="" />
            </div>
          </div>
        </div>
      ) : props.isOwner && (
        <>
          <div className={`demi-summon__modal-buttons ${knight.registered && "demi-summon__modal-buttons--drink"} flex-start-between mt-5`}>
            {!knight.registered && (
              <div className="btn btn--large pt-3 pb-3 mb-3" onClick={register}>
                Activate
              </div>
            )}
            <div className="flex-column-center">
              {knight.registered && (
                <>
                  <div className="btn btn--large pt-3 pb-3 mb-3" onClick={consumeSoulStone}>
                    Consume Soulstone
                  </div>
                  <div className="demi-summon__text--drink mb-2 fs-4">
                    Use<span className=" ml-1 text-orange">{SUMMON_DEMI_COST.LIFE_PER_DRINK * (+demiDrinkSoulStone! + 1)}</span>
                    <img
                      className="demi-summon__icons demi-summon__icons--drink ml-1 mr-1"
                      src={soulStoneIcon}
                      alt=""
                    />
                    to extend this Demi's life for <b>{SUMMON_DEMI_COST.LIFE_PER_DRINK} days</b>
                    <div className="demi-summon__text--drink mb-2 fs-4">
                      <span className="text-orange">{demiDrinkSoulStone}</span> time(s) Soul Stone consumed
                    </div>
                  </div>
                  <DemiLifespanTimer lifespan={knight.lifespan} />
                </>
              )}
            </div>
            {!knight.registered && (
              <div className="flex-column-center ml-5">
                <div className="btn btn--large pt-3 pb-3" onClick={denyDemi}>
                  Sacrifice
                </div>
                <div className="mb-2 mt-1">
                  <span className="text-orange">Sacrifice</span> this demi to get back <span className="text-orange">{SUMMON_DEMI_COST.DENY}</span> <img className="demi-summon__small-icon" src={soulStoneIcon} alt="" />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
