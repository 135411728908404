import React from "react";
import { LandTransaction } from "src/app/types/land";
import MarketTable from "src/app/components/Commons/MarketTable";

type TransactionHistoryProps = {
  transactions: LandTransaction[];
};

export default function LandHistoryTable(props: TransactionHistoryProps) {
  const transactions = props.transactions;
  const isEmpty = transactions.length === 0;

  return (
    <div className={`cm-table ${isEmpty ? "cm-table--empty" : ""} mt-7`}>
      <div className="cm-table__title">Sale History</div>
      <MarketTable
        headerTitles={["Action", "Price", "From", "To", "Time"]}
        cellTypes={["action", "price", "address", "address", "time"]}
        cellKeys={["type", "price", "from", "to", "timestamp"]}
        isEmpty={isEmpty}
        orderData={props.transactions}
        emptyMessage="No History Yet"
        isMyOrder={false}
        myOrderData={false}
      />
    </div>
  );
}
