import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { marketClient } from "src/app/services/subgraph/client";
import { List, Transaction } from "src/app/types/demiKnight";
import { getDemiModalTitle, mapGender } from "src/app/utils/mappingHelpers";
import { INTERVAL, KNIGHT_TYPE } from "src/app/configs/constants";
import { createDemiKnight, createDemiKnightStats, createTx } from "src/app/factories/knightFactory";
import { Lottie } from "@crello/react-lottie";
import { calculateStatsFromItems, getAnimatedJsonOptions } from "src/app/utils/helpers";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import { fetchDemiKnightStatsById, fetchKnightOffChainEquipment } from "src/app/services/api/faralandService";
import DemiPreview from "src/app/components/Demi/Details/DemiPreview";
import { DemiKnight } from "src/app/types/demiKnight";
import DemiData from "src/app/components/Demi/Details/DemiData";
import { DEMI_INFO } from "src/app/services/subgraph/queries";
import { updateKnightStats } from "src/app/utils/converters";
import { KnightStats } from "src/app/types/knight";
import useKnightSkills from "src/app/hooks/useKnightSkills";
import DemiActionModal from "src/app/components/Demi/Details/DemiActionModal";
import DemiHeaderButtons from "src/app/components/Demi/Details/DemiHeaderButtons";
import DemiHistoryTable from "src/app/components/Demi/Details/DemiHistoryTable";
import useFetchSpaceDomain from "src/app/hooks/useFetchSpaceDomain";

type DemiDetailsProps = {
  demiId?: any;
  match?: any;
};

export default function DemiDetails(props: DemiDetailsProps) {
  const { demiId } = props;
  const { address } = useSelector((state: any) => state.account);
  const { web3Service, modal } = useSelector((state: any) => state.global);
  const fetchingInterval = useRef<any>();

  const [demiKnight, setDemiKnight] = useState<DemiKnight>();
  const [knightStats, setKnightStats] = useState<KnightStats>();
  const [demiDrinkSoulStone, setDemiDrinkSoulStone] = useState<string>();
  const [currentList, setCurrentList] = useState<List>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isOwner, setIsOwner] = useState(false);
  const [slotItems, setSlotItems] = useState([]);
  const { domains, getAddresses } = useFetchSpaceDomain();

  const demiModalActive = modal.demiModal.active;
  const paramId = props.match?.params?.id;
  const id = demiModalActive ? demiId : paramId;
  const { renderSkillTree } = useKnightSkills(id, true, demiKnight);

  useEffect(() => {
    window.scrollTo(0, 0);

    if ((paramId || demiId) && web3Service) {
      fetchDemiAndMarketData();

      if ((demiModalActive && demiId) || paramId) {
        fetchingInterval.current = setInterval(() => {
          fetchDemiAndMarketData();
        }, INTERVAL.DEMI_DETAILS);
      }
    }

    return () => {
      clearInterval(fetchingInterval.current);
      setDemiKnight(undefined);
    };
  }, [web3Service, paramId, demiId, address, demiModalActive, domains]); // eslint-disable-line

  useEffect(() => {
    const isOwner = !!demiKnight && !!demiKnight.owner && demiKnight.owner.toLowerCase() === address;
    setIsOwner(isOwner);
  }, [demiKnight, address]);

  async function fetchDemiAndMarketData() {
    try {
      if (!demiModalActive && !paramId) return;

      // Fetch knight data from API.
      const knightFromApi = await fetchDemiKnightStatsById(id);
      if (!knightFromApi) return;
      // Fetch knight data directly from SC.
      const knightFromSC = await web3Service.fetchKnight(id, KNIGHT_TYPE.DEMI);
      const knightOffChainData = await fetchKnightOffChainEquipment(id, true);
      // Re-calculate stats of a knight from SC.
      const knightLevel = knightFromSC.level;
      const knightGender = mapGender(knightFromApi.igcharacter.gender);
      const knightRace = knightFromApi.igcharacter.raceId;
      const { itemStats, itemSetBonus, equipments } = calculateStatsFromItems(
        knightLevel,
        knightGender,
        knightRace,
        knightFromApi.isOffChain ? knightOffChainData : knightFromSC.equipment
      );

      // Update knight stats with data from SC.
      const updatedKnightData = createDemiKnightStats({
        ...knightFromApi,
        level: knightLevel,
        itemStats,
        itemSetBonus,
      });
      await updateKnightStats(id, knightLevel, updatedKnightData, setKnightStats, web3Service, true);

      // Set SS consumed.
      const soulStoneConsumed = await web3Service.fetchSoulStoneConsumed(id);
      setDemiDrinkSoulStone(soulStoneConsumed);

      // Fetch knight data from Subgraph.
      const result = await marketClient.query({
        query: DEMI_INFO,
        variables: { id },
        fetchPolicy: "network-only",
      });
      const knightFromSubgraph = result.data.demiKnight ?? null;
      const dataLists = result.data.demiLists ?? [];
      const dataTransactions = result.data.demiTransactions ?? [];
      if (knightFromSubgraph) {
        const knight = createDemiKnight({
          ...knightFromApi,
          equipments,
          level: knightLevel,
          gender: knightGender,
          race: knightRace,
          lifespan: knightFromSC.lifeTime,
          registered: knightFromSubgraph.registered,
          hasOffchainEquipment: knightOffChainData.filter((data) => data !== 0).length > 0,
        });
        setDemiKnight(knight);
      }

      getAddresses(dataTransactions, []);
      if (dataLists && dataLists[0]) setCurrentList(dataLists[0]);
      if (dataTransactions) {
        setTransactions(
          dataTransactions
            .filter((record: any) => {
              return record.type !== "offer";
            })
            .map((record: any) => {
              return createTx(record, domains);
            })
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onOpenPopup(type: number) {
    modalService.show(DemiActionModal, {
      title: getDemiModalTitle(type),
      type: type,
      demi: demiKnight,
      currentList: currentList,
    });
  }

  return (
    <div className={`knight container slide-up ${demiKnight === undefined && "knight--loading"}`}>
      {demiKnight !== undefined ? (
        <Grid container spacing={4}>
          {!demiModalActive && (
            <Grid item xs={12} md={12} lg={12}>
              <DemiHeaderButtons onOpenPopup={onOpenPopup} demi={demiKnight} isOwner={isOwner} />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={6}>
            {knightStats !== undefined && (
              <DemiPreview
                demi={demiKnight}
                knightStats={knightStats}
                onOpenPopup={onOpenPopup}
                isOwner={!demiModalActive ? isOwner : false}
                slotItems={slotItems}
                setSlotItems={setSlotItems}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {knightStats !== undefined && (
              <DemiData
                knight={demiKnight}
                knightStats={knightStats}
                demiDrinkSoulStone={demiDrinkSoulStone}
                isOwner={!demiModalActive ? isOwner : false}
                slotItems={slotItems}
              />
            )}
          </Grid>

          {!demiModalActive && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                {renderSkillTree()}
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <DemiHistoryTable transactions={transactions} />
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <Lottie className="market__loading slide-up" config={getAnimatedJsonOptions(loadingJson)} />
      )}
    </div>
  );
}
