import * as React from "react";
import teamImage from "src/assets/images/instructions/team-image.png";
import team1 from "src/assets/images/instructions/team-1.png";
import team2 from "src/assets/images/instructions/team-2.png";
import team3 from "src/assets/images/instructions/team-3.png";
import Title from "../Commons/Title";

const BasicTeam = () => {
  const teams = [
    {
      team: team1,
    },
    {
      team: team2,
    },
    {
      team: team3,
    },
  ];
  return (
    <div className="instructions-teams">
      <Title value="Basic Teams for Beginners" />
      <img className="instructions-teams__image" src={teamImage} alt="" />

      <div className="instructions-teams__team">
        {teams.map((value, index) => (
          <img src={value.team} key={index} alt="" />
        ))}
      </div>
    </div>
  );
};

export default BasicTeam;
