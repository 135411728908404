import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import faraLogo from "src/assets/images/tokens/fara.png";
import { marketClient } from "src/app/services/subgraph/client";
import { MATERIAL_INFO, MATERIAL_OWN_INFO } from "src/app/services/subgraph/queries";
import { INTERVAL, NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";
import { roundNumber } from "src/app/utils/helpers";
import { multiplyNumbers } from "src/app/utils/calculators";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalModal } from "src/app/actions/globalAction";
import { TextField } from "@material-ui/core";
import { Material, UserMaterial, MaterialTransactions } from "src/app/types/materials";
import { createMaterial, createListMaterial, createMaterialTransaction } from "src/app/factories/materialFactory";
import { createOfferMaterial } from "src/app/factories/materialFactory";
import { createUserMaterial } from "src/app/factories/materialFactory";
import MaterialListingTable from "src/app/components/Materials/Details/MaterialListingTable";
import MaterialOfferTable from "src/app/components/Materials/Details/MaterialOfferTable";
import MaterialHistoryTable from "./MaterialHistoryTable";
import MaterialIconPreview from "../Commons/MaterialIconPreview";
import { MATERIALS } from "src/app/configs/materials/materials";
import { ListItem, OfferItem } from "src/app/types/equipment";
import { renderBackBtn } from "src/app/utils/renderHelpers";
import useFetchSpaceDomain from "src/app/hooks/useFetchSpaceDomain";
import { queryTotalAmount } from "src/app/services/api/subgraphService";

export default function MaterialDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const { tokenPrice } = useSelector((state: any) => state.global);
  const [material, setMaterial] = useState<Material>();
  const [offers, setOffers] = useState<OfferItem[]>([]);
  const [lists, setLists] = useState<ListItem[]>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [transactions, setTransactions] = useState<MaterialTransactions[]>([]);
  const [materialBalance, setMaterialBalance] = useState<number>(0);
  const [userMaterials, setUserMaterials] = useState<UserMaterial[]>([]);
  const [ownOffers, setOwnOffers] = useState<OfferItem[]>([]);
  const [ownLists, setOwnLists] = useState<ListItem[]>([]);
  const { domains, getAddresses } = useFetchSpaceDomain();
  const materialInfo: Material = MATERIALS[id];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    _getMaterialData(address);
    _getMaterialOwnData();

    const interval = setInterval(() => {
      _getMaterialData(address);
      _getMaterialOwnData();
    }, INTERVAL.MATERIAL_DETAILS);

    return () => clearInterval(interval);
  }, [web3Service, address, id, domains]); // eslint-disable-line

  async function _getMaterialData(address: string) {
    try {
      if (!id) return;
      let result, dataOffers, dataLists, dataTransactions, dataMaterial;

      result = await marketClient.query({
        query: MATERIAL_INFO,
        variables: { id, address },
        fetchPolicy: "network-only",
      });
      const totalEquipmentAmount = await queryTotalAmount(id, NON_FEE_INVENTORY_TYPE.MATERIAL);
      dataMaterial = result.data.material ?? {};
      dataOffers = result.data.offerMaterials ?? [];
      dataLists = result.data.listMaterials ?? [];
      dataTransactions = result.data.materialMarketplaceTransactions ?? [];

      getAddresses(dataOffers, [dataLists, dataTransactions]);
      if (dataMaterial) setMaterial(createMaterial(dataMaterial));
      if (dataOffers) setOffers(dataOffers.map((o: any) => createOfferMaterial(o, domains)));
      if (dataLists) setLists(dataLists.map((l: any) => createListMaterial(l, domains)));
      if (dataTransactions) setTransactions(dataTransactions.map((t: any) => createMaterialTransaction(t, domains)));
      if (totalEquipmentAmount) {
        setTotalAmount(totalEquipmentAmount.totalAmount);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function _getMaterialOwnData() {
    try {
      if (!id || !address) return;
      let result, dataOffers, dataLists, dataUserMaterials;

      result = await marketClient.query({
        query: MATERIAL_OWN_INFO,
        variables: { id, address },
        fetchPolicy: "network-only",
      });

      dataOffers = result.data.offerMaterials ?? [];
      dataLists = result.data.listMaterials ?? [];
      dataUserMaterials = result.data.userOwnMaterials ?? [];

      if (dataOffers) setOwnOffers(dataOffers.map((o: any) => createOfferMaterial(o, domains)));
      if (dataLists) setOwnLists(dataLists.map((l: any) => createListMaterial(l, domains)));
      if (dataUserMaterials) {
        const convertedUserMaterials = dataUserMaterials.map((o: any) => createUserMaterial(o));
        setUserMaterials(convertedUserMaterials);
        const userMaterialData = convertedUserMaterials.find((i: UserMaterial) => i.material.id === +id);
        if (userMaterialData) {
          setMaterialBalance(userMaterialData.amount);
        } else {
          setMaterialBalance(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function openTransferModal() {
    dispatch(
      setGlobalModal("transferMaterials", {
        active: true,
        data: {
          selectedMaterial: createUserMaterial({
            id: material?.id,
            user: { id: address },
            material: createMaterial({ id: material?.id }),
            amount: materialBalance,
          }),
        },
      })
    );
  }

  return (
    <div className="material container slide-up">
      <div className="material__header">
        <div className="material__column">
          {renderBackBtn(history)}
          <div className="material__id">
            <div className={`material__info-tag material__info-tag--small`}>#{material?.id}</div>
          </div>
          <div className="flex-center-start material__balance">
            <div className="flex-column mr-5">
              <TextField variant="outlined" label="BALANCE" value={materialBalance} disabled />
            </div>
            <div className="flex-column">
              <div className="material__balance-title">AVAILABLE</div>
              <div className="material__balance-value">
                {material && +material.minted! - +material.burned! - 1 - totalAmount}
              </div>
            </div>
          </div>
          <div className="material__name">{material?.name}</div>

          <MaterialIconPreview material={materialInfo!} className="center-margin" largeImage={true} />
        </div>

        <div className="material__column">
          <div className="material__header-data">
            <div className="material__header-buttons">
              {materialBalance !== 0 && (
                <div className="btn ml-3 slide-up" onClick={openTransferModal}>
                  Transfer
                </div>
              )}
            </div>

            <div className="detail-price">
              <div className="detail-price__container">
                <div>
                  <div className="detail-price__price">
                    {lists[0]?.price ? roundNumber(lists[0]?.price, 2) : "--/--"} FARA
                  </div>
                  {lists[0]?.price && (
                    <div className="detail-price__dollars">
                      ≈ ${roundNumber(multiplyNumbers(lists[0].price, tokenPrice.FARA), 2)}
                    </div>
                  )}
                </div>
                <img className="detail-price__logo" src={faraLogo} alt="FARA" />
              </div>
            </div>
          </div>

          <div className="material__block stats">
            {materialInfo && (
              <div className="material__block-info mb-5" dangerouslySetInnerHTML={{ __html: materialInfo.desc }} />
            )}
          </div>
        </div>
      </div>

      <div className="material__container mt-10">
        <div className="material__column">
          <MaterialListingTable
            lists={lists}
            offers={offers}
            material={material!}
            ownLists={ownLists}
            userMaterials={userMaterials}
          />
        </div>
        <div className="material__column">
          <MaterialOfferTable
            lists={lists}
            offers={offers}
            material={material!}
            ownOffers={ownOffers}
            userMaterials={userMaterials}
          />
        </div>
      </div>
      <MaterialHistoryTable transactions={transactions} />
    </div>
  );
}
