import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { formatAddress } from "src/app/utils/helpers";
import { ZERO_ADDRESS } from "src/app/configs/constants";

type AddressInputGroupProps = {
  handleChange: any;
  error: string;
  setAddress: (value: string) => void;
  value?: string;
  label?: string;
};

export default function AddressInputGroup(props: AddressInputGroupProps) {
  const { web3Service } = useSelector((state: any) => state.global);
  const { handleChange, error, setAddress, value, label } = props;
  const [addressFromDomain, setAddressFromDomain] = useState("");

  useEffect(() => {
    if (!web3Service) return;
    async function getAddressFromDomainName(name: string | undefined) {
      if (!name) {
        setAddressFromDomain("");
        return;
      }
      try {
        if (name.match(/^[^.]+\.bnb$/)) {
          const address = await web3Service.sid.name(value).getAddress();
          if (address !== ZERO_ADDRESS) {
            setAddressFromDomain(address);
            setAddress(address);
          }
        } else {
          setAddress(name);
        }
      } catch (e) {
        console.log(e);
      }
    }
    setAddressFromDomain("");
    getAddressFromDomainName(value);
  }, [value, web3Service, setAddress]);

  return (
    <div>
      <div className="text-field addressInput">
        <TextField
          value={value}
          onChange={handleChange}
          label={label || "Receive Address"}
          type="string"
          variant="outlined"
          fullWidth
          size="medium"
          placeholder="0x..."
          InputLabelProps={{ shrink: true }}
          error={!!error}
        />
      </div>
      {addressFromDomain && <div className="warning-text">Wallet Address: {formatAddress(addressFromDomain, 4)}</div>}
      {!!error && <div className="error-text">{error}</div>}
    </div>
  );
}
