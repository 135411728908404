import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BodyPart from "src/app/components/Home/DemoCharacters/Hero/Bodypart";
import { heroes } from "src/app/components/Home/DemoCharacters/Hero/Heroes";

const CharacterDescription = (props: any) => {
  const { index } = props;
  const activeHero = heroes[index];

  const [job, setJob] = useState(0);

  useEffect(() => {
    setJob(0);
  }, [props.index]);

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" className="hero__race">
        {activeHero.race === "Dragonborn" ? (
          <>
            <div className="hero__race-name hero__race-name--dragon">{activeHero.race}</div>
            <div className="hero__race-rate hero__race-rate--dragon">Rate {activeHero.heroRate}</div>
          </>
        ) : (
          <>
            <div className="hero__race-name">{activeHero.race}</div>
            <div className="hero__race-rate">Rate {activeHero.heroRate}</div>
          </>
        )}
      </Box>
      <Box display="flex" gridGap={20} alignItems="center" className="hero__bio-wrapper">
        <Box key={job} flexBasis="50%" className="hero__bio slide-up">
          <p>
            {activeHero.jobs[job].desc}
          </p>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="space-around" flexBasis="50%">
          {activeHero.jobs[job].skills.map((s, idx) => (
            <Box key={idx}>
              <BodyPart
                race={activeHero.race}
                job={activeHero.jobs[job].name}
                skill={s}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" className="hero__classes">
        {activeHero.jobs.map((c, idx) => (
          <Box key={idx} className={`hero__classes-class ${idx !== job && 'hero__classes-class--inactive'}`} onClick={() => setJob(idx)}>
            <Box width="100%" textAlign="center" pl={1} pr={1}>{c.name}</Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default CharacterDescription;
