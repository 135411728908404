import { QUESTION_CATEGORY } from "src/app/configs/constants";
import { mapStringToLink } from "src/app/utils/mappingHelpers";

export const faralandQuestions = [
  {
    category: QUESTION_CATEGORY.FARALAND,
    summary: "What is Faraland?",
    answer:
      "Faraland is a role-playing strategy war game for Android and iOS, developed on blockchain with NFT technology by MoonKnight Labs. The players control NFT characters of 7 races: Humans, Orcs, Elves, Fairies, Dragonborns, Angels and Demons — each with different abilities and skills, to fight with other players in a universe called Faraland. Each hero is unique and is indistinguishable from other NFTs. With a lot of exciting use cases, users can use their NFT heroes in many unique ways, from equipping them with powerful weapons or shining armors to having them engage in various thrilling battles. We also focus on the 'play to earn' mechanism which makes Faraland far beyond any traditional game. You can reinvest in the game, make your hero stronger, or cash out for fiat.",
  },
  {
    category: QUESTION_CATEGORY.FARALAND,
    summary: "What's Faraland's vision and mission?",
    answer:
      "Faraland’s vision is to become the biggest turn-based strategy game on Blockchain in general and Binance Smart Chain in particular.",
  },
  {
    category: QUESTION_CATEGORY.FARALAND,
    summary: "When was the idea behind Faraland born?",
    answer: `Faraland is inspired by Warcraft, Pokemon and Final Fantasy. Our childhood is deeply linked to these games and our dream is to make our own fantasy themed game one day and now it’s the time. We have been planning to make this game for three years and have already finished the novel of Faraland story. We will soon release a comic of Faraland also. \n\n
    Faraland is a large world with different races, each race has its own strengths, each character is unique. In Faraland, users will be warriors with brave hearts, fighting for the peace of mankind.`,
  },
];

export const heroQuestions = [
  {
    category: QUESTION_CATEGORY.HERO,
    summary: "How to buy heroes",
    answer: `Summoning heroes was done already and you can ONLY buy/sell heroes here: ${mapStringToLink(
      "https://faraland.io/marketplace"
    )}`,
  },
  {
    category: QUESTION_CATEGORY.HERO,
    summary: "Can the current hero's characteristics (hair, body color, eyes, etc.) be exchanged for other characters?",
    answer: "No they cannot be exchanged",
  },
  {
    category: QUESTION_CATEGORY.HERO,
    summary:
      "If I don't have a specific hero (Dragon, Fairy, Angel, Demon), etc., are there problems that I can't solve (success of a specific stage, etc.)?",
    answer: "You can hire heroes",
  },
  {
    category: QUESTION_CATEGORY.HERO,
    summary:
      "What is the difference between NFT heroes and non-nft Heroes? How are NFT heroes better than non-NFT heroes?",
    answer:
      "Non-NFT heroes will be weaker than NFT heroes and limited in some features & they can not be traded.Free heroes will start with rank D and maximum they can get is rank A while NFT heroes are rank S.",
  },
];

export const tokenQuestions = [
  {
    category: QUESTION_CATEGORY.TOKEN,
    summary: "How to buy FARA Tokens",
    answer: `STEP 1: Go to pancakeswap page with FARA token address: ${mapStringToLink(
      "https://pancakeswap.finance/swap?outputCurrency=0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1"
    )}
    
    - Note: Please make sure you purchase on/buy from our official token address. Be careful with the scam, we have only 1 official token address: ${mapStringToLink(
      "https://bscscan.com/address/0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1"
    )}
    - Click the “I understand” option and then click the [Import] button

    STEP 2: Connect your wallet to pancakeswap. You can use 1 of the following wallets in pancake.

    STEP 3: Click “Select a currency” in the [From] field and select the token you want to use to buy FARA token. You can use any token supported by Pancake to buy FARA token.
    
    STEP 4: Input the amount of [From] token. In this example, I use 10 BNB to buy FARA token , so i input 10 BNB in [From] field
    
    Note: Due to the price impact, you may need to raise the “slippage tolerance”. In this example, I change the “slippage tolerance” to 3%, you can adjust by yourself.
    
    STEP 5: If you have not used BNB to buy FARA before, you need to approve it by clicking on the [Approve] button and then confirm the transaction on your wallet

    STEP 6: Click [Swap] button then Confirm on your wallet

    STEP 7: Wait for tx to be mined

    STEP 8: Done, you have successfully bought FARA tokens! Congratulations!
    `,
  },
  {
    category: QUESTION_CATEGORY.TOKEN,
    summary: "What is Faraland's token address?",
    answer: `We have only 1 official token address: ${mapStringToLink(
      "https://bscscan.com/address/0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1"
    )}`,
  },
  {
    category: QUESTION_CATEGORY.TOKEN,
    summary: "What is the use of FARA token?",
    answer: `FARA tokens will be used for many purposes:
    - Purchase equipment for their Hero under BEP1155 NFT
    - Upgrade their equipment to a higher tier
    - Stake tokens for EXP farming for their Hero
    - Give a unique name to your Hero 
    - Reward for seasonal events and other gaming activities
    - Vote for various gaming parameters in DAO`,
  },
];

export const stakeQuestions = [
  {
    category: QUESTION_CATEGORY.STAKE,
    summary: "How to stake FARA?",
    answer:
      "Faraland is a game with the form of “play to earn”. The most direct way to earn money in Faraland is staking. Users will stake with the FARA token.",
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary: "Where to Stake FARA?",
    answer: `Users can stake at [STAKE] section of Faraland homepage (faraland.io). Link: ${mapStringToLink(
      "https://faraland.io/staking"
    )}`,
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary: "Can we unstake heroes in option 2 (3 months) and option 3 (6 months)?",
    answer:
      "No, you cannot unstake hero. Once you stake your funds in option 2 or option 3, it will automatically be locked in 3 or 6 months respectively.",
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary: "During the lock-up period, Can NFT characters can go hunting or level up and experience the game?",
    answer: "Yes, basically the heroes are still in your wallet. You can freely use them to experience the game.",
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary: "When can I claim my APR reward?",
    answer: "You can claim your FARA and EXP reward anytime",
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary:
      "I stake fara tokens by 6 locked months to level up for hero A. Then after 1–2 months, I want to change to the other hero B to level up with my locked tokens. Is this ok?",
    answer:
      "The EXP will be attached to the NFT hero that you choose. You have to put in another fund to stake your new hero.",
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary:
      "I stake fara tokens by 6 locked months to take level up for hero A. Then after 1–2 months, I want to change to the other hero B for level up with my locked tokens. Is this ok?",
    answer:
      "The EXP will be attached to the NFT hero that you choose. You will have to put in another fund to stake your new hero.",
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary: "If I stake 6000 FARA with option-3 to pool in 6 months, how many lottery tickets do I have?",
    answer: "You will have 2 tickets each month, for a total of 12 lottery tickets in 6 months.",
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary:
      "If I stake 3000 FARA, will I win Option 2 and Option 3 at the same time? Or do I automatically win Option 3 if I stake over 3000 FARA?",
    answer: "No, you have to choose the option to stake first since it affects the time lock.",
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary:
      "Is the equipment received as a staking reward affected by which Hero (Human, Orc, Elf, etc.) is staked? (When staking to an angel, the probability of receiving angel-only equipment increases, etc…)",
    answer:
      "No, the rewards will not be affected by which hero you choose to stake. Plus, the items can be used interchangeably for all races for now.",
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary: "In which part of token allocation is the reward from staking distributed?",
    answer: "From Game Incentive, and later on from Gacha Machine",
  },
  {
    category: QUESTION_CATEGORY.STAKE,
    summary:
      "I’m staking FARA to level up my hero, so can I list this hero for sale? And what happens when someone takes the offer to own my hero? Or can I transfer my hero (in staking of fara to level up) to my other wallet and this hero's level is continuously increasing level up with staked fara tokens of the first wallet?",
    answer:
      "Yes, listing/transferring is still available. However, obviously the EXP will be attached to the hero that you sold. In other words, the EXP that you stake with your FARA will be gone along with your sold hero to the new owner.",
  },
];

export const gachaQuestions = [
  {
    category: QUESTION_CATEGORY.GACHA,
    summary: "What is Gacha?",
    answer: "Gacha is place where you can get equipment and weapons for NFT heroes",
  },
  {
    category: QUESTION_CATEGORY.GACHA,
    summary: "How to play Gacha Machine?",
    answer:
      "FARALAND is a multiplayer RPG NFT GAME that lets players own thousands of unique NFT heroes. NFT heroes need more equipment to increase their strength and ability to win in the arena. Participating in the gacha machine will give players the opportunity to own mythical or legendary equipment for a very small amount of money — just luck.",
  },
  {
    category: QUESTION_CATEGORY.GACHA,
    summary: "Where to play Gacha Machine?",
    answer: `Users can play Gacha at the [GACHA] section of Faraland's homepage (faraland.io) Link: ${mapStringToLink(
      "https://faraland.io/gacha"
    )}`,
  },
  {
    category: QUESTION_CATEGORY.GACHA,
    summary: "What is the price of one spin?",
    answer: "It costs 5 FARA for a single spin",
  },
  {
    category: QUESTION_CATEGORY.GACHA,
    summary: "What is the number of spins per day?",
    answer: "There is no limit to the number of spins for the Gacha Machine",
  },
  {
    category: QUESTION_CATEGORY.GACHA,
    summary: "What is the number of items?",
    answer: "The total number of items in batch 1 is ~180,000, total number of item types: 39",
  },
  {
    category: QUESTION_CATEGORY.GACHA,
    summary: "What is the Gacha rate?",
    answer: `+ 50% for COMMON
      + 35% for UNCOMMON
      + 10% for RARE
      + 4.85% for EPIC
      + 0.1% for LEGENDARY
      + 0.05% for MYTHICAL`,
  },
  {
    category: QUESTION_CATEGORY.GACHA,
    summary: "Are the items in Gacha limited?",
    answer:
      "Some rare equipments will be very limited. Each piece of equipment have limited supply but we will keep releasing new equipments.",
  },
  {
    category: QUESTION_CATEGORY.GACHA,
    summary: "When will the next gacha items get released?",
    answer: "When the current Gacha is sold out completely",
  },
  {
    category: QUESTION_CATEGORY.GACHA,
    summary: "What happens to the FARA tokens spent on gacha?",
    answer: `It will be divided into 4 parts:
    Part 1: 75% of this FARA token amount will be used for game-incentive
    Part 2: 10% of this FARA token amount will be used for staking reward
    Part 3: 5% of this FARA token amount will be used for burning
    Part 4: 10% of this FARA token amount will be used for game development
`,
  },
];

export const expeditionQuestions = [
  {
    category: QUESTION_CATEGORY.EXPEDITION,
    summary: "Will Faraland be a text game?",
    answer: `No, This August expedition text game is the very first part of Faraland’s game ecosystem.
    
    In this text game, Heroes will explore the map, do the quests as demonstrated in the demo video: choose options when encounter a specific events, solve puzzles, etc.

    The DUEL Arena (Turn-based Combat) will be released in December. The team is designing very attractive effects. You won’t be disappointed!
    `,
  },
];

export const fullGameQuestions = [
  {
    category: QUESTION_CATEGORY.FULL_GAME,
    summary: "When will the game be released?",
    answer: `The first mobile game will be released in Dec 2021 as per our roadmap. Meanwhile you can Buy hero from official Marketplace and level them up by staking fara tokens.
    
    We plan to release the expedition text game soon. It's a text game where you can send your heroes to do missions and earn rewards. The more heroes you send out, the more rewards and more % you can get when you succeed a mission. Hidden missions will also be available. This will release in Aug-Sep 2021.
    `,
  },
];
