import { KnightReducer } from "src/app/types/reducer-state";
import { knightActionTypes } from 'src/app/actions/knightAction';

const initialState: KnightReducer = {
  selectedHero: new Array(10).fill(null),
  equippable: false,
  refresh: false
};

export default function accountReducer(state = initialState, action: any): KnightReducer {
  switch (action.type) {
    case knightActionTypes.SET_EQUIPPABLE: {
      return {
        ...state,
        equippable: action.payload
      }
    }
    case knightActionTypes.SELECT: {
      const index = state.selectedHero.findIndex((selected) => selected === null);
      const cloneSelectedHero = [...state.selectedHero];
      cloneSelectedHero.splice(index, 1, action.payload.knight);
      
      return {
        ...state,
        selectedHero: cloneSelectedHero
      };
    }
    case knightActionTypes.DESELECT: {
      const index = state.selectedHero.findIndex((selected) => selected?.id === action.payload.knight.id);
      const cloneSelectedHero = [...state.selectedHero];
      cloneSelectedHero.splice(index, 1)
      cloneSelectedHero.push(null as never);
      
      return {
        ...state,
        selectedHero: cloneSelectedHero
      };
    }
    case knightActionTypes.RESET_SELECTION: {
      return initialState;
    }
    case knightActionTypes.REFRESH: {
      return {
        ...state,
        refresh: action.payload.refresh
      }
    }
    default:
      return state;
  }
}
