// @ts-nocheck

import { allItems } from "src/app/configs/equipment/allItems";

const genesisItem = {
  '0': {
    "id": 0,
    "name": 'The Genesis Sword',
    "displayName": 'The Genesis Sword',
    "races": ['Angel', 'Demon', 'Dragonborn', 'Elf', 'Fairy', 'Human', 'Orc'],
    "genders": ['Female', 'Male'],
    "requiredLevel": 1,
    "rarity": 5,
    "type": 0,
    "twoHanded": false,
    "maxSupply": 1,
    "tier": 1,
    "upgradeAmount": 0,
    "nextTierId": 0,
    "stats": {
      "Magical Damage": 0,
      "Physical Damage": 1000,
      "Magical Defense": 0,
      "Physical Defense": 1000,
      "STR": 50,
      "AGI": 50,
      "INT": 50,
      "LUK": 50,
      "HP": 1000,
      "Critical": 10,
      "Evade": 10,
      "Speed": 10,
      "effects": [
        {
          "name": "Divine Spike",
          "description": "Reflect 20% of the Melee Physical Damage taken to all enemies in a 7x7 area around the user."
        },
        { "name": "God Slayer", "description": "Increases Physical Attack towards all Races by 100%." },
      ]
    }
  },
}

const biswapDescription = "<div>Partnership Series Collections – Biswap</div><div>These gears are blessed with the power of Biswap Dex (<a class='text-9' href='https://biswap.org/' target='_blank' rel='noreferrer noopener'>Biswap.org</a>)</div>";

allItems["429"].description = biswapDescription;
allItems["430"].description = biswapDescription;

export const EQUIPMENT = { ...genesisItem, ...allItems }