import React, { useState } from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import InputGroup from "src/app/components/Commons/InputGroup";
import AddressInputGroup from "src/app/components/Commons/AddressInputGroup";
import { useSelector, useDispatch } from "react-redux";
import { isAddress } from "web3-utils";
import { setGlobalModal } from "src/app/actions/globalAction";
import { transferEquipment } from "src/app/actions/equipmentAction";
import { UserItem } from "src/app/types/equipment";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { compareNumbers } from "src/app/utils/calculators";
import Modal from "src/app/components/Commons/Modals/Modal";

export default function EquipmentTransferModal() {
  const dispatch = useDispatch();

  const { modal } = useSelector((state: any) => state.global);
  const { address } = useSelector((state: any) => state.account);

  const [receiveAddr, setReceiveAddr] = useState("");
  const [receiverInput, setReceiverInput] = useState("");
  
  const [transferNo, setTransferNo] = useState("");
  const [invalidAddressError, setInvalidAddressError] = useState("");
  const [invalidNumber, setInvalidNumber] = useState("");
  
  const transferEquipmentModal = modal.transferEquipment;
  const selectedItem: UserItem = transferEquipmentModal.data?.selectedItem ?? {};
  
  function handleSetReceiverAddress(value: string) {
    setReceiveAddr(value);
  }

  function sendEquipmentTransferTx() {
    let isAddressCorrect = false;
    let isTransferNoCorrect = false;

    if (!isAddress(receiveAddr)) {
      setInvalidAddressError("Invalid BSC address.");
    } else if (receiveAddr.toLowerCase() === address.toLowerCase()) {
      setInvalidAddressError("The receiving address cannot be the same as your current address.");
    } else {
      isAddressCorrect = true;
    }
    if (compareNumbers(transferNo, selectedItem.available) === 1) {
      setInvalidNumber(`Can only transfer ${selectedItem.available} items at most.`);
    } else if (transferNo === "0" || transferNo === "") {
      setInvalidNumber(`You need to transfer at least 1 item.`);
    } else {
      isTransferNoCorrect = true;
    }

    if (isAddressCorrect && isTransferNoCorrect) {
      dispatch(transferEquipment(
        address,
        receiveAddr,
        +selectedItem.item.id,
        +transferNo,
        () => modalService.close()
      ));
      handleClose();
    }
  }

  function handleClose() {
    dispatch(setGlobalModal("transferEquipment"));
    setReceiveAddr("");
    setTransferNo("");
    setInvalidAddressError("");
    setInvalidNumber("");
  }

  function handleEquipmentTransferChange(e: any) {
    setInvalidNumber("");
    setTransferNo(e.target.value);
  }

  function handleAddressChange(e: any) {
    setInvalidAddressError("");
    setReceiverInput(e.target.value);
  }

  function renderTransferContent() {
    return (
      <>
        <AddressInputGroup
          value={receiverInput}
          setAddress={handleSetReceiverAddress}
          handleChange={handleAddressChange}
          error={invalidAddressError}
          label={"Receiving Address"}
        />
        <InputGroup
          className="text-field text-field--transfer mt-6"
          value={transferNo.toString()}
          handleChange={handleEquipmentTransferChange}
          symbol=""
          balance={selectedItem.available?.toString()}
          error={invalidNumber}
          label={"Number of items"}
          balanceText="Available Amount"
          hideMaxBtn
        />
      </>
    );
  }

  function renderPopupContent() {
    let content, onSubmit, submitText;

    content = renderTransferContent();
    onSubmit = sendEquipmentTransferTx;
    submitText = "Transfer";

    return { content, onSubmit, submitText };
  }

  const { content, onSubmit, submitText } = renderPopupContent();

  return (
    <Modal isActive={transferEquipmentModal.active} onClose={handleClose}>
      <BasicModalContent
        customTitle="Transfer Your Equipment"
        onSubmit={onSubmit}
        submitText={submitText}
        close={handleClose}
        content={<div className="text-center mt-7">{content}</div>}
      />
    </Modal>
  );
}
