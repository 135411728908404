import React, { memo, useState, useEffect } from "react";
import { useQueryString } from "use-route-as-state";
import { getDemiFilterFromParams } from "src/app/utils/filterHelper";

interface toggleProps {
  isLoading: boolean,
  showOwned: boolean,
}

const DemiToggle = memo((props: toggleProps) => {
  const [queryParams, setQueryParams] = useQueryString();
  const [demiFilters, setDemiFilters] = useState<any>(getDemiFilterFromParams(queryParams))
  const owned = demiFilters.owned;
  const staked = demiFilters.staked;

  useEffect(() => {
    const newFilters = getDemiFilterFromParams(queryParams);
    setDemiFilters({...newFilters});
  }, [queryParams]);

  function handleToggleChange(owned: boolean, staked: boolean) {
    return setQueryParams({
      ...queryParams,
      owned: owned.toString(),
      staked: staked.toString()
    });
  }

  return (
    <div className={`market__toggles mb-3 ${props.isLoading ? 'soft-disabled' : ''}`}>
      {props.showOwned && (
        <div className='toggle mr-3'>
          <div>Owned</div>
          <input
            id="owned"
            type="checkbox"
            checked={owned}
            onChange={() => handleToggleChange(!owned, staked)}
          />
          <label htmlFor="owned" />
        </div>
      )}
    </div>
  )
})

export default DemiToggle