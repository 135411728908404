import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAccount, importAccount } from "src/app/actions/accountAction";
import { MOBILE_SCREEN_SIZE, WALLET_TYPE } from "src/app/configs/constants";
import { getWalletParams, checkIsMetamask, checkIsCoin98 } from "src/app/utils/helpers";
import DappService from "src/app/services/accounts/DappService";
import { setGlobalModal } from "src/app/actions/globalAction";

export default function useSettingUpAccount() {
  const dispatch = useDispatch();
  const { address, wallet, spaceDomain } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);

  useEffect(() => {
    detectAndConnectToDapp();
    callSubscribeToDisconnect();
  }, []); // eslint-disable-line

  async function detectAndConnectToDapp() {
    const isWalletExtension = checkIsMetamask() || checkIsCoin98();
    const isMobile = window.innerWidth < MOBILE_SCREEN_SIZE;
    const isWeb3Imported = window.ethereum;

    if (wallet && wallet.needTobeInitiated) {
      const result = await wallet.initiateWallet();
      if (!result) {
        dispatchClearAccount();
        return;
      }
    }

    if (address) {
      const currentAddr = await wallet?.connect(openConnectErrorModal);
      if (!currentAddr) {
        dispatchClearAccount();
      } else if (currentAddr && currentAddr !== address) {
        wallet.getDisconnected(
          dispatchClearAccount,
          (address: string, wallet: any, walletType: string) =>
            dispatch(importAccount(address, wallet, walletType, spaceDomain )),
          wallet
        );
        dispatch(importAccount(currentAddr, wallet, wallet.getWalletType(), spaceDomain ));
      }
    }

    if (isWeb3Imported && (!isWalletExtension || isMobile)) {
      const walletParams = getWalletParams();
      const wallet = new DappService(walletParams);
      const address = await wallet.connect(openConnectErrorModal);
      const spaceDomain = await web3Service?.sid.getName(address);

      if (!address) return;

      dispatch(importAccount(address, wallet, WALLET_TYPE.DAPP, spaceDomain?.name ?? ""));
    }
  }

  function callSubscribeToDisconnect() {
    if (wallet && typeof wallet.subscribeToDisconnect === "function") {
      wallet.subscribeToDisconnect(dispatchClearAccount, dispatchImportAccount, wallet);
    }
  }

  function dispatchImportAccount(address: string, wallet: any, type: string, spaceDomain: string) {
    dispatch(importAccount(address, wallet, type, spaceDomain));
  }

  function dispatchClearAccount() {
    dispatch(clearAccount());
  }

  function openConnectErrorModal(message?: string) {
    dispatch(
      setGlobalModal("error", {
        active: true,
        data: message ? message : "Your session has expired",
      })
    );
  }
}
