import React, { useEffect, useState } from "react";
import {
  STAKING_POOL,
  STAKING_CONFIG
} from "src/app/configs/constants";
import { formatBigNumber, formatNumber } from "src/app/utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { emergencyWithdraw } from "src/app/actions/stakingAction";

type StakingFormProps = {
  knightId: number;
  selectedPool: number;
};

export default function StakingFormEmergency(props: StakingFormProps) {
  const { knightId, selectedPool } = props;

  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const [stakedBalance, setStakedBalance] = useState("0");

  const isLpStaking = selectedPool === STAKING_POOL.DEPRECATED_LIQUIDITY_V1;
  const tokenSymbol = isLpStaking ? STAKING_CONFIG.LIQUIDITY.SYMBOL : STAKING_CONFIG.SINGLE.SYMBOL;

  /** Fetching LP token balance **/
  useEffect(() => {
    if (!web3Service || !address) return;
    fetchStakingData();
  }, [web3Service, address, selectedPool]); // eslint-disable-line

  async function fetchStakingData() {
    const stakedBalance = await web3Service.fetchDeprecatedStakedAmount(address, selectedPool);
    setStakedBalance(formatBigNumber(stakedBalance));
  }

  function withdraw() {
    dispatch(emergencyWithdraw(knightId, selectedPool));
  }

  return (
    <div className="staking-form__container" key={selectedPool}>
      <div className="staking-form__block slide-up">
        <div>Balance: {formatNumber(stakedBalance, 4)} {tokenSymbol}</div>
        <div className={`staking-form__btn btn ${+stakedBalance === 0 ? 'disabled' : ''}`} onClick={withdraw}>
          Withdraw
        </div>
      </div>
    </div>
  )
}