import React, { useEffect, useState } from "react";
import { Item } from "src/app/types/equipment";
import { useDispatch, useSelector } from "react-redux";
import Marketplace from "src/app/components/Commons/Marketplace";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import ENV from "src/app/configs/env";
import soulStoneIcon from "src/assets/images/tokens/soulstone.png";
import { setGlobalModal } from "src/app/actions/globalAction";
import useApprovalForAll from "src/app/hooks/useApprovalForAll";
import { decodeTxInput, formatNumber } from "src/app/utils/helpers";
import HeroBurnABI from "src/app/configs/ABIs/HeroBurn.json";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BurnItemModal from "./BurnItemModal";
import { Knight } from "src/app/types/knight";
import { deselectKnight, knightRefresh, resetSelectedKnight } from "src/app/actions/knightAction";
import { burnKnight } from 'src/app/actions/knightAction';
import { queryMultipleKnights } from "src/app/services/api/subgraphService";
import { HERO_BURNING } from "src/app/configs/constants";

export default function HeroBurn() {
  const dispatch = useDispatch();
  const [needApprovalForAll, sendApproveTx] = useApprovalForAll(ENV.CONTRACT.MOONKNIGHT, ENV.CONTRACT.HERO_BURN);
  const { selectedHero } = useSelector((state: any) => state.knight);
  const { balance, address } = useSelector((state: any) => state.account);
  const [error, setError] = useState("");
  const items: Item[] = HERO_BURNING.ITEMS;

  useEffect(() => {
    dispatch(resetSelectedKnight());
  }, [address]); // eslint-disable-line

  const onBurnSubmit = async () => {
    setError("");
    const heroesSelected = selectedHero.filter((selected: Knight | null) => selected !== null);

    if (needApprovalForAll) {
      sendApproveTx();
      return;
    }

    if (heroesSelected.length < HERO_BURNING.HEROES) {
      setError(`Please select ${HERO_BURNING.HEROES} of your heroes for the ritual`);
      return;
    }

    if (balance.SOUL_STONE < HERO_BURNING.SOUL_STONE) {
      setError("Insufficient Soul Stone Balance");
      return;
    }

    const ids = selectedHero
      .map((selected: Knight | null) => selected?.id)
      .filter((selected) => selected !== undefined);
    const selectedKnights = await queryMultipleKnights(ids);

    if (selectedKnights.some((selected: Knight | null) => selected?.equipments && selected.equipments.length > 0)) {
      setError("A hero you selected still has equipped items");
      return;
    }

    dispatch(burnKnight(ids, onBurnTxSuccess));
  };

  async function onBurnTxSuccess(_txHash: string, [log]) {
    dispatch(setGlobalModal("txTracking"));
    if (log) {
      const decodedData = decodeTxInput([log], HeroBurnABI);
      const itemId = decodedData[0].events[1].value;
      modalService.show(BurnItemModal, { itemId });
      dispatch(resetSelectedKnight());
      setTimeout(() => {
        dispatch(knightRefresh(true));
      }, 30000);
    }
  }

  function handleKnightDeselected(knight: Knight) {
    dispatch(deselectKnight(knight));
  }

  return (
    <div className="container slide-up">
      <div className="hero-burn__title">Burning Rewards</div>
      <div className="flex-center-center">
        {items.map((item: Item, index) => (
          <ItemIconPreview key={index} item={item} isMarketplace isBurn showDetails />
        ))}
      </div>
      <div className="hero-burn">
        <div className="hero-burn__container">
          {selectedHero.map((selected: Knight | null, index) => (
            <div key={index} className="relative">
              {selected ? (
                <div className="slide-up pointer" key={index} onClick={() => handleKnightDeselected(selected)}>
                  <div className="hero-burn__selection hero-burn__selection--hero">
                    <img
                      className="knights__item-character"
                      src={`${ENV.URL.FARALAND}/images/heroes/${selected.id}.png`}
                      alt=""
                    />
                  </div>
                  <div className={`hero-burn__selection-id ${selected.element.toLowerCase()}`}>
                    #{selected.id}
                  </div>
                </div>
              ) : (
                <div key={index} className="hero-burn__selection hero-burn__selection--null">+</div>
              )}
            </div>
          ))}
        </div>
        <div className="hero-burn__selection hero-burn__selection--material">
          <img className="knights__item-character" src={soulStoneIcon} alt="" />
          <p>{formatNumber(balance.SOUL_STONE)} / {formatNumber(HERO_BURNING.SOUL_STONE)}</p>
        </div>
        <div className="hero-burn__description">
          <div className="mb-2">
            <div className="mb-1">
              <div className="mb-1">Burning <b>Heroes</b> and <b>Soul Stones</b> to Earn <b>Mythical Items</b>.</div>
              <div>Hero-burning mechanism will be adopted to remove <b>Heroes</b> from the total supply permanently.</div>
            </div>
            <div className="mb-2">
              The motive for this feature is that as <b>Heroes</b> become scarcer, burning will potentially increase the benefits
              that <b>Heroes</b> offer to their owners.
            </div>
            <div>
              <div className="mb-1 bold">Note:</div>
              <div className="mb-1">- The feature gives equal chance for items regardless of heroes' race and/or level.</div>
              <div className="mb-1">- If the <b>Hero</b> is equipped with any <b>Items</b>, those <b>Items</b> will also be burned with the <b>Hero</b>.</div>
              <div className="mb-1">- There will be 2 pieces of <b>Mythical Items</b>, <b>Armor</b> and <b>Gauntlets</b> of a set at a maxed out level of 3 for all races. <b>Items</b> will be randomly received.</div>
            </div>
          </div>
          {error !== "" && (
            <div className="error-text fs-3 mt-1">{error}</div>
          )}
          <div className={`btn btn--large mt-2`} onClick={onBurnSubmit}>
            {needApprovalForAll ? "Approve" : "Burn"}
          </div>
        </div>
      </div>
      <Marketplace />
    </div>
  );
}
