import { Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import LandActionModal from "src/app/components/Land/LandActionModal";
import { Land, LandListing, LandOffer, LandTransaction } from "src/app/types/land";
import { getLandModalTitle } from "src/app/utils/mappingHelpers";
import { Lottie } from "@crello/react-lottie";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import { fetchLandDetailsById } from "src/app/services/api/faralandService";
import LandPreview from "src/app/components/Land/Details/LandPreview";
import LandData from "src/app/components/Land/Details/LandData";
import { landClient } from "src/app/services/subgraph/client";
import { LAND_INFO } from "src/app/services/subgraph/queries";
import { createLandOffer, createLandTransaction } from "src/app/factories/landFactory";
import { ACTIONS, INTERVAL } from "src/app/configs/constants";
import LandHistoryTable from "src/app/components/Land/Details/LandHistoryTable";
import LandHeaderButtons from "src/app/components/Land/Details/LandHeaderButtons";
import useFetchSpaceDomain from "src/app/hooks/useFetchSpaceDomain";

export default function LandDetails() {
  const { address } = useSelector((state: any) => state.account);
  const fetchingInterval = useRef<any>();
  const { id } = useParams<{ id: string }>();
  const [land, setLand] = useState<Land>();
  const [isOwner, setIsOwner] = useState(false);
  const [offers, setOffers] = useState<LandOffer[]>([]);
  const [isHaveOffers, setIsHaveOffers] = useState(false);
  const [currentActiveOffer, setCurrentActiveOffer] = useState<any | undefined>();
  const [currentList, setCurrentList] = useState<LandListing>();
  const [transactions, setTransactions] = useState<LandTransaction[]>([]);
  const [ownerActiveOffer, setOwnerActiveOffer] = useState<LandOffer>();
  const { domains, getAddresses } = useFetchSpaceDomain();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      _getLandDetails(id);

      fetchingInterval.current = setInterval(() => {
        _getLandDetails(id);
      }, INTERVAL.KNIGHT_DETAILS);
    }

    return () => {
      clearInterval(fetchingInterval.current);
    };
  }, [id, address, domains]); // eslint-disable-line


  useEffect(() => {
    const isOwner = !!land && !!land.owner && land.owner.toLowerCase() === address;
    setIsOwner(isOwner);

    const ownerActiveOffer: any =
      offers && offers.length && address ? offers.find((offer) => offer.buyer === address.toLowerCase()) : null;
    setCurrentActiveOffer(ownerActiveOffer);
    setOwnerActiveOffer(ownerActiveOffer);
    if (offers && offers.length && address) {
      const foundOffer = offers.find((o) => address.toLowerCase() === o.buyer);
      if (foundOffer) setIsHaveOffers(true);
    } else {
      setIsHaveOffers(false);
    }
  }, [land, address, offers]); 
  function onOpenPopup(type: number, takeOfferPrice?: string, buyer?: string) {
    modalService.show(LandActionModal, {
      title: getLandModalTitle(type),
      type: type,
      land: land,
      currentActiveOffer: currentActiveOffer,
      currentList: currentList,
      ownerActiveOffer: ownerActiveOffer,
      isHaveOffers: isHaveOffers,
      offerPrice: takeOfferPrice,
      buyer: buyer,
    });
  }
  function handleTakeOffer(price: string, buyer: string) {
    onOpenPopup(ACTIONS.TAKE_LAND_OFFER, price, buyer);
  }

  async function _getLandDetails(id: string) {
    const landFromAPI = await fetchLandDetailsById(id);
    let landData = {
      ...landFromAPI,
    };
    try {
      const result = await landClient.query({
        query: LAND_INFO,
        variables: { id },
        fetchPolicy: "network-only",
      });
      let landFromSubgraph = result.data.lands ?? [];

      const dataOffers = result.data.offers ?? [];
      const dataLists = result.data.lists ?? [];
      const dataTransactions = result.data.transactions ?? [];
      if (landFromSubgraph && landFromSubgraph[0]) {
        landFromSubgraph = landFromSubgraph[0];
        if (!landData.id) {
          landData.id = +landFromSubgraph.id;
        }
        landData.price = landFromSubgraph.listedPrice;
        landData.owner = landFromSubgraph.owner.id;
      }

      getAddresses(dataOffers, [dataTransactions]);
      if (dataOffers) setOffers(dataOffers.map((o: any) => createLandOffer(o, domains)));
      if (dataLists && dataLists[0]) setCurrentList(dataLists[0]);
      if (dataTransactions) {
        setTransactions(
          dataTransactions
            .filter((record: any) => {
              return record.type !== "transfer";
            })
            .map((record: any) => {
              return createLandTransaction(record, domains);
            })
        );
      }
    } catch (e) {
      console.log(e);
    }

    setLand(landData);
  }
  return (
    <div className="land container slide-up">
      {land !== undefined ? (
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            <LandHeaderButtons land={land} onOpenPopup={onOpenPopup} isOwner={isOwner} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <LandPreview land={land} isOwner={isOwner} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <LandData
              land={land}
              isOwner={isOwner}
              offers={offers}
              isHaveOffers={isHaveOffers}
              onOpenPopup={onOpenPopup}
              handleTakeOffer={handleTakeOffer}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <LandHistoryTable transactions={transactions} />
          </Grid>
        </Grid>
      ) : (
        <Lottie className="market__loading slide-up" config={getAnimatedJsonOptions(loadingJson)} />
      )}
    </div>
  );
}
