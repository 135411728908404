import { TextField } from "@material-ui/core";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTE, RUNE_INDEX } from "src/app/configs/constants";
import { RECIPES } from "src/app/configs/equipment/crafting";
import { CraftingSetRecipe } from "src/app/types/materials";
import { useQueryString } from "use-route-as-state";
import runeFilter from "src/assets/images/rune/rune-filter.png";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const races = ["Human", "Orc", "Angel", "Dragon", "Demon", "Elf", "Fairy"];

export default function CraftingFilter(props) {
  const { recipeId, heroIndex, onOpenInfo } = props;
  const history = useHistory();
  const [queryString, setQueryString] = useQueryString();
  const [filteredRecipes, setFilteredRecipes] = useState<CraftingSetRecipe[]>(Object.values(RECIPES));
  const debounceFilterRecipe = useCallback(_.debounce(filterRecipeByName, 500), [filteredRecipes]);

  useEffect(() => {
    setFilteredRecipes(
      Object.values(RECIPES).filter((r) => {
        if (heroIndex !== undefined) {
          if (
            heroIndex !== RUNE_INDEX &&
            r.name.toLowerCase().includes(queryString["name"] ?? "") &&
            r.race !== "All"
          ) {
            return r.race === races[heroIndex];
          } else if (heroIndex === RUNE_INDEX) {
            return r.race === "Rune";
          }
          return true;
        }
        return true;
      })
    );
  }, [heroIndex, queryString]);

  function filterRecipeByName(name: string) {
    setQueryString({
      ...queryString,
      name: name,
    });

    if (name.length === 0) {
      setFilteredRecipes(Object.values(RECIPES));
      return;
    }

    const filteredByName = Object.values(filteredRecipes).filter((recipe: CraftingSetRecipe) => {
      return recipe.name.toLowerCase().includes(name.toLowerCase()) && recipe.race === races[heroIndex];
    });
    setFilteredRecipes(filteredByName);
  }

  return (
    <div className="item-crafting__filter-container">
      <div className="market__filter item-crafting__filter fade-in">
        <div className="item-crafting__recipe">
          <TextField
            className="input number-input item-crafting__recipe-search"
            label="Search"
            placeholder="Search..."
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => debounceFilterRecipe(e.target.value)}
          />
          <div className="item-crafting__recipe-list nice-scroll">
            {filteredRecipes.length > 0 ? (
              filteredRecipes.map((r) => (
                <div
                  key={r.id}
                  onClick={() => history.replace(ROUTE.EQUIPMENT_CRAFTING.replace(":recipeId", r.id.toString()))}
                  className={`item-crafting__recipe-list-option item-crafting__recipe-list-option--${
                    +recipeId === r.id && "active"
                  }`}
                >
                  <img
                    alt=""
                    src={
                      r.race === "Rune"
                        ? runeFilter
                        : require(`src/assets/images/icons/races/${r.race.toLowerCase()}-${r.gender.toLowerCase()}.png`)
                    }
                    style={{ width: 24 }}
                  />
                  <span className="ml-2">{r.name}</span>

                  {r.race === "Rune" && (
                    <span className="ml-1 top-2" onClick={onOpenInfo}>
                      <HelpOutlineIcon fontSize="small" />
                    </span>
                  )}
                </div>
              ))
            ) : (
              <div>Not Found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
