import React from "react";
import guildImage from "src/assets/images/guild/guild-image.png";
import { Guild } from "src/app/types/guild";

type GuildIconProps = {
  guild: Guild | undefined; 
  largeImage?: boolean;
  isMarketplace?: boolean;
  onLoad?: any;
  className?: any;
};

export default function GuildIconPreview({ guild, largeImage, isMarketplace, onLoad, className }: GuildIconProps) {
  function handleImageLoad() {
    if (onLoad) {
      onLoad(false);
    }
  }

  return (
    <div className={`guild-icon__frame ${className} ${isMarketplace && "guild-icon__frame--marketplace"} ${largeImage && "guild-icon__frame--large"}`}>
      <img
        className={`guild-icon__preview ${isMarketplace && "guild-icon__preview--marketplace"} ${largeImage && "guild-icon__preview--large"}`}
        src={guild?.image}
        alt=""
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = guildImage;
        }}
        onLoad={handleImageLoad}
      />
    </div>
  );
}
