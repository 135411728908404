import React, { useState } from "react";
import ENV from "src/app/configs/env";
import { Tooltip } from "@material-ui/core";
import { Knight, KnightStats } from "src/app/types/knight";
import { ACTIONS } from "src/app/configs/constants";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CloudIcon from "@material-ui/icons/Cloud";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import useKnightPreview from "src/app/hooks/useKnightPreview";
import { isKnightDead } from "src/app/utils/helpers";
import { useSelector } from "react-redux";
import { hideHeroFromPublic } from "src/app/services/api/faralandService";
interface IKnightPreviewProps {
  knight: Knight;
  onOpenPopup: any;
  isOwner: boolean;
  slotItems: any[9];
  setSlotItems: any;
  knightStats: KnightStats;
}

export default function KnightPreview(props: IKnightPreviewProps) {
  const { knight, knightStats, isOwner, slotItems, setSlotItems } = props;
  const { address, wallet } = useSelector((state: any) => state.account);
  const [hideSlots, setHideSlots] = useState(false);
  const [refreshingCount, renderAllSlotItems, renderInventory, renderChibi, renderImageButton, chibiImage] =
    useKnightPreview(knight, slotItems, setSlotItems, isOwner, false, knightStats, knight.isOffChain);
  const isDead = isKnightDead(knight.owner);

  async function handleHide() {
    const signData = await wallet.signData(
      address,
      { knightId: knight.id },
      {
        Group: [{ name: "knightId", type: "uint256" }],
      }
    );

    await hideHeroFromPublic(knight.id.toString(), signData.sign, signData.msgParams);
  }
  return (
    <div className="knight-preview">
      <div className="mb-2">
        <div className={`knights__item-id ${knight.type.toLowerCase()}`}>#{knight.id}</div>
        {isDead && <div className={`knights__item-id dead ml-2`}>Dead</div>}
      </div>
      <div className="knight-preview__wrapper">
        <div className="knight-preview__wrapper-race">
          <span>{knight.displayRace}</span>
          <img
            alt={knight.type}
            src={require(`src/assets/images/hero-details/type/${knight.type.toLowerCase()}.png`)}
            className="knight-preview__wrapper-icon"
          />
        </div>
        <div className="knight-preview__wrapper-name">
          <span>{knight.name ? knight.name : "NO NAME"}</span>
          {isOwner && (
            <>
              <Tooltip title="Change Hero Name" placement="top" arrow>
                <div className="btn knight-preview__wrapper-btn" onClick={() => props.onOpenPopup(ACTIONS.CHANGE_NAME)}>
                  <CreateIcon />
                </div>
              </Tooltip>
              <Tooltip title={knight.isHidden ? `Show Hero To Public` : "Hide Hero From Public"} placement="top" arrow>
                <div
                  className={`btn knight-preview__wrapper-btn ${knight.isHidden && "btn--active"}`}
                  onClick={handleHide}
                >
                  {knight.isHidden ? <CloudOffIcon /> : <CloudIcon />}
                </div>
              </Tooltip>
            </>
          )}
          <Tooltip title={hideSlots ? "Show Item Slots" : "Hide Item Slots"} placement="top" arrow>
            <div
              className={`btn knight-preview__wrapper-btn ${hideSlots && "btn--active"}`}
              onClick={() => setHideSlots(!hideSlots)}
            >
              {hideSlots ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </div>
          </Tooltip>
          {renderImageButton()}
        </div>
        {knight.id !== undefined && (
          <img
            src={`${ENV.URL.BASE_IMAGE_URL}/images/heroes/${knight.id}.png?t=${refreshingCount}`}
            className={`knight-preview__wrapper-hero ${knight.race} ${isDead && "filter-gray"} ${
              chibiImage ? "hidden" : ""
            }`}
            alt=""
          />
        )}
        {renderChibi()}

        {!hideSlots && renderAllSlotItems()}
        <img
          className="knight-preview__wrapper-base"
          src={require(`src/assets/images/hero-details/ground.png`)}
          alt="base"
          width="10%"
        />
        <img
          className="knight-preview__wrapper-bg"
          src={require(`src/assets/images/hero-details/hero-bg.png`)}
          alt=""
        />
      </div>
      {isOwner && renderInventory()}
    </div>
  );
}
