import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import React, { useState } from "react";
import claimImg from "src/assets/images/lands/claim-img.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function LandClaim() {
  const [expanded, setExpanded] = useState<string | false>("phase1");

  const handleChange = (panel: string) => (_e: any, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="container">
      <div className="land__claim">
        <div className="land__claim-content">
          <h1>Claim Your Own Land</h1>
          <Accordion
            expanded={expanded === "phase1"}
            onChange={handleChange("phase1")}
            className="land__claim-accordion"
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Phase 1: Airdrop</AccordionSummary>
            <AccordionDetails>
              <div>
                <p>
                  Airdrop users will have the opportunity to claim free land. The airdrop will end when all 207 lands
                  have been claimed.
                </p>
                <p>Airdrop Time: From 3AM UTC June 21st to 3AM UTC June 26th</p>
                <p>
                  You can check Airdrop List{" "}
                  <a href="https://docs.google.com/spreadsheets/d/1WCEMZ75IeLxt2L7M2yiUkJsFC3FBOySV6eHPHnfHuyI/edit#gid=0" className="land__claim-link" target="_blank" rel="noopener noreferrer">
                    here
                  </a>
                  !
                </p>
                <p className="land__claim-requirements">Criteria for Airdrop:</p>
                <ul>
                  <li>Holding 3 heroes: 1 point (max 20 points) </li>
                  <li>Holding or Staking 5000 FARA: 1 point (max 20 points) </li>
                  <li>Holding or Staking 50 FARA-BNB: 2 points (max 20 points) </li>
                  <li>Each Legendary body part hero holding: 1 point (max 20 points)</li>
                  <li>Each Mythical body part hero holding: 2 points (max 20 points)</li>
                  <li>Guild master/ Guild owner: 5 points </li>
                  <li>Each entry from the Lady Of The Lake: 1 point (max 5 points)</li>
                  <li>Each entry from Crafting: 1 point (max 10 points)</li>
                  <li> Each entry from equipment purchases: 1 point (max 5 points)</li>
                </ul>
                <p className="land__claim-requirements">Rules:</p>
                <ul>
                  <li>Airdrop 169 lands to 169 users with points {">="} 15.</li>
                  <li>
                    Users with points {">"} 30 receive an additional 2 lands (maximum total of 3 lands). A maximum of 24
                    lands will be airdropped for this group (for 12 wallets).
                  </li>
                  <li>
                    Users with points {">"} 40 receive an additional 2 lands (maximum total of 5 lands). A maximum of 14
                    lands will be airdropped for this group (for 7 wallets).
                  </li>
                  <li>Total airdropped lands: 207 lands</li>
                </ul>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "phase2"}
            onChange={handleChange("phase2")}
            className="land__claim-accordion"
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Phase 2: Private Mint</AccordionSummary>
            <AccordionDetails>
              <div>
                <p>Whitelist Mint Duration: 24h from 3AM UTC June 27th to 3AM UTC June 28th</p>
                <p>
                  You can check Whitelist{" "}
                  <a
                    href="https://docs.google.com/spreadsheets/d/1kWi1oKKMBhK0SIXYkh3VwPaRGTUtz0c0q5r0XHUVLao/edit#gid=0"
                    className="land__claim-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  !
                </p>
                <p>
                  Congratulations on accomplishing our mission with excellence. Please take a look at our whitelist to
                  see whether you are on it.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "phase3"}
            onChange={handleChange("phase3")}
            className="land__claim-accordion"
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Phase 3: Public Mint</AccordionSummary>
            <AccordionDetails>
              <div>
                <p>Public Mint Duration: From 4AM UTC June 28th to 3AM UTC July 03rd.</p>
                <p>Own Land to enjoy all the benefits of Land.</p>
              </div>
            </AccordionDetails>
          </Accordion>
          {/* <div
            className={`land__claim-btn ${!isAirdroppable && phase === LAND_CLAIM_PHASE.AIRDROP && "disabled"}`}
            onClick={() => handleClaim(phase)}
          >
            {phase === LAND_CLAIM_PHASE.AIRDROP ? "Claim" : "Mint"}
          </div> */}
        </div>
        <img src={claimImg} alt="Claim" />
      </div>
    </div>
  );
}
