import { Land } from "src/app/types/land";

export const landActionTypes = {
  MINT: "LAND.MINT",
  SET_SELECTION: "LAND.SET_SELECTION",
  SELECT: "LAND.SELECT",
  DESELECT: "LAND.DESELECT",
  RESET_SELECTION: "LAND.RESET_SELECTION",
  GET_REWARDS: "LAND.GET_REWARDS",
  BUY_LAND: "LAND.BUY",
  LIST_LAND: "LAND.LIST",
  DELIST_LAND: "LAND.DELIST",
  TAKE_LAND_OFFER: "LAND.TAKE_OFFER",
  CANCEL_LAND_OFFER: "LAND.CANCEL_OFFER",
  OFFER: "LAND.OFFER",
  TRANSFER_LAND: "LAND.TRANSFER",
  CLAIM_SELECTED: "LAND.CLAIM_SELECTED",
  SET_OWNED_LANDS: "LAND.SET_OWNED_LANDS",
  REFRESH_LIST: "LAND.REFRESH_LIST",
  WHITELIST_CLAIM: "LAND.WHITELIST_CLAIM",
  PRIVATE_MINT: "LAND.PRIVATE_MINT",
  PUBLIC_MINT: "LAND.PUBLIC_MINT",
};

export function mintLand(amount: number, onSuccess: any) {
  return {
    type: landActionTypes.MINT,
    payload: { amount },
    callbacks: { onSuccess },
  };
}

export function selectLand(land: Land) {
  return {
    type: landActionTypes.SELECT,
    payload: { land },
  };
}

export function deselectLand(land: Land) {
  return {
    type: landActionTypes.DESELECT,
    payload: { land },
  };
}

export function setOwnedLands(landIds: number[]) {
  return {
    type: landActionTypes.SET_OWNED_LANDS,
    payload: { landIds },
  };
}

export function setLandSelection(value: boolean) {
  return {
    type: landActionTypes.SET_SELECTION,
    payload: { value },
  };
}

export function resetSelectedLand() {
  return {
    type: landActionTypes.RESET_SELECTION,
  };
}

export function getLandRewards(landIds: number[]) {
  return {
    type: landActionTypes.GET_REWARDS,
    payload: { landIds },
  };
}

export function buyLand(landId: number) {
  return {
    type: landActionTypes.BUY_LAND,
    payload: { landId },
    // txValue: value,
  };
}

export function listLand(landId: number, price: string) {
  return {
    type: landActionTypes.LIST_LAND,
    payload: { landId, price },
  };
}

export function delistLand(landId: number) {
  return {
    type: landActionTypes.DELIST_LAND,
    payload: { landId },
  };
}

export function takeLandOffer(landId: number, buyer: string, minPrice: string) {
  return {
    type: landActionTypes.TAKE_LAND_OFFER,
    payload: { landId, buyer, minPrice },
  };
}

export function cancelLandOffer(landId: number) {
  return {
    type: landActionTypes.CANCEL_LAND_OFFER,
    payload: { landId },
  };
}

export function offerLand(landId: number, offerValue: string) {
  return {
    type: landActionTypes.OFFER,
    payload: { landId, offerValue },
  };
}

export function transferLand(landId: number, from: string, to: string) {
  return {
    type: landActionTypes.TRANSFER_LAND,
    payload: { landId, from, to },
  };
}

export function claimSelectedLands(landIds: number[], onSuccess: any) {
  return {
    type: landActionTypes.CLAIM_SELECTED,
    payload: { landIds },
    callbacks: { onSuccess },
  };
}

export function refreshLandList(refresh: boolean, onSuccess?: any) {
  return {
    type: landActionTypes.REFRESH_LIST,
    payload: { refresh },
    callbacks: { onSuccess },
  };
}

export function claimAirdropLands() {
  return {
    type: landActionTypes.WHITELIST_CLAIM,
  }
}

export function claimPrivateMint(amount: number, onSuccess?: any) {
  return {
    type: landActionTypes.PRIVATE_MINT,
    payload: { amount },
    callbacks: { onSuccess },
  }
}

export function claimPublicMint(amount: number, onSuccess?: any) {
  return {
    type: landActionTypes.PUBLIC_MINT,
    payload: { amount },
    callbacks: { onSuccess },
  }
}