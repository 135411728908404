import React from "react";
import MaterialIconPreview from "src/app/components/Materials/Commons/MaterialIconPreview";
import { RARITY_LABEL } from "src/app/configs/constants";
import { MATERIALS } from "src/app/configs/materials/materials";

type MaterialInfoProps = {
  materialId: number;
};

export default function MaterialInfo(props: MaterialInfoProps) {
  const material = MATERIALS[props.materialId];

  return (
    <div>
      <div className="material-icon__header">
        <MaterialIconPreview material={material} isPreviewPopup />
        <div className="material-icon__info">
          <div>
            Name: <span className="fs-2 text-9">{material.displayName}</span>
          </div>
          {material.level ? (
            <div>
              <span>Level: </span>
              <span className={`fs-2 text-9}`}>{material.level}</span>
            </div>
          ) : (
            <div>
              <span>Rarity: </span>
              <span className={`fs-2 fw-4 rarity-${RARITY_LABEL[material.rarity].toLowerCase()}`}>
                {RARITY_LABEL[material.rarity]}
              </span>
            </div>
          )}
        </div>
      </div>
      {material.desc && (
        <div className="mt-2">
          <div className="fw-4 mb-1">Description:</div>
          <div dangerouslySetInnerHTML={{ __html: material.desc }} />
        </div>
      )}
    </div>
  );
}
