import React, { memo, useState, useEffect } from "react";
import { useQueryString } from "use-route-as-state";
import { getItemFilterFromParams } from "src/app/utils/filterHelper";
import { ItemFilterType } from 'src/app/types/equipment';

interface EquipmentSwitcher {
  isLoading: boolean;
}

const EquipmentSwitcher = memo((props: EquipmentSwitcher) => {
  const [queryParams, setQueryParams] = useQueryString();
  const [itemFilters, setItemFilters] = useState<ItemFilterType>(getItemFilterFromParams(queryParams));
  const offering = itemFilters.offering;
  const listings = itemFilters.listings;
  const exchanges = itemFilters.exchanges;
  const owned = itemFilters.owned;
  const craftable = itemFilters.craftable;

  useEffect(() => {
    const newFilters = getItemFilterFromParams(queryParams);
    setItemFilters({ ...newFilters });
  }, [queryParams]);

  function handleToggleChange(
    offering: boolean,
    listings: boolean,
    exchanges: boolean,
    owned: boolean,
    craftable: boolean,
  ) {
    return setQueryParams({
      ...queryParams,
      offering: offering.toString(),
      listings: listings.toString(),
      exchanges: exchanges.toString(),
      owned: owned.toString(),
      craftable: craftable.toString(),
    });
  }

  return (
    <div className={`market__toggles mb-3 ${props.isLoading ? "soft-disabled" : ""}`}>
      <div className="toggle mr-3">
        <div>Offers</div>
        <input
          id="offering"
          type="checkbox"
          checked={offering}
          onChange={() => handleToggleChange(!offering, false, false, false, false)}
        />
        <label htmlFor="offering" />
      </div>
      <div className="toggle mr-3">
        <div>Listings</div>
        <input
          id="listings"
          type="checkbox"
          checked={listings}
          onChange={() => handleToggleChange(false, !listings, false, false, false)}
        />
        <label htmlFor="listings" />
      </div>
      <div className="toggle mr-3">
        <div>Exchanges</div>
        <input
          id="exchanges"
          type="checkbox"
          checked={exchanges}
          onChange={() => handleToggleChange(false, false, !exchanges, false, false)}
        />
        <label htmlFor="exchanges" />
      </div>
      <div className="toggle mr-3">
        <div>Craftable</div>
        <input
          id="craftable"
          type="checkbox"
          checked={craftable}
          onChange={() => handleToggleChange(false, false, false, false, !craftable)}
        />
        <label htmlFor="craftable" />
      </div>
      <div className="toggle mr-3">
        <div>Owned</div>
        <input
          id="owned"
          type="checkbox"
          checked={owned}
          onChange={() => handleToggleChange(false, false, false, !owned, false)}
        />
        <label htmlFor="owned" />
      </div>
    </div>
  );
});

export default EquipmentSwitcher;
