import React, { useEffect, useState } from "react";
import gachaFrame from "src/assets/images/gacha/gacha-frame.png";
import AddIcon from "@material-ui/icons/Add";
import summonBtn from "src/assets/images/demi/btn-summon.png";
import approveBtn from "src/assets/images/demi/btn-approve.png";
import { useDispatch, useSelector } from "react-redux";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import soulStoneIcon from "src/assets/images/tokens/soulstone.png";
import { setGlobalModal } from "src/app/actions/globalAction";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import ENV from "src/app/configs/env";
import { summonDemiKnight } from "src/app/actions/demiAction";
import faraLogo from "src/assets/images/tokens/fara.png";
import { formatNumber } from "src/app/utils/helpers";
import { SUMMON_DEMI_COST } from "src/app/configs/constants";
import { SpinePlayer } from "@esotericsoftware/spine-player";

export default function DemiSummon() {
  const dispatch = useDispatch();
  const { balance } = useSelector((state: any) => state.account);
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.DEMI_KNIGHT);

  const [gatePlayer, setGatePlayer] = useState<any>();
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    const player = new SpinePlayer("player-container", {
      jsonUrl: `${ENV.URL.FARALAND_GITHUB_ASSET}/SoulStoneGate.json`,
      atlasUrl: `${ENV.URL.FARALAND_GITHUB_ASSET}/SoulStoneGate.atlas`,
      animations: ["Gate_Unactive", "Gate_All"],
      showControls: false,
      showLoading: false,
      alpha: true,
      preserveDrawingBuffer: false,
      viewport: {
        x: -600,
        y: -200,
        width: 1200,
        height: 1400,
        padLeft: 0,
        padRight: 0,
        padTop: 0,
        padBottom: 0,
      },
    });
    setGatePlayer(player);
  }, []);

  function openDemiModal() {
    if (needApprove) {
      sendApproveTx();
      return;
    }

    if (+balance.FARA < SUMMON_DEMI_COST.FARA) {
      setErrorText("Insufficient FARA balance");
      return;
    }

    if (+balance.SOUL_STONE < SUMMON_DEMI_COST.SOUL_STONE) {
      setErrorText("Insufficient Soul Stone balance");
      return;
    }

    setErrorText("");

    dispatch(summonDemiKnight(onSummonTxSuccess));
  }

  async function onSummonTxSuccess(txHash: string, [log]) {
    dispatch(setGlobalModal("txTracking"));
    gatePlayer.setAnimation("Gate_All");
    setAnimationPlayed(true);

    if (log) {
      setTimeout(() => {
        const demiId = parseInt(log.topics[1], 16).toString();
        dispatch(setGlobalModal("demiModal", { active: true, data: { demiId } }));
        gatePlayer.setAnimation("Gate_Unactive");
        setAnimationPlayed(false);
      }, 9000);
    }
  }

  return (
    <div className="demi-summon">
      <img className="demi-summon__frame" src={gachaFrame} alt="" />
      <div className={`demi-summon__select demi-summon__container container slide-up`}>
        <div id="player-container" />
        <div className="flex-center-center" />
        <div className="demi-summon__text">
          Cross-dimensional heroes trapped in Faraland. They take the form and features of NFT Heroes to fit in, but has
          a limited lifespan. To extend their lives, Demis have to consume "Soul Stones," which help them adapt
          environmental differences in this dimension. Learn more about Demi Heroes{" "}
          <a
            href="https://guide-faraland.gitbook.io/guide/core-insights/demi-heroes"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </div>
        <div className={`demi-summon__btn-container ${animationPlayed ? "disabled" : ""}`}>
          <div className="flex-center-center mb-3">
            <img
              className={`demi-summon__btn`}
              src={needApprove ? approveBtn : summonBtn}
              alt=""
              onClick={openDemiModal}
            />
          </div>
          <div className="flex-center-center fs-3 mb-3 error-text">{errorText}</div>
          <div className="demi-summon__exchange">
            1 Demi Hero <SwapHorizIcon className="ml-2 mr-3" />{" "}
            <span className="text-orange">{SUMMON_DEMI_COST.SOUL_STONE}</span>
            <img className="demi-summon__icons" src={soulStoneIcon} alt="" />
            <AddIcon className="ml-1 mr-1" />
            <span className="text-orange">{SUMMON_DEMI_COST.FARA}</span>
            <img className="demi-summon__icons--fara ml-1" src={faraLogo} alt="" />
          </div>
          <div className="demi-summon__balance">
            <div className="demi-summon__balance-block">
              <span className="mr-3">Your Balance:</span>
              <span>{balance.SOUL_STONE}</span>
              <img className="demi-summon__icons mr-2" src={soulStoneIcon} alt="" />
              <span>{formatNumber(balance.FARA)}</span>
              <img className="demi-summon__icons--fara ml-1" src={faraLogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
