import {
  withStyles,
  Theme,
  createStyles,
  TableCell,
  TableRow,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withdrawEquipment } from "src/app/actions/equipmentAction";
import { setGlobalModal } from "src/app/actions/globalAction";
import { withdrawMaterials } from "src/app/actions/materialAction";
import faraLogo from "src/assets/images/tokens/fara.png";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import MaterialIconPreview from "src/app/components/Materials/Commons/MaterialIconPreview";
import { INTERVAL, LIMIT, NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";
import { fetchWithdrawHistory } from "src/app/services/api/faralandService";
import Web3Service from "src/app/services/web3/Web3Service";
import { WithdrawHistory } from "src/app/types/equipment";
import { formatNumber, roundNumber, toBigAmount } from "src/app/utils/helpers";
import { renderWalletAddress } from "src/app/utils/renderHelpers";
import { withdrawTokens } from "src/app/actions/tokenAction";

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#111121",
      color: theme.palette.common.white,
      fontFamily: `"American Captain", sans-serif`,
      textTransform: "uppercase",
      fontWeight: 500,
      fontSize: 18,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#292936",
      "&:nth-of-type(odd)": {
        backgroundColor: "#454554",
      },
    },
  })
)(TableRow);

export default function WithdrawHistoryModal() {
  const dispatch = useDispatch();
  const [withdrawHistory, setWithdrawHistory] = useState<WithdrawHistory[]>([]);
  const withdrawHistoryInterval = useRef<any>();
  const { address } = useSelector((state: any) => state.account);
  const { web3Service, tokenPrice } = useSelector((state: any) => state.global);
  const isEmpty = _.isEmpty(withdrawHistory);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    if (!address || !web3Service) {
      setWithdrawHistory([]);
      return;
    }
    _fetchWithdrawHistory(page, address, web3Service);
    withdrawHistoryInterval.current = setInterval(() => {
      _fetchWithdrawHistory(page, address, web3Service);
    }, INTERVAL.REFETCH_CRAFT_HISTORY);

    return () => {
      clearInterval(withdrawHistoryInterval.current);
    };
  }, [page, address, web3Service]); // eslint-disable-line

  useEffect(() => {
    if (total && total < LIMIT.WITHDRAW_HISTORY) {
      handleChangePage(1);
    }

    const newTotalPage = Math.ceil(total / LIMIT.WITHDRAW_HISTORY);
    setTotalPage(newTotalPage);

    if (newTotalPage && page > newTotalPage) {
      handleChangePage(1);
    }
  }, [total]); // eslint-disable-line

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  async function _fetchWithdrawHistory(page: number, address, web3Service: Web3Service) {
    const res = await fetchWithdrawHistory(page, address, web3Service);
    if (res) {
      setWithdrawHistory(res.withdrawHistory);
      setTotal(res.total);
    }
  }

  async function handleReWithdraw(row: WithdrawHistory) {
    const history = row.withdrawHistoryNonce.map((r) => ({
      ids: r.ids,
      amounts: row.type === NON_FEE_INVENTORY_TYPE.TOKEN ? toBigAmount(r.tokenAmount) : r.amounts,
      nonce: row.nonce,
      signature: r.signature,
      tokenAmount: r.tokenAmount,
    }));
    dispatch(
      row.type === NON_FEE_INVENTORY_TYPE.MATERIAL
        ? withdrawMaterials(history[0], () => {
            dispatch(setGlobalModal("txTracking"));
            _fetchWithdrawHistory(page, address, web3Service);
          })
        : row.type === NON_FEE_INVENTORY_TYPE.ITEM
        ? withdrawEquipment(history[0], () => {
            dispatch(setGlobalModal("txTracking"));
            _fetchWithdrawHistory(page, address, web3Service);
          })
        : withdrawTokens(
            _.omit(Object.assign(history[0], { amount: history[0].amounts }), ["ids", "amounts"]),
            () => {
              dispatch(setGlobalModal("txTracking"));
              _fetchWithdrawHistory(page, address, web3Service);
            }
          )
    );
  }

  return (
    <BasicModalContent
      customTitle="Withdraw History"
      content={
        <div className={`equipment__table cm-table ${isEmpty ? "cm-table--empty" : ""}`}>
          <Grid container>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Items</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty ? (
                    withdrawHistory.map((row, eid) => {
                      if (row.withdrawHistoryNonce.length === 0) return <StyledTableRow key={eid} />;
                      return (
                        <StyledTableRow key={eid}>
                          <StyledTableCell>
                            {row.owner && renderWalletAddress(row.owner, row.spaceDomain, 4)}
                          </StyledTableCell>
                          <StyledTableCell>{row.type.toUpperCase()}</StyledTableCell>
                          <StyledTableCell>
                            <div className="flex-center-start item-crafting__history-row nice-scroll nice-scroll--horizontal">
                              {row.withdrawHistoryNonce.map((nonce, id) => {
                                return (
                                  <div key={id} className="flex-center-start">
                                    {row.type === NON_FEE_INVENTORY_TYPE.TOKEN ? (
                                      <div className="flex-center-start">
                                        <div className="mr-1 mt-1 fs-4 align-right">
                                          <div>{formatNumber(nonce.tokenAmount, 4)}</div>
                                          <div className="fs-1 text-gray">
                                            ${roundNumber(nonce.tokenAmount * tokenPrice.FARA, 2)}
                                          </div>
                                        </div>
                                        <img className="token token--small top-2" src={faraLogo} alt="" />
                                      </div>
                                    ) : (
                                      <>
                                        {nonce.data.map((d, index) => (
                                          <div key={index}>
                                            {row.type === NON_FEE_INVENTORY_TYPE.ITEM ? (
                                              <ItemIconPreview
                                                className="item-crafting__history-image"
                                                item={d.item}
                                                showDetails
                                                craftedAmount={d.quantity}
                                              />
                                            ) : (
                                              <MaterialIconPreview
                                                className="item-crafting__history-image"
                                                material={d.item}
                                                showDetails
                                                isModal
                                                withdrawnAmount={d.quantity}
                                              />
                                            )}
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>{row.createdAt}</StyledTableCell>
                          <StyledTableCell>
                            {row.reclaimAble && (
                              <div className="btn" onClick={() => handleReWithdraw(row)}>
                                Re-withdraw
                              </div>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell className="cm-table__no-record">
                        <img src={require("src/assets/images/icons/empty-bids.svg")} width={60} height={60} alt="" />
                        <div>No History Yet</div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {totalPage > 1 && (
              <div className="market__pagination mt-5">
                <Pagination
                  count={totalPage}
                  page={page}
                  onChange={(_, val) => handleChangePage(val)}
                  variant="outlined"
                  color="primary"
                />
              </div>
            )}
          </Grid>
        </div>
      }
    />
  );
}
