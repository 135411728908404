import React from "react";
import { Route } from "react-router-dom";
import Header from "src/app/components/Layout/Header";
import Footer from "src/app/components/Layout/Footer";
import Banner from "src/app/components/Layout/Banner/Banner";
import { BannerData } from "src/app/types/common";

interface LayoutProps {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
  bannerData?: BannerData;
  showBanner?: boolean;
  noHeader?: boolean;
}

export default function Layout({ component: Component, ...props }: LayoutProps) {
  return (
    <Route
      {...props}
      render={(matchProps) => (
        <div className="route">
          {!props.noHeader && <Header path={props.path} />}
          {props.bannerData && <Banner path={props.path} bannerData={props.bannerData} />}
          <Component {...matchProps} />
          <Footer path={props.path} />
        </div>
      )}
    />
  );
}
