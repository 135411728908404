import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS, TOPIC } from "src/app/configs/constants";
import { _sendTx } from "src/app/sagas/index";
import { knightActionTypes } from "src/app/actions/knightAction";

function* transfer(action: any): any {
  yield call(_sendTx, ACTIONS.TRANSFER, action.payload);
}

function* changeName(action: any): any {
  yield call(_sendTx, ACTIONS.CHANGE_NAME, action.payload);
}

function* sacrifice(action: any): any {
  yield call(_sendTx, ACTIONS.SACRIFICE, action.payload);
}

function* equipItems(action: any): any {
  yield call(_sendTx, ACTIONS.HERO_EQUIP_ITEMS, action.payload, action.callbacks.onSuccess);
}

function* unequipItems(action: any): any {
  yield call(_sendTx, ACTIONS.HERO_UNEQUIP_ITEMS, action.payload, action.callbacks.onSuccess);
}

function* increaseStats(action: any): any {
  yield call(_sendTx, ACTIONS.INCREASE_STATS, action.payload);
}

function* convertKnightExp(action: any): any {
  yield call(_sendTx, ACTIONS.CONVERT_KNIGHT_EXP, action.payload, action.callbacks.onSuccess);
}

function* learnSkillBook(action: any): any {
  yield call(_sendTx, ACTIONS.LEARN_SKILL_BOOK, action.payload, action.callbacks.onSuccess);
}

function* resetStats(action: any): any {
  yield call(_sendTx, ACTIONS.RESET_STATS, action.payload);
}

function* upgradeBodyPart(action: any): any {
  yield call(_sendTx, ACTIONS.UPGRADE_BODY_PART, action.payload, action.callbacks.onSuccess);
}

function* burnKnight(action: any): any {
  yield call(_sendTx, ACTIONS.BURN, action.payload, action.callbacks.onSuccess, "0", [TOPIC.BURN]);
}

export default function* knightWatcher() {
  yield takeLatest(knightActionTypes.TRANSFER, transfer);
  yield takeLatest(knightActionTypes.SACRIFICE, sacrifice);
  yield takeLatest(knightActionTypes.EQUIP_ITEMS, equipItems);
  yield takeLatest(knightActionTypes.UNEQUIP_ITEMS, unequipItems);
  yield takeLatest(knightActionTypes.CHANGE_NAME, changeName);
  yield takeLatest(knightActionTypes.INCREASE_STATS, increaseStats);
  yield takeLatest(knightActionTypes.CONVERT_EXP, convertKnightExp);
  yield takeLatest(knightActionTypes.LEARN_SKILL_BOOK, learnSkillBook);
  yield takeLatest(knightActionTypes.RESET_STATS, resetStats);
  yield takeLatest(knightActionTypes.UPGRADE_BODY_PART, upgradeBodyPart);
  yield takeLatest(knightActionTypes.BURN, burnKnight);
}
