import Web3 from "web3";
import { WALLET_TYPE } from "src/app/configs/constants";
import BaseWalletService from "src/app/services/accounts/BaseWalletService";
import ENV from "src/app/configs/env";
import { EthereumProvider } from "@walletconnect/ethereum-provider";

export default class WalletConnectService extends BaseWalletService {
  walletConnector: any;

  constructor(props?: any) {
    super(props);
    this.needTobeInitiated = true;
  }

  initiateWallet = async (): Promise<boolean> => {
    try {
      this.walletConnector = await EthereumProvider.init({
        projectId: "78a4feae843dc5119a7270e0c154d677",
        chains: [ENV.NETWORK_ID],
        optionalChains: [1],
        showQrModal: true,
        optionalMethods: ["eth_signTypedData", "eth_signTypedData_v4", "eth_sign"],
      });

      await this.walletConnector.enable();
      this.ethereum = this.walletConnector;
      this.web3 = new Web3(this.walletConnector);
      return true;
    } catch (e) {
      return false;
    }
  };

  getWalletType = () => {
    return WALLET_TYPE.WALLET_CONNECT;
  };
}
