import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS } from "src/app/configs/constants";
import { _sendTx } from "src/app/sagas/index";
import { landActionTypes } from "../actions/landAction";

function* mint(action: any): any {
  yield call(_sendTx, ACTIONS.MINT_LAND, action.payload, action.callbacks.onSuccess);
}

function* getRewards(action: any): any {
  yield call(_sendTx, ACTIONS.GET_LAND_REWARDS, action.payload);
}

function* buy(action: any): any {
  yield call(_sendTx, ACTIONS.BUY_LAND, action.payload);
}

function* list(action: any): any {
  yield call(_sendTx, ACTIONS.LIST_LAND, action.payload);
}

function* delist(action: any): any {
  yield call(_sendTx, ACTIONS.DELIST_LAND, action.payload);
}

function* takeOffer(action: any): any {
  yield call(_sendTx, ACTIONS.TAKE_LAND_OFFER, action.payload);
}

function* cancelOffer(action: any): any {
  yield call(_sendTx, ACTIONS.CANCEL_LAND_OFFER, action.payload);
}

function* offer(action: any): any {
  yield call(_sendTx, ACTIONS.OFFER_LAND, action.payload);
}

function* transfer(action: any): any {
  yield call(_sendTx, ACTIONS.TRANSFER_LAND, action.payload);
}

function* claimSelected(action: any): any {
  yield call(_sendTx, ACTIONS.CLAIM_SELECTED_LANDS, action.payload, action.callbacks.onSuccess);
}

function* claimWhitelistLands(action: any): any {
  yield call(_sendTx, ACTIONS.WHITELIST_LAND_CLAIM, action.payload);
}

function* claimPrivateMintLands(action: any): any {
  yield call(_sendTx, ACTIONS.PRIVATE_LAND_MINT, action.payload, action.callbacks.onSuccess);
}
function* claimPublicMintLands(action: any): any {
  yield call(_sendTx, ACTIONS.PUBLIC_LAND_MINT, action.payload, action.callbacks.onSuccess);
}

export default function* landWatcher() {
  yield takeLatest(landActionTypes.MINT, mint);
  yield takeLatest(landActionTypes.GET_REWARDS, getRewards);
  yield takeLatest(landActionTypes.BUY_LAND, buy);
  yield takeLatest(landActionTypes.LIST_LAND, list);
  yield takeLatest(landActionTypes.DELIST_LAND, delist);
  yield takeLatest(landActionTypes.TAKE_LAND_OFFER, takeOffer);
  yield takeLatest(landActionTypes.CANCEL_LAND_OFFER, cancelOffer);
  yield takeLatest(landActionTypes.OFFER, offer);
  yield takeLatest(landActionTypes.TRANSFER_LAND, transfer);
  yield takeLatest(landActionTypes.CLAIM_SELECTED, claimSelected);
  yield takeLatest(landActionTypes.WHITELIST_CLAIM, claimWhitelistLands);
  yield takeLatest(landActionTypes.PRIVATE_MINT, claimPrivateMintLands);
  yield takeLatest(landActionTypes.PUBLIC_MINT, claimPublicMintLands);
}
