import icetea_lab from "src/assets/images/icons/backers/icetea_lab.png";
import polka from "src/assets/images/icons/backers/polkafoundry.png";
import moonrock from "src/assets/images/icons/backers/moonrock.svg";
import morningstar from "src/assets/images/icons/backers/morningstar.png";
import spark from "src/assets/images/icons/backers/spark.svg";
import av from "src/assets/images/icons/backers/av.png";
import magnus from "src/assets/images/icons/backers/magnus_capital.png";
import au21 from "src/assets/images/icons/backers/au21.svg";
import pnyx from "src/assets/images/icons/backers/pnyx.svg";
import bsc from "src/assets/images/icons/backers/BSCdaily.svg";
import x21 from "src/assets/images/icons/backers/x21.svg";
import ubCapital from "src/assets/images/icons/backers/ub_capital.svg";
import venture590 from "src/assets/images/icons/backers/590_venture.png";
import chainCapital from "src/assets/images/icons/backers/chain-capital.svg";
import vendetta from "src/assets/images/icons/backers/vendetta.svg";
import dex from "src/assets/images/icons/backers/dex-force.png";
import petrock from "src/assets/images/icons/backers/petrock.png";
import c98 from "src/assets/images/icons/backers/c98.png";
import exntc from "src/assets/images/icons/backers/exntc.png";
import lotus from "src/assets/images/icons/backers/lotus.svg";
import basics from "src/assets/images/icons/backers/basics.png";
import hgVentures from "src/assets/images/icons/backers/HGventures.png";
import tgh from "src/assets/images/icons/backers/tgh.jpeg";
import fomocraft from "src/assets/images/icons/backers/fomocraft.png";
import eee from "src/assets/images/icons/backers/eee.png";
import nebula from "src/assets/images/icons/backers/Nebula_dark.png";
import inclusion from "src/assets/images/icons/backers/inclusion.png";
import cryptox from "src/assets/images/icons/backers/cryptox.png";
import gains from "src/assets/images/icons/backers/gains.png";
import crypto_thugs from "src/assets/images/icons/backers/crypto_thugs.png";

const backers = [
  {
    name: "Icetea Labs",
    img: icetea_lab,
  },
  {
    name: "PolkaFoundry",
    img: polka,
  },
  {
    name: "590 Venture",
    img: venture590,
  },
  {
    name: "moonrock",
    img: moonrock,
  },
  {
    name: "Morning Star",
    img: morningstar,
  },
  {
    name: "spark",
    img: spark,
  },
  {
    name: "av start",
    img: av,
  },
  {
    name: "magnus",
    img: magnus,
  },
  {
    name: "au21",
    img: au21,
  },
  {
    name: "pnyx",
    img: pnyx,
  },
  {
    name: "bscdaily",
    img: bsc,
  },
  {
    name: "x21",
    img: x21,
  },
  {
    name: "ub capital",
    img: ubCapital,
  },
  {
    name: "chain",
    img: chainCapital,
  },
  {
    name: "vendetta",
    img: vendetta,
  },
  {
    name: "dex",
    img: dex,
  },
  {
    name: "petrock",
    img: petrock,
  },
  {
    name: "c98",
    img: c98,
  },
  {
    name: "exntc",
    img: exntc,
  },
  {
    name: "lotus",
    img: lotus,
  },
  {
    name: "basics",
    img: basics,
  },
  {
    name: "HG Ventures",
    img: hgVentures,
  },
  {
    name: "tgh",
    img: tgh,
  },
  {
    name: "Fomocraft",
    img: fomocraft,
  },
  {
    name: "eee",
    img: eee,
  },
  {
    name: "Nebula",
    img: nebula,
  },
  {
    name: "Inclusion",
    img: inclusion,
  },
  {
    name: "CryptoX",
    img: cryptox,
  },
  {
    name: "Gains",
    img: gains,
  },
  {
    name: "Crypto Thugs",
    img: crypto_thugs,
  }
]

export default backers;