import React, { useEffect, useRef, useState } from "react";
import ENV from "src/app/configs/env";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import ImportModal from "src/app/components/Commons/Account/ImportModal";
import logoImage from "src/assets/images/logos/faraland.svg";
import bnbLogo from "src/assets/images/tokens/bnb.svg";
import faraLogo from "src/assets/images/tokens/fara-logo.png";
import { NavLink, Link } from "react-router-dom";
import { ROUTE } from "src/app/configs/constants";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "src/app/utils/helpers";
import { ClickAwayListener, Paper, Popper, Tooltip } from "@material-ui/core";
import { clearAccount } from "src/app/actions/accountAction";
import { changeTab } from "src/app/actions/marketAction";
// import humanChibi from "src/assets/images/icons/races/human-male.png";
// import orcChibi from "src/assets/images/icons/races/orc-male.png";
// import fairyChibi from "src/assets/images/icons/races/fairy-female.png";
// import dragonChibi from "src/assets/images/icons/races/dragon-male.png";
// import angelChibi from "src/assets/images/icons/races/angel-female.png";
// import { compareNumbers, multiplyNumbers, plusNumbers } from "src/app/utils/calculators";
import useWindowDimensions from "src/app/hooks/useWindowDimensions";
import { renderWalletAddress } from "src/app/utils/renderHelpers";
import { disconnectWalletSession } from "src/app/services/api/faralandService";

// const tiers = [
//   {
//     name: "Angel",
//     fsp: 24000,
//     image: angelChibi,
//   },
//   {
//     name: "Dragon",
//     fsp: 8000,
//     image: dragonChibi,
//   },
//   {
//     name: "Fairy",
//     fsp: 2000,
//     image: fairyChibi,
//   },
//   {
//     name: "Orc",
//     fsp: 200,
//     image: orcChibi,
//   },
//   {
//     name: "Human",
//     fsp: 0,
//     image: humanChibi,
//   },
// ];

const menuOptions = {
  Marketplaces: [
    {
      url: ROUTE.HEROES,
      name: "Heroes",
    },
    {
      url: ROUTE.DEMI,
      name: "Demi Heroes",
    },
    {
      url: ROUTE.LANDS,
      name: "Lands",
    },
    {
      url: ROUTE.ITEMS,
      name: "Equipment",
    },
    {
      url: ROUTE.MATERIALS,
      name: "Materials",
    },
    {
      url: ROUTE.BLACK_MARKET,
      name: "Black Market",
    },
  ],
  Features: [
    {
      url: `${ROUTE.STAKING}?owned=true&sortBy=Lowest%20ID&page=1`,
      name: "Staking",
    },
    {
      url: ROUTE.EQUIPMENT_CRAFTING.replace(":recipeId", "1"),
      name: "Crafting",
    },
    {
      url: ROUTE.GUILD,
      name: "Guild",
    },
    {
      url: ROUTE.DEMI_SUMMON,
      name: "Summon Demi",
    },
    // /*{
    //   url: `${ROUTE.HERO_BURN}?owned=true&sortBy=Lowest%20ID&page=1`,
    //   name: "Burn Hero",
    // }, */
    // {
    //   url: ROUTE.EQUIPMENT_GACHA,
    //   name: "Gacha",
    // },
    {
      url: ROUTE.EVENT,
      name: "EVENT",
    },
    {
      url: ROUTE.LAND_MINT,
      name: "Mint Land",
    },
  ],
  Info: [
    {
      url: "https://guide-faraland.gitbook.io/guide/welcome-to-faraland/about-faraland",
      name: "Wiki",
    },
    {
      url: "https://blog.faraland.io/",
      name: "Blog",
    },
  ],
};

export default function Header({ path }) {
  const dispatch = useDispatch();
  const isHomePage = path === ROUTE.HOME;

  const { address, balance, wallet, spaceDomain } = useSelector((state: any) => state.account);
  const [accountPopup, setAccountPopup] = useState(false);
  const [menuSidebar, setMenuSidebar] = useState(false);
  // const [fsp, setFSP] = useState("0");
  const accountPopupRef = useRef<any>(null);
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    if (menuSidebar) {
      document.querySelector("body")!.classList.add("menu-active");
    }

    return () => {
      document.querySelector("body")!.classList.remove("menu-active");
    };
  }, [menuSidebar]);

  // useEffect(() => {
  //   if (!web3Service || !address) return;

  //   fetchStakedAmount();
  // }, [web3Service, address]); // eslint-disable-line

  // async function fetchStakedAmount() {
  //   try {
  //     const { single, liquidity } = await web3Service.fetchSingleAndLPStakedAmount(address);
  //     setFSP(plusNumbers(single, multiplyNumbers(liquidity, STAKING_CONFIG.LP_PRICE_MULTIPLIER)));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  function openImportModal() {
    modalService.show(ImportModal);
  }

  function closeAccountPopup(e: any) {
    if (accountPopupRef.current && accountPopupRef.current.contains(e.target)) return;
    setAccountPopup(false);
  }

  function toggleAccountPopup() {
    setAccountPopup(!accountPopup);
  }

  async function disconnectAccount() {
    setAccountPopup(false);
    if (wallet.disconnect) wallet.disconnect();
    await disconnectWalletSession();
    dispatch(clearAccount());
    localStorage.clear();
  }

  function toggleMenu() {
    setMenuSidebar(!menuSidebar);
  }

  function closeMenu() {
    setMenuSidebar(false);
    dispatch(changeTab(0));
  }

  function NavLinkWithDropDown({ name }) {
    const active =
      menuOptions[name].find((menu: any) => menu.url === path) ||
      (name === "Features" && [ROUTE.STAKING, ROUTE.EQUIPMENT_CRAFTING, ROUTE.HERO_BURN].includes(path));

    return (
      <>
        {windowDimensions.width > 1200 ? (
          <div className={`header__link ${active && "active"}`} style={{ cursor: "default" }}>
            <div className="header__link-title">{name}</div>
            <div className="header__link-dropdown">
              <div className="header__link-dropdown--content slide-up">
                {menuOptions[name].map((menu: any, idx: number) => (
                  <div key={idx}>
                    {name === "Info" ? (
                      <a
                        className="header__link-dropdown--option"
                        href={menu.url}
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={closeMenu}
                      >
                        {menu.name}
                      </a>
                    ) : (
                      <NavLink
                        className="header__link-dropdown--option"
                        style={{ padding: 10 }}
                        to={menu.url}
                        onClick={closeMenu}
                      >
                        {menu.name}
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          menuOptions[name].map((menu: any, idx: number) => (
            <div key={idx} className="header__link">
              {name === "Info" ? (
                <a href={menu.url} target="_blank" rel="noreferrer noopener" onClick={closeMenu}>
                  {menu.name}
                </a>
              ) : (
                <NavLink to={menu.url} onClick={closeMenu}>
                  {menu.name}
                </NavLink>
              )}
            </div>
          ))
        )}
      </>
    );
  }

  return (
    <div className="header">
      <div className="header__container container">
        <div className={`header__menu ${menuSidebar ? "active" : ""}`}>
          <div className="header__menu-bars" onClick={toggleMenu}>
            <div className="header__menu-bar" />
            <div className="header__menu-bar" />
            <div className="header__menu-bar" />
          </div>
          <div className="header__screen" onClick={closeMenu} />
          <div className={`header__links ${isHomePage && "header__links--home"}`}>
            <Link to={ROUTE.HOME} onClick={closeMenu}>
              <img className="header__logo" src={logoImage} alt="" />
            </Link>
            <NavLinkWithDropDown name="Marketplaces" />
            <NavLinkWithDropDown name="Features" />
            <NavLink className="header__link" to={ROUTE.EQUIPMENT_GACHA} onClick={closeMenu}>
              Gacha
            </NavLink>
            <NavLinkWithDropDown name="Info" />
            {address !== "" && (
              <NavLink
                className="header__link"
                to={`${ROUTE.WALLET}?owned=true&sortBy=Highest%20Level&page=1`}
                onClick={closeMenu}
              >
                Wallet
              </NavLink>
            )}
          </div>
        </div>

        <div className="header__button">
          {address ? (
            <div className="relative">
              <div className="header__data" ref={accountPopupRef} onClick={toggleAccountPopup}>
                {/* <Tooltip
                  title={`${
                    fsp === "0"
                      ? "Stake your FARA or FARA-BNB LP tokens in any heroes to be eligible for upcoming IDOs in FaraStarter.com"
                      : `Tier ${currentTier.name} with ${roundNumber(fsp, 0, true)} Farastarter Point`
                  }`}
                  arrow
                  placement="top"
                >
                  <div className="mr-2">
                    <a href="https://farastarter.com/" target="_blank" rel="noopener noreferrer">
                      <img alt="" src={currentTier.image} style={{ width: 24 }} />
                    </a>
                  </div>
                </Tooltip> */}
                <div className="header__token">
                  <div className="fw-3">{formatNumber(balance.BNB, 4)}</div>
                  <img className="token" src={bnbLogo} alt="" />
                </div>
                <div className="header__token">
                  <div className="fw-3">{formatNumber(balance.FARA, 4)}</div>
                  <img className="token token--fara" src={faraLogo} alt="" />
                </div>
                <div className="fw-4">{renderWalletAddress(address, spaceDomain, 4)}</div>
              </div>

              <Popper
                open={accountPopup}
                anchorEl={accountPopupRef.current}
                transition
                disablePortal
                className="dropdown slide-up"
              >
                <Paper>
                  <ClickAwayListener onClickAway={closeAccountPopup}>
                    <div>
                      <Tooltip title="View address on BscScan" arrow placement="top">
                        <a
                          className="dropdown__item"
                          href={`${ENV.URL.EXPLORER}/address/${address}`}
                          onClick={toggleAccountPopup}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {renderWalletAddress(address, spaceDomain, 6)}
                        </a>
                      </Tooltip>
                      <Tooltip title="Claim your reward earned by playing game" arrow placement="top">
                        <a
                          className="dropdown__item"
                          href="https://auth.faraland.io/account/claim"
                          onClick={toggleAccountPopup}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Reward Portal
                        </a>
                      </Tooltip>
                      <div className="dropdown__item" onClick={disconnectAccount}>
                        Disconnect
                      </div>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Popper>
            </div>
          ) : (
            <div className="btn btn--gradient" onClick={openImportModal}>
              Connect Wallet
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
