import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { claimSelectedLands, refreshLandList, resetSelectedLand, setLandSelection } from "src/app/actions/landAction";
import { formatBigNumber, toMeaningfulNumber } from "src/app/utils/helpers";
import { formatLandRewards } from "src/app/utils/mappingHelpers";
import faraLogo from "src/assets/images/tokens/fara.png";

type LandClaimBtnProps = {
  className?: string;
};
export default function LandClaimBtn(props: LandClaimBtnProps) {
  const dispatch = useDispatch();
  const { web3Service } = useSelector((state: any) => state.global);
  const { selectedLands, selection, ownedLands, refresh } = useSelector((state: any) => state.land);
  const [selectedRewards, setSelectedRewards] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);

  useEffect(() => {
    const selected = selectedLands.filter((land) => land !== null);
    setSelectedRewards(
      selected.reduce((acc, curr) => {
        return toMeaningfulNumber(acc + +curr.reward, 5);
      }, 0)
    );
  }, [selectedLands]);

  useEffect(() => {
    if (!web3Service) return;
    getTotalLandRewards(ownedLands);
  }, [ownedLands, web3Service]); // eslint-disable-line

  async function getTotalLandRewards(ownedLands: number[]) {
    const rewards = await web3Service.fetchLandRewards(ownedLands);
    const total = rewards.reduce((acc, curr) => {
      return toMeaningfulNumber(acc + +formatBigNumber(curr), 5);
    }, 0);
    setTotalRewards(total);
  }
  const handleSetSelection = (value: boolean) => {
    dispatch(setLandSelection(value));
  };

  const claimSelected = (selectedLands) => {
    const landIds = selectedLands.filter((land) => land !== null).map((land) => land.id);
    dispatch(
      claimSelectedLands(landIds, () => {
        dispatch(resetSelectedLand());
        getTotalLandRewards(ownedLands);
        dispatch(refreshLandList(!refresh));
      })
    );
  };

  const claimAll = () => {
    dispatch(
      claimSelectedLands(ownedLands, () => {
        dispatch(resetSelectedLand());
        getTotalLandRewards(ownedLands);
        dispatch(refreshLandList(!refresh));
      })
    );
  };

  return (
    <div className="market__claim-container">
      <div className="market__claim">
        <div>
          Total Rewards: <b>{formatLandRewards(totalRewards)}</b>{" "}
          <img className="market__claim-token" src={faraLogo} alt="" />
        </div>

        <div className={`btn ${totalRewards === 0 && "disabled"}`} onClick={claimAll}>
          Claim All
        </div>
      </div>
      <div className="market__claim">
        <div>
          Selected: <b>{formatLandRewards(selectedRewards)}</b>{" "}
          <img className="market__claim-token" src={faraLogo} alt="" />
        </div>
        <div
          className={`btn ${props.className}`}
          onClick={() =>
            !selection
              ? handleSetSelection(true)
              : selectedLands.some((land) => land !== null)
              ? claimSelected(selectedLands)
              : dispatch(resetSelectedLand())
          }
        >
          {!selection
            ? "Select"
            : selection && selectedLands.some((land) => land !== null)
            ? `Claim ${selectedLands.filter((land) => land !== null).length} selected`
            : "Cancel"}
        </div>
      </div>
    </div>
  );
}
