import React from "react";
import ENV from "src/app/configs/env";
import { Land } from "src/app/types/land";

type LandPreviewProps = {
  land: Land;
  isOwner: boolean;
};
export default function LandPreview(props: LandPreviewProps) {
  const { land } = props;

  return (
    <div className="land__preview">
      <div className="mb-2">
        <div className={`lands__item-id ${land.landType.toLowerCase()}`}>#{land.id}</div>
      </div>
      <div className="land__preview-wrapper">
        <div className="land__preview-wrapper-type">
          <span>{land.landType}</span>
          <img
            alt={land.landType}
            src={require(`src/assets/images/lands/${land.landType.toLowerCase()}.png`)}
            className="knight-preview__wrapper-icon"
          />
        </div>
        <img src={`${ENV.URL.FARALAND}/images/lands/png/${land.id}.png`} alt="" className="land__preview-wrapper-land" />
      </div>
    </div>
  );
}
