import React from "react";
import { KnightStats } from "src/app/types/knight";
import { Item, ItemAttackRange, ItemEffect, RuneStatsLabel } from "src/app/types/equipment";
import { PrimaryStats } from "src/app/types/attribute";
import { BASE_STATS, STATS_CAP } from "src/app/configs/stats-allocation/statsAllocation";
import { STATS_ALLOCATION } from "src/app/configs/stats-allocation/statsAllocation";
import { EQUIPMENT_TYPE } from "src/app/configs/constants";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import _ from "lodash";
import { mapStatsKey } from "src/app/utils/mappingHelpers";
import { getTotalPrimaryStats, knightMetRequiredStats } from "src/app/utils/helpers";

type StatsDetailsProps = {
  slotItems: number[];
  knightStats: KnightStats;
};

export default function KnightStatsDetailsModal(props: StatsDetailsProps) {
  function renderStats(stats: KnightStats) {
    const totalPrimaryStats = getTotalPrimaryStats(stats);
    let moveRange = 4;

    stats.itemRanges &&
      Object.keys(stats.itemRanges).forEach((key: any) => {
        if (stats.itemRanges![key] === 0) return 4;
        if (key !== "Move Range") return 4;
        moveRange = stats.itemRanges![key];
      });

    return (
      <>
        <div className="flex-2-column" style={{ paddingLeft: 8 }}>
          {Object.keys(stats.itemStats).map((key: any, index: number) => {
            if (key === "effects") return null;
            const lowerCaseKey = key.toLowerCase();
            let total = _getTotalStatsConverted(key, totalPrimaryStats, stats.level, props.slotItems, stats.runeStats);

            switch (lowerCaseKey) {
              case "str":
              case "agi":
              case "int":
              case "luk":
                total += totalPrimaryStats[lowerCaseKey];
                break;
              case "evade":
              case "critical":
                if (total + stats.itemStats[key] + stats.itemSetBonus[key] >= STATS_CAP[0].stats) {
                  total = STATS_CAP[0].stats;
                  break;
                } else {
                  total += stats.itemStats[key] + stats.itemSetBonus[key];
                  break;
                }
              case "armor penetration":
                if (total + stats.itemStats[key] + stats.itemSetBonus[key] >= STATS_CAP[2].stats) {
                  total = STATS_CAP[2].stats;
                  break;
                } else {
                  total += stats.itemStats[key] + stats.itemSetBonus[key];
                  break;
                }
              default:
                total += stats.itemStats[key] + stats.itemSetBonus[key];
                break;
            }

            let postfix = "";
            const percentageKeys = ["Critical", "Critical Damage", "Evade", "Armor Penetration", "Accuracy"];
            if (percentageKeys.indexOf(key) !== -1) postfix = "%";

            return (
              <div key={index}>
                <span>- {mapStatsKey(key)}: </span>
                <span className="fs-2 text-9">
                  {total}
                  {postfix}
                </span>
              </div>
            );
          })}
          <div>
            <span>- Move Range: </span>
            <span className="fs-2 text-9">{moveRange}</span>
          </div>
          {props.knightStats.itemRanges &&
            props.knightStats.itemRanges.attackRange.map((range: ItemAttackRange, index) => {
              return (
                <div key={index}>
                  <span>- {range.type === EQUIPMENT_TYPE.MAIN_WEAPON ? "Main" : "Sub"} Atk Range: </span>
                  <span className="fs-2 text-9">
                    {range.min}-{range.max}
                  </span>
                </div>
              );
            })}
          {stats.runeStats &&
            Object.keys(stats.runeStats)
              .filter((stat) =>
                [
                  "Bonus Passive Skill",
                  "Bonus Active Skill",
                  "Bonus Normal Attack",
                  "Bonus Elemental Counter",
                ].includes(stat)
              )
              .map((key, index) => {
                let total = _getTotalStatsConverted(
                  key,
                  totalPrimaryStats,
                  stats.level,
                  props.slotItems,
                  stats.runeStats
                );
                if (!total) return <></>;
                return (
                  <div key={index}>
                    <span>- {key}: </span>
                    <span className="fs-2 text-9">{total}%</span>
                  </div>
                );
              })}
        </div>
      </>
    );
  }

  function _getBaseStats(baseStats, property) {
    const obj = baseStats.find((stats) => stats.name === property);
    if (!obj) return;
    return obj.stats;
  }

  function _calculateAdaptiveDamage(slotItems: number[], level: number, key: string) {
    let total = 0;
    if (_.isEmpty(slotItems) && key === "Physical") {
      return (total += level);
    }
    const totalNonItemStats = getTotalPrimaryStats(props.knightStats, true);
    const equipment: Item[] = slotItems.map((item) => EQUIPMENT[item]);
    const mainWeapon = equipment
      .filter(
        (item) =>
          item?.type === EQUIPMENT_TYPE.MAIN_WEAPON && knightMetRequiredStats(totalNonItemStats, item.requiredStats)
      )
      .map((weapon) => EQUIPMENT[weapon.id])[0];

    const subWeapon = equipment
      .filter(
        (item) =>
          item?.type === EQUIPMENT_TYPE.SUB_WEAPON && knightMetRequiredStats(totalNonItemStats, item.requiredStats)
      )
      .map((weapon) => EQUIPMENT[weapon.id])[0];

    //If both weapons are equipped, compare the damage and choose one with higher stats
    if (mainWeapon && subWeapon) {
      const totalPhysicalDmg = mainWeapon.stats["Physical Damage"] + subWeapon.stats["Physical Damage"];
      const totalMagicalDmg = mainWeapon.stats["Magical Damage"] + subWeapon.stats["Magical Damage"];

      if (totalPhysicalDmg > totalMagicalDmg && key === "Physical") {
        total += level;
      } else if (totalMagicalDmg > totalPhysicalDmg && key === "Magical") {
        total += level;
      } else if (totalPhysicalDmg === totalMagicalDmg && mainWeapon.damageType === key) {
        total += level;
      }
    }
    //If main weapon is equipped, only take the damage type of that main weapon
    else if (mainWeapon && mainWeapon.damageType === key) {
      total += level;
      //If no main weapon is equipped, then take the damage type of the sub weapon
    } else if (!mainWeapon && subWeapon) {
      if (subWeapon.damageType && subWeapon.damageType === key) {
        total += level;
        //If the sub weapon is a shield, make it physical
      } else if (!subWeapon.damageType && key === "Physical") {
        total += level;
      }
    } else if (!(mainWeapon || subWeapon) && key === "Physical") {
      total += level;
    }
    return total;
  }

  function _calculateStatsAllocation(key: string, totalPrimaryStats: PrimaryStats) {
    let total = 0;
    const results = STATS_ALLOCATION.filter((stats) => stats.effects[key]);
    results.forEach((result) => {
      total += Math.floor(totalPrimaryStats[result.stat] / result.number);
    });
    return total;
  }

  function _getTotalStatsConverted(
    key: string,
    totalPrimaryStats: PrimaryStats,
    level: number,
    slotItems: number[],
    runeStats?: RuneStatsLabel
  ): number {
    let total = 0;
    const BASE_HP = _getBaseStats(BASE_STATS, "Base HP") + (level !== 1 && level);
    const BASE_DAMAGE = _getBaseStats(BASE_STATS, "Base Physical Damage");
    const BASE_EVASION = _getBaseStats(BASE_STATS, "Base Evasion Chance");
    const BASE_CRIT = _getBaseStats(BASE_STATS, "Base Critical Chance");
    const BASE_CRIT_DAMAGE = 150;
    console.log(key, runeStats?.HP);
    if (key === "HP") {
      total += BASE_HP + _calculateStatsAllocation(key, totalPrimaryStats) + (runeStats?.HP ?? 0);
    } else if (key === "Physical Damage") {
      total +=
        BASE_DAMAGE +
        _calculateStatsAllocation(key, totalPrimaryStats) +
        _calculateAdaptiveDamage(slotItems, level, key.split(" ")[0]);
    } else if (key === "Speed") {
      total += _calculateStatsAllocation(key, totalPrimaryStats);
    } else if (key === "Physical Defense") {
      total += _calculateStatsAllocation(key, totalPrimaryStats) + (runeStats?.["Physical Defense"] ?? 0);
    } else if (key === "Evade") {
      total += BASE_EVASION + _calculateStatsAllocation("Evasion Chance", totalPrimaryStats);
    } else if (key === "Magical Damage") {
      total +=
        BASE_DAMAGE +
        _calculateStatsAllocation(key, totalPrimaryStats) +
        _calculateAdaptiveDamage(slotItems, level, key.split(" ")[0]);
    } else if (key === "Magical Defense") {
      total += _calculateStatsAllocation(key, totalPrimaryStats) + (runeStats?.["Magical Defense"] ?? 0);
    } else if (key === "Critical") {
      total += BASE_CRIT + _calculateStatsAllocation("Critical Chance", totalPrimaryStats);
    } else if (key === "Critical Damage") {
      total += BASE_CRIT_DAMAGE + _calculateStatsAllocation(key, totalPrimaryStats);
    } else if (key === "Armor Penetration") {
      total += _calculateStatsAllocation(key, totalPrimaryStats);
    } else if (key === "Accuracy") {
      total += _calculateStatsAllocation(key, totalPrimaryStats);
    } else if (key === "Bonus Passive Skill") {
      total += runeStats?.["Bonus Passive Skill"] ?? 0;
    } else if (key === "Bonus Active Skill") {
      total += runeStats?.["Bonus Active Skill"] ?? 0;
    } else if (key === "Bonus Normal Attack") {
      total += runeStats?.["Bonus Normal Attack"] ?? 0;
    } else if (key === "Bonus Elemental Counter") {
      total += runeStats?.["Bonus Elemental Counter"] ?? 0;
    }

    return total;
  }

  function renderItemEffects() {
    const effects = props.knightStats.itemStats.effects;
    const formattedEffects: ItemEffect[] = [];

    for (let i = 0; i < effects.length; i++) {
      const isExist = formattedEffects.find((effect: ItemEffect) => effect.name === effects[i].name);
      if (isExist) continue;
      formattedEffects.push(effects[i]);
    }

    return formattedEffects.map((effect: ItemEffect, index: number) => {
      return (
        <div key={index}>
          -{" "}
          <span className="fs-2 text-9">
            {effect.name}: {effect.description}
          </span>
        </div>
      );
    });
  }

  return (
    <div>
      <div>
        <div>Base Stats:</div>
        <div className="flex-2-column" style={{ paddingLeft: 8 }}>
          {Object.keys(props.knightStats.baseStats).map((key: any, index: number) => {
            const stat = props.knightStats.baseStats[key];
            return (
              <div key={index}>
                <span>- {key.toUpperCase()}: </span>
                <span className="fs-2 text-9">
                  <span>{stat} </span>
                  {props.knightStats.allocatedStats[key] !== 0 && (
                    <span className="text-gray">(+{props.knightStats.allocatedStats[key]})</span>
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-4">
        <div>Total Stats:</div>
        {renderStats(props.knightStats)}
      </div>
      {(props.knightStats.itemStats.effects.length > 0 || props.knightStats.itemSetBonus.effects.length > 0) && (
        <div className="mt-4">
          <div>Effects:</div>
          <div style={{ paddingLeft: 8 }}>
            {renderItemEffects()}
            {props.knightStats.itemSetBonus.effects.map((effect: ItemEffect, index: number) => {
              return (
                <div key={index}>
                  -{" "}
                  <span className="fs-2 text-9">
                    {effect.name}: {effect.description}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
