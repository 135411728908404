import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { isDemiInactive } from "src/app/utils/helpers";

type DemiLifespanTimerProps = {
  lifespan: number;
};

const DemiLifespanTimer = (props: DemiLifespanTimerProps) => {
  const { lifespan } = props;

  const lifespanInterval = useRef<any>();
  const [isExpired, setIsExpired] = useState(false);
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    lifespanInterval.current = setInterval(() => {
      const { then, now, isExpired } = _setIsExpired();
      if (isExpired) {
        clearInterval(lifespanInterval.current);
        return;
      }

      let duration = moment.duration(then.diff(now));

      //Get Days and subtract from duration
      let days = Math.floor(duration.asDays());
      setDays(days);
      duration.subtract(moment.duration(days, "days"));

      //Get hours and subtract from duration
      let hours = duration.hours();
      setHours(hours);
      duration.subtract(moment.duration(hours, "hours"));

      //Get Minutes and subtract from duration
      let minutes = duration.minutes();
      setMinutes(minutes);
      duration.subtract(moment.duration(minutes, "minutes"));

      //Get seconds
      let seconds = duration.seconds();
      setSeconds(seconds);
    }, 1000);

    return () => {
      clearInterval(lifespanInterval.current);
    };
  }, []); // eslint-disable-line

  function _setIsExpired() {
    const { then, now, isExpired } = isDemiInactive(+lifespan);

    setIsExpired(isExpired);

    return { then, now, isExpired }
  }

  return (
    <div className="align-center text-yellow">
      {!isExpired ? (
        <div> Lifespan: {days} days {hours} hours {minutes} minutes {seconds} seconds</div>
      ) : (
        <div className="text-yellow">
          This Demi went into hibernation on {moment.unix(lifespan).format("MMMM Do YYYY, h:mm:ss A")}. By using
          Soul Stone, this Demi can be awakened for another 7 days starting from this date.
        </div>
      )}
    </div>
  );
};

export default DemiLifespanTimer;
