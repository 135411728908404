import React from "react";
import gemIcon from "src/assets/images/decors/frames/frame-gem.png";
import { Item } from "src/app/types/equipment";
import { RARITY_LABEL } from "src/app/configs/constants";
import { getEquipmentImageURL } from "src/app/utils/helpers";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import ItemInfo from "src/app/components/Equipment/Commons/ItemInfo";
import { useHistory } from "react-router-dom";
import blackMarketTreasures from "src/assets/images/icons/black-market/treasure.png";

type ItemIconProps = {
  item: Item;
  largeImage?: boolean;
  isMarketplace?: boolean;
  showAvailable?: boolean;
  showAmount?: boolean;
  onLoad?: any;
  className?: any;
  showDetails?: boolean;
  isLearnedSkills?: boolean;
  isBurn?: boolean;
  craftedAmount?: number;
  disableGems?: boolean;
  showTreasureImg?: boolean;
  isBlackMarketAmount?: boolean;
};

export default function ItemIconPreview({
  item,
  largeImage,
  isMarketplace,
  onLoad,
  showAvailable,
  showAmount,
  className,
  showDetails,
  isLearnedSkills,
  isBurn,
  craftedAmount,
  disableGems,
  showTreasureImg,
  isBlackMarketAmount
}: ItemIconProps) {
  const history = useHistory();
  function handleImageLoad() {
    if (onLoad) {
      onLoad(false);
    }
  }

  function redirectToDetails(itemId: number) {
    history.push(`/equipment/${itemId}`);
    modalService.close();
  }

  function showEquipmentInfoModal(itemId: number) {
    modalService.show(BasicModalContent, {
      content: (
        <ItemInfo itemId={itemId} />
      ),
      submitText: "Details",
      onSubmit: () => redirectToDetails(itemId)
    });
  }

  return (
    <div
      className={`
        item-icon__frame item-icon__frame--${RARITY_LABEL[item.rarity].toLowerCase()}
        ${className}
        ${isMarketplace && "item-icon__frame--marketplace"}
        ${showDetails && "item-icon__frame--details"}
      `}
      onClick={() => showDetails && showEquipmentInfoModal(item.id)}
    >
      <img
        className={`item-icon__preview ${isMarketplace && "item-icon__preview--marketplace"}`}
        src={
          showTreasureImg ? blackMarketTreasures :
          isLearnedSkills
            ? require(`src/assets/images/learned-skills/${item.name.replaceAll(" ", "")}.png`)
            : getEquipmentImageURL(item.id, largeImage ? "500x500" : "178x178")
        }
        alt=""
        onLoad={handleImageLoad}
      />
      
      {showAvailable && <div className="item-icon__available">x{item.available}</div>}
      {showAmount && <div className={`item-icon__available ${isBlackMarketAmount ? "item-icon__available--black-market" : ""}`}>x{item.amount}</div>}
      {craftedAmount && <div className="item-icon__amount">x{craftedAmount}</div>}
      {!disableGems && Array.from(Array(item.tier).keys()).map((tier: number) => {
        return (
          <img
            className={`item-icon__gem item-icon__gem--${tier} ${
              isMarketplace && !isBurn && `item-icon__gem-marketplace--${tier}`
            }`}
            src={gemIcon}
            key={tier}
            alt=""
            onLoad={handleImageLoad}
          />
        );
      })}
    </div>
  );
}
