import React, { useEffect, useMemo, useRef, useState } from "react";
import moment, { unix } from "moment";
import faraTokenLogo from "src/assets/images/tokens/fara.png";
import bnbTokenLogo from "src/assets/images/tokens/bnb.svg";
import InputGroup from "src/app/components/Commons/InputGroup";
import { FormControl, InputLabel, Select, Tooltip } from "@material-ui/core";
import Countdown from "react-countdown";
import { AMPLIFIED_STAKING, INTERVAL, STAKING_POOL, STAKING_CONFIG } from "src/app/configs/constants";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import useStakingFormData from "src/app/components/Staking/hooks/useStakingFormData";
import { formatBigNumber, formatNumber, roundNumber } from "src/app/utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { claimItems } from "src/app/actions/stakingAction";
import { compareNumbers, multiplyNumbers } from "src/app/utils/calculators";
import infoIcon from "src/assets/images/icons/info-icon-white.svg";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import { MATERIALS } from "src/app/configs/materials/materials";
import { STAKING_REWARDS } from "src/app/configs/staking/stakingRewards";
import { divideNumbers } from "../../utils/calculators";
import _ from "lodash";

type StakingFormProps = {
  knightId: number;
  selectedPool: number;
  contractAddress: string;
};

export default function StakingForm(props: StakingFormProps) {
  const { knightId, selectedPool, contractAddress } = props;

  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const lpBalanceInterval = useRef<any>();

  const [lpBalance, setLpBalance] = useState("0");
  const [tickets, setTickets] = useState(0);
  const [localEligibleTime, setLocalEligibleTime] = useState(0);
  const [fspAmount, setFSPAmount] = useState("0");
  const {
    stakingData,
    stakingAmount,
    stakingError,
    unstakingAmount,
    unstakingError,
    lockedMonths,
    needApprove,
    disableUnstakeBtn,
    disableClaimBtn,
    balance,
    isStakingV3,
    isLpStaking,
    stakingRewards,
    handleClaim,
    handleStake,
    handleUnstake,
    handleUnstakingAmountChange,
    handleStakingAmountChange,
    handleLockTimeChange,
    renderCountUpNumber,
    renderTime,
    handleClaimExpJar,
    handleDistributeExp,
    renderTokenRequirementsTooltip,
  } = useStakingFormData(selectedPool, contractAddress, lpBalance);
  const [reward, setReward] = useState<any>();
  const { tokenSymbol, tokenBalance, requiredStakedAmount, requiredLockedMonths, rewardItems } = getStakingData();
  const [eligibleExpJar, setEligibleExpJar] = useState(
    Math.floor(+divideNumbers(3.445, STAKING_CONFIG.EXP_JAR_REQUIREMENT) ?? 0)
  );
  const isNotEligibleForTicket =
    stakingData.lockedMonths < requiredLockedMonths ||
    compareNumbers(stakingData.stakedAmount, requiredStakedAmount) === -1;
  const secondTokenImage = isLpStaking ? bnbTokenLogo : undefined;
  const milestones = Object.keys(stakingRewards).map(Number);

  /** Fetching LP token balance **/
  useEffect(() => {
    if (!web3Service || !address || !isLpStaking) return;

    updateLpBalance();
    lpBalanceInterval.current = setInterval(() => {
      updateLpBalance();
    }, INTERVAL.BALANCE);

    return () => {
      clearInterval(lpBalanceInterval.current);
    };
  }, [web3Service, address, selectedPool]); // eslint-disable-line

  /** Fetching available item reward tickets **/
  useEffect(() => {
    if (stakingData.eligibleStakingTime === 0 || localEligibleTime > 0) return;

    const tickets = Math.floor(stakingData.eligibleStakingTime / STAKING_CONFIG.SECONDS);
    setTickets(tickets);
    setLocalEligibleTime(stakingData.eligibleStakingTime);
  }, [stakingData.stakedAmount, stakingData.eligibleStakingTime]); // eslint-disable-line

  useEffect(() => {
    if (!stakingAmount) {
      setFSPAmount("0");
      return;
    }

    setFSPAmount(multiplyNumbers(stakingAmount, isLpStaking ? STAKING_CONFIG.LP_PRICE_MULTIPLIER : 1));
  }, [stakingAmount, isLpStaking]);

  useEffect(() => {
    setEligibleExpJar(Math.floor(+divideNumbers(stakingData.claimedExp, STAKING_CONFIG.EXP_JAR_REQUIREMENT) ?? 0));
  }, [stakingData.claimedExp]);

  useEffect(() => {
    function getTokenEligibility(stakingAmount: number, lockedMonths: number) {
      const milestones = Object.keys(stakingRewards).map(Number);

      if (stakingAmount >= milestones[0] && lockedMonths >= 6) {
        // const doubleRewards = compareNumbers(lockedMonths, 12) === 0;

        let stakeMilestone = 0;
        for (let i = 0; i < milestones.length; i++) {
          if (stakingAmount < milestones[i]) {
            break;
          }
          stakeMilestone = milestones[i];
        }
        const milestoneReached = stakingRewards[stakeMilestone];
        const earnedReward = {
          ...milestoneReached,
          // amount: doubleRewards ? milestoneReached.amount * 2 : milestoneReached.amount,
          amount: milestoneReached.amount,
        };
        setReward(earnedReward);
      } else {
        setReward(null);
      }
    }

    getTokenEligibility(+stakingAmount, lockedMonths);
  }, [stakingAmount, lockedMonths]); // eslint-disable-line

  function handleClaimItems() {
    dispatch(claimItems(knightId, selectedPool));
  }

  async function updateLpBalance() {
    const balance = await web3Service.fetchLpTokenBalance(address);
    setLpBalance(formatBigNumber(balance));
  }

  function getStakingData() {
    let tokenBalance = lpBalance;
    let tokenSymbol = STAKING_CONFIG.LIQUIDITY.SYMBOL;
    let requiredStakedAmount = STAKING_CONFIG.LIQUIDITY.STAKED_AMOUNT;
    let requiredLockedMonths = STAKING_CONFIG.LIQUIDITY.LOCKED_MONTHS;
    let rewardItems = STAKING_CONFIG.LIQUIDITY.ITEM_IDS;

    if (!isLpStaking) {
      tokenBalance = balance.FARA;
      tokenSymbol = STAKING_CONFIG.SINGLE.SYMBOL;
      requiredStakedAmount = STAKING_CONFIG.SINGLE.STAKED_AMOUNT;
      requiredLockedMonths = STAKING_CONFIG.SINGLE.LOCKED_MONTHS;
      rewardItems = STAKING_CONFIG.SINGLE.ITEM_IDS;
    }

    return { tokenSymbol, tokenBalance, requiredStakedAmount, requiredLockedMonths, rewardItems };
  }

  function renderStakingOption(value: number) {
    if (stakingData.stakedAmount !== "0" && value < stakingData.lockedMonths) return;
    return (
      <option value={value} key={value}>
        {value === 0
          ? "Flexible - 0% reward bonus"
          : `${value} Month${value === 1 ? "" : "s"} - ${value * AMPLIFIED_STAKING}% reward bonus`}
      </option>
    );
  }

  function renderTokenRewards(reward) {
    const minimumReward: any = _.find(stakingRewards);
    if (reward) {
      return `Eligible for ${reward.amount} ${MATERIALS[reward.id].displayName} `;
    }
    return `At least ${formatNumber(milestones[0], 4)} ${
      isLpStaking ? "FARA-BNB" : "FARA"
    } locked for 6 months to get ${minimumReward!.amount} ${MATERIALS[minimumReward!.id].displayName}`;
  }

  function renderTicketTime({ days, hours, minutes, seconds }: any) {
    return (
      <span>
        {days}d {hours}h {minutes}m {seconds}s
      </span>
    );
  }

  function handleOnCountDownComplete() {
    setLocalEligibleTime(STAKING_CONFIG.SECONDS);
    setTickets(tickets + 1);
  }

  const itemRewardCountdown = useMemo(() => {
    const startTime = moment()
      .add((STAKING_CONFIG.SECONDS - (localEligibleTime % STAKING_CONFIG.SECONDS)) * 1000)
      .toString();
    return <Countdown date={startTime} renderer={renderTicketTime} onComplete={handleOnCountDownComplete} overtime />;
  }, [localEligibleTime, tickets]); // eslint-disable-line

  return (
    <div className="staking-form__container" key={selectedPool}>
      <div className="staking-form__block slide-up">
        <div>
          <div className="dashed-title">
            <span>Stake</span>
          </div>
          <InputGroup
            value={stakingAmount}
            handleChange={handleStakingAmountChange}
            symbol={tokenSymbol}
            balance={tokenBalance}
            error={stakingError}
            tokenImage={faraTokenLogo}
            secondTokenImage={secondTokenImage}
          />
          {![STAKING_POOL.SINGLE_V3, STAKING_POOL.LIQUIDITY_V3].includes(selectedPool) && (
            <div className="text-field__fsp">
              <span>FSP you will get: </span>
              <span>{roundNumber(fspAmount, 0, true)} FSP</span>
              <Tooltip
                title={
                  (!isLpStaking
                    ? "1 FARA = 1 FSP (Farastarter Point)"
                    : `1 FARA-BNB LP = ${STAKING_CONFIG.LP_PRICE_MULTIPLIER} FSP (Farastarter Point)`) +
                  ". FSP is used for participating in IDOs from potential projects launched at FaraStarter.com"
                }
                arrow
                placement="top"
              >
                <img className="info-icon" src={infoIcon} alt="" />
              </Tooltip>
            </div>
          )}
          <FormControl variant="outlined" className="input input--select mt-3">
            <InputLabel htmlFor="outlined-age-native-simple">Lock Time</InputLabel>
            <Select label="Lock Time" value={lockedMonths} onChange={handleLockTimeChange} native>
              {[...Array(7)].map((x, i) => renderStakingOption(i))}
            </Select>
          </FormControl>
          {isStakingV3 ? (
            <div className="fs-2">
              <div className="mt-2 mb-2">
                <span>Token Eligibility: </span>
                <span className="text-9">{renderTokenRewards(reward)}</span>
              </div>
              <div>
                <div className="mb-1">
                  <span>Tokens:</span>
                  <Tooltip title={renderTokenRequirementsTooltip()} arrow placement="top">
                    <img className="info-icon" src={infoIcon} alt="" />
                  </Tooltip>
                </div>
                <div>
                  {[STAKING_REWARDS[20000].id, STAKING_REWARDS[100000].id].map((materialId: number, index: number) => {
                    return <MaterialIcon key={index} material={MATERIALS[materialId]} layer={2} />;
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="fs-2">
              <div className="mt-2 mb-2">
                <span>Ticket Requirements: </span>
                <span className="text-9">
                  At least {formatNumber(requiredStakedAmount)} {tokenSymbol} locked for {requiredLockedMonths} months.
                </span>
                <Tooltip
                  title={`The tickets gained are not cumulative on the same hero. It means that by staking ${formatNumber(
                    requiredStakedAmount * 2
                  )} ${tokenSymbol} locked for ${requiredLockedMonths} months on this hero, only 1 ticket can be generated per month, not 2.`}
                  arrow={true}
                  placement="top"
                >
                  <img className="info-icon" src={infoIcon} alt="" />
                </Tooltip>
              </div>
              <div>
                <div className="mb-1">
                  <span>Ticket Items:</span>
                  <Tooltip
                    title="The ticket received each month will be randomly converted to NFT item with the corresponding rarity class. More type of NFT items will be added later on."
                    arrow
                    placement="top"
                  >
                    <img className="info-icon" src={infoIcon} alt="" />
                  </Tooltip>
                </div>
                <div>
                  {rewardItems.map((itemId: number, index: number) => {
                    return <ItemIcon key={index} item={EQUIPMENT[itemId]} layer={2} />;
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        {/*TODO: Re-enable for Staking V3 */}
        <div className={`staking-form__btn btn ${!isStakingV3 ? "disabled" : ""}`} onClick={() => handleStake(reward)}>
          {needApprove ? "Approve" : "Stake"}
        </div>
      </div>
      <div className="staking-form__block slide-up">
        <div>
          <div className="dashed-title">
            <span>Unstake</span>
          </div>
          <InputGroup
            value={unstakingAmount}
            handleChange={handleUnstakingAmountChange}
            symbol={tokenSymbol}
            balance={stakingData.stakedAmount}
            balanceText="Staked"
            error={unstakingError}
            tokenImage={faraTokenLogo}
            secondTokenImage={secondTokenImage}
          />
          {stakingData.lockedMonths !== 0 && (
            <div className="text-field__balance">
              <span>Amplified Amount Staked:</span>
              <div className="text-9">
                <span>
                  {formatNumber(
                    +stakingData.stakedAmount +
                      (+stakingData.stakedAmount * stakingData.lockedMonths * AMPLIFIED_STAKING) / 100,
                    4
                  )}{" "}
                  {tokenSymbol}
                </span>
                <Tooltip
                  title="Only EXP and FARA reward are calculated accordingly to the amplified amount."
                  arrow
                  placement="top"
                >
                  <img className="info-icon" src={infoIcon} alt="" />
                </Tooltip>
              </div>
            </div>
          )}
          {stakingData.unlockedTime !== 0 && (
            <div className="staking-form__countdown">
              <div>Unlocked In:</div>
              <div className="fs-2">
                <Countdown date={unix(stakingData.unlockedTime).toString()} renderer={renderTime} />
              </div>
            </div>
          )}
        </div>
        <div className={`staking-form__btn btn ${disableUnstakeBtn ? "disabled" : ""}`} onClick={handleUnstake}>
          Unstake
        </div>
      </div>
      <div className="staking-form__block align-center slide-up">
        <div className="staking-form__content">
          <div>
            <div className="dashed-title">
              <span>Reward</span>
            </div>
            <div>
              <div className="mb-1">{renderCountUpNumber(+stakingData.tokenEarned)} FARA</div>
              <div>{renderCountUpNumber(+stakingData.expEarned)} EXP</div>
            </div>
          </div>
          <div className={`staking-form__btn btn ${disableClaimBtn ? "disabled" : ""}`} onClick={handleClaim}>
            Claim Reward
          </div>
        </div>
        {isStakingV3 ? (
          <div className="staking-form__content">
            <div>
              <div className="dashed-title">
                <span>Claimed Exp</span>
              </div>
              <div>{formatNumber(stakingData.claimedExp, 4)} EXP</div>

              <div className="fs-2 text-gray mt-1">
                1 EXP Jar ={" "}
                <span className="knight-staking__highlight-text no-margin">
                  {formatNumber(STAKING_CONFIG.EXP_JAR_REQUIREMENT)} EXP
                </span>{" "}
              </div>
              {eligibleExpJar > 0 && <div className="mt-1">Eligible for {eligibleExpJar} EXP Jars</div>}
            </div>
            <div
              className={`staking-form__btn btn ${eligibleExpJar === 0 ? "disabled" : ""}`}
              onClick={() => handleClaimExpJar(eligibleExpJar.toString())}
            >
              Claim Exp Jar
            </div>
            <div className={`staking-form__btn btn ${tickets === 0 ? "disabled" : ""}`} onClick={handleDistributeExp}>
              Distribute EXP
            </div>
          </div>
        ) : (
          <div className="staking-form__content">
            <div>
              <div className="dashed-title">
                <span>Ticket</span>
              </div>
              <div>{tickets} TICKET</div>
              {isNotEligibleForTicket ? (
                <div className="fs-2 text-gray">(Requirements not met)</div>
              ) : (
                <div className="mt-1">
                  <span>Next Ticket:</span>
                  <span className="knight-staking__highlight-text">{itemRewardCountdown}</span>
                </div>
              )}
            </div>
            <div className={`staking-form__btn btn ${tickets === 0 ? "disabled" : ""}`} onClick={handleClaimItems}>
              Claim Item
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
