import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Equipment, UserItem } from "src/app/types/equipment";
import { INTERVAL, SKILL_BOOK_TRANSACTION } from "src/app/configs/constants";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import { useHistory } from "react-router-dom";
import { SkillProvider, SkillTreeGroup, SkillTree } from "beautiful-skill-tree";
import _ from "lodash";
import { Knight } from "src/app/types/knight";
import { fetchLearnedSkills, getSkills, learnSkills, resetSkill } from "src/app/services/api/faralandService";
import { Skill } from "src/app/types/skills";
import { NodeSelectEvent } from "beautiful-skill-tree/dist/models";
import { Tooltip } from "@material-ui/core";
import { createSkills } from "src/app/factories/knightSkillsFactory";
import { DemiKnight } from "src/app/types/demiKnight";
import { setGlobalModal } from "src/app/actions/globalAction";

const useKnightSkills = (id: string, isDemi: boolean, knight?: Knight | DemiKnight) => {
  const dispatch = useDispatch();
  const { address, wallet } = useSelector((state: any) => state.account);
  const [userSkills, setUserSkills] = useState<any[]>([]);
  const [isOwner, setIsOwner] = useState(false);
  const history = useHistory();
  const inventoryInterval = useRef<any>();
  const [closed, setClosed] = useState<boolean>(false);
  const [skillPoints, setSkillPoints] = useState(0);
  const [apiSkillPoints, setApiSkillPoints] = useState(0);
  const [apiSkills, setApiSkills] = useState<Skill[]>([]);
  const [localStorageFlag, setLocalStorageFlag] = useState<Skill[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const isSkillLearned = useMemo(() => {
    return !_.isEmpty(
      skills.filter((skill: Skill) => {
        return skill.actualLearned > 0;
      })
    );
  }, [skills]);

  const theme = {
    backgroundColor: "transparent",
    border: "1px solid transparent",
    primaryFont: "Montserrat",
    headingFont: "American Captain",
    headingFontColor: "transparent",
    headingHoverColor: "#444453",
    nodeBorderColor: "transparent",
    nodeBackgroundColor: "transparent",
    nodeActiveBackgroundColor: "#31d0aa",
    tooltipTitleFontSize: "30px",
    treeBackgroundColor: "#2A2935",
    nodeHoverBorder: "4px solid #31d0aa",
    nodeHoverBorderColor: `linear-gradient(
      to right,
      #31d0aa 0%,
      #31d0aa 100%
    )`,
    edgeBorder: "1px solid #444165",
  };

  useEffect(() => {
    _fetchSkillTree(id, isDemi);
  }, [id, isDemi]);

  useEffect(() => {
    if (!(address || id) || isDemi) {
      setUserSkills([]);
      return;
    }
    _fetchLearnedSkills(id);
    inventoryInterval.current = setInterval(() => {
      _fetchLearnedSkills(id);
    }, INTERVAL.INVENTORY_WALLET);

    return () => {
      clearInterval(inventoryInterval.current);
    };
  }, [address, id, isDemi]); // eslint-disable-line

  useEffect(() => {
    const isOwner = !!knight && !!knight.owner && knight.owner.toLowerCase() === address;
    setIsOwner(isOwner);
  }, [knight, address]);

  useEffect(() => {
    if (!localStorageFlag) return;

    return () => {
      const jobs = _getSkillJobs(localStorageFlag);
      jobs.forEach((value) => {
        localStorage.removeItem(`skills-${value}-${id}`);
      });
    };
  }, [localStorageFlag]); // eslint-disable-line

  async function _fetchSkillTree(id, isDemi) {
    if (!id) return;

    const skillTree = await getSkills(id, isDemi);
    if (skillTree) {
      setSkills(skillTree.tree);
      setApiSkills(skillTree.tree);
      setLocalStorageFlag(skillTree.tree);
      setSkillPoints(skillTree.points);
      setApiSkillPoints(skillTree.points);
    }
  }

  function _getSkillJobs(skills: Skill[]) {
    // Get unique job values for skill tree
    return [
      ...Array.from(
        new Set(
          skills.map((skill) => {
            return skill.job;
          })
        )
      ),
    ];
  }

  async function _fetchLearnedSkills(id) {
    if (!id) return;
    const learnedSkills = await fetchLearnedSkills(id, address);
    if (learnedSkills) {
      setUserSkills(learnedSkills);
    }
  }

  function redirectToEquipmentDetails(userItem: UserItem) {
    history.push(`/equipment/${userItem.item.id}`);
  }

  function renderLearnedSkills() {
    return (
      <>
        {userSkills.length > 0 && (
          <div className="knight-skills knight-preview__inventory">
            <div className="knight-preview__inventory-title">
              <p>Learned Skills</p>
              <div className={`inventory nice-scroll nice-scroll--small`}>
                {userSkills.map((userItem: UserItem, index: number) => {
                  return (
                    <ItemIcon
                      key={index}
                      userItem={userItem}
                      item={userItem.item}
                      showBalance={false}
                      closeOnSubmit={false}
                      submitText="Detail"
                      isLearnedSkills={true}
                      onSubmit={() => redirectToEquipmentDetails(userItem)}
                      extraPanels={() => renderLearnedSKillsCustomPanel(userItem.item)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  // function openUpgradeLearnedSkillModal(item: Equipment) {
  //   dispatch(
  //     setGlobalModal("skillbookActionModal", {
  //       active: true,
  //       data: { item, heroId: id, transactionType: SKILL_BOOK_TRANSACTION.UPGRADE },
  //     })
  //   );
  // }

  function openTransferLearnedSkillModal(item: Equipment) {
    dispatch(
      setGlobalModal("skillbookActionModal", {
        active: true,
        data: { item, heroId: id, transactionType: SKILL_BOOK_TRANSACTION.TRANSFER },
      })
    );
  }

  function renderLearnedSKillsCustomPanel(item: Equipment) {
    return (
      <>
        {/* <div className={`btn mb-4`} onClick={() => openUpgradeLearnedSkillModal(item)}>
          Level Up
        </div> */}
        <div className={`btn`} onClick={() => openTransferLearnedSkillModal(item)}>
          Transfer
        </div>
      </>
    );
  }
  function handleNodeSelect(e: any) {
    setSkillPoints(skillPoints - 1);
    setSkills(createSkills(skills, e.skill.id, e.learned, apiSkills));
  }

  function handleNodeRemove(e: any) {
    setSkillPoints(skillPoints + 1);
    setSkills(createSkills(skills, e.skill.id, e.learned, apiSkills));
  }

  async function handleReset() {
    const signData = await wallet.signData(
      address,
      { knightId: id },
      {
        Group: [{ name: "knightId", type: "uint256" }],
      }
    );

    await resetSkill(id, isDemi, signData.sign, signData.msgParams);
    const res = await getSkills(id, isDemi, undefined, 0);
    if (res) {
      setSkills(res.tree);
      setApiSkills(res.tree);
      setSkillPoints(res.points);
      setApiSkillPoints(res.points);
    }
  }

  async function handleConfirm() {
    let selected = {};
    const jobs = _getSkillJobs(skills);
    jobs.forEach((job) => {
      selected = { ...selected, ...JSON.parse(localStorage.getItem(`skills-${job}-${id}`) || "{}") };
    });
    /**
     * Format: [{ "id": 2, "learned": 5 }, { "id": 15, "learned": 5 }]
     **/
    const bodyObj = Object.values(selected).map((value: any) => {
      return {
        id: value.id,
        learned: value.learned,
      };
    });

    const signData = await wallet.signData(
      address,
      { id: id, bodyObj: bodyObj },
      {
        Body: [
          { name: "id", type: "uint256" },
          { name: "learned", type: "uint256" },
        ],
        Group: [
          { name: "id", type: "uint256" },
          { name: "bodyObj", type: "Body[]" },
        ],
      }
    );

    await learnSkills(id, isDemi, bodyObj, signData.sign, signData.msgParams);
    const res = await getSkills(id, isDemi);
    if (res) {
      setSkills(res.tree);
      setApiSkills(res.tree);
      setSkillPoints(res.points);
      setApiSkillPoints(res.points);
    }
  }

  function renderSkillTree() {
    if (skills.length === 0) return;
    return (
      <div>
        <div className="mt-5">
          <div className="flex-center-start mb-3">
            <div className="knight-skills__title mr-3">Innate Skills</div>
            <div className="knight-skills__btn">
              <Tooltip title={closed ? "Click to show Innate Skills" : "Click to hide Innate Skills"} placement="top">
                <div className={`btn stats__btn`} onClick={() => setClosed(!closed)}>
                  {closed ? "Show" : "Hide"}
                </div>
              </Tooltip>

              {isOwner && isSkillLearned && (
                <div className="btn stats__btn ml-2" onClick={handleReset}>
                  Reset Skills
                </div>
              )}
              {isOwner && skillPoints !== apiSkillPoints && (
                <div className="btn stats__btn ml-2" onClick={handleConfirm}>
                  Confirm
                </div>
              )}
            </div>
          </div>
          <div className="knight-skills__available">{skillPoints} Skill Point(s) is available</div>
          <SkillProvider>
            <SkillTreeGroup theme={theme}>
              {() => {
                return (
                  <div className={`flex knight-skills__tree slide-up ${closed ? "knight-skills__tree--closed" : ""}`}>
                    {skills.map((skill: Skill, index: number) => (
                      <SkillTree
                        key={index}
                        treeId={`${skill.job}-${id}`}
                        title={skill.jobName}
                        data={[skill]}
                        currentLevel={knight!.level}
                        skillPoint={skillPoints}
                        isOwner={isOwner}
                        handleNodeSelect={(e: NodeSelectEvent) => handleNodeSelect(e)}
                        handleNodeRemove={(e: NodeSelectEvent) => handleNodeRemove(e)}
                      />
                    ))}
                  </div>
                );
              }}
            </SkillTreeGroup>
          </SkillProvider>
        </div>
      </div>
    );
  }

  return { renderLearnedSkills, renderSkillTree };
};

export default useKnightSkills;
