import React from "react";
import KnightList from "src/app/components/Knight/KnightList";

export default function KnightMarketplace() {
  return (
    <div className="slide-up">
      <KnightList
        showOwned={true}
        showStaked={false}
        showOffering={true}
        redirectToDetails={true}
      />
    </div>
  )
}