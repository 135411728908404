import React from "react";
import BasicTeam from "./BasicTeam/BasicTeam";
import BodyParts from "./BodyParts/BodyParts";
import DemiHero from "./DemiHero/DemiHero";
import FaraCrystal from "./FaraCrystal/FaraCrystal";
import Gameplay from "./Gameplay/Gameplay";
import Guild from "./Guild/Guild";
import HeroLineup from "./HeroLineup/HeroLineup";
import Intro from "./Intro/Intro";
import Soulstone from "./Soulstone/Soulstone";
import TypeAdvantages from "./TypeAdvantages/TypeAdvantages";
import UpcomingFeatures from "./UpcomingFeatures/UpcomingFeatures";

export default function Instructions() {
  return (
    <div className="instructions slide-up">
      <div className="container">
        <Intro />
        <Gameplay />
        <BodyParts />
      </div>
      <TypeAdvantages />
      <div className="container">
        <HeroLineup />
        <BasicTeam />
      </div>
      <DemiHero />
      <Soulstone />
      <div className="container">
        <FaraCrystal />
        <UpcomingFeatures />
        <Guild />
      </div>
    </div>
  );
}
