import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS } from "src/app/configs/constants";
import { _sendTx } from "src/app/sagas/index";
import { commonActionTypes } from "src/app/actions/commonAction";

function* approveForAll(action: any): any {
  yield call(_sendTx, ACTIONS.APPROVE_FOR_ALL, action.payload, action.callbacks.onSuccess);
}

export default function* commonWatcher() {
  yield takeLatest(commonActionTypes.APPROVE_FOR_ALL, approveForAll);
}
