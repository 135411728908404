import { StakingData } from "src/app/types/staking";

export const stakingActionTypes = {
  SET_STAKING_DATA: "STAKING.SET_STAKING_DATA",
  STAKE: "STAKING.STAKE",
  UNSTAKE: "STAKING.UNSTAKE",
  CLAIM: "STAKING.CLAIM",
  CLAIM_ITEM: "STAKING.CLAIM_ITEM",
  EMERGENCY_WITHDRAW: "STAKING.EMERGENCY_WITHDRAW",
  CONVERT_EXP: "STAKING.CONVERT_EXP",
  CLAIM_EXP_JAR: "STAKING.CLAIM_EXP_JAR",
  CONSUME_EXP_JAR: "STAKING.CONSUME_EXP_JAR",
  DISTRIBUTE_EXP: "STAKING.DISTRIBUTE_EXP",
};

export function setStakingData(data: StakingData) {
  return {
    type: stakingActionTypes.SET_STAKING_DATA,
    payload: data,
  };
}

export function stake(knightId: number, amount: string, lockedMonths: number, selectedPool: number, earnedReward) {
  return {
    type: stakingActionTypes.STAKE,
    payload: { knightId, amount, lockedMonths, selectedPool, earnedReward },
  };
}

export function unstake(knightId: number, amount: string, selectedPool: number) {
  return {
    type: stakingActionTypes.UNSTAKE,
    payload: { knightId, amount, selectedPool },
  };
}

export function claim(knightId: number, selectedPool: number) {
  return {
    type: stakingActionTypes.CLAIM,
    payload: { knightId, selectedPool },
  };
}

export function claimItems(knightId: number, selectedPool: number) {
  return {
    type: stakingActionTypes.CLAIM_ITEM,
    payload: { knightId, selectedPool },
  };
}

export function convertExp(knightId: number, levelUpAmount: number, onSuccess: any) {
  return {
    type: stakingActionTypes.CONVERT_EXP,
    payload: { knightId, levelUpAmount },
    callbacks: { onSuccess },
  };
}

export function emergencyWithdraw(knightId: number, selectedPool: number) {
  return {
    type: stakingActionTypes.EMERGENCY_WITHDRAW,
    payload: { knightId, selectedPool },
  };
}

export function claimExpJar(expJarAmount: string) {
  return {
    type: stakingActionTypes.CLAIM_EXP_JAR,
    payload: { expJarAmount },
  };
}

export function consumeExpJar(knightId: number, amount: number, onSuccess: any) {
  return {
    type: stakingActionTypes.CONSUME_EXP_JAR,
    payload: { knightId, amount },
    callbacks: { onSuccess },
  };
}

export function distributeExp(knightIds: number[], amount: string[], onSuccess?: any) {
  console.log(amount);
  return {
    type: stakingActionTypes.DISTRIBUTE_EXP,
    payload: { knightIds, amount },
    callbacks: { onSuccess },
  };
}
