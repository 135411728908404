import Web3 from "web3";
import { checkIsCoin98, fromNetworkIdToName, toBigAmount, toHex } from "src/app/utils/helpers";
import { TxObject } from "src/app/types/tx";
import ENV from "src/app/configs/env";
import { createSignedMessage } from "src/app/factories/txFactory";
import * as SIDfunctions from "@siddomains/sidjs";
import SID from "@siddomains/sidjs";
import { disconnectWalletSession } from "src/app/services/api/faralandService";
export default class BaseWalletService {
  ethereum: any;
  address: string | null;
  nodeUrl: string;
  networkId: number;
  chainName: string;
  web3: any;
  sid: any;
  needTobeInitiated: any;

  constructor(props: any) {
    this.ethereum = null;
    this.address = props?.address;
    this.nodeUrl = props?.nodeUrl;
    this.networkId = props?.networkId;
    this.chainName = props?.chainName;
    this.web3 = new Web3(new Web3.providers.HttpProvider(this.nodeUrl));
    this.sid = new SID({
      provider: new Web3.providers.HttpProvider(ENV.NODE.URL),
      sidAddress: SIDfunctions.getSidAddress(ENV.NETWORK_ID.toString()),
    });
  }

  connect = async (onEthereumError: any = null, firstRun = false) => {
    if (!this.web3 && !this.ethereum) {
      this._returnEthereumError(onEthereumError, "Something went wrong with your wallet connection.");
      return false;
    }

    if (this.ethereum.overrideIsMetaMask === true) {
      this._returnEthereumError(
        onEthereumError,
        "There is another wallet extension installed other than Metamask, please disable them first."
      );
      return false;
    }

    const currentChainId = await this._getCurrentChainId(firstRun);

    if (!currentChainId) {
      this._returnEthereumError(onEthereumError, "Cannot find current chain ID");
      return false;
    } else if (currentChainId !== this.networkId) {
      if (checkIsCoin98()) {
        this._returnEthereumError(onEthereumError, `Please make sure that your Coin98 wallet is on ${ENV.CHAIN_NAME}.`);
        return false;
      }

      const isSwitchSuccess = await this._requestSwitchChain();
      if (!isSwitchSuccess) return false;
    }

    this.address = await this._requestAccounts();

    if (!this.address) {
      this._returnEthereumError(onEthereumError, "Cannot find any available addresses");
      return false;
    }

    return this.address.toLowerCase();
  };

  getDisconnected = (clearAccount?: any, importAccount?: any, wallet?: any) => {
    if (!this.ethereum) return;

    this.ethereum.on("accountsChanged", async (accounts: any) => {
      const isError = await this._throwErrorOnNetworkError(clearAccount);
      if (isError) return;
      await disconnectWalletSession();

      if (accounts.length === 0) {
        clearAccount();
        localStorage.clear();
        return;
      }

      if (accounts[0] === this.address) return;

      this.address = await this._requestAccounts();

      if (wallet) {
        const spaceDomain = await this.sid.getName(this.address);
        wallet.address = this.address;
        // await signAndAuthenticateWallet(wallet, this.address ?? "");
        importAccount(this.address, wallet, wallet.getWalletType(), spaceDomain?.name ?? "");
      }
    });

    this.ethereum.on("chainChanged", async (networkId: any) => {
      if (+networkId === this.networkId) return;
      clearAccount();
      localStorage.clear();
    });

    this.ethereum.on("disconnect", async () => {
      clearAccount();
      localStorage.clear();
    });
  };

  makeTransaction = async (txObject: TxObject): Promise<string> => {
    try {
      await this._throwErrorOnNetworkError();
      return await this.sendTransaction(txObject);
    } catch (error: any) {
      throw Error(error);
    }
  };

  sendTransaction = (txObject: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      this.web3.eth.sendTransaction(txObject, function (err: any, txHash: string) {
        if (!err) {
          resolve(txHash);
        } else {
          let errorMessage = err.message;

          if (err.code === -32000) {
            errorMessage = "Your BNB balance is insufficient to make the transaction.";
          } else if (err.code === -32602) {
            errorMessage =
              "Your current address is different from your previously imported one. Please re-import your address to make the transaction.";
          } else if (err.code === 4100) {
            errorMessage =
              "You are not authorized to interact with this address. Please re-import your address to make the transaction.";
          }

          reject(errorMessage);
        }
      });
    });
  };

  signData = async (account: string, signedData: any, signedType: any) => {
    const msgParams = createSignedMessage(56, "Faraland.io", signedData, signedType);

    const sign = await this.ethereum.request({
      method: "eth_signTypedData_v4",
      params: [account, JSON.stringify(msgParams)],
    });

    return { sign, msgParams };
  };

  _throwErrorOnNetworkError = async (actionOnError?: any) => {
    let isError = false;
    const currentNetworkId = await this._getCurrentChainId();

    if (!currentNetworkId || +currentNetworkId !== this.networkId) {
      if (actionOnError) {
        actionOnError();
      } else {
        throw Error(`Please make sure that your wallet is on ${fromNetworkIdToName(this.networkId)}`);
      }

      isError = true;
    }
    return isError;
  };

  _getCurrentChainId = async (firstRun = false) => {
    let chainId;

    if (this.ethereum) {
      if (firstRun && checkIsCoin98()) {
        chainId = this.ethereum.chainId;
      } else {
        chainId = await this.ethereum.request({ method: "eth_chainId" });
        chainId = toBigAmount(chainId, 0);
      }
    } else if (this.web3) {
      chainId = await this.web3.eth.net.getId();
    }

    return +chainId;
  };

  _requestSwitchChain = async (): Promise<boolean> => {
    try {
      await this.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(ENV.NETWORK_ID) }],
      });

      return true;
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await this.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: toHex(ENV.NETWORK_ID),
                chainName: ENV.CHANGE_NAME,
                rpcUrl: ENV.NODE.URL,
                blockExplorerUrls: [ENV.URL.EXPLORER],
                nativeCurrency: ENV.CHAIN_CURRENCY,
              },
            ],
          });
        } catch (addError) {
          console.log(addError);
        }
      }

      console.log(switchError);
    }

    return false;
  };

  _requestAccounts = async () => {
    let accounts;

    try {
      if (this.ethereum) {
        accounts = await this.ethereum.request({ method: "eth_requestAccounts" });
      }
    } catch (e) {
      console.log(e);
    }

    if (!accounts || accounts.length === 0) {
      accounts = await this.web3.eth.getAccounts();
    }

    const legacyAccount = accounts ? accounts[0] : null;
    const newAccount = accounts.result ? accounts.result[0] : null;

    return legacyAccount ? legacyAccount : newAccount;
  };

  _returnEthereumError = (onEthereumError: any, message: string) => {
    if (typeof onEthereumError === "function") onEthereumError(message);
  };
}
