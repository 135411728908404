import React, { useEffect, useState } from "react";
import { Item, ItemEffectType, ItemSet, ItemSetBonusType, SlotEquipment } from "src/app/types/equipment";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { EQUIPMENT_SET } from "src/app/configs/equipment/sets";
import { mapStatsKey } from "src/app/utils/mappingHelpers";
import { KnightStats } from 'src/app/types/knight';
import { getTotalPrimaryStats, knightMetRequiredStats } from "src/app/utils/helpers";

type ItemIconProps = {
  item: Item
  knightStats?: KnightStats
  slotEquipments?: SlotEquipment[]
  isKnightPreview?: boolean
  largeText?: boolean
}

export default function ItemSetBonus({ item, slotEquipments, isKnightPreview, largeText, knightStats }: ItemIconProps) {
  const [equippedSetNames, setEquippedSetNames] = useState<string[]>([]);

  const itemSet: ItemSet = item.setId ? EQUIPMENT_SET[item.setId] : 0;

  useEffect(() => {
    if (!slotEquipments) return;

    let equippedSetNames: string[] = [];
    const totalNonItemStats = knightStats && getTotalPrimaryStats(knightStats, true);
    slotEquipments.forEach((slotEquipment: SlotEquipment) => {
      const equippedItem: Item = EQUIPMENT[slotEquipment.id];
      if (item.setId === equippedItem.setId && knightMetRequiredStats(totalNonItemStats, equippedItem.requiredStats)) {
        equippedSetNames.push(equippedItem.name);
      }
    });

    setEquippedSetNames(equippedSetNames);
  }, [slotEquipments, item.setId, knightStats]);

  function renderSetBonus(stats: any) {
    let isFirstValue = true;
    return Object.keys(stats).map((key: string, i: number) => {
      if (!stats[key]) return null;

      const separator = !isFirstValue && ', ';
      isFirstValue = false;

      if (key === 'effects') {
        return stats['effects'].map((effect: ItemEffectType) => {
          return <span key={i}>{separator}{effect.name}: {effect.description}</span>
        })
      }

      return <span key={i}>{separator}+{stats[key]} {mapStatsKey(key)}</span>
    })
  }

  return (
    <div className={`item-stats item-stats--${largeText && 'large'}`}>
      {!!item.setId && (
        <>
          <div className="mt-1">
            <div className="item-stats__label">Item Set Bonus:</div>
            <div className="item-stats__content pl-1">
              {itemSet.bonuses.map((bonus: ItemSetBonusType, i: number) => {
                return (
                  <div key={i} className="item-stats__block">
                    <span>- </span>
                    <span>
                      <span>{bonus.pieces} Items: </span>
                      <span className={`item-stats__value text-${equippedSetNames.length >= bonus.pieces ? "9" : "gray"}`}>
                        {renderSetBonus(bonus.stats)}
                      </span>
                    </span>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="mt-1">
            <div className="item-stats__label">
              <span>The {itemSet.name} Set </span>
              <span>({`${isKnightPreview ? `${equippedSetNames.length}/` : ''}${itemSet.itemNames.length}`}):</span>
            </div>
            <div className="item-stats__content flex-2-column pl-1">
              {itemSet.itemNames.map((itemName: string, i: number) => {
                return (
                  <div key={i} className="item-stats__block">
                    <span>- </span>
                    <span className={`item-stats__value text-${equippedSetNames.includes(itemName) ? "yellow" : "gray"}`}>
                      {itemName}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}