import React from "react";
import { Tooltip } from "@material-ui/core";
import { Equipment } from "src/app/types/equipment";
import ENV from "src/app/configs/env";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import ItemInfo from "src/app/components/Equipment/Commons/ItemInfo";
import { RARITY_LABEL } from "src/app/configs/constants";

type EquipmentIconProps = {
  items: Equipment[],
  showMinted: boolean,
  showAmount: boolean
}

export default function ItemIconGacha(props: EquipmentIconProps) {
  function showEquipmentInfoModal(itemId: number) {
    modalService.show(BasicModalContent, {
      content: (
        <ItemInfo itemId={itemId} />
      )
    })
  }

  return (
    <div className='gacha__item'>
      {props.items.map((item: Equipment) => {
        return (
          <Tooltip title={`${item.displayName} - ${RARITY_LABEL[item.rarity]}`} arrow placement="top" key={item.id}>
            <div className={`item-icon ${item.minted === item.maxSupply ? 'low-opacity' : ''}`} onClick={() => showEquipmentInfoModal(item.id)}>
              <img className='item-icon__image' src={`${ENV.URL.BASE_IMAGE_URL}/images/icon/178x178/${item.id}.png`} alt='' />
              {props.showAmount && (
                <div className="item-icon__amount">x{item.amount}</div>
              )}
              {props.showMinted && (
                <div className="item-icon__text">{item.minted}/{item.maxSupply}</div>
              )}
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}