import React from "react";
import { FormControl, IconButton, InputLabel, Select, Tooltip } from "@material-ui/core";
import { Item, ItemStatsLabel } from "src/app/types/equipment";
import { formatAddress, isEmptyHistory, onBackClicked } from "src/app/utils/helpers";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { RARITY_LABEL } from "src/app/configs/constants";
import disabledGem from "src/assets/images/decors/gems/tier-gem-disabled.svg";
import activeGem from "src/assets/images/decors/gems/tier-gem-active.svg";
import _ from "lodash";
import { mapStatsKey } from "src/app/utils/mappingHelpers";

export function renderEmptyInventory(amount) {
  return Array.from(Array(amount).keys()).map((_, index) => (
    <Tooltip title={`Slot ${index + 1}`} placement="top" arrow key={index}>
      <div className="knight-preview__inventory-item">
        <img
          className="knight-preview__inventory-bg"
          src={require(`src/assets/images/hero-details/slot/inventory-slot.png`)}
          alt=""
        />
      </div>
    </Tooltip>
  ));
}

export function renderStats(stats: ItemStatsLabel, isComparisonTooltip: boolean, item?: Item) {
  return (
    <div className={`mt-2 ${isComparisonTooltip ? "item-icon__comparison" : ""}`}>
      <div className="mb-1">Stats:</div>
      <div className="flex-2-column pl-1">
        {Object.keys(stats).map((key: any, index: number) => {
          const stat = stats[key];
          if (stat === 0 || key === "effects") return null;
          return (
            <div key={index}>
              - {mapStatsKey(key)}{" "}
              <span className={`fs-2 ${stat > 0 ? "text-9" : "error-text"}`}>{stat > 0 ? `+${stat}` : stat}</span>
            </div>
          );
        })}
        {item?.moveRange && (
          <div>
            <span>- Move Range: </span>
            <span className={`fs-2 ${item?.moveRange > 0 ? "text-9" : "error-text"}`}>
              {item?.moveRange > 0 ? `${item?.moveRange}` : item?.moveRange}
            </span>
          </div>
        )}
        {item?.attackRange &&
          item?.animType !== "shield" &&
          Object.entries(item.attackRange).map(([key, value]) => (
            <div>
              <span>- {key === "min" ? "Min Attack Range" : "Max Attack Range"}: </span>
              <span className={`fs-2 ${value > 0 ? "text-9" : "error-text"}`}>{value > 0 ? `${value}` : value}</span>
            </div>
          ))}
      </div>
    </div>
  );
}

export function renderBackBtn(history) {
  if (!isEmptyHistory(history)) {
    return (
      <div className="btn btn--icon btn--gray" onClick={() => onBackClicked(history)}>
        <ChevronLeftIcon />
        <span>Back</span>
      </div>
    );
  }

  return "";
}

export function renderRarityFilter(
  filterObj: { rarity: string },
  onChange: (value: string) => void,
  isDismantle = false
) {
  const labels = isDismantle ? RARITY_LABEL.filter((_, idx) => idx < 2) : RARITY_LABEL;
  return (
    <FormControl variant="outlined" className="input mr-4 mt-1" style={{ width: 300 }}>
      <InputLabel htmlFor="outlined-age-native-simple">Rarity</InputLabel>
      <Select
        label="Rarity"
        native
        value={filterObj.rarity === "" ? "All" : filterObj.rarity}
        onChange={(e) => onChange(e.target.value as string)}
      >
        {Object.entries(["All", ...labels]).map(([key, value]) => (
          <option value={value} key={key}>
            {value}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

export function renderTierFilter(filterObj: { tier: number }, onChange: (value: number) => void) {
  return (
    <div className="flex-center-center">
      <b>Tier</b>
      {_.range(1, 4).map((tier, index: number) => (
        <Tooltip title={`Tier ${tier}`} placement="top" key={index}>
          <IconButton onClick={() => onChange(tier)}>
            <img className="axe__choosing-item-tier" src={filterObj.tier >= tier ? activeGem : disabledGem} alt="" />
          </IconButton>
        </Tooltip>
      ))}
    </div>
  );
}

export function renderWalletAddress(address: string, domain: string, first = 10, last = -4) {
  if (domain && domain !== "") {
    return <span className="warning-text fs-3">{domain}</span>;
  }
  return formatAddress(address, first, last);
}
