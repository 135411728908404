import React, { useEffect, useRef, useState } from "react";
import { useQueryString } from "use-route-as-state";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import * as notFoundJson from "src/assets/lotties/404.json";
import { Lottie } from "@crello/react-lottie";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";
import { LIMIT, SORT_DATA } from "src/app/configs/constants";
import { useSelector } from "react-redux";
import { fetchDemiFromApi } from "src/app/services/api/faralandService";
import Pagination from "@material-ui/lab/Pagination";
import useUpdateEffect from "src/app/hooks/useUpdateEffect";
import DemiFilter from "src/app/components/Demi/Marketplace/DemiFilter";
import DemiToggle from "src/app/components/Demi/Marketplace/DemiToggle";
import DemiListContent from "src/app/components/Demi/Marketplace/DemiListContent";
import { DemiKnight, DemiFilterType } from "src/app/types/demiKnight";
import { getDemiFilterFromParams } from "src/app/utils/filterHelper";
import { DemiQuery } from "src/app/types/demiKnight";
import { createDemiKnight, updateKnightAttributesFromApi } from "src/app/factories/knightFactory";
import { KnightStats } from "src/app/types/knight";

interface DemiMarketplaceProps {
  showOwned: boolean;
  redirectToDetails: boolean;
}

export default function DemiList(props: DemiMarketplaceProps) {
  const { address } = useSelector((state: any) => state.account);
  const demiListRef = useRef(null);
  const [queryParams, setQueryParams] = useQueryString();
  const [demiFilters, setDemiFilters] = useState<DemiFilterType>(getDemiFilterFromParams(queryParams));
  const [demi, setDemi] = useState<DemiKnight[]>([]);
  const [demiStatsMap, setDemiStatsMap] = useState(new Map<number, KnightStats>());
  const [isLoading, setLoading] = useState(false);
  const [showConnectWalletError, setShowConnectWalletError] = useState(demiFilters.owned && !address);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useUpdateEffect(() => {
    setDemiFilters(getDemiFilterFromParams(queryParams));
  }, [queryParams]);

  useEffect(() => {
    setShowConnectWalletError(demiFilters.owned && !address);
  }, [demiFilters.owned, address]);

  useEffect(() => {
    setPage(demiFilters.page);
    fetchDemi(demiFilters);

  }, [address, demiFilters]); // eslint-disable-line

  useEffect(() => {
    if (total && total < LIMIT.DEMI) {
      handleChangePage(1);
    }

    const newTotalPage = Math.ceil(total / LIMIT.DEMI);
    setTotalPage(newTotalPage);

    if (newTotalPage && page > newTotalPage) {
      handleChangePage(1);
    }
  }, [total]); // eslint-disable-line

  async function fetchDemi(filter: DemiFilterType) {
    let filteredDemiKnights: DemiKnight[] = [];
    const sortData = SORT_DATA[filter.sortBy];
    const variables: DemiQuery = {
      limit: LIMIT.DEMI,
      orderBy: sortData.key,
      orderDirection: sortData.direction,
      owner: address,
      itemPerPage: LIMIT.DEMI,
      page: filter.page,
    };

    setLoading(true);

    try {
      const result = await fetchDemiFromApi(filter, variables);

      filteredDemiKnights = (result?.list ?? []).map((item: any) => createDemiKnight(item, true));

      if (!filter.staked) {
        setDemiStatsMap(updateKnightAttributesFromApi(result, filteredDemiKnights));
      }
      setTotal(result?.total ?? 0);
    } catch (e) {
      console.log(e);
    }
    setDemi(filteredDemiKnights);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  function onClickPaging(page: number) {
    if (demiListRef && demiListRef.current) {
      // @ts-ignore
      demiListRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    handleChangePage(page);
  }

  function handleChangePage(page: number) {
    setQueryParams({
      ...queryParams,
      page: page.toString(),
    });
  }

  return (
    <div className="market slide-up" ref={demiListRef}>
      <DemiFilter />

      <div className="market__content">
        <DemiToggle isLoading={isLoading} showOwned={props.showOwned} />
        {isLoading && (
          <Lottie
            className="market__loading market__loading--marketplace slide-up"
            config={getAnimatedJsonOptions(loadingJson)}
          />
        )}
        {!isLoading && (showConnectWalletError || demi.length === 0) && (
          <div className="align-center slide-up">
            <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
            <div
              className="market__404-title">{showConnectWalletError ? 'Please connect your wallet first' : 'No Hero found with your searching queries'}.
            </div>
          </div>
        )}
        {!isLoading && demi.length !== 0 && !showConnectWalletError && (
          <div className="market__items slide-up-slow">
            {demi.map((demi: DemiKnight, index: number) => {
              return (
                <DemiListContent
                  key={index}
                  demi={demi}
                  demiStats={demiStatsMap[demi.id]}
                />
              );
            })}
          </div>
        )}
        {totalPage > 1 && !showConnectWalletError && (
          <div className="market__pagination">
            <Pagination
              count={totalPage}
              page={page}
              onChange={(_, val) => onClickPaging(val)}
              variant="outlined"
              color="primary"
            />
          </div>
        )}
      </div>
    </div>
  );
}
