import { TxHistoryRecord } from "src/app/types/tx";
import ENV from "src/app/configs/env";

export const txActionTypes = {
  SET_TRACKING_TXS: 'TX.SET_TRACKING_TXS',
  APPEND_TRACKING_TX: 'TX.APPEND_TRACKING_TX',
  SET_ERROR: 'TX.SET_ERROR',
  SET_TX_CONFIRMING: 'TX.SET_TX_CONFIRMING',
  APPROVE: 'TX.APPROVE',
};

export function approve(spender: string, onSuccess?: any, tokenAddress = ENV.CONTRACT.FARA_TOKEN) {
  return {
    type: txActionTypes.APPROVE,
    payload: { spender, tokenAddress },
    callbacks: { onSuccess }
  }
}

export function setTrackingTxs(txs: TxHistoryRecord[]) {
  return {
    type: txActionTypes.SET_TRACKING_TXS,
    payload: txs
  }
}

export function appendTrackingTx(tx: TxHistoryRecord) {
  return {
    type: txActionTypes.APPEND_TRACKING_TX,
    payload: tx
  }
}

export function setTxError(error: string) {
  return {
    type: txActionTypes.SET_ERROR,
    payload: error
  }
}

export function setTxConfirming(isConfirming: boolean) {
  return {
    type: txActionTypes.SET_TX_CONFIRMING,
    payload: isConfirming
  }
}