import ENV from "src/app/configs/env";
import { WALLET_TYPE, EQUIPMENT_TYPE, ZERO_ADDRESS, BURN_ADDRESS, STAKING_POOL } from "src/app/configs/constants";
import MetamaskService from "src/app/services/accounts/MetamaskService";
import WalletConnectService from "src/app/services/accounts/WalletConnectService";
import DappService from "src/app/services/accounts/DappService";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import moment from "moment";
import {
  EquipmentDismantle,
  Item,
  ItemEffect,
  ItemRanges,
  ItemSet,
  ItemStatsLabel,
  RuneStatsLabel,
  SlotEquipment,
} from "src/app/types/equipment";

import { EQUIPMENT_SET } from "src/app/configs/equipment/sets";
import Coin98Service from "src/app/services/accounts/Coin98Service";
import { getEmptyRanges, getEmptyRuneStats, getEmptyStats } from "src/app/factories/knightFactory";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import genesisSword from "src/assets/images/decors/genesis-sword.png";
import abiDecoder from "abi-decoder";
import CoinbaseWalletService from "src/app/services/accounts/CoinbaseWalletService";
import { PrimaryStats } from "src/app/types/attribute";
import { KnightStats } from "src/app/types/knight";
import _ from "lodash";
import { authenticateWallet } from "src/app/services/api/faralandService";
import { Material } from "src/app/types/materials";

export function getAnimatedJsonOptions(json: any) {
  return {
    loop: true,
    autoplay: true,
    animationData: json.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
}

export function getWalletParams(address?: string) {
  return {
    nodeUrl: ENV.NODE.URL,
    nodeTimeout: ENV.NODE.CONNECTION_TIMEOUT,
    networkId: ENV.NETWORK_ID,
    chainName: ENV.CHAIN_NAME,
    address: address,
  };
}

export function initiateWeb3Object(isMetamask = false) {
  let web3;
  const ethereum = window.ethereum;

  if (ethereum && (isMetamask || +ethereum.networkVersion === ENV.NETWORK_ID)) {
    web3 = new Web3(ethereum as any);
  } else {
    const provider = new Web3.providers.HttpProvider(ENV.NODE.URL);
    web3 = new Web3(provider);
  }

  return { web3, ethereum };
}

export function getWalletByType(address: string, type: string) {
  let wallet: MetamaskService | WalletConnectService | CoinbaseWalletService | DappService | null = null;
  const props = getWalletParams(address);

  if (type === WALLET_TYPE.METAMASK) {
    wallet = new MetamaskService(props);
  } else if (type === WALLET_TYPE.COIN98) {
    wallet = new Coin98Service(props);
  } else if (type === WALLET_TYPE.WALLET_CONNECT) {
    wallet = new WalletConnectService(props);
  } else if (type === WALLET_TYPE.COINBASE_WALLET) {
    wallet = new CoinbaseWalletService(props);
  } else if (type === WALLET_TYPE.DAPP) {
    wallet = new DappService(props);
  }

  return wallet;
}

export function fromNetworkIdToName(networkId: number) {
  let networkName = "Unknown Network";

  if (networkId === 1) {
    networkName = "Ethereum";
  } else if (networkId === 3) {
    networkName = "Ropsten";
  } else if (networkId === 4) {
    networkName = "Rinkeby";
  } else if (networkId === 5) {
    networkName = "Goerli Test";
  } else if (networkId === 42) {
    networkName = "Kovan";
  } else if (networkId === 97) {
    networkName = "BSC testnet";
  } else if (networkId === 56) {
    networkName = "Binance Smart Chain";
  }

  return networkName;
}

export function getBiggestNumber() {
  return "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
}

export function checkIsMetamask() {
  return window.ethereum && window.ethereum.isMetaMask;
}

export function checkIsCoin98() {
  return window.ethereum && window.ethereum.isCoin98;
}

export function formatBigNumber(number: number | string, decimals = 18, precision = 0): any {
  if (!number) return "0";

  const bigNumber = new BigNumber(number.toString());
  const result = bigNumber.div(Math.pow(10, decimals));
  if (precision !== 0) {
    return formatNumber(result, precision);
  }

  return result.toString();
}

export function roundNumber(number: number | string, precision = 6, isFormatted = false) {
  if (!number) return 0;

  const amountBigNumber = new BigNumber(number);
  const amountString = amountBigNumber.toFixed().toString();
  const indexOfDecimal = amountString.indexOf(".");
  const roundedNumber = indexOfDecimal !== -1 ? amountString.slice(0, indexOfDecimal + (precision + 1)) : amountString;

  return isFormatted ? formatNumber(roundedNumber, precision) : roundedNumber;
}

export function formatNumber(number: any, decimals?: number) {
  if (+number === 0) return 0;

  const formattedDecimals = decimals === undefined ? 0 : decimals;
  if (number < 1000) return toMeaningfulNumber(+number, decimals === undefined ? 20 : formattedDecimals);

  let bigNumber = new BigNumber(number);
  let formattedNumber = bigNumber.toFormat(formattedDecimals);
  const numberParts = formattedNumber.split(".");

  if (numberParts.length === 2 && !+numberParts[1]) {
    formattedNumber = numberParts[0];
  }

  return formattedNumber;
}

export function formatAddress(address: string, first = 10, last = -4) {
  if (!address) return "";
  return `${address.slice(0, first)}...${address.slice(last)}`;
}

export function isAddress(address: string) {
  return Web3.utils.isAddress(address);
}

export function toGwei(number: number | string) {
  const bigNumber = new BigNumber(number.toString());
  return bigNumber.div(1000000000).toString();
}

export function toWei(number: number | string) {
  return toBigAmount(number, 9);
}

export function toBigAmount(number: number | string, decimal = 18) {
  const bigNumber = new BigNumber(number.toString());
  return bigNumber.times(Math.pow(10, decimal)).toFixed(0);
}

export function toHex(number: string | number) {
  const bigNumber = new BigNumber(number);
  return "0x" + bigNumber.toString(16);
}

export function findByValue(array: any, key: string, value: any) {
  return array.find((item: any) => {
    return item[key] === value;
  });
}

export function isEmptyObject(object: any) {
  return Object.keys(object).length === 0;
}

export function toMeaningfulNumber(number: number, decimals: number): number {
  const meaningfulNumber = number.toFixed(decimals).match(/^-?\d*\.?0*\d{0,4}/);
  if (!meaningfulNumber) return 0;
  return +meaningfulNumber[0];
}

export function shortenNumber(number: number) {
  const symbol = ["", "K", "M", "B", "T", "P", "E"];
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  if (tier === 0) return number;

  const suffix = symbol[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;

  return scaled.toFixed(1) + suffix;
}

export function timeAgo(timestamp: string | number) {
  return moment(timestamp).fromNow();
}

export function convertStringToNumArray(value: string) {
  return value.split(",").map((num) => +num);
}

export function isMainStat(key: string): boolean {
  return key === "effects" || key === "STR" || key === "AGI" || key === "INT" || key === "LUK";
}

export function wait(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("resolved");
    }, time);
  });
}

export function knightMetRequiredStats(stats: any, requiredStats): boolean {
  if (_.isEmpty(requiredStats)) return true;
  let qualifyCount = 0;
  const statArray: number[] = Object.values(stats);
  requiredStats.forEach((stat, idx: number) => {
    if (statArray[idx] >= stat) {
      qualifyCount++;
    }
  });
  if (qualifyCount >= 2) {
    return true;
  }
  return false;
}

export function calculateStatsFromItems(
  knightLevel: number,
  knightGender: string,
  knightRace: string,
  itemIds: number[],
  knightStats?: KnightStats,
  equippedRunes?: Material[]
) {
  let itemStats: ItemStatsLabel = getEmptyStats();
  let itemSetBonus: ItemStatsLabel = getEmptyStats();
  let equipments: SlotEquipment[] = [];
  let itemRanges: ItemRanges = getEmptyRanges();
  let runeStats: RuneStatsLabel = getEmptyRuneStats();
  let equippedItemSet = {};
  for (let i = 0; i < itemIds.length; i++) {
    if (!+itemIds[i]) continue;
    const item = EQUIPMENT[+itemIds[i]];
    const invalidWeapon =
      item.type === EQUIPMENT_TYPE.SUB_WEAPON && EQUIPMENT[itemIds[EQUIPMENT_TYPE.MAIN_WEAPON]]?.twoHanded;
    const invalidRace = item.races.indexOf(knightRace) === -1;
    const invalidGender = item.genders.indexOf(knightGender) === -1;
    const invalidLevel = item.level > knightLevel;
    const totalStats = knightStats && getTotalPrimaryStats(knightStats, true);
    const invalidStats = !_.isEmpty(totalStats) && !knightMetRequiredStats(totalStats, item.requiredStats);

    equipments.push({
      id: item.id,
      name: item.name,
      slot: item.type,
    });

    if (
      invalidWeapon ||
      invalidRace ||
      invalidGender ||
      invalidLevel ||
      (!_.isEmpty(item.requiredStats) && invalidStats === true)
    )
      continue;

    const setId = item.setId;
    if (setId) equippedItemSet[setId] = (equippedItemSet[setId] ?? 0) + 1;
    const stats = item.stats;
    _updateItemStats(stats, itemStats);

    if (item.type === EQUIPMENT_TYPE.MAIN_WEAPON || item.type === EQUIPMENT_TYPE.SUB_WEAPON) {
      _updateItemRanges(item, itemRanges);
    }
  }

  if (equippedRunes && equippedRunes.length > 0) {
    equippedRunes.forEach((rune) => {
      _updateRuneStats(runeStats, rune.stats);
    });
  }
  Object.entries(equippedItemSet).forEach(([setId, pieces]: any) => {
    const itemSet = EQUIPMENT_SET[setId];
    itemSet.bonuses.forEach((value: any) => {
      if (pieces >= value.pieces) {
        _updateItemStats(value.stats, itemSetBonus);
      }
    });
  });

  return { itemStats, itemSetBonus, equipments, itemRanges, runeStats };
}

function _updateRuneStats(totalRuneStats: RuneStatsLabel, stats?: RuneStatsLabel) {
  if (!stats) return;
  Object.entries(stats).forEach(([key, value]) => {
    totalRuneStats[key] = totalRuneStats[key] + value;
  });
}

function _updateItemStats(stats: ItemStatsLabel, totalItemStats: ItemStatsLabel) {
  Object.entries(stats).forEach(([key, value]: any) => {
    if (key === "effects") {
      if (value.length > 0) {
        value.forEach((effect: ItemEffect) => {
          totalItemStats[key].push(effect);
        });
      }
    } else {
      totalItemStats[key] = totalItemStats[key] + value;
    }
  });
}

function _updateItemRanges(item: Item, totalItemRanges) {
  Object.entries(item).forEach(([key, value]: any) => {
    if (key === "moveRange") {
      if (totalItemRanges["Move Range"] === 0) {
        totalItemRanges["Move Range"] = totalItemRanges["Move Range"] + value;
      } else {
        totalItemRanges["Move Range"] = totalItemRanges["Move Range"] < value ? totalItemRanges["Move Range"] : value;
      }
    } else if (key === "attackRange") {
      const index = totalItemRanges["attackRange"].findIndex((el) => el.type === item.type);
      if (index === -1) {
        totalItemRanges["attackRange"].push({
          type: item.type,
          min: value["min"],
          max: value["max"],
        });
      } else {
        totalItemRanges["attackRange"][index] = {
          type: item.type,
          min: value["min"],
          max: value["max"],
        };
      }
    }
  });
}

export function isDemiInactive(lifespan: number) {
  let isExpired = false;
  const then = moment.unix(lifespan);
  const now = moment();

  if (now.isSameOrAfter(then)) {
    isExpired = true;
  }

  return { then, now, isExpired };
}

export function isKnightDead(owner: string) {
  return owner === BURN_ADDRESS || owner === ZERO_ADDRESS || owner === ENV.CONTRACT.MOONKNIGHT;
}

export function getEquipmentImageURL(itemId: number, size: string) {
  return itemId !== 0 ? `${ENV.URL.BASE_IMAGE_URL}/images/icon/${size}/${itemId}.png` : genesisSword;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function decodeTxInput(input: any[], abi: any) {
  abiDecoder.addABI(abi);
  return abiDecoder.decodeLogs(input);
}

export function calculateStatsDifference(obj1: ItemStatsLabel, obj2: ItemStatsLabel) {
  let difference: ItemStatsLabel = {
    STR: 0,
    AGI: 0,
    INT: 0,
    LUK: 0,
    HP: 0,
    "Physical Damage": 0,
    "Physical Defense": 0,
    "Magical Damage": 0,
    "Magical Defense": 0,
    Critical: 0,
    Evade: 0,
    Speed: 0,
    effects: [],
    "Critical Damage": 0,
  };

  Object.keys(obj1).forEach((key) => {
    if (obj1[key] > 0 && obj2[key] > 0) {
      difference[key] = obj2[key] - obj1[key];
    } else if (obj1[key] > 0) {
      difference[key] = -obj1[key];
    } else {
      difference[key] = obj2[key];
    }
  });
  return difference;
}

export const getItemSetPieces = (itemSet: ItemSet) => {
  return (
    itemSet &&
    itemSet.bonuses.map((itemSet) => {
      return itemSet.pieces;
    })
  );
};

export function isEmptyHistory(history) {
  return !!history && history.length === 1;
}

export function onBackClicked(history) {
  history.goBack();
}

export function calculateRewards(dismantleMaterials: EquipmentDismantle[]) {
  return dismantleMaterials.reduce(
    (acc, cur) => {
      return {
        ...acc,
        quantity: acc.quantity + cur.quantity,
        dismantlePoints: acc.dismantlePoints + cur.dismantlePoints,
      };
    },
    { materials: [8, 9], quantity: 0, dismantlePoints: 0 }
  );
}

export function getTotalPrimaryStats(stats: KnightStats, getNonItemStats = false): PrimaryStats {
  const baseStats = stats.baseStats;
  const allocatedStats = stats.allocatedStats;
  const itemStats = stats.itemStats;
  const itemSetBonus = stats.itemSetBonus;

  return {
    str: baseStats["str"] + allocatedStats["str"] + (!getNonItemStats ? itemStats["STR"] + itemSetBonus["STR"] : 0),
    agi: baseStats["agi"] + allocatedStats["agi"] + (!getNonItemStats ? itemStats["AGI"] + itemSetBonus["AGI"] : 0),
    int: baseStats["int"] + allocatedStats["int"] + (!getNonItemStats ? itemStats["INT"] + itemSetBonus["INT"] : 0),
    luk: baseStats["luk"] + allocatedStats["luk"] + (!getNonItemStats ? itemStats["LUK"] + itemSetBonus["LUK"] : 0),
  };
}

export function getStakingContract(selectedPool: number) {
  let contract, address;

  if (selectedPool === STAKING_POOL.DEPRECATED) {
    contract = "deprecatedStakingContract";
    address = ENV.CONTRACT.DEPRECATED_SINGLE_STAKING;
  } else if (selectedPool === STAKING_POOL.DEPRECATED_SINGLE_V1) {
    contract = "deprecatedSingleV1StakingContract";
    address = ENV.CONTRACT.DEPRECATED_SINGLE_V1_STAKING;
  } else if (selectedPool === STAKING_POOL.DEPRECATED_LIQUIDITY_V1) {
    contract = "deprecatedLiquidityV1StakingContract";
    address = ENV.CONTRACT.DEPRECATED_LIQUIDITY_V1_STAKING;
  } else if (selectedPool === STAKING_POOL.SINGLE_V2) {
    contract = "singleV2StakingContract";
    address = ENV.CONTRACT.SINGLE_V2_STAKING;
  } else if (selectedPool === STAKING_POOL.LIQUIDITY_V2) {
    contract = "liquidityV2StakingContract";
    address = ENV.CONTRACT.LIQUIDITY_V2_STAKING;
  } else if (selectedPool === STAKING_POOL.SINGLE_V3) {
    contract = "singleV3StakingContract";
    address = ENV.CONTRACT.SINGLE_V3_STAKING;
  } else if (selectedPool === STAKING_POOL.LIQUIDITY_V3) {
    contract = "liquidityV3StakingContract";
    address = ENV.CONTRACT.LIQUIDITY_V3_STAKING;
  }

  return { contract, address };
}

export async function signAndAuthenticateWallet(wallet: any, address: string) {
  const signData = await wallet.signData(
    address,
    { account: address },
    {
      Group: [{ name: "account", type: "string" }],
    }
  );
  const response = await authenticateWallet(signData.sign, signData.msgParams, address);
  return response;
}
