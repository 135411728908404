import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalModal } from "src/app/actions/globalAction";
import {
  buyLand,
  cancelLandOffer,
  claimPrivateMint,
  claimPublicMint,
  delistLand,
  listLand,
  mintLand,
  offerLand,
  takeLandOffer,
  transferLand,
} from "src/app/actions/landAction";
import AddressInputGroup from "src/app/components/Commons/AddressInputGroup";
import InputGroup from "src/app/components/Commons/InputGroup";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import MintCompleteModal from "src/app/components/Land/Mint/MintCompleteModal";
import { ACTIONS, LAND_LIMITS } from "src/app/configs/constants";
import ENV from "src/app/configs/env";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import { Land, LandListing, LandOffer } from "src/app/types/land";
import { compareNumbers, minusNumbers, multiplyNumbers } from "src/app/utils/calculators";
import { formatNumber, roundNumber, toBigAmount } from "src/app/utils/helpers";
import faraLogo from "src/assets/images/tokens/fara.png";
import { isAddress } from "web3-utils";

type LandActionModalProps = {
  type: number;
  land: Land;
  currentList: LandListing;
  offerPrice: string;
  buyer: string;
  ownerActiveOffer?: LandOffer;
  currentActiveOffer?: LandOffer;
};

export default function LandActionModal(props: LandActionModalProps) {
  const dispatch = useDispatch();
  const { land, currentActiveOffer, ownerActiveOffer, currentList, type, buyer } = props;
  const { balance, address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const [needApprove, sendApproveTx, addSpendingAmount] = useFetchingAllowance(
    ENV.CONTRACT.LAND,
    undefined,
    undefined,
    getSpendingAmountByType(type)
  );
  const [whitelistEligibleLands, setWhitelistEligibleLands] = useState(0);
  const [listingPrice, setListingPrice] = useState(
    currentList && currentList.price ? currentList.price.toString() : ""
  );
  const [offerPrice, setOfferPrice] = useState("");
  const [mintAmount, setMintAmount] = useState("1");
  const [amountError, setAmountError] = useState("");
  const [inputError, setInputError] = useState("");
  const [receiveAddr, setReceiveAddr] = useState("");
  const debounceAddSpendingAmount = useCallback(debounce(addSpendingAmount, 500), []);
  const [receiverInput, setReceiverInput] = useState("");

  useEffect(() => {
    if (!ownerActiveOffer) return;
    setOfferPrice(ownerActiveOffer.price);
  }, [ownerActiveOffer]);

  useEffect(() => {
    if (!address || type !== ACTIONS.PRIVATE_LAND_MINT) return;
    checkEligibleWhiteListLands(address);
  }, [type, address]); // eslint-disable-line

  async function checkEligibleWhiteListLands(address: string) {
    const eligibleLands = await web3Service.fetchLandWhitelistAccounts(address);
    setWhitelistEligibleLands(eligibleLands);
  }

  function getSpendingAmountByType(type: number) {
    switch (type) {
      case ACTIONS.BUY_LAND:
        return currentList.price ?? 0;
      case ACTIONS.OFFER_LAND:
        return 0;
    }
  }

  function handleSetReceiverAddress(value: string) {
    setReceiveAddr(value);
  }

  function handleMintAmountChange(e: any, amount?: string) {
    setAmountError("");
    if (amount) {
      setMintAmount(amount);
      return;
    }
    setMintAmount(e.target.value);
  }

  function renderBalanceBlock(error?: string) {
    return (
      <div className="mt-2">
        <div>
          <span>Current balance:</span>
          <span> {roundNumber(balance.BNB, 3, true)} BNB</span>
          <span>, {roundNumber(balance.FARA, 2, true)} FARA</span>
        </div>
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }

  function renderMintData(type: number) {
    let content, onSubmit, submitText;
    const totalCost = multiplyNumbers(LAND_LIMITS.MINT_PRICE, mintAmount ?? 0);

    content = (
      <div className="knight__checkoutWrapper">
        <InputGroup
          className="text-field--large"
          value={mintAmount}
          handleChange={handleMintAmountChange}
          symbol="FARA"
          balance={balance.FARA}
          error={amountError}
          hideBalance={true}
          label={"Mint Amount"}
          maxAmount={LAND_LIMITS.MINT}
        />
        <div className="mt-1 fs-2">{renderBalanceBlock()}</div>
        <div className="mt-3 small-text small-text--warning">
          You are minting {mintAmount ? formatNumber(mintAmount) : 0} lands with total of {formatNumber(totalCost, 4)}{" "}
          FARA.
        </div>
      </div>
    );
    if (needApprove) {
      onSubmit = sendApproveTx;
      submitText = "Approve";
    } else {
      onSubmit = type === ACTIONS.MINT_LAND ? sendMintTx : sendPublicMintTx;
      submitText = "Mint";
    }

    return { content, onSubmit, submitText };
  }

  function renderPrivateMintData() {
    let content, onSubmit, submitText;
    const totalCost = multiplyNumbers(LAND_LIMITS.MINT_PRICE, mintAmount ?? 0);

    content = (
      <div className="knight__checkoutWrapper">
        <InputGroup
          className="text-field--large"
          value={mintAmount}
          handleChange={handleMintAmountChange}
          symbol="FARA"
          balance={balance.FARA}
          error={amountError}
          hideBalance={true}
          label={"Mint Amount"}
          maxAmount={whitelistEligibleLands}
        />
        <div className="mt-1 fs-2">{renderBalanceBlock()}</div>
        <div className="mt-1 fs-2">Lands available to claim: {whitelistEligibleLands}</div>
        <div className="mt-3 small-text small-text--warning">
          You are minting {mintAmount ? formatNumber(mintAmount) : 0} lands with total of {formatNumber(totalCost, 4)}{" "}
          FARA.
        </div>
      </div>
    );
    if (needApprove) {
      onSubmit = sendApproveTx;
      submitText = "Approve";
    } else {
      onSubmit = sendPrivateMintTx;
      submitText = "Mint";
    }

    return { content, onSubmit, submitText };
  }

  function renderBuyContent() {
    const isNotEnough = compareNumbers(toBigAmount(balance.FARA), land.price ?? 0);

    return (
      <div className="knight__checkoutWrapper">
        <div>
          Are you sure to buy this Land with <b>{currentList.price} FARA</b>?
        </div>
        <div className="fs-2">{renderBalanceBlock(isNotEnough === -1 ? "Not enough $FARA balance" : "")}</div>
      </div>
    );
  }

  function sendBuyTx() {
    dispatch(buyLand(land.id));
  }

  function handleListingAmountChange(e: any, amount?: string) {
    setInputError("");
    if (amount) {
      setListingPrice(amount);
      return;
    }
    setListingPrice(e.target.value);
  }

  function handleOfferPriceChange(e: any, amount?: string) {
    setInputError("");
    if (amount) {
      setOfferPrice(amount);
      return;
    }
    setOfferPrice(e.target.value);
    debounceAddSpendingAmount(e.target.value ?? 0);
  }

  function sendListTx() {
    const compareListingPrice = compareNumbers(listingPrice, land.price || 0);

    if (listingPrice === "") {
      setInputError("Invalid listing price.");
    } else if (compareListingPrice === 0) {
      setInputError(`New listing price must not be the same as your current listing price.`);
    } else {
      dispatch(listLand(land.id, toBigAmount(listingPrice)));
    }
  }

  function renderListContent() {
    return (
      <InputGroup
        className="text-field--large"
        value={listingPrice}
        handleChange={handleListingAmountChange}
        symbol="FARA"
        balance={balance.FARA}
        error={inputError}
        tokenImage={faraLogo}
        label={"FARA Amount"}
        hideMaxBtn={true}
      />
    );
  }
  function renderTakeOfferContent() {
    return <div>Are you sure to sell this Land with {props.offerPrice} FARA?</div>;
  }

  function renderCancelOfferContent() {
    return <div>Are you sure to cancel this offer?</div>;
  }

  function renderDelistContent() {
    return <div>Are you sure to delist your Land from the marketplace?</div>;
  }

  function renderMakeOfferContent() {
    let content, onSubmit, submitText, childContent;
    const currentListedPrice = land ? land.price : 0;
    const isBought = !!(currentListedPrice && +offerPrice >= currentListedPrice);
    const isOfferMore = !!(currentActiveOffer && currentActiveOffer.price && +offerPrice >= +currentActiveOffer.price);
    const isNeedCancelOffer = !!(
      currentActiveOffer &&
      currentActiveOffer.price &&
      +offerPrice < +currentActiveOffer.price
    );

    if (isBought) {
      childContent = (
        <div className="slide-up mt-2 small-text small-text--warning">
          Your offer price is higher than listing price of {currentListedPrice} FARA. The transaction will buy this land
          directly instead of creating offer.
        </div>
      );
      onSubmit = sendBuyTx;
      submitText = "Buy";
    } else if (isOfferMore && currentActiveOffer) {
      childContent = (
        <div className="slide-up mt-2 small-text small-text--warning">
          You already placed an offer for this land with {currentActiveOffer.price} FARA. This offer will need{" "}
          {minusNumbers(offerPrice, currentActiveOffer.price)} FARA more.
        </div>
      );
      onSubmit = sendMoreOfferTx;
      submitText = "Offer More";
    } else if (isNeedCancelOffer && currentActiveOffer) {
      childContent = (
        <div className="slide-up mt-2 small-text small-text--warning">
          You had an offer at higher price of {currentActiveOffer.price} FARA. By placing this new offer,{" "}
          {minusNumbers(currentActiveOffer.price, offerPrice)} FARA will be returned to your address.
        </div>
      );
      onSubmit = sendMoreOfferTx;
      submitText = "Update Offer";
    } else {
      onSubmit = sendOfferTx;
      submitText = "Offer";
    }

    content = (
      <div>
        <InputGroup
          className="text-field--large"
          value={offerPrice.toString()}
          handleChange={handleOfferPriceChange}
          symbol="FARA"
          balance={balance.FARA}
          error={inputError}
          tokenImage={faraLogo}
          label={"FARA Amount"}
        />
        {childContent}
      </div>
    );

    return { content, onSubmit, submitText };
  }

  function renderTransferContent() {
    return (
      <AddressInputGroup
        value={receiverInput}
        handleChange={(event: any) => setReceiverInput(event.target.value)}
        setAddress={handleSetReceiverAddress}
        error={inputError}
        label={"Receive Address"}
      />
    );
  }

  function sendDelistTx() {
    dispatch(delistLand(land.id));
  }

  function sendCancelOfferTx() {
    dispatch(cancelLandOffer(land.id));
  }

  function sendTakeOfferTx() {
    dispatch(takeLandOffer(land.id, buyer, toBigAmount(props.offerPrice)));
  }

  function sendMoreOfferTx() {
    if (!currentActiveOffer) return;

    const compareOffer = compareNumbers(offerPrice, currentActiveOffer.price);
    const isOfferMore = currentActiveOffer.price && (compareOffer === 1 || compareOffer === 0);
    const sendingValue = isOfferMore ? +offerPrice - +currentActiveOffer.price : offerPrice;
    const compareBNB = compareNumbers(balance.FARA, sendingValue);

    if (compareBNB === -1) {
      setInputError(`Insufficient FARA Balance.`);
    } else {
      const price = toBigAmount(offerPrice);
      dispatch(offerLand(land.id, price));
    }
  }

  function sendOfferTx() {
    const compareFARA = compareNumbers(balance.FARA, offerPrice);

    if (offerPrice === "") {
      setInputError(`Invalid amount.`);
    } else if (compareFARA === -1) {
      setInputError(`Insufficient FARA Balance.`);
    } else {
      const value = toBigAmount(offerPrice);
      dispatch(offerLand(land.id, value));
    }
  }

  function sendMintTx() {
    const totalCost = multiplyNumbers(LAND_LIMITS.MINT_PRICE, mintAmount ?? 0);
    const isNotEnough = compareNumbers(balance.FARA, totalCost ?? 0);

    if (mintAmount === "" || +mintAmount <= 0) {
      setAmountError("Invalid minting amount.");
      return;
    }
    if (isNotEnough === -1) {
      setAmountError("Insufficient FARA balance.");
      return;
    }
    if (+mintAmount > LAND_LIMITS.MINT) {
      setAmountError("Only 3 lands can be minted at a time");
      return;
    }
    dispatch(mintLand(+mintAmount, () => displayMintCompleteModal(+mintAmount)));
  }

  function sendPrivateMintTx() {
    const totalCost = multiplyNumbers(LAND_LIMITS.MINT_PRICE, mintAmount ?? 0);
    const isNotEnough = compareNumbers(balance.FARA, totalCost ?? 0);

    if (mintAmount === "" || +mintAmount <= 0) {
      setAmountError("Invalid minting amount.");
      return;
    }
    if (isNotEnough === -1) {
      setAmountError("Insufficient FARA balance.");
      return;
    }
    if (+mintAmount > whitelistEligibleLands) {
      setAmountError(`Only ${whitelistEligibleLands} lands are available to mint`);
      return;
    }
    dispatch(claimPrivateMint(+mintAmount, () => displayMintCompleteModal(+mintAmount)));
  }

  function sendPublicMintTx() {
    const totalCost = multiplyNumbers(LAND_LIMITS.MINT_PRICE, mintAmount ?? 0);
    const isNotEnough = compareNumbers(balance.FARA, totalCost ?? 0);

    if (mintAmount === "" || +mintAmount <= 0) {
      setAmountError("Invalid minting amount.");
      return;
    }
    if (isNotEnough === -1) {
      setAmountError("Insufficient FARA balance.");
      return;
    }
    if (+mintAmount > LAND_LIMITS.MINT) {
      setAmountError("Only 1 land can be minted at a time");
      return;
    }
    dispatch(claimPublicMint(+mintAmount, () => displayMintCompleteModal(+mintAmount)));
  }

  function displayMintCompleteModal(mintAmount: number) {
    dispatch(setGlobalModal("txTracking"));
    modalService.show(MintCompleteModal, { mintAmount });
  }

  function sendTransferTx() {
    if (!isAddress(receiveAddr)) {
      setInputError("Invalid BSC address.");
    } else {
      dispatch(transferLand(land.id, land.owner, receiveAddr));
    }
  }

  function renderPopupContent() {
    let content, onSubmit, submitText;

    if (type === ACTIONS.MINT_LAND) {
      const landMintData = renderMintData(type);
      content = landMintData.content;
      onSubmit = landMintData.onSubmit;
      submitText = landMintData.submitText;
    } else if (type === ACTIONS.BUY_LAND) {
      content = renderBuyContent();
      onSubmit = sendBuyTx;
      submitText = "Buy";
    } else if (type === ACTIONS.LIST_LAND) {
      content = renderListContent();
      onSubmit = sendListTx;
      submitText = "List";
    } else if (type === ACTIONS.DELIST_LAND) {
      content = renderDelistContent();
      onSubmit = sendDelistTx;
      submitText = "Delist";
    } else if (type === ACTIONS.TAKE_LAND_OFFER) {
      content = renderTakeOfferContent();
      onSubmit = sendTakeOfferTx;
      submitText = "Take Offer";
    } else if (type === ACTIONS.CANCEL_LAND_OFFER) {
      content = renderCancelOfferContent();
      onSubmit = sendCancelOfferTx;
      submitText = "Cancel Offer";
    } else if (type === ACTIONS.OFFER_LAND) {
      const makeOfferData = renderMakeOfferContent();
      content = makeOfferData.content;
      onSubmit = makeOfferData.onSubmit;
      submitText = makeOfferData.submitText;
    } else if (type === ACTIONS.TRANSFER_LAND) {
      content = renderTransferContent();
      onSubmit = sendTransferTx;
      submitText = "Transfer";
    } else if (type === ACTIONS.PRIVATE_LAND_MINT) {
      const landMintData = renderPrivateMintData();
      content = landMintData.content;
      onSubmit = landMintData.onSubmit;
      submitText = landMintData.submitText;
    } else if (type === ACTIONS.PUBLIC_LAND_MINT) {
      const landMintData = renderMintData(type);
      content = landMintData.content;
      onSubmit = landMintData.onSubmit;
      submitText = landMintData.submitText;
    }

    if (needApprove && (type === ACTIONS.BUY_LAND || type === ACTIONS.OFFER_LAND)) {
      onSubmit = sendApproveTx;
      submitText = "Approve";
    }

    return { content, onSubmit, submitText };
  }

  const { content, onSubmit, submitText } = renderPopupContent();

  return (
    <BasicModalContent
      onSubmit={onSubmit}
      submitText={submitText}
      content={<div className="text-center">{content}</div>}
    />
  );
}
