const env = {
  NETWORK_ID: 56,
  CHAIN_NAME: "Binance Smart Chain",
  CHAIN_CURRENCY: {
    name: "Binance Coin",
    symbol: "BNB",
    decimals: 18
  },
  URL: {
    EXPLORER: 'https://bscscan.com',
    MARKET_GRAPHQL: "https://queries-graphnode-x.faraland.io/subgraphs/name/edwardevans094/farastore-v1",
    LAND_GRAPHQL: "https://queries-graphnode-x.faraland.io/subgraphs/name/edwardevans094/land-v1",
    GUILD_GRAPHQL: "https://queries-graphnode-x.faraland.io/subgraphs/name/edwardevans094/guild-v1",
    GRAPHQL_STAKING: "https://queries-graphnode-x.faraland.io/subgraphs/name/edwardevans094/staking-v1",
    GACHA_EQUIPMENT_GRAPHQL: "https://queries-graphnode-x.faraland.io/subgraphs/name/edwardevans094/golden-axe-v1",
    DEPOSIT_EQUIPMENT_GRAPHQL: "https://queries-graphnode-x.faraland.io/subgraphs/name/edwardevans094/deposit-v1",
    // FARALAND: 'https://service.faraland.io',
    FARALAND: 'https://faraland.moonknightlabs.com',
    FARALAND_GITHUB_ASSET: 'https://raw.githubusercontent.com/faraland/faraland-assets/master',
    COINGECKO_API: 'https://api.coingecko.com/',
    BASE_IMAGE_URL: 'https://service.faraland.io',
    EVENT_IMAGE_URL: 'https://faraland.moonknightlabs.com/images/contest',
  },
  NODE: {
    // URL: "https://bsc-mainnet.nodereal.io/v1/249145d9b7e44a7fa76f570871f35957"
    URL: "https://bsc-dataseed.binance.org/"
    // URL: "https://bsc-dataseed1.defibit.io/"
    // URL: "https://bsc-dataseed1.ninicoin.io/"
    // URL: "https://speedy-nodes-nyc.moralis.io/dcd85b47f385666e0a09d389/bsc/mainnet"
  },
  CONTRACT: {
    FARA_TOKEN: '0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1',
    WBNB_TOKEN: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    LP_TOKEN: '0xffd50f49ad4f9335abc1f1b9e5a6ed90815f5fda',
    MOONKNIGHT: '0xa7a9a8156c24c4b0ca910c3ba842d1f1ac7200ef',
    EQUIPMENT: '0x39383f4172095090d0127d8bac79615e5dc2ba0e',
    EQUIPMENT_MARKET: '0x64e65079bbce21C974907376ed73Ada1e0C46519',
    KNIGHT_ATTRIBUTE: '0x2dE1F87aD1eEB00e43DaC03A71db1c2550efd5e1',
    MATERIAL: "0x57cc56cec2f2612f43cb0eaa010677766c5aeb4d",
    DEMI_KNIGHT: "0x11d20864cFe0E8fed15C10658c298572792daD65",
    MATERIAL_MARKET: "0xeC5f3Cf5C6fd8C8DE9814cB54514b065e093039C",
    REWARDS: "0x783b1316aff977236A3C75eAf66b22B29a42Ab97",
    EXP_COLLECTOR: "0xbbe16bfa40fefe581f4410ec2520a16dbb544926",
    SOUL_STONE_FOR_EXP: "0xc7f1e4a4b82af7899513c02861c455a5ed70efd8",
    GUILD: "0xf008964409bec32fAD03D4BBc739fC2DAa35b8ae",
    KNIGHT_UPGRADE: "0x11E86Ac624eA8474fffb7B011041F823c82E8229",
    DEPRECATED_SINGLE_STAKING: '0x3057a6ef2acf803371315380d21d0193471e3ef8',
    DEPRECATED_SINGLE_V1_STAKING: '0x4bF665ff93426B7Dc640e63ccd1eA7EdF12C91C5',
    DEPRECATED_LIQUIDITY_V1_STAKING: '0x5B4F9f37e724ceBD6a856336675354E843C3F29D',
    SINGLE_V2_STAKING: '0x4DBEa86445e1aBC582291Ab3B8cd4e7Db28393cA',
    LIQUIDITY_V2_STAKING: '0x21e49693B1696543a90D985674f216eE24771Dcc',
    SINGLE_V3_STAKING: "0x107E5696fAc55207DFC05C071404C31CEc57d47A",
    LIQUIDITY_V3_STAKING: "0x267Cc464AC42185910b4bB6008E10a76FEa647B1",
    EXP_JAR: "0x00524fEDeBe989072803CE5f39dF1D60871F6ACa",
    HERO_BURN: "0x3F51c46A032f509Eb05DAE4D64d00100eA2137ff",
    EQUIPMENT_CRAFTING: "0x9C2964E3E6F578EC89cd13347a9602Ac410B285F",
    GACHA: "0xB6Ab38F62814104255D8FC624189A0DF62c64882",
    EQUIPMENT_DISMANTLE: "0xfbf22F3e7CAfa78C3D6ab6a18b1985E9d11DA46c",
    LAND: "0x1c1A9CcB8229B6d7d08BAa12c050bb366d9A8a20",
    EQUIPMENT_DEPOSIT: "0xc29f10A06b5B3325b4a927893318115b2B45998f",
    // EQUIPMENT_DEPOSIT: "0xf46ac7be47c3f20afb06a5a9c50fc2c290311d42",
  }
};

export default env;
