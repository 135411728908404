import React from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import useItemAction, { userItemActionProps } from "src/app/hooks/useItemAction";
import { getEquipmentModalTitle } from "src/app/utils/mappingHelpers";

export default function EquipmentActionModal(props: userItemActionProps) {
  const [onSubmit, submitText, content] = useItemAction(props);
  const title = getEquipmentModalTitle(props.type);

  return (
    <BasicModalContent
      customTitle={title}
      onSubmit={onSubmit}
      submitText={submitText}
      content={<div className="text-center">{content}</div>}
    />
  );
}
