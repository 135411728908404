import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRune } from "src/app/actions/runeAction";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import { INTERVAL } from "src/app/configs/constants";
import { fetchRuneInventory, fetchUnclaimedRune } from "src/app/services/api/faralandService";
import { UserMaterial } from "src/app/types/materials";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

type Props = {
  onOpenInfo: () => void;
};

export const RuneLevelUpSection = ({ onOpenInfo }: Props) => {
  const dispatch = useDispatch();
  const { selectedRune, runeRefresh } = useSelector((state: any) => state.rune);
  const [claimedRunes, setClaimedRunes] = useState<UserMaterial[]>([]);
  const [unclaimedRunes, setUnclaimedRunes] = useState<UserMaterial[]>([]);
  const { address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const balanceInterval = useRef<any>();

  useEffect(() => {
    if (!address || !web3Service) {
      setClaimedRunes([]);
      setUnclaimedRunes([]);
      return;
    }
    _fetchRuneInventory(address);
    _fetchUnclaimedRune(address);
    balanceInterval.current = setInterval(() => {
      _fetchRuneInventory(address);
      _fetchUnclaimedRune(address);
    }, INTERVAL.BALANCE);

    return () => {
      clearInterval(balanceInterval.current);
    };
  }, [address, web3Service, runeRefresh]); // eslint-disable-line

  async function _fetchRuneInventory(address: string) {
    const res = await fetchRuneInventory(address);
    if (res) setClaimedRunes(res);
  }

  async function _fetchUnclaimedRune(address: string) {
    const res = await fetchUnclaimedRune(address);
    if (res) setUnclaimedRunes(res);
  }

  return (
    <div className="item-crafting__rune-level-up">
      <div className="flex-center-start">
        <h2>Choose Rune to Level Up</h2>
        <div onClick={onOpenInfo}>
          <HelpOutlineIcon fontSize="medium" className="ml-2" />
        </div>
      </div>
      <div className="mt-5">
        <div className="item-crafting__rune-type">Claimed Runes</div>
        <div className="inventory item-crafting__inventory nice-scroll nice-scroll--small">
          {claimedRunes
            .filter((rune: UserMaterial) => rune.material.level && rune.material.level < 10)
            .map((rune: UserMaterial, index: number) => (
              <MaterialIcon
                key={index}
                userMaterial={rune}
                className="item-crafting__inventory--item"
                material={rune.material}
                closeOnSubmit={true}
                submitText="Detail"
                hideAmount
                showBalance
                amount={selectedRune?.material?.id === rune.material.id && !selectedRune?.inUnclaimed ? 1 : 0}
                onSelect={() => dispatch(selectRune(rune))}
              />
            ))}
        </div>
      </div>
      <div className="mt-5">
        <div className="item-crafting__rune-type">Unclaimed Runes</div>
        <div className="inventory item-crafting__inventory nice-scroll nice-scroll--small">
          {unclaimedRunes
            .filter((rune: UserMaterial) => rune.material.level && rune.material.level < 10)
            .map((rune: UserMaterial, index: number) => (
              <MaterialIcon
                key={index}
                userMaterial={rune}
                className="item-crafting__inventory--item"
                material={rune.material}
                closeOnSubmit={true}
                submitText="Detail"
                hideAmount
                showBalance
                amount={selectedRune?.material?.id === rune.material.id && selectedRune?.inUnclaimed ? 1 : 0}
                onSelect={() => dispatch(selectRune(rune))}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
