import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { UserItem } from "src/app/types/equipment";
import AddressInputGroup from "src/app/components/Commons/AddressInputGroup";
import { roundNumber } from "src/app/utils/helpers";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import SearchIcon from "@material-ui/icons/Search";
import { isAddress } from "web3-utils";
import {
  batchTransferEquipment,
  depositEquipment,
  withdrawEquipment,
} from "src/app/actions/equipmentAction";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import _ from "lodash";
import { UserMaterial } from "src/app/types/materials";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import {
  batchTransferMaterials,
  depositMaterials,
  withdrawMaterials,
} from "src/app/actions/materialAction";
import useApprovalForAll from "src/app/hooks/useApprovalForAll";
import ENV from "src/app/configs/env";
import { INVENTORY_TYPE, NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";
import { fetchWithdrawEquipmentSignature } from "src/app/services/api/faralandService";
import InputGroup from "../InputGroup";

type BatchTransferModalProps = {
  userItems: UserItem[];
  userMaterials: UserMaterial[];
  isMaterialTransfer: boolean;
  inventoryType?: number;
};

export default function BatchTransferModal(props: BatchTransferModalProps) {
  const dispatch = useDispatch();
  const { userItems, userMaterials, isMaterialTransfer, inventoryType } = props;
  const { balance, address } = useSelector((state: any) => state.account);
  const [receiveAddr, setReceiveAddr] = useState("");
  const [receiverInput, setReceiverInput] = useState("");
  const [balanceError, setBalanceError] = useState("");
  const [invalidAddressError, setInvalidAddressError] = useState("");
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [searchName, setSearchName] = useState("");
  const [selectedAmount, setSelectedAmount] = useState<string[]>([]);
  const [filteredItems, setFilteredItems] = useState<UserItem[]>([
    ...userItems,
  ]);
  const [filteredMaterials, setFilteredMaterials] = useState<UserMaterial[]>([
    ...userMaterials,
  ]);
  const [needApprovalForAll, sendApproveForAllTx] = useApprovalForAll(
    isMaterialTransfer ? ENV.CONTRACT.MATERIAL : ENV.CONTRACT.EQUIPMENT,
    ENV.CONTRACT.EQUIPMENT_DEPOSIT
  );
  const isInventoryTransfer = inventoryType && inventoryType > 0;
  const selectedList: any[] = isMaterialTransfer ? userMaterials : userItems;

  useEffect(() => {
    if (isMaterialTransfer) {
      if (!userMaterials) {
        setFilteredMaterials([]);
      } else {
        setFilteredMaterials(
          userMaterials.filter((value: UserMaterial) => {
            return searchName === ""
              ? true
              : value.material.displayName
                  .toLowerCase()
                  .includes(searchName.toLowerCase());
          })
        );
      }
    }
    if (!userItems) {
      setFilteredItems([]);
    } else {
      setFilteredItems(
        userItems.filter((value: UserItem) => {
          return searchName === ""
            ? true
            : value.item.displayName
                .toLowerCase()
                .includes(searchName.toLowerCase());
        })
      );
    }
  }, [searchName, isMaterialTransfer]); // eslint-disable-line

  function handleAddressChange(e: any) {
    setInvalidAddressError("");
    setReceiverInput(e.target.value);
  }

  function handleSetReceiverAddress(value: string) {
    setReceiveAddr(value);
  }
  function handleSearchNameChange(e: any) {
    setBalanceError("");
    setSearchName(e.target.value);
  }

  function resetSelectedItems() {
    setSelectedItemIds([]);
    setSelectedAmount([]);
  }

  function selectItem(itemId: number) {
    setBalanceError("");
    if (selectedItemIds.includes(itemId)) {
      deselectItem(itemId);
      return;
    }

    setSelectedItemIds([...selectedItemIds, itemId]);
    setSelectedAmount([...selectedAmount, ""]);
  }

  function deselectItem(itemId: number) {
    const index = selectedItemIds.findIndex((id) => id === itemId);

    if (index !== -1) {
      setSelectedItemIds([
        ...selectedItemIds.slice(0, index),
        ...selectedItemIds.slice(index + 1),
      ]);
      setSelectedAmount([
        ...selectedAmount.slice(0, index),
        ...selectedAmount.slice(index + 1),
      ]);
    }
  }

  function selectAll() {
    const items = isMaterialTransfer
      ? userMaterials.map((material: UserMaterial) => {
          return _.uniq(
            Array.from(Array(material.available).keys()).map(() => {
              return material.id;
            })
          );
        })
      : userItems.map((item: UserItem) => {
          return _.uniq(
            Array.from(Array(item.available).keys()).map(() => {
              return item.id;
            })
          );
        });

    const quantity = isMaterialTransfer
      ? userMaterials.map((material: UserMaterial) => {
          return _.uniq(
            Array.from(Array(material.available).keys()).map(() => {
              return material.available;
            })
          );
        })
      : userItems.map((item: UserItem) => {
          return _.uniq(
            Array.from(Array(item.available).keys()).map(() => {
              return item.available;
            })
          );
        });
    setSelectedItemIds(items.flat());
    setSelectedAmount(quantity.flat().map(String));
  }

  function handleConfirm() {
    const itemAvailability = selectedItemIds.map(
      (id) => selectedList.filter((item) => item.id === id)[0].available
    );
    const quantityError = selectedAmount
      .map((value, index) => value > itemAvailability[index])
      .some((value) => value === true);
    const indices = selectedAmount
      .map((e, i) => (["", "0"].includes(e) || +e <= 0 ? i : ""))
      .filter(String);
    const filteredSelectedItemIds = _.uniq(selectedItemIds).filter(
      (_, index) => !indices.includes(index)
    );
    const filteredSelectedAmount = selectedAmount
      .map(Number)
      .filter((_, index) => !indices.includes(index));
    if (!isAddress(receiveAddr)) {
      setInvalidAddressError("Invalid BSC address.");
    } else if (receiveAddr.toLowerCase() === address.toLowerCase()) {
      setInvalidAddressError(
        "The receiving address cannot be the same as your current address."
      );
    } else {
      if (selectedItemIds.length === 0) {
        setBalanceError("Select at least 1 item to transfer");
        return;
      }
      if (
        quantityError ||
        filteredSelectedItemIds.length === 0 ||
        filteredSelectedAmount.length === 0
      ) {
        setBalanceError("Invalid quantity entered");
        return;
      }

      dispatch(
        isMaterialTransfer
          ? batchTransferMaterials(
              address,
              receiveAddr,
              filteredSelectedItemIds,
              filteredSelectedAmount,
              () => modalService.close()
            )
          : batchTransferEquipment(
              address,
              receiveAddr,
              filteredSelectedItemIds,
              filteredSelectedAmount,
              () => modalService.close()
            )
      );
    }
  }

  async function handleInventoryTransfer() {
    const itemAvailability = selectedItemIds.map(
      (id) => selectedList.filter((item) => item.id === id)[0].available
    );
    const quantityError = selectedAmount
      .map((value, index) => value > itemAvailability[index])
      .some((value) => value === true);
    const indices = selectedAmount
      .map((e, i) => (["", "0"].includes(e) || +e <= 0 ? i : ""))
      .filter(String);
    const filteredSelectedItemIds = _.uniq(selectedItemIds).filter(
      (_, index) => !indices.includes(index)
    );
    const filteredSelectedAmount = selectedAmount
      .map(Number)
      .filter((_, index) => !indices.includes(index));

    if (needApprovalForAll) {
      return sendApproveForAllTx();
    }
    if (selectedItemIds.length === 0) {
      setBalanceError("Select at least 1 item to transfer");
      return;
    }

    if (
      quantityError ||
      filteredSelectedItemIds.length === 0 ||
      filteredSelectedAmount.length === 0
    ) {
      setBalanceError("Invalid quantity entered");
      return;
    }

    if (inventoryType === INVENTORY_TYPE.TRADITIONAL) {
      dispatch(
        isMaterialTransfer
          ? depositMaterials(
              filteredSelectedItemIds,
              filteredSelectedAmount,
              () => modalService.close()
            )
          : depositEquipment(
              filteredSelectedItemIds,
              filteredSelectedAmount,
              () => modalService.close()
            )
      );
    } else if (inventoryType === INVENTORY_TYPE.NON_FEE) {
      const res = await fetchWithdrawEquipmentSignature(
        address,
        filteredSelectedAmount,
        filteredSelectedItemIds,
        isMaterialTransfer ? NON_FEE_INVENTORY_TYPE.MATERIAL : NON_FEE_INVENTORY_TYPE.ITEM
      );
      if (!res) return;
      dispatch(
        isMaterialTransfer
          ? withdrawMaterials(res, () => modalService.close())
          : withdrawEquipment(res, () => modalService.close())
      );
    }
  }

  function handleQuantityChange(e: any, index: number, amount?: string) {
    e.preventDefault();
    setBalanceError("");
    const newArray = [...selectedAmount];
    if (amount) {
      newArray.splice(index, 1, amount);
    } else {
      newArray.splice(index, 1, e.target.value);
    }
    setSelectedAmount(newArray);
  }

  return (
    <BasicModalContent
      onSubmit={isInventoryTransfer ? handleInventoryTransfer : handleConfirm}
      submitText={needApprovalForAll ? "Approve" : "Confirm"}
      content={
        <div>
          <div className="item-exchange mt-5">
            <div className="item-exchange__wrapper">
              <div className="item-exchange__header">
                <div className="mb-2">
                  <div className="item-exchange__title mb-1">
                    Items ({selectedItemIds.length})
                  </div>
                  <div className="flex-center-start">
                    <div className="btn btn--tiny" onClick={resetSelectedItems}>
                      Reset
                    </div>
                    <div className="btn btn--tiny ml-2" onClick={selectAll}>
                      Select All
                    </div>
                  </div>
                </div>
                <div className="icon-input">
                  <SearchIcon className="icon-input__icon" />
                  <input
                    className="icon-input__input"
                    value={searchName}
                    onChange={handleSearchNameChange}
                    type="text"
                    placeholder="Search"
                  />
                </div>
              </div>
              {isMaterialTransfer ? (
                <div className="item-exchange__storage nice-scroll nice-scroll--small nice-scroll--gray">
                  {filteredMaterials.length === 0 && (
                    <div>No Material found...</div>
                  )}
                  {filteredMaterials.map(
                    (userMaterial: UserMaterial, i: number) => {
                      return (
                        <MaterialIcon
                          key={i}
                          material={userMaterial.material}
                          onSelect={() => selectItem(userMaterial.id)}
                        />
                      );
                    }
                  )}
                </div>
              ) : (
                <div className="item-exchange__storage nice-scroll nice-scroll--small nice-scroll--gray">
                  {filteredItems.length === 0 && <div>No Item found...</div>}
                  {filteredItems.map((userItem: UserItem, i: number) => {
                    return (
                      <ItemIcon
                        key={i}
                        item={userItem.item}
                        layer={2}
                        onClick={selectItem}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            {selectedItemIds.map((id: number, index: number) => {
              const selected = selectedList.filter((item) => item.id === id)[0];
              return (
                <InputGroup
                  key={index}
                  className="text-field--large mt-4"
                  value={selectedAmount[index] || ""}
                  handleChange={(e, amount) =>
                    handleQuantityChange(e, index, amount)
                  }
                  label={`${
                    selected.item
                      ? selected.item?.displayName
                      : selected.material.displayName
                  }`}
                  maxAmount={selected.available}
                  balance={selected.available.toString()}
                  error={
                    +selectedAmount[index] > selected.available
                      ? "Insufficient quantity"
                      : ""
                  }
                />
              );
            })}
            {!isInventoryTransfer && (
              <>
                <div className="item-exchange__title mr-2 mb-3">TO</div>
                <AddressInputGroup
                  value={receiverInput}
                  handleChange={handleAddressChange}
                  setAddress={handleSetReceiverAddress}
                  error={invalidAddressError}
                  label={"Receiving Address"}
                />
                <div className="mt-1 fs-2">
                  <div>
                    <span>Current balance:</span>
                    <span> {roundNumber(balance.BNB, 4, true)} BNB</span>
                  </div>
                </div>
              </>
            )}
            {balanceError && <div className="error-text">{balanceError}</div>}
          </div>
        </div>
      }
    />
  );
}
