import React, { useState } from "react";
import faraLogo from "src/assets/images/tokens/fara.png";
import { formatNumber } from "src/app/utils/helpers";
import { multiplyNumbers } from "src/app/utils/calculators";
import { useDispatch, useSelector } from "react-redux";
import landPlaceholder from "src/assets/images/lands/land-placeholder.png";
import ENV from "src/app/configs/env";
import { Link, useRouteMatch } from "react-router-dom";
import activeGem from "src/assets/images/decors/gems/tier-gem-active.svg";
import { Box, Grid } from "@material-ui/core";
import useWindowDimensions from "src/app/hooks/useWindowDimensions";
import { Land } from "src/app/types/land";
import { deselectLand, selectLand } from "src/app/actions/landAction";
import { ROUTE } from "src/app/configs/constants";
import { formatLandRewards } from "src/app/utils/mappingHelpers";

interface LandListContentProps {
  land: Land;
  className?: string;
  isLoading?: boolean;
  showClaim?: boolean;
}

export default function LandListContent(props: LandListContentProps) {
  const dispatch = useDispatch();
  const { tokenPrice } = useSelector((state: any) => state.global);
  const { selectedLands, selection } = useSelector((state: any) => state.land);
  const [isLoading, setLoading] = useState(true);
  // const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const walletMatch = useRouteMatch(ROUTE.WALLET);
  const windowDimensions = useWindowDimensions();

  // const openPopover = Boolean(anchorEl);
  // const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   isLg && setAnchorEl(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  function handleImageLoaded() {
    setLoading(false);
  }

  const handleSelect = () => {
    if (selectedLands.some((selected) => selected?.id === props.land.id)) {
      dispatch(deselectLand(props.land));
    } else {
      dispatch(selectLand(props.land));
    }
  };

  const renderLandListContent = () => {
    if (props.land.id === undefined) return;

    return (
      <Grid container className="lands__item-wrapper" alignItems="center">
        <Box {...(windowDimensions.width <= 1200 && { display: "flex", alignItems: "center" })}>
          <div className="lands__item-images">
            {isLoading && <img className="lands__item-character" src={landPlaceholder} alt="" />}
            <img
              style={!isLoading ? {} : { display: "none" }}
              className="lands__item-character"
              src={`${ENV.URL.FARALAND}/images/lands/png/${props.land.id}.png`}
              onLoad={handleImageLoaded}
              alt=""
            />
          </div>
          <div className={`lands__item-id ${props.land.landType.toLowerCase()}`}>#{props.land.id}</div>
          {props.land.reward && (
            <div className="lands__item-reward">
              Earn: <b>{formatLandRewards(props.land.reward)}</b> <img className="lands__item-reward--token" src={faraLogo} alt="" />
            </div>
          )}
          <div>
            <div className="lands__item-data">
              <div className="lands__item-tier">
                {Array(props.land.tier)
                  .fill(1)
                  .map((_, idx) => (
                    <img key={idx} src={activeGem} alt="" />
                  ))}
              </div>
              <div className="lands__item-type">{props.land.landType}</div>
            </div>
            <div className="lands__item-content">
              <div className="lands__item-footer">
                <div className="flex-start-center">
                  <img className="lands__item-token--fara top-2" src={faraLogo} alt="" />
                  <div className="lands__item-price">
                    <div>{props.land.price ? formatNumber(props.land.price, 2) : "-/-"} FARA</div>
                    {!!props.land.price && (
                      <div className="lands__item-value">
                        ≈ ${formatNumber(multiplyNumbers(props.land.price, tokenPrice.FARA), 2)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        {/* {props.demiStats && (
          <>
            <KnightStatsInfo baseStats={props.demiStats!.baseStats} />
            <KnightPopover
              open={openPopover}
              anchorEl={anchorEl}
              knight={props.demi}
              knightStats={props.demiStats}
              handlePopoverClose={handlePopoverClose}
            />
          </>
        )} */}
      </Grid>
    );
  };

  return (
    <>
      {selection && walletMatch ? (
        <div
          className={`market__item market__item--knight knights__item ${props.className ?? ""} ${
            selectedLands.some((selected: any) => selected?.id === props.land.id) && "market__item--selected"
          }`}
          // onMouseEnter={handlePopoverOpen}
          // onMouseLeave={handlePopoverClose}
          onClick={handleSelect}
        >
          {renderLandListContent()}
        </div>
      ) : (
        <Link
          to={`land/${props.land.id}`}
          className={`market__item market__item--knight knights__item ${props.className ?? ""}`}
          // onMouseEnter={handlePopoverOpen}
          // onMouseLeave={handlePopoverClose}
        >
          {renderLandListContent()}
        </Link>
      )}
    </>
  );
}
