import { LandReducer } from "src/app/types/reducer-state";
import { landActionTypes } from "src/app/actions/landAction";

const initialState: LandReducer = {
  selectedLands: new Array(100).fill(null),
  selection: false,
  ownedLands: [],
  refresh: false,
};

export default function landReducer(state = initialState, action: any): LandReducer {
  switch (action.type) {
    case landActionTypes.SELECT: {
      const index = state.selectedLands.findIndex((selected) => selected === null);
      const cloneSelectedLands = [...state.selectedLands];
      cloneSelectedLands.splice(index, 1, action.payload.land);

      return {
        ...state,
        selectedLands: cloneSelectedLands,
      };
    }
    case landActionTypes.DESELECT: {
      const index = state.selectedLands.findIndex((selected) => selected?.id === action.payload.land.id);
      const cloneSelectedLands = [...state.selectedLands];
      cloneSelectedLands.splice(index, 1);
      cloneSelectedLands.push(null as never);

      return {
        ...state,
        selectedLands: cloneSelectedLands,
      };
    }
    case landActionTypes.SET_OWNED_LANDS: {
      return { ...state, ownedLands: action.payload.landIds };
    }
    case landActionTypes.SET_SELECTION: {
      return { ...state, selection: action.payload.value };
    }
    case landActionTypes.RESET_SELECTION: {
      return { ...state, selection: false, selectedLands: new Array(100).fill(null) };
    }
    case landActionTypes.REFRESH_LIST: {
      return { ...state, refresh: action.payload.refresh };
    }
    default:
      return state;
  }
}
