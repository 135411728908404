import * as React from "react";
import crystal from "src/assets/images/decors/gems/gem-yellow.svg";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
import upcomingImg from "src/assets/images/instructions/upcoming-img.png";
import brokenCrystal from "src/assets/images/instructions/broken-crystal.png";
import mapType from "src/assets/images/instructions/map-type.png";
import map from "src/assets/images/instructions/map.gif";
import mapTerrain from "src/assets/images/instructions/map-terrain.png";

import Title from "../Commons/Title";

const UpcomingFeatures = () => {
  return (
    <>
      <Title value="Upcoming Features" />
      <div className="instructions-upcoming pt-1">
        <FloatingCrystal speed={5} src={crystal} style={{ right: 0, top: 500, zIndex: -1 }} />
        <img className="instructions-upcoming__image" src={upcomingImg} alt="" />
        <div className="instructions-upcoming__content">
          <h2>PVP Combat Mode</h2>
          <p>PvE turn-based strategy gameplay with exciting storylines, multiple maps, and skill systems</p>
          <p>Player vs player with a team of three heroes Seasonal battle mechanism with huge rewards</p>
          <p>
            Combat mode: Turn-based combat system is extremely tactical. Each race possesses a sui generis skillset with
            unique gameplay, applying to both PvE and PvP modes.
          </p>
        </div>
      </div>
      <div className="instructions-upcoming instructions-upcoming--map">
        <img className="instructions-upcoming__image--crystal" src={brokenCrystal} alt="" />
        <div className="instructions-upcoming__content">
          <h2>Custom Map</h2>
          <p>
            Players can create their custom map to play and share with the community. Fantastic custom maps with
            creative ideas can be officially applied to the gameplay and prestigious rewards can be given to users for
            their contribution to Faraland.
          </p>
        </div>
      </div>
      <div className="instructions-upcoming instructions-upcoming--map pt-1">
        <img className="instructions-upcoming__image--type" src={mapType} alt="" />
        <img className="instructions-upcoming__image" src={map} alt="" />
      </div>
      <img className="instructions-upcoming__terrain" src={mapTerrain} alt="" />
      
    </>
  );
};

export default UpcomingFeatures;
