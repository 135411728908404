import React, { useEffect, useState } from "react";
import { Knight, Offer } from "src/app/types/knight";
import { isKnightDead } from "src/app/utils/helpers";
import { compareNumbers } from "src/app/utils/calculators";
import { ACTIONS, FLOOR_PRICE, RACE } from "src/app/configs/constants";
import { useSelector } from "react-redux";
import MarketTable from "src/app/components/Commons/MarketTable";

type KnightOfferTableProps = {
  knight: Knight;
  offers: Offer[];
  isOwner: boolean;
  isHaveOffers: boolean;
  onOpenPopup: any;
  handleTakeOffer: any;
  isHeroHidden: boolean;
};

export default function KnightOfferTable(props: KnightOfferTableProps) {
  const { address } = useSelector((state: any) => state.account);
  const [filteredOffers, setFilteredOffers] = useState<Offer[]>(props.offers);

  const { knight, offers, isOwner, onOpenPopup, handleTakeOffer, isHeroHidden } = props;
  const isEmptyOffer = filteredOffers.length === 0;

  useEffect(() => {
    const race = knight.race === "Dragon" ? RACE.DRAGONBORN : knight.race;
    const filteredOffers = offers.filter((offer: Offer) => {
      const configPriceCompare = compareNumbers(offer.price, FLOOR_PRICE[race]);
      const showOffer = configPriceCompare === 1 || configPriceCompare === 0;
      return address === knight.owner ? showOffer : true;
    });
    setFilteredOffers(filteredOffers);
  }, [offers]); // eslint-disable-line

  function renderButtons(offer: any) {
    if (isOwner) {
      return (
        <div className="btn btn--small" onClick={() => handleTakeOffer(offer.price, offer.buyer)}>
          Accept
        </div>
      );
    }
    if (address === offer.buyer) {
      return (
        <div className="btn btn--small btn--yellow" onClick={() => onOpenPopup(ACTIONS.CANCEL_OFFER)}>
          Cancel
        </div>
      );
    }
  }
  return (
    <div className={`cm-table ${isEmptyOffer ? "cm-table--empty" : ""} mt-5`}>
      <div className="cm-table__title">Offers</div>
      <MarketTable
        headerTitles={["From", "Price", "Time", ""]}
        cellTypes={["address", "price", "time"]}
        cellKeys={["buyer", "price", "timestamp"]}
        isEmpty={isEmptyOffer}
        orderData={offers}
        emptyMessage="No Offers Yet"
        isMyOrder={false}
        myOrderData={false}
        bnbToken
        renderBtnActions={renderButtons}
      />
      {!isHeroHidden && !isOwner && !isKnightDead(knight.owner) && (
        <div className="btn mt-4" onClick={() => onOpenPopup(ACTIONS.OFFER)}>
          Make Offer
        </div>
      )}
    </div>
  );
}
