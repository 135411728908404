import { FormControlLabel, Checkbox, FormControl, InputLabel, Select, Tabs, Tab, TextField, IconButton, Tooltip } from "@material-ui/core";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { ROUTE, SORT_LAND_BY } from "src/app/configs/constants";
import { LandFilterType } from "src/app/types/land";
import {
  getAdvancedFilterCount,
  getBasicFilterCount,
  getFilterCount,
  getLandFilterFromParams,
} from "src/app/utils/filterHelper";
import { useQueryString } from "use-route-as-state";
import { INIT_LAND_FILTER } from "src/app/configs/constants";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import disabledGem from "src/assets/images/decors/gems/tier-gem-disabled.svg";
import activeGem from "src/assets/images/decors/gems/tier-gem-active.svg";

function LandFilter(props: any) {
  const [filterCount, setFilterCount] = useState(0);
  const [basicFilterCount, setBasicFilterCount] = useState(0);
  const [advancedFilterCount, setAdvancedFilterCount] = useState(0);
  const [queryParams, setQueryParams] = useQueryString();
  const [landFilters, setLandFilters] = useState<LandFilterType>(getLandFilterFromParams(queryParams));
  const [landIdInput, setLandIdInput] = useState<any>(landFilters.id);
  const [showFilter, setShowFilter] = useState(false);
  const [traitRarity, setTraitRarity] = useState<any>(landFilters.traitRarity);
  // const [traits, setTraits] = useState<any[]>([]);
  // const [traitSearch, setTraitSearchValue] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const disableFilter = landFilters.offering;

  const debounceSetParams = useCallback(debounce(setQueryParams, 500), []);

  useEffect(() => {
    setFilterCount(getFilterCount(landFilters));
    setBasicFilterCount(getBasicFilterCount(landFilters));
    setAdvancedFilterCount(getAdvancedFilterCount(landFilters));
  }, [landFilters]);

  useEffect(() => {
    const newFilters = getLandFilterFromParams(queryParams);
    setLandFilters({ ...newFilters });
  }, [queryParams]);

  function handleClearFilters() {
    const isWallet = matchPath(props.location.pathname, { path: ROUTE.WALLET });

    setLandFilters(isWallet ? { ...INIT_LAND_FILTER, owned: true, sortBy: SORT_LAND_BY.LOWEST_ID } : INIT_LAND_FILTER);
    setQueryParams(isWallet ? { owned: "true", sortBy: SORT_LAND_BY.LOWEST_ID } : {});
    setLandIdInput("");
    setTraitRarity("");
  }

  const handleTabChange = (_e: any, newValue: number) => {
    setTabValue(newValue);
  };

  function handleCheckboxChange(e: any, category: string, key: string) {
    const selectedCategory = queryParams[category];
    const arraySelected = selectedCategory ? selectedCategory.split(",") : [];
    const found = arraySelected.indexOf(key);

    if (found >= 0 && !e.target.value) {
      arraySelected.splice(found, 1);
    } else if (e.target.checked) {
      arraySelected.push(key);
    }

    return setQueryParams({
      ...queryParams,
      [category]: arraySelected.join(","),
    });
  }

  function _getDataByCheckbox(category: string, imageName?: string) {
    let data;
    let icon = "";

    if (category === "landType") {
      data = landFilters.landType;
      icon = imageName ? require(`src/assets/images/lands/${imageName}`) : "";
    } else {
      data = landFilters.sales;
    }

    return { data, icon };
  }

  const handleRarityChange = (_e: any, value: any) => {
    if (value === null) {
      setTraitRarity("");
    } else {
      setTraitRarity(value);
    }
    setQueryParams({
      ...queryParams,
      traitRarity: value ?? "",
    });
  };

  function handleSortChange(e: any) {
    setQueryParams({
      ...queryParams,
      sortBy: e.target.value,
    });
  }

  const handleInputChange = (category: string) => (e: any) => {
    const value = e.target.value;
    if (category === "id") {
      setLandIdInput(value);
    }
    debounceSetParams({
      ...queryParams,
      [category]: value,
    });
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };
  
  const handleTierChange = (value: any) => {
    if (queryParams && queryParams.tier && queryParams.tier === value.toString()) {
      setQueryParams({
        ...queryParams,
        tier: "",
      });
    } else {
      setQueryParams({
        ...queryParams,
        tier: value,
      });
    }
  };
  function renderTextField(label: string, category: string, values: any) {
    return (
      <div className={category === "id" ? "" : "mb-4"}>
        {category !== "id" && (
          <div className="dashed-title mb-4">
            <span>{label}</span>
          </div>
        )}
        <TextField
          value={values}
          className={`input ${category !== "id" && "number-input"}`}
          label={label}
          type="number"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          onChange={handleInputChange(category)}
        />
      </div>
    );
  }

  function renderButtonGroup(category: string, values: any) {
    return (
      <div className="mb-2">
        <div className="dashed-title">
          <span>{category}</span>
        </div>
        <div className="flex-center-center">
          <Tooltip title="Tier 1" placement="top">
            <IconButton onClick={() => handleTierChange(1)}>
              <img src={values >= 1 ? activeGem : disabledGem} alt="" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Tier 2" placement="top">
            <IconButton onClick={() => handleTierChange(2)}>
              <img src={values >= 2 ? activeGem : disabledGem} alt="" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Tier 3" placement="top">
            <IconButton onClick={() => handleTierChange(3)}>
              <img src={values === 3 ? activeGem : disabledGem} alt="" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  }
  function renderToggleButtons(category: string) {
    return (
      <div className="mb-2">
        <div className="dashed-title">
          <span>{category}</span>
        </div>
        <ToggleButtonGroup
          value={traitRarity}
          className="market__filter-rarity"
          exclusive
          onChange={handleRarityChange}
        >
          {["Common", "Uncommon", "Rare"].map((rarity: string, key: number) => (
            <ToggleButton value={key} key={key}>
              {rarity}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    );
  }

  function renderCheckboxes(label: string, category: string, values: any = {}, iconMargin = "", subImageName = "") {
    return (
      <div className="mb-2">
        <div className="dashed-title">
          <span>{label}</span>
        </div>
        <div className="market__filter-items">
          {Object.keys(values).map((key: string) => {
            const { icon } = _getDataByCheckbox(category, `${key.toLowerCase()}.png`);
            return (
              <FormControlLabel
                key={key}
                className={`market__filter-item`}
                control={
                  <Checkbox
                    checked={values[key]}
                    onChange={(e) => handleCheckboxChange(e, category, key)}
                    color="primary"
                  />
                }
                label={
                  <div className="flex-center-start bot-1">
                    {icon !== "" && (
                      <img className={`icon${category === "landType" && "--small"} ${iconMargin}`} src={icon} alt="" />
                    )}
                    <span className="top-2 fs-3">{key}</span>
                  </div>
                }
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={`market__filter-container ${disableFilter ? "disabled" : ""}`}>
      <div className="market__filter-button">
        {/* {props.showClaim && <LandClaimBtn />} */}
        <div className="btn btn--filter" onClick={handleShowFilter}>
          Filters <ArrowDropDownIcon />
        </div>
      </div>
      <div className={`market__filter ${showFilter && "market__filter--mobile"} fade-in `}>
        <div className="market__filter-header">
          <div className="fw-4 top-1">FILTERS {filterCount ? `(${filterCount})` : ""}</div>
          <div className="btn btn--small" onClick={handleClearFilters}>
            Clear Filter
          </div>
        </div>
        <div className="flex mb-4">
          <FormControl variant="outlined" className="input mr-4" style={{ width: 300 }}>
            <InputLabel htmlFor="outlined-age-native-simple">Sort By</InputLabel>
            <Select label="Sort By" native value={landFilters.sortBy} onChange={handleSortChange}>
              {Object.keys(SORT_LAND_BY).map((key: string) => {
                return (
                  <option value={SORT_LAND_BY[key]} key={key}>
                    {SORT_LAND_BY[key]}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {renderTextField("Land ID", "id", landIdInput)}
        </div>
        <Tabs value={tabValue} onChange={handleTabChange} className="market__filter-tabs" variant="fullWidth">
          <Tab label={`Basic${basicFilterCount ? ` (${basicFilterCount})` : ""}`} />
          <Tab label={`Advanced${advancedFilterCount ? ` (${advancedFilterCount})` : ""}`} />
        </Tabs>
        {tabValue === 0 ? (
          <>
            {renderCheckboxes("Land Type", "landType", landFilters.landType, "mr-2")}
            {renderCheckboxes("sales", "sales", landFilters.sales)}
            {renderButtonGroup("tier", landFilters.tier)}
          </>
        ) : (
          <>
            {/* {renderAutoComplete("Body Parts", "trait", heroFilters.traits)} */}
            {renderToggleButtons("Trait Rarity")}
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(LandFilter);
