import { ACTIONS, NON_FEE_INVENTORY_TYPE, RACE, RARITY_LABEL, STATS_LABEL, TRANSACTION_TYPE, ZERO_ADDRESS } from "src/app/configs/constants";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { MATERIALS } from "src/app/configs/materials/materials";
import { resolveDomainNames } from "src/app/services/api/faralandService";
import { Trait } from "src/app/types/knight";

export function mapHistoryTypeToText(type: string) {
  switch (type) {
    case TRANSACTION_TYPE.TYPE_ITEM_OFFER:
    case TRANSACTION_TYPE.OFFER:
      return "OFFER";
    case TRANSACTION_TYPE.TYPE_ITEM_LIST:
    case TRANSACTION_TYPE.LIST:
      return "LIST";
    case TRANSACTION_TYPE.TYPE_ITEM_DELIST:
    case TRANSACTION_TYPE.DELIST:
      return "DELIST";
    case TRANSACTION_TYPE.TYPE_ITEM_EXCHANGE_CANCELED:
      return "CANCEL EXCHANGE";
    case TRANSACTION_TYPE.TYPE_ITEM_OFFER_CANCELED:
      return "CANCEL OFFER";
    case TRANSACTION_TYPE.TYPE_ITEM_BOUGHT:
    case TRANSACTION_TYPE.BOUGHT:
      return "BOUGHT";
    case TRANSACTION_TYPE.TYPE_ITEM_EXCHANGE_OFFERED:
      return "OFFER EXCHANGE";
    case TRANSACTION_TYPE.TYPE_ITEM_EXCHANGED:
      return "TAKE EXCHANGE";
    case TRANSACTION_TYPE.TYPE_ITEM_OFFER_TAKEN:
    case TRANSACTION_TYPE.TAKE_OFFER:
      return "TAKE OFFER";
    case TRANSACTION_TYPE.MINT:
      return "MINT";
    default:
      break;
  }
}

export function getMarketModalTitle(type: number) {
  let title = "";

  switch (type) {
    case ACTIONS.BUY:
      title = "Buy Hero";
      break;
    case ACTIONS.OFFER:
      title = "Make Offer";
      break;
    case ACTIONS.LIST:
      title = "List your Hero on Marketplace";
      break;
    case ACTIONS.DELIST:
      title = "Delist your Hero from Marketplace";
      break;
    case ACTIONS.TAKE_OFFER:
      title = "Take Offer";
      break;
    case ACTIONS.CANCEL_OFFER:
      title = "Cancel Offer";
      break;
    case ACTIONS.TRANSFER:
      title = "Transfer your Hero";
      break;
    case ACTIONS.CHANGE_NAME:
      title = "Change Hero Name";
      break;
    case ACTIONS.RESET_STATS:
      title = "Reset Hero Stats";
      break;
    default:
      break;
  }

  return title;
}

export function getDemiModalTitle(type: number) {
  let title = "";

  switch (type) {
    case ACTIONS.BUY:
      title = "Buy Demi Hero";
      break;
    case ACTIONS.OFFER:
      title = "Make Offer";
      break;
    case ACTIONS.LIST:
      title = "List your Demi Hero on Marketplace";
      break;
    case ACTIONS.DELIST:
      title = "Delist your Demi Hero from Marketplace";
      break;
    case ACTIONS.TAKE_OFFER:
      title = "Take Offer";
      break;
    case ACTIONS.CANCEL_OFFER:
      title = "Cancel Offer";
      break;
    case ACTIONS.TRANSFER:
      title = "Transfer your Demi Hero";
      break;
    case ACTIONS.RESET_STATS:
      title = "Reset Demi Stats";
      break;
    default:
      break;
  }

  return title;
}

export function getEquipmentModalTitle(type: number) {
  let title = "";

  switch (type) {
    case ACTIONS.BUY_EQUIPMENT:
      title = "Buy Item";
      break;
    case ACTIONS.LIST_EQUIPMENT:
      title = "List your Item on Marketplace";
      break;
    case ACTIONS.DELIST_EQUIPMENT:
      title = "Delist your Item from Marketplace";
      break;
    case ACTIONS.OFFER_EQUIPMENT:
      title = "Make Offer";
      break;
    case ACTIONS.TAKE_OFFER_EQUIPMENT:
      title = "Take Offer";
      break;
    case ACTIONS.CANCEL_OFFER_EQUIPMENT:
      title = "Cancel Offer";
      break;
    case ACTIONS.LIST_EXCHANGE_EQUIPMENT:
      title = "Exchange your Items on Marketplace";
      break;
    case ACTIONS.CANCEL_EXCHANGE_EQUIPMENT:
      title = "Cancel your exchange Items";
      break;
    case ACTIONS.EXCHANGE_EQUIPMENT:
      title = "Exchange your Items";
      break;
    case ACTIONS.TAKE_EXCHANGE_OFFER:
      title = "Take Exchange Offer";
      break;
    default:
      break;
  }

  return title;
}

export function getMaterialModalTitle(type: number) {
  let title = "";

  switch (type) {
    case ACTIONS.BUY_MATERIAL:
      title = "Buy Material";
      break;
    case ACTIONS.LIST_MATERIAL:
      title = "List your Material on Marketplace";
      break;
    case ACTIONS.DELIST_MATERIAL:
      title = "Delist your Material from Marketplace";
      break;
    case ACTIONS.OFFER_MATERIAL:
      title = "Make Offer";
      break;
    case ACTIONS.TAKE_OFFER_MATERIAL:
      title = "Take Offer";
      break;
    case ACTIONS.CANCEL_OFFER_MATERIAL:
      title = "Cancel Offer";
      break;
    default:
      break;
  }

  return title;
}

export function getLandModalTitle(type: number) {
  let title = "";

  switch (type) {
    case ACTIONS.MINT_LAND:
      title = "Mint Land";
      break;
    case ACTIONS.BUY_LAND:
      title = "Buy Land";
      break;
    case ACTIONS.LIST_LAND:
      title = "List your Land on Marketplace";
      break;
    case ACTIONS.DELIST_LAND:
      title = "Delist your Land from Marketplace";
      break;
    case ACTIONS.OFFER_LAND:
      title = "Make Offer";
      break;
    case ACTIONS.TAKE_LAND_OFFER:
      title = "Take Offer";
      break;
    case ACTIONS.CANCEL_LAND_OFFER:
      title = "Cancel Offer";
      break;
    default:
      break;
  }
  return title;
}
export function getKnightStatsLabel(stat: string): string {
  let label = "";

  switch (stat) {
    case STATS_LABEL.STR:
      label = "Strength";
      break;
    case STATS_LABEL.AGI:
      label = "Agility";
      break;
    case STATS_LABEL.INT:
      label = "Intelligence";
      break;
    case STATS_LABEL.LUK:
      label = "Luck";
      break;
  }

  return label;
}

export function convertStatNameToAPIQuery(name: string) {
  switch (name) {
    case STATS_LABEL.STR:
    case STATS_LABEL.AGI:
      return name.toString().toLowerCase();
    case STATS_LABEL.INT:
      return "intel";
    default:
      return "luck";
  }
}

export function formatString(text: string, arrayToFormat: number[]) {
  for (let i = 0; i < arrayToFormat.length; i++) {
    const regexp = new RegExp("\\{" + i + "\\}", "gi");
    text = text.replace(regexp, arrayToFormat[i].toString());
  }
  return text;
}

export function mapTraitsToAPIQuery(traits: Trait[]) {
  return {
    traitHair: traits
      .filter((trait: Trait) => trait.traitType === "Hair")
      .map((trait: Trait) => {
        return trait.value;
      }),
    traitScar: traits
      .filter((trait: Trait) => trait.traitType === "Scar")
      .map((trait: Trait) => {
        return trait.value;
      }),
    traitSkintone: traits
      .filter((trait: Trait) => trait.traitType === "Skintone")
      .map((trait: Trait) => {
        return trait.value;
      }),
    traitEyes: traits
      .filter((trait: Trait) => trait.traitType === "Eyes")
      .map((trait: Trait) => {
        return trait.value;
      }),
    traitMouth: traits
      .filter((trait: Trait) => trait.traitType === "Mouth")
      .map((trait: Trait) => {
        return trait.value;
      }),
    traitTattoo: traits
      .filter((trait: Trait) => trait.traitType === "Tattoo")
      .map((trait: Trait) => {
        return trait.value;
      }),
  };
}

export function mapLandTraitsToAPIQuery(traits: Trait[]) {
  return {
    traitHair: traits
      .filter((trait: Trait) => trait.traitType === "Tree")
      .map((trait: Trait) => {
        return trait.value;
      }),
    traitScar: traits
      .filter((trait: Trait) => trait.traitType === "House")
      .map((trait: Trait) => {
        return trait.value;
      }),
    traitSkintone: traits
      .filter((trait: Trait) => trait.traitType === "Water")
      .map((trait: Trait) => {
        return trait.value;
      }),
    traitEyes: traits
      .filter((trait: Trait) => trait.traitType === "Mountain")
      .map((trait: Trait) => {
        return trait.value;
      }),
    traitMouth: traits
      .filter((trait: Trait) => trait.traitType === "Ice")
      .map((trait: Trait) => {
        return trait.value;
      }),
    traitTattoo: traits
      .filter((trait: Trait) => trait.traitType === "Symbol")
      .map((trait: Trait) => {
        return trait.value;
      }),
  };
}

export function formatSetName(name: string) {
  return name?.split("_").join(" ");
}

export function mapSlotTypeNameToApi(type: string) {
  switch (type) {
    case "Main Weapon":
      return "MAIN_WEAPON";
    case "Sub Weapon":
      return "SUB_WEAPON";
    case "Skill Book":
      return "SKILL_BOOK";
    case "Companion":
      return "MOUNT";
    case "Pants":
      return "PANT";
    default:
      return type.toUpperCase();
  }
}

export function mapStringToLink(url: string) {
  return `<a class="help__links" target="_blank" href=${url}>${url}</a>`;
}

export function mapEquipmentSetToObjectArray(set: any) {
  return Object.keys(set).map((value) => {
    return {
      id: value,
      name: set[value].name,
    };
  });
}

export function mapGender(gender: string) {
  return gender === "F" ? "Female" : "Male";
}

export function mapStatsKey(key: string) {
  return key === "Evade" ? "Evasion" : key;
}

export function mapSkillBookValue(val: string) {
  switch (val) {
    case "magicDmg":
      return "Magic Damage";
    case "magicDef":
      return "Magic Defense";
    case "physicDmg":
      return "Physical Damage";
    case "str":
    case "int":
    case "agi":
      return val.toUpperCase();
    case "luck":
      return "LUK";
    case "critical":
      return "Critical";
    case "evade":
      return "Evade";
    case "speed":
      return "Speed";
    case "bonusHeal":
      return "Bonus Heal";
  }
}

export function mapChibiRaceValue(race: string) {
  switch (race) {
    case RACE.DEMON:
    case "Dragon":
      return race.substring(0, 2).toUpperCase();
    default:
      return race.substring(0, 1).toUpperCase();
  }
}

export function formatLandRewards(reward: number) {
  if (reward > 0 && reward < 0.01) {
    return "< 0.01";
  }
  return reward;
}

export function mapLandTraitType(traitType: string) {
  switch (traitType) {
    case "BackGround":
      return "Background";
    case "MainBuilding":
      return "Main Building";
    case "DecoA":
      return "Decoration A";
    case "DecoB":
      return "Decoration B";
    case "SubBuildingA":
      return "Sub Building A";
    case "SubBuildingB":
      return "Sub Building B";
    default:
      return traitType;
  }
}

export async function getDomainNamesForTransactions(transactions: any) {
  if (!transactions) return;
  const addresses = Array.from(
    new Set<string>(
      transactions
        .reduce((transactions, { from, to, seller, buyer, id }) => [...transactions, from?.id, to?.id, seller?.id, buyer?.id, id], [])
        .filter((transaction) => transaction && transaction !== ZERO_ADDRESS)
    )
  );
  const domainNames = await resolveDomainNames(addresses);
  return domainNames;
}

export function minTwoDigits(n: number) {
  return (n < 10 ? '0' : '') + n;
}

export function convertRarityToNumber(rarity: string) {
  return RARITY_LABEL.map((label) => label.toLowerCase()).indexOf(rarity);
}

export function createWithdrawDataByType(type) {
  switch(type) {
    case NON_FEE_INVENTORY_TYPE.ITEM:
      return EQUIPMENT;
    default:
      return MATERIALS;
  }
}