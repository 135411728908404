import React from "react";
import trophies from "src/assets/images/instructions/trophies.png";
import emperorStone from "src/assets/images/instructions/emperor-stone.png";

const Guild = () => {
  return (
    <div className="instructions-guild">
      <div className="container">
        <div className="flex-start-center">
          <div className="instructions-guild__content">
            <img className="instructions-guild__content-image" src={emperorStone} alt="" />
            <div className="ml-7">
              <h2>Guild</h2>
              <p>
                Faraland's Guild system will be a multi-game system where players can join multiple games and grow their
                guild. Main activities in guild mode includes Guild Ranking, War Guild, Land Defense, Resource sharing.
              </p>
              <p className="mt-2">New functions will be updated during game development according to community feedback</p>
            </div>
          </div>
          <img className="instructions-guild__image" src={trophies} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Guild;
