import { BLACK_MARKET_BOX_NAMES } from "src/app/configs/constants";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { MATERIALS } from "src/app/configs/materials/materials";
import { BlackMarketItem } from "src/app/types/blackMarket";

export function createBlackMarketItem(data: any): BlackMarketItem {
  return {
    id: data.id,
    name: BLACK_MARKET_BOX_NAMES[data.id - 1],
    amount: data.amount,
    tokenId: data.tokenId,
    price: data.price,
    itemIds: data.itemIds,
    items: data.items && data.items.map((item) => ({ ...EQUIPMENT[item.itemNftId], amount: item.quantity })),
    materialIds: data.materialIds,
    materials:
      data.materials && data.materials.map((material) => ({ ...MATERIALS[material.id], quantity: material.quantity })),
    maxAmount: data.maxAmount,
    refilledTimestamp: data.refilledTimestamp,
  };
}
