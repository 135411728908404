import { Tabs, Tab } from "@material-ui/core";
import React, { memo, useEffect } from "react";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import EquipmentMarketplace from "src/app/components/Equipment/Marketplace/EquipmentMarketplace";
import EquipmentSummary from "src/app/components/Equipment/Marketplace/EquipmentSummary";
import MaterialMarketplace from "src/app/components/Materials/Marketplace/MaterialMarketplace";
import { useQueryString } from "use-route-as-state";
import KnightMarketplace from "src/app/components/Knight/Marketplace/KnightMarketplace";
import KnightSummary from "src/app/components/Knight/Marketplace/KnightSummary";
import DemiMarketplace from "src/app/components/Demi/Marketplace/DemiMarketplace";
import KnightList from "src/app/components/Knight/KnightList";
import DemiList from "src/app/components/Demi/Marketplace/DemiList";
import { ROUTE } from "src/app/configs/constants";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "src/app/actions/marketAction";
import StakingSummary from "src/app/components/Staking/StakingSummary";
import DemiSummary from "src/app/components/Demi/Marketplace/DemiSummary";
import MaterialSummary from "src/app/components/Materials/Marketplace/MaterialSummary";
import LandList from "src/app/components/Land/LandList";
import LandSummary from "src/app/components/Land/LandSummary";

const Marketplace = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tabNumber } = useSelector((state: any) => state.market);
  const FIRST_TAB = 0;
  const SECOND_TAB = 1;
  const THIRD_TAB = 2;
  const heroesMarketplaceMatch = useRouteMatch(ROUTE.HEROES);
  const demiMarketplaceMatch = useRouteMatch(ROUTE.DEMI);
  const equipmentMarketplaceMatch = useRouteMatch(ROUTE.ITEMS);
  const materialsMarketplaceMatch = useRouteMatch(ROUTE.MATERIALS);
  const walletMatch = useRouteMatch(ROUTE.WALLET);
  const stakingMatch = useRouteMatch(ROUTE.STAKING);
  const heroBurnMatch = useRouteMatch(ROUTE.HERO_BURN);
  const landMatch = useRouteMatch(ROUTE.LANDS);

  const [tabValue, setTabValue] = useState(tabNumber);
  const [queryParams, setQueryParams] = useQueryString();

  useEffect(() => {
    if (walletMatch || stakingMatch) return;
    if (heroesMarketplaceMatch || equipmentMarketplaceMatch) {
      setTabValue(FIRST_TAB);
    } else if (demiMarketplaceMatch || materialsMarketplaceMatch) {
      setTabValue(SECOND_TAB);
    } else {
      setTabValue(THIRD_TAB);
    }
  }, [
    demiMarketplaceMatch,
    materialsMarketplaceMatch,
    walletMatch,
    stakingMatch,
    heroesMarketplaceMatch,
    equipmentMarketplaceMatch,
    landMatch,
  ]);

  const handleTabChange = (_e: any, newValue: number) => {
    if (newValue === tabValue) return;

    dispatch(changeTab(newValue));
    setTabValue(newValue);
    if (queryParams && !walletMatch && !stakingMatch) {
      setQueryParams({});
    }

    if (walletMatch || stakingMatch) return;

    switch (newValue) {
      case FIRST_TAB:
        history.replace(demiMarketplaceMatch || landMatch ? ROUTE.HEROES : ROUTE.ITEMS);
        break;
      case SECOND_TAB:
        history.replace(heroesMarketplaceMatch || landMatch ? ROUTE.DEMI : ROUTE.MATERIALS);
        break;
      case THIRD_TAB:
        history.replace(demiMarketplaceMatch || heroesMarketplaceMatch ? ROUTE.LANDS : ROUTE.HEROES);
        break;
    }
  };

  const renderTabContent = (tabValue: number) => {
    switch (tabValue) {
      case FIRST_TAB:
        return heroesMarketplaceMatch ? (
          <KnightMarketplace />
        ) : walletMatch || heroBurnMatch ? (
          <KnightList
            showOwned={false}
            showStaked={false}
            showOffering={false}
            redirectToDetails={true}
            isHeroBurn={Boolean(heroBurnMatch)}
          />
        ) : stakingMatch ? (
          <KnightList showOwned={true} showStaked={true} showOffering={false} redirectToDetails={false} isStakingPage />
        ) : (
          <EquipmentMarketplace />
        );
      case SECOND_TAB:
        return demiMarketplaceMatch ? (
          <DemiMarketplace />
        ) : walletMatch ? (
          <DemiList showOwned={false} redirectToDetails={true} />
        ) : stakingMatch ? (
          <DemiList showOwned={true} redirectToDetails={false} />
        ) : (
          <MaterialMarketplace />
        );
      case THIRD_TAB:
        return landMatch ? <LandList /> : <LandList hideSwitcher showClaim={true} />;
    }
  };

  return (
    <div className="container slide-up">
      {heroesMarketplaceMatch ? (
        <KnightSummary />
      ) : demiMarketplaceMatch ? (
        <DemiSummary />
      ) : materialsMarketplaceMatch ? (
        <MaterialSummary />
      ) : equipmentMarketplaceMatch ? (
        <EquipmentSummary />
      ) : landMatch ? (
        <LandSummary />
      ) : (
        stakingMatch && <StakingSummary />
      )}
      {!heroBurnMatch && (
        <Tabs value={tabValue} onChange={handleTabChange} className="market__tab" variant="fullWidth">
          <Tab
            label={heroesMarketplaceMatch || demiMarketplaceMatch || walletMatch || stakingMatch || landMatch ? "Heroes" : "Items"}
          />
          <Tab
            label={heroesMarketplaceMatch || demiMarketplaceMatch || walletMatch || stakingMatch || landMatch ? "Demi" : "Materials"}
          />
          {(walletMatch || heroesMarketplaceMatch || demiMarketplaceMatch || landMatch) && <Tab label="Lands" />}
        </Tabs>
      )}
      {renderTabContent(heroBurnMatch ? FIRST_TAB : tabValue)}
    </div>
  );
});

export default Marketplace;
