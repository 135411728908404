import React, { useEffect, useState } from "react";
import { formatNumber } from 'src/app/utils/helpers';
import { queryGuildSummary } from "src/app/services/api/subgraphService";
import { GuildSummaryData } from 'src/app/types/guild';
import { createGuildSummary } from 'src/app/factories/guildFactory';

const GuildSummary = () => {
  const [summary, setSummary] = useState<GuildSummaryData>(createGuildSummary(false));

  useEffect(() => {
    getTotalInfo();
  }, []);

  async function getTotalInfo() {    
    const result = await queryGuildSummary();
    if (result) setSummary(result);
  }
  
  return (
    <div className="data-summary data-summary--guild">
      <div className="data-summary__container">
        <div>
          <div className="data-summary__title">
            Total Guilds
          </div>
          <div className="data-summary__value align-center">
            {summary.totalGuild ? `${formatNumber(summary.totalGuild)}` : "--/--"}
          </div>
        </div>
        <div>
          <div color="primary" className="data-summary__title">
            Top Guild
          </div>
          <div className="data-summary__value align-center">
            {summary.topGuild ? `${summary.topGuild.name}` : "--/--"}
          </div>
        </div>
        <div>
          <div color="primary" className="data-summary__title">
            Total Guild Members
          </div>
          <div className="data-summary__value align-center">
            {summary.totalMem ? `${formatNumber(summary.totalMem)}` : "--/--"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuildSummary;
