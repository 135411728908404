import React, { useState, useEffect, useCallback } from "react";
import { matchPath, withRouter } from "react-router";
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  Paper,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  StylesProvider,
} from "@material-ui/core";
import { INIT_DEMI_FILTER, MAX_STAT_RANGE, RACE, RARITY_LABEL, ROUTE, SORT_DEMI_BY, STATS_LABEL } from "src/app/configs/constants";
import { getFilterCount, getDemiFilterFromParams, getAdvancedFilterCount, getBasicFilterCount } from "src/app/utils/filterHelper";
import { useQueryString } from "use-route-as-state";
import { Trait } from "src/app/types/knight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { debounce } from "lodash";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DemiFilterType } from "src/app/types/demiKnight";
import { getTraits } from "src/app/services/api/faralandService";

function DemiFilter(props: any) {
  const [raceGenderIcon, setRaceGenderIcon] = useState("male");
  const [filterCount, setFilterCount] = useState(0);
  const [basicFilterCount, setBasicFilterCount] = useState(0);
  const [advancedFilterCount, setAdvancedFilterCount] = useState(0);
  const [queryParams, setQueryParams] = useQueryString();
  const [demiFilters, setDemiFilters] = useState<DemiFilterType>(getDemiFilterFromParams(queryParams));
  const [demiIdInput, setdemiIdInput] = useState<any>(demiFilters.id);
  const [totalBaseStats, setTotalBaseStats] = useState<any>(demiFilters.totalBaseStats);
  const [numOfBodyParts, setNumOfBodyParts] = useState<any>(demiFilters.numberOfBodyParts);
  const [strSlider, setStrSlider] = useState<any>(demiFilters.stats[STATS_LABEL.STR]);
  const [agiSlider, setAgiSlider] = useState<any>(demiFilters.stats[STATS_LABEL.AGI]);
  const [intSlider, setIntSlider] = useState<any>(demiFilters.stats[STATS_LABEL.INT]);
  const [lukSlider, setLukSlider] = useState<any>(demiFilters.stats[STATS_LABEL.LUK]);
  const [rarityValue, setRarityValue] = useState<any>(demiFilters.traitRarity);
  const [tabValue, setTabValue] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [traits, setTraits] = useState<Trait[]>([]);
  const [loading, setLoading] = useState(false);
  const [traitSearchValue, setTraitSearchValue] = useState("");

  const debounceGetTraits = useCallback(debounce(_getTraits, 500), []);
  const debouncePushToParams = useCallback(debounce(setQueryParams, 500), []);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    debounceGetTraits(traitSearchValue, active);

    return () => {
      active = false;
    };
  }, [traitSearchValue, loading]); // eslint-disable-line

  useEffect(() => {
    if (!open) {
      setTraits([]);
      setLoading(false);
    }
    if (open) {
      setLoading(true);
    }
  }, [open]);

  useEffect(() => {
    const raceGender = Math.random() < 0.5 ? "male" : "female";
    setRaceGenderIcon(raceGender);
  }, []);

  useEffect(() => {
    setFilterCount(getFilterCount(demiFilters));
    setBasicFilterCount(getBasicFilterCount(demiFilters));
    setAdvancedFilterCount(getAdvancedFilterCount(demiFilters));
  }, [demiFilters]);

  useEffect(() => {
    const newFilters = getDemiFilterFromParams(queryParams);
    setDemiFilters({ ...newFilters });
  }, [queryParams]);

  async function _getTraits(value: string, active: boolean) {
    const bodyParts = await getTraits(value);
    if (active) {
      setTraits(bodyParts);
      if (bodyParts.length === 0) {
        setLoading(false);
      }
    }
  }

  const handleInputChange = (category: string) => (e: any) => {
    const value = e.target.value;
    if (category === "id") {
      setdemiIdInput(value);
    } else if (category === "totalBaseStats") {
      setTotalBaseStats(value);
    } else if (category === "numberOfBodyParts") {
      setNumOfBodyParts(value);
    }
    debouncePushToParams({
      ...queryParams,
      [category]: value
    })
  }

  function handleSortChange(e: any) {
    setQueryParams({
      ...queryParams,
      sortBy: e.target.value,
    });
  }

  function handleClearFilters() {
    const isStaking = matchPath(props.location.pathname, { path: ROUTE.STAKING });
    const isWallet = matchPath(props.location.pathname, { path: ROUTE.WALLET });
    const isOwnedList = isStaking || isWallet;

    setDemiFilters(
      isOwnedList ? { ...INIT_DEMI_FILTER, owned: true, sortBy: SORT_DEMI_BY.LOWEST_ID } : INIT_DEMI_FILTER
    );
    setQueryParams(isOwnedList ? { owned: "true", sortBy: SORT_DEMI_BY.LOWEST_ID } : {});
    setdemiIdInput("");
    setTotalBaseStats("");
    setNumOfBodyParts("");
    setRarityValue("");
    setStrSlider([0, 25]);
    setAgiSlider([0, 25]);
    setIntSlider([0, 25]);
    setLukSlider([0, 25]);
  }

  function handleCheckboxChange(e: any, category: string, key: string) {
    const selectedCategory = queryParams[category];
    const arraySelected = selectedCategory ? selectedCategory.split(",") : [];
    const found = arraySelected.indexOf(key);

    if (found >= 0 && !e.target.value) {
      arraySelected.splice(found, 1);
    } else if (e.target.checked) {
      arraySelected.push(key);
    }

    return setQueryParams({
      ...queryParams,
      [category]: arraySelected.join(","),
    });
  }

  const handleTraitChange = (category: string) => (_e: any, newValue: any) => {
    setQueryParams({
      ...queryParams,
      [category]: JSON.stringify(newValue),
    });
  };

  const handleTraitInputChange = (e: any) => {
    if (e) {
      setTraitSearchValue(e.target.value);
      setLoading(true);
    }
  };

  const handleTabChange = (_e: any, newValue: number) => {
    setTabValue(newValue);
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleRarityChange = (_e: any, value: any) => {
    if (value === null) {
      setRarityValue("");
    } else {
      setRarityValue(value);
    }
    setQueryParams({
      ...queryParams,
      traitRarity: value ?? "",
    });
  };

  function _getDataByCheckbox(category: string, imageName?: string) {
    let data;
    let icon = "";

    if (category === "races") {
      data = demiFilters.races;
      icon = imageName ? require(`src/assets/images/icons/races/${imageName}`) : "";
    } else if (category === "elements") {
      data = demiFilters.elements;
      icon = imageName ? require(`src/assets/images/icons/elements/${imageName}`) : "";
    } else if (category === "genders") {
      data = demiFilters.genders;
    } else {
      data = demiFilters.sales;
    }

    return { data, icon };
  }

  function renderCheckboxes(category: string, values: any = {}, iconMargin = "", subImageName = "") {
    return (
      <div className="mb-2">
        <div className="dashed-title">
          <span>{category}</span>
        </div>
        <div className="market__filter-items">
          {Object.keys(values).map((key: string) => {
            const { icon } = _getDataByCheckbox(
              category,
              `${key === RACE.DRAGONBORN ? "dragon" : key.toLowerCase()}${subImageName}.png`
            );

            return (
              <FormControlLabel
                key={key}
                className={`market__filter-item`}
                control={
                  <Checkbox
                    checked={values[key]}
                    onChange={(e) => handleCheckboxChange(e, category, key)}
                    color="primary"
                  />
                }
                label={
                  <div className="flex-center-start bot-1">
                    {icon !== "" && <img className={`icon ${iconMargin}`} src={icon} alt="" />}
                    <span className="top-2 fs-3">{key === RACE.DRAGONBORN ? "Dragon" : key}</span>
                  </div>
                }
              />
            );
          })}
        </div>
      </div>
    );
  }

  function renderToggleButtons(category: string) {
    return (
      <div className="mb-2">
        <div className="dashed-title">
          <span>{category}</span>
        </div>
        <ToggleButtonGroup
          value={rarityValue}
          className="market__filter-rarity"
          exclusive
          onChange={handleRarityChange}
        >
          {RARITY_LABEL.map((rarity: string, key: number) => (
            <ToggleButton value={key} key={key}>
              {rarity}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    );
  }

  function _mapFiltersToState(key: string) {
    switch (key) {
      case STATS_LABEL.STR:
        return strSlider;
      case STATS_LABEL.AGI:
        return agiSlider;
      case STATS_LABEL.INT:
        return intSlider;
      default:
        return lukSlider;
    }
  }

  const handleSliderChange = (key: string) => (_e: any, newValue: any) => {
    switch (key) {
      case STATS_LABEL.STR:
        setStrSlider(newValue);
        break;
      case STATS_LABEL.AGI:
        setAgiSlider(newValue);
        break;
      case STATS_LABEL.INT:
        setIntSlider(newValue);
        break;
      default:
        setLukSlider(newValue);
        break;
    }
    debouncePushToParams({
      ...queryParams,
      [key]: newValue,
    });
  };

  function renderSliders(category: string, values: any) {
    const statMarks = [
      { value: 0, label: "0" },
      { value: MAX_STAT_RANGE, label: MAX_STAT_RANGE },
    ];
    return (
      <div className="mb-2">
        <div className="dashed-title">
          <span>{category}</span>
        </div>
        {Object.keys(values).map((key: string) => {
          return (
            <div className="flex mt-5 mr-2 mb-5 z-index-2" key={key}>
              {category === "stats" ? (
                <Tooltip title={key} placement="top" className="market__filter-slider--stats">
                  <img src={require(`src/assets/images/hero-details/stats/${key.toLowerCase()}.svg`)} alt="" />
                </Tooltip>
              ) : (
                <Tooltip title="Level" placement="top" className="market__filter-slider--levels">
                  <div>{key}</div>
                </Tooltip>
              )}
              <Slider
                value={_mapFiltersToState(key)}
                min={0}
                max={MAX_STAT_RANGE}
                step={1}
                onChange={handleSliderChange(key)}
                valueLabelDisplay="auto"
                marks={statMarks}
              />
            </div>
          );
        })}
      </div>
    );
  }

  function renderAutoComplete(label: string, category: string, values: any) {
    return (
      <div className="mb-4">
        <div className="dashed-title mb-4">
          <span>{label}</span>
        </div>
        <Autocomplete
          multiple
          className="input"
          options={traits}
          loading={loading}
          value={values}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setTraitSearchValue("");
            setOpen(false);
          }}
          getOptionLabel={(option) => option.value}
          onChange={handleTraitChange(category)}
          onInputChange={handleTraitInputChange}
          getOptionSelected={(option, val) => option.value === val.value}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Body Parts"
              InputLabelProps={{ shrink: true }}
              placeholder="Search Body Parts"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && traits.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          PaperComponent={(props) => (
            <StylesProvider injectFirst>
              <Paper {...props} className="autocomplete-paper" />
            </StylesProvider>
          )}
          renderOption={(option) => (
            <div className="market__filter-body-part">
              <Tooltip title={option.traitType} placement="top">
                <img
                  src={require(`src/assets/images/hero-details/attributes/Human/${option.traitType}.png`)}
                  alt={option.traitType}
                  width={60}
                />
              </Tooltip>
              <div className="body-part__content">
                <div className="body-part__name">{option.value}</div>
              </div>
            </div>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                icon={
                  <img
                    src={require(`src/assets/images/hero-details/attributes/Human/${option.traitType}.png`)}
                    alt={option.traitType}
                    width={20}
                  />
                }
                label={option.value}
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </div>
    );
  }

  function renderTextField(label: string, category: string, values: any) {
    return (
      <div className={category === "id" ? "" : "mb-4"}>
        {category !== "id" && (
          <div className="dashed-title mb-4">
            <span>{label}</span>
          </div>
        )}
        <TextField
          value={values}
          className={`input ${category !== "id" && "number-input"}`}
          label={label}
          type="number"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          onChange={handleInputChange(category)}
        />
      </div>
    );
  }

  return (
    <div className={`market__filter-container`}>
      <div className="market__filter-button">
        <div className="btn" onClick={handleShowFilter}>
          Filters <ArrowDropDownIcon />
        </div>
      </div>
      <div className={`market__filter ${showFilter && "market__filter--mobile"} fade-in ${props.isHeroBurn && "market__filter--burn"}`}>
        <div className="market__filter-header">
          <div className="fw-4 top-1">FILTERS {filterCount ? `(${filterCount})` : ""}</div>
          <div className="btn btn--small" onClick={handleClearFilters}>
            Clear Filter
          </div>
        </div>
        <div className="flex mb-4">
          <FormControl variant="outlined" className="input mr-4" style={{ width: 300 }}>
            <InputLabel htmlFor="outlined-age-native-simple">Sort By</InputLabel>
            <Select label="Sort By" native value={demiFilters.sortBy} onChange={handleSortChange}>
              {Object.keys(SORT_DEMI_BY).map((key: string) => {
                return (
                  <option value={SORT_DEMI_BY[key]} key={key}>
                    {SORT_DEMI_BY[key]}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {renderTextField("Demi ID", "id", demiIdInput)}

        </div>
        <Tabs value={tabValue} onChange={handleTabChange} className="market__filter-tabs" variant="fullWidth">
          <Tab label={`Basic${basicFilterCount ? ` (${basicFilterCount})` : ""}`} />
          <Tab label={`Advanced${advancedFilterCount ? ` (${advancedFilterCount})` : ""}`} />
        </Tabs>
        {tabValue === 0 ? (
          <>
            {renderCheckboxes("races", demiFilters.races, "mr-2", `-${raceGenderIcon}`)}
            {renderCheckboxes("elements", demiFilters.elements, "mr-1 ml-1-negative")}
            {renderCheckboxes("genders", demiFilters.genders)}
            {renderCheckboxes("sales", demiFilters.sales, "", "")}
          </>
        ) : (
          <>
            {renderAutoComplete("Body Parts", "trait", demiFilters.traits)}
            {renderTextField("Total Base Stats", "totalBaseStats", totalBaseStats)}
            {renderTextField("Number of Body Parts", "numberOfBodyParts", numOfBodyParts)}
            {renderToggleButtons("Trait Rarity")}
            {renderSliders("stats", demiFilters.stats)}
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(DemiFilter);
