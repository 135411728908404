export const marketActionTypes = {
  LIST: 'MARKET.LIST',
  DELIST: 'MARKET.DELIST',
  OFFER: 'MARKET.OFFER',
  TAKE_OFFER: 'MARKET.TAKE_OFFER',
  CANCEL_OFFER: 'MARKET.CANCEL_OFFER',
  BUY: 'MARKET.BUY',
  CHANGE_TAB: 'MARKET.CHANGE_TAB',
  REFRESH_BLACK_MARKET: "MARKET.REFRESH_BLACK_MARKET",
};

export function list(knightId: number, price: string, isDemi: boolean) {
  return {
    type: marketActionTypes.LIST,
    payload: { knightId, price, isDemi }
  }
}

export function delist(knightId: number, isDemi: boolean) {
  return {
    type: marketActionTypes.DELIST,
    payload: { knightId, isDemi }
  }
}

export function offer(knightId: number, offerValue: string, value: string, isDemi: boolean) {
  return {
    type: marketActionTypes.OFFER,
    payload: { knightId, offerValue, isDemi },
    txValue: isDemi ? 0 : value
  }
}

export function takeOffer(knightId: number, buyer: string, minPrice: string, isDemi: boolean) {
  return {
    type: marketActionTypes.TAKE_OFFER,
    payload: { knightId, buyer, minPrice, isDemi },
  }
}

export function cancelOffer(knightId: number, isDemi: boolean) {
  return {
    type: marketActionTypes.CANCEL_OFFER,
    payload: { knightId, isDemi },
  }
}

export function buy(knightId: number, value: string, isDemi: boolean) {
  return {
    type: marketActionTypes.BUY,
    payload: { knightId, isDemi },
    txValue: value
  }
}

export function changeTab(value: number) {
  return {
    type: marketActionTypes.CHANGE_TAB,
    payload: value,
  }
}

export function blackMarketRefresh(value: boolean) {
  return {
    type: marketActionTypes.REFRESH_BLACK_MARKET,
    payload: value
  }
}