import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAccount, importAccount } from "src/app/actions/accountAction";
import { WALLET_TYPE } from "src/app/configs/constants";
import { checkIsCoin98, getWalletParams } from "src/app/utils/helpers";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { setGlobalModal } from "src/app/actions/globalAction";
import Coin98Service from "src/app/services/accounts/Coin98Service";

export default function Coin98Account() {
  const dispatch = useDispatch();
  const { web3Service } = useSelector((state: any) => state.global);

  async function connect() {
    if (!checkIsCoin98()) {
      openConnectErrorModal();
      return;
    }

    dispatch(setGlobalModal("loading", { active: true }));

    const props = getWalletParams();
    const wallet = new Coin98Service(props);
    const address = await wallet.connect(openConnectErrorModal, true);

    if (address) {
      const spaceDomain = await web3Service?.sid.getName(address);
      // await signAndAuthenticateWallet(wallet, address);

      wallet.getDisconnected(
        () => dispatch(clearAccount()),
        (address: string, wallet: any, walletType: string, domainName: string) =>
          dispatch(importAccount(address, wallet, walletType, domainName)),
        wallet,
      );
      dispatch(importAccount(address, wallet, WALLET_TYPE.COIN98, spaceDomain.name ?? ""));
      modalService.close();
    }

    dispatch(setGlobalModal("loading"));
  }

  function openConnectErrorModal(message?: string) {
    dispatch(
      setGlobalModal("error", {
        active: true,
        data: (
          <div>
            {message
              ? message
              : "Cannot connect to your Coin98 wallet. Please make sure you have Coin98 Extension installed."}
          </div>
        ),
      })
    );
  }

  return (
    <div className="account__item" onClick={connect}>
      <div className="account__icon coin98" />
      <div className="account__name">Coin98</div>
    </div>
  );
}
