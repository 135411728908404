import React from "react";
import { MaterialTransactions } from 'src/app/types/materials';
import MarketTable from "src/app/components/Commons/MarketTable";

type MaterialHistoryTableProps = {
  transactions: MaterialTransactions[];
};

export default function MaterialHistoryTable(props: MaterialHistoryTableProps) {
  const isEmpty = props.transactions.length === 0;

  return (
    <div className={`equipment__table cm-table ${isEmpty ? "cm-table--empty" : ""} mt-7`}>
      <div className="cm-table__title">Sale History</div>
      <MarketTable
        headerTitles={["Action", "Amt", "Price", "From", "To", "Time"]}
        cellTypes={["action", "amount", "price", "address", "address", "time"]}
        cellKeys={["type", "amount", "price", "from", "to", "timestamp"]}
        isEmpty={isEmpty}
        orderData={props.transactions}
        emptyMessage="No History Yet"
        isMyOrder={false}
        myOrderData={false}
      />
    </div>
  );
}
