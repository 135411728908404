import React, { useEffect, useState } from "react";
import gachaFrame from "src/assets/images/gacha/gacha-frame.png";
import rollBtn from "src/assets/images/gacha/btn-roll.png";
import backBtn from "src/assets/images/gacha/btn-back.png";
import approveBtn from "src/assets/images/gacha/btn-approve.png";
import cancelBtn from "src/assets/images/gacha/btn-cancel.png";
import maxBtn from "src/assets/images/gacha/btn-max.png";
import arrowBtn from "src/assets/images/gacha/gacha-arrow-up.png";
import {
  INTERVAL,
  MAX_ROLL_NUMBER,
  ITEM_MINT_FEE,
  GACHA_VENDORS
} from "src/app/configs/constants";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import ENV from "src/app/configs/env";
import { useDispatch, useSelector } from "react-redux";
import { rollEquipment } from "src/app/actions/equipmentAction";
import { marketClient } from "src/app/services/subgraph/client";
import { GACHA_ITEMS_QUERY, GACHA_RECEIVED_ITEMS_QUERY } from "src/app/services/subgraph/queries";
import { Equipment } from "src/app/types/equipment";
import { createItems } from "src/app/factories/equipmentFactory";
import ItemIconGacha from "src/app/components/Equipment/Commons/ItemIconGacha";
import { setGlobalModal } from "src/app/actions/globalAction";
import { formatNumber } from "src/app/utils/helpers";
import Carousel from "react-material-ui-carousel";
import prevButton from "src/assets/images/home/characters/previous.png";
import nextButton from "src/assets/images/home/characters/next.png";
import { SpinePlayer } from "@esotericsoftware/spine-player";

export const GACHA_STEP = {
  SELECT_BANNER: 1,
  SELECT_ROLL_NUMBER: 2,
  CONFIRM_EQUIPMENT: 3,
};

export default function Gacha() {
  const dispatch = useDispatch();
  const { balance } = useSelector((state: any) => state.account);
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.EQUIPMENT);

  const [gachaPlayer, setGachaPlayer] = useState<any>();
  const [step, setStep] = useState(GACHA_STEP.SELECT_BANNER);
  const [vendorId, setVendorId] = useState<number | undefined>();
  const [rollAmount, setRollAmount] = useState(1);
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const [vendorItems, setVendorItems] = useState<Equipment[]>([]);
  const [receivedItems, setReceivedItems] = useState<Equipment[]>([]);
  const [remainingItems, setRemainingItems] = useState(0);

  useEffect(() => {
    _fetchVendorItems();
  }, [vendorId]); // eslint-disable-line

  useEffect(() => {
    if (step === GACHA_STEP.SELECT_ROLL_NUMBER) {
      const player = new SpinePlayer("player-container", {
        jsonUrl: `${ENV.URL.FARALAND_GITHUB_ASSET}/Stone_1.json`,
        atlasUrl: `${ENV.URL.FARALAND_GITHUB_ASSET}/Stone_1.atlas`,
        animations: ["1_frame", "animation2"],
        showControls: false,
        showLoading: false,
        alpha: true,
        preserveDrawingBuffer: false,
        viewport: {
          x: -600,
          y: 0,
          width: 1200,
          height: 1200,
          padLeft: 0,
          padRight: 0,
          padTop: 0,
          padBottom: 0,
        },
      });
      setGachaPlayer(player);
    }
  }, [step]);

  function toBannerStep() {
    setStep(GACHA_STEP.SELECT_BANNER);
  }

  function toRollStep(vendorId: number | undefined) {
    if (vendorId === undefined) return;
    setVendorId(vendorId);
    setStep(GACHA_STEP.SELECT_ROLL_NUMBER);
  }

  function adjustRollNumber(isUp: boolean, isMax = false) {
    let number = isUp ? rollAmount + 1 : rollAmount - 1;
    if (number > MAX_ROLL_NUMBER) number = MAX_ROLL_NUMBER;
    if (number < 1) number = 1;
    if (isMax) number = MAX_ROLL_NUMBER;

    setRollAmount(number);
  }

  function roll() {
    if (vendorId === undefined) return;

    if (+rollAmount * ITEM_MINT_FEE > +balance.FARA) {
      dispatch(setGlobalModal("error", {
        active: true,
        data: "Your FARA balance is insufficient.",
      }));
      return;
    }

    dispatch(rollEquipment(vendorId, +rollAmount, onRollingTxSuccess));
  }

  function onRollingTxSuccess(txHash: string) {
    dispatch(setGlobalModal('txTracking'));
    gachaPlayer.setAnimation("animation2");
    setAnimationPlayed(true);

    setTimeout(() => {
      setStep(GACHA_STEP.CONFIRM_EQUIPMENT);
      gachaPlayer.setAnimation("1_frame");
      setAnimationPlayed(false);

      _fetchGachaReceivedItems(txHash);
    }, 7000);
  }

  async function _fetchGachaReceivedItems(txHash: string) {
    const result = await marketClient.query({
      query: GACHA_RECEIVED_ITEMS_QUERY(txHash),
      fetchPolicy: "no-cache",
    });
    const data = result.data.gachaReceivedItem;

    if (!data) {
      setTimeout(() => {
        _fetchGachaReceivedItems(txHash);
      }, INTERVAL.GACHA_ITEMS);
    } else {
      setReceivedItems(createItems(data.items));
    }
  }

  async function _fetchVendorItems() {
    let remaining = 0;
    if (vendorId === undefined) return;
    const result = await marketClient.query({ query: GACHA_ITEMS_QUERY(vendorId) });
    const data = createItems(result.data.gachaItems);
    data.forEach((item: Equipment) => {
      remaining += item.maxSupply - item.minted;
    });
    setRemainingItems(remaining);
    setVendorItems(data);
  }

  return (
    <div className="gacha">
      <img className="gacha__frame" src={gachaFrame} alt="" />

      {step === GACHA_STEP.SELECT_BANNER && (
        <div className="gacha__banner gacha__container container slide-up">
          <Carousel
            className="unset-overflow gacha__carousel"
            autoPlay={false}
            animation="slide"
            activeIndicatorIconButtonProps={{
              style: {
                color: "#ff9900",
              },
            }}
            navButtonsAlwaysVisible
            NextIcon={<img src={nextButton} alt="" />}
            PrevIcon={<img src={prevButton} alt="" />}
            navButtonsProps={{
              style: {
                backgroundColor: "transparent",
              },
            }}
            navButtonsWrapperProps={{
              style: {
                height: "90%",
              },
            }}
          >
            {GACHA_VENDORS.map((vendor: any) => {
              return (
                <div className="relative" key={vendor.id}>
                  <img
                    className={`${!vendor.active && "disabled"}`}
                    key={vendor.id}
                    src={require(`src/assets/images/gacha/gacha-banner-${vendor.id}.png`)}
                    onClick={() => toRollStep(vendor.id)}
                    alt=""
                  />
                  {!vendor.active && (
                    <h1 className="gacha__closed">CLOSED</h1>
                  )}
                </div>
              );
            })}
          </Carousel>
        </div>
      )}

      {step === GACHA_STEP.SELECT_ROLL_NUMBER && (
        <div className={`gacha__select gacha__container container slide-up`}>
          <div id="player-container" />
          <div className={`${animationPlayed ? "disabled" : ""}`}>
            <div className="gacha__select-roll">
              <div className="gacha__select-text">Roll: {rollAmount}</div>
              <div className="flex-column-center">
                <img className="gacha__select-btn" src={arrowBtn} onClick={() => adjustRollNumber(true)} alt="" />
                <img
                  className="gacha__btn gacha__btn--small"
                  src={maxBtn}
                  onClick={() => adjustRollNumber(true, true)}
                  alt="Max"
                />
                <img className="gacha__select-btn" src={arrowBtn} onClick={() => adjustRollNumber(false)} alt="" />
              </div>
            </div>
            <div className="gacha__select-info">
              <span>you are purchasing </span>
              <span className="text-orange fw-4">{rollAmount} </span>
              <span>items which cost </span>
              <span className="text-orange fw-4">{rollAmount * ITEM_MINT_FEE} FARA</span>
            </div>
            <div className="gacha__action flex-center-center">
              <img src={cancelBtn} className="gacha__btn" onClick={toBannerStep} alt="Cancel" />
              {needApprove ? (
                <img src={approveBtn} className="gacha__btn" onClick={sendApproveTx} alt="Approve" />
              ) : (
                <img src={rollBtn} className={`gacha__btn ${remainingItems === 0 ? 'disabled' : ''}`} onClick={roll} alt="Roll" />
              )}
            </div>
          </div>
        </div>
      )}

      {step === GACHA_STEP.CONFIRM_EQUIPMENT && (
        <div className="gacha__confirm gacha__container slide-up">
          <div className="gacha__confirm-inventory">
            {receivedItems.length !== 0 ? (
              <>
                {receivedItems[0].id === 0 ? (
                  <div>There is no item in this gacha machine.</div>
                ) : (
                  <ItemIconGacha items={receivedItems} showMinted={false} showAmount={true} />
                )}
              </>

            ) : (
              <div>Calculating your Items...</div>
            )}
          </div>
          <div className="gacha__action flex-center-center">
            <img src={backBtn} className="gacha__btn" onClick={() => toRollStep(vendorId)} alt="Back" />
          </div>
        </div>
      )}

      {step !== GACHA_STEP.SELECT_BANNER && (
        <>
          <div className="gacha__items-left">
            <p>Items left: {formatNumber(remainingItems)}</p>
          </div>
          <div className="gacha__storage">
            <div className="gacha__storage-container nice-scroll nice-scroll--small">
              <ItemIconGacha items={vendorItems} showMinted={true} showAmount={false} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
