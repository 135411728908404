import React from "react";

const YoutubeEmbed = (props: any) => {
  const { isPveActive } = props;

  return (
    <div className="intro__video">
      <iframe
        width="100%"
        height="100%"
        src={isPveActive ? "https://www.youtube.com/embed/B5W2v_Tj1CE" : "https://www.youtube.com/embed/PWpUGpsBWzg"}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default YoutubeEmbed;
