import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ResultContent from "src/app/components/Commons/ResultContent";
import { Material } from "src/app/types/materials";

import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import { MATERIALS } from "src/app/configs/materials/materials";

type ModalProps = {
  runesCrafted: Material[];
  getBlacksmithData: any;
};

export default function RuneCraftingResultModal(props: ModalProps) {
  const { address } = useSelector((state: any) => state.account);

  const [runeIdAmounts, setRuneIdAmounts] = useState({});
  const { runesCrafted, getBlacksmithData } = props;

  useEffect(() => {
    let amount = {};
    getBlacksmithData(address);
    runesCrafted.forEach((rune) => {
      if (rune.id === null) return;
      amount[+rune.id] = (amount[+rune.id] || 0) + 1;
    });
    setRuneIdAmounts(amount);
  }, [runesCrafted, address, getBlacksmithData]);

  return (
    <div>
      <ResultContent
        isSuccess={true}
        content={<div>Congratulations! You have successfully crafted {runesCrafted.length} runes</div>}
      />
      {Object.keys(runeIdAmounts).map((runeId, index: number) => {
        return (
          <MaterialIcon key={index} material={MATERIALS[runeId]} amount={runeIdAmounts[runeId]} hideSelectedBorder />
        );
      })}
    </div>
  );
}
