import React from "react";
import DemiList from 'src/app/components/Demi/Marketplace/DemiList';

export default function DemiMarketplace() {
  return (
    <div className="slide-up">
      <DemiList
        showOwned={true}
        redirectToDetails={true}
      />
    </div>
  )
}