import React, { useState } from "react";
import heroPlaceholder from "src/assets/images/decors/hero-placeholder.png";
import { RARITY_LABEL } from "src/app/configs/constants";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import { BlackMarketItem } from "src/app/types/blackMarket";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import knightToken from "src/assets/images/icons/black-market/knight-token.png";
import royalToken from "src/assets/images/icons/black-market/royal-token.png";
import { MATERIALS } from "src/app/configs/materials/materials";
import _ from "lodash";
import BlackMarketTimer from "src/app/components/BlackMarket/BlackMarketTimer";
import timerImg from "src/assets/images/icons/black-market/timer.png";
import { useMediaQuery } from "@material-ui/core";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BlackMarketModal from "src/app/components/BlackMarket/BlackMarketModal";

export interface BlackMarketContentProps {
  blackMarketItem: BlackMarketItem;
}

const BlackMarketContent = (props: BlackMarketContentProps) => {
  const { blackMarketItem } = props;
  const [isLoading, setLoading] = useState(true);
  const item = _.isEmpty(blackMarketItem.itemIds)
    ? MATERIALS[blackMarketItem.materialIds[0]]
    : EQUIPMENT[blackMarketItem.itemIds[0]];
  const isMobile = useMediaQuery("(max-width: 1200px)");

  function handleImageLoaded(loading: boolean) {
    setLoading(loading);
  }

  function handleClick() {
    modalService.show(BlackMarketModal, {
      blackMarketItem,
      className: "medium",
    });
  }
  return (
    <>
      {item && (
        <>
          <div className="item-list__images" onClick={handleClick}>
            {isLoading && (
              <img
                className="item-list__item--placeholder"
                src={heroPlaceholder}
                alt=""
              />
            )}
            <div
              style={!isLoading ? {} : { display: "none" }}
              className="item-list__item item-list__item--black-market"
            >
              <ItemIconPreview
                item={item}
                isMarketplace
                onLoad={handleImageLoaded}
                disableGems
                showTreasureImg
              />
              {/* {isMaterialReward ? (
                <MaterialIconPreview
                  material={item}
                  isMarketplace
                  onLoad={handleImageLoaded}
                  showTreasureImg
                />
              ) : (
                <ItemIconPreview
                  item={item}
                  isMarketplace
                  onLoad={handleImageLoaded}
                  disableGems
                  showTreasureImg
                />
              )} */}
            </div>
          </div>
          {!isMobile && (
            <div className="item-list__timer">
              <img src={timerImg} alt="" />
              <BlackMarketTimer
                refillTime={blackMarketItem.refilledTimestamp}
              />
            </div>
          )}
          <div
            className={`item-list__rarity ${RARITY_LABEL[
              item.rarity
            ].toLowerCase()} `}
          >
            {blackMarketItem.amount}/{blackMarketItem.maxAmount}
          </div>

          <div className="item-list__content item-list__content--black-market mt-4">
            <div className="flex-column">
              <div className=" item-list__item-info--black-market">
                {blackMarketItem.name.toUpperCase()}
              </div>
            </div>
            {isMobile && (
              <BlackMarketTimer
                refillTime={blackMarketItem.refilledTimestamp}
              />
            )}

            <div className={`flex-center-start ${!isMobile ? "ml-2" : "mt-2"}`}>
              <div className="flex-column-right">
                <div className="item-list__price item-list__price--black-market warning-text">
                  <div>{blackMarketItem.price}</div>
                  <img
                    className="token--black-market ml-1 bot-2"
                    src={
                      blackMarketItem.tokenId === 18 ? knightToken : royalToken
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BlackMarketContent;
