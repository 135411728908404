import React from "react";
import smallBannerPlaceholderImage from "src/assets/images/decors/banner-placeholder-small.png";
import barImage from "src/assets/images/decors/banner-bar.png";
import { BannerData } from "src/app/types/common";
import orcImage from "src/assets/images/decors/homepage/orc.png";
import humanImage from "src/assets/images/decors/homepage/human.png";
import logoImage from "src/assets/images/logos/faraland.png";
import RellaxWrapper from "react-rellax-wrapper";
import HomeBannerContent from "src/app/components/Layout/Banner/HomeBannerContent";
import { ROUTE } from "src/app/configs/constants";
import formatImage from "src/assets/images/banners/instructions-formats.png";
import lionBannerLeft from "src/assets/images/guild/lion-banner-left.png";
import lionBannerRight from "src/assets/images/guild/lion-banner-right.png";
import LandBannerContent from "src/app/components/Layout/Banner/LandBannerContent";
interface BannerProps {
  path: string;
  bannerData: BannerData;
}

export default function Banner(props: BannerProps) {
  const { background, title, subtitle } = props.bannerData;
  const isHomePage = props.path === ROUTE.HOME;
  const isLandMintPage = props.path === ROUTE.LAND_MINT;
  const isGuildPage = props.path === ROUTE.GUILD;
  const isGuildDetails = props.path === ROUTE.GUILD_DETAILS;
  const isInstructionsPage = props.path === ROUTE.INSTRUCTIONS;
  const noMargin =
    isHomePage ||
    isGuildPage ||
    isGuildDetails ||
    [ROUTE.PROJECT_OVERVIEW, ROUTE.TOKEN_METRIC, ROUTE.EVENT, ROUTE.EQUIPMENT_CRAFTING, ROUTE.EQUIPMENT_GACHA].includes(
      props.path
    );

  return (
    <div
      className={`banner ${isHomePage || isLandMintPage ? "banner__home" : ""} ${
        isInstructionsPage ? "banner__instructions" : ""
      } ${noMargin ? "no-margin" : ""}`}
      style={{ backgroundImage: `url(${background})` }}
    >
      <img className="banner__bar" src={barImage} alt="" />
      {isHomePage && (
        <>
          <div className="banner__home-image">
            <RellaxWrapper speed={4} className="banner__home-image--orc">
              <img src={orcImage} alt="" />
            </RellaxWrapper>
            <RellaxWrapper speed={-2} className="banner__home-image--human">
              <img src={humanImage} alt="" />
            </RellaxWrapper>
            <RellaxWrapper speed={3} className="banner__home-image--logo">
              <img src={logoImage} alt="" />
            </RellaxWrapper>
          </div>
          <div className="banner__home-content">
            <HomeBannerContent />
          </div>
        </>
      )}
      {isLandMintPage && (
        <>
          <div className="banner__home-image">
            <h1 className="banner__land-phase">PHASE 3</h1>
          </div>
          <div className="banner__home-content">
            <LandBannerContent />
          </div>
        </>
      )}
      {isInstructionsPage && (
        <>
          <div className="banner__instructions-image">
            <RellaxWrapper speed={4} className="banner__instructions-image--orc">
              <img src={orcImage} alt="" />
            </RellaxWrapper>
            <RellaxWrapper speed={-2} className="banner__instructions-image--human">
              <img src={humanImage} alt="" />
            </RellaxWrapper>
            <RellaxWrapper speed={3} className="banner__instructions-image--logo">
              <a href={ROUTE.HOME}>
                <img src={logoImage} alt="" />
              </a>
            </RellaxWrapper>
            <div className="banner__instructions-content">
              <h1 className="mb-2">Faraland Expedition game is now ready!!!</h1>
              <div>
                Players will embody characters of Faraland Universe, with diversified races and a well-articulated job
                system.
              </div>
              <div className="align-center">
                <b>Experience Faraland Expedition Game</b>
              </div>
              <div>
                Faraland’s unique feature is its immensely diversified mission system, which includes a meticulously
                crafted storyline. Players can participate in many different mission routes: Daily missions, story
                quests, or randomly hidden quests encountered during gameplay. There will be different endings to a
                quest, with the final treasure only revealing itself if players chose the right path.
              </div>
              <div className="align-center ">
                <b>Collect in-game materials to craft tradable items</b>
              </div>
              <div>
                With a diverse career system, each race in Faraland has its distinct characteristics. The crafting
                system allows players to create valuable in-game items by using droppable raw materials. There are a
                variety of in-game items such as weapons, clothes, heroes’ equipment, potions, support props, and many
                more. Raw materials can be traded on Marketplace with $FARA
              </div>
              <div className="align-center">
                <b>Earning Gold, SoulStone, EXP from completing Faraland Quests</b>
              </div>
              <div>
                In the current expedition version, players can earn Gold (In-Game Currency), Soul Stone (which can be
                exchanged on the market using Fara Token), Experience Points to increase hero level. The in-game
                incentives will be continuously updated as well as new gameplay features.
              </div>
            </div>
          </div>
          <div className="banner__instructions-format">
            <img src={formatImage} alt="" />
          </div>
        </>
      )}
      {isGuildPage && (
        <div>
          <img className="banner__lion--left" src={lionBannerLeft} alt="" />
          <img className="banner__lion--right" src={lionBannerRight} alt="" />
        </div>
      )}
      {!isHomePage && !isInstructionsPage && !isLandMintPage && title && (
        <div className="banner__content">
          <div className="banner__titles">
            <div className="banner__title">{title}</div>
            <div className="banner__subtitle">{subtitle}</div>
          </div>
          <img className="banner__placeholder" src={smallBannerPlaceholderImage} alt="" />
        </div>
      )}
    </div>
  );
}
