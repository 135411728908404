import React, { useRef, useState } from "react";
import { KnightAttributes } from "src/app/types/attribute";
import { Tooltip } from "@material-ui/core";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import { useDispatch, useSelector } from "react-redux";
import { MATERIALS } from "src/app/configs/materials/materials";
import { INTERVAL, RARITY_LABEL, RARITY_UPGRADE_RATE, SOUL_STONE_FOR_UPGRADE } from "src/app/configs/constants";
import { upgradeBodyPart } from "src/app/actions/knightAction";
import { fetchUpgradeResult } from "src/app/services/api/faralandService";
import loadingIcon from "src/assets/images/icons/loading-circle.svg";
import { setGlobalModal } from "src/app/actions/globalAction";
import useIsMounted from "src/app/hooks/useIsMounted";
import ResultContent from "src/app/components/Commons/ResultContent";

type ModalProps = {
  knightId: number,
  attributes: KnightAttributes[];
  displayRace: string;
};

export default function KnightUpgradeModal(props: ModalProps) {
  const dispatch = useDispatch();
  const { balance } = useSelector((state: any) => state.account);
  const [isMounted] = useIsMounted();
  const fetchResultInterval = useRef<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [upgradingAttribute, setUpgradingAttribute] = useState<KnightAttributes>();

  const { knightId, attributes, displayRace } = props;
  const invalidBalance = balance.SOUL_STONE < SOUL_STONE_FOR_UPGRADE;
  const isFirstStep = !isLoading && isSuccess === null;

  function upgrade(attribute: KnightAttributes) {
    setUpgradingAttribute(attribute);
    dispatch(upgradeBodyPart(knightId, attribute.traitType, requestResult));
  }

  async function requestResult(txHash: string) {
    dispatch(setGlobalModal('txTracking'));
    setIsLoading(true);

    const result = await fetchUpgradeResult(txHash);
    if (result === null && isMounted.current) {
      clearInterval(fetchResultInterval.current);
      fetchResultInterval.current = setInterval(() => {
        requestResult(txHash);
      }, INTERVAL.REFETCH_UPGRADE);
      return;
    }

    setIsSuccess(result);
    setIsLoading(false);
    clearInterval(fetchResultInterval.current);
  }

  return (
    <div className="knight-upgrade">
      {isLoading && (
        <div className='flex-start-center mb-7'>
          <img className='loading-icon' src={loadingIcon} alt='loading' />
          <div>Please wait a moment until the result is fully calculated.</div>
        </div>
      )}

      {!isFirstStep && upgradingAttribute !== undefined && (
        <div>
          {isSuccess === true && (
            <ResultContent
              isSuccess={true}
              content={(
                <div>
                  {upgradingAttribute.value} {upgradingAttribute.traitType} has been upgraded successfully
                  from {upgradingAttribute.displayRarity} to {RARITY_LABEL[upgradingAttribute.rarity + 1]}.
                </div>
              )}
            />
          )}

          {isSuccess === false && (
            <ResultContent
              isSuccess={false}
              content={<div>Upgrade failed. Better luck next time.</div>}
            />
          )}
        </div>
      )}

      {isFirstStep && (
        <>
          <div className="flex-column-center">
            <MaterialIcon material={MATERIALS[0]} />
            <div className={`${invalidBalance ? 'text-gray' : 'text-9'} fw-3`}>
              {balance.SOUL_STONE} / {SOUL_STONE_FOR_UPGRADE}
            </div>
            <div className="knight-upgrade__text">
              You will need {SOUL_STONE_FOR_UPGRADE} Soul Stones to make an upgrade for a Body Part of your choice.
            </div>
          </div>
          <div className="body-part body-part--column">
            {attributes.map((attribute: KnightAttributes, index: number) => (
              <div className="body-part__item" key={index}>
                <div className="flex-center-start">
                  <Tooltip title={attribute.traitType} placement="top">
                    <img
                      src={require(`src/assets/images/hero-details/attributes/${displayRace}/${attribute.traitType}.png`)}
                      alt={attribute.traitType}
                      width={60}
                    />
                  </Tooltip>
                  <div className="body-part__content">
                    <div className="body-part__name">{attribute.value}</div>
                    <div className="body-part__rarity">
                      <span className={`rarity-${attribute.displayRarity.toLowerCase()}`}>{attribute.displayRarity}</span>
                      <span className="mr-1 ml-1 bot-1">&#8594;</span>
                      <span className={`rarity-${RARITY_LABEL[attribute.rarity + 1].toLowerCase()}`}>
                        {RARITY_LABEL[attribute.rarity + 1]}
                      </span>
                      <p className='mt-1'>{RARITY_UPGRADE_RATE[attribute.rarity]}% success</p>
                    </div>
                  </div>
                </div>
                <div className={`body-part__btn btn btn--small ${invalidBalance ? 'disabled' : ''}`} onClick={() => upgrade(attribute)}>
                  Upgrade
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
