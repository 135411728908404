import React, { ReactElement } from "react";
import { Tooltip } from "@material-ui/core";
import {
  UserItem,
  Equipment,
  Item,
  RuleDestItem,
  SlotEquipment,
  ItemSet,
  ItemEffectType,
} from "src/app/types/equipment";
import gemIcon from "src/assets/images/decors/frames/frame-gem.png";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import ItemInfo from "src/app/components/Equipment/Commons/ItemInfo";
import { RARITY_LABEL } from "src/app/configs/constants";
import { calculateStatsDifference, getEquipmentImageURL, getItemSetPieces } from "src/app/utils/helpers";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { renderStats } from "src/app/utils/renderHelpers";
import { EQUIPMENT_SET } from "src/app/configs/equipment/sets";
import { KnightStats } from "src/app/types/knight";

type ItemIconProps = {
  item: Equipment | Item;
  onClick?: any;
  onRightClick?: (itemId: number) => void;
  amount?: number;
  layer?: number;
  showBalance?: boolean;
  closeOnSubmit?: boolean;
  userItem?: UserItem;
  onSubmit?: any;
  submitText?: string;
  slotEquipments?: SlotEquipment[];
  isKnightPreview?: boolean;
  disableSubmit?: boolean;
  rule?: RuleDestItem;
  error?: string;
  isLearnedSkills?: boolean;
  isComparisonTooltip?: boolean;
  className?: string;
  hideSelectedBorder?: boolean;
  showFrame?: boolean;
  knightStats?: KnightStats;
  hideAmount?: boolean;
  extraPanels?: () => ReactElement;
};

export default function ItemIcon(props: ItemIconProps) {
  const { item, slotEquipments } = props;
  const error = props.error === undefined ? "" : props.error;

  function showEquipmentInfoModal(itemId: number) {
    if (props.onClick) {
      props.onClick(itemId);
      return;
    }

    const isDisabled = !props.disableSubmit;
    const validCondition = error === "" || props.submitText === "Remove";

    modalService.show(BasicModalContent, {
      content: (
        <div>
          <ItemInfo
            userItem={props.userItem}
            itemId={itemId}
            isLearnedSkills={props.isLearnedSkills}
            noUpgradeInfo={props.isLearnedSkills}
            slotEquipments={props.slotEquipments}
            isKnightPreview={props.isKnightPreview}
            knightStats={props.knightStats}
          />
          {error && (
            <div className="mt-4 pl-1 small-text small-text--warning" dangerouslySetInnerHTML={{ __html: error }} />
          )}
        </div>
      ),
      onSubmit: isDisabled && validCondition ? props.onSubmit : undefined,
      submitText: props.submitText,
      closeOnSubmit: !!props.closeOnSubmit,
      layer: props.layer,
      extraPanels: props.extraPanels && props.extraPanels(),
    });
  }

  const getEquippedSetNames = (item: Item) => {
    if (!slotEquipments) return [];

    let equippedSetNames: string[] = [];

    slotEquipments.forEach((slotEquipment: SlotEquipment) => {
      const equippedItem: Item = EQUIPMENT[slotEquipment.id];
      if (item.setId === equippedItem.setId) equippedSetNames.push(equippedItem.name);
    });
    return equippedSetNames;
  };

  function renderTooltipSetBonuses(
    itemSet: ItemSet,
    setPieces: number[],
    equippedLength: number,
    isEquippedSet: boolean
  ) {
    return (
      <>
        {setPieces.length > 0 && setPieces.includes(equippedLength) && (
          <div className={`fs-3 ${isEquippedSet ? "error-text" : "success-text"}`}>
            {renderSetBonus(itemSet.bonuses[setPieces.indexOf(equippedLength)].stats, isEquippedSet)}
          </div>
        )}
      </>
    );
  }

  function renderSetBonus(stats: any, isEquippedSet: boolean) {
    let isFirstValue = true;
    return Object.keys(stats).map((key: string, i: number) => {
      if (!stats[key]) return null;

      const separator = !isFirstValue && ", ";
      isFirstValue = false;

      if (key === "effects") {
        return stats["effects"].map((effect: ItemEffectType) => {
          return (
            <span key={i}>
              {separator}
              {effect.name}: {effect.description}
            </span>
          );
        });
      }

      return (
        <span key={i}>
          {separator}
          {isEquippedSet ? "-" : "+"}
          {stats[key]} {key}
        </span>
      );
    });
  }

  const renderTooltipContent = (itemId: number) => {
    if (!props.isComparisonTooltip) return `${item.displayName} - ${RARITY_LABEL[item.rarity]}`;

    const hoverItem: Item = EQUIPMENT[itemId];
    if (!hoverItem) return "";
    const hoverItemSet: ItemSet = hoverItem.setId ? EQUIPMENT_SET[hoverItem.setId] : 0;
    const hoverItemSetPieces = getItemSetPieces(hoverItemSet);
    const hoverEquippedSetNames = getEquippedSetNames(hoverItem);
    const itemInSlot = props.slotEquipments?.filter((item) => item.slot === hoverItem.type);

    if (itemInSlot && itemInSlot.length > 0 && itemInSlot[0].id !== itemId && !error) {
      const slotItem: Item = EQUIPMENT[itemInSlot[0].id];
      const slotItemSet: ItemSet = slotItem.setId ? EQUIPMENT_SET[slotItem.setId] : 0;
      const slotItemSetPieces = getItemSetPieces(slotItemSet);
      const slotEquippedSetNames = getEquippedSetNames(slotItem);
      const baseStatsDifference = calculateStatsDifference(slotItem.stats, hoverItem.stats);
      const displayCondition =
        (slotItemSetPieces.length > 0 && slotItemSetPieces.includes(slotEquippedSetNames.length)) ||
        (hoverItemSetPieces.length > 0 && hoverItemSetPieces.includes(hoverEquippedSetNames.length + 1));

      return (
        <>
          <div className="item-icon__hover-text">
            {hoverItem.displayName} - {RARITY_LABEL[hoverItem.rarity]}
          </div>
          {renderStats(baseStatsDifference, props.isComparisonTooltip)}
          {displayCondition && (
            <div className="fs-3 mt-2">
              <div>Set bonuses: </div>
              {renderTooltipSetBonuses(slotItemSet, slotItemSetPieces, slotEquippedSetNames.length, true)}
              {renderTooltipSetBonuses(hoverItemSet, hoverItemSetPieces, hoverEquippedSetNames.length + 1, false)}
            </div>
          )}
        </>
      );
    } else if (itemInSlot?.length === 0 && !error) {
      return (
        <>
          <div className="item-icon__hover-text">
            {hoverItem.displayName} - {RARITY_LABEL[hoverItem.rarity]}
          </div>
          {renderStats(hoverItem.stats, props.isComparisonTooltip)}
          {hoverItemSetPieces.length > 0 && hoverItemSetPieces.includes(hoverEquippedSetNames.length + 1) && (
            <div className="fs-3 mt-2">
              <div>Set bonuses: </div>
              {renderTooltipSetBonuses(hoverItemSet, hoverItemSetPieces, hoverEquippedSetNames.length + 1, false)}
            </div>
          )}
        </>
      );
    }
    return `${item.displayName} - ${RARITY_LABEL[item.rarity]}`;
  };

  return (
    <Tooltip title={renderTooltipContent(props.item.id)} arrow placement="top" key={props.item.id}>
      <div
        className={`${
          props.showFrame &&
          `item-icon__rarity-frame item-icon__rarity-frame-icon item-icon__frame--${RARITY_LABEL[
            item.rarity
          ].toLowerCase()}`
        } item-icon ${!!props.amount && !props.hideSelectedBorder ? "item-icon--selected" : ""} slide-up ${
          props.className
        }`}
        onClick={() => showEquipmentInfoModal(props.item.id)}
        onContextMenu={(e) => {
          e.preventDefault();
          props.onRightClick?.call(props.onRightClick, props.item.id);
        }}
      >
        <img
          className={`item-icon__image ${props.hideSelectedBorder && "item-icon__image--no-border"} ${
            error !== "" ? "item-icon__image--error" : ""
          }`}
          src={
            props.isLearnedSkills
              ? require(`src/assets/images/learned-skills/${item.name.replaceAll(" ", "")}.png`)
              : getEquipmentImageURL(item.id, "178x178")
          }
          alt=""
        />
        {!!props.amount && !props.hideAmount && <div className="item-icon__amount">x{props.amount}</div>}
        {props.showBalance && props.userItem && (
          <div className="item-icon__text">
            {props.userItem.available}/{props.userItem.amount}
          </div>
        )}
        {props.rule && (
          <div className={`item-exchange__icon-amount item-exchange__icon-amount--modal`}>x{props.rule.amount}</div>
        )}
        {props.showFrame &&
          (props.item as Item).tier &&
          Array.from(Array((props.item as Item).tier).keys()).map((tier: number, index: number) => {
            return (
              <img
                className={`item-icon__gem item-icon__gem--${tier} item-icon__gem-alt--${tier}`}
                src={gemIcon}
                key={index}
                alt=""
              />
            );
          })}
      </div>
    </Tooltip>
  );
}
