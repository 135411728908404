import React, { useState, useEffect, useCallback, useRef } from "react";
import { StylesProvider, TextField } from "@material-ui/core";
import { timeAgo } from "src/app/utils/helpers";
import { GuildFilterType, GuildMember, GuildQuery } from "src/app/types/guild";
import strengthIcon from "src/assets/images/guild/strength-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { kickMembers } from "src/app/actions/guildAction";
import { debounce } from "lodash";
import { getGuildFilterFromParams } from "src/app/utils/filterHelper";
import { useQueryString } from "use-route-as-state";
import { useParams } from "react-router-dom";
import { LIMIT, SORT_DATA } from "src/app/configs/constants";
import { fetchGuildMembersFromApi } from "src/app/services/api/faralandService";
import { Pagination } from "@material-ui/lab";
import _ from "lodash";
import GuildTables from "../Common/GuildTables";
import useFetchSpaceDomain from "src/app/hooks/useFetchSpaceDomain";
import { renderWalletAddress } from "src/app/utils/renderHelpers";

type GuildMembersTableProps = {
  isOwner: boolean;
};

export default function GuildMembersTable(props: GuildMembersTableProps) {
  const dispatch = useDispatch();
  const guildMembersRef = useRef(null);
  const { id } = useParams<{ id: string }>();
  const [queryParams, setQueryParams] = useQueryString();
  const { address } = useSelector((state: any) => state.account);
  const { isOwner } = props;
  const [walletInput, setWalletInput] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [guildMembers, setGuildMembers] = useState<GuildMember[]>([]);
  const isEmpty = guildMembers.length === 0;
  const [selectedMembers, setSelectedMembers] = useState<GuildMember[]>([]);
  const [guildFilters, setGuildFilters] = useState<GuildFilterType>(getGuildFilterFromParams(queryParams, true));
  const { domains, getAddresses } = useFetchSpaceDomain();

  const [columns] = useState([
    {
      title: "Wallet",
      field: "id",
      render: (row) => renderWalletAddress(row.id, row.domainAddress, 4),
    },
    { title: "Username", field: "username" },
    { title: "Position", field: "position" },
    {
      title: "Time Joined",
      field: "timeJoin",
      render: (row) => <div>Joined {row.timeJoin && timeAgo(+row.timeJoin * 1000)}</div>,
    },
    {
      title: "Strength",
      field: "strength",
      render: (row) => (
        <div>
          <img src={strengthIcon} alt="Strength Icon" style={{ width: 15, position: "relative", top: 3 }} />{" "}
          {row.strength}
        </div>
      ),
    },
  ]);

  useEffect(() => {
    const newFilters = getGuildFilterFromParams(queryParams, true);
    setGuildFilters({ ...newFilters });
  }, [queryParams]);

  const debouncePushWalletId = useCallback(debounce(pushWalletToUri, 500), []);

  function pushWalletToUri(walletId: string) {
    setQueryParams({
      ...queryParams,
      id: walletId,
    });
  }

  useEffect(() => {
    setPage(guildFilters.page);
    fetchGuilds(guildFilters);
  }, [address, guildFilters, domains]); // eslint-disable-line

  async function fetchGuilds(filter: GuildFilterType) {
    let filteredMembers: GuildMember[] = [];
    const sortData = SORT_DATA[filter.sortBy];
    const variables: GuildQuery = {
      limit: LIMIT.MEMBERS,
      orderBy: sortData.key,
      owner: address,
      orderDirection: sortData.direction,
      itemPerPage: LIMIT.MEMBERS,
      page: filter.page,
    };

    try {
      let result;
      result = await fetchGuildMembersFromApi(id, filter, variables);
      let members = result?.list ?? [];
      filteredMembers = _.sortBy(members, "position");
      getAddresses(filteredMembers, [], true);
      setTotal(result?.total ?? 0);
    } catch (e) {
      console.log(e);
    }
    setGuildMembers(
      filteredMembers.map((member: GuildMember) => ({ ...member, domainAddress: domains ? domains[member.id] : "" }))
    );
  }

  useEffect(() => {
    if (total && total < LIMIT.MEMBERS) {
      handleChangePage(1);
    }

    const newTotalPage = Math.ceil(total / LIMIT.MEMBERS);
    setTotalPage(newTotalPage);
    if (newTotalPage && page > newTotalPage) {
      handleChangePage(1);
    }
  }, [total]); // eslint-disable-line

  function onClickPaging(page: number) {
    if (guildMembersRef && guildMembersRef.current) {
      // @ts-ignore
      guildMembersRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    handleChangePage(page);
  }

  function handleChangePage(page: number) {
    setQueryParams({
      ...queryParams,
      page: page.toString(),
    });
  }

  const handleKick = (guildId: number, guildMembers: GuildMember[]) => {
    dispatch(
      kickMembers(
        guildId,
        guildMembers.map((member: GuildMember) => {
          return member.id;
        }),
        () => setSelectedMembers([])
      )
    );
  };

  const handleInputChange = (e: any) => {
    setWalletInput(e.target.value);
    debouncePushWalletId(e.target.value);
  };

  function renderNameField(label: string) {
    return (
      <StylesProvider injectFirst>
        <TextField
          value={walletInput}
          className={`input guild-details__input`}
          label={label}
          type={"text"}
          placeholder="0x..."
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          onChange={handleInputChange}
        />
      </StylesProvider>
    );
  }

  function handleSelectionChange(rows) {
    const displayedIds = guildMembers.map((result) => result.id);
    const selectedMembersNotDisplayed = selectedMembers.filter((selectedRow) => {
      return !displayedIds.includes(selectedRow.id);
    });
    setSelectedMembers([...selectedMembersNotDisplayed, ...rows]);
  }

  return (
    <div className={`guild-details__table cm-table cm-table--guild ${isEmpty ? "cm-table--guild-empty" : ""} mt-3 `}>
      <div className="flex-center-between">
        {renderNameField("Search Address")}
        <div className={`request-btn ${selectedMembers.length === 0 ? "hidden" : ""}`}>
          <div className="btn btn-small disabled">Promote</div>
          <div className="btn btn--red btn-small" onClick={() => handleKick(+id, selectedMembers)}>
            Kick
          </div>
        </div>
      </div>
      <GuildTables
        isOwner={isOwner}
        columns={columns}
        data={guildMembers.map((row) =>
          selectedMembers.find((selected) => selected.id === row.id) ? { ...row, tableData: { checked: true } } : row
        )}
        emptyMessage={"No Members Yet"}
        handleSelectionChange={handleSelectionChange}
        isEmpty={isEmpty}
      />
      {totalPage > 1 && (
        <div className="market__pagination mt-5">
          <Pagination
            count={totalPage}
            page={page}
            onChange={(_, val) => onClickPaging(val)}
            variant="outlined"
            color="primary"
          />
        </div>
      )}
    </div>
  );
}
