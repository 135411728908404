import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import ENV from "src/app/configs/env";
import AddressInputGroup from "src/app/components/Commons/AddressInputGroup";
import { transferGuildOwnership } from "src/app/actions/guildAction";
import { isAddress } from "src/app/utils/helpers";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { GuildMember } from "src/app/types/guild";

type GuildOwnershipTransferModalProps = {
  guildId: string;
  members: GuildMember[];
};

export default function GuildOwnershipTransferModal(props: GuildOwnershipTransferModalProps) {
  const dispatch = useDispatch();
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.GUILD);
  const { address } = useSelector((state: any) => state.account);

  const [receiveAddr, setReceiveAddr] = useState("");
  const [invalidAddressError, setInvalidAddressError] = useState("");
  const [receiverInput, setReceiverInput] = useState("");

  function handleSetReceiverAddress(value: string) {
    setReceiveAddr(value);
  }
  
  function handleAddressChange(e: any) {
    setInvalidAddressError("");
    setReceiverInput(e.target.value);
  }

  const onSubmit = () => {
    if (needApprove) {
      sendApproveTx();
      return;
    }
    if (!isAddress(receiveAddr)) {
      setInvalidAddressError("Invalid BSC address.");
      return;
    }
    if (receiveAddr.toLowerCase() === address.toLowerCase()) {
      setInvalidAddressError("The receiving address cannot be the same as your current address.");
      return;
    }
    if (!props.members.some((member) => receiveAddr.toLowerCase() === member.id)) {
      setInvalidAddressError("This address is not one of the guild members.");
      return;
    }

    dispatch(transferGuildOwnership(address, receiveAddr, +props.guildId, () => modalService.close()));
  };

  function renderTransferContent() {
    return (
      <>
        <AddressInputGroup
          value={receiverInput}
          handleChange={handleAddressChange}
          setAddress={handleSetReceiverAddress}
          error={invalidAddressError}
          label="New Guild Master Address"
        />
        <div className="warning-text mt-3">You can only transfer guild ownership to a member of the guild.</div>
      </>
    );
  }

  return (
    <BasicModalContent
      customTitle="Transfer Guild Ownership"
      onSubmit={onSubmit}
      submitText={needApprove ? "Approve" : "Confirm"}
      content={renderTransferContent()}
    />
  );
}
