import React, { useState, useRef, MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { Button, Paper, MenuList, MenuItem, Grow, ClickAwayListener, Popper } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { ACTIONS } from "src/app/configs/constants";
import { renderBackBtn } from "src/app/utils/renderHelpers";
import { Land } from "src/app/types/land";

type LandHeaderButtonsProps = {
  land: Land;
  onOpenPopup: any;
  isOwner: boolean;
};

export default function LandHeaderButtons(props: LandHeaderButtonsProps) {
  const { land, onOpenPopup, isOwner } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(event: MouseEvent<EventTarget>) {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }

  function onSelectDropdown(event: MouseEvent<EventTarget>, type?: number) {
    handleClose(event);
    if (type) onOpenPopup(type);
  }

  function renderButton() {
    if (isOwner) {
      return (
        <>
          <div className="btn" onClick={() => onOpenPopup(ACTIONS.LIST_LAND)}>
            Sell
          </div>
          <Button
            ref={anchorRef}
            variant="outlined"
            className="knight__btn-more bot-1"
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <MoreHorizIcon />
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={onSelectDropdown}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      {land.price && land.price !== 0 && (
                        <MenuItem onClick={(e: any) => onSelectDropdown(e, ACTIONS.DELIST_LAND)}>Delist</MenuItem>
                      )}
                      <MenuItem onClick={(e: any) => onSelectDropdown(e, ACTIONS.TRANSFER_LAND)}>Transfer</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      );
    }

    if (land.price) {
      return (
        <div className="btn" onClick={() => onOpenPopup(ACTIONS.BUY_LAND)}>
          Buy now
        </div>
      );
    }
  }

  return (
    <div className="knight__btn">
      <div>{renderBackBtn(history)}</div>
      <div>{renderButton()}</div>
    </div>
  );
}
