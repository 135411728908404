import React, { useEffect, useRef, useState } from "react";
import { useQueryString } from "use-route-as-state";
import KnightFilter from "src/app/components/Knight/KnightListFilter";
import KnightContent from "src/app/components/Knight/KnightListContent";
import KnightToggle from "src/app/components/Knight/KnightListToggle";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import * as notFoundJson from "src/assets/lotties/404.json";
import { Lottie } from "@crello/react-lottie";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";
import { getHeroFilterFromParams } from "src/app/utils/filterHelper";
import { Knight, KnightFilterType, KnightQuery, KnightStats } from "src/app/types/knight";
import { LIMIT, SORT_DATA } from "src/app/configs/constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchKnightFromApi } from "src/app/services/api/faralandService";
import Pagination from "@material-ui/lab/Pagination";
import { queryOfferFromSubgraph, queryStakeFromSubgraph } from "src/app/services/api/subgraphService";
import useUpdateEffect from "src/app/hooks/useUpdateEffect";
import { createKnight, updateKnightAttributesFromApi } from "src/app/factories/knightFactory";
import { knightRefresh } from "src/app/actions/knightAction";

interface KnightListProps {
  showOwned: boolean;
  showStaked: boolean;
  showOffering: boolean;
  redirectToDetails: boolean;
  isStakingPage?: boolean;
  isHeroBurn?: boolean;
}

export default function KnightList(props: KnightListProps) {
  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.account);
  const knightListRef = useRef(null);
  const [queryParams, setQueryParams] = useQueryString();
  const { refresh } = useSelector((state: any) => state.knight);

  const [heroFilters, setHeroFilters] = useState<KnightFilterType>(getHeroFilterFromParams(queryParams));
  const [knights, setKnights] = useState<Knight[]>([]);
  const [knightStatsMap, setKnightStatsMap] = useState(new Map<number, KnightStats>());
  const [isLoading, setLoading] = useState(false);
  const [showConnectWalletError, setShowConnectWalletError] = useState(heroFilters.owned && !address);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useUpdateEffect(() => {
    setHeroFilters(getHeroFilterFromParams(queryParams));
  }, [queryParams]);

  useEffect(() => {
    setShowConnectWalletError(heroFilters.owned && !address);
  }, [heroFilters.owned, address]);

  useEffect(() => {
    setPage(heroFilters.page);
    fetchKnights(heroFilters);

    if (refresh) {
      setPage(heroFilters.page);
      fetchKnights(heroFilters);
      return;
    }
  }, [address, heroFilters, refresh]); // eslint-disable-line

  useEffect(() => {
    if (total && total < LIMIT.HEROES) {
      handleChangePage(1);
    }

    const newTotalPage = Math.ceil(total / LIMIT.HEROES);
    setTotalPage(newTotalPage);

    if (newTotalPage && page > newTotalPage) {
      handleChangePage(1);
    }
  }, [total]); // eslint-disable-line

  async function fetchKnights(filter: KnightFilterType) {
    let filteredKnights: Knight[] = [];
    const sortData = SORT_DATA[filter.sortBy];
    const variables: KnightQuery = {
      limit: LIMIT.HEROES,
      orderBy: sortData.key,
      orderDirection: sortData.direction,
      owner: address,
      itemPerPage: LIMIT.HEROES,
      page: filter.page,
    };

    setLoading(true);

    try {
      let result;

      if (filter.offering) {
        result = await queryOfferFromSubgraph(variables);
      } else if (filter.staked) {
        result = await queryStakeFromSubgraph(variables);
      } else {
        result = await fetchKnightFromApi(filter, variables);
      }
      filteredKnights = (result?.list ?? []).map((knight: any) => createKnight(knight));
      if (!filter.staked && !filter.offering) {
        setKnightStatsMap(updateKnightAttributesFromApi(result, filteredKnights));
      }

      setTotal(result?.total ?? 0);
    } catch (e) {
      console.log(e);
    }

    setKnights(filteredKnights);
    dispatch(knightRefresh(false));

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  function onClickPaging(page: number) {
    if (knightListRef && knightListRef.current) {
      // @ts-ignore
      knightListRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    handleChangePage(page);
  }

  function handleChangePage(page: number) {
    setQueryParams({
      ...queryParams,
      page: page.toString(),
    });
  }

  return (
    <div className="market" ref={knightListRef}>
      <KnightFilter isHeroBurn={props.isHeroBurn} />

      <div className="market__content">
        <KnightToggle
          isLoading={isLoading}
          showOwned={props.showOwned}
          showStaked={props.showStaked}
          showOffering={props.showOffering}
        />
        {isLoading && (
          <Lottie
            className="market__loading market__loading--marketplace slide-up"
            config={getAnimatedJsonOptions(loadingJson)}
          />
        )}
        {!isLoading && (showConnectWalletError || knights.length === 0) && (
          <div className="align-center slide-up">
            <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
            <div className="market__404-title">{showConnectWalletError ? 'Please connect your wallet first' : 'No Hero found with your searching queries'}.</div>
          </div>
        )}
        {!isLoading && knights.length !== 0 && !showConnectWalletError && (
          <div className="market__items slide-up-slow">
            {knights.map((knight: Knight, index: number) => {
              return (
                <KnightContent
                  key={index}
                  knight={knight}
                  isStakingPage={props.isStakingPage}
                  knightStats={knightStatsMap[knight.id]}
                  isHeroBurn={props.isHeroBurn}
                />
              );
            })}
          </div>
        )}
        {totalPage > 1 && !showConnectWalletError && !heroFilters.staked && (
          <div className="market__pagination">
            <Pagination
              count={totalPage}
              page={page}
              onChange={(_, val) => onClickPaging(val)}
              variant="outlined"
              color="primary"
            />
          </div>
        )}
      </div>
    </div>
  );
}
