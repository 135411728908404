import BigNumber from "bignumber.js";
import { STAKING_CONFIG } from "src/app/configs/constants";

/**
 * 1 = bigger; -1 = smaller; 0 = equal
 */
export function compareNumbers(firstNumber: string | number, secondNumber: string | number): number {
  const firstBigNumber = new BigNumber(firstNumber);
  return firstBigNumber.comparedTo(secondNumber);
}

export function multiplyNumbers(firstNumber: number | string, secondNumber: number | string) {
  const firstBigNumber = new BigNumber(firstNumber);
  const secondBigNumber = new BigNumber(secondNumber);

  return firstBigNumber.multipliedBy(secondBigNumber).toString();
}

export function divideNumbers(firstNumber: number | string, secondNumber: number | string) {
  const firstBigNumber = new BigNumber(firstNumber);
  const secondBigNumber = new BigNumber(secondNumber);

  return firstBigNumber.div(secondBigNumber).toString();
}

export function plusNumbers(firstNumber: number | string, secondNumber: number | string) {
  const firstBigNumber = new BigNumber(firstNumber);
  const secondBigNumber = new BigNumber(secondNumber);

  return firstBigNumber.plus(secondBigNumber).toString();
}

export function minusNumbers(firstNumber: number | string, secondNumber: number | string) {
  const firstBigNumber = new BigNumber(firstNumber);
  const secondBigNumber = new BigNumber(secondNumber);

  return firstBigNumber.minus(secondBigNumber).toString();
}

export function calculateAPR(tokenPrice: number, totalStakedAmount: number, lpPrice?: number) {
  const price = lpPrice ? lpPrice : tokenPrice;
  const rewardPerSecond = !lpPrice ? STAKING_CONFIG.SINGLE.REWARD_RATE : STAKING_CONFIG.LIQUIDITY.REWARD_RATE;
  const rewardPerYear = rewardPerSecond * 31536000 * tokenPrice;

  return (rewardPerYear / (totalStakedAmount * price)) * 100;
}