import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { EquipmentDismantle, Item, UserItem } from "src/app/types/equipment";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import useDismantleData from "src/app/hooks/useDismantleData";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { DISMANTLE_REWARDS } from "src/app/configs/equipment/dismantle";
import arrowDown from "src/assets/images/decors/arrow-down.png";
import useApprovalForAll from "src/app/hooks/useApprovalForAll";
import ENV from "src/app/configs/env";
import { dismantle } from "src/app/actions/equipmentAction";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { RARITY_LABEL } from "src/app/configs/constants";
import { Box } from "@material-ui/core";
import { renderRarityFilter, renderTierFilter } from "src/app/utils/renderHelpers";

interface ChoosingEquipmentFilter {
  name: string;
  tier: number;
  rarity: string;
}

type BatchDismantleModalProps = {
  userItems: UserItem[];
};

export default function BatchDismantleModal(props: BatchDismantleModalProps) {
  const dispatch = useDispatch();
  const { userItems } = props;
  const [error, setError] = useState("");
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [dismantleMaterials, setDismantleMaterials] = useState<EquipmentDismantle[]>([]);
  const [needApprovalForAll, sendApproveForAllTx] = useApprovalForAll(
    ENV.CONTRACT.EQUIPMENT,
    ENV.CONTRACT.EQUIPMENT_DISMANTLE
  );
  const [filteredItems, setFilteredItems] = useState<UserItem[]>([...userItems]);
  const [filterObj, setFilterObj] = useState<ChoosingEquipmentFilter>({ name: "", tier: 1, rarity: "" });
  const debounceFilterItems = useCallback(_.debounce(filterItemsByName, 500), [filteredItems]);

  const { renderDismantleMaterials } = useDismantleData(dismantleMaterials);

  useEffect(() => {
    if (!userItems) {
      setFilteredItems([]);
    } else {
      const result = userItems.filter((value: UserItem) => {
        return (
          value.item.rarity < 2 &&
          value.item.displayName.toLowerCase().includes(filterObj.name.toLowerCase()) &&
          EQUIPMENT[value.id].tier === filterObj.tier &&
          RARITY_LABEL[value.item.rarity].includes(filterObj.rarity)
        );
      });
      setFilteredItems(result);
    }
  }, [userItems, filterObj]); // eslint-disable-line

  function filterItemsByName(name: string) {
    setFilterObj({ ...filterObj, name: name });
  }

  function filterItemsByTier(tier: number) {
    setFilterObj({ ...filterObj, tier: tier });
  }

  function filterItemsByRarity(value: any) {
    setFilterObj({ ...filterObj, rarity: value === "All" ? "" : value });
  }

  function resetSelectedItems() {
    setSelectedItemIds([]);
    setDismantleMaterials([]);
  }

  function selectItem(itemId: number) {
    setError("");
    const selectedAmount = selectedItemIds.filter((id) => id === itemId).length;
    const item = filteredItems.find((item) => {
      return item.id === itemId;
    });
    if (item && item.available <= selectedAmount) return;
    const selectedItem: Item = EQUIPMENT[itemId];
    setSelectedItemIds([...selectedItemIds, itemId]);
    setDismantleMaterials([...dismantleMaterials, DISMANTLE_REWARDS[`${selectedItem.rarity}-${selectedItem.tier}`]]);
  }


  function deselectItem(itemId: number) {
    const index = selectedItemIds.findIndex((id) => id === itemId);

    if (index !== -1) {
      setSelectedItemIds([...selectedItemIds.slice(0, index), ...selectedItemIds.slice(index + 1)]);
      setDismantleMaterials([...dismantleMaterials.slice(0, index), ...dismantleMaterials.slice(index + 1)]);
    }
  }

  function selectAll() {
    const items = filteredItems.map((item: UserItem) => {
      return Array.from(Array(item.available).keys()).map(() => {
        return item.id;
      });
    });
    const selectedItems: Item[] = items.flat().map((id) => EQUIPMENT[id]);
    const selectedDismantleMaterials: EquipmentDismantle[] = selectedItems.map(
      (item) => DISMANTLE_REWARDS[`${item.rarity}-${item.tier}`]
    );
    setSelectedItemIds(items.flat());
    setDismantleMaterials(selectedDismantleMaterials);
  }

  function handleConfirm() {
    if (needApprovalForAll) {
      return sendApproveForAllTx();
    }
    if (selectedItemIds.length === 0) {
      setError("Select at least 1 item to dismantle");
    } else {
      const amount: number[] = Object.values(
        selectedItemIds.reduce((prev, cur) => {
          prev[cur] = (prev[cur] || 0) + 1;
          return prev;
        }, {})
      );
      dispatch(dismantle(_.sortBy(_.uniq(selectedItemIds)), amount, dismantleMaterials, () => modalService.close()));
    }
  }

  return (
    <BasicModalContent
      onSubmit={handleConfirm}
      submitText={needApprovalForAll ? "Approve NFT" : "Confirm"}
      content={
        <div>
          <div className="item-exchange mt-5">
            <div className="item-exchange__wrapper">
              <div className="item-exchange__header">
                <div className="mb-2">
                  <div className="item-exchange__title mb-1">Items ({selectedItemIds.length})</div>
                  <div className="flex-center-start">
                    <div className="btn btn--tiny" onClick={resetSelectedItems}>
                      Reset
                    </div>
                    <div className="btn btn--tiny ml-2" onClick={selectAll}>
                      Select All
                    </div>
                  </div>
                </div>
                <div className="icon-input">
                  <SearchIcon className="icon-input__icon" />
                  <input
                    className="icon-input__input"
                    onChange={(e) => debounceFilterItems(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </div>
              </div>
              <Box display="flex" justifyContent="space-between" alignContent="center" className="mt-4" gridGap={1}>
                {renderRarityFilter(filterObj, filterItemsByRarity, true)}
                {renderTierFilter(filterObj, filterItemsByTier)}
              </Box>
              <div className="item-exchange__storage nice-scroll nice-scroll--small nice-scroll--gray">
                {filteredItems.length === 0 && <div>No Item found...</div>}
                {filteredItems.map((userItem: UserItem, i: number) => {
                  const selectedAmount = selectedItemIds.filter((id) => id === userItem.id).length;
                  return (
                    <ItemIcon amount={selectedAmount} key={i} item={userItem.item} layer={2} onClick={selectItem} onRightClick={deselectItem}/>
                  );
                })}
              </div>
            </div>
            <div className="flex-column-center">
              {selectedItemIds.length > 0 && <img src={arrowDown} alt="Arrow" className="mt-2" />}
              {renderDismantleMaterials()}
            </div>
            {error && <div className="error-text">{error}</div>}
          </div>
        </div>
      }
    />
  );
}
