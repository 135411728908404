import femaleHumanChar from "src/assets/images/icons/characters/female_human.png";
import maleHumanChar from "src/assets/images/icons/characters/male_human.png";
import maleHumanChibi from "src/assets/images/icons/races/human-male.png";
import humanBg from "src/assets/images/icons/backgrounds/humanbg.png";
import femaleOrcChar from "src/assets/images/icons/characters/female_orc.png";
import maleOrcChar from "src/assets/images/icons/characters/male_orc.png";
import maleOrcChibi from "src/assets/images/icons/races/orc-male.png";
import orcBg from "src/assets/images/icons/backgrounds/orcbg.png";
import femaleAngelChar from "src/assets/images/icons/characters/female_angel.png";
import femaleAngelChibi from "src/assets/images/icons/races/angel-female.png";
import angelBg from "src/assets/images/icons/backgrounds/angelbg.png";
import maleDragonChar from "src/assets/images/icons/characters/male_dragon.png";
import maleDragonChibi from "src/assets/images/icons/races/dragon-male.png";
import dragonBg from "src/assets/images/icons/backgrounds/dragonbg.png";
import maleDemonChar from "src/assets/images/icons/characters/male_demon.png";
import femaleDemonChar from "src/assets/images/icons/characters/female_demon.png";
import maleDemonChibi from "src/assets/images/icons/races/demon-male.png";
import demonBg from "src/assets/images/icons/backgrounds/demonbg.png";
import femaleElfChar from "src/assets/images/icons/characters/female_elf.png";
import maleElfChar from "src/assets/images/icons/characters/male_elf.png";
import femaleElfChibi from "src/assets/images/icons/races/elf-female.png";
import elfBg from "src/assets/images/icons/backgrounds/elfbg.png";
import femaleFairyChar from "src/assets/images/icons/characters/female_fairy.png";
import maleFairyChar from "src/assets/images/icons/characters/male_fairy.png";
import femaleFairyChibi from "src/assets/images/icons/races/fairy-female.png";
import fairyBg from "src/assets/images/icons/backgrounds/fairybg.png";

export const heroes = [
  {
    img: "/static/marketplace/Male_Human_Armor.png",
    frontImg: femaleHumanChar,
    behindImg: maleHumanChar,
    thumbnail: maleHumanChibi,
    background: humanBg,
    race: "Human",
    heroRate: "50%",
    jobs: [
      {
        name: "Paladin",
        desc: "Paladins take on the position of a Tanker with a defensive and supporting skill set. Paladins will be a reliable shield for other allies. The typical equipment for Paladins is a shield, which maximizes the hero's defense.",
        skills: [
          {
            name: "Heroic Charge",
            desc: "Paladin dashes to an ally's tile, increases Physical Defense and Magical Defense for Paladin and the ally.",
          },
          {
            name: "Holy Defiance Aura",
            desc: "Holy Defiance Aura increases Physical Defense and Magical Defense for all allies based on Paladin's original Physical Defense and Magical Defense.",
          },
          {
            name: "Holy Judgement",
            desc: "Paladin strikes an enemy, deals Pure Damage based on current Physical Damage.",
          },
        ],
      },
      {
        name: "Blade Dancer",
        desc: "Blade Dancers are of a class with high mobilitty and great physical damage, making them suitable for the assassin role in a team. Their mobility enables them to approach heroes in the back of the enemy's formation. The signature weapon for this class is the dual-dagger.",
        skills: [
          {
            name: "Fury Strike",
            desc: "Deals a critical strike to an enemy and reduces the enemy's Magical/Physical Damage.",
          },
          {
            name: "Evasion",
            desc: "Passively increases Evasion Chance.",
          },
          {
            name: "Shadow Walk",
            desc: "Teleports to a selected tile and gets bonus Physical/Magical Defense.",
          },
        ],
      },
      {
        name: "Spell Singer",
        desc: "Spell Singers possess magical powers passed down from the ancients, which enable them to master the basic elements. This hero class can take on the nuker/disabler role in a team with a spell-heavy skill set. Preferrably equipped with a medium-ranged Staff",
        skills: [
          {
            name: "Magic Ball",
            desc: "Blasts a magic ball inflicting Magical Damage and reduces the enemy's 1 tile movement.",
          },
          {
            name: "Wisdom Aura",
            desc: "Wisdom Aura increases team's INT based on the Spell Singer's original INT.",
          },
          {
            name: "Time Warp",
            desc: "Spell Singer creates a Time Warp relocating an ally from the current tile to a selected tile.",
          },
        ],
      },
    ],
  },
  {
    img: "/static/marketplace/Base_Male_Orc.png",
    frontImg: femaleOrcChar,
    behindImg: maleOrcChar,
    thumbnail: maleOrcChibi,
    background: orcBg,
    heroRate: "24.6%",
    race: "Orc",
    jobs: [
      {
        name: "Warrior",
        desc: "Orc Warriors are powerful and fearsome with great defensive abilities proven effective against enemies. Fierce and relentless, they'll fight until their last breath.",
        skills: [
          {
            name: "Skull Bash",
            desc: "Skull Bash deals bonus Physical Damage and applies Weakness effect to an enemy for 3 turns.",
          },
          {
            name: "War Shout",
            desc: "War Shout increases Physical and Magical Damage for all ally heroes in 3 turns.",
          },
          {
            name: "Glory Stance",
            desc: "Glory Stance removes 99% HP of Warrior to gain immortality and bonus of Physical Damage in 1 turn.",
          },
        ],
      },
      {
        name: "Berserker",
        desc: "Berserkers are the most valiant warriors of the Orc race. When in full power, they become unquenchably bloodthirsty and unstoppably dangerous. Stay alert on confrontations with Orc Berserkers, since they have exceedingly dangerous counter attacks.",
        skills: [
          {
            name: "Provoke",
            desc: "Berserker provokes the enemy to do normal attack to Berserker in the enemy's next turn. Berserker's Physical Defense is increased for 1 turn.",
          },
          {
            name: "Death's Fury",
            desc: "Normal attack increases Physical Damage dealt to an enemy based on the enemy's HP loss.",
          },
          {
            name: "Brutal Strength",
            desc: "Increases Critical Strike Damage.",
          },
        ],
      },
      {
        name: "Warlord Shaman",
        desc: "Perceived as enigmatic wizards of the Orc race, Warlord Shamans possess exceptional physical durability and a unique set of magical skills. The special spells of Warlock Shaman become the enemies’ nightmares whenever encountered.",
        skills: [
          {
            name: "Flame Shield",
            desc: "Casts a Flame Shield on an ally or yourself that increases Physical Defense based on current INT. If an enemy performs a normal attack on the hero, the Flame Shield will return Pure Damage to the attacker.",
          },
          {
            name: "Chain of Wounds",
            desc: "Chains 2 enemies, if one of them gets damaged, the other also gets damaged.",
          },
          {
            name: "Arcanum Totem",
            desc: "Lays a totem spreading over 3 surrounding tiles, the ally standing on that gets increased Physical and Magical Damage. Totem survives 1 attack and lasts for 3 turns.",
          },
        ],
      },
    ],
  },
  {
    img: "/static/marketplace/Female_Angel_Default.png",
    centerImg: femaleAngelChar,
    thumbnail: femaleAngelChibi,
    background: angelBg,
    heroRate: "0.2%",
    race: "Angel",
    jobs: [
      {
        name: "Vanadis",
        desc: "These knights from heaven proudly boast their distinctive features of armor sets and large builds. Their stamina is beyond imagination, they are great allies in every journey.",
        skills: [
          {
            name: "Clarity",
            desc: "Vanadis counter attack has a chance to recover mana pool.",
          },
          {
            name: "Enlighten Protection",
            desc: "Enlighten Protection passively increases Vanadis Physical Defense and Magical Defense gained from the items.",
          },
          {
            name: "Light of Relief Aura",
            desc: "Light of Relief Aura increases Physical Defense and Magical Defense for your team.",
          },
        ],
      },
      {
        name: "Celestial",
        desc: "Angels who are capable of controlling others’ spirits. With their extraordinary psychic powers, they can enchant enemies into the loss of self-control and under their complete command.",
        skills: [
          {
            name: "Dream: Sleep",
            desc: "Sleeps the enemy or ally. Sleeping hero can not use skill or attack or move and wakes up when get damaged. Ally get healed when wakes up.",
          },
          {
            name: "Dream Maker",
            desc: "Dream Maker passively makes Celestial normal attack sleep the enemy for 1 turn.",
          },
          {
            name: "Mild",
            desc: "Celestial creates a strike that deals Magical Damage. This skill does not wake sleeping enemy up.",
          },
        ],
      },
      {
        name: "Valkyrie",
        desc: "With a pure heart and sheer magic, Valkyrie's mission is to help the beings of Faraland escape the threat of Beelzebul. They acquire the ability to resurrect the dead and grant them powers",
        skills: [
          {
            name: "Vigor",
            desc: "Valkyrie places Vigor on self or ally which recovers HP equals to current INT for 3 turns.",
          },
          {
            name: "Radiant Aura",
            desc: "Radiant Aura increases INT for the team based on Valkyrie INT.",
          },
          {
            name: "Lucent Heart",
            desc: "Angel rebirths when get killed in next turn.",
          },
        ],
      },
    ],
  },
  {
    img: "/static/marketplace/Dragon_Male_Default.png",
    centerImg: maleDragonChar,
    thumbnail: maleDragonChibi,
    background: dragonBg,
    heroRate: "1%",
    race: "Dragonborn",
    jobs: [
      {
        name: "Immortal",
        desc: "Very few people have ever caught a glimpse of these Dragons. As creatures present long ago in Faraland, their sturdy scales breed great defense. It remains daunting to defeat these ancient warriors for they are suffused with exquisite healing and regeneration prowess.",
        skills: [
          {
            name: "Spin Slash",
            desc: "Deploys the weapon to make a Spin Slash dealing Physical Damage to all enemies within 6 surrounding tiles.",
          },
          {
            name: "Divine Aura",
            desc: "Divine Aura increases HP for the team equal to 5% of Immortal max HP.",
          },
          {
            name: "Rebirth",
            desc: "Immortal turns into an egg upon death. After 1 turns, the egg hatches into Immortal with a small amount of HP. The egg gets broken after 2 normal attacks.",
          },
        ],
      },
      {
        name: "Sky Predator",
        desc: "As the name implies, this class effectively harnesses the Dragonborn's ability to fly. With a large range of movement and the tenacity to fly over any terrain, they can approach and destroy the target in the blink of an eye.",
        skills: [
          {
            name: "Dragon Feast",
            desc: "Sky Predator bites enemy, inflicts Physical Damage then apply Bleeding which deal Physical Damage over time, recover HP of the Sky Predator.",
          },
          {
            name: "Barbarous Wings",
            desc: "Barbarous Wings removes all negative buff and recovers HP for Sky Predator.",
          },
          {
            name: "Dragon Fury",
            desc: "Dragon Fury increases Sky Predator Physical Damage for 3 turns.",
          },
        ],
      },
      {
        name: "Wraith",
        desc: "Possessing the mysterious spells of the Dragonborn, Wraiths are omnipotent mages you can ever find. Wraiths have a formidable physical strength and an impressive ability to manipulate magics. Scepters and spellbooks are the very signature weapons of this class.",
        skills: [
          {
            name: "Dark Theurge",
            desc: "Dark Theurge increases a large amount of Magical Damage, costs 5% max HP per turn for 6 turns.",
          },
          {
            name: "Breath of Fire",
            desc: "Breathes the ancient fire that inflicting Magical Damage then burning the enemy for 2 turns.",
          },
          {
            name: "Bloody Thirst",
            desc: "Increases Wrath Life Steal for each 20% of missing HP.",
          },
        ],
      },
    ],
  },
  {
    img: "/static/marketplace/Male_Human_Armor.png",
    frontImg: maleDemonChar,
    behindImg: femaleDemonChar,
    thumbnail: maleDemonChibi,
    background: demonBg,
    race: "Demon",
    heroRate: "0.2%",
    jobs: [
      {
        name: "Hell Bringer",
        desc: "Overruling and controlling creatures from hell, Hell Bringers can summon mercenaries to serve them. Clustering in collective herds, they spread morbid fear to anyone happening to encounter them.",
        skills: [
          {
            name: "Raise Dead Warrior",
            desc: "Summon a skeleton to combat, skeleton deal 50% of Physical Damage based on the Demon Physical Damage. If a skeleton is already called, this skill will call new one and destroy the old skeleton.",
          },
          {
            name: "Enhance Minion",
            desc: "Enhance Minion increases Hell Bringer's summoned creature Physical Damage and HP.",
          },
          {
            name: "Explosion Minion",
            desc: "When the skeleton dies, he explodes and inflicts Physical Damage around.",
          },
        ],
      },
      {
        name: "Necropolis",
        desc: "Being demons that absorb the souls of the ill-fated, Necropolises devour corpses to bulk up their strength. They drain their enemies’ energy and finish off their prey outright with a terrifying curse.",
        skills: [
          {
            name: "Shadow Conjuration",
            desc: "Necropolis uses Shadow Conjuration to curse the enemy. Shadow Conjuration inflicts Magical Damage per turn and heals Necropolis HP, last for 4 turns.",
          },
          {
            name: "Terror Clap",
            desc: "Terror Clap can only cast on the enemy under 15% HP, kills it instantly and recovers Necropolis HP.",
          },
          {
            name: "Apostle of Decay",
            desc: "Necropolis passively collects souls when an ally or enemy dies. The soul heals Necropolis HP and increases movement tile by 1.",
          },
        ],
      },
      {
        name: "Soul Master",
        desc: "Possessing the magic that steals the enemies’ soul, Soul Masters can turn creatures they killed into their mercenaries. These demons can also clone their enemies and control them to serve their master.",
        skills: [
          {
            name: "Malediction",
            desc: "Curses the enemy, decreases current HP per turn.",
          },
          {
            name: "Hallucination",
            desc: "Creates a clone of the selected enemy with 40% HP, last 3 turns. Clone can attack, move but cannot use spell.",
          },
          {
            name: "Malediction: Ravage",
            desc: "Malediction: Ravage increases Physical Damage and Magical Damage to all the enemy heroes that converted to your ally by Malediction: Conversion.",
          },
        ],
      },
    ],
  },

  {
    img: "/static/marketplace/Male_Human_Armor.png",
    frontImg: femaleElfChar,
    behindImg: maleElfChar,
    thumbnail: femaleElfChibi,
    background: elfBg,
    race: "Elf",
    heroRate: "20%",
    jobs: [
      {
        name: "Wind Hunter",
        desc: "Wind Hunters take up the pivotal position in the line-up with long-ranged attacks and massive Physical Damage. Due to scant mobility, they rely on other heroes in the squad for aids in unleashing full power. Their signature weapon are the Bow and Arrows.",
        skills: [
          {
            name: "Teardown Arrow",
            desc: "Wind Hunter shoots a critical strike arrow that ignores enemy's Physical Defense.",
          },
          {
            name: "Swift Step",
            desc: "Wild Hunter casts Swift Step on an ally or self. Swift Step increases tile movement for several turns.",
          },
          {
            name: "Swiftness Aura",
            desc: "Increases the team's Speed based on hero's current Speed.",
          },
        ],
      },
      {
        name: "Forest Ghost",
        desc: "As elite warriors, Forest Ghosts possess great agility and combat skills. Their forte is unrivaled stealth skills. By wielding dual blades for dexterity and maximum damage, they make any enemies encountered have no chance to escape.",
        skills: [
          {
            name: "Phantom Strike",
            desc: "Dashes to an enemy and performs a strike, deals Physical Damage based on how far Forest Ghost dashes.",
          },
          {
            name: "Agility Aura",
            desc: "Agility Aura increases AGI for team based on 10% of the hero's original AGI.",
          },
          {
            name: "Dazzle Slash",
            desc: "Strikes an enemy with melee weapons, deals bonus Physical Damage and applies Disarm.",
          },
        ],
      },
      {
        name: "Nature Keeper",
        desc: "Descended from hunters lodging in the impenetrable woods, Nature Keepers are masters of traps. They lurk in bushes to stalk prey, yet even little mistakes in movement can lead to disastrous hazards. Their bush-fighting and surprise attacks are conspicuous.",
        skills: [
          {
            name: "Tanglevine",
            desc: "Puts a trap at a selected tile, an enemy touching it is dealt with Physical Damage and gets tied in 1 turn. The trap is hidden and lasts for 2 turns.",
          },
          {
            name: "Latent Venom Arrow",
            desc: "Nature Keeper's normal attack now applies Poison, deals Pure Damage based on Physical Damage. Poison lasts 3 turns and cannot stack. A normal attack on the trapped enemy grants bonus Physical Damage.",
          },
          {
            name: "Even Swiftness",
            desc: "Even Swiftness increases Evasion Chance for the team.",
          },
        ],
      },
    ],
  },

  {
    img: "/static/marketplace/Male_Human_Armor.png",
    frontImg: femaleFairyChar,
    behindImg: maleFairyChar,
    thumbnail: femaleFairyChibi,
    background: fairyBg,
    race: "Fairy",
    heroRate: "4%",
    jobs: [
      {
        name: "Enchantress",
        desc: "As descendants of the jungle, Enchantresses inherited healing magics from mother nature. Every squad wishes to have an Enchantress due to their healing aids. These little spirits always use their signature wand.",
        skills: [
          {
            name: "Seed Of Healing",
            desc: "Seed of Healing heals ally or self HP for several turns based on current INT, the last turn gets the doubled amount of healing.",
          },
          {
            name: "Arcane Transmit",
            desc: "Casts a buff on an ally or self, increases Physical Damage and Magical Damage based on current INT.",
          },
          {
            name: "Seed of Healing Enhance",
            desc: "Seed Of Healing now gives Physical Defense and Magical Defense based on Enchantress's INT.",
          },
        ],
      },
      {
        name: "Outlaw Wanderer",
        desc: "Let's not get tricked by their tiny builds, as Outlaw Wanderers are a strikingly agile and dangerous Fairy Class. Without hesitation in close combat, they wield daggers for maximum lightness when delivering assassinations, leaving all enemies behind with none surviving.",
        skills: [
          {
            name: "Doppelganger",
            desc: "Teleports to an enemy then strikes, gets bonus Physical Damage.",
          },
          {
            name: "Outlaw Reflexes",
            desc: "Outlaw Reflexes increases Evasion Chance for each nearby enemy. Gets double effect after casting Doppelganger for 1 turn.",
          },
          {
            name: "Outlaw Evasion",
            desc: "Outlaw Evasion heals Outlaw Wanderer some HP after evading successfully.",
          },
        ],
      },
      {
        name: "Thaumaturge",
        desc: "As vengeful spirits consumed with resentments, Thaumaturge's powers are spells that curse enemies. Like other fairies, they are very agile and intelligent. With a skill set that causes multiple adverse effects, they fill their enemies with abject horror in combat.",
        skills: [
          {
            name: "Shadow Words",
            desc: "Casts a shadow curse that deals Magical Damage and reduces the enemy’s tile movement for 2 turns.",
          },
          {
            name: "Shadow Words Corruption",
            desc: "Heals Thaumaturge based on how much Magical Damage deals by Shadow Words.",
          },
          {
            name: "Shadow Realm",
            desc: "Teleports to a selected tile within 5 tiles and becomes invisible for 1 turn.",
          },
        ],
      },
    ],
  },
];
