import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS, TOPIC } from "src/app/configs/constants";
import { _sendTx } from "src/app/sagas/index";
import { equipmentActionTypes } from "src/app/actions/equipmentAction";

function* roll(action: any): any {
  yield call(_sendTx, ACTIONS.ROLL_GACHA, action.payload, action.callbacks.onSuccess);
}

function* transfer(action: any): any {
  yield call(_sendTx, ACTIONS.TRANSFER_EQUIPMENT, action.payload, action.callbacks.onSuccess);
}

function* upgrade(action: any): any {
  yield call(_sendTx, ACTIONS.UPGRADE_EQUIPMENT, action.payload, action.callbacks.onSuccess);
}

function* listExchange(action: any): any {
  yield call(_sendTx, ACTIONS.LIST_EXCHANGE_EQUIPMENT, action.payload, action.callbacks.onSuccess);
}

function* cancelExchange(action: any): any {
  yield call(_sendTx, ACTIONS.CANCEL_EXCHANGE_EQUIPMENT, action.payload, action.callbacks.onSuccess);
}

function* takeExchangeOffer(action: any): any {
  yield call(_sendTx, ACTIONS.TAKE_EXCHANGE_OFFER, action.payload);
}

function* list(action: any): any {
  yield call(_sendTx, ACTIONS.LIST_EQUIPMENT, action.payload);
}

function* delist(action: any): any {
  yield call(_sendTx, ACTIONS.DELIST_EQUIPMENT, action.payload);
}

function* buy(action: any): any {
  yield call(_sendTx, ACTIONS.BUY_EQUIPMENT, action.payload);
}

function* offer(action: any): any {
  yield call(_sendTx, ACTIONS.OFFER_EQUIPMENT, action.payload);
}

function* cancelOffer(action: any): any {
  yield call(_sendTx, ACTIONS.CANCEL_OFFER_EQUIPMENT, action.payload);
}

function* takeOffer(action: any): any {
  yield call(_sendTx, ACTIONS.TAKE_OFFER_EQUIPMENT, action.payload);
}

function* batchTransfer(action: any): any {
  yield call(_sendTx, ACTIONS.BATCH_TRANSFER, action.payload, action.callbacks.onSuccess);
}

function* craft(action: any): any {
  yield call(_sendTx, ACTIONS.CRAFT_ITEM, action.payload, action.callbacks.onSuccess, "0", [TOPIC.ITEM_CRAFTED]);
}

function* levelUpBlacksmith(action: any): any {
  yield call(_sendTx, ACTIONS.LEVEL_UP_BLACKSMITH, action.payload, action.callbacks.onSuccess);
}

function* throwItemGacha(action: any): any {
  yield call(_sendTx, ACTIONS.THROW_ITEM_GACHA, action.payload, action.callbacks.onSuccess, "0", [
    TOPIC.JACKPOT_WON,
    TOPIC.GACHA_ITEM_RECEIVED,
  ]);
}

function* dismantle(action: any): any {
  yield call(_sendTx, ACTIONS.EQUIPMENT_DISMANTLE, action.payload, action.callbacks.onSuccess);
}

function* convertExpToBp(action: any): any {
  yield call(_sendTx, ACTIONS.CONVERT_EXP_TO_BP, action.payload, action.callbacks.onSuccess);
}

function* claimEquipment(action: any): any {
  yield call(_sendTx, ACTIONS.CLAIM_EQUIPMENT, action.payload, action.callbacks.onSuccess);
}

function* deposit(action: any): any {
  yield call(_sendTx, ACTIONS.EQUIPMENT_DEPOSIT, action.payload, action.callbacks.onSuccess);
}

function* withdraw(action: any): any {
  yield call(_sendTx, ACTIONS.EQUIPMENT_WITHDRAW, action.payload, action.callbacks.onSuccess);
}

export default function* equipmentSaga() {
  yield takeLatest(equipmentActionTypes.ROLL_GACHA, roll);
  yield takeLatest(equipmentActionTypes.TRANSFER, transfer);
  yield takeLatest(equipmentActionTypes.UPGRADE, upgrade);
  yield takeLatest(equipmentActionTypes.LIST, list);
  yield takeLatest(equipmentActionTypes.DELIST, delist);
  yield takeLatest(equipmentActionTypes.BUY, buy);
  yield takeLatest(equipmentActionTypes.OFFER, offer);
  yield takeLatest(equipmentActionTypes.CANCEL_OFFER, cancelOffer);
  yield takeLatest(equipmentActionTypes.TAKE_OFFER, takeOffer);
  yield takeLatest(equipmentActionTypes.LIST_EXCHANGE, listExchange);
  yield takeLatest(equipmentActionTypes.CANCEL_EXCHANGE, cancelExchange);
  yield takeLatest(equipmentActionTypes.TAKE_EXCHANGE_OFFER, takeExchangeOffer);
  yield takeLatest(equipmentActionTypes.BATCH_TRANSFER, batchTransfer);
  yield takeLatest(equipmentActionTypes.CRAFT, craft);
  yield takeLatest(equipmentActionTypes.LEVEL_UP_BLACKSMITH, levelUpBlacksmith);
  yield takeLatest(equipmentActionTypes.THROW_ITEM_GACHA, throwItemGacha);
  yield takeLatest(equipmentActionTypes.DISMANTLE, dismantle);
  yield takeLatest(equipmentActionTypes.CONVERT_EXP_TO_BP, convertExpToBp);
  yield takeLatest(equipmentActionTypes.CLAIM, claimEquipment)
  yield takeLatest(equipmentActionTypes.DEPOSIT, deposit)
  yield takeLatest(equipmentActionTypes.WITHDRAW, withdraw)
}
