import React from "react";
import orcBg from "src/assets/images/lands/orc_bg.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createStyles,
  withStyles,
} from "@material-ui/core";

export const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: "transparent",
      color: "#E5E5E5",
      fontFamily: `"Montserrat", sans-serif`,
      fontSize: 16,
      textAlign: "center",
      borderWidth: "2px",
      borderStyle: "solid",
      borderImage: "linear-gradient(90deg, #4A4237 0%, #C5A87E 51.56%, #4A4237 100%) 1",
      borderTop: "0px !important",
    },
    body: {
      fontSize: 14,
      fontFamily: `"Montserrat", sans-serif`,
      color: "#E5E5E5",
      textAlign: "center",
      borderWidth: "2px",
      borderStyle: "solid",
      borderImage: "linear-gradient(90deg, #4A4237 0%, #C5A87E 51.56%, #4A4237 100%) 1",
      borderLeft: "0px",
      borderBottom: "0px",
    },
  })
)(TableCell);

export const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: "transparent",
    },
  })
)(TableRow);
export default function LandBuying() {
  return (
    <div className="land__buying">
      <img src={orcBg} alt="" className="land__buying-bg" />
      <div className="container">
        <h1 className="land__buying-title">Buying and Selling</h1>
        <p className="land__buying-desc">
          Each piece of land is a unique NFT of different traits and rarities and can be traded freely on the
          marketplace..
        </p>
        <div className="cm-table land__buying-table--container">
            <TableContainer>
              <Table className="land__buying-table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell colSpan={2}>Land Quantity Sold</StyledTableCell>
                    <StyledTableCell>Land Rarity</StyledTableCell>
                    <StyledTableCell>Land Owner's Benefits</StyledTableCell>
                    <StyledTableCell>Selling Price</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell rowSpan={4}>
                      <div className="land__buying-table--yellow">400</div>
                      <div>(total)</div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div>Inferno Realm</div>
                      <div className="land__buying-table--yellow">100</div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div>
                        <span className="land__buying-table--yellow">24</span> Lands
                      </div>
                      <div className="land__buying-table--subtitle">8 Traits + 15 Crystals mint</div>
                    </StyledTableCell>
                    <StyledTableCell rowSpan={2}>
                      An amount of $FARA tokens extracted from Faraland's Land Market Fee
                    </StyledTableCell>
                    <StyledTableCell rowSpan={6}>
                      <span className="land__buying-table--yellow">500</span> $FARA
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>
                      <div>Aquatic Realm</div>
                      <div className="land__buying-table--yellow">100</div>
                    </StyledTableCell>
                    <StyledTableCell rowSpan={2}>
                      <div>
                        <span className="land__buying-table--yellow">56</span> Lands
                      </div>
                      <div className="land__buying-table--subtitle">7 Traits + 6 Crystals mint</div>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>
                      <div>Glacial Realm</div>
                      <div className="land__buying-table--yellow">100</div>
                    </StyledTableCell>
                    <StyledTableCell rowSpan={2}>
                      An amount of Crystals minted during the land war and can be traded to items in Land's Shop in
                      Phase 3
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>
                      <div>Golem Realm</div>
                      <div className="land__buying-table--yellow">100</div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div>
                        <span className="land__buying-table--yellow">320</span> Lands
                      </div>
                      <div className="land__buying-table--subtitle">5 Traits + 1 Crystals mint</div>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
        </div>
      </div>
    </div>
  );
}
