import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { StylesProvider } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import {
  faralandQuestions,
  heroQuestions,
  tokenQuestions,
  stakeQuestions,
  gachaQuestions,
  expeditionQuestions,
  fullGameQuestions,
} from "./CategoryFAQ/FAQ";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import { QUESTION_CATEGORY } from "src/app/configs/constants";

const HelpCenter = () => {
  // const [chipData, setChipData] = useState([
  //   { key: 0, label: "Faraland" },
  //   { key: 1, label: "Faraland" },
  //   { key: 2, label: "Faraland" },
  //   { key: 3, label: "Faraland" },
  //   { key: 4, label: "Faraland" },
  // ]);
  const [selectedListIndex, setSelectedListIndex] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const allQuestions = [
    ...faralandQuestions,
    ...heroQuestions,
    ...tokenQuestions,
    ...stakeQuestions,
    ...gachaQuestions,
    ...expeditionQuestions,
  ];
  const [listItems] = useState([
    {
      index: QUESTION_CATEGORY.FARALAND,
      value: "Faraland",
    },
    {
      index: QUESTION_CATEGORY.HERO,
      value: "NFT Heroes",
    },
    {
      index: QUESTION_CATEGORY.TOKEN,
      value: "Fara Tokens",
    },
    {
      index: QUESTION_CATEGORY.STAKE,
      value: "Staking",
    },
    {
      index: QUESTION_CATEGORY.GACHA,
      value: "Gacha",
    },
    {
      index: QUESTION_CATEGORY.EXPEDITION,
      value: "Expedition Text Game",
    },
    {
      index: QUESTION_CATEGORY.FULL_GAME,
      value: "the full game",
    },
  ]);

  useEffect(() => {
    getQuestions(selectedListIndex);
  }, [selectedListIndex]);

  const getQuestions = (selectedList: number) => {
    switch (selectedList) {
      case QUESTION_CATEGORY.FARALAND:
        return faralandQuestions;
      case QUESTION_CATEGORY.HERO:
        return heroQuestions;
      case QUESTION_CATEGORY.TOKEN:
        return tokenQuestions;
      case QUESTION_CATEGORY.STAKE:
        return stakeQuestions;
      case QUESTION_CATEGORY.GACHA:
        return gachaQuestions;
      case QUESTION_CATEGORY.EXPEDITION:
        return expeditionQuestions;
      case QUESTION_CATEGORY.FULL_GAME:
        return fullGameQuestions;
      default:
        return faralandQuestions;
    }
  };

  // const handleDelete = (chipToDelete) => () => {
  //   setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  // };

  const handleListItemClick = (_, index) => {
    setSelectedListIndex(index);
  };

  const handleQuestionSearch = (_e: any, value: any) => {
    console.log(value);
    if (value) {
      setSelectedListIndex(value.category);
      setSelectedQuestion(value);
    }
  };

  const handleAccordionChange = (question: any) => (_e: any, isExpanded: boolean) => {
    if (isExpanded) {
      setSelectedQuestion(question);
    } else {
      setSelectedQuestion({});
    }
  };
  return (
    <StylesProvider injectFirst>
      <div className="help">
        <div className="help__title">Hello! How can we help?</div>
        <div className="help__search">
          <Autocomplete
            debug
            className="help__search-autocomplete"
            options={allQuestions}
            getOptionLabel={(option) => option.summary}
            onChange={handleQuestionSearch}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <Input
                  {...params}
                  className="help__search-bar"
                  placeholder="All About Faraland"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </div>
            )}
            PaperComponent={(props) => <Paper {...props} className="help__search-autocomplete--paper" />}
            renderOption={(option) => <div className="help__search-autocomplete--options">{option.summary}</div>}
          />
        </div>
        <div className="help__chips">
          {/* {chipData.map((data) => (
            <Chip
              key={data.key}
              label={data.label}
              onDelete={handleDelete(data)}
              deleteIcon={<CloseIcon />}
              className="help__chip"
            />
          ))} */}
        </div>
        <div className="help__content">
          <List component="nav" className="help__list">
            {listItems.map((item, key) => (
              <ListItem
                key={key}
                button
                className="help__list-item"
                selected={selectedListIndex === item.index}
                onClick={(e) => handleListItemClick(e, item.index)}
              >
                <ListItemText primary={`All About ${item.value}`} />
              </ListItem>
            ))}
          </List>
          <div className="help__accordions">
            {getQuestions(selectedListIndex).map((question, key) => (
              <Accordion
                className="help__accordion"
                expanded={selectedQuestion === question}
                key={key}
                onChange={handleAccordionChange(question)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h3>{question.summary}</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="new-line help__accordion-details"
                    dangerouslySetInnerHTML={{ __html: question.answer }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </StylesProvider>
  );
};

export default HelpCenter;
