import React from "react";
import { TextField } from "@material-ui/core";
import { formatNumber } from "src/app/utils/helpers";

type InputGroupProps = {
  handleChange: any;
  error: string;
  symbol?: string;
  balance?: string;
  tokenImage?: string;
  secondTokenImage?: string;
  className?: string;
  secondSymbol?: string;
  secondBalance?: string;
  value?: string;
  balanceText?: string;
  label?: string;
  hideMaxBtn?: boolean;
  hideBalance?: boolean;
  isTextField?: boolean;
  hasRaceIcon?: boolean;
  placeholder?: string;
  multiline?: boolean;
  maxLength?: number;
  maxAmount?: number;
};

export default function InputGroup(props: InputGroupProps) {
  function fillMaxAmount(e: any) {
    const maxAmount = props.maxAmount ?? props.balance;
    if (!maxAmount) return;
    props.handleChange(e, maxAmount);
  }

  return (
    <>
      <div className={`text-field ${props.className ?? ""} ${props.secondTokenImage ? 'text-field--dual-icon' : ''}`}>
        {props.tokenImage && (
          <div className={`text-field__icon ${(props.isTextField || props.hasRaceIcon) && "text-field__icon--race"}`}>
            <img src={props.tokenImage} alt="" />
            {props.secondTokenImage && (
              <img src={props.secondTokenImage} alt="" />
            )}
          </div>
        )}
        <TextField
          value={props.value}
          onChange={props.handleChange}
          label={props.label || "FARA Amount"}
          type={props.isTextField ? "text" : "number"}
          variant="outlined"
          fullWidth
          size="medium"
          placeholder={props.placeholder ?? "0"}
          InputLabelProps={{ shrink: true }}
          error={!!props.error}
          multiline={props.multiline}
          inputProps={props.maxLength ? {maxLength: props.maxLength} : {}}
        />
        {!props.hideMaxBtn && (
          <div onClick={fillMaxAmount} className="text-field__tag">
            Max
          </div>
        )}
      </div>
      {!!props.error && <div className="error-text">{props.error}</div>}
      {!props.hideBalance && (
        <div className="text-field__balance">
          <span>{props.balanceText ?? "Balance"}: </span>
          <span>
            <span>
              {props.balance ? formatNumber(props.balance, 4) : "0"} {props.symbol}
            </span>
            {props.secondBalance && (
              <span> , {formatNumber(props.secondBalance, 4)} {props.secondSymbol}</span>
            )}
          </span>
        </div>
      )}
    </>
  );
}
