
import { GuildReducer } from "src/app/types/reducer-state";
import { guildActionTypes } from 'src/app/actions/guildAction';

const initialState: GuildReducer = {
  refresh: false
};

export default function guildReducer(state = initialState, action: any): GuildReducer {
  switch (action.type) {
    case guildActionTypes.REFRESH_DETAILS: {
      return {
        ...state,
        refresh: action.payload
      }
    }
    default:
      return state;
  }
}
