export const materialActionTypes = {
  LIST: "MATERIAL.LIST",
  DELIST: "MATERIAL.DELIST",
  BUY: "MATERIAL.BUY",
  OFFER: "MATERIAL.OFFER",
  CANCEL_OFFER: "MATERIAL.CANCEL_OFFER",
  TAKE_OFFER: "MATERIAL.TAKE_OFFER",
  TRANSFER: "MATERIAL.TRANSFER",
  BATCH_TRANSFER: "MATERIAL.BATCH_TRANSFER",
  CONSUME_SS: "MATERIAL.CONSUME_SS",
  DEPOSIT: "MATERIAL.DEPOSIT",
  WITHDRAW: "MATERIAL.WITHDRAW",
};

export function listMaterial(mineralId: number, price: string, amount: string) {
  return {
    type: materialActionTypes.LIST,
    payload: { mineralId, price, amount },
  };
}

export function delistMaterial(mineralId: number) {
  return {
    type: materialActionTypes.DELIST,
    payload: { mineralId },
  };
}
export function buyMaterial(mineralId: number, price: string, amount: number, seller: string) {
  return {
    type: materialActionTypes.BUY,
    payload: { mineralId, price, amount, seller },
  };
}

export function offerMaterial(mineralId: number, price: string, amount: string) {
  return {
    type: materialActionTypes.OFFER,
    payload: { mineralId, price, amount },
  };
}

export function cancelOfferMaterial(mineralId: number) {
  return {
    type: materialActionTypes.CANCEL_OFFER,
    payload: { mineralId },
  };
}

export function takeOfferMaterial(mineralId: number, price: string, amount: number, buyer: string) {
  return {
    type: materialActionTypes.TAKE_OFFER,
    payload: { mineralId, price, amount, buyer },
  };
}

export function transferMaterials(from: string, to: string, id: number, amount: number, onSuccess: any) {
  return {
    type: materialActionTypes.TRANSFER,
    payload: { from, to, id, amount },
    callbacks: { onSuccess },
  };
}

export function batchTransferMaterials(from: string, to: string, ids: number[], amounts: number[], onSuccess: any) {
  return {
    type: materialActionTypes.BATCH_TRANSFER,
    payload: { from, to, ids, amounts },
    callbacks: { onSuccess },
  };
}

export function consumeSoulStone(knightId: number, knightType: number, amount: number, onSuccess: any) {
  return {
    type: materialActionTypes.CONSUME_SS,
    payload: { knightId, knightType, amount },
    callbacks: { onSuccess },
  };
}

export function depositMaterials(ids: number[], amounts: number[], onSuccess: any) {
  return {
    type: materialActionTypes.DEPOSIT,
    payload: { ids, amounts },
    callbacks: { onSuccess },
  };
}

export function withdrawMaterials(withdrawData, onSuccess: any) {
  return {
    type: materialActionTypes.WITHDRAW,
    payload: { withdrawData },
    callbacks: { onSuccess },
  };
}
