const materials = {
  "0": {
    id: 0,
    name: "Soul Stone",
    displayName: "Soul Stone",
    rarity: 0,
    desc: `<div>Legend has it that Demi Hero's lifespan is limited due to their distinct biological mechanisms. They need a sufficient amount of unfiltered primeval energy to prolong their lifespan.</div><div class="fs-3 mt-2 text-gray">More than an in-game reward, Soul Stone can be used to summon and extend lifespan of demi heroes which plays an important role in Faraland’s system.<br/>Soul Stone can also be exchanged for EXPs or used as fuel to craft powerful equipment.</div>`,
  },
  "1": {
    id: 1,
    name: "Emperor Stone",
    displayName: "Emperor Stone",
    rarity: 4,
    desc: `<div>A very rare material found in the deepest forest from an ancient monster that grants wielder the power of integrity that only the mightiest individual can handle.</div><div class="fs-3 mt-2 text-gray">Whoever posses the Emperor Stone has the ability to create a Guild and recruit other players to build his own empire.</div>`,
  },
  "2": {
    id: 2,
    name: "Lucky Clover",
    displayName: "Lucky Clover",
    rarity: 1,
    desc: `<div>A four-leaf clover features numerous good luck values. By a stroke of luck, it helps a hero face any ordeals.</div><div class="fs-3 mt-2 text-gray">Can be used to boost 5% of success in crafting.</div>`,
  },
  "3": {
    id: 3,
    name: "Sun Crystal",
    displayName: "Sun Crystal",
    rarity: 2,
    desc: `<div>A primordial crystal chronicles millennia of forging to grant unfathomable power of the sun. It can smash up the luck in crafting things with ghastly penetration to deliver downright crushing savagery in the heat of battle.</div><div class="fs-3 mt-2 text-gray">Can be used to boost 10% of success in crafting.</div>`,
  },
  "4": {
    id: 4,
    name: "Prism Jewel",
    displayName: "Prism Jewel",
    rarity: 3,
    desc: `<div>Its intricate patterns are hypnotic, charming, yet lethal. Even a faint ray of light can still reflect it in dazzling color.</div><div class="fs-3 mt-2 text-gray">Can be used to boost 15% of success in crafting.</div>`,
  },
  "5": {
    id: 5,
    name: "Divine Powder",
    displayName: "Divine Powder",
    rarity: 4,
    desc: `<div>A brimmed felt bag holds the blessing of Faraland's goddess.</div><div class="fs-3 mt-2 text-gray">Can be used to boost 20% of success in crafting.</div>`,
  },
  "6": {
    id: 6,
    name: "Grima's Wing",
    displayName: "Grima's Wing",
    rarity: 4,
    desc: `<div>The bones of Grima’s wings detain all spirits of dead warriors in the bygone battles sealing the Demon Lord Beelzebub.</div><div class="fs-3 mt-2 text-gray">Can be used for crafting.</div>`,
  },
  "7": {
    id: 7,
    name: "Grima's Horn",
    displayName: "Grima's Horn",
    rarity: 4,
    desc: `<div>A metallic horn with an ominous shine covered with hardest metal of Faraland. It holds the combat fighting power of Grima.</div><div class="fs-3 mt-2 text-gray">Can be used for crafting.</div>`,
  },
  "8": {
    id: 8,
    name: "Dark Ore",
    displayName: "Dark Ore",
    rarity: 0,
    desc: `<div>The ore is a dense, metallic mineral often found encased in corpses of dark monsters. It's said to hold the soul of dead monsters.</div><div class="fs-3 mt-2 text-gray">Can be used for crafting.</div>`,
  },
  "9": {
    id: 9,
    name: "Arcane Dust",
    displayName: "Arcane Dust",
    rarity: 0,
    desc: `<div>Mysterious dust that absorbed the power of monsters while they're still alive.</div><div class="fs-3 mt-2 text-gray">Can be used for crafting.</div>`,
  },
  "10": {
    id: 10,
    name: "Dark Butterfly",
    displayName: "Dark Butterfly",
    rarity: 3,
    desc: `<div>The imploded form of Evil King's illusion upon his defeat. It contains the essence of his energy.</div><div class="fs-3 mt-2 text-gray">Can be used for crafting.</div>`,
  },
  "11": {
    id: 11,
    name: "Chaos Core",
    displayName: "Chaos Core",
    rarity: 1,
    desc: `<div>A core that comes from dying ancient ruins. It's a part of the energy machine that give power to monsters.</div><div class="fs-3 mt-2 text-gray">Can be used for crafting.</div>`,
  },
  "12": {
    id: 12,
    name: "Chaos Oculus",
    displayName: "Chaos Oculus",
    rarity: 2,
    desc: `<div>An oculus that comes from dying ancient ruins. It's a part of the energy machine that gives power to monsters.</div><div class="fs-3 mt-2 text-gray">Can be used for crafting.</div>`,
  },
  "13": {
    id: 13,
    name: "Rotten Meat",
    displayName: "Rotten Meat",
    rarity: 2,
    desc: `<div>A meat belongs to Grima's minions that it's used to cut his own meat to feed them.</div><div class="fs-3 mt-2 text-gray">Can be used for crafting.</div>`,
  },
  "14": {
    id: 14,
    name: "Aqua Crown",
    displayName: "Aqua Crown",
    rarity: 3,
    desc: `<div>A crown belonging to monsters upon the deep, freezing lakes of the North.</div><div class="fs-3 mt-2 text-gray">Can be used for crafting.</div>`,
  },
  "15": {
    id: 15,
    name: "Demonic Essence",
    displayName: "Demonic Essence",
    rarity: 3,
    desc: `<div>The demonic core is implanted into the body, empowering the most barbaric monsters.</div><div class="fs-3 mt-2 text-gray">Can be used for crafting.</div>`,
  },
  "17": {
    id: 17,
    name: "EXP Jar",
    displayName: "EXP Jar",
    rarity: 1,
    desc: `<div>Deep within the mystical realm of Faraland, there lies the Holy Lake, renowned for its sacred waters possessing unparalleled power. From its depths, a precious elixir is extracted. This extraordinary liquid holds the key to unlocking unimaginable strength and is carefully collected and stored within a special vessel called the EXP Jar. </div>`,
  },
  "18": {
    id: 18,
    name: "Knight Token",
    displayName: "Knight Token",
    rarity: 2,
    desc: `<div>The mystical Knight Token is a remarkable and enchanting artifact that takes the form of a ticket, graced with a captivating image of a majestic blue-eyed steed. It holds deep significance within the realm of legends, as it is said to have been gifted to noble warriors as a symbol of their unwavering loyalty and courageous deeds.</div>`,
  },
  "19": {
    id: 19,
    name: "Royal Token",
    displayName: "Royal Token",
    rarity: 3,
    desc: `<div>Centuries ago, in a realm of boundless magic, emerged a magnificent artifact known as the "Royal Token." Adorned with the image of a lion, its fiery red eyes symbolized the ultimate power of a mythical lord.  Royal token, a representation of unparalleled might, bestowed upon its holder the status of an unstoppable force.</div>`,
  },
  "20": {
    id: 20,
    name: "Shard of Infinity",
    displayName: "Shard of Infinity",
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Material for high level runes. Purchased only from the Landwar shop</div>`,
  },
  "21": {
    id: 21,
    name: "Laguz Rune level 1",
    displayName: "Laguz Rune",
    rarity: 0,
    level: 1,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances HP by 4</div>`,
    stats: {
      HP: 4,
    },
  },
  "22": {
    id: 22,
    name: "Laguz Rune level 2",
    displayName: "Laguz Rune",
    rarity: 0,
    level: 2,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances HP by 11</div>`,
    stats: {
      HP: 11,
    },
  },
  "23": {
    id: 23,
    name: "Laguz Rune level 3",
    displayName: "Laguz Rune",
    level: 3,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances HP by 29</div>`,
    stats: {
      HP: 29,
    },
  },
  "24": {
    id: 24,
    name: "Laguz Rune level 4",
    displayName: "Laguz Rune",
    level: 4,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances HP by 56</div>`,
    stats: {
      HP: 56,
    },
  },
  "25": {
    id: 25,
    name: "Laguz Rune level 5",
    displayName: "Laguz Rune",
    level: 5,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances HP by 112</div>`,
    stats: {
      HP: 112,
    },
  },
  "26": {
    id: 26,
    name: "Laguz Rune level 6",
    displayName: "Laguz Rune",
    level: 6,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances HP by 190</div>`,
    stats: {
      HP: 190,
    },
  },
  "27": {
    id: 27,
    name: "Laguz Rune level 7",
    displayName: "Laguz Rune",
    level: 7,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances HP by 315</div>`,
    stats: {
      HP: 315,
    },
  },
  "28": {
    id: 28,
    name: "Laguz Rune level 8",
    displayName: "Laguz Rune",
    level: 8,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances HP by 480</div>`,
    stats: {
      HP: 480,
    },
  },
  "29": {
    id: 29,
    name: "Laguz Rune level 9",
    displayName: "Laguz Rune",
    level: 9,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances HP by 750</div>`,
    stats: {
      HP: 750,
    },
  },
  "30": {
    id: 30,
    name: "Laguz Rune level 10",
    displayName: "Laguz Rune",
    level: 10,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances HP by 1000</div>`,
    stats: {
      HP: 1000,
    },
  },
  "31": {
    id: 31,
    name: "Eihwaz Rune level 1",
    displayName: "Eihwaz Rune",
    level: 1,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Physical Defense by 1</div>`,
    stats: {
      "Physical Defense": 1,
    },
  },
  "32": {
    id: 31,
    name: "Eihwaz Rune level 2",
    displayName: "Eihwaz Rune",
    level: 2,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Physical Defense by 2</div>`,
    stats: {
      "Physical Defense": 2,
    },
  },
  "33": {
    id: 33,
    name: "Eihwaz Rune level 3",
    displayName: "Eihwaz Rune",
    level: 3,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Physical Defense by 6</div>`,
    stats: {
      "Physical Defense": 6,
    },
  },
  "34": {
    id: 34,
    name: "Eihwaz Rune level 4",
    displayName: "Eihwaz Rune",
    rarity: 0,
    level: 4,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Physical Defense by 12</div>`,
    stats: {
      "Physical Defense": 12,
    },
  },
  "35": {
    id: 35,
    name: "Eihwaz Rune level 5",
    displayName: "Eihwaz Rune",
    level: 5,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Physical Defense by 22</div>`,
    stats: {
      "Physical Defense": 22,
    },
  },
  "36": {
    id: 36,
    name: "Eihwaz Rune level 6",
    displayName: "Eihwaz Rune",
    level: 6,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Physical Defense by 38</div>`,
    stats: {
      "Physical Defense": 38,
    },
  },
  "37": {
    id: 37,
    name: "Eihwaz Rune level 7",
    displayName: "Eihwaz Rune",
    level: 7,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Physical Defense by 62</div>`,
    stats: {
      "Physical Defense": 62,
    },
  },
  "38": {
    id: 38,
    name: "Eihwaz Rune level 8",
    displayName: "Eihwaz Rune",
    level: 8,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Physical Defense by 96</div>`,
    stats: {
      "Physical Defense": 96,
    },
  },
  "39": {
    id: 39,
    name: "Eihwaz Rune level 9",
    displayName: "Eihwaz Rune",
    level: 9,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Physical Defense by 150</div>`,
    stats: {
      "Physical Defense": 150,
    },
  },
  "40": {
    id: 40,
    name: "Eihwaz Rune level 10",
    displayName: "Eihwaz Rune",
    level: 10,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Physical Defense by 200</div>`,
    stats: {
      "Physical Defense": 200,
    },
  },
  "41": {
    id: 41,
    name: "Wunjo Rune level 1",
    displayName: "Wunjo Rune",
    level: 1,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Magical Defense by 1</div>`,
    stats: {
      "Magical Defense": 1,
    },
  },
  "42": {
    id: 42,
    name: "Wunjo Rune level 2",
    displayName: "Wunjo Rune",
    level: 2,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Magical Defense by 2</div>`,
    stats: {
      "Magical Defense": 2,
    },
  },
  "43": {
    id: 43,
    name: "Wunjo Rune level 3",
    displayName: "Wunjo Rune",
    level: 3,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Magical Defense by 6</div>`,
    stats: {
      "Magical Defense": 6,
    },
  },
  "44": {
    id: 44,
    name: "Wunjo Rune level 4",
    displayName: "Wunjo Rune",
    level: 4,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Magical Defense by 12</div>`,
    stats: {
      "Magical Defense": 12,
    },
  },
  "45": {
    id: 45,
    name: "Wunjo Rune level 5",
    displayName: "Wunjo Rune",
    level: 5,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Magical Defense by 22</div>`,
    stats: {
      "Magical Defense": 22,
    },
  },
  "46": {
    id: 46,
    name: "Wunjo Rune level 6",
    displayName: "Wunjo Rune",
    level: 6,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Magical Defense by 38</div>`,
    stats: {
      "Magical Defense": 38,
    },
  },
  "47": {
    id: 47,
    name: "Wunjo Rune level 7",
    displayName: "Wunjo Rune",
    level: 7,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Magical Defense by 62</div>`,
    stats: {
      "Magical Defense": 62,
    },
  },
  "48": {
    id: 48,
    name: "Wunjo Rune level 8",
    displayName: "Wunjo Rune",
    level: 8,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Magical Defense by 96</div>`,
    stats: {
      "Magical Defense": 96,
    },
  },
  "49": {
    id: 49,
    name: "Wunjo Rune level 9",
    displayName: "Wunjo Rune",
    level: 9,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Magical Defense by 150</div>`,
    stats: {
      "Magical Defense": 150,
    },
  },
  "50": {
    id: 50,
    name: "Wunjo Rune level 10",
    displayName: "Wunjo Rune",
    level: 10,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Magical Defense by 200</div>`,
    stats: {
      "Magical Defense": 200,
    },
  },
  "51": {
    id: 51,
    name: "Sowilo Rune level 1",
    displayName: "Sowilo Rune",
    level: 1,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Passive Skill by 0.2%</div>`,
    stats: {
      "Bonus Passive Skill": 0.2,
    },
  },
  "52": {
    id: 52,
    name: "Sowilo Rune level 2",
    displayName: "Sowilo Rune",
    level: 2,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Passive Skill by 0.6%</div>`,
    stats: {
      "Bonus Passive Skill": 0.6,
    },
  },
  "53": {
    id: 53,
    name: "Sowilo Rune level 3",
    displayName: "Sowilo Rune",
    level: 3,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Passive Skill by 1%</div>`,
    stats: {
      "Bonus Passive Skill": 1,
    },
  },
  "54": {
    id: 54,
    name: "Sowilo Rune level 4",
    displayName: "Sowilo Rune",
    level: 4,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Passive Skill by 2%</div>`,
    stats: {
      "Bonus Passive Skill": 2,
    },
  },
  "55": {
    id: 55,
    name: "Sowilo Rune level 5",
    displayName: "Sowilo Rune",
    level: 5,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Passive Skill by 5%</div>`,
    stats: {
      "Bonus Passive Skill": 5,
    },
  },
  "56": {
    id: 56,
    name: "Sowilo Rune level 6",
    displayName: "Sowilo Rune",
    level: 6,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Passive Skill by 8%</div>`,
    stats: {
      "Bonus Passive Skill": 8,
    },
  },
  "57": {
    id: 57,
    name: "Sowilo Rune level 7",
    displayName: "Sowilo Rune",
    level: 7,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Passive Skill by 13%</div>`,
    stats: {
      "Bonus Passive Skill": 13,
    },
  },
  "58": {
    id: 58,
    name: "Sowilo Rune level 8",
    displayName: "Sowilo Rune",
    level: 8,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Passive Skill by 20%</div>`,
    stats: {
      "Bonus Passive Skill": 20,
    },
  },
  "59": {
    id: 59,
    name: "Sowilo Rune level 9",
    displayName: "Sowilo Rune",
    level: 9,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Passive Skill by 30%</div>`,
    stats: {
      "Bonus Passive Skill": 30,
    },
  },
  "60": {
    id: 60,
    name: "Sowilo Rune level 10",
    displayName: "Sowilo Rune",
    level: 10,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Passive Skill by 40%</div>`,
    stats: {
      "Bonus Passive Skill": 40,
    },
  },
  "61": {
    id: 61,
    name: "Algiz Rune level 1",
    displayName: "Algiz Rune",
    level: 1,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Active Skill by 0.2%</div>`,
    stats: {
      "Bonus Active Skill": 0.2,
    },
  },
  "62": {
    id: 62,
    name: "Algiz Rune level 2",
    displayName: "Algiz Rune",
    level: 2,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Active Skill by 0.6%</div>`,
    stats: {
      "Bonus Active Skill": 0.6,
    },
  },
  "63": {
    id: 63,
    name: "Algiz Rune level 3",
    displayName: "Algiz Rune",
    level: 3,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Active Skill by 1%</div>`,
    stats: {
      "Bonus Active Skill": 1,
    },
  },
  "64": {
    id: 64,
    name: "Algiz Rune level 4",
    displayName: "Algiz Rune",
    level: 4,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Active Skill by 2%</div>`,
    stats: {
      "Bonus Active Skill": 22,
    },
  },
  "65": {
    id: 65,
    name: "Algiz Rune level 5",
    displayName: "Algiz Rune",
    level: 5,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Active Skill by 5%</div>`,
    stats: {
      "Bonus Active Skill": 5,
    },
  },
  "66": {
    id: 66,
    name: "Algiz Rune level 6",
    displayName: "Algiz Rune",
    level: 6,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Active Skill by 8%</div>`,
    stats: {
      "Bonus Active Skill": 8,
    },
  },
  "67": {
    id: 67,
    name: "Algiz Rune level 7",
    displayName: "Algiz Rune",
    level: 7,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Active Skill by 13%</div>`,
    stats: {
      "Bonus Active Skill": 13,
    },
  },
  "68": {
    id: 68,
    name: "Algiz Rune level 8",
    displayName: "Algiz Rune",
    level: 8,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Active Skill by 20%</div>`,
    stats: {
      "Bonus Active Skill": 20,
    },
  },
  "69": {
    id: 69,
    name: "Algiz Rune level 9",
    displayName: "Algiz Rune",
    level: 9,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Active Skill by 30%</div>`,
    stats: {
      "Bonus Active Skill": 30,
    },
  },
  "70": {
    id: 70,
    name: "Algiz Rune level 10",
    displayName: "Algiz Rune",
    level: 10,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Active Skill by 40%</div>`,
    stats: {
      "Bonus Active Skill": 40,
    },
  },
  "71": {
    id: 71,
    name: "Uruz Rune level 1",
    displayName: "Uruz Rune",
    level: 1,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Normal Attack by 0.5%</div>`,
    stats: {
      "Bonus Normal Attack": 0.5,
    },
  },
  "72": {
    id: 72,
    name: "Uruz Rune level 2",
    displayName: "Uruz Rune",
    level: 2,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Normal Attack by 1%</div>`,
    stats: {
      "Bonus Normal Attack": 1,
    },
  },
  "73": {
    id: 73,
    name: "Uruz Rune level 3",
    displayName: "Uruz Rune",
    level: 3,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Normal Attack by 2%</div>`,
    stats: {
      "Bonus Normal Attack": 2,
    },
  },
  "74": {
    id: 74,
    name: "Uruz Rune level 4",
    displayName: "Uruz Rune",
    level: 4,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Normal Attack by 3%</div>`,
    stats: {
      "Bonus Normal Attack": 3,
    },
  },
  "75": {
    id: 75,
    name: "Uruz Rune level 5",
    displayName: "Uruz Rune",
    level: 5,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Normal Attack by 6%</div>`,
    stats: {
      "Bonus Normal Attack": 6,
    },
  },
  "76": {
    id: 76,
    name: "Uruz Rune level 6",
    displayName: "Uruz Rune",
    level: 6,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Normal Attack by 10%</div>`,
    stats: {
      "Bonus Normal Attack": 10,
    },
  },
  "77": {
    id: 77,
    name: "Uruz Rune level 7",
    displayName: "Uruz Rune",
    level: 7,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Normal Attack by 16%</div>`,
    stats: {
      "Bonus Normal Attack": 16,
    },
  },
  "78": {
    id: 78,
    name: "Uruz Rune level 8",
    displayName: "Uruz Rune",
    level: 8,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Normal Attack by 24%</div>`,
    stats: {
      "Bonus Normal Attack": 24,
    },
  },
  "79": {
    id: 79,
    name: "Uruz Rune level 9",
    displayName: "Uruz Rune",
    level: 9,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Normal Attack by 38%</div>`,
    stats: {
      "Bonus Normal Attack": 38,
    },
  },
  "80": {
    id: 80,
    name: "Uruz Rune level 10",
    displayName: "Uruz Rune",
    level: 10,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Normal Attack by 50%</div>`,
    stats: {
      "Bonus Normal Attack": 50,
    },
  },
  "81": {
    id: 81,
    name: "Tiwaz Rune level 1",
    displayName: "Tiwaz Rune",
    level: 1,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Elemental Counter by 0.2%</div>`,
    stats: {
      "Bonus Elemental Counter": 0.2,
    },
  },
  "82": {
    id: 82,
    name: "Tiwaz Rune level 2",
    displayName: "Tiwaz Rune",
    level: 2,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Elemental Counter by 0.6%</div>`,
    stats: {
      "Bonus Elemental Counter": 0.6,
    },
  },
  "83": {
    id: 83,
    name: "Tiwaz Rune level 3",
    displayName: "Tiwaz Rune",
    level: 3,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Elemental Counter by 1%</div>`,
    stats: {
      "Bonus Elemental Counter": 1,
    },
  },
  "84": {
    id: 84,
    name: "Tiwaz Rune level 4",
    displayName: "Tiwaz Rune",
    level: 4,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Elemental Counter by 2%</div>`,
    stats: {
      "Bonus Elemental Counter": 2,
    },
  },
  "85": {
    id: 85,
    name: "Tiwaz Rune level 5",
    displayName: "Tiwaz Rune",
    level: 5,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Elemental Counter by 5%</div>`,
    stats: {
      "Bonus Elemental Counter": 5,
    },
  },
  "86": {
    id: 86,
    name: "Tiwaz Rune level 6",
    displayName: "Tiwaz Rune",
    level: 6,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Elemental Counter by 8%</div>`,
    stats: {
      "Bonus Elemental Counter": 8,
    },
  },
  "87": {
    id: 87,
    name: "Tiwaz Rune level 7",
    displayName: "Tiwaz Rune",
    level: 7,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Elemental Counter by 13%</div>`,
    stats: {
      "Bonus Elemental Counter": 13,
    },
  },
  "88": {
    id: 88,
    name: "Tiwaz Rune level 8",
    displayName: "Tiwaz Rune",
    level: 8,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Elemental Counter by 20%</div>`,
    stats: {
      "Bonus Elemental Counter": 20,
    },
  },
  "89": {
    id: 89,
    name: "Tiwaz Rune level 9",
    displayName: "Tiwaz Rune",
    level: 9,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Elemental Counter by 30%</div>`,
    stats: {
      "Bonus Elemental Counter": 30,
    },
  },
  "90": {
    id: 90,
    name: "Tiwaz Rune level 10",
    displayName: "Tiwaz Rune",
    level: 10,
    rarity: 0,
    desc: `<div class="fs-3 mt-2 text-gray">Enhances Elemental Counter by 40%</div>`,
    stats: {
      "Bonus Elemental Counter": 40,
    },
  },
};

export const MATERIALS = { ...materials };
