import React, { useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Knight, Offer, KnightStats } from "src/app/types/knight";
import bnbLogo from "src/assets/images/tokens/bnb.svg";
import { formatNumber } from "src/app/utils/helpers";
import { multiplyNumbers } from "src/app/utils/calculators";
import { BASE_KNIGHT_EXP, KNIGHT_TYPE, RARITY, STATS_LABEL } from "src/app/configs/constants";
import { KnightAttributes } from "src/app/types/attribute";
import KnightOfferTable from "src/app/components/Knight/Details/KnightOfferTable";
import ExpProgressBar from "src/app/components/Commons/ExpProgressBar";
import useKnightData from "src/app/hooks/useKnightData";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import KnightUpgradeModal from "src/app/components/Knight/Details/KnightUpgradeModal";
import sparklingIcon from "src/assets/images/decors/sparkling.svg";
import { resetStats } from "src/app/actions/knightAction";
import useKnightLevelData from "src/app/hooks/useKnightLevelData";
import runeIcon from "src/assets/images/rune/rune-icon.svg";
import { setGlobalModal } from "src/app/actions/globalAction";
import { Material } from "src/app/types/materials";

type KnightDataProps = {
  knight: Knight;
  offers: Offer[];
  isOwner: boolean;
  isHaveOffers: boolean;
  onOpenPopup: any;
  handleTakeOffer: any;
  slotItems: number[];
  knightStats: KnightStats;
  isHidden: boolean;
  equippedRunes: Material[];
};

export default function KnightData(props: KnightDataProps) {
  const dispatch = useDispatch();
  const { knight, knightStats, slotItems, isHidden, isOwner, equippedRunes } = props;
  const { tokenPrice, modal } = useSelector((state: any) => state.global);
  const [
    statsData,
    totalBaseStats,
    totalItemStats,
    totalAllocatedStats,
    knightLevel,
    setKnightLevel,
    showLevelUpModal,
    showStatsDetailsModal,
  ] = useKnightData(knight, knightStats, slotItems, false, equippedRunes);
  const latestLevel = knightLevel === 0 ? knight.level : knightLevel;
  const [knightExp, levelUpAmount, convertExpToLevels, openIncreaseEXPModal, openUseExpJarModal] = useKnightLevelData(
    knight.id,
    KNIGHT_TYPE.IMMORTAL,
    latestLevel,
    setKnightLevel,
    false
  );
  function openUpgradeBodyPartModal() {
    if (!knight.attributes) return;

    const attributes: KnightAttributes[] = knight.attributes.filter((attribute: KnightAttributes) => {
      return attribute.rarity < RARITY.MYTHICAL;
    });

    modalService.show(BasicModalContent, {
      content: <KnightUpgradeModal knightId={knight.id} attributes={attributes} displayRace={knight.displayRace} />,
      customTitle: "Upgrade Body Parts",
      layer: 3,
    });
  }

  useEffect(() => {
    if (modal.runeEquipmentModal.active) {
      dispatch(
        setGlobalModal("runeEquipmentModal", {
          active: true,
          data: {
            knight,
            attribute: knight.attributes.find(
              (attribute) => attribute.id === modal.runeEquipmentModal.data?.attribute.id
            ),
            isOwner,
          },
        })
      );
    }
  }, [knight]); // eslint-disable-line

  function sendResetStatsTx() {
    dispatch(resetStats(knight.id, KNIGHT_TYPE.IMMORTAL));
  }

  function handleRuneEquip(attribute: KnightAttributes) {
    dispatch(setGlobalModal("runeEquipmentModal", { active: true, data: { knight, attribute, isOwner } }));
  }
  return (
    <div className={`${!isHidden && "knight-stats"}`}>
      {isHidden ? (
        <div className="knight-preview">
          <div className="mb-2">
            <div className={`knights__item-id normal`}>#{knight.id}</div>
          </div>
          <div className="knight-preview__wrapper-race">
            <span>{knight.displayRace}</span>
          </div>
          <div className="knight-preview__wrapper-name">
            <span>{knight.name ? knight.name : "NO NAME"}</span>
          </div>
        </div>
      ) : (
        <>
          <div className="detail-price">
            <div className="detail-price__container">
              <div>
                <div className="detail-price__price">{knight.price || "-/-"} BNB</div>
                {!!knight.price && (
                  <div className="detail-price__dollars">
                    ≈ ${formatNumber(multiplyNumbers(knight.price, tokenPrice.BNB), 2)}
                  </div>
                )}
              </div>
              <img className="detail-price__logo" src={bnbLogo} alt="BNB" />
            </div>
          </div>
          <div className="knight-stats__info">
            <div className="knight-stats__info-general">
              <div className="knight-stats__info-item">
                <div className="knight-stats__info-name">Class</div>
                <div className="knight-stats__info-value bot-5">
                  <img
                    className="knight-stats__info-icon"
                    src={require(`src/assets/images/icons/races/${knight.race.toLowerCase()}-${knight.gender.toLowerCase()}.png`)}
                    alt=""
                  />
                  <span className="top-1">{knight.displayRace}</span>
                </div>
              </div>
              <div className="knight-stats__info-item">
                <div className="knight-stats__info-name">Level</div>
                <div className="knight-stats__info-value top-6">{latestLevel}</div>
              </div>
              <div className="knight-stats__info-item">
                <div className="knight-stats__info-name">Element</div>
                <div className="knight-stats__info-value top-1">
                  <img
                    className="knight-stats__info-icon knight-stats__info-icon--small"
                    src={require(`src/assets/images/hero-details/type/${knight.type.toLowerCase()}.png`)}
                    alt={knight.type}
                  />
                  <span>{knight.type}</span>
                </div>
              </div>
            </div>
          </div>

          <ExpProgressBar
            currentExp={knightExp}
            requiredExp={latestLevel * BASE_KNIGHT_EXP}
            levelUpAmount={levelUpAmount}
            isOwner={props.isOwner}
            levelUpText={`Convert your EXPs to ${levelUpAmount} levels`}
            convertExpToLevels={convertExpToLevels}
            openUseExpJarModal={openUseExpJarModal}
            openIncreaseEXPModal={openIncreaseEXPModal}
          />

          <div className="stats mt-6">
            <div className="flex-center-start">
              <div className="stats__title">
                <span>Stats ({totalBaseStats} </span>
                {totalItemStats + totalAllocatedStats !== 0 && (
                  <span className="stats__title-bonus"> + {totalItemStats + totalAllocatedStats}</span>
                )}
                <span>)</span>
              </div>
              <div className="stats__btn-container">
                <div className="btn stats__btn" onClick={showStatsDetailsModal}>
                  Details
                </div>
                {props.isOwner && (
                  <div className="btn stats__btn" onClick={showLevelUpModal}>
                    Increase Stats
                  </div>
                )}
                {props.isOwner && totalAllocatedStats > 0 && (
                  <div className="btn stats__btn" onClick={sendResetStatsTx}>
                    Reset Stats
                  </div>
                )}
              </div>
            </div>
            <div className="stats__container">
              {Object.values(STATS_LABEL).map((stats: any, index: number) => {
                const data = statsData[stats] ?? {};
                return (
                  <div className="stats__item" key={index}>
                    <div className="stats__name">{data.label}</div>
                    <Tooltip title={data.tooltip ?? ""} placement="bottom">
                      <div className="stats__value">
                        <img
                          src={require(`src/assets/images/hero-details/stats/${stats.toLowerCase()}.svg`)}
                          alt={data.label}
                        />
                        <div>
                          {data.baseValue}
                          {data.itemStatsValue + data.levelUpStats !== 0 && (
                            <span className="text-17"> + {data.itemStatsValue + data.levelUpStats}</span>
                          )}
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="knight__body-parts">
            <div className="flex-center-start">
              <div className="stats__title">Body Parts</div>
              <div className="stats__btn-container">
                {props.isOwner && (
                  <div className="btn stats__btn" onClick={openUpgradeBodyPartModal}>
                    Upgrade
                  </div>
                )}
              </div>
            </div>
            <div className="body-part">
              {knight.attributes.map((attribute: KnightAttributes, index: number) => {
                return (
                  <div className="body-part__item" key={index}>
                    <div className="flex-center-start">
                      <Tooltip title={attribute.traitType} placement="top">
                        <img
                          src={require(`src/assets/images/hero-details/attributes/${knight.displayRace}/${attribute.traitType}.png`)}
                          alt={attribute.traitType}
                          width={60}
                        />
                      </Tooltip>
                      <div className="body-part__content">
                        <div className="body-part__name">{attribute.value}</div>
                        <div className={`body-part__rarity rarity-${attribute.displayRarity.toLowerCase()}`}>
                          <span>{attribute.displayRarity}</span>
                          {attribute.isUpgraded && <img className="body-part__upgraded" src={sparklingIcon} alt="" />}
                        </div>
                      </div>
                    </div>
                    <Tooltip title="Equip Rune" placement="top">
                      <div
                        className={`btn ${attribute.rarity < RARITY.EPIC ? "disabled" : ""} body-part__rune-wrapper`}
                        onClick={() => handleRuneEquip(attribute)}
                      >
                        <img className="body-part__rune" src={runeIcon} alt="Rune Icon" />
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      <KnightOfferTable
        knight={props.knight}
        offers={props.offers}
        isOwner={props.isOwner}
        isHaveOffers={props.isHaveOffers}
        onOpenPopup={props.onOpenPopup}
        handleTakeOffer={props.handleTakeOffer}
        isHeroHidden={isHidden}
      />
    </div>
  );
}
