import { Tooltip } from "@material-ui/core";
import React from "react";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import RuneEquipmentSelectModal from "src/app/components/Equipment/Crafting/Rune/RuneEquipmentSelectModal";
import { RARITY_LABEL } from "src/app/configs/constants";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { UserItem } from "src/app/types/equipment";

type EquipmentSelectProps = {
  rarity: number;
  selectedItemId: number;
  selectedItemAmount: number;
  userItem?: UserItem;
  amount?: number;
  showBalance?: boolean;
  onRightClick?: (itemId: number) => void;
  hideSelectedBorder?: boolean;
  requiredAmount?: number;
  postfix?: string;
  submitText?: string;
  onSubmit?: () => void;
  closeOnSubmit?: boolean;
  className?: string;
  layer?: number;
};

export default function EquipmentSelect(props: EquipmentSelectProps) {
  const { requiredAmount, rarity, selectedItemId, selectedItemAmount, userItem } = props;
  const onSelect = () => {
    modalService.show(RuneEquipmentSelectModal, { requiredAmount, rarity, selectedItemId, selectedItemAmount });
  };
  return (
    <Tooltip
      title={`Please select ${RARITY_LABEL[rarity].toLowerCase()} item from your non-fee inventory`}
      arrow
      placement="top"
    >
      <div className={`item-icon ${props.className ? props.className : ""} slide-up`}>
        {selectedItemId !== null ? (
          <ItemIcon item={EQUIPMENT[selectedItemId]} onClick={onSelect} className={"no-margin"} />
        ) : (
          <img
            className={`item-icon__image item-icon__image--${RARITY_LABEL[rarity].toLowerCase()}`}
            src={require(`src/assets/images/hero-details/slot/inventory-slot.png`)}
            onClick={onSelect}
            alt=""
          />
        )}
        {requiredAmount && (
          <div
            className={`item-icon__text fs-2 ${
              userItem?.available ?? 0 >= requiredAmount ? `success-text` : `error-text`
            }`}
          >
            {userItem?.available ?? 0} / {requiredAmount}
          </div>
        )}
      </div>
    </Tooltip>
  );
}
