export const guildActionTypes = {
  CREATE_GUILD: "GUILD.CREATE_GUILD",
  JOIN_GUILD: "GUILD.JOIN_GUILD",
  APPROVE_MEMBERS: "GUILD.APPROVE_MEMBERS",
  REJECT_MEMBERS: "GUILD.REJECT_MEMBERS",
  KICK_MEMBERS: "GUILD.KICK_MEMBERS",
  LEAVE_GUILD: "GUILD.LEAVE_GUILD",
  LEVEL_UP: "GUILD.LEVEL_UP",
  CHANGE_NAME: "GUILD.CHANGE_NAME",
  TRANSFER: "GUILD.TRANSFER",
  REFRESH_DETAILS: "GUILD.REFRESH_DETAILS",
  CANCEL_JOIN_REQUEST: "GUILD.CANCEL_JOIN_REQUEST",
};

export function createGuild(name: string, onSuccess: any) {
  return {
    type: guildActionTypes.CREATE_GUILD,
    payload: { name },
    callbacks: { onSuccess },
  };
}

export function joinGuild(guildId: number, onSuccess?: any) {
  return {
    type: guildActionTypes.JOIN_GUILD,
    payload: { guildId },
    callbacks: { onSuccess },
  };
}

export function approveMembers(guildId: number, address: string[], onSuccess?: any) {
  return {
    type: guildActionTypes.APPROVE_MEMBERS,
    payload: { guildId, address },
    callbacks: { onSuccess },
  };
}

export function rejectMembers(guildId: number, address: string[], onSuccess?: any) {
  return {
    type: guildActionTypes.REJECT_MEMBERS,
    payload: { guildId, address },
    callbacks: { onSuccess },
  };
}

export function kickMembers(guildId: number, address: string[], onSuccess?: any) {
  return {
    type: guildActionTypes.KICK_MEMBERS,
    payload: { guildId, address },
    callbacks: { onSuccess },
  };
}

export function leaveGuild(guildId: number, onSuccess?: any) {
  return {
    type: guildActionTypes.LEAVE_GUILD,
    payload: { guildId },
    callbacks: { onSuccess },
  };
}

export function changeGuildName(guildId: number, newName: string, onSuccess?: any) {
  return {
    type: guildActionTypes.CHANGE_NAME,
    payload: { guildId, newName },
    callbacks: { onSuccess },
  };
}

export function transferGuildOwnership(from: string, to: string, guildId: number, onSuccess?: any) {
  return {
    type: guildActionTypes.TRANSFER,
    payload: { from, to, guildId },
    callbacks: { onSuccess },
  };
}

export function refreshGuildDetails(refresh: boolean) {
  return {
    type: guildActionTypes.REFRESH_DETAILS,
    payload: refresh,
  };
}

export function cancelJoinRequest(guildId: number, onSuccess?: any) {
  return {
    type: guildActionTypes.CANCEL_JOIN_REQUEST,
    payload: { guildId },
    callbacks: { onSuccess },
  };
}
