import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputGroup from "src/app/components//Commons/InputGroup";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import imagePlaceholder from "src/assets/images/guild/image-placeholder.png";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import { StepLabel } from "@material-ui/core";
import { StylesProvider } from "@material-ui/styles";
import { createGuild } from "src/app/actions/guildAction";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import ENV from "src/app/configs/env";
import Modal from "../../Commons/Modals/Modal";
import { setGlobalModal } from "src/app/actions/globalAction";
import { updateGuildDetails } from "src/app/services/api/faralandService";

export const GUILD_CREATE_STEP = {
  NAME_INPUT: 0,
  DESC_INPUT: 1,
}

export default function GuildCreationModal() {
  const dispatch = useDispatch();
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.GUILD);
  const { modal } = useSelector((state: any) => state.global);
  const { wallet, address } = useSelector((state: any) => state.account);
  const [guildName, setGuildName] = useState("");
  const [guildNameError, setGuildNameError] = useState("");
  const [guildImg, setGuildImg] = useState<any>();
  const [imgFile, setImgFile] = useState();
  const [imageError, setImageError] = useState("");
  const [desc, setDesc] = useState("");
  const [descError, setDescError] = useState("");
  const [guildId, setGuildId] = useState("");
  const { balance } = useSelector((state: any) => state.account);
  const [step, setStep] = useState(GUILD_CREATE_STEP.NAME_INPUT);
  const steps = ["Guild Name", "Guild Description & Avatar"];
  const guildCreationModal = modal.guildCreationModal;

  const handleNameChange = (e: any) => {
    setGuildNameError("");
    setGuildName(e.target.value);
  };

  const handleDescChange = (e: any) => {
    setDescError("");
    setDesc(e.target.value);
  };

  const handleImageSelect = (e) => {
    let reader = new FileReader();
    if (!e.target.files[0]) return;
    const fileExtension = e.target.files[0].name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
      setImageError(`Unsupported file type`);
      return false;
    }
    reader.onload = function (e) {
      if (reader.readyState === 2) {
        setImageError("");
        setGuildImg(reader.result);
      }
    };
    setImgFile(e.target?.files[0]);
    reader.readAsDataURL(e.target?.files[0]);
  };

  const onSubmit = () => {
    if (needApprove) {
      sendApproveTx();
      return;
    }

    if (guildName.length === 0) {
      setGuildNameError("Guild Name cannot be empty");
      return;
    }
    if (guildName.length < 6 || guildName.length > 30) {
      setGuildNameError("Guild Name must have between 6 and 30 characters");
      return;
    }
    dispatch(createGuild(guildName, onCreateGuildTxSuccess));
  };

  async function onCreateGuildTxSuccess(_txHash: string, [log]) {
    dispatch(setGlobalModal("txTracking"));
    if (log) {
      let id = parseInt(log.topics[1], 16).toString();
      setGuildId(id);
      setStep(GUILD_CREATE_STEP.DESC_INPUT);
    }
  }

  const onDescSubmit = async () => {
    if (!guildImg) {
      setImageError("Please select a guild image");
      return;
    }
    if (desc.length === 0) {
      setDescError("Descripiton cannot be empty");
      return;
    }
    const signData = await wallet.signData({ file: imgFile, desc }, address);
    // const res = await addGuildToApi(
    //   guildId,
    //   guildName,
    //   imgFile,
    //   desc,
    //   signData.sign,
    //   signData.msgParams,
    //   transactionHash
    // );
    const res = await updateGuildDetails(
      guildId,
      imgFile,
      desc,
      signData.sign,
      signData.msgParams,
    );
    if (res.success) {
      setGuildImg(undefined);
      setImgFile(undefined);
      setDesc("");
      dispatch(setGlobalModal("guildCreationModal", { active: true, guildRefresh: true }));
      dispatch(setGlobalModal("guildCreationModal"));
    } else {
      setImageError(res.message);
    }
  };

  const handleClose = () => {
    if (step === GUILD_CREATE_STEP.DESC_INPUT) return;
    dispatch(setGlobalModal("guildCreationModal"));
  };

  function renderCreationContent() {
    return (
      <StylesProvider>
        <div className="guild-creation__stepper">
          <Stepper activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {step === GUILD_CREATE_STEP.DESC_INPUT ? (
          <div className="guild-creation">
            <div className="mr-5 guild-creation__container">
              <div className={`guild-creation__image`}>
                <img
                  src={guildImg ?? imagePlaceholder}
                  alt=""
                  className={guildImg && `guild-creation__image--selected`}
                />
              </div>
              <input accept="image/*" id="image-select" type="file" hidden onChange={handleImageSelect} />
              <label htmlFor="image-select">
                <div className="btn btn--small mt-3">Choose File</div>
              </label>
            </div>
            <div className="full-width">
              <InputGroup
                className="guild-creation__text-field"
                label="Description"
                placeholder="Enter guild description"
                value={desc}
                handleChange={handleDescChange}
                hideMaxBtn={true}
                isTextField
                error={descError}
                multiline
                maxLength={100}
                hideBalance
              />
            </div>
          </div>
        ) : (
          <div>
            <InputGroup
              className="text-field--default"
              value={guildName}
              handleChange={handleNameChange}
              symbol="Emperor Stone"
              balance={balance.EMPEROR_STONE}
              label={"Guild Name"}
              secondBalance={balance.FARA}
              secondSymbol="FARA"
              hideMaxBtn={true}
              placeholder="Enter a guild name"
              isTextField
              error={guildNameError}
            />
          </div>
        )}
        <div className="error-text">{imageError}</div>
      </StylesProvider>
    );
  }
  return (
    <Modal isActive={guildCreationModal.active} onClose={handleClose} layer={1}>
      <BasicModalContent
        customTitle="Create Your Guild"
        onSubmit={step === GUILD_CREATE_STEP.NAME_INPUT ? onSubmit : onDescSubmit}
        submitText={needApprove ? "Approve" : step === GUILD_CREATE_STEP.NAME_INPUT ? "Create" : "Confirm"}
        close={handleClose}
        content={renderCreationContent()}
      />
    </Modal>
  );
}
