import React from "react";
import { Box, Tooltip } from "@material-ui/core";
import _ from "lodash";

type BodyPartProps = {
  race: string;
  job: string;
  skill: { name: string; desc: string };
}

export default function BodyPart(props: BodyPartProps) {
  const { race, job, skill } = props;

  function loadImage(job: string) {
    try {
      return require(`src/assets/images/icons/skills/${race.toLowerCase()}/${_.kebabCase(job)}/${_.kebabCase(skill.name)}.png`);
    } catch {
      return require(`src/assets/images/icons/skills/default.png`);
    }
  }

  return (
    <Tooltip title={skill.desc} arrow placement="top">
      <Box
        display="flex"
        alignItems="center"
        className="part"
      >
        <img
          alt=""
          src={loadImage(job)}
          className="part__skill"
        />
        <Box style={{ marginLeft: 10 }}>
          <div className="part__trait">{skill.name}</div>
        </Box>
      </Box>
    </Tooltip>
  );
}
