import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import strengthIcon from "src/assets/images/guild/strength-icon.png";
import { Link } from "react-router-dom";
import { Guild, GuildFilterType } from "src/app/types/guild";
import GuildIconPreview from "./Common/GuildIconPreview";
import { joinGuild } from "src/app/actions/guildAction";
import { getGuildFilterFromParams } from "src/app/utils/filterHelper";
import { useQueryString } from "use-route-as-state";

export interface GuildListContentProps {
  guild: Guild;
  alreadyGuildMember: boolean;
  guildIds: string[];
}

const GuildListContent = (props: GuildListContentProps) => {
  const { guild, alreadyGuildMember, guildIds } = props;
  const dispatch = useDispatch();
  const [queryParams] = useQueryString();
  const [guildFilters] = useState<GuildFilterType>(getGuildFilterFromParams(queryParams, false))
  const pending = guildFilters.pending;
  const [displayJoinBtn, setDisplayJoinBtn] = useState(false);
  useEffect(() => {
    checkDisplayJoinBtn(alreadyGuildMember, pending);
  }, [pending, alreadyGuildMember]) // eslint-disable-line

  const handleJoin = () => {
    dispatch(joinGuild(+guild.id));
  };

  const checkDisplayJoinBtn = (alreadyGuildMember: boolean, pending: boolean) => {
    if(pending || alreadyGuildMember || guildIds.some(id => +guild.id === +id)) {
      setDisplayJoinBtn(false);
    } else {
      setDisplayJoinBtn(true);
    }
  }

  return (
    <div className="flex-center-start guild-list__content">
      <GuildIconPreview guild={guild} isMarketplace />
      <div className="guild-list__content-container">
        <div className="flex-column guild-list__content-info">
          <div className="guild-list__content-info--title">{guild.name.toUpperCase()}</div>
          <div className="guild-list__content-info--level">Guild level: {guild.level}</div>
          <div className="guild-list__content-info--strength">
            Guild Strength:{" "}
            <span className="success-text">
              <img src={strengthIcon} alt="Strength Icon" /> {guild.totalStrength}
            </span>
          </div>
          <div className="guild-list__content-info--members">
            Members: <span className="success-text">{guild.memberCount}</span>
          </div>
        </div>
        <div className="flex-column mr-3">
          <Link to={`guild/${guild.id}`}>
            <div className="btn">View Details</div>
          </Link>
          {displayJoinBtn && (
            <div className="btn full-width mt-3" onClick={handleJoin}>
              Ask To Join
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuildListContent;
