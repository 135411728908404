import { Material, MaterialTransactions, RuneCraftingHistory, UserMaterial } from "src/app/types/materials";
import { ListItem, OfferItem } from "src/app/types/equipment";
import { timeAgo } from "src/app/utils/helpers";
import { MATERIALS } from "src/app/configs/materials/materials";

export function createMaterial(material: any, price?: number): Material {
  return {
    id: +material.id,
    name: material.name,
    displayName: material.name,
    desc: material.description,
    minted: material.minted,
    burned: material.burned,
    price: price ? price : material.listedPrice,
    rarity: material.rarity ?? 0,
    runeBuff: material.runeBuff ?? 0,
    level: material.level ?? 0,
  };
}

export function createUserMaterial(data: any): UserMaterial {
  return {
    id: +data.material.id,
    owner: data.user?.id,
    material: createMaterial(data.material),
    price: data.price,
    amount: +data.amount,
    available: +data.amount,
  };
}

export function createUserMaterialFromApi(data: any, walletAddress?: string): UserMaterial {
  return {
    id: +data.material.id,
    owner: walletAddress ?? data.walletAddress,
    material: createMaterial(data.material),
    price: data.price,
    amount: +data.quantity,
    available: +data.quantity,
    inUnclaimed: data.inUnclaimed ?? false,
  };
}

export function createListMaterial(data: any, domainNames: any): ListItem {
  return {
    id: data.id,
    item: createMaterial(data.material),
    amount: +data.amount,
    price: data.price,
    seller: data.seller.id,
    timestamp: data.timestamp,
    sellerDomain: domainNames ? domainNames[data.seller.id] : "",
  };
}

export function createOfferMaterial(data: any, domainNames: any): OfferItem {
  return {
    id: data.id,
    item: createMaterial(data.material),
    amount: +data.amount,
    price: data.price,
    buyer: data.buyer.id,
    timestamp: data.timestamp,
    buyerDomain: domainNames ? domainNames[data.buyer.id] : "",
  };
}

export function createMaterialTransaction(data: any, domainNames: any): MaterialTransactions {
  return {
    id: data.id,
    type: data.type,
    material: createMaterial(data.material),
    amount: +data.amount,
    price: data.price,
    from: data.from && data.from.id,
    to: data.to && data.to.id,
    timestamp: data.timestamp,
    fromAddressDomain: domainNames ? domainNames[data.from?.id] : "",
    toAddressDomain: domainNames ? domainNames[data.to?.id] : "",
  };
}

export function createRuneCraftHistory(data: any): RuneCraftingHistory {
  return {
    createdAt: timeAgo(data.createdAt),
    owner: data.walletAddress,
    materials: data.reason.map((item) => MATERIALS[item.runeId]),
    spaceDomain: data.spaceDomain,
    type: data.type,
    status: data.reason[0]?.status ? 'Success' : 'Fail',
  };
}
