import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INTERVAL } from "src/app/configs/constants";
import { fetchCraftingResult } from "src/app/services/api/faralandService";
import loadingIcon from "src/assets/images/icons/loading-circle.svg";
import { setGlobalModal } from "src/app/actions/globalAction";
import useIsMounted from "src/app/hooks/useIsMounted";
import ResultContent from "src/app/components/Commons/ResultContent";
import { CraftingSetRecipe } from "src/app/types/materials";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";

type ModalProps = {
  txHash: string;
  itemSetRecipe: CraftingSetRecipe;
  getBlacksmithData: any;
};

export default function CraftingResultModal(props: ModalProps) {
  const dispatch = useDispatch();
  const [isMounted] = useIsMounted();
  const fetchResultInterval = useRef<any>();
  const { address } = useSelector((state: any) => state.account);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [craftedItems, setCraftedItems] = useState<(number | null)[]>([]);
  const [itemIdAmounts, setItemIdAmounts] = useState({});
  const { txHash, itemSetRecipe } = props;

  useEffect(() => {
    requestResult(txHash);
  }, [txHash]); // eslint-disable-line

  async function requestResult(txHash: string) {
    dispatch(setGlobalModal("txTracking"));
    setIsLoading(true);

    const result = await fetchCraftingResult(txHash);

    if (result === null && isMounted.current) {
      clearInterval(fetchResultInterval.current);
      fetchResultInterval.current = setInterval(() => {
        requestResult(txHash);
      }, INTERVAL.REFETCH_CRAFTING);
      return;
    }
    if (result) {
      let amount = {};
      setIsSuccess(result.success);
      setCraftedItems(result.filteredData);
      props.getBlacksmithData(address);
      setIsLoading(false);
      result.filteredData.forEach((itemId) => {
        if (itemId === null) return;
        amount[+itemId!] = (amount[+itemId!] || 0) + 1;
      });
      setItemIdAmounts(amount);
      clearInterval(fetchResultInterval.current);
    }
  }

  return (
    <div>
      {isLoading && (
        <div className="flex-start-center mb-7">
          <img className="loading-icon" src={loadingIcon} alt="loading" />
          <div>Please wait a moment until the result is fully calculated.</div>
        </div>
      )}
      {!isLoading && isSuccess === true && (
        <>
          <ResultContent
            isSuccess={true}
            content={
              <div>
                Congratulations! You have successfully crafted {craftedItems.length} equipment of the{" "}
                {itemSetRecipe.name} set!
              </div>
            }
          />
          {Object.keys(itemIdAmounts).map((itemId, index: number) => {
            return (
              <ItemIcon
                key={index}
                item={EQUIPMENT[itemId]}
                amount={itemIdAmounts[itemId]}
                onClick={() => null}
                hideSelectedBorder
              />
            );
          })}
        </>
      )}
      {!isLoading && isSuccess === false && (
        <ResultContent
          isSuccess={false}
          content={<div>Failed to craft {itemSetRecipe.name}. Better luck next time.</div>}
        />
      )}
    </div>
  );
}
