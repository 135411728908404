import React from "react";
import { Redirect, Switch, BrowserRouter } from "react-router-dom";
import Layout from "src/app/components/Layout/Layout";
import { ROUTE } from "src/app/configs/constants";
import useSettingUpAccount from "src/app/hooks/useSettingUpAccount";
import GlobalErrorBoundary from "src/app/components/Commons/ErrorBoundaries/GlobalErrorBoundary";
import { ModalListener } from "src/app/components/Commons/Modals/ModalListener";
import useFetchingData from "src/app/hooks/useFetchingData";
import heroesBannerImage from "src/assets/images/banners/heroes.png";
import marketplaceBannerImage from "src/assets/images/banners/marketplace.png";
import stakingBannerImage from "src/assets/images/banners/fire-demon.png";
import summonBannerImage from "src/assets/images/banners/ice-demon.png";
import gachaBanner from "src/assets/images/banners/gacha-banner.png";
import homeBannerImage from "src/assets/images/banners/home.png";
import eventBannerImage from "src/assets/images/banners/event.png";
import landBannerImage from "src/assets/images/banners/land-banner.png";
import { createBannerData } from "src/app/factories/commonFactory";
import Staking from "src/app/components/Staking/Staking";
import GlobalModals from "src/app/components/Commons/Modals/GlobalModals";
import KnightDetails from "src/app/components/Knight/Details/KnightDetails";
import Home from "src/app/components/Home/Home";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import ProjectOverview from "src/app/components/Layout/OnePager/ProjectOverview";
import TokenMetrics from "src/app/components/Layout/OnePager/TokenMetric";
import Gacha from "src/app/components/Gacha/Gacha";
import Wallet from "src/app/components/Wallet/Wallet";
import EquipmentDetails from "src/app/components/Equipment/Details/EquipmentDetails";
import HelpCenter from "src/app/components/HelpCenter/HelpCenter";
import DemiSummon from "src/app/components/Demi/Summon/DemiSummon";
import Marketplace from "src/app/components/Commons/Marketplace";
import MaterialDetails from "src/app/components/Materials/Details/MaterialDetails";
import DemiDetails from "src/app/components/Demi/Details/DemiDetails";
import EquipmentCrafting from "src/app/components/Equipment/Crafting/EquipmentCrafting";
import Instructions from "src/app/components/Instructions/Instructions";
import instructionsBannerImage from "src/assets/images/instructions/banner-bg.png";
import GuildList from "src/app/components/Guild/GuildList";
import GuildDetails from "src/app/components/Guild/Details/GuildDetails";
import Event from "src/app/components/Event/Event";
// import HeroBurn from 'src/app/components/Knight/Burn/HeroBurn';
import EquipmentGacha from "src/app/components/EquipmentGacha/EquipmentGacha";
import NotFoundPage from "src/app/components/Commons/Modals/NotFoundPage";
import HeroBurn from "src/app/components/Knight/Burn/HeroBurn";
import LandMint from "src/app/components/Land/Mint/LandMint";
import LandDetails from "src/app/components/Land/Details/LandDetails";
import BlackMarket from "src/app/components/BlackMarket/BlackMarket";

const darkTheme = createTheme({
  palette: {
    type: "dark",
  },
});

export default function App() {
  useFetchingData();
  useSettingUpAccount();

  return (
    <div className="app">
      <GlobalErrorBoundary>
        <ThemeProvider theme={darkTheme}>
          <BrowserRouter>
            <Switch>
              <Layout
                path={ROUTE.HOME}
                component={Home}
                exact
                bannerData={createBannerData(homeBannerImage, "Home", "Home")}
              />
              <Layout
                path={ROUTE.HEROES}
                component={Marketplace}
                exact
                bannerData={createBannerData(heroesBannerImage, "The Marketplace", "Heroes")}
              />
              <Layout
                path={ROUTE.DEMI}
                component={Marketplace}
                exact
                bannerData={createBannerData(heroesBannerImage, "The Marketplace", "Demi Heroes")}
              />
              <Layout exact path={ROUTE.EQUIPMENT_DETAILS} component={EquipmentDetails} />
              <Layout
                path={ROUTE.ITEMS}
                component={Marketplace}
                exact
                bannerData={createBannerData(marketplaceBannerImage, "The Trading Port", "Equipment")}
              />
              <Layout
                path={ROUTE.MATERIALS}
                component={Marketplace}
                exact
                bannerData={createBannerData(marketplaceBannerImage, "The Trading Port", "Materials")}
              />
              <Layout
                exact
                path={ROUTE.LANDS}
                component={Marketplace}
                bannerData={createBannerData(heroesBannerImage, "The Trading Port", "Lands")}
              />
              <Layout path={ROUTE.LAND_DETAILS} component={LandDetails} />
              <Layout path={ROUTE.EQUIPMENT_DETAILS} component={EquipmentDetails} />
              <Layout
                path={ROUTE.STAKING}
                component={Staking}
                exact
                bannerData={createBannerData(stakingBannerImage, "The Training Ground", "Staking")}
              />
              <Layout path={ROUTE.HEROES_DETAILS} component={KnightDetails} />
              <Layout
                path={ROUTE.GACHA}
                component={Gacha}
                exact
                bannerData={createBannerData(gachaBanner, "The Destiny Crystal", "Gacha")}
              />
              <Layout
                path={ROUTE.WALLET}
                component={Wallet}
                bannerData={createBannerData(heroesBannerImage, "Your Campfire", "Wallet")}
              />
              <Layout
                path={ROUTE.EVENT}
                component={Event}
                bannerData={createBannerData(eventBannerImage, "Let Your Art", "Shine!")}
              />
              <Layout
                path={ROUTE.PROJECT_OVERVIEW}
                component={ProjectOverview}
                bannerData={createBannerData(heroesBannerImage, "Overview", "Faraland")}
              />
              <Layout
                path={ROUTE.TOKEN_METRIC}
                component={TokenMetrics}
                bannerData={createBannerData(heroesBannerImage, "Tokenomics", "Distribution")}
              />
              <Layout
                path={ROUTE.HELP_CENTER}
                component={HelpCenter}
                bannerData={createBannerData(heroesBannerImage, "Support Hub", "Help Center")}
              />
              <Layout
                path={ROUTE.DEMI_SUMMON}
                component={DemiSummon}
                bannerData={createBannerData(summonBannerImage, "Ethereal Gate", "Summon Demi")}
              />
              <Layout path={ROUTE.MATERIAL_DETAILS} component={MaterialDetails} />
              <Layout path={ROUTE.DEMI_DETAILS} component={DemiDetails} />
              <Layout
                path={ROUTE.GUILD}
                component={GuildList}
                bannerData={createBannerData(heroesBannerImage, "Knights Assemble", "Guild Hall")}
              />
              <Layout
                path={ROUTE.GUILD_DETAILS}
                component={GuildDetails}
                bannerData={createBannerData(heroesBannerImage, "Knights Assemble", "Guild Hall")}
              />
              <Layout
                path={ROUTE.INSTRUCTIONS}
                noHeader
                component={Instructions}
                bannerData={createBannerData(instructionsBannerImage, "Support Hub", "Help Center")}
              />
              <Layout
                path={ROUTE.HERO_BURN}
                component={HeroBurn}
                bannerData={createBannerData(marketplaceBannerImage, "Sacrificial Tomb", "Burn Hero")}
              />
              <Layout
                exact
                path={ROUTE.EQUIPMENT_CRAFTING}
                component={EquipmentCrafting}
                bannerData={createBannerData(gachaBanner, "Legendary Forge", "Crafting")}
              />
              <Layout
                exact
                path={ROUTE.EQUIPMENT_GACHA}
                component={EquipmentGacha}
                bannerData={createBannerData(gachaBanner, "Lady of the lake", "Gacha")}
              />
              <Layout exact path={ROUTE.NOT_FOUND} component={NotFoundPage} />
              <Layout
                path={ROUTE.LAND_MINT}
                component={LandMint}
                exact
                bannerData={createBannerData(landBannerImage, "Land Mint", "Land Mint")}
              />
              <Layout
                path={ROUTE.BLACK_MARKET}
                component={BlackMarket}
                exact
                bannerData={createBannerData(heroesBannerImage, "Black", "Market")}
              />
              <Redirect to={ROUTE.HOME} />
            </Switch>
            <ModalListener />
          </BrowserRouter>
          <GlobalModals />
        </ThemeProvider>
      </GlobalErrorBoundary>
    </div>
  );
}
