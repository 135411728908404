import React from "react";
import StakingModal from "src/app/components/Staking/StakingModal";
import EquipmentTransferModal from "src/app/components/Equipment/Commons/EquipmentTransferModal";
import LoadingModal from "src/app/components/Commons/Modals/LoadingModal";
import ErrorModal from "src/app/components/Commons/Modals/ErrorModal";
import TxTrackingModal from "src/app/components/Commons/Modals/TxTrackingModal";
import EquipmentExchangeModal from "src/app/components/Equipment/Details/EquipmentExchangeModal";
import EquipmentUpgradeModal from "src/app/components/Equipment/Commons/EquipmentUpgradeModal";
import DemiModal from "src/app/components/Demi/Summon/DemiModal";
import MaterialTransferModal from "src/app/components/Materials/Commons/MaterialTransferModal";
import DownloadModal from "src/app/components/Commons/Modals/DownloadModal";
import GuildCreationModal from "src/app/components/Guild/Common/GuildCreationModal";
import EquipmentDismantleModal from "src/app/components/Equipment/Commons/EquipmentDismantleModal";
import SkillbookActionModal from "src/app/components/Commons/Modals/SkillbookActionModal";
import RuneEquipmentModal from "src/app/components/Knight/RuneEquipment/RuneEquipmentModal";

export default function GlobalModals() {
  return (
    <div>
      <StakingModal />
      <EquipmentTransferModal />
      <EquipmentExchangeModal />
      <EquipmentUpgradeModal />
      <MaterialTransferModal />
      <DemiModal />
      <LoadingModal />
      <DownloadModal />
      <ErrorModal />
      <TxTrackingModal />
      <GuildCreationModal />
      <EquipmentDismantleModal />
      <SkillbookActionModal />
      <RuneEquipmentModal />
    </div>
  );
}
