import { MarketReducer } from "src/app/types/reducer-state";
import { marketActionTypes } from 'src/app/actions/marketAction';

const initialState: MarketReducer = {
  tabNumber: 0,
  refreshBlackMarket: false,
};

export default function accountReducer(state = initialState, action: any): MarketReducer {
  switch (action.type) {
    case marketActionTypes.CHANGE_TAB: {
      return {
        ...state,
        tabNumber: action.payload
      }
    }
    case marketActionTypes.REFRESH_BLACK_MARKET: {
      return {
        ...state,
        refreshBlackMarket: action.payload
      }
    }
    default:
      return state;
  }
}
