import { Tooltip, Box } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InputGroup from "src/app/components/Commons/InputGroup";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import EquipmentSelect from "src/app/components/Equipment/Crafting/Rune/EquipmentSelect";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import { BLACKSMITH_RATE, RARITY_LABEL } from "src/app/configs/constants";
import ENV from "src/app/configs/env";
import { ENHANCED_STONE_RATES } from "src/app/configs/equipment/crafting";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { MATERIALS } from "src/app/configs/materials/materials";
import useBlacksmithData from "src/app/hooks/useBlacksmithData";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import { UserItem } from "src/app/types/equipment";
import { ItemRecipe, Material, UserMaterial } from "src/app/types/materials";
import { findByValue } from "src/app/utils/helpers";
import faraLogo from "src/assets/images/tokens/fara.png";

type CraftingRequirementsProps = {
  selectedItemRecipe: ItemRecipe;
  userMaterials: UserMaterial[];
  userItems: UserItem[];
  craftingNumber: number;
  isChakraRune: boolean;
  handleAction: VoidFunction;
  error: string;
  setError: (msg: string) => void;
  faraBalance: number;
  craftingAmount: string;
  setCraftingAmount: (amount: string) => void;
  isRuneLevelUp: boolean;
};

export default function CraftingRequirements(props: CraftingRequirementsProps) {
  const history = useHistory();
  const { requiredItemIds, selectedRune } = useSelector((state: any) => state.rune);
  const {
    selectedItemRecipe,
    userMaterials,
    userItems,
    craftingNumber,
    isChakraRune,
    handleAction,
    error,
    faraBalance,
    setError,
    craftingAmount,
    setCraftingAmount,
    isRuneLevelUp,
  } = props;
  const [selectedStone, setSelectedStone] = useState<number>();
  const { address } = useSelector((state: any) => state.account);
  const { blacksmithData } = useBlacksmithData(address);
  const totalFaraAmount = selectedItemRecipe ? selectedItemRecipe.tokenFee * +craftingAmount : 0;
  const [needApprove] = useFetchingAllowance(ENV.CONTRACT.EQUIPMENT_CRAFTING);
  function redirectToDetail(itemId: number) {
    history.push(`/material/${itemId}`);
    modalService.close();
  }

  function selectEnhancedStone(stone: Material) {
    setSelectedStone(stone.id);
    modalService.close();
  }
  function onCraftingAmountChanged(e: any) {
    setError("");
    const value = e.target.value;
    setCraftingAmount(value > 10 ? 10 : value);
  }

  function openChooseEnhancementStoneModal() {
    const stones = Object.values(MATERIALS)
      .filter((material: Material) => ENHANCED_STONE_RATES[material.id])
      .map((material: Material) => material);
    modalService.show(BasicModalContent, {
      title: "Your enhancement stones",
      className: "basic-modal--normal-lh",
      content: (
        <Box display="flex" justifyContent="center">
          {stones.map((material: Material, index: number) => {
            const stoneRate = ENHANCED_STONE_RATES[material.id];
            const userMaterial: UserMaterial = findByValue(userMaterials, "id", material.id) ?? {
              id: material.id,
              owner: address,
              material: material,
              price: 0,
              amount: 0,
              available: 0,
            };
            return (
              <div key={index} onClick={() => selectEnhancedStone(material)}>
                <MaterialIcon
                  material={material}
                  userMaterial={userMaterial}
                  showBalance={true}
                  postfix={` - boost ${stoneRate}%`}
                />
              </div>
            );
          })}
        </Box>
      ),
    });
  }

  function getImprovedRateText(index: number, itemRecipe: ItemRecipe) {
    let improvedRate = blacksmithData?.level * BLACKSMITH_RATE;
    if (itemRecipe.maxRarityImproved <= 10) improvedRate /= 2;
    let sign = "+";

    if (index === 0) {
      sign = "-";
    } else if (index !== itemRecipe.itemIds.length - 1) {
      return;
    }

    return (
      <b className="text-9 fs-2">
        {" "}
        {sign} {improvedRate}%
      </b>
    );
  }

  const renderLevelUpContent = () => {
    if (!selectedRune && isRuneLevelUp) return <></>;
    return (
      <>
        {selectedItemRecipe && (
          <div className="item-crafting__block">
            <div className="dashed-title">
              <span>Requirements</span>
            </div>
            <div className="item-crafting__materials-row">
              {selectedItemRecipe.materialIds.map((materialId, index) => {
                const userMaterial = userMaterials?.filter(
                  (userMaterial: UserMaterial) => userMaterial.id === +materialId
                );
                const requiredAmount = selectedItemRecipe.materialAmounts[index] * craftingNumber;

                return (
                  <MaterialIcon
                    key={index}
                    userMaterial={userMaterial?.[0]}
                    material={MATERIALS[materialId]}
                    requiredAmount={requiredAmount}
                    className="item-crafting__materials-material"
                    submitText="Detail"
                    onSubmit={() => redirectToDetail(materialId)}
                  />
                );
              })}
              {selectedItemRecipe.requiredItemRarity?.map((rarity, index) => {
                const requiredAmount = selectedItemRecipe.requiredItemAmount?.[index]! * craftingNumber;
                const selectedItemId = requiredItemIds[index];
                const userItem = userItems?.filter((userItem: UserItem) => +userItem.id === +selectedItemId);
                return (
                  <EquipmentSelect
                    rarity={rarity}
                    key={index}
                    userItem={userItem?.[0]}
                    className="item-crafting__materials-material"
                    requiredAmount={requiredAmount}
                    selectedItemId={selectedItemId}
                    selectedItemAmount={userItem?.[0]?.available ?? 0}
                  />
                );
              })}
            </div>
            <div className="dashed-title">
              <span>Success Rate</span>
            </div>
            <div className="flex-column-center">
              <MaterialIcon
                material={MATERIALS[selectedRune?.material?.id + 1]}
                className="item-crafting__materials-material"
                submitText="Detail"
                onSubmit={() => redirectToDetail(selectedRune?.material?.id + 1)}
              />
              <div className="">
                {MATERIALS[selectedRune?.material?.id]?.displayName} Level{" "}
                <span className="text-9">{MATERIALS[selectedRune?.material?.id]?.level}</span> {"->"}{" "}
                <span className="text-9">{MATERIALS[selectedRune?.material?.id + 1]?.level}</span>
              </div>
            </div>
            <div className="mt-2 item-crafting__materials-chance">
              <span className="text-gray">Success Rate:</span> <b>{selectedItemRecipe.successRate}%</b>
              {Math.floor(blacksmithData.level / 2) > 0 && (
                <span className="success-text fs-3">
                  <b>+ {Math.floor(blacksmithData.level / 2)}%</b>{" "}
                </span>
              )}
            </div>
            <div className="mt-4">
              <div className="dashed-title">
                <span>Level Up</span>
              </div>
              <div>Balance: {faraBalance.toString()} FARA</div>
              <div className="mt-1 mb-1">
                <span>Level Up Cost: </span>
                <span className="text-9">{totalFaraAmount} FARA</span>
                <img className="token token--small top-5 ml-1" src={faraLogo} alt="" />
              </div>
              <div className="error-text mt-2">{error}</div>
              <div className="btn full-width mt-2 mb-3" onClick={handleAction}>
                Level Up
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  const renderCraftingContent = () => {
    return (
      <>
        {selectedItemRecipe && (
          <div className="item-crafting__block">
            <div className="dashed-title">
              <span>Requirements</span>
            </div>
            <div className="item-crafting__materials-row">
              {selectedItemRecipe.materialIds.map((materialId, index) => {
                const userMaterial = userMaterials?.filter(
                  (userMaterial: UserMaterial) => userMaterial.id === +materialId
                );
                const requiredAmount = selectedItemRecipe.materialAmounts[index] * craftingNumber;

                return (
                  <MaterialIcon
                    key={index}
                    userMaterial={userMaterial?.[0]}
                    material={MATERIALS[materialId]}
                    requiredAmount={requiredAmount}
                    className="item-crafting__materials-material"
                    submitText="Detail"
                    onSubmit={() => redirectToDetail(materialId)}
                  />
                );
              })}
              {selectedItemRecipe.requiredItemRarity?.map((rarity, index) => {
                const requiredAmount = selectedItemRecipe.requiredItemAmount?.[index]! * craftingNumber;
                const selectedItemId = requiredItemIds[index];
                const userItem = userItems?.filter((userItem: UserItem) => +userItem.id === +selectedItemId);
                return (
                  <EquipmentSelect
                    rarity={rarity}
                    key={index}
                    userItem={userItem?.[0]}
                    className="item-crafting__materials-material"
                    requiredAmount={requiredAmount}
                    selectedItemId={selectedItemId}
                    selectedItemAmount={userItem?.[0]?.available ?? 0}
                  />
                );
              })}
            </div>
            <div className="mt-1 item-crafting__materials-chance">
              <span className="text-gray">Success Rate:</span> <b>{selectedItemRecipe.successRate}%</b>
              {!isChakraRune && (
                <>
                  <span> + </span>
                  {selectedStone && <b className="text-9">{ENHANCED_STONE_RATES[selectedStone]}%</b>}
                  {(selectedItemRecipe.materialIds?.length ?? 0) > 0 && (
                    <span className="item-crafting__materials-enhanced">
                      {selectedStone ? (
                        <MaterialIcon
                          material={MATERIALS[selectedStone]}
                          userMaterial={userMaterials?.find((m) => m.id === selectedStone)}
                          requiredAmount={+craftingAmount}
                          onSelect={() => setSelectedStone(undefined)}
                        />
                      ) : (
                        <Tooltip
                          title="Choose an Enhancement Stone to boost your crafting success rate."
                          placement="top"
                          arrow
                        >
                          <img
                            src={require(`src/assets/images/hero-details/slot/inventory-slot.png`)}
                            alt=""
                            onClick={openChooseEnhancementStoneModal}
                          />
                        </Tooltip>
                      )}
                    </span>
                  )}
                </>
              )}
            </div>
            {!isChakraRune && (
              <div className="mt-4">
                <div className="dashed-title">
                  <span>Rarity Rate</span>
                </div>
                <div className="fs-3" style={{ color: "#C4C4C4" }}>
                  {(selectedItemRecipe.itemIds ?? []).map((item, index: number) => (
                    <Box key={index} display="flex">
                      <div className="item-crafting__rarity-level">
                        <ItemIconPreview className="item-crafting__rarity-image" item={EQUIPMENT[item]} showDetails />
                      </div>
                      <Box flex="1" alignSelf="center" className="ml-1">
                        {RARITY_LABEL[EQUIPMENT[item].rarity]}
                      </Box>
                      <Box alignSelf="center">
                        <b>{selectedItemRecipe.itemRates[index]}%</b>
                        {blacksmithData.level > 0 && getImprovedRateText(index, selectedItemRecipe)}
                      </Box>
                    </Box>
                  ))}
                </div>
              </div>
            )}
            <div className="mt-4">
              <div className="dashed-title">
                <span>Crafting</span>
              </div>
              <InputGroup
                className="text-field--default"
                value={craftingAmount}
                handleChange={onCraftingAmountChanged}
                symbol="FARA"
                balance={faraBalance.toString()}
                label="Amount"
                placeholder="Crafting amount"
                error={error}
                hideMaxBtn
              />
              <div className="mt-1 mb-1">
                <span>Crafting Cost: </span>
                <span className="text-9">{totalFaraAmount} FARA</span>
                <img className="token token--small top-5 ml-1" src={faraLogo} alt="" />
              </div>
              <div className="btn full-width mt-4 mb-3" onClick={handleAction}>
                {!isChakraRune && needApprove ? "Approve FARA" : "Craft"}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return isRuneLevelUp ? renderLevelUpContent() : renderCraftingContent();
}
