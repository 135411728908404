import React, { useState, useEffect, useRef } from "react";
import ENV from "src/app/configs/env";
import { Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { DemiKnight } from "src/app/types/demiKnight";
import useKnightPreview from "src/app/hooks/useKnightPreview";
import { isKnightDead } from "src/app/utils/helpers";
import { KnightStats } from "src/app/types/knight";

interface DemiPreviewProps {
  demi: DemiKnight;
  knightStats: KnightStats;
  onOpenPopup: any;
  isOwner: boolean;
  slotItems: any[9];
  setSlotItems: any;
}

export default function DemiPreview(props: DemiPreviewProps) {
  const { demi, isOwner, slotItems, setSlotItems, knightStats } = props;

  const { modal } = useSelector((state: any) => state.global);
  const fetchingInterval = useRef<any>();
  const [hideSlots, setHideSlots] = useState(false);
  const [heroImage, setHeroImage] = useState<string>();
  const [refreshingCount, renderAllSlotItems, renderInventory, renderChibi, renderImageButton, chibiImage] =
    useKnightPreview(demi, slotItems, setSlotItems, isOwner, true, knightStats, demi.isOffChain);
  const isDead = isKnightDead(demi.owner);

  useEffect(() => {
    const heroImage = `${ENV.URL.FARALAND}/images/souls/png/${demi.id}.png?t=${refreshingCount}`;
    setHeroImage(heroImage);

    if (modal.demiModal.active) {
      setHideSlots(true);
      fetchingInterval.current = setInterval(() => {
        setHeroImage(heroImage);
      }, 10000);
      return () => {
        clearInterval(fetchingInterval.current);
      };
    }
  }, []); // eslint-disable-line

  return (
    <div className="knight-preview">
      <div className="mb-2">
        <div className={`knights__item-id ${demi.element.toLowerCase()}`}>#{demi.id}</div>
        <div className={`knights__item-id demi ml-2`}>Demi</div>
        {isDead && <div className={`knights__item-id dead ml-2`}>Dead</div>}
      </div>
      <div className="knight-preview__wrapper">
        <div className="knight-preview__wrapper-race">
          <span>{demi.displayRace}</span>
          <img
            alt={demi.element}
            src={require(`src/assets/images/hero-details/type/${demi.element.toLowerCase()}.png`)}
            className="knight-preview__wrapper-icon"
          />
        </div>
        {!modal.demiModal.active && (
          <div className="knight-preview__wrapper-name">
            <span>{demi.name ? demi.name : "NO NAME"}</span>
            <Tooltip title={hideSlots ? "Show Item Slots" : "Hide Item Slots"} placement="top" arrow>
              <div className="btn knight-preview__wrapper-btn" onClick={() => setHideSlots(!hideSlots)}>
                {hideSlots ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </div>
            </Tooltip>
            {renderImageButton()}
          </div>
        )}
        {demi.id !== undefined && (
          <img
            src={heroImage}
            className={`knight-preview__wrapper-hero ${demi.race} ${isDead && "filter-gray"} ${
              chibiImage ? "hidden" : ""
            }`}
            alt=""
          />
        )}
        {renderChibi()}
        {!hideSlots && renderAllSlotItems()}
        <img
          className="knight-preview__wrapper-base"
          src={require(`src/assets/images/hero-details/ground.png`)}
          alt="base"
          width="10%"
        />
        <div className="knight-preview__wrapper-bg">
          <img src={require(`src/assets/images/demi/demi-bg.png`)} alt="" />
        </div>
      </div>
      {/*<div className="knight-preview__skills">
        <div className="knight-preview__skills-slots">
          <Tooltip title="Skill 1" placement="top">
            <img
              className="knight-preview__skills-item"
              src={require(`src/assets/images/hero-details/slot/skill-slot.png`)}
              alt="Skill 1"
            />
          </Tooltip>
          <Tooltip title="Skill 2" placement="top">
            <img
              className="knight-preview__skills-item"
              src={require(`src/assets/images/hero-details/slot/skill-slot.png`)}
              alt="Skill 2"
            />
          </Tooltip>
          <Tooltip title="Skill 3" placement="top">
            <img
              className="knight-preview__skills-item"
              src={require(`src/assets/images/hero-details/slot/skill-slot.png`)}
              alt="Skill 3"
            />
          </Tooltip>
          <Tooltip title="Skill 4" placement="top">
            <img
              className="knight-preview__skills-item"
              src={require(`src/assets/images/hero-details/slot/skill-slot.png`)}
              alt="Skill 4"
            />
          </Tooltip>
        </div>
      </div>*/}
      {isOwner && demi.registered && renderInventory()}
    </div>
  );
}
