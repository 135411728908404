import React, { useState, useEffect, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  StylesProvider,
  TextField,
} from "@material-ui/core";
import { INIT_MATERIAL_FILTER, SORT_BY } from "src/app/configs/constants";
import { getFilterCount, getMaterialFilterFromParams } from "src/app/utils/filterHelper";
import { useQueryString } from "use-route-as-state";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { debounce } from "lodash";
import { MaterialFilterType } from "src/app/types/materials";
// import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

export default function MaterialListFilter() {
  const [filterCount, setFilterCount] = useState(0);
  const [queryParams, setQueryParams] = useQueryString();
  const [materialFilters, setMaterialFilters] = useState<MaterialFilterType>(getMaterialFilterFromParams(queryParams));
  const [itemIdInput, setItemIdInput] = useState<any>(materialFilters.id);
  const [materialNameInput, setMaterialNameInput] = useState<any>(materialFilters.name);
  const [showFilter, setShowFilter] = useState(false);
  // const [rarityValue, setRarityValue] = useState<any>(materialFilters.rarity);
  const disableFilter = materialFilters.offering || materialFilters.owned || materialFilters.listings;
  const debounceSetParams = useCallback(debounce(setQueryParams, 500), []);

  useEffect(() => {
    setFilterCount(getFilterCount(materialFilters));
  }, [materialFilters]);

  useEffect(() => {
    const newFilters = getMaterialFilterFromParams(queryParams);
    setMaterialFilters({ ...newFilters });
  }, [queryParams]);

  const handleInputChange = (category: string) => (e: any) => {
    const value = e.target.value;

    if (category === "name") {
      setMaterialNameInput(value);
    } else if (category === "id") {
      setItemIdInput(value);
    } else {
      return;
    }

    debounceSetParams({
      ...queryParams,
      [category]: value.toLowerCase()
    });
  };

  // const handleRarityChange = (_e: any, value: any) => {
  //   if (value === null) {
  //     setRarityValue("");
  //   } else {
  //     setRarityValue(value);
  //   }
  //   setQueryParams({
  //     ...queryParams,
  //     rarity: value ?? "",
  //   });
  // };

  const handleSelectionChange = (category: string) => (e: any) => {
    setQueryParams({
      ...queryParams,
      [category]: e.target.value,
    });
  };

  function handleClearFilters() {
    setMaterialFilters(INIT_MATERIAL_FILTER);
    setItemIdInput("");
    setMaterialNameInput("");
    setQueryParams({});
  }

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  function renderSelectionBox(category: string, values: any, constant: any) {
    return (
      <div className={category && category !== "sortBy" ? `mb-4` : ""}>
        {category !== "sortBy" && (
          <div className="dashed-title">
            <span>{category === "sortBy" ? "Sort By" : "Slot Type"}</span>
          </div>
        )}
        <FormControl
          variant="outlined"
          className="input mr-4"
          style={{ width: `${category !== "sortBy" ? "100%" : "200px"}` }}
        >
          <InputLabel htmlFor="outlined-age-native-simple">
            {category === "sortBy" ? "Sort By" : "Slot Type"}
          </InputLabel>
          <Select
            label={category}
            native
            value={values}
            onChange={handleSelectionChange(category)}
            style={{ padding: `${category !== "sortBy" && "9px"}` }}
          >
            {Object.keys(constant).map((key: string) => {
              return (
                <option value={constant[key]} key={key}>
                  {constant[key]}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  }

  function renderNameField(category: string, label: string) {
    return (
      <div className={category && category !== "id" ? `mb-4` : ""}>
        {category !== "id" && (
          <div className="dashed-title mb-4">
            <span>{label}</span>
          </div>
        )}
        <StylesProvider injectFirst>
          <TextField
            value={category === "id" ? itemIdInput : materialNameInput}
            className={`input ${category !== "id" && "market__input"}`}
            label={label}
            type={category === "id" ? "number" : "text"}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={handleInputChange(category)}
          />
        </StylesProvider>
      </div>
    );
  }

  // function renderToggleButtons(category: string) {
  //   return (
  //     <div className="mb-2">
  //       <div className="dashed-title">
  //         <span>{category}</span>
  //       </div>
  //       <ToggleButtonGroup
  //         value={rarityValue}
  //         className="market__filter-rarity"
  //         exclusive
  //         onChange={handleRarityChange}
  //       >
  //         {RARITY_LABEL.map((rarity) => (
  //           <ToggleButton value={rarity} key={rarity}>
  //             {rarity}
  //           </ToggleButton>
  //         ))}
  //       </ToggleButtonGroup>
  //     </div>
  //   );
  // }

  return (
    <div className={`market__filter-container ${disableFilter ? "disabled" : ""}`}>
      <div className="market__filter-button">
        <div className="btn" onClick={handleShowFilter}>
          Filters <ArrowDropDownIcon />
        </div>
      </div>
      <div className={`market__filter ${showFilter && "market__filter--mobile"} fade-in`}>
        <div className="market__filter-header">
          <div className="fw-4 top-1">FILTERS ({filterCount})</div>
          <div className="btn btn--small" onClick={handleClearFilters}>
            Clear Filter
          </div>
        </div>
        <div className="flex mb-4">
          {renderSelectionBox("sortBy", materialFilters.sortBy, SORT_BY)}
          {renderNameField("id", "Material ID")}
        </div>
        {renderNameField("name", "Material Name")}
        {/* {renderToggleButtons("Rarity")} */}
      </div>
    </div>
  );
}
