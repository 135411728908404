import React, { useRef, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core";
import { useEffect } from "react";
import _ from "lodash";
import { INTERVAL, LIMIT } from "src/app/configs/constants";
import { Pagination } from "@material-ui/lab";
import { fetchRuneCraftingHistory } from "src/app/services/api/faralandService";
import { useSelector } from "react-redux";
import { renderWalletAddress } from "src/app/utils/renderHelpers";
import { RuneCraftingHistory } from "src/app/types/materials";
import MaterialIconPreview from "src/app/components/Materials/Commons/MaterialIconPreview";

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#111121",
      color: theme.palette.common.white,
      fontFamily: `"American Captain", sans-serif`,
      textTransform: "uppercase",
      fontWeight: 500,
      fontSize: 18,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export default function RuneCraftHistory() {
  const [craftHistory, setCraftHistory] = useState<RuneCraftingHistory[]>([]);
  const craftHistoryInterval = useRef<any>();
  const { address } = useSelector((state: any) => state.account);
  const isEmpty = _.isEmpty(craftHistory);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    _fetchCraftHistory(page);
    craftHistoryInterval.current = setInterval(() => {
      _fetchCraftHistory(page);
    }, INTERVAL.REFETCH_CRAFT_HISTORY);

    return () => {
      clearInterval(craftHistoryInterval.current);
    };
  }, [page, address]); // eslint-disable-line

  useEffect(() => {
    if (total && total < LIMIT.CRAFT_HISTORY) {
      handleChangePage(1);
    }

    const newTotalPage = Math.ceil(total / LIMIT.CRAFT_HISTORY);
    setTotalPage(newTotalPage);

    if (newTotalPage && page > newTotalPage) {
      handleChangePage(1);
    }
  }, [total]); // eslint-disable-line

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  async function _fetchCraftHistory(page: number) {
    const res = await fetchRuneCraftingHistory(page);
    if (res) {
      setCraftHistory(res.histories);
      setTotal(res.total);
    }
  }

  return (
    <div className={`equipment__table item-crafting__history cm-table ${isEmpty ? "cm-table--empty" : ""} mt-10`}>
      <div className="cm-table__header">
        <div className="cm-table__title">Crafting History</div>
      </div>
      <Grid container>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>Address</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Materials</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty ? (
                craftHistory.map((row, eid) => {
                  if (row.materials.length === 0) return <StyledTableRow key={eid} />;
                  const itemOccurrence: number[] = Object.values(
                    _.sortBy(row.materials.map((material) => material?.id)).reduce(function (prev, cur) {
                      prev[cur] = (prev[cur] || 0) + 1;
                      return prev;
                    }, {})
                  );
                  return (
                    <StyledTableRow key={eid}>
                      <StyledTableCell>
                        {row.owner && renderWalletAddress(row.owner, row.spaceDomain, 4)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="flex-center-start">
                          <div className={`mr-2 ${row.status === "Success" ? "text-9" : "text-red"}`}>
                            {row.type} {row.status}
                          </div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="flex-center-start item-crafting__history-row nice-scroll nice-scroll--horizontal">
                          {_.orderBy(_.uniq(row.materials), ["id"]).map((material, id) => {
                            return (
                              <div key={id} className="flex-center-start">
                                <MaterialIconPreview
                                  className="item-crafting__history-image"
                                  material={material}
                                  showDetails
                                  isRune
                                  withdrawnAmount={itemOccurrence[id]}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>{row.createdAt}</StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell className="cm-table__no-record">
                    <img src={require("src/assets/images/icons/empty-bids.svg")} width={60} height={60} alt="" />
                    <div>No History Yet</div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {totalPage > 1 && (
          <div className="market__pagination mt-5">
            <Pagination
              count={totalPage}
              page={page}
              onChange={(_, val) => handleChangePage(val)}
              variant="outlined"
              color="primary"
            />
          </div>
        )}
      </Grid>
    </div>
  );
}
