import React from "react";
import { Item } from "src/app/types/equipment";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import ItemInfo from "src/app/components/Equipment/Commons/ItemInfo";

type BurnItemProps = {
  itemId: number;
};

export default function BurnItemModal(props: BurnItemProps) {
  const { itemId } = props;
  const item: Item = EQUIPMENT[itemId];
  
  function showEquipmentInfoModal(itemId: number) {
    modalService.show(BasicModalContent, {
      content: (
        <div>
          <ItemInfo itemId={itemId} />
        </div>
      ),
    });
  }
  return (
    <BasicModalContent
      submitText="Details"
      onSubmit={() => showEquipmentInfoModal(itemId)}
      content={
        <div className="flex-column-center">
          <ItemIconPreview item={item} isMarketplace isBurn />
          <div className="fs-4 mt-5">You got</div>
          <div className="hero-burn__item-title">{item.name}</div>
        </div>
      }
    />
  );
}
