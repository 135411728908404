import { BannerData, LandSummaryData, MaterialSummaryData, SummaryData } from "src/app/types/common";

export function createBannerData(background: string, title?: string, subtitle?: string): BannerData {
  return {
    background,
    title,
    subtitle,
  };
}

export function createSummaryData(data: any): SummaryData {
  if (!data) {
    return {
      knightCount: 0,
      demiCount: 0,
      equipmentCount: 0,
      heroVolume: "0",
      demiVolume: "0",
      heroHighestPrice: 0,
      highestDemiPrice: 0,
      numberActiveUser: 0,
      tradeCount: 0,
      itemSold: 0,
      itemVolume: 0,
      itemHighestPrice: 0
    }
  }

  const summaryData = data.data.summary[0];

  return {
    knightCount: summaryData.knightCount,
    demiCount: summaryData.demiCount,
    equipmentCount: summaryData.equipmentCount,
    heroVolume: summaryData.totalVolume,
    demiVolume: summaryData.totalDemiVolume,
    heroHighestPrice: summaryData.highestPrice,
    highestDemiPrice: summaryData.highestDemiPrice,
    numberActiveUser: summaryData.numberActiveUser,
    tradeCount: summaryData.tradeCount,
    itemSold: summaryData.totalNumberEquipmentSold,
    itemVolume: summaryData.totalVolumeEquipmentSold,
    itemHighestPrice: summaryData.highestPriceEquipment
  };
}

export function createMaterialSummaryData(data: any): MaterialSummaryData {
  if (!data) {
    return {
      materialSold: 0,
      totalVolume: "0",
      highestPrice: 0
    }
  }

  const summaryData = data.data.summary[0];

  return {
    materialSold: summaryData.materialSold,
    totalVolume: summaryData.totalVolume,
    highestPrice: summaryData.highestPrice,
  };
}


export function createLandSummaryData(data: any): LandSummaryData {
  if (!data) {
    return {
      landCount: 0,
      totalVolume: "0",
      highestPrice: 0
    }
  }

  const summaryData = data.data.summary[0];

  return {
    landCount: summaryData.tradeCount,
    totalVolume: summaryData.totalVolume,
    highestPrice: summaryData.highestPrice,
  };
}