import React, { memo, useState, useEffect } from "react";
import { useQueryString } from "use-route-as-state";
import { getLandFilterFromParams } from "src/app/utils/filterHelper";
import { LandFilterType } from "src/app/types/land";

interface toggleProps {
  isLoading: boolean;
}

const LandSwitcher = memo((props: toggleProps) => {
  const [queryParams, setQueryParams] = useQueryString();
  const [landFilters, setLandFilters] = useState<LandFilterType>(getLandFilterFromParams(queryParams));
  const offering = landFilters.offering;
  const owned = landFilters.owned;

  useEffect(() => {
    const newFilters = getLandFilterFromParams(queryParams);
    setLandFilters({ ...newFilters });
  }, [queryParams]);

  function handleToggleChange(owned: boolean, offering: boolean) {
    return setQueryParams({
      ...queryParams,
      owned: owned.toString(),
      offering: offering.toString(),
    });
  }

  return (
    <div className={`market__toggles mb-3 ${props.isLoading ? "soft-disabled" : ""}`}>
      <div className="toggle mr-3">
        <div>Offers</div>
        <input id="offering" type="checkbox" checked={offering} onChange={() => handleToggleChange(false, !offering)} />
        <label htmlFor="offering" />
      </div>

      <div className="toggle mr-3">
        <div>Owned</div>
        <input id="owned" type="checkbox" checked={owned} onChange={() => handleToggleChange(!owned, false)} />
        <label htmlFor="owned" />
      </div>
    </div>
  );
});

export default LandSwitcher;
