import React, { useState } from "react";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { ACTIONS } from "src/app/configs/constants";
import { useSelector } from "react-redux";
import { Material, UserMaterial } from 'src/app/types/materials';
import MaterialActionModal from "./MaterialActionModal";
import { ListItem, OfferItem } from "src/app/types/equipment";
import MarketTable from "src/app/components/Commons/MarketTable";

type MaterialListingTableProps = {
  material: Material;
  ownLists: ListItem[];
  offers: OfferItem[];
  lists: ListItem[];
  userMaterials: UserMaterial[];
};

export default function MaterialListingTable(props: MaterialListingTableProps) {
  const lists = props.lists;
  const offers = props.offers;
  const material = props.material;
  const ownLists = props.ownLists;
  const userMaterials = props.userMaterials;
  const [myListings, setMyListings] = useState(false);
  const { address } = useSelector((state: any) => state.account);
  const isEmpty = myListings ? ownLists.length === 0 : lists.length === 0;

  function toggleMyListings() {
    setMyListings(!myListings);
  }

  function onOpenPopup(type: number, selectedList?: ListItem) {
    modalService.show(MaterialActionModal, {
      type: type,
      isEquipmentMarket: false,
      item: material,
      selectedList: selectedList,
      arrayLists: lists,
      arrayOffers: offers,
      ownLists: ownLists,
      items: userMaterials
    });
  }

  function handleListItem() {
    onOpenPopup(ACTIONS.LIST_MATERIAL);
  }

  function renderButtons(row: any) {
    if (address === row.seller) {
      return (
        <div className="btn btn--small btn--yellow" onClick={() => onOpenPopup(ACTIONS.DELIST_MATERIAL)}>
          Cancel
        </div>
      )
    } else if (address) {
      return (
        <div className="btn btn--small" onClick={() => onOpenPopup(ACTIONS.BUY_MATERIAL, row)}>
          Buy
        </div>
      )
    }
  }

  return (
    <div className={`cm-table ${isEmpty ? "cm-table--empty" : ""}`}>
      <div className="cm-table__header">
        <div className="cm-table__title">Listings</div>
        {address && (
          <div className="flex-center-start bot-6">
            <div className="toggle mr-3">
              <div>My Listings</div>
              <input id="my-listings" type="checkbox" checked={myListings} onChange={toggleMyListings} />
              <label htmlFor="my-listings" />
            </div>
            <div className="btn" onClick={handleListItem}>
              List
            </div>
          </div>
        )}
      </div>
      <MarketTable
        headerTitles={["From", "Amt", "Price", "Time", ""]}
        cellTypes={["address", "amount", "price", "time"]}
        cellKeys={["seller", "amount", "price", "timestamp"]}
        isEmpty={isEmpty}
        isMyOrder={myListings}
        myOrderData={ownLists}
        orderData={lists}
        emptyMessage="No Listing Yet"
        renderBtnActions={renderButtons}
      />
    </div>
  );
}
