import React from 'react';
import overview from "src/assets/images/footer/project_overview.jpeg";

const ProjectOverview = () => {
  return ( 
    <div>
      <img src={overview} alt="Project Overview" style={{maxWidth: '100%'}}/>
    </div>
   );
}
 
export default ProjectOverview;