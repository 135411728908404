import React from "react";
import ENV from "src/app/configs/env";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import AddIcon from "@material-ui/icons/Add";
import { Item, RuleDestItem } from "src/app/types/equipment";
import { Tooltip } from "@material-ui/core";
import { RARITY_LABEL } from "src/app/configs/constants";

export interface ItemExchangeProps {
  srcItem: Item;
  ruleDestItems: RuleDestItem[];
}

const ItemExchange = (props: ItemExchangeProps) => {
  return (
    <div className="flex-center-start item-exchange__row nice-scroll nice-scroll--horizontal">
      <Tooltip title={`${props.srcItem.displayName} - ${RARITY_LABEL[props.srcItem.rarity]}`} arrow placement="top">
        <img
          className={`item-exchange__image`}
          src={`${ENV.URL.BASE_IMAGE_URL}/images/icon/178x178/${props.srcItem.id}.png`}
          alt=""
        />
      </Tooltip>
      <SwapHorizIcon className="item-exchange__icon item-exchange__icon--swap" />
      {props.ruleDestItems.map((rule, id, arr) => {
        return (
          <div key={id} className="flex-center-start">
            <Tooltip title={`${rule.item.displayName} - ${RARITY_LABEL[rule.item.rarity]}`} arrow placement="top">
              <div className="relative flex-center-start">
                <img
                  className={`item-exchange__image`}
                  src={`${ENV.URL.BASE_IMAGE_URL}/images/icon/178x178/${rule.item.id}.png`}
                  alt=""
                />
                <div className={`item-exchange__icon-amount`}>x{rule.amount}</div>
              </div>
            </Tooltip>
            {arr.length - 1 !== id && <AddIcon className="item-exchange__icon item-exchange__icon--add" />}
          </div>
        );
      })}
    </div>
  );
};

export default ItemExchange;
