import React, { ReactNode, useState } from "react";
import ruleFrame from "src/assets/images/event/rule-frame.svg";

export interface RuleProps {
  number: number;
  title: string;
  children: ReactNode;
}

export default function Rule({ number, title, children }: RuleProps) {
  const [expand, setExpand] = useState(false);

  return (
    <div>
      <div className={`event__rule ${expand && "event__rule--expand"}`}>
        <div className="event__rule-index">
          <img alt="rule-frame" src={ruleFrame} />
          <div>{number}</div>
        </div>
        {!expand && (
          <div className="event__rule-collapse slide-up" onClick={() => setExpand(true)}>
            <div className="event__rule-title mt-3 mb-3">{title}</div>
            <div className="event__rule-more">Click to see more</div>
          </div>
        )}
        {expand && (
          <div className="event__rule-expand slide-up">
            <div className="event__rule-title mb-4" onClick={() => setExpand(false)}>{title}</div>
            <div className="event__rule-content">
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}