import React, { useState, useEffect, useRef } from "react";
import Modal from "src/app/components/Commons/Modals/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalModal } from "src/app/actions/globalAction";
import KnightListContent from "src/app/components/Knight/KnightListContent";
import { Tab, Tabs, Tooltip } from "@material-ui/core";
import { formatNumber, getAnimatedJsonOptions } from "src/app/utils/helpers";
import {
  BASE_KNIGHT_EXP,
  INTERVAL,
  KNIGHT_TYPE,
  STAKING_CONFIG,
  STAKING_POOL,
} from "src/app/configs/constants";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import infoIcon from "src/assets/images/icons/info-icon-white.svg";
import { Lottie } from "@crello/react-lottie";
import { setStakingData } from "src/app/actions/stakingAction";
import useKnightLevelData from "src/app/hooks/useKnightLevelData";
import StakingFormDeprecated from "src/app/components/Staking/StakingFormDeprecated";
import StakingForm from "src/app/components/Staking/StakingForm";
import ENV from "src/app/configs/env";
import { calculateAPR } from "src/app/utils/calculators";
import StakingFormEmergency from "src/app/components/Staking/StakingFormEmergency";

export default function StakingModal() {
  const stakingDataInterval = useRef<any>();
  const dispatch = useDispatch();
  const { modal, web3Service, tokenPrice } = useSelector(
    (state: any) => state.global
  );
  const { address } = useSelector((state: any) => state.account);
  const [isLoading, setLoading] = useState(true);
  const [knightLevel, setKnightLevel] = useState();
  const [selectedPool, setSelectedPool] = useState(0);
  const [apr, setApr] = useState({ single: 0, liquidity: 0 });
  const [lpValue, setLpValue] = useState({ FARA: 0, BNB: 0, USD: 0 });

  const knightModal = modal.knightStaking;
  const knight = knightModal.data?.knight ?? {};
  const isDeprecatedStaking = selectedPool === STAKING_POOL.DEPRECATED;
  const [knightExp, levelUpAmount, convertExpToLevels] = useKnightLevelData(
    knight.id,
    KNIGHT_TYPE.IMMORTAL,
    knight.level,
    setKnightLevel,
    isDeprecatedStaking
  );

  /** Calculate APR & fetch liquidity pool data **/
  useEffect(() => {
    if (!web3Service || !knight.id) return;

    if (tokenPrice.FARA && lpValue.USD) {
      _setApr();
    }

    _setLpValue();
  }, [web3Service, knight.id, tokenPrice.FARA, lpValue.USD]); // eslint-disable-line

  /** Update staking data interval **/
  useEffect(() => {
    if (!address || !web3Service || !knight.id) return;

    _callStakingData(knight.id, address);
    stakingDataInterval.current = setInterval(() => {
      _callStakingData(knight.id, address, false);
    }, INTERVAL.STAKING_DATA);

    return () => {
      clearInterval(stakingDataInterval.current);
    };
  }, [web3Service, knight, address, selectedPool]); // eslint-disable-line

  async function _setLpValue() {
    try {
      const data = await web3Service.fetchLiquidityPool();
      const poolValueInUSD =
        data.bnbAmount * tokenPrice.BNB + data.faraAmount * tokenPrice.FARA;
      const lpValueInUSD = poolValueInUSD / data.lpSupply;
      const haftLpValue = lpValueInUSD / 2;

      setLpValue({
        FARA: haftLpValue / tokenPrice.FARA,
        BNB: haftLpValue / tokenPrice.BNB,
        USD: lpValueInUSD,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function _setApr() {
    try {
      const data = await web3Service.fetchTotalStakedAmount();
      setApr({
        single: calculateAPR(tokenPrice.FARA, data.single),
        liquidity: calculateAPR(tokenPrice.FARA, data.liquidity, lpValue.USD),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function _callStakingData(
    knightId: number,
    address: string,
    loading = true
  ) {
    setLoading(loading);

    try {
      const data = await web3Service.fetchStakingData(
        knightId,
        address,
        selectedPool
      );
      dispatch(setStakingData(data));
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }

  function closeModal() {
    dispatch(setGlobalModal("knightStaking"));
    setKnightLevel(undefined);
  }

  function changeSelectedPool(_e: any, value: number) {
    setSelectedPool(value);
  }

  return (
    <Modal
      className="super-large"
      isActive={knightModal.active}
      onClose={closeModal}
    >
      <div className="knight-staking">
        <div className="knight-staking__left slide-up-slow">
          <KnightListContent
            className="knights__item--full"
            knight={knight}
            isStakingPage={true}
            isLoading={isLoading}
          />
        </div>

        <div className="knight-staking__right">
          {isLoading ? (
            <div className="knight-staking__loading">
              <Lottie
                className="market__loading"
                config={getAnimatedJsonOptions(loadingJson)}
              />
            </div>
          ) : (
            <>
              <div className="knight-staking__info">
                <div className="mb-1">
                  <span>Level:</span>
                  <span className="knight-staking__highlight-text">
                    {knightLevel ?? knight.level}
                  </span>
                </div>
                <div className="mb-1">
                  <span>Next Level:</span>
                  <span className="knight-staking__highlight-text">
                    {formatNumber(knightExp)} /{" "}
                    {formatNumber(
                      (knightLevel ?? knight.level) * BASE_KNIGHT_EXP
                    )}{" "}
                    EXP
                  </span>
                  {levelUpAmount > 0 && knight.owner === address && (
                    <Tooltip
                      title={`Convert your EXPs to ${levelUpAmount} levels`}
                      arrow
                      placement="top"
                    >
                      <span className="plus-btn" onClick={convertExpToLevels}>
                        +
                      </span>
                    </Tooltip>
                  )}
                </div>
                {[
                  STAKING_POOL.LIQUIDITY_V2,
                  STAKING_POOL.LIQUIDITY_V3,
                ].includes(selectedPool) && (
                  <div className="mt-1">
                    <span>Information: </span>
                    <span className="fs-2">
                      {!!lpValue.USD ? (
                        <>
                          <span className="text-9">
                            1 {STAKING_CONFIG.LIQUIDITY.SYMBOL} LP{" "}
                          </span>
                          <span>= </span>
                          <span className="text-9">
                            {formatNumber(lpValue.FARA, 2)} FARA{" "}
                          </span>
                          <span>+ </span>
                          <span className="text-9">
                            {formatNumber(lpValue.BNB)} BNB{" "}
                          </span>
                          <span>
                            (
                            <span className="text-9">
                              {formatNumber(lpValue.USD, 2)}$
                            </span>
                            ) and
                          </span>
                        </>
                      ) : (
                        <span className="text-9">
                          {STAKING_CONFIG.LIQUIDITY.SYMBOL} LP
                        </span>
                      )}
                      <span> can be obtained </span>
                      <a
                        className="link-text link-text--color"
                        href={`https://pancakeswap.finance/v2/add/BNB/${ENV.CONTRACT.FARA_TOKEN}`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        here
                      </a>
                    </span>
                    <Tooltip
                      title="This is a more risky pool (suffering Impermanent Loss) compared to FARA pool by providing liquidity with both FARA and BNB."
                      arrow
                      placement="top"
                    >
                      <img className="info-icon" src={infoIcon} alt="" />
                    </Tooltip>
                  </div>
                )}
              </div>

              <div className="staking-form">
                <Tabs
                  value={selectedPool}
                  onChange={changeSelectedPool}
                  className="staking-form__tab"
                  variant="scrollable"
                  scrollButtons="off"
                >
                  {/*TODO: Re-enable Staking V3 */}
                  <Tab
                    label={
                      <div className="staking-form__tab-content">
                        <div className="staking-form__tab-title">
                          {STAKING_CONFIG.SINGLE.SYMBOL}
                        </div>
                        <div className="staking-form__tab-subtitle">
                          APR: {formatNumber(apr.single, 2)}%
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    label={
                      <div className="staking-form__tab-content">
                        <div className="staking-form__tab-title">
                          {STAKING_CONFIG.LIQUIDITY.SYMBOL} LP
                        </div>
                        <div className="staking-form__tab-subtitle">
                          APR: {formatNumber(apr.liquidity, 2)}%
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    label={
                      <div className="staking-form__tab-content">
                        <div className="staking-form__tab-title">
                          {STAKING_CONFIG.SINGLE.SYMBOL} V2
                        </div>

                        <div className="staking-form__tab-subtitle staking-form__tab-subtitle--deprecated">
                          Deprecated
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    label={
                      <div className="staking-form__tab-content">
                        <div className="staking-form__tab-title">
                          {STAKING_CONFIG.LIQUIDITY.SYMBOL} LP V2
                        </div>

                        <div className="staking-form__tab-subtitle staking-form__tab-subtitle--deprecated">
                          Deprecated
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    label={
                      <div className="staking-form__tab-content">
                        <div className="staking-form__tab-title">
                          {STAKING_CONFIG.SINGLE.SYMBOL} V1{" "}
                        </div>

                        <div className="staking-form__tab-subtitle staking-form__tab-subtitle--deprecated">
                          Deprecated
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    label={
                      <div className="staking-form__tab-content">
                        <div className="staking-form__tab-title">
                          {STAKING_CONFIG.LIQUIDITY.SYMBOL} LP V1
                        </div>
                        <div className="staking-form__tab-subtitle staking-form__tab-subtitle--deprecated">
                          Deprecated
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    label={
                      <div className="staking-form__tab-content">
                        <div className="staking-form__tab-title">
                          {STAKING_CONFIG.SINGLE.SYMBOL}
                        </div>
                        <div className="staking-form__tab-subtitle staking-form__tab-subtitle--deprecated">
                          Deprecated
                        </div>
                      </div>
                    }
                  />
                </Tabs>
                {selectedPool === STAKING_POOL.SINGLE_V3 && (
                  <StakingForm
                    knightId={knight.id}
                    selectedPool={selectedPool}
                    contractAddress={ENV.CONTRACT.SINGLE_V3_STAKING}
                  />
                )}
                {selectedPool === STAKING_POOL.LIQUIDITY_V3 && (
                  <StakingForm
                    knightId={knight.id}
                    selectedPool={selectedPool}
                    contractAddress={ENV.CONTRACT.LIQUIDITY_V3_STAKING}
                  />
                )}
                {selectedPool === STAKING_POOL.SINGLE_V2 && (
                  <StakingFormEmergency
                    knightId={knight.id}
                    selectedPool={selectedPool}
                  />
                )}
                {selectedPool === STAKING_POOL.LIQUIDITY_V2 && (
                  <StakingFormEmergency
                    knightId={knight.id}
                    selectedPool={selectedPool}
                  />
                )}
                {selectedPool === STAKING_POOL.DEPRECATED_SINGLE_V1 && (
                  <StakingFormEmergency
                    knightId={knight.id}
                    selectedPool={selectedPool}
                  />
                )}
                {selectedPool === STAKING_POOL.DEPRECATED_LIQUIDITY_V1 && (
                  <StakingFormEmergency
                    knightId={knight.id}
                    selectedPool={selectedPool}
                  />
                )}
                {selectedPool === STAKING_POOL.DEPRECATED && (
                  <StakingFormDeprecated
                    selectedPool={selectedPool}
                    contractAddress={ENV.CONTRACT.DEPRECATED_SINGLE_STAKING}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
