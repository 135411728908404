import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INTERVAL, TX_STATUS } from "src/app/configs/constants";
import { setBalance } from "src/app/actions/accountAction";
import Web3Service from "src/app/services/web3/Web3Service";
import { setGlobalModal, setSyncedBlock, setTokenPrice, setWeb3Service } from "src/app/actions/globalAction";
import { findByValue, formatBigNumber } from "src/app/utils/helpers";
import { setTrackingTxs } from "src/app/actions/txAction";
import { fetchTokenUsdPrice } from "src/app/services/api/coingeckoService";
import { querySyncedBlock } from "src/app/services/api/subgraphService";
import { marketClient } from "src/app/services/subgraph/client";

export default function useFetchingData() {
  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const { subgraphClient } = useSelector((state: any) => state.global);
  const { trackingTxs } = useSelector((state: any) => state.tx);

  const balanceInterval = useRef<any>();
  const txInterval = useRef<any>();
  const syncedBlockInterval = useRef<any>();

  useEffect(() => {
    /** Fetching BNB and FARA price **/
    _updateTokenPrice();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!web3Service) return;

    /** Interval fetching synced block **/
    _updateSyncedBlock(web3Service);
    syncedBlockInterval.current = setInterval(() => {
      _updateSyncedBlock(web3Service);
    }, INTERVAL.SYNCED_BLOCK);

    return () => {
      clearInterval(syncedBlockInterval.current);
    };
  }, [web3Service, subgraphClient]); // eslint-disable-line

  /** Fetching BNB and FARA balance **/
  useEffect(() => {
    const web3Service = _initiateWeb3Service();

    if (!address) return;

    _updateBalance(web3Service);
    balanceInterval.current = setInterval(() => {
      _updateBalance(web3Service);
    }, INTERVAL.BALANCE);

    return () => {
      clearInterval(balanceInterval.current);
    };
  }, [address]); // eslint-disable-line

  /** Tracking pending transaction **/
  useEffect(() => {
    if (trackingTxs.length === 0) return;

    _clearTxInterval();

    dispatch(setGlobalModal("txTracking", { active: true }));

    txInterval.current = setInterval(async () => {
      const updatedTxs = await web3Service.updateTxsStatus(trackingTxs);
      const pendingTx = findByValue(updatedTxs, "status", TX_STATUS.PENDING);

      if (!pendingTx) {
        _clearTxInterval();
        dispatch(setTrackingTxs(updatedTxs));
      }
    }, INTERVAL.TX);

    return () => {
      _clearTxInterval();
    };
  }, [trackingTxs.length]); // eslint-disable-line

  function _clearTxInterval() {
    clearInterval(txInterval.current);
  }

  async function _updateBalance(web3Service: Web3Service) {
    const bnbBalance = await web3Service.fetchBNBBalance(address);
    const faraBalance = await web3Service.fetchFaraBalance(address);
    const soulStoneBalance = await web3Service.fetchSoulStoneBalance(address);
    const emperorStoneBalance = await web3Service.fetchEmperorStoneBalance(address);
    const expJarBalance = await web3Service.fetchExpJarBalance(address);
    dispatch(
      setBalance({
        BNB: formatBigNumber(bnbBalance),
        FARA: formatBigNumber(faraBalance),
        SOUL_STONE: soulStoneBalance,
        EMPEROR_STONE: emperorStoneBalance,
        EXP_JAR: expJarBalance,
      })
    );
  }

  async function _updateTokenPrice() {
    const tokenPrice = await fetchTokenUsdPrice();
    dispatch(setTokenPrice(tokenPrice));
  }

  async function _updateSyncedBlock(web3Service: Web3Service) {
    const blockNumber = await querySyncedBlock(subgraphClient ?? marketClient);
    const blockData = await web3Service.fetchBlock(blockNumber);

    dispatch(
      setSyncedBlock({
        number: blockData.number,
        timestamp: blockData.timestamp,
      })
    );
  }

  function _initiateWeb3Service() {
    let service = web3Service;
    if (!service) {
      service = new Web3Service();
      dispatch(setWeb3Service(service));
    }
    return service;
  }
}
