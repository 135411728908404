import { useEffect, useState } from "react";
import { BASE_KNIGHT_EXP, MAX_LEVEL } from "src/app/configs/constants";
import { convertExp } from "src/app/actions/stakingAction";
import { useDispatch, useSelector } from "react-redux";
import { convertKnightExp } from "src/app/actions/knightAction";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import SoulStoneExpModal from "src/app/components/Commons/Modals/SoulStoneExpModal";
import ExpJarModal from "../components/Commons/Modals/ExpJarModal";

export default function useKnightLevelData(
  knightId: number,
  knightType: number,
  knightLevel: number,
  setKnightLevel: any,
  isDeprecatedStaking: boolean
) {
  const dispatch = useDispatch();

  const { web3Service } = useSelector((state: any) => state.global);
  const [levelUpAmount, setLevelUpAmount] = useState(0);
  const [knightExp, setKnightExp] = useState(0);
  const [remainingExp, setRemainingExp] = useState(0);

  useEffect(() => {
    let amount = 0;
    let remainingExp = knightExp;

    for (let i = knightLevel; i < MAX_LEVEL; i++) {
      const expRequired = i * BASE_KNIGHT_EXP;
      if (remainingExp < expRequired) break;
      remainingExp -= expRequired;
      amount += 1;
    }

    setLevelUpAmount(amount);
    setRemainingExp(remainingExp);
  }, [knightLevel, knightExp]);

  useEffect(() => {
    if (!web3Service || !knightId) return;
    _updateKnightExp();
  }, [web3Service, knightId, isDeprecatedStaking]); // eslint-disable-line

  function convertExpToLevels() {
    if (isDeprecatedStaking) {
      dispatch(convertExp(knightId, levelUpAmount, _onConvertSuccess));
    } else {
      dispatch(convertKnightExp(knightId, knightType, levelUpAmount, _onConvertSuccess));
    }
  }

  function openIncreaseEXPModal() {
    modalService.show(SoulStoneExpModal, {
      knightId: knightId,
      knightType: knightType,
      onSuccess: _updateKnightExp,
    });
  }

  function openUseExpJarModal() {
    modalService.show(ExpJarModal, {
      knightId: knightId,
      knightType: knightType,
      onSuccess: _updateKnightExp,
    });
  }
  
  async function _updateKnightExp() {
    let exp = await web3Service.fetchKnightExp(knightId, knightType, isDeprecatedStaking);
    setKnightExp(exp);
  }

  function _onConvertSuccess() {
    setKnightExp(remainingExp);
    setKnightLevel(knightLevel + levelUpAmount);
  }

  return [knightExp, levelUpAmount, convertExpToLevels as any, openIncreaseEXPModal as any, openUseExpJarModal as any];
}
