import React from "react";
import humanImg from "src/assets/images/lands/human-img.png";
import benefit from "src/assets/images/lands/benefits.png";
import { useMediaQuery } from "@material-ui/core";
import accumulate from "src/assets/images/lands/accumulate.png";
import perkGeneration from "src/assets/images/lands/perk-generation.png";
import freeClaiming from "src/assets/images/lands/free-claim.png";
import claimPortal from "src/assets/images/lands/claim-portal.png";
export default function LandDefinition() {
  const isMobile = useMediaQuery("(max-width: 900px)");

  return (
    <div className="land__mint-definition">
      <div className="container">
        <div className="land__mint-definition--container">
          <img src={humanImg} alt="Human" />
          <div className="land__mint-definition--content">
            <h1>Land System Definition</h1>
            <p>
              The land system is the latest feature in the Faraland ecosystem. Operating completely on-chain, it is a
              function that helps players get more perks and rewards. Each piece of Land is also a unique NFT sold in a
              limited quantity. Landowners will constantly gain a passive income from the land market fee. Besides,
              players can also assign their heroes onto Land Wars to retrieve valuable loot.
            </p>
          </div>
        </div>
        <div className="land__mint-benefit">
          <h1>Benefit Mechanism</h1>
          <p>
            Landowners’ passive income from market fees will be automatically distributed to their wallet addresses on a
            basis, making the feature a guarantee of benefits for players with a long-term dedication to the project.
          </p>
          {isMobile ? (
            <div className="land__mint-benefit--mobile">
              <img src={accumulate} alt="" />
              <img src={perkGeneration} alt="" />
              <img src={freeClaiming} alt="" />
              <img src={claimPortal} alt="" />
            </div>
          ) : (
            <img className="land__mint-benefit--img" src={benefit} alt="" />
          )}
        </div>
      </div>
    </div>
  );
}
