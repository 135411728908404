import React from "react";
import { Box, Grid, Popover } from "@material-ui/core";
import { STATS_LABEL } from "src/app/configs/constants";
import { KnightAttributes } from "src/app/types/attribute";
import { DemiKnight } from "src/app/types/demiKnight";
import { Knight, KnightStats } from "src/app/types/knight";

export interface KnightPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  knight: Knight | DemiKnight;
  knightStats: KnightStats;
  handlePopoverClose: () => void;
}

const labels = ["STR", "AGI", "INT", "LUK"];

export default function KnightPopover(props: KnightPopoverProps) {
  return (
    <Popover
      key={props.knight.id}
      style={{
        pointerEvents: 'none',
      }}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.handlePopoverClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      disableScrollLock
      disableAutoFocus
      disableRestoreFocus
    >
      <Box p={2} pb={0} width={400}>
        <div className="flex-center-start">
          <div className="stats__title">
            <span>Stats ({props.knightStats.totalBaseStats ?? 0})</span>
          </div>
        </div>
        <Grid container spacing={1} className="stats__container" style={{ marginTop: 10 }}>
          {Object.values(STATS_LABEL).map((stats, index) => {
            const data = props.knightStats.baseStats[stats.toLowerCase()] ?? {};
            return (
              <Grid item md={3} lg={3} className="stats__item" key={index} style={{ textAlign: "left" }}>
                <div className="stats__name">{labels[index]}</div>
                <div className="stats__value" style={{ justifyContent: "flex-start" }}>
                  <img
                    src={require(`src/assets/images/hero-details/stats/${stats.toLowerCase()}.svg`)}
                    alt={labels[index]}
                    style={{marginLeft: -6}}
                    width={28}
                  />
                  <div>{data}</div>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <div className="knight__body-parts" style={{ marginTop: 20 }}>
          <div className="flex-center-start">
            <div className="stats__title">Body Parts</div>
            <div className="stats__btn-container">
            </div>
          </div>
          <div className="body-part">
            {props.knight.attributes.map((attribute: KnightAttributes, index: number) => (
              <div className="body-part__item" key={index}>
                <img
                  src={require(`src/assets/images/hero-details/attributes/${props.knight.displayRace}/${attribute.traitType}.png`)}
                  alt={attribute.traitType}
                  width={60}
                />
                <div className="body-part__content">
                  <div className="body-part__name">{attribute.value}</div>
                  <div className={`body-part__rarity rarity-${attribute.displayRarity.toLowerCase()}`}>
                    <span>{attribute.displayRarity}</span>
                    {/* {attribute.isUpgraded && (
                      <img className="body-part__upgraded" src={sparklingIcon} alt="" />
                    )} */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Box>
    </Popover>
  );
}