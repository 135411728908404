import React, { useEffect, useState } from "react";
import { calculateStatsFromItems } from "src/app/utils/helpers";
import { STATS_LABEL } from "src/app/configs/constants";
import { getKnightStatsLabel } from "src/app/utils/mappingHelpers";
import { Knight, KnightStats } from "src/app/types/knight";
import { DemiKnight } from "src/app/types/demiKnight";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import KnightStatsDetailsModal from "src/app/components/Knight/Details/KnightStatsDetailsModal";
import LevelUpModal from "src/app/components/Knight/LevelUpModal";
import { useHistory } from "react-router-dom";
import { TraitStats } from "src/app/types/attribute";
import { Material } from "src/app/types/materials";

export default function useKnightData(
  knight: Knight | DemiKnight,
  knightStats: KnightStats,
  slotItems: number[],
  isDemi: boolean,
  equippedRunes?: Material[]
) {
  const history = useHistory();
  const [totalBaseStats, setTotalBaseStats] = useState(0);
  const [totalItemStats, setTotalItemStats] = useState(0);
  const [totalAllocatedStats, setTotalAllocatedStats] = useState(0);
  const [statsData, setStatsData] = useState({});
  const [knightLevel, setKnightLevel] = useState(0);
  const [previewStats, setPreviewStats] = useState<KnightStats>(knightStats);
  
  useEffect(() => {
    const { itemStats, itemSetBonus, itemRanges, runeStats } = calculateStatsFromItems(
      knightLevel,
      knight.gender,
      knight.race,
      slotItems,
      knightStats,
      equippedRunes,
    );
    setPreviewStats({
      ...knightStats,
      itemStats,
      itemSetBonus,
      itemRanges,
      runeStats
    });
  }, [slotItems, knightStats, equippedRunes]); // eslint-disable-line

  useEffect(() => {
    let allStats = {};
    let totalBaseStats = 0;
    let totalAllocatedStats = 0;
    let totalItemStats = 0;
    Object.values(STATS_LABEL).forEach((stats: string) => {
      const calculatedStats = _calculateStats(stats);
      allStats[stats] = calculatedStats;
      totalBaseStats += calculatedStats.baseValue;
      totalAllocatedStats += calculatedStats.levelUpStats;
      totalItemStats += calculatedStats.itemStatsValue;
    });
    setStatsData(allStats);
    setTotalBaseStats(totalBaseStats);
    setTotalAllocatedStats(totalAllocatedStats);
    setTotalItemStats(totalItemStats);
  }, [previewStats]); // eslint-disable-line

  function showStatsDetailsModal() {
    modalService.show(BasicModalContent, {
      content: <KnightStatsDetailsModal knightStats={previewStats} slotItems={slotItems} />,
      customTitle: (
        <div className="flex-center-start">
          <div>Hero Stats</div>
        </div>
      ),
      layer: 2,
    });
  }

  function showLevelUpModal() {
    modalService.show(LevelUpModal, {
      knightStats: previewStats,
      slotItems: slotItems,
      goToStaking: goToStaking,
      isDemiKnight: isDemi,
      className: "level-up",
    });
  }

  function goToStaking() {
    history.push(`/staking?owned=true&sortBy=Lowest%20ID&page=1&id=${knight.id}`);
    modalService.close();
  }

  function _calculateStats(statsLabel: string) {
    const label = getKnightStatsLabel(statsLabel);
    const lowerStatsLabel = statsLabel.toLowerCase();
    const levelUpStats = previewStats.allocatedStats[lowerStatsLabel];
    const elementStats = previewStats.elementStats[lowerStatsLabel];
    const raceStats = previewStats.raceStats[lowerStatsLabel];
    const itemsStats = previewStats.itemStats[statsLabel];
    const bonusStats = previewStats.itemSetBonus[statsLabel];

    let traitStats = 0;
    previewStats.traitStats.forEach((trait: TraitStats) => {
      traitStats += trait.stats[lowerStatsLabel];
    });

    const baseValue = elementStats + raceStats + traitStats;
    const itemStatsValue = itemsStats + bonusStats;
    const tooltip = `Race: +${raceStats}, Element: +${elementStats}, Traits: +${traitStats}, Levels: +${levelUpStats}, Items: +${itemStatsValue}`;

    return { tooltip, label, baseValue, itemStatsValue, levelUpStats };
  }

  return [
    statsData,
    totalBaseStats,
    totalItemStats,
    totalAllocatedStats,
    knightLevel,
    setKnightLevel,
    showLevelUpModal as any,
    showStatsDetailsModal as any,
  ];
}
