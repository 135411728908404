export const STATS_ALLOCATION = [
  {
    stat: "str",
    number: 1,
    effects: {
      HP: 1,
    },
  },
  {
    stat: "str",
    number: 5,
    effects: {
      "Physical Defense": 1,
    },
  },
  {
    stat: "agi",
    number: 3,
    effects: {
      "Armor Penetration": 1,
    },
  },
  {
    stat: "agi",
    number: 4,
    effects: {
      Speed: 1,
    },
  },
  {
    stat: "int",
    number: 4,
    effects: {
      "Magical Defense": 1,
      Accuracy: 1,
    },
  },
  {
    stat: "luk",
    number: 1,
    effects: {
      "Critical Damage": 1,
    },
  },
  {
    stat: "luk",
    number: 10,
    effects: {
      "Critical Chance": 1,
      "Evasion Chance": 1,
    },
  },
];

export const BASE_STATS = [
  {
    name: "Base HP",
    stats: 50,
  },
  {
    name: "Base Physical Damage",
    stats: 10,
  },
  {
    name: "Base Magical Damage",
    stats: 10,
  },
  {
    name: "Base Critical Chance",
    stats: 1,
  },
  {
    name: "Base Critical Damage",
    stats: 150,
  },
  {
    name: "Base Evasion Chance",
    stats: 1,
  },
  {
    name: "1 Level",
    stats: 1,
  },
];

export const STATS_CAP = [
  {
    name: "Evasion Chance Cap",
    stats: 40,
  },
  {
    name: "Critical Chance Cap",
    stats: 40,
  },
  {
    name: "Armor Penetration Cap",
    stats: 40,
  },
];
