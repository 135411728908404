import React, { useEffect, useRef, useState } from "react";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
import crystal from "src/assets/images/decors/gems/gem-green.svg";
import marketplaceImg from "src/assets/images/icons/features/marketplace.png";
import stakingImg from "src/assets/images/icons/features/staking.png";
import summonDemiImg from "src/assets/images/icons/features/summon-demi.png";
import gachaImg from "src/assets/images/icons/features/gacha.png";
import viewButtonImg from "src/assets/images/icons/features/view-button.svg";
import featuresPrevButtonImg from "src/assets/images/icons/features/features-prev.svg";
import featuresNextButtonImg from "src/assets/images/icons/features/features-next.svg";
import { ROUTE } from "src/app/configs/constants";
import { changeTab } from "src/app/actions/marketAction";
import { useDispatch } from "react-redux";

interface Feature {
  image: string;
  title: string;
  desc: string;
  options: { name: string; url: string }[];
  url?: string;
}

const features: Feature[] = [
  {
    image: marketplaceImg,
    title: "MARKETPLACE",
    desc: "A place for tradings of NFT items that increases heroes' strength. The rarer the item, the more power it carries.",
    options: [
      {
        name: "HERO MARKET",
        url: ROUTE.HEROES,
      },
      {
        name: "DEMI MARKET",
        url: ROUTE.DEMI,
      },
      {
        name: "ITEMS MARKET",
        url: ROUTE.ITEMS,
      },
      {
        name: "MATERIAL MARKET",
        url: ROUTE.MATERIALS,
      },
    ],
  },
  {
    image: stakingImg,
    title: "STAKING",
    desc: "Staking your $FARA boosts your hero's EXP, in addition to earning profits with an attractive APR",
    url: `${ROUTE.STAKING}?owned=true&sortBy=Lowest%20ID&page=1`,
    options: [],
  },
  {
    image: summonDemiImg,
    title: "SUMMON DEMI",
    desc: "Demi Heroes summoning gate. Players have a chance to summon Demi of rare races with a small amount of $FARA and Soul stones",
    url: ROUTE.DEMI_SUMMON,
    options: [],
  },
  {
    image: gachaImg,
    title: "GACHA",
    desc: "Gacha machine with a limited number of items from special rarities. Gacha Machine will only be available on special occasions of the year.",
    url: ROUTE.GACHA,
    options: [],
  },
];

const Features = () => {
  const dispatch = useDispatch();
  const featureContainerRef = useRef<HTMLDivElement>(null);
  const [activeLeft, setActiveLeft] = useState(false);
  const [activeRight, setActiveRight] = useState(true);

  const scrollBy = (offset: number) => {
    const ref = featureContainerRef.current;
    if (!ref) return;

    ref.scrollBy(offset, 0);

    setActiveLeft(ref.scrollLeft !== 0);
    setActiveRight(Math.abs(ref.scrollLeft) !== ref.scrollWidth - ref.clientWidth);
  };

  useEffect((): any => {
    scrollBy(0);

    function handleResize() {
      scrollBy(0);
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div id="features" className="features">
      <FloatingCrystal speed={3} src={crystal} style={{ left: -50 }} />
      <div className="features__heading">
        <div className="features__heading-title">Features</div>
      </div>
      <div className="features__wrapper">
        <img
          className={!activeLeft ? "features__button--inactive" : ""}
          src={featuresPrevButtonImg}
          alt="Previous"
          onClick={() => scrollBy(-295)}
          style={{ cursor: "pointer" }}
        />
        <div className="features__container" ref={featureContainerRef}>
          {features.map((feature, id) => (
            <div key={id} className="features__feature">
              <img src={feature.image} alt="feature" />
              <div className="features__feature-content">
                <div className="features__feature-title">{feature.title}</div>
                <div className="features__feature-desc">{feature.desc}</div>
                <div className="features__feature-spacer" />
                <div className="features__feature-dropdown">
                  <a
                    className={`${!feature.url ? "unclickable" : ""}`}
                    href={feature.url ?? "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="home__button features__feature-dropdown--button" src={viewButtonImg} alt="view" />
                  </a>
                  <div className="features__feature-dropdown--content">
                    {feature.options.map((option, id) => (
                      <div key={id} className="features__feature-dropdown--option">
                        <a
                          href={option.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => dispatch(changeTab(0))}
                        >
                          {option.name}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <img
          className={!activeRight ? "features__button--inactive" : ""}
          src={featuresNextButtonImg}
          alt="Next"
          onClick={() => scrollBy(295)}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

export default Features;
