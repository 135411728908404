import { RARITY } from "src/app/configs/constants";

export const RECIPES = {
  "1": {
    id: 1,
    name: "Hegemon Princess",
    race: "Fairy",
    gender: "Female",
    items: [
      {
        id: 1,
        name: "Hegemon Princess Shoes",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [481, 484, 487],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 11],
        materialAmounts: [50, 9, 4],
      },
      {
        id: 2,
        name: "Hegemon Princess Gloves",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [460, 463, 466],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 11],
        materialAmounts: [100, 25, 15],
      },
      {
        id: 3,
        name: "Hegemon Princess Pants",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [490, 493, 496],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 12],
        materialAmounts: [100, 25, 10],
      },
      {
        id: 4,
        name: "Hegemon Princess Wings",
        tokenFee: 100,
        successRate: 85,
        maxRarityImproved: 10,
        point: 4,
        itemIds: [472, 475, 478],
        itemRates: [85, 12, 3],
        materialIds: [0, 8, 10, 12],
        materialAmounts: [500, 50, 10, 20],
      },
      {
        id: 5,
        name: "Hegemon Princess Fist",
        tokenFee: 100,
        successRate: 85,
        maxRarityImproved: 10,
        point: 4,
        itemIds: [451, 454, 457],
        itemRates: [85, 12, 3],
        materialIds: [0, 8, 10, 12],
        materialAmounts: [500, 50, 10, 20],
      },
    ],
  },
  "2": {
    id: 2,
    name: "Fretful Devastator",
    race: "Elf",
    gender: "Male",
    items: [
      {
        id: 6,
        name: "Fretful Devastator Boots",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [544, 547, 550],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [50, 9, 4],
      },
      {
        id: 7,
        name: "Fretful Devastator Arms",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [517, 520, 523],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [100, 25, 15],
      },
      {
        id: 8,
        name: "Fretful Devastator Mask",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [526, 529, 532],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 13],
        materialAmounts: [100, 25, 10],
      },
      {
        id: 9,
        name: "Fretful Devastator Robe",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [535, 538, 541],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 13],
        materialAmounts: [100, 25, 10],
      },
      {
        id: 10,
        name: "Fretful Devastator Blade",
        tokenFee: 100,
        successRate: 85,
        maxRarityImproved: 10,
        point: 4,
        itemIds: [499, 502, 505],
        itemRates: [85, 12, 3],
        materialIds: [0, 9, 13, 14],
        materialAmounts: [500, 50, 20, 10],
      },
      {
        id: 11,
        name: "Fretful Devastator Sigil",
        tokenFee: 200,
        successRate: 80,
        maxRarityImproved: 10,
        point: 7,
        itemIds: [508, 511, 514],
        itemRates: [92, 5, 3],
        materialIds: [0, 9, 13, 14, 15],
        materialAmounts: [1000, 100, 40, 20, 15],
      },
    ],
  },
  "3": {
    id: 3,
    name: "Grimfrost",
    race: "All",
    gender: "All",
    items: [
      {
        id: 12,
        name: "Grimfrost Spear",
        tokenFee: 200,
        successRate: 80,
        maxRarityImproved: 10,
        point: 10,
        itemIds: [566, 569, 572],
        itemRates: [92, 5, 3],
        materialIds: [0, 7, 8, 9, 11],
        materialAmounts: [1000, 1, 80, 80, 48],
      },
      {
        id: 13,
        name: "Grimfrost Shield",
        tokenFee: 100,
        successRate: 85,
        maxRarityImproved: 10,
        point: 7,
        itemIds: [575, 578, 581],
        itemRates: [85, 12, 3],
        materialIds: [0, 6, 8, 9, 11],
        materialAmounts: [500, 1, 40, 40, 24],
      },
    ],
  },
  "4": {
    id: 4,
    name: "Tribataur",
    race: "Orc",
    gender: "Male",
    items: [
      {
        id: 14,
        name: "Tribataur Staff",
        tokenFee: 35,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [627, 630, 633],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 12],
        materialAmounts: [60, 25, 10],
      },
    ],
  },
  "5": {
    id: 5,
    name: "Pontinex",
    race: "Dragon",
    gender: "Male",
    items: [
      {
        id: 15,
        name: "Golden Pontinex Spear",
        tokenFee: 35,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [588, 591, 594],
        itemRates: [60, 30, 10],
        materialIds: [0, 11, 12],
        materialAmounts: [60, 15, 10],
      },
    ],
  },
  "6": {
    id: 6,
    name: "Moon's Elegance",
    race: "Dragon",
    gender: "Female",
    items: [
      {
        id: 16,
        name: "Moon's Elegance Glaive",
        tokenFee: 75,
        successRate: 85,
        maxRarityImproved: 8,
        point: 4,
        itemIds: [669, 672, 675, 1089],
        itemRates: [12, 75, 11, 2],
        materialIds: [0, 9, 13, 14],
        materialAmounts: [375, 43, 17, 8],
      },
      {
        id: 17,
        name: "Moon's Elegance Armor",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [678, 681, 1092],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [20, 9, 4],
      },
      {
        id: 18,
        name: "Moon's Elegance Boots",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [684, 687, 1095],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [20, 9, 4],
      },
      {
        id: 19,
        name: "Moon's Elegance Gloves",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [690, 693, 1098],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [50, 25, 15],
      },
      {
        id: 20,
        name: "Moon's Elegance Helmet",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [696, 699, 1101],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 13],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 21,
        name: "Moon's Elegance Pants",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [702, 705, 1104],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 13],
        materialAmounts: [50, 25, 10],
      },
    ],
  },
  "7": {
    id: 7,
    name: "Masked Maniac",
    race: "Human",
    gender: "Male",
    items: [
      {
        id: 22,
        name: "Masked Maniac Mace",
        tokenFee: 100,
        successRate: 85,
        maxRarityImproved: 10,
        point: 4,
        itemIds: [708, 711, 714],
        itemRates: [85, 12, 3],
        materialIds: [0, 9, 13, 14],
        materialAmounts: [500, 50, 20, 20],
      },
      {
        id: 23,
        name: "Masked Maniac Armor",
        tokenFee: 20,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [717, 720],
        itemRates: [70, 30],
        materialIds: [0, 9, 13],
        materialAmounts: [90, 25, 10],
      },
      {
        id: 24,
        name: "Masked Maniac Boots",
        tokenFee: 40,
        successRate: 85,
        maxRarityImproved: 20,
        point: 3,
        itemIds: [723, 726],
        itemRates: [80, 20],
        materialIds: [0, 9, 13, 14],
        materialAmounts: [350, 50, 20, 10],
      },
      {
        id: 25,
        name: "Masked Maniac Gloves",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [729, 732, 1107],
        itemRates: [60, 30, 10],
        materialIds: [0, 11, 13],
        materialAmounts: [100, 10, 10],
      },
      {
        id: 26,
        name: "Masked Maniac Helmet",
        tokenFee: 40,
        successRate: 85,
        maxRarityImproved: 20,
        point: 3,
        itemIds: [735, 738],
        itemRates: [80, 20],
        materialIds: [0, 9, 13, 14],
        materialAmounts: [350, 50, 20, 10],
      },
      {
        id: 27,
        name: "Masked Maniac Pants",
        tokenFee: 20,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [741, 744],
        itemRates: [70, 30],
        materialIds: [0, 9, 13],
        materialAmounts: [90, 25, 10],
      },
    ],
  },
  "8": {
    id: 8,
    name: "Potence",
    race: "Elf",
    gender: "Male",
    items: [
      {
        id: 28,
        name: "Potence Staff",
        tokenFee: 35,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [762, 765, 768],
        itemRates: [60, 30, 10],
        materialIds: [0, 11, 12],
        materialAmounts: [60, 15, 10],
      },
    ],
  },
  "9": {
    id: 9,
    name: "Piraslaus",
    race: "Human",
    gender: "Female",
    items: [
      {
        id: 29,
        name: "Piraslaus Sword",
        tokenFee: 35,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [801, 804, 807],
        itemRates: [60, 30, 10],
        materialIds: [0, 11, 12],
        materialAmounts: [60, 15, 15],
      },
      // {
      //   id: 30,
      //   name: "Piraslaus Handgonne",
      //   tokenFee: 15,
      //   successRate: 90,
      //   maxRarityImproved: 20,
      //   point: 2,
      //   itemIds: [810, 813],
      //   itemRates: [70, 30],
      //   materialIds: [0, 8, 12],
      //   materialAmounts: [50, 25, 10]
      // },
    ],
  },
  "10": {
    id: 10,
    name: "Enigcrypt",
    race: "Angel",
    gender: "Female",
    items: [
      {
        id: 31,
        name: "Enigcrypt Hammer",
        tokenFee: 75,
        successRate: 85,
        maxRarityImproved: 8,
        point: 4,
        itemIds: [846, 849, 852, 1110],
        itemRates: [12, 75, 11, 2],
        materialIds: [0, 9, 13, 14],
        materialAmounts: [375, 43, 17, 8],
      },
      {
        id: 32,
        name: "Enigcrypt Shield",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [855, 858, 1113],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 13],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 33,
        name: "Enigcrypt Armor",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [861, 864, 1116],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 34,
        name: "Enigcrypt Boots",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [867, 870, 1119],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 35,
        name: "Enigcrypt Gloves",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [873, 876, 1122],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 36,
        name: "Enigcrypt Helmet",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [879, 882, 1125],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [20, 9, 4],
      },
      {
        id: 37,
        name: "Enigcrypt Pants",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [885, 888, 1128],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [20, 9, 4],
      },
    ],
  },
  "11": {
    id: 11,
    name: "Astrorius",
    race: "Elf",
    gender: "Male",
    items: [
      {
        id: 38,
        name: "Astrorius Spear",
        tokenFee: 75,
        successRate: 85,
        maxRarityImproved: 8,
        point: 4,
        itemIds: [891, 894, 897, 1131],
        itemRates: [12, 75, 11, 2],
        materialIds: [0, 8, 10, 12],
        materialAmounts: [375, 43, 8, 17],
      },
      {
        id: 39,
        name: "Astrorius Armor",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [900, 903, 1134],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 12],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 40,
        name: "Astrorius Boots",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [906, 909, 1137],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 11],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 41,
        name: "Astrorius Gloves",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [912, 915, 1140],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 11],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 42,
        name: "Astrorius Helmet",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [918, 921, 1143],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 11],
        materialAmounts: [20, 9, 4],
      },
      {
        id: 43,
        name: "Astrorius Pants",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [924, 927, 1146],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 11],
        materialAmounts: [20, 9, 4],
      },
    ],
  },
  "12": {
    id: 12,
    name: "Silent Howl",
    race: "Human",
    gender: "Female",
    items: [
      {
        id: 44,
        name: "Silent Howl Staff",
        imageName: "main-weapon-2",
        tokenFee: 200,
        successRate: 80,
        maxRarityImproved: 10,
        point: 7,
        itemIds: [951, 954, 957],
        itemRates: [92, 5, 3],
        materialIds: [0, 9, 13, 14, 15],
        materialAmounts: [200, 100, 40, 20, 15],
      },
      {
        id: 45,
        name: "Silent Howl Sword",
        tokenFee: 200,
        successRate: 80,
        maxRarityImproved: 10,
        point: 7,
        itemIds: [933, 936, 939],
        itemRates: [92, 5, 3],
        materialIds: [0, 9, 13, 14, 15],
        materialAmounts: [200, 100, 40, 20, 15],
      },
      {
        id: 46,
        name: "Silent Howl Shield",
        tokenFee: 100,
        successRate: 85,
        maxRarityImproved: 10,
        point: 4,
        itemIds: [942, 945, 948],
        itemRates: [85, 12, 3],
        materialIds: [0, 9, 13, 14],
        materialAmounts: [100, 50, 20, 10],
      },
      {
        id: 47,
        name: "Silent Howl Gloves",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [960, 963, 966],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [50, 25, 15],
      },
      {
        id: 48,
        name: "Silent Howl Helmet",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [969, 972, 975],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 13],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 49,
        name: "Silent Howl Armor",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [978, 981, 984],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 13],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 50,
        name: "Silent Howl Boots",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [987, 990, 993],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [20, 9, 4],
      },
      {
        id: 51,
        name: "Silent Howl Pants",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [996, 999, 1002],
        itemRates: [60, 30, 10],
        materialIds: [0, 9, 11],
        materialAmounts: [20, 9, 4],
      },
    ],
  },
  "13": {
    id: 13,
    name: "Encircled",
    race: "Fairy",
    gender: "Male",
    items: [
      {
        id: 52,
        name: "Encircled Spear",
        tokenFee: 200,
        successRate: 80,
        maxRarityImproved: 10,
        point: 7,
        itemIds: [1026, 1029, 1032],
        itemRates: [92, 5, 3],
        materialIds: [0, 8, 10, 12, 15],
        materialAmounts: [200, 100, 20, 40, 15],
      },
      {
        id: 53,
        name: "Encircled Staff",
        imageName: "main-weapon-2",
        tokenFee: 200,
        successRate: 80,
        maxRarityImproved: 10,
        point: 7,
        itemIds: [1035, 1038, 1041],
        itemRates: [92, 5, 3],
        materialIds: [0, 8, 10, 12, 15],
        materialAmounts: [200, 100, 20, 40, 15],
      },
      {
        id: 54,
        name: "Encircled Gloves",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [1044, 1047, 1050],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 11],
        materialAmounts: [50, 25, 15],
      },
      {
        id: 55,
        name: "Encircled Helmet",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [1053, 1056, 1059],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 12],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 56,
        name: "Encircled Armor",
        tokenFee: 50,
        successRate: 90,
        maxRarityImproved: 20,
        point: 2,
        itemIds: [1062, 1065, 1068],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 12],
        materialAmounts: [50, 25, 10],
      },
      {
        id: 57,
        name: "Encircled Boots",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [1071, 1074, 1077],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 11],
        materialAmounts: [20, 9, 4],
      },
      {
        id: 58,
        name: "Encircled Pants",
        tokenFee: 20,
        successRate: 95,
        maxRarityImproved: 20,
        point: 1,
        itemIds: [1080, 1083, 1086],
        itemRates: [60, 30, 10],
        materialIds: [0, 8, 11],
        materialAmounts: [20, 9, 4],
      },
    ],
  },
  "14": _generateItemRecipeConfig(
    14,
    "Rampaging Fury",
    "Orc",
    "Male",
    59,
    1149,
    ["Staff", "Shield", "Axe", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon1hand", "sub", "weapon2hand", "gloves", "helmet", "armor", "boots", "pants"],
    2
  ),
  "15": _generateItemRecipeConfig(
    15,
    "Elven Grove",
    "Fairy",
    "Female",
    67,
    1221,
    ["Staff", "Orb", "Sword", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon1hand", "sub", "weapon2hand", "gloves", "helmet", "armor", "boots", "pants"],
    2
  ),
  "16": _generateItemRecipeConfig(
    16,
    "Draconic Cataclysm",
    "Dragon",
    "Male",
    75,
    1293,
    ["Staff", "Shield", "Axe", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon2hand", "sub", "weapon1hand", "gloves", "helmet", "armor", "boots", "pants"],
    0
  ),
  "17": _generateItemRecipeConfig(
    17,
    "Aegis",
    "Angel",
    "Male",
    83,
    1365,
    ["Sword", "Shield", "Staff", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon1hand", "sub", "weapon1hand", "gloves", "helmet", "armor", "boots", "pants"],
    2
  ),
  "18": _generateItemRecipeConfig(
    18,
    "Razorwing",
    "Human",
    "Male",
    91,
    1437,
    ["Staff", "Book", "Blade", "Sword", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon1hand", "sub", "sub", "weapon1hand", "gloves", "helmet", "armor", "boots", "pants"],
    0,
    1
  ),
  "19": _generateItemRecipeConfig(
    19,
    "Invictus",
    "Dragon",
    "Female",
    100,
    1518,
    ["Staff", "Shield", "Axe", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon2hand", "sub", "weapon1hand", "gloves", "helmet", "armor", "boots", "pants"],
    0
  ),
  "20": _generateItemRecipeConfig(
    20,
    "Colossal Guardian",
    "Orc",
    "Female",
    108,
    1590,
    ["Staff", "Shield", "Axe", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon2hand", "sub", "weapon1hand", "gloves", "helmet", "armor", "boots", "pants"],
    0
  ),
  "21": _generateItemRecipeConfig(
    21,
    "Excalibur",
    "Human",
    "Male",
    116,
    1662,
    ["Staff", "Shield", "Spear", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon2hand", "sub", "weapon1hand", "gloves", "helmet", "armor", "boots", "pants"],
    0
  ),
  "22": _generateItemRecipeConfig(
    22,
    "Hecateus",
    "Demon",
    "Female",
    124,
    1734,
    ["Staff", "Shield", "Axe", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon1hand", "sub", "weapon2hand", "gloves", "helmet", "armor", "boots", "pants"],
    2
  ),
  "23": _generateItemRecipeConfig(
    23,
    "Galestrike",
    "Elf",
    "Male",
    132,
    1806,
    ["Staff", "Orb", "Bow", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon1hand", "sub", "weapon2hand", "gloves", "helmet", "armor", "boots", "pants"],
    2
  ),
  "24": _generateItemRecipeConfig(
    24,
    "Antheia",
    "Fairy",
    "Female",
    140,
    1878,
    ["Staff", "Orb", "Knife", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon1hand", "sub", "weapon2hand", "gloves", "helmet", "armor", "boots", "pants"],
    2
  ),
  "25": _generateItemRecipeConfig(
    25,
    "Astarot",
    "Demon",
    "Male",
    148,
    1950,
    ["Staff", "Orb", "Axe", "Gloves", "Helmet", "Armor", "Boots", "Pants"],
    ["weapon1hand", "sub", "weapon2hand", "gloves", "helmet", "armor", "boots", "pants"],
    2
  ),
  "26": {
    id: 26,
    name: "Chakra Rune",
    race: "Rune",
    gender: "All",
    items: [
      {
        id: 53,
        name: "Chakra Rune",
        tokenFee: 5,
        successRate: 100,
        maxRarityImproved: 0,
        point: 1,
        itemIds: [],
        itemRates: [],
        materialIds: [0, 8, 2, 12, 18],
        materialAmounts: [150, 30, 30, 30, 1],
        requiredItemRarity: [RARITY.RARE],
        requiredItemAmount: [1],
      },
      {
        id: 54,
        name: "Chakra Rune",
        tokenFee: 5,
        successRate: 90,
        maxRarityImproved: 0,
        point: 1,
        itemIds: [],
        itemRates: [],
        materialIds: [0, 3, 10, 4, 18],
        materialAmounts: [150, 30, 15, 5, 1],
        requiredItemRarity: [RARITY.RARE],
        requiredItemAmount: [1],
      },
      {
        id: 55,
        name: "Chakra Rune",
        tokenFee: 10,
        successRate: 75,
        maxRarityImproved: 0,
        point: 1,
        itemIds: [],
        itemRates: [],
        materialIds: [0, 8, 11, 13, 18],
        materialAmounts: [300, 50, 30, 50, 1],
        requiredItemRarity: [RARITY.RARE],
        requiredItemAmount: [1],
      },
      {
        id: 56,
        name: "Chakra Rune",
        tokenFee: 15,
        successRate: 60,
        maxRarityImproved: 0,
        point: 1,
        itemIds: [],
        itemRates: [],
        materialIds: [0, 15, 4, 18],
        materialAmounts: [450, 15, 10, 1],
        requiredItemRarity: [RARITY.RARE],
        requiredItemAmount: [1],
      },
      {
        id: 57,
        name: "Chakra Rune",
        tokenFee: 25,
        successRate: 50,
        maxRarityImproved: 0,
        point: 1,
        itemIds: [],
        itemRates: [],
        materialIds: [0, 8, 9, 12, 14, 10, 19],
        materialAmounts: [750, 30, 50, 50, 20, 15, 1],
        requiredItemRarity: [RARITY.RARE],
        requiredItemAmount: [1],
      },
      {
        id: 58,
        name: "Chakra Rune",
        tokenFee: 25,
        successRate: 30,
        maxRarityImproved: 0,
        point: 1,
        itemIds: [],
        itemRates: [],
        materialIds: [0, 2, 11, 3, 14, 19],
        materialAmounts: [750, 50, 50, 50, 10, 1],
        requiredItemRarity: [RARITY.RARE, RARITY.EPIC],
        requiredItemAmount: [1, 1],
      },
      {
        id: 59,
        name: "Chakra Rune",
        tokenFee: 50,
        successRate: 25,
        maxRarityImproved: 0,
        point: 1,
        itemIds: [],
        itemRates: [],
        materialIds: [0, 9, 13, 14, 4, 19],
        materialAmounts: [1500, 80, 120, 30, 15, 1],
        requiredItemRarity: [RARITY.RARE, RARITY.EPIC],
        requiredItemAmount: [1, 1],
      },
      {
        id: 60,
        name: "Chakra Rune",
        tokenFee: 50,
        successRate: 20,
        maxRarityImproved: 0,
        point: 1,
        itemIds: [],
        itemRates: [],
        materialIds: [0, 8, 12, 15, 5, 20, 19],
        materialAmounts: [1500, 150, 60, 15, 1, 1, 1],
        requiredItemRarity: [RARITY.RARE, RARITY.EPIC, RARITY.LEGENDARY],
        requiredItemAmount: [1, 1, 1],
      },
      {
        id: 61,
        name: "Chakra Rune",
        tokenFee: 80,
        successRate: 15,
        maxRarityImproved: 0,
        point: 1,
        itemIds: [],
        itemRates: [],
        materialIds: [0, 2, 11, 12, 10, 20, 19],
        materialAmounts: [2250, 120, 120, 20, 25, 1, 1],
        requiredItemRarity: [RARITY.RARE, RARITY.EPIC, RARITY.LEGENDARY],
        requiredItemAmount: [1, 1, 1],
      },
      {
        id: 62,
        name: "Chakra Rune",
        tokenFee: 100,
        successRate: 10,
        maxRarityImproved: 0,
        point: 1,
        itemIds: [],
        itemRates: [],
        materialIds: [0, 9, 3, 15, 4, 5, 20, 19],
        materialAmounts: [3000, 120, 80, 25, 25, 1, 1, 1],
        requiredItemRarity: [RARITY.RARE, RARITY.EPIC, RARITY.LEGENDARY],
        requiredItemAmount: [1, 1, 1],
      },
    ],
  },
};

// Mapping of Item ID and its recipe ID.
export const CRAFTABLE_EQUIPMENT = {
  451: 1,
  454: 1,
  457: 1,
  460: 1,
  463: 1,
  466: 1,
  472: 1,
  475: 1,
  478: 1,
  481: 1,
  484: 1,
  487: 1,
  490: 1,
  493: 1,
  496: 1,
  499: 2,
  502: 2,
  505: 2,
  508: 2,
  511: 2,
  514: 2,
  517: 2,
  520: 2,
  523: 2,
  526: 2,
  529: 2,
  532: 2,
  535: 2,
  538: 2,
  541: 2,
  544: 2,
  547: 2,
  550: 2,
  566: 3,
  569: 3,
  572: 3,
  575: 3,
  578: 3,
  581: 3,
  627: 4,
  630: 4,
  633: 4,
  588: 5,
  591: 5,
  594: 5,
  669: 6,
  672: 6,
  675: 6,
  678: 6,
  681: 6,
  684: 6,
  687: 6,
  690: 6,
  693: 6,
  696: 6,
  699: 6,
  702: 6,
  705: 6,
  1089: 6,
  1092: 6,
  1095: 6,
  1098: 6,
  1101: 6,
  1104: 6,
  708: 7,
  711: 7,
  714: 7,
  717: 7,
  720: 7,
  723: 7,
  726: 7,
  729: 7,
  732: 7,
  735: 7,
  738: 7,
  741: 7,
  744: 7,
  1107: 7,
  762: 8,
  765: 8,
  768: 8,
  801: 9,
  804: 9,
  807: 9,
  /* 810: 9, 813: 9, */ 846: 10,
  849: 10,
  852: 10,
  855: 10,
  858: 10,
  861: 10,
  864: 10,
  867: 10,
  870: 10,
  873: 10,
  876: 10,
  879: 10,
  882: 10,
  885: 10,
  888: 10,
  1110: 10,
  1113: 10,
  1116: 10,
  1119: 10,
  1122: 10,
  1125: 10,
  1128: 10,
  891: 11,
  894: 11,
  897: 11,
  900: 11,
  903: 11,
  906: 11,
  909: 11,
  912: 11,
  915: 11,
  918: 11,
  921: 11,
  924: 11,
  927: 11,
  1131: 11,
  1134: 11,
  1137: 11,
  1140: 11,
  1143: 11,
  1146: 11,
  ..._generateMappingConfig(12, 933, 8),
  ..._generateMappingConfig(13, 1026, 7),
  ..._generateMappingConfig(14, 1149, 8),
  ..._generateMappingConfig(15, 1221, 8),
  ..._generateMappingConfig(16, 1293, 8),
  ..._generateMappingConfig(17, 1365, 8),
  ..._generateMappingConfig(18, 1437, 9),
  ..._generateMappingConfig(19, 1518, 8),
  ..._generateMappingConfig(20, 1590, 8),
  ..._generateMappingConfig(21, 1662, 8),
  ..._generateMappingConfig(22, 1734, 8),
  ..._generateMappingConfig(23, 1806, 8),
  ..._generateMappingConfig(24, 1878, 8),
  ..._generateMappingConfig(25, 1950, 8),
};

// Mapping of Enhanced Stone IDs and its boosted rate.
export const ENHANCED_STONE_RATES = {
  2: 5,
  3: 10,
  4: 15,
  5: 20,
};

function _generateItemRecipeConfig(
  id: number,
  name: string,
  race: string,
  gender: string,
  firstRecipeId: number,
  firstItemId: number,
  itemNames: Array<string>,
  itemTypes: Array<string>,
  weapon2Index?: number,
  subWeapon2Index?: number
) {
  const items: any = [];

  for (let i = 0; i < itemNames.length; i++) {
    const recipeId = firstRecipeId + i;
    const itemId = firstItemId + i * 9;
    const customMainImage = weapon2Index === i;
    const customSubImage = subWeapon2Index === i;

    items.push(
      _generateItemRecipe(recipeId, itemId, `${name} ${itemNames[i]}`, itemTypes[i], customMainImage, customSubImage)
    );
  }

  return {
    id: id,
    name: name,
    race: race,
    gender: gender,
    items: items,
  };
}

function _generateItemRecipe(
  recipeId: number,
  itemId: number,
  name: string,
  type: string,
  customMainImage: boolean,
  customSubImage: boolean
) {
  const itemIds = [itemId, itemId + 3, itemId + 6];
  let recipe;

  if (type === "weapon1hand") {
    recipe = _createItemRecipeObject(
      recipeId,
      name,
      200,
      80,
      10,
      7,
      itemIds,
      [92, 5, 3],
      [0, 8, 12, 14, 15],
      [200, 100, 40, 20, 15],
      customMainImage,
      customSubImage
    );
  } else if (type === "sub") {
    recipe = _createItemRecipeObject(
      recipeId,
      name,
      100,
      85,
      10,
      4,
      itemIds,
      [85, 12, 3],
      [0, 9, 12, 14],
      [100, 50, 20, 10],
      customMainImage,
      customSubImage
    );
  } else if (type === "weapon2hand") {
    recipe = _createItemRecipeObject(
      recipeId,
      name,
      200,
      80,
      10,
      7,
      itemIds,
      [92, 5, 3],
      [0, 9, 13, 14, 10],
      [200, 100, 40, 20, 15],
      customMainImage,
      customSubImage
    );
  } else if (type === "gloves") {
    recipe = _createItemRecipeObject(
      recipeId,
      name,
      50,
      90,
      20,
      2,
      itemIds,
      [60, 30, 10],
      [0, 9, 11],
      [50, 25, 15],
      customMainImage,
      customSubImage
    );
  } else if (type === "helmet") {
    recipe = _createItemRecipeObject(
      recipeId,
      name,
      50,
      90,
      20,
      2,
      itemIds,
      [60, 30, 10],
      [0, 8, 12],
      [50, 25, 10],
      customMainImage,
      customSubImage
    );
  } else if (type === "armor") {
    recipe = _createItemRecipeObject(
      recipeId,
      name,
      50,
      90,
      20,
      2,
      itemIds,
      [60, 30, 10],
      [0, 8, 13],
      [50, 25, 10],
      customMainImage,
      customSubImage
    );
  } else if (type === "boots" || type === "pants") {
    recipe = _createItemRecipeObject(
      recipeId,
      name,
      20,
      95,
      20,
      1,
      itemIds,
      [60, 30, 10],
      [0, 8, 11],
      [20, 9, 4],
      customMainImage,
      customSubImage
    );
  }

  return recipe;
}

function _createItemRecipeObject(
  id,
  name,
  tokenFee,
  successRate,
  maxRarityImproved,
  point,
  itemIds,
  itemRates,
  materialIds,
  materialAmounts,
  customMainImage: boolean,
  customSubImage: boolean
) {
  const recipeObject: any = {
    id,
    name,
    tokenFee,
    successRate,
    maxRarityImproved,
    point,
    itemIds,
    itemRates,
    materialIds,
    materialAmounts,
  };

  if (customMainImage) {
    recipeObject.imageName = "main-weapon-2";
  } else if (customSubImage) {
    recipeObject.imageName = "sub-weapon-2";
  }

  return recipeObject;
}

function _generateMappingConfig(recipeId: number, firstItemId: number, itemCount: number) {
  const mappingConfig = {};

  for (let i = 0; i < itemCount * 3; i++) {
    const itemId = firstItemId + i * 3;
    mappingConfig[itemId] = recipeId;
  }

  return mappingConfig;
}
