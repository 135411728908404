import { NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { MATERIALS } from "src/app/configs/materials/materials";
import { Contributor, GachaHistory, GachaSpinResult, ItemsInPoolGacha, UserReceivedItem } from "src/app/types/gacha";

export function createUserReceivedItems(data: any): UserReceivedItem {
  return {
    account: data.walletAddress,
    from: EQUIPMENT[data.from.itemNftId],
    to: data.to ? EQUIPMENT[data.to.itemNftId] : null,
    spaceDomain: data.spaceDomain,
    timestamp: data.time,
  };
}

export function createContributor(data: any): Contributor {
  return {
    id: data.walletAddress,
    spaceDomain: data.spaceDomain,
    amount: data.amount,
  };
}

export function createItemsInPool(data: any): ItemsInPoolGacha {
  const item = data.itemId ? EQUIPMENT[data.itemId] : MATERIALS[data.materialId];
  return {
    id: data.itemId ? data.itemId : data.materialId,
    item: item,
    amount: data.quantity,
    rarity: item.rarity,
    type: data.itemId ? NON_FEE_INVENTORY_TYPE.ITEM : NON_FEE_INVENTORY_TYPE.MATERIAL
  }
}

export function createGachaSpinResult(data: any): GachaSpinResult {
  return {
    isJackpot: data.isJackpot ?? false,
    item: data.item ? EQUIPMENT[data.item.itemNftId] : null,
    totalFara: data.totalFara ?? 0
  }
}

export function createJackpotHistory(data: any): GachaHistory {
  return {
    jackpot: {
      account: data.walletAddress,
      amount: data.totalFara,
      spaceDomain: data.spaceDomain
    },
    timestamp: data.time
  }
}