import React, { ReactElement } from "react";
import humanImage from "src/assets/images/stickers/human.png";
import successIcon from "src/assets/images/icons/status-done.svg";
import failedIcon from "src/assets/images/icons/status-failed.svg";
import demonImage from "src/assets/images/stickers/demon.png";

type ResultContentProps = {
  isSuccess: boolean;
  content: ReactElement;
};

export default function ResultContent(props: ResultContentProps) {
  const { isSuccess, content } = props;

  return (
    <div className='result-modal'>
      <div className="align-center">
        <img
          className={`result-modal__sticker ${isSuccess && "result-modal__sticker--human"}`}
          src={isSuccess ? humanImage : demonImage}
          alt=''
        />
      </div>
      <div className="flex-center-center">
        <img className="mr-1" src={isSuccess ? successIcon : failedIcon} alt='' />
        <span className={`result-modal__title ${isSuccess ? "text-9" : "text-red"}`}>
          {isSuccess ? "Success" : "Failed"}!
        </span>
      </div>
      <div className="result-modal__content">
        {content}
      </div>
    </div>
  )
}