import ENV from "src/app/configs/env";
import { KnightFilterType, KnightQuery } from "src/app/types/knight";
import { createKnightStats } from "src/app/factories/knightFactory";
import {
  ClaimHistory,
  CraftedItem,
  CraftedMaterials,
  ItemFilterType,
  ItemQuery,
  UserItem,
  WithdrawHistory,
} from "src/app/types/equipment";
import {
  createClaimHistory,
  createCraftHistory,
  createCraftedItems,
  createCraftedMaterials,
  createFilteredItemData,
  createItem,
  createUserItemFromAPI,
  createWithdrawHistory,
} from "src/app/factories/equipmentFactory";
import { DemiFilterType, DemiQuery } from "src/app/types/demiKnight";
import { MaterialFilterType, MaterialQuery, UserMaterial } from "src/app/types/materials";
import { createMaterial, createRuneCraftHistory, createUserMaterialFromApi } from "src/app/factories/materialFactory";
import { createSkillBookCosts, createSkills } from "src/app/factories/knightSkillsFactory";
import {
  buildGuildListWhereCondition,
  buildItemListWhereCondition,
  buildKnightListWhereCondition,
  buildLandListWhereCondition,
  buildMaterialListWhereCondition,
} from "src/app/utils/filterHelper";
import { GuildFilterType, GuildQuery } from "src/app/types/guild";
import { createGuild } from "src/app/factories/guildFactory";
import { buildGuildMemberListWhereCondition } from "../../utils/filterHelper";
import { createGuildMember } from "src/app/factories/guildFactory";
import { createSkillBook } from "../../factories/equipmentFactory";
import Web3Service from "src/app/services/web3/Web3Service";
import _ from "lodash";
import { LandFilterType, LandQuery } from "src/app/types/land";
import { createLand } from "src/app/factories/landFactory";
import { createBlackMarketItem } from "src/app/factories/blackMarketFactory";
import { NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";
import { UserToken } from "src/app/types/token";
import { Contributor, GachaHistory, GachaSpinResult, ItemsInPoolGacha, UserReceivedItem } from "src/app/types/gacha";
import {
  createContributor,
  createGachaSpinResult,
  createItemsInPool,
  createJackpotHistory,
  createUserReceivedItems,
} from "src/app/factories/gachaFactory";
import { toBigAmount } from "src/app/utils/helpers";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";

export async function fetchEventCollections() {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/event/all`);
    const result = await response.json();
    if (result.success) {
      return result.project;
    }
  } catch (error) {
    console.log(error);
  }
}

// export async function voteProjectUsingWallet(projectId: number, sign: string, msgParams: any) {
//   try {
//     const response = await fetch(`${ENV.URL.FARALAND}/api/event/vote/wallet/${projectId}`, {
//       method: "POST",
//       body: JSON.stringify({ sign: sign, msgParams: msgParams }),
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     return await response.json();
//   } catch (e) {
//     console.log(e);
//     return null;
//   }
// }

export async function fetchGuildsFromApi(filters: GuildFilterType, query: GuildQuery) {
  try {
    const where = buildGuildListWhereCondition(filters, query);
    const endpoint = `${ENV.URL.FARALAND}/api/guild/filter`;
    const result = await _getResultFromPostAPI(endpoint, where);
    const list = result.data.list.map((guild: any) => createGuild(guild));
    const total = result.data.total[0].count;
    return { list, total };
  } catch (error) {
    console.log(error);
  }
}

export async function hideHeroFromPublic(knightId: string, sign: string, msgParams: any) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/${knightId}/hide`, {
      method: "POST",
      body: JSON.stringify({ sign: sign, msgParams: msgParams }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (e) {
    console.log(e);
    return null;
  }
}
export async function fetchTopGuild() {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/guild/stats/top`);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function fetchJoinRequestsFromApi(wallets: string[]) {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/guild/members/strength`;
    const data = { wallets };
    return await _getResultFromPostAPI(endpoint, data);
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function fetchMultipleGuilds(guildIds: string[]) {
  try {
    if (!guildIds || !guildIds.length) {
      return [];
    }
    const idParams = guildIds.join(",");
    const response = await fetch(`${ENV.URL.FARALAND}/api/guild?ids=${idParams}`);
    const arrayGuilds = await response.json();
    if (arrayGuilds.success) {
      return arrayGuilds.data.map((guild: any) => createGuild(guild));
    }
  } catch (e) {
    return false;
  }
}

//TODO: Remove if unused in future
export async function addGuildToApi(
  guildId: string,
  guildName: string,
  imgFile: any,
  desc: string,
  sign: string,
  msgParams: any,
  txHash: string
) {
  try {
    const fd = new FormData();
    fd.append("id", guildId);
    fd.append("name", guildName);
    fd.append("file", imgFile);
    fd.append("description", desc);
    fd.append("sign", sign);
    fd.append("msgParams", JSON.stringify(msgParams));
    fd.append("txHash", txHash);
    const response = await fetch(`${ENV.URL.FARALAND}/api/guild/create?bucket=guild`, {
      method: "POST",
      body: fd,
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function updateGuildDetails(guildId: string, imgFile: any, desc: string, sign: string, msgParams: any) {
  try {
    const fd = new FormData();
    fd.append("file", imgFile);
    fd.append("description", desc);
    fd.append("sign", sign);
    fd.append("msgParams", JSON.stringify(msgParams));
    const response = await fetch(`${ENV.URL.FARALAND}/api/guild/${guildId}`, {
      method: "POST",
      body: fd,
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function fetchGuildDetailsFromApi(guildId: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/guild/${guildId}`);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
}
export async function fetchGuildMembersFromApi(guildId: string, filters: GuildFilterType, query: GuildQuery) {
  try {
    const where = buildGuildMemberListWhereCondition(filters, query);
    const endpoint = `${ENV.URL.FARALAND}/api/guild/${guildId}/members`;
    const result = await _getResultFromPostAPI(endpoint, where);
    const list = result.data.list.map((member: any) => createGuildMember(member));
    const total = result.data.total[0].count;
    return { list, total };
  } catch (error) {
    console.log(error);
  }
}

export async function claimAttribute(id: string, type: number) {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/hero/claim-attribute`;
    const data = { id, type };
    return await _getResultFromPostAPI(endpoint, data);
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getSkills(knightId: string, isDemi: boolean, skillId?: number, learned?: number) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/${knightId}/skills`, {
      method: "POST",
      body: JSON.stringify({ isDemi: isDemi }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();

    if (!result.data) {
      return null;
    }

    return {
      tree: createSkills(result.data.tree, skillId, learned),
      points: result.data.availablePoints,
    };
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function learnSkills(
  knightId: string,
  isDemi: boolean,
  learnedSkills: any[],
  sign: string,
  msgParams: any
) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/${knightId}/learn_skills`, {
      method: "POST",
      body: JSON.stringify({ isDemi: isDemi, learned: learnedSkills, sign: sign, msgParams: msgParams }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function resetSkill(knightId: string, isDemi: boolean, sign: string, msgParams: any) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/${knightId}/reset_skills`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isDemi: isDemi, sign: sign, msgParams: msgParams }),
    });
    return await response.json();
  } catch (e) {
    console.log(e);
    return null;
  }
}
export async function fetchKnightStatsById(knightId: string, owner: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/${knightId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ owner: owner }),
    });
    const data = await response.json();
    let stats = createKnightStats(data);
    return { stats, data };
  } catch (e: any) {
    console.log(e.status);
    return null;
  }
}

export async function fetchLandDetailsById(landId: string) {
  try {
    let land;
    const response = await fetch(`${ENV.URL.FARALAND}/api/land/${landId}`);
    const res = await response.json();
    if (res) {
      land = createLand(res);
    }
    return land;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getTraits(value: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/trait?${new URLSearchParams({ q: value })}`);
    const res = await response.json();
    return res.docs.map((trait) => {
      return {
        traitType: trait.type[0].toUpperCase() + trait.type.slice(1),
        value: trait.name.split("_").splice(2).join(" "),
      };
    });
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getLandTraits(value: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/trait/landTraits?${new URLSearchParams({ q: value })}`);
    const res = await response.json();
    // console.log(res);
    // console.log(
    //   res.docs.map((trait) => {
    //     return {
    //       traitType: trait.type,
    //       value: trait.name.split("_").join(" "),
    //     };
    //   })
    // );
    return res.docs.map((trait) => {
      return {
        traitType: trait.type,
        value: trait.name.split("_").join(" "),
      };
    });
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function fetchLandAttribute(landIds: string[]) {
  try {
    if (!landIds || !landIds.length) {
      return {};
    }
    const idParams = landIds.join(",");
    const response = await fetch(`${ENV.URL.FARALAND}/api/land?ids=${idParams}`);
    const arrayAttributes = await response.json();
    const returnObj = {};
    arrayAttributes.forEach((attr) => {
      returnObj[attr.id] = attr;
    });
    console.log(returnObj);
    return returnObj;
  } catch (e) {
    return false;
  }
}

export async function fetchDemiKnightStatsById(demiId: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/soul/${demiId}`);
    const result = await response.json();
    return !result || result.doc === null ? null : result;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function defineDemiKnight(demiId: string) {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/soul/summon`;
    const data = { id: demiId };
    const result = await _getResultFromPostAPI(endpoint, data);

    if (!result || result.error) {
      setTimeout(async () => {
        await defineDemiKnight(demiId);
      }, 3000);
    }
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function fetchKnightFromApi(filters: KnightFilterType, query: KnightQuery) {
  try {
    const where = buildKnightListWhereCondition(filters, query);
    const endpoint = `${ENV.URL.FARALAND}/api/hero/filter`;
    const result = await _getResultFromPostAPI(endpoint, where);

    const list = result.data.list;
    const total = result.data.total[0].count;

    return { list, total };
  } catch (error) {
    console.log(error);
  }
}

export async function fetchDemiFromApi(filters: DemiFilterType, query: DemiQuery) {
  try {
    const where = buildKnightListWhereCondition(filters, query);
    const endpoint = `${ENV.URL.FARALAND}/api/soul/filter`;
    const result = await _getResultFromPostAPI(endpoint, where);

    const list = result.data.list;
    const total = result.data.total[0].count;

    return { list, total };
  } catch (error) {
    console.log(error);
  }
}

export async function fetchItemsFromApi(filters: ItemFilterType, query: ItemQuery) {
  try {
    const where = buildItemListWhereCondition(filters, query);
    const endpoint = `${ENV.URL.FARALAND}/api/item/nft/filter`;
    const result = await _getResultFromPostAPI(endpoint, where);

    const list = result.data.list.map((item: any) => createFilteredItemData(item));
    const total = result.data.total[0].count;

    return { list, total };
  } catch (error) {
    console.log(error);
  }
}

export async function fetchLandFromApi(filters: LandFilterType, query: LandQuery) {
  try {
    const where = buildLandListWhereCondition(filters, query);
    const endpoint = `${ENV.URL.FARALAND}/api/land/filter`;
    const result = await _getResultFromPostAPI(endpoint, where);

    const list = result.data.list.map((data: any) => createLand(data));
    const total = result.data.total[0].count;
    const landIds = result.data.landIds;

    return { list, total, landIds };
  } catch (error) {
    console.log(error);
  }
}
export async function fetchMaterialsFromApi(filters: MaterialFilterType, query: MaterialQuery) {
  try {
    const where = buildMaterialListWhereCondition(filters, query);
    const endpoint = `${ENV.URL.FARALAND}/api/material/filter`;
    const result = await _getResultFromPostAPI(endpoint, where);

    const list = result.data.list.map((material: any) => createMaterial(material));
    const total = result.data.total[0].count;

    return { list, total };
  } catch (error) {
    console.log(error);
  }
}

export async function fetchSkillBookFromApi(itemId: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/item/nft/${itemId}`);
    const result = await response.json();
    const item = createSkillBook(result.doc.item);
    return item;
  } catch (error) {
    console.log(error);
  }
}

export async function generateKnightImage(knightId: string): Promise<boolean> {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/hero/image/refresh`;
    const data = { id: knightId };
    const result = await _getResultFromPostAPI(endpoint, data);
    return result.status === 200;
  } catch (e) {
    return false;
  }
}

export async function generateDemiImage(demiId: string): Promise<boolean> {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/soul/image/refresh`;
    const data = { id: demiId };
    const result = await _getResultFromPostAPI(endpoint, data);
    return result.status === 200;
  } catch (e) {
    return false;
  }
}

export async function fetchKnightsAttribute(knightIds: string[]) {
  try {
    if (!knightIds || !knightIds.length) {
      return {};
    }
    const idParams = knightIds.join(",");
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero?ids=${idParams}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const arrayAttributes = await response.json();
    const returnObj = {};
    arrayAttributes.forEach((attr) => {
      returnObj[attr.id] = attr;
    });
    return returnObj;
  } catch (e) {
    return false;
  }
}

export async function fetchDemiAttributes(knightIds: string[]) {
  try {
    if (!knightIds || !knightIds.length) {
      return {};
    }
    const idParams = knightIds.join(",");
    const response = await fetch(`${ENV.URL.FARALAND}/api/soul?ids=${idParams}`);
    const arrayAttributes = await response.json();
    const returnObj = {};
    arrayAttributes.forEach((attr) => {
      returnObj[attr.id] = attr;
    });
    return returnObj;
  } catch (e) {
    return false;
  }
}

export async function fetchUpgradeResult(txHash: string): Promise<boolean | null> {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/trait/upgrade/status`;
    const data = { txHash };
    const result = await _getResultFromPostAPI(endpoint, data);

    return result.success === null ? null : result.success;
  } catch (e) {
    return null;
  }
}

export async function fetchCraftingResult(txHash: string) {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/item/crafting/status`;
    const data = { txHash };
    const result = await _getResultFromPostAPI(endpoint, data);
    if (result.success.reason.length === 0) {
      return null;
    }
    const filteredData: (number | null)[] = result.success.reason
      .filter((item) => item.status === true)
      .map((item) => {
        return item.itemId;
      });
    const success = filteredData.length > 0;

    return { success, filteredData };
  } catch (e) {
    return null;
  }
}

export async function fetchCraftingRewards(wallet: string, isChakraRune = false) {
  try {
    const result = await fetch(`https://auth.faraland.io/api/crafting/reward?type=Crafting&walletAddress=${wallet}`);
    const response = await result.json();
    if (isChakraRune) {
      const rewardMaterials: CraftedMaterials[] = response.rewardMaterials
        .filter((reward) => {
          return reward.quantity > 0;
        })
        .map((material) => createCraftedMaterials(material));
      return rewardMaterials;
    }
    const rewardItems: CraftedItem[] = response.rewardEquipments
      .filter((reward) => {
        return reward.quantity > 0;
      })
      .map((item) => createCraftedItems(item));
    return rewardItems;
  } catch (e) {
    return null;
  }
}

export async function fetchClaimHistory(page: number, wallet: string, web3Service: Web3Service) {
  try {
    const result = await fetch(
      `https://staging-auth.moonknightlabs.com/api/crafting/reward-history/${page}?type=Crafting&walletAddress=${wallet}`
    );
    const res = await result.json();
    if (res.success) {
      let histories = res.history.map((history) => ({ ...history, reclaimAble: false }));
      histories = await Promise.all(histories.map((history) => isEnoughGasFee(history, wallet, web3Service)));

      const addresses = Array.from(new Set<string>(histories.map((history) => history.owner)));
      const domainNames = await resolveDomainNames(addresses);

      histories = histories.map((history) => ({ ...history, spaceDomain: domainNames[history.owner] }));
      const total = res.total;
      const craftHistory: ClaimHistory[] = histories.map((history) => createClaimHistory(history));
      return { craftHistory, total };
    }
  } catch (e) {
    return null;
  }
}

export async function fetchCraftingHistory(page: number, web3Service: Web3Service) {
  try {
    const result = await fetch(`${ENV.URL.FARALAND}/api/item/crafting/history/${page}`);
    const res = await result.json();
    if (res.success) {
      const total = res.total;
      const addresses = Array.from(new Set<string>(res.histories.map((history) => history.walletAddress)));
      const domainNames = await resolveDomainNames(addresses);

      const histories = res.histories
        .map((history) => ({ ...history, spaceDomain: domainNames[history.walletAddress] }))
        .map((history) => createCraftHistory(history));
      return { total, histories };
    }
  } catch (e) {
    return null;
  }
}

export async function fetchRuneCraftingHistory(page: number) {
  try {
    const result = await fetch(`${ENV.URL.FARALAND}/api/material/runeHistory/${page}`);
    const res = await result.json();
    if (res.success) {
      const total = res.total;
      const addresses = Array.from(new Set<string>(res.histories.map((history) => history.walletAddress)));
      const domainNames = await resolveDomainNames(addresses);
      const histories = res.histories
        .map((history) => ({ ...history, spaceDomain: domainNames[history.walletAddress] }))
        .map((history) => createRuneCraftHistory(history));
      return { total, histories };
    }
  } catch (e) {
    return null;
  }
}

export async function checkReclaimable(wallet: string, web3Service: Web3Service) {
  try {
    const result = await fetch(
      `https://auth.faraland.io/api/crafting/reward-history/1?type=Crafting&walletAddress=${wallet}`
    );
    const res = await result.json();
    if (res.success) {
      let histories = res.history.map((history) => ({ ...history, reclaimAble: false }));
      histories = await Promise.all(histories.map((history) => isEnoughGasFee(history, wallet, web3Service)));
      histories = await Promise.all(
        histories.map((history) => fetchSpaceDomainForEachAddress(history, history.owner, web3Service))
      );
      const reclaimableEmpty = _.isEmpty(
        histories.filter((history) => {
          return history.reclaimAble;
        })
      );
      return reclaimableEmpty;
    }
  } catch (e) {
    return false;
  }
}

const isEnoughGasFee = async (row: any, wallet: string, web3Service: Web3Service): Promise<any> => {
  if (!wallet) return Promise.resolve({ ...row });

  try {
    const itemRewards = row.claimHistoryNonce.map((r) => ({
      id: r.material ? r.material.id : r.item.itemNftId,
      amount: r.quantity,
      NFTType: r.material ? 1 : 0,
      signature: r.signature,
    }));
    const res = await web3Service.estimateClaimGasFee(itemRewards, row.nonce, wallet);
    return Promise.resolve({ ...row, reclaimAble: res });
  } catch (error) {
    console.log("Error from outside try catch", error);
  }

  return Promise.resolve({ ...row });
};

const enoughWithdrawGasFee = async (row: any, wallet: string, web3Service: Web3Service): Promise<any> => {
  if (!wallet) return Promise.resolve({ ...row });
  try {
    const history = row.withdrawnHistoryNonce.map((r) => ({
      ids: r.ids,
      amounts: row.type === NON_FEE_INVENTORY_TYPE.TOKEN ? toBigAmount(r.tokenAmount) : r.amounts,
      nonce: row.nonce,
      signature: r.signature,
    }));
    const res = await web3Service.estimateWithdrawGasFee(history[0], wallet, row.type);
    return Promise.resolve({ ...row, reclaimAble: res });
  } catch (error) {
    console.log("Error from outside try catch", error);
  }

  return Promise.resolve({ ...row });
};

export async function claimCraftedItems(itemsClaim: CraftedItem[] | CraftedMaterials[], walletAddress: string) {
  try {
    const endpoint = `https://auth.faraland.io/api/crafting/claim-nft`;
    const data = { itemsClaim, walletAddress };
    const res = await _getResultFromPostAPI(endpoint, data);
    if (res.success) {
      const nftRewards = res.data.map((reward, index) => {
        return {
          id: reward.nftId,
          amount: reward.amount,
          NFTType: reward.nftTypeId,
          nonce: reward.nonce,
          signature: res.sigs[index],
        };
      });

      return nftRewards ?? [];
    }
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function fetchBlacksmithData(account: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/item/crafting/blacksmith/${account}`);
    const res = await response.json();
    if (res) {
      return {
        exp: +res.points,
        level: +res.level,
        pity: {
          common: +res.common,
          uncommon: +res.uncommon,
          rare: +res.rare,
          epic: +res.epic,
        },
      };
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchSpaceDomainForEachAddress(row: any, address: string, web3Service: Web3Service) {
  try {
    const domain = await web3Service?.sid.getName(address);
    if (domain) {
      return Promise.resolve({ ...row, spaceDomain: domain.name });
    }
    return Promise.resolve({ ...row });
  } catch (e) {
    console.log(e);
  }
}

export async function resolveDomainNames(list: string[]) {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/item/resolve`;
    const data = { list };
    const response = await _getResultFromPostAPI(endpoint, data);
    if (response) {
      return response.result;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchBlackMarket() {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/blackmarket/getMarket`);
    const res = await response.json();
    if (res.success) {
      const results = res.result.map((res) => createBlackMarketItem(res));
      return results;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function buyBlackMarketItem(id: number) {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/blackmarket/buy`;
    const data = { id };
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) return response.status;
    const res = await response.json();
    return res;
  } catch (e: any) {
    console.log(e);
  }
}

export async function disconnectWalletSession() {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/disconnect`, { credentials: "include" });
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function getToken() {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/connect/token`);
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
  }
}

export async function authenticateWallet(sign: string, msgParams: any, account: string) {
  try {
    const response = await _postAPIWithCredentials(`${ENV.URL.FARALAND}/authentication`, {
      sign: sign,
      msgParams: msgParams,
      account: account,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getTokenBalance(address: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/blackmarket/getBalance/${address}`);
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchNonFeeInventory(type: string, address: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/item/userInventory/${address}?type=${type}`);
    const res = await response.json();
    if (res.success) {
      if (type === NON_FEE_INVENTORY_TYPE.ITEM) {
        const equipment = res.data.map((item: any) => createUserItemFromAPI(item));
        return { equipment };
      }
      if (type === NON_FEE_INVENTORY_TYPE.MATERIAL) {
        const materials = res.data
          .map((mat: any) => createUserMaterialFromApi(mat))
          .filter((mat: UserMaterial) => mat.available > 0);
        return { materials };
      }
      if (type === NON_FEE_INVENTORY_TYPE.TOKEN) {
        const tokens: UserToken[] = res.data.map((token) => ({
          id: token.partnerId,
          amount: token.tokenAmount,
        }));
        return { tokens };
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchWithdrawHistory(page: number, wallet: string, web3Service: Web3Service) {
  try {
    const result = await fetch(`${ENV.URL.FARALAND}/api/item/withdrawnHistory/${wallet}/${page}`);
    const res = await result.json();
    if (res.success) {
      let histories = res.data.histories.map((history) => ({ ...history, reclaimAble: false }));

      histories = await Promise.all(histories.map((history) => enoughWithdrawGasFee(history, wallet, web3Service)));

      const addresses = Array.from(new Set<string>(histories.map((history) => history.owner)));
      const domainNames = await resolveDomainNames(addresses);

      histories = histories.map((history) => ({ ...history, spaceDomain: domainNames[history.owner] }));
      const total = res.data.total;
      const withdrawHistory: WithdrawHistory[] = histories.map((history) => createWithdrawHistory(history));
      return { withdrawHistory, total };
    }
  } catch (e) {
    return null;
  }
}

export async function fetchWithdrawEquipmentSignature(
  walletAddress: string,
  amounts: number[] | string,
  ids: number[] = [],
  type: string
) {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/item/withdrawn`;
    const data =
      type === NON_FEE_INVENTORY_TYPE.TOKEN ? { walletAddress, amounts, type } : { walletAddress, amounts, ids, type };
    const response = await _getResultFromPostAPI(endpoint, data);
    if (response.success) {
      let dataToSend = { nonce: response.nonce, signature: response.signature };
      if ([NON_FEE_INVENTORY_TYPE.ITEM, NON_FEE_INVENTORY_TYPE.MATERIAL].includes(type)) {
        Object.assign(dataToSend, { ids });
      } else if (type === NON_FEE_INVENTORY_TYPE.TOKEN) {
        Object.assign(dataToSend, { amount: amounts });
      }
      return dataToSend;
    }
    return false;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchGachaTopContributors() {
  try {
    const result = await fetch(`${ENV.URL.FARALAND}/api/gacha/top?list=10`);
    const res = await result.json();
    if (res.success) {
      const addresses = Array.from(new Set<string>(res.result.map((data) => data.walletAddress)));
      const domainNames = await resolveDomainNames(addresses);

      const resData = res.result.map((data) => ({ ...data, spaceDomain: domainNames[data.walletAddress] }));
      const contributors: Contributor[] = resData.map((contributor) => createContributor(contributor));
      return contributors;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchGachaHistory() {
  try {
    const result = await fetch(`${ENV.URL.FARALAND}/api/gacha/histories?list=100`);
    const res = await result.json();
    if (res.success) {
      const addresses = Array.from(new Set<string>(res.result.map((data) => data.walletAddress)));
      const domainNames = await resolveDomainNames(addresses);

      const resData = res.result.map((data) => ({ ...data, spaceDomain: domainNames[data.walletAddress] }));
      const receivedItems: UserReceivedItem[] = resData.map((item) => createUserReceivedItems(item));
      return receivedItems;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchItemsInPool() {
  try {
    const result = await fetch(`${ENV.URL.FARALAND}/api/gacha/pool`);
    const res = await result.json();
    if (res.success) {
      const itemsInPool: ItemsInPoolGacha[] = res.result.map((item) => createItemsInPool(item));
      return itemsInPool;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function spinGacha(id: number, type = NON_FEE_INVENTORY_TYPE.ITEM) {
  try {
    const endpoint = `${ENV.URL.FARALAND}/api/gacha/spin`;
    const data = { type, id };
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) return response.status;
    const res = await response.json();
    if (res.success) {
      const spinGachaResult: GachaSpinResult = createGachaSpinResult(res.result);
      return spinGachaResult;
    }
  } catch (e: any) {
    console.log(e);
  }
}

export async function fetchJackpotHistory() {
  try {
    const result = await fetch(`${ENV.URL.FARALAND}/api/gacha/jackpotHistory`);
    const res = await result.json();
    if (res.success) {
      const addresses = Array.from(new Set<string>(res.result.map((data) => data.walletAddress)));
      const domainNames = await resolveDomainNames(addresses);

      const resData = res.result.map((data) => ({ ...data, spaceDomain: domainNames[data.walletAddress] }));
      const jackpotHistories: GachaHistory[] = resData.map((data) => createJackpotHistory(data));
      return jackpotHistories;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchNonFeeBalance(walletAddress: string) {
  try {
    const result = await fetch(`${ENV.URL.FARALAND}/api/gacha/getBalance/${walletAddress}`);
    const res = await result.json();
    if (res.success) {
      return res;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchGachaPot() {
  try {
    const result = await fetch(`${ENV.URL.FARALAND}/api/gacha/getGachaPot`);
    const res = await result.json();
    if (res.success) {
      return res.gachaPot.toString();
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchAssets(item: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND_GITHUB_ASSET}/${item}`);
    return response.status === 200;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function fetchLearnedSkills(heroId: string, address: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/${heroId}/getLearnedBook`);
    const res = await response.json();
    if (res.success) {
      const skills: UserItem[] = res.learnedBook.map((skill) => {
        const item = EQUIPMENT[skill.book.itemNftId];
        return {
          id: +skill.book.itemNftId,
          owner: address,
          amount: 0,
          available: 0,
          used: 0,
          price: undefined,
          ruleDestItem: undefined,
          item: createItem(item),
        };
      });
      return skills;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function transferBook(heroId: string, tokenId: number, skillId: number, targetCharacterId: number) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/${heroId}/transfer_book`, {
      method: "POST",
      body: JSON.stringify({
        tokenId,
        skillId,
        targetCharacterId,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) return response.status;
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function upgradeBook(heroId: string, tokenId: number, skillId: number) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/${heroId}/uptier_book`, {
      method: "POST",
      body: JSON.stringify({
        tokenId,
        skillId,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) return response.status;
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
    return false;
  }
}
export async function fetchCosts(itemId: number) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/book/getCost/${itemId}`);
    const res = await response.json();
    if (res.success) {
      return createSkillBookCosts(res.cost);
    }
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function switchKnightBuilds(heroId: number, sign: string, msgParams: any, isDemi = false) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/${isDemi ? "soul" : "hero"}/${heroId}/switch`, {
      method: "POST",
      body: JSON.stringify({ sign: sign, msgParams: msgParams }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
  }
}
export async function fetchKnightOffChainEquipment(heroId: string, isDemi = false) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/${isDemi ? "soul" : "hero"}/${heroId}/equipments`);
    const res = await response.json();
    if (res.success) {
      return res.result;
    }
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function equipOffChainItems(knightId: number, itemIds: number[], isDemi = false) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/equip`, {
      method: "POST",
      body: JSON.stringify({
        knightId,
        itemIds,
        isDemi,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) return response.status;
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function unequipOffChainItems(knightId: number, itemIds: number[], isDemi = false) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/hero/unequip`, {
      method: "POST",
      body: JSON.stringify({
        knightId,
        itemIds,
        isDemi,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) return response.status;
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
  }
}
async function _getResultFromPostAPI(endpoint: string, data: any) {
  const response = await fetch(endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return await response.json();
}

export async function fetchNonFeeMaterials(walletAddress: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/material/getMaterialBalance/${walletAddress}`);
    const res = await response.json();
    if (res.success) {
      const materials = res.data
        .map((mat: any) => createUserMaterialFromApi(mat))
        .filter((mat: UserMaterial) => mat.available > 0);
      return { materials };
    }
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function craftRune(
  walletAddress: string,
  amount: number,
  requiredMaterial: number[],
  requiredMaterialAmount: number[],
  requiredItem: number[],
  requiredItemAmount: number[]
) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/material/craftRune`, {
      method: "POST",
      body: JSON.stringify({
        walletAddress,
        amount,
        requiredMaterial,
        requiredMaterialAmount,
        requiredItem,
        requiredItemAmount,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) return response.status;
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function levelUpRune(
  walletAddress: string,
  runeId: number,
  requiredMaterial: number[],
  requiredMaterialAmount: number[],
  requiredItem: number[],
  requiredItemAmount: number[],
  inUnclaimed: boolean
) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/material/levelupRune`, {
      method: "POST",
      body: JSON.stringify({
        walletAddress,
        runeId,
        requiredMaterial,
        requiredMaterialAmount,
        requiredItem,
        requiredItemAmount,
        inUnclaimed,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) return response.status;
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
  }
}
export async function fetchRuneInventory(walletAddress: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/material/runeInventory/${walletAddress}`);
    const res = await response.json();
    if (res.success) {
      return res.data
        .map((mat: any) => createUserMaterialFromApi(mat, walletAddress))
        .filter((mat: UserMaterial) => mat.available > 0);
    }
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function fetchUnclaimedRune(walletAddress: string) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/material/unclaimedRune/${walletAddress}`);
    const res = await response.json();
    if (res.success) {
      return res.data
        .map((mat: any) => createUserMaterialFromApi(mat, walletAddress))
        .filter((mat: UserMaterial) => mat.available > 0);
    }
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function unlockRuneSlot(
  heroId: number,
  sign: string,
  msgParams: any,
  traitType: string,
  slot: string,
  requiredMaterial: number[],
  requiredMaterialAmount: number[]
) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/material/${heroId}/unlockRuneSlot`, {
      method: "POST",
      body: JSON.stringify({ sign, msgParams, traitType, slot, requiredMaterial, requiredMaterialAmount }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function equipRune(
  heroId: number,
  sign: string,
  msgParams: any,
  traitType: string,
  slot: string,
  runeId: number
) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/material/${heroId}/equipRune`, {
      method: "POST",
      body: JSON.stringify({ sign, msgParams, traitType, slot, runeId }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function unequipRune(
  heroId: number,
  sign: string,
  msgParams: any,
  traitType: string,
  slot: string,
  requiredMaterial: number[],
  requiredMaterialAmount: number[]
) {
  try {
    const response = await fetch(`${ENV.URL.FARALAND}/api/material/${heroId}/unequipRune`, {
      method: "POST",
      body: JSON.stringify({ sign, msgParams, traitType, slot, requiredMaterial, requiredMaterialAmount }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
    return [];
  }
}
async function _postAPIWithCredentials(endpoint: string, data: any) {
  const response = await fetch(endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return await response.json();
}
