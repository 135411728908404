import React, { useState } from "react";
import faraLogo from "src/assets/images/tokens/fara.png";
import { formatNumber } from "src/app/utils/helpers";
import { multiplyNumbers } from "src/app/utils/calculators";
import { useSelector } from "react-redux";
import hourglassHighlight from "src/assets/images/decors/hourglass-highlight.png";
import heroPlaceholder from "src/assets/images/decors/hero-placeholder.png";
import ENV from "src/app/configs/env";
import { Link } from "react-router-dom";
import { DemiKnight } from "src/app/types/demiKnight";
import { KnightStats } from "src/app/types/knight";
import KnightPopover from "src/app/components/Knight/KnightPopover";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import KnightStatsInfo from "src/app/components/Knight/KnightStatsInfo";
import useWindowDimensions from "src/app/hooks/useWindowDimensions";

interface DemiListContent {
  demi: DemiKnight;
  demiStats?: KnightStats;
  className?: string;
  isLoading?: boolean;
}

export default function DemiListContent(props: DemiListContent) {
  const { tokenPrice } = useSelector((state: any) => state.global);
  const [isLoading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const windowDimensions = useWindowDimensions();
  const theme: any = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    isLg && setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function handleImageLoaded() {
    setLoading(false);
  }

  const renderDemiListContent = () => {
    if (props.demi.id === undefined) return;

    return (
      <Grid container className="knights__item-wrapper" alignItems="center">
        <Box {...(windowDimensions.width <= 1200 && { display: "flex", alignItems: "center" })}>
          <div className="knights__item-images">
            <img className="knights__item-highlight" src={hourglassHighlight} alt="" />
            {isLoading && <img className="knights__item-character" src={heroPlaceholder} alt="" />}
            <img
              style={!isLoading ? {} : { display: "none" }}
              className="knights__item-character"
              src={`${ENV.URL.FARALAND}/images/souls/png/${props.demi.id}.png`}
              onLoad={handleImageLoaded}
              alt=""
            />
          </div>
          <div className={`knights__item-id ${props.demi.element?.toLowerCase()}`}>#{props.demi.id}</div>
          <div className={`knights__item-level`}>Level: {props.demi.level}</div>
          <div>
            <div className="knights__item-data">
              {props.demi.element && (
                <img
                  className="knights__item-element"
                  src={require(`src/assets/images/icons/elements/${props.demi.element.toLowerCase()}.png`)}
                  alt=""
                />
              )}
              <div className="knights__item-gender">{props.demi.gender}</div>
              <div className="knights__item-race">{props.demi.displayRace}</div>
            </div>
            <div className="knights__item-content">
              <div className="knights__item-footer">
                <div className="flex-start-center">
                  <img className="knights__item-token--fara top-2" src={faraLogo} alt="" />
                  <div className="knights__item-price">
                    <div>{props.demi.price ? formatNumber(props.demi.price, 2) : "-/-"} FARA</div>
                    {!!props.demi.price && (
                      <div className="knights__item-value">
                        ≈ ${formatNumber(multiplyNumbers(props.demi.price, tokenPrice.FARA), 2)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        {props.demiStats && (
          <>
            <KnightStatsInfo baseStats={props.demiStats!.baseStats} />
            <KnightPopover
              open={openPopover}
              anchorEl={anchorEl}
              knight={props.demi}
              knightStats={props.demiStats}
              handlePopoverClose={handlePopoverClose}
            />
          </>
        )}
      </Grid>
    );
  };

  return (
    <Link
      to={`demi-hero/${props.demi.id}`}
      className={`market__item market__item--knight knights__item ${props.className ?? ""}`}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {renderDemiListContent()}
    </Link>
  );
}
