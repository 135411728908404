import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import Modal from "./Modal";
import { setGlobalModal } from "src/app/actions/globalAction";
import androidDownload from "src/assets/images/banners/android-download.png";
import appleDownload from "src/assets/images/banners/apple-download.png";
import { ROUTE } from "src/app/configs/constants";

export default function DownloadModal() {
  const { modal } = useSelector((state: any) => state.global);

  const dispatch = useDispatch();
  const downloadModal = modal.downloadModal;

  function handleClose() {
    dispatch(setGlobalModal("downloadModal"));
  }
  function renderContent() {
    return (
      <div className="home-banner__modal">
        <div className="home-banner__modal-bold">THE GAME IS NOW READY</div>
        <div className="home-banner__modal-text">Learn everything about Faraland <a href={ROUTE.INSTRUCTIONS}><b>here</b></a></div>
        <div className="home-banner__modal-separator">-----------------------</div>
        <div className="home-banner__modal-bold home-banner__modal-bold--download">Download and Sign-up Instructions</div>
        <div className="home-banner__modal-buttons">
          <a href="https://blog.faraland.io/2021/11/30/faraland-expedition-game-register-instruction-android-version/" target="_blank" rel="noopener noreferrer">
            <img src={androidDownload} alt="" />
          </a>
          <a href=" https://blog.faraland.io/2021/11/30/faraland-expedition-game-register-instruction-ios-version/"  target="_blank" rel="noopener noreferrer">
            <img src={appleDownload} alt="" />
          </a>
        </div>
      </div>
    );
  }

  return (
    <Modal isActive={downloadModal.active} onClose={handleClose} hideCloseBtn className="home-banner__modal-bg large">
      <BasicModalContent
        noPanel
        content={<div className="text-center">{renderContent()}</div>}
      />
    </Modal>
  );
}
