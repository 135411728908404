import React, { useState } from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { useSelector, useDispatch } from "react-redux";
import InputGroup from "src/app/components/Commons/InputGroup";
import soulStoneLogo from "src/assets/images/tokens/soulstone.png";
import { EXP_PER_SS } from "src/app/configs/constants";
import { formatNumber } from "src/app/utils/helpers";
import { compareNumbers } from "src/app/utils/calculators";
import { consumeSoulStone } from "src/app/actions/materialAction";

type SoulStoneExpModalProps = {
  knightId: number;
  knightType: number;
  onSuccess: any;
};

export default function SoulStoneExpModal(props: SoulStoneExpModalProps) {
  const dispatch = useDispatch();
  const { balance } = useSelector((state: any) => state.account);
  const [inputError, setInputError] = useState("");
  const [amount, setAmount] = useState("");
  const invalidAmount = +amount <= 0 || amount.indexOf('.') === 1;

  function handleAmountChange(e: any, amount?: string) {
    setInputError("");
    if (amount) {
      setAmount(amount);
      return;
    }
    setAmount(e.target.value);
  }

  function consume() {
    const compareBalance = compareNumbers(balance.SOUL_STONE, amount);
    if (invalidAmount) {
      setInputError(`Invalid amount.`);
      return;
    } else if (compareBalance === -1) {
      setInputError(`Insufficient Soul Stone balance.`);
      return;
    }

    dispatch(consumeSoulStone(props.knightId, props.knightType, +amount, props.onSuccess));
  }

  return (
    <BasicModalContent
      customTitle="Exchange Soul Stone for EXP"
      submitText="Consume"
      onSubmit={consume}
      content={(
        <div className="mt-6">
          <InputGroup
            className="text-field--large"
            value={amount}
            handleChange={handleAmountChange}
            symbol="Soul Stone(s)"
            balance={balance.SOUL_STONE}
            error={inputError}
            tokenImage={soulStoneLogo}
            label={"Soul Stone Amount"}
          />
          {!invalidAmount && (
            <div className="mt-3 small-text small-text--warning slide-up">
              By consuming {formatNumber(amount)} Soul Stone(s), your Hero will earn
              an equivalent of {formatNumber(+amount * EXP_PER_SS)} EXPs.
            </div>
          )}
          <div className="fs-2 mt-3">
            Note: 1 Soul Stone = {EXP_PER_SS} EXPs
          </div>
        </div>
      )}
    />
  );
}
