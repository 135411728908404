import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import jackpotImage from "src/assets/images/equipment-gacha/jackpot.png";
import fountainImage from "src/assets/images/equipment-gacha/fountain.png";
import itemPoolImage from "src/assets/images/equipment-gacha/item-pool.png";
import tableFrameImage from "src/assets/images/equipment-gacha/table-frame.png";
import fountainItemImage from "src/assets/images/equipment-gacha/fountain-item.png";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ENV from "src/app/configs/env";
import { setGlobalModal } from "src/app/actions/globalAction";
import { Contributor, GachaHistory, ItemsInPoolGacha, UserReceivedItem } from "src/app/types/gacha";
import JackpotHistoryTable from "src/app/components/EquipmentGacha/JackpotHistoryTable";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import ChoosingEquipmentModal from "src/app/components/EquipmentGacha/ChoosingEquipmentModal";
import { INTERVAL, NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";
import ResultContent from "src/app/components/Commons/ResultContent";
import { SpinePlayer } from "@esotericsoftware/spine-player";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import { formatNumber, signAndAuthenticateWallet, timeAgo } from "src/app/utils/helpers";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import faraLogo from "src/assets/images/tokens/fara.png";
import {
  fetchAssets,
  fetchGachaHistory,
  fetchGachaPot,
  fetchGachaTopContributors,
  fetchItemsInPool,
  fetchJackpotHistory,
  spinGacha,
} from "src/app/services/api/faralandService";
import { Item } from "src/app/types/equipment";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import { Material } from "src/app/types/materials";
import { renderWalletAddress } from "src/app/utils/renderHelpers";

export default function EquipmentGacha() {
  const dispatch = useDispatch();
  const { web3Service } = useSelector((state: any) => state.global);
  const { wallet, address } = useSelector((state: any) => state.account);
  const fetchingInterval = useRef<any>();
  const history = useHistory();

  const [itemsInPool, setItemsInPool] = useState<ItemsInPoolGacha[]>([]);
  const [userReceivedItems, setUserReceivedItems] = useState<UserReceivedItem[]>([]);
  const [topContributors, setTopContributors] = useState<Contributor[]>([]);
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const [jackpotHistories, setJackpotHistories] = useState<GachaHistory[]>([]);
  const [gachaPlayer, setGachaPlayer] = useState<any>();
  const [jackpotReward, setJackpotReward] = useState("0");
  const [filterObj, setFilterObj] = useState({ name: "", tier: 1, rarity: "" });
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect(() => {
    if (!assetsLoaded) {
      checkAssets("Stone_1.json", "Stone_1.atlas");
      return;
    }
    const player = new SpinePlayer("player-container", {
      jsonUrl: `${ENV.URL.FARALAND_GITHUB_ASSET}/Stone_1.json`,
      atlasUrl: `${ENV.URL.FARALAND_GITHUB_ASSET}/Stone_1.atlas`,
      animations: ["1_frame", "animation2"],
      showControls: false,
      showLoading: false,
      preserveDrawingBuffer: false,
      alpha: true,
      viewport: {
        x: -600,
        y: 0,
        width: 1200,
        height: 1200,
        padLeft: 0,
        padRight: 0,
        padTop: 0,
        padBottom: 0,
      },
    });
    setGachaPlayer(player);
  }, [assetsLoaded]);

  useEffect(() => {
    if (!web3Service) return;
    fetchTopContributors();
    fetchHistory();
    fetchItemsInGachaPool();
    fetchJackpotHistories();
    fetchJackpotTokenReward();
    fetchingInterval.current = setInterval(() => {
      fetchTopContributors();
      fetchHistory();
      fetchItemsInGachaPool();
      fetchJackpotHistories();
      fetchJackpotTokenReward();
    }, INTERVAL.GACHA_EQUIPMENT);

    return () => {
      clearInterval(fetchingInterval.current);
    };
  }, [web3Service]); // eslint-disable-line

  async function fetchTopContributors() {
    const res = await fetchGachaTopContributors();
    if (res) {
      setTopContributors(res);
    }
  }

  async function checkAssets(jsonUrl: string, atlasUrl) {
    const jsonCheck = await fetchAssets(jsonUrl);
    const atlasCheck = await fetchAssets(atlasUrl);
    if (jsonCheck && atlasCheck) {
      setAssetsLoaded(true);
      return;
    }
    setAssetsLoaded(false);
  }

  async function fetchHistory() {
    const res = await fetchGachaHistory();
    if (res) {
      setUserReceivedItems(res);
    }
  }

  async function fetchJackpotHistories() {
    const res = await fetchJackpotHistory();
    if (res) {
      setJackpotHistories(res);
    }
  }

  async function fetchItemsInGachaPool() {
    const res = await fetchItemsInPool();
    if (res) {
      setItemsInPool(_.orderBy(res, (item: ItemsInPoolGacha) => item.rarity, ["desc"]));
    }
  }

  async function fetchJackpotTokenReward() {
    const res = await fetchGachaPot();
    if (res) {
      setJackpotReward(res);
    }
  }

  function showSenoraChestModal() {
    modalService.show(BasicModalContent, {
      content: (
        <div>
          <div className="align-center">
            <img src={jackpotImage} alt="" />
          </div>
          <h3 className="mt-3 mb-1">What is Senora Chest?</h3>
          <div>
            <div>
              This is a special gift that brings blessings from the Lady of the Lake. She will return a generous reward
              to those who devoted themselves to her.
            </div>
            <div className="mt-3">
              <b>Lady of the Lake fee distribution:</b>
              <div>1. Distribution will be as follows:</div>
              <div className="mb-1">
                - From 1 - 100,000 FARA: <b>75%</b> of the fee will be contributed to the Senora chest as reward for the
                luckiest person. The remaining <b>25%</b> will be used for development.
              </div>
              <div className="mb-1">
                - From 100,000 - 150,000 FARA: the rate remains the same: <b>30%</b> of the fee is for those who win the
                Senora Chest and the remaining <b>70%</b> is used for development.
              </div>
              <div className="mb-2">
                - Over 150,000 FARA: <b>10%</b> of fees will go to Senora Chest winners and <b>90%</b> to development.
              </div>
              <div className="mb-2">2. All Soul Stones collected will be burned with each transaction.</div>
              <div className="mb-2">
                3. The reward rate is <b>0.05%</b>. After someone is lucky enough to receive the reward, the magic chest
                continues to be filled and waits for the next person to receive it.
              </div>
              <div className="mb-2">
                <b>4. Mechanism: Win the Senora Chest</b>
                <p className="mb-1">
                  - Total pool Senora Chest below 80,000 FARA: The winner gets 100% of the Senora Chest. Development
                  will add an additional 20,000 FARA to remain of the next Senora Chest.
                </p>
                <p>
                  - Total pool Senora Chest is greater than or equal to 80,000 FARA: The winner receives prize (Total pool Senora Chest minus
                  20,000 FARA). 20,000 FARA is added back into the next Senora Chest.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    });
  }

  function showLearnMoreModal() {
    modalService.show(BasicModalContent, {
      content: (
        <div>
          <h3 className="mt-1 mb-3">Lady of the Lake's rules</h3>
          <div>
            <div>
              1. The equipment tier will not be taken into consideration when participating in Lady of the Lake.
            </div>
            <div className="mb-1 mt-1">
              2. The chance of getting an equal rarity equipment is <b>70%</b>. There might be a possibility to receive
              the same item that you've just put into the Lake.
            </div>
            <div className="mb-1">
              3. The chance of getting a higher rarity equipment is <b>5%</b> for epic and <b>10%</b> for all other
              rarities.
            </div>
            <div>
              4. There will be <b>20%</b> of having your item downgraded.
              <div>
                - With equipment from <b>Uncommon and above</b>, you will get lower level equipment.
              </div>
              <div>
                - With <b>Common</b> items, you will lose them forever.
              </div>
            </div>
            <div>
              5. There is a small chance to increase 2 rarity levels for <b className="rarity-rare">Rare</b> and{" "}
              <b className="rarity-epic">Epic</b> item, specifically as follows:
              <div>
                - <b className="rarity-rare">Rare</b> to <b className="rarity-legendary">Legendary</b>: <b>0.5%</b>
              </div>
              <div>
                - <b className="rarity-epic">Epic</b> to <b className="rarity-mythical">Mythical</b>: <b>2%</b>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <i>*Note:</i> By lower level equipment, it does not mean 1 level rarity lower but it can be 2 or 3 depending
            on the current available items in the pool. So a <b className="rarity-legendary">Legendary</b> Item can be
            down all the way to even <b className="rarity-rare">Rare</b> one in case there is no{" "}
            <b className="rarity-epic">Epic</b> items in the pool.
          </div>
          <div className="mt-3">
            Lady of the Lake:{" "}
            <i className="text-gray">
              “Dear our mighty Heroes, test your luck by throwing one of your equipment here. Under my blessing, your
              item can be swapped with another item of equal or greater value. But be aware, there will be a small
              chance of having your item downgraded!”
            </i>
          </div>
        </div>
      ),
    });
  }

  function showHistoryModal() {
    modalService.show(BasicModalContent, {
      content: <JackpotHistoryTable histories={jackpotHistories} />,
    });
  }

  function showChoosingItemModal() {
    modalService.show(ChoosingEquipmentModal, {
      poolItems: itemsInPool,
      onItemSelected: throwItem,
      filterCriteria: filterObj,
      setFilterCriteria: setFilterObj,
    });
  }

  function showReceivedItem(receivedItem: Item | null) {
    modalService.show(BasicModalContent, {
      content: receivedItem ? (
        <>
          <ItemIconPreview item={receivedItem} className="axe__result-item" showDetails />
          <div className="axe__result-desc">
            You got <b>{receivedItem.displayName}</b>
          </div>
        </>
      ) : (
        <ResultContent isSuccess={false} content={<div>Your item is lost. Better luck next time.</div>} />
      ),
      submitText: "Try again",
      onSubmit: () => showChoosingItemModal(),
    });
  }

  async function throwItem(itemId: number | undefined) {
    if (!itemId) return showChoosingItemModal();
    const res = await spinGacha(itemId, NON_FEE_INVENTORY_TYPE.ITEM);
    if (res === 401) {
      const response = await signAndAuthenticateWallet(wallet, address);
      if (response.success) {
        throwItem(itemId);
      } else {
        dispatch(
          setGlobalModal("error", {
            active: true,
            data: <div>Unable to authenticate your wallet. Please try again</div>,
          })
        );
      }
      return;
    }
    if (res) {
      if (assetsLoaded) {
        setAnimationPlayed(true);
        gachaPlayer.setAnimation("animation2");
      } else {
        dispatch(setGlobalModal("loading", { active: true }));
      }
      setTimeout(() => {
        if (assetsLoaded) {
          gachaPlayer.setAnimation("1_frame");
          setAnimationPlayed(false);
        } else {
          dispatch(setGlobalModal("loading"));
        }
        if (res.isJackpot) {
          modalService.show(BasicModalContent, {
            content: (
              <div className="align-center">
                <img src={jackpotImage} onClick={showHistoryModal} alt="" />
                <div className="mt-3 mb-1 axe__content-jackpot--title">Congrats!</div>
                <div className="axe__content-jackpot--reward">You won {formatNumber(jackpotReward)} FARA</div>
              </div>
            ),
            close: () => showReceivedItem(res.item),
          });
        } else {
          showReceivedItem(res.item);
        }
      }, 7000);
    }
  }

  function redirectToEquipmentDetails(item) {
    history.push(`/equipment/${item.id}`);
    modalService.close();
  }

  function redirectToMaterialDetails(item) {
    history.push(`/material/${item.id}`);
    modalService.close();
  }

  return (
    <div className="axe">
      <div className="axe__wrapper">
        <div className="axe__hint">Place your item here</div>
        <div className="axe__content">
          <div
            id="player-container"
            className={`axe__content-player axe__content-player--${animationPlayed && "visible"}`}
          />
          <div className={`${animationPlayed && "disabled"} axe__content-main-area`}>
            <div className="axe__content-table">
              <div className="axe__content-table--title">Top Contributors</div>
              <div className="axe__content-table--content nice-scroll nice-scroll--small">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Address</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.take(topContributors, 10).map((c, idx) => (
                      <tr key={idx}>
                        <td className="axe__content-table--index">{idx + 1}</td>
                        <td>{renderWalletAddress(c.id, c.spaceDomain, 4)}</td>
                        <td className="axe__content-table--fara">
                          <span>{formatNumber(c.amount)}</span>
                          <img className="token token--small top-2 ml-1" src={faraLogo} alt="" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <img className="axe__content-table--frame" src={tableFrameImage} alt="" />
            </div>
            <div className="axe__content-wrapper">
              <div className="axe__content-jackpot">
                <img className="axe__content-jackpot--image" src={jackpotImage} onClick={showHistoryModal} alt="" />
                <div className="axe__content-jackpot--reward">
                  {formatNumber(jackpotReward)} FARA{" "}
                  <HelpOutlineIcon className="axe__content-jackpot--help" onClick={showSenoraChestModal} />
                </div>
              </div>
              <div className="axe__content-main">
                <div className="axe__content-help" onClick={showLearnMoreModal}>
                  <HelpOutlineIcon fontSize="small" />
                </div>
                <img className="axe__content-image" src={fountainImage} alt="" />
                <div style={{ position: "absolute", top: -40 }}>
                  <div style={{ position: "relative" }}>
                    <img className="axe__content-image--pool" src={itemPoolImage} alt="" />
                    <div className="axe__content-inventory nice-scroll nice-scroll--small">
                      {itemsInPool
                        .filter((i) => i.amount > 0)
                        .map((i, idx) => {
                          if (i.type === NON_FEE_INVENTORY_TYPE.ITEM) {
                            return (
                              <ItemIcon
                                key={idx}
                                item={i.item as Item}
                                amount={i.amount}
                                submitText="Detail"
                                onSubmit={() => redirectToEquipmentDetails(i)}
                                showFrame
                                showBalance
                                hideSelectedBorder
                              />
                            );
                          }
                          return (
                            <MaterialIcon
                              key={idx}
                              material={i.item as Material}
                              amount={i.amount}
                              submitText="Detail"
                              onSubmit={() => redirectToMaterialDetails(i)}
                              hideSelectedBorder
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
                <img
                  className="axe__content-image--item"
                  src={fountainItemImage}
                  alt=""
                  onClick={showChoosingItemModal}
                />
              </div>
            </div>
            <div className="axe__content-table">
              <div className="axe__content-table--title">History</div>
              <img className="axe__content-table--frame" src={tableFrameImage} alt="" />
              <div className="axe__content-table--content axe__content-table--history nice-scroll nice-scroll--small">
                <table>
                  <thead>
                    <tr>
                      <th>Address</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userReceivedItems.map((i, idx) => (
                      <tr key={idx}>
                        <td>{renderWalletAddress(i.account, i.spaceDomain, 4)}</td>
                        <td>
                          <div className="item-crafting__rarity-level">
                            <ItemIconPreview className="item-crafting__rarity-image" item={i.from} showDetails />
                          </div>
                        </td>
                        <td>
                          <div className="item-crafting__rarity-level align-center">
                            {!i.to ? (
                              <span>Loss</span>
                            ) : (
                              <ItemIconPreview className="item-crafting__rarity-image" item={i.to} showDetails />
                            )}
                          </div>
                        </td>
                        <td>{timeAgo(i.timestamp)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
