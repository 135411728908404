import React from "react";
import { Skill, SkillBookCosts, SkillLevel } from "src/app/types/skills";
import ENV from "src/app/configs/env";

export function createSkills(data: any, skillId?: number, learned?: number, apiSkills?: any): Skill[] {
  if (!data) return [];
  return data.map((skill) => {
    let actualLearned;
    if (apiSkills) {
      actualLearned = _getApiSkill(apiSkills, skill.id)?.learned;
    }
    return {
      id: skill.id,
      skillId: skill.skillId,
      icon: `${ENV.URL.BASE_IMAGE_URL}/assets/skills/${skill.skillId}.png`,
      title: skill.name ?? skill.title,
      children: createSkills(skill.children, skillId, learned, _getApiSkill(apiSkills, skill.id)?.children),
      tooltip: {
        content: renderTooltip(skill, skillId, learned),
      },
      description: skill.description,
      learned: learned !== undefined && skillId === skill.id ? learned : skill.learned,
      actualLearned: actualLearned ?? skill.learned,
      levels: skill.levels,
      parentId: skill.parentId,
      requiredLevel: skill.requiredLevel ?? 0,
      job: skill.job,
      jobName: skill.jobName,
      type: skill.type,
      availableInGame: skill.availableInGame ?? false,
      bodyPartBonus: skill.bodyPartBonus,
    };
  });
}

export function createSkillBookCosts(data: any): SkillBookCosts {
  return {
    knight: {
      tokenId: data.knight.tokenId,
      moveCost: data.knight.moveCost ?? 0,
      upCost: data.knight.upCost ?? 0,
    },
    royal: {
      tokenId: data.royal.tokenId,
      moveCost: data.royal.moveCost ?? 0,
      upCost: data.royal.upCost ?? 0,
    },
  };
}

function _getApiSkill(apiSkills, skillId) {
  return apiSkills?.filter((apiSkill) => {
    return apiSkill.id === skillId;
  })[0];
}

const _getLevelStats = (levels: any[], stat: (level) => string) => {
  const isEqual = levels && levels.every((level) => stat(level) === stat(levels[0]));

  return levels.reduce((acc: any[], level) => {
    if (isEqual) {
      return [stat(levels[0])];
    } else {
      return [...acc, stat(level)];
    }
  }, []);
};

const renderStats = (skill: Skill, statsArray: any[], learnedNum: number) => {
  const hasOneStat = statsArray.length === 1;
  return (
    <>
      {skill.availableInGame ? (
        <>
          {statsArray.map((val, index) => (
            <span key={index}>
              <span className="text-gray">{index !== 0 && " / "}</span>
              <span
                className={(hasOneStat ? learnedNum <= index : learnedNum !== index + 1) ? "text-gray" : "bold fs-3"}
              >
                {val}
              </span>
            </span>
          ))}
        </>
      ) : (
        <span className="text-gray">???</span>
      )}
    </>
  );
};

const renderTooltip = (skill: Skill, skillId?: number, learned?: number) => {
  const learnedNum = learned !== undefined && skillId === +skill.id ? learned : skill.learned;
  const cooldown = _getLevelStats(skill.levels, (level: SkillLevel) => level.cooldown);
  const stats = Object.keys(skill.levels[0].skillConfig).map((key) => {
    return {
      text: skill.levels[0].skillConfig[key].text === key ? "" : skill.levels[0].skillConfig[key].text,
      value: skill.levels.map((level) => {
        return level.skillConfig[key].value;
      }),
    };
  });
  return (
    <>
      {skill.availableInGame ? (
        <div className="knight-skills__available--true">Can be used in-game</div>
      ) : (
        <div className="knight-skills__available--false">Cannot be used in-game yet</div>
      )}
      <div className="fs-3">
        <b>Level {skill.levels[learnedNum === 0 ? learnedNum : learnedNum - 1].lvl}</b>
      </div>
      <div className="success-text fs-3">{skill.description}</div>
      <div className="mt-5">
        {stats.map((stat, index) => {
          if (stat.text === "") return null;
          const levelStats = _getLevelStats(stat.value, (value: string) => value);
          return (
            <div className="mt-1" key={index}>
              {stat.text}: {renderStats(skill, levelStats, learnedNum)}
            </div>
          );
        })}
        {cooldown && Boolean(cooldown[0]) && (
          <div className="mt-1">Cooldown (turns): {renderStats(skill, cooldown, learnedNum)}</div>
        )}
        {skill.range && (
          <div className="mt-1">
            Cast Range (tiles): <span className={learnedNum <= 0 ? "text-gray" : "bold fs-3"}>{skill.range}</span>
          </div>
        )}
        {skill.bodyPartBonus && (
          <>
            <div className="fs-4 mt-3">Body Parts Bonus</div>
            {skill.bodyPartBonus.split("\n").map((bonus, index) => (
              <div key={index} className="text-gray mt-1">
                {bonus}
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};
