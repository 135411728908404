import { Tabs, Tab, Tooltip } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BatchDismantleModal from "src/app/components/Commons/Modals/BatchDismantleModal";
import BatchTransferModal from "src/app/components/Commons/Modals/BatchTransferModal";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import ItemIcon from "src/app/components/Equipment/Commons/ItemIcon";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import { INTERVAL, EQUIPMENT_LABEL, INVENTORY_TYPE, NON_FEE_INVENTORY_TYPE } from "src/app/configs/constants";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { queryUserEquipment, queryUserMaterials } from "src/app/services/api/subgraphService";
import { Item, UserItem } from "src/app/types/equipment";
import { UserMaterial } from "src/app/types/materials";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import GavelIcon from "@material-ui/icons/Gavel";
import BookIcon from "@material-ui/icons/Book";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import swordIcon from "src/assets/images/icons/sword-icon-white.svg";
import depositIcon from "src/assets/images/icons/deposit.svg";
import withdrawIcon from "src/assets/images/icons/withdraw.svg";
import { fetchNonFeeInventory } from "src/app/services/api/faralandService";
import WithdrawHistoryModal from "src/app/components/Commons/Modals/WithdrawHistoryModal";
import HistoryIcon from "@material-ui/icons/History";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import faraLogo from "src/assets/images/tokens/fara-logo.png";
import { formatNumber } from "src/app/utils/helpers";
import TokenTransferModal from "src/app/components/Commons/TokenTransferModal";
import { UserToken } from "src/app/types/token";

type WalletInventoryProps = {
  type: number;
};

export default function WalletInventory(props: WalletInventoryProps) {
  const { type } = props;
  const { address, balance } = useSelector((state: any) => state.account);
  const [availableUserItems, setAvailableUserItems] = useState<UserItem[]>([]);
  const [userItems, setUserItems] = useState<UserItem[]>([]);
  const [userSkills, setUserSkills] = useState<UserItem[]>([]);
  const [itemLoading, setItemLoading] = useState(false);
  const [userMaterials, setUserMaterials] = useState<UserMaterial[]>([]);
  const [userTokens, setUserTokens] = useState<UserToken[]>([]);
  const [materialLoading, setMaterialLoading] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const history = useHistory();
  const inventoryInterval = useRef<any>();

  const handleTabChange = (_e: any, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!address) {
      setUserItems([]);
      setUserMaterials([]);
      setUserTokens([]);
      return;
    }

    _fetchUserOwnEquipment(address, type);
    _fetchUserOwnMaterials(address, type);

    inventoryInterval.current = setInterval(() => {
      _fetchUserOwnEquipment(address, type, false);
      _fetchUserOwnMaterials(address, type, false);
    }, INTERVAL.INVENTORY_WALLET);

    return () => {
      clearInterval(inventoryInterval.current);
    };
  }, [address, type]); // eslint-disable-line

  useEffect(() => {
    if (!address) {
      setUserTokens([]);
      return;
    }
    _fetchUserOwnTokens(address, type);
  }, [address, balance]); // eslint-disable-line

  function redirectToEquipmentDetails(userItem: UserItem) {
    history.push(`/equipment/${userItem.item.id}`);
  }

  function redirectToMaterialDetails(userMaterial: UserMaterial) {
    history.push(`/material/${userMaterial.material.id}`);
  }

  async function _fetchUserOwnEquipment(address: string, type: number, isLoading = true) {
    const items: Item[] = [];
    let result;
    setItemLoading(isLoading);
    if (type === INVENTORY_TYPE.TRADITIONAL) {
      result = await queryUserEquipment(address);
    } else if (type === INVENTORY_TYPE.NON_FEE) {
      result = await fetchNonFeeInventory(NON_FEE_INVENTORY_TYPE.ITEM, address);
    }
    if (result) {
      result.equipment.forEach((userItem: UserItem) => {
        items.push(EQUIPMENT[userItem.item.id]);
      });
      const skillItems = items
        .filter((item: Item) => {
          return item && EQUIPMENT_LABEL[item.type] === "Skill Book";
        })
        .map((item: Item) => {
          return item.id;
        });
      setAvailableUserItems(
        result.equipment.filter((item: UserItem) => {
          return item.available > 0;
        })
      );
      setUserItems(
        result.equipment.filter((inventoryItem: UserItem) => {
          return !skillItems.includes(inventoryItem.item.id) && inventoryItem.amount > 0;
        })
      );
      setUserSkills(
        result.equipment.filter((inventoryItem: UserItem) => {
          return skillItems.includes(inventoryItem.item.id) && inventoryItem.amount > 0;
        })
      );
    }
    setItemLoading(false);
  }

  async function _fetchUserOwnMaterials(address: string, type: number, isLoading = true) {
    let result;
    setMaterialLoading(isLoading);
    if (type === INVENTORY_TYPE.TRADITIONAL) {
      result = await queryUserMaterials(address);
    } else if (type === INVENTORY_TYPE.NON_FEE) {
      result = await fetchNonFeeInventory(NON_FEE_INVENTORY_TYPE.MATERIAL, address);
    }
    if (result) setUserMaterials(result.materials);
    setMaterialLoading(false);
  }

  async function _fetchUserOwnTokens(address: string, type: number, isLoading = true) {
    let result;
    setTokenLoading(isLoading);
    if (type === INVENTORY_TYPE.TRADITIONAL) {
      result = {
        tokens: [
          {
            id: 1,
            amount: balance.FARA,
          },
        ],
      };
    } else if (type === INVENTORY_TYPE.NON_FEE) {
      result = await fetchNonFeeInventory(NON_FEE_INVENTORY_TYPE.TOKEN, address);
    }
    if (result) setUserTokens(result.tokens);
    setTokenLoading(false);
  }

  function openBatchTransferModal() {
    modalService.show(BatchTransferModal, {
      userItems: availableUserItems,
      userMaterials: userMaterials,
      isMaterialTransfer: tabValue === 2,
    });
  }

  function openBatchDismantleModal() {
    modalService.show(BatchDismantleModal, {
      userItems: availableUserItems,
    });
  }

  const renderTabContent = (tabValue: number) => {
    switch (tabValue) {
      case 0:
        return (
          <div>
            {userItems.length > 0 && !itemLoading ? (
              <>
                {userItems.map((userItem: UserItem, index: number) => {
                  return (
                    <ItemIcon
                      key={index}
                      userItem={userItem}
                      item={userItem.item}
                      showBalance={true}
                      closeOnSubmit={true}
                      submitText="Detail"
                      onSubmit={() => redirectToEquipmentDetails(userItem)}
                    />
                  );
                })}
              </>
            ) : (
              <div className="mt-2 text-gray">
                {itemLoading ? "Loading..." : address ? "You have no Equipment." : "Please connect your wallet first."}
              </div>
            )}
          </div>
        );
      case 1:
        return (
          <div>
            {userSkills.length > 0 && !itemLoading ? (
              <>
                {userSkills.map((userItem: UserItem, index: number) => {
                  return (
                    <ItemIcon
                      key={index}
                      userItem={userItem}
                      item={userItem.item}
                      showBalance={true}
                      closeOnSubmit={true}
                      submitText="Detail"
                      onSubmit={() => redirectToEquipmentDetails(userItem)}
                    />
                  );
                })}
              </>
            ) : (
              <div className="mt-2 text-gray">
                {itemLoading
                  ? "Loading..."
                  : address
                  ? "You have no Skill Books."
                  : "Please connect your wallet first."}
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div>
            {userMaterials.length > 0 && !materialLoading ? (
              <>
                {userMaterials.map((userMaterial: UserMaterial, index: number) => {
                  return (
                    <MaterialIcon
                      key={index}
                      userMaterial={userMaterial}
                      material={userMaterial.material}
                      showBalance={true}
                      submitText="Detail"
                      onSubmit={() => redirectToMaterialDetails(userMaterial)}
                      closeOnSubmit
                    />
                  );
                })}
              </>
            ) : (
              <div className="mt-2 text-gray">
                {materialLoading
                  ? "Loading..."
                  : address
                  ? "You have no Materials."
                  : "Please connect your wallet first."}
              </div>
            )}
          </div>
        );
      case 3:
        return (
          <>
            {userTokens.length > 0 ? (
              <>
                {userTokens.map((token, idx) => (
                  <div className="token__icon" key={idx}>
                    <img className="token__logo" src={faraLogo} alt="" />
                    <p>{formatNumber(token.amount, 4)}</p>
                  </div>
                ))}
              </>
            ) : (
              <div className="mt-2 text-gray">
                {tokenLoading ? "Loading..." : address ? "You have no Tokens." : "Please connect your wallet first."}
              </div>
            )}
          </>
        );
    }
  };

  function openInventoryTransferModal(type: number): void {
    switch (tabValue) {
      case 3:
        modalService.show(TokenTransferModal, {
          userTokens,
          type,
        });
        return;
      default:
        modalService.show(BatchTransferModal, {
          userItems: availableUserItems,
          userMaterials: userMaterials,
          isMaterialTransfer: tabValue === 2,
          inventoryType: type,
        });
        break;
    }
  }

  function openWithdrawHistoryModal(): void {
    modalService.show(WithdrawHistoryModal, {
      className: "large",
    });
  }

  return (
    <div className="wallet__inventory container mb-10">
      <div className="flex-center-between">
        <div className="flex-center-start">
          <div className="knight__title mr-2">{type === INVENTORY_TYPE.TRADITIONAL ? "Your" : "Non-fee"} Wallet</div>
          <Tooltip
            title={`${
              type === INVENTORY_TYPE.TRADITIONAL ? "Deposit to Non-fee wallet" : "Withdraw to traditional wallet"
            }`}
            placement="top"
            arrow
          >
            <div className="btn wallet__btn wallet__deposit" onClick={() => openInventoryTransferModal(type)}>
              <img src={type === INVENTORY_TYPE.TRADITIONAL ? depositIcon : withdrawIcon} alt="Deposit/Withdraw" />
            </div>
          </Tooltip>
          {type === INVENTORY_TYPE.NON_FEE && (
            <Tooltip title="Withdraw History" placement="top" arrow>
              <div className="btn wallet__btn ml-2" onClick={openWithdrawHistoryModal}>
                <HistoryIcon />
              </div>
            </Tooltip>
          )}
          {type === INVENTORY_TYPE.TRADITIONAL && (
            <>
              <Tooltip title="Transfer" placement="top" arrow>
                <div className="btn wallet__btn ml-2" onClick={openBatchTransferModal}>
                  <SyncAltIcon />
                </div>
              </Tooltip>

              <Tooltip title="Dismantle" placement="top" arrow>
                <div className="btn wallet__btn btn--red ml-2" onClick={openBatchDismantleModal}>
                  <GavelIcon />
                </div>
              </Tooltip>
            </>
          )}
        </div>
        <Tabs value={tabValue} onChange={handleTabChange} className="wallet__tab">
          <Tooltip title="Equipment" placement="top" arrow>
            <Tab icon={<img src={swordIcon} alt="Equipment" />} />
          </Tooltip>
          <Tooltip title="Skills" placement="top" arrow>
            <Tab icon={<BookIcon />} />
          </Tooltip>

          <Tooltip title="Materials" placement="top" arrow>
            <Tab icon={<ShoppingBasketIcon />} />
          </Tooltip>
          <Tooltip title="Tokens" placement="top" arrow>
            <Tab icon={<MonetizationOnIcon />} />
          </Tooltip>
        </Tabs>
      </div>
      <div className="inventory nice-scroll nice-scroll--small">{renderTabContent(tabValue)}</div>
    </div>
  );
}
