import { RARITY_LABEL } from "src/app/configs/constants";
import { Land, LandAttributes, LandOffer, LandTransaction } from "src/app/types/land";

export function createLand(data: any, price?: string, landAttributes?: any): Land {
  return {
    id: data.id,
    name: data.name,
    owner: price ? data.owner.id : data.owner,
    attributes: _createLandAttributes(data.attributes),
    landType: landAttributes ? landAttributes.type.split("_")[0] : data.type.split("_")[0],
    price: price ? price : data.listedPrice,
    tier: landAttributes ? landAttributes.tier : data.tier,
  };
}

function _createLandAttributes(attributes: any): LandAttributes[] {
  if (!attributes) return [];

  return attributes
    .map((attribute: any) => {
      return {
        id: `${attribute.value.display_name}-${attribute.trait_type}`,
        traitType: attribute.trait_type,
        value: attribute.value.display_name,
        rarity: attribute.value.tier,
        displayRarity: RARITY_LABEL[attribute.value.tier],
      };
    })
    .filter((attribute: any) => {
      return attribute.value !== undefined;
    });
}

export function createLandOffer(data: any, domainNames: any): LandOffer {
  return {
    id: data.id,
    landId: data.land.id,
    isActive: data.isActive,
    buyer: data.buyer.id,
    price: data.price,
    timestamp: data.timestamp,
    buyerDomain: domainNames ? domainNames[data.buyer.id] : "",
  };
}

export function createLandTransaction(data: any, domainNames: any): LandTransaction {
  return {
    type: data.type,
    price: data.price,
    landId: data.land.id,
    from: data.from?.id,
    to: data.to?.id,
    timestamp: data.timestamp,
    fromAddressDomain: domainNames ? domainNames[data.from?.id] : "",
    toAddressDomain: domainNames ? domainNames[data.to?.id] : "",
  };
}
