import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalModal } from "src/app/actions/globalAction";
import InputGroup from "src/app/components/Commons/InputGroup";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import Modal from "src/app/components/Commons/Modals/Modal";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import ResultContent from "src/app/components/Commons/ResultContent";
import { INTERVAL, MATERIAL_IDS, SKILL_BOOK_TRANSACTION } from "src/app/configs/constants";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { fetchCosts, getTokenBalance, transferBook, upgradeBook } from "src/app/services/api/faralandService";
import { Item } from "src/app/types/equipment";
import { SkillBookCosts } from "src/app/types/skills";
import { compareNumbers } from "src/app/utils/calculators";
import { signAndAuthenticateWallet } from "src/app/utils/helpers";

export default function SkillbookActionModal() {
  const dispatch = useDispatch();
  const { wallet, address } = useSelector((state: any) => state.account);
  const { modal } = useSelector((state: any) => state.global);
  const skillbookActionModal = modal.skillbookActionModal;
  const userItem: Item = skillbookActionModal.data.item ? EQUIPMENT[skillbookActionModal.data.item.id] : undefined;
  const transactionType = skillbookActionModal.data.transactionType;
  const heroId = skillbookActionModal.data.heroId;
  const [selectedToken, setSelectedToken] = useState<number>(0);
  const [costs, setCosts] = useState<SkillBookCosts>();
  const [error, setError] = useState("");
  const [targetCharacterId, setTargetCharacterId] = useState("");
  const [knightToken, setKnightToken] = useState("0");
  const [royalToken, setRoyalToken] = useState("0");
  const balanceInterval = useRef<any>();
  const isMaxTier = userItem && (userItem.tier === 3 || (userItem.id === 586 && userItem.tier === 1));

  useEffect(() => {
    if (!address) return;
    async function fetchTokenBalance(address: string) {
      const tokenBalance = await getTokenBalance(address);
;      if (tokenBalance && tokenBalance.success) {
        const knightTokenBalance = tokenBalance.result.find(
          (token) => token.materialId === MATERIAL_IDS.KNIGHT_TOKEN
        )?.quantity;
        const royalTokenBalance = tokenBalance.result.find(
          (token) => token.materialId === MATERIAL_IDS.ROYAL_TOKEN
        )?.quantity;
        setKnightToken(knightTokenBalance ?? 0);
        setRoyalToken(royalTokenBalance ?? 0);
      }
    }
    fetchTokenBalance(address);
    balanceInterval.current = setInterval(() => {
      fetchTokenBalance(address);
    }, INTERVAL.BALANCE);

    return () => {
      clearInterval(balanceInterval.current);
    };
  }, [address]);

  useEffect(() => {
    if (!userItem) return;

    async function getCosts(itemId: number) {
      const res = await fetchCosts(itemId);
      if (res) setCosts(res);
    }

    getCosts(userItem.id);
  }, [userItem]);

  function handleClose() {
    setSelectedToken(0);
    setError("");
    dispatch(setGlobalModal("skillbookActionModal"));
  }

  function renderSkillbookUpgradeContent() {
    if (!isMaxTier) {
      return (
        <div className="mt-4 fw-3">
          Upgrade <span className="success-text fs-3">{userItem.displayName}</span> to{" "}
          <span className="success-text fs-3">Tier {userItem.tier + 1}</span> using this ticket?
        </div>
      );
    }
    return <div className="mt-4 fw-3">This item is already at max tier and cannot be upgraded any further</div>;
  }

  function handleTargetCharacterIdChange(e: any) {
    e.preventDefault();
    setTargetCharacterId(e.target.value);
  }

  function renderSkillbookTransferContent() {
    return (
      <div>
        <InputGroup
          value={targetCharacterId}
          handleChange={handleTargetCharacterIdChange}
          error={""}
          label="Receiver Hero"
          hideBalance
        />
        <div className="mt-2 fw-3">
          Transfer <span className="success-text fs-3">{userItem.displayName}</span> with this ticket?
        </div>
      </div>
    );
  }

  function _compareTokenBalance(selectedToken: number) {
    const knightCost =
      transactionType === SKILL_BOOK_TRANSACTION.UPGRADE ? costs?.knight.upCost : costs?.knight.moveCost;
    const royalCost = transactionType === SKILL_BOOK_TRANSACTION.UPGRADE ? costs?.royal.upCost : costs?.royal.moveCost;
    if (selectedToken === MATERIAL_IDS.KNIGHT_TOKEN) {
      return compareNumbers(knightToken, knightCost ?? 0);
    }
    if (selectedToken === MATERIAL_IDS.ROYAL_TOKEN) {
      return compareNumbers(royalToken, royalCost ?? 0);
    }
  }

  async function handleUpgradeSkillBook(heroId: string, tokenId: number, skillId: number) {
    setError("");
    if (userItem.tier === 3) {
      setError("This skill is already maxed");
      return;
    }
    if (![MATERIAL_IDS.KNIGHT_TOKEN, MATERIAL_IDS.ROYAL_TOKEN].includes(selectedToken)) {
      setError("Please select the token you would like to use");
      return;
    }
    if (_compareTokenBalance(selectedToken) === -1) {
      setError("Insufficient token balance");
      return;
    }
    const res = await upgradeBook(heroId, tokenId, skillId);
    if (res === 401) {
      const response = await signAndAuthenticateWallet(wallet, address);
      if (response.success) {
        handleUpgradeSkillBook(heroId, tokenId, skillId);
      } else {
        dispatch(
          setGlobalModal("error", {
            active: true,
            data: <div>Unable to authenticate your wallet. Please try again</div>,
          })
        );
      }
      return;
    }
    if (res && !res.success && res.message) {
      setError(res.message);
      return;
    }

    if (res && res.success) {
      modalService.show(BasicModalContent, {
        content: (
          <ResultContent
            isSuccess={true}
            content={
              <div>
                Congratulations! You have successfully upgraded{" "}
                <span className="success-text fs-3">{userItem.displayName}</span> to{" "}
                <span className="success-text fs-3">Tier {userItem.tier + 1}</span>
              </div>
            }
          />
        ),
        layer: 3,
      });
      handleClose();
    }
  }

  async function handleTransferSkillBook(heroId: string, tokenId: number, skillId: number, targetCharacterId: string) {
    setError("");
    if (targetCharacterId.length === 0) {
      setError("Target hero ID is required");
      return;
    }
    if (+targetCharacterId < 0) {
      setError("Invalid hero ID");
      return;
    }
    if (![MATERIAL_IDS.KNIGHT_TOKEN, MATERIAL_IDS.ROYAL_TOKEN].includes(selectedToken)) {
      setError("Please select the token you would like to use");
      return;
    }
    if (_compareTokenBalance(selectedToken) === -1) {
      setError("Insufficient token balance");
      return;
    }
    const res = await transferBook(heroId, tokenId, skillId, +targetCharacterId);
    if (res === 401) {
      const response = await signAndAuthenticateWallet(wallet, address);
      if (response.success) {
        handleTransferSkillBook(heroId, tokenId, skillId, targetCharacterId);
      } else {
        dispatch(
          setGlobalModal("error", {
            active: true,
            data: <div>Unable to authenticate your wallet. Please try again</div>,
          })
        );
      }
      return;
    }
    if (res && !res.success && res.message) {
      setError(res.message);
      return;
    }
    if (res && res.success) {
      modalService.show(BasicModalContent, {
        content: (
          <ResultContent
            isSuccess={true}
            content={
              <div>
                Congratulations! You have successfully transferred{" "}
                <span className="success-text fs-3">{userItem.displayName}</span> to hero #{targetCharacterId}
              </div>
            }
          />
        ),
        layer: 3,
      });
      handleClose();
    }
  }
  
  function renderPopupContent() {
    let content, onSubmit, submitText, title;
    if (transactionType === SKILL_BOOK_TRANSACTION.UPGRADE) {
      content = renderSkillbookUpgradeContent();
      onSubmit = !isMaxTier ? () => handleUpgradeSkillBook(heroId, selectedToken, userItem.id) : undefined;
      submitText = "Upgrade";
      title = "SKILLBOOK UPGRADE";
    } else if (transactionType === SKILL_BOOK_TRANSACTION.TRANSFER) {
      content = renderSkillbookTransferContent();
      onSubmit = () => handleTransferSkillBook(heroId, selectedToken, userItem.id, targetCharacterId);
      submitText = "Transfer";
      title = "SKILLBOOK TRANSFER";
    }

    return { content, onSubmit, submitText, title };
  }

  function renderCosts() {
    const knightCost =
      transactionType === SKILL_BOOK_TRANSACTION.UPGRADE ? costs?.knight.upCost : costs?.knight.moveCost;
    const royalCost = transactionType === SKILL_BOOK_TRANSACTION.UPGRADE ? costs?.royal.upCost : costs?.royal.moveCost;

    return (
      <div className="skill-book__costs">
        <div
          className={`btn ${knightCost === 0 ? "disabled" : ""} ${
            selectedToken === MATERIAL_IDS.KNIGHT_TOKEN ? "btn-active" : ""
          }`}
          onClick={() => setSelectedToken(MATERIAL_IDS.KNIGHT_TOKEN)}
        >
          <div className="skill-book__costs-price">{knightCost}</div>
          <div>x Knight Tokens</div>
        </div>
        <div
          className={`btn ${royalCost === 0 ? "disabled" : ""} ${
            selectedToken === MATERIAL_IDS.ROYAL_TOKEN ? "btn-active" : ""
          }`}
          onClick={() => setSelectedToken(MATERIAL_IDS.ROYAL_TOKEN)}
        >
          <div className="skill-book__costs-price">{royalCost}</div>
          <div>x Royal Tokens</div>
        </div>
      </div>
    );
  }

  const { content, onSubmit, submitText, title } = renderPopupContent();
  return (
    <Modal layer={2} isActive={skillbookActionModal.active} onClose={handleClose} hideCloseBtn={true}>
      <BasicModalContent
        customTitle={title}
        content={
          <>
            {content}
            {(!isMaxTier || transactionType === SKILL_BOOK_TRANSACTION.TRANSFER) && (
              <>
                {renderCosts()}
                {selectedToken > 0 && (
                  <div className="mt-2 fs-3 mb-2">
                    Token Balance:{" "}
                    <span className="warning-text">
                      {selectedToken === MATERIAL_IDS.KNIGHT_TOKEN
                        ? knightToken + " Knight Tokens"
                        : royalToken + " Royal Tokens"}
                    </span>{" "}
                  </div>
                )}
              </>
            )}
            {error && <div className="error-text">{error}</div>}
          </>
        }
        close={handleClose}
        onSubmit={onSubmit}
        submitText={submitText}
      />
    </Modal>
  );
}
