import { txActionTypes } from "src/app/actions/txAction";
import { TxReducer } from "src/app/types/reducer-state";

const initialState: TxReducer = {
  txError: '',
  txConfirming: false,
  trackingTxs: [],
  successTxCount: 0,
};

export default function txReducer(state = initialState, action: any): TxReducer {
  switch (action.type) {
    case txActionTypes.APPEND_TRACKING_TX: {
      return {
        ...state,
        trackingTxs: state.trackingTxs.concat(action.payload)
      };
    }
    case txActionTypes.SET_TRACKING_TXS: {
      return {
        ...state,
        trackingTxs: action.payload,
        successTxCount: state.successTxCount + 1
      };
    }
    case txActionTypes.SET_ERROR: {
      return {
        ...state,
        txError: action.payload
      }
    }
    case txActionTypes.SET_TX_CONFIRMING: {
      return {
        ...state,
        txConfirming: action.payload
      }
    }
    default:
      return state;
  }
}
