export const commonActionTypes = {
  APPROVE_FOR_ALL: "COMMON.APPROVE_FOR_ALL"
};

export function approveForAll(contract: string, operator: string, approved: boolean, onSuccess?: any) {
  return {
    type: commonActionTypes.APPROVE_FOR_ALL,
    payload: { contract, operator, approved },
    callbacks: { onSuccess },
  };
}