import React from "react";
import { ItemStatsLabel } from "src/app/types/equipment";

type ItemIconProps = {
  itemStats: ItemStatsLabel
  largeText?: boolean
}

export default function ItemEffect({ itemStats, largeText }: ItemIconProps) {
  return (
    <div>
      {itemStats.effects.map((effect: any, index: number) => {
        return (
          <div key={index} className={`${largeText && 'mb-1 normal-line-height'}`}>
            <span>- </span>
            <span className={`${!largeText && 'fs-2'} text-9`}>{effect.name}: {effect.description}</span>
          </div>
        )
      })}
    </div>
  )
}