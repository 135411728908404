import { stakingActionTypes } from "src/app/actions/stakingAction";

const initialState: any = {
  stakingData: {
    expEarned: "0",
    tokenEarned: "0",
    stakedAmount: "0",
    unlockedTime: 0,
    lockedMonths: 0,
    eligibleStakingTime: 0,
    expClaimed: 0,
  },
};

export default function stakingReducer(state = initialState, action: any) {
  switch (action.type) {
    case stakingActionTypes.SET_STAKING_DATA: {
      return {
        ...state,
        stakingData: action.payload,
      };
    }
    default:
      return state;
  }
}
