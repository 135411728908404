import { KnightFilterType } from "src/app/types/knight";
import { ItemFilterType } from "src/app/types/equipment";
import { MaterialFilterType } from "src/app/types/materials";
import { DemiFilterType } from "src/app/types/demiKnight";
import { GuildFilterType } from "src/app/types/guild";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { LandFilterType } from "src/app/types/land";
export const MAX_STAT_RANGE = 30;
export const MAX_LEVEL = 100;
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const BURN_ADDRESS = "0x000000000000000000000000000000000000dead";
export const MOONKNIGHT_SERVICE_FEE = 15;
export const ITEM_MINT_FEE = 10;
export const ITEM_UPGRADE_FEE = 10;
export const ITEM_EXCHANGE_FEE = 5;
export const MAX_ROLL_NUMBER = 10;
export const BASE_KNIGHT_EXP = 1000;
export const MOBILE_SCREEN_SIZE = 700;
export const MAX_TIER = 3;
export const SYNCED_MINUTE_THRESHOLD = 1;
export const DEFAULT_GAS_PRICE = 3;
export const EXP_PER_SS = 250;
export const EXP_PER_JAR = 20000;
export const SOUL_STONE_FOR_UPGRADE = 500;
export const AMPLIFIED_STAKING = 5;
export const MAX_BLACKSMITH_LEVEL = 10;
export const BASE_BLACKSMITH_EXP = 100;
export const BLACKSMITH_RATE = 2;
export const DISMANTLE_POINT_PER_EXP = 100;
export const FARA_ALLOWANCE = 0;
export const MAX_LAND_SUPPLY = 400;
export const GACHA_COST = {
  FARA: 20,
  SS: 20,
};

export const ROUTE = {
  HOME: "/",
  HEROES: "/heroes",
  HEROES_DETAILS: "/hero/:id",
  DEMI: "/demi-heroes",
  DEMI_DETAILS: "/demi-hero/:id",
  DEMI_SUMMON: "/summon-demi",
  ITEMS: "/equipment",
  EQUIPMENT_DETAILS: "/equipment/:id",
  EQUIPMENT_CRAFTING: "/crafting/recipe/:recipeId",
  MATERIALS: "/materials",
  MATERIAL_DETAILS: "/material/:id",
  GUILD: "/guilds",
  GUILD_DETAILS: "/guild/:id",
  STAKING: "/staking",
  GACHA: "/gacha",
  WALLET: "/wallet",
  EVENT: "/event",
  INSTRUCTIONS: "/instructions",
  PROJECT_OVERVIEW: "/project-overview",
  TOKEN_METRIC: "/token-metric",
  HELP_CENTER: "/help",
  HERO_BURN: "/hero-burn",
  EQUIPMENT_GACHA: "/equipment-gacha",
  NOT_FOUND: "/not-found",
  LANDS: "/lands",
  LAND_DETAILS: "/land/:id",
  LAND_MINT: "/mint-land",
  BLACK_MARKET: "/black-market",
};

export const WALLET_TYPE = {
  METAMASK: "metamask",
  COIN98: "coin98",
  WALLET_CONNECT: "wallet connect",
  COINBASE_WALLET: "Coinbase Wallet",
  DAPP: "dapp",
};

export const HERO_TYPE = {
  IMMORTAL_KNIGHT: 0,
  DEMI_KNIGHT: 1,
};

export const ACTIONS = {
  APPROVE: 1,
  STAKE: 2,
  UNSTAKE: 3,
  CLAIM: 4,
  CONVERT_EXP: 5,
  LIST: 6,
  DELIST: 7,
  OFFER: 8,
  TAKE_OFFER: 9,
  CANCEL_OFFER: 10,
  BUY: 11,
  TRANSFER: 12,
  SACRIFICE: 14,
  HERO_EQUIP_ITEMS: 15,
  HERO_UNEQUIP_ITEMS: 16,
  CHANGE_NAME: 17,
  ROLL_GACHA: 18,
  TRANSFER_EQUIPMENT: 19,
  UPGRADE_EQUIPMENT: 20,
  LIST_EQUIPMENT: 21,
  DELIST_EQUIPMENT: 22,
  BUY_EQUIPMENT: 23,
  OFFER_EQUIPMENT: 24,
  CANCEL_OFFER_EQUIPMENT: 25,
  TAKE_OFFER_EQUIPMENT: 26,
  LIST_EXCHANGE_EQUIPMENT: 27,
  CANCEL_EXCHANGE_EQUIPMENT: 28,
  EXCHANGE_EQUIPMENT: 29,
  TAKE_EXCHANGE_OFFER: 30,
  INCREASE_STATS: 31,
  SUMMON_DEMI: 32,
  DENY_DEMI: 33,
  LIST_MATERIAL: 34,
  DELIST_MATERIAL: 35,
  BUY_MATERIAL: 36,
  OFFER_MATERIAL: 37,
  CANCEL_OFFER_MATERIAL: 38,
  TAKE_OFFER_MATERIAL: 39,
  REGISTER: 40,
  DRINK_SOULSTONE: 41,
  DEMI_EQUIP_ITEMS: 42,
  DEMI_UNEQUIP_ITEMS: 43,
  DEMI_TRANSFER: 44,
  MATERIAL_TRANSFER: 45,
  CONVERT_KNIGHT_EXP: 46,
  CONSUME_SS: 47,
  LEARN_SKILL_BOOK: 48,
  RESET_STATS: 49,
  BATCH_TRANSFER: 50,
  CREATE_GUILD: 51,
  JOIN_GUILD: 52,
  APPROVE_MEMBERS: 53,
  REJECT_MEMBERS: 54,
  KICK_MEMBERS: 55,
  LEAVE_GUILD: 56,
  UPGRADE_BODY_PART: 58,
  DELETE_DEMI: 59,
  STAKING_CLAIM_ITEM: 60,
  EMERGENCY_WITHDRAW: 61,
  APPROVE_FOR_ALL: 62,
  BURN: 63,
  CHANGE_GUILD_NAME: 64,
  CRAFT_ITEM: 65,
  TRANSFER_GUILD_OWNERSHIP: 66,
  CANCEL_JOIN_REQUEST: 67,
  THROW_ITEM_GACHA: 68,
  MATERIAL_BATCH_TRANSFER: 69,
  LEVEL_UP_BLACKSMITH: 70,
  EQUIPMENT_DISMANTLE: 71,
  CONVERT_EXP_TO_BP: 72,
  CLAIM_EQUIPMENT: 73,
  MINT_LAND: 74,
  GET_LAND_REWARDS: 75,
  LIST_LAND: 76,
  DELIST_LAND: 77,
  BUY_LAND: 78,
  TRANSFER_LAND: 79,
  TAKE_LAND_OFFER: 80,
  CANCEL_LAND_OFFER: 81,
  OFFER_LAND: 82,
  CLAIM_SELECTED_LANDS: 83,
  WHITELIST_LAND_CLAIM: 84,
  PRIVATE_LAND_MINT: 85,
  PUBLIC_LAND_MINT: 86,
  CLAIM_EXP_JAR: 87,
  CONSUME_EXP_JAR: 88,
  DISTRIBUTE_EXP: 89,
  MATERIAL_DEPOSIT: 90,
  MATERIAL_WITHDRAW: 91,
  EQUIPMENT_DEPOSIT: 92,
  EQUIPMENT_WITHDRAW: 93,
  TOKEN_DEPOSIT: 94,
  TOKEN_WITHDRAW: 95,
};

export const TX_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
};

export const RARITY = {
  COMMON: 0,
  UNCOMMON: 1,
  RARE: 2,
  EPIC: 3,
  LEGENDARY: 4,
  MYTHICAL: 5,
};

export const RARITY_LABEL = ["Common", "Uncommon", "Rare", "Epic", "Legendary", "Mythical"];

export const MAX_PITY = {
  common: 50,
  uncommon: 60,
  rare: 55,
  epic: 30
}

export const EQUIPMENT_TYPE = {
  MAIN_WEAPON: 0,
  SUB_WEAPON: 1,
  HEADGEAR: 2,
  ARMOR: 3,
  FOOTWEAR: 4,
  PANTS: 5,
  GLOVES: 6,
  MOUNT: 7,
  MEDAL: 8,
  SKILL_BOOK: 9,
};

export const EQUIPMENT_LABEL = [
  "Main Weapon",
  "Sub Weapon",
  "Headgear",
  "Armor",
  "Footwear",
  "Pants",
  "Gloves",
  "Companion",
  "Medal",
  "Skill Book",
];

export const ELEMENT = {
  FIRE: "Fire",
  WATER: "Water",
  GRASS: "Grass",
  THUNDER: "Thunder",
  ROCK: "Rock",
  ICE: "Ice",
  NORMAL: "Normal",
  WINGS: "Wings",
};

export const RACE = {
  HUMAN: "Human",
  ORC: "Orc",
  ELF: "Elf",
  FAIRY: "Fairy",
  DRAGONBORN: "Dragonborn",
  DEMON: "Demon",
  ANGEL: "Angel",
};

export const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
};

export const KNIGHT_TYPE = {
  IMMORTAL: 0,
  DEMI: 1,
};

export const SALE = {
  FOR_SALE: "For Sale",
  NOT_FOR_SALE: "Not for Sale",
  HAS_OFFER: "Has Offer",
};

export const STATS_LABEL = {
  STR: "STR",
  AGI: "AGI",
  INT: "INT",
  LUK: "LUK",
};

export const SORT_BY: any = {
  LOWEST_PRICE: "Lowest Price",
  HIGHEST_PRICE: "Highest Price",
  LOWEST_ID: "Lowest ID",
  HIGHEST_ID: "Highest ID",
};

export const SORT_ITEM_BY: any = {
  ...SORT_BY,
  HIGHEST_LEVEL: "Highest Required Level",
  HIGHEST_STR: "Highest Strength",
  HIGHEST_AGI: "Highest Agility",
  HIGHEST_INT: "Highest Intelligence",
  HIGHEST_LUK: "Highest Luck",
};

export const SORT_DEMI_BY: any = {
  ...SORT_BY,
  HIGHEST_STR: "Highest Strength",
  HIGHEST_AGI: "Highest Agility",
  HIGHEST_INT: "Highest Intelligence",
  HIGHEST_LUK: "Highest Luck",
  HIGHEST_LEVEL: "Highest Level",
};

export const SORT_HERO_BY: any = {
  ...SORT_BY,
  RECENTLY_LISTED: "Recently Listed",
  RECENTLY_SOLD: "Recently Sold",
  RECENTLY_OFFERED: "Recently Offered",
  HIGHEST_LEVEL: "Highest Level",
  ...SORT_DEMI_BY,
};

export const SORT_GUILD_LIST_BY: any = {
  TOTAL_STRENGTH: "Total Strength",
  MEMBER_COUNT: "Member Count",
  LEVEL: "Level",
};

export const SORT_GUILD_MEMBER_BY: any = {
  STRENGTH: "Strength",
};

export const SORT_LAND_BY: any = {
  ...SORT_BY,
  RECENTLY_LISTED: "Recently Listed",
  RECENTLY_SOLD: "Recently Sold",
};

export const SORT_DATA: any = {
  [SORT_BY.LOWEST_PRICE]: { key: "listedPrice", direction: "asc" },
  [SORT_BY.HIGHEST_PRICE]: { key: "listedPrice", direction: "desc" },
  [SORT_BY.LOWEST_ID]: { key: "id", direction: "asc" },
  [SORT_BY.HIGHEST_ID]: { key: "id", direction: "desc" },
  [SORT_HERO_BY.HIGHEST_LEVEL]: { key: "level", direction: "desc" },
  [SORT_HERO_BY.RECENTLY_LISTED]: { key: "listedBlock", direction: "desc" },
  [SORT_HERO_BY.RECENTLY_SOLD]: { key: "soldBlock", direction: "desc" },
  [SORT_HERO_BY.RECENTLY_OFFERED]: { key: "offeredBlock", direction: "desc" },
  [SORT_ITEM_BY.HIGHEST_LEVEL]: { key: "level", direction: "desc" },
  [SORT_ITEM_BY.HIGHEST_STR]: { key: "str", direction: "desc" },
  [SORT_ITEM_BY.HIGHEST_AGI]: { key: "agi", direction: "desc" },
  [SORT_ITEM_BY.HIGHEST_INT]: { key: "intel", direction: "desc" },
  [SORT_ITEM_BY.HIGHEST_LUK]: { key: "luck", direction: "desc" },
  [SORT_DEMI_BY.HIGHEST_LEVEL]: { key: "level", direction: "desc" },
  [SORT_DEMI_BY.HIGHEST_STR]: { key: "str", direction: "desc" },
  [SORT_DEMI_BY.HIGHEST_AGI]: { key: "agi", direction: "desc" },
  [SORT_DEMI_BY.HIGHEST_INT]: { key: "intel", direction: "desc" },
  [SORT_DEMI_BY.HIGHEST_LUK]: { key: "luck", direction: "desc" },
  [SORT_GUILD_LIST_BY.TOTAL_STRENGTH]: { key: "totalStrength", direction: "desc" },
  [SORT_GUILD_LIST_BY.MEMBER_COUNT]: { key: "memberCount", direction: "desc" },
  [SORT_GUILD_LIST_BY.LEVEL]: { key: "level", direction: "desc" },
  [SORT_GUILD_MEMBER_BY.STRENGTH]: { key: "strength", direction: "desc" },
  [SORT_LAND_BY.RECENTLY_LISTED]: { key: "listedBlock", direction: "desc" },
  [SORT_LAND_BY.RECENTLY_SOLD]: { key: "soldBlock", direction: "desc" },
};

export const INTERVAL = {
  TX: 3000,
  BALANCE: 10000,
  STAKING_DATA: 10000,
  KNIGHT_DETAILS: 10000,
  DEMI_DETAILS: 10000,
  ITEM_DETAILS: 10000,
  USER_ITEMS: 10000,
  GACHA_ITEMS: 2000,
  INVENTORY_WALLET: 10000,
  SYNCED_BLOCK: 60000,
  MATERIAL_DETAILS: 10000,
  GUILD_DETAILS: 10000,
  REFETCH_UPGRADE: 15000,
  GACHA_EQUIPMENT: 15000,
  REFETCH_CRAFTING: 10000,
  REFETCH_CRAFT_HISTORY: 30000,
};

export const LIMIT = {
  HEROES: 15,
  ITEMS: 15,
  MATERIALS: 15,
  DEMI: 15,
  LAND: 15,
  GUILD: 4,
  MEMBERS: 50,
  CRAFT_HISTORY: 50,
  WITHDRAW_HISTORY: 15,
};

export const INIT_KNIGHT_FILTER: KnightFilterType = {
  id: false,
  races: {
    [RACE.HUMAN]: false,
    [RACE.ORC]: false,
    [RACE.ELF]: false,
    [RACE.FAIRY]: false,
    [RACE.DRAGONBORN]: false,
    [RACE.DEMON]: false,
    [RACE.ANGEL]: false,
  },
  elements: {
    [ELEMENT.FIRE]: false,
    [ELEMENT.WATER]: false,
    [ELEMENT.GRASS]: false,
    [ELEMENT.THUNDER]: false,
    [ELEMENT.ROCK]: false,
    [ELEMENT.ICE]: false,
    [ELEMENT.NORMAL]: false,
  },
  genders: {
    [GENDER.MALE]: false,
    [GENDER.FEMALE]: false,
  },
  sales: {
    [SALE.FOR_SALE]: false,
    [SALE.NOT_FOR_SALE]: false,
  },
  stats: {
    [STATS_LABEL.STR]: [0, MAX_STAT_RANGE],
    [STATS_LABEL.AGI]: [0, MAX_STAT_RANGE],
    [STATS_LABEL.INT]: [0, MAX_STAT_RANGE],
    [STATS_LABEL.LUK]: [0, MAX_STAT_RANGE],
  },
  traits: [],
  traitRarity: "",
  totalBaseStats: false,
  numberOfBodyParts: false,
  sortBy: SORT_BY.LOWEST_PRICE,
  offering: false,
  owned: false,
  staked: false,
  page: 1,
};

export const INIT_DEMI_FILTER: DemiFilterType = {
  id: false,
  races: {
    [RACE.HUMAN]: false,
    [RACE.ORC]: false,
    [RACE.ELF]: false,
    [RACE.FAIRY]: false,
    [RACE.DRAGONBORN]: false,
    [RACE.DEMON]: false,
    [RACE.ANGEL]: false,
  },
  elements: {
    [ELEMENT.FIRE]: false,
    [ELEMENT.WATER]: false,
    [ELEMENT.GRASS]: false,
    [ELEMENT.THUNDER]: false,
    [ELEMENT.ROCK]: false,
    [ELEMENT.ICE]: false,
    [ELEMENT.NORMAL]: false,
  },
  genders: {
    [GENDER.MALE]: false,
    [GENDER.FEMALE]: false,
  },
  sales: {
    [SALE.FOR_SALE]: false,
    [SALE.NOT_FOR_SALE]: false,
  },
  stats: {
    [STATS_LABEL.STR]: [0, MAX_STAT_RANGE],
    [STATS_LABEL.AGI]: [0, MAX_STAT_RANGE],
    [STATS_LABEL.INT]: [0, MAX_STAT_RANGE],
    [STATS_LABEL.LUK]: [0, MAX_STAT_RANGE],
  },
  traits: [],
  traitRarity: "",
  totalBaseStats: false,
  numberOfBodyParts: false,
  sortBy: SORT_BY.LOWEST_PRICE,
  offering: false,
  owned: false,
  staked: false,
  page: 1,
};

export const LAND_TYPE = {
  MOUNTAINS: "Mountains",
  VALLEYS: "Valleys",
  PLATEAUS: "Plateaus",
  DESERTS: "Deserts",
  PLAINS: "Plains",
  DELTAS: "Deltas",
  OCEANS: "Oceans",
  GLACIERS: "Glaciers",
  AQUATIC: "Aquatic",
  GLACIAL: "Glacial",
  GOLEM: "Golem",
  INFERNO: "Inferno",
};

export const INIT_LAND_FILTER: LandFilterType = {
  id: false,
  landType: {
    [LAND_TYPE.AQUATIC]: false,
    [LAND_TYPE.GLACIAL]: false,
    [LAND_TYPE.GOLEM]: false,
    [LAND_TYPE.INFERNO]: false,
  },
  traits: [],
  sortBy: SORT_BY.LOWEST_PRICE,
  traitRarity: "",
  offering: false,
  owned: false,
  tier: null,
  name: "",
  sales: {
    [SALE.FOR_SALE]: false,
    [SALE.NOT_FOR_SALE]: false,
  },
  page: 1,
};

export const INIT_MATERIAL_FILTER: MaterialFilterType = {
  id: false,
  rarity: "",
  sortBy: SORT_BY.LOWEST_PRICE,
  offering: false,
  owned: false,
  listings: false,
  page: 1,
  name: "",
};

export const MATERIAL_IDS = {
  SOUL_STONE: 0,
  EMPEROR_STONE: 1,
  EXP_JAR: 17,
  KNIGHT_TOKEN: 18,
  ROYAL_TOKEN: 19,
};

export const SUMMON_DEMI_COST = {
  FARA: 3,
  SOUL_STONE: 36,
  DENY: 18,
  LIFE_PER_DRINK: 7,
};

export const GUILD_COSTS = {
  CREATE_FARA: 5000,
  CREATE_EMPEROR_STONE: 1,
  CHANGE_NAME_FARA: 1000,
};

export const LAND_LIMITS = {
  MINT: 1,
  MINT_PRICE: 500,
};

export const INIT_ITEM_FILTER: ItemFilterType = {
  id: false,
  races: {
    [RACE.HUMAN]: false,
    [RACE.ORC]: false,
    [RACE.ELF]: false,
    [RACE.FAIRY]: false,
    [RACE.DRAGONBORN]: false,
    [RACE.DEMON]: false,
    [RACE.ANGEL]: false,
  },
  genders: {
    [GENDER.MALE]: false,
    [GENDER.FEMALE]: false,
  },
  stats: {
    [STATS_LABEL.STR]: [0, MAX_STAT_RANGE],
    [STATS_LABEL.AGI]: [0, MAX_STAT_RANGE],
    [STATS_LABEL.INT]: [0, MAX_STAT_RANGE],
    [STATS_LABEL.LUK]: [0, MAX_STAT_RANGE],
  },
  levels: {
    LVL: [1, MAX_LEVEL],
  },
  rarity: "",
  sortBy: SORT_BY.LOWEST_PRICE,
  offering: false,
  owned: false,
  listings: false,
  exchanges: false,
  craftable: false,
  effects: [],
  tier: null,
  page: 1,
  slotType: "All",
  set: "",
  name: "",
};

export const INIT_GUILD_FILTER: GuildFilterType = {
  id: false,
  sortBy: SORT_GUILD_LIST_BY.TOTAL_STRENGTH,
  joinable: false,
  pending: false,
  page: 1,
  name: "",
};

export const INIT_GUILD_MEMBER_FILTER: GuildFilterType = {
  id: false,
  sortBy: SORT_GUILD_MEMBER_BY.STRENGTH,
  joinable: false,
  pending: false,
  page: 1,
  name: "",
};

export const QUESTION_CATEGORY = {
  FARALAND: 0,
  HERO: 1,
  TOKEN: 2,
  STAKE: 3,
  GACHA: 4,
  EXPEDITION: 5,
  FULL_GAME: 6,
};

export const STAKING_POOL = {
  SINGLE_V3: 0,
  LIQUIDITY_V3: 1,
  SINGLE_V2: 2,
  LIQUIDITY_V2: 3,
  DEPRECATED_SINGLE_V1: 4,
  DEPRECATED_LIQUIDITY_V1: 5,
  DEPRECATED: 6,

};

export const STAKING_CONFIG = {
  SECONDS: 2592000,
  LP_PRICE_MULTIPLIER: 350,
  LP_REWARD_DIVIDER: 500,
  EXP_JAR_REQUIREMENT: 20000,
  SINGLE: {
    SYMBOL: "FARA",
    STAKED_AMOUNT: 5000,
    LOCKED_MONTHS: 3,
    ITEM_IDS: [431, 434, 437, 553],
    REWARD_RATE: 0.009,
  },
  LIQUIDITY: {
    SYMBOL: "FARA-BNB",
    STAKED_AMOUNT: 50,
    LOCKED_MONTHS: 2,
    ITEM_IDS: [440, 443, 446, 469],
    REWARD_RATE: 0.023,
  },
};

export const SKILL_BOOK_TRANSACTION = {
  TRANSFER: 0,
  UPGRADE: 1,
}

export const HERO_BURNING = {
  HEROES: 10,
  SOUL_STONE: 30000,
  ITEMS: [EQUIPMENT[560], EQUIPMENT[563]],
};

export const FLOOR_PRICE = {
  [RACE.HUMAN]: 0.01,
  [RACE.ORC]: 0.01,
  [RACE.ELF]: 0.01,
  [RACE.FAIRY]: 0.01,
  [RACE.DRAGONBORN]: 0.01,
  [RACE.DEMON]: 0.01,
  [RACE.ANGEL]: 0.01,
};

export const TRANSACTION_TYPE = {
  TYPE_ITEM_LIST: "item_listed",
  TYPE_ITEM_DELIST: "item_delist",
  TYPE_ITEM_BOUGHT: "item_bought",
  TYPE_ITEM_OFFER: "item_offer",
  TYPE_ITEM_OFFER_CANCELED: "item_offer_canceled",
  TYPE_ITEM_OFFER_TAKEN: "item_offer_taken",
  TYPE_ITEM_EXCHANGE_OFFERED: "item_exchange_offered",
  TYPE_ITEM_EXCHANGE_CANCELED: "item_exchange_canceled",
  TYPE_ITEM_EXCHANGED: "item_exchanged",
  OFFER: "offer",
  LIST: "list",
  DELIST: "delist",
  BOUGHT: "bought",
  MINT: "mint",
  TAKE_OFFER: "takeOffer",
};

export const GACHA_VENDORS = [
  {
    id: 2,
    active: false,
  },
  {
    id: 1,
    active: false,
  },
  {
    id: 0,
    active: false,
  },
];

export const RARITY_UPGRADE_RATE = {
  [RARITY.COMMON]: 80,
  [RARITY.UNCOMMON]: 40,
  [RARITY.RARE]: 5,
  [RARITY.EPIC]: 1,
  [RARITY.LEGENDARY]: 0.1,
};

export const TOPIC = {
  DEMI: "0x117bed42e180dbb2090cfa4a0435e06c244d42082ebd500640a04948eb264626",
  ITEM_CRAFTED: "0x61fa7200e6ba0540c0897205ca3bda59d08553fef73a865488818db7e0425314",
  GUILD: "0x9676efcabdc254306f37dc4cf127654733df9143d5002c88d797f98f33c2a68b",
  BURN: "0x6ea412b125ed242bc34c304877264761547306c2429c03d7fd1b477aa61a3f5d",
  GACHA_ITEM_RECEIVED: "0x94bc99690243890eefcbb347a9819cf0dc10febe37669c898f6d9fc4b7884595",
  JACKPOT_WON: "0xbf737c87eff4ef74213cb228c9e25ca0c4519947f5fcc4a41b221ba93d76a425",
};

export const LAND_CLAIM_PHASE = {
  AIRDROP: 1,
  PRIVATE_MINT: 2,
  PUBLIC_MINT: 3,
};

export const BLACK_MARKET_REWARD_AMOUNT = [3, 3, 1, 1, 3, 1, 2, 1, 2]
export const BLACK_MARKET_BOX_NAMES = ["Cyan Chest", "Cyan Chest", "Violet Chest", "Lavender Chest", "Indigo Chest", "Violet Chest", "Sunflower Chest", "Majestic Chest", "Goldenrod Chest"]

export const INVENTORY_TYPE = {
  TRADITIONAL: 1,
  NON_FEE: 2
}

export const NON_FEE_INVENTORY_TYPE = {
  ITEM: "Item",
  MATERIAL: "Material",
  HERO: "Hero",
  TOKEN: "Token",
}

export const RUNE_INDEX = 7;