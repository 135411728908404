import React, { useEffect, useRef, useState } from "react";
import EquipmentFilter from "src/app/components/Equipment/Marketplace/EquipmentFilter";
import EquipmentSwitcher from "src/app/components/Equipment/Marketplace/EquipmentSwitcher";
import { Lottie } from "@crello/react-lottie";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";
import { getItemFilterFromParams } from "src/app/utils/filterHelper";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import * as notFoundJson from "src/assets/lotties/404.json";
import { Item, ItemFilterType, ItemQuery } from "src/app/types/equipment";
import { useSelector } from "react-redux";
import { useQueryString } from "use-route-as-state";
import { Pagination } from "@material-ui/lab";
import EquipmentListContent from "src/app/components/Equipment/Marketplace/EquipmentListContent";
import { LIMIT, SORT_DATA } from "src/app/configs/constants";
import { fetchItemsFromApi } from "src/app/services/api/faralandService";
import {
  queryItemExchanges,
  queryItemListings,
  queryItemOffers,
  queryUserEquipment
} from "src/app/services/api/subgraphService";
import useUpdateEffect from "src/app/hooks/useUpdateEffect";
import { createFilteredItemData } from "src/app/factories/equipmentFactory";
import { CRAFTABLE_EQUIPMENT } from "src/app/configs/equipment/crafting";

export default function EquipmentMarketplace() {
  const { address } = useSelector((state: any) => state.account);
  const itemListRef = useRef(null);
  const [queryParams, setQueryParams] = useQueryString();
  const [total, setTotal] = useState(0);
  const [itemFilters, setItemFilters] = useState<ItemFilterType>(getItemFilterFromParams(queryParams));
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const disablePagination = itemFilters.owned || itemFilters.offering || itemFilters.listings || itemFilters.exchanges || itemFilters.craftable;
  const [showConnectWalletError, setShowConnectWalletError] = useState(disablePagination && !address);

  useUpdateEffect(() => {
    setItemFilters(getItemFilterFromParams(queryParams));
  }, [queryParams]);

  useEffect(() => {
    setShowConnectWalletError(disablePagination && !address);
  }, [disablePagination, address]);

  useEffect(() => {
    setPage(itemFilters.page);
    fetchItems(itemFilters);
  }, [address, itemFilters]); // eslint-disable-line

  async function fetchItems(filter: ItemFilterType) {
    let filteredItems: Item[] = [];
    const sortData = SORT_DATA[filter.sortBy];
    const variables: ItemQuery = {
      limit: LIMIT.ITEMS,
      orderBy: sortData.key,
      orderDirection: sortData.direction,
      itemPerPage: LIMIT.ITEMS,
      page: filter.page,
    };

    setLoading(true);

    try {
      let result;
      if (filter.offering) {
        result = await queryItemOffers(address);
      } else if (filter.listings) {
        result = await queryItemListings(address);
      } else if (filter.exchanges) {
        result = await queryItemExchanges(address);
      } else if (filter.owned) {
        result = await queryUserEquipment(address);
      } else if (filter.craftable) {
        result = getCraftableEquipment();
      } else {
        result = await fetchItemsFromApi(filter, variables);
      }

      filteredItems = result?.list ?? [];
      setTotal(result?.total ?? 0);
    } catch (e) {
      console.log(e);
    }

    setItems(filteredItems);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  useEffect(() => {
    if (total && total < LIMIT.ITEMS) {
      handleChangePage(1);
    }

    const newTotalPage = Math.ceil(total / LIMIT.ITEMS);
    setTotalPage(newTotalPage);
    if (newTotalPage && page > newTotalPage) {
      handleChangePage(1);
    }
  }, [total]); // eslint-disable-line

  function getCraftableEquipment() {
    const list = Object.keys(CRAFTABLE_EQUIPMENT).map((itemId) => {
      return createFilteredItemData({ id: itemId });
    });
    return { list, total: list.length };
  }

  function onClickPaging(page: number) {
    if (itemListRef && itemListRef.current) {
      // @ts-ignore
      itemListRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    handleChangePage(page);
  }

  function handleChangePage(page: number) {
    setQueryParams({
      ...queryParams,
      page: page.toString(),
    });
  }

  return (
    <div className="equipment-marketplace slide-up" ref={itemListRef}>
      <div className="market">
        <EquipmentFilter />

        <div className="market__content">
          <EquipmentSwitcher isLoading={isLoading} />

          {isLoading && (
            <Lottie
              className="market__loading market__loading--marketplace slide-up"
              config={getAnimatedJsonOptions(loadingJson)}
            />
          )}

          {!isLoading && items.length === 0 && !showConnectWalletError && (
            <div className="align-center slide-up">
              <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
              <div className="market__404-title">No Item found with your searching queries.</div>
            </div>
          )}

          {!isLoading && showConnectWalletError && (
            <div className="align-center slide-up">
              <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
              <div className="market__404-title">Please connect your wallet first</div>
            </div>
          )}

          {!isLoading && items.length !== 0 && !showConnectWalletError && (
            <div className="market__items">
              {items.map((item: Item, index: number) => {
                return (
                  <EquipmentListContent
                    key={index}
                    item={item}
                    showOwned={itemFilters.owned}
                    showOffers={itemFilters.offering}
                    showListings={itemFilters.listings}
                    showExchanges={itemFilters.exchanges}
                    showCraftable={itemFilters.craftable}
                  />
                );
              })}
            </div>
          )}

          {totalPage > 1 && !disablePagination && (
            <div className="market__pagination">
              <Pagination
                count={totalPage}
                page={page}
                onChange={(_, val) => onClickPaging(val)}
                variant="outlined"
                color="primary"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
