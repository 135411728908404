import React, { useEffect, useRef, useState } from "react";

import { Lottie } from "@crello/react-lottie";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import * as notFoundJson from "src/assets/lotties/404.json";
import { useDispatch, useSelector } from "react-redux";
import { useQueryString } from "use-route-as-state";
import { Pagination } from "@material-ui/lab";
import { LIMIT, SORT_DATA } from "src/app/configs/constants";
import useUpdateEffect from "src/app/hooks/useUpdateEffect";
import GuildSummary from "src/app/components/Guild/GuildSummary";
import GuildListFilter from "src/app/components/Guild/GuildFilter";
import GuildSwitcher from "src/app/components/Guild/GuildSwitcher";
import { Guild, GuildFilterType, GuildQuery } from "src/app/types/guild";
import GuildListContent from "./GuildListContent";
import { getGuildFilterFromParams } from "src/app/utils/filterHelper";
import { fetchGuildsFromApi } from "src/app/services/api/faralandService";
import { fetchGuildOfMember, queryPendingRequests } from "src/app/services/api/subgraphService";
import { setSubgraphClient } from "src/app/actions/globalAction";
import { guildClient } from "src/app/services/subgraph/client";

export default function GuildList() {
  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.account);
  const { web3Service, modal } = useSelector((state: any) => state.global);
  const guildListRef = useRef(null);
  const [queryParams, setQueryParams] = useQueryString();
  const [total, setTotal] = useState(0);
  const [guildFilters, setGuildFilters] = useState<GuildFilterType>(getGuildFilterFromParams(queryParams, false));
  const [guilds, setGuilds] = useState<Guild[]>([]);
  const [guildIds, setGuildIds] = useState<string[]>([]);
  const [guildOfAddress, setGuildOfAddress] = useState<Guild>();
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [alreadyGuildMember, setAlreadyGuildMember] = useState(false);
  const disablePagination = guildFilters.pending || guildFilters.joinable;
  const [showConnectWalletError, setShowConnectWalletError] = useState(disablePagination && !address);
  const guildRefresh = modal.guildCreationModal.guildRefresh;

  useEffect(() => {
    dispatch(setSubgraphClient(guildClient));
    return () => {
      dispatch(setSubgraphClient(null));
    };
  }, []); // eslint-disable-line

  useUpdateEffect(() => {
    setGuildFilters(getGuildFilterFromParams(queryParams, false));
  }, [queryParams]);

  useEffect(() => {
    if (!web3Service) return;
    checkIfGuildMember(address);
  }, [web3Service, address]); // eslint-disable-line

  useEffect(() => {
    setShowConnectWalletError(disablePagination && !address);
  }, [disablePagination, address]);

  useEffect(() => {
    setPage(guildFilters.page);
    fetchGuilds(guildFilters);
  }, [address, guildFilters]); // eslint-disable-line

  useEffect(() => {
    if (!guildRefresh) return;
    setPage(guildFilters.page);
    fetchGuilds(guildFilters);
  }, [guildRefresh]); // eslint-disable-line

  async function checkIfGuildMember(address: string) {
    const result = await web3Service.getIsGuildMember(address);
    if (+result !== -1) {
      setAlreadyGuildMember(true);
      const guild = await fetchGuildOfMember(address);
      setGuildOfAddress(guild);
    } else {
      setAlreadyGuildMember(false);
      setGuildOfAddress(undefined);
    }
  }

  async function fetchGuilds(filter: GuildFilterType) {
    let filteredGuilds: Guild[] = [];
    const sortData = SORT_DATA[filter.sortBy];
    const variables: GuildQuery = {
      limit: LIMIT.GUILD,
      orderBy: sortData.key,
      owner: address,
      orderDirection: sortData.direction,
      itemPerPage: LIMIT.GUILD,
      page: filter.page,
    };

    setLoading(true);

    try {
      let result;
      const pendingRequests = await queryPendingRequests(variables);
      if (filter.pending) {
        result = await queryPendingRequests(variables);
      } else {
        result = await fetchGuildsFromApi(filter, variables);
      }
      // console.log(result?.ids);
      filteredGuilds = result?.list ?? [];
      setTotal(result?.total ?? 0);
      setGuildIds(pendingRequests?.ids ?? []);
    } catch (e) {
      console.log(e);
    }
    setGuilds(filteredGuilds);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  useEffect(() => {
    if (total && total < LIMIT.GUILD) {
      handleChangePage(1);
    }

    const newTotalPage = Math.ceil(total / LIMIT.GUILD);
    setTotalPage(newTotalPage);
    if (newTotalPage && page > newTotalPage) {
      handleChangePage(1);
    }
  }, [total]); // eslint-disable-line

  function onClickPaging(page: number) {
    if (guildListRef && guildListRef.current) {
      // @ts-ignore
      guildListRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    handleChangePage(page);
  }

  function handleChangePage(page: number) {
    setQueryParams({
      ...queryParams,
      page: page.toString(),
    });
  }

  return (
    <div className="guild-list">
      <div className="container slide-up" ref={guildListRef}>
        <GuildSummary />
        <div className="market">
          <GuildListFilter guildOfAddress={guildOfAddress} />

          <div className="market__content">
            <GuildSwitcher isLoading={isLoading} />

            {isLoading && (
              <Lottie
                className="market__loading market__loading--marketplace slide-up"
                config={getAnimatedJsonOptions(loadingJson)}
              />
            )}

            {!isLoading && guilds.length === 0 && !showConnectWalletError && (
              <div className="align-center slide-up">
                <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
                <div className="market__404-title">No Guild found with your searching queries.</div>
              </div>
            )}

            {!isLoading && showConnectWalletError && (
              <div className="align-center slide-up">
                <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
                <div className="market__404-title">Please connect your wallet first</div>
              </div>
            )}

            {!isLoading && guilds.length !== 0 && !showConnectWalletError && (
              <div className="flex-column">
                {guilds?.map((guild: Guild, index: number) => {
                  return <GuildListContent key={index} guild={guild} guildIds={guildIds} alreadyGuildMember={alreadyGuildMember} />;
                })}
              </div>
            )}

            {totalPage > 1 && !disablePagination && (
              <div className="market__pagination">
                <Pagination
                  count={totalPage}
                  page={page}
                  onChange={(_, val) => onClickPaging(val)}
                  variant="outlined"
                  color="primary"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
