import React, { ReactElement } from "react";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";

type ModalProps = {
  content: any;
  submitText?: string;
  isSubmitDisabled?: boolean;
  noPanel?: boolean;
  customTitle?: string | ReactElement;
  contentClassName?: string;
  className?: string;
  close?: any;
  onSubmit?: any;
  closeOnSubmit?: any;
  extraPanels?: () => ReactElement;
};

export default function BasicModalContent(props: ModalProps) {
  function closeModal() {
    if (props.close) {
      props.close();
      return;
    }

    modalService.close();
  }

  function onClick() {
    if (props.closeOnSubmit) {
      modalService.close();
    }
    props.onSubmit();
  }

  return (
    <div className={`basic-modal ${props.className ? props.className : ""}`}>
      {props.customTitle && <div className="basic-modal__title">{props.customTitle}</div>}
      <div className={`basic-modal__content ${props.contentClassName ? props.contentClassName : ""}`}>
        {props.content}
      </div>
      {!props.noPanel && (
        <>
          {props.extraPanels ? (
            <div className={`basic-modal__panel--extra`}>
              <div className="basic-modal__panel-container">
                {props.extraPanels}
                {!!props.onSubmit && (
                  <div className={`btn ml-4 ${props.isSubmitDisabled ? "disabled" : ""}`} onClick={onClick}>
                    {props.submitText ?? "Confirm"}
                  </div>
                )}
              </div>

              <div className={`btn ${!!props.onSubmit && "btn--gray ml-0"}`} onClick={closeModal}>
                Close
              </div>
            </div>
          ) : (
            <div className={`basic-modal__panel ${props.extraPanels && `basic-modal__panel--extra`}`}>
              <div className={`btn ${!!props.onSubmit && "btn--gray"}`} onClick={closeModal}>
                Close
              </div>

              {!!props.onSubmit && (
                <div className={`btn ml-4 ${props.isSubmitDisabled ? "disabled" : ""}`} onClick={onClick}>
                  {props.submitText ?? "Confirm"}
                </div>
              )}
              {props.extraPanels}
            </div>
          )}
        </>
      )}
    </div>
  );
}
