import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { INTERVAL, MATERIAL_IDS } from "src/app/configs/constants";
import { getTokenBalance } from "src/app/services/api/faralandService";
import knightTokenImg from "src/assets/images/icons/black-market/knight-token.png";
import royalTokenImg from "src/assets/images/icons/black-market/royal-token.png";

const BlackMarketSummary = () => {
  const [knightToken, setKnightToken] = useState("0");
  const [royalToken, setRoyalToken] = useState("0");
  const balanceInterval = useRef<any>();

  const { address } = useSelector((state: any) => state.account);
  
  useEffect(() => {
    if (!address) return;
    async function fetchBlackMarketTokenBalance(address: string) {
      const blackMarketBalance = await getTokenBalance(address);
      if (blackMarketBalance && blackMarketBalance.success) {
        const knightTokenBalance = blackMarketBalance.result.find(
          (token) => token.materialId === MATERIAL_IDS.KNIGHT_TOKEN
        )?.quantity;
        const royalTokenBalance = blackMarketBalance.result.find(
          (token) => token.materialId === MATERIAL_IDS.ROYAL_TOKEN
        )?.quantity;
        setKnightToken(knightTokenBalance ?? 0);
        setRoyalToken(royalTokenBalance ?? 0);
      }
    }
    fetchBlackMarketTokenBalance(address);
    balanceInterval.current = setInterval(() => {
      fetchBlackMarketTokenBalance(address);
    }, INTERVAL.BALANCE);

    return () => {
      clearInterval(balanceInterval.current);
    };
  }, [address]);

  return (
    <div className="data-summary">
      <div className="data-summary__container">
        <div>
          <div className="data-summary__title">Your Knight Tickets</div>
          <div className="data-summary__value--center">
            {knightToken} <img src={knightTokenImg} alt="Knight Token" />
          </div>
        </div>
        <div>
          <div color="primary" className="data-summary__title">
            Your Royal Tickets
          </div>
          <div className="data-summary__value--center">
            {royalToken} <img src={royalTokenImg} alt="Royal Token" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlackMarketSummary;
