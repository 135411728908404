import React, { Fragment, useEffect, useState } from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import Modal from "src/app/components/Commons/Modals/Modal";
import { setGlobalModal } from "src/app/actions/globalAction";
import { useDispatch, useSelector } from "react-redux";
import { upgradeEquipment } from "src/app/actions/equipmentAction";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import ENV from "src/app/configs/env";
import { Item } from "src/app/types/equipment";
import { ITEM_UPGRADE_FEE } from "src/app/configs/constants";
import faraLogo from "src/assets/images/tokens/fara.png";
import ArrowIcon from '@material-ui/icons/Forward';
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import ItemInfo from "src/app/components/Equipment/Commons/ItemInfo";
import { compareNumbers } from "src/app/utils/calculators";

export default function EquipmentUpgradeModal() {
  const dispatch = useDispatch();
  const { balance } = useSelector((state: any) => state.account);
  const { modal } = useSelector((state: any) => state.global);
  const [needApprove, sendApproveTx] = useFetchingAllowance(ENV.CONTRACT.EQUIPMENT);
  const [error, setError] = useState('');

  const currentModal = modal.upgradeEquipment;
  const item: Item = currentModal.data.item;
  const itemIndex = item?.tierItemIds?.indexOf(item.id);
  const itemBalance = currentModal.data.balance;
  const viewOnly = itemBalance === undefined;
  const isDisabled = item?.upgradeAmount !== undefined ? itemBalance < item.upgradeAmount : true;

  useEffect(() => {
    setError("");
  }, [currentModal.active]);

  function handleClose() {
    dispatch(setGlobalModal("upgradeEquipment"));
  }

  function upgrade() {
    if (compareNumbers(balance.FARA, ITEM_UPGRADE_FEE) === -1) {
      setError('Insufficient FARA balance.');
      return;
    }

    if (needApprove) {
      sendApproveTx();
      return;
    }

    dispatch(upgradeEquipment(item.id, handleClose));
  }

  function showEquipmentInfoModal(itemId: number) {
    modalService.show(BasicModalContent, {
      layer: 3,
      content: (<ItemInfo itemId={itemId} noUpgradeInfo={true} />)
    })
  }

  function getBlurClassName(currentIndex: number, number = 1) {
    const isActive = itemIndex !== undefined && (currentIndex >= itemIndex && currentIndex <= itemIndex + number);
    return isActive ? '' : 'low-opacity';
  }

  return (
    <Modal className="medium" isActive={currentModal.active} onClose={handleClose} layer={2}>
      {(!!item && !!item.tierItemIds && item.upgradeAmount !== undefined) && (
        <BasicModalContent
          customTitle={`Enhancement - ${item.displayName}`}
          onSubmit={!viewOnly ? upgrade : undefined}
          submitText={needApprove ? 'Approve FARA' : 'Upgrade'}
          isSubmitDisabled={isDisabled}
          close={handleClose}
          content={(
            <div className="item-upgrade">
              <div className="item-upgrade__container">
                {item.tierItemIds.map((id: number, currentIndex: number) => {
                  const currentItem: Item = EQUIPMENT[id];
                  const sameItem = currentIndex === itemIndex;
                  const nextTierItem = item.nextTierId === currentItem.id;

                  return (
                    <Fragment key={currentIndex}>
                      <div className={`item-upgrade__content ${getBlurClassName(currentIndex)}`}>
                        <img
                          className={`item-upgrade__icon item-icon__image ${sameItem || nextTierItem ? 'active' : ''}`}
                          src={`${ENV.URL.BASE_IMAGE_URL}/images/icon/178x178/${id}.png`}
                          onClick={() => showEquipmentInfoModal(id)}
                          alt=''
                        />
                        <div className="item-upgrade__tier">Tier {currentItem.tier}</div>
                        {!viewOnly && (
                          <div className="item-upgrade__balance">
                            {sameItem && itemBalance > 0 ? `Inventory x${itemBalance}` : ''}
                          </div>
                        )}
                      </div>
                      {!!currentItem.upgradeAmount && currentItem.upgradeAmount > 0 && (
                        <div className={`item-upgrade__arrow ${getBlurClassName(currentIndex, 0)}`}>
                          <ArrowIcon />
                          <div>x{currentItem.upgradeAmount}</div>
                        </div>
                      )}
                    </Fragment>
                  )
                })}
              </div>
              <div>
                <span>Upgrade fee: </span>
                <span className="text-9">{ITEM_UPGRADE_FEE} FARA</span>
                <img className="token top-5 ml-1" src={faraLogo} alt="" />
              </div>
              {(isDisabled && !viewOnly) && (
                <div className='small-text small-text--warning mt-4'>You need {item.upgradeAmount - itemBalance} more {item.displayName} to upgrade.</div>
              )}
              {error !== '' && (
                <div className='small-text small-text--error slide-up mt-4'>{error}</div>
              )}
            </div>
          )}
        />
      )}
    </Modal>
  );
}
