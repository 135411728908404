import React, { useCallback, useEffect, useRef, useState } from "react";
import { StylesProvider, TextField, debounce } from "@material-ui/core";
import { timeAgo } from "src/app/utils/helpers";
import { JoinRequest } from "src/app/types/guild";
import { useDispatch } from "react-redux";
import { approveMembers, rejectMembers } from "src/app/actions/guildAction";
import { queryJoinRequests } from "src/app/services/api/subgraphService";
import { INTERVAL } from "src/app/configs/constants";
import strengthIcon from "src/assets/images/guild/strength-icon.png";
import GuildTables from "../Common/GuildTables";
import { renderWalletAddress } from "src/app/utils/renderHelpers";
import useFetchSpaceDomain from "src/app/hooks/useFetchSpaceDomain";

type JoinRequestTableProps = {
  guildId: string;
};

export default function JoinRequestTable(props: JoinRequestTableProps) {
  const dispatch = useDispatch();
  const { guildId } = props;
  const fetchingInterval = useRef<any>();
  const [joinRequests, setJoinRequests] = useState<JoinRequest[]>([]);
  const isEmpty = joinRequests.length === 0;
  const [selectedMembers, setSelectedMembers] = useState<JoinRequest[]>([]);
  const [walletInput, setWalletInput] = useState("");
  const [debouncedWalletInput, setDebouncedWalletInput] = useState("");
  const { domains, getAddresses } = useFetchSpaceDomain();

  const [columns] = useState([
    {
      title: "Wallet",
      field: "member.id",
      render: (row) => renderWalletAddress(row.member.id, row.member.domainAddress, 4),
    },
    {
      title: "Username",
      field: "member.username",
    },
    {
      title: "Strength",
      field: "member.strength",
      render: (row) => (
        <div>
          <img src={strengthIcon} alt="Strength Icon" style={{ width: 15, position: "relative", top: 3 }} />{" "}
          {row.member.strength}
        </div>
      ),
    },
    {
      title: "Time Elapsed",
      field: "timestamp",
      render: (row) => <div>Requested {row.timestamp && timeAgo(+row.timestamp * 1000)}</div>,
    },
  ]);

  const debouncePushToInput = useCallback(debounce(setDebouncedWalletInput, 500), []);

  useEffect(() => {
    if (guildId) {
      fetchJoinRequests(guildId, debouncedWalletInput);

      fetchingInterval.current = setInterval(() => {
        fetchJoinRequests(guildId, debouncedWalletInput);
      }, INTERVAL.KNIGHT_DETAILS);
    }

    return () => {
      clearInterval(fetchingInterval.current);
    };
  }, [guildId, debouncedWalletInput, domains]); // eslint-disable-line

  async function fetchJoinRequests(id: string, name?: string) {
    try {
      const res = await queryJoinRequests(id, name);
      if (!res) return;
      getAddresses(
        res.joinRequests.map((request) => {
          return request.member;
        }),
        []
      );
      setJoinRequests(
        res.joinRequests.map((request) => ({
          ...request,
          member: { ...request.member, domainAddress: domains ? domains[request.member.id] : "" },
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }

  const handleInputChange = (e: any) => {
    setWalletInput(e.target.value);
    debouncePushToInput(e.target.value);
  };

  function renderNameField(label: string) {
    return (
      <StylesProvider injectFirst>
        <TextField
          value={walletInput}
          className="input guild-details__input"
          label={label}
          type={"text"}
          placeholder="0x..."
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          onChange={handleInputChange}
        />
      </StylesProvider>
    );
  }

  const handleApprove = (guildId: number, address: JoinRequest[]) => {
    dispatch(
      approveMembers(
        guildId,
        address.map((value) => value.member.id),
        () => setSelectedMembers([])
      )
    );
  };

  const handleReject = (guildId: number, address: JoinRequest[]) => {
    dispatch(
      rejectMembers(
        guildId,
        address.map((value) => value.member.id),
        () => setSelectedMembers([])
      )
    );
  };

  function handleSelectionChange(rows) {
    const displayedIds = joinRequests.map((result) => result.member.id);
    const selectedMembersNotDisplayed = selectedMembers.filter((selectedRow) => {
      return !displayedIds.includes(selectedRow.member.id);
    });
    setSelectedMembers([...selectedMembersNotDisplayed, ...rows]);
  }

  return (
    <div className={`guild-details__table cm-table cm-table--guild ${isEmpty ? "cm-table--guild-empty" : ""} mt-3 `}>
      <div className="flex-center-between">
        {renderNameField("Search Address")}
        <div className={`request-btn ${selectedMembers.length === 0 ? "hidden" : ""}`}>
          <div className="btn btn-small" onClick={() => handleApprove(+guildId, selectedMembers)}>
            Approve
          </div>
          <div className="btn btn--red btn-small" onClick={() => handleReject(+guildId, selectedMembers)}>
            Reject
          </div>
        </div>
      </div>
      <GuildTables
        isOwner={true}
        columns={columns}
        data={joinRequests.map((row) =>
          selectedMembers.find((selected) => selected.member.id === row.member.id)
            ? { ...row, tableData: { checked: true } }
            : row
        )}
        emptyMessage={"No Join Requests Yet"}
        handleSelectionChange={handleSelectionChange}
        isEmpty={isEmpty}
      />
    </div>
  );
}
