import React from "react";
import { Container } from "@material-ui/core";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
import yellowCrystal from "src/assets/images/decors/gems/gem-yellow.svg";
import RoadmapTimeline from "src/app/components/Home/Roadmap/RoadmapTimeline";
import orcImage from "src/assets/images/decors/homepage/orc.png";
import humanImage from "src/assets/images/decors/homepage/human.png";

const roadmap = [
  {
    year: 2021,
    height: 700,
    timeline: [
      {
        time: "MAY 2021 - PHASE 1",
        subtasks: ["NFT Heroes Sale (BEP-721)", "Hero Marketplace"],
        reached: true,
      },
      {
        time: "JUN 2021 - PHASE 2",
        subtasks: ["Introduction of $FARA Token & IDO", "Staking $FARA to Increase Heroes' level"],
        reached: true,
      },
      {
        time: "JULY 2021 - PHASE 3",
        subtasks: ["Hero Equipment (BEP-1155) Gacha", "Equipment Vendor"],
        reached: true,
      },
      {
        time: "AUG - DEC 2021 - PHASE 4",
        subtasks: [
          "Equipment Enhancement",
          "Equipment Marketplace",
          "DAO Governance",
          "Resource earning by deploying Heroes to Expedition",
          "Release of PvE Mode",
          "PvP Mode (Beta)",
          "Android & iOS Version",
        ],
        reached: true,
      },
    ],
  },
  {
    year: 2022,
    height: 700,
    timeline: [
      {
        time: "Q1 - 2022",
        subtasks: [
          "PvP Mode (Official)",
          "In-game Shop",
          "Body Part Upgrade",
          "Adventure Mission",
          "Guild Recruitment",
          "Skill System Release",
          "New Staking System",
        ],
        subtasksDone: [0, 1, 2, 3, 4, 5, 6],
        reached: true,
      },
      {
        time: "Q2 - 2022",
        subtasks: ["Equipment Crafting", "Skill System Upgrade", "Guild Activities"],
        subtasksDone: [0, 1, 2],
        reached: true,
      },
      {
        time: "Q3 - 2022",
        subtasks: ["Skill System Upgrade", "Partner Portal Quest"],
        subtasksDone: [0, 1],
        reached: true,
      },
      {
        time: "Q4 - 2022",
        subtasks: [
          "World Boss",
          "Lady of The Lake",
          "Accuracy & Extra Turn Mechanism",
          "In-game Gacha (Wheel of Fortune)",
        ],
        reached: true,
      },
    ],
  },
  {
    year: 2023,
    height: 600,
    timeline: [
      {
        time: "Q1 - 2023",
        subtasks: ["Release Skill Trees", "Add New Sets In Crafting", "Add Requirements for Items"],
        subtasksDone: [0, 1, 2],
        reached: true,
      },
      {
        time: "Q2 - 2023",
        subtasks: ["Land System - 1st Batch Minting", "Re-balance All Existing Crafting, Gacha Sets, and Items"],
        subtasksDone: [0, 1],
        reached: true,
      },
      {
        time: "Q3 - 2023",
        subtasks: [
          "Refactor Code",
          "Introduce a New Staking Mechanism",
          "Black Market",
          "Non-fee Wallet for Equipment, Skills, Materials, and Tokens",
          "Equipment Dismantlement",
          "Relaunch Lady of The Lake",
        ],
        subtasksDone: [0, 1, 2, 3, 4, 5],
        reached: true,
      },
      {
        time: "Q4 - 2023",
        subtasks: [
          "Release SKill Trees",
          "Artisan Blacksmith",
          "SkillShift",
          "Body Parts Effectiveness Update for Skills",
        ],
        subtasksDone: [0, 1, 2, 3],
        reached: true,
      },
    ],
  },
  {
    year: 2024,
    height: 700,
    timeline: [
      {
        time: "Q1 - 2024",
        subtasks: ["Chakra Rune", "Allow Off-chain Equipment/Unequipment of Clothing ", "Continue Implementing Race Skills"],
        reached: false,
      },
      {
        time: "Q2 - 2024",
        subtasks: ["Complete All Race Skills", "Land War", "War Guild", "Basic Guild Quests"],
        reached: false,
      },
      {
        time: "Q3 - 2024",
        subtasks: ["Throne War"],
        reached: false,
      },
      {
        time: "Q4 - 2024",
        subtasks: ["Kingdom War", "Advanced Guild Quests"],
        reached: false,
      },
    ],
  },
];

const Roadmap = () => {
  return (
    <div className="roadmap">
      <img className="roadmap__background-orc" src={orcImage} alt="" />
      <Container maxWidth="xs">
        <div className="roadmap__header">
          <div className="roadmap__header-title">Roadmap</div>
        </div>
      </Container>
      {roadmap.map((r) => (
        <div key={r.year}>
          <RoadmapTimeline {...r} />
        </div>
      ))}
      <img className="roadmap__background-human" src={humanImage} alt="" />
      <div className="roadmap__divider" />
      <FloatingCrystal speed={3} percentage={1} src={yellowCrystal} style={{ right: 10, top: 0, zIndex: -1 }} />
    </div>
  );
};

export default Roadmap;
