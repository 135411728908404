import { UserMaterial } from "src/app/types/materials";

export const runeActionTypes = {
  SET_REQUIRED_ITEM: "RUNE.SET_REQUIRED_ITEM",
  SELECT_RUNE: "RUNE.SELECT_RUNE",
  REFRESH: "RUNE.REFRESH",
};

export function setRequiredItem(itemId: number, itemAmount: number, index: number) {
  return {
    type: runeActionTypes.SET_REQUIRED_ITEM,
    payload: { itemId, itemAmount, index },
  };
}

export function selectRune(rune: UserMaterial | null) {
  return {
    type: runeActionTypes.SELECT_RUNE,
    payload: { rune },
  };
}

export function refreshRune(runeRefresh: boolean) {
  return {
    type: runeActionTypes.REFRESH,
    payload: { runeRefresh },
  };
}
