import React, { useEffect } from "react";
import Title from "../Commons/Title";
import rockImg from "src/assets/images/instructions/soulstone-rock.png";
import ENV from "src/app/configs/env";
import tryBtn from "src/assets/images/instructions/try-btn.png";
import { ROUTE } from "src/app/configs/constants";
import { SpinePlayer } from "@esotericsoftware/spine-player";

const Soulstone = () => {
  useEffect(() => {
    new SpinePlayer("gate-instructions-player", {
      jsonUrl: `${ENV.URL.FARALAND_GITHUB_ASSET}/SoulStoneGate.json`,
      atlasUrl: `${ENV.URL.FARALAND_GITHUB_ASSET}/SoulStoneGate.atlas`,
      animations: ["Gate_All"],
      showControls: false,
      showLoading: false,
      alpha: true,
      preserveDrawingBuffer: false,
      viewport: {
        x: -600,
        y: 0,
        width: 1200,
        height: 1200,
        padLeft: 0,
        padRight: 0,
        padTop: 0,
        padBottom: 0,
      },
    });
  }, []);

  return (
    <div className="instructions-soulstone">
      <img className="instructions-soulstone__rock" src={rockImg} alt="" />
      <div className="container">
        <Title value="Soulstone" style={{ zIndex: 2, position: "relative", padding: "20px 0" }} />
        <div id="gate-instructions-player" />
        <div className="instructions-soulstone__text">
          Legend has it that Demi's lifespan is limited due to their distinct biological mechanisms. They need
          unfiltered primeval energy to prolong their lifespan. That energy is called the "soul stone".
        </div>
        <div className="instructions-soulstone__text mt-10">
          More than an in-game reward, Soul stone can be used to summon demi heroes, which plays an important role in
          Faraland’s system
        </div>
        <div className="instructions-lineup__btn">
          <a href={ROUTE.DEMI_SUMMON} target="_blank" rel="noreferrer noopener">
            <img src={tryBtn} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Soulstone;
