import React, { useState, useRef, MouseEvent } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Paper, MenuList, MenuItem, Grow, ClickAwayListener, Popper } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ACTIONS } from "src/app/configs/constants";
import { DemiKnight } from 'src/app/types/demiKnight';
import { isKnightDead, isDemiInactive } from "src/app/utils/helpers";
import { renderBackBtn } from "src/app/utils/renderHelpers";

type KnightNavigateProps = {
  demi: DemiKnight
  onOpenPopup: any
  isOwner: boolean
}

export default function DemiHeaderButtons(props: KnightNavigateProps) {
  const { demi, onOpenPopup, isOwner } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const isDemiNotActive = demi.registered && isDemiInactive(demi.lifespan).isExpired;

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(event: MouseEvent<EventTarget>) {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }

  function onSelectDropdown(event: MouseEvent<EventTarget>, type?: number) {
    handleClose(event)
    if (type) onOpenPopup(type)
  }

  function renderButton() {
    if (isOwner) {
      return (
        <>
          {!demi.registered &&
            <div className="btn" onClick={() => onOpenPopup(ACTIONS.LIST)}>Sell</div>
          }
          <Button
            ref={anchorRef}
            variant="outlined"
            className="knight__btn-more bot-1"
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <MoreHorizIcon />
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={onSelectDropdown}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      {demi.price !== 0 && (
                        <MenuItem onClick={(e: any) => onSelectDropdown(e, ACTIONS.DELIST)}>Delist</MenuItem>
                      )}
                      {!demi.registered && <MenuItem onClick={(e: any) => onSelectDropdown(e, ACTIONS.TRANSFER)}>Transfer</MenuItem>}
                      {isDemiNotActive && <MenuItem onClick={(e: any) => onSelectDropdown(e, ACTIONS.DELETE_DEMI)}>Delete</MenuItem>}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )
    }

    if (isKnightDead(demi.owner)) {
      return null;
    }

    if (demi.price) {
      return <div className="btn" onClick={() => onOpenPopup(ACTIONS.BUY)}>Buy now</div>;
    }
  }

  return (
    <div className="knight__btn">
      <div>{renderBackBtn(history)}</div>
      <div>
        {(!demi.registered || isDemiNotActive) && renderButton()}
      </div>
    </div>
  )
}