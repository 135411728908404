import React from "react";
import ENV from "src/app/configs/env";
import { Knight } from "src/app/types/knight";

type HeroIconProps = {
  onClick?: (heroId: number) => void;
  hero: Knight;
  selected: boolean;
  onRightClick?: (heroId: number) => void;
};
export default function HeroIcon(props: HeroIconProps) {
  const { onClick, hero, onRightClick, selected } = props;
  return (
    <div className="relative hero-burn__icon">
      <div
        className="slide-up pointer"
        onClick={(e) => {
          e.preventDefault();
          onClick?.call(onClick, hero.id);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          onRightClick?.call(onRightClick, hero.id);
        }}
      >
        <div
          className={`hero-burn__selection hero-burn__selection--hero ${
            selected ? "hero-burn__selection--selected" : ""
          }`}
        >
          <img className="knights__item-character" src={`${ENV.URL.FARALAND}/images/heroes/${hero.id}.png`} alt="" />
        </div>
        <div className={`hero-burn__selection-id ${hero.element.toLowerCase()}`}>#{hero.id}</div>
      </div>
    </div>
  );
}
