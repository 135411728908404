import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import ExpProgressBar from "src/app/components/Commons/ExpProgressBar";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import { MAX_PITY, RARITY_LABEL } from "src/app/configs/constants";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import useBlacksmithData from "src/app/hooks/useBlacksmithData";
import { BlacksmithData } from "src/app/types/common";
import { CraftingSetRecipe, ItemRecipe } from "src/app/types/materials";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";

type RecipeItemListProps = {
  selectedItemRecipe: ItemRecipe;
  itemSetRecipe: CraftingSetRecipe;
  setSelectedItemRecipe: (item: ItemRecipe) => void;
};

export default function RecipeItemList(props: RecipeItemListProps) {
  const { selectedItemRecipe, itemSetRecipe, setSelectedItemRecipe } = props;
  const { address } = useSelector((state: any) => state.account);
  const { blacksmithData } = useBlacksmithData(address);

  function getSelectedItemRecipePity(blacksmithData: BlacksmithData, itemId: number, isMaxPity = false) {
    return isMaxPity
      ? MAX_PITY[RARITY_LABEL[EQUIPMENT[itemId]?.rarity]?.toLowerCase()]
      : blacksmithData.pity[RARITY_LABEL[EQUIPMENT[itemId]?.rarity]?.toLowerCase()];
  }
  function showLearnMoreModal() {
    modalService.show(BasicModalContent, {
      content: (
        <div>
          <h3 className="mt-1 mb-3">What is an Artisan Blacksmith?</h3>
          <div>
            <div>
              It's a feature designed to help crafters create items of the highest rarity after a certain number of
              crafting attempts. Specifically, when a crafting attempt reaches a certain threshold, it activates the{" "}
              <b>Artisan Blacksmith</b> state, ensuring the creation of highly rare items.
            </div>
            <h3 className="mt-3 mb-3">Artisan Blacksmith Info</h3>
            <div>
              There are four separate bars corresponding to the four lowest rarities:{" "}
              <b className="rarity-epic">Epic</b>, <b className="rarity-rare">Rare</b>,{" "}
              <b className="rarity-uncommon">Uncommon</b>, and <b>Common</b>. Each bar fills up with each crafting
              attempt, and the number of times each bar is filled is independent of the others.
              <div>
                - <b>Common</b> bar fills up after <b>50</b> attempts
              </div>
              <div>
                - <b className="rarity-uncommon">Uncommon</b> bar fills up after <b>60</b> attempts
              </div>
              <div>
                - <b className="rarity-rare">Rare</b> bar fills up after <b>55</b> attempts
              </div>
              <div>
                - <b className="rarity-epic">Epic</b> bar fills up after <b>30</b> attempts
              </div>
            </div>
            <div className="mt-1">
              This means that the <b className="rarity-epic">Epic</b> Bar will fill up after <b>30</b> crafting
              attempts, and on the 31st attempt, it will result in the highest rarity -{" "}
              <b className="rarity-mythical">Mythical</b>. When a bar is full, a glowing orb appears, and clicking on it
              reveals the highest rarity that will be obtained on the next craft.{" "}
              <b>
                <u>
                  Afterwards, the progress bar will be reset to zero, and the ball will also be dimmed, and the artisan
                  count will start from the beginning.
                </u>
              </b>
            </div>
            <div className="mt-1">
              When a bar is full, the next craft will yield the highest rarity of that bar type:
              <div>
                - <b>Common</b> Bar yields <b className="rarity-rare">Rare</b>
                <div>
                  - <b className="rarity-uncommon">Uncommon</b> Bar yields <b className="rarity-epic">Epic</b>
                </div>
                <div>
                  - <b className="rarity-rare">Rare</b> Bar yields <b className="rarity-legendary">Legendary</b>
                </div>
                <div>
                  - <b className="rarity-epic">Epic</b> Bar yields <b className="rarity-mythical">Mythical</b>
                </div>
              </div>
            </div>
            <div className="mt-1">
              There are two exceptions:
              <div>
                1. For sets such as Enigcrypt, Astrorius, Moon's Elegance, all of which have four rarity tiers, they
                will not reach the highest tier but will only result in <b className="rarity-epic">Epic</b>
              </div>
              <div>2. The Masked Maniac set has its own unique rules (Check the recipe for more details)</div>
            </div>
          </div>
        </div>
      ),
    });
  }
  return (
    <div className="item-crafting__container">
      <Box display={"flex"} alignItems={"flex-end"} gridColumnGap={10}>
        <div style={{ flex: 2 }}>
          <ExpProgressBar
            expText="ARTISAN"
            hasArtisanOrb
            artisanRarity={
              RARITY_LABEL[EQUIPMENT[selectedItemRecipe.itemIds[2] ?? selectedItemRecipe.itemIds[1]]?.rarity]
            }
            currentExp={getSelectedItemRecipePity(blacksmithData, selectedItemRecipe.itemIds[0])}
            requiredExp={getSelectedItemRecipePity(blacksmithData, selectedItemRecipe.itemIds[0], true)}
          />
        </div>
        <div onClick={showLearnMoreModal}>
          <HelpOutlineIcon fontSize="small" />
        </div>
      </Box>
      <Grid container justifyContent="center" className="mb-5 item-crafting__items">
        {itemSetRecipe.items.map((itemRecipe: ItemRecipe, index: number) => {
          const isSelected = selectedItemRecipe.id === itemRecipe.id;
          return (
            <Grid
              className={`item-crafting__icon ${isSelected ? "item-crafting__icon--selected" : ""}`}
              key={index}
              item
              lg={4}
              md={4}
              onClick={() => setSelectedItemRecipe(itemRecipe)}
            >
              <ItemIconPreview className="item-crafting__items-item" item={EQUIPMENT[itemRecipe.itemIds[0]]} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
