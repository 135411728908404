import * as React from "react";
import { useState } from "react";
import crystal from "src/assets/images/decors/gems/gem-purple.svg";
import blueCrystal from "src/assets/images/decors/gems/gem-blue.svg";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
import YoutubeEmbed from "src/app/components/Home/Intro/YoutubeEmbed";
import downloadAndroid from "src/assets/images/home/intro/download-android.png";
import downloadIOS from "src/assets/images/home/intro/download-ios.png";
import downloadPC from "src/assets/images/home/intro/download-pc.png";
import activePageImage from "src/assets/images/home/intro/active-page.svg";
import inactivePageImage from "src/assets/images/home/intro/inactive-page.svg";

const descriptions = [
  "Explore the Faraland world and the races living in the continent to find a way to seal the Demon Lord Beelzebul. In their journey, players must collect heroes from different races to overcome tough challenges. Each choice made during a quest can alter the storyline and expose your heroes to mysteries and hidden treasures.",
  "Where the best heroes of Faraland compete against one another. A turn-based gameplay with unique and deep RPG (Role-playing game) elements, and a rich skill system that will never let you down. Showcase your tactical skills and game insight in this Arena!",
]

const Intro = () => {
  const [isPveActive, setIsPveActive] = useState(true);

  return (
    <div className="intro">
      <FloatingCrystal speed={5} src={crystal} style={{ left: -100, top: 0, zIndex: -1 }} />

      <h2 className="intro__title">THE BIGGEST WAR GAME ON BLOCKCHAIN</h2>
      <div className="intro__download">
        <a href="https://blog.faraland.io/2021/11/30/faraland-expedition-game-register-instruction-android-version/" target="_blank" rel="noopener noreferrer">
          <img src={downloadAndroid} className="home__button" alt="" />
        </a>

        <a href=" https://blog.faraland.io/2021/11/30/faraland-expedition-game-register-instruction-ios-version/" target="_blank" rel="noopener noreferrer">
          <img src={downloadIOS} className="home__button" alt="" />
        </a>
        <img src={downloadPC} className="home__button" alt="" />
      </div>
      <div className="intro__content">
        <FloatingCrystal speed={5} src={blueCrystal} style={{ left: -200, top: 500, zIndex: -1 }} />
        <div className="intro__content-description">
          <div className="intro__content-mode">
            <div className="intro__content-switch">
              <div className={isPveActive ? "intro__content-mode--active" : ""} onClick={() => setIsPveActive(true)}>PVE</div>
              <img src={isPveActive ? activePageImage : inactivePageImage} className={`intro__content-option${isPveActive ? '--active' : ''}`} alt="" />
            </div>

            <div className="intro__content-connector" />

            <div className="intro__content-switch">
              <div className={!isPveActive ? "intro__content-mode--active" : ""} onClick={() => setIsPveActive(false)}>PVP</div>
              <img src={!isPveActive ? activePageImage : inactivePageImage} className={`intro__content-option${!isPveActive ? '--active' : ''}`} alt="" />
            </div>
          </div>
          {isPveActive && <div className="slide-up">{descriptions[0]}</div>}
          {!isPveActive && <div className="slide-up">{descriptions[1]}</div>}
          <div className="intro__content-divider" />
        </div>
        <YoutubeEmbed isPveActive={isPveActive} />
      </div>
    </div >
  );
};

export default Intro;
