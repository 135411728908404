import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSubgraphClient } from "src/app/actions/globalAction";
import KnightList from "src/app/components/Knight/KnightList";
import StakingSummary from "src/app/components/Staking/StakingSummary";
import { stakingSubgraph } from "src/app/services/subgraph/client";

export default function Staking() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSubgraphClient(stakingSubgraph));
    return () => {
      dispatch(setSubgraphClient(null));
    };
  }, []); // eslint-disable-line

  return (
    <div className="staking container slide-up">
      <StakingSummary />
      <KnightList
        showOwned={true}
        showStaked={true}
        showOffering={false}
        redirectToDetails={false}
        isStakingPage={true}
      />
    </div>
  );
}
