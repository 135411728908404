import React, { useState } from "react";
import clsx from "clsx";
import iceLand from "src/assets/images/lands/land-ice.png";
import waterLand from "src/assets/images/lands/land-water.png";
import fireLand from "src/assets/images/lands/land-fire.png";
import earthLand from "src/assets/images/lands/land-earth.png";
import { Avatar, Box, Drawer, useMediaQuery } from "@material-ui/core";
import iceDrawerImg from "src/assets/images/lands/land-drawer-img--ice.png";
import waterDrawerImg from "src/assets/images/lands/land-drawer-img--water.png";
import fireDrawerImg from "src/assets/images/lands/land-drawer-img--fire.png";
import earthDrawerImg from "src/assets/images/lands/land-drawer-img--earth.png";
import { heroes } from "src/app/components/Home/DemoCharacters/Hero/Heroes";

export default function LandMap() {
  const [lands, setLands] = useState([
    {
      type: "ice",
      image: iceLand,
      name: "Glacial Realm",
      fullName: "Serene Icebound Domain",
      open: false,
      description:
        "Nestled in the northwest corner of Elemental Realm lies the Glacial, a majestic and icy wonderland. Vast expanses of shimmering ice and snow stretch out as far as the eye can see, glistening under the sun's rays. Towering glaciers dominate the landscape, their icy formations creating a mesmerizing and ethereal ambiance. The air is crisp and invigorating, and the stillness is broken only by the occasional crackling of ice or the howling wind. The Glacial Realm is a land of solitude and serenity, where explorers can witness the raw power of nature and immerse themselves in the enchanting beauty of this frozen kingdom.",
    },
    {
      type: "earth",
      image: earthLand,
      name: "Golem Realm",
      fullName: "Primal Wilderness of Stone",
      open: false,
      description:
        "In the northeast corner of Elemental Realm, the Golem Realm unveils its rugged and arid landscapes. Endless stretches of desert sands ripple under the scorching sun, creating an illusionary dance of mirages. Towering rock formations, weathered by time, rise proudly from the desert floor, each bearing witness to the passage of centuries. Dust devils whirl in the distance, while ancient ruins whisper tales of forgotten civilizations. It is a realm where strength and resilience are celebrated, and where explorers can embark on an adventure of discovery and unravel the mysteries concealed within the shifting sands.",
    },
    {
      type: "water",
      image: waterLand,
      name: "Aquatic Ream",
      fullName: "The Ancient Sanctuary",
      open: false,
      description:
        "The Aquatic in Elemental Realm is a mesmerizing land of natural beauty, teeming with life and brimming with enchantment. Lush forests stretch as far as the eye can see, where ancient trees provide shade to a rich and diverse ecosystem. Crystal-clear lakes reflect the vibrant colors of exotic flora, while majestic waterfalls cascade down rocky cliffs, creating a symphony of soothing sounds. It is a realm where mythical creatures frolic in the shimmering waters and where the secrets of the deep are whispered among the swaying reeds. Explorers who venture into the Aquatic Realm will be captivated by its tranquility and the sense of harmony that emanates from this ethereal land.",
    },
    {
      type: "fire",
      image: fireLand,
      name: "Inferno Realm",
      fullName: "Fiery Land of Volcanoes",
      open: false,
      description:
        "The Inferno Realm, located in the southeastern reaches of Elemental Realm, is a realm of intense heat and untamed fire. Fiery volcanoes dominate the horizon, spewing molten lava that cascades down their slopes, painting the land in shades of red and orange. The air is thick with the scent of sulfur, and the heat is palpable as flames dance and flicker in the vast expanse. Jagged cliffs and rocky outcrops bear witness to the volcanic eruptions that have shaped the landscape over time. It is a realm of both danger and power, where the primal forces of nature are on full display. Adventurers who dare to explore the Inferno Realm will be immersed in a world of elemental energy, where the heat of the earth's core meets the boundless spirit of exploration.",
    },
  ]);
  const [activeLandIndex, setActiveLandIndex] = useState(0);
  const [selectedLand, setSelectedLand] = useState("");

  const onSelectLand = (idx: number) => {
    if (idx < 0 || idx > heroes.length - 1) {
      return;
    }
    setActiveLandIndex(idx);
  };
  const isMobile = useMediaQuery("(max-width: 900px)");

  const handleDrawerOpen = (type: string, open: boolean) => {
    const newLands = [...lands].map((land) => {
      if (land.type === type) {
        return {
          ...land,
          open,
        };
      } else {
        return land;
      }
    });
    setLands(newLands);
    setSelectedLand(open ? type : "");
  };

  const getDrawerImage = (type: string) => {
    switch (type) {
      case "ice":
        return iceDrawerImg;
      case "water":
        return waterDrawerImg;
      case "fire":
        return fireDrawerImg;
      case "earth":
        return earthDrawerImg;
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <div className="overflow-hidden">
            <Box mt={3} style={{ position: "relative", zIndex: 3 }}>
              <div className="heroes__divider" />
              <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={2}>
                <div className="heroes__thumbnail">
                  {lands.map((land, idx) => (
                    <Avatar
                      key={idx}
                      onClick={() => onSelectLand(idx)}
                      src={land.image}
                      sizes="lg"
                      className={clsx(
                        "land__mint-thumbnail-icon",
                        idx === activeLandIndex && "land__mint-thumbnail-icon--active"
                      )}
                    />
                  ))}
                </div>
              </Box>
              <div className="heroes__divider" />
            </Box>
          </div>
          <div className="container land__mint-drawer--mobile">
            <img
              src={getDrawerImage(lands[activeLandIndex].type)}
              alt="Land Background"
              className="land__mint-drawer-bg--mobile"
            />
            <div className="land__mint-drawer--container--mobile">
              <h1 className="land__mint-drawer-name--mobile">{lands[activeLandIndex].name}</h1>
              <h3 className="land__mint-drawer-subtitle--mobile">{lands[activeLandIndex].fullName}</h3>
              <p>{lands[activeLandIndex].description}</p>
            </div>
          </div>
        </>
      ) : (
        <div className="land__mint-shadow">
          <div className={`land__mint-map ${selectedLand && `land__mint-map--${selectedLand}`}`}>
            {lands.map((land, idx) => (
              <>
                <div
                  key={idx}
                  className={`relative land__mint-region`}
                  onClick={() => handleDrawerOpen(land.type, true)}
                >
                  <img
                    key={idx}
                    src={land.image}
                    alt=""
                    // className={`land__mint-map--${land.type}`}
                  />
                  <h1>{land.name}</h1>
                  <h3>{land.fullName}</h3>
                  {/* <div className={`land__mint-overlay--${land.type}`} /> */}
                </div>
                <Drawer
                  className="land__mint-drawer"
                  anchor="right"
                  open={land.open}
                  onClose={() => handleDrawerOpen(land.type, false)}
                >
                  <img src={getDrawerImage(land.type)} alt="Land Drawer" />
                  <div className="land__mint-drawer--container">
                    <h1>{land.name}</h1>
                    <h3>{land.fullName}</h3>
                    <p>{land.description}</p>
                  </div>
                </Drawer>
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
