import React, { useState } from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { useSelector, useDispatch } from "react-redux";
import InputGroup from "src/app/components/Commons/InputGroup";
import expJarLogo from "src/assets/images/tokens/expjar.png";
import { EXP_PER_JAR } from "src/app/configs/constants";
import { formatNumber } from "src/app/utils/helpers";
import { compareNumbers } from "src/app/utils/calculators";
import { consumeExpJar } from "src/app/actions/stakingAction";

type ExpJarModalProps = {
  knightId: number;
  knightType: number;
  onSuccess: any;
};

export default function ExpJarModal(props: ExpJarModalProps) {
  const dispatch = useDispatch();
  const { balance } = useSelector((state: any) => state.account);
  const [inputError, setInputError] = useState("");
  const [amount, setAmount] = useState("");
  const invalidAmount = +amount <= 0 || amount.indexOf(".") === 1;

  function handleAmountChange(e: any, amount?: string) {
    setInputError("");
    if (amount) {
      setAmount(amount);
      return;
    }
    setAmount(e.target.value);
  }

  function consume() {
    const compareBalance = compareNumbers(balance.EXP_JAR, amount);
    if (invalidAmount) {
      setInputError(`Invalid amount.`);
      return;
    } else if (compareBalance === -1) {
      setInputError(`Insufficient EXP Jar balance.`);
      return;
    }

    dispatch(consumeExpJar(props.knightId, +amount, props.onSuccess));
  }

  return (
    <BasicModalContent
      customTitle="Exchange EXP Jars for EXP"
      submitText="Consume"
      onSubmit={consume}
      content={
        <div className="mt-6">
          <InputGroup
            className="text-field--large"
            value={amount}
            handleChange={handleAmountChange}
            symbol="EXP Jar(s)"
            balance={balance.EXP_JAR}
            error={inputError}
            tokenImage={expJarLogo}
            label={"EXP Jar Amount"}
          />
          {!invalidAmount && (
            <div className="mt-3 small-text small-text--warning slide-up">
              By consuming {formatNumber(amount)} EXP Jars(s), your Hero will earn an equivalent of{" "}
              {formatNumber(+amount * EXP_PER_JAR)} EXPs.
            </div>
          )}
          <div className="fs-2 mt-3">Note: 1 EXP Jar = {formatNumber(EXP_PER_JAR)} EXPs</div>
        </div>
      }
    />
  );
}
