import React, { memo, useState, useEffect } from "react";
import { useQueryString } from "use-route-as-state";
import { getGuildFilterFromParams } from "src/app/utils/filterHelper";
import { GuildFilterType } from 'src/app/types/guild';

interface GuildSwitcher {
  isLoading: boolean
}

const GuildSwitcher = memo((props: GuildSwitcher) => {
  const [queryParams, setQueryParams] = useQueryString();
  const [guildFilters, setItemFilters] = useState<GuildFilterType>(getGuildFilterFromParams(queryParams, false))
  const pending = guildFilters.pending;
  // const joinable = guildFilters.joinable;

  useEffect(() => {
    const newFilters = getGuildFilterFromParams(queryParams, false);
    setItemFilters({...newFilters});
  }, [queryParams]);

  function handleToggleChange(joinable: boolean, pending: boolean) {
    return setQueryParams({
      ...queryParams,
      joinable: joinable.toString(),
      pending: pending.toString(),
    });
  }

  return (
    <div className={`market__toggles mb-3 ${props.isLoading ? 'soft-disabled' : ''}`}>
      {/* <div className='toggle mr-3'>
        <div>Joinable</div>
        <input
          id="joinable"
          type="checkbox"
          checked={joinable}
          onChange={() => handleToggleChange(!joinable, false)}
        />
        <label htmlFor="joinable" />
      </div> */}

      <div className='toggle mr-3'>
        <div>Pending</div>
        <input
          id="pending"
          type="checkbox"
          checked={pending}
          onChange={() => handleToggleChange(false, !pending)}
        />
        <label htmlFor="pending" />
      </div>
    </div>
  )
})

export default GuildSwitcher;