import React, { useState } from "react";
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, Timeline } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import { Collapse } from "@material-ui/core";
import checkIcon from "src/assets/images/icons/check-icon.svg";
import ongoingIcon from "src/assets/images/icons/ongoing-icon.svg";

export interface Milestone {
  time: string;
  subtasks: string[];
  // Empty: none has been done, undefined: all has been done, has tasks numbers: specified tasks are done
  subtasksDone?: number[];
  reached: boolean;
}

const RoadmapTimeline = (props: any) => {
  const { year, timeline, height } = props;

  const [isOpen, setIsOpen] = useState(year === 2024);

  return (
    <div key={year}>
      <div className={`roadmap__year roadmap__year${!isOpen && '--inactive'}`} onClick={() => year !== 2024 && setIsOpen(!isOpen)}>{year}</div>
      <Collapse in={isOpen} timeout={0} unmountOnExit className="roadmap__collapse">
        <Timeline align="alternate" style={{ position: 'relative', height: height }} className="roadmap__timeline slide-up">
          <TimelineItem className="roadmap__placeholder" />
          {timeline.map((m: Milestone, id: number) => (
            <TimelineItem key={id} className="timeline" style={{ top: `${130 * id}px` }}>
              <TimelineSeparator className={`timeline__connector${id % 2 === 0 ? '--left' : '--right'}`}>
                <div className={`timeline__line timeline__line${!m.reached ? '-inactive' : ''}${id % 2 !== 0 ? '--right' : ''}`} />
                <TimelineConnector className={`timeline__connector timeline__connector${id === 0 ? '--first' : (id === timeline.length - 1) && '--last'}`} />
              </TimelineSeparator>
              <TimelineContent className={`timeline__content${id % 2 === 0 ? '--left' : '--right'}`}>
                <div className={`timeline__content-wrapper${id % 2 === 0 ? '--left' : '--right'}`}>
                  <div className="timeline__content-time">{m.time}</div>
                  <Box mt={1} mb={1}>
                    {m.subtasks.map((st, id) => {
                      const isDone = m.reached && (!m.subtasksDone || m.subtasksDone.includes(id));
                      return (
                        <div key={id} className="timeline__content-milestone timeline__content-milestone--finished">
                          {isDone && <img src={checkIcon} style={{ marginRight: 5 }} alt="" />}
                          {!isDone && <img src={ongoingIcon} style={{ padding: "4px 10px 4px 4px" }} alt="" />} {st}
                        </div>
                      );
                    })}
                  </Box>
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Collapse>
    </div>
  );
};

export default RoadmapTimeline;
