import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS, TOPIC } from "src/app/configs/constants";
import { _sendTx } from "src/app/sagas/index";
import { demiActionTypes } from "src/app/actions/demiAction";

function* summon(action: any): any {
  yield call(_sendTx, ACTIONS.SUMMON_DEMI, action.payload, action.callbacks.onSuccess, "0", [TOPIC.DEMI]);
}

function* deny(action: any): any {
  yield call(_sendTx, ACTIONS.DENY_DEMI, action.payload, action.callbacks.onSuccess);
}

function* register(action: any): any {
  yield call(_sendTx, ACTIONS.REGISTER, action.payload, action.callbacks.onSuccess);
}

function* drinkSoulStone(action: any): any {
  yield call(_sendTx, ACTIONS.DRINK_SOULSTONE, action.payload, action.callbacks.onSuccess);
}

function* equipItems(action: any): any {
  yield call(_sendTx, ACTIONS.DEMI_EQUIP_ITEMS, action.payload, action.callbacks.onSuccess);
}

function* unequipItems(action: any): any {
  yield call(_sendTx, ACTIONS.DEMI_UNEQUIP_ITEMS, action.payload, action.callbacks.onSuccess);
}

function* transfer(action: any): any {
  yield call(_sendTx, ACTIONS.DEMI_TRANSFER, action.payload);
}

export default function* demiWatcher() {
  yield takeLatest(demiActionTypes.SUMMON, summon);
  yield takeLatest(demiActionTypes.DENY, deny);
  yield takeLatest(demiActionTypes.REGISTER, register);
  yield takeLatest(demiActionTypes.DRINK_SOULSTONE, drinkSoulStone);
  yield takeLatest(demiActionTypes.EQUIP_ITEMS, equipItems);
  yield takeLatest(demiActionTypes.UNEQUIP_ITEMS, unequipItems);
  yield takeLatest(demiActionTypes.TRANSFER, transfer);
}
