import React from "react";
import { Item, ItemTransaction } from "src/app/types/equipment";
import MarketTable from "src/app/components/Commons/MarketTable";

type EquipmentExchangeTableProps = {
  item: Item;
  transactions: ItemTransaction[];
};

export default function EquipmentHistoryTable(props: EquipmentExchangeTableProps) {
  const isEmpty = props.transactions.length === 0;

  return (
    <div className={`equipment__table cm-table ${isEmpty ? "cm-table--empty" : ""} mt-7`}>
      <div className="cm-table__title">Sale History</div>
      <MarketTable
        item={props.item}
        headerTitles={["Action", "Amt", "Price", "From", "To", "Time"]}
        cellTypes={["action", "amount", "price", "address", "address", "time"]}
        cellKeys={["type", "amount", "price", "from", "to", "timestamp"]}
        isEmpty={isEmpty}
        orderData={props.transactions}
        emptyMessage="No History Yet"
        isMyOrder={false}
        myOrderData={false}
      />
    </div>
  );
}
