import Web3 from "web3";
import { Contract } from "web3-eth-contract";
import ENV from "src/app/configs/env";
import { formatBigNumber, getStakingContract, initiateWeb3Object, roundNumber } from "src/app/utils/helpers";
import { plusNumbers } from "src/app/utils/calculators";
import { TxHistoryRecord } from "src/app/types/tx";
import { KNIGHT_TYPE, MATERIAL_IDS, NON_FEE_INVENTORY_TYPE, STAKING_POOL, TX_STATUS } from "src/app/configs/constants";
import { StakingData } from "src/app/types/staking";
import { createStats } from "src/app/factories/knightFactory";
import { PrimaryStats } from "src/app/types/attribute";
import { BlockData } from "src/app/types/common";
import fp from "lodash/fp";
import * as SIDfunctions from "@siddomains/sidjs";
import SID from "@siddomains/sidjs";
import _ from "lodash";

const ERC20ABI = require("src/app/configs/ABIs/ERC20.json");
const LPABI = require("src/app/configs/ABIs/LPABI.json");
const MoonKnightABI = require("src/app/configs/ABIs/MoonKnight.json");
const EquipmentABI = require("src/app/configs/ABIs/Equipment.json");
const EquipmentMarketABI = require("src/app/configs/ABIs/EquipmentMarket.json");
const KnightAttributeABI = require("src/app/configs/ABIs/KnightAttribute.json");
const DemiKnightABI = require("src/app/configs/ABIs/DemiKnight.json");
const MaterialABI = require("src/app/configs/ABIs/Material.json");
const MaterialMarketABI = require("src/app/configs/ABIs/MaterialMarket.json");
const RewardsABI = require("src/app/configs/ABIs/Rewards.json");
const ExpCollectorABI = require("src/app/configs/ABIs/ExpCollector.json");
const SoulStoneForExpABI = require("src/app/configs/ABIs/SoulStoneForExp.json");
const GuildABI = require("src/app/configs/ABIs/Guild.json");
const KnightUpgradeABI = require("src/app/configs/ABIs/KnightUpgrade.json");
const DeprecateStakingABI = require("src/app/configs/ABIs/StakingPool.json");
const V2StakingABI = require("src/app/configs/ABIs/V2StakingKnight.json");
const V3StakingABI = require("src/app/configs/ABIs/V3StakingKnight.json");
const HeroBurnABI = require("src/app/configs/ABIs/HeroBurn.json");
const EquipmentCraftingABI = require("src/app/configs/ABIs/EquipmentCrafting.json");
const GachaABI = require("src/app/configs/ABIs/Gacha.json");
const EquipmentDismantleABI = require("src/app/configs/ABIs/EquipmentDismantle.json");
const LandABI = require("src/app/configs/ABIs/Land.json");
const ExpJarABI = require("src/app/configs/ABIs/ExpJar.json");
const EquipmentDepositABI = require("src/app/configs/ABIs/EquipmentDeposit.json");
export default class Web3Service {
  web3: Web3;
  sid: any;
  faraContract: Contract;
  lpTokenContract: Contract;
  moonKnightContract: Contract;
  equipmentContract: Contract;
  equipmentMarketContract: Contract;
  knightAttributeContract: Contract;
  demiKnightContract: Contract;
  materialContract: Contract;
  materialMarketContract: Contract;
  rewardsContract: Contract;
  expCollectorContract: Contract;
  soulStoneForExpContract: Contract;
  guildContract: Contract;
  knightUpgradeContract: Contract;
  deprecatedStakingContract: Contract;
  deprecatedSingleV1StakingContract: Contract;
  deprecatedLiquidityV1StakingContract: Contract;
  singleV2StakingContract: Contract;
  liquidityV2StakingContract: Contract;
  singleV3StakingContract: Contract;
  liquidityV3StakingContract: Contract;
  expJarContract: Contract;
  heroBurnContract: Contract;
  equipmentCraftingContract: Contract;
  gachaContract: Contract;
  equipmentDismantleContract: Contract;
  landContract: Contract;
  equipmentDepositContract: Contract;

  constructor() {
    const { web3 } = initiateWeb3Object();
    this.web3 = web3;
    this.sid = new SID({
      provider: new Web3.providers.HttpProvider(ENV.NODE.URL),
      sidAddress: SIDfunctions.getSidAddress(ENV.NETWORK_ID.toString()),
    });
    this.faraContract = new this.web3.eth.Contract(ERC20ABI, ENV.CONTRACT.FARA_TOKEN);
    this.lpTokenContract = new this.web3.eth.Contract(LPABI, ENV.CONTRACT.LP_TOKEN);
    this.moonKnightContract = new this.web3.eth.Contract(MoonKnightABI, ENV.CONTRACT.MOONKNIGHT);
    this.equipmentContract = new this.web3.eth.Contract(EquipmentABI, ENV.CONTRACT.EQUIPMENT);
    this.equipmentMarketContract = new this.web3.eth.Contract(EquipmentMarketABI, ENV.CONTRACT.EQUIPMENT_MARKET);
    this.knightAttributeContract = new this.web3.eth.Contract(KnightAttributeABI, ENV.CONTRACT.KNIGHT_ATTRIBUTE);
    this.demiKnightContract = new this.web3.eth.Contract(DemiKnightABI, ENV.CONTRACT.DEMI_KNIGHT);
    this.materialContract = new this.web3.eth.Contract(MaterialABI, ENV.CONTRACT.MATERIAL);
    this.materialMarketContract = new this.web3.eth.Contract(MaterialMarketABI, ENV.CONTRACT.MATERIAL_MARKET);
    this.rewardsContract = new this.web3.eth.Contract(RewardsABI, ENV.CONTRACT.REWARDS);
    this.expCollectorContract = new this.web3.eth.Contract(ExpCollectorABI, ENV.CONTRACT.EXP_COLLECTOR);
    this.soulStoneForExpContract = new this.web3.eth.Contract(SoulStoneForExpABI, ENV.CONTRACT.SOUL_STONE_FOR_EXP);
    this.guildContract = new this.web3.eth.Contract(GuildABI, ENV.CONTRACT.GUILD);
    this.knightUpgradeContract = new this.web3.eth.Contract(KnightUpgradeABI, ENV.CONTRACT.KNIGHT_UPGRADE);
    this.deprecatedStakingContract = new this.web3.eth.Contract(
      DeprecateStakingABI,
      ENV.CONTRACT.DEPRECATED_SINGLE_STAKING
    );
    this.deprecatedSingleV1StakingContract = new this.web3.eth.Contract(
      V2StakingABI,
      ENV.CONTRACT.DEPRECATED_SINGLE_V1_STAKING
    );
    this.deprecatedLiquidityV1StakingContract = new this.web3.eth.Contract(
      V2StakingABI,
      ENV.CONTRACT.DEPRECATED_LIQUIDITY_V1_STAKING
    );
    this.singleV2StakingContract = new this.web3.eth.Contract(V2StakingABI, ENV.CONTRACT.SINGLE_V2_STAKING);
    this.liquidityV2StakingContract = new this.web3.eth.Contract(V2StakingABI, ENV.CONTRACT.LIQUIDITY_V2_STAKING);
    this.singleV3StakingContract = new this.web3.eth.Contract(V3StakingABI, ENV.CONTRACT.SINGLE_V3_STAKING);
    this.liquidityV3StakingContract = new this.web3.eth.Contract(V3StakingABI, ENV.CONTRACT.LIQUIDITY_V3_STAKING);
    this.expJarContract = new this.web3.eth.Contract(ExpJarABI, ENV.CONTRACT.EXP_JAR);
    this.heroBurnContract = new this.web3.eth.Contract(HeroBurnABI, ENV.CONTRACT.HERO_BURN);
    this.equipmentCraftingContract = new this.web3.eth.Contract(EquipmentCraftingABI, ENV.CONTRACT.EQUIPMENT_CRAFTING);
    this.gachaContract = new this.web3.eth.Contract(GachaABI, ENV.CONTRACT.GACHA);
    this.equipmentDismantleContract = new this.web3.eth.Contract(
      EquipmentDismantleABI,
      ENV.CONTRACT.EQUIPMENT_DISMANTLE
    );
    this.landContract = new this.web3.eth.Contract(LandABI, ENV.CONTRACT.LAND);
    this.equipmentDepositContract = new this.web3.eth.Contract(EquipmentDepositABI, ENV.CONTRACT.EQUIPMENT_DEPOSIT);
  }

  fetchBlock = async (blockNumber?: number): Promise<BlockData> => {
    const block = await this.web3.eth.getBlock(blockNumber ?? "latest");

    let number = 0;
    let timestamp = 0;
    if (block) {
      number = block.number;
      timestamp = +block.timestamp;
    }

    return { number, timestamp };
  };

  getIsGuildMember = async (address: string) => {
    return await this.guildContract.methods.memberOf(address).call();
  };

  fetchBNBBalance = async (address: string): Promise<string> => {
    return await this.web3.eth.getBalance(address);
  };

  fetchIsApproveForAll = async (contract: string, address: string, operator: string): Promise<boolean> => {
    let ctr: Contract | undefined;
    switch (contract) {
      case ENV.CONTRACT.MOONKNIGHT:
        ctr = this.moonKnightContract;
        break;
      case ENV.CONTRACT.EQUIPMENT:
        ctr = this.equipmentContract;
        break;
      case ENV.CONTRACT.MATERIAL:
        ctr = this.materialContract;
        break;
    }

    return ctr ? await ctr.methods.isApprovedForAll(address, operator).call() : false;
  };

  fetchDismantlePoints = async (address: string): Promise<string> => {
    return await this.equipmentDismantleContract.methods.blacksmithExps(address).call();
  };

  estimateClaimGasFee = async (nftRewards, nonce, wallet) => {
    try {
      const gasAmount = await this.rewardsContract.methods
        .claimNFTs(nftRewards, nonce)
        .estimateGas({ from: wallet, value: 0 });
      return gasAmount <= 5000000;
    } catch (error: any) {
      return false;
    }
  };

  estimateWithdrawGasFee = async (withdrawData, wallet, type) => {
    try {
      let gasAmount;
      switch (type) {
        case NON_FEE_INVENTORY_TYPE.ITEM:
          gasAmount = await this.equipmentDepositContract.methods
            .withdrawEquipments(withdrawData)
            .estimateGas({ from: wallet, value: 0 });
          break;
        case NON_FEE_INVENTORY_TYPE.MATERIAL:
          gasAmount = await this.equipmentDepositContract.methods
            .withdrawMaterials(withdrawData)
            .estimateGas({ from: wallet, value: 0 });
          break;
        case NON_FEE_INVENTORY_TYPE.TOKEN:
          gasAmount = await this.equipmentDepositContract.methods
            .withdrawTokens(_.omit(Object.assign(withdrawData, { amount: withdrawData.amounts }), ["ids", "amounts"]))
            .estimateGas({ from: wallet, value: 0 });
          break;
      }
      return gasAmount <= 5000000;
    } catch (error: any) {
      // console.log(withdrawData, type, error);
      return false;
    }
  };

  fetchFaraBalance = async (address: string): Promise<string> => {
    return await this.faraContract.methods.balanceOf(address).call();
  };

  fetchLpTokenBalance = async (address: string): Promise<string> => {
    return await this.lpTokenContract.methods.balanceOf(address).call();
  };

  fetchSoulStoneBalance = async (address: string): Promise<string> => {
    return await this.materialContract.methods.balanceOf(address, MATERIAL_IDS.SOUL_STONE).call();
  };

  fetchExpJarBalance = async (address: string): Promise<string> => {
    return await this.materialContract.methods.balanceOf(address, MATERIAL_IDS.EXP_JAR).call();
  };

  fetchKnightTokenBalance = async (address: string): Promise<string> => {
    return await this.materialContract.methods.balanceOf(address, MATERIAL_IDS.KNIGHT_TOKEN).call();
  };

  fetchRoyalTokenBalance = async (address: string): Promise<string> => {
    return await this.materialContract.methods.balanceOf(address, MATERIAL_IDS.ROYAL_TOKEN).call();
  };

  fetchEmperorStoneBalance = async (address: string): Promise<string> => {
    return await this.materialContract.methods.balanceOf(address, MATERIAL_IDS.EMPEROR_STONE).call();
  };

  fetchItemBalance = async (address: string, itemId: number): Promise<string> => {
    return await this.equipmentContract.methods.balanceOf(address, itemId).call();
  };

  fetchSoulStoneConsumed = async (demiId: string): Promise<string> => {
    return await this.demiKnightContract.methods.knightDrinkSoulStone(demiId).call();
  };

  fetchTokenAllowance = async (address: string, spender: string, contract: string): Promise<string> => {
    return await this[contract].methods.allowance(address, spender).call();
  };

  fetchExchangingItems = async (address: string, srcItemId: number): Promise<string> => {
    return await this.equipmentMarketContract.methods.exchangedItems(address, srcItemId).call();
  };

  fetchKnightAllocatedStats = async (knightId: string): Promise<PrimaryStats> => {
    const result = await this.knightAttributeContract.methods.getKnightAttributes(knightId).call();
    return createStats(result.allocatedStats);
  };

  fetchDemiKnightAllocatedStats = async (knightId: string): Promise<PrimaryStats> => {
    const result = await this.knightAttributeContract.methods.getDemiKnightAttributes(knightId).call();
    return createStats(result.allocatedStats);
  };

  fetchStakingData = async (knightId: number, address: string, selectedPool: number): Promise<StakingData> => {
    let expEarned = "0";
    let tokenEarned = "0";
    let stakedAmount = "0";
    let unlockedTime = 0;
    let lockedMonths = 0;
    let eligibleStakingTime = 0;
    let claimedExp = 0;
    try {
      if (selectedPool === STAKING_POOL.DEPRECATED) {
        const earned = await this.deprecatedStakingContract.methods.earned(knightId, address).call();
        const stakingData = await this.deprecatedStakingContract.methods.stakingData(address, knightId).call();

        expEarned = formatBigNumber(earned.expEarned);
        tokenEarned = formatBigNumber(plusNumbers(earned.tokenEarned, stakingData.reward));
        stakedAmount = formatBigNumber(stakingData.balance);
        unlockedTime = +stakingData.lockedTime;
        lockedMonths = +stakingData.lockedMonths;
      } else {
        const poolContract = getStakingContract(selectedPool);
        const stakingData = await this[poolContract.contract].methods.getStakingData(address, knightId).call();
        if ([STAKING_POOL.SINGLE_V3, STAKING_POOL.LIQUIDITY_V3].includes(selectedPool)) {
          const expBalance = await this.expJarContract.methods.expBalances(address).call();
          claimedExp = formatBigNumber(expBalance);
        }
        if (+stakingData.stakedAmount !== 0) {
          expEarned = formatBigNumber(stakingData.expEarned);
          tokenEarned = formatBigNumber(stakingData.tokenEarned);
          stakedAmount = formatBigNumber(stakingData.stakedAmount);
        }

        unlockedTime = +stakingData.unlockedTime;
        lockedMonths = +stakingData.lockedMonths;
        eligibleStakingTime = +stakingData.eligibleStakingTime;
      }
    } catch (e) {}

    return { expEarned, tokenEarned, stakedAmount, unlockedTime, lockedMonths, eligibleStakingTime, claimedExp };
  };

  fetchTotalStakedAmount = async () => {
    //TODO: Re-enable for Staking V3
    const singlePoolData = await this.faraContract.methods.balanceOf(ENV.CONTRACT.SINGLE_V3_STAKING).call();
    const liquidityPoolData = await this.lpTokenContract.methods.balanceOf(ENV.CONTRACT.LIQUIDITY_V3_STAKING).call();
    return {
      single: formatBigNumber(singlePoolData),
      liquidity: formatBigNumber(liquidityPoolData),
    };
  };

  fetchLiquidityPool = async () => {
    const reserves = await this.lpTokenContract.methods.getReserves().call();
    const lpSupply = await this.lpTokenContract.methods.totalSupply().call();

    return {
      lpSupply: roundNumber(formatBigNumber(lpSupply), 2),
      bnbAmount: roundNumber(formatBigNumber(reserves._reserve0), 2),
      faraAmount: roundNumber(formatBigNumber(reserves._reserve1), 2),
    };
  };

  fetchSingleAndLPStakedAmount = async (account: string) => {
    const singleData = await this.singleV2StakingContract.methods.stakingBalances(account).call();
    const liquidityData = await this.liquidityV2StakingContract.methods.stakingBalances(account).call();

    return {
      single: formatBigNumber(singleData),
      liquidity: formatBigNumber(liquidityData),
    };
  };

  fetchDeprecatedStakedAmount = async (account: string, selectedPool: number) => {
    const poolContract = getStakingContract(selectedPool);
    return await this[poolContract.contract].methods.stakingBalances(account).call();
  };

  fetchKnightExp = async (knightId: number, knightType: number, isDeprecatedStaking: boolean): Promise<number> => {
    let exp;

    if (!isDeprecatedStaking) {
      if (knightType === KNIGHT_TYPE.DEMI) {
        exp = await this.expCollectorContract.methods.getDemiKnightExp(knightId).call();
      } else {
        exp = await this.expCollectorContract.methods.getKnightExp(knightId).call();
      }
    } else {
      exp = await this.deprecatedStakingContract.methods.knightExp(knightId).call();
    }

    return formatBigNumber(exp);
  };

  fetchKnight = async (knightId: number, knightType: number) => {
    let data;

    if (knightType === KNIGHT_TYPE.DEMI) {
      data = await this.demiKnightContract.methods.getKnight(knightId).call();
    } else {
      data = await this.moonKnightContract.methods.getKnight(knightId).call();
    }

    return data;
  };

  fetchLandRewards = async (landIds: number[]) => {
    const data = await this.landContract.methods.getRewards(landIds).call();
    return data;
  };

  fetchLandAirdropAccounts = async (address: string) => {
    const data = await this.landContract.methods.airdropAccounts(address).call();
    return data;
  };

  fetchLandWhitelistAccounts = async (address: string) => {
    const data = await this.landContract.methods.whitelistAccounts(address).call();
    return data;
  };

  fetchLandSupply = async () => {
    const data = await this.landContract.methods.supply().call();
    return data;
  };

  fetchJackpotTokenReward = async () => {
    const data = await this.gachaContract.methods.jackpotTokenReward().call();
    return formatBigNumber(data);
  };

  // fetchBlacksmithData = async (account: string): Promise<BlacksmithData> => {
  //   const data = await this.equipmentCraftingContract.methods.blacksmiths(account).call();

  //   return {
  //     exp: +data.points,
  //     level: +data.level
  //   };
  // };

  updateTxsStatus = async (txs: TxHistoryRecord[]) => {
    try {
      for (let i = 0; i < txs.length; i++) {
        const tx = txs[i];
        const txHash = tx.hash;

        if (tx.status === TX_STATUS.PENDING) {
          const { status, logs } = await this._checkTxMined(txHash, tx.topics);

          switch (status) {
            case true:
              tx.status = TX_STATUS.SUCCESS;
              if (tx.onSuccess) tx.onSuccess(txHash, logs);
              break;
            case false:
              tx.status = TX_STATUS.FAILED;
              if (tx.onFailed) tx.onFailed(txHash);
              break;
            default:
              tx.status = TX_STATUS.PENDING;
              break;
          }

          if (tx.status !== TX_STATUS.PENDING && tx.onDone) {
            tx.onDone(txHash);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }

    return txs;
  };

  _checkTxMined = async (txHash: string, topics: string[]) => {
    const receipt = await this.web3.eth.getTransactionReceipt(txHash);
    let status: boolean | null = null;
    let logs: any[] = [];

    if (receipt !== null) {
      const blockNumber = receipt.blockNumber;
      if (!blockNumber) {
        status = null;
      } else {
        status = receipt.status;
        logs = receipt.logs.filter((log) => {
          const topic = log.topics[0].toLowerCase();
          return topics.map(fp.toLower).includes(topic);
        });
      }
    }

    return { status, logs };
  };
}
