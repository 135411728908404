import React, { useEffect } from "react";
import {  useSelector } from "react-redux";
import ResultContent from "src/app/components/Commons/ResultContent";
import { Material } from "src/app/types/materials";
import { MATERIALS } from "src/app/configs/materials/materials";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";

type ModalProps = {
  getBlacksmithData: any;
  runeCreated: Material;
  isSuccess: boolean;
};

export default function RuneLevelUpResultModal(props: ModalProps) {
  const { address } = useSelector((state: any) => state.account);
  const { runeCreated, getBlacksmithData, isSuccess } = props;
  const { selectedRune } = useSelector((state: any) => state.rune);

  useEffect(() => {
    getBlacksmithData(address);
  }, [address, getBlacksmithData]);

  return (
    <div>
      {isSuccess && (
        <>
          <ResultContent
            isSuccess={true}
            content={
              <div>
                <div>
                  Congratulations! You have successfully leveled up{" "}
                  <span className="text-9">{MATERIALS[selectedRune?.material.id]?.name}</span> to
                  <span className="text-9"> Level {MATERIALS[selectedRune?.material.id]?.level + 1}</span>
                </div>
              </div>
            }
          />
          <div className="flex-center-center">
            <MaterialIcon className="no-margin" material={MATERIALS[runeCreated.id]} hideSelectedBorder />
          </div>
        </>
      )}
      {!isSuccess && (
        <ResultContent isSuccess={false} content={<div>Failed to level up your rune. Better luck next time.</div>} />
      )}
    </div>
  );
}
