import React from "react";
import { Tooltip } from "@material-ui/core";
import ENV from "src/app/configs/env";
import { Material, UserMaterial } from "src/app/types/materials";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import MaterialInfo from "src/app/components/Materials/Commons/MaterialInfo";
import runeImg from "src/assets/images/rune/default-rune.png";
import lockedRuneImg from "src/assets/images/rune/rune-locked.png";

type UserMaterialIconProps = {
  material: Material | undefined | null;
  amount?: number;
  showBalance?: boolean;
  userMaterial?: UserMaterial;
  onSelect?: any;
  onRightClick?: (itemId: number) => void;
  hideSelectedBorder?: boolean;
  requiredAmount?: number;
  postfix?: string;
  submitText?: string;
  onSubmit?: () => void;
  closeOnSubmit?: boolean;
  className?: string;
  layer?: number;
  hideAmount?: boolean;
};

export default function MaterialIcon(props: UserMaterialIconProps) {
  const { material, amount, showBalance, userMaterial, onSelect, requiredAmount, postfix, hideAmount } = props;

  function showMaterialInfoModal(id: number) {
    if (props.onSelect) {
      return props.onSelect();
    }

    modalService.show(BasicModalContent, {
      content: (
        <div>
          <MaterialInfo materialId={id} />
        </div>
      ),
      onSubmit: props.onSubmit,
      submitText: props.submitText,
      closeOnSubmit: !!props.closeOnSubmit,
      layer: props.layer,
    });
  }

  return (
    <Tooltip
      title={
        material === undefined
          ? "Locked Rune Slot"
          : material === null
          ? "Equip Rune"
          : `${material.name}${postfix !== undefined ? postfix : ""}`
      }
      arrow
      placement="top"
      key={material?.id}
    >
      <div
        className={`item-icon ${props.className ? props.className : ""} ${
          !!amount && !props.hideSelectedBorder ? "item-icon--selected" : ""
        } slide-up`}
        onClick={() => showMaterialInfoModal(props.material?.id ?? -1)}
        onContextMenu={(e) => {
          e.preventDefault();
          props.onRightClick?.call(props.onRightClick, props.material?.id ?? -1);
        }}
      >
        <img
          className="item-icon__image"
          src={
            material === undefined
              ? lockedRuneImg
              : material === null
              ? runeImg
              : `${ENV.URL.BASE_IMAGE_URL}/images/material/178x178/${material?.id}.png`
          }
          onClick={onSelect}
          alt=""
        />
        {!!amount && !hideAmount && <div className="item-icon__amount">x{amount}</div>}
        {showBalance && userMaterial && <div className="item-icon__text">{userMaterial.amount}</div>}
        {requiredAmount && (
          <div
            className={`item-icon__text fs-2 ${
              (userMaterial?.amount ?? 0) >= requiredAmount ? `success-text` : `error-text`
            }`}
          >
            {userMaterial?.amount ?? 0} / {requiredAmount}
          </div>
        )}
      </div>
    </Tooltip>
  );
}
