import React, { useEffect, useState } from "react";
import exchangeItems from "src/assets/images/home/soulstone/exchange-items.png";
import earnExpImage from "src/assets/images/home/soulstone/earn-exp.png";
import mintAndMaintainImage from "src/assets/images/home/soulstone/mint-and-maintain.png";
import upgradeImage from "src/assets/images/home/soulstone/upgrade.png";
import soulstoneUseCase from "src/assets/images/home/soulstone/soulstone-usecase.png";

const usecases = [exchangeItems, earnExpImage, mintAndMaintainImage, upgradeImage];

const SoulstoneUsecase = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect((): any => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div className="home__soulstone">
      <div className="home__soulstone-title">SOULSTONE USECASE</div>
      <div className="home__soulstone-desc">
        Magic stones that are hunted down everywhere in Faraland, with lots of integrated functions. Demi Heroes need
        them to lengthen their lives while NFT Heroes need them to grow significantly in strength.
      </div>

      {dimensions.width <= 700 ? (
        <div className="home__soulstone-images">
          {usecases.map((value, idx) => (
            <img key={idx} src={value} alt="" />
          ))}
        </div>
      ) : (
        <img src={soulstoneUseCase} style={{ transform: "translate(-100px, -80px)", maxWidth: "100%" }} alt="" />
      )}
    </div>
  );
};

export default SoulstoneUsecase;
