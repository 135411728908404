import React from "react";
import crystal from "src/assets/images/decors/gems/gem-orange.svg";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
import typeImg from "src/assets/images/instructions/types-img.png";
import Title from "../Commons/Title";
import wing from "src/assets/images/instructions/wing.png";
import normal from "src/assets/images/instructions/normal.png";
import lightning from "src/assets/images/instructions/lightning.png";
import wind from "src/assets/images/instructions/wind.png";
import ice from "src/assets/images/instructions/ice.png";
import water from "src/assets/images/instructions/water.png";
import rock from "src/assets/images/instructions/rock.png";
import fire from "src/assets/images/instructions/fire.png";

const TypeAdvantages = () => {
  const advantages = [
    {
      type: wing,
    },
    {
      type: normal,
    },
    {
      type: lightning,
    },
    {
      type: wind,
    },
    {
      type: ice,
    },
    {
      type: water,
    },
    {
      type: rock,
    },
    {
      type: fire,
    },
  ];

  return (
    <div className="instructions-types">
      <div className="container">
        <FloatingCrystal speed={5} src={crystal} style={{ right: 0, top: 3500, zIndex: 1 }} />
        <Title value="Hero Elements" style={{ paddingBottom: "20px" }} />
        <div className="instructions-types__desc">
          Each element has certain advantages and disadvantages against each of its counterfeit.
        </div>
        <img className="instructions-types__image" src={typeImg} alt="" />
        <div className="instructions-types__advantages">
          {advantages.map((value, index) => (
            <img src={value.type} key={index} alt="" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TypeAdvantages;
