import React from "react";
import DemoCharacters from "src/app/components/Home/DemoCharacters/DemoCharacters";
import Intro from "src/app/components/Home/Intro/Intro";
import Features from "src/app/components/Home/Features/Features";
import Roadmap from 'src/app/components/Home/Roadmap/Roadmap';
import SecuredBy from "src/app/components/Home/SecuredBy/SecuredBy";
import SoulstoneUsecase from "src/app/components/Home/Soulstone/SoulstoneUsecase";
import BodyParts from "src/app/components/Home/DemoCharacters/BodyParts";
import TypeAdvantages from "src/app/components/Home/DemoCharacters/TypeAdvantages";
import FaraCrystal from "src/app/components/Home/DemoCharacters/FaraCrystal";
import BackedBy from "src/app/components/Home/BackedBy/BackedBy";

export default function Home() {
  return (
    <div className="home">
      {/* <div className="home__demo">
        <img src={iphoneFrame} />
      </div> */}

      {/* <div className="home__carousel">
        <div className="home__carousel-preview">
          <img src={bannerFrame} />
          <img src={bannerFrame} />
          <img src={bannerFrame} />
        </div>
        <div className="home__carousel-page">
          <img src={inactivePageImage} className="home__carousel-page--inactive" />
          <img src={inactivePageImage} className="home__carousel-page--inactive" />
          <img src={inactivePageImage} className="home__carousel-page--inactive" />
          <img src={activePageImage} />
          <img src={inactivePageImage} className="home__carousel-page--inactive" />
          <img src={inactivePageImage} className="home__carousel-page--inactive" />
          <img src={inactivePageImage} className="home__carousel-page--inactive" />
        </div>
      </div> */}
      <Intro />
      <DemoCharacters />
      <div className="home__features">
        <BodyParts />
        <TypeAdvantages />
      </div>
      <div className="home__features-spacer" />
      <Features />
      <FaraCrystal />
      <SoulstoneUsecase />
      <Roadmap />
      <BackedBy />
      <SecuredBy />
    </div>
  )
}