import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import faraLogo from "src/assets/images/tokens/fara.png";
import EquipmentHistoryTable from "src/app/components/Equipment/Details/EquipmentHistoryTable";
import EquipmentExchangeTable from "src/app/components/Equipment/Details/EquipmentExchangeTable";
import EquipmentOfferTable from "src/app/components/Equipment/Details/EquipmentOfferTable";
import EquipmentListingTable from "src/app/components/Equipment/Details/EquipmentListingTable";
import { marketClient } from "src/app/services/subgraph/client";
import { ITEM_INFO, ITEM_OWN_INFO } from "src/app/services/subgraph/queries";
import { EQUIPMENT_TYPE, INTERVAL } from "src/app/configs/constants";
import {
  ListItem,
  OfferItem,
  ExchangeItem,
  ItemTransaction,
  UserItem,
  Equipment,
  SkillBook,
  ItemAttackRange,
} from "src/app/types/equipment";
import {
  createOfferItem,
  createListItem,
  createExchangeItem,
  createItemTransaction,
  createUserItem,
  createItem,
} from "src/app/factories/equipmentFactory";
import { EQUIPMENT_LABEL, RARITY_LABEL, STATS_LABEL } from "src/app/configs/constants";
import { getKnightStatsLabel } from "src/app/utils/mappingHelpers";
import { EQUIPMENT } from "src/app/configs/equipment/equipment";
import { Item, ItemStatsLabel } from "src/app/types/equipment";
import { formatNumber, isMainStat } from "src/app/utils/helpers";
import { multiplyNumbers } from "src/app/utils/calculators";
import ItemRequirement from "src/app/components/Equipment/Commons/ItemRequirement";
import ItemEffect from "src/app/components/Equipment/Commons/ItemEffect";
import ItemSetBonus from "src/app/components/Equipment/Commons/ItemSetBonus";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalModal } from "src/app/actions/globalAction";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import { TextField } from "@material-ui/core";
import { renderBackBtn } from "src/app/utils/renderHelpers";
import { CRAFTABLE_EQUIPMENT } from "src/app/configs/equipment/crafting";
import { fetchSkillBookFromApi } from "src/app/services/api/faralandService";
import { mapSkillBookValue } from "src/app/utils/mappingHelpers";
import _ from "lodash";
import useFetchSpaceDomain from "src/app/hooks/useFetchSpaceDomain";
import { queryTotalAmount } from "src/app/services/api/subgraphService";

export default function EquipmentDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { address } = useSelector((state: any) => state.account);
  const { web3Service, tokenPrice } = useSelector((state: any) => state.global);

  const [equipment, setEquipment] = useState<Equipment>();
  const [offers, setOffers] = useState<OfferItem[]>([]);
  const [lists, setLists] = useState<ListItem[]>([]);
  const [exchanges, setExchanges] = useState<ExchangeItem[]>([]);
  const [transactions, setTransactions] = useState<ItemTransaction[]>([]);
  const [itemBalance, setItemBalance] = useState<number>(0);
  const [isExchanging, setIsExchanging] = useState(false);
  const [userItems, setUserItems] = useState<UserItem[]>([]);
  const [ownOffers, setOwnOffers] = useState<OfferItem[]>([]);
  const [ownLists, setOwnLists] = useState<ListItem[]>([]);
  const [ownExchanges, setOwnExchanges] = useState<ExchangeItem[]>([]);
  const [skillBookInfo, setSkillBookInfo] = useState<SkillBook>();
  const { domains, getAddresses } = useFetchSpaceDomain();
  const [totalAmount, setTotalAmount] = useState(0);
  const item: Item = EQUIPMENT[id];
  const itemRecipeId = CRAFTABLE_EQUIPMENT[item.id];
  const itemStats: ItemStatsLabel = item.stats;
  const totalStats = itemStats.STR + itemStats.AGI + itemStats.INT + itemStats.LUK;
  const lowerCaseRarity = RARITY_LABEL[item.rarity].toLowerCase();

  useEffect(() => {
    window.scrollTo(0, 0);
    _getSkillbookData(id);
  }, [id]); // eslint-disable-line

  useEffect(() => {
    if (!web3Service) return;

    _getItemData();
    _getItemOwnData();

    const interval = setInterval(() => {
      _getItemData();
      _getItemOwnData();
    }, INTERVAL.ITEM_DETAILS);

    return () => clearInterval(interval);
  }, [web3Service, id, domains]); // eslint-disable-line


  async function _getItemData() {
    try {
      if (!id) return;

      const result = await marketClient.query({
        query: ITEM_INFO,
        variables: { id },
        fetchPolicy: "network-only",
      });
      const totalEquipmentAmount = await queryTotalAmount(id, "Item");
      const dataEquipment = result.data.equipment ?? {};
      const dataOffers = result.data.offerItems ?? [];
      const dataLists = result.data.listItems ?? [];
      const dataExchanges = result.data.exchangeItems ?? [];
      const dataTransactions = result.data.itemMarketplaceTransactions ?? [];
      getAddresses(dataOffers, [dataLists, dataExchanges, dataTransactions]);
      if (dataEquipment) setEquipment(createItem(dataEquipment));
      if (dataOffers) setOffers(dataOffers.map((o: any) => createOfferItem(o, domains)));

      if (dataLists) setLists(dataLists.map((l: any) => createListItem(l, domains)));

      if (dataExchanges) setExchanges(dataExchanges.map((e: any) => createExchangeItem(e, domains)));

      if (dataTransactions) setTransactions(dataTransactions.map((t: any) => createItemTransaction(t, domains)));
      if(totalEquipmentAmount) {
        setTotalAmount(totalEquipmentAmount.totalAmount);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function _getSkillbookData(id: string) {
    try {
      if (item.type !== EQUIPMENT_TYPE.SKILL_BOOK) return;
      const bookInfo = await fetchSkillBookFromApi(id);
      setSkillBookInfo(bookInfo);
    } catch (err) {
      console.log(err);
    }
  }

  async function _getItemOwnData() {
    try {
      if (!id || !address) return;
      let result, dataOffers, dataLists, dataExchanges, dataUserItems;

      result = await marketClient.query({
        query: ITEM_OWN_INFO,
        variables: { id, address },
        fetchPolicy: "network-only",
      });
      dataOffers = result.data.offerItems ?? [];
      dataLists = result.data.listItems ?? [];
      dataExchanges = result.data.exchangeItems ?? [];
      dataUserItems = result.data.userOwnEquipments ?? [];

      if (dataOffers) setOwnOffers(dataOffers.map((o: any) => createOfferItem(o, domains)));

      if (dataLists) setOwnLists(dataLists.map((l: any) => createListItem(l, domains)));

      if (dataExchanges) {
        setOwnExchanges(dataExchanges.map((e: any) => createExchangeItem(e, domains)));
        setIsExchanging(!!dataExchanges.length);
      }
      if (dataUserItems) {
        const convertedUserItems = dataUserItems.map((o: any) => createUserItem(o));
        setUserItems(convertedUserItems);
        const userItemData = convertedUserItems.find((i: UserItem) => i.item.id === item.id);
        if (userItemData) {
          setItemBalance(userItemData.available);
        } else {
          setItemBalance(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function openExchangeModal() {
    dispatch(setGlobalModal("exchangeItem", { active: true, data: { item, isExchanging, setIsExchanging } }));
  }

  function openTransferModal() {
    dispatch(
      setGlobalModal("transferEquipment", {
        active: true,
        data: {
          selectedItem: createUserItem({
            id: item.id,
            user: { id: address },
            item: createItem({ id: item.id }),
            amount: itemBalance,
            used: 0,
          }),
        },
      })
    );
  }

  function openDismantleModal() {
    dispatch(
      setGlobalModal("equipmentDismantleModal", {
        active: true,
        data: { item: item, userItem: userItems.find((i: UserItem) => i.item.id === item.id) },
      })
    );
  }

  function openUpgradeModal() {
    const itemBalance = userItems && userItems[0] ? userItems[0].available : 0;

    dispatch(
      setGlobalModal("upgradeEquipment", {
        active: true,
        data: { item: item, balance: itemBalance },
      })
    );
  }

  function renderItemContent() {
    return (
      <div>
        {item.description && (
          <div className="equipment__block stats">
            <div className="stats__title no-margin">Description</div>
            <div className="stats__description" dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        )}
        <div className="equipment__block stats">
          <div className="stats__title no-margin">Stats ({totalStats})</div>
          <div className="stats__container">
            {Object.values(STATS_LABEL).map((stat: any, index: number) => {
              return (
                <div className="stats__item" key={index}>
                  <div className="stats__name">{getKnightStatsLabel(stat)}</div>
                  <div className="stats__value">
                    <img src={require(`src/assets/images/hero-details/stats/${stat.toLowerCase()}.svg`)} alt={stat} />
                    <div>{itemStats[stat]}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex-2-column mt-5 pl-1 item-stats--large">
            {Object.keys(itemStats).map((key: string, index: number) => {
              const stat = itemStats[key];
              if (isMainStat(key) || stat === 0) return null;
              return (
                <div key={index} className="item-stats__block mb-1">
                  <span className="fs-4">- {key} </span>
                  <span className="text-9">+{stat}</span>
                </div>
              );
            })}
            {item.moveRange && (
              <div className="item-stats__block mb-1">
                <span className="fs-4">- Move Range: </span>
                <span className="text-9">{item.moveRange}</span>
              </div>
            )}
            {item.attackRange && renderAttackRange(item.attackRange)}
          </div>
        </div>

        {itemStats.effects.length > 0 && (
          <div className="equipment__block">
            <div className="equipment__title">Effects</div>
            <ItemEffect itemStats={itemStats} largeText={true} />
          </div>
        )}

        <ItemSetBonus item={item} largeText={true} />

        <div className="equipment__block">
          <div className="equipment__title">Requirement</div>
          <ItemRequirement item={item} largeText={true} />
        </div>
      </div>
    );
  }

  function renderAttackRange(attackRange: ItemAttackRange) {
    if (attackRange.min === attackRange.max) {
      return (
        <div className="item-stats__block mb-1">
          <span className="fs-4">- Attack Range: </span>
          <span className="text-9">{attackRange.min}</span>
        </div>
      );
    } else {
      return Object.entries(attackRange).map(([key, value]) => (
        <div className="item-stats__block mb-1">
          <span className="fs-4">- {key === "min" ? "Min Attack Range" : "Max Attack Range"}: </span>
          <span className="text-9">{value}</span>
        </div>
      ));
    }
  }

  function renderSkillBookContent() {
    return (
      <div>
        <div className="equipment__block stats">
          <div className="stats__title no-margin">Skill Effect</div>
          <div className="stats__container stats__skill-effect">{skillBookInfo?.skillDescription}</div>
        </div>
        <div className="equipment-block mt-5">
          {(!_.isEmpty(skillBookInfo?.elementalBonus) || !_.isEmpty(skillBookInfo?.partDescription)) && (
            <div className="equipment__title">Bonus</div>
          )}
          {!_.isEmpty(skillBookInfo?.elementalBonus) && (
            <div className="item-stats item-stats--large">
              <div className="item-stats__label">Elemental Bonus:</div>
              {skillBookInfo?.elementalBonus.map((bonus, index) => (
                <div key={index} className="item-stats__block item-stats__value">
                  +{bonus.val[1]}% {mapSkillBookValue(bonus.val[0])} if hero is of {bonus.type} type
                </div>
              ))}
            </div>
          )}
          {!_.isEmpty(skillBookInfo?.partDescription) && (
            <div className="item-stats item-stats--large">
              <div className="item-stats__label">Body Parts Bonus:</div>
              {skillBookInfo?.partDescription.map((bonus, index) => (
                <div key={index} className="item-stats__block item-stats__value">
                  {bonus}
                </div>
              ))}
            </div>
          )}

          <div className="equipment__block mt-8">
            <div className="equipment__title">Requirement</div>
            <ItemRequirement item={item} largeText={true} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="equipment container slide-up">
      <div className="equipment__header">
        <div className="equipment__column">
          {renderBackBtn(history)}
          <div className="equipment__id">
            <div className={`equipment__info-tag equipment__info-tag--small equipment__info-tag--${lowerCaseRarity}`}>
              #{item.id}
            </div>
          </div>
          <div className="flex-center-start equipment__balance">
            <div className="flex-column mr-5">
              <TextField variant="outlined" label="BALANCE" value={itemBalance} disabled />
            </div>
            <div className="flex-column">
              <div className="equipment__balance-title">AVAILABLE / TOTAL SUPPLY</div>
              <div className="equipment__balance-value">
                {equipment && equipment.minted - equipment.burned! - totalAmount}/{equipment && equipment.minted}
              </div>
            </div>
          </div>
          <div className="equipment__name">{item.displayName}</div>
          <ItemIconPreview item={item} className="center-margin" largeImage={true} />
        </div>
        <div className="equipment__column">
          <div className="equipment__header-data">
            <div className="equipment__header-buttons">
              {(itemBalance !== 0 || isExchanging) && (
                <div className="btn slide-up" onClick={openExchangeModal}>
                  Exchange
                </div>
              )}
              {itemBalance !== 0 && (
                <>
                  <div className="btn ml-3 slide-up" onClick={openTransferModal}>
                    Transfer
                  </div>
                  {["Common", "Uncommon"].includes(RARITY_LABEL[item.rarity]) && (
                    <div className="btn ml-3 slide-up" onClick={openDismantleModal}>
                      Dismantle
                    </div>
                  )}
                </>
              )}
              <div className={`btn ml-3 slide-up ${!item.nextTierId ? "disabled" : ""}`} onClick={openUpgradeModal}>
                Upgrade
              </div>
              {!!itemRecipeId && (
                <Link
                  to={{
                    pathname: `/crafting/recipe/${itemRecipeId}`,
                    state: { userItem: userItems[0] },
                  }}
                  className="btn ml-3 slide-up"
                >
                  Craft
                </Link>
              )}
            </div>
            <div className="detail-price">
              <div className="detail-price__container">
                <div>
                  <div className="detail-price__price">
                    {lists[0]?.price ? formatNumber(lists[0]?.price, 2) : "--/--"} FARA
                  </div>
                  {lists[0]?.price && (
                    <div className="detail-price__dollars">
                      ≈ ${formatNumber(multiplyNumbers(lists[0].price, tokenPrice.FARA), 2)}
                    </div>
                  )}
                </div>
                <img className="detail-price__logo" src={faraLogo} alt="FARA" />
              </div>
            </div>
          </div>
          <div className="equipment__info">
            <div>
              <div className="equipment__info-title">Tier</div>
              <div className="equipment__info-value">{item.tier}</div>
            </div>
            <div>
              <div className="equipment__info-title">Type</div>
              <div className="equipment__info-value">
                <div>{EQUIPMENT_LABEL[item.type]}</div>
                {EQUIPMENT_LABEL[item.type] === "Main Weapon" && (
                  <div className="mt-1">({item.twoHanded ? "Two handed" : "One handed"})</div>
                )}
              </div>
            </div>
            <div>
              <div className="equipment__info-title">Rarity</div>
              <div className={`equipment__info-tag equipment__info-tag--${lowerCaseRarity}`}>{lowerCaseRarity}</div>
            </div>
          </div>
          {EQUIPMENT_LABEL[item.type] === "Skill Book" ? renderSkillBookContent() : renderItemContent()}
        </div>
      </div>
      <div className="equipment__container mt-10">
        <div className="equipment__column">
          <EquipmentListingTable lists={lists} offers={offers} item={item} ownLists={ownLists} userItems={userItems} />
        </div>
        <div className="equipment__column">
          <EquipmentOfferTable lists={lists} offers={offers} item={item} ownOffers={ownOffers} userItems={userItems} />
        </div>
      </div>
      <EquipmentExchangeTable
        item={item}
        exchanges={exchanges}
        openExchangeItemModal={openExchangeModal}
        itemBalance={itemBalance}
        isExchanging={isExchanging}
        ownExchanges={ownExchanges}
      />
      <EquipmentHistoryTable item={item} transactions={transactions} />
    </div>
  );
}
