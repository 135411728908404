import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import KnightHeaderButtons from "src/app/components/Knight/Details/KnightHeaderButons";
import KnightPreview from "src/app/components/Knight/Details/KnightPreview";
import KnightActionModal from "src/app/components/Knight/Details/KnightActionModal";
import KnightHistoryTable from "src/app/components/Knight/Details/KnightHistoryTable";
import { marketClient } from "src/app/services/subgraph/client";
import { KNIGHT_INFO } from "src/app/services/subgraph/queries";
import { Knight, Offer, List, Transaction, KnightStats } from "src/app/types/knight";
import { getMarketModalTitle, mapGender } from "src/app/utils/mappingHelpers";
import { ACTIONS, INTERVAL, KNIGHT_TYPE } from "src/app/configs/constants";
import { createKnight, createOffer, createTx } from "src/app/factories/knightFactory";
import { Lottie } from "@crello/react-lottie";
import { calculateStatsFromItems, getAnimatedJsonOptions, isKnightDead } from "src/app/utils/helpers";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import { fetchKnightOffChainEquipment, fetchKnightStatsById } from "src/app/services/api/faralandService";
import KnightData from "src/app/components/Knight/Details/KnightData";
import { updateKnightStats } from "src/app/utils/converters";
import useKnightSkills from "src/app/hooks/useKnightSkills";
import useFetchSpaceDomain from "src/app/hooks/useFetchSpaceDomain";
import { KnightAttributes } from "src/app/types/attribute";
import { MATERIALS } from "src/app/configs/materials/materials";
import { Material } from "src/app/types/materials";
export default function KnightDetails() {
  const { id } = useParams<{ id: string }>();
  const fetchingInterval = useRef<any>();
  const { address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const [knight, setKnight] = useState<Knight>();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [ownerActiveOffer, setOwnerActiveOffer] = useState<Offer>();
  const [currentList, setCurrentList] = useState<List>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isHaveOffers, setIsHaveOffers] = useState(false);
  const [currentActiveOffer, setCurrentActiveOffer] = useState<any | undefined>();
  const [knightStats, setKnightStats] = useState<KnightStats>();
  const [slotItems, setSlotItems] = useState([]);
  const [isHidden, setIsHidden] = useState(false);
  const { domains, getAddresses } = useFetchSpaceDomain();
  const { refresh } = useSelector((state: any) => state.knight);
  const { renderLearnedSkills, renderSkillTree } = useKnightSkills(id, false, knight);
  const [equippedRunes, setEquippedRunes] = useState<Material[]>([]);
  const isDead = isKnightDead(knight?.owner ?? "");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (id && web3Service) {
      fetchKnightAndMarketData();

      fetchingInterval.current = setInterval(() => {
        fetchKnightAndMarketData();
      }, INTERVAL.KNIGHT_DETAILS);
    }

    return () => {
      clearInterval(fetchingInterval.current);
    };
  }, [web3Service, id, address, domains, refresh]); // eslint-disable-line

  useEffect(() => {
    const isOwner = !!knight && knight.owner.toLowerCase() === address;
    setIsOwner(isOwner);

    const ownerActiveOffer: any =
      offers && offers.length && address ? offers.find((offer) => offer.buyer === address.toLowerCase()) : null;
    setCurrentActiveOffer(ownerActiveOffer);
    setOwnerActiveOffer(ownerActiveOffer);

    if (offers && offers.length && address) {
      const foundOffer = offers.find((o) => address.toLowerCase() === o.buyer);
      if (foundOffer) setIsHaveOffers(true);
    } else {
      setIsHaveOffers(false);
    }
  }, [knight, offers, address]);

  function getEquippedRunes(attributes: KnightAttributes[]) {
    const runes = attributes
      .map((attribute) => {
        return ["slot1", "slot2", "slot3"].map((keys) => {
          return attribute[keys];
        });
      })
      .flat()
      .filter((rune) => !!rune)
      .map((rune) => MATERIALS[rune]);
    setEquippedRunes(runes);
  }
  async function fetchKnightAndMarketData() {
    try {
      // Fetch knight data from API.
      const knightFromApi = await fetchKnightStatsById(id, address);
      if (!knightFromApi) return;
      if (knightFromApi.data === "Not the owner") {
        setIsHidden(true);
      }

      // Fetch knight data directly from SC.
      const knightFromSC = await web3Service.fetchKnight(+id, KNIGHT_TYPE.IMMORTAL);
      const knightOffChainData = await fetchKnightOffChainEquipment(id);
      // Re-calculate stats of a knight from SC.
      const knightLevel = knightFromSC.level;
      const knightGender = mapGender(knightFromApi.data.gender);
      const knightRace = knightFromApi.data.race;
      const { itemStats, itemSetBonus, equipments } = calculateStatsFromItems(
        knightLevel,
        knightGender,
        knightRace,
        knightFromApi.data.isOffChain ? knightOffChainData : knightFromSC.equipment
      );
      // Update knight stats with data from SC.
      const statsFromAPI = knightFromApi.stats;
      const updatedKnightData = { ...statsFromAPI, itemStats, itemSetBonus };
      await updateKnightStats(id, knightLevel, updatedKnightData, setKnightStats, web3Service);
      let knightData = {
        ...knightFromApi.data,
        equipments,
        level: knightLevel,
        owner: { id: knightFromApi.data.owner },
        hasOffchainEquipment: knightOffChainData.filter((data) => data !== 0).length > 0,
      };
      // Fetch knight data from Subgraph & marketplace history.
      try {
        const result = await marketClient.query({
          query: KNIGHT_INFO,
          variables: { id },
          fetchPolicy: "network-only",
        });

        let knightFromSubgraph = result.data.knights ?? [];

        const dataOffers = result.data.offers ?? [];
        const dataLists = result.data.lists ?? [];
        const dataTransactions = result.data.transactions ?? [];
        if (knightFromSubgraph && knightFromSubgraph[0]) {
          knightFromSubgraph = knightFromSubgraph[0];
          if (!knightData.id) {
            knightData.id = knightFromSubgraph.id;
          }
          knightData.listedPrice = knightFromSubgraph.listedPrice;
          knightData.owner = knightFromSubgraph.owner;
        }

        getAddresses(dataOffers, [dataTransactions]);
        if (dataOffers) setOffers(dataOffers.map((o: any) => createOffer(o, domains)));
        if (dataLists && dataLists[0]) setCurrentList(dataLists[0]);
        if (dataTransactions) {
          setTransactions(
            dataTransactions
              .filter((record: any) => {
                return record.type !== "transfer";
              })
              .map((record: any) => {
                return createTx(record, domains);
              })
          );
        }
      } catch (e) {
        console.log(e);
      }
      const knightCreated = createKnight(knightData);
      getEquippedRunes(knightCreated.attributes);
      setKnight(knightCreated);
    } catch (error) {
      console.log(error);
    }
  }

  function onOpenPopup(type: number, takeOfferPrice?: string, buyer?: string) {
    modalService.show(KnightActionModal, {
      title: getMarketModalTitle(type),
      type: type,
      knight: knight,
      currentActiveOffer: currentActiveOffer,
      currentList: currentList,
      ownerActiveOffer: ownerActiveOffer,
      isHaveOffers: isHaveOffers,
      offerPrice: takeOfferPrice,
      buyer: buyer,
    });
  }

  function handleTakeOffer(price: string, buyer: string) {
    onOpenPopup(ACTIONS.TAKE_OFFER, price, buyer);
  }

  return (
    <div className="knight container slide-up">
      {knight !== undefined ? (
        <Grid container spacing={4}>
          {!isHidden && (
            <>
              {!isDead && (
                <Grid item xs={12} md={12} lg={12}>
                  <KnightHeaderButtons onOpenPopup={onOpenPopup} knight={knight} isOwner={isOwner} />
                </Grid>
              )}
              <Grid item xs={12} md={6} lg={6}>
                {knightStats !== undefined && (
                  <KnightPreview
                    knight={knight}
                    knightStats={knightStats}
                    onOpenPopup={onOpenPopup}
                    isOwner={isOwner}
                    slotItems={slotItems}
                    setSlotItems={setSlotItems}
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6} lg={6}>
            {knightStats !== undefined && (
              <KnightData
                knight={knight}
                knightStats={knightStats}
                equippedRunes={equippedRunes}
                offers={offers}
                isOwner={isOwner}
                isHaveOffers={isHaveOffers}
                onOpenPopup={onOpenPopup}
                handleTakeOffer={handleTakeOffer}
                slotItems={slotItems}
                isHidden={isHidden}
              />
            )}
          </Grid>
          {!isHidden && (
            <Grid item xs={12} md={12} lg={12}>
              {renderSkillTree()}
              {renderLearnedSkills()}
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <KnightHistoryTable transactions={transactions} />
          </Grid>
        </Grid>
      ) : (
        <Lottie className="market__loading slide-up" config={getAnimatedJsonOptions(loadingJson)} />
      )}
    </div>
  );
}
