import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setGlobalModal } from "src/app/actions/globalAction";
import { knightRefresh } from "src/app/actions/knightAction";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import Modal from "src/app/components/Commons/Modals/Modal";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { RuneSelectionModal } from "src/app/components/Knight/RuneEquipment/RuneSelectionModal";
import MaterialIcon from "src/app/components/Materials/Commons/MaterialIcon";
import { INTERVAL, MATERIAL_IDS } from "src/app/configs/constants";
import { MATERIALS } from "src/app/configs/materials/materials";
import { getEmptyRuneStats } from "src/app/factories/knightFactory";
import { equipRune, fetchNonFeeMaterials, unequipRune, unlockRuneSlot } from "src/app/services/api/faralandService";
import { KnightAttributes } from "src/app/types/attribute";
import { RuneStatsLabel } from "src/app/types/equipment";
import { Material, UserMaterial } from "src/app/types/materials";

export default function RuneEquipmentModal() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { address, wallet } = useSelector((state: any) => state.account);
  const { refresh } = useSelector((state: any) => state.knight);
  const { web3Service, modal } = useSelector((state: any) => state.global);
  const runeEquipmentModal = modal.runeEquipmentModal;
  const { knight, attribute, isOwner } = runeEquipmentModal.data;
  const [selectedSlot, setSelectedSlot] = useState<number>(0);
  const [userMaterials, setUserMaterials] = useState<UserMaterial[]>([]);
  const [unlockError, setUnlockError] = useState<string>();
  const requiredUnlockMaterials = [0, 9, 8];
  const [currentAttributes, setCurrentAttributes] = useState(attribute);
  const requiredUnlockAmount = [100, 50, 50];
  const balanceInterval = useRef<any>();
  const [disableUnlock, setDisableUnlock] = useState(false);
  const [runeStats, setRuneStats] = useState<RuneStatsLabel>(getEmptyRuneStats());
  const isUnlockRune = Boolean(selectedSlot) && currentAttributes?.[`slot${selectedSlot}`] === undefined;
  useEffect(() => {
    if (!address || !web3Service) {
      setUserMaterials([]);
      return;
    }

    _fetchUserOwnMaterials(address);

    balanceInterval.current = setInterval(() => {
      _fetchUserOwnMaterials(address);
    }, INTERVAL.BALANCE);

    return () => {
      clearInterval(balanceInterval.current);
    };
  }, [address, web3Service]); // eslint-disable-line

  function _updateRuneStats(totalRuneStats: RuneStatsLabel, stats?: RuneStatsLabel) {
    if (!stats) return;
    Object.entries(stats).forEach(([key, value]) => {
      totalRuneStats[key] = totalRuneStats[key] + value;
    });
  }

  function getTotalRuneStats(attribute: KnightAttributes) {
    const runes = ["slot1", "slot2", "slot3"]
      .map((keys) => {
        return attribute[keys];
      })
      .flat()
      .filter((rune) => !!rune)
      .map((rune) => MATERIALS[rune]);
    let stats = getEmptyRuneStats();
    if (runes && runes.length > 0) {
      runes.forEach((rune) => {
        _updateRuneStats(stats, rune.stats);
      });
    }
    setRuneStats(stats);
  }
  useEffect(() => {
    if (JSON.stringify(currentAttributes) === JSON.stringify(attribute)) return;
    setDisableUnlock(false);
    getTotalRuneStats(attribute);
    setCurrentAttributes(attribute);
  }, [attribute]); // eslint-disable-line

  async function _fetchUserOwnMaterials(address: string) {
    let result;
    result = await fetchNonFeeMaterials(address);
    if (result) setUserMaterials(result.materials);
  }

  function redirectToDetail(itemId: number) {
    history.push(`/material/${itemId}`);
  }

  const handleUnlockRune = async () => {
    const signData = await wallet.signData(
      address,
      { knightId: knight.id },
      {
        Group: [{ name: "knightId", type: "uint256" }],
      }
    );
    const res = await unlockRuneSlot(
      knight.id,
      signData.sign,
      signData.msgParams,
      `trait${currentAttributes.traitType}`,
      `slot${selectedSlot}`,
      requiredUnlockMaterials,
      requiredUnlockAmount
    );
    if (res.success) {
      setDisableUnlock(true);
    }
  };

  const closeRuneSelect = () => {
    setSelectedSlot(0);
    dispatch(knightRefresh(!refresh));
    modalService.close();
  };
  const handleUnequipRune = async (selectedSlot: number) => {
    const signData = await wallet.signData(
      address,
      { knightId: knight.id },
      {
        Group: [{ name: "knightId", type: "uint256" }],
      }
    );
    const res = await unequipRune(
      knight.id,
      signData.sign,
      signData.msgParams,
      `trait${currentAttributes.traitType}`,
      `slot${selectedSlot}`,
      [MATERIAL_IDS.KNIGHT_TOKEN],
      [2]
    );
    if (res.success) {
      closeRuneSelect();
    }
  };
  const handleEquipRune = async (selectedRune: Material, selectedSlot: number) => {
    const signData = await wallet.signData(
      address,
      { knightId: knight.id },
      {
        Group: [{ name: "knightId", type: "uint256" }],
      }
    );
    const res = await equipRune(
      knight.id,
      signData.sign,
      signData.msgParams,
      `trait${currentAttributes.traitType}`,
      `slot${selectedSlot}`,
      selectedRune.id
    );
    if (res.success) {
      closeRuneSelect();
    }
  };
  const renderRuneEquipmentContent = () => {
    return (
      <>
        <div className="body-part__rune-equip">
          {new Array((currentAttributes?.rarity ?? 3) - 2).fill(undefined).map((value, index) => (
            <MaterialIcon
              key={index}
              amount={selectedSlot === index + 1 ? 1 : 0}
              material={
                currentAttributes?.[`slot${index + 1}`]
                  ? MATERIALS[currentAttributes?.[`slot${index + 1}`]]
                  : currentAttributes?.[`slot${index + 1}`]
              }
              hideAmount
              onSelect={() => {
                if (!isOwner) return;
                setSelectedSlot(index + 1);
                if (currentAttributes?.[`slot${index + 1}`] === null || currentAttributes?.[`slot${index + 1}`]) {
                  modalService.show(RuneSelectionModal, {
                    layer: 2,
                    selectedSlot: index + 1,
                    currentAttributes,
                    userMaterials,
                    handleEquipRune,
                    handleUnequipRune,
                    close: closeRuneSelect,
                  });
                }
              }}
            />
          ))}
        </div>
        {!isUnlockRune && Object.values(runeStats).some((value) => value > 0) && (
          <div className="flex-column-center fs-3">
            <div>
              <div className="fs-4 mb-2">
                <b>Total Stats From Runes</b>
              </div>
              {Object.keys(runeStats).map((key, index) => {
                let postfix = "";
                const percentageKeys = [
                  "Bonus Active Skill",
                  "Bonus Passive Skill",
                  "Bonus Normal Attack",
                  "Bonus Elemental Counter",
                ];
                if (percentageKeys.indexOf(key) !== -1) postfix = "%";
                if (runeStats[key] === 0) return <></>;
                return (
                  <div key={index}>
                    <span>- {key}: </span>
                    <span className="text-9">
                      {runeStats[key]}
                      {postfix}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {isUnlockRune && (
          <>
            <div className="dashed-title">
              <span>Unlock Requirements</span>
            </div>
            <div className="item-crafting__materials-row">
              {requiredUnlockMaterials.map((materialId, index) => {
                const userMaterial = userMaterials?.filter(
                  (userMaterial: UserMaterial) => userMaterial.id === +materialId
                );
                const requiredAmount = requiredUnlockAmount[index];

                return (
                  <MaterialIcon
                    key={index}
                    userMaterial={userMaterial?.[0]}
                    material={MATERIALS[materialId]}
                    requiredAmount={requiredAmount}
                    className="item-crafting__materials-material"
                    submitText="Detail"
                    onSubmit={() => redirectToDetail(materialId)}
                  />
                );
              })}
            </div>
            <div className="error-text">{unlockError}</div>
          </>
        )}
      </>
    );
  };

  function closeModal() {
    setSelectedSlot(0);
    setUnlockError("");
    setDisableUnlock(false);
    dispatch(setGlobalModal("runeEquipmentModal"));
  }
  return (
    <Modal isActive={runeEquipmentModal.active} onClose={closeModal} layer={1}>
      <BasicModalContent
        customTitle={
          <>
            <div>Manage Runes For</div>
            <div>
              {currentAttributes?.value}:{" "}
              <span className={`body-part__rarity rarity-${currentAttributes?.displayRarity.toLowerCase()} fs-4`}>
                {currentAttributes?.displayRarity}
              </span>
            </div>
          </>
        }
        submitText="Unlock"
        onSubmit={isUnlockRune ? handleUnlockRune : undefined}
        isSubmitDisabled={disableUnlock}
        close={closeModal}
        content={renderRuneEquipmentContent()}
      />
    </Modal>
  );
}
