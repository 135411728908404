import { useEffect, useState } from "react";
import { BASE_BLACKSMITH_EXP, MAX_BLACKSMITH_LEVEL } from "src/app/configs/constants";
import { useSelector } from "react-redux";
import { BlacksmithData } from "src/app/types/common";
import { fetchBlacksmithData } from "src/app/services/api/faralandService";

export default function useBlacksmithData(account: string) {
  const { web3Service } = useSelector((state: any) => state.global);
  const [blacksmithData, setBlacksmithData] = useState<BlacksmithData>({
    exp: 0,
    level: 0,
    pity: {
      common: 0,
      uncommon: 0,
      rare: 0,
      epic: 0,
    },
  });

  useEffect(() => {
    if (!web3Service || !account) return;
    getBlacksmithData(account);
  }, [web3Service, account]); // eslint-disable-line

  async function getBlacksmithData(account: string) {
    const data = await fetchBlacksmithData(account);
    if (data) {
      let remainingExp = data.exp;

      for (let i = data.level + 1; i < MAX_BLACKSMITH_LEVEL; i++) {
        const expRequired = i * BASE_BLACKSMITH_EXP;
        if (remainingExp < expRequired) break;
        remainingExp -= expRequired;
      }

      setBlacksmithData(data);
    }
  }

  return { blacksmithData, setBlacksmithData, getBlacksmithData };
}
