import { Guild, GuildMember, GuildSummaryData, JoinRequest } from "src/app/types/guild";
import guildImage from "src/assets/images/guild/guild-image.png";

export function createGuild(data: any): Guild {
  return {
    id: data.id,
    name: data.name,
    owner: data.owner,
    memberCount: data.memberCount,
    level: data.level,
    totalStrength: data.totalStrength ?? 0,
    image: data.image ?? guildImage,
    desc: data.description 
  };
}

export function createGuildMember(data: any, apiData?: any[], domainNames?: any): GuildMember {
  const memberApiData = apiData?.filter((data) => data.walletAddress === data.id)[0];
  const strength = memberApiData ? memberApiData.strength : data.strength;
  const username = memberApiData ? memberApiData.username : data.username
  return {
    id: data.id,
    guild: data.guild?.id,
    strength: strength ?? 0,
    username: username,
    position: data.position?.toUpperCase(),
    timeJoin: data.joinedAt,
    domainAddress: domainNames ? domainNames[data.id] : ""
  }
}

export function createJoinRequest(data: any, apiData?: any): JoinRequest {
  return {
    guild: data.guild.id,
    member: createGuildMember(data.member, apiData),
    timestamp: data.timestamp
  } 
}

export function createGuildSummary(data: any, topGuild?: Guild): GuildSummaryData {
  if (!data) {
    return {
      totalGuild: 0,
      totalMem: 0
    }
  }

  return {
    totalGuild: data.totalGuilds,
    totalMem: data.totalMems,
    topGuild: topGuild && createGuild(topGuild)
  }
}