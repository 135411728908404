import React, { useEffect, useState } from "react";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { Knight } from "src/app/types/knight";
import { queryOwnedKnights } from "src/app/services/api/subgraphService";
import HeroIcon from "src/app/components/Knight/HeroIcon";
import InputGroup from "src/app/components/Commons/InputGroup";
import { formatNumber, toBigAmount } from "src/app/utils/helpers";
import { minusNumbers, plusNumbers } from "src/app/utils/calculators";
import { distributeExp } from "src/app/actions/stakingAction";
import { Tooltip } from "@material-ui/core";
import infoIcon from "src/assets/images/icons/info-icon-white.svg";

export default function DistributeExpModal() {
  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.account);
  const [ownedHeroes, setOwnedHeroes] = useState<Knight[]>([]);
  const { stakingData } = useSelector((state: any) => state.staking);

  const [searchName, setSearchName] = useState("");
  const [filteredHeroes, setFilteredHeroes] = useState<Knight[]>([...ownedHeroes]);
  const [selectedHeroIds, setSelectedHeroIds] = useState<number[]>([]);
  const [selectedExpAmount, setSelectedExpAmount] = useState<string[]>([]);
  const [remainingExp, setRemainingExp] = useState(stakingData.claimedExp);
  const [balanceError, setBalanceError] = useState("");

  useEffect(() => {
    if (!address) return;
    const getOwnedHeroes = async (address: string) => {
      const heroes = await queryOwnedKnights(address);
      if (heroes) {
        setOwnedHeroes(heroes);
      }
    };
    getOwnedHeroes(address);
  }, [address]);

  useEffect(() => {
    if (!ownedHeroes) {
      setFilteredHeroes([]);
    } else {
      setFilteredHeroes(
        ownedHeroes.filter((value: Knight) => {
          return searchName === "" ? true : value.id.toString().includes(searchName.toLowerCase());
        })
      );
    }
  }, [searchName, ownedHeroes]); // eslint-disable-line

  useEffect(() => {
    setRemainingExp(stakingData.claimedExp);
    if (selectedExpAmount.length === 0) {
      return;
    }
    const totalExp = selectedExpAmount
      .filter((amount) => amount !== "")
      .reduce((prev, cur) => {
        return plusNumbers(prev, cur);
      }, "0");
    setRemainingExp((prev) => (+totalExp !== 0 ? minusNumbers(prev, totalExp) : stakingData.claimedExp));
  }, [selectedExpAmount]); // eslint-disable-line

  function resetSelectedHeroes() {
    setSelectedHeroIds([]);
    setSelectedExpAmount([]);
  }

  function selectHero(heroId: number) {
    setBalanceError("");
    if (selectedHeroIds.includes(heroId)) {
      deselectHero(heroId);
      return;
    }
    setSelectedHeroIds([...selectedHeroIds, heroId]);
    setSelectedExpAmount([...selectedExpAmount, ""]);
  }

  function deselectHero(heroId: number) {
    const index = selectedHeroIds.findIndex((id) => id === heroId);
    if (index !== -1) {
      setSelectedHeroIds([...selectedHeroIds.slice(0, index), ...selectedHeroIds.slice(index + 1)]);
      setSelectedExpAmount([...selectedExpAmount.slice(0, index), ...selectedExpAmount.slice(index + 1)]);
    }
  }

  function handleSearchNameChange(e: any) {
    setBalanceError("");
    setSearchName(e.target.value);
  }

  function handleExpAmountChange(e: any, index: number, amount?: string) {
    setBalanceError("");
    const newArray = [...selectedExpAmount];
    if (amount) {
      newArray.splice(index, 1, amount);
    } else {
      newArray.splice(index, 1, e.target.value);
    }

    setSelectedExpAmount(newArray);
  }

  function distribute() {
    // const invalidAmount = +amount <= 0 || amount.indexOf(".") === 1;
    const invalidAmount = selectedExpAmount.filter((amount) => ["", "0"].includes(amount) || +amount <= 0).length > 0;
    if (remainingExp < 0) {
      setBalanceError("Insufficient EXP");
      return;
    }
    if (invalidAmount) {
      setBalanceError("Invalid EXP amount entered");
      return;
    }
    dispatch(
      distributeExp(
        selectedHeroIds,
        selectedExpAmount.map((amount) => toBigAmount(amount))
      )
    );
  }
  return (
    <BasicModalContent
      submitText="Consume"
      onSubmit={distribute}
      content={
        <div>
          <div className="item-exchange mt-5">
            <div className="item-exchange__wrapper">
              <div className="item-exchange__header">
                <div className="mb-2">
                  <div className="item-exchange__title mb-1">
                    Heroes ({selectedHeroIds.length}){" "}
                    <Tooltip
                      title="Distribute EXP to multiple heroes by selecting the heroes you want to add EXP to"
                      arrow
                      placement="top"
                    >
                      <img className="info-icon" src={infoIcon} alt="" />
                    </Tooltip>
                  </div>
                  <div className="flex-center-start">
                    <div className="btn btn--tiny" onClick={resetSelectedHeroes}>
                      Reset
                    </div>
                  </div>
                </div>
                <div className="icon-input">
                  <SearchIcon className="icon-input__icon" />
                  <input
                    className="icon-input__input"
                    value={searchName}
                    onChange={handleSearchNameChange}
                    type="text"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="item-exchange__storage nice-scroll nice-scroll--small nice-scroll--gray">
                {filteredHeroes.length === 0 && <div>No Hero found...</div>}
                {filteredHeroes.map((knight: Knight, i: number) => {
                  const selected = selectedHeroIds.some((id) => id === knight.id);
                  return (
                    <HeroIcon
                      key={i}
                      hero={knight}
                      selected={selected}
                      onClick={selectHero}
                    />
                  );
                })}
              </div>
            </div>
            <div className="item-exchange__title mr-2 mb-3">DISTRIBUTE EXP </div>
            {selectedHeroIds.length === 0 && <div>Please select a hero first</div>}
            {selectedHeroIds.map((id: number, index: number) => {
              const selectedKnight = ownedHeroes.filter((hero) => hero.id === id)[0];
              const raceImage = require(`src/assets/images/icons/races/${selectedKnight.race.toLowerCase()}-${selectedKnight.gender.toLowerCase()}.png`);

              return (
                <InputGroup
                  key={index}
                  className="text-field--large mb-4"
                  value={selectedExpAmount[index] || ""}
                  handleChange={(e, amount) => handleExpAmountChange(e, index, amount)}
                  error={""}
                  tokenImage={raceImage}
                  label={`#${id} EXP Distribution`}
                  maxAmount={remainingExp}
                  hideBalance
                  hasRaceIcon
                />
              );
            })}
            <div className="mt-1 fs-2">
              <div>
                <span>Current EXP Balance:</span>
                <span>
                  {" "}
                  <span className={`${remainingExp < 0 ? "error-text" : "success-text"}`}>
                    {formatNumber(remainingExp, 4)}
                  </span>{" "}
                  EXP
                </span>
              </div>
            </div>

            {balanceError && <div className="error-text">{balanceError}</div>}
          </div>
        </div>
      }
    />
  );
}
