import React, { useState } from "react";
import { KNIGHT_TYPE, STATS_LABEL } from "src/app/configs/constants";
import { KnightStats } from "src/app/types/knight";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch } from "react-redux";
import { increaseStats } from "src/app/actions/knightAction";
import KnightStatsDetailsModal from "src/app/components/Knight/Details/KnightStatsDetailsModal";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import StatsAllocationModal from "src/app/components/Commons/Modals/StatsAllocationModal";
import { getTotalPrimaryStats } from "src/app/utils/helpers";

type LevelUpModalProps = {
  slotItems: number[];
  knightStats: KnightStats;
  isDemiKnight: boolean;
  goToStaking?: any;
};

export default function LevelUpModal(props: LevelUpModalProps) {
  const dispatch = useDispatch();
  const { knightStats, goToStaking, isDemiKnight, slotItems } = props;
  const totalPrimaryStats = getTotalPrimaryStats(knightStats);
  const [levelPoints, setLevelPoints] = useState(knightStats.availablePoints);
  const [strPoints, setStrPoints] = useState(0);
  const [agiPoints, setAgiPoints] = useState(0);
  const [intPoints, setIntPoints] = useState(0);
  const [lukPoints, setLukPoints] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const noStatPoint = knightStats.availablePoints === 0;

  async function allocateStats(data) {
    if (levelPoints === knightStats.availablePoints) {
      setErrorMessage("Please allocate your stat points first.");
      return;
    }

    setErrorMessage("");
    if (isDemiKnight) {
      dispatch(increaseStats(knightStats.id, KNIGHT_TYPE.DEMI, data));
    } else {
      dispatch(increaseStats(knightStats.id, KNIGHT_TYPE.IMMORTAL, data));
    }
  }

  function changeStats(primaryStat: string, isIncrement: boolean) {
    const levelPoint = isIncrement ? -1 : 1;
    const statPoint = isIncrement ? 1 : -1;

    setErrorMessage("");
    setLevelPoints(levelPoints + levelPoint);

    switch (primaryStat.toUpperCase()) {
      case STATS_LABEL.STR:
        setStrPoints(strPoints + statPoint);
        break;
      case STATS_LABEL.AGI:
        setAgiPoints(agiPoints + statPoint);
        break;
      case STATS_LABEL.INT:
        setIntPoints(intPoints + statPoint);
        break;
      default:
        setLukPoints(lukPoints + statPoint);
        break;
    }
  }

  function renderContent() {
    return (
      <div className="knight-stats__level-up">
        <div className="knight-stats__level-up-stats">
          <KnightStatsDetailsModal
            slotItems={slotItems}
            knightStats={{
              ...knightStats,
              allocatedStats: {
                str: knightStats.allocatedStats.str + strPoints,
                agi: knightStats.allocatedStats.agi + agiPoints,
                int: knightStats.allocatedStats.int + intPoints,
                luk: knightStats.allocatedStats.luk + lukPoints,
              },
            }}
          />
        </div>
        <div className="knight-stats__level-up-points">
          {Object.keys(knightStats.baseStats).map((primaryStat: any, index: number) => {
            const stat = _mapStatsToCounter(primaryStat);
            const isDisabled = stat === totalPrimaryStats[primaryStat];

            return (
              <div key={index} className="flex-center-center mt-4">
                <div style={{ width: 32 }}>{primaryStat.toUpperCase()}:</div>
                <div className="ml-2 flex-center-start">
                  <div
                    className={`btn knight-preview__wrapper-btn ${isDisabled && "disabled"}`}
                    onClick={() => changeStats(primaryStat, false)}
                  >
                    <RemoveIcon />
                  </div>
                  <div style={{ width: 50, textAlign: "center" }}>{stat}</div>
                  <div
                    className={`btn knight-preview__wrapper-btn no-margin ${!levelPoints && "disabled"}`}
                    onClick={() => changeStats(primaryStat, true)}
                  >
                    <AddIcon />
                  </div>
                </div>
              </div>
            );
          })}
          <div className="mt-6 flex-center-center text-9">Stat Points: {levelPoints}</div>
          {noStatPoint && !isDemiKnight && (
            <div className="basic-modal__text-center mt-2 text-gray">
              <span>Stake your FARA to level up and gain some stats.&nbsp;</span>
              <span className="link-text" onClick={goToStaking}>
                Click here
              </span>
            </div>
          )}
          {errorMessage && <div className="mt-2 flex-center-center error-text">{errorMessage}</div>}
        </div>
      </div>
    );
  }

  function _mapStatsToCounter(key: string) {
    switch (key.toUpperCase()) {
      case STATS_LABEL.STR:
        return totalPrimaryStats.str + strPoints;
      case STATS_LABEL.AGI:
        return totalPrimaryStats.agi + agiPoints;
      case STATS_LABEL.INT:
        return totalPrimaryStats.int + intPoints;
      default:
        return totalPrimaryStats.luk + lukPoints;
    }
  }

  const openStatsAllocationModal = () => {
    modalService.show(StatsAllocationModal, { className: "level-up slide-up", layer: 3 });
  };

  return (
    <BasicModalContent
      className="title-center"
      customTitle="Increase Stats"
      isSubmitDisabled={noStatPoint}
      content={(
        <div className="text-center">
          <div className="btn btn--small mb-3" onClick={openStatsAllocationModal}>
            Show Stats Info
          </div>
          {renderContent()}
        </div>
      )}
      onSubmit={() => {
        allocateStats({
          STR: strPoints,
          AGI: agiPoints,
          INT: intPoints,
          LUK: lukPoints,
        });
      }}
    />
  );
}
