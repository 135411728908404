import * as React from "react";
import crystal from "src/assets/images/decors/gems/gem-purple.svg";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
import heroSpread from "src/assets/images/instructions/hero-spread.png";
import Title from "../Commons/Title";
import buyBtn from "src/assets/images/instructions/buy-btn.png";
import { ROUTE } from "src/app/configs/constants";

const HeroLineup = () => {
  return (
    <div className="instructions-lineup">
      <FloatingCrystal speed={5} src={crystal} style={{ left: 0, top: 5500, zIndex: -1 }} />
      <Title value="Hero Line Up" style={{ paddingBottom: "20px" }} />
      <div className="instructions-lineup__content">
        Players can send 3 heroes to battle each time they fight to form a team with complementary abilities, so it is
        critical to understand the characteristics and strengths of each race
      </div>
      <img className="instructions-lineup__image" src={heroSpread} alt="" />
      <div className="instructions-lineup__btn">
        <a href={ROUTE.HEROES} target="_blank" rel="noreferrer noopener">
          <img src={buyBtn} alt="" />
        </a>
      </div>
    </div>
  );
};

export default HeroLineup;
