import React, { useState } from "react";
import type { FC } from "react";
import clsx from "clsx";
import { Box, Container, Grid, Avatar, withWidth, WithWidthProps } from "@material-ui/core";
import { heroes } from "src/app/components/Home/DemoCharacters/Hero/Heroes";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
import crystal from "src/assets/images/decors/gems/gem-fragments.svg";
import buyHeroButtonImage from "src/assets/images/home/characters/buy-hero-button.png";
import buyDemiButtonImage from "src/assets/images/home/characters/buy-demi-button.png";
import CharacterDescription from "src/app/components/Home/DemoCharacters/CharacterDescription";
import CharacterImages from "src/app/components/Home/DemoCharacters/CharacterImages";
import { ROUTE } from "src/app/configs/constants";

type Props = WithWidthProps;

const DemoCharacters: FC<Props> = () => {
  const [activeHeroIndex, setActiveHeroIndex] = useState(0);

  const onSelectHero = (idx: number) => {
    if (idx < 0 || idx > heroes.length - 1) {
      return;
    }
    setActiveHeroIndex(idx);
  };

  const activeHeroRace = heroes[activeHeroIndex].race.toLowerCase();

  return (
    <div className="heroes">
      <Container maxWidth="lg">
        <FloatingCrystal percentage={1} speed={-2} src={crystal} style={{ right: 25, top: 100 }} />
        <div className="heroes__title">CHARACTERS</div>

        <div className="heroes__description">
          NFT Hero: Owners of the Faraland world, with each race unique in powers and classes as well as immensely
          strong when teamed up. The poopulation of Faraland is only 20,000 heroes, with more powers as they originate
          from rarer races!
          <br /> <br />
          Demi Heroes: Cross-dimensional heroes trapped in Faraland. They take the form and features of NFT Heroes to
          fit in, but has a limited lifespan. To extend their lives, Demis have to consume "Soul Stones," which help
          them adapt environmental differences in this dimension.
        </div>

        <div className="heroes__buy">
          <a href={ROUTE.HEROES} target="_blank" rel="noopener noreferrer">
            <img src={buyHeroButtonImage} className="home__button" alt="" />
          </a>
          <a href={ROUTE.DEMI} target="_blank" rel="noopener noreferrer">
            <img src={buyDemiButtonImage} className="home__button" alt="" />
          </a>
        </div>

        <div className="overflow-hidden">
          <Box mt={3} style={{ position: "relative", zIndex: 3 }}>
            <div className="heroes__divider" />
            <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={2}>
              <div className="heroes__thumbnail">
                {heroes.map((h, idx) => (
                  <Avatar
                    key={idx}
                    onClick={() => onSelectHero(idx)}
                    src={h.thumbnail}
                    sizes="lg"
                    className={clsx(
                      "heroes__thumbnail-icon",
                      idx === activeHeroIndex && "heroes__thumbnail-icon--active"
                    )}
                  />
                ))}
              </div>
            </Box>
            <div className="heroes__divider" />
          </Box>
          <Box mt={2} display="flex" alignItems="center" className="heroes__container">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6} style={{ position: "relative" }}>
                <CharacterImages index={activeHeroIndex} />
                <div style={{ position: "absolute", bottom: -40, zIndex: 3, left: 0, right: 0 }}>
                  <div className="heroes__chibi" key={activeHeroIndex}>
                    <img
                      alt="male"
                      className={`heroes__chibi-${activeHeroRace} heroes__chibi-${activeHeroRace}--male`}
                      src={require(`src/assets/images/home/characters/${activeHeroRace}/male.gif`)}
                    />
                    <img
                      alt="female"
                      className={`heroes__chibi-${activeHeroRace} heroes__chibi-${activeHeroRace}--female`}
                      src={require(`src/assets/images/home/characters/${activeHeroRace}/female.gif`)}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="heroes__desc">
                <Box className="heroes__desc-wrapper">
                  <CharacterDescription index={activeHeroIndex} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <div className="heroes__divider" />
        </div>
      </Container>
    </div>
  );
};

export default withWidth()(DemoCharacters);
