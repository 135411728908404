import React, { memo, useState, useEffect } from "react";
import { useQueryString } from "use-route-as-state";
import { getHeroFilterFromParams } from "src/app/utils/filterHelper";

interface toggleProps {
  isLoading: boolean,
  showOwned: boolean,
  showStaked: boolean,
  showOffering: boolean,
}

const KnightListToggle = memo((props: toggleProps) => {
  const [queryParams, setQueryParams] = useQueryString();
  const [heroFilters, setHeroFilters] = useState<any>(getHeroFilterFromParams(queryParams))
  const offering = heroFilters.offering;
  const owned = heroFilters.owned;
  const staked = heroFilters.staked;

  useEffect(() => {
    const newFilters = getHeroFilterFromParams(queryParams);
    setHeroFilters({...newFilters});
  }, [queryParams]);

  function handleToggleChange(owned: boolean, staked: boolean, offering: boolean) {
    return setQueryParams({
      ...queryParams,
      owned: owned.toString(),
      staked: staked.toString(),
      offering: offering.toString()
    });
  }

  return (
    <div className={`market__toggles mb-3 ${props.isLoading ? 'soft-disabled' : ''}`}>
      {props.showOffering && (
        <div className='toggle mr-3'>
          <div>Offers</div>
          <input
            id="offering"
            type="checkbox"
            checked={offering}
            onChange={() => handleToggleChange(false, false, !offering)}
          />
          <label htmlFor="offering" />
        </div>
      )}

      {props.showOwned && (
        <div className='toggle mr-3'>
          <div>Owned</div>
          <input
            id="owned"
            type="checkbox"
            checked={owned}
            onChange={() => handleToggleChange(!owned, staked, false)}
          />
          <label htmlFor="owned" />
        </div>
      )}

      {props.showStaked && (
        <div className='toggle mr-3'>
          <div>Staked</div>
          <input
            id="staked"
            type="checkbox"
            checked={staked}
            onChange={() => handleToggleChange(owned, !staked, false)}
          />
          <label htmlFor="staked" />
        </div>
      )}
    </div>
  )
})

export default KnightListToggle