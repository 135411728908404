import React, { useEffect, useState } from "react";
import crystalUseCase from "src/assets/images/instructions/crystal-usecase.png";
import Title from "../Commons/Title";
import pancakeLogo from "src/assets/images/logos/pancakeswap.svg";
import biswapLogo from "src/assets/images/logos/biswap.png";
import raiseFloor from "src/assets/images/instructions/raise-floor.png";
import upgradeEquipment from "src/assets/images/instructions/upgrade-equipment.png";
import buyEquipment from "src/assets/images/instructions/buy-equipment.png";
import levelStake from "src/assets/images/instructions/level-stake.png";
import changeName from "src/assets/images/instructions/change-name.png";
import vote from "src/assets/images/instructions/vote.png";
import trade from "src/assets/images/instructions/trade.png";
import faraStarterStake from "src/assets/images/instructions/farastarter-stake.png";
import craftItem from "src/assets/images/instructions/craft-item.png";
import createGuild from "src/assets/images/instructions/create-guild.png";

const FaraCrystal = () => {
  const usecase = [
    {
      image: raiseFloor,
    },
    {
      image: upgradeEquipment,
    },
    {
      image: buyEquipment,
    },
    {
      image: levelStake,
    },
    {
      image: changeName,
    },
    {
      image: vote,
    },
    {
      image: trade,
    },
    {
      image: faraStarterStake,
    },
    {
      image: craftItem,
    },
    {
      image: createGuild,
    },
  ];
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect((): any => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div className="instructions-crystal">
      <Title value="FaraCrystal Usecase" style={{ paddingBottom: "20px" }} />
      <div className="instructions-lineup__content">Contract Address:</div>
      <div className="instructions-lineup__content">
        <b>BEP20:</b> 0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1
      </div>
      <div className="flex-center-center">
        <a
          className="footer__biswap"
          href="https://exchange.biswap.org/#/swap?outputCurrency=0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={biswapLogo} alt="PancakeSwap" />
          <span>Buy FARA</span>
        </a>
        <a
          className="footer__pancake ml-2"
          href="https://pancakeswap.finance/swap?outputCurrency=0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={pancakeLogo} alt="PancakeSwap" />
          <span>Buy FARA</span>
        </a>
      </div>
      {dimensions.width <= 700 ? (
        <div className="instructions-crystal__usecase">
          {usecase.map((value) => (
            <img src={value.image} alt="" />
          ))}
        </div>
      ) : (
        <img className="instructions-lineup__image" src={crystalUseCase} alt="" />
      )}
    </div>
  );
};

export default FaraCrystal;