import { Box, Container, Grid } from "@material-ui/core";
import React from "react";
import certik from "src/assets/images/icons/security/certik.svg";
import moonpool from "src/assets/images/icons/security/moonpool.svg";
import crystal from "src/assets/images/decors/gems/gem-pink.svg";
import FloatingCrystal from "src/app/components/Home/FloatingCrystal";
// import peckshield from "src/assets/images/icons/security/peckshield.png";

export default function SecuredBy() {
  return (
    <div className="secure">
      <FloatingCrystal speed={3} percentage={0.9} src={crystal} style={{ left: 10, top: 100, zIndex: -1 }} />
      <Container maxWidth="md">
        <div className="secure__title">
          SECURED BY
        </div>
        <Box mt={6}>
          {/*TODO: Change sm to 4 when adding Peckshield */}
          <Grid container spacing={4} className="secure__grid">
            <Grid item xs={12} sm={6} className="secure__grid-item">
              <a
                href={require("src/assets/documents/certik_report.pdf")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  alt="Certik"
                  src={certik}
                  className="secure__grid-item--certik"
                />
              </a>
            </Grid>
            <Grid item xs={12} sm={6} className="secure__grid-item">
              <a
                href={require("src/assets/documents/moonpool_report.pdf")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  alt="Moonpool"
                  src={moonpool}
                  className="secure__grid-item--moonpool"
                />
              </a>
            </Grid>
            {/* <Grid item xs={12} sm={4} className="secure__grid-item">
            <a
                href={require("../../../../assets/documents/moonpool_report.pdf")}
                target="_blank"
                rel="noreferrer noopener"
              >
              <img
                alt="PeckShield"
                src={peckshield}
                className="secure__grid-item--peckshield"
              />
              </a>
            </Grid> */}
            {/* <Grid item xs={6} sm={2} md={2} /> */}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
