import React, { useEffect, useState } from "react";
import BlackMarketSummary from "src/app/components/BlackMarket/BlackMarketSummary";
import { fetchBlackMarket } from "src/app/services/api/faralandService";
import { BlackMarketItem } from "src/app/types/blackMarket";
import * as loadingJson from "src/assets/lotties/cube-loading-2.json";
import { Lottie } from "@crello/react-lottie";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";
import BlackMarketContent from "src/app/components/BlackMarket/BlackMarketContent";
import { useSelector } from "react-redux";
import { ROUTE } from "src/app/configs/constants";

export default function BlackMarket() {
  const [blackMarketItems, setBlackMarketItems] = useState<BlackMarketItem[]>([]);
  const { address } = useSelector((state: any) => state.account);
  const [isLoading, setLoading] = useState(false);
  const { refreshBlackMarket } = useSelector((state: any) => state.market);
  useEffect(() => {
    async function fetchBlackMarketData() {
      const content = await fetchBlackMarket();
      setLoading(true);
      if (content) {
        setBlackMarketItems(content);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
    fetchBlackMarketData();
  }, [address, refreshBlackMarket]);

  return (
    <div className="container slide-up">
      <BlackMarketSummary />
      <div className="market__items--black-market mb-1 fs-4 warning-text">
        Tokens need to be deposited to non-fee wallet first before they appear above
      </div>
      <div className="market__items--black-market fs-4 mb-3">
        <a href={`${ROUTE.WALLET}?owned=true&sortBy=Highest%20Level&page=1`} className="market__links">Click here</a> to deposit them!
      </div>
      {isLoading && (
        <Lottie
          className="market__loading market__loading--marketplace slide-up"
          config={getAnimatedJsonOptions(loadingJson)}
        />
      )}
      {!isLoading && blackMarketItems.length !== 0 && (
        <div className="market__items market__items--black-market slide-up-slow">
          {blackMarketItems.map((item: BlackMarketItem, index: number) => {
            return (
              <div className="market__item" key={index}>
                <BlackMarketContent blackMarketItem={item} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
