import React, { memo, useState, useEffect } from "react";
import { useQueryString } from "use-route-as-state";
import { getMaterialFilterFromParams } from "src/app/utils/filterHelper";
import { MaterialFilterType } from 'src/app/types/materials';

interface MaterialSwitcher {
  isLoading: boolean
}

const MaterialSwitcher = memo((props: MaterialSwitcher) => {
  const [queryParams, setQueryParams] = useQueryString();
  const [materialFilters, setMaterialFilters] = useState<MaterialFilterType>(getMaterialFilterFromParams(queryParams))
  const offering = materialFilters.offering;
  const owned = materialFilters.owned;
  const listings = materialFilters.listings;

  useEffect(() => {
    const newFilters = getMaterialFilterFromParams(queryParams);
    setMaterialFilters({...newFilters});
  }, [queryParams]);

  function handleToggleChange(owned: boolean, offering: boolean, listings: boolean) {
    return setQueryParams({
      ...queryParams,
      owned: owned.toString(),
      offering: offering.toString(),
      listings: listings.toString(),
    });
  }

  return (
    <div className={`market__toggles mb-3 ${props.isLoading ? 'soft-disabled' : ''}`}>
      <div className='toggle mr-3'>
        <div>Offers</div>
        <input
          id="offering"
          type="checkbox"
          checked={offering}
          onChange={() => handleToggleChange(false, !offering, false)}
        />
        <label htmlFor="offering" />
      </div>

      <div className='toggle mr-3'>
        <div>Listings</div>
        <input
          id="listings"
          type="checkbox"
          checked={listings}
          onChange={() => handleToggleChange(false, false, !listings)}
        />
        <label htmlFor="listings" />
      </div>

      <div className='toggle mr-3'>
        <div>Owned</div>
        <input
          id="owned"
          type="checkbox"
          checked={owned}
          onChange={() => handleToggleChange(!owned, false, false)}
        />
        <label htmlFor="owned" />
      </div>
    </div>
  )
})

export default MaterialSwitcher;