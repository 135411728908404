import React, { useState } from "react";
import { ListItem, Item, OfferItem, UserItem } from "src/app/types/equipment";
import EquipmentActionModal from "src/app/components/Equipment/Details/EquipmentActionModal";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { ACTIONS } from "src/app/configs/constants";
import { useSelector } from "react-redux";
import MarketTable from "src/app/components/Commons/MarketTable";

type EquipmentListingTableProps = {
  item: Item;
  ownLists: ListItem[];
  offers: OfferItem[];
  lists: ListItem[];
  userItems: UserItem[];
};

export default function EquipmentListingTable(props: EquipmentListingTableProps) {
  const { lists, offers, item, ownLists, userItems } = props;
  const [myListings, setMyListings] = useState(false);
  const { address } = useSelector((state: any) => state.account);
  const isEmpty = myListings ? ownLists.length === 0 : lists.length === 0;

  function toggleMyListings() {
    setMyListings(!myListings);
  }

  function onOpenPopup(type: number, selectedList?: ListItem) {
    modalService.show(EquipmentActionModal, {
      type: type,
      isEquipmentMarket: true,
      item: item,
      selectedList: selectedList,
      arrayLists: lists,
      arrayOffers: offers,
      ownLists: ownLists,
      items: userItems
    });
  }

  function handleListItem() {
    onOpenPopup(ACTIONS.LIST_EQUIPMENT);
  }

  function renderButtons(row: any) {
    if (address === row.seller) {
      return (
        <div className="btn btn--small btn--yellow" onClick={() => onOpenPopup(ACTIONS.DELIST_EQUIPMENT)}>
          Cancel
        </div>
      )
    } else if (address) {
      return (
        <div className="btn btn--small" onClick={() => onOpenPopup(ACTIONS.BUY_EQUIPMENT, row)}>
          Buy
        </div>
      )
    }
  }

  return (
    <div className={`cm-table ${isEmpty ? "cm-table--empty" : ""}`}>
      <div className="cm-table__header">
        <div className="cm-table__title">Listings</div>
        {address && (
          <div className="flex-center-start bot-6">
            <div className="toggle mr-3">
              <div>My Listings</div>
              <input id="my-listings" type="checkbox" checked={myListings} onChange={toggleMyListings} />
              <label htmlFor="my-listings" />
            </div>
            <div className="btn" onClick={handleListItem}>
              List
            </div>
          </div>
        )}
      </div>
      <MarketTable
        headerTitles={["From", "Amt", "Price", "Time", ""]}
        cellTypes={["address", "amount", "price", "time"]}
        cellKeys={["seller", "amount", "price", "timestamp"]}
        isEmpty={isEmpty}
        isMyOrder={myListings}
        myOrderData={ownLists}
        orderData={lists}
        emptyMessage="No Listing Yet"
        renderBtnActions={renderButtons}
      />
    </div>
  );
}
