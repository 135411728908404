import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GuildIconPreview from "src/app/components/Guild/Common/GuildIconPreview";
import { Guild, GuildMember, JoinRequest } from "src/app/types/guild";
import strengthIcon from "src/assets/images/guild/strength-icon.png";
import { INTERVAL } from "src/app/configs/constants";
import { useDispatch, useSelector } from "react-redux";
import { queryGuildInfo } from "src/app/services/api/subgraphService";
import { joinGuild, leaveGuild, cancelJoinRequest } from "src/app/actions/guildAction";
import JoinRequestTable from "src/app/components/Guild/Details/JoinRequestTable";
import GuildMembersTable from "src/app/components/Guild/Details/GuildMembersTable";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { fetchGuildDetailsFromApi } from "src/app/services/api/faralandService";
import { createGuild } from "src/app/factories/guildFactory";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import GuildOwnershipTransferModal from "src/app/components/Guild/Common/GuildOwnershipTransferModal";
import GuildInfoChangeModal from "src/app/components/Guild/Common/GuildInfoChangeModal";
import { renderBackBtn } from "src/app/utils/renderHelpers";

export const JOIN_REQUEST_STEP = {
  DETAILS: 0,
  JOIN_REQUESTS: 1,
};

export default function GuildDetails() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const fetchingInterval = useRef<any>();
  const dispatch = useDispatch();
  const { web3Service } = useSelector((state: any) => state.global);
  const { address } = useSelector((state: any) => state.account);
  const { refresh } = useSelector((state: any) => state.guild);

  const [guild, setGuild] = useState<Guild>();
  const [isOwner, setIsOwner] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [alreadyGuildMember, setAlreadyGuildMember] = useState(false);
  const [hasJoinRequest, setHasJoinRequest] = useState(false);
  const [members, setMembers] = useState<GuildMember[]>([]);
  const [joinRequests, setJoinRequests] = useState<JoinRequest[]>([]);
  const [joinRequestStep, setJoinRequestStep] = useState(JOIN_REQUEST_STEP.DETAILS);

  const requestActive = joinRequestStep !== JOIN_REQUEST_STEP.DETAILS;

  useEffect(() => {
    if (id && web3Service) {
      fetchGuildData(id);
      fetchGuildDetails(id);

      fetchingInterval.current = setInterval(() => {
        fetchGuildData(id);
      }, INTERVAL.GUILD_DETAILS);
    }

    return () => {
      clearInterval(fetchingInterval.current);
    };
  }, [web3Service, id, address]); // eslint-disable-line

  useEffect(() => {
    if (!web3Service) return;
    checkIfGuildMember(address);
  }, [web3Service, address]); // eslint-disable-line

  useEffect(() => {
    fetchGuildDetails(id);
  }, [refresh]); // eslint-disable-line

  useEffect(() => {
    const isOwner = !!guild && guild.owner.toLowerCase() === address;
    if (!isOwner) {
      setJoinRequestStep(JOIN_REQUEST_STEP.DETAILS);
    }
    setIsOwner(isOwner);
    const isMember =
      !isOwner &&
      members &&
      members.some((member: GuildMember) => {
        return member.id.toLowerCase() === address;
      });
    setIsMember(isMember);
    const joinRequestAvailable = joinRequests.some((request) => request.member.id === address);
    setHasJoinRequest(joinRequestAvailable);
  }, [guild, address, members, joinRequests]);

  async function checkIfGuildMember(address: string) {
    const result = await web3Service.getIsGuildMember(address);
    if (+result !== -1) {
      setAlreadyGuildMember(true);
    } else {
      setAlreadyGuildMember(false);
    }
  }

  async function fetchGuildData(id: string) {
    try {
      const res = await queryGuildInfo(id);
      if (!res) return;
      setMembers(res.members);
      setJoinRequests(res.joinRequests);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchGuildDetails(id: string) {
    try {
      const res = await fetchGuildDetailsFromApi(id);
      if (!res.success) return;
      const guildFromApi = createGuild(res.data);
      setGuild(guildFromApi);
    } catch (error) {
      console.log(error);
    }
  }

  function onOpenGuildInfoModal() {
    modalService.show(GuildInfoChangeModal, { guildId: id, guildImg: guild?.image, guildDesc: guild?.desc });
  }

  function onOpenTransferPopup() {
    modalService.show(GuildOwnershipTransferModal, { guildId: id, members: members });
  }
  const handleJoin = () => {
    dispatch(joinGuild(+id));
  };

  const handleCancelJoinRequest = () => {
    dispatch(cancelJoinRequest(+id));
  };

  const handleLeave = () => {
    dispatch(leaveGuild(+id));
  };

  return (
    <div className="guild-details">
      <div className="container slide-up">
        {renderBackBtn(history)}
        <div className="guild-details__container">
          <GuildIconPreview guild={guild} largeImage />
          <div className="guild-details__flex">
            <div className="guild-details__content">
              <div className="guild-details__content--name">{guild?.name}</div>
              <div className="flex">
                <div className="guild-details__content--level">
                  Level: <span className="success-text">{guild?.level}</span>
                </div>
                <div className="guild-details__content--strength">
                  Strength:{" "}
                  <span className="success-text">
                    <img src={strengthIcon} alt="Strength Icon" /> {guild?.totalStrength}
                  </span>
                </div>
                <div className="guild-details__content--members">
                  Members: <span className="success-text">{guild?.memberCount}</span>
                </div>
              </div>
              <div className="guild-details__content--desc">{guild?.desc}</div>
            </div>
            {isOwner ? (
              <div className="guild-details__buttons">
                <div
                  className={`btn`}
                  onClick={() =>
                    setJoinRequestStep(requestActive ? JOIN_REQUEST_STEP.DETAILS : JOIN_REQUEST_STEP.JOIN_REQUESTS)
                  }
                >
                  {!requestActive ? (
                    <>View Join Requests {joinRequests.length > 0 && <span>{joinRequests.length}</span>}</>
                  ) : (
                    <>Back To Guild</>
                  )}
                </div>
                <div className="btn" onClick={onOpenGuildInfoModal}>
                  Change Guild Info
                </div>
                <div className="btn" onClick={onOpenTransferPopup}>
                  Transfer Ownership
                </div>
              </div>
            ) : isMember ? (
              <div className="guild-details__buttons">
                <div className="btn btn--red btn-small" onClick={handleLeave}>
                  <div className="flex-center-start">
                    <p>Leave Guild</p>
                    <ExitToAppIcon className="ml-1 bot-1" />
                  </div>
                </div>
              </div>
            ) : (
              <div className={`guild-details__buttons`}>
                {hasJoinRequest ? (
                  <div className={`btn btn-small`} onClick={handleCancelJoinRequest}>
                    Cancel Join Request
                  </div>
                ) : (
                  <div className={`btn btn-small ${alreadyGuildMember && "disabled"}`} onClick={handleJoin}>
                    Ask to Join
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {joinRequestStep === JOIN_REQUEST_STEP.JOIN_REQUESTS ? (
          <JoinRequestTable guildId={id} />
        ) : (
          <GuildMembersTable isOwner={isOwner} />
        )}
      </div>
    </div>
  );
}
