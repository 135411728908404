import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS, TOPIC } from "src/app/configs/constants";
import { _sendTx } from "src/app/sagas/index";
import { guildActionTypes } from "src/app/actions/guildAction";

function* createGuild(action: any): any {
  yield call(_sendTx, ACTIONS.CREATE_GUILD, action.payload, action.callbacks.onSuccess, "0", [TOPIC.GUILD]);
}

function* joinGuild(action: any): any {
  yield call(_sendTx, ACTIONS.JOIN_GUILD, action.payload, action.callbacks.onSuccess);
}

function* approveMembers(action: any): any {
  yield call(_sendTx, ACTIONS.APPROVE_MEMBERS, action.payload, action.callbacks.onSuccess);
}

function* rejectMembers(action: any): any {
  yield call(_sendTx, ACTIONS.REJECT_MEMBERS, action.payload, action.callbacks.onSuccess);
}

function* kickMembers(action: any): any {
  yield call(_sendTx, ACTIONS.KICK_MEMBERS, action.payload, action.callbacks.onSuccess);
}

function* leaveGuild(action: any): any {
  yield call(_sendTx, ACTIONS.LEAVE_GUILD, action.payload, action.callbacks.onSuccess);
}

function* changeGuildName(action: any): any {
  yield call(_sendTx, ACTIONS.CHANGE_GUILD_NAME, action.payload, action.callbacks.onSuccess);
}

function* transferGuildOwnership(action: any): any {
  yield call(_sendTx, ACTIONS.TRANSFER_GUILD_OWNERSHIP, action.payload, action.callbacks.onSuccess);
}

function* cancelJoinRequest(action: any): any {
  yield call(_sendTx, ACTIONS.CANCEL_JOIN_REQUEST, action.payload, action.callbacks.onSuccess);
}

export default function* guildWatcher() {
  yield takeLatest(guildActionTypes.CREATE_GUILD, createGuild);
  yield takeLatest(guildActionTypes.JOIN_GUILD, joinGuild);
  yield takeLatest(guildActionTypes.APPROVE_MEMBERS, approveMembers);
  yield takeLatest(guildActionTypes.REJECT_MEMBERS, rejectMembers);
  yield takeLatest(guildActionTypes.KICK_MEMBERS, kickMembers);
  yield takeLatest(guildActionTypes.LEAVE_GUILD, leaveGuild);
  yield takeLatest(guildActionTypes.CHANGE_NAME, changeGuildName);
  yield takeLatest(guildActionTypes.TRANSFER, transferGuildOwnership);
  yield takeLatest(guildActionTypes.CANCEL_JOIN_REQUEST, cancelJoinRequest);
}
