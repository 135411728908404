import React from "react";
import bodyPartsImg from "src/assets/images/instructions/body-parts.png";
import Title from "../Commons/Title";
import common from "src/assets/images/instructions/common.png";
import uncommon from "src/assets/images/instructions/uncommon.png";
import rare from "src/assets/images/instructions/rare.png";
import epic from "src/assets/images/instructions/epic.png";
import legendary from "src/assets/images/instructions/legendary.png";
import mythical from "src/assets/images/instructions/mythical.png";

const BodyParts = () => {
  const rankings = [
    {
      rank: common,
    },
    {
      rank: uncommon,
    },
    {
      rank: rare,
    },
    {
      rank: epic,
    },
    {
      rank: legendary,
    },
    {
      rank: mythical,
    },
  ];

  return (
    <div className="instructions-parts slide-up">
      <Title value="Body Part Ranking" style={{ paddingBottom: "20px" }} />
      <div className="instructions-parts__desc">A hero has a minimum of four and a maximum of six body parts.</div>
      <div className="instructions-parts__desc mt-1 mb-5">
        Body parts ranking indicates the rarity of Faraland heroes.
      </div>
      <img className="instructions-parts__image" src={bodyPartsImg} alt="" />
      <div className="instructions-parts__ranking">
        {rankings.map((value, index) => (
          <img src={value.rank} key={index} alt="" />
        ))}
      </div>
    </div>
  );
};

export default BodyParts;
