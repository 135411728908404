import React, { useRef, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core";
import { useEffect } from "react";
import _ from "lodash";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import { ClaimHistory } from "src/app/types/equipment";
import { INTERVAL, LIMIT } from "src/app/configs/constants";
import { Pagination } from "@material-ui/lab";
import { checkReclaimable, fetchClaimHistory } from "src/app/services/api/faralandService";
import { useDispatch, useSelector } from "react-redux";
import Web3Service from "src/app/services/web3/Web3Service";
import { claimEquipment } from "src/app/actions/equipmentAction";
import { setGlobalModal } from "src/app/actions/globalAction";
import { renderWalletAddress } from "src/app/utils/renderHelpers";
import MaterialIconPreview from "src/app/components/Materials/Commons/MaterialIconPreview";

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#111121",
      color: theme.palette.common.white,
      fontFamily: `"American Captain", sans-serif`,
      textTransform: "uppercase",
      fontWeight: 500,
      fontSize: 18,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

type EquipmentClaimHistoryProps = {
  refresh: boolean;
  handleReclaimableCheck: (value: boolean) => void;
};

export default function EquipmentClaimHistory({ refresh, handleReclaimableCheck }: EquipmentClaimHistoryProps) {
  const dispatch = useDispatch();
  const [claimHistory, setClaimHistory] = useState<ClaimHistory[]>([]);
  const claimHistoryInterval = useRef<any>();
  const checkReclaimableInterval = useRef<any>();
  const { address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const isEmpty = _.isEmpty(claimHistory);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    if (!address || !web3Service) {
      setClaimHistory([]);
      return;
    }
    _fetchClaimHistory(page, address, web3Service);
    claimHistoryInterval.current = setInterval(() => {
      _fetchClaimHistory(page, address, web3Service);
    }, INTERVAL.REFETCH_CRAFT_HISTORY);

    return () => {
      clearInterval(claimHistoryInterval.current);
    };
  }, [page, address, refresh, web3Service]); // eslint-disable-line

  useEffect(() => {
    if (!address || !web3Service) {
      return;
    }
    checkForReclaimable();
    checkReclaimableInterval.current = setInterval(() => {
      checkForReclaimable();
    }, INTERVAL.REFETCH_CRAFT_HISTORY);

    return () => {
      clearInterval(checkReclaimableInterval.current);
    };
  }, [address, refresh, web3Service]); // eslint-disable-line

  useEffect(() => {
    if (total && total < LIMIT.CRAFT_HISTORY) {
      handleChangePage(1);
    }

    const newTotalPage = Math.ceil(total / LIMIT.CRAFT_HISTORY);
    setTotalPage(newTotalPage);

    if (newTotalPage && page > newTotalPage) {
      handleChangePage(1);
    }
  }, [total]); // eslint-disable-line

  const checkForReclaimable = async () => {
    const res = await checkReclaimable(address, web3Service);
    if (res) {
      handleReclaimableCheck(res);
    }
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  async function _fetchClaimHistory(page: number, address, web3Service: Web3Service) {
    const res = await fetchClaimHistory(page, address, web3Service);
    if (res) {
      setClaimHistory(res.craftHistory);
      setTotal(res.total);
    }
  }

  async function handleReclaim(row: ClaimHistory) {
    const itemRewards = row.claimHistoryNonce.map((r) => ({
      id: r.nftId,
      amount: r.quantity,
      NFTType: r.material ? 1 : 0,
      signature: r.signature,
    }));
    dispatch(
      claimEquipment(itemRewards, row.nonce, () => {
        dispatch(setGlobalModal("txTracking"));
        _fetchClaimHistory(page, address, web3Service);
        checkForReclaimable();
      })
    );
  }

  return (
    <div className={`equipment__table item-crafting__history cm-table ${isEmpty ? "cm-table--empty" : ""} mt-10`}>
      <div className="cm-table__header">
        <div className="cm-table__title">Claim History</div>
      </div>
      <Grid container>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>Address</StyledTableCell>
                <StyledTableCell>Items</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty ? (
                claimHistory.map((row, eid) => {
                  if (row.claimHistoryNonce.length === 0) return <StyledTableRow key={eid} />;
                  return (
                    <StyledTableRow key={eid}>
                      <StyledTableCell>
                        {row.owner && renderWalletAddress(row.owner, row.spaceDomain, 4)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="flex-center-start item-crafting__history-row nice-scroll nice-scroll--horizontal">
                          {_.orderBy(_.uniq(row.claimHistoryNonce), ["nftId"]).map((nonce, id) => {
                            return (
                              nonce.item && (
                                <div key={id} className="flex-center-start">
                                  <ItemIconPreview
                                    className="item-crafting__history-image"
                                    item={nonce.item}
                                    showDetails
                                    craftedAmount={nonce.quantity}
                                  />
                                </div>
                              )
                            );
                          })}
                          {_.orderBy(_.uniq(row.claimHistoryNonce), ["id"]).map((nonce, id) => {
                            return (
                              nonce.material && (
                                <div key={id} className="flex-center-start">
                                  <MaterialIconPreview
                                    className="item-crafting__history-image"
                                    material={nonce.material}
                                    showDetails
                                    isRune
                                    withdrawnAmount={nonce.quantity}
                                  />
                                </div>
                              )
                            );
                          })}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>{row.createdAt}</StyledTableCell>
                      <StyledTableCell>
                        {row.reclaimAble && (
                          <div className="btn" onClick={() => handleReclaim(row)}>
                            Reclaim
                          </div>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell className="cm-table__no-record">
                    <img src={require("src/assets/images/icons/empty-bids.svg")} width={60} height={60} alt="" />
                    <div>No History Yet</div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {totalPage > 1 && (
          <div className="market__pagination mt-5">
            <Pagination
              count={totalPage}
              page={page}
              onChange={(_, val) => handleChangePage(val)}
              variant="outlined"
              color="primary"
            />
          </div>
        )}
      </Grid>
    </div>
  );
}
