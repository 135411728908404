import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { approveForAll } from 'src/app/actions/commonAction';

export default function useApprovalForAll(contract: string, operator: string) {
  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const [needApproveForAll, setNeedApproveForAll] = useState(false);

  useEffect(() => {
    async function checkAllowance(address: string) {
      try {
        const approval = await web3Service.fetchIsApproveForAll(contract, address, operator);
        setNeedApproveForAll(!approval);
      } catch (e) {
        console.log(e);
      }
    }

    if (!address || !web3Service) {
      setNeedApproveForAll(false);
      return;
    }

    checkAllowance(address);
  }, [address, web3Service]); // eslint-disable-line

  function sendApproveTx() {
    dispatch(approveForAll(contract, operator, true, onApproveSuccess));
  }

  function onApproveSuccess() {
    setNeedApproveForAll(false);
  }

  return [needApproveForAll, sendApproveTx as any];
}
