import React from "react";
import ENV from "src/app/configs/env";
import { Material } from "src/app/types/materials";
import { RARITY_LABEL } from "src/app/configs/constants";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import MaterialInfo from "src/app/components/Materials/Commons/MaterialInfo";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { useHistory } from "react-router-dom";
import blackMarketTreasures from "src/assets/images/icons/black-market/treasure.png";
import runeImg from "src/assets/images/rune/default-rune.png";
import lockedRuneImg from "src/assets/images/rune/rune-locked.png";

type ItemIconProps = {
  material: Material;
  largeImage?: boolean;
  isMarketplace?: boolean;
  isPreviewPopup?: boolean;
  showAvailable?: boolean;
  showAmount?: boolean;
  onLoad?: any;
  className?: any;
  showDetails?: boolean;
  showTreasureImg?: boolean;
  isModal?: boolean;
  withdrawnAmount?: number;
  isRune?: boolean;
};

export default function MaterialIconPreview({
  material,
  largeImage,
  isMarketplace,
  isPreviewPopup,
  onLoad,
  className,
  showDetails,
  showTreasureImg,
  isModal,
  withdrawnAmount,
  isRune,
}: ItemIconProps) {
  const history = useHistory();
  function handleImageLoad() {
    if (onLoad) {
      onLoad(false);
    }
  }

  function redirectToDetails(materialId: number) {
    history.push(`/material/${materialId}`);
    modalService.close();
  }

  function showMaterialInfoModal(materialId: number) {
    modalService.show(BasicModalContent, {
      content: <MaterialInfo materialId={materialId} />,
      submitText: "Details",
      onSubmit: () => redirectToDetails(materialId),
    });
  }

  return (
    <div
      className={`material-icon__frame material-icon__frame--${RARITY_LABEL[
        material?.rarity ?? 0
      ].toLowerCase()} ${className} ${isMarketplace && "material-icon__frame--marketplace"} ${
        isModal && "material-icon__frame--modal"
      }  ${isRune && "material-icon__frame--rune"}`}
      onClick={() => showDetails && showMaterialInfoModal(material.id)}
    >
      <img
        className={`material-icon__preview ${isMarketplace && "material-icon__preview--marketplace"} ${
          isPreviewPopup && "material-icon__preview--popup"
        } ${isModal && "material-icon__preview--modal"} ${isRune && "material-icon__preview--rune"}`}
        src={
          showTreasureImg
            ? blackMarketTreasures
            : material === undefined
            ? lockedRuneImg
            : material === null
            ? runeImg
            : `${ENV.URL.BASE_IMAGE_URL}/images/material/${largeImage ? "500x500" : "178x178"}/${material.id}.png`
        }
        alt=""
        onLoad={handleImageLoad}
      />
      {withdrawnAmount && <div className="item-icon__amount">x{withdrawnAmount}</div>}
      {material?.quantity && <div className={`material-icon__quantity`}>x{material?.quantity}</div>}
    </div>
  );
}
