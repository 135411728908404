import React from "react";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";
import { CSSProperties } from "react";
import * as animatedJson from "src/assets/lotties/dots-in-move.json";
import fullBannerPlaceholderImage from "src/assets/images/decors/banner-placeholder-full.png";
import Lottie from "react-lottie";
import playNow from "src/assets/images/banners/play-now.png";
import { useDispatch } from "react-redux";
import { setGlobalModal } from "src/app/actions/globalAction";

const jsonOptions = getAnimatedJsonOptions(animatedJson);
const jsonStyles: CSSProperties = {
  width: 300,
  height: 300,
  position: "absolute",
  right: 0,
  left: 0,
  margin: "0 auto",
  top: "-105px",
};

export default function HomeBannerContent() {
  const dispatch = useDispatch();

  const showDownloadModal = () => {
    dispatch(setGlobalModal("downloadModal", { active: true }));
  };

  return (
    <div className="home-banner">
      <img className="home-banner__placeholder" src={fullBannerPlaceholderImage} alt="" />
      <div className="home-banner__lottie">
        <Lottie style={jsonStyles} options={jsonOptions} isClickToPauseDisabled />
      </div>
      <div className="home-banner__button pointer" onClick={showDownloadModal}>
        <img src={playNow} alt="Play Now" />
      </div>
      <div className="progress-bar">
        <div className="progress-bar__info">
          <h4>20000/20000 SUMMONED</h4>
          <h4>100% SUMMONED</h4>
        </div>
        <div className="progress-bar__bar">
          <div/>
        </div>
      </div>
    </div>
  );
}
