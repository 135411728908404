import React from "react";
import * as notFoundJson from "src/assets/lotties/404.json";
import { Lottie } from "@crello/react-lottie";
import { getAnimatedJsonOptions } from "src/app/utils/helpers";

const NotFoundPage = () => {
  return (
    <div className="material align-center slide-up ">
    <Lottie className="market__404" config={getAnimatedJsonOptions(notFoundJson)} />
    <div className="market__404-title">Page Not Found</div>
    </div>
  )
}

export default NotFoundPage;