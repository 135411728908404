import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS } from "src/app/configs/constants";
import { _sendTx } from "src/app/sagas/index";
import { txActionTypes } from "src/app/actions/txAction";

function* approve(action: any): any {
  yield call(_sendTx, ACTIONS.APPROVE, action.payload, action.callbacks.onSuccess);
}

export default function* txWatcher() {
  yield takeLatest(txActionTypes.APPROVE, approve);
}
