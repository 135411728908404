import { call, takeLatest } from "redux-saga/effects";
import { ACTIONS } from "src/app/configs/constants";
import { _sendTx } from "src/app/sagas/index";
import { marketActionTypes } from "src/app/actions/marketAction";

function* list(action: any): any {
  yield call(_sendTx, ACTIONS.LIST, action.payload);
}

function* delist(action: any): any {
  yield call(_sendTx, ACTIONS.DELIST, action.payload);
}

function* offer(action: any): any {
  yield call(_sendTx, ACTIONS.OFFER, action.payload, undefined, action.txValue);
}

function* takeOffer(action: any): any {
  yield call(_sendTx, ACTIONS.TAKE_OFFER, action.payload);
}

function* cancelOffer(action: any): any {
  yield call(_sendTx, ACTIONS.CANCEL_OFFER, action.payload);
}

function* buy(action: any): any {
  yield call(_sendTx, ACTIONS.BUY, action.payload, undefined, action.txValue);
}

export default function* marketWatcher() {
  yield takeLatest(marketActionTypes.LIST, list);
  yield takeLatest(marketActionTypes.DELIST, delist);
  yield takeLatest(marketActionTypes.OFFER, offer);
  yield takeLatest(marketActionTypes.TAKE_OFFER, takeOffer);
  yield takeLatest(marketActionTypes.CANCEL_OFFER, cancelOffer);
  yield takeLatest(marketActionTypes.BUY, buy);
}
