export const tokenActionTypes = {
  DEPOSIT: "TOKEN.DEPOSIT",
  WITHDRAW: "TOKEN.WITHDRAW",
};

export function depositTokens(amount: string, onSuccess: any) {
  return {
    type: tokenActionTypes.DEPOSIT,
    payload: { amount },
    callbacks: { onSuccess },
  };
}

export function withdrawTokens(withdrawData, onSuccess: any) {
  return {
    type: tokenActionTypes.WITHDRAW,
    payload: { withdrawData },
    callbacks: { onSuccess },
  };
}
