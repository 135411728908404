import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { claim, emergencyWithdraw, stake, unstake } from "src/app/actions/stakingAction";
import { formatNumber, toBigAmount } from "src/app/utils/helpers";
import useFetchingAllowance from "src/app/hooks/useFetchingAllowance";
import { unix } from "moment";
import CountUp from "react-countup";
import { STAKING_CONFIG, STAKING_POOL } from "src/app/configs/constants";
import ENV from "src/app/configs/env";
import { compareNumbers } from "src/app/utils/calculators";
import MintExpJarModal from "src/app/components/Commons/Modals/MintExpJarModal";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { STAKING_REWARDS, STAKING_REWARDS_LP } from "src/app/configs/staking/stakingRewards";
import DistributeExpModal from "src/app/components/Commons/Modals/DistributeExpModal";
import { MATERIALS } from "src/app/configs/materials/materials";
import { debounce } from "lodash";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";

export default function useStakingFormData(selectedPool: number, contractAddress: string, lpBalance?: string) {
  const dispatch = useDispatch();
  const { modal } = useSelector((state: any) => state.global);
  const { stakingData } = useSelector((state: any) => state.staking);
  const { balance } = useSelector((state: any) => state.account);

  const isStakingV3 = [STAKING_POOL.SINGLE_V3, STAKING_POOL.LIQUIDITY_V3].includes(selectedPool);
  const isOriginalStaking = [STAKING_POOL.DEPRECATED].includes(selectedPool);
  const isLpStaking = [STAKING_POOL.LIQUIDITY_V2, STAKING_POOL.LIQUIDITY_V3].includes(selectedPool);
  const [stakingAmount, setStakingAmount] = useState("");
  const [unstakingAmount, setUnstakingAmount] = useState("");
  const [stakingError, setStakingError] = useState("");
  const [unstakingError, setUnstakingError] = useState("");
  const [lockedMonths, setLockedMonths] = useState(0);
  const [needApprove, sendApproveTx, addSpendingAmount] = useFetchingAllowance(
    contractAddress,
    isLpStaking ? "lpTokenContract" : "faraContract",
    isLpStaking ? ENV.CONTRACT.LP_TOKEN : ENV.CONTRACT.FARA_TOKEN,
    0
  );
  const debounceAddSpendingAmount = useCallback(debounce(addSpendingAmount, 500), []);

  const knightModal = modal.knightStaking;
  const knight = knightModal.data?.knight ?? {};
  const disableClaimBtn = !+stakingData.expEarned && !+stakingData.tokenEarned && !+stakingData.currentReward;
  const disableUnstakeBtn = !+stakingData.stakedAmount || unix(stakingData.unlockedTime).isAfter();
  const stakingRewards = isLpStaking
    ? STAKING_REWARDS_LP
    : STAKING_REWARDS;

  const tokenSymbol = isLpStaking ? STAKING_CONFIG.LIQUIDITY.SYMBOL : STAKING_CONFIG.SINGLE.SYMBOL;

  useEffect(() => {
    setStakingAmount("");
    setUnstakingAmount("");
    setStakingError("");
    setUnstakingError("");
  }, [knightModal.active]);

  useEffect(() => {
    setLockedMonths(stakingData.lockedMonths);
  }, [stakingData.lockedMonths]);

  function handleStakingAmountChange(e: any, amount?: string) {
    setStakingError("");
    if (amount) {
      setStakingAmount(amount);
      return;
    }
    setStakingAmount(e.target.value);
    debounceAddSpendingAmount(e.target.value ?? 0);
  }

  function handleUnstakingAmountChange(e: any, amount?: string) {
    setUnstakingError("");
    if (amount) {
      setUnstakingAmount(amount);
      return;
    }
    setUnstakingAmount(e.target.value);
  }

  function handleLockTimeChange(e: any) {
    setLockedMonths(e.target.value);
  }

  function handleStake(reward) {
    if (needApprove) {
      sendApproveTx();
      return;
    }
    const tokenBalance = isLpStaking ? lpBalance : balance.FARA;
    if (!stakingAmount || +stakingAmount <= 0) {
      setStakingError("Please enter a valid amount to stake.");
      return;
    } else if (compareNumbers(stakingAmount, tokenBalance) === 1) {
      setStakingError(`Your FARA balance is insufficient.`);
      return;
    } else if (compareNumbers(lockedMonths, 6) === 0 && compareNumbers(stakingAmount, Object.keys(stakingRewards)[0]) === -1) {
      setStakingError(`You need to stake at least ${Object.keys(stakingRewards)[0]} ${isLpStaking ? "FARA-BNB" : "FARA"} if you lock for 6 months`);
      return;
    } else {
      setStakingError("");
    }
    if (stakingData.stakedAmount !== "0" && lockedMonths > stakingData.lockedMonths) {
      modalService.show(BasicModalContent, {
        title: "Lock Time Update Warning",
        content: (
          <>
          <div className="warning-text fs-3">
            By updating the lock time, all the FARA you have staked previously will be locked according to the time you
            have just recently chosen
          </div>
          <div className="error-text fs-3">Are you sure you want to continue?</div>
          </>
        ),
        onSubmit: () => dispatch(stake(knight.id, toBigAmount(stakingAmount), lockedMonths, selectedPool, reward)),
        submitText: "Stake",
        layer: 3,
      });
      return;
    }
    dispatch(stake(knight.id, toBigAmount(stakingAmount), lockedMonths, selectedPool, reward));
  }

  function handleUnstake() {
    if (!(isStakingV3 || isOriginalStaking)) {
      dispatch(emergencyWithdraw(knight.id, selectedPool));
      return;
    }
    if (!unstakingAmount || +unstakingAmount <= 0) {
      setUnstakingError("Please enter a valid amount to unstake");
      return;
    } else if (compareNumbers(unstakingAmount, stakingData.stakedAmount) === 1) {
      setUnstakingError(`Your FARA staked balance is insufficient`);
      return;
    } else {
      setUnstakingError("");
    }
    dispatch(unstake(knight.id, toBigAmount(unstakingAmount), selectedPool));
  }

  function handleClaim() {
    dispatch(claim(knight.id, selectedPool));
  }

  function handleClaimExpJar(eligibleExpJar: string) {
    modalService.show(MintExpJarModal, {
      eligibleExpJar,
      layer: 2,
    });
  }

  function handleDistributeExp() {
    modalService.show(DistributeExpModal, { layer: 2 });
  }

  function renderCountUpNumber(endValue: number) {
    if (endValue === 0) return 0;
    return <CountUp end={endValue} separator="," decimals={4} preserveValue={true} duration={1} />;
  }

  function renderTime({ days, hours, minutes, seconds, completed }: any) {
    if (completed) return <div>Available to Withdraw</div>;
    return (
      <div>
        {days}d {hours}h {minutes}m {seconds}s
      </div>
    );
  }

  function renderTokenRequirementsTooltip() {
    const rewards = Object.entries(stakingRewards);
    return (
      <>
        <div className="item-icon__hover-text">Token Requirements</div>
        <div className="mt-3">The amount of rewards you get will be based on</div>
        <div className="mb-3">the amount staked and the lock time chosen </div>

        <div className="staking-form__tooltip">
          <div>
            <div className="fs-3 mb-2">Lock Time</div>
            {rewards.map(([milestone], index) => (
              <div key={index}>
                <span className="success-text mb-2">{formatNumber(milestone)}</span>+ {tokenSymbol} {"=>"}{" "}
              </div>
            ))}
          </div>
          <div>
            <div className="fs-3 mb-2"> 6 Months</div>
            {rewards.map(([_milestone, reward], index) => (
              <div key={index}>
                <span className="success-text mb-2">{reward.amount}</span> {MATERIALS[reward.id].displayName}
              </div>
            ))}
          </div>
          {/* <div>
            <div className="fs-3 mb-2">12 Months</div>
            {rewards.map(([_milestone, reward], index) => (
              <div key={index}>
                <span className="success-text mb-2">{reward.amount * 2}</span> {MATERIALS[reward.id].displayName}
              </div>
            ))}
          </div> */}
        </div>
      </>
    );
  }
  return {
    stakingData,
    stakingAmount,
    stakingError,
    unstakingAmount,
    unstakingError,
    lockedMonths,
    needApprove,
    disableUnstakeBtn,
    disableClaimBtn,
    balance,
    isStakingV3,
    isLpStaking,
    stakingRewards,
    handleClaim,
    handleStake,
    handleUnstake,
    handleUnstakingAmountChange,
    handleStakingAmountChange,
    handleLockTimeChange,
    renderCountUpNumber,
    renderTime,
    handleClaimExpJar,
    handleDistributeExp,
    renderTokenRequirementsTooltip,
  };
}
