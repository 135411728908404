import React from "react";
import typeAdvantages from "src/assets/images/instructions/type-advantages.png";
import normalImg from "src/assets/images/hero-details/type/normal.png";
import thunderImg from "src/assets/images/hero-details/type/thunder.png";
import grassImg from "src/assets/images/hero-details/type/grass.png";
import iceImg from "src/assets/images/hero-details/type/ice.png";
import waterImg from "src/assets/images/hero-details/type/water.png";
import rockImg from "src/assets/images/hero-details/type/rock.png";
import fireImg from "src/assets/images/hero-details/type/fire.png";
import { Tooltip } from "@material-ui/core";
import { ELEMENT } from "src/app/configs/constants";

const TypeAdvantages = () => {
  const types = [
    { name: ELEMENT.THUNDER, image: thunderImg },
    { name: ELEMENT.WATER, image: waterImg },
    { name: ELEMENT.FIRE, image: fireImg },
    { name: ELEMENT.GRASS, image: grassImg },
    { name: ELEMENT.ICE, image: iceImg },
    { name: ELEMENT.NORMAL, image: normalImg },
    { name: ELEMENT.ROCK, image: rockImg },
  ];

  return (
    <div className="heroes-types">
      <div className="heroes-types__title pb-4">
        <h1>Hero Elements</h1>
      </div>
      <div className="heroes-types__desc">
        Each element has certain advantages and disadvantages against each of its counterfeit.
      </div>
      <div className="pt-6">
        <div className="heroes__divider" />
        <div className="heroes-types__list">
          {types.map((p, idx) => (
            <Tooltip key={idx} title={p.name} arrow placement="top">
              <img src={p.image} style={{ maxWidth: 40, maxHeight: 37 }} alt="" />
            </Tooltip>
          ))}
        </div>
        <div className="heroes__divider" />
      </div>
      <div className="heroes-types__advantages">
        <img src={typeAdvantages} alt="" />
      </div>
    </div>
  );
};

export default TypeAdvantages;
