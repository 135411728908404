import _ from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicModalContent from "src/app/components/Commons/Modals/BasicModalContent";
import ItemIconPreview from "src/app/components/Equipment/Commons/ItemIconPreview";
import MaterialIconPreview from "src/app/components/Materials/Commons/MaterialIconPreview";
import { MATERIALS } from "src/app/configs/materials/materials";
import { buyBlackMarketItem } from "src/app/services/api/faralandService";
import { BlackMarketItem } from "src/app/types/blackMarket";
import { setGlobalModal } from "src/app/actions/globalAction";
import { MATERIAL_IDS } from "src/app/configs/constants";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import ResultContent from "src/app/components/Commons/ResultContent";
import { signAndAuthenticateWallet } from "src/app/utils/helpers";
import { compareNumbers } from "src/app/utils/calculators";
import { blackMarketRefresh } from "src/app/actions/marketAction";

type BlackMarketModalProps = {
  blackMarketItem: BlackMarketItem;
};
export default function BlackMarketModal(props: BlackMarketModalProps) {
  const dispatch = useDispatch();
  const { blackMarketItem } = props;
  const { wallet, address } = useSelector((state: any) => state.account);
  const { balance } = useSelector((state: any) => state.account);
  const { refreshBlackMarket } = useSelector((state: any) => state.market);
  const [buyError, setBuyError] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const isMaterialReward = _.isEmpty(blackMarketItem.itemIds) && !_.isEmpty(blackMarketItem.materialIds);
  const rewards = isMaterialReward ? blackMarketItem.materials : blackMarketItem.items;

  function _compareBalance(item: BlackMarketItem) {
    switch (item.tokenId) {
      case MATERIAL_IDS.KNIGHT_TOKEN:
        return compareNumbers(balance.KNIGHT_TOKEN, item.price);
      case MATERIAL_IDS.ROYAL_TOKEN:
        return compareNumbers(balance.ROYAL_TOKEN, item.price);
    }
  }
  async function buyItem(item: BlackMarketItem) {
    setBuyError("");
    if (item.amount === 0) {
      setBuyError("This item has been sold out. Please wait until the next reset");
      return;
    }
    if (_compareBalance(item) === -1) {
      setBuyError("Insufficient Token Balance");
      return;
    }
    setDisableButton(true);
    setTimeout(() => setDisableButton(false), 10000);
    const res = await buyBlackMarketItem(item.id);
    if (res === 401) {
      const response = await signAndAuthenticateWallet(wallet, address);
      if (response.success) {
        buyItem(item);
      } else {
        dispatch(
          setGlobalModal("error", {
            active: true,
            data: <div>Unable to authenticate your wallet. Please try again</div>,
          })
        );
      }      
      return;
    }
    dispatch(blackMarketRefresh(!refreshBlackMarket));
    if (!res.success) {
      modalService.show(BasicModalContent, {
        content: (
          <ResultContent
            isSuccess={false}
            content={
              <div>
                Failed to exchange{" "}
                <b>
                  <span className="warning-text fs-3">
                    {blackMarketItem.price} {MATERIALS[blackMarketItem.tokenId].displayName}
                  </span>{" "}
                </b>{" "}
                to exchange for items. Better luck next time.
              </div>
            }
          />
        ),
        layer: 3,
      });
      return;
    }
    modalService.show(BasicModalContent, {
      content: (
        <ResultContent
          isSuccess
          content={
            <>
              <h4>
                Congratulations! You have successfully exchanged{" "}
                <b>
                  <span className="warning-text fs-3">
                    {blackMarketItem.price} {MATERIALS[blackMarketItem.tokenId].displayName}
                  </span>{" "}
                </b>
                for these items:
              </h4>
              <div className="black-market__modal-items">
                {rewards &&
                  rewards.map((reward, index) => {
                    return (
                      <div key={index}>
                        {isMaterialReward ? (
                          <MaterialIconPreview isMarketplace material={reward} showDetails />
                        ) : (
                          <ItemIconPreview
                            item={reward}
                            isMarketplace
                            showDetails
                            disableGems
                            showAmount
                            isBlackMarketAmount
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
              <p className="mt-2">
                <b>
                  Please go to{" "}
                  <a className="market__links" href="https://auth.faraland.io" target="_blank" rel="noopener noreferrer">
                    auth.faraland.io
                  </a>{" "}
                  to claim your items
                </b>
              </p>
            </>
          }
        />
      ),
      layer: 3,
    });
  }

  return (
    <BasicModalContent
      submitText="Exchange"
      isSubmitDisabled={disableButton}
      onSubmit={() => buyItem(blackMarketItem)}
      content={
        <div>
          <h3 className="black-market__modal-exchange">
            You will exchange{" "}
            <span className="warning-text">
              {blackMarketItem.price} {MATERIALS[blackMarketItem.tokenId].displayName}
            </span>{" "}
            to get
          </h3>

          <div className="black-market__modal-items">
            {rewards &&
              rewards.map((reward, index) => {
                return (
                  <div key={index}>
                    {isMaterialReward ? (
                      <MaterialIconPreview isMarketplace material={reward} showDetails />
                    ) : (
                      <ItemIconPreview
                        item={reward}
                        isMarketplace
                        showDetails
                        disableGems
                        showAmount
                        isBlackMarketAmount
                      />
                    )}
                  </div>
                );
              })}
          </div>
          <div className="mt-2 align-center">
            Current Balance:{" "}
            <b>
              <span className="warning-text fs-3">
                {balance.KNIGHT_TOKEN} {MATERIALS[MATERIAL_IDS.KNIGHT_TOKEN].displayName}, {balance.ROYAL_TOKEN}{" "}
                {MATERIALS[MATERIAL_IDS.ROYAL_TOKEN].displayName}
              </span>
            </b>
          </div>
          <div className="mt-2 align-center error-text fs-3">{buyError}</div>
        </div>
      }
    />
  );
}
