export const EQUIPMENT_SET = {
  1: {
    id: 1,
    name: "Dazzling Summer",
    itemNames: ["Dazzling Summer Armor", "Dazzling Summer Boots", "Dazzling Summer Gloves", "Dazzling Summer Helmet", "Dazzling Summer Pants", "Dazzling Summer Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 0,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 0,
          "AGI": 1,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 0,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 6,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 3,
          "Physical Defense": 3,
          "STR": 1,
          "AGI": 1,
          "LUK": 1,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 0,
          "Evade": 1,
          "Critical": 2,
          "Speed": 2,
          "Critical Damage": 11,
          "Magical Damage": 0,
          "Magical Defense": 6,
          "Physical Damage": 10,
          "Physical Defense": 5,
          "STR": 1,
          "AGI": 2,
          "LUK": 2,
          "INT": 2,
          "effects": [{ "name": "Knockback", "description": "Attacks have 20% chance to push enemy back 1 tile." }]
        }
      },
    ]
  },

  2: {
    id: 2,
    name: "Summer Solstice",
    itemNames: ["Summer Solstice Armor", "Summer Solstice Boots", "Summer Solstice Gloves", "Summer Solstice Helmet", "Summer Solstice Pants", "Summer Solstice Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 0,
          "Evade": 1,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 1,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 0,
          "AGI": 0,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 0,
          "Evade": 2,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 2,
          "Physical Damage": 5,
          "Physical Defense": 3,
          "STR": 1,
          "AGI": 2,
          "LUK": 1,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 0,
          "Evade": 2,
          "Critical": 2,
          "Speed": 0,
          "Critical Damage": 3,
          "Magical Damage": 0,
          "Magical Defense": 5,
          "Physical Damage": 7,
          "Physical Defense": 5,
          "STR": 1,
          "AGI": 4,
          "LUK": 2,
          "INT": 2,
          "effects": [{ "name": "Slow", "description": "Attacks have 10% chance to reduce enemy Movement Range by 1 for 1 turn." }]
        }
      },
    ]
  },

  3: {
    id: 3,
    name: "Lagan",
    itemNames: ["Lagan Armor", "Lagan Boots", "Lagan Pants", "Lagan Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 25,
          "Evade": 0,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 3,
          "Magical Damage": 10,
          "Magical Defense": 3,
          "Physical Damage": 0,
          "Physical Defense": 0,
          "STR": 1,
          "AGI": 0,
          "LUK": 0,
          "INT": 3,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 39,
          "Evade": 0,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 3,
          "Magical Damage": 25,
          "Magical Defense": 4,
          "Physical Damage": 0,
          "Physical Defense": 0,
          "STR": 1,
          "AGI": 0,
          "LUK": 1,
          "INT": 8,
          "effects": []
        }
      },
    ]
  },

  4: {
    id: 4,
    name: "Phalanx",
    itemNames: ["Phalanx Armor", "Phalanx Boots", "Phalanx Gloves", "Phalanx Helmet", "Phalanx Pants", "Phalanx Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 7,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 0,
          "Physical Damage": 5,
          "Physical Defense": 0,
          "STR": 3,
          "AGI": 0,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 19,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 3,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 8,
          "Physical Defense": 1,
          "STR": 4,
          "AGI": 1,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 31,
          "Evade": 2,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 7,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 36,
          "Physical Defense": 1,
          "STR": 7,
          "AGI": 1,
          "LUK": 1,
          "INT": 1,
          "effects": [{ "name": "Weakness", "description": "Attacks have 20% chance to reduce the enemy's Physical Damage by 5 for 2 turn(s)." }]
        }
      },
    ]
  },

  5: {
    id: 5,
    name: "Unscrutable",
    itemNames: ["Unscrutable Armor", "Unscrutable Boots", "Unscrutable Gloves", "Unscrutable Pants", "Unscrutable Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 7,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 3,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 6,
          "Physical Defense": 1,
          "STR": 0,
          "AGI": 0,
          "LUK": 1,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 20,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 8,
          "Magical Damage": 0,
          "Magical Defense": 2,
          "Physical Damage": 10,
          "Physical Defense": 2,
          "STR": 1,
          "AGI": 1,
          "LUK": 1,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 5,
        stats: {
          "HP": 41,
          "Evade": 2,
          "Critical": 2,
          "Speed": 2,
          "Critical Damage": 15,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 30,
          "Physical Defense": 4,
          "STR": 1,
          "AGI": 2,
          "LUK": 2,
          "INT": 1,
          "effects": []
        }
      },
    ]
  },

  6: {
    id: 6,
    name: "Talhoffer",
    itemNames: ["Talhoffer Armor", "Talhoffer Boots", "Talhoffer Gloves", "Talhoffer Helmet", "Talhoffer Pants", "Talhoffer Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 9,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 0,
          "Magical Damage": 0,
          "Magical Defense": 0,
          "Physical Damage": 8,
          "Physical Defense": 0,
          "STR": 1,
          "AGI": 0,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 16,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 12,
          "Physical Defense": 1,
          "STR": 3,
          "AGI": 1,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 38,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 38,
          "Physical Defense": 1,
          "STR": 6,
          "AGI": 1,
          "LUK": 1,
          "INT": 1,
          "effects": []
        }
      },
    ]
  },

  7: {
    id: 7,
    name: "Acacia",
    itemNames: ["Acacia Armor", "Acacia Boots", "Acacia Gloves", "Acacia Pants", "Acacia Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 10,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 1,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 0,
          "STR": 0,
          "AGI": 0,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 29,
          "Evade": 0,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 4,
          "Magical Defense": 2,
          "Physical Damage": 0,
          "Physical Defense": 0,
          "STR": 1,
          "AGI": 0,
          "LUK": 1,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 5,
        stats: {
          "HP": 59,
          "Evade": 1,
          "Critical": 2,
          "Speed": 0,
          "Critical Damage": 4,
          "Magical Damage": 34,
          "Magical Defense": 2,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 2,
          "AGI": 1,
          "LUK": 2,
          "INT": 3,
          "effects": [{ "name": "Root", "description": "Attacks have 20% chance to Root enemy for 1 turn." }]
        }
      },
    ]
  },

  8: {
    id: 8,
    name: "Ciara",
    itemNames: ["Ciara Armor", "Ciara Boots", "Ciara Gloves", "Ciara Pants", "Ciara Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 7,
          "Evade": 0,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 0,
          "STR": 1,
          "AGI": 0,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 22,
          "Evade": 0,
          "Critical": 3,
          "Speed": 0,
          "Critical Damage": 5,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 4,
          "Physical Defense": 1,
          "STR": 2,
          "AGI": 1,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 5,
        stats: {
          "HP": 44,
          "Evade": 1,
          "Critical": 5,
          "Speed": 1,
          "Critical Damage": 8,
          "Magical Damage": 0,
          "Magical Defense": 6,
          "Physical Damage": 32,
          "Physical Defense": 2,
          "STR": 4,
          "AGI": 1,
          "LUK": 0,
          "INT": 1,
          "effects": [{ "name": "Vanguard Warrior", "description": "If this unit stands alone (no adjacent allies within 1 tile), reduce 15% Physical Damage & 10% Magical Damage taken." }]
        }
      },
    ]
  },

  9: {
    id: 9,
    name: "Oroboros",
    itemNames: ["Oroboros Armor", "Oroboros Boots", "Oroboros Gloves", "Oroboros Helmet", "Oroboros Pants", "Oroboros Sub Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 10,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 1,
          "Magical Damage": 5,
          "Magical Defense": 2,
          "Physical Damage": 0,
          "Physical Defense": 0,
          "STR": 0,
          "AGI": 0,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 30,
          "Evade": 0,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 3,
          "Magical Damage": 10,
          "Magical Defense": 3,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 1,
          "AGI": 1,
          "LUK": 1,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 60,
          "Evade": 1,
          "Critical": 2,
          "Speed": 3,
          "Critical Damage": 7,
          "Magical Damage": 37,
          "Magical Defense": 4,
          "Physical Damage": 0,
          "Physical Defense": 2,
          "STR": 2,
          "AGI": 1,
          "LUK": 2,
          "INT": 4,
          "effects": [{ "name": "Root", "description": "Attacks have 20% chance to Root enemy for 1 turn." }]
        }
      },
    ]
  },

  10: {
    id: 10,
    name: "Cheetara",
    itemNames: ["Cheetara Armor", "Cheetara Boots", "Cheetara Gloves", "Cheetara Helmet", "Cheetara Pants", "Cheetara Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 11,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 0,
          "AGI": 0,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 32,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 5,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 2,
          "Physical Defense": 3,
          "STR": 2,
          "AGI": 1,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 62,
          "Evade": 3,
          "Critical": 3,
          "Speed": 1,
          "Critical Damage": 9,
          "Magical Damage": 0,
          "Magical Defense": 6,
          "Physical Damage": 23,
          "Physical Defense": 5,
          "STR": 3,
          "AGI": 2,
          "LUK": 1,
          "INT": 1,
          "effects": [{ "name": "Lifesteal", "description": "Heal for 15 HP per attack" }]
        }
      },
    ]
  },

  11: {
    id: 11,
    name: "Serenity",
    itemNames: ["Serenity Armor", "Serenity Boots", "Serenity Gloves", "Serenity Helmet", "Serenity Pants", "Serenity Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 8,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 1,
          "Magical Damage": 5,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 0,
          "STR": 0,
          "AGI": 0,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 19,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 2,
          "Magical Damage": 8,
          "Magical Defense": 2,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 1,
          "AGI": 0,
          "LUK": 0,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 48,
          "Evade": 2,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 3,
          "Magical Damage": 32,
          "Magical Defense": 3,
          "Physical Damage": 0,
          "Physical Defense": 2,
          "STR": 2,
          "AGI": 1,
          "LUK": 1,
          "INT": 4,
          "effects": [{ "name": "Absorb", "description": "20% chance to absorb 15 of the inflicted damage and recover as HP." }]
        }
      },
    ]
  },

  12: {
    id: 12,
    name: "Lysandra",
    itemNames: ["Lysandra Armor", "Lysandra Boots", "Lysandra Gloves", "Lysandra Helmet", "Lysandra Pants", "Lysandra Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 11,
          "Evade": 1,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 1,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 5,
          "Physical Defense": 1,
          "STR": 1,
          "AGI": 1,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 33,
          "Evade": 2,
          "Critical": 1,
          "Speed": 2,
          "Critical Damage": 3,
          "Magical Damage": 0,
          "Magical Defense": 4,
          "Physical Damage": 12,
          "Physical Defense": 3,
          "STR": 2,
          "AGI": 1,
          "LUK": 1,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 66,
          "Evade": 2,
          "Critical": 3,
          "Speed": 2,
          "Critical Damage": 6,
          "Magical Damage": 0,
          "Magical Defense": 7,
          "Physical Damage": 30,
          "Physical Defense": 6,
          "STR": 4,
          "AGI": 2,
          "LUK": 1,
          "INT": 1,
          "effects": [{ "name": "Silent", "description": "Attacks have 20% chance to deal 10 extra Physical Damage to enemy and Silent them for 1 turn(s)." }]
        }
      },
    ]
  },

  13: {
    id: 13,
    name: "Legolas",
    itemNames: ["Legolas Armor", "Legolas Boots", "Legolas Gloves", "Legolas Helmet", "Legolas Pants", "Legolas Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 10,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 0,
          "AGI": 0,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 36,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 5,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 3,
          "Physical Defense": 2,
          "STR": 1,
          "AGI": 2,
          "LUK": 1,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 66,
          "Evade": 2,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 10,
          "Magical Damage": 0,
          "Magical Defense": 5,
          "Physical Damage": 21,
          "Physical Defense": 4,
          "STR": 1,
          "AGI": 4,
          "LUK": 1,
          "INT": 0,
          "effects": [{ "name": "Disarm", "description": "Attacks have 20% chance to Disarm enemy that make them cannot use normal attack for 1 turn." }]
        }
      },
    ]
  },

  14: {
    id: 14,
    name: "Wakefield",
    itemNames: ["Wakefield Armor", "Wakefield Boots", "Wakefield Gloves", "Wakefield Helmet", "Wakefield Pants", "Wakefield Weapon"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 35,
          "Evade": 1,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 1,
          "Magical Damage": 3,
          "Magical Defense": 5,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 0,
          "AGI": 1,
          "LUK": 0,
          "INT": 3,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 52,
          "Evade": 2,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 1,
          "Magical Damage": 26,
          "Magical Defense": 8,
          "Physical Damage": 0,
          "Physical Defense": 3,
          "STR": 0,
          "AGI": 2,
          "LUK": 1,
          "INT": 4,
          "effects": [{ "name": "Glacial Feather Wings", "description": "Increase 6 Evade & 2 extra Mana each turn in Snowfield terrain." }]
        }
      },
    ]
  },

  15: {
    id: 15,
    name: "Tiamat",
    itemNames: ["Tiamat Armor", "Tiamat Boots", "Tiamat Gloves", "Tiamat Helmet", "Tiamat Pants", "Tiamat Sub Weapon"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 37,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 1,
          "Magical Damage": 10,
          "Magical Defense": 7,
          "Physical Damage": 0,
          "Physical Defense": 3,
          "STR": 2,
          "AGI": 1,
          "LUK": 1,
          "INT": 3,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 56,
          "Evade": 3,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 2,
          "Magical Damage": 42,
          "Magical Defense": 11,
          "Physical Damage": 0,
          "Physical Defense": 4,
          "STR": 2,
          "AGI": 1,
          "LUK": 1,
          "INT": 4,
          "effects": [{ "name": "Frostheart", "description": "Regenerate 5% HP each turn, plus 1% for each Epic or higher body part in Snowfield terrain." }]
        }
      },
    ]
  },

  16: {
    id: 16,
    name: "Donatella",
    itemNames: ["Donatella Armor", "Donatella Boots", "Donatella Gloves", "Donatella Helmet", "Donatella Pants", "Donatella Weapon"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 31,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 1,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 9,
          "Physical Defense": 1,
          "STR": 2,
          "AGI": 2,
          "LUK": 1,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 46,
          "Evade": 2,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 3,
          "Magical Damage": 0,
          "Magical Defense": 2,
          "Physical Damage": 30,
          "Physical Defense": 3,
          "STR": 2,
          "AGI": 3,
          "LUK": 1,
          "INT": 0,
          "effects": [{ "name": "Snow Huntress", "description": "Increase 1 extra move in Snowfield terrain." }]
        }
      },
    ]
  },

  17: {
    id: 17,
    name: "Yashita",
    itemNames: ["Yashita Armor", "Yashita Boots", "Yashita Gloves", "Yashita Helmet", "Yashita Pants", "Yashita Weapon"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 38,
          "Evade": 1,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 6,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 6,
          "Physical Defense": 3,
          "STR": 2,
          "AGI": 1,
          "LUK": 1,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 55,
          "Evade": 2,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 10,
          "Magical Damage": 0,
          "Magical Defense": 2,
          "Physical Damage": 23,
          "Physical Defense": 4,
          "STR": 4,
          "AGI": 1,
          "LUK": 1,
          "INT": 1,
          "effects": [{ "name": "Frost Brawler", "description": "Increase 15% Physical Attack bonus after taking damage for 2 turn(s) if attacked by Water or Ice magic damage. This effect can not be stacked." }]
        }
      },
    ]
  },

  18: {
    id: 18,
    name: "Antaura",
    itemNames: ["Antaura Armor", "Antaura Boots", "Antaura Gloves", "Antaura Helmet", "Antaura Pants", "Antaura Weapon"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 49,
          "Evade": 3,
          "Critical": 5,
          "Speed": 1,
          "Critical Damage": 12,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 18,
          "Physical Defense": 1,
          "STR": 2,
          "AGI": 2,
          "LUK": 1,
          "INT": 3,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 75,
          "Evade": 2,
          "Critical": 6,
          "Speed": 2,
          "Critical Damage": 17,
          "Magical Damage": 0,
          "Magical Defense": 5,
          "Physical Damage": 69,
          "Physical Defense": 1,
          "STR": 4,
          "AGI": 2,
          "LUK": 1,
          "INT": 3,
          "effects": [{ "name": "Tundra Commander", "description": "All summoned units from allies will be granted with 12% Physical Attack & Physical Defense in Snowfield terrain." }]
        }
      },
    ]
  },

  19: {
    id: 19,
    name: "Frederick",
    itemNames: ["Frederick Armor", "Frederick Boots", "Frederick Gloves", "Frederick Helmet", "Frederick Pants", "Frederick Weapon"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 37,
          "Evade": 3,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 5,
          "Magical Damage": 7,
          "Magical Defense": 5,
          "Physical Damage": 0,
          "Physical Defense": 3,
          "STR": 1,
          "AGI": 2,
          "LUK": 1,
          "INT": 3,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 55,
          "Evade": 2,
          "Critical": 2,
          "Speed": 3,
          "Critical Damage": 8,
          "Magical Damage": 44,
          "Magical Defense": 8,
          "Physical Damage": 0,
          "Physical Defense": 4,
          "STR": 2,
          "AGI": 3,
          "LUK": 1,
          "INT": 5,
          "effects": [{ "name": "Peace Maker", "description": "Heal all other allies for 10 HP with every damage taken. The bonus is 50% effective for summoned units." }]
        }
      },
    ]
  },

  20: {
    id: 20,
    name: "Biswap",
    itemNames: ["Biswap Sub Weapon", "Biswap Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 30,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 0,
          "Magical Damage": 0,
          "Magical Defense": 5,
          "Physical Damage": 0,
          "Physical Defense": 7,
          "STR": 5,
          "AGI": 2,
          "LUK": 0,
          "INT": 2,
          "effects": [{ "name": "Absorb", "description": "10% chance to absorb 5 inflicted damage and recover as HP." }]
        }
      },
    ]
  },

  21: {
    id: 21,
    name: "Hegemon Princess",
    itemNames: ["Hegemon Princess Armor", "Hegemon Princess Boots", "Hegemon Princess Gloves", "Hegemon Princess Helmet", "Hegemon Princess Pants", "Hegemon Princess Weapon"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 89,
          "Evade": 3,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 11,
          "Magical Damage": 8,
          "Magical Defense": 3,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 4,
          "AGI": 1,
          "LUK": 2,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 135,
          "Evade": 4,
          "Critical": 3,
          "Speed": 1,
          "Critical Damage": 15,
          "Magical Damage": 18,
          "Magical Defense": 3,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 7,
          "AGI": 4,
          "LUK": 2,
          "INT": 4,
          "effects": [{ "name": "Silent", "description": "Attacks have 25% chance to deal 30 extra Physical Damage to enemy and Silent them for 1 turn(s)." }]
        }
      },
    ]
  },

  22: {
    id: 22,
    name: "Fretful Devastator",
    itemNames: ["Fretful Devastator Armor", "Fretful Devastator Boots", "Fretful Devastator Gloves", "Fretful Devastator Helmet", "Fretful Devastator Pants", "Fretful Devastator Weapon", "Fretful Devastator Sub Weapon"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 53,
          "Evade": 5,
          "Critical": 3,
          "Speed": 0,
          "Critical Damage": 15,
          "Magical Damage": 0,
          "Magical Defense": 4,
          "Physical Damage": 2,
          "Physical Defense": 5,
          "STR": 0,
          "AGI": 5,
          "LUK": 0,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 7,
        stats: {
          "HP": 80,
          "Evade": 7,
          "Critical": 5,
          "Speed": 2,
          "Critical Damage": 21,
          "Magical Damage": 0,
          "Magical Defense": 6,
          "Physical Damage": 21,
          "Physical Defense": 9,
          "STR": 1,
          "AGI": 8,
          "LUK": 0,
          "INT": 2,
          "effects": [{ "name": "Root", "description": "Attacks have 25% chance to Root enemy for 1 turn." }]
        }
      },
    ]
  },

  23: {
    id: 23,
    name: "Emidst",
    itemNames: ["Emidst Armor", "Emidst Gloves"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 210,
          "Evade": 5,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 0,
          "Magical Damage": 0,
          "Magical Defense": 23,
          "Physical Damage": 0,
          "Physical Defense": 29,
          "STR": 9,
          "AGI": 12,
          "LUK": 10,
          "INT": 5,
          "effects": []
        }
      },
    ]
  },

  24: {
    id: 24,
    name: "Grimfrost",
    itemNames: ["Grimfrost Shield Sub Weapon", "Grimfrost Spear Weapon"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 150,
          "Evade": 0,
          "Critical": 0,
          "Speed": 3,
          "Critical Damage": 0,
          "Magical Damage": 0,
          "Magical Defense": 5,
          "Physical Damage": 25,
          "Physical Defense": 8,
          "STR": 7,
          "AGI": 5,
          "LUK": 0,
          "INT": 3,
          "effects": [{ "name": "Frost Blood", "description": "Immune to all Damage Over Time effects." }]
        }
      },
    ]
  },

  25: {
    id: 25,
    name: "Tribataur",
    itemNames: ["Tribataur Staff Weapon", "Tribataur Armor", "Tribataur Boots", "Tribataur Gloves", "Tribataur Helmet", "Tribataur Pants"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 32,
          "Evade": 0,
          "Critical": 3,
          "Speed": 0,
          "Critical Damage": 7,
          "Magical Damage": 3,
          "Magical Defense": 5,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 2,
          "AGI": 0,
          "LUK": 1,
          "INT": 4,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 48,
          "Evade": 1,
          "Critical": 2,
          "Speed": 0,
          "Critical Damage": 11,
          "Magical Damage": 32,
          "Magical Defense": 9,
          "Physical Damage": 0,
          "Physical Defense": 3,
          "STR": 4,
          "AGI": 1,
          "LUK": 1,
          "INT": 5,
          "effects": [{ "name": "Disarm", "description": "Attacks have 20% chance to Disarm enemy that make them cannot use normal attack for 1 turn." }]
        }
      },
    ]
  },

  26: {
    id: 26,
    name: "Golden Pontinex",
    itemNames: ["Golden Pontinex Spear Weapon", "Golden Pontinex Armor", "Golden Pontinex Boots", "Golden Pontinex Gloves", "Golden Pontinex Helmet", "Golden Pontinex Pants"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 33,
          "Evade": 1,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 1,
          "Magical Damage": 0,
          "Magical Defense": 4,
          "Physical Damage": 3,
          "Physical Defense": 4,
          "STR": 4,
          "AGI": 2,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 49,
          "Evade": 3,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 1,
          "Magical Damage": 0,
          "Magical Defense": 7,
          "Physical Damage": 25,
          "Physical Defense": 6,
          "STR": 5,
          "AGI": 3,
          "LUK": 1,
          "INT": 1,
          "effects": [{ "name": "Golden Warrior", "description": "Reduces 70% Physical and Magical Damage taken when hero is at maximum HP." }]
        }
      },
    ]
  },

  27: {
    id: 27,
    name: "Moon's Elegance",
    itemNames: ["Moons Elegance Weapon", "Moons Elegance Armor", "Moons Elegance Boots", "Moons Elegance Gloves", "Moons Elegance Helmet", "Moons Elegance Pants"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 22,
          "Evade": 0,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 0,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 9,
          "Physical Defense": 5,
          "STR": 2,
          "AGI": 1,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 43,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 0,
          "Magical Damage": 0,
          "Magical Defense": 5,
          "Physical Damage": 40,
          "Physical Defense": 7,
          "STR": 3,
          "AGI": 2,
          "LUK": 0,
          "INT": 2,
          "effects": [{ "name": "Dragon Blood", "description": "Increases Movement Tile by 1 and when the hero is at full HP, increases Physical Damage by 40%." }]
        }
      },
    ]
  },

  28: {
    id: 28,
    name: "Masked Maniac",
    itemNames: ["Masked Maniac Weapon", "Masked Maniac Armor", "Masked Maniac Boots", "Masked Maniac Gloves", "Masked Maniac Helmet", "Masked Maniac Pants"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 31,
          "Evade": 1,
          "Critical": 3,
          "Speed": 1,
          "Critical Damage": 0,
          "Magical Damage": 0,
          "Magical Defense": 5,
          "Physical Damage": 1,
          "Physical Defense": 5,
          "STR": 3,
          "AGI": 2,
          "LUK": 0,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 49,
          "Evade": 3,
          "Critical": 4,
          "Speed": 3,
          "Critical Damage": 0,
          "Magical Damage": 0,
          "Magical Defense": 7,
          "Physical Damage": 14,
          "Physical Defense": 9,
          "STR": 4,
          "AGI": 4,
          "LUK": 0,
          "INT": 3,
          "effects": [{ "name": "Disarm", "description": "Attacks have 20% chance to Disarm enemy that make them cannot use normal attack for 1 turn(s)." }]
        }
      },
    ]
  },

  29: {
    id: 29,
    name: "Honey Comb",
    itemNames: ["Honey Comb Weapon", "Honey Comb Armor", "Honey Comb Boots", "Honey Comb Gloves", "Honey Comb Helmet", "Honey Comb Pants"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 37,
          "Evade": 0,
          "Critical": 3,
          "Speed": 0,
          "Critical Damage": 9,
          "Magical Damage": 2,
          "Magical Defense": 7,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 1,
          "AGI": 1,
          "LUK": 0,
          "INT": 5,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 52,
          "Evade": 0,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 1,
          "Magical Damage": 14,
          "Magical Defense": 10,
          "Physical Damage": 0,
          "Physical Defense": 3,
          "STR": 1,
          "AGI": 1,
          "LUK": 0,
          "INT": 4,
          "effects": [{ "name": "Improve Healing", "description": "Increase healing effects by 25%." }]
        }
      },
    ]
  },

  30: {
    id: 30,
    name: "Potence",
    itemNames: ["Potence Weapon", "Potence Armor", "Potence Boots", "Potence Gloves", "Potence Helmet", "Potence Pants"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 28,
          "Evade": 1,
          "Critical": 3,
          "Speed": 0,
          "Critical Damage": 9,
          "Magical Damage": 3,
          "Magical Defense": 5,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 2,
          "AGI": 1,
          "LUK": 1,
          "INT": 4,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 41,
          "Evade": 1,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 14,
          "Magical Damage": 23,
          "Magical Defense": 7,
          "Physical Damage": 0,
          "Physical Defense": 2,
          "STR": 3,
          "AGI": 1,
          "LUK": 1,
          "INT": 5,
          "effects": [{ "name": "ViperSting", "description": "Attacks apply Poison to enemy, inflicts 50 Pure Damage for 1 turn(s)." }]
        }
      },
    ]
  },

  31: {
    id: 31,
    name: "Piraslaus",
    itemNames: ["Piraslaus Weapon", "Piraslaus Sub Weapon", "Piraslaus Armor", "Piraslaus Boots", "Piraslaus Gloves", "Piraslaus Helmet", "Piraslaus Pants"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 37,
          "Evade": 0,
          "Critical": 3,
          "Speed": 0,
          "Critical Damage": 11,
          "Magical Damage": 0,
          "Magical Defense": 6,
          "Physical Damage": 10,
          "Physical Defense": 3,
          "STR": 4,
          "AGI": 0,
          "LUK": 1,
          "INT": 4,
          "effects": []
        }
      },

      {
        pieces: 7,
        stats: {
          "HP": 50,
          "Evade": 0,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 11,
          "Magical Damage": 0,
          "Magical Defense": 4,
          "Physical Damage": 15,
          "Physical Defense": 5,
          "STR": 5,
          "AGI": 1,
          "LUK": 1,
          "INT": 4,
          "effects": [{ "name": "Absorb", "description": "10% chance to absorb 40 inflicted damage and recover as HP." }]
        }
      },
    ]
  },

  32: {
    id: 32,
    name: "Enigcrypt",
    itemNames: ["Enigcrypt Weapon", "Enigcrypt Sub Weapon", "Enigcrypt Armor", "Enigcrypt Boots", "Enigcrypt Gloves", "Enigcrypt Helmet", "Enigcrypt Pants"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 68,
          "Evade": 0,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 13,
          "Magical Damage": 6,
          "Magical Defense": 9,
          "Physical Damage": 0,
          "Physical Defense": 5,
          "STR": 4,
          "AGI": 2,
          "LUK": 2,
          "INT": 4,
          "effects": []
        }
      },

      {
        pieces: 7,
        stats: {
          "HP": 102,
          "Evade": 0,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 19,
          "Magical Damage": 55,
          "Magical Defense": 14,
          "Physical Damage": 0,
          "Physical Defense": 8,
          "STR": 5,
          "AGI": 2,
          "LUK": 3,
          "INT": 5,
          "effects": [{ "name": "Improve Healing", "description": "Increase healing effects by 10%." }]
        }
      },
    ]
  },

  33: {
    id: 33,
    name: "Astrorius",
    itemNames: ["Astrorius Weapon", "Astrorius Armor", "Astrorius Boots", "Astrorius Gloves", "Astrorius Helmet", "Astrorius Pants"],
    bonuses: [
      {
        pieces: 3,
        stats: {
          "HP": 45,
          "Evade": 0,
          "Critical": 3,
          "Speed": 1,
          "Critical Damage": 11,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 24,
          "Physical Defense": 5,
          "STR": 2,
          "AGI": 2,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 69,
          "Evade": 1,
          "Critical": 2,
          "Speed": 2,
          "Critical Damage": 15,
          "Magical Damage": 0,
          "Magical Defense": 4,
          "Physical Damage": 50,
          "Physical Defense": 7,
          "STR": 4,
          "AGI": 3,
          "LUK": 0,
          "INT": 0,
          "effects": [{ "name": "Beast Penetration", "description": "Pierces Physical Defense of Orc and Dragon by 10%." }]
        }
      },
    ]
  },

  34: {
    id: 34,
    name: "Silent Howl",
    itemNames: ["Silent Howl Weapon", "Silent Howl Sub Weapon", "Silent Howl Armor", "Silent Howl Boots", "Silent Howl Gloves", "Silent Howl Helmet", "Silent Howl Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 17,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 3,
          "Magical Damage": 0,
          "Magical Defense": 2,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 1,
          "AGI": 1,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 51,
          "Evade": 0,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 10,
          "Magical Damage": 7,
          "Magical Defense": 7,
          "Physical Damage": 7,
          "Physical Defense": 4,
          "STR": 3,
          "AGI": 1,
          "LUK": 2,
          "INT": 3,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 102,
          "Evade": 0,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 19,
          "Magical Damage": 62,
          "Magical Defense": 14,
          "Physical Damage": 62,
          "Physical Defense": 8,
          "STR": 5,
          "AGI": 2,
          "LUK": 3,
          "INT": 5,
          "effects": []
        }
      },
    ]
  },

  35: {
    id: 35,
    name: "Kingdom",
    itemNames: ["Kingdom Weapon", "Kingdom Sub Weapon", "Kingdom Armor", "Kingdom Boots", "Kingdom Gloves", "Kingdom Helmet", "Kingdom Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 9,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 1,
          "Magical Damage": 17,
          "Magical Defense": 2,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 0,
          "AGI": 1,
          "LUK": 0,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 28,
          "Evade": 0,
          "Critical": 3,
          "Speed": 1,
          "Critical Damage": 9,
          "Magical Damage": 24,
          "Magical Defense": 5,
          "Physical Damage": 0,
          "Physical Defense": 2,
          "STR": 2,
          "AGI": 1,
          "LUK": 1,
          "INT": 5,
          "effects": []
        }
      },

      {
        pieces: 7,
        stats: {
          "HP": 56,
          "Evade": 0,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 5,
          "Magical Damage": 50,
          "Magical Defense": 8,
          "Physical Damage": 0,
          "Physical Defense": 5,
          "STR": 4,
          "AGI": 2,
          "LUK": 1,
          "INT": 7,
          "effects": []
        }
      },
    ]
  },

  36: {
    id: 36,
    name: "Encircled",
    itemNames: ["Encircled Weapon", "Encircled Armor", "Encircled Boots", "Encircled Gloves", "Encircled Helmet", "Encircled Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 12,
          "Evade": 1,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 3,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 0,
          "AGI": 1,
          "LUK": 1,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 35,
          "Evade": 2,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 8,
          "Magical Damage": 2,
          "Magical Defense": 4,
          "Physical Damage": 2,
          "Physical Defense": 3,
          "STR": 0,
          "AGI": 9,
          "LUK": 5,
          "INT": 5,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 71,
          "Evade": 2,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 11,
          "Magical Damage": 18,
          "Magical Defense": 9,
          "Physical Damage": 18,
          "Physical Defense": 6,
          "STR": 0,
          "AGI": 13,
          "LUK": 8,
          "INT": 8,
          "effects": []
        }
      },
    ]
  },

  37: {
    id: 37,
    name: "Rampaging Fury",
    itemNames: ["Rampaging Fury Weapon", "Rampaging Fury Sub Weapon", "Rampaging Fury Armor", "Rampaging Fury Boots", "Rampaging Fury Gloves", "Rampaging Fury Helmet", "Rampaging Fury Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 18,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 3,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 0,
          "Physical Defense": 5,
          "STR": 1,
          "AGI": 1,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 51,
          "Evade": 0,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 10,
          "Magical Damage": 7,
          "Magical Defense": 7,
          "Physical Damage": 7,
          "Physical Defense": 6,
          "STR": 3,
          "AGI": 1,
          "LUK": 2,
          "INT": 3,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 102,
          "Evade": 0,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 19,
          "Magical Damage": 65,
          "Magical Defense": 14,
          "Physical Damage": 65,
          "Physical Defense": 8,
          "STR": 5,
          "AGI": 2,
          "LUK": 3,
          "INT": 5,
          "effects": []
        }
      },
    ]
  },

  38: {
    id: 38,
    name: "Elven Grove",
    itemNames: ["Elven Grove Weapon", "Elven Grove Sub Weapon", "Elven Grove Armor", "Elven Grove Boots", "Elven Grove Gloves", "Elven Grove Helmet", "Elven Grove Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 15,
          "Evade": 1,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 3,
          "Magical Damage": 0,
          "Magical Defense": 4,
          "Physical Damage": 0,
          "Physical Defense": 2,
          "STR": 1,
          "AGI": 0,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 44,
          "Evade": 2,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 9,
          "Magical Damage": 2,
          "Magical Defense": 10,
          "Physical Damage": 2,
          "Physical Defense": 3,
          "STR": 2,
          "AGI": 0,
          "LUK": 2,
          "INT": 4,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 88,
          "Evade": 3,
          "Critical": 3,
          "Speed": 1,
          "Critical Damage": 17,
          "Magical Damage": 21,
          "Magical Defense": 27,
          "Physical Damage": 21,
          "Physical Defense": 7,
          "STR": 5,
          "AGI": 1,
          "LUK": 3,
          "INT": 7,
          "effects": []
        }
      },
    ]
  },

  39: {
    id: 39,
    name: "Draconic Cataclysm",
    itemNames: ["Draconic Cataclysm Weapon", "Draconic Cataclysm Sub Weapon", "Draconic Cataclysm Armor", "Draconic Cataclysm Boots", "Draconic Cataclysm Gloves", "Draconic Cataclysm Helmet", "Draconic Cataclysm Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 34,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 5,
          "Magical Damage": 0,
          "Magical Defense": 4,
          "Physical Damage": 0,
          "Physical Defense": 2,
          "STR": 2,
          "AGI": 2,
          "LUK": 0,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 102,
          "Evade": 0,
          "Critical": 0,
          "Speed": 2,
          "Critical Damage": 15,
          "Magical Damage": 8,
          "Magical Defense": 14,
          "Physical Damage": 8,
          "Physical Defense": 8,
          "STR": 5,
          "AGI": 2,
          "LUK": 3,
          "INT": 5,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 204,
          "Evade": 0,
          "Critical": 2,
          "Speed": 2,
          "Critical Damage": 29,
          "Magical Damage": 66,
          "Magical Defense": 28,
          "Physical Damage": 66,
          "Physical Defense": 16,
          "STR": 8,
          "AGI": 3,
          "LUK": 5,
          "INT": 8,
          "effects": []
        }
      },
    ]
  },

  40: {
    id: 40,
    name: "Aegis",
    itemNames: ["Aegis Weapon", "Aegis Sub Weapon", "Aegis Armor", "Aegis Boots", "Aegis Gloves", "Aegis Helmet", "Aegis Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 24,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 1,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 0,
          "Physical Defense": 2,
          "STR": 1,
          "AGI": 1,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 56,
          "Evade": 0,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 3,
          "Magical Damage": 7,
          "Magical Defense": 5,
          "Physical Damage": 7,
          "Physical Defense": 5,
          "STR": 4,
          "AGI": 1,
          "LUK": 0,
          "INT": 4,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 145,
          "Evade": 0,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 7,
          "Magical Damage": 64,
          "Magical Defense": 16,
          "Physical Damage": 64,
          "Physical Defense": 9,
          "STR": 7,
          "AGI": 2,
          "LUK": 1,
          "INT": 7,
          "effects": []
        }
      },
    ]
  },

  41: {
    id: 41,
    name: "Razorwing",
    itemNames: ["Razorwing Weapon", "Razorwing Sub Weapon", "Razorwing Armor", "Razorwing Boots", "Razorwing Gloves", "Razorwing Helmet", "Razorwing Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 21,
          "Evade": 0,
          "Critical": 2,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 0,
          "AGI": 1,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 53,
          "Evade": 1,
          "Critical": 4,
          "Speed": 0,
          "Critical Damage": 9,
          "Magical Damage": 2,
          "Magical Defense": 4,
          "Physical Damage": 2,
          "Physical Defense": 3,
          "STR": 1,
          "AGI": 3,
          "LUK": 1,
          "INT": 4,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 130,
          "Evade": 3,
          "Critical": 8,
          "Speed": 0,
          "Critical Damage": 15,
          "Magical Damage": 21,
          "Magical Defense": 4,
          "Physical Damage": 21,
          "Physical Defense": 7,
          "STR": 2,
          "AGI": 7,
          "LUK": 2,
          "INT": 5,
          "effects": []
        }
      },
    ]
  },

  42: {
    id: 42,
    name: "Invictus",
    itemNames: ["Invictus Weapon", "Invictus Sub Weapon", "Invictus Armor", "Invictus Boots", "Invictus Gloves", "Invictus Helmet", "Invictus Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 16,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 0,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 2,
          "AGI": 0,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 50,
          "Evade": 0,
          "Critical": 3,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 6,
          "Magical Defense": 2,
          "Physical Damage": 6,
          "Physical Defense": 4,
          "STR": 4,
          "AGI": 1,
          "LUK": 2,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 103,
          "Evade": 0,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 3,
          "Magical Damage": 52,
          "Magical Defense": 3,
          "Physical Damage": 52,
          "Physical Defense": 9,
          "STR": 8,
          "AGI": 2,
          "LUK": 3,
          "INT": 3,
          "effects": []
        }
      },
    ]
  },

  43: {
    id: 43,
    name: "Colossal Guardian",
    itemNames: ["Colossal Guardian Weapon", "Colossal Guardian Sub Weapon", "Colossal Guardian Armor", "Colossal Guardian Boots", "Colossal Guardian Gloves", "Colossal Guardian Helmet", "Colossal Guardian Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 35,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 1,
          "Magical Damage": 0,
          "Magical Defense": 2,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 1,
          "AGI": 0,
          "LUK": 1,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 89,
          "Evade": 1,
          "Critical": 3,
          "Speed": 0,
          "Critical Damage": 4,
          "Magical Damage": 6,
          "Magical Defense": 5,
          "Physical Damage": 6,
          "Physical Defense": 4,
          "STR": 4,
          "AGI": 0,
          "LUK": 1,
          "INT": 4,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 218,
          "Evade": 1,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 9,
          "Magical Damage": 54,
          "Magical Defense": 13,
          "Physical Damage": 54,
          "Physical Defense": 7,
          "STR": 8,
          "AGI": 1,
          "LUK": 2,
          "INT": 5,
          "effects": []
        }
      },
    ]
  },

  44: {
    id: 44,
    name: "Excalibur",
    itemNames: ["Excalibur Weapon", "Excalibur Sub Weapon", "Excalibur Armor", "Excalibur Boots", "Excalibur Gloves", "Excalibur Helmet", "Excalibur Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 13,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 2,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 1,
          "AGI": 0,
          "LUK": 0,
          "INT": 0,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 39,
          "Evade": 1,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 3,
          "Magical Damage": 6,
          "Magical Defense": 5,
          "Physical Damage": 6,
          "Physical Defense": 4,
          "STR": 4,
          "AGI": 1,
          "LUK": 1,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 77,
          "Evade": 1,
          "Critical": 1,
          "Speed": 2,
          "Critical Damage": 5,
          "Magical Damage": 56,
          "Magical Defense": 8,
          "Physical Damage": 56,
          "Physical Defense": 9,
          "STR": 7,
          "AGI": 1,
          "LUK": 2,
          "INT": 3,
          "effects": []
        }
      },
    ]
  },

  45: {
    id: 45,
    name: "Hecateus",
    itemNames: ["Hecateus Weapon", "Hecateus Sub Weapon", "Hecateus Armor", "Hecateus Boots", "Hecateus Gloves", "Hecateus Helmet", "Hecateus Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 13,
          "Evade": 0,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 3,
          "Physical Damage": 0,
          "Physical Defense": 3,
          "STR": 1,
          "AGI": 0,
          "LUK": 0,
          "INT": 2,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 38,
          "Evade": 1,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 3,
          "Magical Damage": 7,
          "Magical Defense": 8,
          "Physical Damage": 7,
          "Physical Defense": 5,
          "STR": 3,
          "AGI": 0,
          "LUK": 1,
          "INT": 4,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 74,
          "Evade": 1,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 4,
          "Magical Damage": 62,
          "Magical Defense": 14,
          "Physical Damage": 62,
          "Physical Defense": 18,
          "STR": 5,
          "AGI": 1,
          "LUK": 1,
          "INT": 8,
          "effects": []
        }
      },
    ]
  },

  46: {
    id: 46,
    name: "Galestrike",
    itemNames: ["Galestrike Weapon", "Galestrike Sub Weapon", "Galestrike Armor", "Galestrike Boots", "Galestrike Gloves", "Galestrike Helmet", "Galestrike Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 30,
          "Evade": 0,
          "Critical": 1,
          "Speed": 0,
          "Critical Damage": 2,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 1,
          "STR": 0,
          "AGI": 1,
          "LUK": 1,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 61,
          "Evade": 1,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 5,
          "Magical Damage": 2,
          "Magical Defense": 3,
          "Physical Damage": 2,
          "Physical Defense": 2,
          "STR": 0,
          "AGI": 2,
          "LUK": 1,
          "INT": 3,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 113,
          "Evade": 3,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 11,
          "Magical Damage": 21,
          "Magical Defense": 5,
          "Physical Damage": 21,
          "Physical Defense": 5,
          "STR": 1,
          "AGI": 5,
          "LUK": 2,
          "INT": 6,
          "effects": []
        }
      },
    ]
  },

  47: {
    id: 47,
    name: "Antheia",
    itemNames: ["Antheia Weapon", "Antheia Sub Weapon", "Antheia Armor", "Antheia Boots", "Antheia Gloves", "Antheia Helmet", "Antheia Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 14,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 0,
          "Magical Damage": 2,
          "Magical Defense": 3,
          "Physical Damage": 2,
          "Physical Defense": 1,
          "STR": 1,
          "AGI": 0,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 43,
          "Evade": 0,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 1,
          "Magical Damage": 5,
          "Magical Defense": 8,
          "Physical Damage": 5,
          "Physical Defense": 3,
          "STR": 1,
          "AGI": 1,
          "LUK": 0,
          "INT": 4,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 87,
          "Evade": 0,
          "Critical": 0,
          "Speed": 1,
          "Critical Damage": 3,
          "Magical Damage": 25,
          "Magical Defense": 14,
          "Physical Damage": 25,
          "Physical Defense": 5,
          "STR": 2,
          "AGI": 2,
          "LUK": 1,
          "INT": 7,
          "effects": []
        }
      },
    ]
  },

  48: {
    id: 48,
    name: "Astarot",
    itemNames: ["Astarot Weapon", "Astarot Sub Weapon", "Astarot Armor", "Astarot Boots", "Astarot Gloves", "Astarot Helmet", "Astarot Pants"],
    bonuses: [
      {
        pieces: 2,
        stats: {
          "HP": 18,
          "Evade": 0,
          "Critical": 0,
          "Speed": 0,
          "Critical Damage": 1,
          "Magical Damage": 0,
          "Magical Defense": 1,
          "Physical Damage": 0,
          "Physical Defense": 0,
          "STR": 2,
          "AGI": 0,
          "LUK": 0,
          "INT": 1,
          "effects": []
        }
      },

      {
        pieces: 4,
        stats: {
          "HP": 55,
          "Evade": 0,
          "Critical": 1,
          "Speed": 1,
          "Critical Damage": 4,
          "Magical Damage": 2,
          "Magical Defense": 2,
          "Physical Damage": 2,
          "Physical Defense": 1,
          "STR": 4,
          "AGI": 0,
          "LUK": 1,
          "INT": 3,
          "effects": []
        }
      },

      {
        pieces: 6,
        stats: {
          "HP": 110,
          "Evade": 0,
          "Critical": 2,
          "Speed": 1,
          "Critical Damage": 8,
          "Magical Damage": 22,
          "Magical Defense": 4,
          "Physical Damage": 22,
          "Physical Defense": 2,
          "STR": 8,
          "AGI": 1,
          "LUK": 1,
          "INT": 5,
          "effects": []
        }
      },
    ]
  },
}
