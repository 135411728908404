import React, { useState } from "react";
import { ACTIONS } from "src/app/configs/constants";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import { useSelector } from "react-redux";
import { Material, UserMaterial } from 'src/app/types/materials';
import MaterialActionModal from './MaterialActionModal';
import { ListItem, OfferItem } from "src/app/types/equipment";
import MarketTable from "src/app/components/Commons/MarketTable";

type MaterialOfferTableProps = {
  material: Material;
  ownOffers: OfferItem[];
  offers: OfferItem[];
  lists: ListItem[];
  userMaterials: UserMaterial[];
};

export default function MaterialOfferTable(props: MaterialOfferTableProps) {
  const offers = props.offers;
  const material = props.material;
  const lists = props.lists;
  const ownOffers = props.ownOffers;
  const userMaterials = props.userMaterials;

  const [myOffers, setMyOffers] = useState(false);
  const { address } = useSelector((state: any) => state.account);
  const isEmpty = myOffers ? ownOffers.length === 0 : offers.length === 0;

  function toggleMyOffers() {
    setMyOffers(!myOffers);
  }

  function onOpenPopup(type: number, selectedOffer?: OfferItem) {
    modalService.show(MaterialActionModal, {
      type: type,
      item: material,
      isEquipmentMarket: false,
      selectedOffer: selectedOffer,
      arrayOffers: offers,
      arrayLists: lists,
      ownOffers: ownOffers,
      items: userMaterials
    });
  }

  function handleOfferItem() {
    onOpenPopup(ACTIONS.OFFER_MATERIAL);
  }

  function renderButtons(row: any) {
    if (address === row.buyer) {
      return (
        <div className="btn btn--small btn--yellow" onClick={() => onOpenPopup(ACTIONS.CANCEL_OFFER_MATERIAL)}>
          Cancel
        </div>
      )
    } else if (address) {
      return (
        <div className="btn btn--small" onClick={() => onOpenPopup(ACTIONS.TAKE_OFFER_MATERIAL, row)}>
          Sell
        </div>
      )
    }
  }

  return (
    <div className={`cm-table ${isEmpty ? "cm-table--empty" : ""}`}>
      <div className="cm-table__header">
        <div className="cm-table__title">Offers</div>
        {address && (
          <div className="flex-center-start bot-6">
            <div className="toggle mr-3">
              <div>My Offers</div>
              <input id="my-offers" type="checkbox" checked={myOffers} onChange={toggleMyOffers} />
              <label htmlFor="my-offers" />
            </div>
            <div className="btn" onClick={handleOfferItem}>
              Offer
            </div>
          </div>
        )}
      </div>
      <MarketTable
        headerTitles={["From", "Amt", "Price", "Time", ""]}
        cellTypes={["address", "amount", "price", "time"]}
        cellKeys={["buyer", "amount", "price", "timestamp"]}
        isEmpty={isEmpty}
        isMyOrder={myOffers}
        myOrderData={ownOffers}
        orderData={offers}
        emptyMessage="No Offer Yet"
        renderBtnActions={renderButtons}
      />
    </div>
  );
}
