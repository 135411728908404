import React, { useState } from "react";
import bnbLogo from "src/assets/images/tokens/bnb.svg";
import faraLogo from "src/assets/images/tokens/fara.png";
import { Knight, KnightStats } from "src/app/types/knight";
import { formatNumber } from "src/app/utils/helpers";
import { multiplyNumbers } from "src/app/utils/calculators";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalModal } from "src/app/actions/globalAction";
import circleHighlight from "src/assets/images/decors/circle-highlight.png";
import heroPlaceholder from "src/assets/images/decors/hero-placeholder.png";
import ENV from "src/app/configs/env";
import { Link } from "react-router-dom";
import { modalService } from "src/app/components/Commons/Modals/ModalListener";
import ImportModal from "src/app/components/Commons/Account/ImportModal";
import KnightPopover from "src/app/components/Knight/KnightPopover";
import { Box } from "@material-ui/core";
import KnightStatsInfo from "src/app/components/Knight/KnightStatsInfo";
import useWindowDimensions from "src/app/hooks/useWindowDimensions";
import { deselectKnight, selectKnight } from "src/app/actions/knightAction";

interface KnightListItemProps {
  knight: Knight;
  knightStats?: KnightStats;
  className?: string;
  isLoading?: boolean;
  isStakingPage?: boolean;
  isHeroBurn?: boolean;
}

export default function KnightListContent(props: KnightListItemProps) {
  const dispatch = useDispatch();
  const { selectedHero } = useSelector((state: any) => state.knight);
  const { address } = useSelector((state: any) => state.account);
  const { tokenPrice } = useSelector((state: any) => state.global);
  const [isLoading, setLoading] = useState(true);
  const windowDimensions = useWindowDimensions();
  const stakedAmount = props.knight.stakedAmount;
  const lpStakedAmount = props.knight.lpStakedAmount;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    windowDimensions.width >= 1200 && setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function openKnightInModal() {
    if (!address) {
      modalService.show(ImportModal);
      return;
    }

    if (props.isStakingPage) {
      dispatch(setGlobalModal("knightStaking", { active: true, data: { knight: props.knight } }));
      return;
    }
  }

  function handleImageLoaded() {
    setLoading(false);
  }

  const handleSelect = () => {
    if (selectedHero.some((selected) => selected?.id === props.knight.id)) {
      dispatch(deselectKnight(props.knight));
    } else {
      dispatch(selectKnight(props.knight));
    }
  };

  const renderKnightListContent = () => {
    if (props.knight.id === undefined) return;

    return (
      <div className="knights__item-wrapper">
        <Box {...(windowDimensions.width <= 1200 && { display: "flex", alignItems: "center" })}>
          <div className="knights__item-images">
            <img className="knights__item-highlight" src={circleHighlight} alt="" />
            {isLoading && <img className="knights__item-character" src={heroPlaceholder} alt="" />}
            <img
              style={!isLoading ? {} : { display: "none" }}
              className="knights__item-character"
              src={`${ENV.URL.FARALAND}/images/heroes/${props.knight.id}.png`}
              onLoad={handleImageLoaded}
              alt=""
            />
          </div>
          <div className={`knights__item-id ${props.knight.element?.toLowerCase()}`}>#{props.knight.id}</div>
          <div className={`knights__item-level`}>Level: {props.knight.level}</div>
          <div>
            <div className="knights__item-data">
              {props.knight.element && (
                <img
                  className="knights__item-element"
                  src={require(`src/assets/images/icons/elements/${props.knight.element.toLowerCase()}.png`)}
                  alt=""
                />
              )}
              <div className="knights__item-gender">{props.knight.gender}</div>
              <div className="knights__item-race">{props.knight.displayRace}</div>
            </div>
            <div className="knights__item-content">
              {props.isStakingPage ? (
                <div className="knights__item-footer staked">
                  <div className="flex-center-center">
                    <img className="knights__item-token" src={faraLogo} alt="" />
                    <span className="knights__item-price">
                      <span className="mr-1">{formatNumber(stakedAmount)}</span>
                      <span className="text-gray fs-4">Staked</span>
                    </span>
                  </div>
                  <div className="flex-center-center">
                    <img className="knights__item-token" style={{ marginRight: -3 }} src={faraLogo} alt="" />
                    <img className="knights__item-token" src={bnbLogo} alt="" />
                    <span className="knights__item-price">
                      <span className="mr-1">{formatNumber(lpStakedAmount)}</span>
                      <span className="text-gray fs-4">Staked</span>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="knights__item-footer">
                  <div className="flex-start-center">
                    <img className="knights__item-token top-2" src={bnbLogo} alt="" />
                    <div className="knights__item-price">
                      <div>{props.knight.price ? formatNumber(props.knight.price, 2) : "-/-"} BNB</div>
                      {props.knight.price !== 0 && (
                        <div className="knights__item-value">
                          ≈ ${formatNumber(multiplyNumbers(props.knight.price, tokenPrice.BNB), 0)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Box>
        {props.knightStats && (
          <>
            <KnightStatsInfo baseStats={props.knightStats!.baseStats} />
            <KnightPopover
              open={openPopover}
              anchorEl={anchorEl}
              knight={props.knight}
              knightStats={props.knightStats}
              handlePopoverClose={handlePopoverClose}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {props.isStakingPage ? (
        <div className={`market__item knights__item ${props.className ?? ""}`} onClick={openKnightInModal}>
          {renderKnightListContent()}
        </div>
      ) : props.isHeroBurn ? (
        <div
          className={`market__item market__item--knight knights__item ${props.className ?? ""} ${
            selectedHero.some((selected: any) => selected?.id === props.knight.id) && "market__item--selected"
          }`}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          onClick={handleSelect}
        >
          {renderKnightListContent()}
        </div>
      ) : (
        <Link
          className={`market__item market__item--knight knights__item ${props.className ?? ""}`}
          to={`hero/${props.knight.id}`}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {renderKnightListContent()}
        </Link>
      )}
    </>
  );
}
