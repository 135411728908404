import { KnightFilterType, KnightQuery } from "src/app/types/knight";
import {
  INIT_DEMI_FILTER,
  INIT_GUILD_FILTER,
  INIT_ITEM_FILTER,
  INIT_KNIGHT_FILTER,
  INIT_LAND_FILTER,
  INIT_MATERIAL_FILTER,
  MAX_STAT_RANGE,
  SALE,
  SORT_BY,
  SORT_DATA,
  STATS_LABEL,
} from "src/app/configs/constants";
import {
  convertStatNameToAPIQuery,
  mapLandTraitsToAPIQuery,
  mapSlotTypeNameToApi,
  mapTraitsToAPIQuery,
} from "src/app/utils/mappingHelpers";
import { ItemFilterType, ItemQuery } from "src/app/types/equipment";
import { MaterialFilterType, MaterialQuery } from "src/app/types/materials";
import _ from "lodash";
import { EQUIPMENT_SET } from "src/app/configs/equipment/sets";
import { EQUIPMENT_EFFECTS } from "src/app/configs/equipment/effects";
import { DemiFilterType } from "src/app/types/demiKnight";
import { convertStringToNumArray } from "src/app/utils/helpers";
import { GuildFilterType, GuildQuery } from "src/app/types/guild";
import { INIT_GUILD_MEMBER_FILTER } from "../configs/constants";
import { LandFilterType } from "src/app/types/land";

export function buildKnightListWhereCondition(filters: KnightFilterType, query: KnightQuery) {
  const whereObj: any = {};

  /** ID filtering **/
  if (filters.id) {
    return { id: filters.id, address: query.owner };
  }
  /** Owner filtering **/
  if (filters.owned) {
    whereObj.owner = query.owner;
  }
  if (filters.staked) {
    whereObj.staked = true;
  }

  /** Sale filtering **/
  if (filters.sales[SALE.FOR_SALE]) {
    whereObj.isSale = true;
  }
  if (filters.sales[SALE.NOT_FOR_SALE]) {
    whereObj.isSale = false;
  }

  /** Race filtering **/
  if (filters.races) {
    let arrayRaces: string[] = [];
    Object.keys(filters.races).forEach((race: string) => {
      if (filters.races[race]) arrayRaces.push(race);
    });
    whereObj.races = arrayRaces;
  }

  /** Type filtering **/
  if (filters.elements) {
    let arrayTypes: string[] = [];
    Object.keys(filters.elements).forEach((element: string) => {
      if (filters.elements[element]) arrayTypes.push(element);
    });
    whereObj.types = arrayTypes;
  }

  /** Gender filtering **/
  if (filters.genders) {
    let arrayGenders: string[] = [];
    Object.keys(filters.genders).forEach((gender: string) => {
      if (filters.genders[gender]) arrayGenders.push(gender);
    });
    whereObj.genders = arrayGenders;
  }

  if (filters.stats) {
    for (let key in filters.stats) {
      let name = convertStatNameToAPIQuery(key);
      whereObj[name] = filters.stats[key.toString()];
    }
  }

  if (filters.traits) {
    whereObj["traits"] = mapTraitsToAPIQuery(filters.traits);
  }

  if (filters.traitRarity) {
    whereObj["traitRarity"] = +filters.traitRarity;
  }

  if (filters.totalBaseStats) {
    whereObj.totalBaseStats = +filters.totalBaseStats;
  }

  if (filters.numberOfBodyParts) {
    whereObj.numberOfBodyParts = +filters.numberOfBodyParts;
  }

  // build order and paging
  /** Sale Time filtering **/
  const sortData = SORT_DATA[filters.sortBy];
  whereObj.orderBy = { [sortData.key]: sortData.direction };
  whereObj.page = query.page;
  whereObj.itemPerPage = query.itemPerPage;
  whereObj.address = query.owner;
  return whereObj;
}

export function buildItemListWhereCondition(filters: ItemFilterType, query: ItemQuery) {
  const whereObj: any = {};

  /** ID filtering **/
  if (filters.id) {
    return { id: +filters.id };
  }

  if (filters.name) {
    whereObj.displayName = filters.name;
  }
  /** Race filtering **/
  if (filters.races) {
    let arrayRaces: string[] = [];
    Object.keys(filters.races).forEach((race: string) => {
      if (filters.races[race]) arrayRaces.push(race);
    });
    whereObj.races = arrayRaces;
  }

  if (filters.stats) {
    for (let key in filters.stats) {
      let name = convertStatNameToAPIQuery(key);
      whereObj[name] = filters.stats[key.toString()];
    }
  }

  if (filters.levels) {
    whereObj.levels = filters.levels.LVL;
  }

  if (filters.rarity) {
    let arrayRarity: string[] = [];
    arrayRarity.push(filters.rarity.toLowerCase());
    whereObj.rarities = arrayRarity;
  }

  /** Gender filtering **/
  if (filters.genders) {
    let arrayGenders: string[] = [];
    Object.keys(filters.genders).forEach((gender: string) => {
      if (filters.genders[gender]) arrayGenders.push(gender);
    });
    whereObj.genders = arrayGenders;
  }

  if (filters.effects) {
    whereObj.effects = filters.effects.map((effect) => {
      return effect.id;
    });
  }

  if (filters.tier) {
    whereObj.tier = filters.tier;
  }

  if (filters.slotType) {
    whereObj.slotType = filters.slotType === "All" ? "" : mapSlotTypeNameToApi(filters.slotType);
  }

  if (filters.set) {
    whereObj.set = filters.set.id;
  }

  // build order and paging
  /** Sale Time filtering **/
  const sortData = SORT_DATA[filters.sortBy];
  whereObj.orderBy = { [sortData.key]: sortData.direction };
  whereObj.page = query.page;
  whereObj.itemPerPage = query.itemPerPage;

  return whereObj;
}

export function buildLandListWhereCondition(filters: LandFilterType, query: ItemQuery) {
  const whereObj: any = {};

  /** ID filtering **/
  if (filters.id) {
    return { id: +filters.id };
  }

  /** Owner filtering **/
  if (filters.owned) {
    whereObj.owner = query.owner;
  }

  if (filters.tier) {
    whereObj.tier = filters.tier;
  }
  
  /** Sale filtering **/
  if (filters.sales[SALE.FOR_SALE]) {
    whereObj.isSale = true;
  }
  if (filters.sales[SALE.NOT_FOR_SALE]) {
    whereObj.isSale = false;
  }

  /** Race filtering **/
  if (filters.landType) {
    let arrayLandTypes: string[] = [];
    Object.keys(filters.landType).forEach((type: string) => {
      if (filters.landType[type]) arrayLandTypes.push(`${type}_Realm`);
    });
    whereObj.types = arrayLandTypes;
  }

  if (filters.name) {
    whereObj.displayName = filters.name;
  }

  if (filters.traits) {
    whereObj["traits"] = mapLandTraitsToAPIQuery(filters.traits);
  }

  if (filters.traitRarity) {
    whereObj["traitRarity"] = +filters.traitRarity;
  }

  // build order and paging
  /** Sale Time filtering **/
  const sortData = SORT_DATA[filters.sortBy];
  whereObj.orderBy = { [sortData.key]: sortData.direction };
  whereObj.page = query.page;
  whereObj.itemPerPage = query.itemPerPage;
  whereObj.address = query.owner;
  return whereObj;
}

export function buildMaterialListWhereCondition(filters: MaterialFilterType, query: MaterialQuery) {
  const whereObj: any = {};

  /** ID filtering **/
  if (filters.id) {
    return { id: +filters.id };
  }

  if (filters.name) {
    whereObj.name = filters.name;
  }

  if (filters.rarity) {
    let arrayRarity: string[] = [];
    arrayRarity.push(filters.rarity.toLowerCase());
    whereObj.rarities = arrayRarity;
  }

  // build order and paging
  /** Sale Time filtering **/
  const sortData = SORT_DATA[filters.sortBy];
  whereObj.orderBy = { [sortData.key]: sortData.direction };
  whereObj.page = query.page;
  whereObj.itemPerPage = query.itemPerPage;

  return whereObj;
}

export function buildGuildListWhereCondition(filters: GuildFilterType, query: GuildQuery) {
  const whereObj: any = {};

  /** ID filtering **/
  if (filters.id) {
    return { id: +filters.id };
  }

  if (filters.name) {
    whereObj.name = filters.name;
  }

  // build order and paging
  /** Sale Time filtering **/
  const sortData = SORT_DATA[filters.sortBy];
  whereObj.orderBy = { [sortData.key]: sortData.direction };
  whereObj.page = query.page;
  whereObj.itemPerPage = query.itemPerPage;

  return whereObj;
}

export function buildGuildMemberListWhereCondition(filters: GuildFilterType, query: GuildQuery) {
  const whereObj: any = {};

  /** ID filtering **/
  if (filters.id) {
    whereObj.id = filters.id;
  }

  // build order and paging
  /** Sale Time filtering **/
  const sortData = SORT_DATA[filters.sortBy];
  whereObj.orderBy = { [sortData.key]: sortData.direction };
  whereObj.page = query.page;
  whereObj.itemPerPage = query.itemPerPage;

  return whereObj;
}
export function getGuildFilterFromParams(queryParams: any, isMemberList: boolean): GuildFilterType {
  const filterObj = _.cloneDeep(isMemberList ? INIT_GUILD_MEMBER_FILTER : INIT_GUILD_FILTER);

  if (!queryParams) return filterObj;

  Object.keys(queryParams).forEach((category: string) => {
    if (category === "id") return (filterObj.id = queryParams[category]);
    if (category === "name") return (filterObj.name = queryParams[category]);
    if (category === "sortBy") return (filterObj.sortBy = queryParams[category]);
    if (category === "joinable") return (filterObj.joinable = queryParams[category] === "true");
    if (category === "pending") return (filterObj.pending = queryParams[category] === "true");
    if (category === "page" && parseInt(queryParams[category]) > 0) {
      return (filterObj.page = parseInt(queryParams[category]));
    }

    const stringSelected = queryParams[category];
    const categoryValue: any = {};

    if (stringSelected) {
      const arraySelected = stringSelected.split(",");
      arraySelected.forEach((value: string) => {
        categoryValue[value] = true;
      });
    }
    filterObj[category] = { ...filterObj[category], ...categoryValue };
  });
  return filterObj;
}

export function getMaterialFilterFromParams(queryParams: any): MaterialFilterType {
  const filterObj = _.cloneDeep(INIT_MATERIAL_FILTER);

  if (!queryParams) return filterObj;

  Object.keys(queryParams).forEach((category: string) => {
    if (category === "id") return (filterObj.id = queryParams[category]);
    if (category === "name") return (filterObj.name = queryParams[category]);
    if (category === "sortBy") return (filterObj.sortBy = queryParams[category]);
    if (category === "rarity") return (filterObj.rarity = queryParams[category]);
    if (category === "owned") return (filterObj.owned = queryParams[category] === "true");
    if (category === "offering") return (filterObj.offering = queryParams[category] === "true");
    if (category === "listings") return (filterObj.listings = queryParams[category] === "true");
    if (category === "page" && parseInt(queryParams[category]) > 0) {
      return (filterObj.page = parseInt(queryParams[category]));
    }

    const stringSelected = queryParams[category];
    const categoryValue: any = {};

    if (stringSelected) {
      const arraySelected = stringSelected.split(",");
      arraySelected.forEach((value: string) => {
        categoryValue[value] = true;
      });
    }
    filterObj[category] = { ...filterObj[category], ...categoryValue };
  });
  return filterObj;
}

export function getItemFilterFromParams(queryParams: any): ItemFilterType {
  const filterObj = _.cloneDeep(INIT_ITEM_FILTER);

  if (!queryParams) return filterObj;

  Object.keys(queryParams).forEach((category: string) => {
    if (category === "id") return (filterObj.id = queryParams[category]);
    if (category === "name") return (filterObj.name = queryParams[category]);
    if (category === "sortBy") return (filterObj.sortBy = queryParams[category]);
    if (category === "rarity") return (filterObj.rarity = queryParams[category]);
    if (category === "owned") return (filterObj.owned = queryParams[category] === "true");
    if (category === "offering") return (filterObj.offering = queryParams[category] === "true");
    if (category === "listings") return (filterObj.listings = queryParams[category] === "true");
    if (category === "craftable") return (filterObj.craftable = queryParams[category] === "true");
    if (category === "set") {
      if (queryParams[category]) {
        return (filterObj.set = EQUIPMENT_SET[queryParams[category]]);
      } else {
        return (filterObj.set = []);
      }
    }
    if (category === "effects") {
      const effects: any[] = queryParams[category].split(",");
      filterObj.effects = effects.map((value) => {
        return _.find(EQUIPMENT_EFFECTS, (effect) => {
          return effect.name === value;
        });
      });
      if (!filterObj.effects[0]) {
        filterObj.effects = [];
      }
      return filterObj.effects;
    }
    if (category === "slotType") return (filterObj.slotType = queryParams[category]);
    if (category === "exchanges") return (filterObj.exchanges = queryParams[category] === "true");
    if (category === "tier") return (filterObj.tier = +queryParams[category]);
    if (category === "page" && parseInt(queryParams[category]) > 0) {
      return (filterObj.page = parseInt(queryParams[category]));
    }
    if (category === "LVL") return (filterObj.levels.LVL = convertStringToNumArray(queryParams[category]));
    if ([STATS_LABEL.STR, STATS_LABEL.AGI, STATS_LABEL.INT, STATS_LABEL.LUK].includes(category)) {
      return (filterObj.stats[category.toString()] = convertStringToNumArray(queryParams[category]));
    }

    const stringSelected = queryParams[category];
    const categoryValue: any = {};

    if (stringSelected) {
      const arraySelected = stringSelected.split(",");
      arraySelected.forEach((value: string) => {
        categoryValue[value] = true;
      });
    }
    filterObj[category] = { ...filterObj[category], ...categoryValue };
  });

  return filterObj;
}

export function getHeroFilterFromParams(queryParams: any): KnightFilterType {
  const filterObj = _.cloneDeep(INIT_KNIGHT_FILTER);

  if (!queryParams) return filterObj;

  Object.keys(queryParams).forEach((category: string) => {
    if (category === "id") return (filterObj.id = queryParams[category]);
    if (category === "totalBaseStats") return (filterObj.totalBaseStats = queryParams[category]);
    if (category === "numberOfBodyParts") return (filterObj.numberOfBodyParts = queryParams[category]);
    if (category === "sortBy") return (filterObj.sortBy = queryParams[category]);
    if (category === "owned") return (filterObj.owned = queryParams[category] === "true");
    if (category === "staked") return (filterObj.staked = queryParams[category] === "true");
    if (category === "offering") return (filterObj.offering = queryParams[category] === "true");
    if (category === "trait") return (filterObj.traits = JSON.parse(queryParams[category]));
    if (category === "traitRarity") return (filterObj.traitRarity = +queryParams[category]);
    if (category === "page" && parseInt(queryParams[category]) > 0) {
      return (filterObj.page = parseInt(queryParams[category]));
    }

    if ([STATS_LABEL.STR, STATS_LABEL.AGI, STATS_LABEL.INT, STATS_LABEL.LUK].includes(category)) {
      return (filterObj.stats[category.toString()] = convertStringToNumArray(queryParams[category]));
    }

    const stringSelected = queryParams[category];
    const categoryValue: any = {};

    if (stringSelected) {
      const arraySelected = stringSelected.split(",");
      arraySelected.forEach((value: string) => {
        categoryValue[value] = true;
      });
    }

    filterObj[category] = { ...filterObj[category], ...categoryValue };
  });

  return filterObj;
}

export function getDemiFilterFromParams(queryParams: any): DemiFilterType {
  const filterObj = _.cloneDeep(INIT_DEMI_FILTER);

  if (!queryParams) return filterObj;

  Object.keys(queryParams).forEach((category: string) => {
    if (category === "id") return (filterObj.id = queryParams[category]);
    if (category === "totalBaseStats") return (filterObj.totalBaseStats = queryParams[category]);
    if (category === "numberOfBodyParts") return (filterObj.numberOfBodyParts = queryParams[category]);
    if (category === "sortBy") return (filterObj.sortBy = queryParams[category]);
    if (category === "owned") return (filterObj.owned = queryParams[category] === "true");
    if (category === "staked") return (filterObj.staked = queryParams[category] === "true");
    if (category === "offering") return (filterObj.offering = queryParams[category] === "true");
    if (category === "trait") return (filterObj.traits = JSON.parse(queryParams[category]));
    if (category === "traitRarity") return (filterObj.traitRarity = +queryParams[category]);
    if (category === "page" && parseInt(queryParams[category]) > 0) {
      return (filterObj.page = parseInt(queryParams[category]));
    }

    if ([STATS_LABEL.STR, STATS_LABEL.AGI, STATS_LABEL.INT, STATS_LABEL.LUK].includes(category)) {
      return (filterObj.stats[category.toString()] = convertStringToNumArray(queryParams[category]));
    }

    const stringSelected = queryParams[category];
    const categoryValue: any = {};

    if (stringSelected) {
      const arraySelected = stringSelected.split(",");
      arraySelected.forEach((value: string) => {
        categoryValue[value] = true;
      });
    }

    filterObj[category] = { ...filterObj[category], ...categoryValue };
  });

  return filterObj;
}

export function getLandFilterFromParams(queryParams: any): LandFilterType {
  const filterObj = _.cloneDeep(INIT_LAND_FILTER);

  if (!queryParams) return filterObj;

  Object.keys(queryParams).forEach((category: string) => {
    if (category === "id") return (filterObj.id = queryParams[category]);
    if (category === "sortBy") return (filterObj.sortBy = queryParams[category]);
    if (category === "owned") return (filterObj.owned = queryParams[category] === "true");
    if (category === "offering") return (filterObj.offering = queryParams[category] === "true");
    if (category === "traits") return (filterObj.traits = JSON.parse(queryParams[category]));
    if (category === "tier") return (filterObj.tier = +queryParams[category]);
    if (category === "traitRarity") return (filterObj.traitRarity = +queryParams[category]);
    if (category === "page" && parseInt(queryParams[category]) > 0) {
      return (filterObj.page = parseInt(queryParams[category]));
    }

    const stringSelected = queryParams[category];
    const categoryValue: any = {};

    if (stringSelected) {
      const arraySelected = stringSelected.split(",");
      arraySelected.forEach((value: string) => {
        categoryValue[value] = true;
      });
    }

    filterObj[category] = { ...filterObj[category], ...categoryValue };
  });

  return filterObj;
}

export function getFilterCount(filters: any): number {
  let filterCount = 0;

  if (filters.id) filterCount++;
  if (filters.sortBy !== SORT_BY.LOWEST_PRICE) filterCount++;
  filterCount += getBasicFilterCount(filters) + getAdvancedFilterCount(filters);

  return filterCount;
}

export function getBasicFilterCount(filters: any): number {
  let filterCount = 0;

  if (filters.races) {
    Object.keys(filters.races).forEach((key: string) => {
      if (filters.races[key]) filterCount++;
    });
  }

  if (filters.name) filterCount++;

  if (filters.elements) {
    Object.keys(filters.elements).forEach((key: string) => {
      if (filters.elements[key]) filterCount++;
    });
  }

  if (filters.genders) {
    Object.keys(filters.genders).forEach((key: string) => {
      if (filters.genders[key]) filterCount++;
    });
  }

  if (filters.sales) {
    Object.keys(filters.sales).forEach((key: string) => {
      if (filters.sales[key]) filterCount++;
    });
  }

  if (filters.landType) {
    Object.keys(filters.landType).forEach((key: string) => {
      if (filters.landType[key]) filterCount++;
    });
  }

  if (filters.rarity) filterCount++;

  if (filters.tier) {
    filterCount++;
  }

  return filterCount;
}

export function getAdvancedFilterCount(filters: any): number {
  let filterCount = 0;

  if (filters.stats) {
    Object.keys(filters.stats).forEach((key: string) => {
      if (!_.isEqual(filters.stats[key], [0, MAX_STAT_RANGE])) filterCount++;
    });
  }

  if (filters.traits) filterCount += filters.traits.length;

  if (filters.landComponents) filterCount += filters.landComponents.length;

  if (filters.componentRarity) filterCount++;

  if (filters.traitRarity) filterCount++;

  if (filters.effects) filterCount += filters.effects.length;

  if (filters.slotType) {
    filters.slotType !== "All" && filterCount++;
  }

  if (filters.set) filterCount++;

  if (filters.totalBaseStats) filterCount++;

  if (filters.numberOfBodyParts) filterCount++;

  return filterCount;
}
