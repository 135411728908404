import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { approve } from "src/app/actions/txAction";
import { FARA_ALLOWANCE } from "src/app/configs/constants";
import ENV from "src/app/configs/env";
import { compareNumbers } from "src/app/utils/calculators";
import { toBigAmount } from "src/app/utils/helpers";

export default function useFetchingAllowance(
  spender: string,
  contract = "faraContract",
  tokenAddress = ENV.CONTRACT.FARA_TOKEN,
  spendingAmount?: number | string
) {
  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.account);
  const { web3Service } = useSelector((state: any) => state.global);
  const [needApprove, setNeedApprove] = useState(false);
  const [amount, setAmount] = useState(spendingAmount ?? FARA_ALLOWANCE);
  const [allowance, setAllowance] = useState<string | number>("");

  useEffect(() => {
    if (!address || !web3Service) {
      setNeedApprove(false);
      return;
    }
    async function fetchAllowance(address) {
      try {
        const tokenAllowance = await web3Service.fetchTokenAllowance(address, spender, contract);
        setAllowance(tokenAllowance);
      } catch (e) {
        console.log(e);
      }
    }
    fetchAllowance(address);
  }, [address, web3Service, needApprove]); // eslint-disable-line

  useEffect(() => {
    if (allowance === "" || +allowance === 0) {
      setNeedApprove(true);
      return;
    }
    const compareAllowance = compareNumbers(allowance, toBigAmount(amount));

    setNeedApprove(compareAllowance === -1);
  }, [amount, allowance]);

  function sendApproveTx() {
    dispatch(approve(spender, onApproveSuccess, tokenAddress));
  }

  function addSpendingAmount(amount: number | string) {
    if (amount === 0) return;
    setAmount(!Number.isNaN(+amount) ? +amount : 0);
  }

  function onApproveSuccess() {
    setNeedApprove(false);
  }

  return [needApprove, sendApproveTx as any, addSpendingAmount as any];
}
