/**
 * Rate: 70% for COMMON, 24.5% for UNCOMMON, 5% for RARE, 0.494% for EPIC, 0.005% for LEGENDARY, 0.001% for MYTHICAL
 * Total: 75000
 *
 * Common: 56250
 * Uncommon: 18375
 * RARE: 3750
 * EPIC: 375
 * LEGENDARY: 20
 * MYTHICAL: 5
 *
 */

export const EQUIPMENT_EFFECTS = [
  {
    id: 1,
    name: "Devour"
  },
  {
    id: 2,
    name: "Lifesteal"
  },
  {
    id: 3,
    name: "Piercing Strike"
  },
  {
    id: 4,
    name: "Heaven Revenge"
  },
  {
    id: 5,
    name: "Absorb"
  },
  {
    id: 6,
    name: "ViperSting"
  },
  {
    id: 7,
    name: "Weakness"
  },
  {
    id: 8,
    name: "Seed Of Evil"
  },
  {
    id: 9,
    name: "Mark of Death"
  },
  {
    id: 10,
    name: "Beast Penetration"
  },
  {
    id: 11,
    name: "Bash"
  },
  {
    id: 12,
    name: "Root"
  },
  {
    id: 13,
    name: "Slow"
  },
  {
    id: 14,
    name: "Knockback"
  },
  {
    id: 15,
    name: "Sea Proof"
  },
  {
    id: 16,
    name: "Crazy"
  },
  {
    id: 17,
    name: "Sleep"
  },
  {
    id: 18,
    name: "Silent"
  },
  {
    id: 19,
    name: "Disarm"
  },
  {
    id: 20,
    name: "Doom"
  },
  {
    id: 21,
    name: "Blind"
  },
  {
    id: 22,
    name: "Seed of Forest"
  },
  {
    id: 23,
    name: "Eye of Darkness"
  },
  {
    id: 24,
    name: "Burning"
  },
  {
    id: 25,
    name: "Breaking Strike"
  },
  {
    id: 26,
    name: "Deadly Strike"
  },
  {
    id: 27,
    name: "Soul Hook"
  },
  {
    id: 28,
    name: "Thunder Proof"
  },
  {
    id: 29,
    name: "Vanguard Warrior"
  },
  {
    id: 30,
    name: "The True Courage"
  },
  {
    id: 31,
    name: "Death and Decay"
  },
  {
    id: 32,
    name: "Peace Maker"
  },
  {
    id: 33,
    name: "Holy Light"
  },
  {
    id: 34,
    name: "Honeyed Sanction"
  },
  {
    id: 35,
    name: "Sweet Party Treats"
  },
  {
    id: 36,
    name: "Holy Blessing"
  },
  {
    id: 37,
    name: "Winter Soldier"
  },
  {
    id: 38,
    name: "Snow Huntress"
  },
  {
    id: 39,
    name: "Frost Brawler"
  },
  {
    id: 40,
    name: "Glacial Feather Wings"
  },
  {
    id: 41,
    name: "Tundra Commander"
  },
  {
    id: 42,
    name: "Frostheart"
  },
  {
    id: 43,
    name: "Binance aura"
  },
  {
    id: 44,
    name: "LifeBurn"
  },
  {
    id: 45,
    name: "Wild Boar Summoning"
  },
  {
    id: 46,
    name: "Mad Tiger Summoning"
  },
  {
    id: 47,
    name: "Magic Strike"
  },
  {
    id: 48,
    name: "Wind Cutter"
  },
  // {
  //   id: 49,
  //   name: "Intellectual Convergence"
  // },
  // {
  //   id: 50,
  //   name: "Soul Summoning Lv1"
  // },
  // {
  //   id: 51,
  //   name: "Soul Summoning Lv2"
  // },
  // {
  //   id: 52,
  //   name: "Soul Summoning Lv3"
  // },
  // {
  //   id: 53,
  //   name: "Isolation"
  // },
  {
    id: 54,
    name: "Meteor"
  },
  {
    id: 55,
    name: "Frost Blood"
  },
  {
    id: 56,
    name: "Viking Warrior"
  },
  {
    id: 57,
    name: "Atrophy aura"
  },
  {
    id: 58,
    name: "Golden Warrior"
  },
  {
    id: 59,
    name: "Improve Healing"
  },
  {
    id: 60,
    name: "Dragon Blood"
  },
  {
    id: 61,
    name: "Soul Strike"
  },
]