import React from "react";
import { unix } from "moment";
import faraTokenLogo from "src/assets/images/tokens/fara.png";
import InputGroup from "src/app/components/Commons/InputGroup";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import Countdown from "react-countdown";
import useStakingFormData from "src/app/components/Staking/hooks/useStakingFormData";

type StakingFormDeprecatedProps = {
  selectedPool: number;
  contractAddress: string;
};

export default function StakingFormDeprecated(props: StakingFormDeprecatedProps) {
  const { selectedPool, contractAddress } = props;

  const {
    stakingData,
    stakingAmount,
    stakingError,
    unstakingAmount,
    unstakingError,
    lockedMonths,
    needApprove,
    disableUnstakeBtn,
    disableClaimBtn,
    balance,
    handleClaim,
    handleStake,
    handleUnstake,
    handleUnstakingAmountChange,
    handleStakingAmountChange,
    handleLockTimeChange,
    renderCountUpNumber,
    renderTime,
  } = useStakingFormData(selectedPool, contractAddress);

  function renderStakingOption(value: number) {
    return <option value={value}>Flexible 5% APR</option>;
  }

  return (
    <div className="staking-form__container" key={selectedPool}>
      <div className="staking-form__block disabled">
        <div>
          <div className="dashed-title">
            <span>Stake</span>
          </div>
          <InputGroup
            value={stakingAmount}
            handleChange={handleStakingAmountChange}
            symbol="FARA"
            balance={balance.FARA}
            error={stakingError}
            tokenImage={faraTokenLogo}
          />
          {stakingData.lockedMonths === 0 && (
            <FormControl variant="outlined" className="input input--select mt-2">
              <InputLabel htmlFor="outlined-age-native-simple">Lock Time</InputLabel>
              <Select label="Lock Time" value={lockedMonths} onChange={handleLockTimeChange} native>
                {renderStakingOption(0)}
              </Select>
            </FormControl>
          )}
        </div>
        <div
          className={`staking-form__btn btn ${stakingData.lockedMonths > 0 ? "disabled" : ""}`}
          onClick={handleStake}
        >
          {needApprove ? "Approve FARA" : "Stake"}
        </div>
      </div>
      <div className="staking-form__block slide-up">
        <div>
          <div className="dashed-title">
            <span>Unstake</span>
          </div>
          <InputGroup
            value={unstakingAmount}
            handleChange={handleUnstakingAmountChange}
            symbol="FARA"
            balance={stakingData.stakedAmount}
            balanceText="Staked"
            error={unstakingError}
            tokenImage={faraTokenLogo}
          />
          <div className="staking-form__countdown">
            {stakingData.unlockedTime !== 0 && (
              <>
                <div>Unlocked In:</div>
                <div className="fs-2">
                  <Countdown date={unix(stakingData.unlockedTime).toString()} renderer={renderTime} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`staking-form__btn btn ${disableUnstakeBtn ? "disabled" : ""}`} onClick={handleUnstake}>
          Unstake
        </div>
      </div>
      <div className="staking-form__block align-center slide-up">
        <div>
          <div className="dashed-title">
            <span>Reward</span>
          </div>
          <div>
            <div className="mb-1">{renderCountUpNumber(+stakingData.tokenEarned)} FARA</div>
            <div>{renderCountUpNumber(+stakingData.expEarned)} EXP</div>
          </div>
        </div>
        <div className={`staking-form__btn btn ${disableClaimBtn ? "disabled" : ""}`} onClick={handleClaim}>
          Claim
        </div>
      </div>
    </div>
  );
}
