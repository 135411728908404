import React from "react";
import { Tooltip } from "@material-ui/core";
import { formatNumber } from "src/app/utils/helpers";
import soulStoneLogo from "src/assets/images/tokens/soulstone.png";
import expJarLogo from "src/assets/images/tokens/expjar.png";
import pityNotReachedImg from "src/assets/images/crafting/pity/initial.png";
import pityReachedImg from "src/assets/images/crafting/pity/reached.png";

type ExpProgressBarProps = {
  currentExp: number;
  requiredExp: number;
  level?: number;
  levelUpAmount?: number;
  isOwner?: boolean;
  convertExpToLevels?: any;
  levelUpText?: string;
  isExpired?: boolean;
  openIncreaseEXPModal?: any;
  openUseExpJarModal?: any;
  hideExp?: boolean;
  expText?: string;
  className?: string;
  hasArtisanOrb?: boolean;
  artisanRarity?: string;
};

export default function ExpProgressBar(props: ExpProgressBarProps) {
  const {
    currentExp,
    requiredExp,
    level,
    levelUpAmount,
    isOwner,
    levelUpText,
    isExpired,
    convertExpToLevels,
    openIncreaseEXPModal,
    hideExp,
    openUseExpJarModal,
    expText,
    hasArtisanOrb,
    artisanRarity
  } = props;
  const barWidth = currentExp >= requiredExp ? 100 : ((currentExp / requiredExp) * 100).toFixed(0);

  return (
    <div className="progress-bar">
      <div className="progress-bar__info">
        <div className={`progress-bar__exp ${level !== undefined || hasArtisanOrb ? "full-width" : ""}`}>
          {level !== undefined ? (
            <div className="progress-bar__exp-title">
              <div className="text-gray fs-1">Level {level}</div>
              <div className="text-yellow fs-1">
                {formatNumber(currentExp)} / {formatNumber(requiredExp)}
              </div>
            </div>
          ) : (
            !hideExp && (
              <div className="progress-bar__exp-artisan">
                <div className={hasArtisanOrb ? "progress-bar__exp-artisan--text" : ""}>
                  <span className="fw-4 mr-1">{expText ?? "EXP"}:</span>
                  <span className="text-yellow">
                    {formatNumber(currentExp)} / {formatNumber(requiredExp)}
                  </span>
                </div>
                {hasArtisanOrb && (
                  <Tooltip
                    placement="top"
                    arrow
                    title={
                      currentExp === requiredExp
                        ? `${artisanRarity} Item Guaranteed`
                        : "Earn more artisan to guarantee high-quality item by crafting"
                    }
                  >
                    <img
                      style={{width: 30}}
                      src={currentExp === requiredExp ? pityReachedImg : pityNotReachedImg}
                      className={currentExp === requiredExp ? "item-crafting__pity-reached" : ""}
                      alt="Artisan Orb"
                    />
                  </Tooltip>
                )}
              </div>
            )
          )}
          {levelUpAmount !== undefined && levelUpAmount > 0 && isOwner && (
            <Tooltip
              title={`${
                isExpired === true
                  ? "Your demi has been in hibernation state, consume some Soul Stones to extend its lifespan."
                  : levelUpText
              }`}
              arrow
              placement="top"
            >
              <span>
                <span className={`plus-btn ${isExpired === true ? "disabled" : ""}`} onClick={convertExpToLevels}>
                  +
                </span>
              </span>
            </Tooltip>
          )}
        </div>
        <div className="flex">
          {isOwner && openIncreaseEXPModal && (
            <div className="flex-center-start">
              <div className="progress-bar__link" onClick={openIncreaseEXPModal}>
                Get more EXP
              </div>
              <img className="token token--small" src={soulStoneLogo} alt="" />
            </div>
          )}
          {isOwner && openUseExpJarModal && (
            <div className="flex-center-start ml-2">
              <div className="progress-bar__link" onClick={openUseExpJarModal}>
                Use Exp Jar
              </div>
              <img className="token token--small" src={expJarLogo} alt="" />
            </div>
          )}
        </div>
      </div>
      <div className={`progress-bar__bar ${barWidth === 100 ? "progress-bar__bar--full" : ""}`}>
        <div style={{ width: `${barWidth}%` }} />
      </div>
    </div>
  );
}
